import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Company } from '../models/company.model';
import { User } from '../models/user.model';
import { Invite } from '../models/invite.model';
import { Level } from '../models/level.model';
import { Department } from '../models/department.model';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class CompanyService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Company[]>(BACKEND_URL + '/company');
  }

  getAllCreateAt(start, end) {
    return this.http.get<Company[]>(BACKEND_URL + '/company/from/' + start + '/to/' + end);
  }

  getUsersById(companyId: number) {
    return this.http.get<User[]>(BACKEND_URL + '/company/' + companyId + '/user');
  }

  getUsersAndInvitesById(companyId: number) {
    return this.http.get<User[]>(BACKEND_URL + '/company/' + companyId + '/all-user');
  }

  saveDepartmentsById(companyId: number, departments: Department[]) {
    return this.http.post(BACKEND_URL + '/company/' + companyId + '/department', departments);
  }

  saveDepartmentById(companyId: number, department: Department) {
    return this.http.post(BACKEND_URL + '/company/' + companyId + '/department', department);
  }

  updateDepartmentById(companyId: number, departmentId: number, department: Department) {
    return this.http.patch(BACKEND_URL + '/company/' + companyId + '/department/' + departmentId, department);
  }

  getDepartmentsById(companyId: number): Observable<Department[]> {
    return this.http.get<Department[]>(`${BACKEND_URL}/company/${companyId}/department`).pipe(take(1));
  }

  getById(companyId: number) {
    return this.http.get<Company>(BACKEND_URL + '/company/' + companyId);
  }

  deleteDepartmentByDepartmentId(companyId: number, departmentId: number) {
    return this.http.delete(BACKEND_URL + '/company/' + companyId + '/department/' + departmentId);
  }

  findOneDepartmentByDepartmentId(companyId: number, departmentId: number) {
    return this.http.get(BACKEND_URL + '/company/' + companyId + '/department/' + departmentId);
  }

  getCompanyDetail(companyId: number) {
    return this.http.get<Company>(BACKEND_URL + '/company/' + companyId + '/detail');
  }

  getInvitesById(companyId: number) {
    return this.http.get<Invite[]>(BACKEND_URL + '/company/' + companyId + '/invite');
  }

  getLevelsById(companyId: number) {
    return this.http.get<Level[]>(BACKEND_URL + '/company/' + companyId + '/level');
  }

  delete(companyId: number) {
    return this.http.delete(BACKEND_URL + '/company/' + companyId);
  }

  save(company: Company) {
    return this.http.post(BACKEND_URL + '/company', company);
  }

  update(companyId: number, company: Company) {
    return this.http.patch(BACKEND_URL + '/company/' + companyId, company);
  }

  getUsersDownloadByCompanyId(companyId) {
    const url = `${BACKEND_URL}/company/${companyId}/users-download`;
    return this.http.get<{ fileName: string; fileMimeType: string; fileBase64Content: string }>(url);
  }

  getStatisticsByCompanyId(companyId: number): Observable<any> {
    return this.http.get<any[]>(`${BACKEND_URL}/company/${companyId}/statistics`);
  }

  companyLogoById(companyId: number) {
    return this.http.get(BACKEND_URL + '/company/' + companyId + '/logo');
  }

  assignTestToCompanyById(companyId: number, testId: number) {
    return this.http.post(`${BACKEND_URL}/company/${companyId}/psy-test`, { psyTestId: testId });
  }

  findAllAssessmentAssignedToCompany(companyId: number): Observable<any> {
    return this.http.get<any[]>(`${BACKEND_URL}/company/${companyId}/psy-test-list-assigned-to-company`);
  }

  removeAssignedTestFromCompany(companyPsyTestId: number) {
    return this.http.delete(`${BACKEND_URL}/company/${companyPsyTestId}/psy-test-list-assigned-to-company`);
  }

  assignChartToCompanyById(companyId: number, chartId: number) {
    return this.http.post(`${BACKEND_URL}/company/${companyId}/chart`, { chartId: chartId });
  }

  findAllChartAssignedToCompany(companyId: number): Observable<any> {
    return this.http.get<any[]>(`${BACKEND_URL}/company/${companyId}/chart-list-assigned-to-company`);
  }

  removeAssignedChartFromCompany(companyChartId: number) {
    return this.http.delete(`${BACKEND_URL}/company/${companyChartId}/chart-list-assigned-to-company`);
  }
}
