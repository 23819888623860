import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// components
import { SuperscaleCreateDialogComponent } from '../superscale-create-dialog/superscale-create-dialog.component';

// services
import { SuperscaleService } from '@app/core/services/superscale.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { Superscale } from '@app/core/models/superscale.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-superscale-detail',
  templateUrl: './superscale-detail.component.html',
  styleUrls: ['./superscale-detail.component.scss'],
})
export class SuperscaleDetailComponent implements OnInit {
  superscaleId: number;
  superscale: Superscale;
  tabs: string[] = ['Details'];
  selected = new FormControl(0);

  constructor(
    private superscaleService: SuperscaleService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.superscaleId = Number(this.route.snapshot.params.id);
    this.getSuperscale();
  }

  private getSuperscale(): void {
    this.superscaleService.get(this.superscaleId).subscribe((superscale: Superscale) => (this.superscale = superscale));
  }

  onEdit() {
    this.dialog
      .open(SuperscaleCreateDialogComponent, { data: { isEdit: true, superscale: this.superscale } })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.superscaleService.update(this.superscaleId, result.data).subscribe(() => {
            this.superscale = result.data;
            this.snackBar.info('Superscale updated with success!');
          });
        }
      });
  }

  tabChange($event): void {
    this.selected.setValue($event);
  }
}
