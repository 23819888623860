import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// services
import { UserService } from '@app/core/services/user.service';

// models
import { UserLogin } from './user-login.model';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
})
export class UserLoginComponent implements OnInit, OnDestroy {
  hide = true;
  loginError: boolean;

  isAuthenticated$: Subscription;
  userNotAuthenticated: boolean;
  formChanged: boolean = false;
  @ViewChild("postForm", { static: true }) myForm: NgForm

  constructor(public userService: UserService) {
    this.loginError = this.userService.getLoginError();
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.userService.isAuthenticated$.subscribe(() => {
      this.loginError = this.userService.getLoginError();
    });

    this.myForm.form.valueChanges.subscribe(c => {
      this.formChanged = true;
    });
  }

  async onLoginRequest(form: NgForm) {
    this.userNotAuthenticated = null;

    if (form.invalid) {
      return;
    }

    const credentials: UserLogin = {
      username: form.value.username,
      password: form.value.password,
    };

    await this.userService.authenticate(credentials);

    this.isAuthenticated$ = this.userService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      // Partial fix until we discover why the subscription exception is not working anymore
      await this.executeAfterDelay(isAuthenticated);
    });

    form.resetForm();
  }

  async executeAfterDelay(isAuthenticated) {
    if (isAuthenticated) {
      this.userNotAuthenticated = false;
    }
    await new Promise((resolve) => setTimeout(resolve, 3500));
    this.userNotAuthenticated = !isAuthenticated;
    this.formChanged = false;
  }

  ngOnDestroy(): void {
    this.isAuthenticated$?.unsubscribe();
  }
}
