import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { UserInfo } from '@app/core/models/userInfo.model';
import moment from 'moment';
import { TestGroupService } from '@app/core/services/test-group.service';
import { ReportModel } from '@app/core/models/report-model.model';

@Component({
  selector: 'app-user-report-pdf-with-cover',
  templateUrl: './user-report-pdf-with-cover.component.html',
  styleUrls: ['./user-report-pdf-with-cover.component.scss'],
})
export class UserReportPdfWithCoverComponent implements OnInit, AfterViewInit {
  userInfo: UserInfo;
  reportModel: ReportModel;

  tableOfContents: { h2: Element; children: { h3 }[] }[];

  now = moment().format('MMMM YYYY');

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private testGroupService: TestGroupService,
  ) {}

  ngAfterViewInit() {
    this.tableOfContents = [];
    const h2list = Array.from(document.querySelectorAll('#table-of-contents-parent h2')).filter((h2) => {
      return h2.id && h2.id.includes('toc');
    });
    for (let h2 of h2list) {
      this.tableOfContents.push({ h2, children: [] });
    }

    const h3list = Array.from(document.querySelectorAll('#table-of-contents-parent h3')).filter((h3) => {
      return h3.id && h3.id.includes('toc');
    });
    for (let h3 of h3list) {
      const item = this.tableOfContents
        .concat()
        .reverse()
        .find(({ h2 }) => {
          return h3.compareDocumentPosition(h2) === Node.DOCUMENT_POSITION_PRECEDING;
        });
      if (item) {
        item.children.push({ h3 });
      }
    }
  }

  ngOnInit(): void {
    this.userService.get(Number(this.route.snapshot.params.id)).subscribe((userInfo) => {
      this.userInfo = userInfo;
    });

    this.testGroupService
      .getReportModelByTestGroupId(Number(this.route.snapshot.params.testGroupId))
      .subscribe((reportModel) => {
        this.reportModel = reportModel;
      });
  }
}
