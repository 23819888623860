import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { TestGroupModule } from '../test-group/test-group.module';

import { ClientsRoutingModule } from './clients-routing.module';

import { ClientModalComponent } from './client-modal/client-modal.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientDetailsComponent } from './client-details/client-details.component';

import { PhoneMaskDirective } from '@app/shared/directives/phone-mask.directive';
import { StatisticsComponent } from '../statistics/statistics.component';

@NgModule({
  declarations: [
    ClientsComponent,
    ClientModalComponent,
    ClientDetailsComponent,
    PhoneMaskDirective,
    StatisticsComponent,
  ],
  imports: [SharedModule, CommonModule, ClientsRoutingModule, FormsModule, ReactiveFormsModule, TestGroupModule],
  exports: [ClientsComponent, ClientModalComponent, ClientDetailsComponent, PhoneMaskDirective],
})
export class ClientsModule {}
