import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

// services
import { AnswerService } from '@app/core/services/answer.service';
import { QuestionService } from '@app/core/services/question.service';

// models
import { Question } from '@app/core/models/question.model';
import { IntegerAnswer } from '@app/core/models/integer-answer.model';
import { UserAnswer } from '@app/core/models/user-answer.model';

@Component({
  selector: 'app-answer-multiple-vertical-choice',
  templateUrl: './answer-multiple-vertical-choice.component.html',
  styleUrls: ['./answer-multiple-vertical-choice.component.scss'],
})
export class AnswerMultipleVerticalChoiceComponent implements OnInit {
  @Input() question: Question;
  @Input() answering: boolean;
  @Input() loadAnswer: boolean;
  @Input() psyTestId: number;
  @Input() superTestId: number;
  @Input() userInfoId: number;
  @Input() testResultId: number;
  @Input() isPreview: boolean;
  @Output() userAnswer = new EventEmitter<{ questionId: number; userAnswer: UserAnswer }>();

  multipleVertialChoiceAnswer: IntegerAnswer;
  selectedVerticalChoiceAnswer: number;
  answersRange: any[] = [];
  answersId: any[] = [];
  constructor(private questionService: QuestionService, private answerService: AnswerService) {}

  ngOnInit(): void {
    this.userInfoId = this.userInfoId ? this.userInfoId : +localStorage.getItem('userInfoId');
    if (!this.isPreview) {
      if (this.answering) {
        if (!this.multipleVertialChoiceAnswer && !this.selectedVerticalChoiceAnswer) {
          if (this.question.userAnswer && this.question.userAnswer.integerAnswer) {
            this.loadAnswerFromUserAnswer(this.question.userAnswer);
          }
        }
      } else {
        this.questionService
          .findIntegerAnswerByQuestionIdAndTestResultId(this.question.id, this.testResultId)
          .subscribe((answer) => {
            if (answer && Object.keys(answer).length > 0) {
              this.multipleVertialChoiceAnswer = answer;
              this.selectedVerticalChoiceAnswer = answer.answer;
            }
          });
      }
    }
  }

  loadAnswerFromUserAnswer(userAnswer: UserAnswer) {
    this.multipleVertialChoiceAnswer = {
      answerId: userAnswer.integerAnswer.id,
      answer: userAnswer.integerAnswer.answer,
      questionId: this.question.id,
      testResultId: this.testResultId,
    };

    this.selectedVerticalChoiceAnswer = userAnswer.integerAnswer.answer;
  }

  radioChange(event: MatRadioChange) {
    if (this.superTestId || this.psyTestId) {
      if (this.multipleVertialChoiceAnswer && this.multipleVertialChoiceAnswer.answerId) {
        this.multipleVertialChoiceAnswer.answer = event.value;
        this.answerService.updateIntegerAnswer(this.question.id, this.multipleVertialChoiceAnswer).subscribe(() => {
          this.selectedVerticalChoiceAnswer = event.value;
          const userAnswer = {
            questionId: this.question.id,
            userAnswer: {
              integerAnswer: { id: this.multipleVertialChoiceAnswer.answerId, answer: event.value },
              textAnswer: null,
              listAnswer: null,
            },
          };
          this.userAnswer.emit(userAnswer);
        });
      } else {
        this.multipleVertialChoiceAnswer = {
          questionId: this.question.id,
          userInfoId: this.userInfoId,
          answer: event.value,
          testResultId: this.testResultId,
        };

        this.answerService.saveIntegerAnswer(this.question.id, this.multipleVertialChoiceAnswer).subscribe((result) => {
          const userAnswer = {
            questionId: this.question.id,
            userAnswer: {
              integerAnswer: { id: result.id, answer: event.value },
              textAnswer: null,
              listAnswer: null,
            },
          };
          this.userAnswer.emit(userAnswer);
          this.multipleVertialChoiceAnswer.answerId = result.id;
          this.selectedVerticalChoiceAnswer = event.value;
        });
      }
    }
  }
}
