<app-loader></app-loader>

<router-outlet></router-outlet>

<footer>
  <div class="footer">
    <div class="footer-list">
      <div class="footer-logo">
        <!-- <mat-icon>psychology</mat-icon> -->
        <span>
          <a href="/">Psynet Group</a>
        </span>
      </div>
      <div class="footer-version">
        <span class="version"> Current Version 2.0 beta </span>
      </div>
      <div class="footer-copyright">
        <div>
          <span> Team </span>
        </div>
      </div>
    </div>
  </div>

  <div class="secondary-footer">
    <a href="">Terms & Conditions | Privacy</a>
  </div>
</footer>
