<div class="sub-btn" *ngIf="chartBase == 'CIRCLE_IN_CARTESIAN'">
  <button
    mat-raised-button
    [disabled]="isDisableSubscaleBtn"
    color="primary"
    class="add-button"
    (click)="onAddSubscale('subscale')"
  >
    <mat-icon>add</mat-icon>
    Add subscale
  </button>

  <button
    mat-raised-button
    color="primary"
    [disabled]="isDisableSuperscaleBtn"
    class="add-button"
    (click)="onAddSubscale('supersubscale')"
  >
    <mat-icon>add</mat-icon>
    Add Superscale
  </button>
</div>

<div class="mat-elevation-z3" *ngIf="chartBase == 'CIRCLE_IN_CARTESIAN'">
  <mat-table [dataSource]="subscalesDataSource" *ngIf="subscalesDataSource.data.length > 0">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Subscale </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="axis">
      <mat-header-cell *matHeaderCellDef> Axis </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-select [(ngModel)]="element.axis">
          <mat-option *ngFor="let axis of axes" [value]="axis">{{ axis }}</mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="weight">
      <mat-header-cell *matHeaderCellDef> Weight </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <input matInput type="number" [(ngModel)]="element.weight" />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="action-buttons">
          <button mat-button (click)="onSave(element)">Save</button>
          <button mat-button class="btn-delete" (click)="onDelete(element.id)">Delete</button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedSubscalesColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedSubscalesColumns"></mat-row>
  </mat-table>
</div>

<div class="mat-elevation-z3" *ngIf="chartBase == 'HEXAGON' || chartBase == 'FOUR_PLUS_ONE_CARTESIAN'">
  <mat-table [dataSource]="hexagonOrFourPlusSubScaleDataSource">
    <ng-container matColumnDef="sector">
      <mat-header-cell *matHeaderCellDef>
        {{ chartBase == 'FOUR_PLUS_ONE_CARTESIAN' ? 'Sextant' : 'Sector' }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.sector.label }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subscale">
      <mat-header-cell *matHeaderCellDef> Subscale </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <mat-select
          [(value)]="element.subscale"
          (selectionChange)="selectSubscaleOrSuperSubScale('subscale', element, i)"
        >
          <mat-option [value]="item.id" *ngFor="let item of subscaleList">
            {{ item.title }}
          </mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="superscale">
      <mat-header-cell *matHeaderCellDef> Superscale </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <mat-select
          [(value)]="element.superscale"
          (selectionChange)="selectSubscaleOrSuperSubScale('superSubscale', element, i)"
        >
          <mat-option [value]="item.id" *ngFor="let item of superSubscaleList">
            {{ item.title }}
          </mat-option>
        </mat-select></mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <div class="action-buttons">
          <button mat-button [disabled]="!element.subscale && !element.superscale" (click)="saveChartSubscale(element)">
            Save
          </button>
          <button mat-button class="btn-delete" (click)="resetSubscaleRow(element, i)">Cancel</button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedSubscalesColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedSubscalesColumns"></mat-row>
  </mat-table>
</div>
