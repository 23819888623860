import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EVENT_CANCEL } from '@app/core/constants';
import { Company } from '@app/core/models/company.model';
import { User } from '@app/core/models/user.model';
import { CompanyService } from '@app/core/services/company.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-psynet-add-new-user',
  templateUrl: './psynet-add-new-user.component.html',
  styleUrls: ['./psynet-add-new-user.component.scss'],
})
export class PsynetAddNewUserComponent implements OnInit {
  title: string = '';
  otherCompanyUserForm: FormGroup;
  filteredCompanies: Observable<Company[]>;
  filteredUsers: Observable<User[]>;
  companies: Company[] = [];
  users: User[] = [];

  constructor(
    public dialogRef: MatDialogRef<PsynetAddNewUserComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { items: any[]; title: string },
    private companyService: CompanyService,
  ) {
    this.otherCompanyUserForm = new FormGroup({
      company: new FormControl('', [Validators.required]),
      user: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });

    this.title = this.data.title;
    this.companies = this.data.items;

    this.filteredCompanies = this.otherCompanyUserForm.controls['company'].valueChanges.pipe(
      startWith(''),
      map((company) => (company ? this._filter(company) : this.data.items.slice())),
    );
  }

  private _filter(value: string): Company[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.companies;
    }
    return this.companies.filter((company) => company.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
  }

  private _filterUser(value: string): User[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.users;
    }
    return this.users.filter(
      (user) =>
        user.firstName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
        [user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase()].indexOf(filterValue.toLowerCase()) > -1,
    );
  }

  displayFn(company: Company): string {
    return company ? company.name : '';
  }

  displayFnUser(user: User): string {
    return user ? user.firstName + ' ' + user.lastName : '';
  }

  loadUsers(company: Company) {
    this.companyService.getUsersById(company.id).subscribe((users) => {
      this.users = [
        ...new Map(
          users.filter((user) => user.firstName && user.lastName).map((user) => [user.firstName + user.lastName, user]),
        ).values(),
      ];

      this.filteredUsers = this.otherCompanyUserForm.controls['user'].valueChanges.pipe(
        startWith(''),
        map((user) => (user ? this._filterUser(user) : this.users.slice())),
      );
    });
  }

  setCompany(company: Company) {
    this.loadUsers(company);
  }
  onSave() {
    if (this.otherCompanyUserForm.invalid) return false;
    this.dialogRef.close({ event: 'save', data: this.otherCompanyUserForm.value });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
