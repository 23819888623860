import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { QuestionGroup } from '../models/question-group.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class QuestionGroupService {
  constructor(private http: HttpClient) {}

  saveQuestionGroupForRoleMandate(roleMandateId: number, questionGroup: QuestionGroup) {
    return this.http.post<{ id: number }>(BACKEND_URL + '/question/group/role-mandate/' + roleMandateId, questionGroup);
  }

  saveQuestionGroupForConnectionIndicator(connectionIndicatorId: number, questionGroup: QuestionGroup) {
    return this.http.post<{ id: number }>(
      BACKEND_URL + '/question/group/connection-indicator/' + connectionIndicatorId,
      questionGroup,
    );
  }

  getAllQuestionGroupForRoleMandate(roleMandateId: number) {
    return this.http
      .get<QuestionGroup[]>(BACKEND_URL + '/question/group/role-mandate/' + roleMandateId)
      .pipe(catchError(() => of(undefined)));
  }

  getAllQuestionGroupForPsyTest(psyTestId: number) {
    return this.http.get<QuestionGroup[]>(BACKEND_URL + '/question/group/psy-test/' + psyTestId);
  }

  getAllQuestionGroupForSuperTest(superTestId: number) {
    return this.http.get<QuestionGroup[]>(BACKEND_URL + '/question/group/super-test/' + superTestId);
  }

  getAllQuestionGroupForConnectionIndicator(connectionIndicatorId: number) {
    return this.http.get<QuestionGroup[]>(
      BACKEND_URL + '/question/group/connection-indicator/' + connectionIndicatorId,
    );
  }

  getAllBySubscaleId(subscaleId: number): Observable<any> {
    return this.http.get<QuestionGroup[]>(`${BACKEND_URL}/question/group/subscale/${subscaleId}`).pipe(take(1));
  }

  delete(questionGroupId: number) {
    return this.http.delete(BACKEND_URL + '/question/group/' + questionGroupId);
  }

  update(questionGroup: QuestionGroup) {
    return this.http.put(BACKEND_URL + '/question/group/' + questionGroup.id, questionGroup);
  }

  getAll(): Observable<QuestionGroup[]> {
    return this.http.get<QuestionGroup[]>(`${BACKEND_URL}/question/group`).pipe(take(1));
  }

  getById(id: number): Observable<QuestionGroup> {
    return this.http.get<QuestionGroup>(`${BACKEND_URL}/question/group/${id}`).pipe(take(1));
  }

  create(questionGroup: QuestionGroup): Observable<any> {
    return this.http.post(`${BACKEND_URL}/question/group`, questionGroup).pipe(take(1));
  }
}
