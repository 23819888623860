import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { SuperscaleSubscale } from '../models/superscale-subscale.model';
import { environment } from '../../../environments/environment';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SuperscaleSubscaleService {
  constructor(private http: HttpClient) {}

  getAllBySuperscaleId(superscaleId: number): Observable<SuperscaleSubscale[]> {
    return this.http
      .get<SuperscaleSubscale[]>(`${BACKEND_URL}/superscale-subscale/superscale/${superscaleId}`)
      .pipe(take(1));
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${BACKEND_URL}/superscale-subscale/${id}`).pipe(take(1));
  }

  create(superscaleSubscale: SuperscaleSubscale): Observable<any> {
    return this.http.post<any>(`${BACKEND_URL}/superscale-subscale`, superscaleSubscale).pipe(take(1));
  }
}
