/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Subscale } from '../models/Subscale';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class SubscaleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all subscales by test group id and psy test id
     * Retrieve all subscales by test group id and psy test id
     * @param id Id of the test group
     * @param psyTestId Id of the psy test
     * @returns Subscale A list of subscales
     * @throws ApiError
     */
    public getTestGroupPsyTestSubscale(
        id: number,
        psyTestId: number,
    ): Observable<Subscale> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/psy-test/{psyTestId}/subscale',
            path: {
                'id': id,
                'psyTestId': psyTestId,
            },
            errors: {
                404: `a list of subscales not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all report Subscale
     * Retrieve all subscale
     * @param reportId Id of the report
     * @returns any One report subscales
     * @throws ApiError
     */
    public getReportAllSubscale(
        reportId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/{reportId}/all-subscale',
            path: {
                'reportId': reportId,
            },
            errors: {
                404: `Subscales not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all subscale by id
     * Retrieve all subscale by id
     * @param id Id of the subscale
     * @returns Subscale All subscale
     * @throws ApiError
     */
    public getTestAllSubscale(
        id: number,
    ): Observable<Array<Subscale>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/{id}/all-subscale',
            path: {
                'id': id,
            },
            errors: {
                404: `Subscale not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Sort subscales
     * Sort subscales
     * @param testId Id of the test
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public postTestSortSubscales(
        testId: number,
        requestBody?: Array<number>,
    ): Observable<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/{testId}/sort-subscales',
            path: {
                'testId': testId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param testId
     * @param testGroupId
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public postTestTestGroupSortSubscales(
        testId: number,
        testGroupId: number,
        requestBody?: Array<number>,
    ): Observable<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/{testId}/test-group/{testGroupId}/sort-subscales',
            path: {
                'testId': testId,
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
