<h1>{{ isEdit ? 'Edit Invite' : 'View Invite' }}</h1>
<form [formGroup]="userInviteForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>First Name</mat-label>
    <input matInput type="text" formControlName="firstName" readonly />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Last Name</mat-label>
    <input matInput type="text" formControlName="lastName" readonly />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input
      matInput
      type="text"
      formControlName="email"
      (ngModelChange)="this.emailFieldUpdate.next($event)"
      [readonly]="!isEdit"
    />
    <mat-error
      *ngIf="
        userInviteForm.get('email').errors &&
        !userInviteForm.get('email').hasError('invalidEmailAddress') &&
        !userInviteForm.get('email').hasError('isEmailExist')
      "
      >Email address is required</mat-error
    >
    <mat-error
      *ngIf="
        userInviteForm.get('email').hasError('invalidEmailAddress') &&
        !userInviteForm.get('email').hasError('isEmailExist')
      "
    >
      This email is an invalid address.
    </mat-error>
    <mat-error
      *ngIf="
        !userInviteForm.get('email').hasError('invalidEmailAddress') &&
        userInviteForm.get('email').hasError('isEmailExist')
      "
      >This email has already been registered or invited.
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>TG/RN/CI</mat-label>
    <input matInput type="text" formControlName="title" readonly />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Company</mat-label>
    <input matInput type="text" formControlName="company" readonly />
  </mat-form-field>
  <mat-form-field *ngIf="!isEdit">
    <mat-label>Link</mat-label>
    <input matInput type="text" formControlName="url" readonly />
  </mat-form-field>
  <div class="bottom-button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button *ngIf="isEdit" mat-button class="btn-success btn-300" type="submit" [disabled]="!userInviteForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
    <button *ngIf="!isEdit" mat-button class="btn-success btn-300" type="button" (click)="onCopy()">
      <mat-icon>file_copy</mat-icon>Copy Link
    </button>
  </div>
</form>
