<base target="_blank" />
<div class="container">
  <div class="row" *ngIf="this.config?.currentPage === 1 || !this.config?.currentPage">
    <button mat-raised-button class="btn-success btn-center btn-300" (click)="onBack()">
      <mat-icon>arrow_back</mat-icon> Back to Role Title
    </button>
  </div>

  <div *ngIf="allObj">
    <div *ngFor="let obj of allObj | paginate: config">
      <div *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'CARDSORT'">
        <app-answer-cardsort
          [question]="obj"
          [roleMandateId]="roleMandateId"
          [answering]="true"
          [userInfoId]="userInfoId"
          (cardAnswerSaved)="onChangeCardSortAnswerSaved($event)"
        >
        </app-answer-cardsort>
      </div>
      <div *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'USER_CONNECTION'">
        <app-answer-user-connection
          [question]="obj"
          [companyId]="this.roleMandate.companyId"
          [roleMandateId]="roleMandateId"
          [userInfoId]="userInfoId"
          [answering]="true"
        ></app-answer-user-connection>
      </div>
      <div
        *ngIf="
          testObjectService.isQuestion(obj) && (obj.questionType === 'TEXT_AREA' || obj.questionType === 'RICH_TEXT')
        "
      >
        <app-answer-text-area
          [question]="obj"
          [companyId]="this.roleMandate.companyId"
          [roleMandateId]="roleMandateId"
          [userInfoId]="userInfoId"
          [answering]="true"
        ></app-answer-text-area>
      </div>
      <div *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'LIST_TEXT'">
        <app-answer-list-text
          [question]="obj"
          [companyId]="this.roleMandate.companyId"
          [roleMandateId]="roleMandateId"
          [userInfoId]="userInfoId"
          [currentPage]="this.config?.currentPage"
          (relatedAnswersWithAnsweringCardsort)="setRelatedAnswerWithAnsweringCardsort($event)"
          [answering]="true"
          [lastQuestionNumber]="lastQuestionNumber"
          (answerSaved)="onChangeListTextAnswerSaved($event)"
        >
        </app-answer-list-text>
      </div>

      <div *ngIf="testObjectService.isDecisionTable(obj)">
        <app-answer-decision-table
          [companyId]="this.roleMandate.companyId"
          [roleMandateId]="roleMandateId"
          [userInfoId]="userInfoId"
          [decisionTable]="obj"
          [answering]="true"
        >
        </app-answer-decision-table>
      </div>

      <div *ngIf="testObjectService.isQuestionsGroup(obj)" class="question-group">
        <h2>{{ obj.title }}</h2>
        <p *ngIf="obj.instructions" [innerHTML]="obj.instructions"></p>
        <div *ngFor="let question of obj.questions" class="question">
          <div *ngIf="question.questionType === 'CARDSORT'">
            <app-answer-cardsort
              [question]="question"
              [roleMandateId]="roleMandateId"
              [answering]="true"
              [userInfoId]="userInfoId"
              (cardAnswerSaved)="onChangeCardSortAnswerSaved($event)"
            >
            </app-answer-cardsort>
          </div>
          <div *ngIf="question.questionType === 'USER_CONNECTION'">
            <app-answer-user-connection
              [question]="question"
              [companyId]="this.roleMandate.companyId"
              [roleMandateId]="roleMandateId"
              [userInfoId]="userInfoId"
              [answering]="true"
            ></app-answer-user-connection>
          </div>
          <div *ngIf="question.questionType === 'TEXT_AREA' || question.questionType === 'RICH_TEXT'">
            <app-answer-text-area
              [question]="question"
              [companyId]="this.roleMandate.companyId"
              [roleMandateId]="roleMandateId"
              [userInfoId]="userInfoId"
              [answering]="true"
            ></app-answer-text-area>
          </div>

          <div *ngIf="question.questionType === 'LIST_TEXT'">
            <app-answer-list-text
              [question]="question"
              [companyId]="this.roleMandate.companyId"
              [roleMandateId]="roleMandateId"
              [userInfoId]="userInfoId"
              [answering]="true"
              (answerSaved)="onChangeListTextAnswerSaved($event)"
              [currentPage]="this.config?.currentPage"
              [lastQuestionNumber]="lastQuestionNumber"
              (relatedAnswersWithAnsweringCardsort)="setRelatedAnswerWithAnsweringCardsort($event)"
            ></app-answer-list-text>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <pagination-controls responsive="true" class="pagination" (pageChange)="pageChanged($event)">
      </pagination-controls>
    </div>

    <div class="row" *ngIf="this.config.currentPage === this.config.totalItems">
      <button mat-raised-button class="btn-success btn-center btn-300" (click)="onReview()">Review</button>
    </div>
  </div>
</div>
