import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { SuperscaleSubscaleCreateDialogComponent } from '../superscale-subscale-create-dialog/superscale-subscale-create-dialog.component';

// services
import { SuperscaleSubscaleService } from '@app/core/services/superscale-subscale.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { SuperscaleSubscale } from '@app/core/models/superscale-subscale.model';
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-superscale-subscale-list',
  templateUrl: './superscale-subscale-list.component.html',
  styleUrls: ['./superscale-subscale-list.component.scss'],
})
export class SuperscaleSubscaleListComponent implements OnInit {
  @Input() superscaleId: number;
  superscaleSubscales: PsbItem[];

  constructor(
    private superscaleSubscaleService: SuperscaleSubscaleService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.loadAllSuperscaleSubscaleBySuperscaleId();
  }

  private loadAllSuperscaleSubscaleBySuperscaleId() {
    this.superscaleSubscales = [];
    this.superscaleSubscaleService
      .getAllBySuperscaleId(this.superscaleId)
      .subscribe((superscaleSubscales: SuperscaleSubscale[]) => {
        superscaleSubscales.map((superscaleSubscale) => {
          const item: PsbItem = {
            id: superscaleSubscale.id,
            title: superscaleSubscale.subscale.title,
            description: superscaleSubscale.subscale.description,
            extraColumns: [
              {
                key: 'Weight',
                value: superscaleSubscale.weight,
              },
            ],
          };
          this.superscaleSubscales = [...this.superscaleSubscales, item];
        });
      });
  }

  onCreate() {
    this.dialog
      .open(SuperscaleSubscaleCreateDialogComponent, {
        data: { isEdit: false, superscaleSubscales: this.superscaleSubscales },
      })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          this.superscaleSubscaleService
            .create({
              superscaleId: this.superscaleId,
              subscaleId: result.data.subscaleId,
              weight: result.data.weight,
            })
            .subscribe(() => {
              this.loadAllSuperscaleSubscaleBySuperscaleId();
              this.snackBar.info('Superscale Subscale created with success!');
            });
        }
      });
  }

  onDelete(superscaleSubscaleId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.superscaleSubscaleService.delete(superscaleSubscaleId).subscribe(() => {
          this.superscaleSubscales = this.superscaleSubscales.filter(
            (superscaleSubscale) => superscaleSubscale.id !== superscaleSubscaleId,
          );
          this.snackBar.info('Superscale Subscale deleted with success!');
        });
      });
  }
}
