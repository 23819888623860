<div class="multiple-vertical-choice" [id]="question.id">
  <h3 [innerHTML]="question.instructions"></h3>
  <label>
    <span>{{ question.questionNumber }}) </span>
    <img *ngIf="question.image" [src]="question.image" style="margin: 5px" /> <br *ngIf="question.image" />
    <app-question-text [text]="question.text"></app-question-text>
  </label>
  <br />
  <mat-radio-group class="radio-group" (change)="radioChange($event)" *ngFor="let answer of question.answers">
    <mat-radio-button
      class="radio-button"
      color="primary"
      [checked]="selectedVerticalChoiceAnswer == answer.id ? true : false"
      [value]="answer.id"
      [disabled]="!answering"
    >
      <div *ngIf="answer.text">{{ answer.text }}</div>
      <img *ngIf="!answer.text && answer.image" [src]="answer.image" />
      <span *ngIf="!answering"> ({{ answer.weight }}) </span>
    </mat-radio-button>
  </mat-radio-group>
</div>
