export class Node implements d3.SimulationNodeDatum {
  index?: number;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  fx?: number | null;
  fy?: number | null;

  id: string;
  userInfoId: number;
  eigenvectorCentrality: number;
  neighborhoodCentrality: number;
  centrality: string;
  linkCount = 0;

  constructor(id, userInfoId, eigenvectorCentrality, neighborhoodCentrality, centrality, linkCount) {
    this.id = id;
    this.userInfoId = userInfoId;
    this.eigenvectorCentrality = eigenvectorCentrality;
    this.neighborhoodCentrality = neighborhoodCentrality;
    this.centrality = centrality;
    this.linkCount = linkCount;
  }

  normal = () => {
    return Math.sqrt(this.linkCount / 100);
  };

  get r() {
    switch (this.centrality) {
      case 'global':
        return isNaN(50 * ((this.eigenvectorCentrality + this.neighborhoodCentrality) / 2) + 5)
          ? null
          : 50 * ((this.eigenvectorCentrality + this.neighborhoodCentrality) / 2) + 5;
      case 'neighborhoodCentrality':
        return isNaN(50 * this.neighborhoodCentrality + 5) ? null : 50 * this.neighborhoodCentrality + 5;
      case 'eigenvectorCentrality':
        return isNaN(50 * this.eigenvectorCentrality + 5) ? null : 50 * this.eigenvectorCentrality + 5;
      default:
        return 0;
    }
  }

  get fontSize() {
    return 30 * this.normal() + 10 + 'px';
  }

  get color() {
    const index = Math.floor(6 * this.normal());
    return 'rgb(241, 89, 32)';
  }
}
