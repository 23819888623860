import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { AreaFormDialogComponent } from './../area-form-dialog/area-form-dialog.component';

// services
import { AreaIndexService } from '@app/core/services/area-index.service';

// models
import { AreaIndex } from '@app/core/models/area-index.model';
import { Area } from '@app/core/models/area.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-area-mapping-areas',
  templateUrl: './area-mapping-areas.component.html',
  styleUrls: ['./area-mapping-areas.component.scss'],
})
export class AreaMappingAreasComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  @Input() areaIndexId: number;
  @Input() areaIndexBase: string;
  areaIndex: AreaIndex;

  areasDataSource = new MatTableDataSource<any>();
  displayedAreaColumns = ['num', 'he', 'she', 'they', 'actions'];
  selectedAreaNumber: any;
  constructor(private areaIndexService: AreaIndexService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.areasDataSource.sort = this.sort;
    this.areaIndexService.getById(this.areaIndexId).subscribe((areaIndex) => {
      this.areaIndex = areaIndex;
      this.areasDataSource.data = this.sortAreas(areaIndex.areas);
    });
  }

  sortAreas(areas: Area[]) {
    return areas.sort((a, b) => a.num - b.num);
  }

  onAddArea() {
    this.dialog
      .open(AreaFormDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          const { num, he, she, they } = result.area;
          this.areaIndexService.addArea(this.areaIndexId, { num, he, she, they }).subscribe((newArea) => {
            this.areasDataSource.data = this.sortAreas([...this.areasDataSource.data, newArea]);
          });
        }
      });
  }

  onRemove(areaId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.areaIndexService.removeArea(this.areaIndexId, areaId).subscribe(() => {
          this.areasDataSource.data = this.areasDataSource.data.filter((area) => area.id !== areaId);
        });
      });
  }

  onEdit(area: Area) {
    const data = { values: area };
    this.dialog
      .open(AreaFormDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          this.areaIndexService.updateArea(this.areaIndexId, area.id, result.area).subscribe((newArea) => {
            this.areasDataSource.data = this.areasDataSource.data.map((oldArea) =>
              oldArea.id === newArea.id ? newArea : oldArea,
            );
          });
        }
      });
  }

  onAreaClick(areaNumber: any) {
    this.selectedAreaNumber = areaNumber;
  }
}
