/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { SystemSettings } from '../models/SystemSettings';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class SystemSettingsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Update a system settings
     * Update a system settings
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchSystemSettings(
        id: number,
        requestBody?: {
            /**
             * status of the system settings
             */
            maintenanceEnabled?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/system-settings/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `SystemSettings not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve one System Setting
     * @returns SystemSettings One System Setting
     * @throws ApiError
     */
    public getSystemSettings(): Observable<Array<SystemSettings>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/system-settings',
            errors: {
                500: `Internal Error`,
            },
        });
    }

}
