import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { D3Service } from '@app/core/services/d3.service';

@Directive({
  selector: 'g[appZoomable], appZoomable',
})
export class ZoomableDirective implements OnInit {
  @Input() appZoomable: ElementRef;

  constructor(private d3Service: D3Service, private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.d3Service.applyZoomableBehaviour(this.appZoomable, this.elementRef.nativeElement);
  }
}
