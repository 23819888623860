<h1 mat-dialog-title>Edit chart</h1>

<form id="addChartForm" [formGroup]="form" mat-dialog-content (submit)="onFormSubmit()">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" required />
    <mat-error *ngIf="form.get('name').invalid">Please enter a valid name.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Header</mat-label>
    <input matInput formControlName="header" required />
    <mat-error *ngIf="form.get('header').invalid">Please enter a valid header.</mat-error>
  </mat-form-field>

  <mat-list>
    <mat-list-item>
      <mat-checkbox formControlName="hasSelfEvaluationKey">Show Self Evaluation Key</mat-checkbox>
    </mat-list-item>
    <mat-list-item>
      <mat-checkbox formControlName="hasLogo">Show Logo</mat-checkbox>
    </mat-list-item>
    <mat-list-item>
      <mat-checkbox formControlName="isTable">Show Table</mat-checkbox>
    </mat-list-item>
  </mat-list>
</form>

<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>
    <mat-icon>block</mat-icon>
    Cancel
  </button>
  <button mat-flat-button color="primary" [disabled]="form.invalid" type="submit" form="addChartForm">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</div>
