/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { TestGroupChartComparison } from '../models/TestGroupChartComparison';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class TestGroupChartComparisonService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a list with TestGroupChartComparison by testGroupId and chartId
     * Returns a list with TestGroupChartComparison by the given testGroupId and chartId
     * @param testGroupId Id of the TestGroup
     * @param chartId Id of the Chart
     * @returns TestGroupChartComparison A list with TestGroupChartComparison
     * @throws ApiError
     */
    public getReportTestGroupChartComparisonTestGroupChart(
        testGroupId: number,
        chartId: number,
    ): Observable<TestGroupChartComparison> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/test-group-chart-comparison/test-group/{testGroupId}/chart/{chartId}',
            path: {
                'testGroupId': testGroupId,
                'chartId': chartId,
            },
            errors: {
                404: `TestGroupChartComparison not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Updates a TestGroupChartComparison
     * Updates a TestGroupChartComparison
     * @param testGroupId Id of the TestGroup
     * @param chartId Id of the Chart
     * @param hided Hide or not
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchReportTestGroupComparison(
        testGroupId: number,
        chartId: number,
        hided: boolean,
        requestBody?: {
            /**
             * Id of the TestGroup
             */
            testGroupId: number;
            /**
             * Id of the Chart
             */
            chartId: number;
            /**
             * Id of the UserInfo
             */
            userInfoId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/test-group-comparison',
            path: {
                'testGroupId': testGroupId,
                'chartId': chartId,
                'hided': hided,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `TestGroupChartComparison not found`,
                500: `Internal error`,
            },
        });
    }

}
