import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionArea } from '@app/core/models/decision-area.model';

@Component({
  selector: 'app-edit-decision-area',
  templateUrl: './edit-decision-area.component.html',
  styleUrls: ['./edit-decision-area.component.scss'],
})
export class EditDecisionAreaComponent implements OnInit {
  @Input() decisionArea: DecisionArea;
  @Output() edit = new EventEmitter<DecisionArea>();

  editDecisionAreaForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });
  originalDecisionArea: DecisionArea;

  constructor(private decisionTableService: DecisionTableService) {}

  ngOnInit(): void {
    this.originalDecisionArea = JSON.parse(JSON.stringify(this.decisionArea));
    this.editDecisionAreaForm.get('name').setValue(this.decisionArea.name);
  }

  editDecisionArea(formDirective: FormGroupDirective) {
    if (this.editDecisionAreaForm.valid) {
      const editedDecisionArea: DecisionArea = {
        id: this.decisionArea.id,
        name: this.editDecisionAreaForm.get('name').value,
        companyId: this.decisionArea.companyId,
      };

      this.decisionTableService.updateDecisionArea(editedDecisionArea).subscribe(
        () => {
          this.decisionArea = editedDecisionArea;
          this.edit.emit(this.decisionArea);
        },
        () => (this.decisionArea = this.originalDecisionArea),
      );
    }
    return;
  }

  onCancel() {
    this.edit.emit(this.decisionArea);
  }
}
