import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-role-mandate',
  templateUrl: './role-mandate.component.html',
  styleUrls: ['./role-mandate.component.scss'],
})
export class RoleMandateComponent implements OnInit {
  roleMandates: PsbItem[] = [];
  isLoading = false;
  isDialogOpen = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private roleMandateService: RoleMandateService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.roleMandateService.get().subscribe({
      next: (result) => {
        this.roleMandates = result.map((roleMandate) => {
          let progressDescription;

          if (
            roleMandate.userStarted + roleMandate.userCompleted > 0 &&
            roleMandate.userStarted + roleMandate.userCompleted <= 1
          ) {
            progressDescription = roleMandate.userStarted + roleMandate.userCompleted + ' user started';
          } else if (roleMandate.userStarted + roleMandate.userCompleted > 1) {
            progressDescription = roleMandate.userStarted + roleMandate.userCompleted + ' users started';
          } else if (roleMandate.usersInvited <= 0) {
            progressDescription = 'No users invited';
          } else if (roleMandate.usersInvited > 0 && roleMandate.userCompleted <= 0 && roleMandate.userStarted <= 0) {
            progressDescription = 'Waiting ' + roleMandate.usersInvited + ' to start';
          } else if (roleMandate.userCompleted === roleMandate.usersInvited && roleMandate.usersInvited > 0) {
            progressDescription = 'All users completed';
          }

          return {
            id: roleMandate.id,
            title: roleMandate.title,
            description: roleMandate.companyName,
            progress: Number(
              roleMandate.usersInvited > 0
                ? (((roleMandate.userStarted + roleMandate.userCompleted) / roleMandate.usersInvited) * 100).toFixed(0)
                : 0,
            ),
            progressDescription,
          };
        });
        this.isLoading = false;
      },
    });
  }

  onNewTitle() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  onView(roleMandateId: number) {
    this.router.navigate([`/user/role-mandate/${roleMandateId}/instuctions`]);
  }

  onEdit(roleMandateId: number) {
    this.router.navigate([`/role-mandate/${roleMandateId}/edit`]);
  }

  onDashboard(roleMandateId: number) {
    this.router.navigate([`/role-mandate/${roleMandateId}/dashboard`]);
  }

  onDelete(roleMandateId: number) {
    this.isDialogOpen = true;
    const deleteDialog = this.dialog.open(DeleteDialogComponent);
    deleteDialog.componentInstance.message = `Deleting this Role Navigator all the questions,
      decision tables and other components connected will be deleted.
      Are you sure you want to delete?`;
    deleteDialog.afterClosed().subscribe((result) => {
      this.isDialogOpen = false;
      if (result.event !== EVENT_CANCEL) {
        this.roleMandateService.delete(roleMandateId).subscribe({
          next: () => {
            this.roleMandates = this.roleMandates.filter((item) => item.id !== roleMandateId);
          },
        });
      }
    });
  }
}
