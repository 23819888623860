/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Invite } from '../models/Invite';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class InviteService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all users and invites by id
     * Retrieve all users and invites by id
     * @param id Id of the Company
     * @returns Invite A list of invites
     * @throws ApiError
     */
    public getCompanyAllUser(
        id: number,
    ): Observable<Invite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/all-user',
            path: {
                'id': id,
            },
            errors: {
                404: `Invites not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a Invite
     * Create a Invite
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postCompanyInvite(
        id: number,
        requestBody?: {
            /**
             * Company id of the invite
             */
            companyId?: number;
            /**
             * test group id of the invite
             */
            psyTestGroupId?: number;
            /**
             * active of the invite
             */
            active?: boolean;
            /**
             * token of the invite
             */
            token?: string;
            /**
             * Email of the invite
             */
            email?: string;
            /**
             * First Name of the invite
             */
            firstName?: string;
            /**
             * Last Name of the invite
             */
            lastName?: string;
            /**
             * Invited of the invite
             */
            invited?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/{id}/invite',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Find invitation by Test Group Id
     * @param testGroupId Id of the Test Group
     * @param emailTypeId Id of the Test EmailType
     * @returns Invite A list of EmailLog
     * @throws ApiError
     */
    public getSendEmailTestGroupInviteMessage(
        testGroupId: number,
        emailTypeId: number,
    ): Observable<Array<Invite>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/send-email/test-group/{testGroupId}/invite-message/{emailTypeId}',
            path: {
                'testGroupId': testGroupId,
                'emailTypeId': emailTypeId,
            },
            errors: {
                500: `Internal Error`,
            },
        });
    }

}
