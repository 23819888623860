<div class="container">
  <div class="page-list-header">
    <h1>Superscale Subscales</h1>
    <button mat-raised-button class="new-button pull-right" color="primary" (click)="onCreate()">
      <mat-icon aria-hidden="false">add</mat-icon>
      Create a Superscale Subscale
    </button>
  </div>

  <app-psb-list
    *ngIf="superscaleSubscales.length"
    [items]="superscaleSubscales"
    [itemsPerPage]="10"
    (delete)="onDelete($event)"
  >
  </app-psb-list>
</div>
