import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// services
import { CompanyService } from '@app/core/services/company.service';
import { UserService } from '@app/core/services/user.service';

// models
import { Level } from '@app/core/models/level.model';
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss'],
})
export class LevelComponent implements OnInit {
  @Input() answering: boolean;
  @Input() userInfo: UserInfo;

  levels: Level[] = [];
  filteredLevels: Observable<Level[]>;
  levelControl = new FormControl('', [Validators.required]);

  constructor(private userService: UserService, private companyService: CompanyService) {}

  ngOnInit(): void {
    this.companyService.getLevelsById(this.userInfo.companyId).subscribe({
      next: (result) => {
        this.levels = [
          ...new Map(result.filter((level) => level.name !== 'undefined').map((level) => [level.name, level])).values(),
        ];
        if (this.userInfo.levelId) {
          const dpt = result.filter((level) => level.id === this.userInfo.levelId)[0];
          this.levelControl.setValue(this.answering ? dpt : dpt.name);
        }

        this.filteredLevels = this.levelControl.valueChanges.pipe(
          startWith(''),
          map((level) => (level ? this._filterLevel(level) : this.levels.slice())),
        );
      },
    });
  }

  displayLevel(level: Level): string {
    return level ? level.name : '';
  }

  setLevel(event) {
    this.userService.updateLevel(this.userInfo.id, event.option.value).subscribe({
      next: (result) => {
        this.userInfo.levelId = event.option.value.id;
      },
    });
  }

  private _filterLevel(value: string): Level[] {
    const filterValue = value;
    return this.levels.filter((level) => level.name.indexOf(filterValue) === 0);
  }
}
