/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyTestResult } from '../models/PsyTestResult';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestResultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve the test group test result by id
     * Retrieve the test group test result by id
     * @param id Id of the test group
     * @returns PsyTestResult A list of test group test result by test group id
     * @throws ApiError
     */
    public getTestGroupTestResult(
        id: number,
    ): Observable<PsyTestResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/test-result',
            path: {
                'id': id,
            },
            errors: {
                404: `a test group test result not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Export the test group test result by id
     * Export the test group test result by id
     * @param id Id of the test group
     * @returns PsyTestResult Export a list of test group test result
     * @throws ApiError
     */
    public getTestGroupExportTestResult(
        id: number,
    ): Observable<PsyTestResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/export-test-result',
            path: {
                'id': id,
            },
            errors: {
                404: `export test result not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Search the test group result by id
     * Search the test group result by id
     * @param id Id of the test group
     * @returns PsyTestResult Search a list of test group result
     * @throws ApiError
     */
    public getTestGroupSearchTestResult(
        id: number,
    ): Observable<PsyTestResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/search-test-result',
            path: {
                'id': id,
            },
            errors: {
                404: `search test result not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * List the test group result by id
     * List the transferred test groups by id
     * @param id Id of the test group
     * @returns PsyTestResult Search a list of transferredTestGroups
     * @throws ApiError
     */
    public getTestGroupSearchTestResult1(
        id: number,
    ): Observable<PsyTestResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/search-test-result/{companyId}',
            path: {
                'id': id,
            },
            errors: {
                404: `search test result not found`,
                500: `Internal error`,
            },
        });
    }

}
