import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';

// models
import { RoleMandate } from '@app/core/models/role-mandate.model';

@Component({
  selector: 'app-role-mandate-search',
  templateUrl: './role-mandate-search.component.html',
  styleUrls: ['./role-mandate-search.component.scss'],
})
export class RoleMandateSearchComponent implements OnInit {
  private roleMandateId: number;
  roleMandate: RoleMandate;
  search: { test: RoleMandate; type: 'PRN' };

  constructor(private rMService: RoleMandateService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.roleMandateId = Number(this.route.snapshot.params.id);
    this.rMService.getById(this.roleMandateId).subscribe((roleMandate) => {
      this.roleMandate = roleMandate;
      this.search = { test: roleMandate, type: 'PRN' };
    });
  }
}
