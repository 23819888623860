import { Injectable } from '@angular/core';

import { RoleMandateService } from './role-mandate.service';
import { PsbPage } from '../models/psb-page.model';
import { RoleMandate } from '../models/role-mandate.model';

@Injectable({ providedIn: 'root' })
export class PageService {
  connectionIndicatorPages: PsbPage[] = [];
  roleMandatePages: PsbPage[] = [];
  private roleMandate: RoleMandate;

  constructor(private roleMandateService: RoleMandateService) {}

  setConnectionIndicatorPages(connectionIndicatorPages: PsbPage[]) {
    this.connectionIndicatorPages = connectionIndicatorPages;
  }

  getConnectionIndicatorPages(): PsbPage[] {
    return this.connectionIndicatorPages;
  }

  getRoleMandatePages(roleMandateId: number): PsbPage[] {
    // reset singleton if id is different
    if (this.roleMandate?.id !== roleMandateId) {
      this.roleMandate = null;
    }

    // singleton for role mandate
    if (!this.roleMandate && roleMandateId !== null) {
      this.roleMandateService.getById(roleMandateId).subscribe((result) => {
        this.roleMandate = result;
      });
    }

    let pages = [
      {
        title: 'Role Navigators',
        path: '/role-mandate',
      },
    ];

    if (!roleMandateId) {
      pages = [
        ...pages,
        {
          title: `<b>New</b>`,
          path: '/role-mandate/new',
        },
      ];
    } else {
      pages = [
        ...pages,
        {
          title: `<b>${this.roleMandate ? this.roleMandate.title : roleMandateId}</b>`,
          path: '/role-mandate/' + roleMandateId + '/edit',
        },
      ];
    }

    if (roleMandateId) {
      pages = [
        ...pages,
        {
          title: 'Users',
          path: '/role-mandate/' + roleMandateId + '/users',
        },
        {
          title: 'Positions',
          path: '/role-mandate/' + roleMandateId + '/positions',
        },
        {
          title: 'Questions',
          path: '/role-mandate/' + roleMandateId + '/questions',
        },
        {
          title: 'Decision Tables',
          path: '/role-mandate/' + roleMandateId + '/decision-tables',
        },
        {
          title: 'Sort Questions',
          path: '/role-mandate/' + roleMandateId + '/sort',
        },
        {
          title: 'Review and Complete',
          path: '/role-mandate/' + roleMandateId + '/review',
        },
      ];
    }

    return pages;
  }
}
