import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { CreateSubscaleQuestionDialogComponent } from '../create-subscale-question-dialog/create-subscale-question-dialog.component';

// services
import { QuestionService } from '@app/core/services/question.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { Question } from '@app/core/models/question.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { SubscaleBreadcrumbService } from '@app/shared/services/subscale-breadcrumb.service';
import { MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { extractContent, isHTML } from '@app/shared/utils/common-utils';

@Component({
  selector: 'app-subscale-question-detail',
  templateUrl: './subscale-question-detail.component.html',
  styleUrls: ['./subscale-question-detail.component.scss'],
})
export class SubscaleQuestionDetailComponent implements OnInit {
  questionId: number;
  subscaleId: number;
  tabs: any[] = [];
  selected = new FormControl(0);
  question: Question;
  @ViewChild('questionDetailTabs') questionDetailTabs: MatTabGroup;
  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private questionService: QuestionService,
    private breadScrumService: SubscaleBreadcrumbService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.questionId = Number(this.route.snapshot.params.questionId);
    this.subscaleId = Number(this.route.snapshot.params.id);
    this.getQuestion();
    this.breadScrumService.updatedBreadScrum.subscribe((data) => {
      this.tabs = data;
    });
  }

  private getQuestion(): void {
    this.questionService.getById(this.questionId).subscribe((question: Question) => {
      this.question = question;
      if (isHTML(this.question.text)) {
        this.question.text = extractContent(this.question.text);
      }
      this.breadScrumService.setBreadCrumbNavigation('question_Details', {
        subScaleTitle: this.question.subscaleTitle,
        subScaleId: this.subscaleId,
        questionTitle: this.question.text,
        questionId: this.question.id,
      });
      this.breadScrumService.currentNavigationLink.subscribe((data) => {
        if (data == 'QuestionName') {
          this.selected.setValue(0);
        }
        if (data == 'Answers') {
          this.selected.setValue(1);
          this.breadScrumService.setBreadCrumbNavigation('answer', {
            subScaleTitle: this.question.subscaleTitle,
            subScaleId: this.subscaleId,
            questionTitle: this.question.text,
          });
        }
      });
    });
  }

  tabChange($event): void {
    this.selected.setValue($event);
    if ($event == 0) {
      this.breadScrumService.setBreadCrumbNavigation('question_Details', {
        subScaleTitle: this.question.subscaleTitle,
        subScaleId: this.subscaleId,
        questionTitle: this.question.text,
      });
    }
    if ($event == 1) {
      this.breadScrumService.setBreadCrumbNavigation('answer', {
        subScaleTitle: this.question.subscaleTitle,
        subScaleId: this.subscaleId,
        questionTitle: this.question.text,
        questionId: this.questionId,
      });
    }
  }

  onEdit() {
    this.dialog
      .open(CreateSubscaleQuestionDialogComponent, { data: { isEdit: true, question: this.question } })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          const updatedQuestion: Question = {
            id: this.questionId,
            text: result.data.text,
            instructions: result.data.instructions,
            questionGroupId: result.data.questionGroupId,
            questionType: result.data.questionType,
            aorder: result.data.aorder,
            numberAnswersAllowed: result.data.numberAnswersAllowed,
            numOfCards: result.data.numOfCards,
            numOnGarbage: result.data.numOnGarbage,
            image: result.data.image,
            imageContentType: result.data.imageContentType,
            video: result.data.video,
            videoContentType: result.data.videoContentType,
            active: result.data.active,
            subscaleId: this.subscaleId,
            showDiscarded: result.data.showDiscarded,
            randomizeCards: result.data.randomizeCards,
          };

          this.questionService.update(updatedQuestion).subscribe(() => {
            this.getQuestion();
            this.snackBar.info('Subscale Question updated with success!');
          });
        }
      });
  }

  navigateUrl(item: any) {
    if (this.router.url != item.url) {
      this.router.navigate([item.url]);
    }
    this.breadScrumService.currentNavigationLink.next(item.currentTab);
  }
}
