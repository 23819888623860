<app-layout-header (toggleEmitter)="onToggle($event)"></app-layout-header>
<mat-sidenav-container>
  <mat-sidenav #drawer [mode]="mobileQuery.matches ? 'over' : 'side'" class="sidenav" [opened]="toggled">
    <mat-nav-list *ngIf="role === 'admin'">
      <a mat-list-item [routerLink]="'/clients'"><mat-icon>business</mat-icon> Clients </a>
    </mat-nav-list>
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/psy-test-group'"><mat-icon>people</mat-icon> Test Group </a>
    </mat-nav-list>
    <mat-nav-list>
      <mat-expansion-panel hideToggle class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title><mat-icon>person</mat-icon> Users </mat-panel-title>
        </mat-expansion-panel-header>
        <a mat-list-item [routerLink]="'/users'"><mat-icon>person_search</mat-icon> Users </a>
        <a mat-list-item [routerLink]="'/users/all-invite'" *ngIf="role === 'admin'"
          ><mat-icon>search</mat-icon> Invites
        </a>
      </mat-expansion-panel>
    </mat-nav-list>
    <mat-nav-list *ngIf="role === 'admin'">
      <mat-expansion-panel hideToggle class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title><mat-icon>assignment_turned_in</mat-icon> Assessments </mat-panel-title>
        </mat-expansion-panel-header>
        <a mat-list-item [routerLink]="'/psy-test'"><mat-icon>check_circle</mat-icon> Assessments </a>
        <a mat-list-item [routerLink]="'/super-test'"><mat-icon>done_all</mat-icon> Supertests </a>
        <a mat-list-item [routerLink]="'/subscale'"><mat-icon>shop</mat-icon> Subscales </a>
        <a mat-list-item [routerLink]="'/superscale'"><mat-icon>shop_two</mat-icon> Superscales </a>
      </mat-expansion-panel>
    </mat-nav-list>
    <mat-nav-list *ngIf="role === 'admin'">
      <a mat-list-item [routerLink]="'/connections-indicator'"><mat-icon>map</mat-icon> Connections Indicators </a>
    </mat-nav-list>
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/role-mandate'" *ngIf="role === 'admin'"
        ><mat-icon>navigation</mat-icon> Role Navigators
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="role === 'admin' || 'tgm'">
      <mat-expansion-panel hideToggle class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title><mat-icon> insert_drive_file</mat-icon> Report </mat-panel-title>
        </mat-expansion-panel-header>
        <a mat-list-item routerLink="/report-dashboard"><mat-icon>dashboard</mat-icon> Dashboard </a>
        <a *ngIf="role === 'admin'" mat-list-item routerLink="/report/wizard"><mat-icon>file_copy</mat-icon> Wizard </a>
        <a mat-list-item [routerLink]="'/report/report-data-download'"
          ><mat-icon>cloud_download</mat-icon> Data Download
        </a>
        <a *ngIf="role === 'admin'" mat-list-item [routerLink]="'/report'"><mat-icon>store</mat-icon> Summary </a>
        <a *ngIf="role === 'admin'" mat-list-item routerLink="/chart"><mat-icon> insert_chart</mat-icon> Charts </a>
        <a *ngIf="role === 'admin'" mat-list-item routerLink="/area-index"
          ><mat-icon> format_list_bulleted</mat-icon> Area Mapping
        </a>
        <a *ngIf="role === 'admin'" mat-list-item routerLink="/optimal-curve"
          ><mat-icon>library_books</mat-icon> Optimal Curves
        </a>
        <a *ngIf="role === 'admin'" mat-list-item [routerLink]="'/report-model'"
          ><mat-icon>layers</mat-icon> Report Model
        </a>
      </mat-expansion-panel>
    </mat-nav-list>
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/dashboard'"><mat-icon>list_alt</mat-icon> My Assessments </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="role === 'admin'">
      <a mat-list-item [routerLink]="'/maintenance-mode'"><mat-icon>engineering</mat-icon> Settings </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
