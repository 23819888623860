import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { UserService } from '@app/core/services/user.service';
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { QuestionService } from '@app/core/services/question.service';
import { TestObjectService } from '@app/core/services/test-object.service';

// models
import { Question } from '@app/core/models/question.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { UserInfo } from '@app/core/models/userInfo.model';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { QuestionGroup } from '@app/core/models/question-group.model';
import { TextAnswer } from '@app/core/models/text-answer.model';
import { Answer } from '@app/core/models/answer.model';

@Component({
  selector: 'app-test-role-mandate-review',
  templateUrl: './test-role-mandate-review.component.html',
  styleUrls: ['./test-role-mandate-review.component.scss'],
})
export class TestRoleMandateReviewComponent implements OnInit {
  roleMandateId: number;
  userInfoId: number;
  userInfoData: UserInfo;
  roleMandate: RoleMandate;
  questions: Question[];
  questionNumber = 1;
  decisionTables: DecisionTable[];
  questionGroups: QuestionGroup[];
  allObj: any[];
  relatedAnswers: Answer[] = [];
  textAreaAnswers: TextAnswer[] = [];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private rMService: RoleMandateService,
    private questionGroupService: QuestionGroupService,
    private questionService: QuestionService,
    public testObjectService: TestObjectService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.roleMandateId = this.route.snapshot.params['id'];

    this.rMService.getById(this.roleMandateId).subscribe((retult) => {
      this.roleMandate = retult;
    });

    this.userInfoId = parseInt(localStorage.getItem('userInfoId'), 10);
    this.userService.get(this.userInfoId).subscribe({
      next: (result) => {
        this.userInfoData = result;
      },
    });

    forkJoin([
      this.questionGroupService.getAllQuestionGroupForRoleMandate(this.roleMandateId),
      this.rMService.getAllQuestionById(this.roleMandateId),
      this.rMService.getAllDecisionTableById(this.roleMandateId),
    ]).subscribe((results) => {
      this.questionGroups = results[0];

      this.questionGroups.forEach((questionGroup) => {
        questionGroup.questions = results[1].filter((question) => question.questionGroupId === questionGroup.id);
      });

      this.questions = results[1].filter(
        (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
      );

      this.decisionTables = results[2];

      this.allObj = [
        ...this.questionGroups.map((obj) => {
          obj.networkType = 'questionGroup';
          return obj;
        }),
        ...this.decisionTables.map((obj) => {
          obj.networkType = 'decisionTable';
          return obj;
        }),
        ...this.questions.map((obj) => {
          obj.networkType = 'question';
          return obj;
        }),
      ];

      this.allObj = this.allObj.sort((a, b) => {
        return a.aorder - b.aorder;
      });

      this.caluateQuestionNumber();
    });
  }

  getForkJoin(f: Question) {
    return forkJoin([
      this.questionService.findAllTextAnswerByQuestionIdAndUserId(f.id, this.userInfoId, this.roleMandateId),
      this.questionService.findAnswerCardsortByQuestionIdAndUserId(
        f.relatedQuestionId,
        this.userInfoId,
        this.roleMandateId,
      ),
      this.questionService.findAllAnswersByQuestionId(f.relatedQuestionId),
    ]);
  }

  async getRelatedAnswerTotalNumber(f: Question) {
    const results = await this.getForkJoin(f).toPromise();
    return this.refreshData(results[0], results[1], results[2]);
  }

  refreshData(result0: TextAnswer[], result1: Answer[], result2: any[]) {
    let relatedAnswerQuestionNumber = 0;
    this.textAreaAnswers = result0 ? result0 : [];

    if (result1) {
      this.relatedAnswers = result1;
    } else {
      this.relatedAnswers = result2;
    }

    this.relatedAnswers.forEach((rA: Answer, index) => {
      if (index === this.relatedAnswers.length - 1) {
        if (this.relatedAnswers && this.relatedAnswers.length > 0) {
          relatedAnswerQuestionNumber = this.relatedAnswers.length;
        }
      }
    });

    return relatedAnswerQuestionNumber;
  }

  async caluateQuestionNumber() {
    this.questionNumber = 1;

    for (const e of this.allObj) {
      if (e.hasOwnProperty('questions')) {
        for (const q of e.questions) {
          if (q.questionType !== 'LIST_TEXT' || q.relatedQuestionId === null) {
            q.questionNumber = this.questionNumber;
            this.questionNumber++;
          } else {
            q.relatedQuestionNumber = this.questionNumber;
            this.questionNumber = this.questionNumber + (await this.getRelatedAnswerTotalNumber(q));
          }
        }
      } else {
        if (e.questionType === 'LIST_TEXT' && e.relatedQuestionId !== null) {
          e.relatedQuestionNumber = this.questionNumber;
          this.questionNumber = this.questionNumber + (await this.getRelatedAnswerTotalNumber(e));
        } else {
          e.questionNumber = this.questionNumber;
          this.questionNumber++;
        }
      }
    }
  }

  onSave() {
    this.rMService.completeRoleMandate(this.roleMandateId, this.userInfoId).subscribe({
      next: (result) => {
        this.router.navigate([`/dashboard`]);
      },
    });
  }
}
