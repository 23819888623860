/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyTestGroupTestScale } from '../models/PsyTestGroupTestScale';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupTestScaleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve the subscale ranges by id
     * Retrieve the subscale ranges by id
     * @param id Id of the test group
     * @returns PsyTestGroupTestScale A list of subscale range by test group id
     * @throws ApiError
     */
    public getTestGroupTestSubscaleRanges(
        id: number,
    ): Observable<PsyTestGroupTestScale> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/test/{testId}/subscale-ranges',
            path: {
                'id': id,
            },
            errors: {
                404: `subscale ranges not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Add subscaleRanges to test group
     * Add subscaleRanges to test group
     * @param testGroupId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupTestSubscaleRanges(
        testGroupId: number,
        requestBody?: {
            /**
             * Id of the test group
             */
            id?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{id}/test/{testId}/subscale-ranges',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

}
