<div class="container">
  <div class="page-list-header">
    <h1>Report Models</h1>
    <button mat-raised-button class="new-button pull-right" color="primary" (click)="createNewReportModel()">
      <mat-icon aria-hidden="false">add</mat-icon>Create a new Report Model
    </button>
  </div>
  <app-psb-list
    *ngIf="reportModelPsbItems.length > 0"
    [itemsPerPage]="10"
    [items]="reportModelPsbItems"
    (view)="onView($event)"
    (edit)="onEdit($event)"
    (delete)="onDelete($event)"
  >
  </app-psb-list>
</div>
