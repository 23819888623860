import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-unanswered-questions-dialog',
  templateUrl: './unanswered-questions-dialog.component.html',
  styleUrls: ['./unanswered-questions-dialog.component.scss'],
})
export class UnansweredQuestionsDialogComponent implements OnInit {
  public message: string;
  public isShowCancel: boolean;

  constructor(public dialogRef: MatDialogRef<UnansweredQuestionsDialogComponent>) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onConfirm() {
    this.dialogRef.close({ event: 'confirm' });
  }
}
