<mat-card>
  <h3>{{ question.questionNumber }}) {{ question.text }}</h3>
  <mat-card-content>
    <div *ngIf="answering">
      <form (submit)="selectUser()" class="row">
        <div class="column">
          <mat-form-field>
            <input
              type="text"
              placeholder="Search"
              matInput
              #trigger="matAutocompleteTrigger"
              [formControl]="searchControl"
              autocomplete="off"
              [matAutocomplete]="auto"
            />
            <mat-icon matSuffix (click)="onClearSearch($event, trigger)">clear</mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUser">
              <mat-option
                *ngFor="let user of filteredUsers | async"
                [value]="user"
                (onSelectionChange)="onSelectionChange($event, user)"
              >
                <div (click)="optionClicked($event, user)">
                  <mat-checkbox
                    color="primary"
                    [checked]="user.selected"
                    (change)="toggleSelection(user)"
                    (click)="$event.stopPropagation()"
                  >
                    {{ user.firstName }} {{ user.lastName }}
                  </mat-checkbox>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error *ngIf="searchControl.errors" style="margin-bottom: 5px"> Please, select a valid user. </mat-error>
        </div>
        <div>
          <button type="submit" mat-button class="btn-primary" (click)="clearUserList()">Add</button>
        </div>
      </form>
    </div>

    <table mat-table [dataSource]="userConnections" class="mat-elevation-z3">
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>First Name</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Last Name</th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let element">{{ element.role }}</td>
      </ng-container>

      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef>Department</th>
        <td mat-cell *matCellDef="let element">{{ element.department }}</td>
      </ng-container>

      <ng-container matColumnDef="private" *ngIf="displayedColumns.includes('private')">
        <th mat-header-cell *matHeaderCellDef>Private</th>
        <td *matCellDef="let element">
          <mat-slide-toggle
            *ngIf="question.acl === 5"
            color="primary"
            [disabled]="!answering"
            [checked]="+element.private === 1"
            (change)="togglePrivacy(element)"
          ></mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="actionDelete">
        <th mat-header-cell class="action" *matHeaderCellDef>Action</th>
        <td *matCellDef="let element">
          <button mat-button class="btn-error" (click)="onDelete(element)" *ngIf="answering">Delete</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </mat-card-content>
</mat-card>
