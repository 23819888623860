import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.scss']
})
export class ArchiveDialogComponent implements OnInit {
  public message: string;

  constructor(public dialogRef: MatDialogRef<ArchiveDialogComponent>) { }

  ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe(event => {
      this.onCancel();
    })
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
