/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Chart } from '../models/Chart';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a list with charts by user id
     * Retrieve a list with charts by user id
     * @param id Id of the chart
     * @param userId Id of the user
     * @returns Chart A list of charts
     * @throws ApiError
     */
    public getChartUser(
        id: number,
        userId: number,
    ): Observable<Chart> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{id}/user/{userId}',
            path: {
                'id': id,
                'userId': userId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve user's connection indicator report
     * Retrieve user's connection indicator report
     * @param userId Id of the user
     * @returns any User's report
     * @throws ApiError
     */
    public getReportUserConnectionIndicatorDownload(
        userId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/user/{userId}/connection-indicator/download',
            path: {
                'userId': userId,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve user's report
     * Retrieve user's report
     * @param id Id of the report
     * @param userId Id of the user
     * @param testGroupId Id of the test group
     * @returns any User's report
     * @throws ApiError
     */
    public getReportUserTestGroup(
        id: number,
        userId: number,
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/{id}/user/{userId}/test-group/{testGroupId}',
            path: {
                'id': id,
                'userId': userId,
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve user's test results
     * Retrieve user's report
     * @param userId Id of the user Info
     * @param testGroupId Id of the test group
     * @returns any User's test results
     * @throws ApiError
     */
    public getReportUserTestGroupTestResults(
        userId: number,
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/user/{userId}/test-group/{testGroupId}/test-results',
            path: {
                'userId': userId,
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Test results not found`,
                500: `Internal error`,
            },
        });
    }

}
