import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  QueryList,
  ViewChildren,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { EVENT_CANCEL } from '@app/core/constants';
import { EVENT_SUCCESS } from '@app/core/constants';
import { TransferUserController } from '../../../../../../../generated/v3';
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-move-users-from-other-test-groups',
  templateUrl: './move-users-from-other-test-groups.component.html',
  styleUrls: ['./move-users-from-other-test-groups.component.scss'],
})
export class MoveUsersFromOtherTestGroupsComponent implements OnInit, AfterViewInit {
  originalTestGroupId: number;
  displayedTestGroupUsersColumns: string[] = ['select', 'name'];
  displayedTestGroupsColumns: string[] = ['select', 'title'];
  testGroupUsers = new MatTableDataSource<UserInfo>();
  testGroups = new MatTableDataSource<PsyTestGroup>();
  selectionUsers = new SelectionModel<UserInfo>(true, []);
  selectedUserList: any[] = [];
  selectedTestGroup: any = null;

  filterUser = new FormControl('');
  filterTestGroup = new FormControl('');

  @ViewChildren('userPaginator') userPaginator = new QueryList<MatPaginator>();
  @ViewChildren('testGroupPaginator') testGroupPaginator = new QueryList<MatPaginator>();
  @ViewChild('sortUsers') sortUsers: MatSort;
  @ViewChild('sortTestGroups') sortTestGroups: MatSort;

  constructor(
    private transferUserController: TransferUserController,

    public dialogRef: MatDialogRef<MoveUsersFromOtherTestGroupsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      testGroupUsers: UserInfo[];
      transferredTestGroups: PsyTestGroup[];
      originalTestGroupId: number;
    },
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.originalTestGroupId = this.data.originalTestGroupId;
    this.testGroupUsers = new MatTableDataSource<UserInfo>(this.data.testGroupUsers);
    this.testGroups = new MatTableDataSource<PsyTestGroup>(this.data.transferredTestGroups);

    if (this.testGroupUsers.paginator) {
      this.testGroupUsers.paginator.firstPage();
    }

    if (this.testGroups.paginator) {
      this.testGroups.paginator.firstPage();
    }

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onClose();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onClose();
    });
  }

  ngAfterViewInit() {
    this.testGroupUsers.paginator = this.userPaginator.toArray()[0];
    this.testGroupUsers.sort = this.sortUsers;
    this.cd.detectChanges();

    this.testGroups.paginator = this.testGroupPaginator.toArray()[0];
    this.testGroups.sort = this.sortTestGroups;
    this.cd.detectChanges();
  }

  applyFilterUser(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.testGroupUsers.filter = filterValue.trim().toLowerCase();
  }

  isAllUserSelected() {
    const numSelected = this.selectionUsers.selected.length;
    const numRows = this.testGroupUsers.data.length;
    return numSelected === numRows;
  }

  checkUserboxLabel(row?: UserInfo): string {
    if (!row) {
      return `${this.isAllUserSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionUsers.isSelected(row) ? 'deselect' : 'select'} row ${row.name}`;
  }

  onUserSelection(row: UserInfo, isChecked: boolean) {
    if (isChecked) {
      if (!this.selectedUserList.some((e) => e.id === row.id)) {
        this.selectedUserList.push({ ...row, name: this.formatUserChipSetLabel(row) });
        this.selectionUsers.toggle(row);
        this.filterUser.reset();
      }
    } else {
      if (this.selectedUserList.some((e) => e.id === row.id)) {
        this.selectedUserList = this.selectedUserList.filter((e) => e.id !== row.id);
        this.selectionUsers.deselect(row);
      }
    }
  }

  formatUserChipSetLabel(row: UserInfo): string {
    if (row.name) {
      return row.name;
    }
  }

  removeUser(user) {
    if (user) {
      let removeUserData = this.testGroupUsers.data.filter((e) => e.id == user.id)[0];
      this.selectionUsers.deselect(removeUserData);
      this.selectedUserList = this.selectedUserList.filter((e) => e.id != removeUserData.id);
    }
  }

  masterToggleUser() {
    this.isAllUserSelected()
      ? `${this.selectionUsers.clear()} ${(this.selectedUserList.length = 0)}`
      : this.testGroupUsers.data.forEach((row) => {
          this.selectionUsers.select(row);
          this.selectedUserList.push({ ...row, name: this.formatUserChipSetLabel(row) });
        });
    this.filterUser.reset();
  }

  onClose() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onTransfer() {
    this.transferUserController
      .transferUserControllerTransferUserWithTestResults({
        transferUserIdIn: this.selectedUserList.map((u) => u.userInfoId),
        transferFromTestGroupIdIn: this.originalTestGroupId,
        transferToTestGroupId: this.selectedTestGroup.id,
      })
      .subscribe((result) => {
        this.dialogRef.close({ event: EVENT_SUCCESS, result });
      });
  }

  applyFilterTestGroup(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.testGroups.filter = filterValue.trim().toLowerCase();
  }

  checkTestGroupboxLabel(row?: PsyTestGroup): string {
    if (!row) {
      return `${this.isAllUserSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectedTestGroup?.id === row.id ? 'deselect' : 'select'} row ${row.title}`;
  }

  onTestGroupSelection(row: PsyTestGroup, isChecked: boolean) {
    if (isChecked) {
      this.selectedTestGroup = { id: row.id, title: this.formatTestGroupChipSetLabel(row) };
      this.filterTestGroup.reset();
    } else {
      this.selectedTestGroup = null;
    }
  }

  formatTestGroupChipSetLabel(row: PsyTestGroup): string {
    if (row.title) {
      return row.title;
    }
  }

  removeTestGroup() {
    this.selectedTestGroup = null;
  }
}
