<div style="flex: 35%">
  <svg viewBox="0 0 400 400" style="width: auto; max-height: 400px">
    <g style="pointer-events: all">
      <path
        style="fill: none; stroke: white; stroke-width: 0.75590551; stroke-opacity: 1"
        d="M 1.2472873,100.37468 0.83773727,299.37361"
      />
      <path
        style="fill: none; stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="M 399.65633,299.65023 399.53191,101.01516"
      />
      <path
        style="fill: none; stroke: white; stroke-width: 0.75590551; stroke-opacity: 1"
        d="M 1.2472873,100.37468 100.76097,0.82823804"
      />
      <path
        style="fill: none; stroke: white; stroke-width: 0.75590551; stroke-opacity: 1"
        d="m 300.60263,399.28803 98.56592,-99.29087"
      />
      <path
        style="fill: none; stroke: white; stroke-width: 0.75590551; stroke-opacity: 1"
        d="M 399.53191,101.01516 300.16435,0.35460803"
      />
      <path
        style="fill: none; stroke: white; stroke-width: 0.75590551; stroke-opacity: 1"
        d="M 100.8882,399.1672 0.83772727,299.37361"
      />
      <path
        style="fill: none; stroke: white; stroke-width: 0.75590551; stroke-opacity: 1"
        d="m 100.88821,399.16739 199.71442,0.12064"
      />
      <path
        style="fill: none; stroke: white; stroke-width: 0.75590551; stroke-opacity: 1"
        d="M 100.76097,0.82823804 300.16435,0.35460803"
      />
      <path
        style="
          fill: none;
          stroke: white;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
        "
        d="M 199.88452,99.647761 C 262.5884,101.92113 297.19363,145.93758 300.073,199.95558"
      />
      <path
        style="
          fill: none;
          stroke: white;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
        "
        d="m 101.70717,199.78466 c -0.0714,-30.16778 18.40878,-96.3853 98.17735,-100.136899"
      />
      <path
        style="
          fill: none;
          stroke: white;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
        "
        d="m 101.70717,199.78466 c 1.87037,55.77535 42.21932,96.57844 98.01247,99.38463"
      />
      <path
        style="
          fill: none;
          stroke: white;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
        "
        d="m 199.71964,299.16929 c 43.47992,-0.67741 100.4974,-30.8023 100.32085,-99.42504"
      />
      <path
        style="
          fill: none;
          stroke: white;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
        "
        d="m 300.04049,199.74425 99.54152,-0.33464"
      />
      <path
        style="
          fill: none;
          stroke: white;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
        "
        d="M 1.0597075,199.90114 101.64799,199.61197"
      />
      <path
        style="
          fill: none;
          stroke: white;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
        "
        d="M 199.88452,99.647761 199.72954,0.76817804"
      />
      <path
        style="
          fill: none;
          stroke: white;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
        "
        d="m 199.71964,299.16929 0.21653,99.70735"
      />
      <path
        number="1"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 200.00072,199.55436 -0.11615,-99.906599 c 16.48357,-0.805694 35.23987,5.000179 44.18212,10.289529 l -44.11752,89.72038"
        [style.fill]="selectedAreaNumber == 1 ? '#ccc' : '#425465'"
      />
      <path
        number="2"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        [style.fill]="selectedAreaNumber == 2 ? '#ccc' : '#425465'"
        d="m 200.00072,199.55436 44.06592,-89.61707 c 24.13818,10.27836 38.15239,31.81706 45.45173,44.47146 z"
      />
      <path
        number="3"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 89.5693,-45.24892 c 8.93093,16.2482 10.06227,30.06871 10.52212,45.3355 z"
        [style.fill]="selectedAreaNumber == 3 ? '#ccc' : '#425465'"
      />
      <path
        number="4"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 100.09142,0.0866 c 0.0598,16.79588 -3.03125,27.85829 -9.89295,44.80913 z"
        [style.fill]="selectedAreaNumber == 4 ? '#ccc' : '#425465'"
      />
      <path
        number="5"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 90.19847,44.89571 c -9.69794,16.84653 -22.90827,34.10629 -44.8996,45.271 z"
        [style.fill]="selectedAreaNumber == 5 ? '#ccc' : '#425465'"
      />
      <path
        number="6"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 0.0343,100.60922 c 11.54402,0.47856 32.38516,-3.11028 45.26457,-10.44251 z"
        [style.fill]="selectedAreaNumber == 6 ? '#ccc' : '#425465'"
      />
      <path
        number="7"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 -44.38295,89.65652 c 17.38839,9.43602 29.40489,9.47122 44.41725,10.9527 z"
        [style.fill]="selectedAreaNumber == 7 ? '#ccc' : '#425465'"
      />
      <path
        number="8"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 -88.51401,43.83651 c 10.43004,22.1324 31.30246,40.31724 44.13106,45.82001 z"
        [style.fill]="selectedAreaNumber == 8 ? '#ccc' : '#425465'"
        id="path1749"
      />
      <path
        number="9"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 -98.2419,0.12699 c -0.73464,16.27439 4.00592,30.68927 9.72789,43.70952 z"
        [style.fill]="selectedAreaNumber == 9 ? '#ccc' : '#425465'"
      />
      <path
        number="10"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 -87.98989,-44.23836 c -7.54957,16.75126 -10.03715,31.02318 -10.25201,44.36535 z"
        [style.fill]="selectedAreaNumber == 10 ? '#ccc' : '#425465'"
      />
      <path
        number="11"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 -87.98989,-44.23836 c 8.63178,-13.62666 18.28624,-31.27098 43.86521,-44.60545 z"
        [style.fill]="selectedAreaNumber == 11 ? '#ccc' : '#425465'"
      />
      <path
        number="12"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.94907,199.65767 -0.0645,-100.009909 c -10.60613,-0.461851 -31.59449,3.570099 -44.06013,11.166099 l 44.12468,88.84381"
        [style.fill]="selectedAreaNumber == 12 ? '#ccc' : '#425465'"
      />
      <path
        number="13"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.72954,0.76817804 0.15498,98.87958296 c 16.84514,-0.909 35.70475,5.620019 44.18212,10.289529 L 300.16435,0.35460803 Z"
        [style.fill]="selectedAreaNumber == 13 ? '#ccc' : '#425465'"
      />
      <path
        number="14"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 244.06664,109.93729 c 21.65187,9.27216 37.38683,29.2824 45.45173,44.47146 L 399.53191,101.01516 300.16435,0.35344803 Z"
        [style.fill]="selectedAreaNumber == 14 ? '#ccc' : '#425465'"
      />
      <path
        number="15"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 289.48185,154.37223 c 8.47822,17.29342 10.13827,30.40297 10.55864,45.37202 l 99.79829,-0.16881 -0.30687,-98.56028 z"
        [style.fill]="selectedAreaNumber == 15 ? '#ccc' : '#425465'"
      />
      <path
        number="16"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 300.04049,199.74425 c 0.2664,17.41572 -3.961,30.18267 -9.89295,44.80913 l 109.50879,55.09685 0.21884,-100.03956 z"
        [style.fill]="selectedAreaNumber == 16 ? '#ccc' : '#425465'"
      />
      <path
        number="17"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 245.24794,289.82438 c 14.60129,-6.50717 34.09682,-23.09499 44.8996,-45.271 l 109.02101,55.44378 -98.56592,99.29087 z"
        [style.fill]="selectedAreaNumber == 17 ? '#ccc' : '#425465'"
      />
      <path
        number="18"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 199.98337,300.26689 -0.0472,98.60975 100.66646,0.41139 -55.35469,-109.46365 c -14.70334,8.56092 -36.40662,11.00703 -45.26457,10.44251 z"
        [style.fill]="selectedAreaNumber == 18 ? '#ccc' : '#425465'"
      />
      <path
        number="19"
        style="stroke: #394e61; stroke-dasharray: 0 65"
        d="m 199.98337,300.26689 c -13.52741,-1.31336 -22.81802,-0.25265 -44.41725,-10.9527 l -54.67791,109.85301 99.04796,-0.29056 z"
        [style.fill]="selectedAreaNumber == 19 ? '#ccc' : '#425465'"
      />
      <path
        number="20"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="m 111.43506,243.49418 c 10.88804,24.20929 35.4641,42.11692 44.13106,45.82001 L 100.88821,399.16739 0.83773727,299.37361 Z"
        [style.fill]="selectedAreaNumber == 20 ? '#ccc' : '#425465'"
      />
      <path
        number="21"
        style="stroke: white; stroke-width: 0.99346489px; stroke-opacity: 1"
        d="M 1.1735373,199.64722 0.83772727,299.37361 111.43506,243.49418 c -5.30875,-13.02025 -9.946,-22.88968 -9.72789,-43.70952 z"
        [style.fill]="selectedAreaNumber == 21 ? '#ccc' : '#425465'"
      />
      <path
        number="22"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="M 1.2472873,100.37468 111.95918,155.41931 c -7.39125,15.58747 -10.27188,32.78146 -10.25201,44.36535 L 1.1735373,199.64722 Z"
        [style.fill]="selectedAreaNumber == 22 ? '#ccc' : '#425465'"
      />
      <path
        number="23"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="M 1.2472873,100.37468 111.95918,155.41931 c 4.29116,-5.67427 13.57036,-29.12877 43.86521,-44.60545 L 100.76097,0.82823804 Z"
        [style.fill]="selectedAreaNumber == 23 ? '#ccc' : '#425465'"
      />
      <path
        number="24"
        style="stroke: white; stroke-width: 1; stroke-opacity: 1"
        d="M 100.90369,0.66885803 155.82439,110.81386 c 13.66403,-7.95883 32.3684,-11.242574 44.06013,-11.166099 L 199.72953,0.76690804 Z"
        [style.fill]="selectedAreaNumber == 24 ? '#ccc' : '#425465'"
      />
    </g>
  </svg>
</div>
