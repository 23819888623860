import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// components
import { CreateSubscaleDialogComponent } from '../../subscale/create-subscale-dialog/create-subscale-dialog.component';

// services
import { SubscaleService } from '@app/core/services/subcale.service';

// models
import { Subscale } from '@app/core/models/subscale.model';
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-superscale-subscale-create-dialog',
  templateUrl: './superscale-subscale-create-dialog.component.html',
  styleUrls: ['./superscale-subscale-create-dialog.component.scss'],
})
export class SuperscaleSubscaleCreateDialogComponent implements OnInit {
  isEdit: boolean;
  superscaleSubscaleDialogForm = new FormGroup({
    subscaleId: new FormControl('', [Validators.required]),
    weight: new FormControl(null),
  });
  subscales: Subscale[];
  filteredSubscales$: Observable<Subscale[]>;
  alreadyExistedSuperscaleSubscales: PsbItem[];
  subscaleId: number;
  constructor(
    public dialogRef: MatDialogRef<CreateSubscaleDialogComponent>,
    private subscaleService: SubscaleService,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; superscaleSubscales: PsbItem[] },
  ) {}

  ngOnInit(): void {
    this.alreadyExistedSuperscaleSubscales = this.data.superscaleSubscales;

    this.subscaleService.getAll().subscribe((subscales: Subscale[]) => {
      this.subscales = subscales.filter(
        (subscale) => !this.alreadyExistedSuperscaleSubscales.map((item) => item.id).includes(subscale.id),
      );
      this.filteredSubscales$ = this.superscaleSubscaleDialogForm.controls['subscaleId'].valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value)),
      );
    });
  }

  onSave(): void {
    this.dialogRef.close({
      event: 'save',
      data: {
        weight: this.superscaleSubscaleDialogForm.get('weight').value,
        subscaleId: this.subscaleId,
      },
    });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  private _filter(value: string): Subscale[] {
    const filterValue = value.toLowerCase();

    return this.subscales.filter((subscale) => subscale.title.toLowerCase().includes(filterValue));
  }

  onChange(subscale: Subscale) {
    this.subscaleId = subscale.id;
  }
}
