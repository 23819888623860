/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestSubscaleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a PsyTestSubscale
     * Create a PsyTestSubscale
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestPsyTestSubscale(
        requestBody?: {
            /**
             * Id of the PsyTestScale
             */
            id: number;
            /**
             * Id of the psy test
             */
            testId?: number;
            /**
             * Id of the subscale
             */
            subscaleId?: number;
            /**
             * Id of the parent subscale
             */
            parentSubscale?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/{id}/psy-test-subscale',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Create all PsyTestSubscale
     * Create all PsyTestSubscale
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestAllPsyTestSubscale(
        requestBody?: {
            /**
             * Id of the PsyTestScale
             */
            id: number;
            /**
             * Id of the psy test
             */
            testId?: number;
            /**
             * Id of the subscale
             */
            subscaleId?: number;
            /**
             * Id of the parent subscale
             */
            parentSubscale?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/{id}/all-psy-test-subscale',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

}
