<form [formGroup]="emailForm" (submit)="sendEmail()">
  <h2>Message</h2>

  <mat-form-field>
    <mat-label>From</mat-label>
    <mat-select formControlName="from">
      <mat-option *ngFor="let source of sources" [value]="source"> {{ source }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Email Type</mat-label>
    <mat-select formControlName="emailTypeId">
      <mat-option *ngFor="let item of emailTypes" [value]="item.id" (onSelectionChange)="selectEmail(item.id, $event)">
        {{ item.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Template</mat-label>
    <mat-select formControlName="template">
      <mat-option *ngFor="let email of emailSet?.email" [value]="email.id"> {{ email.subject }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Subject</mat-label>
    <input type="text" formControlName="subject" matInput />
  </mat-form-field>

  <angular-editor type=" text" formControlName="message" [config]="editorConfig"> </angular-editor>
  <mat-hint>Use <b>#LINK#</b> to place the link on your e-mail and <b>#NAME#</b> to user's name</mat-hint>

  <div class="row buttons">
    <div></div>
    <div>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="this.emailForm.invalid || this.users.length === 0"
      >
        Send E-mail
      </button>
      <button
        mat-raised-button
        type="button"
        (click)="onUpsertMessage()"
        [disabled]="emailForm.get('message').invalid || emailForm.get('subject').invalid"
      >
        Save Message
      </button>
    </div>
  </div>
</form>
