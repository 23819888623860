import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-connections-indicator',
  templateUrl: './connections-indicator.component.html',
  styleUrls: ['./connections-indicator.component.scss'],
})
export class ConnectionsIndicatorComponent implements OnInit, OnDestroy {
  connectionIndicators: PsbItem[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private connectionIndicator$: Subscription;
  isDialogOpen = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cIService: ConnectionsIndicatorService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.connectionIndicator$ = this.cIService.getAll().subscribe((result) => {
      this.connectionIndicators = result.map((connectionIndicator) => {
        let progressDescription;

        if (
          connectionIndicator.userStarted + connectionIndicator.userCompleted > 0 &&
          connectionIndicator.userStarted + connectionIndicator.userCompleted <= 1
        ) {
          progressDescription = connectionIndicator.userStarted + connectionIndicator.userCompleted + ' user started';
        } else if (connectionIndicator.userStarted + connectionIndicator.userCompleted > 1) {
          progressDescription = connectionIndicator.userStarted + connectionIndicator.userCompleted + ' users started';
        } else if (connectionIndicator.usersInvited <= 0) {
          progressDescription = 'No users invited';
        } else if (
          connectionIndicator.usersInvited > 0 &&
          connectionIndicator.userCompleted <= 0 &&
          connectionIndicator.userStarted <= 0
        ) {
          progressDescription = 'Waiting ' + connectionIndicator.usersInvited + ' to start';
        } else if (
          connectionIndicator.userCompleted === connectionIndicator.usersInvited &&
          connectionIndicator.usersInvited > 0
        ) {
          progressDescription = 'All users completed';
        }

        return {
          id: connectionIndicator.id,
          title: connectionIndicator.title,
          description: connectionIndicator.companyName,
          progress: Number(
            connectionIndicator.usersInvited > 0
              ? (
                  ((connectionIndicator.userStarted + connectionIndicator.userCompleted) /
                    connectionIndicator.usersInvited) *
                  100
                ).toFixed(0)
              : 0,
          ),
          progressDescription,
        };
      });
    });
  }

  onNewCI() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  onView(connectionIndicatorId: number) {
    this.router.navigate([`/user/connections-indicator/${connectionIndicatorId}/instructions`]);
  }

  onEdit(connectionIndicatorId: number) {
    this.router.navigate([`/connections-indicator/${connectionIndicatorId}/edit`]);
  }

  onDashboard(connectionIndicatorId: number) {
    this.router.navigate([`/connections-indicator/${connectionIndicatorId}/dashboard/tab/0`]);
  }

  onDelete(connectionIndicatorId: number) {
    this.isDialogOpen = true;

    const deleteDialog = this.dialog.open(DeleteDialogComponent);
    deleteDialog.componentInstance.message = `Deleting this Connection Indicator all the questions,
      decision tables and other components connected will be deleted.
      Are you sure you want to delete?`;
    deleteDialog.afterClosed().subscribe((result) => {
      this.isDialogOpen = false;
      if (result.event !== EVENT_CANCEL) {
        this.cIService.delete(connectionIndicatorId).subscribe({
          next: () => {
            this.connectionIndicators = this.connectionIndicators.filter((item) => item.id !== connectionIndicatorId);
          },
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.connectionIndicator$.unsubscribe();
  }
}
