import { Component, Input, OnInit } from '@angular/core';

// models
import { OrdinalParagraph } from '@app/core/models/ordinal-paragraph.model';
import { ReportService } from '@app/core/services/report.service';

@Component({
  selector: 'app-ordinal-paragraph',
  templateUrl: './ordinal-paragraph.component.html',
  styleUrls: ['./ordinal-paragraph.component.scss'],
})
export class OrdinalParagraphComponent implements OnInit {
  @Input() model: OrdinalParagraph;
  @Input() showBottom: boolean;

  topSentences: {
    subscaleId: number;
    scaleType: string;
    percentile: number;
    he: string;
    she: string;
    they: string;
  }[];
  bottomSentences: {
    subscaleId: number;
    scaleType: string;
    percentile: number;
    he: string;
    she: string;
    they: string;
  }[];

  constructor(private reportService: ReportService) {}

  ngOnInit(): void {
    this.formatSentences();
  }

  formatSentences() {
    // Removes unnecessary tags
    this.model.sentences.forEach((sentence) => {
      sentence.text = this.reportService.removeSpecificHtmlChars(sentence.text);
      sentence.text = this.reportService.removeSelectionBoundary(sentence.text);
      sentence.text = this.reportService.removeUnnecessaryParagraphs(sentence.text);
    });
    this.topSentences = this.model.sentences.filter((sentence) => sentence.scaleType === 'TOP');
    this.bottomSentences = this.model.sentences.filter((sentence) => sentence.scaleType === 'BOTTOM');
  }
}
