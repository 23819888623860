/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Notification } from '../models/Notification';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class NotificationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a notification
     * Create a notification
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postNotification(
        requestBody?: {
            /**
             * Webhook of the notification
             */
            webhook?: string;
            /**
             * Chanel of the notification
             */
            chanel?: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all notifications
     * @returns Notification A list of notifications
     * @throws ApiError
     */
    public getNotification(): Observable<Array<Notification>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notification',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Update a notification
     * Update a notification
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchNotification(
        id: number,
        requestBody?: {
            /**
             * webhook of notification
             */
            webhook?: string;
            /**
             * Chanel of the notification
             */
            chanel?: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/notification/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Notification not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a notification
     * Delete a notification
     * @param id Id of the notification
     * @returns any Notification deleted successfully
     * @throws ApiError
     */
    public deleteNotification(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/notification/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a psy test group notification
     * @param testGroupId Id of the test group
     * @returns any PsyTestGroupNotification deleted successfully
     * @throws ApiError
     */
    public deleteTestGroupNotificationNotification(
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group-notification/{testGroupId}/notification/{notificationId}',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a role mandate notification
     * @param roleMandateId Id of the Role Mandate
     * @returns any RoleMandateNotification deleted successfully
     * @throws ApiError
     */
    public deleteRoleMandateNotificationNotification(
        roleMandateId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/role-mandate-notification/{roleMandateId}/notification/{notificationId}',
            path: {
                'roleMandateId': roleMandateId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
