import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { CompanyService } from '@app/core/services/company.service';
import { DepartmentService } from '@app/core/services/department.service';
import { SendEmailService } from '@app/core/services/send-email.service';
import { PageService } from '@app/core/services/page.service';

// models
import { Department } from '@app/core/models/department.model';
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';
import { Company } from '@app/core/models/company.model';
import { User } from '@app/core/models/user.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-connections-indicator-review',
  templateUrl: './connections-indicator-review.component.html',
  styleUrls: ['./connections-indicator-review.component.scss'],
})
export class ConnectionsIndicatorReviewComponent implements OnInit {
  company: Company;
  connectionIndicator: ConnectionIndicator;
  connectionIndicatorId: number;
  departments: MatTableDataSource<Department>;
  users: MatTableDataSource<User>;
  displayedReviewUsersColumns: string[] = ['firstName', 'lastName', 'email', 'invited'];
  displayedReviewDepartmentsColumns: string[] = ['name', 'actionDelete'];
  displayedReviewDecisionTablesColumns: string[] = ['type', 'selection'];
  displayedReviewQuestionsColumns: string[] = ['Text', 'Weight'];
  configPaginationUsers: any;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  privacyMsgs = [
    { id: 5, msg: 'Allow users to set answers as private' },
    { id: 9, msg: 'This Connections Indicator is fully private' },
  ];

  @ViewChild(MatPaginator, { static: true }) paginatorUsers: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginatorDepartments: MatPaginator;

  constructor(
    private cIService: ConnectionsIndicatorService,
    private companyService: CompanyService,
    private departmentService: DepartmentService,
    public dialog: MatDialog,
    private sendEmailService: SendEmailService,
    private router: Router,
    private route: ActivatedRoute,
    public pageService: PageService,
  ) {}

  ngOnInit(): void {
    this.getCIReviewInfo();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  getCIReviewInfo() {
    this.connectionIndicatorId = Number(this.route.snapshot.params.id);

    this.cIService.getById(this.connectionIndicatorId).subscribe({
      next: (connectionIndicator) => {
        this.connectionIndicator = connectionIndicator;
        this.companyService.getById(connectionIndicator.companyId).subscribe({
          next: (company) => {
            this.company = company;
          },
        });

        this.cIService.getUsersAndInvites(this.connectionIndicatorId).subscribe({
          next: (users) => {
            this.users = new MatTableDataSource<User>(users);
            this.users.paginator = this.paginatorUsers;
          },
        });

        this.companyService.getDepartmentsById(connectionIndicator.companyId).subscribe({
          next: (departments) => {
            this.departments = new MatTableDataSource<Department>(departments);
            this.departments.paginator = this.paginatorDepartments;
          },
        });
      },
      error: (err) => console.error('Observer got an error: ' + err),
    });
  }

  onDelete(department: Department) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.componentInstance.message = `Please confirm that you want delete this Department?`;

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.departmentService.delete(department.id).subscribe((res) => {
          if (res) {
            const index = this.departments.data.indexOf(department);
            this.departments.data.splice(index, 1);
            this.departments._updateChangeSubscription();
          }
        });
      }
    });
  }

  onChangePrivacy(acl) {
    this.connectionIndicator.acl = acl;

    this.cIService.updateAcl(this.connectionIndicatorId, this.connectionIndicator.acl).subscribe((result) => {
      return this.connectionIndicator.acl;
    });
  }

  saveAndInvite() {
    this.cIService.updateActive(this.connectionIndicator).subscribe(() => {
      this.router.navigate(['/connections-indicator/']);
    });
  }
}
