import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericLinkInviteComponent } from '@app/user/generic-link-invite/generic-link-invite.component';
import { GenericLinkInviteRoutingModule } from '@app/user/generic-link-invite/generic-link-invite-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { SharedModule } from '@app/shared/shared.module';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  declarations: [GenericLinkInviteComponent],
  imports: [
    CommonModule,
    GenericLinkInviteRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatPasswordStrengthModule,
    MatStepperModule,
  ],
})
export class GenericLinkInviteModule {}
