<div class="container">
  <div class="page-list-header">
    <h1>Supertests</h1>
    <button mat-raised-button class="new-button pull-right" color="primary" (click)="createNewSupertest()">
      <mat-icon aria-hidden="false">add</mat-icon>Create a new Supertest
    </button>
  </div>
  <app-psb-list
    *ngIf="superTests.length > 0"
    [itemsPerPage]="10"
    [items]="superTests"
    (delete)="onDelete($event)"
    (view)="onView($event)"
  >
  </app-psb-list>
</div>
