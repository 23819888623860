<form>
  <button mat-raised-button color="primary" (click)="onAddPetal()">
    <mat-icon>add</mat-icon>
    Add Petal
  </button>

  <mat-table [dataSource]="matTableDataSource$ | async">
    <ng-container matColumnDef="order">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.order }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef>Label</mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.label }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef>Color</mat-header-cell>
      <mat-cell *matCellDef="let item">
        <span [style]="'color: ' + item.color">{{ item.color }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="score">
      <mat-header-cell *matHeaderCellDef>Score by Superscale</mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ displaySuperscaleFn(item.superscaleId) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        <button mat-button class="btn-view" (click)="onEditPetal(item)">Edit</button>
        <button mat-button class="btn-delete" (click)="onDeletePetal(item)">Delete</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
  </mat-table>
</form>
