import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

// components
import { SubsectionFormComponent } from '@app/core/models/subsection-form-component';
import { EditDoubleRangeDialogComponent } from '@app/admin/pages/report-wizard/edit-double-range-dialog/edit-double-range-dialog.component';

// services
import { SuperscaleService } from '@app/core/services/superscale.service';
import { SubscaleService } from '@app/core/services/subcale.service';

// models
import { Superscale } from '@app/core/models/superscale.model';
import { Subscale } from '@app/core/models/subscale.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

type FormSubscale = Subscale & { weight: number };

@Component({
  selector: 'app-section-all-combination-paragraph-form',
  templateUrl: './section-all-combination-paragraph-form.component.html',
  styleUrls: ['./section-all-combination-paragraph-form.component.scss'],
})
export class SectionAllCombinationParagraphFormComponent implements OnInit, SubsectionFormComponent {
  @Input() model: any;

  superscales: Superscale[] = [];
  subscales: Subscale[] = [];
  subscaleFieldUpdate = new Subject<string>();
  subscaleField: string;
  superscaleFieldUpdate = new Subject<string>();
  superscaleField: string;
  addedSuperscales: { LEFT: Superscale[]; RIGHT: Superscale[] } = {
    LEFT: [],
    RIGHT: [],
  };

  addedSubscales: { LEFT: FormSubscale[]; RIGHT: FormSubscale[] } = {
    LEFT: [],
    RIGHT: [],
  };

  editorConfig: AngularEditorConfig = {
    height: '5rem',
    editable: true,
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  form: FormGroup;

  addedDoubleRanges: {
    from1: number;
    to1: number;
    from2: number;
    to2: number;
    text: {
      he: string;
      she: string;
      they: string;
    };
  }[] = [];

  constructor(
    private fb: FormBuilder,
    private superscaleService: SuperscaleService,
    private subscaleService: SubscaleService,
    private dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      heading: ['', [Validators.required]],
      positiveHeading: [''],
      concernHeading: [''],
      doubleRangesDefaultSentence: [''],
      defaultSentenceEnabled: [false],
      leadSentenceOption: ['he', [Validators.required]],
      leadSentence: this.fb.group({
        he: ['', [Validators.required]],
        she: [''],
        they: [''],
      }),
      useSubscale: [true, [Validators.required]],
      subscale: this.fb.group({
        subscaleId: ['', [Validators.required]],
        weight: [0, [Validators.required]],
        side: ['LEFT', [Validators.required]],
      }),
      superscale: this.fb.group({
        superscaleId: ['', [Validators.required]],
        side: ['LEFT', [Validators.required]],
      }),
      doubleRange: this.fb.group({
        from1: [0, [Validators.required]],
        to1: [0, [Validators.required]],
        from2: [0, [Validators.required]],
        to2: [0, [Validators.required]],
        sentence: this.fb.group({
          option: ['he', [Validators.required]],
          text: this.fb.group({
            he: ['', [Validators.required]],
            she: [''],
            they: [''],
          }),
        }),
      }),
    });

    this.subscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.subscaleField = value;
      }
    });

    this.superscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.superscaleField = value;
      }
    });
  }

  ngOnInit(): void {
    if (this.model) {
      this.loadFormData();
    }
    forkJoin({
      superscales: this.superscaleService.getAll(),
      subscales: this.subscaleService.getAll(),
    }).subscribe(({ subscales, superscales }) => {
      this.superscales = superscales;
      this.subscales = subscales.map((subscale) => {
        if (subscale.cardsortSubscale === 1) {
          return { ...subscale, title: `${subscale.title} (card-sort)` };
        }
        return subscale;
      });
    });
  }

  get useSubscale() {
    return this.form.value.useSubscale;
  }

  displaySubscale(subscale: Subscale): string {
    return subscale ? subscale.title : '';
  }

  displaySuperscale(superscale: Superscale): string {
    return superscale ? superscale.title : '';
  }

  onAddSubscale() {
    const subscaleForm = this.form.get('subscale');
    const { weight, side } = subscaleForm.value;
    const { id: subscaleId } = subscaleForm.value.subscaleId;

    const subscale = this.subscales.find(({ id }) => id === subscaleId);
    this.addedSubscales[side].push({ ...subscale, weight });

    subscaleForm.patchValue({
      subscaleId: '',
      weight: 0,
    });
  }

  onAddSuperscale() {
    const superscaleForm = this.form.get('superscale');
    const { side } = superscaleForm.value;
    const { id: superscaleId } = superscaleForm.value.superscaleId;

    const superscale = this.superscales.find(({ id }) => id === superscaleId);
    this.addedSuperscales[side].push(superscale);

    superscaleForm.patchValue({
      superscaleId: '',
    });
  }

  getSubsection() {
    const {
      heading,
      leadSentence,
      useSubscale,
      positiveHeading,
      concernHeading,
      doubleRangesDefaultSentence,
      defaultSentenceEnabled,
    } = this.form.value;
    const { he, she, they } = leadSentence;

    const subsection: any = {
      id: this.model?.id,
      heading,
      positiveHeading,
      concernHeading,
      useSubscale,
      leadSentence: { he, she, they },
      doubleRangesDefaultSentence,
      defaultSentenceEnabled,
      doubleRanges: this.addedDoubleRanges.map((doubleRange) => ({
        from1: doubleRange.from1,
        to1: doubleRange.to1,
        from2: doubleRange.from2,
        to2: doubleRange.to2,
        text: {
          he: doubleRange.text.he,
          she: doubleRange.text.she,
          they: doubleRange.text.they,
        },
      })),
    };

    if (useSubscale) {
      subsection.subscales = {
        left: this.addedSubscales.LEFT.map((subscale) => ({ id: subscale.id, weight: subscale.weight })),
        right: this.addedSubscales.RIGHT.map((subscale) => ({ id: subscale.id, weight: subscale.weight })),
      };
    } else {
      subsection.superscales = {
        left: this.addedSuperscales.LEFT.map((superscale) => ({ id: superscale.id })),
        right: this.addedSuperscales.RIGHT.map((superscale) => ({ id: superscale.id })),
      };
    }

    return subsection;
  }

  onDeleteSubscale(side: 'LEFT' | 'RIGHT', subscaleId: number) {
    this.addedSubscales[side] = this.addedSubscales[side].filter((subscale) => subscale.id !== subscaleId);
  }

  onDeleteSuperscale(side: 'LEFT' | 'RIGHT', superscaleId: number) {
    this.addedSuperscales[side] = this.addedSuperscales[side].filter((superscale) => superscale.id !== superscaleId);
  }

  addDoubleRange() {
    const { value } = this.form.get('doubleRange');
    this.addedDoubleRanges.push({
      from1: value.from1,
      to1: value.to1,
      from2: value.from2,
      to2: value.to2,
      text: {
        he: value.sentence.text.he,
        she: value.sentence.text.she,
        they: value.sentence.text.they,
      },
    });

    this.form.get('doubleRange').patchValue({
      from1: value.to1,
      to1: value.to1 + 1,
      from2: value.to2,
      to2: value.to2 + 1,
      sentence: {
        option: 'he',
        text: {
          he: '',
          she: '',
          they: '',
        },
      },
    });
  }

  removeDoubleRange(idx: number) {
    this.addedDoubleRanges = this.addedDoubleRanges.filter((_, doubleRangeIdx) => doubleRangeIdx !== idx);
  }

  onEditDoubleRange(idx: number) {
    const data = this.addedDoubleRanges.filter((_, rangeIdx) => idx === rangeIdx)[0];
    this.dialog
      .open(EditDoubleRangeDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event === EVENT_SUCCESS) {
          if (result.range) {
            this.addedDoubleRanges[idx] = result.range;
            this.addedDoubleRanges[idx].text = result.range.reportText;
          }
        }
      });
  }

  private loadFormData(): void {
    // Heading
    this.form.get('heading').setValue(this.model.heading);
    this.form.get('positiveHeading').setValue(this.model.positiveHeading);
    this.form.get('concernHeading').setValue(this.model.concernHeading);
    this.form.get('doubleRangesDefaultSentence').setValue(this.model.doubleRangesDefaultSentence);
    this.form.get('defaultSentenceEnabled').setValue(this.model.defaultSentenceEnabled === 0 ? false : true);
    // UseSubscale
    this.form.get('useSubscale').setValue(this.model.useSubscale);
    // Lead sentence
    const modelLeadSentence = this.model.leadSentence;
    const formLeadSentence = this.form.get('leadSentence');
    Object.keys(modelLeadSentence).forEach((pronoun: string) => {
      if (pronoun !== 'id') {
        if (modelLeadSentence[pronoun]) {
          this.form.get('leadSentenceOption').setValue(pronoun);
          formLeadSentence.get(pronoun).setValue(modelLeadSentence[pronoun] !== '' ? modelLeadSentence[pronoun] : '');
        }
      }
    });
    // Subscales
    if (this.model.subscales) {
      if ('left' in this.model.subscales) {
        this.model.subscales.left = this.model.subscales.left.filter((subscale) => subscale.title !== null);
        if (this.model.subscales.left) {
          this.addedSubscales.LEFT = [...this.addedSubscales.LEFT, ...this.model.subscales.left];
        }
      }
      if ('right' in this.model.subscales) {
        this.model.subscales.right = this.model.subscales.right.filter((subscale) => subscale.title !== null);
        if (this.model.subscales.right) {
          this.addedSubscales.RIGHT = [...this.addedSubscales.RIGHT, ...this.model.subscales.right];
        }
      }
    }
    // Superscales
    if (this.model.superscales) {
      if ('left' in this.model.superscales) {
        this.model.superscales.left = this.model.superscales.left.filter((superscale) => superscale.id !== null);
        if (this.model.superscales.left) {
          this.addedSuperscales.LEFT = [...this.addedSuperscales.LEFT, ...this.model.superscales.left];
        }
      }
      if ('right' in this.model.superscales) {
        this.model.superscales.right = this.model.superscales.right.filter((superscale) => superscale.id !== null);
        if (this.model.superscales.right) {
          this.addedSuperscales.RIGHT = [...this.addedSuperscales.RIGHT, ...this.model.superscales.right];
        }
      }
    }
    // Double ranges
    if (this.model.doubleRanges) {
      this.addedDoubleRanges = [...this.addedDoubleRanges, ...this.model.doubleRanges];
    }
  }
}
