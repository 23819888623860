<div>
  <div class="row">
    <div class="col-2">
      <app-total-box [total]="testGroups ? testGroups.length : 0" description="Total Test Groups" color="blue">
      </app-total-box>
    </div>
    <div class="col-10">
      <app-line-chart [chartData]="lineChartData" [chartLabels]="lineChartLabels" [label]="'Test Groups'">
      </app-line-chart>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.title }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10]"></mat-paginator>
  </div>
</div>
