import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

import { NgxPaginationModule } from 'ngx-pagination';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgProgressModule } from 'ngx-progressbar';

import { SharedModule } from '@app/shared/shared.module';
import { UserRoutingModule } from './user-routing.module';

import { TestComponent } from './test/test.component';
import { UserRegistrationByInviteComponent } from './user-registration-by-invite/user-registration-by-invite.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { MFARequiredModalComponent } from './mfa-required-modal/mfa-required-modal.component';
import { DashboardUserComponent } from './dashboard/dashboard.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserRecoverPasswordComponent } from './user-recover-password/user-recover-password.component';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { ProfileComponent } from './profile/profile.component';
import { MfaEnrollModalComponent } from './mfa-enroll-modal/mfa-enroll-modal.component';
import { UserReportComponent } from './user-report/user-report.component';
import { TestConnectionsIndicatorInstructionsComponent } from './connections-indicator/test-connections-indicator-instructions/test-connections-indicator-instructions.component';
import { TestConnectionsIndicatorUserInfoComponent } from './connections-indicator/test-connections-indicator-user-info/test-connections-indicator-user-info.component';
import { TestConnectionsIndicatorUserListComponent } from './connections-indicator/test-connections-indicator-user-list/test-connections-indicator-user-list.component';
import { TestConnectionsIndicatorUserResultComponent } from './connections-indicator/test-connections-indicator-user-result/test-connections-indicator-user-result.component';
import { TestConnectionsIndicatorComponent } from './connections-indicator/test-connections-indicator/test-connections-indicator.component';
import { TestReviewComponent } from './connections-indicator/test-review/test-review.component';
import { TestRoleMandateInstructionsComponent } from './role-mandate/test-role-mandate-instructions/test-role-mandate-instructions.component';
import { TestRoleMandateReviewComponent } from './role-mandate/test-role-mandate-review/test-role-mandate-review.component';
import { TestRoleMandateUserInfoComponent } from './role-mandate/test-role-mandate-user-info/test-role-mandate-user-info.component';
import { TestRoleMandateUserListComponent } from './role-mandate/test-role-mandate-user-list/test-role-mandate-user-list.component';
import { TestRoleMandateComponent } from './role-mandate/test-role-mandate/test-role-mandate.component';
import { TestInstructionsComponent } from './test-instructions/test-instructions.component';
import { RedirectDialogComponent } from './test-list/redirect-dialog/redirect-dialog.component';
import { TestListComponent } from './test-list/test-list.component';
import { TestViewComponent } from './test-view/test-view.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { UserReportPdfComponent } from './user-report/user-report-pdf/user-report-pdf.component';
import { UserReportPdfWithCoverComponent } from './user-report/user-report-pdf-with-cover/user-report-pdf-with-cover.component';
import { ReportVersioningComponent } from './user-report/report-versioning/report-versioning.component';

@NgModule({
  declarations: [
    UserLoginComponent,
    MFARequiredModalComponent,
    DashboardUserComponent,
    UserCreateComponent,
    UserRecoverPasswordComponent,
    MfaEnrollModalComponent,
    UserResetPasswordComponent,
    ProfileComponent,
    UserRegistrationByInviteComponent,
    UserReportComponent,
    TestListComponent,
    TestConnectionsIndicatorComponent,
    TestInstructionsComponent,
    TestConnectionsIndicatorInstructionsComponent,
    TestConnectionsIndicatorUserInfoComponent,
    TestReviewComponent,
    TestRoleMandateUserInfoComponent,
    TestRoleMandateComponent,
    TestRoleMandateReviewComponent,
    TestRoleMandateInstructionsComponent,
    TestConnectionsIndicatorUserListComponent,
    TestConnectionsIndicatorUserResultComponent,
    RedirectDialogComponent,
    TestViewComponent,
    TestRoleMandateUserListComponent,
    TestComponent,
    UserReportPdfComponent,
    UserReportPdfWithCoverComponent,
    ReportVersioningComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatPasswordStrengthModule,
    SharedModule,
    UserRoutingModule,
    NgxPaginationModule,
    AngularEditorModule,
    NgProgressModule,
    NgxSliderModule,
  ],
  exports: [
    UserLoginComponent,
    TestListComponent,
    TestInstructionsComponent,
    TestConnectionsIndicatorInstructionsComponent,
    TestConnectionsIndicatorUserInfoComponent,
    TestConnectionsIndicatorUserListComponent,
    TestReviewComponent,
    TestRoleMandateReviewComponent,
    TestConnectionsIndicatorUserResultComponent,
    TestViewComponent,
    TestRoleMandateUserListComponent,
  ],
})
export class UserModule {}
