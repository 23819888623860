<div lass="row" style="margin: 10px 0px">
  <div class="column">
    <span>{{ question.text }}</span>
    <div class="row">
      <span class="title">{{ question.text }} | </span>
      <span class="network-type">{{ question.networkType }} </span>
    </div>

    <button mat-icon-button aria-label="Refresh" (click)="refreshQuestion(question.id)">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>
<div class="row" style="margin: 10px 0px">
  <div class="column">
    <span class="row title">{{ question.text }}</span>
    <span class="row instructions">{{ question.questionGroup.title }}</span>
  </div>

  <div class="column">
    <button mat-button aria-label="Refresh" class="pull-right" (click)="refreshQuestion(question.id)">
      <mat-icon>refresh</mat-icon> Refresh Centrality
    </button>
  </div>
</div>

<div class="row">
  <div class="column" style="margin-top: 0px">
    <h2>Centrality</h2>
    <app-centrality-table *ngIf="analysis" [dataSource]="analysis"> </app-centrality-table>
  </div>

  <div class="column">
    <h2>Network</h2>
    <app-user-network
      [nodesData]="nodes"
      [type]="'question'"
      [id]="question.id"
      [roleMandateId]="roleMandateId"
      *ngIf="nodes"
    ></app-user-network>
  </div>
</div>
