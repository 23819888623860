import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';

// models
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-board-users',
  templateUrl: './board-users.component.html',
  styleUrls: ['./board-users.component.scss'],
})
export class BoardUsersComponent implements OnInit {
  @Input() users: UserInfo[];
  @Output() openAnswers = new EventEmitter<number>();
  usersComplete: MatTableDataSource<UserInfo>;
  filter = new FormControl('');

  @ViewChild('sortUsers') sortUsers: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor() {}

  displayedColumns: string[] = ['name', 'role-title', 'status', 'date-completed', 'actions'];

  ngOnInit(): void {
    this.usersComplete = new MatTableDataSource(this.users);
    this.usersComplete.paginator = this.paginator;
    this.usersComplete.sort = this.sortUsers;
  }

  onOpenUser(userInfo: UserInfo) {
    this.openAnswers.emit(userInfo.id);
  }

  applyFilterUsers(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.usersComplete.filter = filterValue.trim().toLowerCase();

    if (this.usersComplete.paginator) {
      this.usersComplete.paginator.firstPage();
    }
  }
}
