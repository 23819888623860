/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ViolinChartController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param userId
     * @param testGroupId
     * @param sectionId
     * @returns string
     * @throws ApiError
     */
    public violinChartControllerViolinChartPng(
        userId: number,
        testGroupId: number,
        sectionId: number,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/user/{userId}/testGroup/{testGroupId}/sectionId/{sectionId}',
            path: {
                'userId': userId,
                'testGroupId': testGroupId,
                'sectionId': sectionId,
            },
        });
    }

}
