import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';

@Component({
  selector: 'app-test-connections-indicator-instructions',
  templateUrl: './test-connections-indicator-instructions.component.html',
  styleUrls: ['./test-connections-indicator-instructions.component.scss'],
})
export class TestConnectionsIndicatorInstructionsComponent implements OnInit {
  userInfoId: number;
  connectionsIndicatorId: number;
  title: string;
  instructions: string;
  constructor(private router: Router, private route: ActivatedRoute, private cIService: ConnectionsIndicatorService) {}

  ngOnInit(): void {
    this.userInfoId = parseInt(localStorage.getItem('userInfoId'), 10);
    this.connectionsIndicatorId = this.route.snapshot.params['id'];

    this.cIService.getById(this.connectionsIndicatorId).subscribe((data) => {
      this.title = data.title;
      this.instructions = data.instructions;
    });
  }

  onStart() {
    this.router.navigate([`/user/connections-indicator/${this.connectionsIndicatorId}/user-info`]);
  }
}
