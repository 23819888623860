<div *ngIf="state" class="container state-detail">
  <h1>State: {{ stateId }}</h1>
  <mat-divider></mat-divider>
  <h4>Abbrev</h4>
  <p>{{ state.abbrev }}</p>
  <h4>Name</h4>
  <p>{{ state.name }}</p>
  <h4>Country</h4>
  <p>{{ state.countryId }}</p>
  <button mat-raised-button color="primary" type="button" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>Back
  </button>
  <button mat-raised-button color="warn" type="button" (click)="onEdit()"><mat-icon>edit</mat-icon>Edit</button>
</div>
