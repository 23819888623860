import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Invite } from '../models/invite.model';
import { PsyEmail } from '../models/psy-email.model';
import { EmailSet } from '../models/email-set.model';

const BACKEND_URL = environment.apiUrl;
const FILE_SERVE_API_URL = environment.fileServeApiUrl;

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient) {}

  findAllByRoleMandateId(roleMandateId: number) {
    return this.http.get<EmailSet>(BACKEND_URL + '/psy-email/role-mandate/' + roleMandateId);
  }

  findAllByConnectionIndicatorId(ciId: number) {
    return this.http.get<EmailSet>(BACKEND_URL + '/psy-email/connection-indicator/' + ciId);
  }

  findAllByTestGroupId(testGroupId: number) {
    return this.http.get<EmailSet>(BACKEND_URL + '/psy-email/test-group/' + testGroupId);
  }

  update(templateEmail: any) {
    return this.http.put<EmailSet>(BACKEND_URL + '/psy-email/' + templateEmail.id, templateEmail);
  }

  save(templateEmail: any) {
    return this.http.post<EmailSet>(BACKEND_URL + '/psy-email', templateEmail);
  }

  replaceImgSrc(message: string, companyId: number) {
    const newSrc = `${FILE_SERVE_API_URL}/company-logo?id=${companyId}`;
    const replacementString = `<img src="${newSrc}" width="150" height="auto">`;
    return message.replace(/<img.+?>/i, replacementString);
  }

  resizeImage(htmlString: string) {
    return htmlString.replace(
      /<img([^>]*)style="([^"]*)"([^>]*)>/gi,
      '<img$1style="$2 width: 150px; height: auto;"$3>',
    );
  }

  findAllSources() {
    return this.http.get<string[]>(`${BACKEND_URL}/psy-email/sources`);
  }
}
