import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { CreateNewAreaDialogComponent } from './../create-new-area-dialog/create-new-area-dialog.component';

// services
import { AreaIndexService } from '@app/core/services/area-index.service';

// model
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-area-mappings',
  templateUrl: './area-mappings.component.html',
  styleUrls: ['./area-mappings.component.scss'],
})
export class AreaMappingsComponent implements OnInit {
  areaPsbItems: PsbItem[] = [];

  constructor(private areaIndexService: AreaIndexService, private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.areaIndexService.getAll().subscribe((areas) => {
      this.areaPsbItems = areas.map((area) => ({
        id: area.id,
        title: area.name,
      }));
    });
  }

  onDashboard(areaId: number) {
    return this.router.navigate(['/area-index', areaId]);
  }

  onDelete(areaId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.areaIndexService.remove(areaId).subscribe(() => {
          this.areaPsbItems = this.areaPsbItems.filter((area) => area.id !== areaId);
        });
      });
  }

  onCreateNewArea() {
    this.dialog
      .open(CreateNewAreaDialogComponent, { data: { isEdit: false } })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          this.areaIndexService
            .save(result.data)
            .subscribe((newAreaIndex) => this.router.navigate(['/area-index', newAreaIndex.id]));
        }
      });
  }
}
