import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

// components
import { EditTestGroupUserModalComponent } from '../../../../../shared/components/edit-test-group-user-modal/edit-test-group-user-modal.component';
import { PsyOptionsDialogComponent } from '@app/shared/components/psy-options-dialog/psy-options-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { MoveUsersFromOtherTestGroupsComponent } from '@app/admin/pages/test-group/test-group-details/test-group-user-list-table/move-users-from-other-test-groups/move-users-from-other-test-groups.component';

// services
import { CompanyService } from '@app/core/services/company.service';
import { TestGroupService } from '@app/core/services/test-group.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { UserService } from '@app/core/services/user.service';

// models
import { UserInfo } from '@app/core/models/userInfo.model';
import { PsbOptionItem } from '@app/core/models/psb-option-item.model';
import { Report } from '@app/core/models/report.model';

// constants
import { EVENT_CANCEL, EVENT_SUCCESS, ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TGM } from '@app/core/constants';
import { PsynetAddNewUserComponent } from '../../../../../shared/components/psynet-add-new-user/psynet-add-new-user.component';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { UserCreateDialogComponent } from '@app/shared/components/user-create-dialog/user-create-dialog.component';
import { AddUsersFromOtherTestGroupsComponent } from '@app/admin/pages/test-group/test-group-details/test-group-user-list-table/add-users-from-other-test-groups/add-users-from-other-test-groups.component';

@Component({
  selector: 'app-test-group-user-list-table',
  templateUrl: './test-group-user-list-table.component.html',
  styleUrls: ['./test-group-user-list-table.component.scss'],
})
export class TestGroupUserListTableComponent implements OnInit {
  @Input() testGroupId: number;
  @Input() companyId: number;

  @ViewChild(MatPaginator, { static: true }) paginatorPsyTestGroupUsers: MatPaginator;

  testGroupUsers: MatTableDataSource<UserInfo> = new MatTableDataSource<UserInfo>();
  allTestGroupUsers = [];
  allTestGroupUserInfos = [];
  allCompletedTests = [];
  items: PsbOptionItem[] = [];
  reports: Report[] = [];
  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';
  isAdmin = false;
  isTGM = false;
  isOA = false;
  allowRawScoresAccess = false;

  searchInTestGroups: PsyTestGroup[] = [];

  displayedTestGroupUsersColumns: string[] = ['name', 'userType', 'autoSendPdf', 'status', 'actions'];

  constructor(
    private testGroupService: TestGroupService,
    private userService: UserService,
    private companyService: CompanyService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {
    const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
    if (authorities.includes(ROLE_ADMIN)) {
      this.isAdmin = true;
    } else if (authorities.includes(ROLE_ORGANIZATIONAL_ADMIN)) {
      this.isOA = true;
    } else if (authorities.includes(ROLE_TGM)) {
      this.isTGM = true;
    }

    this.testGroupService.reportModelUpdate$.subscribe(() => {
      this.getTestGroupUserListTable();
    });
  }

  ngOnInit(): void {
    if (this.testGroupId) {
      this.getTestGroupUserListTable();
    }

    if (this.companyId) {
      this.companyService.getById(this.companyId).subscribe((company) => {
        this.allowRawScoresAccess = +company.allowRawScoresAccess === 1 ? true : false;
      });

      this.testGroupService.getAllTestGroupUser().subscribe((response) => {
        let allTestGroupUsers = [];
        response.forEach((data) => {
          const testGroupUser = {
            id: data.id,
            dateAssigned: data.dateAssigned,
            userType: data.userType,
            name: data.name,
            userInfoId: data.userInfoId,
            status: data.status ? data.status : 0,
            testGroupId: data.testGroupId,
            completedPsyTests: data.completedPsyTests,
            completedSuperTests: data.completedSuperTests,
            reportModel: data.reportModel,
            privateScores: data.privateScores ? data.privateScores : false,
            progress: data.progress ? data.progress : null,
            autoSendPdf: !!data.autoSendPdf,
          };
          allTestGroupUsers.push(testGroupUser);
        });

        if (allTestGroupUsers && allTestGroupUsers.length > 0) {
          this.getUsersByCompanyId(allTestGroupUsers);
        }
      });
    }

    this.getTransferredTestGroups();
  }

  onEdit(psyTestGroupUser: any) {
    const editTestGroupUserDialog = this.dialog.open(EditTestGroupUserModalComponent, {
      data: { psyTestGroupUserId: psyTestGroupUser.id },
    });

    editTestGroupUserDialog.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.testGroupService.updateTestGroupUser(psyTestGroupUser.id, result.data).subscribe(() => {
          this.getTestGroupUserListTable();
          this.snackBar.info('Test group user was updated with success.');
        });
      }
    });
  }

  addNewTestUser() {
    const addNewTestUserModal = this.dialog.open(PsyOptionsDialogComponent, {
      data: { items: this.items, title: 'Add a new user', formFieldName: 'User' },
    });

    addNewTestUserModal.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.saveTestGroupUser(this.testGroupId, result.data.item.id);
      }
    });
  }

  saveTestGroupUser(testGroupId: number, userId: number) {
    this.testGroupService.saveTestGroupUser(testGroupId, userId).subscribe(
      (response: any) => {
        if (!response.hasOwnProperty('id') && response.hasOwnProperty('message')) {
          this.snackBar.info(response.message);
        } else {
          this.snackBar.info('User added successfully.');
        }
        this.getTestGroupUserListTable();
        this.getUsersByCompanyId();
      },
      (error) => {
        this.snackBar.info('Something went wrong. Please try again later.');
      },
    );
  }

  addNewTestUserFromOtherCompany(): void {
    this.companyService.getAll().subscribe((result) => {
      if (this.companyId && result.length) {
        let otherCompanyInfo = result.filter((item) => item.id != this.companyId);
        this.dialog
          .open(PsynetAddNewUserComponent, {
            data: { items: otherCompanyInfo, title: 'Add Test User From Other Company' },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result.event !== EVENT_CANCEL) {
              this.saveTestGroupUser(this.testGroupId, result.data.user.id);
            }
          });
      }
    });
  }

  getUsersByCompanyId(allTestGroupUsers?: any[]) {
    this.allTestGroupUsers = allTestGroupUsers ? allTestGroupUsers : this.allTestGroupUsers;
    this.items = [];
    this.companyService.getUsersById(this.companyId).subscribe((users) => {
      users.forEach((user) => {
        if (user) {
          const item: PsbOptionItem = {
            id: user.id,
            itemName: user.firstName + ' ' + user.lastName,
          };
          let isTestGroupAssigned = this.allTestGroupUsers.some((e) => e.userInfoId == user.id);
          if (!isTestGroupAssigned) {
            this.items.push(item);
          }
        }
      });
    });
  }

  getTransferredTestGroups() {
    this.testGroupService.getAll().subscribe((transferredTestGroups) => {
      this.searchInTestGroups = transferredTestGroups.filter(({ id }) => id !== this.testGroupId);
    });
  }

  onDelete(psyTestGroupUser: any) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.testGroupService.deleteTestGroupUser(psyTestGroupUser.id).subscribe((res) => {
          if (res) {
            this.getTestGroupUserListTable();
            this.getUsersByCompanyId();
          }
        });
      }
    });
  }

  onSelectReport(psyTestGroupUser: any, reportId: any) {
    window.open('/user/' + psyTestGroupUser.userInfoId + '/test-group/' + this.testGroupId + '/report/' + reportId);
  }

  getTestGroupUserListTable() {
    this.testGroupService.getUsersById(this.testGroupId).subscribe((testGroupUsers: UserInfo[]) => {
      if (testGroupUsers) {
        this.renderTableData(testGroupUsers);

        this.testGroupService.getAllReportById(this.testGroupId).subscribe((results) => {
          this.reports = results;
        });
      }
    });
  }

  renderTableData(response: UserInfo[]) {
    this.allTestGroupUsers = [];
    response.forEach((data) => {
      const testGroupUser = {
        id: data.testGroupUserId,
        dateAssigned: data.dateAssigned,
        userType: data.userType === 'TEST' ? 'Candidate' : 'Manager',
        name: data.name,
        userInfoId: data.id,
        status: data.status ? (+data.status * 100).toFixed(2) : 0,
        testGroupId: data.testGroupId,
        completedPsyTests: data.completedPsyTests,
        completedSuperTests: data.completedSuperTests,
        reportModel: data.reportModelId ? false : true,
        privateScores: +data.privateScores === 0 ? false : true,
        progress: data.progress ? data.progress : null,
        autoSendPdf: !!data.autoSendPdf,
      };

      this.allTestGroupUsers.push(testGroupUser);
      this.allTestGroupUserInfos.push(testGroupUser.userInfoId);
    });

    this.testGroupService.setAllTestGroupUser(this.allTestGroupUsers);

    this.refreshTableData();
  }

  refreshTableData() {
    this.testGroupUsers.data = this.allTestGroupUsers;
    this.testGroupUsers.paginator = this.paginatorPsyTestGroupUsers;
    this.changeDetectorRef.detectChanges();
  }

  onPsyTestView(psyTest: any, user) {
    if (this.isAdmin || (this.isOA && this.allowRawScoresAccess)) {
      return this.router.navigate([
        `user/${user.userInfoId}/test/${psyTest.testId}/psy-test-results/${psyTest.testResultId}/view`,
      ]);
    }
  }

  onSupertestView(supetTest: any) {
    if (this.isAdmin || (this.isOA && this.allowRawScoresAccess)) {
      return this.router.navigate([
        `user/test/${supetTest.testId}/psy-super-test-results/${supetTest.testResultId}/view`,
      ]);
    }
  }

  updateUsersScores() {
    this.userService.updateUserScores(this.allTestGroupUserInfos, this.testGroupId).subscribe((res) => {
      if (res) {
        this.snackBar.info('All user scores updated successfully');
      }
    });
  }

  moveUserFromOtherTestGroup() {
    const modal = this.dialog.open(MoveUsersFromOtherTestGroupsComponent, {
      data: {
        testGroupUsers: this.allTestGroupUsers,
        transferredTestGroups: this.searchInTestGroups,
        originalTestGroupId: this.testGroupId,
      },
    });

    modal.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.getTestGroupUserListTable();
        this.snackBar.info('Successfully transferred user and his results from other test group.');
      }
    });
  }

  addUserFromOtherTestGroup() {
    const modal = this.dialog.open(AddUsersFromOtherTestGroupsComponent, {
      data: {
        testGroupUsers: this.allTestGroupUsers,
        transferredTestGroups: this.searchInTestGroups,
        originalTestGroupId: this.testGroupId,
      },
    });

    modal.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.getTestGroupUserListTable();
        this.snackBar.info('Successfully copied user user and his results from other test group.');
      } else {
        console.log('resultresultresult1', result);
        // this.snackBar.info('Errrr.');
      }
    });
  }

  onCreateUser() {
    const createUserDialog = this.dialog.open(UserCreateDialogComponent, {
      height: '90vh',
      width: '55vw',
      data: { companyId: this.companyId, testGroupId: this.testGroupId },
    });

    createUserDialog.afterClosed().subscribe((result) => {
      if (result && result.event === EVENT_SUCCESS) {
        this.snackBar.info('User created with success.');
        this.getTestGroupUserListTable();
      }
    });
  }

  getUserProgress(progress: any) {
    let toolTip = '';
    if (progress) {
      for (const key in progress) {
        if (Object.prototype.hasOwnProperty.call(progress, key)) {
          const value = progress[key];
          toolTip += `${key}: ${value} \n`;
        }
      }
    }
    return toolTip;
  }
}
