/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyTestGroupUser } from '../models/PsyTestGroupUser';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupUserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a list of test group users by id
     * Retrieve a list of test group users by test group id
     * @param id Id of the test group
     * @returns PsyTestGroupUser A test group user
     * @throws ApiError
     */
    public getTestGroupUser(
        id: number,
    ): Observable<PsyTestGroupUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/user',
            path: {
                'id': id,
            },
            errors: {
                404: `PsyTestGroupUser not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update a test group user
     * Update a test group user
     * @param testGroupUserId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchTestGroupPsyTestGroupUser(
        testGroupUserId: number,
        requestBody?: {
            /**
             * Date Assigned of the test group user
             */
            dateAssigned?: string;
            /**
             * User type of the test group user
             */
            userType?: string;
            /**
             * Id of the user info
             */
            userId?: number;
            /**
             * Id of the test group
             */
            testGroupId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/test-group/psy-test-group-user/{testGroupUserId}',
            path: {
                'testGroupUserId': testGroupUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `PsyTestGroupUser not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a test group user by testGroupUserId
     * Retrieve a test group user by testGroupUserId
     * @param testGroupUserId Id of the Test Group User
     * @returns PsyTestGroupUser A test group user
     * @throws ApiError
     */
    public getTestGroupPsyTestGroupUser(
        testGroupUserId: number,
    ): Observable<PsyTestGroupUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/psy-test-group-user/{testGroupUserId}',
            path: {
                'testGroupUserId': testGroupUserId,
            },
            errors: {
                404: `PsyTestGroupUser not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a from test group user
     * Remove a test group user
     * @param testGroupUserId Id of the test group user
     * @returns any TestGroupUser deleted from test group successfully
     * @throws ApiError
     */
    public deleteTestGroupPsyTestGroupUser(
        testGroupUserId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/psy-test-group-user/{testGroupUserId}',
            path: {
                'testGroupUserId': testGroupUserId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a psyTestGroupUser
     * Create a psyTestGroupUser
     * @param testGroupId Id of the test group
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupPsyTestGroupUser(
        testGroupId: number,
        requestBody?: {
            /**
             * Date Assigned of the test group user
             */
            dateAssigned?: string;
            /**
             * User type of the test group user
             */
            userType?: string;
            /**
             * Id of the user info
             */
            userId?: number;
            /**
             * Id of the test group
             */
            testGroupId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{testGroupId}/psy-test-group-user',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all Test Users by test group id and test group test id
     * Retrieve all Test Users by test group id and test group test id
     * @param id Id of the test group
     * @param psyTestGroupTestId Id of the test group test
     * @returns PsyTestGroupUser A list of test groups
     * @throws ApiError
     */
    public getTestGroupPsyTestGroupAnswerTestUsers(
        id: number,
        psyTestGroupTestId: number,
    ): Observable<PsyTestGroupUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/psy-test-group-answer-test-users/{psyTestGroupTestId}',
            path: {
                'id': id,
                'psyTestGroupTestId': psyTestGroupTestId,
            },
            errors: {
                404: `a list of test users not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve the test group users by id
     * Retrieve the test group users by id
     * @param id Id of the test group
     * @returns PsyTestGroupUser A list of test group user by test group id
     * @throws ApiError
     */
    public getTestGroupPsyTestGroupUsers(
        id: number,
    ): Observable<PsyTestGroupUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/psy-test-group-users/{userInfoId}',
            path: {
                'id': id,
            },
            errors: {
                404: `a test group user not found`,
                500: `Internal error`,
            },
        });
    }

}
