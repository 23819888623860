<div class="container">
  <app-psb-page-navigator [pages]="pages"></app-psb-page-navigator>

  <h2>Question Groups</h2>
  <p>All the questions that belong to the same Question Group will be showed on the same screen.</p>
  <div *ngFor="let questionGroup of questionGroups" class="question-group">
    <app-question-group [questionGroup]="questionGroup" (delete)="onDeleteQuestionGroup($event)"></app-question-group>
  </div>

  <form
    *ngIf="creatingNewQuestionGroup"
    #newQuestionGroupFormRef="ngForm"
    [formGroup]="newQuestionGroupForm"
    (ngSubmit)="saveQuestionGroup()"
  >
    <mat-card>
      <h3>New Question Group</h3>
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title" />
        <mat-error *ngIf="newQuestionGroupForm.get('title').errors">Please, enter a valid text.</mat-error>
      </mat-form-field>

      <angular-editor type="text" formControlName="instructions" [config]="editorConfig"></angular-editor>
      <mat-error *ngIf="newQuestionGroupForm.get('instructions').errors">Please, enter a valid text.</mat-error>
      <mat-hint>The instructions is a optional introdutory text to the questions on the question group.</mat-hint>

      <div class="row center mt-3">
        <button mat-button type="submit" mat-raised-button>Save</button>
        <button mat-raised-button (click)="onCancelQuestionGroup(newQuestionGroupFormRef)">Cancel</button>
      </div>
    </mat-card>
  </form>

  <button
    mat-raised-button
    class="btn-success btn-center btn-300"
    (click)="createNewQuestionGroup()"
    *ngIf="!creatingNewQuestionGroup"
  >
    <mat-icon>add</mat-icon>New Question Group
  </button>

  <mat-divider></mat-divider>
  <h2>Questions</h2>

  <div *ngFor="let question of getSavedQuestions(); let i = index">
    <app-question
      [question]="question"
      [questions]="getSavedCardsortQuestions()"
      [showAcl]="true"
      (delete)="onDeleteQuestion($event)"
      [questionGroups]="questionGroups"
      (edit)="onEditQuestion($event)"
    >
    </app-question>
  </div>

  <form *ngIf="creatingNewQuestion" [formGroup]="newQuestionForm">
    <mat-card>
      <h3>New Question</h3>
      <p>To create a new question enter the text, select the type, add the answers if needed and click on save.</p>

      <mat-form-field>
        <mat-label>Text</mat-label>
        <input matInput type="text" formControlName="text" />
        <mat-error *ngIf="newQuestionForm.get('text').errors">Please, enter a valid text.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput type="number" formControlName="weight" />
        <mat-error *ngIf="newQuestionForm.get('weight').errors">Please, enter a valid weight.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Question Type</mat-label>
        <mat-select formControlName="questionType">
          <mat-option value="TEXT_AREA">Text Area</mat-option>
          <mat-option value="RICH_TEXT">Rich Text</mat-option>
          <mat-option value="LIST_TEXT">List Text</mat-option>
          <mat-option value="USER_CONNECTION">User Connection</mat-option>
          <mat-option value="CARDSORT">Cardsort</mat-option>
        </mat-select>
        <mat-error *ngIf="newQuestionForm.get('questionType').errors">Please, select a valid question type.</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="questionGroups.length">
        <mat-label>Question Group</mat-label>
        <mat-select formControlName="questionGroupId">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let questionGroup of questionGroups" value="{{ questionGroup.id }}">
            {{ questionGroup.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div [ngSwitch]="newQuestionForm.get('questionType').value">
        <div *ngIf="answerSubscalesLoaded | async">
          <mat-slide-toggle
            *ngSwitchCase="'CARDSORT'"
            class="toggle-margin"
            color="primary"
            formControlName="showDiscarded"
          >
            Show list of cards discarded
          </mat-slide-toggle>
          <app-cardsort
            *ngSwitchCase="'CARDSORT'"
            [answerSubscales]="answerSubscales"
            [answers]="answers"
            [allowSubscale]="false"
            [allowWeight]="false"
            mode="insert"
            (add)="onAddAnswer($event)"
          ></app-cardsort>
        </div>

        <mat-form-field *ngSwitchCase="'LIST_TEXT'">
          <mat-label>Cardsort Related</mat-label>
          <mat-select formControlName="relatedQuestionId">
            <mat-option *ngFor="let question of getSavedCardsortQuestions()" value="{{ question.id }}">
              {{ question.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row center">
        <button mat-raised-button type="submit" (click)="saveQuestion()">Save</button>
        <button mat-raised-button (click)="cancelCreateQuestion()">Cancel</button>
      </div>
    </mat-card>
  </form>

  <button
    mat-raised-button
    class="btn-success btn-center btn-300"
    (click)="createNewQuestion()"
    *ngIf="!creatingNewQuestion"
  >
    <mat-icon>add</mat-icon>New Question
  </button>
  <button mat-raised-button class="btn-success btn-center btn-300" (click)="onNewDecisionTables()">Next</button>
</div>
