import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../environments/environment';
import { PsyTestGroup } from '../models/psy-test-group.model';
import { UserLogin } from '@app/user/user-login/user-login.model';
import { User } from '../models/user.model';
import { RoleTitle } from '../models/role-title.model';
import { AlternativeTitle } from '../models/alternative-title.model';
import { UserInfo } from '../models/userInfo.model';
import { UserForm } from '../models/user-form.model';
import { UserRecoverPassword } from '@app/user/user-recover-password/user-recover-password.model';
import { UserResetPassword } from '@app/user/user-reset-password/user-reset-password.model';
import { Level } from '../models/level.model';
import { Department } from '../models/department.model';
import { RoleMandatePosition } from '../models/role-mandate-position.model';
import { UserNotification } from '../models/user-notification.model';
import { Score } from '../models/score.model';
import { RoleMandate } from '../models/role-mandate.model';
import { ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TEST_USER, ROLE_TGM } from '../constants';
import { Authority } from '@app/core/models/authority.model';
import { SnackBarService } from './snack-bar.service';
import { SystemSettingsService } from '@app/core/services/system-settings.service';

export type UpdateUserValue = {
  firstName: string;
  lastName: string;
  email: string;
  activated: boolean;
  language: string;
  phone: string;
  stateId: number;
  timeZoneId: number;
  companyId: number;
  gender: string;
};

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private token: string;
  private tokenTimer: any;
  private userInfoId: number;
  private loginError = false;
  private userData: User;
  private userInfoData: UserInfo;
  private expirationDate: Date;

  isAuthenticated = new BehaviorSubject<boolean>(false);
  userNotification = new Subject<boolean>();

  public isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();
  public userNotification$: Observable<boolean> = this.userNotification.asObservable();

  genderMap = [
    ['batman', 'batwoman'],
    ['boy', 'girl'],
    ['boyfriend', 'girlfriend'],
    ['father', 'mother'],
    ['husband', 'wife'],
    ['wife', 'husband'],
    ['he', 'she'],
    ['He', 'She'],
    ['his', 'her'],
    ['His', 'Her'],
    ['him', 'her'],
    ['Him', 'Her'],
    ['himself', 'herself'],
    ['male', 'female'],
    ['man', 'woman'],
    ['Mr', 'Ms'],
    ['sir', 'madam'],
    ['son', 'daughter'],
    ['uncle', 'aunt'],
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private snackBar: SnackBarService,
    private systemSettingsService: SystemSettingsService,
  ) {}

  getToken() {
    return this.token;
  }

  getLoginError() {
    return this.loginError;
  }

  getUserData() {
    return this.userInfoData;
  }

  // TODO: remove using cookie after full migration to new version
  integrate() {
    const authCookie = this.cookieService.get('JWT');
    if (authCookie.length <= 100) {
      return;
    }

    this.http
      .post<{ message: string; token: string; expiresIn: number; userData: User; userInfoId: number }>(
        `${BACKEND_URL}/authentication/integrate`,
        { token: authCookie },
      )
      .subscribe(this.handleLoginResponse, () => {
        this.loginError = true;
        this.isAuthenticated.next(false);
      });
  }

  authenticate(credentials: UserLogin) {
    return this.http
      .post<{ message: string; token: string; expiresIn: number; userData: any; userInfoId: number }>(
        environment.apiUrl + '/login',
        credentials,
      )
      .subscribe(this.handleLoginResponse, () => {
        this.loginError = true;
        this.isAuthenticated.next(false);
      });
  }

  handleLoginResponse = (response: any, isGenericLink?: boolean) => {
    const token = response.token;
    this.token = token;
    this.userInfoId = response.userInfoId;
    this.userData = response.userData;
    if (token) {
      if (this.userInfoId) {
        this.get(this.userInfoId).subscribe((account) => {
          if (!account) {
            this.snackBar.info('User blocked, Please contact administator!');
            return;
          }
          this.userInfoData = account;
          this.setAuthTimer(response.expiresIn);
          this.isAuthenticated.next(true);
          this.expirationDate = new Date(new Date().getTime() + response.expiresIn * 1000);
          this.saveAuthData();

          const authorities = account.authorities?.map((authority: Authority) => authority.name);

          this.systemSettingsService.getMaintenanceMode().subscribe((result) => {
            const maintenanceEnabled = result.maintenanceEnabled === 0 ? false : true;
            if (maintenanceEnabled) {
              if (
                authorities?.includes(ROLE_TEST_USER) ||
                authorities?.includes(ROLE_TGM) ||
                authorities?.includes(ROLE_ORGANIZATIONAL_ADMIN)
              ) {
                return this.router.navigate(['/setting']);
              } else {
                return this.router.navigate(['/admin']);
              }
            } else {
              if (!isGenericLink) {
                if (
                  authorities?.includes(ROLE_ADMIN) ||
                  authorities?.includes(ROLE_TGM) ||
                  authorities?.includes(ROLE_ORGANIZATIONAL_ADMIN)
                ) {
                  return this.router.navigate(['/admin']);
                } else {
                  return this.router.navigate(['/dashboard']);
                }
              } else {
                return this.router.navigate(['/dashboard']);
              }
            }
          });
        });
      }
    }
  };

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  logout() {
    this.token = null;

    this.isAuthenticated.next(false);
    clearTimeout(this.tokenTimer);

    this.clearAuthData();
    this.router.navigate(['/']);
  }

  private saveAuthData() {
    localStorage.setItem('expiration', this.expirationDate.toISOString());
    localStorage.setItem('token', this.token);
    localStorage.setItem('login', this.userData?.login?.toString());
    localStorage.setItem('firstName', this.userData?.firstName?.toString());
    localStorage.setItem('lastName', this.userData?.lastName?.toString());
    localStorage.setItem('email', this.userData?.email?.toString());
    localStorage.setItem('langKey', this.userData?.langKey?.toString());
    localStorage.setItem('userId', this.userInfoData?.userId?.toString());
    localStorage.setItem('userInfoId', this.userInfoData?.id?.toString());
    localStorage.setItem('companyId', this.userInfoData?.companyId?.toString());
    localStorage.setItem('testGroupId', this.userInfoData?.testGroupId?.toString());
    localStorage.setItem('authorities', JSON.stringify(this.userInfoData?.authorities));
  }

  private clearAuthData() {
    localStorage.removeItem('expiration');
    localStorage.removeItem('token');
    localStorage.removeItem('login');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('email');
    localStorage.removeItem('userId');
    localStorage.removeItem('userInfoId');
    localStorage.removeItem('langKey');
    localStorage.removeItem('companyId');
    localStorage.removeItem('testGroupId');
    localStorage.removeItem('authorities');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const userId = +localStorage.getItem('userId');
    if (!token || !expirationDate || !userId) {
      return;
    }

    return {
      token,
      expirationDate,
      userId,
      login: localStorage.getItem('login'),
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),
      email: localStorage.getItem('email'),
      langKey: localStorage.getItem('langKey'),
      userInfoId: +localStorage.getItem('userInfoId'),
      companyId: +localStorage.getItem('companyId'),
      testGroupId: +localStorage.getItem('testGroupId'),
      authorities: JSON.parse(localStorage.getItem('authorities')),
    };
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    const now = new Date();
    const expiresIn = new Date(authInformation?.expirationDate).getTime() - now.getTime();

    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.setAuthTimer(expiresIn / 1000);

      this.userInfoData = {
        id: authInformation.userInfoId,
        userId: authInformation.userId,
        login: authInformation.login,
        firstName: authInformation.firstName,
        lastName: authInformation.lastName,
        email: authInformation.email,
        languageKey: authInformation.langKey,
        companyId: authInformation.companyId,
        testGroupId: authInformation.testGroupId,
        authorities: authInformation.authorities,
      };

      this.isAuthenticated.next(true);
    } else {
      this.integrate();
    }
  }

  isOwner(userInfoId: number) {
    return this.getUserData().id === userInfoId;
  }

  /**
   * Only users part of the company or admin can access Role Mandates
   * @returns boolean
   */
  isAllowedOnRoleMandate(roleMandate: RoleMandate) {
    if (this.isAdmin()) {
      return true;
    }

    return roleMandate.companyId === this.getUserData().companyId;
  }

  /**
   * Boolean for users admin, based on the user logged
   * @returns boolean
   */
  isAdmin() {
    return this.getUserData().authorities.findIndex((value) => value.name === ROLE_ADMIN) > -1;
  }

  isSameCompany(companyId: number) {
    return this.getUserData().companyId === companyId;
  }

  create(userForm: UserForm): Observable<{ message: string; userInfoId: string }> {
    return this.http.post<{ message: string; userInfoId: string }>(`${BACKEND_URL}/user`, userForm).pipe(take(1));
  }

  createByInvite(userForm: UserForm, inviteToken: string): Observable<any> {
    return this.http.post<any>(`${BACKEND_URL}/user/invite/${inviteToken}`, userForm).pipe(take(1));
  }

  getPronoun(user: UserInfo) {
    const pronoun = user.gender;
    if (pronoun === null) return 'he';
    if (pronoun === 'MALE') return 'he';
    if (pronoun === 'FEMALE') return 'she';
    if (pronoun === 'OTHER') return 'he';
    if (pronoun === 'PREFER_NOT_TO_SAY') return 'he';
    return 'he';
  }

  getPronounDescription(chart: any, pronoun: string) {
    if (pronoun === 'he') {
      return chart.area && chart.area.he ? this.replaceName(chart.area.he, chart.user) : null;
    }

    if (pronoun === 'she') {
      return chart.area && chart.area.she
        ? this.replaceName(chart.area.she, chart.user)
        : this.convertText(chart.area.he, 'she', chart.user);
    }

    if (pronoun === 'they') {
      return chart.area && chart.area.they
        ? this.replaceName(chart.area.they, chart.user)
        : this.replaceName(chart.area.he, chart.user);
    }
  }

  replaceName(text, user) {
    return text ? text.replace('<Name>', user.userName) : '';
  }

  convertText(text, pronoun, user) {
    const from = pronoun === 'she' ? 0 : 1;
    const to = pronoun === 'she' ? 1 : 0;

    for (var index = 0; index < this.genderMap.length; index++) {
      if (text.includes(' ' + this.genderMap[index][from] + ' '))
        text = text.split(' ' + this.genderMap[index][from] + ' ').join(' ' + this.genderMap[index][to] + ' ');
      if (text.includes(' ' + this.genderMap[index][from] + ','))
        text = text.split(' ' + this.genderMap[index][from] + ',').join(' ' + this.genderMap[index][to] + ',');
      if (text.includes(' ' + this.genderMap[index][from] + '.'))
        text = text.split(' ' + this.genderMap[index][from] + '.').join(' ' + this.genderMap[index][to] + '.');
    }

    text = this.replaceName(text, user);

    return text;
  }

  recoverPassword(userRecoverPassword: UserRecoverPassword) {
    return this.http.put<{ message: string }>(BACKEND_URL + '/user/password', userRecoverPassword);
  }

  checkResetKey(resetKey: string) {
    return this.http.get<User>(BACKEND_URL + '/user/password/' + resetKey);
  }

  checkExistedUsername(username: string) {
    return this.http.get<boolean>(BACKEND_URL + '/user/existed-username/' + username);
  }

  checkExistedEmail(email: string) {
    return this.http.get<boolean>(BACKEND_URL + '/user/existed-email/' + email);
  }

  resetPassword(userResetPassword: UserResetPassword) {
    return this.http.put(BACKEND_URL + '/user/finish-reset-password/', userResetPassword);
  }

  get(userInfoId: number) {
    return this.http.get<UserInfo>(BACKEND_URL + '/user/' + userInfoId);
  }

  getAll() {
    return this.http.get<UserInfo[]>(BACKEND_URL + '/user');
  }

  getAllCreatedAt(start, end) {
    return this.http.get<UserInfo[]>(BACKEND_URL + '/user/from/' + start + '/to/' + end);
  }

  getAllByCompanyIdCreatedAt(companyId: number, start, end) {
    return this.http.get<UserInfo[]>(BACKEND_URL + '/user/company/' + companyId + '/from/' + start + '/to/' + end);
  }

  getAllByCompanyId(companyId: number): Observable<UserInfo[]> {
    return this.http.get<UserInfo[]>(`${BACKEND_URL}/user/company/${companyId}`).pipe(take(1));
  }

  getRoleTitles(userInfoId: number) {
    return this.http.get<RoleTitle[]>(BACKEND_URL + '/user/' + userInfoId + '/role-title');
  }

  getRoleTitle(roleTitleId: number) {
    return this.http.get<RoleTitle>(BACKEND_URL + '/user/role-title/' + roleTitleId);
  }

  getAlternativeTitles(userInfoId: number) {
    return this.http.get<AlternativeTitle[]>(BACKEND_URL + '/user/' + userInfoId + '/alternative-title');
  }

  saveRoleTitle(role: string) {
    return this.http.post<RoleTitle>(BACKEND_URL + '/user/role-title', { title: role });
  }

  saveAlternativeTitle(title: string) {
    return this.http.post<{ message: string; alternativeTitleId: number }>(BACKEND_URL + '/user/alternative-title', {
      alternativeTitle: title,
    });
  }

  updateUser(userInfoId: number, values: UpdateUserValue) {
    const url = BACKEND_URL + '/user/' + userInfoId;

    return this.http.patch<UserInfo>(url, values);
  }

  updateDepartment(userInfoId: number, department: Department) {
    return this.http.patch(BACKEND_URL + '/user/' + userInfoId + '/department', department);
  }

  updateRoleTitle(userInfoId: number, roleTitle: RoleTitle) {
    return this.http.patch(BACKEND_URL + '/user/' + userInfoId + '/role-title', roleTitle);
  }

  updateRolePosition(userInfoId: number, roleMandatePosition: RoleMandatePosition) {
    return this.http.patch(BACKEND_URL + '/user/' + userInfoId + '/role-mandate-position', roleMandatePosition);
  }

  updateAlternativeTitle(userInfoId: number, alternativeTitle: AlternativeTitle) {
    return this.http.patch(BACKEND_URL + '/user/' + userInfoId + '/alternative-title', alternativeTitle);
  }

  updateLevel(userInfoId: number, level: Level) {
    return this.http.patch(BACKEND_URL + '/user/' + userInfoId + '/level', level);
  }

  delete(userInfoId: number) {
    return this.http.delete(BACKEND_URL + '/user/' + userInfoId);
  }

  getTestGroups(userInfoId: number) {
    const url = `${BACKEND_URL}/user/${userInfoId}/test-group`;

    return this.http.get<PsyTestGroup[]>(url);
  }

  removeFromTestGroup(userId: number, testGroupId: number) {
    const url = `${BACKEND_URL}/user/${userId}/test-group/${testGroupId}`;

    return this.http.delete(url);
  }

  addToTestGroup(userId: number, testGroupId: number) {
    const url = `${BACKEND_URL}/user/${userId}/test-group`;
    const body = { testGroupId };
    return this.http.post(url, body);
  }

  getUserNotificationsByUserInfoId(userInfoId: number): Observable<UserNotification[]> {
    return this.http.get(BACKEND_URL + '/user/' + userInfoId + '/notification').pipe(
      take(1),
      map((notifications: UserNotification[]) => {
        return notifications;
      }),
    );
  }

  getUserNotificationsNewByUserInfoId(userInfoId: number): Observable<UserNotification[]> {
    return this.http.get(BACKEND_URL + '/user/' + userInfoId + '/notification/new').pipe(
      take(1),
      map((notifications: UserNotification[]) => {
        return notifications;
      }),
    );
  }

  getUserScores(userId: number) {
    const url = `${BACKEND_URL}/user/${userId}/score`;

    return this.http.get<Score[]>(url).pipe(
      map((score: Score[]) => {
        for (const s of score) {
          if (!s.score) {
            s.score = 0;
          }
        }
        return score;
      }),
    );
  }

  deleteScore(scoreId) {
    const url = `${BACKEND_URL}/user/score/${scoreId}`;
    return this.http.delete(url);
  }

  getReportResult(userInfoId: number) {
    const url = `${BACKEND_URL}/user/${userInfoId}/report-result`;
    return this.http.get<any>(url).pipe(
      map((report) => {
        if (report.tests) {
          for (const test of Object.keys(report.tests)) {
            for (const score of report.tests[test]) {
              if (!score.score) {
                score.score = 0;
              }
            }
          }
        }
        return report;
      }),
    );
  }

  updateUserScores(userIds: number[], testGroupId: number) {
    return this.http.post(`${BACKEND_URL}/user-score`, { userIds, testGroupId }).pipe(take(1));
  }

  deletedByUserInfoId(userInfoId: number) {
    return this.http.delete(BACKEND_URL + '/user/' + userInfoId + '/score');
  }

  getUserScoresDownloadByUserInfoId(userInfoId: number) {
    const url = `${BACKEND_URL}/user/${userInfoId}/user-score-percentile-download`;
    return this.http.get<{ fileName: string; fileMimeType: string; fileBase64Content: string }>(url);
  }

  resetPasswordForUser(userId: number, resetPassword: string) {
    return this.http.put(BACKEND_URL + '/user/reset-password', { userId, password: resetPassword });
  }

  authenticateGenericLink(data: { login: string; password: string; token: string }) {
    return this.http.post(`${BACKEND_URL}/user/generic-invite/authenticate`, data);
  }

  registerGenericLink(userForm: UserForm) {
    return this.http.post(`${BACKEND_URL}/user/generic-invite/register`, userForm);
  }
}
