<mat-card>
  <mat-card-header>
    <h2>Users Completed</h2>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field style="margin-top: 10px">
      <mat-label>Search</mat-label>
      <input matInput [formControl]="filter" (keyup)="applyFilterUsers($event)" placeholder="Ex. John" #input />
    </mat-form-field>
    <table mat-table [dataSource]="usersComplete" matSort #sortUsers="matSort">
      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let user">{{ user.firstName }} {{ user.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="role-title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let user">
          <div *ngIf="user.roleTitle">
            {{ user.roleTitle }}
            <div *ngIf="user.department">at {{ user.department }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let user">
          <mat-chip-list aria-label="Status" *ngIf="user.status">
            <mat-chip [ngClass]="user.status">{{
              user.status != 'NOT_STARTED' ? user.status : 'NOT STARTED'
            }}</mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <ng-container matColumnDef="date-completed">
        <th mat-header-cell *matHeaderCellDef>Date Complete</th>
        <td mat-cell *matCellDef="let user">{{ user.dateCompleted }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <button mat-button (click)="onOpenUser(user)">Answers</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="2" *ngIf="filter.value">No data matching the filter "{{ input.value }}"</td>
      </tr>
    </table>
    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons> </mat-paginator>
  </mat-card-content>
</mat-card>
