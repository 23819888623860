import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Answer } from '../models/answer.model';
import { TextAnswer } from '../models/text-answer.model';
import { IntegerAnswer } from '../models/integer-answer.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class AnswerService {
  constructor(private http: HttpClient) {}

  saveTextArea(questionId: number, answer: TextAnswer) {
    return this.http.post<{ id: number }>(BACKEND_URL + '/question/' + questionId + '/answer-text-area', answer);
  }

  updateTextArea(questionId: number, answer: TextAnswer) {
    return this.http.patch(BACKEND_URL + '/question/' + questionId + '/answer-text-area', answer);
  }

  saveIntegerAnswer(questionId: number, answer: IntegerAnswer) {
    return this.http.post<{ id: number }>(BACKEND_URL + '/question/' + questionId + '/answer-integer-answer', answer);
  }

  updateIntegerAnswer(questionId: number, answer: IntegerAnswer) {
    return this.http.patch(BACKEND_URL + '/question/' + questionId + '/answer-integer-answer', answer);
  }

  saveAll(questionId: number, answers: Answer[]) {
    return this.http.post(BACKEND_URL + '/question/' + questionId + '/answers', answers);
  }

  updateAll(questionId: number, answers: Answer[]) {
    return this.http.put(BACKEND_URL + '/question/' + questionId + '/answers', answers);
  }

  delete(answerId: number) {
    return this.http.delete(`${BACKEND_URL}/question/answer/${answerId}`).pipe(take(1));
  }

  getAllByQuestionId(questionId: number): Observable<Answer[]> {
    return this.http.get<Answer[]>(`${BACKEND_URL}/question/${questionId}/answer`).pipe(take(1));
  }

  getById(id: number): Observable<Answer> {
    return this.http.get<Answer>(`${BACKEND_URL}/question/answer/${id}`).pipe(take(1));
  }

  create(questionId: number, answer: Answer): Observable<Answer> {
    return this.http.post<Answer>(`${BACKEND_URL}/question/${questionId}/answer`, answer).pipe(take(1));
  }

  update(questionId: number, answer: Answer) {
    return this.http.patch(`${BACKEND_URL}/question/${questionId}/answer`, answer).pipe(take(1));
  }
}
