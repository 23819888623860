import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxPaginationModule } from 'ngx-pagination';

import { SharedModule } from '@app/shared/shared.module';

import { ConnectionsIndicatorComponent } from './connections-indicator/connections-indicator.component';
import { ConnectionsIndicatorInfoComponent } from './connections-indicator-info/connections-indicator-info.component';
import { ConnectionsIndicatorRoutingModule } from './connections-indicator-routing.module';
import { ConnectionsIndicatorQuestionsComponent } from './connections-indicator-questions/connections-indicator-questions.component';
import { ConnectionsIndicatorDecisionTablesComponent } from './connections-indicator-decision-tables/connections-indicator-decision-tables.component';
import { ConnectionsIndicatorReviewComponent } from './connections-indicator-review/connections-indicator-review.component';
import { ConnectionsIndicatorDashboardComponent } from './connections-indicator-dashboard/connections-indicator-dashboard.component';
import { ConnectionsIndicatorSortComponent } from './connections-indicator-sort/connections-indicator-sort.component';
import { ConnectionsIndicatorUsersComponent } from './connections-indicator-users/connections-indicator-users.component';
import { ConnectionsIndicatorSearchComponent } from './connections-indicator-search/connections-indicator-search.component';

import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

@NgModule({
  declarations: [
    ConnectionsIndicatorComponent,
    ConnectionsIndicatorInfoComponent,
    ConnectionsIndicatorQuestionsComponent,
    ConnectionsIndicatorDecisionTablesComponent,
    ConnectionsIndicatorReviewComponent,
    DeleteDialogComponent,
    ConnectionsIndicatorDashboardComponent,
    ConnectionsIndicatorSortComponent,
    ConnectionsIndicatorUsersComponent,
    ConnectionsIndicatorSearchComponent,
  ],
  exports: [
    ConnectionsIndicatorComponent,
    ConnectionsIndicatorInfoComponent,
    ConnectionsIndicatorQuestionsComponent,
    ConnectionsIndicatorDecisionTablesComponent,
    ConnectionsIndicatorReviewComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ConnectionsIndicatorRoutingModule,
    NgxPaginationModule,
    AngularEditorModule,
    DragDropModule,
  ],
})
export class ConnectionsIndicatorModule {}
