/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { UserScores } from '../models/UserScores';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class UserScoresService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a list with all user scores
     * @returns UserScores A list of User Scores
     * @throws ApiError
     */
    public getTestUserScores(): Observable<Array<UserScores>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/{id}/user-scores',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a user scores
     * Create a user scores
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestUserScores(
        requestBody?: {
            /**
             * Id of the psy test
             */
            id?: number;
            /**
             * Score of user score
             */
            score?: string;
            /**
             * Percentile of user score
             */
            percentile?: string;
            /**
             * User Info Id of user score
             */
            userInfoId?: number;
            /**
             * Subscale Id of user score
             */
            subscaleId?: number;
            /**
             * Psy test Id of user score
             */
            psyTestId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/{id}/user-scores',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

}
