<div #dtTable>
  <mat-card *ngIf="!showEdit">
    <div class="row">
      <div class="pull-right">
        <button mat-button (click)="onEdit()">Edit</button>
        <button mat-button class="btn-delete" (click)="onDelete(decisionTable.id)">Delete</button>
      </div>
    </div>
    <div>
      <div class="form-value"><span class="label">Title</span> {{ decisionTable.title }}</div>
      <div class="form-value"><span class="label">Instructions</span> {{ decisionTable.instructions }}</div>
      <div class="form-value"><span class="label">Description</span> {{ decisionTable.description }}</div>
      <div class="form-value"><span class="label">Network Type</span> {{ decisionTable.networkType }}</div>
      <div class="form-value">
        <span class="label">Collaboration Type</span>
        {{ getDecisionTableCollaborationTypeNameById(+decisionTable.collaborationTypeId) }}
      </div>
    </div>

    <mat-table [dataSource]="getDataSource(decisionTable.columns)" class="mat-elevation-z3">
      <ng-container
        *ngFor="let decisionTableColumn of decisionTable.columns"
        [matColumnDef]="getColumnDef(decisionTableColumn.title)"
      >
        <mat-header-cell *matHeaderCellDef> {{ decisionTableColumn.title }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ printColumnTypeName(+decisionTableColumn.type) }}
          <i *ngIf="decisionTableColumn.required"> [Required]</i>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <p *ngIf="(connectionIndicatorId || roleMandateId) && decisionTable.acl" class="privacy-text">
      <b>Privacy: </b>
      {{ getDecisionTablePrivacyByACLId(decisionTable.acl) }}
    </p>
  </mat-card>

  <app-edit-decision-table
    *ngIf="collaborationTypes.length > 0 && showEdit"
    [decisionTable]="decisionTable"
    [collaborationTypes]="collaborationTypes"
    (edit)="onEditConfirm($event)"
    (create)="onCreate($event)"
    [showAcl]="showAcl"
    (deleteColumn)="onDeleteColumn($event)"
  >
  </app-edit-decision-table>
</div>
