import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// models
import { Notification } from '@app/core/models/notification.model';

@Component({
  selector: 'app-notification-detail-dialog',
  templateUrl: './notification-detail-dialog.component.html',
  styleUrls: ['./notification-detail-dialog.component.scss'],
})
export class NotificationDetailDialogComponent implements OnInit {
  notification: Notification;

  constructor(
    public dialogRef: MatDialogRef<NotificationDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { notification: Notification },
  ) {}

  ngOnInit(): void {
    this.notification = this.data.notification;
  }

  onClose() {
    this.dialogRef.close({ event: 'close' });
  }
}
