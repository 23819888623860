<h1 mat-dialog-title>{{ isEdit ? 'Edit area mapping' : 'Create new area mapping' }}</h1>

<form id="createNewAreaForm" mat-dialog-content [formGroup]="form" (submit)="onFormSubmit()">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" />
    <mat-error *ngIf="form.get('name').invalid">Please insert a valid name.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Base</mat-label>
    <mat-select formControlName="base">
      <mat-option *ngFor="let base of bases" [value]="base">{{ base }}</mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('base').invalid">Please select a base.</mat-error>
  </mat-form-field>
</form>

<div mat-dialog-actions align="end">
  <button mat-flat-button (click)="onCancel()">
    <mat-icon>block</mat-icon>
    Cancel
  </button>
  <button mat-flat-button color="primary" type="submit" form="createNewAreaForm" [disabled]="form.invalid">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</div>
