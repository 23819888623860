import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// models
import { Question } from '@app/core/models/question.model';

@Component({
  selector: 'app-cardsort-analysis',
  templateUrl: './cardsort-analysis.component.html',
  styleUrls: ['./cardsort-analysis.component.scss'],
})
export class CardsortAnalysisComponent implements OnInit, AfterViewInit {
  @Input() connectionIndicatorId: number;
  @Input() cardsortAnalysis: { question: Question; analysis: any[] };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['answer', 'subscale', 'totalAppearance', 'topThree', 'thrownOut', 'importance'];

  constructor() {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.cardsortAnalysis.analysis);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
