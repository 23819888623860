import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { EditChartDialogComponent } from './../edit-chart-dialog/edit-chart-dialog.component';

// services
import { ChartService } from '@app/core/services/chart.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { PsyChart } from '@app/core/models/psy-chart.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-chart-details',
  templateUrl: './chart-details.component.html',
  styleUrls: ['./chart-details.component.scss'],
})
export class ChartDetailsComponent implements OnInit {
  chartId: number;
  chart: PsyChart;

  tabs: string[] = ['Details', 'Base Setup', 'Plot Setup', 'Subscales'];
  selected = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private chartService: ChartService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.chartId = this.activatedRoute.snapshot.params.id;
    this.activatedRoute.params.subscribe((params) => {
      this.selected = params.id ? +params.tab : 0;
    });

    this.getChart();
  }

  tabChange(idx: number) {
    this.selected = idx;
    this.router.navigate([this.router.url.split('tab/')[0] + '/tab/' + idx]);

    if (idx === 1) {
      this.getChart();
    }
  }

  getChart() {
    this.chartService.getById(this.chartId).subscribe(
      (chart) => {
        this.chart = chart;
      },
      () => {
        this.snackBar.info('Something went wrong.');
      },
    );
  }

  onEdit() {
    const data = { chart: this.chart };
    this.dialog
      .open(EditChartDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_SUCCESS) {
          return;
        }

        this.chart = result.updatedChart;
      });
  }
}
