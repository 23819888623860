import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SummaryComponent } from './summary/summary.component';
import { ReportDataDownloadComponent } from './report-data-download/report-data-download.component';

const adminRoutes: Routes = [
  { path: '', component: SummaryComponent },
  { path: 'report-data-download', component: ReportDataDownloadComponent },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
})
export class ReportRoutingModule {}
