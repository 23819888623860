/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { EmailLog } from '../models/EmailLog';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class EmailLogService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve EmailLogs by Connection Indicator Id
     * @param id Id of the Connection Indicator
     * @returns EmailLog A list of EmailLog
     * @throws ApiError
     */
    public getSendEmailConnectionIndicatorEmailLogs(
        id: number,
    ): Observable<Array<EmailLog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/send-email/connection-indicator/{id}/email-logs',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Retrieve EmailLogs by Test Group Id
     * @param testGroupId Id of the Test Group
     * @returns EmailLog A list of EmailLog
     * @throws ApiError
     */
    public getSendEmailTestGroupEmailLogs(
        testGroupId: number,
    ): Observable<Array<EmailLog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/send-email/test-group/{testGroupId}/email-logs',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Retrieve EmailLogs by Role Mandate Id
     * @param testGroupId Id of the Role Mandate
     * @returns EmailLog A list of EmailLog
     * @throws ApiError
     */
    public getSendEmailRoleMandateEmailLogs(
        testGroupId: number,
    ): Observable<Array<EmailLog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/send-email/role-mandate/{id}/email-logs',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Retrieve EmailLogContents by email log id
     * @param emailLogId Id of the Email Log
     * @returns EmailLog A list of EmailLog
     * @throws ApiError
     */
    public getSendEmailEmailLogsContent(
        emailLogId: number,
    ): Observable<Array<EmailLog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/send-email/email-logs/{id}/content',
            path: {
                'emailLogId': emailLogId,
            },
            errors: {
                500: `Internal Error`,
            },
        });
    }

}
