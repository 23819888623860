import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { SuperscaleRoutingModule } from './superscale-routing.module';

import { SuperscaleListComponent } from './superscale-list/superscale-list.component';
import { SuperscaleDetailComponent } from './superscale-detail/superscale-detail.component';
import { SuperscaleSubscaleListComponent } from './superscale-subscale-list/superscale-subscale-list.component';
import { SuperscaleCreateDialogComponent } from './superscale-create-dialog/superscale-create-dialog.component';
import { SuperscaleSubscaleCreateDialogComponent } from './superscale-subscale-create-dialog/superscale-subscale-create-dialog.component';

@NgModule({
  declarations: [
    SuperscaleListComponent,
    SuperscaleDetailComponent,
    SuperscaleSubscaleListComponent,
    SuperscaleCreateDialogComponent,
    SuperscaleSubscaleCreateDialogComponent,
  ],
  imports: [CommonModule, SuperscaleRoutingModule, SharedModule, ReactiveFormsModule],
})
export class SuperscaleModule {}
