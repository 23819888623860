<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ title }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>{{ description }}</p>
    <section *ngFor="let privacyMsg of privacyMsgs">
      <mat-checkbox
        color="primary"
        [checked]="this.acl === privacyMsg.id"
        (change)="onChangePrivacy($event, privacyMsg)"
      >
        {{ privacyMsg.msg }}</mat-checkbox
      >
    </section>
  </mat-card-content>
</mat-card>
