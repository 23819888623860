import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, AbstractControl, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// models
import { PsyTestGroupTestScale } from '@app/core/models/psy-test-group-test-scale.model';

// services
import { TestGroupService } from '@app/core/services/test-group.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-test-group-preferred-ranges-subscale',
  templateUrl: './test-group-preferred-ranges-subscale.component.html',
  styleUrls: ['./test-group-preferred-ranges-subscale.component.scss'],
})
export class TestGroupPreferredRangesSubscaleComponent implements OnInit {
  testGroupId: number;
  testId: number;
  testGroupName: string;
  testName: string;
  textForRangeTitle: string;
  form: FormGroup;
  addTextDialogRef: MatDialogRef<any>;
  addTextEditorControl = new FormControl('', [Validators.required]);

  editorConfig: AngularEditorConfig = {
    height: '10rem',
    editable: true,
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private testGroupServive: TestGroupService,
    private snackBar: SnackBarService,
  ) {
    this.testGroupName = this.router.getCurrentNavigation()?.extras.state?.testGroupTitle;
    this.testName = this.router.getCurrentNavigation()?.extras.state?.testTitle;
    this.form = this.fb.group({
      testGroupSubscales: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.testGroupId = Number(this.route.snapshot.params.id);
    this.testId = Number(this.route.snapshot.params.testId);
    this.getFormData(this.testGroupId, this.testId);
  }

  getFormData(testGroupId: number, testId: number) {
    this.testGroupServive.getTestGroupSubscaleRanges(testGroupId, testId).subscribe((testGroupSubscales) => {
      if (testGroupSubscales && testGroupSubscales.length > 0) {
        this.loadFormData(testGroupSubscales);
      }
    });
  }

  private loadFormData(testGroupSubscales: PsyTestGroupTestScale[]): void {
    testGroupSubscales.map((testGroupSubscale) => {
      this.addTestGroupSubscale(testGroupSubscale);
    });
  }

  get testGroupSubscalesFormArray() {
    return this.form.get('testGroupSubscales') as FormArray;
  }

  resetTestGroupSubscalesFormArray() {
    (<FormArray>this.form.get('testGroupSubscales')).clear();
  }

  addTestGroupSubscale(testGroupSubscale?: any) {
    this.testGroupSubscalesFormArray.push(this.createTestGroupSubscaleFormGroup(testGroupSubscale));
  }

  createOutOfRangeLowFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      outOfRangeLowFrom: [
        range && !isNaN(range.outOfRangeLowFrom) ? range.outOfRangeLowFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      outOfRangeLowTo: [
        range && !isNaN(range.outOfRangeLowTo) ? range.outOfRangeLowTo : null,
        [Validators.required, Validators.min(0)],
      ],
      outOfRangeLowText: [range && range.outOfRangeLowText ? range.outOfRangeLowText : '', [Validators.required]],
    });
  }

  createCautionaryLowFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      cautionaryLowFrom: [
        range && !isNaN(range.cautionaryLowFrom) ? range.cautionaryLowFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      cautionaryLowTo: [
        range && !isNaN(range.cautionaryLowTo) ? range.cautionaryLowTo : null,
        [Validators.required, Validators.min(0)],
      ],
      cautionaryLowText: [range && range.cautionaryLowText ? range.cautionaryLowText : '', [Validators.required]],
    });
  }

  createOptimalFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      optimalFrom: [
        range && !isNaN(range.optimalFrom) ? range.optimalFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      optimalTo: [range && !isNaN(range.optimalTo) ? range.optimalTo : null, [Validators.required, Validators.min(0)]],
      optimalText: [range && range.optimalText ? range.optimalText : '', [Validators.required]],
    });
  }

  createOutOfRangeHighFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      outOfRangeHighFrom: [
        range && !isNaN(range.outOfRangeHighFrom) ? range.outOfRangeHighFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      outOfRangeHighTo: [
        range && !isNaN(range.outOfRangeHighTo) ? range.outOfRangeHighTo : null,
        [Validators.required, Validators.min(0)],
      ],
      outOfRangeHighText: [range && range.outOfRangeHighText ? range.outOfRangeHighText : '', [Validators.required]],
    });
  }

  createCautionaryHighFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      cautionaryHighFrom: [
        range && !isNaN(range.cautionaryHighFrom) ? range.cautionaryHighFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      cautionaryHighTo: [
        range && !isNaN(range.cautionaryHighTo) ? range.cautionaryHighTo : null,
        [Validators.required, Validators.min(0)],
      ],
      cautionaryHighText: [range && range.cautionaryHighText ? range.cautionaryHighText : '', [Validators.required]],
    });
  }

  createTestGroupSubscaleFormGroup(subscale?: PsyTestGroupTestScale) {
    return this.fb.group({
      id: [subscale && subscale.id ? subscale.id : null, [Validators.required]],
      subscale: [subscale && subscale.title ? subscale.title : null, [Validators.required]],
      subscaleId: [subscale && subscale.subscaleId ? subscale.subscaleId : null],
      testScaleId: [subscale && subscale.testScaleId ? subscale.testScaleId : null],
      testGroupTestId: [subscale && subscale.testGroupTestId ? subscale.testGroupTestId : null],
      ranges: this.fb.group({
        outOfRangeLow: this.createOutOfRangeLowFormGroup(
          subscale &&
            (!isNaN(subscale.outOfRangeLowFrom) || !isNaN(subscale.outOfRangeLowTo) || subscale.outOfRangeLowText)
            ? subscale
            : null,
        ),
        cautionaryLow: this.createCautionaryLowFormGroup(
          subscale &&
            (!isNaN(subscale.cautionaryLowFrom) || !isNaN(subscale.cautionaryLowTo) || subscale.cautionaryLowText)
            ? subscale
            : null,
        ),
        optimal: this.createOptimalFormGroup(
          subscale && (!isNaN(subscale.optimalFrom) || !isNaN(subscale.optimalTo) || subscale.optimalText)
            ? subscale
            : null,
        ),
        outOfRangeHigh: this.createOutOfRangeHighFormGroup(
          subscale &&
            (!isNaN(subscale.outOfRangeHighFrom) || !isNaN(subscale.outOfRangeHighTo) || subscale.outOfRangeHighText)
            ? subscale
            : null,
        ),
        cautionaryHigh: this.createCautionaryHighFormGroup(
          subscale &&
            (!isNaN(subscale.cautionaryHighFrom) || !isNaN(subscale.cautionaryHighTo) || subscale.cautionaryHighText)
            ? subscale
            : null,
        ),
      }),
    });
  }

  openAddTextDialog(ref: TemplateRef<any>, textControl: AbstractControl, title: string) {
    this.addTextEditorControl.setValue(textControl.value);
    this.textForRangeTitle = title;
    this.addTextDialogRef = this.dialog.open(ref);
    this.addTextDialogRef.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_SUCCESS) {
        return;
      }
      textControl.setValue(this.addTextEditorControl.value);
      this.addTextEditorControl.setValue('');
    });
  }

  removeTextDialog(textControl: AbstractControl, title: string) {
    this.addTextEditorControl.setValue('');
    this.textForRangeTitle = title;

    textControl.setValue(this.addTextEditorControl.value);
  }

  closeAddTextDialog() {
    this.addTextDialogRef.close();
  }

  saveAddTextDialog() {
    this.convertNumericalCodeToUmlautAndSpecialChar();
    this.addTextDialogRef.close({ event: EVENT_SUCCESS });
  }

  convertNumericalCodeToUmlautAndSpecialChar() {
    let newValue = this.addTextEditorControl.value;

    if (this.addTextEditorControl.value.indexOf('&#160;') > -1) {
      newValue = newValue.replace(/&#160;/g, '');
      this.addTextEditorControl.setValue(newValue);
    }

    if (this.addTextEditorControl.value.indexOf('&#192;') > -1) {
      newValue = newValue.replace(/&#192;/g, 'À');
    }

    if (this.addTextEditorControl.value.indexOf('&#224;') > -1) {
      newValue = newValue.replace(/&#224;/g, 'à');
    }

    if (this.addTextEditorControl.value.indexOf('&#194;') > -1) {
      newValue = newValue.replace(/&#194;/g, 'Â');
    }

    if (this.addTextEditorControl.value.indexOf('&#226;') > -1) {
      newValue = newValue.replace(/&#194;/g, 'Â');
    }

    if (this.addTextEditorControl.value.indexOf('&#198;') > -1) {
      newValue = newValue.replace(/&#198;/g, 'Æ');
    }

    if (this.addTextEditorControl.value.indexOf('&#230;') > -1) {
      newValue = newValue.replace(/&#230;/g, 'æ');
    }

    if (this.addTextEditorControl.value.indexOf('&#199;') > -1) {
      newValue = newValue.replace(/&#199;/g, 'Ç');
    }

    if (this.addTextEditorControl.value.indexOf('&#231;') > -1) {
      newValue = newValue.replace(/&#231;/g, 'ç');
    }

    if (this.addTextEditorControl.value.indexOf('&#200;') > -1) {
      newValue = newValue.replace(/&#200;/g, 'È');
    }

    if (this.addTextEditorControl.value.indexOf('&#232;') > -1) {
      newValue = newValue.replace(/&#232;/g, 'è');
    }

    if (this.addTextEditorControl.value.indexOf('&#201;') > -1) {
      newValue = newValue.replace(/&#201;/g, 'É');
    }

    if (this.addTextEditorControl.value.indexOf('&#233;') > -1) {
      newValue = newValue.replace(/&#233;/g, 'é');
    }

    if (this.addTextEditorControl.value.indexOf('&#202;') > -1) {
      newValue = newValue.replace(/&#202;/g, 'Ê');
    }

    if (this.addTextEditorControl.value.indexOf('&#234;') > -1) {
      newValue = newValue.replace(/&#234;/g, 'ê');
    }

    if (this.addTextEditorControl.value.indexOf('&#203;') > -1) {
      newValue = newValue.replace(/&#203;/g, 'Ë');
    }

    if (this.addTextEditorControl.value.indexOf('&#235;') > -1) {
      newValue = newValue.replace(/&#235;/g, 'ë');
    }

    if (this.addTextEditorControl.value.indexOf('&#206;') > -1) {
      newValue = newValue.replace(/&#206;/g, 'Î');
    }

    if (this.addTextEditorControl.value.indexOf('&#238;') > -1) {
      newValue = newValue.replace(/&#238;/g, 'î');
    }

    if (this.addTextEditorControl.value.indexOf('&#207;') > -1) {
      newValue = newValue.replace(/&#207;/g, 'Ï');
    }

    if (this.addTextEditorControl.value.indexOf('&#239;') > -1) {
      newValue = newValue.replace(/&#239;/g, 'ï');
    }

    if (this.addTextEditorControl.value.indexOf('&#212;') > -1) {
      newValue = newValue.replace(/&#212;/g, 'Ô');
    }

    if (this.addTextEditorControl.value.indexOf('&#244;') > -1) {
      newValue = newValue.replace(/&#244;/g, 'ô');
    }

    if (this.addTextEditorControl.value.indexOf('&#140;') > -1) {
      newValue = newValue.replace(/&#140;/g, 'Œ');
    }

    if (this.addTextEditorControl.value.indexOf('&#156;') > -1) {
      newValue = newValue.replace(/&#156;/g, 'œ');
    }

    if (this.addTextEditorControl.value.indexOf('&#217;') > -1) {
      newValue = newValue.replace(/&#217;/g, 'Ù');
    }

    if (this.addTextEditorControl.value.indexOf('&#249;') > -1) {
      newValue = newValue.replace(/&#249;/g, 'ù');
    }

    if (this.addTextEditorControl.value.indexOf('&#219;') > -1) {
      newValue = newValue.replace(/&#219;/g, 'Û');
    }

    if (this.addTextEditorControl.value.indexOf('&#251;') > -1) {
      newValue = newValue.replace(/&#251;/g, 'û');
    }

    if (this.addTextEditorControl.value.indexOf('&#220;') > -1) {
      newValue = newValue.replace(/&#220;/g, 'Ü');
    }

    if (this.addTextEditorControl.value.indexOf('&#252;') > -1) {
      newValue = newValue.replace(/&#252;/g, 'ü');
    }

    if (this.addTextEditorControl.value.indexOf('&#171;') > -1) {
      newValue = newValue.replace(/&#171;/g, '«');
    }

    if (this.addTextEditorControl.value.indexOf('&#187;') > -1) {
      newValue = newValue.replace(/&#187;/g, '»');
    }

    if (this.addTextEditorControl.value.indexOf('&#128;') > -1) {
      newValue = newValue.replace(/&#128;/g, '€');
    }

    if (this.addTextEditorControl.value.indexOf('&#8355;') > -1) {
      newValue = newValue.replace(/&#8355;/g, '₣');
    }

    this.addTextEditorControl.setValue(newValue);
  }

  onSave() {
    const testGroupSubscales = this.checkEmptyTestGroupSubscale();
    this.testGroupServive
      .saveTestGroupSubscaleRanges(this.testGroupId, this.testId, testGroupSubscales)
      .subscribe((res) => {
        this.resetTestGroupSubscalesFormArray();
        this.getFormData(this.testGroupId, this.testId);
        this.snackBar.info('Preferred ranges are saved successfully');
      });
  }

  checkEmptyTestGroupSubscale() {
    const { value } = this.form;
    let emptySubscaleRange = true;
    let testGroupSubscalesUpdated = [];
    value.testGroupSubscales.forEach((testGroupSubscale) => {
      if (
        testGroupSubscale.id !== null ||
        testGroupSubscale.testScaleId !== null ||
        testGroupSubscale.testGroupTestId !== null
      ) {
        emptySubscaleRange = false;

        if (!emptySubscaleRange) {
          const newTestGroupSubscale = this.getTestGroupSubscale(testGroupSubscale);
          testGroupSubscalesUpdated.push(newTestGroupSubscale);
        }
      } else {
        emptySubscaleRange =
          this.checkRangeEmpty(
            testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowFrom,
            testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowTo,
            testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowText,
          ) &&
          this.checkRangeEmpty(
            testGroupSubscale.ranges.cautionaryLow.cautionaryLowFrom,
            testGroupSubscale.ranges.cautionaryLow.cautionaryLowTo,
            testGroupSubscale.ranges.cautionaryLow.cautionaryLowText,
          ) &&
          this.checkRangeEmpty(
            testGroupSubscale.ranges.optimal.optimalFrom,
            testGroupSubscale.ranges.optimal.optimalTo,
            testGroupSubscale.ranges.optimal.optimalText,
          ) &&
          this.checkRangeEmpty(
            testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighFrom,
            testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighTo,
            testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighText,
          ) &&
          this.checkRangeEmpty(
            testGroupSubscale.ranges.cautionaryHigh.cautionaryHighFrom,
            testGroupSubscale.ranges.cautionaryHigh.cautionaryHighTo,
            testGroupSubscale.ranges.cautionaryHigh.cautionaryHighText,
          );
        if (!emptySubscaleRange) {
          const newTestGroupSuperscale = this.getTestGroupSubscale(testGroupSubscale);
          testGroupSubscalesUpdated.push(newTestGroupSuperscale);
        }
      }
    });

    return testGroupSubscalesUpdated;
  }

  checkRangeEmpty(from: any, to: any, text: any) {
    return from === null && to === null && text === '' ? true : false;
  }

  getTestGroupSubscale(testGroupSubscale: any) {
    const subscaleRange: PsyTestGroupTestScale = {
      id: testGroupSubscale.id ? testGroupSubscale.id : null,
      testScaleId: testGroupSubscale.testScaleId ? testGroupSubscale.testScaleId : null,
      subscaleId: testGroupSubscale.subscaleId ? testGroupSubscale.subscaleId : null,
      testGroupTestId: testGroupSubscale.testGroupTestId ? testGroupSubscale.testGroupTestId : null,
      psyTestGroupSuperscaleId: testGroupSubscale.testGroupSuperscaleId
        ? testGroupSubscale.testGroupSuperscaleId
        : null,
      superscaleId: testGroupSubscale.superscaleId ? testGroupSubscale.superscaleId : null,
      predefinedPreferredRangesId: testGroupSubscale.predefinedPreferredRangesId
        ? testGroupSubscale.predefinedPreferredRangesId
        : null,
      cautionaryHighFrom: testGroupSubscale.ranges.cautionaryHigh.cautionaryHighFrom
        ? testGroupSubscale.ranges.cautionaryHigh.cautionaryHighFrom
        : testGroupSubscale.ranges.cautionaryHigh.cautionaryHighTo > 0
        ? 1
        : null,
      cautionaryHighTo:
        testGroupSubscale.ranges.cautionaryHigh.cautionaryHighTo != null
          ? testGroupSubscale.ranges.cautionaryHigh.cautionaryHighTo
          : null,
      cautionaryLowFrom: testGroupSubscale.ranges.cautionaryLow.cautionaryLowFrom
        ? testGroupSubscale.ranges.cautionaryLow.cautionaryLowFrom
        : testGroupSubscale.ranges.cautionaryLow.cautionaryLowTo > 0
        ? 1
        : null,
      cautionaryLowTo:
        testGroupSubscale.ranges.cautionaryLow.cautionaryLowTo != null
          ? testGroupSubscale.ranges.cautionaryLow.cautionaryLowTo
          : null,
      optimalFrom: testGroupSubscale.ranges.optimal.optimalFrom
        ? testGroupSubscale.ranges.optimal.optimalFrom
        : testGroupSubscale.ranges.optimal.optimalTo > 0
        ? 1
        : null,
      optimalTo: testGroupSubscale.ranges.optimal.optimalTo != null ? testGroupSubscale.ranges.optimal.optimalTo : null,
      outOfRangeHighFrom: testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighFrom
        ? testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighFrom
        : testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighTo > 0
        ? 1
        : null,
      outOfRangeHighTo:
        testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighTo != null
          ? testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighTo
          : null,
      outOfRangeLowFrom: testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowFrom
        ? testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowFrom
        : testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowTo > 0
        ? 1
        : null,
      outOfRangeLowTo:
        testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowTo != null
          ? testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowTo
          : null,
      cautionaryHighText: testGroupSubscale.ranges.cautionaryHigh.cautionaryHighText
        ? testGroupSubscale.ranges.cautionaryHigh.cautionaryHighText
        : '',
      cautionaryLowText: testGroupSubscale.ranges.cautionaryLow.cautionaryLowText
        ? testGroupSubscale.ranges.cautionaryLow.cautionaryLowText
        : '',
      optimalText: testGroupSubscale.ranges.optimal.optimalText ? testGroupSubscale.ranges.optimal.optimalText : '',
      outOfRangeHighText: testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighText
        ? testGroupSubscale.ranges.outOfRangeHigh.outOfRangeHighText
        : '',
      outOfRangeLowText: testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowText
        ? testGroupSubscale.ranges.outOfRangeLow.outOfRangeLowText
        : '',
    };

    return subscaleRange;
  }
}
