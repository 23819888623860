import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OptimalScoreScale } from '@app/core/models/optimal-score-scale.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-optimal-score-list',
  templateUrl: './optimal-score-list.component.html',
  styleUrls: ['./optimal-score-list.component.scss'],
})
export class OptimalScoreListComponent implements OnInit {
  chaptersOptimalScoreScales: MatTableDataSource<OptimalScoreScale> = new MatTableDataSource<OptimalScoreScale>();
  displayedSectionOptimalScoreColumns = [
    'sectionTitle',
    'subscale',
    'superscale',
    'optimalCurveTitle',
    'curve',
    'weight',
  ];
  optimalScoreScales: OptimalScoreScale[] = [];
  isLoaded = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { isLoaded: boolean; optimalScoreScales: OptimalScoreScale[] }) {
    this.optimalScoreScales = this.data.optimalScoreScales;
    this.chaptersOptimalScoreScales = new MatTableDataSource(this.data.optimalScoreScales);
    this.isLoaded = this.data.isLoaded;
  }

  ngOnInit(): void {}
}
