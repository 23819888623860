<div class="container">
  <div class="page-list-header">
    <h1>Optimal Curves</h1>
    <button mat-raised-button color="primary" class="btn-success new-button" (click)="onNew(newOptimalCurveDialog)">
      <mat-icon aria-hidden="false">add</mat-icon>Create a new Curve
    </button>
  </div>

  <app-psb-list [items]="optimalCurves" itemsPerPage="10" (view)="onView($event)" (delete)="onDelete($event)">
  </app-psb-list>
</div>

<ng-template #newOptimalCurveDialog>
  <h1 mat-dialog-title>{{ isFormEditing ? 'Edit a' : 'Create a new' }} Optimal Score</h1>
  <form id="newOptimalCurveForm" mat-dialog-content [formGroup]="newOptimalCurveForm" (submit)="onFormSubmit()">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput type="text" required formControlName="title" />
      <mat-error *ngIf="newOptimalCurveForm.get('title').invalid">Title is required</mat-error>
    </mat-form-field>
  </form>
  <div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <mat-icon>block</mat-icon>
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      form="newOptimalCurveForm"
      [disabled]="newOptimalCurveForm.invalid"
    >
      <mat-icon>save_alt</mat-icon>
      Save
    </button>
  </div>
</ng-template>
