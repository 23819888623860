<div class="container">
  <div class="page-list-header">
    <h1>Clients</h1>
    <button mat-raised-button color="primary" class="btn-success new-button" (click)="createNewClient()">
      <mat-icon aria-hidden="false">add</mat-icon>Create a New Client
    </button>
  </div>

  <app-psb-list [items]="clients" itemsPerPage="10" (view)="onView($event)" (delete)="onDelete($event)"> </app-psb-list>
</div>
