import { Component, OnInit } from '@angular/core';
import { PsbItem } from '@app/core/models/psb-item.model';
import { UserInfo } from '@app/core/models/userInfo.model';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import {
  EVENT_CANCEL,
  EVENT_SUCCESS,
  ROLE_ADMIN,
  ROLE_ORGANIZATIONAL_ADMIN,
  ROLE_TGM,
  ROLE_TEST_USER,
} from '@app/core/constants';
import { UserCreateDialogComponent } from '@app/shared/components/user-create-dialog/user-create-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  users: PsbItem[] = [];
  userData: UserInfo;

  constructor(
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.userData = this.userService.getUserData();

    if (this.userData.authorities.find((authority) => authority.name === ROLE_ADMIN)) {
      this.userService.getAll().subscribe((result: UserInfo[]) => {
        this.users = result.map((user: UserInfo) => this.mapUser(user));
      });
    } else if (
      this.userData.authorities.some((authority) => [ROLE_ORGANIZATIONAL_ADMIN, ROLE_TGM].includes(authority.name))
    ) {
      this.userService.getAllByCompanyId(this.userData.companyId).subscribe((result: UserInfo[]) => {
        this.users = result
          .map((user: UserInfo) => this.mapUser(user))
          .filter((obj, index, array) => {
            return array.findIndex((o) => o.id === obj.id) === index;
          });
      });
    }
  }

  onView(clientId: number) {
    return this.router.navigate([`/users/${clientId}/details`]);
  }

  onDeleteUser(userId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.userService.delete(userId).subscribe(() => {
            this.users = this.users.filter((data) => data.id !== userId);
            this.snackBar.info('User deleted with success.');
          });
        }
      });
  }

  mapUser(user: UserInfo): PsbItem {
    let active: string;
    if (user.activated?.hasOwnProperty('data')) {
      active = parseInt(user.activated['data'], 10) === 1 ? 'Active' : 'Inactive';
    } else {
      active = user.activated ? 'Active' : 'Inactive';
    }
    return {
      id: user.id,
      title: `${user.firstName ? user.firstName : ''} ${user.lastName}`,
      description: user.companyTitle,
      email: user.email,

      extraColumns: [
        {
          key: 'Username',
          value: user.login,
        },
        {
          key: 'Role',
          value: user.authorities ? this.parseRoleAuthority(user.authorities) : '',
        },
        {
          key: 'Email',
          value: user.email,
        },
        {
          key: 'Created Date',
          value: new Date(user.createdDate).toLocaleDateString('en-US'),
        },
        {
          key: 'Status',
          value: active,
        },
      ],
    };
  }

  parseRoleAuthority(role: any) {
    let roles: any[] = JSON.parse(role);
    return roles
      .map((e) => {
        if (e.name === ROLE_ADMIN) {
          return 'Admin';
        } else if (e.name === ROLE_ORGANIZATIONAL_ADMIN) {
          return 'Organizational Admin';
        } else if (e.name === ROLE_TGM) {
          return 'Test Group Manager';
        } else if (e.name === ROLE_TEST_USER) {
          return 'Test User';
        }
      })
      .join(',');
  }

  onCreateUser() {
    const createUserDialog = this.dialog.open(UserCreateDialogComponent, {
      height: '90vh',
      width: '55vw',
    });

    createUserDialog.afterClosed().subscribe((result) => {
      if (result && result.event === EVENT_SUCCESS) {
        this.snackBar.info('User created with success.');
        return this.onView(result.data.userInfoId);
      }
    });
  }
}
