import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// services
import { ReportService } from '@app/core/services/report.service';
import { ChapterService } from '@app/core/services/chapter.service';

// models
import { Chapter } from '@app/core/models/chapter.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-add-chapter-dialog',
  templateUrl: './add-chapter-dialog.component.html',
  styleUrls: ['./add-chapter-dialog.component.scss'],
})
export class AddChapterDialogComponent implements OnInit {
  form = this.fb.group({
    chapterSelect: ['', [Validators.required]],
    chapterInput: ['', [Validators.required]],
    option: ['existing', [Validators.required]],
  });

  chapters: Chapter[];
  filteredChapters: Observable<Chapter[]>;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddChapterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { reportId: number; chaptersIds: number[] },
    private chapterService: ChapterService,
    private reportService: ReportService,
  ) {}

  ngOnInit(): void {
    this.chapterService.getAll().subscribe((chapters) => {
      this.chapters = chapters.filter((chapter) => !this.data.chaptersIds.includes(chapter.id));

      this.filteredChapters = this.form.controls['chapterSelect'].valueChanges.pipe(
        startWith(''),
        map((value) => (value ? this._filter(value) : this.chapters.slice())),
      );
    });
  }

  onSubmit() {
    const { value } = this.form;

    if (value.option === 'new') {
      this.chapterService.create({ title: value.chapterInput }).subscribe((newChapter) => {
        this.reportService.addChapterToReport(this.data.reportId, newChapter.id).subscribe((addedChapter) => {
          this.dialogRef.close({ event: EVENT_SUCCESS, addedChapter });
        });
      });
      return;
    }

    this.reportService.addChapterToReport(this.data.reportId, value.chapterSelect.id).subscribe((addedChapter) => {
      this.dialogRef.close({ event: EVENT_SUCCESS, addedChapter });
    });
  }

  get isInvalidForm() {
    const { option } = this.form.value;

    return (
      !option ||
      (option === 'new' && this.form.get('chapterInput').invalid) ||
      (option === 'existing' && this.form.get('chapterSelect').invalid)
    );
  }

  private _filter(value: string): Chapter[] {
    const filterValue = value;

    if (typeof filterValue === 'object') {
      return this.chapters;
    }

    return this.chapters.filter((option) => option.title.toLowerCase().includes(filterValue.toLowerCase()));
  }

  displayFn(chapter: Chapter): string {
    return chapter.title;
  }
}
