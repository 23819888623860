import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// services
import { FormService } from '@app/core/services/form.service';
import { UserService } from '@app/core/services/user.service';

// models
import { Country } from '@app/core/models/country.model';
import { TimeZone } from '@app/core/models/time-zone.model';
import { State } from '@app/core/models/state.model';
import { Company } from '@app/core/models/company.model';
import { intlTelephoneCodes } from '@app/shared/constants/intl-telephone-codes';
import { INTL_TELEPHONE_PATTERN } from '@app/shared/patterns/pattern-format';
import { Department } from '@app/core/models/department.model';

// constants
import { ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TGM } from '@app/core/constants';

export type UserDetailsDefaultValues = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  gender?: string;
  country?: number;
  state?: number;
  phone?: string;
  language?: string;
  timezone?: number;
  client?: number;
  active?: boolean;
  department?: number;
  authorityName?: string;
  privateScores?: any;
};

@Component({
  selector: 'app-user-details-form',
  templateUrl: './user-details-form.component.html',
  styleUrls: ['./user-details-form.component.scss'],
})
export class UserDetailsFormComponent implements OnInit {
  @Input() defaultValues?: UserDetailsDefaultValues;
  @Output() formSubmit = new EventEmitter<FormGroup>();
  @Output() cancel = new EventEmitter();
  @Input() isUserUpdated?: boolean;

  form = this.fb.group({
    id: ['', [Validators.required]],
    firstName: ['', [Validators.required, Validators.minLength(3)]],
    lastName: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    gender: ['', [Validators.required]],
    country: [''],
    state: [''],
    phone: ['', Validators.compose([Validators.required, Validators.pattern(INTL_TELEPHONE_PATTERN)])],
    language: [''],
    timezone: [''],
    client: ['', [Validators.required]],
    department: [''],
    active: [''],
    authorityName: [''],
    privateScores: [false],
  });

  @Input() countries?: Country[];
  @Input() timeZones?: TimeZone[];
  @Input() companies?: Company[];
  states: State[];
  filteredCompanies: Observable<Company[]>;
  filteredTimeZones: Observable<TimeZone[]>;
  filteredCountries: Observable<Country[]>;
  filteredStates: Observable<State[]>;
  departments: Department[];
  userEmail: string;
  isLoginUserAdmin = false;
  isLoginUserTgm = false;
  isLoginUserOa = false;
  selectedCountry: Country;
  selectedClient: Company;
  selectedTimeZone: TimeZone;
  selectedState: State;

  constructor(private fb: FormBuilder, private formService: FormService, private userService: UserService) {
    const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
    if (authorities.includes(ROLE_ADMIN)) {
      this.isLoginUserAdmin = true;
    } else if (authorities.includes(ROLE_ORGANIZATIONAL_ADMIN)) {
      this.isLoginUserOa = true;
    } else if (authorities.includes(ROLE_TGM)) {
      this.isLoginUserTgm = true;
    }

    this.filteredTimeZones = this.form.controls['timezone'].valueChanges.pipe(
      startWith(''),
      map((timeZone) => (timeZone ? this._filterTimeZone(timeZone) : this.timeZones.slice())),
    );

    this.filteredCompanies = this.form.controls['client'].valueChanges.pipe(
      startWith(''),
      map((company) => (company ? this._filterCompany(company) : this.companies.slice())),
    );

    this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
      startWith(''),
      map((country) => (country ? this._filterCountry(country) : this.countries.slice())),
    );
  }

  ngOnInit(): void {
    if (!this.defaultValues) {
      return;
    }
    this.userEmail = this.defaultValues.email || this.form.value.email;

    if (this.isUserUpdated) {
      this.form.get('email').disable();
    }

    this.selectedClient = this.companies.filter((c) => c && c.id === this.defaultValues.client)[0];
    this.selectedCountry = this.countries.filter((c) => c && c.id === this.defaultValues.country)[0];
    this.selectedTimeZone = this.timeZones.filter((t) => t && t.id === this.defaultValues.timezone)[0];

    this.form.patchValue({
      id: this.defaultValues.id || '',
      firstName: this.defaultValues.firstName || this.form.value.firstName,
      lastName: this.defaultValues.lastName || this.form.value.lastName,
      email: this.defaultValues.email || this.form.value.email,
      gender: this.defaultValues.gender || this.form.value.gender,
      country: this.selectedCountry || this.form.value.country,
      state: this.defaultValues.state || this.form.value.state,
      phone: this.defaultValues.phone || this.form.value.phone,
      language: this.defaultValues.language || this.form.value.language,
      timezone: this.selectedTimeZone || this.form.value.timezone,
      client: this.selectedClient || this.form.value.client,
      department: this.defaultValues.department || this.form.value.department,
      active: this.defaultValues.active || this.form.value.active,
      authorityName: this.defaultValues.authorityName || this.form.value.authorityName,
      privateScores: this.defaultValues.privateScores || this.form.value.privateScores,
    });

    if (this.form.value.country) {
      this.countries.filter((country) => {
        if (country) {
          if (country.id === this.form.value.country.id) {
            this.form.get('country').setValue(country);
          }
        }
      });

      this.formService.getStatesByCountry(this.form.value.country.id).subscribe((response) => {
        this.states = [null, ...response];
        this.selectedState = this.states.filter((s) => s && s.id === this.defaultValues.state)[0];
        this.form.get('state').setValue(this.selectedState);

        this.filteredStates = this.form.controls['state'].valueChanges.pipe(
          startWith(''),
          map((state) => (state ? this._filterState(state) : this.states.slice())),
        );
      });
    }

    if (this.form.value.client) {
      this.formService.getDepartmentByCompanyId(this.form.value.client.id).subscribe(
        (result: any) => {
          this.departments = result;
          if (this.form.value.client && this.departments.length) {
            this.form.controls['department'].setValue(this.form.value.department);
          } else {
            this.form.controls['department'].setValue('');
          }
        },
        () => {
          this.departments = [];
          this.form.controls['department'].setValue('');
        },
      );
    }
  }

  onClientChange(event) {
    if (event.value) {
      this.formService.getDepartmentByCompanyId(this.form.value.client).subscribe(
        (result: any) => {
          this.departments = result;
        },
        () => {
          this.departments = [];
          this.form.controls['department'].setValue('');
        },
      );
    }
  }

  onFocusOutEmail() {
    if (
      this.form.get('email').value &&
      this.form.get('email').value.length > 0 &&
      this.form.get('email').value.trim() !== this.userEmail.trim()
    ) {
      this.userService.checkExistedEmail(this.form.get('email').value).subscribe((result) => {
        if (result) {
          this.form.get('email').setErrors({ isEmailExist: true });
        }
      });
    }
  }

  onSelectCountry(countryId: number) {
    if (countryId) {
      this.form.get('state').setValue('');
      const intlCode = this.form.get('country').value.abbrev;
      const countryId = Number(this.form.value.country.id);

      this.formService.getStatesByCountry(countryId).subscribe((response) => {
        this.states = [null, ...response];

        this.filteredStates = this.form.controls['state'].valueChanges.pipe(
          startWith(''),
          map((state) => (state ? this._filterState(state) : this.states.slice())),
        );
      });

      // When we have a phone number, changing countries will not update the phone number.
      if (this.form.get('phone').value.length < 3) {
        this.intlTelephoneCode(intlCode);
      }
    }
  }

  intlTelephoneCode(intlCode: string) {
    const selectedIntlCode = intlTelephoneCodes.filter((e: any) => e.code === intlCode);
    if (selectedIntlCode.length) {
      this.form.get('phone').setValue(`+${selectedIntlCode[0].callingCode}`);
    }
  }

  displayCompany(client: Company) {
    return client ? client.name : '';
  }

  displayTimeZone(timeZone: TimeZone) {
    return timeZone ? timeZone.name : '';
  }

  displayCountry(country: Country): string {
    return country ? country.name : '';
  }

  displayState(state: State): string {
    return state ? state.name : '';
  }

  private _filterCompany(value: string): Company[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.companies;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.companies.filter((company: Company) => {
      if (company) {
        return company.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterTimeZone(value: string): TimeZone[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.timeZones;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.timeZones.filter((timezone: TimeZone) => {
      if (timezone) {
        return timezone.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterCountry(value: string): Country[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.countries;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.countries.filter((country: Country) => {
      if (country) {
        return country.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterState(value: string): State[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.states;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.states.filter((state: State) => {
      if (state) {
        return state.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }
}
