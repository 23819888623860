<div class="container">
  <div class="page-list-header">
    <h1>Charts</h1>
    <button mat-raised-button color="primary" class="new-chart-button" (click)="onCreateNewChart()">
      <mat-icon>add</mat-icon>
      Create a new chart
    </button>
  </div>

  <app-psb-list [items]="chartPsbItems" (view)="onDashboard($event)" (delete)="onDelete($event)"></app-psb-list>
</div>
