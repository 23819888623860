import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chart-subsection',
  templateUrl: './chart-subsection.component.html',
  styleUrls: ['./chart-subsection.component.scss'],
})
export class ChartSubsectionComponent implements OnInit {
  @Input() chart: any;

  constructor() {}

  ngOnInit(): void {
    this.chart.superscaleLeftTestGroupResults = [];
    this.chart.superscaleRightTestGroupResults = [];
    this.chart.subscaleCenterTestGroupResults = [];

    for (let index = 0; index < 10; index++) {
      this.chart.superscaleLeftTestGroupResults.push({ percentile: Math.random() * 100 });
      this.chart.superscaleRightTestGroupResults.push({ percentile: Math.random() * 100 });
      this.chart.subscaleCenterTestGroupResults.push({ percentile: Math.random() * 100 });
    }
  }
}
