/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Report } from '../models/Report';
import type { TestGroupChartComparison } from '../models/TestGroupChartComparison';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ReportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Export a report
     * @returns any Export a report with report type
     * @throws ApiError
     */
    public getReportDataDownload(): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report-data-download',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Retrieve a list with all reports
     * Retrieve a list with all reports
     * @returns Report A list of reports
     * @throws ApiError
     */
    public getReport(): Observable<Array<Report>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a report
     * Create a report
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postReport(
        requestBody?: {
            /**
             * Title of the report
             */
            title: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a report by id
     * Retrieve a report by id
     * @param id Id of the report
     * @returns Report A report
     * @throws ApiError
     */
    public getReport1(
        id: number,
    ): Observable<Report> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a report
     * Delete a report
     * @param id Id of the report
     * @returns any Report deleted successfully
     * @throws ApiError
     */
    public deleteReport(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/report/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update a report
     * Update a report
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchReport(
        id: number,
        requestBody?: {
            /**
             * Title of the report
             */
            title: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve user's connection indicator report
     * Retrieve user's connection indicator report
     * @param userId Id of the user
     * @returns any User's report
     * @throws ApiError
     */
    public getReportUserConnectionIndicatorDownload(
        userId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/user/{userId}/connection-indicator/download',
            path: {
                'userId': userId,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve connection indicator report
     * Retrieve connection indicator report
     * @param id Id of the connection indicator
     * @returns any Connection Indicator report
     * @throws ApiError
     */
    public getReportConnectionIndicatorDownload(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/connection-indicator/{id}/download',
            path: {
                'id': id,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve user's report
     * Retrieve user's report
     * @param id Id of the report
     * @param userId Id of the user
     * @param testGroupId Id of the test group
     * @returns any User's report
     * @throws ApiError
     */
    public getReportUserTestGroup(
        id: number,
        userId: number,
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/{id}/user/{userId}/test-group/{testGroupId}',
            path: {
                'id': id,
                'userId': userId,
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a chapter from the report
     * Remove a chapter from the report
     * @param reportId Id of the report
     * @param chapterId Id of the chapter
     * @returns any A report
     * @throws ApiError
     */
    public deleteReportChapter(
        reportId: number,
        chapterId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/report/{reportId}/chapter/{chapterId}',
            path: {
                'reportId': reportId,
                'chapterId': chapterId,
            },
            errors: {
                404: `Report or Chapter not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Add a chapter to a report
     * Add a chapter to a report
     * @param id Id of the report
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postReportChapter(
        id: number,
        requestBody?: {
            /**
             * Id of the chapter
             */
            chapterId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/{id}/chapter',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Add a chart to a report
     * Add a chart to a report
     * @param id Id of the report
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postReportChart(
        id: number,
        requestBody?: {
            /**
             * Id of the chart
             */
            chartId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/{id}/chart/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a chart from the report
     * Remove a chart from the report
     * @param reportId Id of the report
     * @param chartId Id of the chart
     * @returns any A report
     * @throws ApiError
     */
    public deleteReportChart(
        reportId: number,
        chartId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/report/{reportId}/chart/{chartId}',
            path: {
                'reportId': reportId,
                'chartId': chartId,
            },
            errors: {
                404: `Report or Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Returns a list with TestGroupChartComparison by testGroupId and chartId
     * Returns a list with TestGroupChartComparison by the given testGroupId and chartId
     * @param testGroupId Id of the TestGroup
     * @param chartId Id of the Chart
     * @returns TestGroupChartComparison A list with TestGroupChartComparison
     * @throws ApiError
     */
    public getReportTestGroupChartComparisonTestGroupChart(
        testGroupId: number,
        chartId: number,
    ): Observable<TestGroupChartComparison> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/test-group-chart-comparison/test-group/{testGroupId}/chart/{chartId}',
            path: {
                'testGroupId': testGroupId,
                'chartId': chartId,
            },
            errors: {
                404: `TestGroupChartComparison not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Updates a TestGroupChartComparison
     * Updates a TestGroupChartComparison
     * @param testGroupId Id of the TestGroup
     * @param chartId Id of the Chart
     * @param hided Hide or not
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchReportTestGroupComparison(
        testGroupId: number,
        chartId: number,
        hided: boolean,
        requestBody?: {
            /**
             * Id of the TestGroup
             */
            testGroupId: number;
            /**
             * Id of the Chart
             */
            chartId: number;
            /**
             * Id of the UserInfo
             */
            userInfoId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/test-group-comparison',
            path: {
                'testGroupId': testGroupId,
                'chartId': chartId,
                'hided': hided,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `TestGroupChartComparison not found`,
                500: `Internal error`,
            },
        });
    }

}
