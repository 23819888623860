<div class="container">
  <form [formGroup]="form">
    <h1>Preferred Ranges</h1>
    <h3>Test Group: {{ testGroupName }}</h3>
    <button mat-raised-button color="primary" type="submit" (click)="onSave()">
      <mat-icon>save_alt</mat-icon>Save
    </button>
    <table>
      <colgroup>
        <col style="width: 10rem" />
        <col class="range" />
        <col class="range" />
        <col class="range" />
        <col class="range" />
        <col class="range" />
      </colgroup>
      <thead>
        <tr>
          <th class="title"></th>
          <th colspan="5" class="title">Preferred Ranges</th>
        </tr>
        <tr>
          <th class="empty-border-top title">Superscale</th>
          <th>Out of Range: Low</th>
          <th>Cautionary: Low</th>
          <th>Optimal</th>
          <th>Cautionary: High</th>
          <th>Out of Range: High</th>
        </tr>
        <tr>
          <th class="empty-border-top title"></th>
          <th>From - To</th>
          <th>From - To</th>
          <th>From - To</th>
          <th>From - To</th>
          <th>From - To</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let testGroupSuperscaleControl of testGroupSuperscalesFormArray.controls; let i = index"
          [formGroup]="testGroupSuperscaleControl"
        >
          <td>
            <p>{{ testGroupSuperscaleControl.get('superscale').value }}</p>
          </td>
          <td [formGroup]="testGroupSuperscaleControl.get('ranges.outOfRangeLow')">
            <div class="range">
              <mat-form-field>
                <mat-label>From</mat-label>
                <input matInput type="number" min="0" required formControlName="outOfRangeLowFrom" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>To</mat-label>
                <input matInput type="number" min="0" required formControlName="outOfRangeLowTo" />
              </mat-form-field>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.outOfRangeLow.outOfRangeLowText').value === ''"
                mat-flat-button
                color="primary"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.outOfRangeLow.outOfRangeLowText'),
                    'Out of Range: low'
                  )
                "
              >
                Add Text
              </button>
              <p
                *ngIf="testGroupSuperscaleControl.get('ranges.outOfRangeLow.outOfRangeLowText').value !== ''"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.outOfRangeLow.outOfRangeLowText'),
                    'Out of Range: low'
                  )
                "
              >
                {{ testGroupSuperscaleControl.get('ranges.outOfRangeLow.outOfRangeLowText').value }}
              </p>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.outOfRangeLow.outOfRangeLowText').value !== ''"
                mat-flat-button
                color="primary"
                (click)="
                  removeTextDialog(
                    testGroupSuperscaleControl.get('ranges.outOfRangeLow.outOfRangeLowText'),
                    'Out of Range: low'
                  )
                "
              >
                Remove Text
              </button>
            </div>
          </td>
          <td [formGroup]="testGroupSuperscaleControl.get('ranges.cautionaryLow')">
            <div class="range">
              <mat-form-field>
                <mat-label>From</mat-label>
                <input matInput type="number" min="0" required formControlName="cautionaryLowFrom" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>To</mat-label>
                <input matInput type="number" min="0" required formControlName="cautionaryLowTo" />
              </mat-form-field>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.cautionaryLow.cautionaryLowText').value === ''"
                mat-flat-button
                color="primary"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.cautionaryLow.cautionaryLowText'),
                    'Cautionary: Low'
                  )
                "
              >
                Add Text
              </button>
              <p
                *ngIf="testGroupSuperscaleControl.get('ranges.cautionaryLow.cautionaryLowText').value !== ''"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.cautionaryLow.cautionaryLowText'),
                    'Cautionary: Low'
                  )
                "
              >
                {{ testGroupSuperscaleControl.get('ranges.cautionaryLow.cautionaryLowText').value }}
              </p>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.cautionaryLow.cautionaryLowText').value !== ''"
                mat-flat-button
                color="primary"
                (click)="
                  removeTextDialog(
                    testGroupSuperscaleControl.get('ranges.cautionaryLow.cautionaryLowText'),
                    'Cautionary: Low'
                  )
                "
              >
                Remove Text
              </button>
            </div>
          </td>
          <td [formGroup]="testGroupSuperscaleControl.get('ranges.optimal')">
            <div class="range">
              <mat-form-field>
                <mat-label>From</mat-label>
                <input matInput type="number" min="0" required formControlName="optimalFrom" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>To</mat-label>
                <input matInput type="number" min="0" required formControlName="optimalTo" />
              </mat-form-field>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.optimal.optimalText').value === ''"
                mat-flat-button
                color="primary"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.optimal.optimalText'),
                    'Optimal'
                  )
                "
              >
                Add Text
              </button>
              <p
                *ngIf="testGroupSuperscaleControl.get('ranges.optimal.optimalText').value !== ''"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.optimal.optimalText'),
                    'Optimal'
                  )
                "
              >
                {{ testGroupSuperscaleControl.get('ranges.optimal.optimalText').value }}
              </p>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.optimal.optimalText').value !== ''"
                mat-flat-button
                color="primary"
                (click)="removeTextDialog(testGroupSuperscaleControl.get('ranges.optimal.optimalText'), 'Optimal')"
              >
                Remove Text
              </button>
            </div>
          </td>
          <td [formGroup]="testGroupSuperscaleControl.get('ranges.cautionaryHigh')">
            <div class="range">
              <mat-form-field>
                <mat-label>From</mat-label>
                <input matInput type="number" min="0" required formControlName="cautionaryHighFrom" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>To</mat-label>
                <input matInput type="number" min="0" required formControlName="cautionaryHighTo" />
              </mat-form-field>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.cautionaryHigh.cautionaryHighText').value === ''"
                mat-flat-button
                color="primary"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.cautionaryHigh.cautionaryHighText'),
                    'Cautionary: High'
                  )
                "
              >
                Add Text
              </button>
              <p
                *ngIf="testGroupSuperscaleControl.get('ranges.cautionaryHigh.cautionaryHighText').value !== ''"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.cautionaryHigh.cautionaryHighText'),
                    'Cautionary: High'
                  )
                "
              >
                {{ testGroupSuperscaleControl.get('ranges.cautionaryHigh.cautionaryHighText').value }}
              </p>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.cautionaryHigh.cautionaryHighText').value !== ''"
                mat-flat-button
                color="primary"
                (click)="
                  removeTextDialog(
                    testGroupSuperscaleControl.get('ranges.cautionaryHigh.cautionaryHighText'),
                    'Cautionary: High'
                  )
                "
              >
                Remove Text
              </button>
            </div>
          </td>
          <td [formGroup]="testGroupSuperscaleControl.get('ranges.outOfRangeHigh')">
            <div class="range">
              <mat-form-field>
                <mat-label>From</mat-label>
                <input matInput type="number" min="0" required formControlName="outOfRangeHighFrom" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>To</mat-label>
                <input matInput type="number" min="0" required formControlName="outOfRangeHighTo" />
              </mat-form-field>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.outOfRangeHigh.outOfRangeHighText').value === ''"
                mat-flat-button
                color="primary"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.outOfRangeHigh.outOfRangeHighText'),
                    'Out Of Range: high'
                  )
                "
              >
                Add Text
              </button>
              <p
                *ngIf="testGroupSuperscaleControl.get('ranges.outOfRangeHigh.outOfRangeHighText').value !== ''"
                (click)="
                  openAddTextDialog(
                    addTextForRangeDialog,
                    testGroupSuperscaleControl.get('ranges.outOfRangeHigh.outOfRangeHighText'),
                    'Out Of Range: high'
                  )
                "
              >
                {{ testGroupSuperscaleControl.get('ranges.outOfRangeHigh.outOfRangeHighText').value }}
              </p>
              <button
                *ngIf="testGroupSuperscaleControl.get('ranges.outOfRangeHigh.outOfRangeHighText').value !== ''"
                mat-flat-button
                color="primary"
                (click)="
                  removeTextDialog(
                    testGroupSuperscaleControl.get('ranges.outOfRangeHigh.outOfRangeHighText'),
                    'Out Of Range: high'
                  )
                "
              >
                Remove Text
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <ng-template #addTextForRangeDialog>
    <h1 mat-dialog-title>Write text</h1>
    <mat-divider></mat-divider>
    <h2>{{ textForRangeTitle }}</h2>
    <angular-editor [formControl]="addTextEditorControl" [config]="editorConfig"></angular-editor>
    <div align="end" mat-dialog-actions>
      <button mat-flat-button (click)="closeAddTextDialog()">
        <mat-icon>cancel</mat-icon>
        Cancel
      </button>
      <button mat-flat-button [disabled]="addTextEditorControl.invalid" color="primary" (click)="saveAddTextDialog()">
        <mat-icon>save_alt</mat-icon>
        Save
      </button>
    </div>
  </ng-template>
</div>
