import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spreads-table',
  templateUrl: './spreads-table.component.html',
  styleUrls: ['./spreads-table.component.scss'],
})
export class SpreadsTableComponent implements OnInit {
  @Input() spreads: any[];
  dataSource: any[];

  displayedColumns: string[];
  columns: any[];
  editRow: string;

  constructor() {}

  ngOnInit(): void {
    this.loadData();
  }

  update() {
    this.loadData();
  }

  loadData() {
    let spreads = Object.values(this.spreads)
      .map((elem) => elem.spread)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();

    spreads.sort((a, b) => a - b);

    this.columns = [
      {
        columnDef: 'percentile',
        header: 'Percentile',
        cell: (elem: any) => `${elem.percentile}`,
      },
    ];

    this.columns = [
      ...this.columns,
      ...spreads
        .filter((item) => item !== undefined)
        .map((spread) => {
          return {
            columnDef: `spread${spread}`,
            header: `Spread ${spread}`,
            spread,
            cell: (elem: any) => elem[`spread${spread}`],
          };
        }),
    ];

    this.displayedColumns = this.columns.filter((item) => item !== undefined).map((c) => c.columnDef);

    const spreadsMap = new Map(Object.entries(this.spreads));

    const dataSource = new Map();

    spreadsMap.forEach((value, key) => {
      const obj = dataSource.has(value.percentile) ? dataSource.get(value.percentile) : {};
      let startPercentile;
      if (value.percentile === 0) {
        startPercentile = value.percentile + 1;
      }
      obj['percentile'] = startPercentile ? startPercentile : value.percentile;
      obj[`spread${value.spread}`] = value.curveNumber;

      dataSource.set(value.percentile, obj);
    });

    let data = [];

    dataSource.forEach((values, key) => {
      data = [...data, values];
    });

    this.dataSource = data;
  }

  isSticky(columnDef: string) {
    return columnDef === 'percentile';
  }

  edit(row) {
    this.editRow = row.percentile;
  }

  onChangeEvent(column, row, event: any) {
    row[column.columnDef] = event.target.value;
    const key = [...this.spreads.entries()]
      .filter(({ 1: value }) => value.percentile === row.percentile && value.spread === column.spread)
      .map(([k]) => k)[0];

    this.spreads[key].curveNumber = Number(event.target.value);
  }
}
