import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class MfaEnrollService {
  constructor(private http: HttpClient) {}

  initializeMFA(userId: number) {
    return this.http.post(BACKEND_URL + '/authentication/init/', {
      userInfoId: userId,
    });
  }

  mfaQueryAttributes(userId: number) {
    return this.http.get<any>(BACKEND_URL + '/authentication/find-attributes/' + userId);
  }

  sendText(userId: number) {
    return this.http.post(BACKEND_URL + '/authentication/send-enrollment-text/', { userInfoId: userId });
  }

  sendEmail(userId: number) {
    return this.http.post(BACKEND_URL + '/authentication/send-enrollment-email/', { userInfoId: userId });
  }

  verifyAppCode(userId: number, code: string) {
    return this.http.post(BACKEND_URL + '/authentication/verify-app-code/', {
      userInfoId: userId,
      code: `${code}`,
    });
  }
}
