<h1>{{ chart.heading }}</h1>

<p [innerHtml]="chart.leadSentence.he"></p>

<div class="scales-and-chart-grid">
  <div class="subscales" *ngIf="chart.useSubscale">
    <table>
      <thead>
        <tr>
          <th>Subscale Left</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ chart.subscales?.left?.title }}</td>
        </tr>
      </tbody>
    </table>

    <table>
      <thead>
        <tr>
          <th>Subscale Center</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ chart.subscales?.center?.title }}</td>
        </tr>
      </tbody>
    </table>

    <table>
      <thead>
        <tr>
          <th>Subscale Right</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ chart.subscales?.right?.title }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="superscales" *ngIf="!chart.useSubscale">
    <table>
      <thead>
        <tr>
          <th>Superscale Left</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ chart.superscales?.left?.title }}</td>
        </tr>
      </tbody>
    </table>

    <table>
      <thead>
        <tr>
          <th>Subscale Center</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ chart.superscales?.center?.title }}</td>
        </tr>
      </tbody>
    </table>

    <table>
      <thead>
        <tr>
          <th>Superscale Right</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ chart.superscales?.right?.title }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-violin
    class="agility-chart"
    [chartName]="chart.title"
    [leftValue]="50"
    [centerValue]="40"
    [rightValue]="50"
    [leftLabel]="chart.labels.left"
    [centerLabel]="chart.labels.center"
    [rightLabel]="chart.labels.right"
    [scalesLeftResults]="chart.superscaleLeftTestGroupResults"
    [scalesRightResults]="chart.superscaleRightTestGroupResults"
    [scalesCenterResults]="chart.subscaleCenterTestGroupResults"
  ></app-violin>
</div>

<h2>Double Ranges</h2>
<table>
  <colgroup>
    <col class="num-col" />
    <col class="num-col" />
    <col class="num-col" />
    <col class="num-col" />
    <col />
  </colgroup>
  <thead>
    <tr>
      <th colspan="2">{{ chart.useSubscale ? 'Subscale' : 'Superscale' }} Left</th>
      <th colspan="2">{{ chart.useSubscale ? 'Subscale' : 'Superscale' }} Right</th>
    </tr>
    <tr>
      <th>From</th>
      <th>To</th>
      <th>From</th>
      <th>To</th>
      <th>Text</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let doubleRange of chart.doubleRanges">
      <td>{{ doubleRange.from1 }}</td>
      <td>{{ doubleRange.to1 }}</td>
      <td>{{ doubleRange.from2 }}</td>
      <td>{{ doubleRange.to2 }}</td>
      <td class="tests">
        <div [innerHtml]="doubleRange.text.he"></div>
        <div [innerHtml]="doubleRange.text.she"></div>
        <div [innerHtml]="doubleRange.text.they"></div>
      </td>
    </tr>
  </tbody>
</table>

<h2>Agility - Both above 50</h2>
<table>
  <colgroup>
    <col class="num-col" />
    <col class="num-col" />
    <col />
  </colgroup>
  <thead>
    <tr>
      <th>From</th>
      <th>To</th>
      <th>Text</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let range of chart.ranges.centerAbove50">
      <td>{{ range.from }}</td>
      <td>{{ range.to }}</td>
      <td [innerHTML]="range.text.he"></td>
    </tr>
  </tbody>
</table>

<h2>Agility - Both below 50</h2>
<table>
  <colgroup>
    <col class="num-col" />
    <col class="num-col" />
    <col />
  </colgroup>
  <thead>
    <tr>
      <th>From</th>
      <th>To</th>
      <th>Text</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let range of chart.ranges.centerBelow50">
      <td>{{ range.from }}</td>
      <td>{{ range.to }}</td>
      <td [innerHTML]="range.text.he"></td>
    </tr>
  </tbody>
</table>

<h2>Agility - One above 50 One below 50</h2>
<table>
  <colgroup>
    <col class="num-col" />
    <col class="num-col" />
    <col />
  </colgroup>
  <thead>
    <tr>
      <th>From</th>
      <th>To</th>
      <th>Text</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let range of chart.ranges.centerAboveBelow">
      <td>{{ range.from }}</td>
      <td>{{ range.to }}</td>
      <td [innerHTML]="range.text.he"></td>
    </tr>
  </tbody>
</table>
