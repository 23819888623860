<div class="container">
  <app-psb-page-navigator [pages]="pages"></app-psb-page-navigator>

  <h2>Position Titles</h2>
  <form [formGroup]="newPositionTitleForm" (submit)="createNewPositionTitle()" class="add-position">
    <mat-form-field>
      <input matInput type="text" formControlName="title" placeholder="Position Title" />
      <mat-error *ngIf="newPositionTitleForm.get('title').errors">Please enter the valid title.</mat-error>
      <mat-hint>Enter the positions to be filled on this Role Navigator</mat-hint>
    </mat-form-field>
    <button mat-raised-button class="btn-success new" type="submit">Add</button>
  </form>

  <mat-table
    [dataSource]="roleMandatePositions"
    *ngIf="roleMandatePositions.data.length > 0"
    class="mat-elevation-z3"
    matSort
  >
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.title }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actionDelete">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button class="btn-error" (click)="onDeletePosition(element)">Delete</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedPositionColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedPositionColumns"></mat-row>
  </mat-table>
  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>

  <button mat-raised-button class="btn-success btn-center btn-300" (click)="onNext()">Next</button>
</div>
