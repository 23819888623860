import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StateDetailComponent } from './state-detail/state-detail.component';

const stateRoutes: Routes = [{ path: ':id/detail', component: StateDetailComponent }];

@NgModule({
  imports: [RouterModule.forChild(stateRoutes)],
  exports: [RouterModule],
})
export class StateRoutingModule {}
