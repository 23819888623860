import { Component, OnInit, Input, ViewChild, Output, OnChanges, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Options, LabelType, CustomStepDefinition } from '@angular-slider/ngx-slider';

// services
import { AnswerService } from '@app/core/services/answer.service';
import { QuestionService } from '@app/core/services/question.service';
import { TestService } from '@app/core/services/test.service';
// models
import { Question } from '@app/core/models/question.model';
import { IntegerAnswer } from '@app/core/models/integer-answer.model';
import { UserAnswer } from '@app/core/models/user-answer.model';

@Component({
  selector: 'app-answer-slider',
  templateUrl: './answer-slider.component.html',
  styleUrls: ['./answer-slider.component.scss'],
})
export class AnswerSliderComponent implements OnInit, OnChanges {
  @ViewChild('slider') sliderElement: any;

  @Input() question: Question;
  @Input() loadAnswer: boolean;
  @Input() answering: boolean;
  @Input() psyTestId: number;
  @Input() superTestId: number;
  @Input() userInfoId: number;
  @Input() testResultId: number;
  @Input() testAnswerRequired?: boolean;
  @Input() isPreview: boolean;
  @Output() userAnswer = new EventEmitter<{ questionId: number; userAnswer: UserAnswer }>();

  slideAnswer: IntegerAnswer;
  slideValue: number;
  options: Options;
  answersRange: any[] = [];
  answersId: any[] = [];
  isLoading: boolean;
  answerStyle: string;

  constructor(
    private questionService: QuestionService,
    private answerService: AnswerService,
    private cDRef: ChangeDetectorRef,
    private testService: TestService,
  ) {}

  ngOnInit(): void {
    // TODO: Need refact
    this.isLoading = true;
    this.init();
    this.userInfoId = this.userInfoId ? this.userInfoId : +localStorage.getItem('userInfoId');
    if (!this.isPreview) {
      if (this.answering) {
        if (!this.slideAnswer && !this.slideValue) {
          if (this.question.userAnswer && this.question.userAnswer.integerAnswer) {
            this.loadAnswerFromUserAnswer(this.question.userAnswer);
          }
        }
      } else {
        if (this.question.userAnswer?.integerAnswer) {
          this.slideAnswer = this.question.userAnswer.integerAnswer;
          this.slideValue = this.question.userAnswer.integerAnswer.answer;
        } else {
          this.questionService
            .findIntegerAnswerByQuestionIdAndTestResultId(this.question.id, this.testResultId)
            .subscribe((answer) => {
              if (answer) {
                this.slideAnswer = answer;
                this.slideValue = answer.answer;
                this.isLoading = false;
              }
            });
        }
      }
    }
  }

  ngOnChanges(): void {
    if (this.testAnswerRequired) {
      this.testService.getUnAnswerQuestionIds().subscribe((unAnswerQuestionIds) => {
        if (unAnswerQuestionIds && unAnswerQuestionIds.length > 0 && unAnswerQuestionIds.includes(this.question.id)) {
          this.saveFirstOption();
          this.cDRef.detectChanges();
        }
      });
    }
  }

  loadAnswerFromUserAnswer(userAnswer: UserAnswer) {
    this.slideAnswer = {
      answerId: userAnswer.integerAnswer.id,
      answer: userAnswer.integerAnswer.answer,
      questionId: this.question.id,
      testResultId: this.testResultId,
    };

    this.slideValue = userAnswer.integerAnswer.answer;
  }

  valueToLegend(value: number) {
    for (const answer of this.answersRange) {
      if (answer[value]) {
        return answer[value];
      }
    }
  }

  saveSliderAnswer() {
    if (this.superTestId || this.psyTestId) {
      if (this.slideAnswer && this.slideAnswer.answerId) {
        this.slideAnswer.answer = this.slideValue;
        this.answerService.updateIntegerAnswer(this.question.id, this.slideAnswer).subscribe(() => {});
      } else {
        this.slideAnswer = {
          questionId: this.question.id,
          userInfoId: this.userInfoId,
          answer: this.slideValue,
          testResultId: this.testResultId,
        };

        this.answerService.saveIntegerAnswer(this.question.id, this.slideAnswer).subscribe((result) => {
          const userAnswer = {
            questionId: this.question.id,
            userAnswer: {
              integerAnswer: { id: result.id, answer: this.slideValue },
              textAnswer: null,
              listAnswer: null,
            },
          };
          this.userAnswer.emit(userAnswer);
          this.slideAnswer.answerId = result.id;
        });
      }
    }
  }

  init(): void {
    this.question.answers = this.question.answers.sort((a, b) => {
      if (a.text && b.text) {
        return Number(a.text) - Number(b.text);
      } else {
        return 0;
      }
    });

    this.question.answers.map((answer) => {
      if (answer.id) {
        const value = answer.id;
        const legend = answer.text;
        const step = {};
        step[value] = legend;
        this.answersRange.push(step);
        this.answersId.push(value);
      }
    });

    this.options = {
      showTicksValues: true,
      stepsArray: this.answersId.map((id: number): CustomStepDefinition => {
        return { value: id };
      }),
      hideLimitLabels: true,
      translate: (value: number, label: LabelType): string => {
        return this.valueToLegend(value);
      },
      disabled: this.isPreview || !this.answering,
      vertical: window.innerWidth > 768 ? false : true,
    };
  }

  saveFirstOption(): void {
    if ((this.superTestId || this.psyTestId) && this.options) {
      this.slideAnswer = {
        questionId: this.question.id,
        userInfoId: this.userInfoId,
        answer: this.options.stepsArray[0].value,
        testResultId: this.testResultId,
      };

      this.answerService.saveIntegerAnswer(this.question.id, this.slideAnswer).subscribe((result) => {
        const userAnswer = {
          questionId: this.question.id,
          userAnswer: {
            integerAnswer: { id: result.id, answer: this.slideValue },
            textAnswer: null,
            listAnswer: null,
          },
        };
        this.userAnswer.emit(userAnswer);
        this.slideAnswer.answerId = result.id;
      });
    }
  }
}
