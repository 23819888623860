import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TextAnswer } from '@app/core/models/text-answer.model';

@Component({
  selector: 'app-edit-answer',
  templateUrl: './edit-answer.component.html',
  styleUrls: ['./edit-answer.component.scss'],
})
export class EditAnswerComponent implements OnInit {
  editAnswerControl: any;

  constructor(
    public dialogRef: MatDialogRef<EditAnswerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { answer: TextAnswer },
  ) {}

  ngOnInit(): void {
    this.editAnswerControl = new FormControl(this.data.answer.answer);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.dialogRef.close(this.editAnswerControl.value);
  }
}
