<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Label</mat-label>
    <input matInput type="text" formControlName="label" />
    <mat-error>Enter label</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Color</mat-label>
    <input formControlName="color" matInput [ngxMatColorPicker]="picker" />
    <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
    <ngx-mat-color-picker #picker [touchUi]="true"></ngx-mat-color-picker>
    <mat-error>Enter valid color</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Order</mat-label>
    <input matInput type="number" formControlName="order" />
    <mat-error>Enter valid order number</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Superscale</mat-label>
    <input #input required matInput type="text" formControlName="superscaleId" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySuperscaleFn">
      <mat-option *ngFor="let superscale of filterSuperscale(input.value)" [value]="superscale.id">
        <div class="double-line-option">
          {{ superscale.title }}
          <div *ngIf="superscale.description" class="small-text">{{ superscale.description }}</div>
        </div>
      </mat-option>
    </mat-autocomplete>
    <mat-error>Select superscale</mat-error>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="save()" type="submit" [disabled]="form.invalid">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</form>
