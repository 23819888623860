import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-copy-dialog',
  templateUrl: './copy-dialog.component.html',
  styleUrls: ['./copy-dialog.component.scss'],
})
export class CopyDialogComponent {
  public title: string;
  public type: string;

  constructor(public dialogRef: MatDialogRef<CopyDialogComponent>) {}

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onCopy() {
    this.dialogRef.close({ event: 'copy' });
  }
}
