import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

// models
import { Company } from '@app/core/models/company.model';

@Component({
  selector: 'app-stats-company',
  templateUrl: './stats-company.component.html',
  styleUrls: ['./stats-company.component.scss'],
})
export class StatsCompanyComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() companies: Company[];

  @Output() selectCompany = new EventEmitter<number>();

  dataSource: MatTableDataSource<Company>;
  displayedColumns: string[] = ['name', 'dateAdded'];
  lineChartLabels: string[];
  lineChartData: number[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    this.loadData();
    this.dataSource = new MatTableDataSource(this.companies);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.loadData();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadData() {
    this.dataSource = new MatTableDataSource(this.companies);

    const result = [];
    this.companies.reduce((res, value) => {
      if (!res[value.dateAdded]) {
        res[value.dateAdded] = { dateAdded: value.dateAdded, qty: 0 };
        result.push(res[value.dateAdded]);
      }
      res[value.dateAdded].qty++;
      return res;
    }, {});

    this.lineChartLabels = result.map((data) => data.dateAdded);
    this.lineChartData = result.map((data) => data.qty);
  }

  select(company: Company) {
    this.selectCompany.emit(company.id);
  }
}
