import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';
import { DecisionTableRelation } from '@app/core/models/decision-table-relation.model';

export interface DialogData {
  decisionTableRelation: DecisionTableRelation;
  decisionTables: DecisionTable[];
}

@Component({
  selector: 'app-edit-decision-table-connection',
  templateUrl: './edit-decision-table-connection.component.html',
  styleUrls: ['./edit-decision-table-connection.component.scss'],
})
export class EditDecisionTableConnectionComponent implements OnInit {
  @Output() save = new EventEmitter<DecisionTableRelation>();

  decisionTables: DecisionTable[];
  decisionTableRelation: DecisionTableRelation;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EditDecisionTableConnectionComponent>,
    private decisionTableService: DecisionTableService,
  ) {}

  ngOnInit(): void {
    this.decisionTables = this.data.decisionTables;
    this.decisionTableRelation = this.data.decisionTableRelation;
  }

  onSave(decisionTableRelation: DecisionTableRelation) {
    this.dialogRef.close({ event: 'confirm', data: decisionTableRelation });
  }

  getFormName(columnTitle: string) {
    return this.decisionTableService.getFormName(columnTitle);
  }
}
