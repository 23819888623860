<div class="container">
  <h1>Users</h1>
  <button mat-raised-button color="primary" class="btn-success create-button" (click)="onCreateUser()">
    <mat-icon aria-hidden="false">add</mat-icon>Create a New User
  </button>
  <app-psb-list
    [items]="users"
    itemsPerPage="10"
    [columnNumber]="6"
    (view)="onView($event)"
    (delete)="onDeleteUser($event)"
  >
  </app-psb-list>
</div>
