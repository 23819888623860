import { Component, Input, OnInit } from '@angular/core';
import { PsyChart } from '@app/core/models/psy-chart.model';
import { MatTableDataSource } from '@angular/material/table';
import { SubscaleService } from '@app/core/services/subcale.service';
import { BlossomChartPetal, BlossomChartPistil, BlossomChartService } from '../../../../../../generated/v2';
import { combineAll, map, shareReplay, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Subscale } from '@app/core/models/subscale.model';
import { BlossomPistilFormComponent } from '@app/admin/pages/chart/blossom/blossom-pistil-setup/blossom-pistil-form/blossom-pistil-form.component';

@Component({
  selector: 'app-blossom-pistil-setup',
  templateUrl: './blossom-pistil-setup.component.html',
  styleUrls: ['./blossom-pistil-setup.component.scss'],
})
export class BlossomPistilSetupComponent implements OnInit {
  @Input() chart: PsyChart;

  matTableDataSource$: Observable<MatTableDataSource<BlossomChartPistil>>;

  petals: BlossomChartPetal[] = [];

  subscales: Subscale[] = [];

  displayColumns = ['order', 'label', 'color', 'petal', 'score', 'reverse', 'actions'];

  refetch$ = new Subject<true>();

  constructor(
    private blossomChart: BlossomChartService,
    private subscaleService: SubscaleService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.matTableDataSource$ = this.refetch$.pipe(
      startWith(true),
      switchMap(() => this.blossomChart.getChartPistil(this.chart.id)),
      map((v) => new MatTableDataSource(v)),
    );

    this.blossomChart.getChartPetal(this.chart.id).subscribe((petals) => {
      this.petals = petals;
    });

    this.subscaleService.getAll().subscribe((subscales) => {
      this.subscales = subscales;
    });
  }

  onAddPistil() {
    this.dialog
      .open(BlossomPistilFormComponent, { data: { petals: this.petals, subscales: this.subscales } })
      .afterClosed()
      .subscribe(({ value }) => {
        this.blossomChart.postChartPistil(this.chart.id, value).subscribe(() => {
          this.refetch$.next(true);
        });
      });
  }

  onEditPistil(pistil: BlossomChartPistil) {
    this.dialog
      .open(BlossomPistilFormComponent, { data: { pistil, petals: this.petals, subscales: this.subscales } })
      .afterClosed()
      .subscribe(({ value }) => {
        this.blossomChart.putChartPistil(this.chart.id, pistil.id, value).subscribe(() => {
          this.refetch$.next(true);
        });
      });
  }

  onDeletePistil(pistil: BlossomChartPistil) {
    this.blossomChart.deleteChartPistil(this.chart.id, pistil.id).subscribe(() => {
      this.refetch$.next(true);
    });
  }

  displaySubscale = (id: number): string => {
    return this.subscales.find((s) => s.id === id)?.title;
  };

  displayPetal = (id: number): string => {
    return this.petals.find((s) => s.id === id)?.label;
  };
}
