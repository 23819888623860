import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SuperTestDetailComponent } from './super-test-detail/super-test-detail.component';
import { SuperTestListComponent } from './super-test-list/super-test-list.component';

const routes: Routes = [
  { path: '', component: SuperTestListComponent },
  { path: ':id/detail', component: SuperTestDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperTestsRoutingModule {}
