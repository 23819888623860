/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyTestGroupReport } from '../models/PsyTestGroupReport';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupReportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a PsyTestGroupReport by testGroupId
     * Retrieve a PsyTestGroupReport by testGroupId
     * @param testGroupId Id of the Test Group
     * @returns PsyTestGroupReport A PsyTestGroupReport
     * @throws ApiError
     */
    public getTestGroupReport(
        testGroupId: number,
    ): Observable<PsyTestGroupReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/report',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `PsyTestGroupReport not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a psyTestGroupReport
     * Create a psyTestGroupReport
     * @param testGroupId Id of the test group
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupPsyTestGroupReport(
        testGroupId: number,
        requestBody?: {
            /**
             * Id of the psy test group
             */
            testGroupId?: number;
            /**
             * Id of the report
             */
            reportId?: number;
            /**
             * Id of the report wizard
             */
            reportWizardId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{testGroupId}/psy-test-group-report',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a report
     * Remove a report from test group
     * @param id Id of the test group
     * @param reportId Id of the report
     * @param reportModelId Id of the report model
     * @returns any Report deleted from test group successfully
     * @throws ApiError
     */
    public deleteTestGroupReport(
        id: number,
        reportId: number,
        reportModelId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/{id}/report/{reportId}',
            path: {
                'id': id,
                'reportId': reportId,
                'reportModelId': reportModelId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a report model
     * Remove a report model from test group
     * @param id Id of the test group
     * @param reportId Id of the report
     * @param reportModelId Id of the report model
     * @returns any Report Model deleted from test group successfully
     * @throws ApiError
     */
    public deleteTestGroupReportModel(
        id: number,
        reportId: number,
        reportModelId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/{id}/report-model/{reportModelId}',
            path: {
                'id': id,
                'reportId': reportId,
                'reportModelId': reportModelId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
