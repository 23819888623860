<div class="container">
  <div class="page-list-header">
    <span *ngIf="optimalScoreCurve" class="psb-page-navigator"
      >Optimal Score Curve > <b>{{ this.optimalScoreCurve.title }}</b> >
      {{ tabs[+selected.value] }}
    </span>

    <button mat-raised-button class="upload-new-button" color="accent" type="button" (click)="saveModifications()">
      Save
    </button>
  </div>

  <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected.value" (selectedIndexChange)="tabChange($event)">
    <mat-tab label="Percentiles">
      <div class="row">
        <div class="column">
          <h2>Curves Definition</h2>
          <span class="small-text">Generate new set of curves</span>

          <form [formGroup]="generateSetForm" flex>
            <mat-form-field>
              <mat-label>Percentile Interval</mat-label>
              <input matInput type="number" formControlName="percentileInterval" />
              <mat-error *ngIf="generateSetForm.get('percentileInterval').errors"
                >Please, enter a valid percentile interval.</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Number Of Curves</mat-label>
              <input matInput type="number" formControlName="numberOfCurves" />
              <mat-error *ngIf="generateSetForm.get('numberOfCurves').errors"
                >Please, enter a valid number of curves.
              </mat-error>
            </mat-form-field>
            <button
              mat-raised-button
              color="accent"
              [disabled]="
                !optimalScoreCurveId || this.optimalScoreCurve?.ranges?.length === 0 || !generateSetForm.valid
              "
              (click)="onGenerateSet()"
            >
              Generate Set
            </button>
          </form>
        </div>

        <div class="column">
          <h2>Add a new curve</h2>
          <span class="small-text">Add a new curve</span>
          <button
            mat-raised-button
            class="upload-new-button align-right"
            color="primary"
            type="button"
            [disabled]="!optimalScoreCurveId"
            (click)="onNewCurve()"
          >
            New Curve
          </button>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <h2>Input File</h2>
          <span class="small-text">Chose a CSV file to input a new Curve or Set of Curves</span>
          <div class="file-imput">
            <input
              type="file"
              accept=".csv"
              (change)="handleCurveDefinitionCSVFile($event)"
              [disabled]="!optimalScoreCurveId"
              class="input-file"
            />
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <app-ranges-table
          *ngIf="optimalScoreCurve?.ranges"
          [ranges]="optimalScoreCurve.ranges"
          [spreads]="optimalScoreCurve.spreads"
          [opptimalScoreCurveId]="optimalScoreCurve.id"
        ></app-ranges-table>
      </div>
    </mat-tab>

    <mat-tab label="Spreads">
      <h2>Spreads Definition</h2>
      <form [formGroup]="spreadForm" flex>
        <mat-form-field>
          <mat-label>Spread</mat-label>
          <input matInput type="number" formControlName="newSpread" />
          <mat-error *ngIf="spreadForm.get('newSpread').errors">Please, enter a valid number of curves. </mat-error>
        </mat-form-field>
        <button mat-raised-button color="accent" (click)="addSpread()">Add Spread</button>
      </form>

      <div class="row" style="margin-top: 10px">
        <app-spreads-table *ngIf="optimalScoreCurve?.spreads" [spreads]="optimalScoreCurve.spreads"></app-spreads-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
