<mat-toolbar>
  <button *ngIf="visibleMenu" mat-icon-button (click)="toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <span ngClass="psybil-logo">
    <a routerLink="/" i18n="Nav Header|Psybil@@navHeader">Psybil</a>
  </span>
  <span class="spacer"></span>
  <div *ngIf="userIsAuthenticated">
    <!-- TODO change url from Home based on the user authority -->
    <a class="user-menu" mat-button [routerLink]="homeUrl">Home</a>
    <a class="user-menu btn-important" *ngIf="isAdmin" mat-button routerLink="/dashboard">Assessments Dashboard</a>
    <a class="user-menu" mat-button routerLink="/help">Help</a>
  </div>
  <span class="spacer"></span>
  <ul>
    <li *ngIf="!userIsAuthenticated">
      <a mat-button routerLink="/login" routerLinkActive="mat-accent" id="log-in">Log in</a>
    </li>
    <li *ngIf="userIsAuthenticated">
      <app-user-notification-header></app-user-notification-header>
      <button mat-button [matMenuTriggerFor]="menu" id="options">Options</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/user/profile">Profile</button>
        <button mat-menu-item (click)="onLogout()" i18n="Logout|Log out@@logout">Log out</button>
      </mat-menu>
    </li>
  </ul>
</mat-toolbar>
