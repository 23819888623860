<svg viewBox="0 0 400 400" style="width: auto; max-height: 400px">
  <g transform="translate(-2.1848517,0.35752119)" style="pointer-events: all">
    <path
      number="1"
      d="M 201.8721,199.92581 m -46.021729, 0 a 46.021729,46.021729 0 1,0 92.043458,0 a 46.021729,46.021729 0 1,0 -92.043458,0"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 1 ? '#ccc' : 'none'"
      r="46.021729"
    />
    <path
      number="2"
      d="m 202.05605,153.76012 -0.276,-46.18581 c 20.60657,0.40035 41.81063,5.38156 65.21757,27.25143 l -32.64589,32.63497 c -8.67721,-7.90769 -18.92643,-13.30015 -32.29568,-13.70059 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 2 ? '#ccc' : '#425465'"
    />
    <path
      number="3"
      d="m 247.86185,199.79589 c -0.72793,-14.96459 -6.23919,-24.84832 -13.51012,-32.33518 l 32.81547,-32.81177 c 13.17886,14.53728 25.39746,30.72616 27.01216,65.15381 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 3 ? '#ccc' : '#425465'"
    />
    <path
      number="4"
      d="m 201.78005,107.57431 0.13756,-46.18576 c 16.26268,0.37861 31.88184,2.52695 46.26728,8.06802 l 0.0211,50.80611 C 236.44383,113.1452 221.98354,108.12627 201.78,107.57431 Z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 4 ? '#ccc' : '#425465'"
    />
    <path
      number="5"
      d="m 248.20604,120.26267 -0.0211,-50.80611 c 33.75962,13.60861 64.41549,35.08006 84.16696,84.17602 l -51.23521,-0.60004 c -7.38859,-10.57081 -13.16417,-20.98288 -32.9106,-32.76987 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 5 ? '#ccc' : '#425465'"
    />
    <path
      number="6"
      d="m 294.17936,199.80275 c -1.27755,-19.22243 -4.02181,-32.63753 -13.06274,-46.77021 l 51.23523,0.60004 c 5.56254,15.59023 8.56107,31.18047 8.01205,46.77071 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 6 ? '#ccc' : '#425465'"
    />
    <path
      number="7"
      d="m 201.91761,61.38855 0.10187,-45.59392 c 15.03112,0.43898 30.43029,1.60053 46.39134,6.22417 l -0.22593,47.43777 C 232.76246,63.6455 217.34003,61.56888 201.91761,61.38855 Z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 7 ? '#ccc' : '#425465'"
    />
    <path
      number="8"
      d="m 248.18489,69.45656 0.22593,-47.43776 c 74.69892,22.32324 113.7695,70.18012 132.1121,132.89205 l -48.17048,-1.27886 C 317.6,114.93077 291.14257,85.59357 248.18489,69.45656 Z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 8 ? '#ccc' : '#425465'"
    />
    <path
      number="9"
      d="m 332.35244,153.632 48.17048,1.27885 c 4.51825,15.59061 5.10652,30.49774 5.44315,46.08835 l -45.60245,-0.59563 c 1.09881,-14.7207 -3.07668,-30.65857 -8.01118,-46.77157 z"
      style="stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 9 ? '#ccc' : '#425465'"
    />
    <path
      number="10"
      style="stroke: #ccc; stroke-width: 1px; stroke-linecap: butt; stroke-linejoin: bevel; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 10 ? '#ccc' : '#425465'"
      d="M 202.01948,15.79463 386.08594,15.783997 385.96607,200.9992 C 386.00686,85.087465 288.63013,15.935409 202.01948,15.79463 Z"
    />
    <path
      number="11"
      d="m 247.86185,199.79589 c -0.43613,13.39812 -4.68164,23.67933 -13.10076,32.0881 l 32.65162,32.6519 c 11.87821,-12.49699 25.71427,-30.92855 26.76665,-64.73314 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 11 ? '#ccc' : '#425465'"
    />
    <path
      number="12"
      d="m 201.89724,246.13393 c 16.67531,-0.7455 25.48953,-6.99376 32.86385,-14.24994 l 32.65162,32.6519 c -18.42897,18.47661 -40.46019,27.17566 -65.47344,27.78063 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 12 ? '#ccc' : '#425465'"
    />
    <path
      number="13"
      d="m 294.24422,199.78448 c -0.84966,13.33429 -1.33353,26.45619 -12.21061,45.61287 l 50.31179,0.60176 c 5.73591,-14.24127 7.5763,-29.69988 8.0182,-45.59554 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 13 ? '#ccc' : '#425465'"
    />
    <path
      number="14"
      d="m 282.03358,245.39735 c -10.47846,18.06138 -22.147,26.27778 -33.8425,34.27119 l 0.0212,50.80622 c 7.50533,-3.23876 14.68205,-5.98905 21.92256,-10.09786 23.98164,-13.60891 46.1757,-34.6592 62.21063,-74.37779 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 14 ? '#ccc' : '#425465'"
    />
    <path
      number="15"
      d="m 201.93927,292.31652 0.042,46.1826 c 14.30681,-0.46778 29.12852,-1.96538 46.23093,-8.02436 l -0.0211,-50.80622 c -13.55064,8.19814 -29.17572,11.97082 -46.25181,12.64798 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 15 ? '#ccc' : '#425465'"
    />
    <path
      number="16"
      d="m 340.3639,200.40329 45.60217,0.59591 c -0.0506,16.19409 -2.03204,31.10263 -5.3818,45.60189 l -48.23865,-0.60219 c 4.63362,-12.95754 8.01804,-27.34276 8.01828,-45.59561 z"
      style="stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 16 ? '#ccc' : '#425465'"
    />
    <path
      number="17"
      d="m 332.34542,245.99911 48.23885,0.60198 c -22.73183,80.37612 -84.52068,120.4696 -132.3532,131.65092 l -0.0188,-47.77725 c 41.04266,-14.92562 69.89823,-46.27354 84.1332,-84.47565 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 17 ? '#ccc' : '#425465'"
    />
    <path
      number="18"
      d="m 201.9813,338.49912 c 14.49534,-0.54529 29.33394,-1.88949 46.23093,-8.02441 l 0.0188,47.7773 c -14.46192,3.53664 -29.49172,5.99587 -46.2869,5.79928 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 18 ? '#ccc' : '#425465'"
    />
    <path
      number="19"
      style="stroke: #ccc; stroke-width: 1px; stroke-linecap: butt; stroke-linejoin: bevel; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 19 ? '#ccc' : '#425465'"
      d="m 385.96607,200.9992 0.11987,183.14899 -184.14174,-0.0969 c 98.75419,0.38453 184.96157,-78.97025 184.02187,-183.05209 z"
    />
    <path
      number="20"
      d="m 201.89724,246.13393 0.042,46.18259 c -23.06742,-1.17747 -45.37975,-7.38897 -65.63901,-27.28759 l 32.81342,-32.8131 c 7.69297,5.89549 13.64202,12.46815 32.78356,13.9181 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 20 ? '#ccc' : '#425465'"
    />
    <path
      number="21"
      d="m 155.69264,198.85592 c 0.30745,13.12607 4.64677,24.93759 13.42104,33.35991 l -32.81342,32.8131 c -19.56818,-20.91081 -26.4708,-43.05726 -26.79768,-65.84526 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 21 ? '#ccc' : '#425465'"
    />
    <path
      number="22"
      d="m 201.93927,292.31652 0.042,46.1826 c -17.17624,-0.33636 -32.2515,-3.37397 -46.14088,-7.93632 l -0.0212,-50.61511 c 22.19584,11.27034 33.39394,11.01922 46.11999,12.36883 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 22 ? '#ccc' : '#425465'"
    />
    <path
      number="23"
      d="M 155.84042,330.5628 C 104.56571,309.48551 84.244562,278.59364 71.190016,245.39773 l 50.681094,0.60094 c 7.42012,11.49684 15.06247,22.98338 33.94817,33.94902 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 23 ? '#ccc' : '#425465'"
    />
    <path
      number="24"
      d="m 109.50258,199.18368 c 0.12955,16.77948 4.24216,32.3875 12.36853,46.81499 L 71.190016,245.39773 C 66.30502,231.73085 63.54737,216.24051 63.31832,198.58283 Z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 24 ? '#ccc' : '#425465'"
    />
    <path
      number="25"
      d="m 201.9813,338.49912 -0.0371,45.55217 c -14.14775,-0.12989 -29.12089,-0.98619 -46.08259,-5.33704 l -0.0212,-48.15145 c 11.96757,3.90277 25.33522,7.2897 46.14087,7.93632 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 25 ? '#ccc' : '#425465'"
    />
    <path
      number="26"
      d="m 155.84042,330.56274 0.0211,48.15151 C 81.362725,358.61881 38.84106,305.2758 22.87877,244.79677 l 48.311237,0.60096 c 15.33312,43.84877 45.844423,69.48369 84.650413,85.16501 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 26 ? '#ccc' : '#425465'"
    />
    <path
      number="27"
      d="m 63.31832,198.58284 c 0.79371,15.37619 1.85842,30.78625 7.871696,46.81489 L 22.87877,244.79677 C 19.27368,229.6144 17.5203,214.62995 17.67053,198.50875 Z"
      style="stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 27 ? '#ccc' : '#425465'"
    />
    <path
      number="28"
      style="stroke: #ccc; stroke-width: 1px; stroke-linecap: butt; stroke-linejoin: bevel; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 28 ? '#ccc' : '#425465'"
      d="m 17.67053,198.50875 0.05376,185.53351 184.21991,0.009 C 98.485356,383.82203 17.463532,303.73185 17.67053,198.50875 Z"
    />
    <path
      number="29"
      d="m 169.4449,167.05689 c -7.79821,8.45282 -13.88892,17.27834 -13.75226,31.79903 l -46.19006,0.32775 c 1.85981,-18.39189 1.76224,-35.91799 27.29071,-64.77869 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 29 ? '#ccc' : '#425465'"
    />
    <path
      number="30"
      d="m 201.80167,153.76012 c -19.21118,0.86758 -24.88094,7.46423 -32.35677,13.29677 l -32.65161,-32.65191 c 11.93095,-11.2237 31.07801,-25.54979 64.98676,-26.83067 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 30 ? '#ccc' : '#425465'"
    />
    <path
      number="31"
      d="m 109.50258,199.18368 -46.18426,-0.60084 c 0.23356,-14.34009 2.11805,-29.2305 8.310766,-45.55699 l 50.448144,0.6015 c -8.95917,15.18544 -12.05233,30.37088 -12.57465,45.55633 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 31 ? '#ccc' : '#425465'"
    />
    <path
      number="32"
      d="M 122.07723,153.62735 71.629086,153.02588 C 86.059027,117.43901 109.09885,86.71861 155.81947,69.38301 l 0.0211,50.65912 c -18.66813,10.52597 -26.0609,22.06958 -33.76338,33.58522 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 32 ? '#ccc' : '#425465'"
    />
    <path
      number="33"
      d="m 155.68191,115.56874 0.13755,-46.18576 c 13.13717,-3.58351 28.30499,-7.7366 46.09814,-7.9944 l -0.13755,46.18573 c -18.51598,0.30236 -35.1232,5.99616 -45.93943,12.46782 z"
      style="stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 33 ? '#ccc' : '#425465'"
    />
    <path
      number="34"
      d="m 63.31832,198.58284 -45.64779,-0.0741 c 0.73667,-15.35648 1.83012,-31.20169 6.27369,-46.0948 l 47.684866,0.6119 c -3.757936,10.49417 -7.178676,22.59003 -8.310766,45.55699 z"
      style="stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 34 ? '#ccc' : '#425465'"
    />
    <path
      number="35"
      d="M 71.629086,153.02585 23.94422,152.41394 C 44.30418,85.21051 87.622777,41.7998 155.87997,21.82836 l -0.0605,47.55462 c -37.23665,13.90279 -66.690163,39.66554 -84.190384,83.64287 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 35 ? '#ccc' : '#425465'"
    />
    <path
      number="36"
      d="m 155.81947,69.38301 0.0605,-47.55465 c 13.13716,-3.58351 28.34637,-5.77592 46.13951,-6.03373 l -0.10188,45.59395 c -18.51598,0.30235 -32.86161,3.73248 -46.09813,7.99443 z"
      style="fill-opacity: 1; stroke: #ccc; stroke-width: 0.92371601; stroke-opacity: 1; stroke-linejoin: bevel"
      [style.fill]="selectedAreaNumber == 36 ? '#ccc' : '#425465'"
    />
    <path
      number="37"
      style="stroke: #ccc; stroke-width: 1px; stroke-linecap: butt; stroke-linejoin: bevel; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 37 ? '#ccc' : '#425465'"
      d="M 17.67053,198.50874 17.724289,15.783997 202.01948,15.79463 C 102.47599,16.814276 17.709331,97.018232 17.67053,198.50874 Z"
    />
  </g>
</svg>
