import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

// services
import { UserService } from '@app/core/services/user.service';

// models
import { UserInfo } from '@app/core/models/userInfo.model';
import { RoleTitle } from '@app/core/models/role-title.model';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
})
export class RoleComponent implements OnInit {
  @Input() answering: boolean;
  @Input() userInfo: UserInfo;

  roleTitles: RoleTitle[];
  filteredRoleTitles: Observable<RoleTitle[]>;
  roleTitleControl = new FormControl('', [Validators.required]);
  optionSelected = false;
  createSubject: Subject<any> = new Subject();

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getRoleTitles();
    this.createSubject.pipe(debounceTime(1000)).subscribe(($event) => {
      if (!this.optionSelected) {
        this.createRoleTitle($event);
      } else {
        this.optionSelected = false;
      }
    });
  }

  getRoleTitles() {
    this.userService.getRoleTitles(this.userInfo.id).subscribe({
      next: (result) => {
        this.roleTitles = [
          ...new Map(
            result
              .filter((roleTitle) => roleTitle.title !== 'undefined')
              .map((roleTitle) => [roleTitle.title, roleTitle]),
          ).values(),
        ];
        if (this.userInfo.roleTitleId) {
          const dpt = result.filter((roleTitle) => roleTitle.id === this.userInfo.roleTitleId)[0];
          this.roleTitleControl.setValue(this.answering ? dpt : dpt.title);
        }

        if (this.answering) {
          this.filteredRoleTitles = this.roleTitleControl.valueChanges.pipe(
            startWith(''),
            map((roleTitle) => (roleTitle ? this._filterRoleTitle(roleTitle) : this.roleTitles.slice())),
          );
        }
      },
    });
  }

  displayRoleTitle(roleTitle: RoleTitle): string {
    return roleTitle ? roleTitle.title : '';
  }

  createRoleTitle($event) {
    if (this.roleTitles.length > 0 && $event.target.value.length > 0) {
      const inputtedRoleTitle = $event.target.value;
      const found = this.roleTitles.find(
        (roleTitle) => roleTitle.title.toLowerCase() === inputtedRoleTitle.toLowerCase(),
      );
      if (typeof found === 'undefined') {
        const newRoleTitle: RoleTitle = { id: null, title: $event.target.value };
        this.userService.saveRoleTitle(newRoleTitle.title).subscribe((createdRoleTitle) => {
          newRoleTitle.id = createdRoleTitle.id;
          this.roleTitles.push(newRoleTitle);
          this.setRoleTitle({ option: { value: { id: newRoleTitle.id, title: newRoleTitle.title } } });
        });
      } else {
        this.setRoleTitle({ option: { value: { id: found.id, title: found.title } } });
      }
    }
  }

  setRoleTitle($event: any) {
    this.optionSelected = true;
    this.createSubject.next($event);
    if (this.userInfo.roleTitleId !== $event.option.value.id) {
      this.userService
        .updateRoleTitle(this.userInfo.id, $event.option.value)
        .subscribe(() => (this.userInfo.roleTitleId = $event.option.value.id));
    }
  }

  private _filterRoleTitle(value: string): RoleTitle[] {
    const filterValue = typeof value === 'object' ? value : value.toLowerCase();
    return this.roleTitles.filter((roleTitle) => roleTitle.title.toLowerCase().indexOf(filterValue) === 0);
  }

  onFocusOut($event: FocusEvent) {
    this.createSubject.next($event);
  }
}
