/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class CustomRangeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve one test group custom ranges
     * Retrieve custom ranges
     * @param testGroupId Id of the test group
     * @returns any One test group custom ranges
     * @throws ApiError
     */
    public getReportTestGroupCustomRange(
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/test-group/{testGroupId}/custom-range',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Custom ranges not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Create one test group custom ranges
     * Create custom ranges
     * @param testGroupId Id of the test group
     * @returns any One test group custom ranges
     * @throws ApiError
     */
    public postReportTestGroupCustomRange(
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/test-group/{testGroupId}/custom-range',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Custom ranges not created`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update one test group custom ranges
     * Update custom ranges
     * @param testGroupId Id of the test group
     * @returns any One test group custom ranges
     * @throws ApiError
     */
    public patchReportTestGroupCustomRange(
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/test-group/{testGroupId}/custom-range',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Custom ranges not updated`,
                500: `Internal error`,
            },
        });
    }

}
