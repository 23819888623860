import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { CompanyService } from '@app/core/services/company.service';
import { PageService } from '@app/core/services/page.service';

// models
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { Company } from '@app/core/models/company.model';

@Component({
  selector: 'app-role-mandate-create',
  templateUrl: './role-mandate-create.component.html',
  styleUrls: ['./role-mandate-create.component.scss'],
})
export class RoleMandateCreateComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  newRoleMandateForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    company: new FormControl('', [Validators.required]),
    instructions: new FormControl('', [Validators.required]),
  });
  isLoading = false;
  roleMandateId: number = null;

  roleMandate: RoleMandate;
  companies: Company[] = [];
  filteredCompanies: Observable<Company[]>;
  disableCompany = false;
  pages: any = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '130px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter the intruction text here',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private roleMandateService: RoleMandateService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public pageService: PageService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    if (this.route.snapshot.params.id) {
      this.roleMandateId = Number(this.route.snapshot.params.id);

      forkJoin([
        this.roleMandateService.getById(this.roleMandateId),
        this.roleMandateService.getRoleMandatePositions(this.roleMandateId),
        this.companyService.getAll(),
      ]).subscribe((results) => {
        this.roleMandate = results[0];
        this.newRoleMandateForm.controls['title'].setValue(this.roleMandate.title);
        this.newRoleMandateForm.controls['instructions'].setValue(this.roleMandate.instructions);

        const comp = results[2].filter((c) => c.id === this.roleMandate.companyId)[0];
        this.newRoleMandateForm.controls['company'].setValue(comp);

        this.isLoading = false;
      });
    } else {
      this.companyService.getAll().subscribe((result) => {
        this.companies = result;
        this.filteredCompanies = this.newRoleMandateForm.controls['company'].valueChanges.pipe(
          startWith(''),
          map((company) => (company ? this._filter(company) : this.companies.slice())),
        );
      });
    }
    this.getRoleMandatePages();
  }

  private _filter(value: string): Company[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.companies;
    }
    return this.companies.filter((company) => company.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
  }

  displayFn(company: Company): string {
    return company ? company.name : '';
  }

  onNext() {
    this.roleMandate = {
      id: this.route.snapshot.params.id ? this.route.snapshot.params.id : null,
      title: this.newRoleMandateForm.controls['title'].value,
      instructions: this.newRoleMandateForm.controls['instructions'].value,
      companyId: this.newRoleMandateForm.controls['company'].value.id,
    };

    if (!this.roleMandate.title) {
      return;
    }

    if (!this.route.snapshot.params.id) {
      this.roleMandateService.save(this.roleMandate).subscribe((result) => {
        if (!result) {
          return;
        }
        this.roleMandate.id = Number(result);

        this.newRoleMandateForm.reset();
        Object.keys(this.newRoleMandateForm.controls).forEach((key) => {
          this.newRoleMandateForm.controls[key].setErrors(null);
        });
        return this.router.navigate(['/role-mandate/' + this.roleMandate.id + '/positions']);
      });
    } else {
      this.roleMandateService.update(this.roleMandate).subscribe((result) => {
        this.newRoleMandateForm.reset();
        Object.keys(this.newRoleMandateForm.controls).forEach((key) => {
          this.newRoleMandateForm.controls[key].setErrors(null);
        });
        return this.router.navigate(['/role-mandate/' + this.roleMandate.id + '/positions']);
      });
    }
  }

  getRoleMandatePages() {
    this.pages = this.pageService.getRoleMandatePages(this.roleMandateId);
  }
}
