import { Component, Inject, OnInit } from '@angular/core';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TestGroupService } from '@app/core/services/test-group.service';
import { EVENT_CANCEL, EVENT_ERROR, EVENT_SUCCESS } from '@app/core/constants';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-user-add-to-test-group-dialog',
  templateUrl: './user-add-to-test-group-dialog.component.html',
  styleUrls: ['./user-add-to-test-group-dialog.component.scss'],
})
export class UserAddToTestGroupDialogComponent implements OnInit {
  testGroups: PsyTestGroup[] = [];
  testGroupControl = new FormControl('');
  filteredTestGroups: Observable<PsyTestGroup[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: number; userCompanyId: number; existingTestGroups: number[] },
    private dialogRef: MatDialogRef<UserAddToTestGroupDialogComponent>,
    private testGroupService: TestGroupService,
  ) {}

  ngOnInit(): void {
    this.testGroupService.getAllByCompanyId(this.data.userCompanyId).subscribe((testGroups) => {
      this.testGroups = testGroups.filter((testGroup) => !this.data.existingTestGroups.includes(testGroup.id));

      this.filteredTestGroups = this.testGroupControl.valueChanges.pipe(
        startWith(''),
        map((testGroup) => (testGroup ? this._filterTestGroup(testGroup) : this.testGroups.slice())),
      );
    });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onAddTestGroup() {
    const { userId } = this.data;
    const testGroupId = Number(this.testGroupControl.value.id);

    this.testGroupService.saveTestGroupUser(testGroupId, userId).subscribe(
      () => {
        this.dialogRef.close({
          event: EVENT_SUCCESS,
          newTestGroup: this.testGroups.find((test) => test.id === testGroupId),
        });
      },
      () => {
        this.dialogRef.close({
          event: EVENT_ERROR,
        });
      },
    );
  }

  displayTestGroup(testGroup: PsyTestGroup): string {
    return testGroup ? testGroup.title : '';
  }

  private _filterTestGroup(value: string): PsyTestGroup[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.testGroups;
    }

    if (typeof filterValue === 'number') {
      return ;
    }

    return this.testGroups.filter((testGroup: PsyTestGroup) => {
      if (testGroup) {
        return testGroup.title.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }
}
