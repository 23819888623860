import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// components
import { TestGroupModalComponent } from '../../../../shared/components/test-group-modal/test-group-modal.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { TestGroupService } from '@app/core/services/test-group.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-test-group-list-table',
  templateUrl: './test-group-list-table.component.html',
  styleUrls: ['./test-group-list-table.component.scss'],
})
export class TestGroupListTableComponent implements OnInit {
  @Input() companyId: number;
  @ViewChild(MatPaginator, { static: true }) paginatorTestGroups: MatPaginator;
  testGroups: MatTableDataSource<PsyTestGroup> = new MatTableDataSource<PsyTestGroup>();
  allTestGroup = [];
  displayedTestGroupsColumns: string[] = ['title', 'description', 'status', 'actions'];

  constructor(
    private router: Router,
    private testGroupService: TestGroupService,
    private companyService: CompanyService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.getTestGroupList();
  }

  createNewTestGroup() {
    const createNewDialog = this.dialog.open(TestGroupModalComponent, {
      data: { isEdit: false, companyId: this.companyId },
    });

    createNewDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.testGroupService.save(result.data).subscribe((res) => {
          if (res) {
            this.getTestGroupList();
          }
        });
      }
    });
  }

  onView(testGroup: PsyTestGroup) {
    this.router.navigate([`/psy-test-group/${testGroup.id}/details`]);
  }

  onDelete(testGroup: PsyTestGroup) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.testGroupService.delete(testGroup.id).subscribe((res) => {
          if (res) {
            this.allTestGroup = this.allTestGroup.filter((data) => data.id !== testGroup.id);
            this.refreshTableData();
            this.snackBar.info('Test group deleted with success.');
          }
        });
      }
    });
  }

  searchTestGroup(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.testGroups.filter = filterValue.trim().toLowerCase();
  }

  getTestGroupList() {
    this.allTestGroup = [];
    if (this.companyId) {
      this.testGroupService.getAllByCompanyId(this.companyId).subscribe((response) => {
        if (response) {
          this.renderTableData(response);
        }
      });
    } else {
      this.testGroupService.getAll().subscribe((response) => {
        if (response) {
          this.renderTableData(response);
        }
      });
    }
  }

  renderTableData(response: PsyTestGroup[]) {
    response.forEach((data) => {
      const testGroup = {
        id: data.id,
        title: data.title,
        description: data.description,
        status: data.status,
      };

      this.allTestGroup.push(testGroup);
    });

    this.refreshTableData();
  }

  refreshTableData() {
    this.testGroups.data = this.allTestGroup;
    this.testGroups.paginator = this.paginatorTestGroups;
    this.changeDetectorRef.detectChanges();
  }
}
