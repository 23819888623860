<div class="chart-container">
  <div class="chart-column">
    <app-blossom-graph [chartId]="chartId" [testGroupId]="testGroupId" [userInfoId]="userInfoId"></app-blossom-graph>
  </div>
  <div *ngIf="blossomChart.hasSelfEvaluationKey" class="legend-column">
    <div class="legend">
      <div class="legend-title">Self evaluation key</div>
      <div class="legend-item" *ngFor="let item of legend()">
        <div class="legend-item-color" [style.background-color]="item.color"></div>
        <div class="legend-item-name">{{ item.label }}</div>
      </div>
    </div>
  </div>
</div>

<table class="mat-table blossom-table" *ngIf="blossomChart.isTable">
  <ng-container *ngFor="let petal of petals">
    <tr class="mat-row">
      <td class="mat-cell" colspan="2">
        <h2 class="r-h2">{{ petal.label }}</h2>
      </td>
      <td class="mat-cell" colspan="2">
        <h2 class="r-h2">{{ petal.percentile }}</h2>
      </td>
    </tr>
    <tr class="mat-header-row">
      <th class="mat-header-cell">Scale</th>
      <th class="mat-header-cell">Description</th>
      <th class="mat-header-cell">Score</th>
      <th class="mat-header-cell">Symbol</th>
    </tr>
    <tr class="mat-row" *ngFor="let pistil of petal.pistils">
      <td class="mat-cell">{{ pistil.label }}</td>
      <td class="mat-cell">{{ pistil.label }}</td>
      <td class="mat-cell">{{ pistil.percentile }}</td>
      <td class="mat-cell">
        <div class="petal-sign" [style.border-color]="petal.color">
          <div class="pistil-sign" [style.background-color]="pistil.color"></div>
        </div>
      </td>
    </tr>
    <tr class="mat-row"></tr>
  </ng-container>
</table>
