<div class="wrapper">
  <h1 mat-dialog-title>Edit report title</h1>
  <form id="editReportTitleForm" [formGroup]="form" (submit)="onFormSubmit()">
    <mat-form-field>
      <mat-label>New Title</mat-label>
      <input matInput required type="text" formControlName="title" />
      <mat-error *ngIf="form.get('title').hasError">Title should not be empty</mat-error>
    </mat-form-field>
  </form>
  <div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <mat-icon>block</mat-icon>
      Cancel
    </button>
    <button mat-flat-button color="primary" type="submit" form="editReportTitleForm" [disabled]="form.invalid">
      <mat-icon>save_alt</mat-icon>
      Save
    </button>
  </div>
</div>
