import { Component, OnInit } from '@angular/core';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { FormControl, Validators } from '@angular/forms';
import { UserInvite } from '@app/core/models/user-invite';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { InviteService } from '@app/core/services/invite.service';
import { TestGroupService } from '@app/core/services/test-group.service';
import { SendEmailService } from '@app/core/services/send-email.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { TestGroupModalComponent } from '@app/shared/components/test-group-modal/test-group-modal.component';
import { EVENT_CANCEL, ROLE_ADMIN, ROLE_TGM } from '@app/core/constants';
import { Invite } from '@app/core/models/invite.model';
import { InviteTestGroup } from '@app/core/models/invite-test-group.model';
import { UserInfo } from '@app/core/models/userInfo.model';
import { PsbStatsLine } from '@app/core/models/psb-stats-line.model';
import { UserService } from '@app/core/services/user.service';
import { PsyTestGroupUser } from '@app/core/models/psy-test-group-user.model';

@Component({
  selector: 'app-test-group-detail',
  templateUrl: './test-group-detail.component.html',
  styleUrls: ['./test-group-detail.component.scss'],
})
export class TestGroupDetailComponent implements OnInit {
  companyLogo: any;
  companyId: number;
  psyTestGroup: PsyTestGroup;
  selected = new FormControl(0);
  emails = new FormControl('', [Validators.required]);
  alreadyExistedInvites: UserInvite[] = [];
  testGroupId: number;
  tabs: string[] = ['Details', 'Users', 'Tests', 'Charts', 'E-mail', 'Report', 'Notifications Channel'];
  subject: string;
  message: string;
  psyEmailId: number;
  hasTests: boolean;
  private initialSubscriptions: Subscription;
  userData: UserInfo;
  role: string;
  psbStats: PsbStatsLine[];
  inviteAndReminderStats: PsbStatsLine[];
  logo = new FormControl(false);
  public isAdmin: boolean;
  psyTestGroupUsers: PsyTestGroupUser[];
  userInvites: Invite[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private inviteService: InviteService,
    private testGroupService: TestGroupService,
    private sendEmailService: SendEmailService,
    private snackBar: SnackBarService,
    private userService: UserService,
    public dialog: MatDialog,
  ) {
    const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
    if (authorities.includes(ROLE_ADMIN)) {
      this.isAdmin = true;
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.selected.setValue(params.id ? +params.tab : 0);
    });
    this.testGroupId = Number(this.route.snapshot.params.id);

    this.getTestGroupDetail();
    this.getInvitationMessage();
    this.getAlreadyExistedUserInvites();
    this.userData = this.userService.getUserData();
    this.role = this.userData.authorities.find((authority) => authority.name === ROLE_ADMIN) ? ROLE_ADMIN : ROLE_TGM;
  }

  tabChange(event) {
    this.selected.setValue(event);
    this.router.navigate([this.router.url.split('tab/')[0] + '/tab/' + event]);
  }

  getAlreadyExistedUserInvites() {
    this.initialSubscriptions = forkJoin([
      this.testGroupService.getTestGroupUserById(this.testGroupId),
      this.inviteService.getAllInviteByTestGroupId(this.testGroupId),
      this.inviteService.getEmailSummaryByTestGroupId(this.testGroupId),
    ]).subscribe((results) => {
      this.psyTestGroupUsers = results[0];
      results[0].map((user) => {
        if (user['email']) {
          const testGroupInvite: UserInvite = {
            email: user['email'],
            status: user['status'] ? user['status'] : 'REGISTERED',
            isNew: false,
            token: null,
          };
          this.alreadyExistedInvites.push(testGroupInvite);
        }
      });
      this.userInvites = results[1];
      results[1].map((invite) => {
        const testGroupInvite: UserInvite = {
          email: invite.email,
          isNew: true,
          status: 'INVITED',
          token: invite.token,
        };
        this.alreadyExistedInvites.push(testGroupInvite);
      });
      this.setInviteAndReminderStats(results[2]);
    });
  }

  getTestGroupDetail() {
    this.testGroupService.get(this.testGroupId).subscribe((psyTestGroup: PsyTestGroup) => {
      this.psyTestGroup = psyTestGroup;
      if (psyTestGroup.logoEnabled) {
        this.companyLogo = psyTestGroup.logo;
        this.companyId = psyTestGroup.companyId;
        this.logo.setValue(true);
      } else {
        this.logo.setValue(false);
      }
      this.setTestGroupStats(this.psyTestGroup);
    });
  }

  getInvitationMessage() {
    this.sendEmailService.findInvitationEmailByTestGroupId(this.testGroupId, 3).subscribe((res) => {
      if (res) {
        this.psyEmailId = res.id;
        this.subject = res.subject;
        this.message = res.email;
      }
    });
  }

  onEdit() {
    const createNewDialog = this.dialog.open(TestGroupModalComponent, {
      data: { isEdit: true, psyTestGroup: this.psyTestGroup },
    });

    createNewDialog.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.testGroupService.updatePsyTestGroup(this.psyTestGroup.id, result.data).subscribe(
          (response) => {
            if (response) {
              this.getTestGroupDetail();
              this.snackBar.info('Test Group Updated Successfully.');
            }
          },
          (error) => {
            this.snackBar.info('Something went wrong. Please, try again later.');
          },
        );
      }
    });
  }

  updateInviteMessage(event: any) {
    if (this.psyEmailId) {
      this.sendEmailService
        .updateInvitationMessage(this.psyEmailId, event.subject, event.message, 3)
        .subscribe((res) => {
          this.showCompleted('Invitation Message is updated');
        });
    } else {
      this.sendEmailService
        .saveInvitationMessage(event.subject, event.message, 3, this.testGroupId)
        .subscribe((res) => {
          this.showCompleted('Invitation Message is saved');
        });
    }
  }

  sendTestGroupInvite(event: any) {
    if (this.emails.value.length === 0) {
      return;
    }
    const inviteEmails = this.emails.value.split(/,| /);
    const invites: UserInvite[] = [];
    inviteEmails.forEach((inviteEmail) => {
      const alreadyExistedInvites = this.alreadyExistedInvites.filter((data) => data.email === inviteEmail);

      if (alreadyExistedInvites.length > 0) {
        this.emails.setErrors({ isEmailExist: true });
        return;
      } else {
        const invite: UserInvite = {
          email: inviteEmail,
          isNew: true,
        };
        invites.push(invite);
      }

      this.saveInviteTestGroup(inviteEmail);
    });

    this.sendEmailService
      .sendTestGroupInvites(this.testGroupId, invites, event.subject, event.message)
      .subscribe((res) => {
        this.showCompleted('Invites sent successfully!');
      });
  }

  saveInviteTestGroup(email: string) {
    const invite: Invite = {
      psyTestGroupId: this.testGroupId,
      active: true,
      token: null,
      firstName: null,
      lastName: null,
      companyId: this.psyTestGroup.companyId,
      email,
      invited: false,
    };

    if (!invite.email) {
      return;
    }

    const inviteTestGroup: InviteTestGroup = {
      invite,
      testGroupId: this.testGroupId,
    };

    this.inviteService.saveInviteTestGroup(inviteTestGroup).subscribe((result) => {
      invite.id = result['inviteId'];
      this.clearValidators();
    });
  }

  showCompleted(message: string) {
    this.snackBar.info(message);
  }

  private clearValidators() {
    this.emails.reset();
    this.emails.clearValidators();
    this.emails.updateValueAndValidity();
  }

  checkTestsInTestGroup(event: any) {
    if (event) {
      this.hasTests = event;
    }
  }

  setTestGroupStats(testGroup: PsyTestGroup) {
    this.psbStats = [
      {
        title: 'Invited',
        number: testGroup.totalInvited,
        description: 'Total invited but not yet registered',
      },
      {
        title: 'Registered',
        number: testGroup.totalUsers,
        description: 'Total registered but not yet started',
      },
      {
        title: 'In Progress',
        number: testGroup.totalInProgress,
        description: 'Total started but not yet completed',
      },
      {
        title: 'Completed',
        number: testGroup.totalCompleted,
        description: 'Total Completed',
      },
    ];
  }

  setInviteAndReminderStats(emailSummary: any) {
    this.inviteAndReminderStats = [
      {
        title: 'Invites Sent Today',
        number: emailSummary.invitedSentByToday,
      },
      {
        title: 'Reminders Sent Today',
        number: emailSummary.remindSentByToday,
      },
      {
        title: 'Invites Sent this Week',
        number: emailSummary.invitedSentByWeek,
      },
      {
        title: 'Reminders Sent this Week',
        number: emailSummary.remindSentByWeek,
      },
      {
        title: 'Total Invites Sent',
        number: emailSummary.totalInvited,
      },
      {
        title: 'Total Reminders Sent',
        number: emailSummary.totalRemind,
      },
    ];
  }

  onLogoChange($event) {
    let isEnabledCompanyLogo = $event.checked;
    this.testGroupService.updateCompanyLogoEnabled(this.testGroupId, isEnabledCompanyLogo).subscribe((result) => {
      if (!$event.checked && result) {
        this.companyLogo = '';
      } else {
        this.companyLogo = this.psyTestGroup.logo;
      }
    });
  }
}
