<form [formGroup]="cardForm" (ngSubmit)="saveAnswer()" class="form-cardosort">
  <mat-form-field>
    <mat-label>Text Answer</mat-label>
    <input type="text" placeholder="Text Answer" matInput formControlName="text" autocomplete="off" />
  </mat-form-field>

  <mat-form-field *ngIf="allowSubscale">
    <mat-label>Subscale</mat-label>
    <input matInput type="text" formControlName="subscale" required [matAutocomplete]="autoSubscale" />
    <mat-autocomplete #autoSubscale="matAutocomplete" [displayWith]="displaySubscale">
      <mat-option *ngFor="let answerSubscale of filteredSubscales | async" [value]="answerSubscale">
        {{ answerSubscale.title }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="cardForm.get('subscale').errors">Please, enter a valid subscale.</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="allowWeight">
    <mat-label>Weight</mat-label>
    <input type="text" placeholder="Weight" matInput formControlName="weight" autocomplete="off" />
  </mat-form-field>

  <button
    type="submit"
    mat-raised-button
    class="btn-success btn-center btn-300"
    [disabled]="cardForm.get('text').invalid"
  >
    Save
  </button>
</form>
