<div class="container">
  <app-psb-page-navigator [pages]="pages"></app-psb-page-navigator>

  <form class="row" [formGroup]="newRoleMandateForm">
    <mat-form-field>
      <mat-label>Role Navigator Title</mat-label>
      <input matInput type="text" formControlName="title" placeholder="Role Navigator Title" />
      <mat-error *ngIf="newRoleMandateForm.get('title').errors">Please enter the valid title.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Company</mat-label>
      <input matInput type="text" formControlName="company" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
          {{ company.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="newRoleMandateForm.get('company').errors">Please, enter a valid company.</mat-error>
    </mat-form-field>

    <h4>Instructions</h4>
    <angular-editor type="text" formControlName="instructions" [config]="editorConfig"></angular-editor>
    <mat-error
      *ngIf="
        newRoleMandateForm.get('instructions').errors &&
        (newRoleMandateForm.get('instructions').dirty || newRoleMandateForm.get('instructions').touched)
      "
    >
      Please, enter valid insturctions.</mat-error
    >
  </form>

  <button
    mat-raised-button
    class="btn-success btn-center btn-300"
    (click)="onNext()"
    [disabled]="!newRoleMandateForm.valid"
  >
    Next
  </button>
</div>
