<div class="container-fluid">
  <span *ngIf="currentUser" class="psb-page-navigator"> User > {{ fullName }} > {{ tabs[+tabSelected] }}</span>

  <mat-tab-group mat-align-tabs="start" [selectedIndex]="tabSelected" (selectedIndexChange)="tabChange($event)">
    <mat-tab label="Details">
      <h1>Details</h1>

      <button
        *ngIf="!hideResetPasswordAndEditButtons"
        mat-raised-button
        color="primary"
        style="margin-bottom: 1rem"
        (click)="onEdit()"
      >
        <mat-icon>edit</mat-icon>
        Edit
      </button>

      <button
        mat-raised-button
        color="primary"
        *ngIf="allowEmailVerification"
        style="margin-left: 1rem"
        (click)="verifyEmail()"
      >
        <mat-icon>check</mat-icon>
        Verify Email
      </button>

      <button
        *ngIf="!hideResetPasswordAndEditButtons"
        mat-raised-button
        color="primary"
        style="margin-left: 1rem"
        (click)="resetPassword()"
      >
        <mat-icon>edit</mat-icon>
        Reset Password
      </button>

      <div class="row" *ngIf="currentUser">
        <div class="column">
          <span class="label">First Name</span>
          <p>{{ currentUser.firstName }}</p>

          <span class="label">Last Name</span>
          <p>{{ currentUser.lastName }}</p>

          <span class="label">E-mail</span>
          <p>{{ currentUser.email }}</p>

          <span class="label">Gender</span>
          <p>{{ gender }}</p>

          <span class="label">Phone</span>
          <p>{{ currentUser.phone }}</p>

          <span class="label">Username</span>
          <p>{{ currentUser.login }}</p>

          <span class="label">Role</span>
          <p>{{ currentUser.authoritieRoles }}</p>

          <span class="label">Private Scores</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="currentUser.privateScores === 1" color="primary" selected> Private </mat-chip>
            <mat-chip *ngIf="currentUser.privateScores === 0" color="warn" selected> Public </mat-chip>
          </mat-chip-list>
        </div>

        <div class="column">
          <span class="label">Status</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="active" color="primary" selected> Active </mat-chip>
            <mat-chip *ngIf="!active" color="warn" selected> Inactive </mat-chip>
          </mat-chip-list>

          <span class="label">Country</span>
          <p>{{ currentUser.country }}</p>

          <span class="label">State</span>
          <p>{{ currentUser.state }}</p>

          <span class="label">Time Zone</span>
          <p>{{ currentUser.timeZone }}</p>

          <span class="label">Client</span>
          <p>{{ currentUser.companyTitle }}</p>

          <span class="label">Department</span>
          <p>{{ currentUser.departmentName }}</p>

          <span class="label">Language</span>
          <p>{{ language }}</p>

          <span class="label">Date Added</span>
          <p>{{ currentUser.createdDate | date: 'mediumDate' }}</p>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Test Groups">
      <app-user-test-group-list></app-user-test-group-list>
    </mat-tab>

    <mat-tab label="Role Navigators">
      <h1>Role Navigators</h1>

      <button mat-raised-button color="primary" (click)="onAddRoleNavigator()">
        <mat-icon>add</mat-icon>
        Add Role Navigator
      </button>

      <app-psb-list-table
        [allItem]="roleNavigatorList"
        [isSearch]="true"
        [itemsPerPage]="10"
        [displayedColumns]="displayedColumns"
        (view)="onView($event)"
        (delete)="onDeleteByUser($event)"
        [isLinkable]="false"
        [actions]="'roleNavigatorList'"
      ></app-psb-list-table>
    </mat-tab>

    <mat-tab [label]="showScoreTab ? 'Scores' : ''" [disabled]="!showScoreTab">
      <app-user-score-list *ngIf="showScoreTab"></app-user-score-list>
    </mat-tab>
  </mat-tab-group>
</div>
