import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

// components
import { AlertDialogComponent } from '@app/shared/components/alert-dialog/alert-dialog.component';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { FilterService } from '@app/core/services/filter.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { User } from '@app/core/models/user.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-role-mandate-migrate-user-answers',
  templateUrl: './role-mandate-migrate-user-answers.component.html',
  styleUrls: ['./role-mandate-migrate-user-answers.component.scss'],
})
export class RoleMandateMigrateUserAnswersComponent implements OnInit {
  @Input() roleMandateId: number;
  migrateForm = new FormGroup({
    sourceUser: new FormControl(null, [Validators.required]),
    targetUser: new FormControl(null, [Validators.required]),
  });
  filteredSourceUsers$: Observable<User[]>;
  filteredTargetUsers$: Observable<User[]>;
  sourceUserId: number;
  targetUserId: number;
  users: User[] = [];
  constructor(
    private rMService: RoleMandateService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private filterService: FilterService,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.loadUsers();
  }

  filterUser(name: string) {
    const filterValue = typeof name === 'string' ? name.toLowerCase() : '';
    return this.filterService.filterUsersByNameAndDepartmentAndRoleTitle(this.users, filterValue);
  }

  migrateUser(formDirective) {
    const alertDialog = this.dialog.open(AlertDialogComponent);
    alertDialog.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.rMService
          .migrate(this.roleMandateId, { sourceUserId: this.sourceUserId, targetUserId: this.targetUserId })
          .subscribe(() => {
            this.snackBar.info('Moved user successfully!');
            this.migrateForm.reset();
            formDirective.resetForm();
          });
      }
    });
  }

  selectUser(controlName: string, user: User): void {
    if (controlName === 'sourceUser') {
      this.sourceUserId = user.id;
      this.migrateForm.get('sourceUser').setValue(`${user.firstName} ${user.lastName}`);
    }
    if (controlName === 'targetUser') {
      this.targetUserId = user.id;
      this.migrateForm.get('targetUser').setValue(`${user.firstName} ${user.lastName}`);
    }
  }

  private loadUsers(): void {
    this.rMService.getAllUsers(this.roleMandateId).subscribe((users: User[]) => {
      this.users = users.filter((user) => user.id && user.firstName !== null);
      this.filteredSourceUsers$ = this.migrateForm.controls['sourceUser'].valueChanges.pipe(
        startWith(''),
        map((value) => this.filterUser(value)),
      );
      this.filteredTargetUsers$ = this.migrateForm.controls['targetUser'].valueChanges.pipe(
        startWith(''),
        map((value) => this.filterUser(value)),
      );
      this.cdRef.detectChanges();
    });
  }
}
