<mat-card>
  <mat-card-header>
    <h2>Company Users</h2>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <form>
        <mat-form-field>
          <mat-label>Search</mat-label>
          <input
            matInput
            type="text"
            name="search"
            placeholder="Name"
            ngModel
            (ngModelChange)="filterUser(searchUser.value)"
            #searchUser="ngModel"
          />
        </mat-form-field>
      </form>
    </div>
    <div class="row">
      <span class="users-selected"
        ><b>{{ linkedUsers }}</b> users selected</span
      >
    </div>

    <mat-selection-list #usersSelection (selectionChange)="selectUser($event)" class="row" color="primary">
      <mat-list-option *ngFor="let user of filteredUsers" [value]="user" [selected]="user.selected">
        {{ user.firstName }} {{ user.lastName }}
      </mat-list-option>
    </mat-selection-list>
  </mat-card-content>
</mat-card>
