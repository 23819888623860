import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bar-subscales-new',
  templateUrl: './bar-subscales.component.html',
  styleUrls: ['./bar-subscales.component.scss'],
})
export class BarSubscalesComponent implements OnInit {
  @Input() chartId: number;
  @Input() userId: number;
  @Input() testGroupId: number;
  @Input() XSubscale?: boolean;
  @Input() YSubscale?: boolean;

  src: string;

  ngOnInit(): void {
    let subsclePath = 'bar-subscales-chart.png';
    if (this.XSubscale) {
      subsclePath = `bar-subscales-chart-x.png`;
    } else if (this.YSubscale) {
      subsclePath = `bar-subscales-chart-y.png`;
    }
    this.src = `${environment.apiUrl}/v3/user/${this.userId}/chart/${this.chartId}/testGroupId/${this.testGroupId}/${subsclePath}`;
  }
}
