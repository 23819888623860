<div style="min-height: 80vh">
  <mat-card>
    <h1>Register</h1>

    <form [formGroup]="createUserForm" (submit)="onSave()">
      <mat-grid-list cols="2" rowHeight="4rem" gutterSize="1rem">
        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>Client</mat-label>
            <mat-select [disabled]="true" formControlName="client">
              <mat-option></mat-option>
              <mat-option *ngFor="let company of companies$ | async" [value]="company.id">
                {{ company.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createUserForm.get('client').errors">Please, enter a valid client.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>E-mail</mat-label>
            <input matInput type="email" formControlName="email" class="disabled" readonly />
            <mat-error *ngIf="createUserForm.get('email').errors">Please, enter a valid e-mail.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput type="text" formControlName="firstName" />
            <mat-error *ngIf="createUserForm.get('firstName').errors">Please, enter a valid first name.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput type="text" formControlName="lastName" />
            <mat-error *ngIf="createUserForm.get('lastName').errors">Please, enter a valid last name.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <mat-select formControlName="country" (selectionChange)="onSelectCountry()">
              <mat-option></mat-option>
              <mat-option *ngFor="let country of countries$ | async" [value]="country.id">
                {{ country.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createUserForm.get('country').errors">Please, enter a valid country.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>State</mat-label>
            <mat-select [disabled]="!states$ || (states$ | async).length === 0" formControlName="state">
              <mat-option *ngFor="let state of states$ | async" [value]="state.id">
                {{ state.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createUserForm.get('state').errors">Please, enter a valid state.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>Username</mat-label>
            <input matInput type="text" formControlName="username" />
            <mat-error *ngIf="createUserForm.get('username').errors">Please, enter a valid username.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1"></mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password" [type]="toggle" />
            <mat-hint align="start" aria-live="polite">
              {{ createUserForm.get('password').value.length }} / 25
            </mat-hint>
            <mat-error *ngIf="createUserForm.get('password').errors">Please, enter a valid password.</mat-error>
            <mat-password-strength [password]="createUserForm.get('password').value"></mat-password-strength>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>Password Confirmation</mat-label>
            <input matInput type="password" formControlName="passwordConfirmation" />
            <mat-error *ngIf="createUserForm.get('passwordConfirmation').invalid"
              >Please enter the same password.</mat-error
            >
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-form-field>
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="FEMALE">Female</mat-option>
              <mat-option value="MALE">Male</mat-option>
              <mat-option value="OTHER">Other</mat-option>
              <mat-option value="PREFER_NOT_TO_SAY">Prefer not to say</mat-option>
            </mat-select>
            <mat-error *ngIf="createUserForm.get('gender').errors">Please, enter a valid gender.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>Phone</mat-label>
            <input matInput type="text" formControlName="phone" />
            <mat-error *ngIf="createUserForm.get('phone').errors">Please, enter a valid phone.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">
              <mat-option value="en">English</mat-option>
              <mat-option value="pt-br">Portuguese</mat-option>
              <mat-option value="zh-cn">Chinese</mat-option>
              <mat-option value="da">Danish</mat-option>
              <mat-option value="nl">Dutch</mat-option>
              <mat-option value="fr">French</mat-option>
              <mat-option value="de">German</mat-option>
              <mat-option value="es">Spanish</mat-option>
            </mat-select>
            <mat-error *ngIf="createUserForm.get('language').errors">Please, enter a valid language.</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-form-field>
            <mat-label>Time Zone</mat-label>
            <mat-select formControlName="timezone">
              <mat-option></mat-option>
              <mat-option *ngFor="let timezone of timezones$ | async" [value]="timezone.id">
                {{ timezone.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createUserForm.get('timezone').errors">Please, enter a valid time zone.</mat-error>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <div class="button">
        <button mat-button class="btn-success btn-300" type="submit">
          <mat-icon>save_alt</mat-icon>
          Save
        </button>
      </div>
    </form>
  </mat-card>
</div>
