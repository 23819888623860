<div class="container">
  <app-psb-page-navigator [pages]="pageService.getConnectionIndicatorPages()"></app-psb-page-navigator>

  <div *ngIf="allObj" cdkDropList [cdkDropListData]="allObj" (cdkDropListDropped)="drop($event)" #questionsList>
    <div *ngFor="let obj of allObj" cdkDrag [cdkDragData]="obj">
      <div class="test-custom-placeholder" *cdkDragPlaceholder></div>

      <mat-accordion>
        <mat-expansion-panel *ngIf="testObjectService.isQuestion(obj)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>{{ obj.text }}</h3>
            </mat-panel-title>
            <mat-panel-description>
              <p>Question: {{ obj.questionType }}</p>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngIf="obj.questionType === 'CARDSORT'">
            <app-answer-cardsort
              [question]="obj"
              [connectionIndicatorId]="connectionIndicatorId"
              [answering]="false"
              [userInfoId]="userInfoId"
            >
            </app-answer-cardsort>
          </div>
          <div *ngIf="obj.questionType === 'USER_CONNECTION'">
            <app-answer-user-connection
              [question]="obj"
              [companyId]="this.connectionIndicator.companyId"
              [connectionIndicatorId]="connectionIndicatorId"
              [answering]="false"
            ></app-answer-user-connection>
          </div>
          <div *ngIf="obj.questionType === 'TEXT_AREA' || obj.questionType === 'RICH_TEXT'">
            <app-answer-text-area
              [question]="obj"
              [companyId]="this.connectionIndicator.companyId"
              [connectionIndicatorId]="connectionIndicatorId"
              [answering]="false"
            ></app-answer-text-area>
          </div>
          <div *ngIf="obj.questionType === 'LIST_TEXT'">
            <app-answer-list-text
              [question]="obj"
              [companyId]="this.connectionIndicator.companyId"
              [connectionIndicatorId]="connectionIndicatorId"
              [answering]="false"
            ></app-answer-list-text>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion>
        <mat-expansion-panel *ngIf="testObjectService.isDecisionTable(obj)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>{{ obj.title }}</h3>
            </mat-panel-title>
            <mat-panel-description>
              <p>Decision Table</p>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-answer-decision-table
            [companyId]="this.connectionIndicator.companyId"
            [connectionIndicatorId]="connectionIndicatorId"
            [decisionTable]="obj"
            [answering]="false"
          >
          </app-answer-decision-table>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion>
        <mat-expansion-panel *ngIf="testObjectService.isQuestionsGroup(obj)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>{{ obj.title }}</h3>
            </mat-panel-title>
            <mat-panel-description>
              <p>Question Group</p>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p *ngIf="obj.instructions" [innerHtml]="obj.instructions"></p>
          <div *ngFor="let question of obj.questions" class="question">
            <div *ngIf="question.questionType === 'CARDSORT'">
              <app-answer-cardsort
                [question]="question"
                [connectionIndicatorId]="connectionIndicatorId"
                [answering]="false"
                [userInfoId]="userInfoId"
              >
              </app-answer-cardsort>
            </div>
            <div *ngIf="question.questionType === 'USER_CONNECTION'">
              <app-answer-user-connection
                [question]="question"
                [companyId]="this.connectionIndicator.companyId"
                [connectionIndicatorId]="connectionIndicatorId"
                [answering]="false"
              ></app-answer-user-connection>
            </div>
            <div *ngIf="question.questionType === 'TEXT_AREA' || question.questionType === 'RICH_TEXT'">
              <app-answer-text-area
                [question]="question"
                [companyId]="this.connectionIndicator.companyId"
                [connectionIndicatorId]="connectionIndicatorId"
                [answering]="false"
              ></app-answer-text-area>
            </div>

            <div *ngIf="question.questionType === 'LIST_TEXT'">
              <app-answer-list-text
                [question]="question"
                [companyId]="this.connectionIndicator.companyId"
                [connectionIndicatorId]="connectionIndicatorId"
                [answering]="false"
              ></app-answer-list-text>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <button mat-raised-button class="btn-success btn-center btn-300" (click)="onNext()">Next</button>
</div>
