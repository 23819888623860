<div *ngIf="userInfoId" class="wrap">
  <app-blossom-graph [chartId]="chartId" [testGroupId]="testGroupId" [userInfoId]="userInfoId"></app-blossom-graph>
  <mat-form-field>
    <mat-label>User</mat-label>
    <mat-select [(ngModel)]="userInfoId">
      <mat-option *ngFor="let user of users" [value]="user.userInfoId"
        >{{ user.firstName }} {{ user.lastName }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
