/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Chart } from '../models/Chart';
import type { TestGroupChartComparison } from '../models/TestGroupChartComparison';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ChartService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a list with all charts
     * Retrieve a list with all charts
     * @returns Chart A list of charts
     * @throws ApiError
     */
    public getChart(): Observable<Array<Chart>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a chart
     * Create a chart
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postChart(
        requestBody?: {
            /**
             * Name of the chart
             */
            name: string;
            /**
             * Header of the chart
             */
            header: string;
            /**
             * Base of the chart
             */
            base: string;
            /**
             * Plot of the chart
             */
            plot: string;
            /**
             * Area index id of the area
             */
            areaIndexId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/chart',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Error while creating a new chart`,
            },
        });
    }

    /**
     * Retrieve a chart by id
     * Retrieve a chart by id
     * @param id Id of the chart
     * @returns Chart A chart
     * @throws ApiError
     */
    public getChart1(
        id: number,
    ): Observable<Chart> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update a chart
     * Update a chart
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchChart(
        id: number,
        requestBody?: {
            /**
             * Type of the chart
             */
            type: string;
            payload: any;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/chart/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `You have to specify a type`,
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a chart
     * Delete a chart
     * @param id Id of the chart
     * @returns any Chart deleted successfully
     * @throws ApiError
     */
    public deleteChart(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/chart/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with charts by test group id
     * Retrieve a list with charts by test group id
     * @param id Id of the chart
     * @param testGroupId Id of the test group
     * @returns Chart A list of charts
     * @throws ApiError
     */
    public getChartTestGroupResults(
        id: number,
        testGroupId: number,
    ): Observable<Chart> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{id}/test-group/{testGroupId}/results',
            path: {
                'id': id,
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with charts by test group id
     * Retrieve a list with charts by test group id
     * @param id Id of the chart
     * @param testGroupId Id of the test group
     * @returns Chart A list of charts
     * @throws ApiError
     */
    public getChartCompanyResults(
        id: number,
        testGroupId: number,
    ): Observable<Chart> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{id}/company/{companyId}/results',
            path: {
                'id': id,
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with charts by user id
     * Retrieve a list with charts by user id
     * @param id Id of the chart
     * @param userId Id of the user
     * @returns Chart A list of charts
     * @throws ApiError
     */
    public getChartUser(
        id: number,
        userId: number,
    ): Observable<Chart> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{id}/user/{userId}',
            path: {
                'id': id,
                'userId': userId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all charts by test group id
     * Retrieve a list with all charts by test group id
     * @param testGroupId Id of the test group
     * @returns Chart A list of charts
     * @throws ApiError
     */
    public getChartTestGroup(
        testGroupId: number,
    ): Observable<Array<Chart>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/test-group/{testGroupId}',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all chart subscales
     * Retrieve a list with all chart subscales
     * @param id Id of the chart
     * @returns Chart A list of chart subscales
     * @throws ApiError
     */
    public getChartSubscale(
        id: number,
    ): Observable<Array<Chart>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{id}/subscale',
            path: {
                'id': id,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Add a subscale to a chart
     * Add a subscale to a chart
     * @param id Id of the chart
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postChartSubscale(
        id: number,
        requestBody?: {
            /**
             * Id of the subscale
             */
            subscaleId: number;
            /**
             * Axis of the chart
             */
            axis: number;
            /**
             * Weight of the chart
             */
            weight: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/chart/{id}/subscale',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Error while creating a chart subscale`,
                404: `Chart not found`,
            },
        });
    }

    /**
     * Update a chart subscale
     * Update a chart subscale
     * @param chartSubscaleId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchChartSubscale(
        chartSubscaleId: number,
        requestBody?: {
            /**
             * Axis of the chart subscale
             */
            axis: number;
            /**
             * Weight of the chart subscale
             */
            weight: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/chart/subscale/{chartSubscaleId}',
            path: {
                'chartSubscaleId': chartSubscaleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `You have to specify a type`,
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a chart subscale
     * Delete a chart subscale
     * @param chartSubscaleId Id of the chart subscale
     * @returns any Chart subscale deleted successfully
     * @throws ApiError
     */
    public deleteChartSubscale(
        chartSubscaleId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/chart/subscale/{chartSubscaleId}',
            path: {
                'chartSubscaleId': chartSubscaleId,
            },
            errors: {
                404: `Chart subscale not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Add a chart to a report
     * Add a chart to a report
     * @param id Id of the report
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postReportChart(
        id: number,
        requestBody?: {
            /**
             * Id of the chart
             */
            chartId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/{id}/chart/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a chart from the report
     * Remove a chart from the report
     * @param reportId Id of the report
     * @param chartId Id of the chart
     * @returns any A report
     * @throws ApiError
     */
    public deleteReportChart(
        reportId: number,
        chartId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/report/{reportId}/chart/{chartId}',
            path: {
                'reportId': reportId,
                'chartId': chartId,
            },
            errors: {
                404: `Report or Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Returns a list with TestGroupChartComparison by testGroupId and chartId
     * Returns a list with TestGroupChartComparison by the given testGroupId and chartId
     * @param testGroupId Id of the TestGroup
     * @param chartId Id of the Chart
     * @returns TestGroupChartComparison A list with TestGroupChartComparison
     * @throws ApiError
     */
    public getReportTestGroupChartComparisonTestGroupChart(
        testGroupId: number,
        chartId: number,
    ): Observable<TestGroupChartComparison> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/test-group-chart-comparison/test-group/{testGroupId}/chart/{chartId}',
            path: {
                'testGroupId': testGroupId,
                'chartId': chartId,
            },
            errors: {
                404: `TestGroupChartComparison not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Updates a TestGroupChartComparison
     * Updates a TestGroupChartComparison
     * @param testGroupId Id of the TestGroup
     * @param chartId Id of the Chart
     * @param hided Hide or not
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchReportTestGroupComparison(
        testGroupId: number,
        chartId: number,
        hided: boolean,
        requestBody?: {
            /**
             * Id of the TestGroup
             */
            testGroupId: number;
            /**
             * Id of the Chart
             */
            chartId: number;
            /**
             * Id of the UserInfo
             */
            userInfoId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/test-group-comparison',
            path: {
                'testGroupId': testGroupId,
                'chartId': chartId,
                'hided': hided,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `TestGroupChartComparison not found`,
                500: `Internal error`,
            },
        });
    }

}
