<div class="row">
  <button mat-icon-button aria-label="Refresh" (click)="loadEmailLogs()" color="primary">
    Refresh Logs
    <mat-icon matSuffix> refresh </mat-icon>
  </button>
</div>
<div class="row">
  <app-psb-table-search [data]="emailLogs" (filter)="applyFilterInvites($event)" placeholder="Search for emails">
  </app-psb-table-search>
</div>

<table mat-table [dataSource]="emailLogs" matSort #sortLogs="matSort">
  <ng-container matColumnDef="toEmail">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
    <td mat-cell *matCellDef="let element">{{ element.toEmail }}</td>
  </ng-container>
  <ng-container matColumnDef="sentAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sent Date</th>
    <td mat-cell *matCellDef="let element">{{ element.sentAt }}</td>
  </ng-container>
  <ng-container matColumnDef="emailType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let element">
      <p
        *ngIf="
          element.emailType === 'invite_role_mandate' ||
          element.emailType === 'invite_connection_indicator' ||
          element.emailType === 'invite_test_group'
        "
      >
        Invite
      </p>
      <p
        *ngIf="
          element.emailType == 'remind_role_mandate' ||
          element.emailType == 'remind_connection_indicator' ||
          element.emailType == 'remind_test_group'
        "
      >
        Remind
      </p>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Content</th>
    <td mat-cell *matCellDef="let element">
      <button mat-button type="button" (click)="showContent(element)">View</button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedLogColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedLogColumns"></tr>
</table>
<mat-paginator #paginator [pageSizeOptions]="[5, 10, 50]" showFirstLastButtons></mat-paginator>
