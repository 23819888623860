import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// components
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

// services
import { CompanyService } from '@app/core/services/company.service';
import { UserService } from '@app/core/services/user.service';

// models
import { User } from '@app/core/models/user.model';

// constants
import { EVENT_CANCEL, ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TGM } from '@app/core/constants';

@Component({
  selector: 'app-user-list-table',
  templateUrl: './user-list-table.component.html',
  styleUrls: ['./user-list-table.component.scss'],
})
export class UserListTableComponent implements OnInit {
  @Input() companyId: number;
  @ViewChild(MatPaginator, { static: true }) paginatorUsers: MatPaginator;
  users: MatTableDataSource<User> = new MatTableDataSource<User>();
  allUser = [];
  displayedUsersColumns: string[] = ['name', 'userName', 'user', 'dateAdded', 'status', 'actions'];

  constructor(
    private companyService: CompanyService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    public dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.companyService.getUsersById(this.companyId).subscribe((res) => {
      this.allUser = [];
      if (res) {
        res.forEach((data) => {
          let status: string;
          if (data.activated?.hasOwnProperty('data')) {
            status = parseInt(data.activated['data'], 10) === 1 ? 'Active' : 'Inactive';
          } else {
            status = data.activated ? 'Active' : 'Inactive';
          }
          const user = {
            id: data.id,
            name: data.firstName + ' ' + data.lastName,
            userName: data.login,
            dateAdded: new Date(data.createdDate).toLocaleDateString('en-US'),
            status,
            userType:
              data.userType == 'ROLE_TEST_USER'
                ? 'Test User'
                : data.userType == ROLE_ADMIN
                ? 'Admin'
                : data.userType == ROLE_TGM
                ? 'Test Group Manager'
                : data.userType == ROLE_ORGANIZATIONAL_ADMIN
                ? 'Organizational Admin'
                : '',
          };

          this.allUser.push(user);
        });
        this.users.data = this.allUser;
        this.users.paginator = this.paginatorUsers;
      }
    });
  }

  onViewUser(user: User) {
    this.router.navigate(['/users', user.id, 'details']);
  }

  onDeleteUser(user: User) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.userService.delete(user.id).subscribe((res) => {
          this.allUser = this.allUser.filter((data) => data.id !== user.id);
          this.refreshTableData();
        });
      }
    });
  }

  refreshTableData() {
    this.users = new MatTableDataSource(this.allUser);
    this.users.paginator = this.paginatorUsers;
    this.changeDetectorRef.detectChanges();
  }
}
