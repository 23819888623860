import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// models
import { ReportDoubleRange } from '@app/core/models/report-double-range.model';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-edit-double-range-dialog',
  templateUrl: './edit-double-range-dialog.component.html',
  styleUrls: ['./edit-double-range-dialog.component.scss'],
})
export class EditDoubleRangeDialogComponent implements OnInit {
  form: FormGroup;
  editorConfig: AngularEditorConfig = {
    height: '8rem',
    editable: true,
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ReportDoubleRange,
    private dialogRef: MatDialogRef<EditDoubleRangeDialogComponent>,
  ) {
    this.form = this.fb.group({
      currentOption: ['he', [Validators.required]],
      from1: [0, [Validators.required]],
      to1: [1, [Validators.required]],
      from2: [0, [Validators.required]],
      to2: [1, [Validators.required]],
      reportText: this.genReportTextFormGroup(),
    });
  }

  ngOnInit(): void {
    this.form.get('from1').setValue(this.data.from1);
    this.form.get('to1').setValue(this.data.to1);
    this.form.get('from2').setValue(this.data.from2);
    this.form.get('to2').setValue(this.data.to2);
    this.form.get('reportText.he').setValue(this.data.text?.he || this.data.reportText?.he);
    if (this.data.text) {
      Object.keys(this.data.text).forEach((pronoun) => {
        if (pronoun !== 'id') {
          if (this.data.text[pronoun]) {
            this.form.get(`reportText.${pronoun}`).setValue(this.data.text[pronoun]);
          }
        }
      });
    }

    if (this.data.reportText) {
      Object.keys(this.data.reportText).forEach((pronoun) => {
        if (pronoun !== 'id') {
          if (this.data.reportText[pronoun]) {
            this.form.get(`reportText.${pronoun}`).setValue(this.data.reportText[pronoun]);
          }
        }
      });
    }
  }

  genReportTextFormGroup() {
    return this.fb.group({
      he: ['', [Validators.required]],
      she: [''],
      they: [''],
    });
  }

  onSubmit() {
    const { value } = this.form;
    this.dialogRef.close({ event: EVENT_SUCCESS, range: value });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
