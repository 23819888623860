<div class="column">
  <h2>Report Model: {{ reportModel.title }}</h2>
  <span class="label">Title</span>
  <p>{{ reportModel.title }}</p>
  <span class="label">Disclosure</span>
  <p>{{ reportModel.disclosure }}</p>
  <span class="label">Text Color</span>
  <p>{{ reportModel.textColor }}</p>
  <span class="label">Title Color</span>
  <p>{{ reportModel.titleColor }}</p>
  <span class="label">Top Bar Color</span>
  <p>{{ reportModel.topBarColor }}</p>
  <span class="label">Side Bar Color</span>
  <p>{{ reportModel.sideBarColor }}</p>
  <span class="label">Signature Title</span>
  <p>{{ reportModel.signatureTitle }}</p>
  <span class="label">Signature Logo</span>
  <img style="max-width: 80px" *ngIf="reportModel && reportModel.signatureLogo" [src]="reportModel.signatureLogo" />
  <span class="label">Signature Text</span>
  <p>{{ reportModel.signatureText }}</p>
</div>
