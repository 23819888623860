import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ToggleMaintenanceModeComponent } from './toggle-maintenance-mode/toggle-maintenance-mode.component';

const maintenanceModeRoutes: Routes = [{ path: '', component: ToggleMaintenanceModeComponent }];

@NgModule({
  imports: [RouterModule.forChild(maintenanceModeRoutes)],
  exports: [RouterModule],
})
export class MaintenanceModeRoutingModule {}
