import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { OptimalCurvesService } from '@app/core/services/optimal-curves.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-optimal-score-curve',
  templateUrl: './optimal-score-curve.component.html',
  styleUrls: ['./optimal-score-curve.component.scss'],
})
export class OptimalScoreCurveComponent implements OnInit {
  optimalCurves: PsbItem[] = [];
  newOptimalCurveForm = this.fb.group({
    id: [''],
    title: ['', [Validators.required]],
  });

  newOptimalCurveDialogRef: MatDialogRef<any>;

  constructor(
    private router: Router,
    private optimalCurvesService: OptimalCurvesService,
    public dialog: MatDialog,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.optimalCurvesService.getAll().subscribe((result) => {
      this.optimalCurves = result.map((optimalCurve) => {
        return {
          id: optimalCurve.id,
          title: optimalCurve.title,
          description: `Number of Curves: ${optimalCurve.numberCurves}`,
        };
      });
    });
  }

  onView(clientId: number) {
    this.router.navigate([`/optimal-curve/${clientId}/details`]);
  }

  onNew(ref: TemplateRef<any>) {
    this.newOptimalCurveForm.patchValue({ id: '', title: '' });
    this.newOptimalCurveDialogRef = this.dialog.open(ref);
  }

  onDelete(clientId: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.optimalCurvesService.delete(clientId).subscribe((res) => {
          if (res) {
            this.optimalCurves = this.optimalCurves.filter((data) => data.id !== clientId);
          }
        });
      }
    });
  }

  onFormSubmit() {
    const { id, title } = this.newOptimalCurveForm.value;

    // if (this.isFormEditing) {
    //   this.reportService.update(id, { title })
    //     .subscribe(updatedReport => {
    //       this.reportPsbItems = this.reportPsbItems
    //         .map(item => item.id !== updatedReport.id ? item : { id: updatedReport.id, title: updatedReport.title });

    //       this.reportFormDialogRef.close();
    //       this.reportFormDialogRef = null;

    //       this.snackBar.info('Report updated successfully');
    //     });
    //   return;
    // }

    this.optimalCurvesService.save({ title }).subscribe((newOptimalCurve) => {
      this.optimalCurves = [{ id: newOptimalCurve.id, title: newOptimalCurve.title }, ...this.optimalCurves];

      this.newOptimalCurveDialogRef.close();
      this.newOptimalCurveDialogRef = null;

      this.router.navigate([`/optimal-curve/${newOptimalCurve.id}/details`]);
    });
  }

  get isFormEditing() {
    return !!this.newOptimalCurveForm.value.id;
  }
}
