import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

// services
import { AreaIndexService } from '@app/core/services/area-index.service';
import { ChartService } from '@app/core/services/chart.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { AreaIndex } from '@app/core/models/area-index.model';
import { PsyChart } from '@app/core/models/psy-chart.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';
import { ChartBaseEnum } from '@app/admin/pages/chart/create-new-chart-dialog/chart-base.enum';

@Component({
  selector: 'app-create-new-chart-dialog',
  templateUrl: './create-new-chart-dialog.component.html',
  styleUrls: ['./create-new-chart-dialog.component.scss'],
})
export class CreateNewChartDialogComponent implements OnInit {
  form = this.fb.group({
    name: ['', [Validators.required]],
    header: ['', [Validators.required]],
    base: ['', [Validators.required]],
    plot: ['', [Validators.required]],
    areaIndex: ['', [Validators.required]],
  });

  bases = Object.values(ChartBaseEnum);

  plots = ['DOT'];

  areas$: Observable<AreaIndex[]>;

  constructor(
    private fb: FormBuilder,
    private chartService: ChartService,
    private areaIndexService: AreaIndexService,
    private dialogRef: MatDialogRef<CreateNewChartDialogComponent>,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.areas$ = this.areaIndexService.getAll();
  }

  onFormSubmit() {
    const { value } = this.form;
    const body: PsyChart = {
      name: value.name,
      header: value.header,
      base: value.base,
      plot: value.plot,
      areaIndexId: value.areaIndex,
    };

    this.chartService.save(body).subscribe(
      (newChart) => {
        this.dialogRef.close({ event: EVENT_SUCCESS, newChart });
      },
      () => {
        this.snackBar.info('Something went wrong.');
      },
    );
  }
}
