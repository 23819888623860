import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// services
import { ChartService } from '@app/core/services/chart.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { PsyChart } from '@app/core/models/psy-chart.model';

@Component({
  selector: 'app-chart-base-setup',
  templateUrl: './chart-base-setup.component.html',
  styleUrls: ['./chart-base-setup.component.scss'],
})
export class ChartBaseSetupComponent implements OnInit, OnChanges {
  @Input() chart: PsyChart;
  form: FormGroup;
  positions = [];

  colors = ['RED', 'ORANGE', 'GREEN', 'BLUE', 'BLACK', 'BROWN'];

  constructor(private fb: FormBuilder, private chartService: ChartService, private snackBar: SnackBarService) {}

  ngOnInit(): void {}

  patchFromValue() {
    // Handling different db connectors responses
    let calculateDiversity;
    if (this.chart.calculateDiversity) {
      if (Object(this.chart.calculateDiversity).hasOwnProperty('data')) {
        calculateDiversity = this.chart.calculateDiversity.data[0];
      } else {
        calculateDiversity = this.chart.calculateDiversity;
      }
    }

    let calculateProximity;
    if (this.chart.calculateProximity) {
      if (Object(this.chart.calculateProximity).hasOwnProperty('data')) {
        calculateProximity = this.chart.calculateProximity.data[0];
      } else {
        calculateProximity = this.chart.calculateProximity;
      }
    }

    this.form.patchValue({
      calculateDiversity,
      calculateProximity,
    });

    const sectorsFormGroup = this.form.get('sectors');
    if (this.chart.base === 'HEXAGON') {
      this.positions.forEach((pos) => {
        const chartSector = this.chart.sextants.find((sec) => sec.direction === pos.code);
        sectorsFormGroup.get(pos.code).patchValue({
          title: chartSector?.title,
          text: chartSector?.text,
          color: chartSector?.color,
        });
      });
    } else {
      this.positions.forEach((pos) => {
        const chartSector = this.chart.sectors.find((sec) => sec.position === pos.code);
        sectorsFormGroup.get(pos.code).patchValue({
          title: chartSector?.title,
          text: chartSector?.text,
          color: chartSector?.color,
        });
      });
    }
  }

  ngOnChanges(): void {
    if (this.chart?.base == 'CIRCLE_IN_CARTESIAN') {
      this.formBuilderForCircle();
    } else if (this.chart?.base == 'HEXAGON') {
      this.formBuilderForHexagon();
    } else if (this.chart?.base == 'FOUR_PLUS_ONE_CARTESIAN') {
      this.formBuilderForFourPlusOneCartesian();
    }

    this.patchFromValue();
  }

  onSave() {
    const { id: chartId } = this.chart;
    const { calculateDiversity, calculateProximity } = this.form.value;
    const sectors = Object.entries(this.form.value.sectors).map(([position, group]: [string, any]) => ({
      title: group.title,
      text: group.text,
      color: group.color,
      position,
    }));

    const params = { calculateDiversity, calculateProximity, sectors, base: this.chart.base };

    this.chartService.updateChartBase(chartId, params).subscribe((newChart) => {
      this.chart = newChart;
      this.snackBar.info('Chart base updated successfully');
    });
  }

  formBuilderForCircle() {
    // Basically 4 phases for base setup
    this.form = this.fb.group({
      calculateDiversity: [false, [Validators.required]],
      calculateProximity: [false, [Validators.required]],
      sectors: this.fb.group({
        TOP_LEFT: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        TOP_RIGHT: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        BOTTOM_RIGHT: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        BOTTOM_LEFT: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
      }),
    });
    this.positions = [
      { code: 'TOP_LEFT', title: 'Top Left' },
      { code: 'TOP_RIGHT', title: 'Top Right' },
      { code: 'BOTTOM_LEFT', title: 'Bottom Left' },
      { code: 'BOTTOM_RIGHT', title: 'Bottom Right' },
    ];
  }

  formBuilderForHexagon() {
    // set base setup 6 phases for hexagon
    this.form = this.fb.group({
      calculateDiversity: [false, [Validators.required]],
      calculateProximity: [false, [Validators.required]],
      sectors: this.fb.group({
        NW: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        N: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        NE: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        SW: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        S: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        SE: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
      }),
    });
    this.positions = [
      { code: 'NW', title: 'Northwest' },
      { code: 'N', title: 'North' },
      { code: 'NE', title: 'Northeast' },
      { code: 'SW', title: 'Southwest' },
      { code: 'S', title: 'South' },
      { code: 'SE', title: 'Southeast' },
    ];
  }

  formBuilderForFourPlusOneCartesian() {
    // Basically 5 phases for FourPlusOneCartesian
    this.form = this.fb.group({
      calculateDiversity: [false, [Validators.required]],
      calculateProximity: [false, [Validators.required]],
      sectors: this.fb.group({
        TOP_LEFT: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        TOP_RIGHT: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        CENTER: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        BOTTOM_RIGHT: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
        BOTTOM_LEFT: this.fb.group({
          title: ['', [Validators.required]],
          text: ['', [Validators.required]],
          color: ['', [Validators.required]],
        }),
      }),
    });
    this.positions = [
      { code: 'TOP_LEFT', title: 'Top Left' },
      { code: 'TOP_RIGHT', title: 'Top Right' },
      { code: 'CENTER', title: 'CENTER' },
      { code: 'BOTTOM_LEFT', title: 'Bottom Left' },
      { code: 'BOTTOM_RIGHT', title: 'Bottom Right' },
    ];
  }
}
