import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-four-one-cartesian',
  templateUrl: './area-four-one-cartesian.component.html',
  styleUrls: ['./area-four-one-cartesian.component.scss'],
})
export class AreaFourOneCartesianComponent implements OnInit {
  @Input('selectedAreaNumber') selectedAreaNumber: any;
  constructor() {}

  ngOnInit(): void {}
}
