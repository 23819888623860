import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// services
import { InviteService } from '@app/core/services/invite.service';

// models
import { Invite } from '@app/core/models/invite.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-edit-user-invite-dialog',
  templateUrl: './edit-user-invite-dialog.component.html',
  styleUrls: ['./edit-user-invite-dialog.component.scss'],
})
export class EditUserInviteDialogComponent implements OnInit {
  editInviteForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    public dialogRef: MatDialogRef<EditUserInviteDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public invite: Invite,
    private inviteService: InviteService,
  ) {}

  ngOnInit(): void {
    this.editInviteForm.get('firstName').setValue(this.invite.firstName);
    this.editInviteForm.get('lastName').setValue(this.invite.lastName);
    this.editInviteForm.get('email').setValue(this.invite.email);
  }

  onCancel() {
    this.dialogRef.close({ event: 'close' });
  }

  onConfirm() {
    if (!this.editInviteForm.valid) {
      return;
    }
    const updatedInvite = Object.assign({}, this.invite);
    updatedInvite.firstName = this.editInviteForm.get('firstName').value;
    updatedInvite.lastName = this.editInviteForm.get('lastName').value;
    updatedInvite.email = this.editInviteForm.get('email').value;
    this.inviteService.update(updatedInvite).subscribe(() => {
      this.invite.firstName = updatedInvite.firstName;
      this.invite.lastName = updatedInvite.lastName;
      this.invite.email = updatedInvite.email;
      this.dialogRef.close({ event: EVENT_SUCCESS, data: this.invite });
    });
  }
}
