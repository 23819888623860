<div class="mat-elevation-z3" *ngIf="scores.length">
  <mat-table [dataSource]="scoresDataSource">
    <ng-container matColumnDef="subscale">
      <mat-header-cell *matHeaderCellDef>Subscale</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.subscale }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="score">
      <mat-header-cell *matHeaderCellDef>Score</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.score.toFixed(2) }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="percentile">
      <mat-header-cell mat-header-cell *matHeaderCellDef>Percentile</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-progress-bar [color]="color" [mode]="mode" [value]="element.percentile"> </mat-progress-bar>
        <div class="status">{{ element.percentile }}%</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell mat-header-cell class="action" *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button class="btn-error test-group-button btn-delete" (click)="onDelete(element.id)">Delete</button>
      </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedScoresColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedScoresColumns"></mat-row>
  </mat-table>
</div>
<h3 class="row" *ngIf="!scores.length">There are no scores for the user.</h3>
