import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';
import { SectionRoutingModule } from './section-routing.module';
import { SectionDetailsComponent } from './section-details/section-details.component';
import { ChapterModule } from '../chapter/chapter.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SectionDetailsComponent],
  imports: [CommonModule, SectionRoutingModule, SharedModule, ChapterModule, ReactiveFormsModule],
})
export class SectionModule {}
