import { AfterViewInit, Component, Input } from '@angular/core';
import * as d3 from 'd3';
import * as d3Scale from 'd3';
import { nest } from 'd3-collection';

// helpers
import { ChartHelper } from '@app/core/helpers/chart-helper';

@Component({
  selector: 'app-violin',
  templateUrl: './violin.component.html',
  styleUrls: ['./violin.component.scss'],
})
export class ViolinComponent extends ChartHelper implements AfterViewInit {
  constructor() {
    super();
    this.width = 450 - this.margin.left - this.margin.right;
    this.height = 550 - this.margin.top - this.margin.bottom;
    this.pageWidth = window.screen.width;
  }

  @Input() leftValue: number;
  @Input() rightValue: number;
  @Input() centerValue: number;
  @Input() leftLabel: string;
  @Input() rightLabel: string;
  @Input() centerLabel: string;
  @Input() chartName: string;
  @Input() scalesLeftResults: { percentile: number }[];
  @Input() scalesRightResults: { percentile: number }[];
  @Input() scalesCenterResults: { percentile: number }[];

  nametoptextcolor = '#425465'; // color for user name or id text
  usercolor = '#F1592A'; // color for user
  maincolor = '#66809a'; // color for main wave
  avgbarcolor = '#425465'; // color of avg bar
  weight = [2, 3, 1];

  margin = { top: 80, right: 50, bottom: 80, left: 50 };
  width: number;
  height: number;
  svg: any;
  pageWidth: any;
  highestSubscalePercentile: number;

  ngAfterViewInit() {
    this.pageWidth = window.screen.width;
    this.highestSubscalePercentile = this.getHighestSubscalePercetile(
      this.scalesLeftResults,
      this.scalesRightResults,
      this.scalesCenterResults,
    );
    this.buildSvg();
    this.buildChart();
  }

  private buildSvg() {
    this.svg = d3
      .select('figure#violin' + this.chartName)
      .append('svg')
      .attr('viewBox', '0 0 ' + 450 + ' ' + 550)
      .attr(
        'width',
        this.responsiveViolin()?.width
          ? this.responsiveViolin().width
          : this.width + (this.margin.left + this.margin.right),
      )
      .attr(
        'height',
        this.responsiveViolin()?.height
          ? this.responsiveViolin().height
          : this.height + (this.margin.top + this.margin.bottom),
      )
      .append('g')
      .attr(
        'transform',
        this.responsiveViolin()?.translate
          ? this.responsiveViolin().translate
          : this.highestSubscalePercentile > 85
          ? 'translate(' + this.margin.left + ',' + 130 + ')'
          : 'translate(' + this.margin.left + ',' + this.margin.top + ')',
      );
  }

  responsiveViolin() {
    if (this.pageWidth < 490) return { width: 250, height: 350, translate: 'translate: (0, 50)' };
  }

  weightedMean(arrValues, arrWeights) {
    const result = arrValues
      .map((value, i) => {
        const weight = arrWeights[i];
        const sum = value * weight;
        return [sum, weight];
      })
      .reduce(
        (p, c) => {
          return [p[0] + c[0], p[1] + c[1]];
        },
        [0, 0],
      );

    return result[0] / result[1];
  }

  private buildChart() {
    const user1arr = [this.leftValue, this.centerValue, this.rightValue];

    const agil = [];
    if (typeof this.scalesCenterResults !== 'undefined') {
      const scalesAgil = this.scalesCenterResults.map((scale) => scale.percentile);
      for (let i = 0; i < scalesAgil.length; i++) {
        agil[i] = {
          subscale: this.centerLabel,
          percentile: scalesAgil[i],
        };
      }
    }

    const leftSubscale = [];
    if (typeof this.scalesLeftResults !== 'undefined') {
      const scalesLeft = this.scalesLeftResults.map((scale) => scale.percentile);
      for (let i = 0; i < scalesLeft.length; i++) {
        leftSubscale[i] = {
          subscale: this.leftLabel,
          percentile: scalesLeft[i],
        };
      }
    }

    const rightSubscale = [];
    if (typeof this.scalesRightResults !== 'undefined') {
      const scalesRight = this.scalesRightResults.map((scale) => scale.percentile);
      for (let i = 0; i < scalesRight.length; i++) {
        rightSubscale[i] = {
          subscale: this.rightLabel,
          percentile: scalesRight[i],
        };
      }
    }

    const allSubscales = leftSubscale.concat(agil, rightSubscale);

    // Build and Show the Y scale
    const y = d3Scale
      .scaleLinear()
      .domain([0, 100]) // Note that here the Y scale is set manually
      .range([this.height - 10, 0]);

    // Append the y axis
    this.svg
      .append('g')
      .call(d3.axisLeft(y))
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .attr('visibility', 'hidden');

    // Build and Show the X scale. It is a band scale like for a boxplot: each group has an
    // dedicated RANGE on the axis. This range has a length of x.bandwidth
    const x = d3
      .scaleBand()
      .range([0, this.width])
      .domain(allSubscales.map((d) => d.subscale))
      .padding(0.02); // This is important: it is the space between 2 groups. 0 means no padding. 1 is the maximum.

    // Apend the X axis
    this.svg
      .append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(x))
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .attr('fill', '#66809a')
      .attr('font-family', 'Arial')
      .attr('font-size', '15px');
    // .attr('class', 'xaxis');

    const dom: number[] = y.domain();
    // Features of the histogram
    const histogram = d3
      .histogram()
      .domain([dom[0], dom[1]])
      .thresholds(y.ticks(31)) // Important: how many bins approx are going to be made? It is the 'resolution' of the violin plot
      .value((d) => d);

    // Compute the binning for each group of the dataset
    const nestData = nest() // nest function allows to group the calculation per level of a factor
      .key((d) => d['subscale'])
      .entries(allSubscales);

    const sumStat = d3.rollup(
      nestData,
      (d) => {
        const input = d[0].values.map((g) => g['percentile']); // Keep the variable called percentile
        const bins = histogram(input); // And compute the binning on it.
        return bins;
      },
      (d) => d['key'],
    );

    const mapSumStat = [];
    sumStat.forEach((value, key) => {
      mapSumStat.push({
        key,
        value,
      });
    });

    // What is the biggest number of value in a bin? We need it cause this value will have a width of 100% of the bandwidth.
    let maxNum = 0;

    for (const mapSum of mapSumStat) {
      const allBins = mapSum.value;
      const lengths = allBins.map((a) => a.length);
      const longuest = parseInt(d3.max(lengths), 10);
      if (longuest > maxNum) {
        maxNum = longuest;
      }
    }

    // The maximum width of a violin must be x.bandwidth = the width dedicated to a group
    const xNum = d3.scaleLinear().range([0, x.bandwidth()]).domain([-maxNum, maxNum]);

    this.svg
      .selectAll('myViolin')
      .data(mapSumStat)
      .enter() // So now we are working group per group
      .append('g')
      .attr('transform', (d) => 'translate(' + x(d.key) + ' ,0)') // Translation on the right to be at the group position
      .append('path')
      .datum((d) => d.value) // So now we are working bin per bin
      .attr(
        'd',
        d3
          .area()
          .x0(xNum(0))
          .x1((d) => xNum(d.length))
          .y((d) => y(d['x0']))
          .curve(d3.curveCatmullRom), // This makes the line smoother to give the violin appearance. Try d3.curveStep to see the difference
      )
      .style('fill', this.maincolor);

    const jitterWidth = 30; // experiment with this  --- different results
    this.svg
      .selectAll('indPoints')
      .data(allSubscales)
      .enter()
      .append('circle')
      .attr('cx', (d) => x(d.subscale) + x.bandwidth() / 2 - Math.random() * jitterWidth)
      .attr('cy', (d) => y(0))
      .attr('r', 2)
      .style('fill', this.maincolor);

    const user1point = [
      { percentile: user1arr[0], subscale: this.leftLabel },
      { percentile: user1arr[1], subscale: this.centerLabel },
      { percentile: user1arr[2], subscale: this.rightLabel },
    ];

    this.svg
      .selectAll('indPoints')
      .data(user1point)
      .enter()
      .append('circle')
      .attr('cx', (d) => x(d.subscale) + x.bandwidth() / 3)
      .attr('cy', (d) => y(d.percentile))
      .attr('r', 7)
      .attr('stroke', '#none')
      .attr('fill', this.usercolor);

    this.svg
      .selectAll('mylines')
      .data(user1point)
      .enter()
      .append('line')
      .attr('x1', (d) => x(d.subscale) + x.bandwidth() / 3)
      .attr('x2', (d) => x(d.subscale) + x.bandwidth() / 3)
      .attr('y1', (d) => y(0))
      .attr('y2', (d) => y(0))
      .attr('stroke', this.usercolor)
      .attr('stroke-width', 2.5);

    this.svg
      .selectAll('line')
      .transition()
      .duration(2000)
      .attr('y1', (d) => y(d.percentile));

    this.svg
      .selectAll('circle')
      .data(allSubscales)
      .transition()
      .duration(2000)
      .attr('cy', (d) => y(d.percentile));

    this.addCopyright(this.svg, this.width / 2, this.height + this.margin.bottom - 3);
  }

  private getHighestSubscalePercetile(scalesLeftResults, scalesRightResults, scalesCenterResults) {
    this.highestSubscalePercentile = scalesLeftResults[0].percentile;
    for (const scalesLeftResult of scalesLeftResults) {
      if (this.highestSubscalePercentile < scalesLeftResult.percentile) {
        this.highestSubscalePercentile = scalesLeftResult.percentile;
      }
    }

    for (const scalesRightResult of scalesRightResults) {
      if (this.highestSubscalePercentile < scalesRightResult.percentile) {
        this.highestSubscalePercentile = scalesRightResult.percentile;
      }
    }

    for (const scalesCenterResult of scalesCenterResults) {
      if (this.highestSubscalePercentile < scalesCenterResult.percentile) {
        this.highestSubscalePercentile = scalesCenterResult.percentile;
      }
    }

    return this.highestSubscalePercentile;
  }
}
