import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HtmlCleanService {
  constructor() {}

  getP(node: any) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    let text = '';
    if (node && node.childNodes) {
      for (const i in node.childNodes) {
        if (node.childNodes[i].textContent) {
          text += node.childNodes[i].textContent ? node.childNodes[i].textContent : '';
        }
      }
    }
    return text;
  }

  handleBoldAndItalicsP(node: any, style?: string) {
    const documentDefinition = { content: { text: [] } };
    this.parseNode(node, documentDefinition.content.text, style);
    return documentDefinition.content;
  }

  checkHasBoldTag(node: any) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    const hasBoldTag = (node && node.querySelector('b') !== null) || (node && node.querySelector('strong') !== null);

    return hasBoldTag;
  }

  parseNode(node: any, content: any[], style?: string) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    if (node) {
      if (node.childNodes) {
        if (node.nodeType === 1 && (node.nodeName === 'B' || node.nodeName === 'STRONG')) {
          const isParentNodeFontColor =
            'parentNode' in node &&
            node.parentNode.nodeType === 1 &&
            node.parentNode.nodeName === 'FONT' &&
            'color' in node.parentNode &&
            node.parentNode.color === '#75425b';
          const isChildNodeFontColor =
            'childNodes' in node &&
            node.childNodes.length > 0 &&
            node.childNodes[0].nodeType === 1 &&
            node.childNodes[0].nodeName === 'FONT' &&
            'color' in node.childNodes[0] &&
            node.childNodes[0].color === '#75425b';
          if (isParentNodeFontColor || isChildNodeFontColor) {
            content.push({ text: node.textContent, bold: true, style: style ? style : null, color: '#75425b' });
          } else {
            content.push({ text: node.textContent, bold: true, style: style ? style : null });
          }
          return;
        } else if (node.nodeType === 1 && node.nodeName === 'I') {
          content.push({ text: `\n`, style: style ? style : null });
          content.push({ text: node.textContent, italics: true, style: style ? style : null });
          return;
        } else if (node.nodeType === 1 && node.nodeName === 'BR') {
          content.push({ text: '\n', style: style ? style : null });
        } else {
          if (node.nodeType === 3) {
            content.push({ text: node.textContent, style: style ? style : null });
          }
        }

        for (const childNode of Array.from(node.childNodes)) {
          this.parseNode(childNode, content, style);
        }
      }
    }
  }

  getU(node: any, style?: any) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    const ul = [];
    if (node.childNodes) {
      for (const i in node.childNodes) {
        if (node.childNodes[i].textContent) {
          if (!this.checkHasBoldTag(node.childNodes[i]) && !this.checkHasItalicsTag(node.childNodes[i])) {
            ul.push(node.childNodes[i].textContent);
          } else {
            ul.push(this.handleBoldAndItalicsP(node.childNodes[i], style));
          }
        }
      }
    }
    return ul;
  }

  getO(node: any, style?: any) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    const ol = [];
    if (node.childNodes) {
      for (const i in node.childNodes) {
        if (node.childNodes[i].textContent) {
          if (!this.checkHasBoldTag(node.childNodes[i]) && !this.checkHasItalicsTag(node.childNodes[i])) {
            ol.push(node.childNodes[i].textContent);
          } else {
            ol.push(this.handleBoldAndItalicsP(node.childNodes[i], style));
          }
        }
      }
    }
    return ol;
  }

  getLabel(node: any) {
    let label = node
      .replace(/<div>/g, '\n')
      .replace(/<\/div>/g, '')
      .replace(/&#160;/g, ' ')
      .replace(/<span>/g, ' ')
      .replace(/<\/span>/g, ' ');
    return label;
  }

  getDiv(node: any) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    let text = '';
    if (node.childNodes) {
      for (const i in node.childNodes) {
        if (node.childNodes[i].textContent) {
          text += node.childNodes[i].textContent ? node.childNodes[i].textContent : '';
        }
      }
    }
    return text;
  }

  checkHasUTag(node: any) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    const hasUTag = node.querySelector('ul') !== null && node.querySelector('li') !== null;

    return hasUTag;
  }

  checkHasOTag(node: any) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    const hasUTag = node.querySelector('ol') !== null && node.querySelector('li') !== null;

    return hasUTag;
  }

  checkHasItalicsTag(node: any) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    const hasItalicsTag = node && node.querySelector('i') !== null;

    return hasItalicsTag;
  }

  checkHasH3Tag(node: any) {
    if (typeof node === 'string') {
      node = new DOMParser().parseFromString(node, 'text/html');
    }

    const hasH3Tag = node.querySelector('h3') !== null;

    return hasH3Tag;
  }
}
