<mat-card-header>
  <h3>Generic Links</h3>
</mat-card-header>
<mat-card-content>
  <span class="mat-card-description"
    >Generate a universal link that allows users to register into this test group.</span
  >
  <br />
  <button
    mat-raised-button
    class="new-button pull-right"
    color="primary"
    style="margin-bottom: 10px; margin-top: 10px"
    (click)="onCreate()"
  >
    <mat-icon aria-hidden="false">add</mat-icon>Create a Generic Link
  </button>
  <mat-table *ngIf="genericLinks && genericLinks.length > 0" [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Token Column -->
    <ng-container matColumnDef="token">
      <mat-header-cell *matHeaderCellDef> Link </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ environment.url }}/g-invite/{{ element.token }} </mat-cell>
    </ng-container>

    <!-- Expiration Date Column -->
    <!--    <ng-container matColumnDef="expirationDate">-->
    <!--      <mat-header-cell *matHeaderCellDef> Expiration Date </mat-header-cell>-->
    <!--      <mat-cell *matCellDef="let element"> {{ element.expirationDate }} </mat-cell>-->
    <!--    </ng-container>-->

    <!-- Enabled Column -->
    <ng-container matColumnDef="enabled">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-chip *ngIf="element.enabled" color="primary" selected> Active </mat-chip>
        <mat-chip *ngIf="!element.enabled" color="primary" selected> Disabled </mat-chip>
      </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button class="btn-error" (click)="onCopy(element.token)">Copy</button>
        <button mat-button class="btn-error" (click)="onView(element)">View</button>
        <button mat-button class="btn-error" (click)="onEdit(element)">Edit</button>
        <button mat-button class="btn-error" (click)="onDelete(element.id)">Delete</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</mat-card-content>
