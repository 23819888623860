<div class="homepage">
  <div class="main primary-background">
    <div class="main-section container">
      <div class="headline">
        <h1>Psybil Help</h1>
        <div class="start">
          <p>Questions or problems? Please reach out to us using the chat tool.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="homepage-section text-center container">
    <h1>FAQs</h1>

    <h2>How much time should I set aside to complete my assessments?</h2>
    <p>Specific times vary based on type of assessment. It’s best to set aside 60 to 120 minutes for completion.</p>

    <h2>Do I need to complete all assessments in one sitting?</h2>
    <p>
      No. However, you must finish each section for your answers to register. You may leave your assessment in between
      test sections and pick up where you left off when you return.
    </p>

    <h2>I’ve registered in the past, but am still unable to sign in?</h2>
    <p>
      Make sure you are using the exact name as password associated with your account. You are able to reset your
      password by clicking the Get started button and following directions for “reset password”.
    </p>

    <h2>Am I able to complete assessments using my mobile device?</h2>
    <p>
      Currently, we are working adapting our assessment to mobile devices. In the meantime, it’s best to complete
      assessments in your desktop or computer browser.
    </p>

    <h2>I have followed all directions and am still having difficulties completing assessments.</h2>
    <p>
      No problem. If you are using a desktop, please make sure that your web browser is updated to its current version.
      If problems persist, please use the chat tool to contact us directly.
    </p>

    <h2>I’m nervous. Any advice for taking Psybil assessments?</h2>
    <p>
      Yes! A feeling of nervousness or apprehensiveness is completely natural. The night before taking assessments,
      relax and get a good night sleep. On the day of assessments, eat a healthy meal, take a deep breath and answer all
      questions honestly.
    </p>

    <h2>Do I need to answer all of the questions?</h2>
    <p>
      For most of our tests, we need you to answer all of the questions to get an accurate result. Because we wanted to
      respect your time, we made the process as efficient as possible so any missed responses will impact the results.
      The exception to this is the PHACT2 or CARA25. On these assessments it is better to leave an item blank if you do
      not know the answer.
    </p>

    <h3>Remember, this is your story and no one knows it better than you— we’re simply here to help you tell it!</h3>
  </div>
</div>
