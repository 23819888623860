import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

// services
import { AnswerService } from '@app/core/services/answer.service';
import { QuestionService } from '@app/core/services/question.service';

// models
import { Question } from '@app/core/models/question.model';
import { UserAnswer } from '@app/core/models/user-answer.model';
import { TextAnswer } from '@app/core/models/text-answer.model';

@Component({
  selector: 'app-answer-text-single-line',
  templateUrl: './answer-text-single-line.component.html',
  styleUrls: ['./answer-text-single-line.component.scss'],
})
export class AnswerTextSingleLineComponent implements OnInit {
  @Input() question: Question;
  @Input() testResultId: number;
  @Input() loadAnswer: boolean;
  @Input() answering: boolean;
  @Input() psyTestId: number;
  @Input() superTestId: number;
  @Input() userInfoId: number;
  @Input() isPreview: boolean;
  @Output() userAnswer = new EventEmitter<{ questionId: number; userAnswer: UserAnswer }>();

  answerTextAreaControl = new FormControl('');
  textAreaAnswer: TextAnswer;
  newAnswer = false;
  answerTextAreaTimer: any;
  qGInstructions: string;

  constructor(private questionService: QuestionService, private answerService: AnswerService) {}

  ngOnInit(): void {
    if (!this.isPreview) {
      if (!this.loadAnswer && (this.psyTestId || this.superTestId)) {
        this.questionService
          .findTextAnswerByQuestionIdAndTestResultId(this.question.id, this.testResultId)
          .subscribe((answer) => this.loadTextAreaAnswer(answer));
      } else if (this.loadAnswer && (this.psyTestId || this.superTestId)) {
        if (!this.textAreaAnswer && !this.answerTextAreaControl.value) {
          if (this.question.userAnswer && this.question.userAnswer.textAnswer) {
            this.loadTextAreaAnswerFromUserAnswer(this.question.userAnswer);
          }
        }
      }
    }

    if (this.question.questionGroup) {
      this.qGInstructions = this.question.questionGroup?.instructions;
    }
  }

  loadTextAreaAnswerFromUserAnswer(userAnswer: UserAnswer) {
    this.textAreaAnswer = {
      answerId: userAnswer.textAnswer.id,
      answer: userAnswer.textAnswer.answer,
      questionId: this.question.id,
    };

    this.answerTextAreaControl.setValue(userAnswer.textAnswer.answer);
  }

  loadTextAreaAnswer(answer: TextAnswer) {
    if (answer?.answerId) {
      this.textAreaAnswer = answer;
      this.answerTextAreaControl.setValue(answer.answer);
    } else {
      this.newAnswer = true;
    }
  }

  savePsyOrSuperTextAreaAnswer(event) {
    if (this.superTestId || this.psyTestId) {
      clearTimeout(this.answerTextAreaTimer);
      this.answerTextAreaTimer = setTimeout(() => {
        if (event.target.value.length > 0) {
          if (this.textAreaAnswer && this.textAreaAnswer.answerId) {
            this.textAreaAnswer.answer = event.target.value;

            this.answerService.updateTextArea(this.question.id, this.textAreaAnswer).subscribe(() => {
              this.answerTextAreaControl.patchValue(this.textAreaAnswer.answer);
            });
          } else {
            this.textAreaAnswer = {
              questionId: this.question.id,
              answer: event.target.value,
              userInfoId: this.userInfoId,
              testResultId: this.testResultId,
            };
            this.answerService.saveTextArea(this.question.id, this.textAreaAnswer).subscribe((result) => {
              const userAnswer = {
                questionId: this.question.id,
                userAnswer: {
                  integerAnswer: null,
                  textAnswer: { id: result.id, answer: this.answerTextAreaControl.value },
                  listAnswer: null,
                },
              };
              this.userAnswer.emit(userAnswer);
              this.textAreaAnswer.answerId = result.id;
              this.answerTextAreaControl.setValue(this.textAreaAnswer.answer);
            });
          }
        }
      }, 2000);
    }
  }
}
