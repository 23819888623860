<div class="chart-container">
  <div class="chart-column">
    <app-blossom-graph [chartId]="chartId" [testGroupId]="testGroupId" [userInfoId]="userInfoId"></app-blossom-graph>
  </div>
  <div *ngIf="blossomChart?.hasSelfEvaluationKey" class="legend-column">
    <div class="legend">
      <div class="legend-title">Self evaluation key</div>
      <div class="legend-item" *ngFor="let item of legend()">
        <div class="legend-item-color" [style.background-color]="item.color"></div>
        <div class="legend-item-name">{{ item.label }}</div>
      </div>
    </div>
  </div>
</div>
<p *ngIf="blossomChart?.description && blossomChart.description !== ''">{{ blossomChart.description }}</p>

<table class="mat-table blossom-table" *ngIf="blossomChart?.isTable">
  <ng-container *ngFor="let petal of petals; let index = index">
    <tr class="mat-row">
      <td class="mat-cell" colspan="2">
        <h2 class="r-h2" [ngStyle]="{ color: petal.color }">{{ petal.label }}</h2>
      </td>
      <td class="mat-cell cell-center">
        <h2 class="r-h2" [ngStyle]="{ color: petal.color }">{{ petal.percentile }}</h2>
      </td>
      <td class="mat-cell"></td>
    </tr>
    <tr class="mat-header-row blossom-header" *ngIf="petal.pistils.length === 0 && petal.description != ''">
      <td class="mat-cell cell-no-border" colspan="4">{{ petal.description }}</td>
    </tr>
    <tr class="mat-header-row blossom-header" *ngIf="petal.pistils.length > 0 && index === 0">
      <th class="mat-header-cell">Scale</th>
      <th class="mat-header-cell">Description</th>
      <th class="mat-header-cell cell-center">Score</th>
      <th class="mat-header-cell cell-center cell-no-pedding">Symbol</th>
    </tr>
    <tr class="mat-row" *ngFor="let pistil of petal.pistils">
      <td class="mat-cell">{{ pistil.label }}</td>
      <td class="mat-cell cell-description">
        {{ pistil.description }}
      </td>
      <td class="mat-cell cell-center">{{ pistil.percentile }}</td>
      <td class="mat-cell cell-no-pedding">
        <div class="petal-sign-wrapper">
          <div class="petal-sign" [style.border-color]="petal.color">
            <div class="pistil-sign" [style.background-color]="pistil.color"></div>
          </div>
        </div>
      </td>
    </tr>
  </ng-container>
</table>
