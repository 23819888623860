import { Directive, Input, HostListener, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AsYouType } from 'libphonenumber-js';

@Directive({
  selector: '[formControlName][app-phone-mask]',
})
export class PhoneMaskDirective implements OnChanges {
  @Input() country: any;
  @Input() isEdit: boolean;
  @Input() phone: string;

  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    if (!this.isEdit) {
      this.onInputChange(event, false, this.country, this.isEdit);
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true, this.country, this.isEdit);
  }

  ngOnChanges() {
    if (this.isEdit) {
      this.onInputChange(this.phone, false, this.country, this.isEdit);
    }
  }

  onInputChange(event, backspace, country, isEdit) {
    const asYouType = this.getFormatter(country);
    if (asYouType) {
      let newVal = '';

      if (isEdit) {
        newVal = event.toString();
      } else {
        newVal = event.replace(/\D/g, '');
      }

      if (backspace && newVal.length <= 6) {
        newVal = newVal.substring(0, newVal.length - 1);
      }

      newVal = asYouType.input(newVal);
      this.ngControl.valueAccessor.writeValue(newVal);
    }
  }

  getFormatter(c): AsYouType {
    let asYouType: AsYouType = null;
    const country = parseInt(c, 10);
    switch (country) {
      case 1:
        asYouType = new AsYouType('US');
        break;
      case 2:
        asYouType = new AsYouType('BR');
        break;
      case 3:
        asYouType = new AsYouType('AF');
        break;
      case 4:
        asYouType = new AsYouType('AL');
        break;
      case 5:
        asYouType = new AsYouType('DZ');
        break;
      case 6:
        asYouType = new AsYouType('AS');
        break;
      case 7:
        asYouType = new AsYouType('AD');
        break;
      case 8:
        asYouType = new AsYouType('AD');
        break;
      case 9:
        asYouType = new AsYouType('AI');
        break;
      case 11:
        asYouType = new AsYouType('AG');
        break;
      case 12:
        asYouType = new AsYouType('AR');
        break;
      case 13:
        asYouType = new AsYouType('AM');
        break;
      case 14:
        asYouType = new AsYouType('AW');
        break;
      case 15:
        asYouType = new AsYouType('AU');
        break;
      case 16:
        asYouType = new AsYouType('AT');
        break;
      case 17:
        asYouType = new AsYouType('AZ');
        break;
      case 18:
        asYouType = new AsYouType('BS');
        break;
      case 19:
        asYouType = new AsYouType('BH');
        break;
      case 20:
        asYouType = new AsYouType('BD');
        break;
      case 21:
        asYouType = new AsYouType('BB');
        break;
      case 22:
        asYouType = new AsYouType('BY');
        break;
      case 23:
        asYouType = new AsYouType('BE');
        break;
      case 24:
        asYouType = new AsYouType('BZ');
        break;
      case 25:
        asYouType = new AsYouType('BJ');
        break;
      case 26:
        asYouType = new AsYouType('BM');
        break;
      case 27:
        asYouType = new AsYouType('BT');
        break;
      case 28:
        asYouType = new AsYouType('BO');
        break;
      case 29:
        asYouType = new AsYouType('BQ');
        break;
      case 30:
        asYouType = new AsYouType('BA');
        break;
      case 31:
        asYouType = new AsYouType('BW');
        break;
      case 33:
        asYouType = new AsYouType('IO');
        break;
      case 34:
        asYouType = new AsYouType('BN');
        break;
      case 35:
        asYouType = new AsYouType('BG');
        break;
      case 36:
        asYouType = new AsYouType('BF');
        break;
      case 37:
        asYouType = new AsYouType('BI');
        break;
      case 38:
        asYouType = new AsYouType('KH');
        break;
      case 39:
        asYouType = new AsYouType('CM');
        break;
      case 40:
        asYouType = new AsYouType('CA');
        break;
      case 41:
        asYouType = new AsYouType('CV');
        break;
      case 42:
        asYouType = new AsYouType('KY');
        break;
      case 43:
        asYouType = new AsYouType('CF');
        break;
      case 44:
        asYouType = new AsYouType('TD');
        break;
      case 45:
        asYouType = new AsYouType('CL');
        break;
      case 46:
        asYouType = new AsYouType('CN');
        break;
      case 47:
        asYouType = new AsYouType('CX');
        break;
      case 48:
        asYouType = new AsYouType('CC');
        break;
      case 49:
        asYouType = new AsYouType('CO');
        break;
      case 50:
        asYouType = new AsYouType('KM');
        break;
      case 51:
        asYouType = new AsYouType('CG');
        break;
      case 52:
        asYouType = new AsYouType('CD');
        break;
      case 53:
        asYouType = new AsYouType('CK');
        break;
      case 54:
        asYouType = new AsYouType('CR');
        break;
      case 55:
        asYouType = new AsYouType('HR');
        break;
      case 56:
        asYouType = new AsYouType('CU');
        break;
      case 57:
        asYouType = new AsYouType('CW');
        break;
      case 58:
        asYouType = new AsYouType('CY');
        break;
      case 59:
        asYouType = new AsYouType('CZ');
        break;
      case 60:
        asYouType = new AsYouType('CI');
        break;
      case 61:
        asYouType = new AsYouType('DK');
        break;
      case 62:
        asYouType = new AsYouType('DJ');
        break;
      case 63:
        asYouType = new AsYouType('DM');
        break;
      case 64:
        asYouType = new AsYouType('DO');
        break;
      case 65:
        asYouType = new AsYouType('EC');
        break;
      case 66:
        asYouType = new AsYouType('EG');
        break;
      case 67:
        asYouType = new AsYouType('SV');
        break;
      case 68:
        asYouType = new AsYouType('GQ');
        break;
      case 69:
        asYouType = new AsYouType('ER');
        break;
      case 70:
        asYouType = new AsYouType('EE');
        break;
      case 71:
        asYouType = new AsYouType('ET');
        break;
      case 72:
        asYouType = new AsYouType('FK');
        break;
      case 73:
        asYouType = new AsYouType('FO');
        break;
      case 74:
        asYouType = new AsYouType('FJ');
        break;
      case 75:
        asYouType = new AsYouType('FI');
        break;
      case 76:
        asYouType = new AsYouType('FR');
        break;
      case 77:
        asYouType = new AsYouType('GF');
        break;
      case 78:
        asYouType = new AsYouType('PF');
        break;
      case 80:
        asYouType = new AsYouType('GA');
        break;
      case 81:
        asYouType = new AsYouType('GM');
        break;
      case 82:
        asYouType = new AsYouType('GE');
        break;
      case 83:
        asYouType = new AsYouType('DE');
        break;
      case 84:
        asYouType = new AsYouType('GH');
        break;
      case 85:
        asYouType = new AsYouType('GI');
        break;
      case 86:
        asYouType = new AsYouType('GR');
        break;
      case 87:
        asYouType = new AsYouType('GL');
        break;
      case 88:
        asYouType = new AsYouType('GL');
        break;
      case 89:
        asYouType = new AsYouType('GP');
        break;
      case 90:
        asYouType = new AsYouType('GU');
        break;
      case 91:
        asYouType = new AsYouType('GT');
        break;
      case 92:
        asYouType = new AsYouType('GG');
        break;
      case 93:
        asYouType = new AsYouType('GN');
        break;
      case 94:
        asYouType = new AsYouType('GW');
        break;
      case 95:
        asYouType = new AsYouType('GY');
        break;
      case 96:
        asYouType = new AsYouType('HT');
        break;
      case 98:
        asYouType = new AsYouType('VA');
        break;
      case 99:
        asYouType = new AsYouType('HN');
        break;
      case 100:
        asYouType = new AsYouType('HK');
        break;
      case 101:
        asYouType = new AsYouType('HU');
        break;
      case 102:
        asYouType = new AsYouType('IS');
        break;
      case 103:
        asYouType = new AsYouType('IN');
        break;
      case 104:
        asYouType = new AsYouType('ID');
        break;
      case 105:
        asYouType = new AsYouType('IR');
        break;
      case 106:
        asYouType = new AsYouType('IQ');
        break;
      case 107:
        asYouType = new AsYouType('IE');
        break;
      case 108:
        asYouType = new AsYouType('IM');
        break;
      case 109:
        asYouType = new AsYouType('IL');
        break;
      case 110:
        asYouType = new AsYouType('IT');
        break;
      case 111:
        asYouType = new AsYouType('JM');
        break;
      case 112:
        asYouType = new AsYouType('JP');
        break;
      case 113:
        asYouType = new AsYouType('JE');
        break;
      case 114:
        asYouType = new AsYouType('JO');
        break;
      case 115:
        asYouType = new AsYouType('KZ');
        break;
      case 116:
        asYouType = new AsYouType('KE');
        break;
      case 117:
        asYouType = new AsYouType('KI');
        break;
      case 118:
        asYouType = new AsYouType('KP');
        break;
      case 119:
        asYouType = new AsYouType('KR');
        break;
      case 120:
        asYouType = new AsYouType('KW');
        break;
      case 121:
        asYouType = new AsYouType('KG');
        break;
      case 122:
        asYouType = new AsYouType('LA');
        break;
      case 123:
        asYouType = new AsYouType('LV');
        break;
      case 124:
        asYouType = new AsYouType('LB');
        break;
      case 125:
        asYouType = new AsYouType('LS');
        break;
      case 126:
        asYouType = new AsYouType('LR');
        break;
      case 127:
        asYouType = new AsYouType('LY');
        break;
      case 128:
        asYouType = new AsYouType('LI');
        break;
      case 129:
        asYouType = new AsYouType('LT');
        break;
      case 130:
        asYouType = new AsYouType('LU');
        break;
      case 131:
        asYouType = new AsYouType('MO');
        break;
      case 132:
        asYouType = new AsYouType('MK');
        break;
      case 133:
        asYouType = new AsYouType('MG');
        break;
      case 134:
        asYouType = new AsYouType('MW');
        break;
      case 135:
        asYouType = new AsYouType('MY');
        break;
      case 136:
        asYouType = new AsYouType('MV');
        break;
      case 137:
        asYouType = new AsYouType('ML');
        break;
      case 138:
        asYouType = new AsYouType('MT');
        break;
      case 139:
        asYouType = new AsYouType('MH');
        break;
      case 140:
        asYouType = new AsYouType('MQ');
        break;
      case 141:
        asYouType = new AsYouType('MR');
        break;
      case 142:
        asYouType = new AsYouType('MU');
        break;
      case 143:
        asYouType = new AsYouType('YT');
        break;
      case 144:
        asYouType = new AsYouType('MX');
        break;
      case 145:
        asYouType = new AsYouType('FM');
        break;
      case 146:
        asYouType = new AsYouType('MD');
        break;
      case 147:
        asYouType = new AsYouType('MC');
        break;
      case 148:
        asYouType = new AsYouType('MN');
        break;
      case 149:
        asYouType = new AsYouType('ME');
        break;
      case 150:
        asYouType = new AsYouType('MS');
        break;
      case 151:
        asYouType = new AsYouType('MA');
        break;
      case 152:
        asYouType = new AsYouType('MZ');
        break;
      case 153:
        asYouType = new AsYouType('MM');
        break;
      case 154:
        asYouType = new AsYouType('NA');
        break;
      case 155:
        asYouType = new AsYouType('NR');
        break;
      case 156:
        asYouType = new AsYouType('NP');
        break;
      case 157:
        asYouType = new AsYouType('NL');
        break;
      case 158:
        asYouType = new AsYouType('NC');
        break;
      case 159:
        asYouType = new AsYouType('NZ');
        break;
      case 160:
        asYouType = new AsYouType('NI');
        break;
      case 161:
        asYouType = new AsYouType('NE');
        break;
      case 162:
        asYouType = new AsYouType('NG');
        break;
      case 163:
        asYouType = new AsYouType('NU');
        break;
      case 164:
        asYouType = new AsYouType('NF');
        break;
      case 165:
        asYouType = new AsYouType('MP');
        break;
      case 166:
        asYouType = new AsYouType('NO');
        break;
      case 167:
        asYouType = new AsYouType('OM');
        break;
      case 168:
        asYouType = new AsYouType('PK');
        break;
      case 169:
        asYouType = new AsYouType('PW');
        break;
      case 170:
        asYouType = new AsYouType('PS');
        break;
      case 171:
        asYouType = new AsYouType('PA');
        break;
      case 172:
        asYouType = new AsYouType('PG');
        break;
      case 173:
        asYouType = new AsYouType('PY');
        break;
      case 174:
        asYouType = new AsYouType('PE');
        break;
      case 175:
        asYouType = new AsYouType('PH');
        break;
      case 177:
        asYouType = new AsYouType('PL');
        break;
      case 178:
        asYouType = new AsYouType('PT');
        break;
      case 179:
        asYouType = new AsYouType('PR');
        break;
      case 180:
        asYouType = new AsYouType('QA');
        break;
      case 181:
        asYouType = new AsYouType('RO');
        break;
      case 182:
        asYouType = new AsYouType('RU');
        break;
      case 183:
        asYouType = new AsYouType('RW');
        break;
      case 184:
        asYouType = new AsYouType('RE');
        break;
      case 185:
        asYouType = new AsYouType('BL');
        break;
      case 186:
        asYouType = new AsYouType('SH');
        break;
      case 187:
        asYouType = new AsYouType('KN');
        break;
      case 188:
        asYouType = new AsYouType('LC');
        break;
      case 189:
        asYouType = new AsYouType('MF');
        break;
      case 190:
        asYouType = new AsYouType('PM');
        break;
      case 191:
        asYouType = new AsYouType('VC');
        break;
      case 192:
        asYouType = new AsYouType('WS');
        break;
      case 193:
        asYouType = new AsYouType('SM');
        break;
      case 194:
        asYouType = new AsYouType('ST');
        break;
      case 195:
        asYouType = new AsYouType('SA');
        break;
      case 196:
        asYouType = new AsYouType('SN');
        break;
      case 197:
        asYouType = new AsYouType('RS');
        break;
      case 198:
        asYouType = new AsYouType('SC');
        break;
      case 199:
        asYouType = new AsYouType('SL');
        break;
      case 200:
        asYouType = new AsYouType('SG');
        break;
      case 201:
        asYouType = new AsYouType('SX');
        break;
      case 202:
        asYouType = new AsYouType('SK');
        break;
      case 203:
        asYouType = new AsYouType('SI');
        break;
      case 204:
        asYouType = new AsYouType('SB');
        break;
      case 205:
        asYouType = new AsYouType('SO');
        break;
      case 206:
        asYouType = new AsYouType('ZA');
        break;
      case 208:
        asYouType = new AsYouType('SS');
        break;
      case 209:
        asYouType = new AsYouType('ES');
        break;
      case 210:
        asYouType = new AsYouType('LK');
        break;
      case 211:
        asYouType = new AsYouType('SD');
        break;
      case 212:
        asYouType = new AsYouType('SR');
        break;
      case 213:
        asYouType = new AsYouType('SJ');
        break;
      case 214:
        asYouType = new AsYouType('SZ');
        break;
      case 215:
        asYouType = new AsYouType('SE');
        break;
      case 216:
        asYouType = new AsYouType('CH');
        break;
      case 217:
        asYouType = new AsYouType('SY');
        break;
      case 218:
        asYouType = new AsYouType('TW');
        break;
      case 219:
        asYouType = new AsYouType('TJ');
        break;
      case 220:
        asYouType = new AsYouType('TZ');
        break;
      case 221:
        asYouType = new AsYouType('TH');
        break;
      case 222:
        asYouType = new AsYouType('TL');
        break;
      case 223:
        asYouType = new AsYouType('TG');
        break;
      case 224:
        asYouType = new AsYouType('TK');
        break;
      case 225:
        asYouType = new AsYouType('TO');
        break;
      case 226:
        asYouType = new AsYouType('TT');
        break;
      case 227:
        asYouType = new AsYouType('TN');
        break;
      case 228:
        asYouType = new AsYouType('TR');
        break;
      case 229:
        asYouType = new AsYouType('TM');
        break;
      case 230:
        asYouType = new AsYouType('TC');
        break;
      case 231:
        asYouType = new AsYouType('TV');
        break;
      case 232:
        asYouType = new AsYouType('UG');
        break;
      case 233:
        asYouType = new AsYouType('UA');
        break;
      case 234:
        asYouType = new AsYouType('AE');
        break;
      case 235:
        asYouType = new AsYouType('GB');
        break;
      case 237:
        asYouType = new AsYouType('UY');
        break;
      case 238:
        asYouType = new AsYouType('UZ');
        break;
      case 239:
        asYouType = new AsYouType('UZ');
        break;
      case 240:
        asYouType = new AsYouType('VE');
        break;
      case 241:
        asYouType = new AsYouType('VN');
        break;
      case 242:
        asYouType = new AsYouType('VG');
        break;
      case 243:
        asYouType = new AsYouType('VI');
        break;
      case 244:
        asYouType = new AsYouType('WF');
        break;
      case 245:
        asYouType = new AsYouType('EH');
        break;
      case 246:
        asYouType = new AsYouType('YE');
        break;
      case 247:
        asYouType = new AsYouType('ZM');
        break;
      case 248:
        asYouType = new AsYouType('ZW');
        break;
    }

    return asYouType;
  }
}
