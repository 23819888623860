import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// components
import { CreateNewTestDialogComponent } from '../../tests/create-new-test-dialog/create-new-test-dialog.component';

// services
import { SuperTestService } from '@app/core/services/super-test.service';

// models
import { SuperTest } from '@app/core/models/supertest.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-super-test-detail',
  templateUrl: './super-test-detail.component.html',
  styleUrls: ['./super-test-detail.component.scss'],
})
export class SuperTestDetailComponent implements OnInit {
  public superTestId: number;
  public active: number;
  public randomizeOrder: number;
  public superTest: SuperTest;
  public selected = new FormControl(0);
  public tabs: string[] = ['Details', 'Preview'];
  public everyPageInstructionEnabled: boolean = false;
  constructor(private superTestService: SuperTestService, private route: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.selected.setValue(params.id ? +params.tab : 0);
    });
    this.superTestId = Number(this.route.snapshot.params.id);
    this.getSuperTestDetail();
  }

  getSuperTestDetail() {
    this.superTestService.getSuperTestById(this.superTestId).subscribe((response) => {
      if (response) {
        this.superTest = response;

        // Handle different db connector responses
        let active: any;
        if (this.superTest.active.hasOwnProperty('data')) {
          active = parseInt(this.superTest.active['data'], 10);
        } else {
          active = this.superTest.active;
        }

        let randomizeOrder: any;
        if (this.superTest.randomizeOrder.hasOwnProperty('data')) {
          randomizeOrder = parseInt(this.superTest.randomizeOrder['data'], 10);
        } else {
          randomizeOrder = this.superTest.randomizeOrder;
        }
        this.active = active;
        this.randomizeOrder = randomizeOrder;
        this.everyPageInstructionEnabled = this.superTest.everyPageInstructionsEnabled;
      }
    });
  }

  onEdit() {
    this.dialog
      .open(CreateNewTestDialogComponent, {
        data: { isSuperTest: true, isEdit: true, test: null, superTest: this.superTest },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          const updatedSuperTest: SuperTest = {
            id: result.data.id,
            title: result.data.title,
            description: result.data.description,
            startInstructions: result.data.startPageInstructions,
            warningInstructions: result.data.warningInstructions,
            paging: result.data.paging,
            active: result.data.active,
            randomizeOrder: result.data.randomizeOrder,
            deleted: false,
            everyPageInstructionsEnabled: result.data.everyPageInstructionsEnabled,
          };

          this.superTestService.updateSuperTest(this.superTestId, updatedSuperTest).subscribe((response) => {
            if (response) {
              this.getSuperTestDetail();
            }
          });
        }
      });
  }

  tabChange(event) {
    this.selected.setValue(event);
  }
}
