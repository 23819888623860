<div class="container-fluid">
  <div *ngIf="this.answer" class="psb-page-navigator">
    <span *ngFor="let item of tabs; last as isLast" class="breadscrum_links">
      <label (click)="navigateUrl(item)"
        >{{ item?.title | slice: 0:40 }}{{ item?.title?.length > 40 ? '...' : '' }} </label
      ><label *ngIf="!isLast" class="breadscrum_arrow_icon">></label>
    </span>
  </div>

  <mat-tab-group
    *ngIf="this.answer"
    mat-align-tabs="start"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="tabChange($event)"
  >
    <mat-tab label="Details">
      <button mat-raised-button color="primary" class="pull-right" (click)="onEdit()">Edit</button>
      <div class="row">
        <div class="column">
          <span class="label">Answer</span>
          <div [innerHTML]="answer.text" class="text"></div>
          <span class="label">Order</span>
          <p>{{ answer.order }}</p>
          <span class="label">Weight</span>
          <p>{{ answer.weight }}</p>
          <span class="label">Subscale</span>
          <p>{{ answer.subscale?.title }}</p>
          <span class="label">Style</span>
          <p>{{ answer.style }}</p>
          <img *ngIf="answer.image" [src]="answer.image" width="200px" height="200px" />
          <video *ngIf="answer.video" [src]="answer.video" controls></video>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
