import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-scorecards-table',
  templateUrl: './scorecards-table.component.html',
  styleUrls: ['./scorecards-table.component.scss'],
})
export class ScorecardsTableComponent implements OnInit {
  @Input() scorecards: any;
  @Input() customRanges: any;
  @Input() typeOfPage: string;
  columnNumber: number;
  outOfRangeLowCustomRange: any;
  cautionaryLowCustomRange: any;
  optimalCustomRange: any;
  cautionaryHighCustomRange: any;
  outOfRangeHighCustomRange: any;
  displayedColumns: string[] = ['subscaleTitle', 'txt', 'testRange', 'percentile', 'class'];
  dataSource = new MatTableDataSource<any>();

  constructor() {}

  ngOnInit(): void {
    this.columnNumber = this.customRanges.filter((customRange) => customRange.hided === 0).length;
    this.outOfRangeLowCustomRange = this.customRanges.filter(
      (customRange) => customRange.rangeTitle === 'Out of Range: Low',
    )[0];
    this.cautionaryLowCustomRange = this.customRanges.filter(
      (customRange) => customRange.rangeTitle === 'Cautionary Low',
    )[0];
    this.optimalCustomRange = this.customRanges.filter((customRange) => customRange.rangeTitle === 'Optimal')[0];
    this.cautionaryHighCustomRange = this.customRanges.filter(
      (customRange) => customRange.rangeTitle === 'Cautionary High',
    )[0];
    this.outOfRangeHighCustomRange = this.customRanges.filter(
      (customRange) => customRange.rangeTitle === 'Out of Range: High',
    )[0];
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.scorecards);
  }

  getBackGroundImage(image: string) {
    return `url(${environment.assetsUrl}/images/${image})`;
  }
}
