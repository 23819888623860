import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';

/// services
import { FormService } from '@app/core/services/form.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { Country } from '@app/core/models/country.model';
import { State } from '@app/core/models/state.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-department-modal',
  templateUrl: './department-modal.component.html',
  styleUrls: ['./department-modal.component.scss'],
})
export class DepartmentModalComponent implements OnInit {
  departmentModalForm = new FormGroup({
    name: new FormControl(''),
    country: new FormControl(''),
    stateId: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    address2: new FormControl(''),
  });
  countries: Country[];
  states: State[];
  filteredCountries: Observable<Country[]>;
  filteredStates: Observable<State[]>;
  selectedCountry: Country;
  selectedState: State;

  constructor(
    public dialogRef: MatDialogRef<DepartmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; companyId: number; departmentId: number },
    private formService: FormService,
    private companyService: CompanyService,
  ) {}

  ngOnInit(): void {
    this.formService.getCountries().subscribe((result: Country[]) => {
      this.countries = [null, ...result];
      
      this.filteredCountries = this.departmentModalForm.controls['country'].valueChanges.pipe(
        startWith(''),
        map((country) => (country ? this._filterCountry(country) : this.countries.slice())),
      );
    })

    if (this.data.isEdit) {
      this.companyService
        .findOneDepartmentByDepartmentId(this.data.companyId, this.data.departmentId)
        .subscribe((res) => {
          if (res) {
            this.selectedCountry = this.countries.filter((c) => c && c.id === res['countryId'])[0];
            this.departmentModalForm.get('name').setValue(res['name']);
            this.departmentModalForm.get('stateId').setValue(res['stateId']);
            this.departmentModalForm.get('city').setValue(res['city']);
            this.departmentModalForm.get('zipCode').setValue(res['zipCode']);
            this.departmentModalForm.get('address').setValue(res['address']);
            this.departmentModalForm.get('address2').setValue(res['address2']);
            this.departmentModalForm.get('country').setValue(this.selectedCountry);

            this.onSelectCountry(res['countryId'], res['stateId']);
          }
        });
    } else {
      this.companyService.getById(this.data.companyId).subscribe((response) => {
        if (response) {
          this.selectedCountry = this.countries.filter((c) => c && c.id === response['countryId'])[0];
          this.departmentModalForm.get('city').setValue(response['city']);
          this.departmentModalForm.get('zipCode').setValue(response['zipCode']);
          this.departmentModalForm.get('address').setValue(response['address']);
          this.departmentModalForm.get('address2').setValue(response['address2']);
          this.departmentModalForm.get('country').setValue(this.selectedCountry);

          this.onSelectCountry(response['countryId'], response['stateId']);
        }
      });
    }
  }

  compareFn(x: any, y: any): boolean {
    return parseInt(x, 10) === y ? true : false;
  }

  onSelectCountry(countryId: number, stateId?: number) {
    if (countryId) {
      this.formService.getStatesByCountry(countryId).subscribe(result => {
        this.states = result;

        if (stateId) {
          this.selectedState = this.states.filter((s) => s && s.id === stateId)[0];
          this.departmentModalForm.get('stateId').setValue(this.selectedState);
        }

        this.filteredStates = this.departmentModalForm.controls['stateId'].valueChanges.pipe(
          startWith(''),
          map((state) => (state ? this._filterState(state) : this.states.slice())),
        );
      })
    }
  }

  onSave() {
    let department = {
      name: this.departmentModalForm.get('name').value,
      country: this.departmentModalForm.get('country').value ? this.departmentModalForm.get('country').value.id : null,
      stateId: this.departmentModalForm.get('stateId').value ? this.departmentModalForm.get('stateId').value.id : null,
      city: this.departmentModalForm.get('city').value,
      zipCode: this.departmentModalForm.get('zipCode').value,
      address: this.departmentModalForm.get('address').value,
      address2: this.departmentModalForm.get('address2').value,
    };

    if (this.data.isEdit) {
      this.dialogRef.close({ event: 'edit', data: department });
    } else {
      this.dialogRef.close({ event: 'save', data: department });
    }
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  displayCountry(country: Country): string {
    return country ? country.name : '';
  }

  displayState(state: State): string {
    return state ? state.name : '';
  }

  private _filterCountry(value: string): Country[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.countries;
    }

    if (typeof filterValue === 'number') {
      return ;
    }

    return this.countries.filter((country: Country) => {
      if (country) {
        return country.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterState(value: string): State[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.states;
    }

    if (typeof filterValue === 'number') {
      return ;
    }

    return this.states.filter((state: State) => {
      if (state) {
        return state.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }
}
