import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  public message: string;

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>) {}

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onConfirm() {
    this.dialogRef.close({ event: 'confirm' });
  }
}
