import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// services
import { QuestionGroupService } from '@app/core/services/question-group.service';

// models
import { QuestionGroup } from '@app/core/models/question-group.model';

@Component({
  selector: 'app-edit-question-group',
  templateUrl: './edit-question-group.component.html',
  styleUrls: ['./edit-question-group.component.scss'],
})
export class EditQuestionGroupComponent implements OnInit {
  @Input() questionGroup: QuestionGroup;
  @Output() edit = new EventEmitter<QuestionGroup>();

  private originalQuestionGroup: QuestionGroup;
  editQuestionGroupForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    instructions: new FormControl(''),
  });

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '130px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Instructions',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(private questionGroupService: QuestionGroupService) {}

  ngOnInit(): void {
    this.originalQuestionGroup = JSON.parse(JSON.stringify(this.questionGroup));
    this.editQuestionGroupForm.get('title').setValue(this.questionGroup.title);
    this.editQuestionGroupForm.get('instructions').setValue(this.questionGroup.instructions);
  }

  editQuestionGroup(formDirective: FormGroupDirective) {
    const editedQuestionGroup: QuestionGroup = {
      id: this.questionGroup.id,
      instructions: this.editQuestionGroupForm.get('instructions').value,
      title: this.editQuestionGroupForm.get('title').value,
      subscaleId: this.questionGroup.subscaleId,
      aorder: this.questionGroup.aorder,
      questions: this.questionGroup.questions,
      networkType: this.questionGroup.networkType,
    };

    this.questionGroupService.update(editedQuestionGroup).subscribe(
      () => {
        this.questionGroup = editedQuestionGroup;
        this.edit.emit(editedQuestionGroup);
      },
      () => (this.questionGroup = this.originalQuestionGroup),
    );
  }

  onCancel() {
    this.edit.emit(this.originalQuestionGroup);
  }
}
