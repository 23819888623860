import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar-determinate',
  templateUrl: './progress-bar-determinate.component.html',
  styleUrls: ['./progress-bar-determinate.component.scss'],
})
export class ProgressBarDeterminateComponent implements OnInit {
  @Input() color: string;
  @Input() minValue: number;
  @Input() maxValue: number;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.color === undefined) {
      this.color = 'black';
    }
  }
}
