import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

// components
import { SubsectionFormComponent } from '@app/core/models/subsection-form-component';
import { AddOptimalScoreDialogComponent } from '@app/shared/components/add-optimal-score-dialog/add-optimal-score-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { SectionDialogFormComponent } from '../../chapter/section-dialog-form/section-dialog-form.component';
import { OptimalScoreSectionSummaryRangeContentComponent } from '@app/shared/components/optimal-score-section-summary-range-content/optimal-score-section-summary-range-content.component';

// services
import { SectionService } from '@app/core/services/section.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { OptimalScoreService } from '@app/core/services/optimal-score.service';

// models
import { Section } from '@app/core/models/section.model';
import { OptimalScoreScale } from '@app/core/models/optimal-score-scale.model';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-section-details',
  templateUrl: './section-details.component.html',
  styleUrls: ['./section-details.component.scss'],
})
export class SectionDetailsComponent implements OnInit {
  @ViewChild('subsectionForm') subsectionFormComponent: SubsectionFormComponent;

  section: Section;
  spreads: any[];
  form: FormGroup = this.fb.group({
    option: ['existing', [Validators.required]],
    section: ['', [Validators.required]],
    title: ['', [Validators.required]],
  });

  sectionOptimalScoreScales: MatTableDataSource<OptimalScoreScale> = new MatTableDataSource<OptimalScoreScale>();
  displayedSectionOptimalScoreColumns = ['subscale', 'superscale', 'optimalCurveTitle', 'curve', 'weight', 'actions'];
  reportId: number;
  chapterId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private optimalScoreService: OptimalScoreService,
    private sectionService: SectionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: SnackBarService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { isEdit: boolean } | null,
  ) {}

  ngOnInit(): void | Promise<any> {
    const sectionId = Number(this.route.snapshot.params.id);
    this.reportId = Number(this.route.snapshot.params['reportId']);
    this.chapterId = Number(this.route.snapshot.params['chapterId']);

    this.route.queryParams.subscribe((params) => {
      this.reportId = params['reportId'];
      this.chapterId = params['chapterId'];
    });

    if (Number.isNaN(sectionId)) {
      return this.router.navigate(['/']);
    }
    this.loadSection(sectionId);

    this.sectionService.getAutoSaveSectionListener().subscribe((autoSaveSection) => {
      this.loadSection(sectionId);
    });
  }

  onEdit() {
    const data = {
      isEdit: true,
      section: this.section,
      reportId: this.reportId,
      chapterId: this.chapterId,
    };
    this.dialog
      .open(SectionDialogFormComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event === EVENT_SUCCESS) {
          if (result.updatedSection) {
            this.loadSection(this.section.id);
            this.snackBar.info('Section has been updated with success.');
          }
        } else if (typeof result !== 'undefined' && result.event === EVENT_CANCEL && result.isSaved) {
          this.loadSection(this.section.id);
        }
      });
  }

  loadSection(sectionId) {
    this.sectionService.findById(sectionId, this.reportId, this.chapterId).subscribe((section) => {
      this.section = section;
      this.sectionOptimalScoreScales = new MatTableDataSource(section.optimalScoreScales);
    });
  }

  onAddOptimalScore() {
    const data = {
      section: this.section,
      reportId: this.reportId,
      chapterId: this.chapterId,
    };

    this.dialog
      .open(AddOptimalScoreDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_SUCCESS) {
          this.loadSection(this.section.id);
          return;
        }
      });
  }

  onEditOptimalScore(id: number) {
    const data = {
      section: this.section,
      optimalScoreScaleId: id,
      reportId: this.reportId,
      chapterId: this.chapterId,
    };

    this.dialog
      .open(AddOptimalScoreDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_SUCCESS) {
          this.loadSection(this.section.id);
          return;
        }
      });
  }

  onDeleteOptimalScore(element: OptimalScoreScale) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.optimalScoreService.delete(element.optimalScoreId, element.id).subscribe(() => {
          this.loadSection(this.section.id);
          return;
        });
      });
  }

  onAddOrEditSummaryRangeContent(element: OptimalScoreScale) {
    const data = {
      section: this.section,
      optimalScoreScale: element,
    };

    this.dialog
      .open(OptimalScoreSectionSummaryRangeContentComponent, {
        maxWidth: '70vw',
        maxHeight: '80vh',
        data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_SUCCESS) {
          this.loadSection(this.section.id);
          return;
        }
      });
  }
}
