import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';
import { Authority } from '@app/core/models/authority.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    const isAuth = this.userService.isAuthenticated.getValue();
    if (!isAuth) {
      this.router.navigate(['/login']).then(() => false);
    }
    if (typeof route.data.authority === 'undefined') {
      return isAuth;
    }
    return this.checkUserLogin(route);
  }

  checkUserLogin(route: ActivatedRouteSnapshot): boolean {
    if (this.userService.isAuthenticated.getValue()) {
      const userAuthorities = this.userService.getUserData().authorities;
      let matchedAuthority;
      if (typeof route.data.authority === 'string') {
        matchedAuthority = userAuthorities.filter((a: Authority) => route.data.authority === a.name).length > 0;
      } else if (typeof route.data.authority === 'object') {
        matchedAuthority =
          userAuthorities.filter((a: Authority) => route.data.authority.indexOf(a.name) !== -1).length > 0;
      }
      if (route.data.authority && !matchedAuthority) {
        this.router.navigate(['/login']).then(() => false);
      }
      return true;
    }
    this.router.navigate(['/home']).then(() => false);
  }
}
