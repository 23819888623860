import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from './loader.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptorService {
  activeRequests = 0;

  constructor(private loadingScreenService: LoaderService, private rMService: RoleMandateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activeRequests === 0) {
      this.loadingScreenService.startLoading();
    }

    this.activeRequests++;
    this.rMService.setLoaderListener(true);

    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.rMService.setLoaderListener(false);
          this.loadingScreenService.stopLoading();
        }
      }),
    );
  }
}
