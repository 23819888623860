import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// models
import { Notification } from '@app/core/models/notification.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent implements OnInit {
  isEdit: boolean;
  notificationForm = new FormGroup({
    id: new FormControl(''),
    webhook: new FormControl(''),
    chanel: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; notification: Notification },
  ) {}

  ngOnInit(): void {
    this.isEdit = this.data.isEdit;

    if (this.isEdit && this.data.notification) {
      this.notificationForm.get('id').setValue(this.data.notification.id);
      this.notificationForm.get('webhook').setValue(this.data.notification.webhook);
      this.notificationForm.get('chanel').setValue(this.data.notification.chanel);
    }
  }

  onSave() {
    this.dialogRef.close({ event: 'save', data: this.notificationForm.value });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
