/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Superscale } from '../models/Superscale';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class SuperscaleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all superscales by test group id
     * Retrieve all superscales by test group id
     * @param id Id of the test group
     * @returns Superscale A list of superscales
     * @throws ApiError
     */
    public getTestGroupSuperscale(
        id: number,
    ): Observable<Superscale> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/superscale',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of superscale not found`,
                500: `Internal error`,
            },
        });
    }

}
