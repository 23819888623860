/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { User } from '../models/User';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Send Emails to users based on the type of the email
     * Send Email Reminders
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postSendEmailReminders(
        requestBody?: {
            /**
             * Subject of email
             */
            subject: string;
            /**
             * Message to be send on the body of the email
             */
            message: string;
            /**
             * Id for EmailType
             */
            emailTypeId: number;
            /**
             * Email to use as origin
             */
            from: string;
            /**
             * List of users
             */
            users: User;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/send-email/reminders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Send an verification email
     * Verify users on AWS SES
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postSendEmailVerifyEmail(
        requestBody?: {
            /**
             * Email to use as origin
             */
            email: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/send-email/verify-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Save message to be reused
     * Save message
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postSendEmailPsyEmail(
        requestBody?: {
            /**
             * Subject of email
             */
            subject: string;
            /**
             * Message to be send on the body of the email
             */
            message: string;
            /**
             * Id for EmailType
             */
            emailTypeId: number;
            /**
             * Id for Test Group
             */
            testGroupId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/send-email/psy-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Update PsyEmail to be reused
     * Update PsyEmail
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchSendEmailPsyEmail(
        requestBody?: {
            /**
             * Subject of email
             */
            subject: string;
            /**
             * Message to be send on the body of the email
             */
            message: string;
            /**
             * Id for EmailType
             */
            emailTypeId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/send-email/{psyEmailId}/psy-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Send email to reset password
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postSendEmailUserResetPassword(
        requestBody?: {
            /**
             * Email to send hash for resetting
             */
            email: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/send-email/user/reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

}
