/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyEmail } from '../models/PsyEmail';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyEmailService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve Emails by Role Mandate Id
     * @returns PsyEmail A list of emails
     * @throws ApiError
     */
    public getPsyEmailRoleMandate(): Observable<Array<PsyEmail>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/psy-email/role-mandate/{roleMandateId}',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Retrieve Emails by Test Group Id
     * @returns PsyEmail A list of emails
     * @throws ApiError
     */
    public getPsyEmailTestGroup(): Observable<Array<PsyEmail>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/psy-email/test-group/{testGroupId}',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a PsyEmail
     * Create a PsyEmail for Role Mandate
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postPsyEmail(
        requestBody?: {
            /**
             * Subject of email
             */
            subject: string;
            /**
             * Id for PsyTestGroup
             */
            psyTestGroupId?: number;
            /**
             * Id for Role Mandate
             */
            roleMandateId?: number;
            /**
             * Id for Connection Indicator
             */
            connectionIndicatorId?: number;
            /**
             * Id for EmailType
             */
            emailTypeId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/psy-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

}
