import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function subscaleValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const subScale = control.value;

    if (!subScale) {
      return null;
    }
    return !subScale['id'] ? { subScaleValid: true } : null;
  };
}
