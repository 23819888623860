<div class="page-list-header">
  <h1>Tests</h1>
  <button mat-raised-button class="new-button pull-right" color="primary" (click)="createNewTest()">
    <mat-icon aria-hidden="false">add</mat-icon>Add new Test
  </button>
</div>

<app-psb-list
  *ngIf="superTestTests.length > 0"
  [items]="superTestTests"
  [itemsPerPage]="10"
  (delete)="onDelete($event)"
  (view)="onView($event)"
>
</app-psb-list>
