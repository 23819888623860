import { OnChanges, Component, Input, AfterViewInit } from '@angular/core';
import * as d3 from 'd3';
import { forkJoin } from 'rxjs';

// services
import { ChartService } from '@app/core/services/chart.service';
import { UserService } from '@app/core/services/user.service';

// helpers
import { ChartHelper } from '@app/core/helpers/chart-helper';
import { environment } from 'src/environments/environment';
import { AreaIndexService } from '@app/core/services/area-index.service';
import { UtilsService } from '@app/core/services/utils.service';
import { TestGroupChartComparison } from '@app/core/models/test-group-chart-comparison.model';
import { ReportService } from '@app/core/services/report.service';

@Component({
  selector: 'app-square-plot',
  templateUrl: './square-plot.component.html',
  styleUrls: ['./square-plot.component.scss'],
})
export class SquarePlotComponent extends ChartHelper implements OnChanges, AfterViewInit {
  pathNumber: any;
  constructor(
    private chartService: ChartService,
    private userService: UserService,
    private areaIndexService: AreaIndexService,
    private reportService: ReportService,
  ) {
    super();
    this.width = 500 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
  }

  @Input() chartId: number;
  @Input() userId?: number;
  @Input() testGroupId: number;
  @Input() companyId?: number;
  @Input() chartSelectedUsers: any[];
  @Input() visible?: boolean = true;

  userColor = '#F1592A'; // color for user
  mainColor = '#425465'; // color for main wave
  rectColor = 'white'; // color for graph division lines
  groupColor = 'white'; // color for group dots
  groupStroke = '#425465'; // stroke for group dots
  groupTextColor = '#4AC29A'; // color for user name or id text
  contourColor = 'white'; // color for contour
  textColor = '#66809a'; // color for graph text and Highlight ideal area of quadrant (topright)

  margin = { top: 50, right: 50, bottom: 50, left: 50 };
  width: number;
  height: number;
  svg: any;

  allUsers = [];
  selectedUsers = [];
  chart: any;
  description: string;
  gender: string;

  subcalesX: any[];
  subcalesY: any[];

  ngAfterViewInit() {
    if (this.userId) {
      if (!this.companyId) {
        forkJoin([
          this.chartService.getResultsByTestGroup(this.chartId, this.testGroupId),
          this.chartService.getChartWithResultsByUser(this.chartId, this.userId),
        ]).subscribe((results) => {
          this.reportService
            .getAllTestGroupChartComparisonByTestGroupIdAndChartId(this.testGroupId, this.chartId)
            .subscribe((testGroupChartComparisons: TestGroupChartComparison[]) => {
              results[0] = results[0].filter((r) =>
                testGroupChartComparisons.find(
                  (testGroupChartComparison: TestGroupChartComparison) =>
                    testGroupChartComparison.userInfoId === r.userInfoId,
                ),
              );

              this.allUsers = results[0];
              this.chart = results[1];
              this.subcalesX = this.chart.subscales.filter((d) => d.direction === 'X');
              this.subcalesY = this.chart.subscales.filter((d) => d.direction === 'Y');

              if (this.chart.subscales?.length === 0) {
                this.chart.superscales?.map((ss) => (ss.subscaleTitle = ss.title));
                this.chart.subscales = this.chart.superscales;
                this.subcalesX = this.chart.subscales.filter((d) => d.axis === 'X');
                this.subcalesY = this.chart.subscales.filter((d) => d.axis === 'Y');
              }

              this.assignSelectedUsers();
              this.buildSvg();
              this.buildChart();
            });
        });
      } else {
        forkJoin([
          this.chartService.getResultsByCompany(this.chartId, this.companyId),
          this.chartService.getChartWithResultsByUser(this.chartId, this.userId),
        ]).subscribe((results) => {
          this.allUsers = results[0];
          this.chart = results[1];

          this.subcalesX = this.chart.subscales.filter((d) => d.direction === 'X');
          this.subcalesY = this.chart.subscales.filter((d) => d.direction === 'Y');

          this.assignSelectedUsers();
          this.buildSvg();
          this.buildChart();
        });
      }
    } else {
      forkJoin([
        this.chartService.getResultsByTestGroup(this.chartId, this.testGroupId),
        this.chartService.getById(this.chartId),
      ]).subscribe((results) => {
        this.allUsers = results[0];
        this.chart = results[1];

        this.subcalesX = this.chart.subscales.filter((d) => d.direction === 'X');
        this.subcalesY = this.chart.subscales.filter((d) => d.direction === 'Y');

        this.assignSelectedUsers();
        this.buildSvg();
        this.buildChart();
        this.chartService.setChartUsersListener(this.allUsers);
      });
    }
  }

  ngOnChanges() {
    if (this.svg) {
      this.assignSelectedUsers();
      this.buildChart();
    }
  }

  between(x, min, max) {
    return x >= min && x <= max;
  }

  private buildChart() {
    // Add x axis
    const x = d3
      .scaleLinear()
      .domain([0, 100])
      .range([50, this.width - 63]);

    this.svg
      .append('g')
      .attr('transform', 'translate(-2.5,' + 340 + ')')
      .call(d3.axisBottom(x))
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .attr('visibility', 'hidden');

    // Add y axis
    const y = d3
      .scaleLinear()
      .domain([0, 100])
      .range([this.height - 63, 50]);

    this.svg
      .append('g')
      // .attr('class', 'svgaxisColor')
      .call(d3.axisLeft(y))
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .attr('visibility', 'hidden');

    const a = 35;
    const b = 55;
    let data2;
    if (this.userId && this.chart && this.chart.userResults) {
      data2 = [
        {
          x: this.chart.userResults.x,
          y: this.chart.userResults.y,
          userName: this.chart.userResults.userName,
        },
      ];
    }
    const circle = [50, 50, 0, 50, 50, 100, 0, 100];
    const data3 = [
      {
        x: circle[0],
        y: circle[1],
        X2: this.between(a, 50, 100),
        Y2: this.between(b, 0, 50),
      },
      {
        x: circle[2],
        y: circle[3],
        X2: this.between(a, 0, 50),
        Y2: this.between(b, 0, 50),
      },
      {
        x: circle[4],
        y: circle[5],
        X2: this.between(a, 50, 100),
        Y2: this.between(b, 50, 100),
      },
      {
        x: circle[6],
        y: circle[7],
        X2: this.between(a, 0, 50),
        Y2: this.between(b, 50, 100),
      },
    ];

    this.svg
      .append('g')
      .selectAll('dot')
      .data(data3)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.x))
      .attr('y', (d) => y(d.y))
      .attr('width', 140)
      .attr('height', 140)
      .style('stroke-width', 2)
      .attr('stroke', 'white')
      .style('fill', this.mainColor);

    const densityData = d3
      .contourDensity()
      .x((d) => x(d['x']))
      .y((d) => y(d['y']))
      .size([this.width, this.height])
      .bandwidth(30)(
      // smaller = more precision in lines = more lines
      this.selectedUsers,
    );

    // Clean up previous line path
    if (this.svg && !this.svg.selectAll('path').empty()) {
      this.svg.selectAll('path').remove();
    }

    this.svg
      .selectAll('path')
      .data(densityData)
      .enter()
      .append('path')
      .attr('d', d3.geoPath())
      .attr('fill', 'none')
      .attr('stroke', this.contourColor)
      .attr('stroke-width', 0.3)
      .attr('stroke-linejoin', 'round');

    const div = d3.select('body').append('div').attr('class', 'tooltip-chart').style('opacity', 0);

    if (this.svg && !this.svg.selectAll('circle').empty()) {
      this.svg.selectAll('circle').remove();
    }

    this.svg
      .append('g')
      .selectAll('dot')
      .data(this.selectedUsers)
      .enter()
      .append('circle')
      .attr('cx', (d) => x(50))
      .attr('cy', (d) => y(50))
      .attr('r', 3)
      .style('fill', this.groupColor)
      .style('stroke', this.groupStroke)
      .style('stroke-width', 0.3)
      .on('mouseover', (event, d) => {
        d3.select(event.currentTarget).transition().duration(100).attr('r', 7);

        d3.select('body')
          .append('div')
          .html(d.userName)
          .attr('class', 'tooltip')
          .style('left', event.pageX + 15 + 'px')
          .style('top', event.pageY - 15 + 'px');
      })
      .on('mouseout', (event, d) => {
        d3.select(event.currentTarget).transition().duration(50).attr('opacity', 1).attr('r', 3);
        d3.selectAll('.tooltip').remove();
      });

    if (this.userId && this.chart && this.chart.userResults) {
      this.svg
        .append('circle')
        .data(data2)
        .attr('cx', (d) => x(50))
        .attr('cy', (d) => y(50))
        .attr('r', 7)
        .style('stroke', 'none')
        .style('fill', this.userColor)
        .on('mouseover', (event, d) => {
          d3.select(event.currentTarget).transition().duration(100).attr('r', 10);

          d3.select('body')
            .append('div')
            .html(d.userName)
            .attr('class', 'tooltip')
            .style('left', event.pageX + 15 + 'px')
            .style('top', event.pageY - 15 + 'px');
        })
        .on('mouseout', (event, d) => {
          d3.select(event.currentTarget).transition().duration(50).attr('opacity', 1).attr('r', 7);
          d3.selectAll('.tooltip').remove();
        });
      this.getPathNumberByCoordinates(x(data2[0]?.x), y(data2[0]?.y));
    }

    // this.svg.selectAll('dot')
    //   .data(this.allUsers)
    //   .enter().append('text')
    //   .attr('x', d => x(d.x))
    //   .attr('y', d => y(d.y))
    //   .style('font-family', 'Arial')
    //   .style('font-size', 12)
    //   .text(d => d.userName)
    //   .style('fill', this.groupTextColor);

    this.svg
      .selectAll('circle')
      .transition()
      .delay((d, i) => i * 3)
      .duration(2000)
      .attr('cx', (d) => x(d.x))
      .attr('cy', (d) => y(d.y));

    this.addCopyright(this.svg, this.width / 2, this.height + this.margin.bottom - 3);

    this.svg
      .append('text')
      .attr('x', 50)
      .attr('y', 45)
      .text(this.chart?.sectors.filter((s) => s.position === 'TOP_LEFT')[0]?.title)
      .style('font-size', 14)
      .style('font-family', 'Arial')
      .style('fill', this.textColor);

    this.svg
      .append('text')
      .attr('x', 265)
      .attr('y', 45)
      .style('font-size', 14)
      .text(this.chart?.sectors.filter((s) => s.position === 'TOP_RIGHT')[0]?.title)
      .style('font-family', 'Arial')
      .style('fill', this.textColor);

    this.svg
      .append('text')
      .attr('x', 50)
      .attr('y', 353)
      .text(this.chart?.sectors.filter((s) => s.position === 'BOTTOM_LEFT')[0]?.title)
      .style('font-size', 14)
      .style('font-family', 'Arial')
      .style('fill', this.textColor);

    this.svg
      .append('text')
      .attr('x', 233)
      .attr('y', 353)
      .style('font-size', 14)
      .text(this.chart?.sectors.filter((s) => s.position === 'BOTTOM_RIGHT')[0]?.title)
      .style('font-family', 'Arial')
      .style('fill', this.textColor);
  }

  private buildSvg() {
    this.svg = d3
      .select('figure#CIRCLE_IN_CARTESIAN' + this.chartId)
      .append('svg')
      .attr('viewBox', '0 0 ' + 400 + ' ' + 400)
      // .attr('width', this.width + (this.margin.left + this.margin.right))
      .attr('max-height', '400')
      // .attr('height', this.height + (this.margin.top + this.margin.bottom))
      .append('g')
      .attr('transform', 'translate(-2.1848517,0.35752119)');
  }

  assignSelectedUsers() {
    this.chartSelectedUsers ? (this.selectedUsers = this.chartSelectedUsers) : (this.selectedUsers = this.allUsers);
  }

  private async getPathNumberByCoordinates(x, y) {
    d3.xml(`${environment.assetsUrl}/images/circle_in_cartesian.svg`).then(async (response: any) => {
      document
        .querySelector('#tempBindCircleInCartesianPlot')
        .appendChild(response.getElementById('circular_in_cartesian_plot_svg'));

      let svg: any = document.getElementById('circular_in_cartesian_plot_svg');
      let paths: any = svg.getElementsByTagName('path');
      let point: any = svg.createSVGPoint();

      // Get x,y scales based on the .svg
      const xScale = d3
        .scaleLinear()
        .domain([0, 100])
        .range([18, 400 - 18]);
      const yScale = d3
        .scaleLinear()
        .domain([0, 100])
        .range([400 - 18, 18]);
      point.x = xScale(this.chart?.userResults?.x);
      point.y = yScale(this.chart?.userResults?.y);

      for (let path of paths) {
        if (path.isPointInFill(point)) {
          this.pathNumber = path.getAttribute('number');
          if (parseInt(this.pathNumber) === 1) {
            this.pathNumber = 0;
          }
          break;
        }
      }
      document.querySelector('#tempBindCircleInCartesianPlot').innerHTML = '';
      let areaPathNumber = await this.areaIndexService.getAreaIndex(this.chart, this.pathNumber);
      if (areaPathNumber) {
        this.chart.area = areaPathNumber;
        this.gender = this.userService.getPronoun(this.chart.user);
        this.description = this.userService.getPronounDescription(this.chart, this.gender);
      } else {
        this.description = null;
      }
    });
  }
}
