import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OptimalScoreCurveComponent } from './optimal-score-curve/optimal-score-curve.component';
import { OptimalScoreCurveDetailComponent } from './optimal-score-curve-detail/optimal-score-curve-detail.component';

const optimalScoreRoutes: Routes = [
  { path: '', component: OptimalScoreCurveComponent },
  { path: ':id/details', component: OptimalScoreCurveDetailComponent },
  { path: ':id/details/tab/:tab', component: OptimalScoreCurveDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(optimalScoreRoutes)],
  exports: [RouterModule],
})
export class OptimalScoreRoutingModule {}
