<div *ngIf="section" class="container">
  <div class="page-list-header">
    <h1 class="section-title">
      {{ section.title }}
      <span class="section-type" *ngIf="section.type">{{ section.type }}</span>
    </h1>
    <button mat-raised-button color="primary" (click)="onEdit()">
      <mat-icon>edit</mat-icon>
      Edit Section
    </button>
  </div>

  <mat-card>
    <div class="section-card">
      <div>
        <mat-card-header>
          <mat-card-title>
            <span>
              <h2>Optimal Score</h2>
            </span>
          </mat-card-title>
        </mat-card-header>
      </div>
      <div id="actions">
        <div id="add-optimal-score-button">
          <button mat-flat-button color="accent" (click)="onAddOptimalScore()">
            <mat-icon>add</mat-icon>
            Add Optimal Score
          </button>
        </div>
      </div>
    </div>
    <mat-divider *ngIf="section.optimalScoreScales" class="section-optimal-score-list"></mat-divider>
    <div *ngIf="section.optimalScoreScales">
      <mat-table [dataSource]="sectionOptimalScoreScales">
        <ng-container matColumnDef="subscale">
          <mat-header-cell *matHeaderCellDef class="column-interval"> Subscale </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p>{{ element.subscaleTitle }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="superscale">
          <mat-header-cell *matHeaderCellDef class="column-interval"> Superscale </mat-header-cell>
          <mat-cell *matCellDef="let element" class="column-interval">
            <p>{{ element.superscaleTitle }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="optimalCurveTitle">
          <mat-header-cell *matHeaderCellDef class="column-interval"> Optimal Curve</mat-header-cell>
          <mat-cell *matCellDef="let element" class="column-interval">
            <p>{{ element.optimalScoreCurveTitle }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="curve">
          <mat-header-cell *matHeaderCellDef class="column-interval"> Curve Range </mat-header-cell>
          <mat-cell *matCellDef="let element" class="column-interval">
            <p>
              Curve {{ element.curveNumber }} [<strong>From:</strong> {{ element.from }} | <strong>To: </strong>
              {{ element.to }}]
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="weight">
          <mat-header-cell *matHeaderCellDef class="weight-interval"> Weight </mat-header-cell>
          <mat-cell *matCellDef="let element" class="content-right">
            <p>{{ element.weight }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="actions-interval"> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="action-buttons">
              <button mat-button color="primary" (click)="onAddOrEditSummaryRangeContent(element)">
                Add Range Content
              </button>
              <button mat-button color="warn" (click)="onEditOptimalScore(element.id)">Edit Optimal Score</button>
              <button mat-button color="accent" (click)="onDeleteOptimalScore(element)">Delete Optimal Score</button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedSectionOptimalScoreColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedSectionOptimalScoreColumns"></mat-row>
      </mat-table>
    </div>
  </mat-card>
  <app-subsection-container [section]="section"></app-subsection-container>
</div>
