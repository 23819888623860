import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAllowAlphabetsOnly]',
})
export class AllowAlphabetsOnlyDirective {
  @HostListener('keypress') onkeypress(e) {
    let event = e || window.event;
    if (event) {
      return this.isLettersKey(event);
    }
  }

  @HostListener('paste', ['$event'])
  onPaste($event: ClipboardEvent) {
    let pastedInput: string = $event.clipboardData.getData('text/plain');
    if (!pastedInput.match(/^[A-Za-z]+$/g)) {
      $event.preventDefault();
    }
  }

  isLettersKey(event) {
    let charCode = event.which ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32) {
      return true;
    }
    return false;
  }
}
