<h1>Users</h1>
<p class="small-text">List of users registered and percentage of complete.</p>
<mat-divider></mat-divider>
<div class="user-buttons">
  <button mat-raised-button color="primary" class="new-button" (click)="onCreateUser()">
    <mat-icon aria-hidden="false">add</mat-icon>Create a User
  </button>

  <button mat-raised-button class="new-button" color="primary" (click)="addNewTestUser()">
    <mat-icon aria-hidden="false">add</mat-icon>Add Test User
  </button>

  <button
    *ngIf="isAdmin"
    mat-raised-button
    class="new-button"
    color="primary"
    (click)="addNewTestUserFromOtherCompany()"
  >
    <mat-icon aria-hidden="false">add</mat-icon>Add a User From Other Company
  </button>

  <button *ngIf="isAdmin" mat-raised-button class="new-button" color="warn" (click)="updateUsersScores()">
    <mat-icon aria-hidden="false">refresh</mat-icon>Update Users Scores
  </button>

  <button mat-raised-button class="new-button" color="accent" (click)="moveUserFromOtherTestGroup()">
    <mat-icon aria-hidden="false">move_to_inbox</mat-icon>Transfer User
  </button>

  <button mat-raised-button class="new-button" color="accent" (click)="addUserFromOtherTestGroup()">
    <mat-icon aria-hidden="false">move_to_inbox</mat-icon>Copy User
  </button>
</div>

<app-psb-table-search [data]="testGroupUsers"></app-psb-table-search>

<mat-table [dataSource]="testGroupUsers" class="mat-elevation-z3">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="mobile-label">Name :</span>
      <div class="no-complete" *ngIf="element.status === 0 || !element.status">{{ element.name }}</div>
      <mat-expansion-panel *ngIf="element.status > 0" [disabled]="element.privateScores && isTGM">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ element.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let test of element.completedPsyTests" class="completed-test-title">
          <div
            matTooltip="Date Completed: {{ test.dateCompleted | date: 'medium' }}"
            (click)="onPsyTestView(test, element)"
          >
            {{ test.title }}
          </div>
        </div>
        <div *ngFor="let supertest of element.completedSuperTests" class="completed-test-title">
          <div matTooltip="{{ supertest.dateCompleted | date: 'medium' }}" (click)="onSupertestView(supertest)">
            {{ supertest.title }}
          </div>
        </div>
      </mat-expansion-panel>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="userType">
    <mat-header-cell *matHeaderCellDef>User Type</mat-header-cell>
    <mat-cell *matCellDef="let element"><span class="mobile-label">UserType :</span> {{ element.userType }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="autoSendPdf">
    <mat-header-cell *matHeaderCellDef class="center-cell">Auto Send PDF</mat-header-cell>
    <mat-cell *matCellDef="let element" class="center-cell">
      {{ element.autoSendPdf ? 'On' : 'Off' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div>
        <span class="mobile-label">Status :</span>
      </div>
      <div
        class="status_progress"
        [matTooltip]="element.progress ? getUserProgress(element.progress) : ''"
        matTooltipClass="my-tooltip"
      >
        <mat-progress-bar class="example-margin" [color]="color" [mode]="mode" [value]="element.status">
        </mat-progress-bar>
        <div class="status">{{ element.status }}%</div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell mat-header-cell class="action" *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="mobile-label">Action :</span>
      <button mat-button class="btn-primary mobile-view-edit" (click)="onEdit(element)">Change Role</button>
      <button
        mat-button
        class="btn-view"
        [matMenuTriggerFor]="menu"
        [disabled]="element.status == 0 || element.reportModel || (element.privateScores && isTGM)"
        [class]="{
          'report-btn-disabled': element.status == 0 || element.reportModel || (element.privateScores && isTGM)
        }"
      >
        Report
      </button>
      <mat-menu #menu="matMenu" class="menu-without-scroll">
        <button mat-menu-item *ngFor="let report of reports" (click)="onSelectReport(element, report.id)">
          {{ report.title }}
        </button>
      </mat-menu>
      <button mat-button class="btn-error btn-delete" (click)="onDelete(element)">Remove</button>
    </mat-cell>
  </ng-container>

  <mat-header-row mat-header-row *matHeaderRowDef="displayedTestGroupUsersColumns"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedTestGroupUsersColumns"></mat-row>
</mat-table>
<mat-paginator
  #paginatorTestGroups
  [pageSize]="10"
  [pageSizeOptions]="[10, 20, 50]"
  showFirstLastButtons
></mat-paginator>
