import { Component, OnInit } from '@angular/core';
import { SystemSettingsService } from '@app/core/services/system-settings.service';
import { FormControl } from '@angular/forms';
import { SnackBarService } from '@app/core/services/snack-bar.service';

@Component({
  selector: 'app-toggle-maintenance-mode',
  templateUrl: './toggle-maintenance-mode.component.html',
  styleUrls: ['./toggle-maintenance-mode.component.scss'],
})
export class ToggleMaintenanceModeComponent implements OnInit {
  maintenanceModeControl = new FormControl(false);
  maintenanceEnabled: boolean;

  constructor(private systemSettingsService: SystemSettingsService, private snackBarService: SnackBarService) {}

  ngOnInit(): void {
    this.systemSettingsService.getMaintenanceMode().subscribe((result) => {
      result.maintenanceEnabled === 0 ? (this.maintenanceEnabled = false) : (this.maintenanceEnabled = true);
      this.maintenanceModeControl.patchValue(this.maintenanceEnabled);
    });
  }

  onToggleMaintenanceMode() {
    this.systemSettingsService.toggleMaintenanceMode(this.maintenanceModeControl.value).subscribe(() => {
      this.maintenanceEnabled = this.maintenanceModeControl.value;
      this.snackBarService.info('System maintenance mode has been updated');
    });
  }
}
