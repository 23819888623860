<h1>Edit a State</h1>
<form [formGroup]="stateModalForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>ID</mat-label>
    <input matInput type="text" formControlName="id" readonly />
    <mat-error *ngIf="stateModalForm.get('id').errors">Please, enter a valid id.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Abbrev</mat-label>
    <input matInput type="text" formControlName="abbrev" />
    <mat-error *ngIf="stateModalForm.get('abbrev').errors">Please, enter a valid abbrev.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput type="text" formControlName="name" />
    <mat-error *ngIf="stateModalForm.get('name').errors">Please, enter a valid name.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Country</mat-label>
    <mat-select formControlName="countryId" [compareWith]="compareFn">
      <mat-option></mat-option>
      <mat-option *ngFor="let country of countries | async" value="{{ country.id }}">
        {{ country.id }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!stateModalForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
