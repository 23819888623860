import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserListComponent } from './user-list/user-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserInviteListComponent } from './user-invite-list/user-invite-list.component';

const allUsersRoutes: Routes = [
  { path: '', component: UserListComponent },
  { path: 'all-invite', component: UserInviteListComponent },
  { path: ':id/details', component: UserDetailComponent },
  { path: ':id/details/tab/:tab', component: UserDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(allUsersRoutes)],
  exports: [RouterModule],
})
export class UserAdminRoutingModule {}
