<div>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Answer</mat-label>
      <textarea matInput rows="5" cols="5" [formControl]="editAnswerControl"></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button class="btn-success btn-300" (click)="onCancelClick()"><mat-icon>block</mat-icon>Cancel</button>
    <button mat-button class="btn-success btn-300" (click)="onSaveClick()"><mat-icon>save_alt</mat-icon>Save</button>
  </div>
</div>
