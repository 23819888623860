/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyTestGroupSuperscale } from '../models/PsyTestGroupSuperscale';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupSuperscaleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Remove a superscale
     * Remove a superscale from test group
     * @param id Id of the test group
     * @param psyTestGroupId Id of the test group
     * @param superscaleId Id of the superscale
     * @returns any Superscale deleted from test group successfully
     * @throws ApiError
     */
    public deleteTestGroupTestGroupSuperscale(
        id: number,
        psyTestGroupId: number,
        superscaleId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/{id}/test-group-superscale/{testGroupSuperscaleId}',
            path: {
                'id': id,
                'psyTestGroupId': psyTestGroupId,
                'superscaleId': superscaleId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Add superscale to test group
     * Add superscale to test group
     * @param testGroupId
     * @param superscaleId Id of the superscale
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupTestGroupSuperscale(
        testGroupId: number,
        superscaleId: number,
        requestBody?: {
            /**
             * Id of the test group
             */
            chartId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{id}/test-group-superscale',
            path: {
                'testGroupId': testGroupId,
                'superscaleId': superscaleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve the superscale ranges by id
     * Retrieve the superscale ranges by id
     * @param id Id of the test group
     * @returns PsyTestGroupSuperscale A list of superscale range by test group id
     * @throws ApiError
     */
    public getTestGroupSuperscaleRanges(
        id: number,
    ): Observable<PsyTestGroupSuperscale> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/superscale-ranges',
            path: {
                'id': id,
            },
            errors: {
                404: `a scorecard trigger not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Add superscaleRanges to test group
     * Add superscaleRanges to test group
     * @param testGroupId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupSuperscaleRanges(
        testGroupId: number,
        requestBody?: {
            /**
             * Id of the test group
             */
            id?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{id}/superscale-ranges',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

}
