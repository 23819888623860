import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';
import { CollaborationType } from '@app/core/models/collaboration-type.model';
import { DecisionTableColumn } from '@app/core/models/decision-table-column.model';

@Component({
  selector: 'app-edit-decision-table',
  templateUrl: './edit-decision-table.component.html',
  styleUrls: ['./edit-decision-table.component.scss'],
})
export class EditDecisionTableComponent implements OnInit, OnDestroy {
  @Input() decisionTable: DecisionTable;
  @Input() collaborationTypes: CollaborationType[];
  @Output() edit = new EventEmitter<DecisionTable>();
  @Output() newColumn = new EventEmitter<boolean>();
  @Output() deleteColumn = new EventEmitter<boolean>();
  @Input() showAcl: boolean;

  private originalDecisionTable: DecisionTable;
  private initialSubscriptions: Subscription;
  editDecisionTableForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    instructions: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    collaborationType: new FormControl('', [Validators.required]),
    networkType: new FormControl(''),
  });
  showNewColumn: boolean;
  privacyMessages = [
    { id: 5, message: 'Allow users to set answers as private' },
    { id: 9, message: 'This table is private' },
  ];
  acl: number;

  constructor(private decisionTableService: DecisionTableService) {}

  ngOnInit(): void {
    if (this.showAcl) {
      this.acl = this.decisionTable.acl;
    }
    this.originalDecisionTable = JSON.parse(JSON.stringify(this.decisionTable));
    this.editDecisionTableForm.get('title').setValue(this.decisionTable.title);
    this.editDecisionTableForm.get('instructions').setValue(this.decisionTable.instructions);
    this.editDecisionTableForm.get('description').setValue(this.decisionTable.description);
    this.editDecisionTableForm.get('networkType').setValue(this.decisionTable.networkType);
    if (this.decisionTable.collaborationTypeId) {
      this.editDecisionTableForm.get('collaborationType').setValue(this.decisionTable.collaborationTypeId);
    }
  }

  editDecisionTable(formDirective: FormGroupDirective) {
    if (this.editDecisionTableForm.invalid) {
      return;
    }

    const newDecisionTable: DecisionTable = {
      id: this.decisionTable.id,
      title: this.editDecisionTableForm.get('title').value,
      instructions: this.editDecisionTableForm.get('instructions').value,
      description: this.editDecisionTableForm.get('description').value,
      collaborationTypeId: this.editDecisionTableForm.get('collaborationType').value,
      networkType: this.editDecisionTableForm.get('networkType').value,
      columns: this.decisionTable.columns,
    };
    if (this.showAcl) {
      newDecisionTable.acl = this.acl;
    }

    this.edit.emit(newDecisionTable);
  }

  onCancel() {
    this.edit.emit(this.originalDecisionTable);
  }

  ngOnDestroy(): void {
    if (this.initialSubscriptions) {
      this.initialSubscriptions.unsubscribe();
    }
  }

  onEditColumn(editedDecisionTableColumn: DecisionTableColumn) {
    return;
  }

  onNew() {
    this.showNewColumn = this.showNewColumn ? false : true;
  }

  onNewColumnConfirm($event: boolean) {
    this.showNewColumn = !this.showNewColumn;
    this.newColumn.emit($event);
  }

  onDeleteColumn(decisionTableColumn: DecisionTableColumn) {
    this.decisionTable.columns = this.decisionTable.columns.filter((dtC) => dtC.id !== decisionTableColumn.id);
    this.originalDecisionTable.columns = this.originalDecisionTable.columns.filter(
      (dtC) => dtC.id !== decisionTableColumn.id,
    );
    this.deleteColumn.emit(true);
  }

  onChangeACL(event, privacyMsg) {
    return event.checked ? (this.acl = privacyMsg.id) : (this.acl = 0);
  }
}
