import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SubscaleListComponent } from './subscale-list/subscale-list.component';
import { SubscaleDetailComponent } from './subscale-detail/subscale-detail.component';
import { SubscaleQuestionDetailComponent } from './subscale-question-detail/subscale-question-detail.component';
import { SubscaleQuestionGroupDetailComponent } from './subscale-question-group-detail/subscale-question-group-detail.component';
import { SubscaleQuestionAnswerDetailComponent } from './subscale-question-answer-detail/subscale-question-answer-detail.component';

const clientsRoutes: Routes = [
  { path: '', component: SubscaleListComponent },
  { path: ':id/detail', component: SubscaleDetailComponent },
  { path: ':id/question/:questionId/detail', component: SubscaleQuestionDetailComponent },
  { path: ':id/question/group/:questionGroupId/detail', component: SubscaleQuestionGroupDetailComponent },
  { path: ':id/question/:questionId/answer/:answerId/detail', component: SubscaleQuestionAnswerDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(clientsRoutes)],
  exports: [RouterModule],
})
export class SubscaleRoutingModule {}
