import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-text',
  templateUrl: './question-text.component.html',
  styleUrls: ['./question-text.component.scss'],
})
export class QuestionTextComponent implements OnInit {
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {
    this.text = this.text.replace(/<[^>]*>/g, '\n');
  }
}
