import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserEditDialogComponent } from '@app/shared/components/user-edit-dialog/user-edit-dialog.component';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ResetPasswordComponent } from '@app/shared/components/reset-password/reset-password.component';
import { extractContent, isHTML } from '@app/shared/utils/common-utils';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { PsbRoleMandateAddUserDialogComponent } from '@app/shared/components/psb-role-mandate-add-user-dialog/psb-role-mandate-add-user-dialog.component';
import { EVENT_SUCCESS, ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TGM, ROLE_TEST_USER } from '@app/core/constants';
import { forkJoin } from 'rxjs';

// services
import { UserService } from '@app/core/services/user.service';
import { SendEmailService } from '@app/core/services/send-email.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { FormService } from '@app/core/services/form.service';

// models
import { Country } from '@app/core/models/country.model';
import { TimeZone } from '@app/core/models/time-zone.model';
import { Company } from '@app/core/models/company.model';
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  currentUser: UserInfo;
  tabSelected = 0;
  allowEmailVerification = false;
  showScoreTab: boolean = false;
  roleNavigatorList = [];
  displayedColumns = ['title', 'description', 'status', 'actions'];
  tabs: string[] = ['Details', 'Test Groups', 'Role Navigators', 'Scores'];
  roleNavigators: RoleMandate[];
  selectedUserRoleNavigator: any;
  roleMandateId: number;
  timeZones: TimeZone[];
  companies: Company[];
  countries: Country[];
  isCurrentUserAdmin: boolean;
  isCurrentUserOrganizationalAdmin: boolean;
  hideResetPasswordAndEditButtons: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private sendEmailService: SendEmailService,
    private snackBar: SnackBarService,
    private roleMandateService: RoleMandateService,
    private formService: FormService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.tabSelected = params.id ? +params.tab : 0;
    });

    const userId = Number(this.activatedRoute.snapshot.params.id);

    if (Number.isNaN(userId)) {
      this.router.navigate(['../']);
      return;
    }

    forkJoin({
      timeZones: this.formService.getTimeZones(),
      companies: this.formService.getCompanies(),
      countries: this.formService.getCountries(),
      user: this.userService.get(userId),
    }).subscribe(({ timeZones, companies, countries, user }) => {
      this.timeZones = [null, ...timeZones];
      this.companies = companies;
      this.countries = [null, ...countries];

      this.currentUser = user;
      (this.currentUser.authoritieRoles = this.currentUser.authorities
        ? this.parseRoleAuthority(this.currentUser.authorities)
        : ''),
        (this.currentUser.phone = this.currentUser.phone?.toString().includes('+')
          ? this.currentUser.phone
          : `+${this.currentUser.phone.toString()}`);
      if (this.currentUser.authorities.some((a) => a.name === 'ROLE_ADMIN')) {
        this.allowEmailVerification = true;
        this.isCurrentUserAdmin = true;
      }

      if (this.currentUser.authorities.some((a) => a.name === 'ROLE_ORGANIZATIONAL_ADMIN')) {
        this.allowEmailVerification = true;
        this.isCurrentUserOrganizationalAdmin = true;
      }

      const currentUserInfo = this.userService.getUserData();
      let loggedUser = currentUserInfo.login;
      let loggedUserRole: any = currentUserInfo.authorities;
      this.roleMandateId = user.roleMandateId;
      if (loggedUserRole.some((a: any) => a.name === 'ROLE_ADMIN')) {
        this.showScoreTab = true;
      } else {
        // OA or TGM
        if (this.isCurrentUserAdmin) {
          this.hideResetPasswordAndEditButtons = true;
        }

        if (loggedUserRole.some((a: any) => a.name === 'ROLE_TGM')) {
          if (this.isCurrentUserOrganizationalAdmin) {
            this.hideResetPasswordAndEditButtons = true;
          }
        }
      }
    });

    this.getRoleMandates();
    this.loadRoleMandateUsers(userId);
  }

  loadRoleMandateUsers(userId) {
    this.roleMandateService.getAllByUserInfoId(this.roleMandateId, userId).subscribe((result: RoleMandate[]) => {
      this.roleNavigatorList = [];
      if (result.length > 0) {
        result.map((e: any) => {
          this.roleNavigatorList.push({
            id: e.id,
            title: e.title,
            description: isHTML(e.instructions) ? extractContent(e.instructions) : e.instructions,
            status: e?.status,
            userId: userId,
          });
        });
      }
    });
  }

  get active(): boolean {
    let active: boolean;
    if (this.currentUser.active.hasOwnProperty('data')) {
      active = this.currentUser.active.data[0] === 0 ? false : true;
    } else {
      active = this.currentUser.active;
    }
    return active;
  }

  get fullName() {
    return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
  }

  get language() {
    const langKey = this.currentUser.languageKey;

    return (
      {
        en: 'English',
        da: 'Danish',
        nl: 'Dutch',
        fr: 'French',
        de: 'German',
        es: 'Spanish',
        'pt-br': 'Portuguese',
        'zh-cn': 'Chinese',
      }[langKey] || 'Not specified'
    );
  }

  get gender() {
    const genderKey = this.currentUser.gender;

    return (
      {
        FEMALE: 'Female',
        MALE: 'Male',
        OTHER: 'Other',
        PREFER_NOT_TO_SAY: 'Prefer not to say',
      }[genderKey] || 'Not specified'
    );
  }

  parseRoleAuthority(role: any) {
    let roles: any[] = role;
    return roles
      .map((e) => {
        if (e.name === ROLE_ADMIN) {
          return 'System Manager';
        } else if (e.name === ROLE_ORGANIZATIONAL_ADMIN) {
          return 'Organizational Admin';
        } else if (e.name === ROLE_TGM) {
          return 'T.G. Manager';
        } else if (e.name === ROLE_TEST_USER) {
          return 'Test User';
        }
      })
      .join(', ');
  }

  onEdit() {
    const dialogData = {
      defaultValues: {
        id: this.currentUser.id,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        email: this.currentUser.email,
        gender: this.currentUser.gender,
        country: this.currentUser.countryId,
        state: this.currentUser.stateId,
        phone: this.currentUser.phone,
        language: this.currentUser.languageKey,
        timezone: this.currentUser.timeZoneId,
        client: this.currentUser.companyId,
        department: this.currentUser.departmentId,
        active: this.active,
        authorityName: this.currentUser.authorityName,
        privateScores: this.currentUser.privateScores,
      },
      timeZones: this.timeZones,
      companies: this.companies,
      countries: this.countries,
    };

    this.dialog
      .open(UserEditDialogComponent, { data: dialogData })
      .afterClosed()
      .subscribe(
        (result) => {
          if (result && result.updatedUser) {
            this.currentUser = result.updatedUser;
            (this.currentUser.authoritieRoles = this.currentUser.authorities
              ? this.parseRoleAuthority(this.currentUser.authorities)
              : ''),
              (this.currentUser.phone = this.currentUser.phone?.toString().includes('+')
                ? this.currentUser.phone
                : `+${this.currentUser.phone.toString()}`);
            this.snackBar.info('User updated successfully.');
          }
        },
        () => this.snackBar.info('Something went wrong. Please, try again later.'),
      );
  }

  verifyEmail() {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
    confirmationDialog.componentInstance.message = `Are you sure you want to verify this email?`;
    confirmationDialog.componentInstance.isShowCancel = true;
    confirmationDialog.afterClosed().subscribe(
      (result) => {
        if (result.event !== 'cancel') {
          this.sendEmailService.verifyEmail(this.currentUser.email).subscribe();
        }
      },
      () => this.snackBar.info('Something went wrong. Please, try again later.'),
    );
  }

  tabChange(event: any) {
    this.tabSelected = event;
    return this.router.navigate([this.router.url.split('tab/')[0] + '/tab/' + event]);
  }

  resetPassword() {
    this.dialog.open(ResetPasswordComponent, {
      data: { userId: this.currentUser.id },
      autoFocus: false,
      width: '35vw',
      height: '40vh',
    });
  }

  onView($event) {
    this.router.navigate([`/role-mandate/${$event}/dashboard`]);
  }

  onDeleteByUser($event) {
    if ($event.id && $event.userId) {
      this.roleMandateService
        .deleteRoleMandateUser($event.id, $event.userId)
        .subscribe((result: { affectedRows: number; insertId: number; warningStatus: number }) => {
          const userId = Number(this.activatedRoute.snapshot.params.id);
          if (result?.affectedRows == 1) {
            this.snackBar.info('User deleted successfully.');
            this.loadRoleMandateUsers(userId);
          }
        });
    }
  }

  getRoleMandates() {
    this.roleMandateService.get().subscribe((e: RoleMandate[]) => {
      this.roleNavigators = e.length ? e : [];
    });
  }

  onAddRoleNavigator() {
    let _filteredRoleNavigator = this.roleNavigators.filter((array_el) => {
      return (
        this.roleNavigatorList.filter((anotherOne_el) => {
          return anotherOne_el.id == array_el.id;
        }).length == 0
      );
    });

    this.dialog
      .open(PsbRoleMandateAddUserDialogComponent, {
        width: '30vw',
        height: '30vh',
        data: {
          roleNavigators: _filteredRoleNavigator,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_SUCCESS) {
          if (result.roleMandateId) {
            const userId = Number(this.activatedRoute.snapshot.params.id);
            this.selectedUserRoleNavigator = {
              email: this.currentUser.email,
              firstName: this.currentUser.firstName,
              lastName: this.currentUser.lastName,
              roleMandateId: result.roleMandateId,
              id: userId,
            };

            this.roleMandateService
              .saveRoleMandateUser(result.roleMandateId, [this.selectedUserRoleNavigator])
              .subscribe((response: any) => {
                this.loadRoleMandateUsers(userId);
                this.snackBar.info('Added user to the role navigator with success.');
              });
          }
        }
      });
  }
}
