<div class="container-fluid">
  <span *ngIf="this.superscale" class="psb-page-navigator">
    Superscale > <b>{{ superscale.title }}</b> >
    {{ tabs[+selected.value] }}
  </span>

  <mat-tab-group
    *ngIf="this.superscale"
    mat-align-tabs="start"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="tabChange($event)"
  >
    <mat-tab label="Details">
      <button mat-raised-button color="primary" class="pull-right" (click)="onEdit()">Edit</button>
      <div class="row">
        <div class="column">
          <span class="label">Title</span>
          <p>{{ superscale.title }}</p>
          <span class="label">Description</span>
          <p>{{ superscale.description }}</p>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Superscale Subscale">
      <app-superscale-subscale-list [superscaleId]="superscaleId"></app-superscale-subscale-list>
    </mat-tab>
  </mat-tab-group>
</div>
