import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  HostListener,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormControl } from '@angular/forms';

// services
import { D3Service } from '@app/core/services/d3.service';
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';

// models
import { ForceDirectedGraph, Link, Node } from '@app/core/models/d3';

@Component({
  selector: 'app-user-network',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-network.component.html',
  styleUrls: ['./user-network.component.scss'],
})
export class UserNetworkComponent implements OnInit, AfterViewInit {
  @Input() nodesData: {
    node: string;
    userInfoId: number;
    eigenvectorCentrality: number;
    neighborhoodCentrality: number;
  }[] = [];
  @Input() type: string;
  @Input() id?: number;
  @Input() connectionIndicatorId?: number;
  @Input() roleMandateId?: number;

  linksData: { fromNode: string; toNode: string; value: string }[];
  nodes: Node[] = [];
  links: Link[] = [];
  loadedContent = false;
  centralityFormControl = new FormControl('global');
  graph: ForceDirectedGraph;
  options: { width: number; height: number } = { width: window.innerWidth - 200, height: 400 };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.graph) {
      this.graph.initSimulation(this.options);
    }
  }

  constructor(
    private ref: ChangeDetectorRef,
    private d3Service: D3Service,
    private cIService: ConnectionsIndicatorService,
    private rMService: RoleMandateService,
  ) {
    this.centralityFormControl.valueChanges.subscribe((data) => {
      this.nodes.forEach((node) => (node.centrality = data));
      this.generateGraph();
    });
  }

  async ngOnInit() {
    if (this.nodesData.length > 0) {
      this.nodesData.forEach((node) => {
        this.nodes.push(
          new Node(
            node.node,
            node.userInfoId,
            node.eigenvectorCentrality,
            node.neighborhoodCentrality,
            this.centralityFormControl.value,
            1,
          ),
        );
      });

      switch (this.type) {
        case 'decisionTable': {
          if (this.connectionIndicatorId) {
            this.cIService.getAllLinkByDecisionTableId(this.connectionIndicatorId, this.id).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          } else if (this.roleMandateId) {
            this.rMService.getAllLinkByDecisionTableId(this.roleMandateId, this.id).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          }
          break;
        }
        case 'question': {
          if (this.connectionIndicatorId) {
            this.cIService.getAllLinkByQuestionId(this.connectionIndicatorId, this.id).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          } else if (this.roleMandateId) {
            this.rMService.getAllLinkByQuestionId(this.roleMandateId, this.id).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          }
          break;
        }
        case 'REFERENT': {
          if (this.connectionIndicatorId) {
            this.cIService.getAllLinkByType(this.connectionIndicatorId, this.type).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          } else if (this.roleMandateId) {
            this.rMService.getAllLinkByType(this.roleMandateId, this.type).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          }
          break;
        }
        case 'EXPERT': {
          if (this.connectionIndicatorId) {
            this.cIService.getAllLinkByType(this.connectionIndicatorId, this.type).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          } else if (this.roleMandateId) {
            this.rMService.getAllLinkByType(this.roleMandateId, this.type).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          }
          break;
        }
        case 'GLOBAL': {
          if (this.connectionIndicatorId) {
            this.cIService.getAllLinks(this.connectionIndicatorId).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          } else if (this.roleMandateId) {
            this.rMService.getAllLinks(this.roleMandateId).subscribe((links) => {
              this.linksData = links;
              this.linksData.forEach((link) => {
                this.links.push(new Link(link.fromNode, link.toNode, link.value));
              });
              this.generateGraph();
            });
          }
          break;
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.loadedContent && this.nodesData.length > 0) {
      this.graph.initSimulation(this.options);
    }
  }

  get getOptions() {
    return (this.options = {
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  generateGraph() {
    this.graph = this.d3Service.getForceDirectedGraph(this.nodes, this.links, this.options);
    this.graph.ticker.subscribe((d) => {
      this.ref.markForCheck();
    });
    this.graph.initSimulation(this.options);
    this.loadedContent = true;
  }
}
