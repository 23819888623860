import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// models
import { ReportRange } from '@app/core/models/report-range.model';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-edit-range-dialog',
  templateUrl: './edit-range-dialog.component.html',
  styleUrls: ['./edit-range-dialog.component.scss'],
})
export class EditRangeDialogComponent implements OnInit {
  form: FormGroup;
  editorConfig: AngularEditorConfig = {
    height: '8rem',
    editable: true,
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ReportRange,
    private dialogRef: MatDialogRef<EditRangeDialogComponent>,
  ) {
    this.form = this.fb.group({
      currentOption: ['he', [Validators.required]],
      from: [0, [Validators.required]],
      to: [1, [Validators.required]],
      reportText: this.genReportTextFormGroup(),
    });
  }

  ngOnInit(): void {
    this.form.get('from').setValue(this.data.from);
    this.form.get('to').setValue(this.data.to);
    this.form.get('reportText.he').setValue(this.data.reportText?.he || this.data['text']?.he);
    if (this.data.reportText || this.data['text']) {
      Object.keys(this.data.reportText || this.data['text']).forEach((pronoun) => {
        if (pronoun !== 'id') {
          if (this.data.reportText[pronoun] || this.data['text'][pronoun]) {
            this.form
              .get(`reportText.${pronoun}`)
              .setValue(this.data.reportText[pronoun] || this.data['text'][pronoun]);
          }
        }
      });
    }
  }

  genReportTextFormGroup() {
    return this.fb.group({
      he: ['', [Validators.required]],
      she: [''],
      they: [''],
    });
  }

  onSubmit() {
    const { value } = this.form;
    this.dialogRef.close({ event: EVENT_SUCCESS, range: value });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
