import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClipboardService } from 'ngx-clipboard';

// models
import { Invite } from '@app/core/models/invite.model';
import { ExistedEmail } from '@app/core/models/existed-email.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { EVENT_CLOSE } from '@app/core/constants';
import { EVENT_SAVE } from '@app/core/constants';
import { EMAIL_PATTERN } from '@app/shared/patterns/pattern-format';

// services
import { InviteService } from '@app/core/services/invite.service';

@Component({
  selector: 'app-user-invite-dialog',
  templateUrl: './user-invite-dialog.component.html',
  styleUrls: ['./user-invite-dialog.component.scss'],
})
export class UserInviteDialogComponent implements OnInit {
  isEdit: boolean;
  emailFieldUpdate = new Subject<string>();
  emailNoExisted = false;
  validEmailAddress = false;

  userInviteForm = new FormGroup({
    firstName: new FormControl({ value: '', disabled: true }),
    lastName: new FormControl({ value: '', disabled: true }),
    title: new FormControl({ value: '', disabled: true }, [Validators.required]),
    email: new FormControl('', [Validators.required]),
    company: new FormControl({ value: '', disabled: true }, [Validators.required]),
    url: new FormControl({ value: '', disabled: true }, [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<UserInviteDialogComponent>,
    private inviteService: InviteService,
    private clipboardApi: ClipboardService,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; invite: Invite },
  ) {
    this.emailFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        if (EMAIL_PATTERN.test(value)) {
          let existedEmail = this.createExistedEmail(value);

          this.inviteService.checkExistedEmail(existedEmail).subscribe((result) => {
            if (result) {
              this.userInviteForm.get('email').setErrors({ isEmailExist: true });
              this.emailNoExisted = false;
            } else {
              this.emailNoExisted = true;
              this.validEmailAddress = true;
            }
          });
        } else {
          this.userInviteForm.get('email').setErrors({ invalidEmailAddress: true });
          this.validEmailAddress = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.isEdit = this.data.isEdit;

    if (this.data.invite) {
      this.userInviteForm.get('firstName').setValue(this.data.invite.firstName);
      this.userInviteForm.get('lastName').setValue(this.data.invite.lastName);
      this.userInviteForm.get('title').setValue(this.data.invite.title);
      this.userInviteForm.get('email').setValue(this.data.invite.email);
      this.userInviteForm.get('company').setValue(this.data.invite.company);
      this.userInviteForm.get('url').setValue(`${environment.url}/invite/${this.data.invite.token}`);
    }

    if (!this.isEdit) {
      this.userInviteForm.controls['email'].disable();
    }
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onCopy() {
    this.clipboardApi.copyFromContent(`${environment.url}/invite/${this.data.invite.token}`);
  }

  onSave() {
    this.dialogRef.close({ event: EVENT_SAVE, data: this.userInviteForm.value });
  }

  createExistedEmail(value: string) {
    let existedEmail: ExistedEmail;

    if (this.data.invite.title.includes('CI')) {
      existedEmail = {
        email: value,
        connectionIndicatorId: this.data.invite.testId,
      };
    }

    if (this.data.invite.title.includes('RN')) {
      existedEmail = {
        email: value,
        roleMandateId: this.data.invite.testId,
      };
    }

    if (this.data.invite.title.includes('TG')) {
      existedEmail = {
        email: value,
        testGroupId: this.data.invite.testId,
      };
    }

    return existedEmail;
  }
}
