import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-redirect-dialog',
  templateUrl: './redirect-dialog.component.html',
  styleUrls: ['./redirect-dialog.component.scss'],
})
export class RedirectDialogComponent {
  constructor(public dialogRef: MatDialogRef<RedirectDialogComponent>) {}

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onConfirm() {
    this.dialogRef.close({ event: 'confirm' });
  }
}
