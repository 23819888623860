/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { RoleMandateNotification } from '../models/RoleMandateNotification';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class RoleMandateNotificationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a roleMandateNotification
     * Create a roleMandateNotification
     * @param roleMandateId Id of the role manadate
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postRoleMandateNotification(
        roleMandateId: number,
        requestBody?: {
            /**
             * Id of the RoleMandate
             */
            psyTestGroupId?: number;
            /**
             * Id of the notification
             */
            notificationId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/role-mandate-notification/{roleMandateId}',
            path: {
                'roleMandateId': roleMandateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a roleMandateNotification by roleMandateId
     * Retrieve a roleMandateNotification by roleMandateId
     * @param roleMandateId Id of the Role Mandate
     * @returns RoleMandateNotification A roleMandateNotification
     * @throws ApiError
     */
    public getRoleMandateNotification(
        roleMandateId: number,
    ): Observable<RoleMandateNotification> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/role-mandate-notification/{roleMandateId}',
            path: {
                'roleMandateId': roleMandateId,
            },
            errors: {
                404: `RoleMandateNotification not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a role mandate notification
     * @param roleMandateId Id of the Role Mandate
     * @returns any RoleMandateNotification deleted successfully
     * @throws ApiError
     */
    public deleteRoleMandateNotificationNotification(
        roleMandateId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/role-mandate-notification/{roleMandateId}/notification/{notificationId}',
            path: {
                'roleMandateId': roleMandateId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
