import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class NetworkAnalysisService {
  constructor(private http: HttpClient, private router: Router) {}

  refreshQuestion(questionId: number) {
    return this.http.post(BACKEND_URL + '/sna/centrality?questionId=' + questionId, {});
  }

  refreshDecisionTable(decisionTableId: number) {
    return this.http.post(BACKEND_URL + '/sna/centrality?decisionTableId=' + decisionTableId, {});
  }
}
