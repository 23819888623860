import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';

// services
import { FormService } from '@app/core/services/form.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { Country } from '@app/core/models/country.model';
import { TimeZone } from '@app/core/models/time-zone.model';
import { State } from '@app/core/models/state.model';
import { Company } from '@app/core/models/company.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { EMAIL_PATTERN, INTL_TELEPHONE_PATTERN } from '@app/shared/patterns/pattern-format';
import { intlTelephoneCodes } from '@app/shared/constants/intl-telephone-codes';

@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.scss'],
})
export class ClientModalComponent implements OnInit {
  clientModalForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    country: new FormControl(''),
    stateId: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    address2: new FormControl(''),
    billingCountry: new FormControl(''),
    billingStateId: new FormControl(''),
    billingCity: new FormControl(''),
    billingZipCode: new FormControl(''),
    billingAddress: new FormControl(''),
    billingAddress2: new FormControl(''),
    telephone: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.pattern(INTL_TELEPHONE_PATTERN)]),
    ),
    email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
    skype: new FormControl(''),
    defaultLanguage: new FormControl(''),
    timeZoneId: new FormControl(''),
    subscriptionTime: new FormControl('', [Validators.required]),
    subscriptionTestTakers: new FormControl(''),
    allowPostPlanAccess: new FormControl(true),
    active: new FormControl(true, [Validators.required]),
    allowRawScoresAccess: new FormControl(false),
    logo: new FormControl(null),
    logoContentType: new FormControl(null),
    logoFile: new FormControl(null),
  });
  countries: Country[];
  states: State[];
  billingStates: State[];
  timeZones: TimeZone[];
  sameAsLocation = true;
  isEdit: boolean;
  countryId: any;
  phone: any;
  fileName: string;
  filteredCountries: Observable<Country[]>;
  filteredStates: Observable<State[]>;
  filteredTimeZones: Observable<TimeZone[]>;
  filteredBillingStates: Observable<State[]>;
  selectedTimeZone: TimeZone;
  selectedState: State;
  selectedBillingState: State;
  company: Company;

  constructor(
    public dialogRef: MatDialogRef<ClientModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; companyId: number },
    private formService: FormService,
    private companyService: CompanyService,
  ) {}

  ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });

    this.formService.getTimeZones().subscribe((result) => {
      this.timeZones = [null, ...result];

      this.filteredTimeZones = this.clientModalForm.controls['timeZoneId'].valueChanges.pipe(
        startWith(''),
        map((timeZone) => (timeZone ? this._filterTimeZone(timeZone) : this.timeZones.slice())),
      );
    });

    this.isEdit = this.data.isEdit;

    this.formService.getCountries().subscribe((responseCountries) => {
      this.countries = [null, ...responseCountries];

      this.filteredCountries = this.clientModalForm.controls['country'].valueChanges.pipe(
        startWith(''),
        map((country) => (country ? this._filterCountry(country) : this.countries.slice())),
      );

      if (this.data.isEdit) {
        this.companyService.getById(this.data.companyId).subscribe((company) => {
          this.company = company;
          if (
            (company.billingAddress === company.address &&
              company.zipCode === company.billingZipCode &&
              company.billingCity === company.city) ||
            (!company.billingAddress && !company.billingZipCode && !company.billingCity)
          ) {
            this.sameAsLocation = true;
          } else {
            this.sameAsLocation = false;
          }

          if (this.sameAsLocation) {
            const selectedCountry = this.countries.filter((e) => e && e.id === company.countryId);
            if (selectedCountry.length) {
              this.clientModalForm.get('country').setValue(selectedCountry[0]);
              this.clientModalForm.get('billingCountry').setValue(selectedCountry[0]);

              this.formService.getStatesByCountry(selectedCountry[0].id).subscribe((responseStates) => {
                this.states = responseStates;
                this.billingStates = responseStates;

                if (company.stateId) {
                  this.selectedState = this.states.filter((s) => s && s.id === company.stateId)[0];
                  this.clientModalForm.get('stateId').setValue(this.selectedState);
                  if (company.billingState) {
                    this.selectedBillingState = this.billingStates.filter((s) => s && s.id === company.stateId)[0];
                    this.clientModalForm.get('billingStateId').setValue(this.selectedBillingState);
                  }
                }
              });
            }
          } else {
            const selectedCountry = this.countries.filter((e) => e && e.id === company?.countryId);
            const selectedBillingCountry = this.countries.filter((e) => e && e.id === company?.billingCountryId);
            if (selectedCountry.length) {
              this.clientModalForm.get('country').setValue(selectedCountry[0]);

              this.formService.getStatesByCountry(selectedCountry[0].id).subscribe((responseStates) => {
                this.states = responseStates;

                if (company.stateId) {
                  this.selectedState = this.states.filter((s) => s && s.id === company.stateId)[0];
                  this.clientModalForm.get('stateId').setValue(this.selectedState);
                }
              });
            }

            if (selectedBillingCountry.length) {
              this.clientModalForm.get('billingCountry').setValue(selectedBillingCountry[0]);

              this.formService.getStatesByCountry(selectedBillingCountry[0].id).subscribe((responseStates) => {
                this.billingStates = responseStates;
                this.selectedBillingState = this.billingStates.filter((s) => s && s.id === company.billingState)[0];
                this.clientModalForm.get('billingStateId').setValue(this.selectedBillingState);
              });
            }
          }

          this.clientModalForm.get('name').setValue(company.name);
          this.clientModalForm.get('city').setValue(company.city);
          this.clientModalForm.get('zipCode').setValue(company.zipCode);
          this.clientModalForm.get('address').setValue(company.address);
          this.clientModalForm.get('address2').setValue(company.address2);
          this.clientModalForm.get('billingCity').setValue(company.billingCity);
          this.clientModalForm.get('billingZipCode').setValue(company.billingZipCode);
          this.clientModalForm.get('billingAddress').setValue(company.billingAddress);
          this.clientModalForm.get('billingAddress2').setValue(company.billingAddress2);
          this.clientModalForm
            .get('telephone')
            .setValue(
              company.telephone.toString().includes('+') ? company.telephone : `+${company.telephone.toString()}`,
            );
          this.phone = company.telephone;
          this.clientModalForm.get('email').setValue(company.email);
          this.clientModalForm.get('skype').setValue(company.skype);
          this.clientModalForm.get('defaultLanguage').setValue(company.defaultLanguage);
          const selectedTimeZone = this.timeZones.filter((t) => t && t.id === company.timeZone)[0];
          this.clientModalForm.get('timeZoneId').setValue(selectedTimeZone);
          this.clientModalForm.get('subscriptionTime').setValue(company.subscriptionTime);
          this.clientModalForm.get('subscriptionTestTakers').setValue(company.subscriptionTestTakers);
          this.clientModalForm.get('allowPostPlanAccess').setValue(company.allowPostPlanAccess);
          this.clientModalForm.get('allowRawScoresAccess').setValue(company.allowRawScoresAccess);
          this.clientModalForm.get('active').setValue(company.active);
        });
      }
    });
  }

  compareFn(x: any, y: any): boolean {
    return parseInt(x, 10) === y ? true : false;
  }

  onSelectCountry(country: Country) {
    this.countryId = country.id;
    this.formService.getStatesByCountry(this.countryId).subscribe((response) => {
      this.states = response;

      this.filteredStates = this.clientModalForm.controls['stateId'].valueChanges.pipe(
        startWith(''),
        map((state) => (state ? this._filterState(state) : this.states.slice())),
      );
    });
    if (this.clientModalForm.value.country) {
      this.clientModalForm.get('stateId').setValue('');
      const intlCode = this.clientModalForm.get('country').value.abbrev;
      this.intlTelphoneCode(intlCode);
    }
  }

  onSelectBillingCountry(billingCountry: Country) {
    this.formService.getStatesByCountry(billingCountry.id).subscribe((response) => {
      this.billingStates = response;

      this.filteredBillingStates = this.clientModalForm.controls['billingStateId'].valueChanges.pipe(
        startWith(''),
        map((billingState) => (billingState ? this._filterBillingState(billingState) : this.billingStates.slice())),
      );
    });
  }

  toggleBillingAddress() {
    this.sameAsLocation = !this.sameAsLocation;
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onSave() {
    if (this.sameAsLocation === true) {
      if (
        this.company &&
        this.company.billingState &&
        this.company.billingAddress &&
        this.company.billingZipCode &&
        this.company.billingCity
      ) {
        this.clientModalForm.get('billingCountry').setValue(this.clientModalForm.get('country').value);
        this.clientModalForm.get('billingStateId').setValue(this.clientModalForm.get('stateId').value);
        this.clientModalForm.get('billingCity').setValue(this.clientModalForm.get('city').value);
        this.clientModalForm.get('billingZipCode').setValue(this.clientModalForm.get('zipCode').value);
        this.clientModalForm.get('billingAddress').setValue(this.clientModalForm.get('address').value);
        this.clientModalForm.get('billingAddress2').setValue(this.clientModalForm.get('address2').value);
      }
    }

    this.clientModalForm.value.country = this.clientModalForm.value.country.id;
    this.clientModalForm.value.billingCountry = this.clientModalForm.value.billingCountry.id;
    if (this.clientModalForm.value.telephone) {
      this.clientModalForm.value.telephone = this.clientModalForm.value.telephone.replace(/\s/g, '');
    }

    let client = {
      name: this.clientModalForm.get('name').value,
      city: this.clientModalForm.get('city').value,
      zipCode: this.clientModalForm.get('zipCode').value,
      address: this.clientModalForm.get('address').value,
      address2: this.clientModalForm.get('address2').value,
      telephone: this.clientModalForm.get('telephone').value,
      active: this.clientModalForm.get('active').value,
      defaultLanguage: this.clientModalForm.get('defaultLanguage').value
        ? this.clientModalForm.get('defaultLanguage').value
        : null,
      stateId: this.clientModalForm.get('stateId').value ? this.clientModalForm.get('stateId').value.id : null,
      timeZoneId: this.clientModalForm.get('timeZoneId').value ? this.clientModalForm.get('timeZoneId').value.id : null,
      billingAddress: this.clientModalForm.get('billingAddress').value
        ? this.clientModalForm.get('billingAddress').value
        : null,
      billingAddress2: this.clientModalForm.get('billingAddress2').value
        ? this.clientModalForm.get('billingAddress2').value
        : null,
      billingCity: this.clientModalForm.get('billingCity').value ? this.clientModalForm.get('billingCity').value : null,
      billingStateId: this.clientModalForm.get('billingStateId').value
        ? this.clientModalForm.get('billingStateId').value.id
        : null,
      billingZipCode: this.clientModalForm.get('billingZipCode').value
        ? this.clientModalForm.get('billingZipCode').value
        : null,
      email: this.clientModalForm.get('email').value,
      skype: this.clientModalForm.get('skype').value ? this.clientModalForm.get('skype').value : null,
      subscriptionTime: this.clientModalForm.value.subscriptionTime
        ? this.convertTimestamp(this.clientModalForm.value.subscriptionTime)
        : null,
      subscriptionTestTakers: this.clientModalForm.get('subscriptionTestTakers').value
        ? this.clientModalForm.get('subscriptionTestTakers').value
        : null,
      allowPostPlanAccess: this.clientModalForm.get('allowPostPlanAccess').value
        ? this.clientModalForm.get('allowPostPlanAccess').value
        : null,
      allowRawScoresAccess: this.clientModalForm.get('allowRawScoresAccess').value
        ? this.clientModalForm.get('allowRawScoresAccess').value
        : false,
      logo: this.clientModalForm.get('logo').value ? this.clientModalForm.get('logo').value : null,
      logoContentType: this.clientModalForm.get('logoContentType').value
        ? this.clientModalForm.get('logoContentType').value
        : null,
    };

    this.dialogRef.close({
      event: 'save',
      data: client,
    });
  }

  // 'utcTimestamp' should follow this pattern `2021-08-31T03:00:00.000Z`
  // The new timestamp format should look like this: `2021-08-31 03:00:00`
  convertTimestamp(utcTimestamp: string) {
    const date = new Date(utcTimestamp);
    return date.getTime();
  }

  intlTelphoneCode(intlCode: string) {
    const selectedIntlCode = intlTelephoneCodes.filter((e: any) => e.code === intlCode);
    if (selectedIntlCode.length) {
      this.clientModalForm.get('telephone').setValue(`+${selectedIntlCode[0].callingCode}`);
    }
  }

  handleFile(fileType: string, $event: any) {
    const file = $event.target.files[0];
    this.fileName = file.name;
    const reader = new FileReader();

    this.clientModalForm.get('logoContentType').setValue(file.type);
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.clientModalForm.get('logo').setValue(event.target.result);
    };
  }

  displayTimeZone(timeZone: TimeZone) {
    return timeZone ? timeZone.name : '';
  }

  displayCountry(country: Country): string {
    return country ? country.name : '';
  }

  displayState(state: State): string {
    return state ? state.name : '';
  }

  private _filterTimeZone(value: string): TimeZone[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.timeZones;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.timeZones.filter((timeZone: TimeZone) => {
      if (timeZone) {
        return timeZone.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterCountry(value: string): Country[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.countries;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.countries.filter((country: Country) => {
      if (country) {
        return country.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterState(value: string): State[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.states;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.states.filter((state: State) => {
      if (state) {
        return state.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterBillingState(value: string): State[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.billingStates;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.billingStates.filter((billingState: State) => {
      if (billingState) {
        return billingState.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }
}
