import { Component, OnInit, Input } from '@angular/core';

// models
import { Chart } from '@app/core/models/chart.model';

@Component({
  selector: 'app-report-dashboard-charts',
  templateUrl: './report-dashboard-charts.component.html',
  styleUrls: ['./report-dashboard-charts.component.scss'],
})
export class ReportDashboardChartsComponent implements OnInit {
  @Input() userInfoId: number;
  @Input() charts: Chart[];
  @Input() testGroupId: number;

  constructor() {}

  ngOnInit(): void {}
}
