<form [formGroup]="editQuestionGroupForm" #formDirective="ngForm" (ngSubmit)="editQuestionGroup(formDirective)">
  <h3>Edit Question Group</h3>
  <mat-form-field>
    <mat-label>Title</mat-label>
    <input matInput type="text" formControlName="title" />
    <mat-error *ngIf="editQuestionGroupForm.get('title').errors">Please, enter a valid title.</mat-error>
    <mat-hint>Title of the Question Group</mat-hint>
  </mat-form-field>

  <angular-editor type="text" formControlName="instructions" [config]="editorConfig"></angular-editor>
  <mat-error *ngIf="editQuestionGroupForm.get('instructions').errors">Please, enter a valid instruction.</mat-error>
  <mat-hint>Instructions to answer the question. An introductory text for example. </mat-hint>

  <div class="edit-buttons-container text-center">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="btn-success btn-300"
      [disabled]="!editQuestionGroupForm.valid"
    >
      Update
    </button>
    <button mat-raised-button color="accent" class="btn-success btn-300" (click)="onCancel()">Cancel</button>
  </div>
</form>
