<mat-card>
  <form [formGroup]="editDecisionTableForm" #formDirective="ngForm" (ngSubmit)="editDecisionTable(formDirective)">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" />
      <mat-error *ngIf="editDecisionTableForm.get('title').errors">Please, enter a valid text.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Instructions</mat-label>
      <textarea matInput type="text" formControlName="instructions"></textarea>
      <mat-error *ngIf="editDecisionTableForm.get('instructions').errors">Please, enter a valid text.</mat-error>
      <mat-hint>Instructions to answer the question. An introductory text for example. </mat-hint>
    </mat-form-field>

    <div id="privacy" *ngIf="showAcl" class="privacy">
      <mat-label>Table Privacy</mat-label>
      <section *ngFor="let privacyMessage of privacyMessages">
        <mat-checkbox
          color="primary"
          [checked]="acl === privacyMessage.id"
          (change)="onChangeACL($event, privacyMessage)"
          >{{ privacyMessage.message }}</mat-checkbox
        >
      </section>
    </div>

    <mat-form-field>
      <mat-label>Network Type</mat-label>
      <mat-select [formControl]="editDecisionTableForm.get('networkType')">
        <mat-option></mat-option>
        <mat-option value="EXPERT">Expert</mat-option>
        <mat-option value="REFERENT">Referent</mat-option>
      </mat-select>
      <mat-hint>The Network Type define if the network is technical or social.</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Collaboration Type</mat-label>
      <mat-select [formControl]="editDecisionTableForm.get('collaborationType')">
        <mat-option *ngFor="let collaborationType of collaborationTypes" [value]="collaborationType.id">
          {{ collaborationType.title }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="editDecisionTableForm.get('collaborationType').hasError('required')">
        You must make a selection
      </mat-error>
      <mat-hint>Collaboration Type is the type that the collaboration between the users.</mat-hint>
    </mat-form-field>

    <div class="columns-header">
      <h2 class="columns-title">Columns</h2>
    </div>

    <app-edit-column
      *ngFor="let column of decisionTable.columns"
      [column]="column"
      (edit)="onEditColumn($event)"
      (delete)="onDeleteColumn($event)"
    ></app-edit-column>

    <app-new-column *ngIf="showNewColumn" [decisionTable]="decisionTable" (create)="onNewColumnConfirm($event)">
    </app-new-column>

    <button
      type="button"
      mat-raised-button
      class="btn-center btn-300 columns-new"
      color="primary"
      *ngIf="!showNewColumn"
      (click)="onNew()"
    >
      Add Column
    </button>

    <div class="edit-buttons-container text-center">
      <button mat-raised-button type="submit" color="primary">Save</button>
      <button mat-button (click)="onCancel()">Cancel</button>
    </div>
  </form>
</mat-card>
