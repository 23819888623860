import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { EVENT_CANCEL, EVENT_ERROR, EVENT_SUCCESS, ROLE_ADMIN, ROLE_TGM } from '@app/core/constants';
import { UserAddToTestGroupDialogComponent } from '@app/shared/components/user-add-to-test-group-dialog/user-add-to-test-group-dialog.component';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';

@Component({
  selector: 'app-user-test-group-list',
  templateUrl: './user-test-group-list.component.html',
  styleUrls: ['./user-test-group-list.component.scss'],
})
export class UserTestGroupListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  testGroupsDataSource = new MatTableDataSource<any>([]);
  displayedTestGroupsColumns = ['title', 'description', 'status', 'actions'];
  currentUserId: number;
  currentUserCompanyId: number;
  testGroups: PsyTestGroup[];
  role: string;
  canAdd: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.role = this.userService.getUserData().authorities.find((authority) => authority.name === ROLE_ADMIN)
      ? ROLE_ADMIN
      : ROLE_TGM;
    const userId = Number(this.activatedRoute.snapshot.params.id);

    if (!userId) {
      this.router.navigate(['/']);
      return;
    }

    this.currentUserId = userId;

    this.userService.getTestGroups(userId).subscribe((testGroups: PsyTestGroup[]) => {
      this.testGroups = testGroups;
      if (this.role === ROLE_TGM && testGroups.length > 0) {
        this.canAdd = testGroups[0].canAdd;
      } else if (this.role === ROLE_ADMIN) {
        this.canAdd = true;
      }
      this.testGroupsDataSource.data = testGroups.map((test) => ({
        id: test.id,
        title: test.title,
        description: test.description,
        status: test.status,
      }));
    });

    this.userService.get(userId).subscribe((user) => {
      this.currentUserCompanyId = user.companyId;
    });
  }

  ngAfterViewInit(): void {
    this.testGroupsDataSource.paginator = this.paginator;
  }

  searchTestGroups(event: Event): void {
    this.testGroupsDataSource.filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
  }

  onDelete(testGroupId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.userService.removeFromTestGroup(this.currentUserId, testGroupId).subscribe(() => {
            this.testGroupsDataSource.data = this.testGroupsDataSource.data.filter((test) => test.id !== testGroupId);
          });
        }
      });
  }

  onView(testGroupId: number) {
    return this.router.navigate([`/psy-test-group/${testGroupId}/details`]);
  }

  onAddTestGroup() {
    const data = {
      userId: this.currentUserId,
      userCompanyId: this.currentUserCompanyId,
      existingTestGroups: this.testGroupsDataSource.data.map((element) => element.id),
    };

    this.dialog
      .open(UserAddToTestGroupDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_SUCCESS) {
          this.testGroupsDataSource.data = [
            {
              id: result.newTestGroup.id,
              title: result.newTestGroup.title,
              description: result.newTestGroup.description,
              status: result.newTestGroup.status,
            },
            ...this.testGroupsDataSource.data,
          ];
          this.snackBar.info('Added user to the test group with success.');
        } else if (result.event === EVENT_ERROR) {
          this.snackBar.info('Error when adding user to the test group. Try again later.');
        }
      });
  }
}
