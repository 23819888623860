import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent implements OnInit {
  public message: string;

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>) {}

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
