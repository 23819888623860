import { Component, Input, OnInit } from '@angular/core';

// services
import { ChartService } from '@app/core/services/chart.service';

// helpers
import { environment } from 'src/environments/environment';
import { BarSubscalesController, SquarePlotController } from 'src/generated/v3';

@Component({
  selector: 'app-square-plot-new',
  templateUrl: './square-plot.component.html',
  styleUrls: ['./square-plot.component.scss'],
})
export class SquarePlotNewComponent implements OnInit {
  @Input() chartId: number;
  @Input() userId: number;
  @Input() testGroupId: number;
  @Input() companyId?: number;
  @Input() chartSelectedUsers: any[];
  @Input() visible?: boolean = true;

  description: string;
  src: string;
  isSubcalesX: boolean;
  isSubcalesY: boolean;

  constructor(
    private barSubscalesController: BarSubscalesController,
    private squarePlotController: SquarePlotController,
  ) {}

  ngOnInit(): void {
    this.src = `${environment.apiUrl}/v3/user/${this.userId}/testGroup/${this.testGroupId}/chart/${this.chartId}/square-chart.png`;

    this.barSubscalesController
      .barSubscalesControllerBarSubscalesChartIsSubscales(this.userId, this.chartId, this.testGroupId, 'X')
      .subscribe((result) => {
        console.log('resultX', result);
        this.isSubcalesX = result;
      });
    this.barSubscalesController
      .barSubscalesControllerBarSubscalesChartIsSubscales(this.userId, this.chartId, this.testGroupId, 'Y')
      .subscribe((result) => {
        console.log('resultY', result);
        this.isSubcalesY = result;
      });
    this.squarePlotController
      .squarePlotControllerSquarePlotChartDescription(this.userId, this.testGroupId, this.chartId)
      .subscribe((result) => {
        this.description = result?.description;
      });
  }
}
