import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  public message: string;
  public isShowCancel: boolean;
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onConfirm() {
    this.dialogRef.close({ event: 'confirm' });
  }
}
