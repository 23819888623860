import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { SearchDecisionTableAnswer } from '@app/core/models/search-decision-table-answer.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClient) {}

  findAllRoleMandateDecision(search: { phrase: string; roleMandateId: number }) {
    return this.http
      .post(BACKEND_URL + '/elasticsearch/role-mandate-decisions', {
        phrase: search.phrase,
        roleMandateId: search.roleMandateId,
      })
      .pipe(
        map((data: []) => {
          const results: SearchDecisionTableAnswer[] = [];
          data.forEach((answer) => {
            if (answer['_source']['user_info_id']) {
              const filteredAnswer: SearchDecisionTableAnswer = {
                id: answer['_source']['id'],
                companyId: answer['_source']['company_id'],
                decisionTable: {
                  id: answer['_source']['decision_table_id'],
                  title: answer['_source']['decision_table_title'],
                  decisionArea: answer['_source']['decision_area'],
                },
                user: {
                  userInfoId: answer['_source']['user_info_id'],
                  username: answer['_source']['username'],
                  companyRole: answer['_source']['user_company_role'],
                },
                text: answer['_source']['text'],
                userConnections: answer['_source']['user_connections'],
              };
              const userConnections: string = answer['_source']['user_connections'];
              if (userConnections) {
                filteredAnswer.userConnections = userConnections.split(',');
              }
              results.push(filteredAnswer);
            }
          });
          return results;
        }),
      );
  }
}
