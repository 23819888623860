import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';
import { SettingRoutingModule } from './setting-routing.module';

@NgModule({
  imports: [CommonModule, SharedModule, SettingRoutingModule],
  exports: [SharedModule],
})
export class SettingModule {}
