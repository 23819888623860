<div class="container">
  <h1>Manage Percentiles</h1>
  <p *ngIf="psyTestTitle">Scale List for test: {{ psyTestTitle }}</p>
  <app-psb-list
    [items]="subscales"
    [dashboardLabel]="'Percentiles'"
    itemsPerPage="10"
    (dashboard)="onDashboard($event)"
    [showSearch]="false"
  >
  </app-psb-list>
</div>
