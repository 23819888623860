/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { OpenTemplateResponse } from '../models/OpenTemplateResponse';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class TemplateController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param testGroupId
     * @returns OpenTemplateResponse
     * @throws ApiError
     */
    public templateControllerCreateDocxTemplate(
        testGroupId: number,
    ): Observable<OpenTemplateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/createDocxTemplate',
            query: {
                'testGroupId': testGroupId,
            },
        });
    }

    /**
     * @param testGroupId
     * @returns OpenTemplateResponse
     * @throws ApiError
     */
    public templateControllerOpenDocxTemplate(
        testGroupId: number,
    ): Observable<OpenTemplateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/openDocxTemplate',
            query: {
                'testGroupId': testGroupId,
            },
        });
    }

}
