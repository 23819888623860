<h2>Percentile: {{ subscaleTitle }}</h2>
<div class="buttons">
  <button mat-raised-button color="primary" (click)="onSave()">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
  <button mat-raised-button color="primary" (click)="onReset()">
    <mat-icon>rotate_right</mat-icon>
    Reset
  </button>
  <span *ngIf="cardsortSubscale === 1" class="cardsort-subscale-buttons">
    <button mat-raised-button color="primary" (click)="onAddPredefinedPercentileThinkingStyle()">
      <mat-icon>autorenew</mat-icon>
      Predefined Thinking Style
    </button>
    <button mat-raised-button color="primary" (click)="onAddPredefinedPercentileCRI()">
      <mat-icon>build</mat-icon>
      Predefined CRI
    </button>
    <button mat-raised-button color="primary" (click)="onAddPredefinedPercentile()">
      <mat-icon>launch</mat-icon>
      Predefined
    </button>
    <button mat-raised-button color="primary">
      <mat-icon>input</mat-icon>
      <input type="file" accept=".csv" (change)="importCSV($event)" class="input-file" />
      Import CSV
    </button>
    <button mat-raised-button color="primary" class="add-button" (click)="onAdd()">
      <mat-icon>add</mat-icon>
      Add
    </button>
  </span>
</div>

<div class="mat-elevation-z3">
  <mat-table [dataSource]="scorePercentileDataSource">
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef> Scale Title </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <p>{{ subscaleTitle }}</p>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="score">
      <mat-header-cell *matHeaderCellDef> Score </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <input matInput type="number" [(ngModel)]="element.score" [readonly]="cardsortSubscale !== 1" />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="percentile">
      <mat-header-cell *matHeaderCellDef> Percentile </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <mat-form-field>
          <input
            matInput
            type="number"
            [(ngModel)]="element.percentile"
            (input)="onInput($event, i)"
            (keypress)="onKeyPress($event)"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="action-buttons">
          <button mat-button class="btn-delete" (click)="onDelete(element)">Delete</button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedScorePercentileColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedScorePercentileColumns"></mat-row>
  </mat-table>
</div>
