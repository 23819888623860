import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  toggled = true;
  userIsAuthenticated = false;

  isAuthenticated$: Subscription;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this.userService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.userIsAuthenticated = isAuthenticated;
    });
  }

  onToggle(toggled: boolean) {
    this.toggled = toggled;
  }

  ngOnDestroy(): void {
    this.isAuthenticated$.unsubscribe();
  }
}
