<div class="container">
  <div class="page-list-header">
    <h1>Subscale Question Groups</h1>
    <button mat-raised-button class="new-button pull-right" color="primary" (click)="onCreate()">
      <mat-icon aria-hidden="false">add</mat-icon>
      Create a Question Group
    </button>
  </div>

  <app-psb-list
    *ngIf="questionGroups.length"
    [items]="questionGroups"
    [itemsPerPage]="10"
    (view)="onView($event)"
    (delete)="onDelete($event)"
  >
  </app-psb-list>
</div>
