<mat-form-field>
  <mat-label>Department</mat-label>
  <input
    matInput
    type="text"
    [formControl]="departmentControl"
    required
    [matAutocomplete]="answering ? autoDepartment : null"
    [readonly]="!answering"
  />
  <mat-autocomplete
    #autoDepartment="matAutocomplete"
    (optionSelected)="setDepartment($event)"
    [displayWith]="displayDepartment"
  >
    <mat-option *ngFor="let department of filteredDepartments | async" [value]="department">
      {{ department.name }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="departmentControl.errors && answering">Please, enter a valid department.</mat-error>
</mat-form-field>
