import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// layouts
import { LayoutAdminComponent } from './core/layout/layout-admin/layout-admin.component';
import { LayoutTestUserComponent } from './core/layout/layout-test-user/layout-test-user.component';

// guards
import { AuthGuard } from './core/guards/auth.guard';

// modules
import { HomeModule } from './home/home.module';
import { AdminModule } from './admin/admin.module';
import { UserModule } from './user/user.module';
import { InviteModule } from './user/invite/invite.module';
import { ConnectionsIndicatorModule } from './admin/pages/connections-indicator/connections-indicator.module';
import { SectionModule } from './admin/pages/section/section.module';
import { ChapterModule } from './admin/pages/chapter/chapter.module';
import { AreaMappingsModule } from './admin/pages/area-mappings/area-mappings.module';
import { ChartModule } from './admin/pages/chart/chart.module';
import { RoleMandateModule } from './admin/pages/role-mandate/role-mandate.module';
import { ReportModule } from './admin/pages/report/report.module';
import { ReportDashboardModule } from './admin/pages/report-dashboard/report-dashboard.module';
import { ClientsModule } from './admin/pages/clients/clients.module';
import { TestGroupModule } from './admin/pages/test-group/test-group.module';
import { StateModule } from './admin/pages/state/state.module';
import { UserAdminModule } from './admin/pages/user/user-admin.module';
import { PsyTestModule } from './admin/pages/tests/tests.module';
import { SuperTestsModule } from './admin/pages/super-tests/super-tests.module';
import { ReportWizardModule } from './admin/pages/report-wizard/report-wizard.module';
import { SubscaleModule } from './admin/pages/subscale/subscale.module';
import { ReportModelModule } from './admin/pages/report-model/report-model.module';
import { SuperscaleModule } from './admin/pages/superscale/superscale.module';
import { OptimalScoreModule } from './admin/pages/optimal-score/optimal-score.module';
import { SettingModule } from './setting/setting.module';
import { GenericLinkInviteModule } from './user/generic-link-invite/generic-link-invite.module';
import { MaintenanceModeModule } from './admin/pages/maintenance-mode/maintenance-mode.module';

// components
import { UserLoginComponent } from './user/user-login/user-login.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserRecoverPasswordComponent } from './user/user-recover-password/user-recover-password.component';
import { UserResetPasswordComponent } from './user/user-reset-password/user-reset-password.component';
import { UserRegistrationByInviteComponent } from './user/user-registration-by-invite/user-registration-by-invite.component';
import { ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TEST_USER, ROLE_TGM } from './core/constants';
import { UserRoutingModule } from '@app/user/user-routing.module';
import { LayoutFooterComponent } from '@app/core/layout/layout-footer/layout-footer.component';
import { UserReportPdfComponent } from '@app/user/user-report/user-report-pdf/user-report-pdf.component';
import { UserReportPdfWithCoverComponent } from '@app/user/user-report/user-report-pdf-with-cover/user-report-pdf-with-cover.component';

const routes: Routes = [
  {
    path: 'user/:id/test-group/:testGroupId/report/:reportId/pdf',
    component: UserReportPdfWithCoverComponent,
  },
  {
    path: '',
    component: LayoutFooterComponent,
    children: [
      { path: 'login', component: UserLoginComponent },
      {
        path: 'user',
        component: LayoutTestUserComponent,
        loadChildren: () => UserModule,
        canActivate: [AuthGuard],
      },
      { path: 'create-user', component: UserCreateComponent },
      { path: 'registration/:inviteToken', component: UserRegistrationByInviteComponent },
      { path: 'recover-password', component: UserRecoverPasswordComponent },
      { path: 'reset-password/:resetKey', component: UserResetPasswordComponent },
      // Users pages
      {
        path: 'dashboard',
        component: LayoutTestUserComponent,
        loadChildren: () => UserRoutingModule,
        canActivate: [AuthGuard],
      },
      // Admin manager pages
      {
        path: 'admin',
        component: LayoutAdminComponent,
        loadChildren: () => AdminModule,
        canActivate: [AuthGuard],
        data: { authority: [ROLE_ADMIN, ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN] },
      },
      {
        path: 'report',
        component: LayoutAdminComponent,
        loadChildren: () => ReportModule,
        canActivate: [AuthGuard],
        data: { authority: [ROLE_ADMIN, ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN] },
      },
      {
        path: 'report/wizard',
        component: LayoutAdminComponent,
        loadChildren: () => ReportWizardModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'report-model',
        component: LayoutAdminComponent,
        loadChildren: () => ReportModelModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'report-dashboard',
        component: LayoutAdminComponent,
        loadChildren: () => ReportDashboardModule,
        canActivate: [AuthGuard],
        data: { authority: [ROLE_ADMIN, ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN] },
      },
      {
        path: 'chapter',
        component: LayoutAdminComponent,
        loadChildren: () => ChapterModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'section',
        component: LayoutAdminComponent,
        loadChildren: () => SectionModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'connections-indicator',
        component: LayoutAdminComponent,
        loadChildren: () => ConnectionsIndicatorModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'role-mandate',
        component: LayoutAdminComponent,
        loadChildren: () => RoleMandateModule,
        canActivate: [AuthGuard],
        data: { authority: [ROLE_ADMIN, ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN] },
      },
      {
        path: 'clients',
        component: LayoutAdminComponent,
        loadChildren: () => ClientsModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'psy-test',
        component: LayoutAdminComponent,
        loadChildren: () => PsyTestModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'subscale',
        component: LayoutAdminComponent,
        loadChildren: () => SubscaleModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'superscale',
        component: LayoutAdminComponent,
        loadChildren: () => SuperscaleModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'super-test',
        component: LayoutAdminComponent,
        loadChildren: () => SuperTestsModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'state',
        component: LayoutAdminComponent,
        loadChildren: () => StateModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'psy-test-group',
        component: LayoutAdminComponent,
        loadChildren: () => TestGroupModule,
        canActivate: [AuthGuard],
        data: { authority: [ROLE_ADMIN, ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN] },
      },
      {
        path: 'users',
        component: LayoutAdminComponent,
        loadChildren: () => UserAdminModule,
        canActivate: [AuthGuard],
        data: { authority: [ROLE_ADMIN, ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN] },
      },
      {
        path: 'chart',
        component: LayoutAdminComponent,
        loadChildren: () => ChartModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'area-index',
        component: LayoutAdminComponent,
        loadChildren: () => AreaMappingsModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'invite',
        component: LayoutTestUserComponent,
        loadChildren: () => InviteModule,
      },
      {
        path: 'g-invite',
        component: LayoutTestUserComponent,
        loadChildren: () => GenericLinkInviteModule,
      },
      {
        path: '',
        component: LayoutTestUserComponent,
        loadChildren: () => HomeModule,
      },
      {
        path: 'optimal-curve',
        component: LayoutAdminComponent,
        loadChildren: () => OptimalScoreModule,
        canActivate: [AuthGuard],
        data: { authority: ROLE_ADMIN },
      },
      {
        path: 'setting',
        loadChildren: () => SettingModule,
        canActivate: [AuthGuard],
        data: { authority: [ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TEST_USER] },
      },
      {
        path: 'maintenance-mode',
        component: LayoutAdminComponent,
        loadChildren: () => MaintenanceModeModule,
        canActivate: [AuthGuard],
        data: { authority: [ROLE_ADMIN] },
      },
      {
        path: '**',
        component: LayoutTestUserComponent,
        loadChildren: () => HomeModule,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
