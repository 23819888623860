/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OpenDocxResponse = {
    id: number;
    createdAt: string;
    generateStatus: OpenDocxResponse.generateStatus;
    userId: number;
    testGroupId: number;
    reportId: number;
    googleDocumentId: string;
    errorMessage: string;
    link: string;
};

export namespace OpenDocxResponse {

    export enum generateStatus {
        PROCESSING = 'processing',
        ERROR = 'error',
        DONE = 'done',
    }


}

