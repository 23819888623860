<h1 mat-dialog-title>Add chart to report</h1>
<form mat-dialog-content [formGroup]="form" id="addChartForm" (submit)="onSubmit()">
  <mat-form-field>
    <mat-label>Select an available chart</mat-label>
    <mat-select formControlName="chart">
      <mat-option *ngFor="let chart of availableCharts" [value]="chart.id">
        {{ chart.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
<div align="end" mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>
    <mat-icon>block</mat-icon>
    Cancel
  </button>
  <button mat-flat-button color="primary" type="submit" form="addChartForm" [disabled]="form.invalid">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</div>
