import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { SharedModule } from '@app/shared/shared.module';
import { ReportRoutingModule } from './report-routing.module';

import { SummaryComponent } from './summary/summary.component';
import { StatsCompanyComponent } from './stats-company/stats-company.component';
import { StatsUserComponent } from './stats-user/stats-user.component';
import { StatsTestGroupComponent } from './stats-test-group/stats-test-group.component';
import { ReportDataDownloadComponent } from './report-data-download/report-data-download.component';

@NgModule({
  declarations: [
    SummaryComponent,
    StatsCompanyComponent,
    StatsUserComponent,
    StatsTestGroupComponent,
    ReportDataDownloadComponent,
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    SharedModule,
    LayoutModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
  ],
  exports: [SummaryComponent, FormsModule, ReactiveFormsModule],
})
export class ReportModule {}
