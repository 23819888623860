<mat-card>
  <div class="row">
    <div class="pull-right">
      <span class="menu-label">Centrality</span>
      <select [formControl]="centralityFormControl">
        <option value="global">Global</option>
        <option value="eigenvectorCentrality">Eigenvector</option>
        <option value="neighborhoodCentrality">Neighborhood</option>
      </select>
    </div>
  </div>

  <svg #svg width="100%" [attr.height]="options.height" *ngIf="loadedContent && nodesData.length > 0">
    <defs>
      <marker
        id="arrow-licensing"
        viewBox="0 -5 10 10"
        refX="15"
        refY="0"
        markerWidth="6"
        markerHeight="6"
        orient="auto"
      >
        <path fill="#000000" d="M0,-5L7,0L0,5"></path>
      </marker>
    </defs>
    <g [appZoomable]="svg">
      <g [linkVisual]="link" *ngFor="let link of links"></g>
      <g
        [nodeVisual]="node"
        *ngFor="let node of nodes"
        [appDraggable]
        [draggableNode]="node"
        [draggableInGraph]="graph"
      ></g>
    </g>
  </svg>
  <div *ngIf="!nodesData || nodesData.length <= 0">
    <i>Network not found.</i>
  </div>
</mat-card>
