import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

// services
import { CompanyService } from '@app/core/services/company.service';
import { TestGroupService } from '@app/core/services/test-group.service';
import { UserService } from '@app/core/services/user.service';

// models
import { Company } from '@app/core/models/company.model';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  public startDate: Date;
  public endDate: Date;

  public companies: Company[];
  public company: Company;
  public testGroups: PsyTestGroup[];
  public users: UserInfo[];

  dateForm = new FormGroup({
    start: new FormControl('', [Validators.required]),
    end: new FormControl('', [Validators.required]),
  });

  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Companies', cols: 1, rows: 1 },
          { title: 'Users', cols: 1, rows: 1 },
          { title: 'Test Groups', cols: 1, rows: 1 },
        ];
      }
      return [
        { title: 'Companies', cols: 1, rows: 1 },
        { title: 'Users', cols: 1, rows: 2 },
        { title: 'Test Groups', cols: 1, rows: 1 },
      ];
    }),
  );
  constructor(
    private breakpointObserver: BreakpointObserver,
    private companyService: CompanyService,
    private testGroupService: TestGroupService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.startDate = new Date(year, month - 1, 1);
    this.endDate = new Date(year, month, new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate());

    this.dateForm.setValue({
      start: this.startDate,
      end: this.endDate,
    });

    this.loadData(this.startDate.getTime(), this.endDate.getTime());
  }

  onChangeDate() {
    this.loadData(this.startDate.getTime(), this.endDate.getTime());
  }

  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type === 'start') {
      this.startDate = event.value;
    } else if (type === 'end') {
      this.endDate = event.value;
    }
  }

  onSelectCompany(selectedCompanyId) {
    forkJoin([
      this.testGroupService.getAllByCompanyIdCreateAt(
        selectedCompanyId,
        this.startDate.getTime(),
        this.endDate.getTime(),
      ),
      this.userService.getAllByCompanyIdCreatedAt(selectedCompanyId, this.startDate.getTime(), this.endDate.getTime()),
    ]).subscribe((results) => {
      this.testGroups = results[0];

      this.users = results[1];
    });

    this.testGroups = this.testGroups.filter((testGroup) => testGroup.companyId === selectedCompanyId);
    this.users = this.users.filter((testGroup) => testGroup.companyId === selectedCompanyId);
  }

  loadData(start, end) {
    forkJoin([
      this.companyService.getAllCreateAt(start, end),
      this.testGroupService.getAllCreateAt(start, end),
      this.userService.getAllCreatedAt(start, end),
    ]).subscribe((results) => {
      this.companies = results[0];

      this.testGroups = results[1];

      this.users = results[2];
    });
  }
}
