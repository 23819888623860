<h1>Notifications Channel</h1>
<p>Add a Slack channel that will receive the notification about the users progress.</p>
<mat-divider></mat-divider>
<div *ngIf="!hasNotification && !newNotificationChannel">
  <button mat-raised-button class="new-button pull-right" color="primary" (click)="addChannel()">
    <mat-icon aria-hidden="false">add</mat-icon>Add Channel
  </button>
</div>
<div *ngIf="hasNotification && !newNotificationChannel && testNoficationList.length > 0">
  <mat-list *ngFor="let notification of testNoficationList">
    <mat-list-item>
      {{ notification.chanel }}
      <button mat-raised-button class="new-button pull-right" color="warn" (click)="removeChannel(notification.id)">
        Remove Channel
      </button></mat-list-item
    >
  </mat-list>
</div>
<div *ngIf="newNotificationChannel && notificationPsbItems.length > 0">
  <button mat-raised-button class="new-button pull-right" color="primary" (click)="createNewChannel()">
    <mat-icon aria-hidden="false">add</mat-icon>Create new Channel
  </button>
  <app-psb-list
    [items]="notificationPsbItems"
    [itemsPerPage]="10"
    (view)="onView($event)"
    (edit)="onEdit($event)"
    (delete)="onDelete($event)"
  >
  </app-psb-list>
</div>
