<button mat-raised-button color="primary" class="add-area-button" (click)="onAddArea()">
  <mat-icon>add</mat-icon>
  Add new area
</button>

<div class="area-chart-container d-flex" [ngSwitch]="areaIndexBase">
  <div class="chart-Contain">
    <app-area-circle-plot
      *ngSwitchCase="'CIRCLE_IN_CARTESIAN'"
      [selectedAreaNumber]="selectedAreaNumber"
    ></app-area-circle-plot>
    <app-area-hexagon *ngSwitchCase="'HEXAGON'" [selectedAreaNumber]="selectedAreaNumber"></app-area-hexagon>
    <app-area-four-one-cartesian
      *ngSwitchCase="'FOUR_PLUS_ONE_CARTESIAN'"
      [selectedAreaNumber]="selectedAreaNumber"
    ></app-area-four-one-cartesian>
  </div>

  <div class="chart-Contain-right">
    <table mat-table [dataSource]="areasDataSource" matSort>
      <ng-container matColumnDef="num">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Num</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onAreaClick(element.num)">
          {{ element.num }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="he">
        <mat-header-cell *matHeaderCellDef>He</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onAreaClick(element.num)">
          {{ element.he }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="she">
        <mat-header-cell *matHeaderCellDef>She</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onAreaClick(element.num)">
          {{ element.she }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="they">
        <mat-header-cell *matHeaderCellDef>They</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onAreaClick(element.num)">
          {{ element.they }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let element" class="action-buttons">
          <button mat-flat-button color="primary" (click)="onEdit(element)">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-flat-button color="accent" (click)="onRemove(element.id)">
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row mat-header-row *matHeaderRowDef="displayedAreaColumns"></mat-header-row>
      <mat-row mat-row *matRowDef="let row; columns: displayedAreaColumns"></mat-row>
    </table>
  </div>
</div>
