import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// models
import { PsyTestGroupTestScale } from '@app/core/models/psy-test-group-test-scale.model';

// services
import { TestGroupService } from '@app/core/services/test-group.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-test-group-preferred-range-superscale',
  templateUrl: './test-group-preferred-range-superscale.component.html',
  styleUrls: ['./test-group-preferred-range-superscale.component.scss'],
})
export class TestGroupPreferredRangeSuperscaleComponent implements OnInit {
  testGroupId: number;
  testGroupName: string;
  form: FormGroup;
  textForRangeTitle: string;
  addTextDialogRef: MatDialogRef<any>;
  addTextEditorControl = new FormControl('', [Validators.required]);

  editorConfig: AngularEditorConfig = {
    height: '10rem',
    editable: true,
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private testGroupServive: TestGroupService,
    private snackBar: SnackBarService,
  ) {
    this.testGroupName = this.router.getCurrentNavigation().extras.state.testGroupTitle;
    this.form = this.fb.group({
      testGroupSuperscales: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.testGroupId = Number(this.route.snapshot.params.id);
    this.getFormData(this.testGroupId);
  }

  getFormData(testGroupId: number) {
    this.testGroupServive.getTestGroupSuperscaleRanges(testGroupId).subscribe((testGroupSuperscales) => {
      if (testGroupSuperscales && testGroupSuperscales.length > 0) {
        this.loadFormData(testGroupSuperscales);
      }
    });
  }

  private loadFormData(testGroupSuperscales: PsyTestGroupTestScale[]): void {
    testGroupSuperscales.map((testGroupSuperscale) => {
      this.addTestGroupSuperscale(testGroupSuperscale);
    });
  }

  createOutOfRangeLowFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      outOfRangeLowFrom: [
        range && !isNaN(range.outOfRangeLowFrom) ? range.outOfRangeLowFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      outOfRangeLowTo: [
        range && !isNaN(range.outOfRangeLowTo) ? range.outOfRangeLowTo : null,
        [Validators.required, Validators.min(0)],
      ],
      outOfRangeLowText: [range && range.outOfRangeLowText ? range.outOfRangeLowText : '', [Validators.required]],
    });
  }

  createCautionaryLowFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      cautionaryLowFrom: [
        range && !isNaN(range.cautionaryLowFrom) ? range.cautionaryLowFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      cautionaryLowTo: [
        range && !isNaN(range.cautionaryLowTo) ? range.cautionaryLowTo : null,
        [Validators.required, Validators.min(0)],
      ],
      cautionaryLowText: [range && range.cautionaryLowText ? range.cautionaryLowText : '', [Validators.required]],
    });
  }

  createOptimalFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      optimalFrom: [
        range && !isNaN(range.optimalFrom) ? range.optimalFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      optimalTo: [range && !isNaN(range.optimalTo) ? range.optimalTo : null, [Validators.required, Validators.min(0)]],
      optimalText: [range && range.optimalText ? range.optimalText : '', [Validators.required]],
    });
  }

  createOutOfRangeHighFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      outOfRangeHighFrom: [
        range && !isNaN(range.outOfRangeHighFrom) ? range.outOfRangeHighFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      outOfRangeHighTo: [
        range && !isNaN(range.outOfRangeHighTo) ? range.outOfRangeHighTo : null,
        [Validators.required, Validators.min(0)],
      ],
      outOfRangeHighText: [range && range.outOfRangeHighText ? range.outOfRangeHighText : '', [Validators.required]],
    });
  }

  createCautionaryHighFormGroup(range?: PsyTestGroupTestScale) {
    return this.fb.group({
      cautionaryHighFrom: [
        range && !isNaN(range.cautionaryHighFrom) ? range.cautionaryHighFrom : null,
        [Validators.required, Validators.min(0)],
      ],
      cautionaryHighTo: [
        range && !isNaN(range.cautionaryHighTo) ? range.cautionaryHighTo : null,
        [Validators.required, Validators.min(0)],
      ],
      cautionaryHighText: [range && range.cautionaryHighText ? range.cautionaryHighText : '', [Validators.required]],
    });
  }

  createTestGroupSuperscaleFormGroup(superscale?: PsyTestGroupTestScale) {
    return this.fb.group({
      id: [superscale && superscale.id ? superscale.id : null, [Validators.required]],
      superscale: [superscale && superscale.title ? superscale.title : null, [Validators.required]],
      superscaleId: [superscale && superscale.superscaleId ? superscale.superscaleId : null],
      testGroupSuperscaleId: [
        superscale && superscale.psyTestGroupSuperscaleId ? superscale.psyTestGroupSuperscaleId : null,
      ],
      ranges: this.fb.group({
        outOfRangeLow: this.createOutOfRangeLowFormGroup(
          superscale &&
            (!isNaN(superscale.outOfRangeLowFrom) || !isNaN(superscale.outOfRangeLowTo) || superscale.outOfRangeLowText)
            ? superscale
            : null,
        ),
        cautionaryLow: this.createCautionaryLowFormGroup(
          superscale &&
            (!isNaN(superscale.cautionaryLowFrom) || !isNaN(superscale.cautionaryLowTo) || superscale.cautionaryLowText)
            ? superscale
            : null,
        ),
        optimal: this.createOptimalFormGroup(
          superscale && (!isNaN(superscale.optimalFrom) || !isNaN(superscale.optimalTo) || superscale.optimalText)
            ? superscale
            : null,
        ),
        outOfRangeHigh: this.createOutOfRangeHighFormGroup(
          superscale &&
            (!isNaN(superscale.outOfRangeHighFrom) ||
              !isNaN(superscale.outOfRangeHighTo) ||
              superscale.outOfRangeHighText)
            ? superscale
            : null,
        ),
        cautionaryHigh: this.createCautionaryHighFormGroup(
          superscale &&
            (!isNaN(superscale.cautionaryHighFrom) ||
              !isNaN(superscale.cautionaryHighTo) ||
              superscale.cautionaryHighText)
            ? superscale
            : null,
        ),
      }),
    });
  }

  get testGroupSuperscalesFormArray() {
    return this.form.get('testGroupSuperscales') as FormArray;
  }

  resetTestGroupSuperscalesFormArray() {
    (<FormArray>this.form.get('testGroupSuperscales')).clear();
  }

  addTestGroupSuperscale(testGroupSuperscale?: any) {
    this.testGroupSuperscalesFormArray.push(this.createTestGroupSuperscaleFormGroup(testGroupSuperscale));
  }

  openAddTextDialog(ref: TemplateRef<any>, textControl: AbstractControl, title: string) {
    this.addTextEditorControl.setValue(textControl.value);
    this.textForRangeTitle = title;
    this.addTextDialogRef = this.dialog.open(ref);
    this.addTextDialogRef.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_SUCCESS) {
        return;
      }
      textControl.setValue(this.addTextEditorControl.value);
      this.addTextEditorControl.setValue('');
    });
  }

  removeTextDialog(textControl: AbstractControl, title: string) {
    this.addTextEditorControl.setValue('');
    this.textForRangeTitle = title;

    textControl.setValue(this.addTextEditorControl.value);
  }

  closeAddTextDialog() {
    this.addTextDialogRef.close();
  }

  saveAddTextDialog() {
    this.convertNumericalCodeToUmlautAndSpecialChar();
    this.addTextDialogRef.close({ event: EVENT_SUCCESS });
  }

  convertNumericalCodeToUmlautAndSpecialChar() {
    let newValue = this.addTextEditorControl.value;

    if (this.addTextEditorControl.value.indexOf('&#160;') > -1) {
      newValue = newValue.replace(/&#160;/g, '');
      this.addTextEditorControl.setValue(newValue);
    }

    if (this.addTextEditorControl.value.indexOf('&#192;') > -1) {
      newValue = newValue.replace(/&#192;/g, 'À');
    }

    if (this.addTextEditorControl.value.indexOf('&#224;') > -1) {
      newValue = newValue.replace(/&#224;/g, 'à');
    }

    if (this.addTextEditorControl.value.indexOf('&#194;') > -1) {
      newValue = newValue.replace(/&#194;/g, 'Â');
    }

    if (this.addTextEditorControl.value.indexOf('&#226;') > -1) {
      newValue = newValue.replace(/&#194;/g, 'Â');
    }

    if (this.addTextEditorControl.value.indexOf('&#198;') > -1) {
      newValue = newValue.replace(/&#198;/g, 'Æ');
    }

    if (this.addTextEditorControl.value.indexOf('&#230;') > -1) {
      newValue = newValue.replace(/&#230;/g, 'æ');
    }

    if (this.addTextEditorControl.value.indexOf('&#199;') > -1) {
      newValue = newValue.replace(/&#199;/g, 'Ç');
    }

    if (this.addTextEditorControl.value.indexOf('&#231;') > -1) {
      newValue = newValue.replace(/&#231;/g, 'ç');
    }

    if (this.addTextEditorControl.value.indexOf('&#200;') > -1) {
      newValue = newValue.replace(/&#200;/g, 'È');
    }

    if (this.addTextEditorControl.value.indexOf('&#232;') > -1) {
      newValue = newValue.replace(/&#232;/g, 'è');
    }

    if (this.addTextEditorControl.value.indexOf('&#201;') > -1) {
      newValue = newValue.replace(/&#201;/g, 'É');
    }

    if (this.addTextEditorControl.value.indexOf('&#233;') > -1) {
      newValue = newValue.replace(/&#233;/g, 'é');
    }

    if (this.addTextEditorControl.value.indexOf('&#202;') > -1) {
      newValue = newValue.replace(/&#202;/g, 'Ê');
    }

    if (this.addTextEditorControl.value.indexOf('&#234;') > -1) {
      newValue = newValue.replace(/&#234;/g, 'ê');
    }

    if (this.addTextEditorControl.value.indexOf('&#203;') > -1) {
      newValue = newValue.replace(/&#203;/g, 'Ë');
    }

    if (this.addTextEditorControl.value.indexOf('&#235;') > -1) {
      newValue = newValue.replace(/&#235;/g, 'ë');
    }

    if (this.addTextEditorControl.value.indexOf('&#206;') > -1) {
      newValue = newValue.replace(/&#206;/g, 'Î');
    }

    if (this.addTextEditorControl.value.indexOf('&#238;') > -1) {
      newValue = newValue.replace(/&#238;/g, 'î');
    }

    if (this.addTextEditorControl.value.indexOf('&#207;') > -1) {
      newValue = newValue.replace(/&#207;/g, 'Ï');
    }

    if (this.addTextEditorControl.value.indexOf('&#239;') > -1) {
      newValue = newValue.replace(/&#239;/g, 'ï');
    }

    if (this.addTextEditorControl.value.indexOf('&#212;') > -1) {
      newValue = newValue.replace(/&#212;/g, 'Ô');
    }

    if (this.addTextEditorControl.value.indexOf('&#244;') > -1) {
      newValue = newValue.replace(/&#244;/g, 'ô');
    }

    if (this.addTextEditorControl.value.indexOf('&#140;') > -1) {
      newValue = newValue.replace(/&#140;/g, 'Œ');
    }

    if (this.addTextEditorControl.value.indexOf('&#156;') > -1) {
      newValue = newValue.replace(/&#156;/g, 'œ');
    }

    if (this.addTextEditorControl.value.indexOf('&#217;') > -1) {
      newValue = newValue.replace(/&#217;/g, 'Ù');
    }

    if (this.addTextEditorControl.value.indexOf('&#249;') > -1) {
      newValue = newValue.replace(/&#249;/g, 'ù');
    }

    if (this.addTextEditorControl.value.indexOf('&#219;') > -1) {
      newValue = newValue.replace(/&#219;/g, 'Û');
    }

    if (this.addTextEditorControl.value.indexOf('&#251;') > -1) {
      newValue = newValue.replace(/&#251;/g, 'û');
    }

    if (this.addTextEditorControl.value.indexOf('&#220;') > -1) {
      newValue = newValue.replace(/&#220;/g, 'Ü');
    }

    if (this.addTextEditorControl.value.indexOf('&#252;') > -1) {
      newValue = newValue.replace(/&#252;/g, 'ü');
    }

    if (this.addTextEditorControl.value.indexOf('&#171;') > -1) {
      newValue = newValue.replace(/&#171;/g, '«');
    }

    if (this.addTextEditorControl.value.indexOf('&#187;') > -1) {
      newValue = newValue.replace(/&#187;/g, '»');
    }

    if (this.addTextEditorControl.value.indexOf('&#128;') > -1) {
      newValue = newValue.replace(/&#128;/g, '€');
    }

    if (this.addTextEditorControl.value.indexOf('&#8355;') > -1) {
      newValue = newValue.replace(/&#8355;/g, '₣');
    }

    this.addTextEditorControl.setValue(newValue);
  }

  onSave() {
    const testGroupSuperscales = this.checkEmptyTestGroupSuperscale();
    this.testGroupServive.saveTestGroupSuperscaleRanges(this.testGroupId, testGroupSuperscales).subscribe((res) => {
      this.resetTestGroupSuperscalesFormArray();
      this.getFormData(this.testGroupId);
      this.snackBar.info('Preferred ranges are saved successfully');
    });
  }

  checkEmptyTestGroupSuperscale() {
    const { value } = this.form;
    let emptySuperscaleRange = true;
    let testGroupSuperscalesUpdated = [];
    value.testGroupSuperscales.forEach((testGroupSuperscale) => {
      if (testGroupSuperscale.id !== null || testGroupSuperscale.testGroupSuperscaleId !== null) {
        emptySuperscaleRange = false;

        if (!emptySuperscaleRange) {
          const newTestGroupSuperscale = this.getTestGroupSuperscale(testGroupSuperscale);
          testGroupSuperscalesUpdated.push(newTestGroupSuperscale);
        }
      } else {
        emptySuperscaleRange =
          this.checkRangeEmpty(
            testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowFrom,
            testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowTo,
            testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowText,
          ) &&
          this.checkRangeEmpty(
            testGroupSuperscale.ranges.cautionaryLow.cautionaryLowFrom,
            testGroupSuperscale.ranges.cautionaryLow.cautionaryLowTo,
            testGroupSuperscale.ranges.cautionaryLow.cautionaryLowText,
          ) &&
          this.checkRangeEmpty(
            testGroupSuperscale.ranges.optimal.optimalFrom,
            testGroupSuperscale.ranges.optimal.optimalTo,
            testGroupSuperscale.ranges.optimal.optimalText,
          ) &&
          this.checkRangeEmpty(
            testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighFrom,
            testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighTo,
            testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighText,
          ) &&
          this.checkRangeEmpty(
            testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighFrom,
            testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighTo,
            testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighText,
          );
        if (!emptySuperscaleRange) {
          const newTestGroupSuperscale = this.getTestGroupSuperscale(testGroupSuperscale);
          testGroupSuperscalesUpdated.push(newTestGroupSuperscale);
        }
      }
    });

    return testGroupSuperscalesUpdated;
  }

  checkRangeEmpty(from: any, to: any, text: any) {
    return from === null && to === null && text === '' ? true : false;
  }

  getTestGroupSuperscale(testGroupSuperscale: any) {
    const superscaleRange: PsyTestGroupTestScale = {
      id: testGroupSuperscale.id ? testGroupSuperscale.id : null,
      testScaleId: testGroupSuperscale.testScaleId ? testGroupSuperscale.testScaleId : null,
      testGroupTestId: testGroupSuperscale.testGroupTestId ? testGroupSuperscale.testGroupTestId : null,
      psyTestGroupSuperscaleId: testGroupSuperscale.testGroupSuperscaleId
        ? testGroupSuperscale.testGroupSuperscaleId
        : null,
      superscaleId: testGroupSuperscale.superscaleId ? testGroupSuperscale.superscaleId : null,
      predefinedPreferredRangesId: testGroupSuperscale.predefinedPreferredRangesId
        ? testGroupSuperscale.predefinedPreferredRangesId
        : null,
      cautionaryHighFrom: testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighFrom
        ? testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighFrom
        : testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighTo > 0
        ? 1
        : null,
      cautionaryHighTo:
        testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighTo != null
          ? testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighTo
          : null,
      cautionaryLowFrom: testGroupSuperscale.ranges.cautionaryLow.cautionaryLowFrom
        ? testGroupSuperscale.ranges.cautionaryLow.cautionaryLowFrom
        : testGroupSuperscale.ranges.cautionaryLow.cautionaryLowTo > 0
        ? 1
        : null,
      cautionaryLowTo:
        testGroupSuperscale.ranges.cautionaryLow.cautionaryLowTo != null
          ? testGroupSuperscale.ranges.cautionaryLow.cautionaryLowTo
          : null,
      optimalFrom: testGroupSuperscale.ranges.optimal.optimalFrom
        ? testGroupSuperscale.ranges.optimal.optimalFrom
        : testGroupSuperscale.ranges.optimal.optimalTo > 0
        ? 1
        : null,
      optimalTo:
        testGroupSuperscale.ranges.optimal.optimalTo != null ? testGroupSuperscale.ranges.optimal.optimalTo : null,
      outOfRangeHighFrom: testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighFrom
        ? testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighFrom
        : testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighTo > 0
        ? 1
        : null,
      outOfRangeHighTo:
        testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighTo != null
          ? testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighTo
          : null,
      outOfRangeLowFrom:
        testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowFrom != null
          ? testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowFrom
          : testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowTo > 0
          ? 1
          : null,
      outOfRangeLowTo:
        testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowTo != null
          ? testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowTo
          : null,
      cautionaryHighText: testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighText
        ? testGroupSuperscale.ranges.cautionaryHigh.cautionaryHighText
        : '',
      cautionaryLowText: testGroupSuperscale.ranges.cautionaryLow.cautionaryLowText
        ? testGroupSuperscale.ranges.cautionaryLow.cautionaryLowText
        : '',
      optimalText: testGroupSuperscale.ranges.optimal.optimalText ? testGroupSuperscale.ranges.optimal.optimalText : '',
      outOfRangeHighText: testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighText
        ? testGroupSuperscale.ranges.outOfRangeHigh.outOfRangeHighText
        : '',
      outOfRangeLowText: testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowText
        ? testGroupSuperscale.ranges.outOfRangeLow.outOfRangeLowText
        : '',
    };

    return superscaleRange;
  }
}
