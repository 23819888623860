<h1 mat-dialog-title>Add Role Navigator</h1>
<div mat-dialog-content class="dialog-panel">
  <mat-form-field>
    <mat-label>Role Navigator</mat-label>
    <input
      matInput
      type="text"
      [formControl]="roleNavigatorControl"
      [matAutocomplete]="autoRoleNavigator" />
    <mat-autocomplete
      #autoRoleNavigator="matAutocomplete"
      [displayWith]="displayRoleNavigator"
    >
    <mat-option *ngFor="let roleNavigator of filteredRoleNavigators | async" [value]="roleNavigator">
      {{ displayRoleNavigator(roleNavigator) }}
    </mat-option>
  </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions class="add-role-navigator-buttons">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="onAddRoleNavigator()">
    <mat-icon>add</mat-icon>
    Add
  </button>
</div>
