<mat-card>
  <form [formGroup]="newDecisionAreaForm" #formDirective="ngForm" (ngSubmit)="newDecisionArea(formDirective)">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" />
      <mat-error *ngIf="newDecisionAreaForm.get('name').errors">Please, enter a valid text.</mat-error>
      <mat-hint>Name of the Decision Area</mat-hint>
    </mat-form-field>
    <div class="edit-buttons-container text-center">
      <button mat-button type="submit" class="btn-success btn-300">Create Decision Area</button>
      <button type="button" mat-button class="btn-success btn-300" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</mat-card>
