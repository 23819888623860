<mat-card>
  <mat-card-header>
    <mat-card-subtitle>
      {{ textAreaAnalysis.question.text }}
    </mat-card-subtitle>
    <mat-chip-list aria-label="question selection">
      <mat-chip color="accent">Text</mat-chip>
      <mat-chip color="primary" *ngIf="textAreaAnalysis.subscaleName">{{ textAreaAnalysis.subscaleName }}</mat-chip>
    </mat-chip-list>
  </mat-card-header>
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex.John" #input />
  </mat-form-field>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z3">
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let element">{{ element.user }}</td>
      </ng-container>

      <ng-container matColumnDef="answer">
        <th mat-header-cell *matHeaderCellDef>Answer</th>
        <td mat-cell *matCellDef="let element">{{ element.answer }}</td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>Notes</th>
        <td mat-cell *matCellDef="let element" (click)="onAddOrUpdateNotes(element)">{{ element.note }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" #paginator></mat-paginator>
  </mat-card-content>
</mat-card>
