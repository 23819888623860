/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Chart } from '../models/Chart';
import type { TestGroup } from '../models/TestGroup';
import type { TestGroupChartComparison } from '../models/TestGroupChartComparison';
import type { User } from '../models/User';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class TestGroupService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all test group by id
     * Retrieve all test group by id
     * @param id Id of the company
     * @returns TestGroup A list of test groups
     * @throws ApiError
     */
    public getCompanyTestGroup(
        id: number,
    ): Observable<TestGroup> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/test-group',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of test groups not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Download all user by company id
     * Download all user by company id
     * @param id Id of the company
     * @returns User A list of test groups
     * @throws ApiError
     */
    public getCompanyUsersDownload(
        id: number,
    ): Observable<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/users-download',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of users not download`,
                500: `Internal error`,
            },
        });
    }

    /**
     * @param testGroupId
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public postTestGroupSortTests(
        testGroupId: number,
        requestBody?: Array<number>,
    ): Observable<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{testGroupId}/sort-tests',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve a list with charts by test group id
     * Retrieve a list with charts by test group id
     * @param id Id of the chart
     * @param testGroupId Id of the test group
     * @returns Chart A list of charts
     * @throws ApiError
     */
    public getChartTestGroupResults(
        id: number,
        testGroupId: number,
    ): Observable<Chart> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{id}/test-group/{testGroupId}/results',
            path: {
                'id': id,
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with charts by test group id
     * Retrieve a list with charts by test group id
     * @param id Id of the chart
     * @param testGroupId Id of the test group
     * @returns Chart A list of charts
     * @throws ApiError
     */
    public getChartCompanyResults(
        id: number,
        testGroupId: number,
    ): Observable<Chart> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{id}/company/{companyId}/results',
            path: {
                'id': id,
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all charts by test group id
     * Retrieve a list with all charts by test group id
     * @param testGroupId Id of the test group
     * @returns Chart A list of charts
     * @throws ApiError
     */
    public getChartTestGroup(
        testGroupId: number,
    ): Observable<Array<Chart>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/test-group/{testGroupId}',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve user's report
     * Retrieve user's report
     * @param id Id of the report
     * @param userId Id of the user
     * @param testGroupId Id of the test group
     * @returns any User's report
     * @throws ApiError
     */
    public getReportUserTestGroup(
        id: number,
        userId: number,
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/{id}/user/{userId}/test-group/{testGroupId}',
            path: {
                'id': id,
                'userId': userId,
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve user's test results
     * Retrieve user's report
     * @param userId Id of the user Info
     * @param testGroupId Id of the test group
     * @returns any User's test results
     * @throws ApiError
     */
    public getReportUserTestGroupTestResults(
        userId: number,
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/user/{userId}/test-group/{testGroupId}/test-results',
            path: {
                'userId': userId,
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Test results not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Returns a list with TestGroupChartComparison by testGroupId and chartId
     * Returns a list with TestGroupChartComparison by the given testGroupId and chartId
     * @param testGroupId Id of the TestGroup
     * @param chartId Id of the Chart
     * @returns TestGroupChartComparison A list with TestGroupChartComparison
     * @throws ApiError
     */
    public getReportTestGroupChartComparisonTestGroupChart(
        testGroupId: number,
        chartId: number,
    ): Observable<TestGroupChartComparison> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/test-group-chart-comparison/test-group/{testGroupId}/chart/{chartId}',
            path: {
                'testGroupId': testGroupId,
                'chartId': chartId,
            },
            errors: {
                404: `TestGroupChartComparison not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Updates a TestGroupChartComparison
     * Updates a TestGroupChartComparison
     * @param testGroupId Id of the TestGroup
     * @param chartId Id of the Chart
     * @param hided Hide or not
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchReportTestGroupComparison(
        testGroupId: number,
        chartId: number,
        hided: boolean,
        requestBody?: {
            /**
             * Id of the TestGroup
             */
            testGroupId: number;
            /**
             * Id of the Chart
             */
            chartId: number;
            /**
             * Id of the UserInfo
             */
            userInfoId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/test-group-comparison',
            path: {
                'testGroupId': testGroupId,
                'chartId': chartId,
                'hided': hided,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `TestGroupChartComparison not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve one test group custom ranges
     * Retrieve custom ranges
     * @param testGroupId Id of the test group
     * @returns any One test group custom ranges
     * @throws ApiError
     */
    public getReportTestGroupCustomRange(
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/test-group/{testGroupId}/custom-range',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Custom ranges not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Create one test group custom ranges
     * Create custom ranges
     * @param testGroupId Id of the test group
     * @returns any One test group custom ranges
     * @throws ApiError
     */
    public postReportTestGroupCustomRange(
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/test-group/{testGroupId}/custom-range',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Custom ranges not created`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update one test group custom ranges
     * Update custom ranges
     * @param testGroupId Id of the test group
     * @returns any One test group custom ranges
     * @throws ApiError
     */
    public patchReportTestGroupCustomRange(
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/test-group/{testGroupId}/custom-range',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Custom ranges not updated`,
                500: `Internal error`,
            },
        });
    }

}
