<mat-card>
  <div id="sign-up" *ngIf="!created && !testTakersLimitReached && isValidToken">
    <h1>Finish your registration</h1>
    <form
      [formGroup]="form"
      (submit)="onSubmit()"
      *ngIf="!created && isValidToken && !testTakersLimitReached"
      autocomplete="off"
    >
      <mat-form-field>
        <mat-label>First Name *</mat-label>
        <input matInput type="text" formControlName="firstName" />
        <mat-error *ngIf="form.get('firstName').invalid">Please enter the first name.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last Name *</mat-label>
        <input matInput type="text" formControlName="lastName" />
        <mat-error *ngIf="form.get('lastName').invalid">Please enter the last name.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>E-mail *</mat-label>
        <input matInput type="text" formControlName="email" />
        <mat-error *ngIf="form.get('email').invalid">Please enter an e-mail.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Gender *</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="FEMALE">Female</mat-option>
          <mat-option value="MALE">Male</mat-option>
          <mat-option value="OTHER">Other</mat-option>
          <mat-option value="PREFER_NOT_TO_SAY">Prefer not to say</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('gender').invalid">Please choose a gender.</mat-error>
      </mat-form-field>
      <!--<mat-form-field>
      <mat-label>Country</mat-label>
      <input matInput type="text" formControlName="country" [matAutocomplete]="autoCountry" />
      <mat-autocomplete
        #autoCountry="matAutocomplete"
        (optionSelected)="onSelectCountry($event.option.value.id)"
        [displayWith]="displayCountry"
      >
        <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
          {{ country.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('country').invalid">Please select a valid country.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>State</mat-label>
      <input matInput type="text" formControlName="state" [matAutocomplete]="autoState" />
      <mat-autocomplete #autoState="matAutocomplete" [displayWith]="displayState">
        <mat-option *ngFor="let state of filteredStates | async" [value]="state">
          {{ state.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('state').invalid">Please select a valid state.</mat-error>
    </mat-form-field>-->
      <mat-form-field>
        <mat-label>Phone *</mat-label>
        <input matInput type="tel" formControlName="phone" />
        <mat-error *ngIf="form.get('phone').invalid">Please enter a phone.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Username *</mat-label>
        <input matInput type="text" formControlName="username" (focusout)="checkExistedUsername()" />
        <mat-error *ngIf="form.get('username').invalid && !form.get('username').hasError('isUsernameExist')"
          >Please enter a valid username.</mat-error
        >
        <mat-error *ngIf="form.get('username').hasError('isUsernameExist')">
          This username has already been registered.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
        <input
          matInput
          #password
          [type]="toggle.type"
          required
          formControlName="password"
          placeholder="Password"
          (focus)="focusInPassword()"
          (focusout)="focusOutPassword()"
        />
        <mat-hint align="end" aria-live="polite"> {{ password.value.length }} / 25</mat-hint>
        <mat-error *ngIf="form.get('password').invalid">Please enter a valid password.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="password"
          #passwordConfirmation
          formControlName="passwordConfirmation"
          placeholder="Password Confirmation *"
          [ngClass]="{ 'is-invalid': submitted && formControls.passwordConfirmation.errors }"
        />
        <mat-error *ngIf="form.get('passwordConfirmation').invalid">Please enter the same password.</mat-error>
        <div *ngIf="submitted && formControls.passwordConfirmation.errors" class="invalid-feedback">
          <div *ngIf="formControls.passwordConfirmation.errors.required">Confirm Password is required</div>
          <div *ngIf="formControls.passwordConfirmation.errors.mustMatch">Passwords must match</div>
        </div>
      </mat-form-field>
      <!--<mat-form-field>
      <mat-label>Language</mat-label>
      <input matInput type="text" formControlName="language" [matAutocomplete]="autoLanguage" />
      <mat-autocomplete #autoLanguage="matAutocomplete" [displayWith]="displayLanguage">
        <mat-option *ngFor="let language of filteredLanguages | async" [value]="language">
          {{ language.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('language').invalid">Please select a valid language.</mat-error>
    </mat-form-field>-->
      <!--<mat-form-field>
      <mat-label>Time Zone</mat-label>
      <input matInput type="text" formControlName="timeZone" [matAutocomplete]="autoTimeZone" />
      <mat-autocomplete #autoTimeZone="matAutocomplete" [displayWith]="displayTimeZone">
        <mat-option *ngFor="let timeZone of filteredTimeZones | async" [value]="timeZone">
          {{ timeZone.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('timeZone').invalid">Please select a valid time zone.</mat-error>
    </mat-form-field>-->
      <mat-form-field *ngIf="departments && departments.length > 0">
        <mat-label>Department</mat-label>
        <mat-select formControlName="department">
          <mat-option *ngFor="let department of departments" value="{{ department.id }}">{{
            department.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button type="submit" class="btn-primary btn-center" [disabled]="form.invalid">
        Create Account
      </button>
      <div class="strength-panel" [ngClass]="{ 'hide-password-info': !showWarningPanel }">
        <mat-password-strength
          (onStrengthChanged)="onStrengthChanged($event)"
          #passwordComponent
          [password]="password.value"
        >
        </mat-password-strength>
        <mat-password-strength-info #passwordInfo [passwordComponent]="passwordComponent"> </mat-password-strength-info>
      </div>
    </form>
  </div>
  <div id="success" *ngIf="created" class="text-center">
    <h2>Congratulations! User created successfully!</h2>
    <h3>You can access the system with your credentials <a routerLink="/login">here</a>.</h3>
  </div>
  <div id="test-takers-limit-reached" *ngIf="!isValidToken && testTakersLimitReached" class="text-center">
    <h1>Something went wrong</h1>
    <h2>The company has exceeded its number of users. Please contact the person who provided this link.</h2>
  </div>
</mat-card>
