import { Component, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

// services
import { TestService } from '@app/core/services/test.service';
import { TestObjectService } from '@app/core/services/test-object.service';
import { QuestionGroupService } from '@app/core/services/question-group.service';

// models
import { QuestionGroup } from '@app/core/models/question-group.model';
import { Question } from '@app/core/models/question.model';
import { PsyTest } from '@app/core/models/psy-test.model';
import { SuperTest } from '@app/core/models/supertest.model';
import { Answer } from '@app/core/models/answer.model';
import { CustomQuestionOrder } from '@app/core/models/custom-question-order.model';
import { IntegerAnswer } from '@app/core/models/integer-answer.model';
import { QuestionService } from '@app/core/services/question.service';

@Component({
  selector: 'app-test-view',
  templateUrl: './test-view.component.html',
  styleUrls: ['./test-view.component.scss'],
})
export class TestViewComponent implements OnInit, AfterContentChecked {
  userInfoId: number;
  psyTestId: number;
  superTestId: number;
  testResultId: number;
  superTestResultId: number;
  config: any;
  allObj: any[];
  psyTest: PsyTest;
  superTest: SuperTest;
  questionGroups: QuestionGroup[];
  allQuestionGroups: QuestionGroup[];
  questions: Question[];
  questionNumber = 1;
  p = 1;
  userCardSortAnswers: Answer[];
  finishGettingCardSortAnswers: boolean;
  customQuestionsOrder: CustomQuestionOrder[];
  integerAnswers: IntegerAnswer[];

  constructor(
    private route: ActivatedRoute,
    private testService: TestService,
    private questionGroupService: QuestionGroupService,
    private viewportScroller: ViewportScroller,
    private cdref: ChangeDetectorRef,
    public testObjectService: TestObjectService,
    private questionService: QuestionService,
  ) {}

  ngOnInit(): void {
    this.userInfoId = Number(this.route.snapshot.params['userInfoId']);
    this.psyTestId = Number(this.route.snapshot.params['psyTestId']);
    this.superTestId = Number(this.route.snapshot.params['superTestId']);
    this.testResultId = Number(this.route.snapshot.params['testResultId']);
    this.superTestResultId = Number(this.route.snapshot.params['superTestResultId']);
    this.questionGroupService.getAll().subscribe((questionGroups) => (this.allQuestionGroups = questionGroups));

    if (this.psyTestId) {
      forkJoin([
        this.testService.getPsyTestById(this.psyTestId),
        this.questionGroupService.getAllQuestionGroupForPsyTest(this.psyTestId),
        this.testService.getAllQuestionByPsyTestId(this.psyTestId, this.userInfoId),
        this.testService.getCustomQuestionsOrderById(this.psyTestId),
      ]).subscribe((results) => {
        this.getUserCardSortAnswers();
        this.getUserIntegerAnswers();
        this.psyTest = results[0];
        this.customQuestionsOrder = results[3];
        this.getAllObjAndConfig(results[1], results[2]);
      });
    }

    if (this.superTestId) {
      forkJoin([
        this.testService.getSuperTestById(this.superTestId),
        this.questionGroupService.getAllQuestionGroupForSuperTest(this.superTestId),
        this.testService.getAllQuestionBySuperTestId(this.superTestId),
      ]).subscribe((results) => {
        this.getUserCardSortAnswers();
        this.getUserIntegerAnswers();
        this.superTest = results[0];
        this.getAllObjAndConfig(results[1], results[2]);
      });
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  pageChanged(event) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  isQuestion(obj: any): boolean {
    return obj.networkType === 'question';
  }

  isQuestionsGroup(obj: any): boolean {
    return obj.networkType === 'questionGroup';
  }

  getAllObjAndConfig(questionGroup: QuestionGroup[], questions: Question[]) {
    if (this.psyTest.customQuestionOrder) {
      this.questions = questions;
      this.questions = this.testService.applyCustomOrder(this.questions, this.customQuestionsOrder);
      questionGroup = [];
    }
    this.questionGroups = questionGroup;

    this.questions = questions.filter(
      (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
    );

    this.questions.forEach((question) => {
      if (question.instructions?.length === 0 && question.questionGroupId) {
        question.instructions = this.allQuestionGroups.filter(
          (qG) => qG.id === question.questionGroupId,
        )[0].instructions;
      }
    });

    this.questions = this.questions.sort((a, b) => {
      return a.aorder - b.aorder;
    });

    this.questionGroups.forEach((group) => {
      group.questions = questions.filter((question) => question.questionGroupId === group.id);

      if (group.questions.length === 0) {
        this.questionGroups = this.questionGroups.filter((qG) => qG.id !== group.id);
      }
    });

    this.questionGroups = this.questionGroups.sort((a, b) => {
      return a.aorder - b.aorder;
    });

    this.allObj = [
      ...this.questionGroups.map((obj) => {
        obj.networkType = 'questionGroup';

        obj.questions.forEach((question) => {
          question.questionNumber = this.questionNumber;
          this.questionNumber++;
        });
        return obj;
      }),
      ...this.questions.map((obj) => {
        obj.networkType = 'question';

        obj.questionNumber = this.questionNumber;
        this.questionNumber++;
        return obj;
      }),
    ];

    this.allObj = this.allObj.sort((a, b) => {
      return a.questionNumber - b.questionNumber;
    });
  }

  private getUserCardSortAnswers() {
    if (this.testResultId) {
      this.finishGettingCardSortAnswers = false;
      this.testService
        .getAllUserCardSortAnswersByTestResultId(this.testResultId)
        .subscribe((userCardSortAnswers: Answer[]) => {
          this.userCardSortAnswers = userCardSortAnswers;

          this.allObj.map((obj) => {
            if (obj.networkType === 'question' && obj.questionType === 'CARDSORT') {
              let userCardSortAnswers = this.userCardSortAnswers.filter(
                (answer: Answer) => answer.questionId === obj.id,
              );
              obj.answers = userCardSortAnswers.length > 0 ? userCardSortAnswers : obj.answers;
            } else if (obj.networkType === 'questionGroup') {
              obj.questions.forEach((question: Question) => {
                if (question.questionType === 'CARDSORT') {
                  let userCardSortAnswers = this.userCardSortAnswers.filter(
                    (answer: Answer) => answer.questionId === question.id,
                  );
                  question.answers = userCardSortAnswers.length > 0 ? userCardSortAnswers : question.answers;
                }
              });
            }
          });

          this.finishGettingCardSortAnswers = true;
          this.cdref.detectChanges();
        });
    }
  }

  private getUserIntegerAnswers() {
    this.questionService
      .findAllIntegerAnswerByTestResultId(this.testResultId)
      .subscribe((integerAnswers: IntegerAnswer[]) => {
        this.integerAnswers = integerAnswers;

        this.allObj.map((obj) => {
          if (
            obj.networkType === 'question' &&
            ['SLIDER', 'IMAGE', 'PENTAGON', 'MULTI_HOR', 'MULTI_VERT'].includes(obj.questionType)
          ) {
            const answer = integerAnswers.find((answer) => answer.questionId === obj.id);
            if (answer) {
              if (!obj.userAnswer) {
                obj.userAnswer = {};
              }
              obj.userAnswer.integerAnswer = answer;
            }
          } else if (obj.networkType === 'questionGroup') {
            obj.questions.forEach((question: Question) => {
              if (['SLIDER', 'IMAGE', 'PENTAGON', 'MULTI_HOR', 'MULTI_VERT'].includes(question.questionType)) {
                const answer = integerAnswers.find((answer) => answer.questionId === question.id);
                if (answer) {
                  if (!obj.userAnswer) {
                    obj.userAnswer = {}; // Create an empty object if it doesn't exist
                  }
                  obj.userAnswer.integerAnswer = answer;
                }
              }
            });
          }
        });
        this.cdref.detectChanges();
      });
  }
}
