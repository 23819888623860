/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestScaleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Delete a psyTestScale
     * Delete a psyTestScale
     * @param id Id of the psyTestScale
     * @returns any PsyTestScale deleted successfully
     * @throws ApiError
     */
    public deleteTestSubscale(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test/subscale/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
