import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { UpdateChapterDialogComponent } from '../update-chapter-dialog/update-chapter-dialog.component';
import { SectionDialogFormComponent } from '../section-dialog-form/section-dialog-form.component';
import { OptimalScoreListComponent } from '@app/shared/components/optimal-score-list/optimal-score-list.component';

// services
import { ChapterService } from '@app/core/services/chapter.service';
import { SectionService } from '@app/core/services/section.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { OptimalScoreService } from '@app/core/services/optimal-score.service';

// models
import { Chapter } from '@app/core/models/chapter.model';
import { Section } from '@app/core/models/section.model';
import { OptimalScoreScale } from '@app/core/models/optimal-score-scale.model';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss'],
})
export class ChapterComponent implements OnInit {
  constructor(
    private chapterService: ChapterService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private sectionService: SectionService,
    private snackBar: SnackBarService,
    private optimalScoreService: OptimalScoreService,
  ) {}

  reportId: number;
  chapter: Chapter;
  selectedSection: Section;
  optimalScoreScales: OptimalScoreScale[] = [];

  ngOnInit(): void {
    const { id: chapterId } = this.activatedRoute.snapshot.params;

    this.activatedRoute.queryParams.subscribe((params) => {
      this.reportId = params['reportId'];
    });

    this.loadChapter(chapterId);
  }

  loadChapter(chapterId) {
    this.chapterService.findById(chapterId).subscribe((chapter) => {
      this.chapter = chapter;
    });
  }

  viewOptimalScore(id: number) {
    this.optimalScoreService.getChapterOptimalScore(id, this.reportId).subscribe((optimalScoreScales) => {
      if (optimalScoreScales && optimalScoreScales.length > 0) {
        this.optimalScoreScales = optimalScoreScales.sort((a, b) => b.sectionId - a.sectionId);
        this.openOptimalScoreListDialog({ optimalScoreScales: this.optimalScoreScales, isLoaded: true });
      } else {
        this.optimalScoreScales = [];
        this.openOptimalScoreListDialog({ optimalScoreScales: this.optimalScoreScales, isLoaded: true });
      }
    });
  }

  openOptimalScoreListDialog(data) {
    this.dialog
      .open(OptimalScoreListComponent, {
        maxWidth: '300vw',
        maxHeight: '70vh',
        data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_SUCCESS) {
          return;
        }
      });
  }

  onAddNewSection() {
    const data = {
      chapterId: this.chapter.id,
      sectionIds: this.chapter.sections.map(({ id }) => id),
    };
    this.dialog
      .open(SectionDialogFormComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_SUCCESS) {
          return;
        }

        if (result && result.newSection) {
          this.chapter.sections = [...this.chapter.sections, result.newSection];
        }

        if (result && result.updatedSection) {
          this.chapter.sections = [...this.chapter.sections, result.updatedSection];
        }
      });
  }

  onDelete(sectionId: number) {
    const chapterId = this.chapter.id;

    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.chapterService.removeSection(chapterId, sectionId).subscribe(() => {
          this.chapter.sections = this.chapter.sections.filter((section) => section.id !== sectionId);
        });
      });
  }

  onEdit() {
    const data = {
      chapter: this.chapter,
    };

    this.dialog
      .open(UpdateChapterDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_SUCCESS) {
          return;
        }
        this.chapter = result.updatedChapter;
        this.snackBar.info('Chapter updated with success.');
      });
  }

  expandSection(chosenSection: any) {
    if (chosenSection.expanded) {
      chosenSection.expanded = false;
      this.selectedSection = null;
      return;
    }

    this.chapter.sections.forEach((section: any) => (section.expanded = false));
    this.sectionService.findById(chosenSection.id).subscribe((section) => {
      this.selectedSection = section;
      chosenSection.expanded = true;
    });
  }

  onEditSection(sectionId: number): void {
    this.sectionService.findById(sectionId).subscribe((section: Section) => {
      const data = { isEdit: true, section };
      this.dialog
        .open(SectionDialogFormComponent, { data })
        .afterClosed()
        .subscribe((result) => {
          if (typeof result !== 'undefined' && result.event === EVENT_SUCCESS) {
            if (result.updatedSection) {
              this.chapter.sections.find((s) => s.id === result.updatedSection.id).title = result.updatedSection.title;
              this.snackBar.info('Section updated with success.');
            }
          }
        });
    });
  }
}
