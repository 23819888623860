import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { TextAnswer } from '../models/text-answer.model';
import { IntegerAnswer } from '../models/integer-answer.model';
import { Answer } from '../models/answer.model';
import { Question } from '../models/question.model';
import { CardSortScorePosition } from '../models/card-sort-score-position.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class QuestionService {
  private originalOrderCardsortAnswersQuestionNumberListener = new Subject<number>();
  constructor(private http: HttpClient) {}

  delete(id: number) {
    return this.http.delete(`${BACKEND_URL}/question/${id}`).pipe(take(1));
  }

  findTextAnswerByQuestionIdAndUserId(
    questionId: number,
    userInfoId: number,
    roleMandateId: number = null,
    connectionIndicatorId: number = null,
  ) {
    const params = [];

    if (roleMandateId && connectionIndicatorId) {
      return;
    }

    if (roleMandateId) {
      params.push({
        key: 'roleMandateId',
        value: roleMandateId,
      });
    }

    if (connectionIndicatorId) {
      params.push({
        key: 'connectionIndicatorId',
        value: connectionIndicatorId,
      });
    }

    let url = BACKEND_URL + '/question/' + questionId + '/user/' + userInfoId + '/text-answer';

    if (params.length > 0) {
      url += '?' + params.map((param) => param.key + '=' + param.value).join('&');
    }

    return this.http.get<TextAnswer>(url);
  }

  findIntegerAnswerByQuestionIdAndUserId(
    questionId: number,
    userInfoId: number,
    requestFromUserInfoId: number = null,
    roleMandateId: number = null,
    connectionIndicatorId: number = null,
  ) {
    const params = [];

    if (roleMandateId && connectionIndicatorId) {
      return;
    }

    if (roleMandateId) {
      params.push({
        key: 'roleMandateId',
        value: roleMandateId,
      });
    }

    if (connectionIndicatorId) {
      params.push({
        key: 'connectionIndicatorId',
        value: connectionIndicatorId,
      });
    }

    if (requestFromUserInfoId) {
      params.push({
        key: 'requestFromUserInfoId',
        value: requestFromUserInfoId,
      });
    }

    let url = BACKEND_URL + '/question/' + questionId + '/user/' + userInfoId + '/integer-answer';

    if (params.length > 0) {
      url += '?' + params.map((param) => param.key + '=' + param.value).join('&');
    }

    return this.http.get<IntegerAnswer>(url);
  }

  findAllTextAnswerByQuestionIdAndUserId(
    questionId: number,
    userInfoId: number,
    roleMandateId: number = null,
    connectionIndicatorId: number = null,
  ) {
    const params = [];

    if (roleMandateId && connectionIndicatorId) {
      return;
    }

    if (roleMandateId) {
      params.push({
        key: 'roleMandateId',
        value: roleMandateId,
      });
    }

    if (connectionIndicatorId) {
      params.push({
        key: 'connectionIndicatorId',
        value: connectionIndicatorId,
      });
    }

    let url = BACKEND_URL + '/question/' + questionId + '/user/' + userInfoId + '/answer-list-text';

    if (params.length > 0) {
      url += '?' + params.map((param) => param.key + '=' + param.value).join('&');
    }

    return this.http.get<TextAnswer[]>(url);
  }

  deleteTextAnswer(answerId) {
    return this.http.delete(BACKEND_URL + '/question/text-answer/' + answerId);
  }

  update(question) {
    return this.http.put(BACKEND_URL + '/question/' + question.id, question);
  }

  findAnswerCardsortByQuestionIdAndUserId(
    questionId: number,
    userInfoId: number,
    roleMandateId: number = null,
    connectionIndicatorId: number = null,
  ) {
    const params = [];

    if (roleMandateId && connectionIndicatorId) {
      return;
    }

    if (roleMandateId) {
      params.push({
        key: 'roleMandateId',
        value: roleMandateId,
      });
    }

    if (connectionIndicatorId) {
      params.push({
        key: 'connectionIndicatorId',
        value: connectionIndicatorId,
      });
    }

    let url = BACKEND_URL + '/question/' + questionId + '/user/' + userInfoId + '/answer-cardsort';

    if (params.length > 0) {
      url += '?' + params.map((param) => param.key + '=' + param.value).join('&');
    }

    return this.http.get<Answer[]>(url);
  }

  findCardsortAnswerByQuestionIdAndTestResultId(questionId: number, testResultId: number) {
    return this.http.get<Answer[]>(
      BACKEND_URL + '/question/' + questionId + '/test-result/' + testResultId + '/answer-cardsort',
    );
  }

  findIntegerAnswerByQuestionIdAndTestResultId(questionId: number, testResultId: number) {
    return this.http.get<IntegerAnswer>(
      BACKEND_URL + '/question/' + questionId + '/test-result/' + testResultId + '/integer-answer',
    );
  }

  findAllIntegerAnswerByTestResultId(testResultId: number) {
    return this.http.get<IntegerAnswer[]>(`${BACKEND_URL}/question/test-result/${testResultId}/integer-answer`);
  }

  findTextAnswerByQuestionIdAndTestResultId(questionId: number, testResultId: number) {
    return this.http.get<TextAnswer>(
      BACKEND_URL + '/question/' + questionId + '/test-result/' + testResultId + '/text-answer',
    );
  }

  findAllAnswersByQuestionId(questionId: number) {
    if (!questionId) {
      return of([]);
    }

    return this.http.get<Answer[]>(BACKEND_URL + '/question/' + questionId + '/answer');
  }

  saveAnswerCardsort(questionId: number, userInfoId: number, newCardsortAnswers: Answer[]) {
    return this.http.post(
      BACKEND_URL + '/question/' + questionId + '/user/' + userInfoId + '/cardsort-answer',
      newCardsortAnswers,
    );
  }

  saveOrder(data: any[]) {
    return this.http.post(BACKEND_URL + '/question/orders', { orders: data });
  }

  getCardsortAnalysisByQuestionId(connectionIndicatorId: number, questionId: number) {
    return this.http.get<any[]>(
      BACKEND_URL +
        '/question/connection-indicator/' +
        connectionIndicatorId +
        '/cardsort-analysis-by-question/' +
        questionId,
    );
  }

  getTextAreaAnalysisByQuestionId(connectionIndicatorId: number, questionId: number) {
    return this.http.get<any[]>(
      BACKEND_URL +
        '/question/connection-indicator/' +
        connectionIndicatorId +
        '/text-area-analysis-by-question/' +
        questionId,
    );
  }

  saveTextAreaNote(genericAnswerId: number, data: string) {
    return this.http.post(BACKEND_URL + '/question/save-note-by-genericAnswerId/' + genericAnswerId, { note: data });
  }

  updateTextAreaNote(textAnswerAnalysesId: number, data: string) {
    return this.http.patch(BACKEND_URL + '/question/update-note-by-textAnswerAnalysesId/' + textAnswerAnalysesId, {
      note: data,
    });
  }

  getSliderAnalysisByConnectionIndicatorIdAndQuestionId(connectionIndicatorId: number, questionId: number) {
    return this.http.get<any[]>(
      BACKEND_URL +
        '/question/connection-indicator/' +
        connectionIndicatorId +
        '/slider-analysis-by-question/' +
        questionId,
    );
  }

  saveConnectionIndicator(connectionIndicatorId: number, question: Question) {
    return this.http.post(BACKEND_URL + '/question/connection-indicator/' + connectionIndicatorId, question);
  }

  getAllBySubscaleId(subscaleId: number): Observable<any> {
    return this.http.get<Question[]>(`${BACKEND_URL}/question/subscale/${subscaleId}`).pipe(take(1));
  }

  create(question: Question): Observable<any> {
    return this.http.post(`${BACKEND_URL}/question`, question).pipe(take(1));
  }

  getById(id: number): Observable<Question> {
    return this.http.get<Question>(`${BACKEND_URL}/question/${id}`).pipe(take(1));
  }

  getAllCardSortScorePositionById(id: number): Observable<CardSortScorePosition[]> {
    return this.http
      .get<CardSortScorePosition[]>(`${BACKEND_URL}/question/${id}/card-sort-score-position`)
      .pipe(take(1));
  }

  updateAllCardSortScorePositionById(
    id: number,
    answersPositionScore: CardSortScorePosition[],
  ): Observable<CardSortScorePosition[]> {
    return this.http
      .put<CardSortScorePosition[]>(`${BACKEND_URL}/question/${id}/card-sort-score-position`, answersPositionScore)
      .pipe(take(1));
  }

  deleteAllCardSortScorePositionById(id: number): Observable<any> {
    return this.http
      .delete<CardSortScorePosition[]>(`${BACKEND_URL}/question/${id}/card-sort-score-position`)
      .pipe(take(1));
  }

  getOriginalOrderCardsortAnswersQuestionNumberListener() {
    return this.originalOrderCardsortAnswersQuestionNumberListener.asObservable().pipe(take(1));
  }

  setOriginalOrderCardsortAnswersQuestionNumberListener(value: number) {
    this.originalOrderCardsortAnswersQuestionNumberListener.next(value);
  }

  saveAllUnansweredCardsortOriginalOrderAndSliderFirstOption(
    testResultId: number,
    userInfoId: number,
    data: Question[],
  ) {
    return this.http.post<any>(
      `${BACKEND_URL}/question/${testResultId}/user/${userInfoId}/all-cardsort-orginal-order-and-slider-first-option`,
      { unAnsweredCardsortAndSliderQuestions: data },
    );
  }
}
