import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { OptimalScoreScaleRangeText } from '@app/core/models/optimal-score-scale-range-text.model';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-edit-optimal-score-scale-range-text',
  templateUrl: './edit-optimal-score-scale-range-text.component.html',
  styleUrls: ['./edit-optimal-score-scale-range-text.component.scss'],
})
export class EditOptimalScoreScaleRangeTextComponent implements OnInit {
  form: FormGroup;
  editorConfig: AngularEditorConfig = {
    height: '8rem',
    editable: true,
    toolbarHiddenButtons: [
      ['subscript', 'superscript', 'indent', 'outdent', 'insertUnorderedList', 'insertOrderedList'],
      ['link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode'],
    ],
  };

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: OptimalScoreScaleRangeText,
    private dialogRef: MatDialogRef<EditOptimalScoreScaleRangeTextComponent>,
  ) {
    this.form = this.fb.group({
      from: [0, [Validators.required]],
      to: [1, [Validators.required]],
      rangeText: [''],
    });
  }

  ngOnInit(): void {
    this.form.get('from').setValue(this.data.from);
    this.form.get('to').setValue(this.data.to);
    this.form.get('rangeText').setValue(this.data.rangeText);
  }

  onSubmit() {
    const { value } = this.form;
    this.dialogRef.close({ event: EVENT_SUCCESS, range: value });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
