import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Chapter } from '../models/chapter.model';


const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class ChapterService {
  constructor(private http: HttpClient) {}

  addSection(chapterId: number, sectionId: number) {
    const url = `${BACKEND_URL}/chapter/${chapterId}/section`;
    const body = { sectionId };

    return this.http.post(url, body);
  }

  findById(chapterId: number) {
    const url = `${BACKEND_URL}/chapter/${chapterId}`;
    return this.http.get<Chapter>(url);
  }

  getAll() {
    const url = BACKEND_URL + '/chapter';
    return this.http.get<Chapter[]>(url);
  }

  create(params: { title: string }) {
    const url = BACKEND_URL + '/chapter';
    const body = {
      title: params.title,
    };

    return this.http.post<Chapter>(url, body);
  }

  getAllSections() {
    const url = BACKEND_URL + '/section';
    return this.http.get<any>(url);
  }

  removeSection(chapterId: number, sectionId: number) {
    const url = `${BACKEND_URL}/chapter/${chapterId}/section/${sectionId}`;
    return this.http.delete(url);
  }

  update(chapterId: number, params: { title: string; secondTierOptimalScoresHeading: string }) {
    const url = `${BACKEND_URL}/chapter/${chapterId}`;
    const body = {
      title: params.title,
      secondTierOptimalScoresHeading: params.secondTierOptimalScoresHeading
        ? params.secondTierOptimalScoresHeading
        : null,
    };

    return this.http.patch<Chapter>(url, body);
  }

  move(id: number, params: any) {
    const url = `${BACKEND_URL}/chapter/${id}/move`;
    return this.http.patch<any>(url, params).pipe(take(1));
  }
}
