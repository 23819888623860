<button mat-raised-button class="new-button pull-right" color="primary" (click)="createNewTestGroup()">
  <mat-icon aria-hidden="false">add</mat-icon>Create a new Test Group
</button>

<div class="mat-elevation-z3">
  <app-psb-table-search [data]="testGroups" (filter)="searchTestGroup($event)" placeholder="Search for Test Group">
  </app-psb-table-search>

  <mat-table [dataSource]="testGroups">
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.title }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.description }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.status }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell mat-header-cell class="action" *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button class="btn-view" (click)="onView(element)">View</button>
        <button mat-button class="btn-delete" (click)="onDelete(element)">Delete</button>
        <button mat-button class="btn-test-result" *ngIf="!companyId">Test Result</button>
      </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedTestGroupsColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedTestGroupsColumns"></mat-row>
  </mat-table>
</div>

<mat-paginator
  #paginatorTestGroups
  [pageSize]="10"
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
></mat-paginator>
