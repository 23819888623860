import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from '@app/app-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutHeaderComponent } from './layout-header/layout-header.component';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';
import { LayoutAdminComponent } from './layout-admin/layout-admin.component';
import { LayoutTgmComponent } from './layout-tgm/layout-tgm.component';
import { LayoutTestUserComponent } from './layout-test-user/layout-test-user.component';

@NgModule({
  declarations: [
    LayoutHeaderComponent,
    LayoutFooterComponent,
    LayoutAdminComponent,
    LayoutTgmComponent,
    LayoutTestUserComponent,
  ],
  exports: [LayoutHeaderComponent, LayoutFooterComponent],
  imports: [CommonModule, AppRoutingModule, SharedModule, ReactiveFormsModule, FormsModule],
})
export class LayoutModule {}
