<h1 mat-dialog-title>Add a new chapter to report</h1>
<form id="addChapterForm" [formGroup]="form" (submit)="onSubmit()" style="width: 50vw" mat-dialog-content>
  <mat-button-toggle-group formControlName="option" style="margin-bottom: 2rem">
    <mat-button-toggle value="existing">Select an existing chapter</mat-button-toggle>
    <mat-button-toggle value="new">Create a new one</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-form-field *ngIf="form.value.option === 'existing'">
    <mat-label>Select an existing one</mat-label>
    <input
      type="text"
      placeholder="Pick one"
      aria-label="Number"
      matInput
      formControlName="chapterSelect"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let chapter of filteredChapters | async" [value]="chapter">
        {{ chapter.title }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="form.get('chapterSelect').hasError">Chapter is required.</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="form.value.option === 'new'">
    <mat-label>Enter the title for the new chapter</mat-label>
    <input matInput type="text" formControlName="chapterInput" />
    <mat-error *ngIf="form.get('chapterInput').hasError">You must provide a title.</mat-error>
  </mat-form-field>
</form>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>
    <mat-icon>block</mat-icon>
    Cancel
  </button>
  <button mat-flat-button color="primary" type="submit" form="addChapterForm" [disabled]="isInvalidForm">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</div>
