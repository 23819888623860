<h1 *ngIf="!isEdit">Create a New Client</h1>
<h1 *ngIf="isEdit">Update a Client</h1>
<form autocomplete="off" [formGroup]="clientModalForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Name *</mat-label>
    <input matInput type="text" formControlName="name" appAllowAlphabetsOnly />
    <mat-error *ngIf="clientModalForm.get('name').errors">Please, enter a valid name.</mat-error>
  </mat-form-field>
  <div class="location">
    <div class="address">
      <h3>Location</h3>
      <mat-form-field>
        <mat-label>Country *</mat-label>
        <input matInput type="text" formControlName="country" [matAutocomplete]="autoCountry" />
        <mat-autocomplete
          #autoCountry="matAutocomplete"
          (optionSelected)="onSelectCountry($event.option.value)"
          [displayWith]="displayCountry"
        >
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{ displayCountry(country) }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="clientModalForm.get('country').errors">Please, select a valid country.</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="states && states.length">
        <mat-label>State *</mat-label>
        <input matInput type="text" formControlName="stateId" [matAutocomplete]="autoState" />
        <mat-autocomplete #autoState="matAutocomplete" [displayWith]="displayState">
          <mat-option *ngFor="let state of filteredStates | async" [value]="state">
            {{ displayState(state) }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="clientModalForm.get('stateId').errors">Please, select a valid state.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>City *</mat-label>
        <input matInput type="text" formControlName="city" />
        <mat-error *ngIf="clientModalForm.get('city').errors">Please, enter a valid city.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Zip Code *</mat-label>
        <input matInput type="text" formControlName="zipCode" />
        <mat-error *ngIf="clientModalForm.get('zipCode').errors">Please, enter a valid zip code.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Address *</mat-label>
        <input matInput type="text" formControlName="address" />
        <mat-error *ngIf="clientModalForm.get('address').errors">Please, enter a valid address.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Address 2</mat-label>
        <input matInput type="text" formControlName="address2" />
      </mat-form-field>
    </div>
    <div class="address">
      <div class="billing-address">
        <h3>Billing Address</h3>
        <mat-checkbox
          color="primary"
          [checked]="sameAsLocation"
          (change)="toggleBillingAddress()"
          (click)="$event.stopPropagation()"
        >
          <h6>Same as Location</h6>
        </mat-checkbox>
      </div>
      <div *ngIf="!sameAsLocation">
        <mat-form-field>
          <mat-label>Country</mat-label>
          <input matInput type="text" formControlName="billingCountry" [matAutocomplete]="autoBillingCountry" />
          <mat-autocomplete
            #autoBillingCountry="matAutocomplete"
            (optionSelected)="onSelectBillingCountry($event.option.value)"
            [displayWith]="displayCountry"
          >
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
              {{ displayCountry(country) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field *ngIf="billingStates && billingStates.length">
          <mat-label>State</mat-label>
          <input matInput type="text" formControlName="billingStateId" [matAutocomplete]="autoBillingState" />
          <mat-autocomplete #autoBillingState="matAutocomplete" [displayWith]="displayState">
            <mat-option *ngFor="let billingState of filteredBillingStates | async" [value]="billingState">
              {{ displayState(billingState) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput type="text" formControlName="billingCity" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Zip Code</mat-label>
          <input matInput type="text" formControlName="billingZipCode" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput type="text" formControlName="billingAddress" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Address 2</mat-label>
          <input matInput type="text" formControlName="billingAddress2" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-form-field>
    <mat-label>Phone *</mat-label>
    <input matInput type="tel" formControlName="telephone" />
    <mat-error *ngIf="clientModalForm.get('telephone').errors">Please, enter a valid phone.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>E-mail *</mat-label>
    <input matInput type="text" formControlName="email" />
    <mat-error *ngIf="clientModalForm.get('email').errors">Please, enter a valid email.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Skype ID</mat-label>
    <input matInput type="text" formControlName="skype" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Language</mat-label>
    <mat-select formControlName="defaultLanguage">
      <mat-option value="ENGLISH">English</mat-option>
      <mat-option value="PORTUGUESE">Portuguese</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Time Zone</mat-label>
    <input matInput type="text" formControlName="timeZoneId" [matAutocomplete]="autoTimezone" />
    <mat-autocomplete #autoTimezone="matAutocomplete" [displayWith]="displayTimeZone">
      <mat-option *ngFor="let timeZone of filteredTimeZones | async" [value]="timeZone">
        {{ displayTimeZone(timeZone) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="clientModalForm.get('timeZoneId').invalid">Please select a valid time zone.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Subscription Date Limit *</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="subscriptionTime" (focus)="picker.open()" />
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="clientModalForm.get('subscriptionTime').errors"
      >Please, enter a valid subscriptionTime.</mat-error
    >
  </mat-form-field>
  <mat-form-field>
    <mat-label>Test Takers Limit</mat-label>
    <input matInput type="number" formControlName="subscriptionTestTakers" />
  </mat-form-field>
  <div id="image">
    <p class="file-name">{{ fileName }}</p>
    <button type="button" mat-button class="btn-primary btn-center btn-300">
      <mat-icon>add</mat-icon>
      Upload Logo Image
      <input
        formControlName="logoFile"
        type="file"
        accept="image/*"
        class="input-file"
        (change)="handleFile('logo', $event)"
      />
    </button>
  </div>
  <mat-slide-toggle
    class="slide"
    color="primary"
    formControlName="allowPostPlanAccess"
    checked="allowPostPlanAccess.value"
  >
    Allow access to reports post-completion of plan
  </mat-slide-toggle>
  <div>
    <mat-slide-toggle
      class="slide"
      color="primary"
      formControlName="allowRawScoresAccess"
      checked="allowRawScoresAccess.value"
    >
      Allow access to raw scores
    </mat-slide-toggle>
  </div>
  <div>
    <mat-slide-toggle class="slide" color="primary" formControlName="active" checked="active.value">
      Status
    </mat-slide-toggle>
  </div>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">Cancel</button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="clientModalForm.invalid">Save</button>
  </div>
</form>
