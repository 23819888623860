<mat-card>
  <mat-card-header>
    <h2>{{ decisionTable.questionNumber }}) {{ decisionTable.title }}</h2>
  </mat-card-header>
  <mat-card-content>
    <p *ngIf="decisionTable.instructions">
      <b>{{ decisionTable.instructions }}</b>
    </p>
    <p *ngIf="decisionTable.description && decisionTable.description !== 'null'">{{ decisionTable.description }}</p>

    <form [formGroup]="decisionTableForm">
      <table *ngIf="decisionTable.columns.length > 0" class="table-columns mat-elevation-z3">
        <thead>
          <tr>
            <td *ngFor="let decisionTableColumn of decisionTable.columns">
              {{ decisionTableColumn.title }}
            </td>
            <td *ngIf="decisionTable.acl === 5">Private</td>
            <td *ngIf="answering" class="actions-column">Actions</td>
          </tr>
          <tr *ngIf="this.answering">
            <td class="td-no-padding-left input-row" *ngFor="let decisionTableColumn of decisionTable.columns">
              <div [ngSwitch]="decisionTableColumn.type">
                <!-- Decision Area -->
                <div *ngSwitchCase="'1'">
                  <mat-form-field>
                    <input
                      type="text"
                      placeholder="Select One"
                      aria-label="Decision Area"
                      matInput
                      [formControlName]="getFormName(decisionTableColumn.title)"
                      [matAutocomplete]="auto"
                    />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayDecisionArea">
                      <mat-option
                        *ngFor="let decisionArea of filteredUsersDecisionAreas | async"
                        [value]="decisionArea"
                      >
                        {{ decisionArea.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error
                    *ngIf="
                      decisionTableForm.get(getFormName(decisionTableColumn.title)).errors &&
                      (decisionTableForm.get(getFormName(decisionTableColumn.title)).dirty ||
                        decisionTableForm.get(getFormName(decisionTableColumn.title)).touched)
                    "
                  >
                    Please, select a valid {{ decisionTableColumn.title }}
                  </mat-error>
                </div>

                <!-- Users Selection -->
                <div *ngSwitchCase="'2'">
                  <mat-chip-list #chipList aria-label="User Selected">
                    <mat-chip
                      *ngFor="let user of selectedUsers[getFormName(decisionTableColumn.title)]"
                      [selectable]="answering"
                      [removable]="answering"
                      (removed)="removedSelectedUser(user, getFormName(decisionTableColumn.title))"
                    >
                      {{ user.firstName }} {{ user.lastName }}
                      <mat-icon matChipRemove *ngIf="answering">cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>

                  <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input
                      type="text"
                      placeholder="User name, Department or Role"
                      matInput
                      #trigger="matAutocompleteTrigger"
                      [formControlName]="getFormName(decisionTableColumn.title)"
                      autocomplete="off"
                      [matAutocomplete]="auto"
                    />
                    <mat-icon
                      matSuffix
                      (click)="onClearSearch(getFormName(decisionTableColumn.title), $event, trigger)"
                    >
                      clear
                    </mat-icon>

                    <mat-autocomplete [displayWith]="displayUser" #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let user of filteredUsers[getFormName(decisionTableColumn.title)] | async"
                        (click)="optionClicked($event, user, getFormName(decisionTableColumn.title))"
                        [value]="this.selectedUsers[getFormName(decisionTableColumn.title)]"
                      >
                        {{ user.firstName }} {{ user.lastName }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error
                    *ngIf="
                      decisionTableForm.get(getFormName(decisionTableColumn.title)).errors &&
                      (decisionTableForm.get(getFormName(decisionTableColumn.title)).dirty ||
                        decisionTableForm.get(getFormName(decisionTableColumn.title)).touched)
                    "
                  >
                    Please, select at least one user.
                  </mat-error>
                </div>

                <!-- Text answer -->
                <div *ngSwitchCase="'3'">
                  <mat-form-field>
                    <input type="text" [formControlName]="getFormName(decisionTableColumn.title)" matInput />
                  </mat-form-field>
                  <mat-error
                    *ngIf="
                      decisionTableForm.get(getFormName(decisionTableColumn.title)).errors &&
                      (decisionTableForm.get(getFormName(decisionTableColumn.title)).dirty ||
                        decisionTableForm.get(getFormName(decisionTableColumn.title)).touched)
                    "
                  >
                    Please, enter valid text.
                  </mat-error>
                </div>
              </div>
            </td>
            <td *ngIf="decisionTable.acl === 5">
              <mat-slide-toggle
                *ngIf="decisionTable.acl === 5"
                color="primary"
                [checked]="answerPrivate"
                (change)="togglePrivacy()"
              ></mat-slide-toggle>
            </td>
            <td>
              <button
                mat-button
                class="btn-primary btn-center"
                (click)="saveAnswer()"
                [disabled]="!decisionTableForm.valid"
              >
                Add
              </button>
            </td>
          </tr>
        </thead>
        <tbody style="display: contents">
          <tr
            *ngFor="let decisionTableAnswer of decisionTableAnswers | slice: lowValue:highValue; let i = index"
            [id]="decisionTableAnswer.id"
          >
            <app-edit-answer-decision-table
              *ngIf="showEditAnswer(decisionTableAnswer)"
              [decisionTableAnswer]="decisionTableAnswer"
              [decisionTableColumns]="decisionTable.columns"
              [answering]="answering"
              [allDecisionArea]="allDecisionArea"
              [userInfoId]="this.userInfoId"
              (edit)="onEditAnswerResponse($event, i)"
              [users]="originalUsers"
              [decisionTable]="decisionTable"
            >
            </app-edit-answer-decision-table>

            <div *ngIf="!showEditAnswer(decisionTableAnswer)" style="display: contents">
              <td *ngFor="let decisionTableColumn of decisionTable.columns" style="padding: 2px 0px">
                <div [ngSwitch]="decisionTableColumn.type">
                  <div *ngSwitchCase="'2'">
                    <mat-chip-list class="mat-chip-list-stacked" aria-label="Users list">
                      <div
                        *ngFor="
                          let answer of decisionTableAnswer[getFormName(decisionTableColumn.title)];
                          let j = index
                        "
                      >
                        <mat-chip style="width: auto" [selectable]="answering" [removable]="answering" *ngIf="answer">
                          {{ answer.userName }}
                          <span
                            *ngIf="decisionTableAnswer.toRelation"
                            [ngSwitch]="answer.accept"
                            style="font-size: 24px; margin-top: 6px"
                          >
                            <mat-icon [inline]="true" *ngSwitchCase="1" class="accepted"> check_circle</mat-icon>
                            <mat-icon [inline]="true" *ngSwitchCase="0" class="waiting"> pending</mat-icon>
                            <mat-icon [inline]="true" *ngSwitchCase="-1" class="not-accpeted"> dangerous</mat-icon>
                          </span>
                        </mat-chip>

                        <div class="row" *ngIf="decisionTableAnswer.shouldSignOff">
                          <mat-checkbox
                            color="primary"
                            style="margin-right: 5px"
                            [checked]="answer.accept === 1"
                            [disabled]="!answering || answer.accept === -1"
                            (change)="accept(i, j, getFormName(decisionTableColumn.title))"
                            >Accept
                          </mat-checkbox>

                          <mat-checkbox
                            color="primary"
                            [checked]="answer.accept === -1"
                            [disabled]="!answering || answer.accept === 1"
                            (change)="reject(i, j, getFormName(decisionTableColumn.title))"
                            >Reject
                          </mat-checkbox>
                        </div>
                      </div>
                    </mat-chip-list>
                  </div>
                  <div *ngSwitchDefault>
                    {{ decisionTableAnswer[getFormName(decisionTableColumn.title)] }}
                  </div>
                </div>
              </td>
              <td *ngIf="decisionTable.acl === 5">
                <mat-slide-toggle
                  *ngIf="decisionTable.acl === 5"
                  color="primary"
                  [disabled]="true"
                  [checked]="+decisionTableAnswer.private === 1"
                  (change)="updateAnswerPrivacy(decisionTableAnswer)"
                >
                </mat-slide-toggle>
              </td>
              <td *ngIf="answering && !decisionTableAnswer.fromRelation" class="actions-column">
                <div class="mobile-actions">
                  <button
                    class="mobile-action-buttons"
                    mat-icon-button
                    aria-label="Edit Answer"
                    (click)="editAnswer(decisionTableAnswer, i)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    class="mobile-action-buttons"
                    mat-icon-button
                    aria-label="Delete Answer"
                    (click)="deleteAnswer(decisionTableAnswer)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <div class="desktop-action-buttons">
                  <button
                    class="desktop-action-buttons"
                    mat-button
                    aria-label="Edit Answer"
                    (click)="editAnswer(decisionTableAnswer, i)"
                  >
                    Edit
                  </button>
                  <button
                    class="desktop-action-buttons"
                    mat-button
                    aria-label="Delete Answer"
                    (click)="deleteAnswer(decisionTableAnswer)"
                  >
                    Delete
                  </button>
                </div>
              </td>
              <td *ngIf="answering && decisionTableAnswer.fromRelation"></td>
            </div>
          </tr>
        </tbody>
      </table>

      <mat-paginator
        [length]="decisionTableAnswers.length"
        pageSize="10"
        (page)="getPaginatorData($event)"
        [pageSizeOptions]="[10, 20, 30]"
        *ngIf="decisionTableAnswers.length > 10"
      ></mat-paginator>
    </form>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="answering && decisionTableAnswersToAccept.length">
  <app-decision-table-accept
    [decisionTable]="decisionTable"
    [decisionTableAnswersToAccept]="decisionTableAnswersToAccept"
    (answer)="onAnswerToAccept($event)"
  >
  </app-decision-table-accept>
</mat-card>
