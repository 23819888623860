import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { SuperTestTest } from '../models/supertest-test.model';
import { SuperTest } from '../models/supertest.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SuperTestService {
  constructor(private http: HttpClient) {}

  createSuperTest(superTest: SuperTest) {
    return this.http.post(BACKEND_URL + '/supertest', { superTest });
  }

  updateSuperTest(superTestId: number, superTest: SuperTest) {
    return this.http.patch(BACKEND_URL + '/supertest/' + superTestId, { superTest });
  }

  getAllSuperTest() {
    return this.http.get<SuperTest[]>(BACKEND_URL + '/supertest');
  }

  getAllSuperTestTestById(superTestId: number) {
    return this.http.get<SuperTestTest[]>(BACKEND_URL + `/supertest/${superTestId}/supertest-test`);
  }

  getSuperTestById(superTestId: number) {
    return this.http.get<SuperTest>(BACKEND_URL + '/supertest/' + superTestId);
  }

  delete(superTestId: number) {
    return this.http.delete(BACKEND_URL + '/supertest/' + superTestId);
  }
}
