import { Component, OnInit, Input } from '@angular/core';
import { KeyValue } from '@angular/common';
import { UserScores } from 'src/app/core/models/user-scores.model';

@Component({
  selector: 'app-report-subscale-detail',
  templateUrl: './report-subscale-detail.component.html',
  styleUrls: ['./report-subscale-detail.component.scss'],
})
export class ReportSubscaleDetailComponent implements OnInit {
  @Input() subscales: any;
  @Input() psyTestTitle: string;
  @Input() isAdmin: boolean;
  @Input() isOA: boolean;
  @Input() isTgm: boolean;
  @Input() allowRawScoresAccess: boolean;

  constructor() {}

  ngOnInit(): void {}

  // Order by desc by percentile value
  percentileDescOrder = (a: KeyValue<number, UserScores>, b: KeyValue<number, UserScores>): number => {
    return a.value.percentile > b.value.percentile ? -1 : b.value.percentile > a.value.percentile ? 1 : 0;
  };
}
