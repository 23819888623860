import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';

// services
import { PaginationService } from '@app/core/services/pagination.service';
import { AnswerService } from '@app/core/services/answer.service';
import { QuestionService } from '@app/core/services/question.service';
import { UserService } from '@app/core/services/user.service';

// models
import { Question } from '@app/core/models/question.model';
import { TextAnswer } from '@app/core/models/text-answer.model';
import { UserAnswer } from '@app/core/models/user-answer.model';

@Component({
  selector: 'app-answer-text-area',
  templateUrl: './answer-text-area.component.html',
  styleUrls: ['./answer-text-area.component.scss'],
})
export class AnswerTextAreaComponent implements OnInit, OnChanges {
  @Input() question: Question;
  @Input() connectionIndicatorId: number;
  @Input() roleMandateId: number;
  @Input() testResultId: number;
  @Input() loadAnswer: boolean;
  @Input() answering: boolean;
  @Input() companyId: number;
  @Input() psyTestId: number;
  @Input() superTestId: number;
  @Input() userInfoId: number;
  @Input() isPreview: boolean;
  @Input() showResults: boolean;
  @Output() userAnswer = new EventEmitter<{ questionId: number; userAnswer: UserAnswer }>();
  @ViewChild('referenceAngular') referenceAngular: AngularEditorComponent;
  answerTextAreaControl = new FormControl('');
  textAreaAnswer: TextAnswer;
  private paginationListenerSubs: Subscription;
  newAnswer = false;
  answerTextAreaTimer: any;
  answerPrivate = false;
  lastRichTextAnswer: string;
  private initialPage: number;
  private currentPage: number;

  editorConfig: AngularEditorConfig = {
    height: '5rem',
    sanitize: true,
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private questionService: QuestionService,
    private answerService: AnswerService,
    private pService: PaginationService,
    private userService: UserService,
    private paginationService: PaginationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.paginationListenerSubs = this.paginationService.getPaginationListener().subscribe((value) => {
      if (value) {
        if (typeof this.currentPage === 'undefined') {
          this.saveTextAreaAnswer();
        }
      }
    });
  }

  ngOnInit(): void {
    // TODO: Need to refact
    this.initialPage = +this.route.snapshot.params['page'];
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPage = +event.url.split('/page/')[1];
      }
    });

    this.editorConfig.editable = this.answering;

    if (!this.isPreview) {
      if (this.showResults) {
        if (this.roleMandateId && this.userInfoId) {
          if (this.question.userAnswer && this.question.userAnswer.textAnswer) {
            this.loadTextAreaAnswerFromUserAnswer(this.question.userAnswer);
          }
        }
      } else {
        if (this.userService.isOwner(+this.userInfoId)) {
          if (this.testResultId) {
            this.questionService
              .findTextAnswerByQuestionIdAndTestResultId(this.question.id, this.testResultId)
              .subscribe((answer) => this.loadTextAreaAnswer(answer));
          } else {
            this.questionService
              .findTextAnswerByQuestionIdAndUserId(this.question.id, this.userInfoId, this.roleMandateId)
              .subscribe((answer) => this.loadTextAreaAnswer(answer));
          }
        } else if (!this.loadAnswer && (this.psyTestId || this.superTestId)) {
          this.questionService
            .findTextAnswerByQuestionIdAndTestResultId(this.question.id, this.testResultId)
            .subscribe((answer) => this.loadTextAreaAnswer(answer));
        } else if (this.loadAnswer && (this.psyTestId || this.superTestId)) {
          if (!this.textAreaAnswer && !this.answerTextAreaControl.value) {
            if (this.question.userAnswer && this.question.userAnswer.textAnswer) {
              this.loadTextAreaAnswerFromUserAnswer(this.question.userAnswer);
            }
          }
        } else {
          this.questionService
            .findTextAnswerByQuestionIdAndUserId(
              this.question.id,
              this.userInfoId,
              this.roleMandateId,
              this.connectionIndicatorId,
            )
            .subscribe((answer) => this.loadTextAreaAnswer(answer));
        }
      }
    }
  }

  ngOnChanges(): void {
    if (!this.answering && this.isPreview) {
      this.editorConfig.sanitize = false;
      this.editorConfig.editable = false;
    }
  }

  loadTextAreaAnswerFromUserAnswer(userAnswer: UserAnswer) {
    this.textAreaAnswer = {
      answerId: userAnswer.textAnswer.id,
      answer: userAnswer.textAnswer.answer,
      questionId: this.question.id,
    };

    if (userAnswer.textAnswer.private) {
      this.answerPrivate = !!userAnswer.textAnswer.private;
      this.textAreaAnswer.private = this.answerPrivate;
    }

    this.answerTextAreaControl.setValue(userAnswer.textAnswer.answer);
  }

  loadTextAreaAnswer(answer: TextAnswer) {
    if (answer && answer.answerId) {
      this.textAreaAnswer = answer;
      if (this.question.questionType === 'RICH_TEXT') {
        this.lastRichTextAnswer = this.textAreaAnswer.answer;
      }

      if (answer.private) {
        this.answerPrivate = !!answer.private;
        this.textAreaAnswer.private = this.answerPrivate;
      }
      this.answerTextAreaControl.setValue(answer.answer);
    } else {
      this.newAnswer = true;
    }
  }

  saveTextAreaAnswer() {
    if (this.newAnswer) {
      if (this.connectionIndicatorId) {
        this.textAreaAnswer = {
          questionId: this.question.id,
          answer: this.answerTextAreaControl.value,
          userInfoId: this.userInfoId,
        };
      }

      if (this.roleMandateId) {
        this.textAreaAnswer = {
          questionId: this.question.id,
          answer: this.answerTextAreaControl.value,
          userInfoId: this.userInfoId,
        };
      }

      this.textAreaAnswer.private = this.answerPrivate;
      if (this.question.acl === 9) {
        this.textAreaAnswer.private = true;
      }

      this.answerService.saveTextArea(this.question.id, this.textAreaAnswer).subscribe(() => {
        this.pService.setPaginationListener(false);
      });
    } else {
      this.textAreaAnswer = {
        id: this.textAreaAnswer.id,
        answerId: this.textAreaAnswer.answerId,
        questionId: this.question.id,
        answer: this.answerTextAreaControl.value,
        userInfoId: this.userInfoId,
      };

      if (this.connectionIndicatorId) {
        this.textAreaAnswer.answer = this.answerTextAreaControl.value;
      }

      if (this.roleMandateId) {
        this.textAreaAnswer.answer = this.answerTextAreaControl.value;
      }

      this.textAreaAnswer.private = this.answerPrivate;
      if (this.question.acl === 9) {
        this.textAreaAnswer.private = true;
      }

      if (this.textAreaAnswer.answer && this.textAreaAnswer.answer.length > 0) {
        this.answerService.updateTextArea(this.question.id, this.textAreaAnswer).subscribe(() => {
          this.pService.setPaginationListener(false);
        });
      }
    }
  }

  savePsyOrSuperTextAreaAnswer(event) {
    if (this.superTestId || this.psyTestId) {
      clearTimeout(this.answerTextAreaTimer);
      this.answerTextAreaTimer = setTimeout(() => {
        if (
          (this.question.questionType === 'TEXT_AREA' && event.target.value.length > 0) ||
          (this.question.questionType === 'RICH_TEXT' && event.length > 0 && event !== this.lastRichTextAnswer)
        ) {
          if (this.textAreaAnswer && this.textAreaAnswer.answerId) {
            this.textAreaAnswer.answer = this.question.questionType === 'TEXT_AREA' ? event.target.value : event;

            this.answerService.updateTextArea(this.question.id, this.textAreaAnswer).subscribe(() => {
              this.answerTextAreaControl.patchValue(this.textAreaAnswer.answer);
              if (this.question.questionType === 'RICH_TEXT') {
                this.lastRichTextAnswer = this.textAreaAnswer.answer;
              }
            });
          } else {
            this.textAreaAnswer = {
              questionId: this.question.id,
              answer: this.question.questionType === 'TEXT_AREA' ? event.target.value : event,
              userInfoId: this.userInfoId,
              testResultId: this.testResultId,
            };
            this.answerService.saveTextArea(this.question.id, this.textAreaAnswer).subscribe((result) => {
              const userAnswer = {
                questionId: this.question.id,
                userAnswer: {
                  integerAnswer: null,
                  textAnswer: { id: result.id, answer: this.answerTextAreaControl.value },
                  listAnswer: null,
                },
              };
              this.userAnswer.emit(userAnswer);
              this.textAreaAnswer.answerId = result.id;
              this.answerTextAreaControl.setValue(this.textAreaAnswer.answer);
              if (this.question.questionType === 'RICH_TEXT') {
                this.lastRichTextAnswer = this.textAreaAnswer.answer;
              }
            });
          }
        }
      }, 2000);
    }
  }

  togglePrivacy() {
    this.answerPrivate = !this.answerPrivate;
  }
}
