import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Superscale } from '../models/superscale.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SuperscaleService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<Superscale[]> {
    return this.http.get<Superscale[]>(`${BACKEND_URL}/superscale`).pipe(take(1));
  }

  get(id: number): Observable<Superscale> {
    return this.http.get<Superscale>(`${BACKEND_URL}/superscale/${id}`).pipe(take(1));
  }

  create(superscale: Superscale): Observable<Superscale> {
    return this.http.post<Superscale>(`${BACKEND_URL}/superscale`, superscale).pipe(take(1));
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${BACKEND_URL}/superscale/${id}`).pipe(take(1));
  }

  update(id: number, superscale: Superscale) {
    return this.http.patch(`${BACKEND_URL}/superscale/${id}`, superscale).pipe(take(1));
  }

  archive(id: number): Observable<any> {
    return this.http.delete(BACKEND_URL + '/superscale/' + id + '/archive').pipe(take(1));
  }
}
