/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class BlossomChartController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param userId
     * @param testGroupId
     * @param chartId
     * @returns string
     * @throws ApiError
     */
    public blossomChartControllerBlossomChart(
        userId: number,
        testGroupId: number,
        chartId: number,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/user/{userId}/testGroup/{testGroupId}/chart/{chartId}/blossom-chart.svg',
            path: {
                'userId': userId,
                'testGroupId': testGroupId,
                'chartId': chartId,
            },
        });
    }

    /**
     * @param userId
     * @param testGroupId
     * @param chartId
     * @returns string
     * @throws ApiError
     */
    public blossomChartControllerBlossomChartPng(
        userId: number,
        testGroupId: number,
        chartId: number,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/user/{userId}/testGroup/{testGroupId}/chart/{chartId}/blossom-chart.png',
            path: {
                'userId': userId,
                'testGroupId': testGroupId,
                'chartId': chartId,
            },
        });
    }

    /**
     * @param petalColor
     * @param pistilColor
     * @returns string
     * @throws ApiError
     */
    public blossomChartControllerSvgSymbol(
        petalColor: string,
        pistilColor: string,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/blossom-chart/{petalColor}/{pistilColor}/symbol.png',
            path: {
                'petalColor': petalColor,
                'pistilColor': pistilColor,
            },
        });
    }

}
