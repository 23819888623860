import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlossomChartPetal, BlossomChartPistil } from '../../../../../../../generated/v2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Superscale } from '@app/core/models/superscale.model';
import { SubscaleService } from '@app/core/services/subcale.service';
import { SuperscaleService } from '@app/core/services/superscale.service';
import { EVENT_SUCCESS } from '@app/core/constants';
import { hexToColor } from '@app/admin/pages/chart/blossom/hex-to-color';

@Component({
  selector: 'app-blossom-petal-form',
  templateUrl: './blossom-petal-form.component.html',
  styleUrls: ['./blossom-petal-form.component.scss'],
})
export class BlossomPetalFormComponent implements OnInit {
  form = new FormGroup({
    id: new FormControl(null),
    color: new FormControl(null, [Validators.required]),
    label: new FormControl(null, [Validators.required]),
    order: new FormControl(1, [Validators.required]),
    superscaleId: new FormControl(null, [Validators.required]),
  });

  superscales: Superscale[] = [];

  constructor(
    private dialogRef: MatDialogRef<BlossomPetalFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { petal: BlossomChartPetal; superscales: Superscale[] },
  ) {}

  ngOnInit(): void {
    this.superscales = this.data.superscales;

    if (this.data.petal) {
      this.form.patchValue({
        id: this.data.petal.id,
        color: hexToColor(this.data.petal.color),
        order: this.data.petal.order,
        label: this.data.petal.label,
        superscaleId: this.data.petal.superscaleId,
      });
    }
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close({
        value: {
          ...this.form.value,
          color: `#${this.form.value.color.toHex()}`,
        },
      });
    }
  }

  displaySuperscaleFn = (id: number): string => {
    const superscale = this.superscales.find((s) => s.id === id);

    if (superscale) {
      return superscale.title;
    }

    return '';
  };

  filterSuperscale(value: string): Superscale[] {
    return this.superscales.filter(
      (superscale) =>
        superscale.title.toLowerCase().includes(value.toLowerCase()) ||
        superscale.description?.toLowerCase().includes(value.toLowerCase()),
    );
  }
}
