<h1 mat-dialog-title *ngIf="title; else defaultTitle">
  {{ title }}
</h1>
<div mat-dialog-content *ngIf="message && isHtml" [innerHTML]="sanitizer.bypassSecurityTrustHtml(message)"></div>
<div mat-dialog-content *ngIf="message && !isHtml">
  {{ message }}
</div>
<div mat-dialog-content *ngIf="!message && !isHtml">
  {{ defaultMessage }}
</div>

<ng-template #defaultMessage> Are you sure you want to execute that? </ng-template>

<ng-template #defaultTitle> Information </ng-template>

<div mat-dialog-actions>
  <button mat-button class="right-bottom" (click)="onClose()">Close</button>
  <button mat-button class="right-bottom" color="primary" *ngIf="copy" (click)="onCopy()">Copy</button>
</div>
