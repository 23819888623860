<div class="container">
  <div class="page-list-header">
    <h1>Subscale Questions</h1>
    <button mat-raised-button class="new-button pull-right" color="primary" (click)="onCreate()">
      <mat-icon aria-hidden="false">add</mat-icon>
      Create a Question
    </button>
  </div>

  <app-psb-list
    *ngIf="questions.length"
    [items]="questions"
    [itemsPerPage]="10"
    (edit)="onView($event)"
    (delete)="onDelete($event)"
  >
  </app-psb-list>
</div>
