import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// services
import { TestService } from '@app/core/services/test.service';

// models
import { PsyTest } from '@app/core/models/psy-test.model';
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-create-new-supertest-test-dialog',
  templateUrl: './create-new-supertest-test-dialog.component.html',
  styleUrls: ['./create-new-supertest-test-dialog.component.scss'],
})
export class CreateNewSupertestTestDialogComponent implements OnInit {
  public psyTests: PsyTest[];
  alreadyExistedTests: PsbItem[];
  psyTestFieldUpdate = new Subject<string>();
  psyTestField: string;
  psyTestId: number;
  public superTestTestDialogForm = new FormGroup({
    psyTest: new FormControl('', [Validators.required]),
  });

  constructor(
    private testService: TestService,
    public dialogRef: MatDialogRef<CreateNewSupertestTestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { superTestTests: PsbItem[] },
  ) {
    this.psyTestFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.psyTestField = value;
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.alreadyExistedTests = this.data.superTestTests;
    this.testService.getAllPsyTest().subscribe((result) => {
      if (result) {
        this.psyTests = result.filter((test) => !this.alreadyExistedTests.map((item) => item.id).includes(test.id));
      }
    });
  }

  displayPsyTest(psyTest: PsyTest) {
    return psyTest ? psyTest.title : '';
  }

  onSave() {
    this.dialogRef.close({ event: 'save', data: this.psyTestId });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onChange(psyTest: PsyTest) {
    this.psyTestId = psyTest.id;
  }
}
