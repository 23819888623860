import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { Department } from '../models/department.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class DepartmentService {
  constructor(private http: HttpClient, private router: Router) {}

  getAll() {
    return this.http.get<Department[]>(BACKEND_URL + '/department');
  }

  get(id: number) {
    return this.http.get<Department>(BACKEND_URL + '/department/' + id);
  }

  delete(id: number) {
    return this.http.delete(BACKEND_URL + '/department/' + id);
  }

  getAllByCompanyId(id: number) {
    return this.http.get(BACKEND_URL + '/department/company/' + id);
  }
}
