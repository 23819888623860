<div class="grid-container">
  <div class="page-list-header">
    <span class="psb-page-navigator"> Dashboard > </span>
    <div>
      <form class="row">
        <div class="column" *ngIf="companies">
          <mat-form-field class="company-selector" appearance="outline">
            <mat-label>Company</mat-label>
            <input
              type="text"
              placeholder="Company Name"
              matInput
              [formControl]="companyControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              (optionSelected)="setCompany($event.option.value.id)"
              [displayWith]="displayCompanyFn"
            >
              <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
                {{ company.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="column" *ngIf="testGroups && testGroups.length">
          <mat-form-field class="company-selector" appearance="outline">
            <mat-label>Test Group</mat-label>
            <input
              type="text"
              placeholder="Test Group Name"
              matInput
              [formControl]="testGroupControl"
              [matAutocomplete]="autoTestGroup"
              (focus)="onTestGroupFocus()"
            />
            <mat-autocomplete autoActiveFirstOption #autoTestGroup="matAutocomplete" [displayWith]="displayTestGroupFn">
              <mat-option
                *ngFor="let testGroup of filteredTestGroups | async"
                [value]="testGroup"
                (onSelectionChange)="setTestGroup(testGroup)"
              >
                {{ testGroup.title }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>

  <div [ngClass]="charts.length ? 'row' : 'column order'">
    <div [ngClass]="charts.length ? 'column col-left' : 'column'">
      <h2>Users</h2>
      <app-psb-list
        [items]="usersPsbItems"
        [showSearch]="true"
        [itemsPerPage]="10"
        [isTGM]="isTGM"
        optionButton="Report"
        [options]="reports"
        (selectOption)="openReport($event)"
        textSize="small-text"
      >
      </app-psb-list>
      <div class="row mt-5" *ngIf="!usersPsbItems.length">There are no users register for that company.</div>
    </div>

    <div [ngClass]="charts.length ? 'col-right' : ''">
      <div [ngClass]="{ 'stats-line-panel': charts.length != 0, 'stats-line-panel-nochart': charts.length == 0 }">
        <app-psb-stats-line *ngIf="psbStats?.length" [psbStats]="psbStats"></app-psb-stats-line>
      </div>
      <div *ngIf="charts.length" class="flex-container">
        <div *ngFor="let chart of charts" [ngSwitch]="chart.base" class="flex-item customchart">
          <mat-card class="chart-card">
            <h2>{{ chart.header }}</h2>

            <app-hexagon *ngSwitchCase="'HEXAGON'" [chartId]="chart.id" [testGroupId]="testGroup.id"> </app-hexagon>

            <app-square-plot *ngSwitchCase="'CIRCLE_IN_CARTESIAN'" [chartId]="chart.id" [testGroupId]="testGroup.id">
            </app-square-plot>

            <app-circular-plot
              *ngSwitchCase="'FOUR_PLUS_ONE_CARTESIAN'"
              [chartId]="chart.id"
              [testGroupId]="testGroup.id"
            >
            </app-circular-plot>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
