import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';

// models
import { DecisionTableAnswer } from '@app/core/models/decision-table-answer.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { DecisionArea } from '@app/core/models/decision-area.model';
import { DecisionTableColumn } from '@app/core/models/decision-table-column.model';
import { User } from '@app/core/models/user.model';
import { FilterService } from '@app/core/services/filter.service';

@Component({
  selector: 'app-edit-answer-decision-table',
  templateUrl: './edit-answer-decision-table.component.html',
  styleUrls: ['./edit-answer-decision-table.component.scss'],
})
export class EditAnswerDecisionTableComponent implements OnInit, AfterViewChecked {
  @Input() decisionTableAnswer: any;
  @Input() decisionTableColumns: DecisionTableColumn[];
  @Input() answering: boolean;
  @Input() userInfoId: number;
  @Input() allDecisionArea: DecisionArea[];
  @Output() edit = new EventEmitter<DecisionTableAnswer | boolean>();
  @Input() users: User[];
  @Input() decisionTable: DecisionTable;
  editDecisionTableAnswerForm: FormGroup;
  selectedUsers: any[] = [];
  filteredUsers: Array<Observable<User[]>> = [];
  originalUsers: User[] = [];
  filteredUsersDecisionAreas: Observable<DecisionArea[]>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private decisionTableService: DecisionTableService,
    private roleMandateService: RoleMandateService,
    private filterService: FilterService,
  ) {}

  ngOnInit(): void {
    this.originalUsers = this.users;

    const group: any = {};
    this.decisionTableColumns.forEach((column) => {
      group[this.getFormName(column.title)] = new FormControl('', column.required ? [Validators.required] : []);
    });

    this.editDecisionTableAnswerForm = new FormGroup(group);
    this.decisionTableColumns.forEach((dTC) => {
      const formName = this.getFormName(dTC.title);

      switch (+dTC.type) {
        case 1:
          this.editDecisionTableAnswerForm
            .get(formName)
            .setValue(this.getDecisionAreaIdByName(this.decisionTableAnswer[formName]));
          this.filteredUsersDecisionAreas = this.editDecisionTableAnswerForm.get(formName).valueChanges.pipe(
            startWith(''),
            map((decisionArea) => this._filterDecisionAreas(decisionArea)),
          );
          break;
        case 2:
          this.selectedUsers[formName] = [];
          this.filteredUsers[formName] = this.editDecisionTableAnswerForm.get(formName).valueChanges.pipe(
            startWith(''),
            map((user) => (user ? this._filter(user) : this.filterOriginalUsers())),
          );
          break;
        case 3:
          this.editDecisionTableAnswerForm.get(formName).setValue(this.decisionTableAnswer[formName]);
          break;
      }
    });
  }

  getFormName(columnTitle: string) {
    return this.decisionTableService.getFormName(columnTitle);
  }

  getDecisionAreaIdByName(name: string) {
    const decArea = this.allDecisionArea.find((decisionArea) => decisionArea.name === name);
    return decArea ? decArea : null;
  }

  filterOriginalUsers() {
    return this.originalUsers.filter((user) => user.id !== this.userInfoId);
  }

  private _filter(value: string): User[] {
    if (value.length > 2) {
      const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
      return this.filterService.filterUsersByNameAndDepartmentAndRoleTitle(this.originalUsers, filterValue);
    }
  }

  onClearSearch(name: string, event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.closePanel();
    this.editDecisionTableAnswerForm.get(name).reset();
    this.clearUserList(name);
  }

  clearUserList(name: string) {
    this.users[name].map((user) => {
      user.selected = false;
    });
    this.selectedUsers[name] = [];

    this.filteredUsers[name] = new Observable();
    this.filteredUsers[name] = of(
      this.originalUsers.filter((user) => user.firstName !== null && user.firstName !== ''),
    );
  }

  optionClicked(event: Event, user: User, fromName: string) {
    event.stopPropagation();
    this.toggleSelection(user, fromName);
  }

  toggleSelection(user: User, fromName: string) {
    const dTAnswerUserConnection = {
      fromUserInfoId: this.userInfoId,
      toUserInfoId: user.id,
      decisionTableAnswerId: this.decisionTableAnswer.id,
      columnName: fromName,
    };

    this.decisionTableService
      .saveUserConnectionForDecisionTableAnswer(this.decisionTableAnswer.id, dTAnswerUserConnection)
      .subscribe(() => {
        if (!this.selectedUsers[fromName].length) {
          this.selectedUsers[fromName] = [];
        }

        user.selected = true;
        const i = this.selectedUsers[fromName].findIndex(
          (value) => value.firstName === user.firstName && value.lastName === user.lastName,
        );
        if (i > -1) {
          this.selectedUsers[fromName].splice(i, 1);
        } else {
          this.selectedUsers[fromName].push(Object.assign({}, user));
        }
        this.editDecisionTableAnswerForm.get(fromName).reset();

        this.editDecisionTableAnswerForm
          .get(fromName)
          .setErrors(this.selectedUsers[fromName].length > 0 ? null : { incorrect: true });

        dTAnswerUserConnection['userName'] = user.firstName + ' ' + user.lastName;
        this.decisionTableAnswer[fromName] = this.decisionTableAnswer[fromName]
          ? this.decisionTableAnswer[fromName].concat(dTAnswerUserConnection)
          : [dTAnswerUserConnection];
      });
  }

  saveDecisionTableAnswer() {
    const decisionTableAnswer: DecisionTableAnswer = {
      decisionArea: null,
      text: null,
      userConnections: [],
      texts: [],
    };

    this.decisionTableColumns.forEach((column) => {
      if (+column.type === 1) {
        decisionTableAnswer.decisionArea = {
          id: this.editDecisionTableAnswerForm.get(this.getFormName(column.title)).value.id,
        };
      } else if (+column.type === 2) {
        decisionTableAnswer.userConnections = this.decisionTableAnswer[this.getFormName(column.title)].map(
          (connection) => {
            connection.fromUserInfoId = this.userInfoId;
            connection.columnName = this.getFormName(column.title);
            return connection;
          },
        );
      } else if (+column.type === 3) {
        if (!decisionTableAnswer.text) {
          decisionTableAnswer.text = this.editDecisionTableAnswerForm.get(this.getFormName(column.title)).value;
        } else {
          decisionTableAnswer.texts.push({
            text: this.editDecisionTableAnswerForm.get(this.getFormName(column.title)).value,
            columnName: this.getFormName(column.title),
            columnId: column.id,
          });
        }
      }
    });

    decisionTableAnswer.id = this.decisionTableAnswer.id;

    this.decisionTableService.updateDecisionTableAnswer(decisionTableAnswer).subscribe(() => {
      this.edit.emit(decisionTableAnswer);
    });
  }

  displayUser(value: User[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((user, index) => {
        if (index === 0) {
          displayValue = user.firstName + ' ' + user.lastName;
        } else {
          displayValue += ', ' + user.firstName + ' ' + user.lastName;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  deleteAnswerUserConnection(decisionTableAnswer: any, formName: string, i: number) {
    this.decisionTableService
      .deleteUserConnectionByDecisionTableAnswerIdAndToUserInfoIdAndColumnName(
        decisionTableAnswer.decisionTableAnswerId,
        decisionTableAnswer.toUserInfoId,
        formName,
      )
      .subscribe(() => {
        const usersList = this.decisionTableAnswer[formName];
        usersList.splice(i, 1);
        this.decisionTableAnswer[formName] = usersList;
        this.editDecisionTableAnswerForm.get(formName).setValue(usersList);
        this.editDecisionTableAnswerForm.get(formName).reset();
      });
  }

  isEmptyRequiredUserConnectionColumn() {
    return (
      this.decisionTableColumns.filter((dTC) => {
        if (dTC.required && dTC.type === '2') {
          if (this.decisionTableAnswer[this.getFormName(dTC.title)]?.length === 0) {
            return dTC;
          }
        }
      }).length > 0
    );
  }

  onCancel() {
    this.edit.emit(false);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  updateAnswerPrivacy(decisionTableAnswer: any) {
    this.decisionTableService
      .updatePrivateAnswer(decisionTableAnswer.id, !decisionTableAnswer.private)
      .subscribe(() => (decisionTableAnswer.private = !decisionTableAnswer.private));
  }

  private _filterDecisionAreas(name: string): DecisionArea[] {
    const filterValue = typeof name === 'string' ? name.toLowerCase() : '';

    return this.allDecisionArea.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  displayDecisionArea(decisionArea: DecisionArea): string {
    return decisionArea ? decisionArea.name : '';
  }
}
