<mat-card>
  <mat-card-header>
    <h2>Move Role Navigator</h2>
  </mat-card-header>
  <mat-card-content>
    <span class="mat-card-description">
      By moving a Role Navigator all the answers will be transferred to the target user.
    </span>
    <form
      [formGroup]="migrateForm"
      (submit)="migrateUser(migrateUserFormDirective)"
      class="migrate-form"
      #migrateUserFormDirective="ngForm"
    >
      <div class="column">
        <mat-form-field>
          <mat-label>From</mat-label>
          <input matInput type="text" formControlName="sourceUser" [matAutocomplete]="source" />
          <mat-autocomplete #source="matAutocomplete">
            <mat-option
              *ngFor="let user of filteredSourceUsers$ | async"
              [value]="user"
              (onSelectionChange)="selectUser('sourceUser', user)"
            >
              {{ user.firstName }} {{ user.lastName }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="migrateForm.get('sourceUser').errors">Please, enter a valid name.</mat-error>
        </mat-form-field>
      </div>
      <div class="column">
        <mat-form-field>
          <mat-label>To</mat-label>
          <input matInput type="text" formControlName="targetUser" [matAutocomplete]="target" />
          <mat-autocomplete #target="matAutocomplete">
            <mat-option
              *ngFor="let user of filteredTargetUsers$ | async"
              [value]="user"
              (onSelectionChange)="selectUser('targetUser', user)"
            >
              {{ user.firstName }} {{ user.lastName }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="migrateForm.get('targetUser').errors">Please, enter a valid name.</mat-error>
        </mat-form-field>
      </div>
      <button mat-raised-button color="primary" class="btn-success" type="submit" [disabled]="!migrateForm.valid">
        Move
      </button>
    </form>
  </mat-card-content>
</mat-card>
