import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// services
import { ReportModelService } from '@app/core/services/report-model.service';

// models
import { ReportModel } from '@app/core/models/report-model.model';

@Component({
  selector: 'app-report-model-detail',
  templateUrl: './report-model-detail.component.html',
  styleUrls: ['./report-model-detail.component.scss'],
})
export class ReportModelDetailComponent implements OnInit {
  reportModelId: number;
  reportModel: ReportModel;

  constructor(private reportModelService: ReportModelService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.reportModelId = Number(this.route.snapshot.params.id);
    this.getReportModelDetail();
  }

  getReportModelDetail() {
    this.reportModelService.getOne(this.reportModelId).subscribe((res) => {
      this.reportModel = res;
    });
  }
}
