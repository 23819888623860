import { Component, AfterViewInit, Input } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-optimal-score',
  templateUrl: './optimal-score.component.html',
  styleUrls: ['./optimal-score.component.scss'],
})
export class OptimalScoreComponent implements AfterViewInit {
  @Input() chartId: string;
  @Input() score: number;
  @Input() description: string;
  @Input() size: string;

  margin = { top: 10, right: 10, bottom: 0, left: 10 };
  width: number;
  height: number;
  svg: any;

  mainColor = '#425465'; // color for main wave
  whiteColor = '#ffffff'; // color for main wave
  marginColor = '#F1592A'; // color for user
  textColor = '#000000';

  colorsMap = {
    1: '#dc143c',
    2: '#f5c71a',
    3: '#2a8000',
  };

  constructor() {
    this.width = 290 - this.margin.left - this.margin.right;
    this.height = 380 - this.margin.top - this.margin.bottom;
  }

  ngAfterViewInit() {
    this.svg = d3
      .select('figure#optimal-score' + this.chartId)
      .append('svg')
      .attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr(
        'transform',
        'translate(' + (this.width - this.margin.left) / 2 + ',' + (this.height / 2 + this.margin.top) + ')',
      );

    //Create scale for use in the visualizations
    const scale = d3.scaleLinear().domain([0, 10]).range([0, 2]);
    const endAngle = scale(this.score) * Math.PI;

    // Create color bins for scores, in this case read is anything 3.5 and below, and green is anything 6.5 and above. It can be changed to whatever dimension you wish
    const colors = d3.scaleThreshold().domain([3.5, 6.5]).range([1, 2, 3]);

    // Creating an arc based on trigonometry. This feeds the the
    // scores from the json into our new scale function to format proper arcs
    const arcGen = d3.arc().innerRadius(70).outerRadius(90).startAngle(0).endAngle(endAngle);

    // Append inner blue arc
    const dot2 = this.svg
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 65)
      .attr('stroke', '#425465')
      .attr('stroke-width', 4)
      .attr('fill', 'white');

    // Append outer color arc
    this.svg
      .append('path')
      .attr('d', arcGen)
      .attr('fill', this.colorsMap[colors(this.score)])
      .attr('stroke', 'none')
      .attr('stroke-width', 1);

    // Circle for animation purposes
    const dot3 = this.svg
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 120)
      // .attr('stroke', '#F1592A')
      .attr('fill', 'white');

    // Append user scores as text
    const score = this.svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('x', -35)
      .style('font-size', '25px')
      .style('font-family', 'Arial')
      .style('font-weight', 'Bold')
      .attr('fill', this.mainColor);

    if (this.score) {
      // Append the "/10" in graph
      const score2 = this.svg
        .append('text')
        .attr('x', 10)
        .attr('y', 20)
        .attr('dy', '.35em')
        .text('10')
        .style('font-size', '25px')
        .style('font-family', 'Arial')
        .style('font-weight', 'Bold')
        .attr('fill', this.mainColor);
    } else {
      // Append the "/A" in graph
      const score2 = this.svg
        .append('text')
        .attr('x', 10)
        .attr('y', 20)
        .attr('dy', '.35em')
        .text('A')
        .style('font-size', '25px')
        .style('font-family', 'Arial')
        .style('font-weight', 'Bold')
        .attr('fill', this.mainColor);
    }

    const lines = this.svg
      .append('line')
      .attr('x1', 0 - 50)
      .attr('y1', 0)
      .attr('x2', 0 + 50)
      .attr('y2', 0)
      .attr('stroke-width', 3)
      .attr('stroke', this.mainColor)
      .attr('transform', function (d) {
        return 'rotate(-65)';
      });

    // Append Title generated from whatever is in the JSON
    let breaklineDescriptionPartOne = '';
    let breaklineDescriptionPartTwo = '';
    let breaklineDescriptionPartThree = '';
    if (this.description && this.description.length > 18) {
      const words = this.description.split(' ');

      for (let i = 0; i < words.length; i++) {
        if (breaklineDescriptionPartOne.length < 10) {
          breaklineDescriptionPartOne = breaklineDescriptionPartOne + words[i] + ' ';
        } else if (breaklineDescriptionPartOne.length >= 10 && breaklineDescriptionPartTwo.length < 10) {
          breaklineDescriptionPartTwo = breaklineDescriptionPartTwo + words[i] + ' ';
        } else if (breaklineDescriptionPartTwo.length >= 10) {
          breaklineDescriptionPartThree = breaklineDescriptionPartThree + words[i] + ' ';
        }
      }
    }

    const titlePartOne = this.svg
      .append('text')
      .text(breaklineDescriptionPartOne ? breaklineDescriptionPartOne : this.description ? this.description : '')
      .attr('text-anchor', 'middle')
      .attr('y', 107)
      .attr('dy', '.35em')
      .style('font-size', '24px')
      .style('font-weight', 'Bold')
      .style('font-family', 'Arial')
      .attr('fill', '#66809a');

    const titlePartTwo = this.svg
      .append('text')
      .text(breaklineDescriptionPartTwo ? breaklineDescriptionPartTwo : '')
      .attr('text-anchor', 'middle')
      .attr('y', 132)
      .attr('dy', '.35em')
      .style('font-size', '24px')
      .style('font-weight', 'Bold')
      .style('font-family', 'Arial')
      .attr('fill', '#66809a');

    const titlePartThree = this.svg
      .append('text')
      .text(breaklineDescriptionPartThree ? breaklineDescriptionPartThree : '')
      .attr('text-anchor', 'middle')
      .attr('y', 157)
      .attr('dy', '.35em')
      .style('font-size', '24px')
      .style('font-weight', 'Bold')
      .style('font-family', 'Arial')
      .attr('fill', '#66809a');

    // Update function for animations
    dot3
      .transition()
      .delay(function (d, i) {
        return i * 3;
      })
      .duration(2000)
      .attr('r', 0);

    if (this.score) {
      const currentValue = this.score == 10.0 ? 10 : this.score;
      score.transition().ease(d3.easeLinear).delay(2000).text(currentValue);
    } else {
      score.transition().ease(d3.easeLinear).delay(2000).text('N');
    }
  }

  /*tweenText(newValue) {
    return function () {
      // get current value as starting point for tween animation
      var currentValue = this.textContent;
      // create interpolator and do not show nasty floating numbers
      var i = d3.interpolateRound(currentValue, newValue);

      return function (t) {
        this.textContent = Math.round(i(t));
      };
    };
  }*/
}
