export const ROLE_ADMIN = 'ROLE_ADMIN';

export const ROLE_ORGANIZATIONAL_ADMIN = 'ROLE_ORGANIZATIONAL_ADMIN';

export const ROLE_TGM = 'ROLE_TGM';

export const ROLE_TEST_USER = 'ROLE_TEST_USER';

export const EVENT_CANCEL = 'cancel';
export const EVENT_SUCCESS = 'success';
export const EVENT_ADD = 'add';
export const EVENT_SAVE = 'save';
export const EVENT_ERROR = 'error';
export const EVENT_CLOSE = 'close';
