import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Notification } from '../models/notification.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class RoleMandateNotificationService {
  constructor(private http: HttpClient) {}

  getRoleMandateNotificationById(roleMandateId: number) {
    return this.http.get<Notification[]>(BACKEND_URL + '/role-mandate-notification/' + roleMandateId);
  }

  saveRoleMandateNotification(roleMandateId: number, notificationId: number) {
    return this.http.post(BACKEND_URL + '/role-mandate-notification/' + roleMandateId, { notificationId });
  }

  deleteByRoleMandateIdAndNotificationId(roleMandateId: number, notificationId: number) {
    return this.http.delete(
      BACKEND_URL + '/role-mandate-notification/' + roleMandateId + '/notification/' + notificationId,
    );
  }
}
