import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ReportService } from '@app/core/services/report.service';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-edit-report-title-dialog',
  templateUrl: './edit-report-title-dialog.component.html',
  styleUrls: ['./edit-report-title-dialog.component.scss'],
})
export class EditReportTitleDialogComponent implements OnInit {
  form = this.fb.group({
    title: ['', [Validators.required]],
  });

  constructor(
    private reportService: ReportService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: { id: number; title: string },
    private dialogRef: MatDialogRef<EditReportTitleDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.form.patchValue({ title: this.data.title });
  }

  onFormSubmit() {
    const { id: reportId } = this.data;
    const { title } = this.form.value;

    this.reportService.update(reportId, { title }).subscribe(() => {
      this.dialogRef.close({ event: EVENT_SUCCESS, newTitle: title });
    });
  }
}
