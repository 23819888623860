import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

// services
import { FormService } from '@app/core/services/form.service';

// models
import { Country } from '@app/core/models/country.model';
import { State } from '@app/core/models/state.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-state-modal',
  templateUrl: './state-modal.component.html',
  styleUrls: ['./state-modal.component.scss'],
})
export class StateModalComponent implements OnInit {
  stateModalForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    abbrev: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    countryId: new FormControl('', [Validators.required]),
  });

  countries: Observable<Country[]>;

  constructor(
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; stateId: number; state: State },
    public dialogRef: MatDialogRef<StateModalComponent>,
  ) {}

  ngOnInit(): void {
    this.countries = this.formService.getCountries();

    if (this.data.isEdit) {
      this.stateModalForm.get('id').setValue(this.data.stateId);
      this.stateModalForm.get('abbrev').setValue(this.data.state.abbrev);
      this.stateModalForm.get('name').setValue(this.data.state.name);
      this.stateModalForm.get('countryId').setValue(this.data.state.countryId);
    }
  }

  compareFn(x: any, y: any): boolean {
    return parseInt(x, 10) === y ? true : false;
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onSave() {
    if (this.data.isEdit) {
      this.dialogRef.close({ event: 'edit', data: this.stateModalForm.value });
    } else {
      this.dialogRef.close({ event: 'save', data: this.stateModalForm.value });
    }
  }
}
