import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { CreateNewSupertestTestDialogComponent } from '../create-new-supertest-test-dialog/create-new-supertest-test-dialog.component';

// services
import { SuperTestService } from '@app/core/services/super-test.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { TestService } from '@app/core/services/test.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';
import { SuperTestTest } from '@app/core/models/supertest-test.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-super-test-test-list',
  templateUrl: './super-test-test-list.component.html',
  styleUrls: ['./super-test-test-list.component.scss'],
})
export class SuperTestTestListComponent implements OnInit {
  @Input() superTestId: number;
  superTestTests: PsbItem[] = [];
  lstSuperTestTest: SuperTestTest[] = [];

  constructor(
    private router: Router,
    private superTestService: SuperTestService,
    private testService: TestService,
    private snackBar: SnackBarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getAllSuperTestTest();
  }

  getAllSuperTestTest() {
    this.superTestTests = [];
    this.lstSuperTestTest = [];

    this.superTestService.getAllSuperTestTestById(this.superTestId).subscribe((superTestTests) => {
      superTestTests.forEach((superTestTest) => {
        this.lstSuperTestTest.push(superTestTest);

        this.testService.getPsyTestById(superTestTest.psyTestId).subscribe((psyTest) => {
          if (psyTest) {
            const item: PsbItem = {
              id: psyTest.id,
              title: psyTest.title,
            };

            this.superTestTests.push(item);
          }
        });
      });
    });
  }

  onView(testId: number) {
    this.router.navigate([`/psy-test/${testId}/detail`]);
  }

  onDelete(superTestTestId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.testService.deleteSuperTestTest(superTestTestId).subscribe(() => {
            this.snackBar.info('Test deleted successfully');
            this.getAllSuperTestTest();
          });
        }
      });
  }

  createNewTest() {
    this.dialog
      .open(CreateNewSupertestTestDialogComponent, { data: { superTestTests: this.superTestTests } })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          const superTestTest: SuperTestTest = {
            id: undefined,
            psyTestId: result.data,
            supertestId: this.superTestId,
          };

          this.testService.saveSupertestTest(superTestTest.psyTestId, superTestTest).subscribe((response) => {
            if (response) {
              this.getAllSuperTestTest();
              this.snackBar.info('Test created with success!');
            }
          });
        }
      });
  }
}
