<div class="row">
  <div class="column" style="margin: 10px 0px">
    <p [innerHTML]="model.leadSentence.text"></p>
    <div class="chart-sentences" *ngFor="let sentence of model.sentences">
      <div class="chart-sentence" [innerHTML]="sentence.text | replaceName: userName"></div>
    </div>
    <div *ngIf="model.agilitySentence" [innerHTML]="model.agilitySentence.text"></div>
    <div *ngIf="!model.agilitySentence && model.defaultSentenceEnabled">
      <div [ngSwitch]="model.agilityType">
        <div *ngSwitchCase="'above50'">
          <div [innerHTML]="model.agilityBothAbove50DefaultSentence"></div>
        </div>
        <div *ngSwitchCase="'bellow50'">
          <div [innerHTML]="model.agilityBothBelow50DefaultSentence"></div>
        </div>
        <div *ngSwitchCase="'aboveandbellow50'">
          <div [innerHTML]="model.agilityOneAbove50OneBelow50DefaultSentence"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="column violin-chart">
    <app-violin
      [leftValue]="model.superscaleLeft ? model.superscaleLeft.percentile : model.subscaleLeft.percentile"
      [rightValue]="model.superscaleRight ? model.superscaleRight?.percentile : model.subscaleRight?.percentile"
      [centerValue]="model.subscaleCenter?.percentile"
      [leftLabel]="model.leftLabel"
      [rightLabel]="model.rightLabel"
      [centerLabel]="model.centerLabel"
      [chartName]="model.id"
      [scalesLeftResults]="model.superscaleLeftTestGroupResults"
      [scalesRightResults]="model.superscaleRightTestGroupResults"
      [scalesCenterResults]="model.subscaleCenterTestGroupResults"
    >
    </app-violin>
  </div>
</div>
