import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatDialog } from '@angular/material/dialog';

// components
import { SubsectionFormComponent } from '@app/core/models/subsection-form-component';
import { EditRangeDialogComponent } from '@app/admin/pages/report-wizard/edit-range-dialog/edit-range-dialog.component';
import { EditDoubleRangeDialogComponent } from '@app/admin/pages/report-wizard/edit-double-range-dialog/edit-double-range-dialog.component';

// services
import { SuperscaleService } from '@app/core/services/superscale.service';
import { SubscaleService } from '@app/core/services/subcale.service';

// models
import { Superscale } from '@app/core/models/superscale.model';
import { Subscale } from '@app/core/models/subscale.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

type ViolinChartScalesResults = {
  left: { percentile: number }[];
  center: { percentile: number }[];
  right: { percentile: number }[];
};

@Component({
  selector: 'app-section-chart-form',
  templateUrl: './section-chart-form.component.html',
  styleUrls: ['./section-chart-form.component.scss'],
})
export class SectionChartFormComponent implements OnInit, SubsectionFormComponent {
  @Input() model: any;
  defaultScaleValue = true;
  subscales: Subscale[] = [];
  superscales: Superscale[] = [];
  subscaleFieldUpdate = new Subject<string>();
  subscaleField: string;
  superscaleFieldUpdate = new Subject<string>();
  superscaleField: string;
  violinChartScalesResults: ViolinChartScalesResults;

  form: FormGroup;

  addedRanges = {
    below50: [],
    above50: [],
    aboveBelow50: [],
  };

  addedDoubleRanges = [];
  editorConfig: AngularEditorConfig = {
    height: '5rem',
    editable: true,
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };
  subscaleCenterTitle: string;

  constructor(
    private fb: FormBuilder,
    private subscaleService: SubscaleService,
    private superscaleService: SuperscaleService,
    private dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      heading: ['', [Validators.required]],
      positiveHeading: [''],
      concernHeading: [''],
      agilityBothAbove50DefaultSentence: [''],
      agilityBothBelow50DefaultSentence: [''],
      agilityOneAbove50OneBelow50DefaultSentence: [''],
      doubleRangesDefaultSentence: [''],
      defaultSentenceEnabled: [false],
      leadSentence: this.createSentenceFormGroup(),
      useSubscale: [true, [Validators.required]],
      chartTitle: [''],
      subscales: this.fb.group({
        left: this.createSubscaleFormGroup(),
        center: this.createSubscaleFormGroup(true),
        right: this.createSubscaleFormGroup(),
      }),
      superscales: this.fb.group({
        left: ['', [Validators.required]],
        center: this.createSubscaleFormGroup(true),
        right: ['', [Validators.required]],
      }),
      ranges: this.fb.group({
        above50: this.createAgilityRangeFormGroup(),
        below50: this.createAgilityRangeFormGroup(),
        aboveBelow50: this.createAgilityRangeFormGroup(),
      }),
      doubleRanges: this.fb.group({
        from1: ['', [Validators.required]],
        to1: ['', [Validators.required]],
        from2: ['', [Validators.required]],
        to2: ['', [Validators.required]],
        sentence: this.createSentenceFormGroup(),
      }),
      labels: this.fb.group({
        left: ['', [Validators.required]],
        center: ['', [Validators.required]],
        right: ['', [Validators.required]],
      }),
    });

    this.subscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.subscaleField = value;
      }
    });

    this.superscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.superscaleField = value;
      }
    });
  }

  ngOnInit(): void {
    this.setInitialChartData();
    if (this.model) {
      this.loadFormData();
    }
    forkJoin({
      subscales: this.subscaleService.getAll(),
      superscales: this.superscaleService.getAll(),
    }).subscribe(({ subscales, superscales }) => {
      this.subscales = subscales.map((subscale) => {
        if (subscale.cardsortSubscale === 1) {
          return { ...subscale, title: `${subscale.title} (card-sort)` };
        }
        return subscale;
      });
      this.superscales = superscales;
      this.setSubscaleCenterTitle();
    });
  }

  createSentenceFormGroup() {
    return this.fb.group({
      option: ['he', [Validators.required]],
      text: this.fb.group({
        he: ['', [Validators.required]],
        she: [''],
        they: [''],
      }),
    });
  }

  createAgilityRangeFormGroup() {
    return this.fb.group({
      from: ['', [Validators.required]],
      to: ['', [Validators.required]],
      sentence: this.createSentenceFormGroup(),
    });
  }

  createSubscaleFormGroup(center?: boolean) {
    return this.fb.group({
      id: [center ? 363 : '', [Validators.required]],
    });
  }

  addRange(type: 'below50' | 'above50' | 'aboveBelow50') {
    const formGroup = this.form.get('ranges').get(type);

    const { value } = formGroup;
    this.addedRanges[type].push({
      from: value.from,
      to: value.to,
      sentence: {
        he: value.sentence.text.he,
        she: value.sentence.text.she,
        they: value.sentence.text.they,
      },
    });

    formGroup.patchValue({
      from: value.to,
      to: value.to + 1,
      sentence: {
        option: 'he',
        text: {
          he: '',
          she: '',
          they: '',
        },
      },
    });
  }

  addDoubleRange() {
    const formGroup = this.form.get('doubleRanges');
    const { value } = formGroup;

    this.addedDoubleRanges.push({
      from1: value.from1,
      to1: value.to1,
      from2: value.from2,
      to2: value.to2,
      sentence: {
        he: value.sentence.text.he,
        she: value.sentence.text.she,
        they: value.sentence.text.they,
      },
    });

    formGroup.patchValue({
      from1: value.to1,
      to1: value.to1 + 1,
      from2: value.to2,
      to2: value.to2 + 1,
      sentence: {
        option: 'he',
        text: {
          he: '',
          she: '',
          they: '',
        },
      },
    });
  }

  removeRange(type: 'below50' | 'above50' | 'aboveBelow50', idx: number) {
    this.addedRanges[type] = this.addedRanges[type].filter((_, rangeIdx) => idx !== rangeIdx);
  }

  removeDoubleRange(idx: number) {
    this.addedDoubleRanges = this.addedDoubleRanges.filter((_, rangeIdx) => idx !== rangeIdx);
  }

  onEditRange(type: 'below50' | 'above50' | 'aboveBelow50', j: number) {
    const data = this.addedRanges[type].filter((_, rangeIdx) => j === rangeIdx)[0];
    this.dialog
      .open(EditRangeDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event === EVENT_SUCCESS) {
          if (result.range) {
            this.addedRanges[type][j] = result.range;
          }
        }
      });
  }

  onEditDoubleRange(idx: number) {
    const data = this.addedDoubleRanges.filter((_, rangeIdx) => idx === rangeIdx)[0];
    this.dialog
      .open(EditDoubleRangeDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event === EVENT_SUCCESS) {
          if (result.range) {
            this.addedDoubleRanges[idx] = result.range;
          }
        }
      });
  }

  getSubsection() {
    const { value } = this.form;

    const subsection: any = {
      id: this.model?.id,
      heading: value.heading,
      positiveHeading: value.positiveHeading,
      concernHeading: value.concernHeading,
      useSubscale: value.useSubscale,
      chartTitle: value.chartTitle,
      leadSentence: {
        he: value.leadSentence.text.he,
        she: value.leadSentence.text.she,
        they: value.leadSentence.text.they,
      },
      agilityBothAbove50DefaultSentence: value.agilityBothAbove50DefaultSentence,
      agilityBothBelow50DefaultSentence: value.agilityBothBelow50DefaultSentence,
      agilityOneAbove50OneBelow50DefaultSentence: value.agilityOneAbove50OneBelow50DefaultSentence,
      doubleRangesDefaultSentence: value.doubleRangesDefaultSentence,
      defaultSentenceEnabled: value.defaultSentenceEnabled,
      labels: {
        left: value.labels.left,
        center: value.labels.center,
        right: value.labels.right,
      },
      ranges: {
        centerBelow50: this.addedRanges.below50.map(this.transformRange),
        centerAbove50: this.addedRanges.above50.map(this.transformRange),
        centerAboveBelow: this.addedRanges.aboveBelow50.map(this.transformRange),
      },
      doubleRanges: this.addedDoubleRanges.map((doubleRange) => ({
        from1: doubleRange.from1,
        to1: doubleRange.to1,
        from2: doubleRange.from2,
        to2: doubleRange.to2,
        text: {
          he: doubleRange.sentence?.he || doubleRange.reportText?.he,
          she: doubleRange.sentence?.she || doubleRange.reportText?.she,
          they: doubleRange.sentence?.they || doubleRange.reportText?.they,
        },
      })),
    };

    if (value.useSubscale) {
      subsection.subscales = {
        left: {
          id: value.subscales.left.id?.id,
        },
        center: {
          id: value.subscales.center.id?.id,
        },
        right: {
          id: value.subscales.right.id?.id,
        },
      };
    } else {
      subsection.superscales = {
        left: {
          id: value.superscales.left?.id,
        },
        center: {
          id: value.superscales.center.id?.id,
        },
        right: {
          id: value.superscales.right?.id,
        },
      };
    }

    return subsection;
  }

  transformRange(range: any) {
    return {
      from: range.from,
      to: range.to,
      text: {
        he: range.sentence?.he || range.reportText?.he,
        she: range.sentence?.she || range.reportText?.she,
        they: range.sentence?.they || range.reportText?.they,
      },
    };
  }

  private loadFormData(): void {
    this.form.get('heading').setValue(this.model.heading);
    this.form.get('positiveHeading').setValue(this.model.positiveHeading);
    this.form.get('concernHeading').setValue(this.model.concernHeading);
    this.form.get('agilityBothAbove50DefaultSentence').setValue(this.model.agilityBothAbove50DefaultSentence);
    this.form.get('agilityBothBelow50DefaultSentence').setValue(this.model.agilityBothBelow50DefaultSentence);
    this.form
      .get('agilityOneAbove50OneBelow50DefaultSentence')
      .setValue(this.model.agilityOneAbove50OneBelow50DefaultSentence);
    this.form.get('doubleRangesDefaultSentence').setValue(this.model.doubleRangesDefaultSentence);
    this.form.get('defaultSentenceEnabled').setValue(this.model.defaultSentenceEnabled === 0 ? false : true);
    const formLeadSentence = this.form.get('leadSentence');
    const modelLeadSentence = this.model.leadSentence;
    Object.keys(modelLeadSentence).forEach((key: string) => {
      if (key !== 'id') {
        if (modelLeadSentence[key]) {
          formLeadSentence.get('option').setValue(key);
        }
      }
    });
    formLeadSentence.get('text.he').setValue(this.model.leadSentence.he);
    formLeadSentence.get('text.she').setValue(this.model.leadSentence.she);
    formLeadSentence.get('text.they').setValue(this.model.leadSentence.they);
    this.form.get('useSubscale').setValue(this.model.useSubscale);
    this.form.get('chartTitle').setValue(this.model.chartTitle);
    const formSubscales = this.form.get('subscales');
    const modelSubscales = this.model.subscales;
    if (modelSubscales) {
      Object.keys(modelSubscales).forEach((key: string) => {
        if ('id' in modelSubscales[key]) {
          formSubscales.get(`${key}.id`).setValue(modelSubscales[key]);
        }
      });
    }
    const formSuperscales = this.form.get('superscales');
    const modelSuperscales = this.model.superscales;
    if (modelSuperscales) {
      Object.keys(modelSuperscales).forEach((key: string) => {
        if ('id' in modelSuperscales[key]) {
          if (key !== 'center') {
            formSuperscales.get(`${key}`).setValue(modelSuperscales[key]);
          } else {
            formSuperscales.get(`${key}.id`).setValue(modelSuperscales[key]);
          }
        }
      });
    }
    this.addedDoubleRanges = this.model.doubleRanges.map((doubleRange) => {
      doubleRange.sentence = doubleRange.text;
      return doubleRange;
    });

    if (typeof this.model.ranges !== 'undefined') {
      if ('centerAbove50' in this.model.ranges) {
        this.model.ranges.centerAbove50.filter((range) => {
          range.sentence = range.text;
          this.addedRanges['above50'] = [...this.addedRanges['above50'], range];
        });
      }
      if ('centerBelow50' in this.model.ranges) {
        this.model.ranges.centerBelow50.filter((range) => {
          range.sentence = range.text;
          this.addedRanges['below50'] = [...this.addedRanges['below50'], range];
        });
      }
      if ('centerAboveBelow' in this.model.ranges) {
        this.model.ranges.centerAboveBelow.filter((range) => {
          range.sentence = range.text;
          this.addedRanges['aboveBelow50'] = [...this.addedRanges['aboveBelow50'], range];
        });
      }
    }
    const formLabels = this.form.get('labels');
    const modelLabels = this.model.labels;
    if (modelLabels) {
      Object.keys(modelLabels).forEach((key: string) => {
        formLabels.get(`${key}`).setValue(modelLabels[key]);
      });
    }
  }

  displaySubscale(subscale: Subscale): string {
    return subscale ? subscale.title : '';
  }

  displaySuperscale(superscale: Superscale): string {
    return superscale ? superscale.title : '';
  }

  setSubscaleCenterTitle(): void {
    if (this.form.get('useSubscale').value) {
      this.subscaleCenterTitle = `(${
        this.subscales.find((subscale) => subscale.id === this.form.get('subscales.center.id').value.id).title
      })`;
    } else {
      this.subscaleCenterTitle = `(${
        this.subscales.find((subscale) => subscale.id === this.form.get('superscales.center.id').value.id).title
      })`;
    }
  }

  private setInitialChartData(): void {
    const leftScaleResults = [];
    const rightScaleResults = [];
    const centerScaleResults = [];

    for (let index = 0; index < 10; index++) {
      leftScaleResults.push({ percentile: Math.random() * 100 });
      rightScaleResults.push({ percentile: Math.random() * 100 });
      centerScaleResults.push({ percentile: Math.random() * 100 });
    }

    this.violinChartScalesResults = {
      left: leftScaleResults,
      center: centerScaleResults,
      right: rightScaleResults,
    };
  }
}
