import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-psb-table-search',
  templateUrl: './psb-table-search.component.html',
  styleUrls: ['./psb-table-search.component.scss'],
})
export class PsbTableSearchComponent {
  @Input() data: MatTableDataSource<any>;
  @Input() placeholder: string;
  @Output() filter = new EventEmitter<MatTableDataSource<any>>();

  constructor() {}

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data.filter = filterValue.trim().toLowerCase();
  }
}
