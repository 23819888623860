<h1 mat-dialog-title>{{ data.isEdit ? 'Edit ' + sectionTitle : 'Add new section to chapter' }}</h1>
<mat-horizontal-stepper #stepper mat-dialog-content>
  <mat-step *ngIf="!data.isEdit" label="Select a form" [stepControl]="form">
    <form id="addSectionForm" [formGroup]="form" (submit)="onSubmit()">
      <mat-button-toggle-group formControlName="option">
        <mat-button-toggle value="existing">Select an existing section</mat-button-toggle>
        <mat-button-toggle value="new">Create a new one</mat-button-toggle>
      </mat-button-toggle-group>
    </form>
    <div mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close>
        <mat-icon>block</mat-icon>
        Cancel
      </button>
      <button mat-flat-button color="primary" matStepperNext>
        <mat-icon>arrow_forward</mat-icon>
        Next
      </button>
    </div>
  </mat-step>

  <mat-step label="Fill required fields" [stepControl]="form">
    <form [formGroup]="form">
      <mat-form-field *ngIf="form.value.option === 'existing'" style="width: 600px">
        <mat-label>Select a section</mat-label>
        <input
          matInput
          required
          type="text"
          (ngModelChange)="this.sectionFieldUpdate.next($event)"
          formControlName="section"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySection">
          <mat-option *ngFor="let section of sections | filter: sectionField" [value]="section">
            {{ section.title }} [{{ section.created_at | date: 'MMM d, y, h:mm a' }}] [{{ section.type }}]
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div *ngIf="form.value.option === 'new'">
        <mat-form-field>
          <mat-label>Select a type</mat-label>
          <mat-select required formControlName="type">
            <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Insert a title</mat-label>
          <input matInput required type="text" formControlName="title" />
        </mat-form-field>
      </div>

      <div *ngIf="form.value.option === 'new' || form.value.option === 'edit'">
        <div class="subsection-form" [ngSwitch]="form.value.type">
          <app-section-paragraph-form
            [model]="data.section?.subsection"
            *ngSwitchCase="'Paragraph'"
            #subsectionForm
          ></app-section-paragraph-form>
          <app-section-bullet-point-form
            [model]="data.section?.subsection"
            *ngSwitchCase="'BulletPoint'"
            #subsectionForm
          ></app-section-bullet-point-form>
          <app-section-all-combination-paragraph-form
            [model]="data.section?.subsection"
            *ngSwitchCase="'AllCombinationsParagraph'"
            #subsectionForm
          ></app-section-all-combination-paragraph-form>
          <app-section-ordinal-paragraph-form
            [model]="data.section?.subsection"
            *ngSwitchCase="'OrdinalParagraph'"
            #subsectionForm
          ></app-section-ordinal-paragraph-form>
          <app-section-chart-form
            [model]="data.section?.subsection"
            *ngSwitchCase="'Chart'"
            #subsectionForm
          ></app-section-chart-form>
          <app-section-scorecards-form
            [model]="data.section?.subsection"
            *ngSwitchCase="'Scorecards'"
            #subsectionForm
          ></app-section-scorecards-form>
        </div>
      </div>
    </form>

    <div *ngIf="!data.isEdit" mat-dialog-actions align="end">
      <button id="stepper-previews-button" mat-flat-button matStepperPrevious>
        <mat-icon>arrow_back</mat-icon>
        Back
      </button>
      <button
        id="save-button"
        mat-flat-button
        color="primary"
        type="submit"
        form="addSectionForm"
        [disabled]="isInvalidForm"
      >
        <mat-icon>save_alt</mat-icon>
        Save
      </button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
<div *ngIf="data.isEdit" mat-dialog-actions align="end">
  <button id="cancel-button" *ngIf="data.isEdit" mat-flat-button (click)="onCancel()">
    <mat-icon>block</mat-icon>
    Cancel
  </button>
  <button
    *ngIf="data.isEdit"
    mat-flat-button
    color="primary"
    form="addSectionForm"
    (click)="onSubmit()"
    [disabled]="isInvalidForm"
  >
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
  <button *ngIf="data.isEdit" mat-flat-button form="addSectionForm" (click)="onSubmit(true)">
    <mat-icon>close</mat-icon>
    Close
  </button>
</div>
