<h1 mat-dialog-title>{{ data.chart.name }}</h1>
<div mat-dialog-content [ngSwitch]="data.chart.base" class="preview">
  <app-hexagon
    class="chart"
    *ngSwitchCase="'HEXAGON'"
    [chartId]="data.chart.id"
    [testGroupId]="data.testGroupId"
    [chartSelectedUsers]="selectedUsers"
  >
  </app-hexagon>

  <app-circular-plot
    class="chart"
    *ngSwitchCase="'FOUR_PLUS_ONE_CARTESIAN'"
    [chartId]="data.chart.id"
    [testGroupId]="data.testGroupId"
    [chartSelectedUsers]="selectedUsers"
  >
  </app-circular-plot>

  <app-square-plot
    class="chart"
    *ngSwitchCase="'CIRCLE_IN_CARTESIAN'"
    [chartId]="data.chart.id"
    [testGroupId]="data.testGroupId"
    [chartSelectedUsers]="selectedUsers"
  >
  </app-square-plot>

  <app-blossom-graph-preview
    class="chart"
    *ngSwitchCase="'BLOSSOM_GRAPH'"
    [chartId]="data.chart.id"
    [testGroupId]="data.testGroupId"
  >
  </app-blossom-graph-preview>

  <p *ngSwitchDefault><b>:(</b></p>

  <app-filter-chart-users
    [testGroupId]="data.testGroupId"
    [chartId]="data.chart.id"
    (filteredUsers)="selectUsers($event)"
  ></app-filter-chart-users>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    <mat-icon>block</mat-icon>
    Close
  </button>
</div>
