import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TestObjectService {
  constructor() {}

  isQuestion(obj: any): boolean {
    return obj.networkType === 'question';
  }

  isDecisionTable(obj: any): boolean {
    return obj.networkType === 'decisionTable';
  }

  isQuestionsGroup(obj: any): boolean {
    return obj.networkType === 'questionGroup';
  }
}
