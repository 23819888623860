import { Component, Input, OnInit } from '@angular/core';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { NetworkAnalysisService } from '@app/core/services/network-analysis.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';

// models
import { Question } from '@app/core/models/question.model';

@Component({
  selector: 'app-question-analysis',
  templateUrl: './question-analysis.component.html',
  styleUrls: ['./question-analysis.component.scss'],
})
export class QuestionAnalysisComponent implements OnInit {
  @Input() question: Question;
  @Input() roleMandateId: number;
  @Input() connectionIndicatorId: number;
  @Input() nodes: Node[];

  analysis: any[];

  constructor(
    private cIService: ConnectionsIndicatorService,
    private rMService: RoleMandateService,
    private networkAnalysisService: NetworkAnalysisService,
  ) {}

  ngOnInit(): void {
    if (this.roleMandateId) {
      this.rMService
        .getNetworkAnalysisByQuestionId(this.roleMandateId, this.question.id)
        .subscribe((analysisResult) => (this.analysis = analysisResult));
    } else if (this.connectionIndicatorId) {
      this.cIService
        .getNetworkAnalysisByQuestionId(this.connectionIndicatorId, this.question.id)
        .subscribe((analysisResult) => (this.analysis = analysisResult));
    }
  }

  refreshQuestion(questionId: number) {
    this.networkAnalysisService.refreshQuestion(questionId).subscribe((result) => {
      this.rMService.getNetworkAnalysisByQuestionId(this.roleMandateId, questionId).subscribe((analysisResult) => {
        this.analysis.filter((element) => element.question.id === questionId)[0].analysis = analysisResult;
      });
    });
  }
}
