<h1>{{ isEdit ? 'Edit a superscale subscale' : 'Create a superscale subscale' }}</h1>
<form [formGroup]="superscaleSubscaleDialogForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Subscale</mat-label>
    <input
      type="text"
      placeholder="Pick one subscale"
      aria-label="Subscale"
      matInput
      formControlName="subscaleId"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let subscale of filteredSubscales$ | async"
        value="{{ subscale.title }}"
        (onSelectionChange)="onChange(subscale)"
      >
        {{ subscale.title }}
        <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Weight</mat-label>
    <input matInput type="number" formControlName="weight" />
  </mat-form-field>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!subscaleId">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
