import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { NgProgress, NgProgressComponent } from 'ngx-progressbar';

// services
import { ReportService } from '@app/core/services/report.service';
import { ChartService } from '@app/core/services/chart.service';
import { UserService } from '@app/core/services/user.service';
import { ReportPdfService } from '@app/core/services/report-pdf.service';
import { TestGroupService } from '@app/core/services/test-group.service';
import { CanvasService } from '@app/core/services/canvas.service';
import { AreaIndexService } from '@app/core/services/area-index.service';

// models
import { Report } from '@app/core/models/report.model';
import { UserInfo } from '@app/core/models/userInfo.model';
import { ReportModel } from '@app/core/models/report-model.model';
import { CustomRange } from '@app/core/models/custom-range.model';
import { SuperscaleSubscaleService } from '@app/core/services/superscale-subscale.service';
import { environment } from '../../../environments/environment';
import { ReportController } from '../../../generated/v3';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.scss'],
})
export class UserReportComponent implements OnInit {
  userId: number;
  testGroupId: number;
  reportId: number;
  report: Report;
  user: UserInfo;
  charts: any[];
  customRanges: CustomRange[];
  reportModel: ReportModel;
  updatedSuperScaleScoreCards: any = [];

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private chartService: ChartService,
    private userService: UserService,
    private testGroupService: TestGroupService,
    private areaIndexService: AreaIndexService,
    private superScaleSubscaleService: SuperscaleSubscaleService,
  ) {}

  async ngOnInit() {
    this.userId = Number(this.route.snapshot.params.id);
    this.testGroupId = Number(this.route.snapshot.params.testGroupId);
    this.reportId = Number(this.route.snapshot.params.reportId);

    forkJoin([
      this.reportService.getReportByUserIdAndId(this.userId, this.reportId, this.testGroupId),
      this.chartService.getAllByTestGroup(this.testGroupId, this.reportId, this.userId),
      this.userService.get(this.userId),
      this.testGroupService.getReportModelByTestGroupId(this.testGroupId),
      this.reportService.getCustomRanges(this.testGroupId),
      this.testGroupService.getScoreCardTrigger(this.testGroupId),
    ]).subscribe(async (results) => {
      this.report = results[0];
      if (!results[5].showScoreCard) {
        delete this.report.scorecards;
      }
      this.charts = results[1];
      this.user = results[2];
      this.reportModel = results[3];
      this.customRanges = results[4];
      this.updatedSuperScaleScoreCards = [];

      if (this.report.scorecards) {
        let scoreCardsList = [];
        let superScaleList = this.report.scorecards.filter((e) => e.isSuperscale == 1);
        let subscaleLists = this.report.scorecards.filter((e) => e.isSuperscale == 0);

        for (let i = 0; i < superScaleList.length; i++) {
          await this.getSuperScaleSubscaleToPromise(i, superScaleList).then((subList: any) => {
            let subLists = [];
            if (subList.length) {
              subList.forEach((element: any) => {
                let subscaleIndex = subscaleLists.findIndex((s: any) => s.subscaleId == element.subscale?.id);
                if (subscaleIndex > -1) {
                  subLists.push(Object.assign(subscaleLists[subscaleIndex], { isGroup: true }));
                  subscaleLists.splice(subscaleIndex, 1);
                }
              });
            }
            scoreCardsList.push(superScaleList[i]);
            scoreCardsList.push(...subLists);
          });
        }
        scoreCardsList.push(...subscaleLists);
        this.updatedSuperScaleScoreCards = scoreCardsList;
        this.report.scorecards = this.updatedSuperScaleScoreCards;
      }
    });
  }

  async getSuperScaleSubscaleToPromise(i, superScaleList) {
    return await this.superScaleSubscaleService
      .getAllBySuperscaleId(superScaleList[i].subscaleId)
      .toPromise()
      .then((results) => {
        return results;
      });
  }
}
