import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DecisionArea } from '@app/core/models/decision-area.model';
import { DecisionTableService } from '@app/core/services/decision-table.service';

@Component({
  selector: 'app-decision-area',
  templateUrl: './decision-area.component.html',
  styleUrls: ['./decision-area.component.scss'],
})
export class DecisionAreaComponent {
  @Input() decisionArea: DecisionArea;
  @Output() delete = new EventEmitter<DecisionArea>();
  showEdit: boolean;

  constructor(private decisionTableService: DecisionTableService) {}

  onEdit() {
    this.showEdit = this.showEdit ? false : true;
  }

  onEditResponse(editedDecisionArea: DecisionArea) {
    this.decisionArea = editedDecisionArea;
    this.showEdit = !editedDecisionArea ? true : false;
  }

  onDelete(id: number) {
    this.decisionTableService.deleteDecisionArea(this.decisionArea.id).subscribe(() => {
      this.delete.emit(this.decisionArea);
    });
  }
}
