import { Component, OnInit, Input } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { Router } from '@angular/router';
import { PsbItem } from '@app/core/models/psb-item.model';

@Component({
  selector: 'app-report-scale-list',
  templateUrl: './report-scale-list.component.html',
  styleUrls: ['./report-scale-list.component.scss']
})
export class ReportScaleListComponent implements OnInit {
 @Input() reportId: number;
 reportScaleList: PsbItem[];

  constructor(
    private router: Router,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.reportService.findAllSubscaleById(this.reportId).subscribe(result => {
      this.reportScaleList = result.map((reportScale) => {
        return {
          id: reportScale.sectionId,
          title: reportScale.scaleName,
          description: 'Sub/Superscale name',
          extraColumns: [
            {
              key: 'Chapter',
              value: reportScale.chapterTitle,
            },
            {
              key: 'Section',
              value: reportScale.sectionTitle,
            },
          ],
        };
      });
    });
  }

  onView(id: number) {
    this.router.navigate([`/section/${id}`]);
  }

}
