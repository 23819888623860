<div class="container">
  <div class="page-list-header">
    <h1>Dashboard</h1>
  </div>
  <form [formGroup]="reportDashboardForm">
    <mat-form-field>
      <mat-label>Clients</mat-label>
      <input matInput type="text" formControlName="company" [matAutocomplete]="auto" />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="setCompany($event.option.value)"
      >
        <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
          {{ company.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="reportDashboardForm.get('company').errors">Please, enter a valid client.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Users</mat-label>
      <input matInput type="text" formControlName="user" [matAutocomplete]="autoUser" />
      <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFnUser" (optionSelected)="onSelectUser()">
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          {{ user.firstName }} {{ user.lastName }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="reportDashboardForm.get('user').errors">Please, enter a valid user.</mat-error>
    </mat-form-field>
  </form>

  <div *ngIf="userInfoId">
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="tabSelected">
      <ng-container *ngIf="reportResult?.testGroups">
        <mat-tab *ngFor="let testGroup of reportResult.testGroups; let i = index" [label]="testGroup.title">
          <ng-template mat-tab-label>
            <div class="custom-tab-label" (click)="switchTab(i, testGroup.id)">{{ testGroup.title }}</div>
          </ng-template>
          <ng-template matTabContent>
            <div *ngIf="currentTabIndex === i">
              <div id="reports" style="text-align: right; margin-top: 5px">
                <button
                  mat-raised-button
                  color="primary"
                  [matMenuTriggerFor]="menu"
                  [disabled]="!!(role === 'ROLE_TGM' && reportResult.user.privateScores)"
                >
                  Report
                </button>
                <mat-menu #menu="matMenu" class="menu-without-scroll">
                  <button
                    mat-menu-item
                    *ngFor="let report of reports"
                    (click)="onSelectReport(userInfoId, report.id, testGroup.id)"
                  >
                    {{ report.title }}
                  </button>
                </mat-menu>
              </div>
              <app-report-dashboard-charts
                [userInfoId]="userInfoId"
                [testGroupId]="testGroup.id"
                [charts]="testGroup.charts"
              ></app-report-dashboard-charts>
            </div>
          </ng-template>
        </mat-tab>
      </ng-container>

      <mat-tab label="Subscales">
        <div align="end">
          <button mat-flat-button color="primary" type="button" class="subscale-button" (click)="updateUsersScores()">
            Update Users Scores
          </button>
          <button mat-flat-button color="warn" type="button" class="subscale-button" (click)="deleteUsersScores()">
            Delete Users Scores
          </button>
          <button
            mat-stroked-button
            color="primary"
            type="button"
            class="subscale-button"
            (click)="downloadUsersScores()"
          >
            Download
          </button>
        </div>
        <app-report-dashboard-subscales
          *ngIf="!isEmptyObject(reportResult?.tests); else noSubscales"
          [userInfoId]="userInfoId"
          [tests]="reportResult?.tests"
          [isAdmin]="isAdmin"
          [isOA]="isOA"
          [isTgm]="isTgm"
          [allowRawScoresAccess]="allowRawScoresAccess"
        ></app-report-dashboard-subscales>
        <ng-template #noSubscales>
          <mat-icon matBadgeColor="#c4c4c4" matBadgeSize="small" color="black"> notifications </mat-icon>
          No results found.
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
