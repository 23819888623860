<div class="ranges-table">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.columnDef"
      [sticky]="isSticky(column.columnDef)"
    >
      <th mat-header-cell *matHeaderCellDef (mouseover)="showDeleteColumn = true" (mouseout)="showDeleteColumn = false">
        <span *ngIf="!showDeleteColumn"> {{ column.header }} </span>
        <span *ngIf="showDeleteColumn" (click)="onRemoveColumn(column)"> Delete </span>
      </th>

      <td
        mat-cell
        *matCellDef="let row"
        [style]="
          column.columnDef === 'percentile' ? '' : 'background-color: rgb(74, 194, 154,' + column.cell(row) / 10 + ');'
        "
      >
        <ng-container *ngIf="column.columnDef !== editColumn || column.columnDef === 'percentile'">
          <span (click)="edit(column.columnDef)"> {{ column.cell(row) }} </span>
        </ng-container>
        <ng-container *ngIf="column.columnDef === editColumn && column.columnDef !== 'percentile'">
          <input
            [name]="column.columnDef"
            type="number"
            min="0"
            max="10"
            [value]="column.cell(row)"
            (change)="onChangeEvent(column, row, $event)"
          />
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
