<div *ngIf="report" class="container">
  <div class="report-header">
    <h1>{{ report.title }}</h1>
    <button mat-raised-button (click)="onEditReportTitle()">
      <mat-icon>edit</mat-icon>
      Edit title
    </button>
  </div>

  <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected.value" (selectedIndexChange)="tabChange($event)">
    <mat-tab label="Details">
      <div class="charts-header">
        <h2>Charts</h2>
        <button mat-raised-button color="primary" (click)="onAddChart()">
          <mat-icon>add</mat-icon>
          Add Chart
        </button>
      </div>
      <app-psb-list
        [showSearch]="false"
        [items]="chartPsbItems"
        (view)="onChartView($event)"
        (delete)="onChartDelete($event)"
      ></app-psb-list>

  <div class="chapters-header">
    <h2>Chapters</h2>
    <button mat-raised-button color="primary" (click)="onAddChapter()">
      <mat-icon>add</mat-icon>
      Add Chapter
    </button>
  </div>
  <ul class="chapters-list">
    <li *ngFor="let chapter of report.chapters; let i = index" class="card-list-item">
      <mat-card>
        <div class="card-header">
          <div>
            <mat-card-title class="chapter-title">
              <span>
                <button [disabled]="i === 0" mat-flat-button (click)="moveUpChapter(chapter.id, report.id, i)">
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>

                <button
                  [disabled]="i === report.chapters.length - 1"
                  mat-flat-button
                  (click)="moveDownChapter(chapter.id, report.id, i)"
                >
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
              </span>
              {{ chapter.title }}
              <mat-icon (click)="chapter.expanded = !chapter.expanded">{{ chapter.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
            </mat-card-title>
            <small *ngIf="!chapter.expanded" class="sections-length">{{ chapter.sections.length }} sections</small>
          </div>
          <mat-card-actions>
            <button mat-button class="btn-delete" (click)="onDelete(chapter.id)">Delete</button>
            <a [routerLink]="['/chapter', chapter.id]" [queryParams]="{ reportId: reportId }">
              <button mat-button class="btn-view">Open</button>
            </a>
          </mat-card-actions>
        </div>
        <ul class="chapter-sections-list" *ngIf="!!chapter.expanded">
          <li *ngFor="let section of chapter.sections; let j = index">
            <div class="item-wrapper">
              <span>
                <button [disabled]="j === 0" mat-flat-button (click)="moveUp(section.id, chapter.id, i, j)">
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>

                    <button
                      [disabled]="j === chapter.sections.length - 1"
                      mat-flat-button
                      (click)="moveDown(section.id, chapter.id, i, j)"
                    >
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>

                    {{ section.title }} <small class="type" *ngIf="section.type">{{ section.type }}</small></span
                  >
                  <div class="chapter-section-actions">
                    <button mat-flat-button (click)="expandSection(section)">
                      <small>
                        <mat-icon>{{ section.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                        {{ section.expanded ? 'Collapse' : 'Expand' }}
                      </small>
                    </button>
                    <button mat-flat-button (click)="onCopy(report.id, chapter.id, section)">
                      <small>
                        <mat-icon>file_copy</mat-icon>
                        Copy
                      </small>
                    </button>
                    <button mat-flat-button color="accent" (click)="onEdit(section.id, i, j)">
                      <small> Edit </small>
                    </button>

                    <a
                      [routerLink]="['/section', section.id]"
                      [queryParams]="{ chapterId: chapter.id, reportId: reportId }"
                    >
                      <button mat-flat-button color="primary">
                        <small> Open </small>
                      </button>
                    </a>
                  </div>
                </div>
                <div *ngIf="section.expanded" class="section-preview">
                  <app-subsection-container [section]="selectedSection"></app-subsection-container>
                </div>
              </li>
            </ul>
          </mat-card>
        </li>
      </ul>
    </mat-tab>
    <mat-tab label="Scales">
      <ng-template matTabContent>
        <app-report-scale-list [reportId]="reportId"></app-report-scale-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
