<div class="container">
  <form [formGroup]="form">
    <button mat-raised-button color="primary" type="submit" (click)="onSave()">
      <mat-icon>save_alt</mat-icon>Save
    </button>
    <table>
      <colgroup>
        <col class="range" />
        <col class="range" />
        <col class="range" />
      </colgroup>
      <thead>
        <tr>
          <th colspan="3" class="title">Custom Ranges</th>
        </tr>
        <tr>
          <th>Range</th>
          <th>Label</th>
          <th>Show</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="range">{{ form.get('ranges.outOfRangeLow').value }}</td>
          <td class="range">
            <p [innerHtml]="form.get('labels.outOfRangeLow').value"></p>
            <button
              mat-flat-button
              color="primary"
              (click)="
                openEditLabelDialog(
                  editLabelDialog,
                  form.get('labels.outOfRangeLow'),
                  form.get('labels.outOfRangeLow').value
                )
              "
            >
              Edit Label
            </button>
          </td>
          <td class="range">
            <mat-button-toggle-group [formControl]="form.get('hides.outOfRangeLow')">
              <mat-button-toggle [value]="false">ON</mat-button-toggle>
              <mat-button-toggle [value]="true">OFF</mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </tr>

        <tr>
          <td class="range">{{ form.get('ranges.cautionaryLow').value }}</td>
          <td class="range">
            <p [innerHtml]="form.get('labels.cautionaryLow').value"></p>
            <button
              mat-flat-button
              color="primary"
              (click)="
                openEditLabelDialog(
                  editLabelDialog,
                  form.get('labels.cautionaryLow'),
                  form.get('labels.cautionaryLow').value
                )
              "
            >
              Edit Label
            </button>
          </td>
          <td class="range">
            <mat-button-toggle-group [formControl]="form.get('hides.cautionaryLow')">
              <mat-button-toggle [value]="false">ON</mat-button-toggle>
              <mat-button-toggle [value]="true">OFF</mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </tr>

        <tr>
          <td class="range">{{ form.get('ranges.optimal').value }}</td>
          <td class="range">
            <p [innerHtml]="form.get('labels.optimal').value"></p>
            <button
              mat-flat-button
              color="primary"
              (click)="
                openEditLabelDialog(editLabelDialog, form.get('labels.optimal'), form.get('labels.optimal').value)
              "
            >
              Edit Label
            </button>
          </td>
          <td class="range">
            <mat-button-toggle-group [formControl]="form.get('hides.optimal')">
              <mat-button-toggle [value]="false">ON</mat-button-toggle>
              <mat-button-toggle [value]="true">OFF</mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </tr>

        <tr>
          <td class="range">{{ form.get('ranges.cautionaryHigh').value }}</td>
          <td class="range">
            <p [innerHtml]="form.get('labels.cautionaryHigh').value"></p>
            <button
              mat-flat-button
              color="primary"
              (click)="
                openEditLabelDialog(
                  editLabelDialog,
                  form.get('labels.cautionaryHigh'),
                  form.get('labels.cautionaryHigh').value
                )
              "
            >
              Edit Label
            </button>
          </td>
          <td class="range">
            <mat-button-toggle-group [formControl]="form.get('hides.cautionaryHigh')">
              <mat-button-toggle [value]="false">ON</mat-button-toggle>
              <mat-button-toggle [value]="true">OFF</mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </tr>

        <tr>
          <td class="range">{{ form.get('ranges.outOfRangeHigh').value }}</td>
          <td class="range">
            <p [innerHtml]="form.get('labels.outOfRangeHigh').value"></p>
            <button
              mat-flat-button
              color="primary"
              (click)="
                openEditLabelDialog(
                  editLabelDialog,
                  form.get('labels.outOfRangeHigh'),
                  form.get('labels.outOfRangeHigh').value
                )
              "
            >
              Edit Label
            </button>
          </td>
          <td class="range">
            <mat-button-toggle-group [formControl]="form.get('hides.outOfRangeHigh')">
              <mat-button-toggle [value]="false">ON</mat-button-toggle>
              <mat-button-toggle [value]="true">OFF</mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <ng-template #editLabelDialog>
    <h1 mat-dialog-title>Edit Label</h1>
    <mat-divider></mat-divider>
    <angular-editor [formControl]="editLabelControl" [config]="editorConfig"></angular-editor>
    <div align="end" mat-dialog-actions>
      <button mat-flat-button (click)="closeEditLabelDialog()">Cancel</button>
      <button mat-flat-button [disabled]="editLabelControl.invalid" color="primary" (click)="saveEditLabelDialog()">
        Save
      </button>
    </div>
  </ng-template>
</div>
