import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PsyChart } from '../models/psy-chart.model';
import { AreaIndex } from '../models/area-index.model';
import { PsyChartSubscale } from '../models/psy-chart-subscale.model';
import { take } from 'rxjs/operators';

const BACKEND_URL = environment.apiUrl;

type UpdateChartBaseParams = {
  calculateDiversity: boolean;
  calculateProximity: boolean;
  sectors: {
    title: string;
    text: string;
    color: string;
    position: string;
  }[];
  base?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  private chartUsersListener = new Subject<any[]>();
  private chartUsersValue: any[];

  constructor(private http: HttpClient) {}

  getChartUsersListener() {
    return this.chartUsersListener.asObservable();
  }

  setChartUsersListener(value: any[]) {
    this.chartUsersValue = value;
    this.chartUsersListener.next(value);
  }

  getChartUsersListenerValue() {
    return this.chartUsersValue;
  }

  save(newChart: PsyChart) {
    return this.http.post<PsyChart>(`${BACKEND_URL}/chart`, newChart);
  }

  getAll() {
    return this.http.get<PsyChart[]>(`${BACKEND_URL}/chart`);
  }

  getById(chartId: number) {
    return this.http.get<PsyChart>(`${BACKEND_URL}/chart/${chartId}`);
  }

  getAllByTestGroupId(testGroupId: number) {
    return this.http.get<PsyChart[]>(`${BACKEND_URL}/chart/test-group/${testGroupId}`);
  }

  getAllAreaIndices() {
    return this.http.get<AreaIndex[]>(`${BACKEND_URL}/area-index`);
  }

  getResultsByTestGroup(id: number, testGroupId: number) {
    return this.http.get<any>(BACKEND_URL + '/chart/' + id + '/test-group/' + testGroupId + '/results');
  }

  getResultsByCompany(id: number, companyId: number) {
    return this.http.get<any>(BACKEND_URL + '/chart/' + id + '/company/' + companyId + '/results');
  }

  getAllByTestGroup(testGroupId: number, reportId: number, userInfoId: number) {
    let url = BACKEND_URL + '/chart/test-group/' + testGroupId;
    const params = [];
    if (reportId) {
      params.push({
        key: 'reportId',
        value: reportId,
      });
    }

    if (userInfoId) {
      params.push({
        key: 'userInfoId',
        value: userInfoId,
      });
    }

    if (params.length > 0) {
      url += '?' + params.map((param) => param.key + '=' + param.value).join('&');
    }

    return this.http.get<any>(url);
  }

  getChartWithResultsByUser(id: number, userId: number) {
    return this.http.get<any>(BACKEND_URL + '/chart/' + id + '/user/' + userId);
  }

  getSubscalesByChartId(chartId: number) {
    return this.http.get<PsyChartSubscale[]>(`${BACKEND_URL}/chart/${chartId}/subscale`);
  }

  deleteChartSubscale(chartSubscaleId: number) {
    return this.http.delete(`${BACKEND_URL}/chart/subscale/${chartSubscaleId}`);
  }

  addSubscaleToChart(chartId: number, params: { axis: string; weight: number; subscaleId: number }) {
    return this.http.post<PsyChartSubscale>(`${BACKEND_URL}/chart/${chartId}/subscale`, params);
  }

  updateChartSubscale(chartSubscaleId: number, params) {
    return this.http.patch<PsyChartSubscale>(`${BACKEND_URL}/chart/subscale/${chartSubscaleId}`, params);
  }

  updateChartPlot(chartId: number, params: { plotRadius: number; coloredPlot: boolean }) {
    const body = {
      type: 'PLOT_SETUP',
      payload: params,
    };

    return this.http.patch<PsyChart>(`${BACKEND_URL}/chart/${chartId}`, body);
  }

  updateChartBase(chartId: number, params: UpdateChartBaseParams) {
    const url = `${BACKEND_URL}/chart/${chartId}`;
    const body = {
      type: 'BASE_SETUP',
      payload: {
        calculateDiversity: params.calculateDiversity,
        calculateProximity: params.calculateProximity,
        sectors: params.sectors,
      },
      base: params.base,
    };

    return this.http.patch<PsyChart>(url, body);
  }

  delete(chartId: number) {
    const url = `${BACKEND_URL}/chart/${chartId}`;
    return this.http.delete(url);
  }

  updateChartDetails(chartId: number, payload: PsyChart) {
    const url = `${BACKEND_URL}/chart/${chartId}`;
    const body = {
      type: 'DETAILS',
      payload,
    };
    return this.http.patch<PsyChart>(url, body);
  }

  updateTestGroupChartVisible(testGroupId: number, chartId: number, visible: boolean) {
    return this.http.patch<any>(`${BACKEND_URL}/test-group/${testGroupId}/chart/${chartId}`, { visible }).pipe(take(1));
  }
}
