import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { OptimalScore } from '@app/core/models/optimal-score.model';
import { OptimalScoreScale } from '@app/core/models/optimal-score-scale.model';
import { OptimalScoreScaleRangeText } from '@app/core/models/optimal-score-scale-range-text.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class OptimalScoreService {
  constructor(private http: HttpClient, private router: Router) {}

  getAll() {
    return this.http.get<OptimalScore[]>(BACKEND_URL + '/optimal-score');
  }

  get(id: number) {
    return this.http.get<OptimalScore>(BACKEND_URL + '/optimal-score/' + id);
  }

  getOptimalScoreScale(optimalScoreScaleId: number) {
    return this.http.get<OptimalScoreScale>(BACKEND_URL + '/optimal-score/optimal-score-scale/' + optimalScoreScaleId);
  }

  getChapterOptimalScore(chapterId: number, reportId: number) {
    return this.http.get<OptimalScoreScale[]>(
      `${BACKEND_URL}/optimal-score/report/${reportId}/chapter-optimal-score-list/${chapterId}`,
    );
  }

  updateCurveSpread(curveSpreadId: number, curveNumber: number) {
    return this.http.patch(BACKEND_URL + '/optimal-score/curve-spread/' + curveSpreadId, { curveNumber });
  }

  findCurveNumberBySpreadAndPercentileAndCurveId(optimalScoreCurveId: number, spread: number, percentile: number) {
    return this.http.get<any>(
      `${BACKEND_URL}/optimal-score/curve/${optimalScoreCurveId}/curve-spread/${spread}/percentile/${percentile}`,
    );
  }

  getCurveSpreadByOptimalScoreCurveId(optimalScoreCurveId: number) {
    return this.http.get<any>(
      BACKEND_URL + '/optimal-score/optimal-score-curve/' + optimalScoreCurveId + '/curve-spread',
    );
  }

  /** come from chapter */
  create(optimalScore: any) {
    const url = `${BACKEND_URL}/optimal-score`;
    return this.http.post(url, optimalScore);
  }

  update(optimalScore: any) {
    const url = `${BACKEND_URL}/optimal-score/${optimalScore.id}`;
    return this.http.put(url, optimalScore);
  }

  delete(optimalScoreId: number, optimalScoreScaleId: number) {
    const url = `${BACKEND_URL}/optimal-score/${optimalScoreId}/optimal-score-scale/${optimalScoreScaleId}`;
    return this.http.delete(url);
  }

  deleteCurveRanges(optimalScoreId: number, curveNumber: number) {
    return this.http.delete(`${BACKEND_URL}/optimal-score/${optimalScoreId}/curve/${curveNumber}/delete-ranges`);
  }

  createOptimalScoreScaleRangeText(optimalScoreScaleRangeText: any) {
    const url = `${BACKEND_URL}/optimal-score/optimal-score-scale-range-text`;
    return this.http.post<OptimalScoreScaleRangeText>(url, optimalScoreScaleRangeText);
  }

  findAllOptimalScoreScaleRangeTextByOptimalScoreScaleId(optimalScoreScaleId: number) {
    const url = `${BACKEND_URL}/optimal-score/${optimalScoreScaleId}/optimal-score-scale/optimal-score-scale-range-text`;
    return this.http.get<OptimalScoreScaleRangeText[]>(url);
  }

  deleteOptimalScoreScaleRangeText(optimalScoreScaleId: number, optimalScoreScaleRangeTextId: number) {
    return this.http.delete(
      `${BACKEND_URL}/optimal-score/${optimalScoreScaleId}/optimal-score-scale/${optimalScoreScaleRangeTextId}/optimal-score-scale-range-text`,
    );
  }

  editOptimalScoreScaleRangeText(
    optimalScoreScaleId: number,
    optimalScoreScaleRangeTextId: number,
    rangeText: OptimalScoreScaleRangeText,
  ) {
    const url = `${BACKEND_URL}/optimal-score/${optimalScoreScaleId}/optimal-score-scale/${optimalScoreScaleRangeTextId}/optimal-score-scale-range-text`;
    return this.http.patch<OptimalScoreScaleRangeText>(url, rangeText);
  }
}
