import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { PageService } from '@app/core/services/page.service';

// models
import { DecisionTableRelation } from '@app/core/models/decision-table-relation.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { DecisionArea } from '@app/core/models/decision-area.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-role-mandate-decision-tables',
  templateUrl: './role-mandate-decision-tables.component.html',
  styleUrls: ['./role-mandate-decision-tables.component.scss'],
})
export class RoleMandateDecisionTablesComponent implements OnInit {
  roleMandateId: number;
  decisionTables: DecisionTable[];
  decisionTableType = 'roleMandate';
  newDecisionTable = false;
  newDecisionTableRelation = false;
  decisionTableRelations: DecisionTableRelation[];
  newDecisionArea: boolean;
  decisionAreas: DecisionArea[];
  companyId: number;
  pages: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private roleMandateService: RoleMandateService,
    private decisionTableService: DecisionTableService,
    public pageService: PageService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.roleMandateId = Number(this.route.snapshot.params.id);
    this.getRoleMandatePages();
    this.loadData();
  }

  updateDecisionTables(decisionTables: DecisionTable[]) {
    this.newDecisionTable = false;
    // Need to reload data because it's not returning the created column(s) id(s)
    // and there's no service to retrieve a decision table columns
    this.loadData();
  }

  onNext() {
    this.router.navigate([`role-mandate/${this.roleMandateId}/sort`]);
  }

  onDeleteDecisionTable(decisionTableId: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.componentInstance.message = `Please confirm that you want delete this Decision Table?`;

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.roleMandateService.deleteRoleMandateDecisionTable(decisionTableId).subscribe(() => {
          this.decisionTables = this.decisionTables.filter((decisionTable) => decisionTable.id !== decisionTableId);
        });
      }
    });
  }

  onEditDecisionTable(decisionTable: DecisionTable) {
    this.decisionTableService.updateByRoleMandate(decisionTable, this.roleMandateId).subscribe();
  }

  onNewDecisionTable() {
    this.newDecisionTable = true;
  }

  onAddDecitionTableRelation(decisionTableRelation) {
    this.decisionTableRelations = this.decisionTableRelations.concat(decisionTableRelation);
    this.newDecisionTableRelation = false;
  }

  onNewDecitionTableRelation() {
    this.newDecisionTableRelation = true;
  }

  onDeleteDecisionRelation(decisionTableRelationId: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.componentInstance.message = `Please confirm that you want delete this Decision Table Connection?`;

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.decisionTableService.deleteDecisionTableRelation(decisionTableRelationId).subscribe(() => {
          this.decisionTableRelations = this.decisionTableRelations.filter(
            (decisionTableRelation) => decisionTableRelation.id !== decisionTableRelationId,
          );
        });
      }
    });
  }

  onEditDecisionRelation($event: any) {
    this.decisionTableRelations = this.decisionTableRelations.map((decisionTableRelation) => {
      if (decisionTableRelation.id === $event.id) {
        $event.data.id = $event.id;
        return $event.data;
      } else {
        return decisionTableRelation;
      }
    });
  }

  onNewDecisionArea() {
    this.newDecisionArea = true;
  }

  onAddDecisionArea($event: boolean) {
    this.newDecisionArea = !$event;
  }

  onDeleteDecisionArea($event: DecisionArea) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.componentInstance.message = `Please confirm that you want delete this Decision Area?`;

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.decisionAreas = this.decisionAreas.filter((decisionArea) => decisionArea.id !== $event.id);
      }
    });
  }

  loadData() {
    forkJoin([
      this.roleMandateService.getAllDecisionTableById(this.roleMandateId),
      this.decisionTableService.findAlDecisionTableRelationByRoleMandateId(this.roleMandateId),
      this.roleMandateService.getById(this.roleMandateId),
    ]).subscribe((results) => {
      this.decisionTables = results[0];
      this.decisionTableRelations = results[1];
      this.companyId = results[2].companyId;
      this.decisionTableService.getDecisionAreasByCompanyId(this.companyId).subscribe((decisionAreas) => {
        this.decisionAreas = decisionAreas ? decisionAreas : [];
      });
    });
  }

  getRoleMandatePages() {
    this.pages = this.pageService.getRoleMandatePages(this.roleMandateId);
  }
}
