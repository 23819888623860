import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ClientsComponent } from './clients/clients.component';
import { ClientDetailsComponent } from './client-details/client-details.component';

const clientsRoutes: Routes = [
  { path: '', component: ClientsComponent },
  { path: ':id/details', component: ClientDetailsComponent },
  { path: ':id/details/tab/:tab', component: ClientDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(clientsRoutes)],
  exports: [RouterModule],
})
export class ClientsRoutingModule {}
