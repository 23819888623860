import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// components
import { CreateSubscaleDialogComponent } from '../create-subscale-dialog/create-subscale-dialog.component';

// services
import { SubscaleService } from '@app/core/services/subcale.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { Subscale } from '@app/core/models/subscale.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { SubscaleBreadcrumbService } from '@app/shared/services/subscale-breadcrumb.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-subscale-detail',
  templateUrl: './subscale-detail.component.html',
  styleUrls: ['./subscale-detail.component.scss'],
})
export class SubscaleDetailComponent implements OnInit {
  subscaleId: number;
  subscale: Subscale;
  tabs: string[] = ['Details'];
  selected = new FormControl(0);
  subscaleDate: string;
  relatedAssessmentsAndSubscales: any;

  constructor(
    private subscaleService: SubscaleService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private route: ActivatedRoute,
    private breadScrumService: SubscaleBreadcrumbService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscaleId = Number(this.route.snapshot.params.id);
    this.getSubscale();
    this.getAllRelatedSubscaleAndAssessment();

    this.breadScrumService.updatedBreadScrum.subscribe((data) => {
      this.tabs = data;
    });
  }

  private getSubscale(): void {
    this.subscaleService.get(this.subscaleId).subscribe((subscale: Subscale) => {
      this.subscale = subscale;
      this.subscaleDate = new Date(subscale.dateAdded).toLocaleDateString('en-US');
      this.breadScrumService.setBreadCrumbNavigation('subScale_Details', {
        subScaleTitle: this.subscale.title,
        subScaleId: this.subscaleId,
      });
      this.breadScrumService.currentNavigationLink.subscribe((data) => {
        if (data == 'subscaleName') this.selected.setValue(0);
        if (data == 'Question') {
          this.selected.setValue(1);
          this.breadScrumService.setBreadCrumbNavigation('question', {
            subScaleTitle: this.subscale.title,
            subScaleId: this.subscaleId,
          });
        }
        if (data == 'question group') {
          this.selected.setValue(2);
          this.breadScrumService.setBreadCrumbNavigation('question_group', {
            subScaleTitle: this.subscale.title,
            subScaleId: this.subscaleId,
          });
        }
      });
    });
  }

  private getAllRelatedSubscaleAndAssessment(): void {
    this.subscaleService.getAllRelatedAssessmentAndSubscaleById(this.subscaleId).subscribe((res) => {
      this.relatedAssessmentsAndSubscales = res;
    });
  }

  onEdit() {
    this.dialog
      .open(CreateSubscaleDialogComponent, { data: { isEdit: true, subscale: this.subscale } })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          const updatedSubscale: Subscale = {
            id: this.subscaleId,
            title: result.data.title,
            description: result.data.description,
            color: result.data.color,
            active: result.data.active,
            randomAllowed: result.data.randomAllowed,
            scorePercentileActive: result.data.scorePercentileActive,
            dateAdded: this.subscale.dateAdded,
            cardsortSubscale: result.data.cardsortSubscale,
          };
          // Update
          this.subscaleService.update(this.subscaleId, updatedSubscale).subscribe((updated: Subscale) => {
            this.getSubscale();
            this.snackBar.info('Subscale updated with success!');
          });
        }
      });
  }

  tabChange(event: MatTabChangeEvent): void {
    this.selected.setValue(event.index);
    switch (event.tab.textLabel) {
      case 'Details':
        this.breadScrumService.setBreadCrumbNavigation('subScale_Details', {
          subScaleTitle: this.subscale.title,
          subScaleId: this.subscaleId,
        });
        break;
      case 'Questions':
        this.breadScrumService.setBreadCrumbNavigation('question', {
          subScaleTitle: this.subscale.title,
          subScaleId: this.subscaleId,
        });
        break;
      case 'Question Groups':
        this.breadScrumService.setBreadCrumbNavigation('question_group', {
          subScaleTitle: this.subscale.title,
          subScaleId: this.subscaleId,
        });
        break;
      case 'Related Assessments':
        this.breadScrumService.setBreadCrumbNavigation('related_assessments', {
          subScaleTitle: this.subscale.title,
          subScaleId: this.subscaleId,
        });
        break;
      case 'Related Subcales':
        this.breadScrumService.setBreadCrumbNavigation('related_subscales', {
          subScaleTitle: this.subscale.title,
          subScaleId: this.subscaleId,
        });
        break;
      case 'Score Percentiles':
        this.breadScrumService.setBreadCrumbNavigation('score_percentiles', {
          subScaleTitle: this.subscale.title,
          subScaleId: this.subscaleId,
        });
        break;
      case 'Preview':
        this.breadScrumService.setBreadCrumbNavigation('preview', {
          subScaleTitle: this.subscale.title,
          subScaleId: this.subscaleId,
        });
        break;
      default:
        break;
    }
  }

  navigateUrl(item) {
    if (this.router.url != item.url) {
      this.router.navigate([item.url]);
    }
    this.breadScrumService.currentNavigationLink.next(item?.currentTab);
  }

  onViewRelatedAssessments(id: number) {
    this.router.navigate([`/psy-test/${id}/detail`]);
  }

  onViewRelatedSubscales(id: number) {
    this.router.navigate([`/subscale/${id}/detail`]).then(() => {
      window.location.reload(); //Refresh the current page to the target page
    });
  }
}
