import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { PageService } from '@app/core/services/page.service';
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { QuestionService } from '@app/core/services/question.service';
import { TestObjectService } from '@app/core/services/test-object.service';

// models
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { QuestionGroup } from '@app/core/models/question-group.model';
import { Question } from '@app/core/models/question.model';

@Component({
  selector: 'app-connections-indicator-sort',
  templateUrl: './connections-indicator-sort.component.html',
  styleUrls: ['./connections-indicator-sort.component.scss'],
})
export class ConnectionsIndicatorSortComponent implements OnInit {
  connectionIndicator: ConnectionIndicator;
  connectionIndicatorId: number;
  userInfoId: number;
  config: any;
  decisionTables: DecisionTable[];
  questionGroups: QuestionGroup[];
  questions: Question[];
  allObj: any[];

  constructor(
    private router: Router,
    private ciService: ConnectionsIndicatorService,
    private questionGroupService: QuestionGroupService,
    private questionService: QuestionService,
    private route: ActivatedRoute,
    public testObjectService: TestObjectService,
    public pageService: PageService,
  ) {}

  async ngOnInit() {
    this.connectionIndicatorId = this.route.snapshot.params['id'];
    this.userInfoId = +localStorage.getItem('userInfoId');

    forkJoin([
      this.ciService.getById(this.connectionIndicatorId),
      this.questionGroupService.getAllQuestionGroupForRoleMandate(this.connectionIndicatorId),
      this.ciService.getAllQuestionById(this.connectionIndicatorId),
      this.ciService.getAllDecisionTableById(this.connectionIndicatorId),
    ]).subscribe((results) => {
      this.connectionIndicator = results[0];
      this.decisionTables = results[3];

      this.questionGroups = results[1];

      this.questions = results[2].filter(
        (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
      );

      this.questionGroups.forEach((questionGroup) => {
        questionGroup.questions = results[2].filter((question) => question.questionGroupId === questionGroup.id);
      });

      this.allObj = [
        ...this.questionGroups.map((obj) => {
          obj.networkType = 'questionGroup';
          return obj;
        }),
        ...this.decisionTables.map((obj) => {
          obj.networkType = 'decisionTable';
          return obj;
        }),
        ...this.questions.map((obj) => {
          obj.networkType = 'question';
          return obj;
        }),
      ];

      this.allObj = this.allObj.sort((a, b) => {
        return a.aorder - b.aorder;
      });
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    const orders = this.allObj.map((element, index) => {
      return {
        order: index,
        type: element.networkType,
        id: element.id,
      };
    });

    this.questionService.saveOrder(orders).subscribe();
  }

  onNext() {
    const orders = this.allObj.map((element, index) => {
      return {
        order: index,
        type: element.networkType,
        id: element.id,
      };
    });

    this.questionService.saveOrder(orders).subscribe((result) => {
      this.router.navigate([`connections-indicator/${this.connectionIndicatorId}/review`]);
    });
  }
}
