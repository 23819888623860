/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Section } from '../models/Section';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class SectionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Add a section to a chapter
     * Add a section to a chapter
     * @param id Id of the chapter
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postChapterSection(
        id: number,
        requestBody?: {
            /**
             * Id of the section
             */
            sectionId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/chapter/{id}/section',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Chapter not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a section from the chapter
     * Remove a section from the chapter
     * @param chapterId Id of the chapter
     * @param sectionId Id of the section
     * @returns any Success on removing section from chapter
     * @throws ApiError
     */
    public deleteChapterSection(
        chapterId: number,
        sectionId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/chapter/{chapterId}/section/{sectionId}',
            path: {
                'chapterId': chapterId,
                'sectionId': sectionId,
            },
            errors: {
                404: `Chapter and/or section not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Copy a section
     * Copy a section
     * @param reportId Id of the report
     * @param chapterId Id of the chapter
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postReportChapterCopySection(
        reportId: number,
        chapterId: number,
        requestBody?: Section,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/{reportId}/chapter/{chapterId}/copy-section',
            path: {
                'reportId': reportId,
                'chapterId': chapterId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all sections
     * Retrieve a list with all sections
     * @returns Section A list of sections
     * @throws ApiError
     */
    public getSection(): Observable<Array<Section>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/section',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a section
     * Create a section
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postSection(
        requestBody?: {
            /**
             * Title of the section
             */
            title: string;
            /**
             * Type of the section
             */
            type: string;
            subsection: any;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/section',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a section by id
     * Retrieve a section by id
     * @param id Id of the section
     * @returns Section A section
     * @throws ApiError
     */
    public getSection1(
        id: number,
    ): Observable<Section> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/section/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Section not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update a section
     * Update a section
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchSection(
        id: number,
        requestBody?: {
            /**
             * Title of the section
             */
            title: string;
            /**
             * Type of the section
             */
            type: string;
            subsection: any;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/section/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Section not found`,
                500: `Internal error`,
            },
        });
    }

}
