<h1>Create or edit a Department</h1>
<form [formGroup]="departmentModalForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput type="text" formControlName="name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Country</mat-label>
    <input
      matInput
      type="text"
      formControlName="country"
      [matAutocomplete]="autoCountry" />
    <mat-autocomplete
      #autoCountry="matAutocomplete"
      (optionSelected)="onSelectCountry($event.option.value.id)"
      [displayWith]="displayCountry"
    >
      <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
        {{ displayCountry(country) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="departmentModalForm.get('country').errors">Please, select a valid country.</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="states && states.length">
    <mat-label>State</mat-label>
    <input
      matInput
      type="text"
      formControlName="stateId"
      [matAutocomplete]="autoState" />
    <mat-autocomplete #autoState="matAutocomplete" [displayWith]="displayState">
      <mat-option *ngFor="let state of filteredStates | async" [value]="state">
        {{ displayState(state) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="departmentModalForm.get('stateId').errors">Please, select a valid state.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>City</mat-label>
    <input matInput type="text" formControlName="city" />
    <mat-error *ngIf="departmentModalForm.get('city').errors">Please, enter a valid city.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Zip Code</mat-label>
    <input matInput type="text" formControlName="zipCode" />
    <mat-error *ngIf="departmentModalForm.get('zipCode').errors">Please, enter a valid zip code.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Address</mat-label>
    <input matInput type="text" formControlName="address" />
    <mat-error *ngIf="departmentModalForm.get('address').errors">Please, enter a valid address.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Address 2</mat-label>
    <input matInput type="text" formControlName="address2" />
  </mat-form-field>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!departmentModalForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
