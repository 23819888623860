import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CurveRange } from '@app/core/models/curve-range.model';
import { HandleCsvService } from '@app/core/services/handle-csv.service';
import { OptimalCurvesService } from '@app/core/services/optimal-curves.service';
import { OptimalScoreCurve } from '@app/core/models/optimal-score-curve.model';
import { CurveRangeService } from '@app/core/services/curve-range.service';
import { RangesTableComponent } from '@app/shared/components/optimal-score/ranges-table/ranges-table.component';
import { SpreadsTableComponent } from '@app/shared/components/optimal-score/spreads-table/spreads-table.component';

@Component({
  selector: 'app-optimal-score-curve-detail',
  templateUrl: './optimal-score-curve-detail.component.html',
  styleUrls: ['./optimal-score-curve-detail.component.scss'],
})
export class OptimalScoreCurveDetailComponent implements OnInit {
  @ViewChild(RangesTableComponent, { static: false }) rangesTable: RangesTableComponent;
  @ViewChild(SpreadsTableComponent, { static: false }) spreadsTable: SpreadsTableComponent;

  optimalScoreCurveId: number;
  optimalScoreCurve: OptimalScoreCurve;
  tabs: string[] = ['Ranges', 'Curves Definition'];

  curveRanges: CurveRange[] = [];
  selected = new FormControl(0);

  generateSetForm = new FormGroup({
    percentileInterval: new FormControl('', [Validators.required]),
    numberOfCurves: new FormControl('', [Validators.required]),
  });

  spreadForm = new FormGroup({
    newSpread: new FormControl('', [Validators.required]),
  });

  constructor(
    private route: ActivatedRoute,
    private optimalCurvesService: OptimalCurvesService,
    private curveRangeService: CurveRangeService,
    private handleCsvService: HandleCsvService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.optimalScoreCurveId = Number(this.route.snapshot.params.id);
    this.route.params.subscribe((params) => {
      this.selected.setValue(params.id ? +params.tab : 0);
    });

    this.optimalCurvesService.findById(this.optimalScoreCurveId).subscribe((optimalScoreCurve) => {
      this.optimalScoreCurve = optimalScoreCurve;
    });
  }

  saveModifications() {
    this.optimalCurvesService
      .update(this.optimalScoreCurveId, this.optimalScoreCurve)
      .subscribe((optimalScoreCurve) => {});
  }

  addSpread() {
    const percentiles = Object.values(this.optimalScoreCurve.spreads)
      .map((element) => element.percentile)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();

    for (const percentile of percentiles) {
      this.optimalScoreCurve.spreads.push({
        curveNumber: null,
        percentile,
        spread: this.spreadForm.get('newSpread').value,
      });
    }

    this.spreadsTable.update();
  }

  onNewCurve() {
    const lastRange = Object.keys(this.optimalScoreCurve.ranges)[Object.keys(this.optimalScoreCurve.ranges).length - 1];
    const newCurveNumber = Number(lastRange) + 1;

    this.optimalScoreCurve.numberCurves = newCurveNumber;

    const stepPercetile = this.generateSetForm.get('percentileInterval').value
      ? this.generateSetForm.get('percentileInterval').value
      : 1;

    const newCurve = [];
    for (let index = 1; index < 100; index += stepPercetile) {
      newCurve.push({
        curveNumber: newCurveNumber,
        score: 0,
        optimalPercentile: index,
      });
    }
    this.optimalScoreCurve.ranges[newCurveNumber] = newCurve;

    const lastSpread = Object.keys(this.optimalScoreCurve.spreads)[
      Object.keys(this.optimalScoreCurve.spreads).length - 1
    ];
    this.optimalScoreCurve.spreads[newCurveNumber] = {
      curveNumber: newCurveNumber,
      percentile: 10,
      spread: 10,
    };
    this.rangesTable.update();
  }

  onGenerateSet() {
    const percentileInterval = this.generateSetForm.get('percentileInterval').value;
    const newCurveNumber = this.generateSetForm.get('numberOfCurves').value;

    this.optimalScoreCurve.numberCurves = newCurveNumber;

    for (let i = 1; i <= newCurveNumber; i++) {
      if (!this.optimalScoreCurve.spreads) {
        this.optimalScoreCurve.spreads = [];
      }

      this.optimalScoreCurve.spreads[i] = {
        curveNumber: i,
        percentile: null,
        spread: null,
      };

      const newCurves = [];
      for (let j = 1; j < 100; j += percentileInterval) {
        newCurves.push({
          curveNumber: i,
          score: 0,
          optimalPercentile: j,
        });
      }

      if (!this.optimalScoreCurve.ranges) {
        this.optimalScoreCurve.ranges = [];
      }

      this.optimalScoreCurve.ranges[i] = newCurves;
    }
  }

  tabChange(event) {
    this.selected.setValue(event);
    this.router.navigate([this.router.url.split('tab/')[0] + '/tab/' + event]);
  }

  async saveSpreadCSVFile($event: Event) {
    const csvData: any = await this.handleCsvService.readCsvByEvent($event);
  }

  async handleCurveDefinitionCSVFile($event: Event) {
    const csvData: any = await this.handleCsvService.readCsvByEvent($event);
    const curves = [];
    let percentiles = [];
    let curveNumber = 1;

    for (let i = 0; i < csvData.length; i++) {
      if (i === 0) {
        for (let j = 2; j < csvData[i].length; j++) {
          percentiles.push(csvData[i][j]);
        }
      } else if (i > 0) {
        const currentRow = csvData[i];
        // check if currentRow not an empty row
        if (currentRow.some(Boolean)) {
          let spread = currentRow[1] - currentRow[0];

          for (let k = 2; k < currentRow.length; k++) {
            const curveRange: CurveRange = {
              id: null,
              curveNumber: curveNumber,
              score: currentRow[k],
              optimalPercentile: percentiles[k - 2],
              optimalScoreCurveId: this.optimalScoreCurveId,
              spread: spread,
              lowRange: currentRow[0],
              highRange: currentRow[1],
            };
            this.curveRanges.push(curveRange);
          }
          curveNumber = curveNumber + 1;
        }
      }
    }

    this.curveRangeService.saveAll(this.curveRanges).subscribe((res) => {
      window.location.reload();
    });
  }
}
