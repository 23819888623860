<mat-table *ngIf="scorecards" [dataSource]="scorecards">
  <ng-container matColumnDef="subscaleTitle">
    <mat-header-cell *matHeaderCellDef> {{ typeOfPage == 'userReport' ? 'Scale' : 'Subscale' }} </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="mobile-label">subscaleTitle:</span>
      <span
        [ngStyle]="{
          'font-weight': element?.isSuperscale == '1' && typeOfPage == 'userReport' ? 'bold' : '',
          'padding-left': element.isGroup == true ? '3.5em' : ''
        }"
        >{{ element?.subscaleTitle }}</span
      >
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="txt">
    <mat-header-cell *matHeaderCellDef fxFlex="500px"> Description </mat-header-cell>
    <mat-cell *matCellDef="let element" fxFlex="500px">
      <span class="mobile-label">Description: </span> {{ element.txt }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="testRange">
    <mat-header-cell class="test-range" *matHeaderCellDef> Test Range </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="mobile-label">Test Range: </span>{{ element.testRange }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="percentile">
    <mat-header-cell class="percentile" *matHeaderCellDef> Percentile </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="mobile-label">Percentile: </span>{{ element.percentile }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="class">
    <mat-header-cell class="target" *matHeaderCellDef> Target </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="mobile-label">Target: </span>
      <div
        *ngIf="element.class === 'outOfRangeLow'"
        class="outOfRangeLow"
        [style.background-image]="getBackGroundImage('yellowblack.png')"
      ></div>
      <div
        *ngIf="element.class === 'cautionaryLow'"
        class="cautionaryLow"
        [style.background-image]="getBackGroundImage('yellowdot2.png')"
      ></div>
      <div
        *ngIf="element.class === 'optimal'"
        class="optimal"
        [style.background-image]="getBackGroundImage('g2.png')"
      ></div>
      <div
        *ngIf="element.class === 'cautionaryHigh'"
        class="cautionaryHigh"
        [style.background-image]="getBackGroundImage('reddot2.png')"
      ></div>
      <div
        *ngIf="element.class === 'outOfRangeHigh'"
        class="outOfRangeHigh"
        [style.background-image]="getBackGroundImage('brdot2.png')"
      ></div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<table *ngIf="scorecards && (!customRanges || (customRanges && customRanges.length === 0))">
  <colgroup>
    <col style="width: 20%" />
    <col style="width: 20%" />
    <col style="width: 20%" />
    <col style="width: 20%" />
    <col style="width: 20%" />
  </colgroup>
  <tr class="legend">
    <td>
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="outOfRangeLow"
        [style.background-image]="getBackGroundImage('yellowblack.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle">Out of Range: Low</p>
    </td>
    <td>
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="cautionaryLow"
        [style.background-image]="getBackGroundImage('yellowdot2.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle">Cautionary: Low</p>
    </td>
    <td>
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="optimal"
        [style.background-image]="getBackGroundImage('g2.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle">Optimal</p>
    </td>
    <td>
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="cautionaryHigh"
        [style.background-image]="getBackGroundImage('reddot2.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle">Cautionary: High</p>
    </td>
    <td>
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="outOfRangeHigh"
        [style.background-image]="getBackGroundImage('brdot2.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle">Out of Range: High</p>
    </td>
  </tr>
</table>
<table *ngIf="scorecards && customRanges && customRanges.length > 0">
  <colgroup>
    <col [ngStyle]="{ width: columnNumber ? 'calc(100% / ' + columnNumber + ')' : '' }" />
    <col [ngStyle]="{ width: columnNumber ? 'calc(100% / ' + columnNumber + ')' : '' }" />
    <col [ngStyle]="{ width: columnNumber ? 'calc(100% / ' + columnNumber + ')' : '' }" />
    <col [ngStyle]="{ width: columnNumber ? 'calc(100% / ' + columnNumber + ')' : '' }" />
    <col [ngStyle]="{ width: columnNumber ? 'calc(100% / ' + columnNumber + ')' : '' }" />
  </colgroup>
  <tr>
    <td *ngIf="!outOfRangeLowCustomRange.hided">
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="outOfRangeLow"
        [style.background-image]="getBackGroundImage('yellowblack.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle" [innerHTML]="outOfRangeLowCustomRange.rangeLabel"></p>
    </td>
    <td *ngIf="!cautionaryLowCustomRange.hided">
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="cautionaryLow"
        [style.background-image]="getBackGroundImage('yellowdot2.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle" [innerHTML]="cautionaryLowCustomRange.rangeLabel"></p>
    </td>
    <td *ngIf="!optimalCustomRange.hided">
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="optimal"
        [style.background-image]="getBackGroundImage('g2.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle" [innerHTML]="optimalCustomRange.rangeLabel"></p>
    </td>
    <td *ngIf="!cautionaryHighCustomRange.hided">
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="cautionaryHigh"
        [style.background-image]="getBackGroundImage('reddot2.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle" [innerHTML]="cautionaryHighCustomRange.rangeLabel"></p>
    </td>
    <td *ngIf="!outOfRangeHighCustomRange.hided">
      <div
        style="margin-right: 20px; display: inline-block; vertical-align: middle"
        class="outOfRangeHigh"
        [style.background-image]="getBackGroundImage('brdot2.png')"
      ></div>
      <p style="display: inline-block; vertical-align: middle" [innerHTML]="outOfRangeHighCustomRange.rangeLabel"></p>
    </td>
  </tr>
</table>
<br />
