<div>
  <h2>{{ title }} Optimal Score Ranges</h2>
  <mat-divider></mat-divider>
  <form [formGroup]="rangeContentForm" clas="range-content-form" (submit)="onAddRange()">
    <button mat-flat-button color="primary" type="submit" [disabled]="rangeContentForm.invalid" class="add-range">
      <mat-icon>add</mat-icon>
      Add range
    </button>
    <div>
      <mat-form-field>
        <mat-label>From</mat-label>
        <input matInput required formControlName="from" type="number" min="0" max="99" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>To</mat-label>
        <input matInput required formControlName="to" type="number" min="1" max="100" />
      </mat-form-field>
      <angular-editor [config]="editorConfig" formControlName="rangeText"> </angular-editor>
    </div>
  </form>
  <div *ngIf="addedRanges && addedRanges.length > 0">
    <div>
      <h3>Ranges</h3>
      <ul class="ranges-list">
        <li *ngFor="let range of addedRanges; let j = index">
          <div class="info">
            <span>
              <small>From</small>
              {{ range.from }}
            </span>
            <span>
              <small>To</small>
              {{ range.to }}
            </span>
            <span>
              <small>Content</small>
              <div [innerHTML]="range.rangeText"></div>
            </span>
            <div>
              <button class="edit-button" mat-flat-button color="primary" (click)="onEditRange(range)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-flat-button color="accent" (click)="onRemoveRange(range.id, range.optimalScoreScaleId)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <mat-icon>block</mat-icon>
      Cancel
    </button>
  </div>
</div>
