import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// services
import { UserService } from '@app/core/services/user.service';

// models
import { UserInfo } from '@app/core/models/userInfo.model';
import { AlternativeTitle } from '@app/core/models/alternative-title.model';

@Component({
  selector: 'app-alternative-role',
  templateUrl: './alternative-role.component.html',
  styleUrls: ['./alternative-role.component.scss'],
})
export class AlternativeRoleComponent implements OnInit {
  @Input() answering: boolean;
  @Input() userInfo: UserInfo;

  alternativeTitles: AlternativeTitle[];
  filteredAlternativeTitles: Observable<AlternativeTitle[]>;
  alternativeTitleControl = new FormControl('', [Validators.required]);
  alternativeTitleTimer: any;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getAlternativeTitles();
  }

  getAlternativeTitles() {
    this.userService.getAlternativeTitles(this.userInfo.id).subscribe({
      next: (result) => {
        this.alternativeTitles = [
          ...new Map(
            result
              .filter((alternativeTitle) => alternativeTitle.alternativeTitle !== 'undefined')
              .map((alternativeTitle) => [alternativeTitle.alternativeTitle, alternativeTitle]),
          ).values(),
        ];
        if (this.userInfo.alternativeTitleId) {
          const dpt = result.filter((alternativeTitle) => alternativeTitle.id === this.userInfo.alternativeTitleId)[0];
          this.alternativeTitleControl.setValue(this.answering ? dpt : dpt.alternativeTitle);
        }

        this.filteredAlternativeTitles = this.alternativeTitleControl.valueChanges.pipe(
          startWith(''),
          map((alternativeTitle) =>
            alternativeTitle ? this._filterAlternativeTitle(alternativeTitle) : this.alternativeTitles.slice(),
          ),
        );
      },
    });
  }

  displayAlternativeTitle(alternativeTitle: AlternativeTitle): string {
    return alternativeTitle ? alternativeTitle.alternativeTitle : '';
  }

  createAlternativeTitle(event) {
    this.filteredAlternativeTitles.subscribe((alternativeTitleList) => {
      const found = alternativeTitleList.find((o) => o.alternativeTitle === event.target.value);
      if (!found) {
        clearTimeout(this.alternativeTitleTimer);
        this.alternativeTitleTimer = setTimeout(() => {
          if (event.target.value.length > 0) {
            const alternativeTitle: AlternativeTitle = {
              id: null,
              alternativeTitle: event.target.value,
            };

            this.userService.saveAlternativeTitle(alternativeTitle.alternativeTitle).subscribe((res) => {
              alternativeTitle.id = res.alternativeTitleId;
              this.userService.updateAlternativeTitle(this.userInfo.id, alternativeTitle).subscribe({
                next: (result) => {
                  this.userInfo.alternativeTitleId = alternativeTitle.id;
                },
              });
            });
          }
        }, 4000);
      }
    });
  }

  private _filterAlternativeTitle(value: string): AlternativeTitle[] {
    const filterValue = value;
    return this.alternativeTitles.filter(
      (alternativeTitle) => alternativeTitle.alternativeTitle.indexOf(filterValue) === 0,
    );
  }

  setAlternativeTitle(event) {
    this.userService.updateAlternativeTitle(this.userInfo.id, event.option.value).subscribe({
      next: (result) => {
        this.userInfo.alternativeTitleId = event.option.value.id;
      },
    });
  }
}
