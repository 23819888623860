import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// models
import { PsyTest } from '@app/core/models/psy-test.model';
import { SuperTest } from '@app/core/models/supertest.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-create-new-test-dialog',
  templateUrl: './create-new-test-dialog.component.html',
  styleUrls: ['./create-new-test-dialog.component.scss'],
})
export class CreateNewTestDialogComponent implements OnInit {
  isSuperTest: boolean;
  isEdit: boolean;
  testDialogForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    version: new FormControl('', [Validators.required]),
    startPageInstructions: new FormControl('', [Validators.required]),
    warningInstructions: new FormControl('', [Validators.required]),
    paging: new FormControl('', [Validators.required]),
    testTime: new FormControl(''),
    answersRequired: new FormControl(false, [Validators.required]),
    randomizeOrder: new FormControl(false),
    scoringRequired: new FormControl(false, [Validators.required]),
    active: new FormControl(true, [Validators.required]),
    everyPageInstructionsEnabled: new FormControl(false, [Validators.required]),
    customQuestionOrder: new FormControl(false, [Validators.required]),
    showCardSortInstructions: new FormControl(false, [Validators.required]),
    discardAll: new FormControl(false, [Validators.required]),
  });

  disabledRandomOrder: boolean = false;
  originalRandomOrderValue: boolean = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '130px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Instructions',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    public dialogRef: MatDialogRef<CreateNewTestDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { isSuperTest: boolean; isEdit: boolean; test: PsyTest; superTest: SuperTest },
  ) {
    if (this.data.isSuperTest) {
      this.testDialogForm.get('testTime').clearValidators();
      this.testDialogForm.get('version').clearValidators();
      this.testDialogForm.get('answersRequired').clearValidators();
      this.testDialogForm.get('scoringRequired').clearValidators();
      this.testDialogForm.get('everyPageInstructionsEnabled').clearValidators();
      this.testDialogForm.get('customQuestionOrder').clearValidators();
      this.testDialogForm.get('showCardSortInstructions').clearValidators();
      this.testDialogForm.get('discardAll').clearValidators();
    }
  }

  ngOnInit(): void {
    this.isSuperTest = this.data.isSuperTest;
    this.isEdit = this.data.isEdit;

    if (this.data.test) {
      this.testDialogForm.get('title').setValue(this.data.test.title);
      this.testDialogForm.get('description').setValue(this.data.test.description);
      this.testDialogForm.get('version').setValue(this.data.test.version);
      this.testDialogForm.get('startPageInstructions').setValue(this.data.test.startPageInstructions);
      this.testDialogForm.get('warningInstructions').setValue(this.data.test.warningInstructions);
      this.testDialogForm.get('paging').setValue(this.data.test.paging);
      this.testDialogForm.get('testTime').setValue(this.data.test.testTime);

      if (Number(this.data.test.answersRequired) === 1) {
        this.testDialogForm.get('answersRequired').setValue(true);
      } else {
        this.testDialogForm.get('answersRequired').setValue(false);
      }

      if (Number(this.data.test.randomizeOrder) === 1) {
        this.testDialogForm.get('randomizeOrder').setValue(true);
        this.originalRandomOrderValue = true;
      } else {
        this.testDialogForm.get('randomizeOrder').setValue(false);
        this.originalRandomOrderValue = false;
      }

      if (Number(this.data.test.scoringRequired) === 1) {
        this.testDialogForm.get('scoringRequired').setValue(true);
      } else {
        this.testDialogForm.get('scoringRequired').setValue(false);
      }

      if (Number(this.data.test.active) === 1) {
        this.testDialogForm.get('active').setValue(true);
      } else {
        this.testDialogForm.get('active').setValue(false);
      }

      if (Number(this.data.test.everyPageInstructionsEnabled) === 1) {
        this.testDialogForm.get('everyPageInstructionsEnabled').setValue(true);
      } else {
        this.testDialogForm.get('everyPageInstructionsEnabled').setValue(false);
      }

      if (Number(this.data.test.customQuestionOrder) === 1) {
        this.testDialogForm.get('customQuestionOrder').setValue(true);
        this.disabledRandomOrder = true;
      } else {
        this.testDialogForm.get('customQuestionOrder').setValue(false);
        this.disabledRandomOrder = false;
      }
      if (Number(this.data.test.showCardSortInstructions) === 1) {
        this.testDialogForm.get('showCardSortInstructions').setValue(true);
      } else {
        this.testDialogForm.get('showCardSortInstructions').setValue(false);
      }
      if (Number(this.data.test.discardAll) === 1) {
        this.testDialogForm.get('discardAll').setValue(true);
      } else {
        this.testDialogForm.get('discardAll').setValue(false);
      }
    }

    if (this.data.superTest) {
      this.testDialogForm.get('title').setValue(this.data.superTest.title);
      this.testDialogForm.get('description').setValue(this.data.superTest.description);
      this.testDialogForm.get('startPageInstructions').setValue(this.data.superTest.startInstructions);
      this.testDialogForm.get('warningInstructions').setValue(this.data.superTest.warningInstructions);
      this.testDialogForm.get('paging').setValue(this.data.superTest.paging);

      let randomizeOrder: boolean;
      if (this.data.superTest.randomizeOrder.hasOwnProperty('data')) {
        randomizeOrder = parseInt(this.data.superTest.randomizeOrder['data'], 10) === 0 ? false : true;
      } else {
        randomizeOrder = this.data.superTest.randomizeOrder;
      }

      let active: boolean;
      if (this.data.superTest.active.hasOwnProperty('data')) {
        active = parseInt(this.data.superTest.active['data'], 10) === 0 ? false : true;
      } else {
        active = this.data.superTest.active;
      }
      if (randomizeOrder) {
        this.testDialogForm.get('randomizeOrder').setValue(true);
      } else {
        this.testDialogForm.get('randomizeOrder').setValue(false);
      }
      if (active) {
        this.testDialogForm.get('active').setValue(true);
      } else {
        this.testDialogForm.get('active').setValue(false);
      }

      if (Number(this.data.superTest.everyPageInstructionsEnabled) === 1) {
        this.testDialogForm.get('everyPageInstructionsEnabled').setValue(true);
      } else {
        this.testDialogForm.get('everyPageInstructionsEnabled').setValue(false);
      }
    }
  }

  changedCustomerQuestionOrder() {
    if (this.testDialogForm.get('customQuestionOrder').value) {
      this.disabledRandomOrder = true;
      this.testDialogForm.get('randomizeOrder').patchValue(false);
    } else {
      this.disabledRandomOrder = false;
      this.testDialogForm.get('randomizeOrder').patchValue(this.originalRandomOrderValue);
    }
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onSave() {
    this.dialogRef.close({ event: 'save', data: this.testDialogForm.value });
  }
}
