<div class="chart-list">
  <button mat-raised-button color="primary" class="btn-success new-button" (click)="createNewChart()">
    <mat-icon aria-hidden="false" inline="true">add</mat-icon>
    <span class="ml-1">Add a new Chart</span>
  </button>

  <app-psb-list
    *ngIf="chartListAssignedToCompany && chartListAssignedToCompany.length > 0"
    [items]="chartListAssignedToCompany"
    itemsPerPage="10"
    (view)="onView($event)"
    (delete)="onDelete($event)"
  >
  </app-psb-list>
</div>
