import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserInvite } from '../models/user-invite';
import { EmailLog } from '../models/email-log.model';
import { PsyEmail } from '../models/psy-email.model';
import { take } from 'rxjs/operators';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SendEmailService {
  private inviteSentListener = new Subject<boolean>();
  private inviteSentListenerValue: boolean = false;

  constructor(private http: HttpClient) {}

  // Working here
  sendReminders(reminder) {
    return this.http.post(BACKEND_URL + '/send-email/reminders', reminder);
  }

  getAllEmailLogsByConnectionIndicatorId(connectionIndicatorId: number) {
    return this.http.get<EmailLog[]>(
      BACKEND_URL + '/send-email/connection-indicator/' + connectionIndicatorId + '/email-logs',
    );
  }

  getAllEmailLogsByRoleMandateId(roleMandateId: number) {
    return this.http.get<EmailLog[]>(BACKEND_URL + '/send-email/role-mandate/' + roleMandateId + '/email-logs');
  }

  findInvitationEmailByTestGroupId(testGroupId: number, emailTypeId: number) {
    return this.http.get<PsyEmail>(
      BACKEND_URL + '/send-email/test-group/' + testGroupId + '/invite-message/' + emailTypeId,
    );
  }

  updateInvitationMessage(psyEmailId: number, subject: string, message: string, emailTypeId: number) {
    return this.http.patch(BACKEND_URL + '/send-email/' + psyEmailId + '/psy-email', { subject, message, emailTypeId });
  }

  saveInvitationMessage(subject: string, message: string, emailTypeId: number, testGroupId: number) {
    return this.http.post(BACKEND_URL + '/send-email/psy-email', { subject, message, testGroupId, emailTypeId });
  }

  sendUserResetPassword(email: string) {
    return this.http.post(BACKEND_URL + '/send-email/user/reset-password', { email });
  }

  sendTestGroupInvites(testGroupId: number, invites: UserInvite[], subject: string, message: string) {
    return this.http.post(BACKEND_URL + '/send-email/test-group/' + testGroupId + '/invite', {
      invites,
      subject,
      message,
    });
  }

  getAllEmailLogsByTestGroupId(testGroupId: number) {
    return this.http.get<EmailLog[]>(BACKEND_URL + '/send-email/test-group/' + testGroupId + '/email-logs');
  }

  verifyEmail(email) {
    return this.http.post(BACKEND_URL + '/send-email/verify-email', { email: email });
  }

  getInviteSentListener() {
    return this.inviteSentListener.asObservable().pipe(take(1));
  }

  setInviteSentListener(value: boolean) {
    this.inviteSentListenerValue = value;
    this.inviteSentListener.next(value);
  }

  getContentByEmailLogId(emailLogId: number) {
    return this.http.get<any>(BACKEND_URL + '/send-email/email-logs/' + emailLogId + '/content');
  }
}
