import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { ChartRoutingModule } from './chart-routing.module';

import { ChartComponent } from './chart/chart.component';
import { ChartDetailsComponent } from './chart-details/chart-details.component';
import { CreateNewChartDialogComponent } from './create-new-chart-dialog/create-new-chart-dialog.component';
import { ChartSubscalesComponent } from './chart-subscales/chart-subscales.component';
import { AddSubscaleDialogComponent } from './add-subscale-dialog/add-subscale-dialog.component';
import { ChartPlotSetupComponent } from './chart-plot-setup/chart-plot-setup.component';
import { ChartBaseSetupComponent } from './chart-base-setup/chart-base-setup.component';
import { EditChartDialogComponent } from './edit-chart-dialog/edit-chart-dialog.component';
import { BlossomPetalSetupComponent } from '@app/admin/pages/chart/blossom/blossom-petal-setup/blossom-petal-setup.component';
import { MAT_COLOR_FORMATS, MatColorFormats, NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { BlossomPistilSetupComponent } from '@app/admin/pages/chart/blossom/blossom-pistil-setup/blossom-pistil-setup.component';
import { ApiClientModule } from '@app/admin/pages/chart/blossom/api-client/api-client.module';
import { BlossomPetalFormComponent } from './blossom/blossom-petal-setup/blossom-petal-form/blossom-petal-form.component';
import { BlossomPistilFormComponent } from './blossom/blossom-pistil-setup/blossom-pistil-form/blossom-pistil-form.component';
import { BlossomChartSetupComponent } from './blossom/blossom-chart-setup/blossom-chart-setup.component';
import { BlossomChartSetupFormComponent } from './blossom/blossom-chart-setup/blossom-chart-setup-form/blossom-chart-setup-form.component';

export const CUSTOM_MAT_COLOR_FORMATS: MatColorFormats = { display: { colorInput: 'hex' } };

@NgModule({
  declarations: [
    ChartDetailsComponent,
    ChartComponent,
    CreateNewChartDialogComponent,
    ChartSubscalesComponent,
    AddSubscaleDialogComponent,
    ChartPlotSetupComponent,
    ChartBaseSetupComponent,
    EditChartDialogComponent,
    BlossomPetalSetupComponent,
    BlossomPistilSetupComponent,
    BlossomPetalFormComponent,
    BlossomPistilFormComponent,
    BlossomChartSetupComponent,
    BlossomChartSetupFormComponent,
  ],
  imports: [
    ApiClientModule,
    CommonModule,
    ChartRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMatColorPickerModule,
  ],
  providers: [{ provide: MAT_COLOR_FORMATS, useValue: CUSTOM_MAT_COLOR_FORMATS }],
})
export class ChartModule {}
