import { Component, OnInit, ViewChild } from '@angular/core';
import { NgProgressComponent } from 'ngx-progressbar';
import { OpenDocxResponse, ReportController } from '../../../../generated/v3';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { ReportService } from '@app/core/services/report.service';
import { environment } from '../../../../environments/environment';
import { interval } from 'd3';
import { timeInterval } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-report-versioning',
  templateUrl: './report-versioning.component.html',
  styleUrls: ['./report-versioning.component.scss'],
})
export class ReportVersioningComponent implements OnInit {
  reports: OpenDocxResponse[] = [];

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  constructor(
    private reportController: ReportController,
    private route: ActivatedRoute,
    private userService: UserService,
    private reportService: ReportService,
  ) {}

  ngOnInit(): void {
    timer(1000, 5000).subscribe(async () => {
      if (this.reports.some((report) => !['done', 'error'].includes(report.generateStatus))) {
        await this.load();
      }
    });

    this.load();
  }

  async load() {
    const userId = Number(this.route.snapshot.params.id);
    const testGroupId = Number(this.route.snapshot.params.testGroupId);
    const reportId = Number(this.route.snapshot.params.reportId);

    this.reports = await this.reportController.reportControllerList(userId, testGroupId, reportId).toPromise();
  }
  //
  // async downloadPDF() {
  //   this.progressBar.start();
  //
  //   const blob = await fetch(
  //     environment.apiUrl +
  //       `/v3/downloadPdfReport?userId=${this.userId}&testGroupId=${this.testGroupId}&reportId=${this.reportId}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //     },
  //   ).then((r) => r.blob());
  //
  //   const filename = this.user.firstName + ' ' + this.user.lastName + '-' + this.report.title + '.pdf';
  //
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.download = filename;
  //   link.click();
  //
  //   this.progressBar.complete();
  // }
  //

  async create() {
    this.progressBar.start();

    const userId = Number(this.route.snapshot.params.id);
    const testGroupId = Number(this.route.snapshot.params.testGroupId);
    const reportId = Number(this.route.snapshot.params.reportId);

    this.reportController
      .reportControllerCreate({
        userId,
        testGroupId,
        reportId,
      })
      .subscribe(async () => {
        await this.load();
        this.progressBar.complete();
      });
  }

  delete(report: OpenDocxResponse) {
    this.progressBar.start();
    this.reportController.reportControllerDelete(report.id).subscribe(async () => {
      await this.load();
      this.progressBar.complete();
    });
  }

  edit(report: OpenDocxResponse) {
    window.open(report.link, 'blank');
  }

  async download(report: OpenDocxResponse) {
    this.progressBar.start();
    const user = await this.userService.get(report.userId).toPromise();
    const oldReport = await this.reportService
      .getReportByUserIdAndId(report.userId, report.reportId, report.testGroupId)
      .toPromise();

    const blob = await fetch(`${environment.apiUrl}/v3/docx-report/${report.id}/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((r) => r.blob());

    const filename = user.firstName + ' ' + user.lastName + '-' + oldReport.title + '.pdf';

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();

    this.progressBar.complete();
  }
}
