import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { PageService } from '@app/core/services/page.service';
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-connections-indicator-decision-tables',
  templateUrl: './connections-indicator-decision-tables.component.html',
  styleUrls: ['./connections-indicator-decision-tables.component.scss'],
})
export class ConnectionsIndicatorDecisionTablesComponent implements OnInit {
  connectionIndicatorId: number;
  decisionTables: DecisionTable[];
  decisionTableType = 'connectionsIndicator';
  newDecisionTable = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cIService: ConnectionsIndicatorService,
    private decisionTableService: DecisionTableService,
    public dialog: MatDialog,
    public pageService: PageService,
  ) {}

  ngOnInit(): void {
    this.connectionIndicatorId = Number(this.route.snapshot.params.id);
    this.cIService.getAllDecisionTableById(this.connectionIndicatorId).subscribe({
      next: (result) => {
        this.decisionTables = result;
      },
    });
  }

  updateDecisionTables(decisionTables: DecisionTable[]) {
    this.decisionTables = decisionTables;
    this.newDecisionTable = false;
  }

  onDeleteDecisionTable(decisionTableId: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.componentInstance.message = `Please confirm that you want delete this Decision Table?`;

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.cIService.deleteConnectionIndicatorDecisionTable(decisionTableId).subscribe(() => {
          this.decisionTables = this.decisionTables.filter((decisionTable) => decisionTable.id !== decisionTableId);
        });
      }
    });
  }

  onEditDecisionTable(decisionTable: DecisionTable) {
    this.decisionTableService.updateByConnectionIndicator(decisionTable, this.connectionIndicatorId).subscribe();
  }

  onNext() {
    this.router.navigate([`connections-indicator/${this.connectionIndicatorId}/sort`]);
  }

  createNewDecisionTable() {
    this.newDecisionTable = true;
  }
}
