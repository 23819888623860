import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-centrality-table',
  templateUrl: './centrality-table.component.html',
  styleUrls: ['./centrality-table.component.scss'],
})
export class CentralityTableComponent implements OnInit {
  @Input() dataSource: any[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  data: MatTableDataSource<any[]>;

  constructor() {}

  ngOnInit(): void {
    if (this.dataSource) {
      this.data = new MatTableDataSource<any[]>(this.dataSource);
      this.data.paginator = this.paginator;
    }
  }

  sortData(sort: Sort) {
    this.data.data = this.handleSortData(sort, this.data);
  }

  handleSortData(sort: Sort, dataAnalysis: any) {
    let sortedData: any;
    const data = dataAnalysis.data.slice();

    if (!sort.active || sort.direction === '') {
      sortedData = data;
      return sortedData;
    }

    sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.firstName, b.firstName, isAsc);
        case 'eigenvectorCentrality':
          return this.compare(a.eigenvectorCentrality, b.eigenvectorCentrality, isAsc);
        case 'neighborhoodCentrality':
          return this.compare(a.neighborhoodCentrality, b.neighborhoodCentrality, isAsc);
        case 'combinationCetrality':
          return this.compare(a.combinationCetrality, b.combinationCetrality, isAsc);
        default:
          return 0;
      }
    });
    return sortedData;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
