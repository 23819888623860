<mat-card class="mb-8">
  <h2>{{ psyTestTitle }}</h2>
  <mat-divider></mat-divider>
  <span *ngFor="let subscale of subscales | keyvalue: percentileDescOrder; let i = index">
    <app-report-user-score
      [userScore]="subscale.value"
      [color]="subscale.value.color"
      [index]="i"
      [isAdmin]="isAdmin"
      [isOA]="isOA"
      [isTgm]="isTgm"
      [allowRawScoresAccess]="allowRawScoresAccess"
    ></app-report-user-score>
  </span>
</mat-card>
