import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { MFARequiredModalService } from './mfa-required-modal.service';

@Component({
  selector: 'app-mfa-required-modal',
  templateUrl: './mfa-required-modal.component.html',
  styleUrls: ['./mfa-required-modal.component.scss'],
})
export class MFARequiredModalComponent implements AfterViewInit {
  @ViewChild('mainDOMElement') mainDOMElement: ElementRef;
  postRequiredDate = false;
  showModal = false;

  constructor(private mfaRequiredModalService: MFARequiredModalService) {}

  ngAfterViewInit(): void {
    this.mfaRequiredModalService.checkIfMFARequired().subscribe({
      next: (result) => {
        if (result) {
          const today = new Date();
          const requiredDate = new Date('Mar 31, 2021');
          const dismissableTimeFrame = 4 * 60 * 60 * 1000; // 4 hours * 60 min/hour * 60 sec/min * 1000 millisec/sec
          const previouslyClosedTimestamp = localStorage.getItem('lastTimeClosedRequiredMFAModal'); // time we last dismissed the modal

          this.postRequiredDate = today >= requiredDate; // determine if allowed to close

          if (previouslyClosedTimestamp && !this.postRequiredDate) {
            const latesttimeDismissed = Number(previouslyClosedTimestamp) + dismissableTimeFrame;

            // Is the latest time the previous dismissal covers before now?
            if (new Date(latesttimeDismissed) < new Date()) {
              this.openModal();
            } else {
              // No need to open in this case - the previous dismissal was within the last $dismissableTimeFrame milliseconds
            }
          } else {
            this.openModal();
          }
        }
      },
    });
  }

  attemptCloseModal(): void {
    if (!this.postRequiredDate) {
      this.closeModal();
    }
  }
  openModal(): void {
    this.showModal = true;
    this.mainDOMElement.nativeElement.classList.remove('fadedOut');
    this.mainDOMElement.nativeElement.classList.remove('fadeOutAnimation');
    this.mainDOMElement.nativeElement.classList.add('fadedIn');
    this.mainDOMElement.nativeElement.classList.add('fadeInAnimation');
    this.mainDOMElement.nativeElement.focus();
  }
  closeModal(): void {
    this.showModal = false;
    // record the time we closed the modal for future reference
    const nowTimeStr = new Date().getTime().toString();
    localStorage.setItem('lastTimeClosedRequiredMFAModal', nowTimeStr);
    this.mainDOMElement.nativeElement.classList.remove('fadeInAnimation');
    this.mainDOMElement.nativeElement.classList.add('fadeOutAnimation');
    // Hide element in 2 seconds after fade out
    setTimeout(() => {
      this.mainDOMElement.nativeElement.classList.remove('fadedIn');
      this.mainDOMElement.nativeElement.classList.add('fadedOut');
    }, 2000);
  }
}
