<h1 mat-dialog-title>Edit range content</h1>
<form [formGroup]="form" (submit)="onSubmit()">
  <div class="range-header">
    <mat-form-field>
      <mat-label>From</mat-label>
      <input matInput required formControlName="from" type="number" min="0" max="99" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>To</mat-label>
      <input matInput required formControlName="to" type="number" min="1" max="100" />
    </mat-form-field>
  </div>
  <div>
    <angular-editor [config]="editorConfig" formControlName="rangeText"> </angular-editor>
  </div>
  <div id="action-buttons" mat-dialog-actions align="end">
    <button id="cancel-button" mat-flat-button (click)="onCancel()">
      <mat-icon>block</mat-icon>
      Cancel
    </button>
    <button id="update-button" mat-flat-button color="primary" type="submit">
      <mat-icon>save_alt</mat-icon>
      Save
    </button>
  </div>
</form>
