import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

// models
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-stats-user',
  templateUrl: './stats-user.component.html',
  styleUrls: ['./stats-user.component.scss'],
})
export class StatsUserComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() users: UserInfo[];

  dataSource: MatTableDataSource<UserInfo>;
  displayedColumns: string[] = ['firstName', 'completed', 'createdDate'];
  lineChartLabels: string[];
  lineChartData: number[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadData() {
    this.dataSource = new MatTableDataSource(this.users);

    const result = [];
    this.users.reduce((res, value) => {
      if (!res[value.createdDate]) {
        res[value.createdDate] = { createdDate: value.createdDate, qty: 0 };
        result.push(res[value.createdDate]);
      }
      res[value.createdDate].qty++;
      return res;
    }, {});

    this.lineChartLabels = result.map((data) => data.createdDate);
    this.lineChartData = result.map((data) => data.qty);
  }

  ngOnChanges() {
    this.loadData();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
