<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ question.text | stripeHtml }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ question.instructions | stripeHtml }}
    </mat-card-subtitle>
    <mat-chip-list>
      <mat-chip class="mat-chip-orange">sliders</mat-chip>
      <mat-chip *ngIf="question.subscale" class="mat-chip-blue">
        {{ question.subscale.title }}
      </mat-chip>
    </mat-chip-list>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z3">
      <ng-container matColumnDef="answer">
        <th mat-header-cell *matHeaderCellDef class="mat-header-row">Answer</th>
        <td mat-cell class="mat-row" *matCellDef="let element">{{ element.answer }}</td>
      </ng-container>

      <ng-container matColumnDef="selectionPercentage">
        <th mat-header-cell *matHeaderCellDef>Selected</th>
        <td mat-cell class="mat-row" *matCellDef="let element">{{ element.selectionPercentage }}%</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
  </mat-card-content>
</mat-card>
