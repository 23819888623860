import { Color } from '@angular-material-components/color-picker';

export function hexToColor(hex?: string) {
  if (hex === undefined) return undefined;

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return new Color(r, g, b);
}
