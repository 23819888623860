<mat-table [dataSource]="data" class="mat-elevation-z3" matSort (matSortChange)="sortData($event)">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.firstName }} {{ element.lastName }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="eigenvectorCentrality">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Eigenvector </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.eigenvectorCentrality.toFixed(3) }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="neighborhoodCentrality">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Neighborhood </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.neighborhoodCentrality.toFixed(3) }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="combinationCetrality">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Combination </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.combinationCetrality.toFixed(3) }} </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="['name', 'eigenvectorCentrality', 'neighborhoodCentrality', 'combinationCetrality']"
  >
  </mat-header-row>
  <mat-row
    *matRowDef="let row; columns: ['name', 'eigenvectorCentrality', 'neighborhoodCentrality', 'combinationCetrality']"
  >
  </mat-row>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="2">No data"</td>
  </tr>
</mat-table>
<mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 50]" showFirstLastButtons></mat-paginator>
