<h2>Subscale: {{ subscale.title }}</h2>
<h3>{{ subscale.description }}</h3>
<div *ngIf="allObj">
  <div *ngFor="let obj of allObj | paginate: config">
    <mat-card
      class="my-5 mx-0"
      *ngIf="
        testObjectService.isQuestion(obj) && (obj.questionType === 'TEXT_AREA' || obj.questionType === 'RICH_TEXT')
      "
    >
      <app-answer-text-area
        [question]="obj"
        [answering]="false"
        [loadAnswer]="true"
        [isPreview]="true"
      ></app-answer-text-area>
    </mat-card>
    <mat-card class="my-5 mx-0" *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'SLIDER'">
      <app-answer-slider
        [question]="obj"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-slider>
    </mat-card>

    <mat-card class="my-5 mx-0" *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'IMAGE'">
      <app-answer-multiple-vertical-choice
        [question]="obj"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-multiple-vertical-choice>
    </mat-card>

    <mat-card class="my-5 mx-0" *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'MULTI_VERT'">
      <app-answer-multiple-vertical-choice
        [question]="obj"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-multiple-vertical-choice>
    </mat-card>
    <mat-card class="my-5 mx-0" *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'MULTI_HOR'">
      <app-answer-multiple-horizontal-choice
        [question]="obj"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-multiple-horizontal-choice>
    </mat-card>
    <mat-card class="my-5 mx-0" *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'CARDSORT'">
      <app-answer-cardsort
        [question]="obj"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-cardsort>
    </mat-card>
    <mat-card class="my-5 mx-0" *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'PENTAGON'">
      <app-answer-pentagon
        [question]="obj"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-pentagon>
    </mat-card>
    <mat-card class="my-5 mx-0" *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'TEXT_SINGLE_LINE'">
      <app-answer-text-single-line
        [question]="obj"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-text-single-line>
    </mat-card>

    <mat-card class="my-5 mx-0" *ngIf="testObjectService.isQuestionsGroup(obj)" class="question-group">
      <p *ngIf="obj.instructions" [innerHTML]="obj.instructions"></p>
      <div *ngFor="let question of obj.questions" class="question">
        <div *ngIf="question.questionType === 'TEXT_AREA' || question.questionType === 'RICH_TEXT'">
          <app-answer-text-area
            [question]="question"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-text-area>
        </div>
        <div *ngIf="question.questionType === 'SLIDER'">
          <app-answer-slider
            [question]="question"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-slider>
        </div>
        <div *ngIf="question.questionType === 'IMAGE'">
          <app-answer-multiple-vertical-choice
            [question]="question"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-multiple-vertical-choice>
        </div>
        <div *ngIf="question.questionType === 'MULTI_VERT'">
          <app-answer-multiple-vertical-choice
            [question]="question"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-multiple-vertical-choice>
        </div>
        <div *ngIf="question.questionType === 'MULTI_HOR'">
          <app-answer-multiple-horizontal-choice
            [question]="question"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-multiple-horizontal-choice>
        </div>
        <div *ngIf="question.questionType === 'CARDSORT'">
          <app-answer-cardsort
            [question]="question"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-cardsort>
        </div>
        <div *ngIf="question.questionType === 'PENTAGON'">
          <app-answer-pentagon
            [question]="question"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-pentagon>
        </div>
        <div *ngIf="question.questionType === 'TEXT_SINGLE_LINE'">
          <app-answer-text-single-line
            [question]="question"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-text-single-line>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="row" *ngIf="allObj.length > 0">
    <pagination-controls
      class="pagination my-4 mx-auto"
      id="preview-paginator"
      (pageChange)="pageChanged($event)"
    ></pagination-controls>
  </div>
</div>
