import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// services
import { PageService } from '@app/core/services/page.service';

@Component({
  selector: 'app-role-mandate-users',
  templateUrl: './role-mandate-users.component.html',
  styleUrls: ['./role-mandate-users.component.scss'],
})
export class RoleMandateUsersComponent implements OnInit {
  roleMandateId: number;
  pages: any[];

  constructor(private route: ActivatedRoute, public pageService: PageService, private router: Router) {}

  ngOnInit(): void {
    this.roleMandateId = Number(this.route.snapshot.params.id);
    this.getRoleMandatePages();
  }

  onNext() {
    if (this.roleMandateId) {
      return this.router.navigate(['/role-mandate/' + this.roleMandateId + '/questions']);
    }
  }

  getRoleMandatePages() {
    this.pages = this.pageService.getRoleMandatePages(this.roleMandateId);
  }
}
