import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { OptimalScoreCurve } from '../models/optimal-score-curve.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class OptimalCurvesService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<OptimalScoreCurve[]>(BACKEND_URL + '/optimal-curve');
  }

  findById(optimalScoreId: number) {
    return this.http.get<OptimalScoreCurve>(BACKEND_URL + '/optimal-curve/' + optimalScoreId);
  }

  delete(optimalScoreId: number) {
    return this.http.delete(BACKEND_URL + '/optimal-curve/' + optimalScoreId);
  }

  save(optimalScore: OptimalScoreCurve) {
    return this.http.post<OptimalScoreCurve>(BACKEND_URL + '/optimal-curve/', optimalScore);
  }

  update(optimalScoreId: number, optimalScoreCurve: OptimalScoreCurve) {
    return this.http.put<OptimalScoreCurve>(BACKEND_URL + '/optimal-curve/' + optimalScoreId, optimalScoreCurve);
  }
}
