export const isHTML = (str: any) => {
  return !(str || '')
    .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
    .replace(/(<([^>]+)>)/gi, '')
    .trim();
};
export const extractContent = (s: any) => {
  var span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
};
