import { Component, Input } from '@angular/core';
import { PsbStatsLine } from '@app/core/models/psb-stats-line.model';

@Component({
  selector: 'app-psb-stats-line',
  templateUrl: './psb-stats-line.component.html',
  styleUrls: ['./psb-stats-line.component.scss'],
})
export class PsbStatsLineComponent {
  @Input() psbStats: PsbStatsLine[];

  constructor() {}
}
