import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { SharedModule } from '@app/shared/shared.module';
import { SuperTestsRoutingModule } from './super-tests-routing.module';
import { PsyTestModule } from '../tests/tests.module';

import { SuperTestListComponent } from './super-test-list/super-test-list.component';
import { SuperTestDetailComponent } from './super-test-detail/super-test-detail.component';
import { SuperTestTestListComponent } from './super-test-test-list/super-test-test-list.component';
import { CreateNewSupertestTestDialogComponent } from './create-new-supertest-test-dialog/create-new-supertest-test-dialog.component';

@NgModule({
  declarations: [
    SuperTestListComponent,
    SuperTestDetailComponent,
    SuperTestTestListComponent,
    CreateNewSupertestTestDialogComponent,
  ],
  imports: [
    CommonModule,
    SuperTestsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    Ng2SearchPipeModule,
    PsyTestModule,
  ],
})
export class SuperTestsModule {}
