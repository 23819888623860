import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { AddChartDialogComponent } from '../add-chart-dialog/add-chart-dialog.component';
import { AddChapterDialogComponent } from '../add-chapter-dialog/add-chapter-dialog.component';
import { EditReportTitleDialogComponent } from '../edit-report-title-dialog/edit-report-title-dialog.component';
import { SectionDialogFormComponent } from '../../chapter/section-dialog-form/section-dialog-form.component';

// services
import { SectionService } from '@app/core/services/section.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { ReportService } from '@app/core/services/report.service';
import { ChapterService } from '@app/core/services/chapter.service';

// models
import { Section } from '@app/core/models/section.model';
import { PsbItem } from '@app/core/models/psb-item.model';
import { Report } from '@app/core/models/report.model';
import { FormControl, Validators } from '@angular/forms';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';
import { Chapter } from '@app/core/models/chapter.model';
import { direction } from 'html2canvas/dist/types/css/property-descriptors/direction';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss'],
})
export class ReportDetailsComponent implements OnInit {
  report: Report & { expanded?: boolean };
  chartPsbItems: PsbItem[] = [];
  selectedSection: Section;
  reportId: number;
  selected = new FormControl(0);

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private dialog: MatDialog,
    private router: Router,
    private sectionService: SectionService,
    private snackBar: SnackBarService,
    private chapterService: ChapterService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.selected.setValue(params.id ? +params.tab : 0);
    });
    const { params } = this.activatedRoute.snapshot;
    this.reportId = Number(params.id);
    this.getReport(this.reportId);
  }

  getReport(reportId: number) {
    this.reportService.getById(reportId).subscribe((report) => {
      this.report = report;
      this.chartPsbItems =
        this.report.charts?.map((chart) => ({
          id: chart.id,
          title: chart.name,
          description: chart.header,
        })) || [];
    });
  }

  onDelete(chapterId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.reportService.removeChapterFromReport(this.report.id, chapterId).subscribe(() => {
          this.report.chapters = this.report.chapters.filter((chapter) => chapter.id !== chapterId);
        });
      });
  }

  onAddChapter() {
    const data = { reportId: this.report.id, chaptersIds: this.report.chapters.map(({ id }) => id) };
    this.dialog
      .open(AddChapterDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_SUCCESS) {
          return;
        }

        const { addedChapter } = result;
        this.report.chapters.push({
          id: addedChapter.id,
          title: addedChapter.title,
          sections: addedChapter.sections,
        });
      });
  }

  onEditReportTitle() {
    const data = { id: this.report.id, title: this.report.title };
    this.dialog
      .open(EditReportTitleDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_SUCCESS) {
          return;
        }
        this.report.title = result.newTitle;
      });
  }

  onAddChart() {
    const data = {
      reportId: this.report.id,
      addedChartsIds: this.report.charts?.map((chart) => chart.id) || [],
    };
    this.dialog
      .open(AddChartDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_SUCCESS) {
          return;
        }

        const { addedChart } = result;
        this.chartPsbItems = [
          ...this.chartPsbItems,
          { id: addedChart.id, title: addedChart.name, description: addedChart.header },
        ];
      });
  }

  onChartView(chartId: number) {
    this.router.navigate(['/chart', chartId]);
  }

  onChartDelete(chartId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.reportService.removeChartFromReport(this.report.id, chartId).subscribe(() => {
          this.chartPsbItems = this.chartPsbItems.filter((chart) => chart.id !== chartId);
        });
      });
  }

  expandSection(chosenSection: any) {
    if (chosenSection.expanded) {
      chosenSection.expanded = false;
      this.selectedSection = null;
      return;
    }

    this.report.chapters.forEach((chapter) => chapter.sections.forEach((section: any) => (section.expanded = false)));

    this.sectionService.findById(chosenSection.id).subscribe((section) => {
      this.selectedSection = section;
      chosenSection.expanded = true;
    });
  }

  moveUp(sectionId: number, chapterId: number, chapterIndex: number, sectionIndex: number) {
    if (sectionIndex < 1) return;

    this.sectionService
      .move(sectionId, {
        chapterId: chapterId,
        direction: 'up',
      })
      .subscribe((result) => {
        this.swap(this.report.chapters[chapterIndex].sections, sectionIndex, sectionIndex - 1);
      });
  }

  moveDown(sectionId: number, chapterId: number, chapterIndex: number, sectionIndex: number) {
    if (sectionIndex >= this.report.chapters.length) return;

    this.sectionService
      .move(sectionId, {
        chapterId: chapterId,
        direction: 'down',
      })
      .subscribe((result) => {
        this.swap(this.report.chapters[chapterIndex].sections, sectionIndex, sectionIndex + 1);
      });
  }

  private swap(selectedSection: any[], x: any, y: any) {
    var b = selectedSection[x];
    selectedSection[x] = selectedSection[y];
    selectedSection[y] = b;
  }

  onEdit(sectionId: number, chapterIndex: number, sectionIndex: number) {
    this.sectionService.findById(sectionId).subscribe((section) => {
      const data = {
        isEdit: true,
        section,
      };

      this.dialog
        .open(SectionDialogFormComponent, { data })
        .afterClosed()
        .subscribe((result) => {
          if (typeof result !== 'undefined' && result.event === EVENT_SUCCESS) {
            if (result.updatedSection) {
              this.report.chapters[chapterIndex].sections[sectionIndex] = result.updatedSection;
              this.snackBar.info('Section updated with success.');
            }
          }
        });
    });
  }

  onCopy(reportId: number, chapterId: number, section: Section) {
    const copySection = {
      id: section.id,
      title: section.title + ' (copy)',
      type: section.type,
    };

    this.reportService.onCopySection(reportId, chapterId, copySection).subscribe((res) => {
      this.getReport(reportId);
      this.snackBar.info('Section copied with success.');
    });
  }

  tabChange(event) {
    this.selected.setValue(event);
    this.router.navigate([this.router.url.split('tab/')[0] + '/tab/' + event]);
  }

  moveUpChapter(chapterId: number, reportId: number, chapterIndex: number) {
    if (chapterIndex < 1) return;

    this.chapterService
      .move(chapterId, {
        reportId: reportId,
        direction: 'up',
      })
      .subscribe((result) => {
        this.swap(this.report.chapters, chapterIndex, chapterIndex - 1);
      });
  }

  moveDownChapter(chapterId: number, reportId: number, chapterIndex: number) {
    if (chapterIndex >= this.report.chapters.length) return;

    this.chapterService
      .move(chapterId, {
        reportId: reportId,
        direction: 'down',
      })
      .subscribe((result) => {
        this.swap(this.report.chapters, chapterIndex, chapterIndex + 1);
      });
  }
}
