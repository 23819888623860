<div class="container">
  <div class="page-list-header">
    <h1>Question Answers</h1>

    <button mat-raised-button class="new-button pull-right" color="primary" (click)="onCreate()">
      <mat-icon aria-hidden="false">add</mat-icon>
      Create an Answer
    </button>
  </div>

  <app-psb-list
    *ngIf="answers.length"
    [items]="answers"
    [itemsPerPage]="10"
    (view)="onView($event)"
    (delete)="onDelete($event)"
    [typeOfView]="'questionAnswerSubscaleTitle'"
    [isIntegerAnswer]="isIntegerAnswer"
  >
  </app-psb-list>
</div>
