<div>
  <div class="row">
    <div class="col-2">
      <app-total-box [total]="users ? users.length : 0" description="Total Users" color="orange"></app-total-box>
    </div>
    <div class="col-10">
      <app-line-chart [chartData]="lineChartData" [chartLabels]="lineChartLabels" [label]="'Users'"></app-line-chart>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <mat-table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.firstName }} {{ row.lastName }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="completed">
        <mat-header-cell *matHeaderCellDef> Completed/Available </mat-header-cell>
        <mat-cell *matCellDef="let row"> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Date Complete </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.createdDate }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
  </div>
</div>
