<div class="row" style="margin: 10px 0px">
  <div class="column">
    <div class="row">
      <span class="title">{{ decisionTable.title }} | </span>
      <span class="network-type">{{ decisionTable.networkType }} </span>
    </div>
    <span class="instructions">{{ decisionTable.instructions }}</span>
  </div>

  <div class="column">
    <button mat-button aria-label="Refresh" class="pull-right" (click)="refreshDecisionTable(decisionTable.id)">
      <mat-icon>refresh</mat-icon> Refresh Centrality
    </button>
  </div>
</div>

<div class="row">
  <div class="column" style="margin-top: 0px">
    <h2>Centrality</h2>
    <app-centrality-table *ngIf="analysis" [dataSource]="analysis"> </app-centrality-table>
  </div>
  <div class="column">
    <h2>Network</h2>
    <app-user-network
      [nodesData]="nodes"
      [type]="'decisionTable'"
      [id]="decisionTable.id"
      [roleMandateId]="roleMandateId"
    ></app-user-network>
  </div>
</div>
