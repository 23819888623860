<div #mainDOMElement class="fadedOut" [attr.aria-hidden]="!showModal">
  <mat-card [attr.aria-modal]="showModal">
    <mat-card-title-group>
      <mat-card-title>Notice</mat-card-title>
      <mat-card-subtitle>MFA Enrollment Required</mat-card-subtitle>
    </mat-card-title-group>
    <mat-card-content
      >You are reqired to enroll in MFA. Please go to your profile page and enroll in an MFA option. This is required
      and beginning on Match 31st, you will be locked out of Psybil until you do so.</mat-card-content
    >
    <mat-card-actions><a href="/profile">Go to profile page</a></mat-card-actions>
  </mat-card>
  <div class="backdrop" (click)="attemptCloseModal()"></div>
</div>
