import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountdownEvent } from 'ngx-countdown';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent {
  @Input() config: object;
  @Input() title: string;
  @Input() position: string;
  @Output() timeOver = new EventEmitter<boolean>();

  isFinish = false;
  countDownColor: string;
  countDownSize: string;

  constructor() {}

  handleEvent(e: CountdownEvent) {
    if (e.left < 600001 && e.left > 120000) {
      this.countDownColor = 'orange';
    } else if (e.left <= 120000 && e.left > -1) {
      this.countDownColor = 'red';
      if (e.left <= 60000 && e.left > 10000) {
        this.countDownSize = '35px';
      } else if (e.left <= 10000 && e.left > -1) {
        this.countDownSize = '70px';
        this.position = 'center';
      }
    } else {
      this.countDownColor = 'green';
    }
    if (e.left === 0) {
      this.isFinish = true;
      if (this.isFinish) {
        this.timeOver.emit(true);
      }
    }
  }

  getColor(): string {
    return this.countDownColor;
  }

  getFontSize(): string {
    return this.countDownSize;
  }
}
