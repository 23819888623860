<form [formGroup]="editDecisionTableAnswerForm" (ngSubmit)="saveDecisionTableAnswer()">
  <td *ngFor="let decisionTableColumn of decisionTableColumns">
    <div [ngSwitch]="decisionTableColumn.type">
      <!-- Decision Area -->
      <div *ngSwitchCase="'1'">
        <mat-form-field>
          <input
            type="text"
            placeholder="Select One"
            aria-label="Decision Area"
            matInput
            [formControlName]="getFormName(decisionTableColumn.title)"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayDecisionArea">
            <mat-option *ngFor="let decisionArea of filteredUsersDecisionAreas | async" [value]="decisionArea">
              {{ decisionArea.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error
          *ngIf="
            editDecisionTableAnswerForm.get(getFormName(decisionTableColumn.title)).errors &&
            (editDecisionTableAnswerForm.get(getFormName(decisionTableColumn.title)).dirty ||
              editDecisionTableAnswerForm.get(getFormName(decisionTableColumn.title)).touched)
          "
        >
          Please, select a valid {{ decisionTableColumn.title }}
        </mat-error>
      </div>

      <!-- Users Selection -->
      <div *ngSwitchCase="'2'">
        <mat-form-field>
          <mat-label>Search</mat-label>
          <input
            type="text"
            placeholder="User name, Department or Role"
            matInput
            #trigger="matAutocompleteTrigger"
            [formControlName]="getFormName(decisionTableColumn.title)"
            autocomplete="off"
            [matAutocomplete]="auto"
          />
          <mat-icon matSuffix (click)="onClearSearch(getFormName(decisionTableColumn.title), $event, trigger)">
            clear
          </mat-icon>

          <mat-autocomplete [displayWith]="displayUser" #auto="matAutocomplete">
            <mat-option
              *ngFor="let user of filteredUsers[getFormName(decisionTableColumn.title)] | async"
              (click)="optionClicked($event, user, getFormName(decisionTableColumn.title))"
              [value]="this.selectedUsers[getFormName(decisionTableColumn.title)]"
            >
              {{ user.firstName }} {{ user.lastName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error
          *ngIf="
            editDecisionTableAnswerForm.get(getFormName(decisionTableColumn.title)).errors &&
            (editDecisionTableAnswerForm.get(getFormName(decisionTableColumn.title)).dirty ||
              editDecisionTableAnswerForm.get(getFormName(decisionTableColumn.title)).touched)
          "
        >
          Please, select at least one user.
        </mat-error>

        <mat-chip-list class="mat-chip-list-stacked" aria-label="Users list" style="margin: 0 3px">
          <div *ngFor="let answer of decisionTableAnswer[getFormName(decisionTableColumn.title)]; let i = index">
            <mat-chip
              [selectable]="answering"
              *ngIf="answer"
              (removed)="deleteAnswerUserConnection(answer, getFormName(decisionTableColumn.title), i)"
            >
              {{ answer.userName }}
              <mat-icon matChipRemove *ngIf="answering && !decisionTableAnswer.fromRelation">cancel </mat-icon>
            </mat-chip>
          </div>
        </mat-chip-list>
      </div>

      <!-- Text answer -->
      <div *ngSwitchCase="'3'">
        <mat-form-field>
          <input type="text" [formControlName]="getFormName(decisionTableColumn.title)" matInput />
        </mat-form-field>
        <mat-error
          *ngIf="
            editDecisionTableAnswerForm.get(getFormName(decisionTableColumn.title)).errors &&
            (editDecisionTableAnswerForm.get(getFormName(decisionTableColumn.title)).dirty ||
              editDecisionTableAnswerForm.get(getFormName(decisionTableColumn.title)).touched)
          "
        >
          Please, enter valid text.
        </mat-error>
      </div>
      <div *ngSwitchDefault>
        {{ decisionTableAnswer[getFormName(decisionTableColumn.title)] }}
      </div>
    </div>
  </td>
  <td class="td-toggle" *ngIf="decisionTable.acl !== undefined && decisionTable.acl === 5">
    <mat-slide-toggle
      *ngIf="decisionTable.acl === 5"
      color="primary"
      [disabled]="!answering"
      [checked]="+decisionTableAnswer.private === 1"
      (change)="updateAnswerPrivacy(decisionTableAnswer)"
    >
    </mat-slide-toggle>
  </td>
  <td *ngIf="answering && !decisionTableAnswer.fromRelation">
    <div class="mobile-actions">
      <button type="submit" class="mobile-action-buttons" mat-icon-button aria-label="Update">
        <mat-icon>check</mat-icon>
      </button>
      <button type="button" class="mobile-action-buttons" mat-icon-button aria-label="Cancel" (click)="onCancel()">
        <mat-icon>block</mat-icon>
      </button>
    </div>
    <div class="desktop-action-buttons">
      <button
        type="submit"
        class="desktop-action-buttons"
        mat-button
        aria-label="Confirm"
        [disabled]="isEmptyRequiredUserConnectionColumn()"
      >
        Confirm
      </button>
      <button type="button" class="desktop-action-buttons" mat-button aria-label="Cancel" (click)="onCancel()">
        Cancel
      </button>
    </div>
  </td>
</form>
