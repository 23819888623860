import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-dashboard-subscales',
  templateUrl: './report-dashboard-subscales.component.html',
  styleUrls: ['./report-dashboard-subscales.component.scss'],
})
export class ReportDashboardSubscalesComponent implements OnInit {
  @Input() userInfoId: number;
  @Input() tests: any;
  @Input() isAdmin: boolean;
  @Input() isOA: boolean;
  @Input() isTgm: boolean;
  @Input() allowRawScoresAccess: boolean;

  constructor() {}

  ngOnInit(): void {}
}
