<div class="container">
  <div class="page-list-header">
    <h1>Tests</h1>
    <button mat-raised-button class="new-button pull-right" color="primary" (click)="createNewTest()">
      <mat-icon aria-hidden="false">add</mat-icon>Create a new Test
    </button>
  </div>

  <app-psb-list
    *ngIf="psyTests.length > 0"
    [items]="psyTests"
    [itemsPerPage]="10"
    (copyEvent)="onCopy($event)"
    (delete)="onDelete($event)"
    (view)="onView($event)"
  >
  </app-psb-list>
</div>
