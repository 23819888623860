import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AreaMappingDetailsComponent } from './area-mapping-details/area-mapping-details.component';
import { AreaMappingsComponent } from './area-mappings/area-mappings.component';

const routes: Routes = [
  { path: '', component: AreaMappingsComponent },
  { path: ':id', component: AreaMappingDetailsComponent },
  { path: ':id/tab/:tab', component: AreaMappingDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AreaMappingsRoutingModule {}
