<button mat-raised-button class="new-button pull-right" color="primary" (click)="addNewSubscale()">
  <mat-icon aria-hidden="false">add</mat-icon>Add Subscale
</button>
<app-psb-list-table
  *ngIf="allSubcaleFromTest.length > 0"
  [allItem]="allSubcaleFromTest"
  [isSearch]="true"
  [itemsPerPage]="10"
  [displayedColumns]="displayedSubscalesColumns"
  (delete)="onDelete($event)"
  (sort)="onSort($event)"
  [isLinkable]="true"
></app-psb-list-table>
