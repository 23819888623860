<div [ngClass]="'timer ' + position">
  <p>Timing remaining</p>
  <countdown
    [config]="config"
    (event)="handleEvent($event)"
    [style.color]="getColor()"
    [style.font-size]="getFontSize()"
  >
  </countdown>
</div>
