/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { OpenDocxRequest } from '../models/OpenDocxRequest';
import type { OpenDocxResponse } from '../models/OpenDocxResponse';
import type { StreamableFile } from '../models/StreamableFile';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ReportController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param userId
     * @param testGroupId
     * @param reportId
     * @returns OpenDocxResponse
     * @throws ApiError
     */
    public reportControllerList(
        userId: number,
        testGroupId: number,
        reportId: number,
    ): Observable<Array<OpenDocxResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/docx-report',
            query: {
                'userId': userId,
                'testGroupId': testGroupId,
                'reportId': reportId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns OpenDocxResponse
     * @throws ApiError
     */
    public reportControllerCreate(
        requestBody: OpenDocxRequest,
    ): Observable<OpenDocxResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/docx-report',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param reportId
     * @returns boolean
     * @throws ApiError
     */
    public reportControllerDelete(
        reportId: number,
    ): Observable<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/docx-report/{reportId}',
            path: {
                'reportId': reportId,
            },
        });
    }

    /**
     * @param reportId
     * @returns OpenDocxResponse
     * @throws ApiError
     */
    public reportControllerGet(
        reportId: number,
    ): Observable<OpenDocxResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/docx-report/{reportId}',
            path: {
                'reportId': reportId,
            },
        });
    }

    /**
     * @param reportId
     * @returns StreamableFile
     * @throws ApiError
     */
    public reportControllerDownload(
        reportId: number,
    ): Observable<StreamableFile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/docx-report/{reportId}/download',
            path: {
                'reportId': reportId,
            },
        });
    }

}
