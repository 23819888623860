import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RoleMandateComponent } from './role-mandate/role-mandate.component';
import { RoleMandateTitlesComponent } from './role-mandate-titles/role-mandate-titles.component';
import { RoleMandateUsersComponent } from './role-mandate-users/role-mandate-users.component';
import { RoleMandateQuestionsComponent } from './role-mandate-questions/role-mandate-questions.component';
import { RoleMandateDecisionTablesComponent } from './role-mandate-decision-tables/role-mandate-decision-tables.component';
import { RoleMandateDashboardComponent } from './role-mandate-dashboard/role-mandate-dashboard.component';
import { RoleMandateUserResultComponent } from './role-mandate-user-result/role-mandate-user-result.component';
import { RoleMandateOrdenateComponent } from './role-mandate-ordenate/role-mandate-ordenate.component';
import { RoleMandateReviewComponent } from './role-mandate-review/role-mandate-review.component';
import { RoleMandateCreateComponent } from './role-mandate-create/role-mandate-create.component';

const roleMandateRoutes: Routes = [
  { path: '', component: RoleMandateComponent },
  { path: 'new', component: RoleMandateCreateComponent },
  { path: ':id/positions', component: RoleMandateTitlesComponent },
  { path: ':id/edit', component: RoleMandateCreateComponent },
  { path: ':id/dashboard', component: RoleMandateDashboardComponent },
  { path: ':id/users', component: RoleMandateUsersComponent },
  { path: ':id/questions', component: RoleMandateQuestionsComponent },
  { path: ':id/decision-tables', component: RoleMandateDecisionTablesComponent },
  { path: ':id/user/:userInfoId/results', component: RoleMandateUserResultComponent },
  { path: ':id/sort', component: RoleMandateOrdenateComponent },
  { path: ':id/review', component: RoleMandateReviewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(roleMandateRoutes)],
  exports: [RouterModule],
})
export class RoleMandateRoutingModule {}
