import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReportDashboardComponent } from './report-dashboard/report-dashboard.component';

const reportDashboardRoutes: Routes = [{ path: '', component: ReportDashboardComponent }];

@NgModule({
  imports: [RouterModule.forChild(reportDashboardRoutes)],
  exports: [RouterModule],
})
export class ReportDashboardRoutingModule {}
