<h1 *ngIf="action !== 'view'">{{ action === 'update' ? 'Edit' : 'Create' }} Generic Link</h1>
<form *ngIf="action !== 'view'" [formGroup]="genericLinkForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Expiration Date</mat-label>
    <input matInput [matDatepicker]="expirationDatePicker" formControlName="expirationDate" />
    <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #expirationDatePicker></mat-datepicker>
  </mat-form-field>

  <!--  <mat-form-field>-->
  <!--    <mat-label>Enabled</mat-label>-->
  <!--    <mat-slide-toggle formControlName="enabled">Enabled</mat-slide-toggle>-->
  <!--  </mat-form-field>-->
  <div class="slide-control">
    <mat-slide-toggle class="slide" color="primary" formControlName="enabled" checked="enabled.value">
      {{ genericLinkForm.get('enabled').value ? 'Enabled' : 'Disabled' }}
    </mat-slide-toggle>
  </div>
  <div class="slide-control">
    <mat-slide-toggle class="slide" color="primary" formControlName="autoSendPdf" checked="autoSendPdf.value">
      Send PDF report on email after finish test
    </mat-slide-toggle>
  </div>

  <div class="bottom-button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!genericLinkForm.valid">
      <mat-icon>save</mat-icon>Save
    </button>
  </div>
</form>
<div *ngIf="action === 'view'" id="view-generic-link">
  <h1>View Generic Link</h1>
  <div class="view">
    <mat-form-field>
      <mat-label>Link</mat-label>
      <input matInput disabled="true" [value]="environment.url + '/invite/' + genericLink.token" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Expiration Date</mat-label>
      <input matInput disabled="true" [value]="genericLink.expirationDate" />
    </mat-form-field>
    <div class="slide-control">
      <mat-slide-toggle class="slide" color="primary" [checked]="genericLink.enabled ? true : false" disabled="true">
        {{ genericLink.enabled ? 'Enabled' : 'Disabled' }}
      </mat-slide-toggle>
    </div>

    <div class="slide-control">
      <mat-slide-toggle
        class="slide"
        color="primary"
        disabled="true"
        [checked]="genericLink.autoSendPdf ? true : false"
      >
        Send PDF report on email after finish test
      </mat-slide-toggle>
    </div>
  </div>
  <div class="bottom-button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Close
    </button>
    <button mat-button class="btn-success btn-300" type="button" (click)="onCopy()">
      <mat-icon>file_copy</mat-icon>Copy Link
    </button>
  </div>
</div>
