import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { SharedModule } from '@app/shared/shared.module';
import { ReportModelRoutingModule } from './report-model.routing.module';

import { ReportModelComponent } from './report-model/report-model.component';
import { ReportModelDetailComponent } from './report-model-detail/report-model-detail.component';
import { CreateOrEditReportModelDialogComponent } from './create-or-edit-report-model-dialog/create-or-edit-report-model-dialog.component';

@NgModule({
  declarations: [ReportModelComponent, ReportModelDetailComponent, CreateOrEditReportModelDialogComponent],
  imports: [CommonModule, ReportModelRoutingModule, SharedModule, ReactiveFormsModule, ColorPickerModule],
})
export class ReportModelModule {}
