import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// models
import { QuestionGroup } from '@app/core/models/question-group.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-create-subscale-question-group-dialog',
  templateUrl: './create-subscale-question-group-dialog.component.html',
  styleUrls: ['./create-subscale-question-group-dialog.component.scss'],
})
export class CreateSubscaleQuestionGroupDialogComponent implements OnInit {
  isEdit: boolean;
  subscaleQuestionGroupDialogForm = new FormGroup({
    instructions: new FormControl('', [Validators.required]),
    aorder: new FormControl(null),
    title: new FormControl(null),
  });

  editorConfig: AngularEditorConfig = {
    height: '8rem',
    editable: true,
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    public dialogRef: MatDialogRef<CreateSubscaleQuestionGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; questionGroup: QuestionGroup },
  ) {}

  ngOnInit(): void {
    this.isEdit = this.data.isEdit;
    if (this.isEdit) {
      this.subscaleQuestionGroupDialogForm.get('title').setValue(this.data.questionGroup.title);
      this.subscaleQuestionGroupDialogForm.get('instructions').setValue(this.data.questionGroup.instructions);
      this.subscaleQuestionGroupDialogForm.get('aorder').setValue(this.data.questionGroup.aorder);
    }
  }

  onSave(): void {
    this.dialogRef.close({ event: 'save', data: this.subscaleQuestionGroupDialogForm.value });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
