1
<div class="container-fluid">
  <div *ngIf="this.question" class="psb-page-navigator">
    <label *ngFor="let item of tabs; last as isLast" class="breadscrum_links">
      <label (click)="navigateUrl(item)">
        {{ item?.title | slice: 0:40 }}{{ item?.title?.length > 40 ? '...' : '' }} </label
      ><label *ngIf="!isLast" class="breadscrum_arrow_icon">></label>
    </label>
  </div>

  <mat-tab-group
    *ngIf="this.question"
    mat-align-tabs="start"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="tabChange($event)"
  >
    <mat-tab label="Details">
      <ng-template matTabContent>
        <button mat-raised-button color="primary" class="pull-right" (click)="onEdit()">Edit</button>
        <div class="row">
          <div class="column">
            <span class="label">Question</span>
            <div [innerHTML]="question.text" class="detail-row"></div>
            <span class="label">Type</span>
            <p>{{ question.questionType }}</p>
            <div id="cardsort" *ngIf="question.questionType === 'CARDSORT'">
              <div id="scoring">
                <span class="label">Scoring</span>
                <app-card-sort-score-position [question]="question"></app-card-sort-score-position>
              </div>
              <div *ngIf="question.numOfCards">
                <span class="label">Number of Cards</span>
                <p>{{ question.numOfCards }}</p>
              </div>
              <div *ngIf="question.numOnGarbage">
                <span class="label">Required number of discards</span>
                <p>{{ question.numOnGarbage }}</p>
              </div>
              <span class="label">Randomize Cards</span>
              <mat-chip-list aria-label="Randomize Cards">
                <mat-chip *ngIf="question.randomizeCards" color="primary" selected> Active </mat-chip>
                <mat-chip *ngIf="!question.randomizeCards" color="warn" selected> Inactive </mat-chip>
              </mat-chip-list>
              <span class="label">Show Discarded</span>
              <mat-chip-list aria-label="Randomize Cards">
                <mat-chip *ngIf="question.showDiscarded" color="primary" selected> Active </mat-chip>
                <mat-chip *ngIf="!question.showDiscarded" color="warn" selected> Inactive </mat-chip>
              </mat-chip-list>
            </div>
            <div
              id="text"
              *ngIf="['TEXT_SINGLE_LINE', 'TEXT_AREA'].includes(question.questionType) && question.numberAnswersAllowed"
            >
              <span *ngIf="['TEXT_SINGLE_LINE', 'TEXT_AREA'].includes(question.questionType)" class="label"
                >Number of Answers Allowed</span
              >
              <p>{{ question.numberAnswersAllowed }}</p>
            </div>
            <span class="label">Order</span>
            <p>{{ question.aorder ? question.aorder : 0 }}</p>
            <span class="label">Status</span>
            <mat-chip-list aria-label="Status selection">
              <mat-chip *ngIf="question.active" color="primary" selected> Active </mat-chip>
              <mat-chip *ngIf="!question.active" color="warn" selected> Inactive </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Answers" *ngIf="!['TEXT_SINGLE_LINE', 'TEXT_AREA', 'RICH_TEXT'].includes(question.questionType)">
      <ng-template matTabContent>
        <app-subscale-question-answer-list [question]="question" [subscaleId]="subscaleId" [questionId]="questionId">
        </app-subscale-question-answer-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
