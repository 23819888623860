<div class="container-fluid">
  <div *ngIf="this.subscale" class="psb-page-navigator">
    <span *ngFor="let item of tabs; last as isLast" class="breadscrum_links">
      <label (click)="navigateUrl(item)"
        >{{ item?.title | slice: 0:40 }}{{ item?.title?.length > 40 ? '...' : '' }}</label
      ><label *ngIf="!isLast" class="breadscrum_arrow_icon">></label>
    </span>
  </div>

  <mat-tab-group
    *ngIf="this.subscale"
    mat-align-tabs="start"
    [selectedIndex]="selected.value"
    (selectedTabChange)="tabChange($event)"
  >
    <mat-tab label="Details">
      <button mat-raised-button color="primary" class="pull-right" (click)="onEdit()">Edit</button>
      <div class="row">
        <div class="column">
          <span class="label">Title</span>
          <p>{{ subscale.title }}</p>
          <span class="label">Description</span>
          <p>{{ subscale.description }}</p>
          <span class="label">Status</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="subscale.active" color="primary" selected> Active </mat-chip>
            <mat-chip *ngIf="!subscale.active" color="warn" selected> Inactive </mat-chip>
          </mat-chip-list>
          <span class="label">Answer Subscale</span>
          <mat-chip-list aria-label="Answer Subscale selection">
            <mat-chip *ngIf="subscale.cardsortSubscale" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="!subscale.cardsortSubscale" color="warn" selected> No </mat-chip>
          </mat-chip-list>
          <span class="label">Date Added</span>
          <p>{{ subscaleDate }}</p>
          <div *ngIf="!subscale.cardsortSubscale">
            <span class="label">Random Allowed</span>
            <mat-chip-list aria-label="Random Allowed selection">
              <mat-chip *ngIf="subscale.randomAllowed" color="primary" selected> Yes </mat-chip>
              <mat-chip *ngIf="!subscale.randomAllowed" color="warn" selected> No </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Questions" *ngIf="subscale.cardsortSubscale === 0">
      <app-subscale-question-list [subscaleId]="subscaleId"></app-subscale-question-list>
    </mat-tab>
    <mat-tab label="Question Groups" *ngIf="subscale.cardsortSubscale === 0">
      <app-subscale-question-group-list
        [subscaleId]="subscaleId"
        [subscaleTitle]="subscale?.title"
      ></app-subscale-question-group-list>
    </mat-tab>
    <mat-tab label="Related Assessments">
      <app-psb-list
        *ngIf="
          relatedAssessmentsAndSubscales &&
            relatedAssessmentsAndSubscales.allRelatedPsyTestScale &&
            relatedAssessmentsAndSubscales.allRelatedPsyTestScale.length > 0;
          else noRelatedAssessmnents
        "
        [items]="relatedAssessmentsAndSubscales.allRelatedPsyTestScale"
        [itemsPerPage]="10"
        (view)="onViewRelatedAssessments($event)"
      ></app-psb-list>
      <ng-template #noRelatedAssessmnents>
        <p>No related assessments found.</p>
      </ng-template>
    </mat-tab>
    <mat-tab label="Related Subcales">
      <app-psb-list
        *ngIf="
          relatedAssessmentsAndSubscales &&
            relatedAssessmentsAndSubscales.allRelatedSubscale &&
            relatedAssessmentsAndSubscales.allRelatedSubscale.length > 0;
          else noRelatedSubscales
        "
        [items]="relatedAssessmentsAndSubscales.allRelatedSubscale"
        [itemsPerPage]="10"
        (view)="onViewRelatedSubscales($event)"
      ></app-psb-list>
      <ng-template #noRelatedSubscales>
        <p>No related subscales found.</p>
      </ng-template>
    </mat-tab>
    <mat-tab label="Score Percentiles">
      <app-subscale-score-percentile
        [subscaleId]="subscaleId"
        [cardsortSubscale]="subscale.cardsortSubscale"
        [subscaleTitle]="subscale.title"
      >
      </app-subscale-score-percentile>
    </mat-tab>
    <mat-tab label="Preview" *ngIf="subscale.cardsortSubscale === 0">
      <ng-template matTabContent>
        <app-subscale-preview [subscaleId]="subscaleId" [subscale]="subscale"></app-subscale-preview>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
