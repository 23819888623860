import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-violin-new',
  templateUrl: './violin.component.html',
  styleUrls: ['./violin.component.scss'],
})
export class ViolinNewComponent implements OnInit {
  @Input() testGroupId: number;
  @Input() userId: number;
  @Input() reportId: number;
  @Input() sectionId: number;
  @Input() XSubscale?: boolean;
  @Input() YSubscale?: boolean;

  src: string;
  ngOnInit(): void {
    this.src = `${environment.apiUrl}/v3/user/${this.userId}/testGroup/${this.testGroupId}/sectionId/${this.sectionId}`;
  }
}
