<form [formGroup]="form" (submit)="onSave()">
  <button mat-raised-button color="primary" type="submit">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>

  <div class="plot-slide">
    <mat-form-field class="plot-radius">
      <mat-label>Plot Radius</mat-label>
      <input matInput type="number" min="1" formControlName="plotRadius" required />
    </mat-form-field>

    <mat-slide-toggle class="slide-toggle" formControlName="isColored" required>Colored Plot</mat-slide-toggle>
  </div>
</form>
