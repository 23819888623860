import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenericLinkInviteComponent } from '@app/user/generic-link-invite/generic-link-invite.component';

const inviteRoutes: Routes = [{ path: ':token', component: GenericLinkInviteComponent }];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(inviteRoutes)],
  exports: [RouterModule],
})
export class GenericLinkInviteRoutingModule {}
