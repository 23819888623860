<div *ngIf="tests; else noTests">
  <span *ngFor="let test of tests | keyvalue" class="column">
    <app-report-subscale-detail
      [subscales]="test.value"
      [psyTestTitle]="test.key"
      [isAdmin]="isAdmin"
      [isOA]="isOA"
      [isTgm]="isTgm"
      [allowRawScoresAccess]="allowRawScoresAccess"
    ></app-report-subscale-detail>
  </span>
</div>
<ng-template #noTests>No subscale results</ng-template>
