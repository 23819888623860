import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Section } from '../models/section.model';

type SectionParams = {
  title: string;
  type: string;
  subsection: any;
};

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  private autoSaveSectionListener = new Subject<Section>();

  constructor(private http: HttpClient) {}

  create(params: SectionParams) {
    const url = `${BACKEND_URL}/section`;
    return this.http.post<any>(url, params).pipe(take(1));
  }

  findById(sectionId: number, reportId?: number, chapterId?: number) {
    const url = `${BACKEND_URL}/section/${sectionId}`;
    return this.http
      .get<Section>(url, {
        params: {
          reportId: String(reportId),
          chapterId: String(chapterId),
        },
      })
      .pipe(take(1));
  }

  update(id: number, params: SectionParams) {
    const url = `${BACKEND_URL}/section/${id}`;
    return this.http.patch<any>(url, params).pipe(take(1));
  }

  move(id: number, params: any) {
    const url = `${BACKEND_URL}/section/${id}/move`;
    return this.http.patch<any>(url, params).pipe(take(1));
  }

  moveBulletPointSentences(bulletPointId: number, params: any) {
    const url = `${BACKEND_URL}/section/${bulletPointId}/move-bulletpoint-sentences`;
    return this.http.patch<any>(url, params).pipe(take(1));
  }

  setAutoSaveSectionListener(value: Section) {
    this.autoSaveSectionListener.next(value);
  }

  getAutoSaveSectionListener() {
    return this.autoSaveSectionListener.asObservable();
  }
}
