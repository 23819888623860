import { Component, Input, OnInit } from '@angular/core';
import { ChartSection } from '@app/core/models/chart-section.model';
import { ReportService } from '@app/core/services/report.service';

@Component({
  selector: 'app-chart-section',
  templateUrl: './chart-section.component.html',
  styleUrls: ['./chart-section.component.scss'],
})
export class ChartSectionComponent implements OnInit {
  @Input() model: ChartSection;
  @Input() testGroupId: number;
  @Input() userId: number;
  @Input() userName?: string;

  constructor(private reportService: ReportService) {}

  ngOnInit(): void {
    this.formatSentences();
  }

  formatSentences() {
    this.model.agilitySentence.text = this.reportService.removeSelectionBoundary(this.model.agilitySentence.text);
    this.model.leadSentence.text = this.reportService.removeSelectionBoundary(this.model.leadSentence.text);
    this.model.leadSentence.text = this.reportService.removeFirstLastPTags(this.model.leadSentence.text);
    this.model.agilitySentence.text = this.reportService.removeFirstLastPTags(this.model.agilitySentence.text);
    Object.keys(this.model.sentences).forEach(
      (key) =>
        (this.model.sentences[key].text = this.reportService.removeSelectionBoundary(this.model.sentences[key].text)),
    );
  }
}
