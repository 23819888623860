/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyTest } from '../models/PsyTest';
import type { Question } from '../models/Question';
import type { SupertestTest } from '../models/SupertestTest';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all completed  psy tests by testGroupId and userInfoId
     * Retrieve all completed psy tests by testGroupId and userInfoId
     * @param testGroupId Id of the Test Group
     * @param userInfoId Id of the User Info
     * @returns PsyTest A list of completed psy tests
     * @throws ApiError
     */
    public getTestGroupUserCompletedPsyTest(
        testGroupId: number,
        userInfoId: number,
    ): Observable<PsyTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/user/{userInfoId}/completed-psy-test/',
            path: {
                'testGroupId': testGroupId,
                'userInfoId': userInfoId,
            },
            errors: {
                404: `PsyTest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list of psy tests by testGroupId
     * Retrieve a list of psy tests by testGroupId
     * @param testGroupId Id of the Test Group
     * @returns PsyTest A list of psy tests
     * @throws ApiError
     */
    public getTestGroupPsyTest(
        testGroupId: number,
    ): Observable<PsyTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/psy-test',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `PsyTest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all tests
     * @returns PsyTest A list of tests
     * @throws ApiError
     */
    public getTest(): Observable<Array<PsyTest>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a psytest
     * Create a psytest
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTest(
        requestBody?: {
            /**
             * Title of the psytest
             */
            title: string;
            /**
             * Description of the psytest
             */
            description?: string;
            /**
             * Version of the psytest
             */
            version: number;
            /**
             * Active of the psytest
             */
            active: number;
            /**
             * Warning Instructions of the psytest
             */
            startPageInstructions?: string;
            /**
             * Warning Instructions of the psytest
             */
            warningInstructions?: string;
            /**
             * Paging of the psytest
             */
            paging: number;
            /**
             * Answer Required of the psytest
             */
            answerRequired?: number;
            /**
             * Test time of the psytest
             */
            testTime?: number;
            /**
             * Randomize Order of the psytest
             */
            randomizeOrder: number;
            /**
             * Scoring Required of the psytest
             */
            scoringRequired: number;
            /**
             * Deleted of the psytest
             */
            deleted?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Update a psytest
     * Update a psytest
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchTest(
        requestBody?: {
            /**
             * Title of the psytest
             */
            title: string;
            /**
             * Description of the psytest
             */
            description?: string;
            /**
             * Version of the psytest
             */
            version: number;
            /**
             * Active of the psytest
             */
            active: number;
            /**
             * Warning Instructions of the psytest
             */
            startPageInstructions?: string;
            /**
             * Warning Instructions of the psytest
             */
            warningInstructions?: string;
            /**
             * Paging of the psytest
             */
            paging: number;
            /**
             * Answer Required of the psytest
             */
            answerRequired?: number;
            /**
             * Test time of the psytest
             */
            testTime?: number;
            /**
             * Randomize Order of the psytest
             */
            randomizeOrder: number;
            /**
             * Scoring Required of the psytest
             */
            scoringRequired: number;
            /**
             * Deleted of the psytest
             */
            deleted?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a psytest by id
     * Retrieve a psytest by id
     * @param id Id of the psytest
     * @returns PsyTest A psytest
     * @throws ApiError
     */
    public getTest1(
        id: number,
    ): Observable<PsyTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `PsyTest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a psytest
     * Delete a psytest
     * @param id Id of the psytest
     * @returns any Psytest deleted successfully
     * @throws ApiError
     */
    public deleteTest(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Start a psytest
     * Start a psytest
     * @param requestBody Callback payload
     * @throws ApiError
     */
    public postTestStart(
        requestBody?: {
            /**
             * Id of the psytest
             */
            id: number;
            /**
             * Id of the Test Group
             */
            testGroupId: string;
            /**
             * Id of the User Info
             */
            userInfoId: number;
        },
    ): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Complete a psytest
     * Complete a psytest
     * @param requestBody Callback payload
     * @throws ApiError
     */
    public postTestComplete(
        requestBody?: {
            /**
             * Id of the psytest
             */
            id: number;
            /**
             * Id of the Test Group
             */
            testGroupId: string;
            /**
             * Id of the User Info
             */
            userInfoId: number;
        },
    ): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/complete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve a psytest by id
     * Retrieve a psytest by id
     * @param id Id of the psytest
     * @param testGroupId Id of the Test Group
     * @param userInfoId Id of the User Info
     * @returns PsyTest A psytest
     * @throws ApiError
     */
    public getTestTestGroupUserInfo(
        id: number,
        testGroupId: number,
        userInfoId: number,
    ): Observable<PsyTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/{id}/test-group/{testGroupId}/user-info/{userInfoId}',
            path: {
                'id': id,
                'testGroupId': testGroupId,
                'userInfoId': userInfoId,
            },
            errors: {
                404: `PsyTest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a psytest by company id
     * Retrieve a psytest by company id
     * @param id Id of the psytest
     * @returns PsyTest A psytest
     * @throws ApiError
     */
    public getTestPsyTestCompany(
        id: number,
    ): Observable<PsyTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/psy-test/{id}/company',
            path: {
                'id': id,
            },
            errors: {
                404: `PsyTest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all question by id
     * Retrieve all question by id
     * @param id Id of the psytest
     * @returns Question A list of questions
     * @throws ApiError
     */
    public getTestPsyTestQuestion(
        id: number,
    ): Observable<Question> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/psy-test/{id}/question',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of questions not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all SupertestTest by id
     * Retrieve all SupertestTest by id
     * @param id Id of the psytest
     * @returns SupertestTest A list of supertestTests
     * @throws ApiError
     */
    public getTestSupertestTest(
        id: number,
    ): Observable<SupertestTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/{id}/supertest-test',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of supertestTests not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Complete a supertesttest
     * Create a supertesttest
     * @param requestBody Callback payload
     * @throws ApiError
     */
    public postSupertestTest(
        requestBody?: {
            /**
             * Id of the psytest
             */
            id: number;
            /**
             * Id of the supertest
             */
            supertestId?: number;
            /**
             * Id of the test
             */
            psyTestId?: number;
        },
    ): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{id}/supertest-test',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
