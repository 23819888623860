<div class="container">
  <div class="page-list-header">
    <h1>Area Indices</h1>

    <button mat-raised-button color="primary" class="btn-success new-button" (click)="onCreateNewArea()">
      <mat-icon aria-hidden="false">add</mat-icon>
      Create new Area
    </button>
  </div>

  <app-psb-list [items]="areaPsbItems" (view)="onDashboard($event)" (delete)="onDelete($event)"></app-psb-list>
</div>
