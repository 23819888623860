/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupChartService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Add chart to test group
     * Add chart to test group
     * @param testGroupId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupChart(
        testGroupId: number,
        requestBody?: {
            /**
             * Id of the chart
             */
            chartId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{id}/chart',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a chart
     * Remove a chart from test group
     * @param id Id of the test group
     * @param chartId Id of the chart
     * @returns any Chart deleted from test group successfully
     * @throws ApiError
     */
    public deleteTestGroupChart(
        id: number,
        chartId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/{id}/chart/{chartId}',
            path: {
                'id': id,
                'chartId': chartId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
