import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-total-box',
  templateUrl: './total-box.component.html',
  styleUrls: ['./total-box.component.scss'],
})
export class TotalBoxComponent {
  @Input() total: number;
  @Input() description: string;
  @Input() color: string;

  constructor() {}
}
