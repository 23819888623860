import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Question } from '@app/core/models/question.model';
import { Subscription, forkJoin } from 'rxjs';

// components
import { RoleMandatePosition } from '@app/core/models/role-mandate-position.model';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { SendEmailService } from '@app/core/services/send-email.service';
import { CompanyService } from '@app/core/services/company.service';
import { InviteService } from '@app/core/services/invite.service';
import { PageService } from '@app/core/services/page.service';

// models
import { User } from '@app/core/models/user.model';
import { Invite } from '@app/core/models/invite.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { Company } from '@app/core/models/company.model';

@Component({
  selector: 'app-role-mandate-review',
  templateUrl: './role-mandate-review.component.html',
  styleUrls: ['./role-mandate-review.component.scss'],
})
export class RoleMandateReviewComponent implements OnInit {
  roleMandateId: number;
  questions: Question[];
  roleMandate: RoleMandate;
  decisionTables: DecisionTable[];
  usersSelected: User[];
  invites: Invite[] = [];
  company: Company;
  roleMandatePositions: RoleMandatePosition[] = [];
  private initialSubscriptions: Subscription;
  users: User[];
  pages: any[];

  displayedInviteColumns: string[] = ['firstName', 'lastName', 'email'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sendEmailService: SendEmailService,
    private inviteSerivce: InviteService,
    private rMService: RoleMandateService,
    private companyService: CompanyService,
    public pageService: PageService,
  ) {}

  ngOnInit(): void {
    this.roleMandateId = Number(this.route.snapshot.params.id);

    this.initialSubscriptions = forkJoin([
      this.rMService.getById(this.roleMandateId),
      this.inviteSerivce.getAllInviteByRoleMandateId(this.roleMandateId),
    ]).subscribe((results) => {
      this.roleMandate = results[0];
      this.companyService.getById(this.roleMandate.companyId).subscribe({
        next: (company) => {
          this.company = company;
        },
      });
      this.invites = results[1];
    });

    this.loadUsers(this.roleMandateId);
  }

  loadUsers(roleMandateId: number) {
    this.rMService.getAllUsers(roleMandateId).subscribe((result) => {
      this.users = [
        ...new Map(
          result
            .filter((user) => user.firstName && user.lastName)
            .map((user) => [user.firstName + user.lastName, user]),
        ).values(),
      ];
    });
  }

  onDeleteQuestion(questionId: number) {
    this.questions = this.questions.filter((question) => question.id !== questionId);
  }

  complete() {
    this.rMService.updateActive(this.roleMandate.id).subscribe(() => {
      this.router.navigate(['/role-mandate']);
    });
  }

  onChangePrivacy(acl) {
    this.roleMandate.acl = acl;

    this.rMService.updateAcl(this.roleMandate.id, this.roleMandate.acl).subscribe((result) => {
      return this.roleMandate.acl;
    });
  }

  getRoleMandatePages() {
    this.pages = this.pageService.getRoleMandatePages(this.roleMandateId);
  }
}
