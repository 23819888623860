import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { SectionDetailsComponent } from './section-details/section-details.component';

const routes: Route[] = [{ path: ':id', component: SectionDetailsComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SectionRoutingModule {}
