<h1>Create a Supertest Test</h1>
<form [formGroup]="superTestTestDialogForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Psy Test</mat-label>
    <input
      matInput
      required
      type="text"
      (ngModelChange)="this.psyTestFieldUpdate.next($event)"
      formControlName="psyTest"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayPsyTest">
      <mat-option
        *ngFor="let psyTest of psyTests | filter: psyTestField"
        [value]="psyTest"
        (onSelectionChange)="onChange(psyTest)"
      >
        {{ psyTest.title }}
        <p *ngIf="psyTest.version" class="small-text">Version: {{ psyTest.version }}</p>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="superTestTestDialogForm.get('psyTest').errors">This field is required.</mat-error>
  </mat-form-field>

  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!psyTestId">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
