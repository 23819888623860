import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

// component
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

// models
import { Answer } from '@app/core/models/answer.model';
import { Subscale } from '@app/core/models/subscale.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-cardsort',
  templateUrl: './cardsort.component.html',
  styleUrls: ['./cardsort.component.scss'],
})
export class CardsortComponent {
  @Input() questionId?: number;
  @Input() allowSubscale?: boolean;
  @Input() allowWeight?: boolean;
  @Input() editing?: boolean;
  @Input() answerSubscales?: Subscale[];
  @Input() answers: Answer[];
  @Input() mode: string;
  @Output() add = new EventEmitter<Answer>();
  @Output() edit = new EventEmitter<Answer>();
  @Output() delete = new EventEmitter<Answer>();

  cdkEntered = false;
  position = 0;
  newAnswer = false;

  filteredSubscales: Observable<Subscale[]>;

  constructor(public dialog: MatDialog) {}

  selectSubscale() {}

  drop(event: CdkDragDrop<Answer[]>) {
    this.cdkEntered = false;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      event.previousContainer.data.forEach((x, index) => {
        x.order = index;
      });
    }

    event.container.data.forEach((x, index) => {
      x.order = index;
    });
  }

  onAddAnswer() {
    this.newAnswer = true;
  }

  onEditAnswer(answer: Answer) {
    answer.edit = true;
  }

  onSaveAnswer(answer: Answer) {
    this.newAnswer = false;
    answer.edit = false;
    this.add.emit(answer);
  }

  onSaveEditAnswer(answer: Answer, index) {
    answer.edit = false;
    this.answers[index] = answer;
    this.edit.emit(answer);
  }

  onDeleteAnswer(answer: Answer, index) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);
    deleteDialog.componentInstance.message = `Deleting this answer may impact on users that already started the Role Navigator.
      Are you sure you want to delete?`;
    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.answers = this.answers.splice(index, 1);
        this.delete.emit(answer);
      }
    });
  }
}
