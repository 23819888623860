import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EVENT_SUCCESS } from '@app/core/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlossomChartService } from '../../../../../../../generated/v2';
import { PsyChart } from '@app/core/models/psy-chart.model';

@Component({
  selector: 'app-blossom-chart-setup-form',
  templateUrl: './blossom-chart-setup-form.component.html',
  styleUrls: ['./blossom-chart-setup-form.component.scss'],
})
export class BlossomChartSetupFormComponent implements OnInit {
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    header: new FormControl('', [Validators.required]),
    hasLogo: new FormControl(false, [Validators.required]),
    hasSelfEvaluationKey: new FormControl(false, [Validators.required]),
    isTable: new FormControl(false, [Validators.required]),
  });

  constructor(
    private dialogRef: MatDialogRef<BlossomChartSetupFormComponent>,
    private blossomChartService: BlossomChartService,
    @Inject(MAT_DIALOG_DATA) private data: { chart: PsyChart },
  ) {}

  ngOnInit(): void {
    this.blossomChartService
      .getChartBlossomChart(this.data.chart.id)
      .subscribe(({ name, header, hasSelfEvaluationKey, hasLogo, isTable }) => {
        this.form.patchValue({ name, header, hasSelfEvaluationKey, hasLogo, isTable });
      });
  }

  onFormSubmit() {
    const { name, header, hasLogo, hasSelfEvaluationKey, isTable } = this.form.value;
    this.blossomChartService
      .postChartBlossomChart(this.data.chart.id, { name, header, hasLogo, hasSelfEvaluationKey, isTable })
      .subscribe((updatedChart) => {
        this.dialogRef.close({ event: EVENT_SUCCESS, updatedChart });
      });
  }
}
