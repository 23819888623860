import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { StateModalComponent } from '../state-modal/state-modal.component';

// services
import { StateService } from '@app/core/services/state.service';

// models
import { State } from '@app/core/models/state.model';

@Component({
  selector: 'app-state-detail',
  templateUrl: './state-detail.component.html',
  styleUrls: ['./state-detail.component.scss'],
})
export class StateDetailComponent implements OnInit {
  stateId: number;
  state: State;

  constructor(private route: ActivatedRoute, private stateService: StateService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.stateId = Number(this.route.snapshot.params.id);
    this.getState();
  }

  goBack() {
    window.history.back();
  }

  onEdit() {
    const editDialog = this.dialog.open(StateModalComponent, {
      data: { isEdit: true, stateId: this.stateId, state: this.state },
    });

    editDialog.afterClosed().subscribe((result) => {
      if (result.event === 'edit') {
        this.stateService.updateById(this.stateId, result.data).subscribe((res) => {
          if (res) {
            this.getState();
          }
        });
      }
    });
  }

  getState() {
    this.stateService.getById(this.stateId).subscribe((res) => {
      this.state = res;
    });
  }
}
