import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SystemMaintenanceComponent } from './system-maintenance/system-maintenance.component';

const settingRoutes: Routes = [{ path: '', component: SystemMaintenanceComponent }];

@NgModule({
  imports: [RouterModule.forChild(settingRoutes)],
  exports: [RouterModule],
})
export class SettingRoutingModule {}
