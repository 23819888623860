/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { TestGroupScorecardTrigger } from '../models/TestGroupScorecardTrigger';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class TestGroupScorecardTriggerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Update showScoreCard to test group
     * Update showScoreCard to test group
     * @param testGroupId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupScorecardTrigger(
        testGroupId: number,
        requestBody?: {
            /**
             * Id of the test group
             */
            chartId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{id}/scorecard-trigger',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve the scorecard trigger by id
     * Retrieve the scorecard trigger by id
     * @param id Id of the test group
     * @returns TestGroupScorecardTrigger A scorecard trigger by test group id
     * @throws ApiError
     */
    public getTestGroupScorecardTrigger(
        id: number,
    ): Observable<TestGroupScorecardTrigger> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/scorecard-trigger',
            path: {
                'id': id,
            },
            errors: {
                404: `a scorecard trigger not found`,
                500: `Internal error`,
            },
        });
    }

}
