import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SystemSettings } from '@app/core/models/system-settings.model';
import { environment } from '../../../environments/environment';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class SystemSettingsService {
  constructor(private http: HttpClient) {}

  toggleMaintenanceMode(toggle: boolean) {
    return this.http.patch(BACKEND_URL + '/system-settings/toggle-maintenance-mode', { toggle: toggle });
  }

  getMaintenanceMode() {
    return this.http.get<SystemSettings>(BACKEND_URL + '/system-settings');
  }
}
