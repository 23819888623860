import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

type AreaFormValues = {
  num: number;
  he: string;
  she: string;
  they: string;
};

@Component({
  selector: 'app-area-form-dialog',
  templateUrl: './area-form-dialog.component.html',
  styleUrls: ['./area-form-dialog.component.scss'],
})
export class AreaFormDialogComponent implements OnInit {
  form = this.fb.group({
    num: [null, [Validators.required, Validators.min(0)]],
    he: [null],
    she: [null],
    they: [null],
  });

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AreaFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { values: AreaFormValues } | null,
  ) {}

  ngOnInit(): void {
    if (!this.data) {
      return;
    }
    this.form.controls['num'].disable();
    const { values } = this.data;
    this.form.patchValue({
      num: values.num || 0,
      he: values.he || null,
      she: values.she || null,
      they: values.they || null,
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const { value } = this.form;
      this.dialogRef.close({
        event: EVENT_SUCCESS,
        area: {
          num: value.num ? value.num : this.data.values.num,
          he: value.he,
          she: value.she,
          they: value.they,
        },
      });
    }
  }

  onChange() {
    if (!this.form.get('he').value && !this.form.get('she').value && !this.form.get('they').value) {
      this.form.controls['he'].setValidators([Validators.required]);
      this.form.controls['he'].updateValueAndValidity();
      this.form.controls['she'].setValidators([Validators.required]);
      this.form.controls['she'].updateValueAndValidity();
      this.form.controls['they'].setValidators([Validators.required]);
      this.form.controls['they'].updateValueAndValidity();
    } else {
      this.form.controls['he'].setValidators(null);
      this.form.controls['he'].updateValueAndValidity();
      this.form.controls['she'].setValidators(null);
      this.form.controls['she'].updateValueAndValidity();
      this.form.controls['they'].setValidators(null);
      this.form.controls['they'].updateValueAndValidity();
    }
  }
}
