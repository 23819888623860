<div class="container">
  <app-psb-page-navigator [pages]="pages"></app-psb-page-navigator>

  <h1>{{ roleMandate?.title }}</h1>

  <mat-form-field>
    <mat-label>Company</mat-label>
    <input matInput type="text" name="company" [ngModel]="company?.name" #ctrl="ngModel" readonly="true" />
  </mat-form-field>

  <mat-card>
    <mat-card-header>
      <h2>Users</h2>
    </mat-card-header>
    <mat-card-content>
      <mat-selection-list #usersSelection class="row" color="primary">
        <mat-list-option *ngFor="let user of users" [value]="user" [selected]="true">
          {{ user.firstName }} {{ user.lastName }}
        </mat-list-option>
      </mat-selection-list>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <h2>New Users Invited</h2>
    </mat-card-header>
    <mat-card-content>
      <mat-table *ngIf="invites.length" [dataSource]="invites" class="mat-elevation-z3">
        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.firstName }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.lastName }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedInviteColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedInviteColumns"></mat-row>
      </mat-table>
    </mat-card-content>
  </mat-card>

  <app-global-privacy
    *ngIf="roleMandate"
    title="Role Navigator Privacy"
    description="Select the level of privacy to the entire Role Navigator"
    [acl]="roleMandate.acl"
    (update)="onChangePrivacy($event)"
  >
  </app-global-privacy>

  <button mat-raised-button class="btn-success btn-center btn-300" (click)="complete()">Complete and Activate</button>
</div>
