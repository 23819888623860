<mat-card *ngIf="!editing">
  <mat-card-header>
    <mat-card-title>{{ question.text | stripeHtml }}</mat-card-title>
    <div>
      <button mat-button class="btn-error" *ngIf="question.id" (click)="onEditQuestion()">Edit</button>
      <button mat-button class="btn-error btn-delete" (click)="onDelete(question.id)">Delete</button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-chip-list aria-label="Fish selection">
      <mat-chip color="primary" *ngIf="question.questionGroup" selected>{{ question.questionGroup.title }} </mat-chip>
      <mat-chip color="accent" *ngIf="questionType" selected> {{ questionType }}</mat-chip>
      <mat-chip color="primary" *ngIf="question.networkType" selected>{{ question.networkType }} </mat-chip>
    </mat-chip-list>
    <p>Weight: {{ question.weight }}</p>

    <div [ngSwitch]="question.questionType">
      <mat-slide-toggle
        *ngSwitchCase="'CARDSORT'"
        class="toggle-margin"
        color="primary"
        [checked]="question.showDiscarded ? true : false"
        [disabled]="true"
      >
        Show list of cards discarded
      </mat-slide-toggle>

      <p *ngSwitchCase="'LIST_TEXT'">Question Related: {{ relatedQuestionText }}</p>

      <app-cardsort *ngSwitchCase="'CARDSORT'" [answers]="question.answers"></app-cardsort>
    </div>

    <p *ngIf="question.acl" class="privacy-text"><b>Privacy: </b> {{ getQuestionPrivacyByACLId(question.acl) }}</p>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="editing">
  <form [formGroup]="editQuestionForm">
    <mat-form-field>
      <mat-label>Text</mat-label>
      <input matInput type="text" formControlName="text" />
      <mat-error *ngIf="editQuestionForm.get('text').errors">Please, enter a valid text.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Weight</mat-label>
      <input matInput type="number" formControlName="weight" />
      <mat-error *ngIf="editQuestionForm.get('weight').errors">Please, enter a valid weight.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Network Type</mat-label>
      <p></p>
      <mat-select formControlName="networkType">
        <mat-option value="EXPERT">Expert</mat-option>
        <mat-option value="REFERENT">Referent</mat-option>
      </mat-select>
      <mat-error *ngIf="editQuestionForm.get('networkType').errors">Please, enter a valid weight.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Question Type</mat-label>
      <mat-select formControlName="questionType">
        <mat-option value="TEXT_AREA">Text Area</mat-option>
        <mat-option value="RICH_TEXT">Rich Text</mat-option>
        <mat-option value="LIST_TEXT">List Text</mat-option>
        <mat-option value="USER_CONNECTION">User Connection</mat-option>
        <mat-option value="CARDSORT">Cardsort</mat-option>
      </mat-select>
      <mat-error *ngIf="editQuestionForm.get('questionType').errors">Please, select a valid question type.</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="questionGroups">
      <mat-label>Question Group</mat-label>
      <mat-select formControlName="questionGroupId" [compareWith]="compareFn">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let questionGroup of questionGroups" [value]="questionGroup.id">
          {{ questionGroup.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="showAcl" class="privacy">
      <mat-label>Question Privacy</mat-label>
      <section *ngFor="let privacyMsg of privacyMsgs">
        <mat-checkbox
          color="primary"
          [checked]="question.acl === privacyMsg.id"
          (change)="onChangePrivacy($event, privacyMsg)"
        >
          {{ privacyMsg.msg }}</mat-checkbox
        >
      </section>
    </div>

    <mat-slide-toggle
      *ngIf="question.questionType == 'CARDSORT'"
      class="toggle-margin"
      color="primary"
      formControlName="showDiscarded"
    >
      Show list of cards discarded
    </mat-slide-toggle>

    <mat-form-field *ngIf="question.questionType == 'LIST_TEXT'">
      <mat-label>Cardsort Related</mat-label>
      <mat-select formControlName="relatedQuestionId">
        <mat-option *ngFor="let question of questions" [value]="question.id">
          {{ question.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-cardsort
      *ngIf="question.questionType == 'CARDSORT'"
      [editing]="true"
      [answers]="question.answers"
      [allowSubscale]="false"
      [allowWeight]="false"
      mode="insert"
      [questionId]="question.id"
      (add)="onAddAnswer($event)"
      (edit)="onEditAnswer($event)"
      (delete)="onDeleteAnswer($event)"
    >
    </app-cardsort>

    <div class="row center">
      <button mat-raised-button type="submit" (click)="save()">Save</button>
      <button mat-raised-button (click)="cancel()">Cancel</button>
    </div>
  </form>
</mat-card>
