import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';

// components
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { InviteService } from '@app/core/services/invite.service';

// models
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { UserInvite } from '@app/core/models/user-invite';
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';
import { EmailSet } from '@app/core/models/email-set.model';
import { environment } from '../../../../../environments/environment';

// constant
import { EMAIL_TYPES } from '@app/shared/constants/emailTypes';

@Component({
  selector: 'app-role-mandate-email',
  templateUrl: './role-mandate-email.component.html',
  styleUrls: ['./role-mandate-email.component.scss'],
})
export class RoleMandateEmailComponent implements OnInit, AfterViewInit {
  @Input() roleMandateId?: number;
  displayedColumns: string[] = ['select', 'firstName', 'lastName', 'email', 'status', 'isNew'];
  displayedLogColumns: string[] = ['toEmail', 'sentAt', 'emailType', 'actions'];
  userInvites = new MatTableDataSource<UserInvite>();

  selection = new SelectionModel<UserInvite>(true, []);
  emailTypes = EMAIL_TYPES;
  roleMandate: RoleMandate;
  connectionIndicator: ConnectionIndicator;

  panelOpenState = false;

  emailSet: EmailSet;
  sources: String[] = [];

  filter = new FormControl('');
  selectedUserList: any[] = [];

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('sortUsers') sortUsers: MatSort;
  initialSubscriptions: Subscription;
  filteredEmailTypes: any[] = [];
  public options: { id: number; label: string; checked: boolean }[] = [
    { id: 0, label: 'All invited users', checked: false },
    { id: 1, label: 'All registered users', checked: false },
    { id: 2, label: 'All users who started survey', checked: false },
  ];

  public checkIdx = -1;
  showFilterOptions: Boolean = false;
  public currentDisabledType: string = '';

  constructor(
    private rMService: RoleMandateService,
    private inviteService: InviteService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.showFilterOptions = false;
    this.loadUsers();
  }

  loadUsers() {
    this.initialSubscriptions = forkJoin([
      this.rMService.getAllUsers(this.roleMandateId),
      this.inviteService.getAllInviteByRoleMandateId(this.roleMandateId),
    ]).subscribe((results) => {
      const userInvites: UserInvite[] = [];
      results[0].map((user) => {
        if (user.firstName && user.lastName) {
          const roleMandateInvite: UserInvite = {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            status: user.status ? user.status : 'REGISTERED',
            isNew: false,
            token: null,
          };
          userInvites.push(roleMandateInvite);
        }
      });
      results[1].map((invite) => {
        const roleMandateInvite: UserInvite = {
          firstName: invite.firstName,
          lastName: invite.lastName,
          email: invite.email,
          isNew: true,
          status: 'INVITED',
          token: invite.token,
        };
        userInvites.push(roleMandateInvite);
      });
      this.userInvites.data = userInvites;
      if (this.userInvites.paginator) {
        this.userInvites.paginator.firstPage();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.userInvites.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.userInvites.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? `${this.selection.clear()} ${(this.selectedUserList.length = 0)}`
      : this.userInvites.data.forEach((row) => {
          this.selection.select(row);
          this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row) });
        });
  }

  checkboxLabel(row?: UserInvite): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email}`;
  }

  showLink(element) {
    const contentDialog = this.dialog.open(InfoDialogComponent);
    contentDialog.componentInstance.title = 'Link';
    contentDialog.componentInstance.message = `${environment.url}/invite/${element.token}`;
    contentDialog.componentInstance.isHtml = true;
    contentDialog.componentInstance.copy = true;
  }

  ngAfterViewInit() {
    this.userInvites.paginator = this.paginator.toArray()[0];
    this.userInvites.sort = this.sortUsers;
    this.cd.detectChanges();
  }

  remove(user) {
    if (user) {
      let removeUserData = this.userInvites.data.filter((e) => e.id == user.id)[0];
      this.selection.deselect(removeUserData);
      this.selectedUserList = this.selectedUserList.filter((e) => e.id != removeUserData.id);
      this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
    }
  }

  formatChipSetLabel(row: UserInvite): string {
    if (row.firstName && row.lastName) {
      return `${row.firstName} ${row.lastName}`;
    } else if (row.firstName && (row.lastName == null || row.lastName == 'undefined')) {
      return row.firstName;
    } else {
      return row.email;
    }
  }

  onUserSelection(row: UserInvite, isChecked: boolean) {
    if (isChecked) {
      if (!this.selectedUserList.some((e) => e.id === row.id)) {
        this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row) });
        this.selection.toggle(row);
      }
      this.filter.reset();
    } else {
      if (this.selectedUserList.some((e) => e.id === row.id)) {
        this.selectedUserList = this.selectedUserList.filter((e) => e.id !== row.id);
        this.selection.deselect(row);
      }
    }
  }

  onSelection(i: number, id: number, checked: boolean) {
    this.showFilterOptions = !this.showFilterOptions;
    this.options.map((e) => {
      if (e.id == id && checked) {
        e.checked = checked;
      } else {
        e.checked = false;
      }
    });
    if (!checked) {
      this.currentDisabledType = '';
    }

    let userType = this.options[i];
    if (userType) {
      switch (userType.id) {
        case 0:
          this.setAllInviteUsers(userType);
          if (checked) {
            this.currentDisabledType = 'REGISTERED';
          }
          break;
        case 1:
          this.setAllRegisterUsers(userType);
          if (checked) {
            this.currentDisabledType = 'INVITED';
          }
          break;
        case 2:
          this.setAllSurveyUsers(userType);
          if (checked) {
            this.currentDisabledType = 'INVITED';
          }
          break;
        default:
          break;
      }
      this.isAllSelected();
    }

    this.filter.reset();
  }

  setAllInviteUsers(e: any) {
    if (e.id == 0 && e.checked) {
      this.checkIdx = 0;
      this.selectedUserList = [];
      this.userInvites.data.forEach((row) => {
        if (row.status == 'INVITED') {
          this.selection.select(row);
          this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row), status: row.status });
        } else if (row.status != 'INVITED') {
          this.selection.deselect(row);
        }
      });
    } else if (e.id == 0 && !e.checked) {
      this.userInvites.data.forEach((row) => {
        this.selection.deselect(row);
      });
      this.selectedUserList = [];
      this.checkIdx = -1;
    }
    this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
  }

  setAllRegisterUsers(e: any) {
    if (e.id == 1 && e.checked) {
      this.checkIdx = 1;
      this.selectedUserList = [];
      this.userInvites.data.forEach((row: any) => {
        if (row.status == 'REGISTERED') {
          this.selection.select(row);
          this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row), status: row.status });
        } else if (row.status != 'REGISTERED') {
          this.selection.deselect(row);
        }
      });
    } else if (e.id == 1 && !e.checked) {
      this.userInvites.data.forEach((row: any) => {
        this.selection.deselect(row);
      });
      this.selectedUserList = [];
      this.checkIdx = -1;
    }
    this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
  }

  setAllSurveyUsers(e: any) {
    if (e.id == 2 && e.checked) {
      this.selectedUserList = [];
      this.checkIdx = 2;
      this.userInvites.data.forEach((row: any) => {
        if (row.status == 'STARTED') {
          this.selection.select(row);
          this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row), status: row.status });
        } else if (row.status == 'INVITED' || row.status == 'REGISTERED') {
          this.selection.deselect(row);
        }
      });
    } else if (e.id == 2 && !e.checked) {
      this.userInvites.data.forEach((row: any) => {
        this.selection.deselect(row);
      });
      this.selectedUserList = [];
      this.checkIdx = -1;
    }
    this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
  }

  getAlreadyCheckedEmailType() {
    let invitedUsers = this.selectedUserList.some((e) => e.status === 'INVITED');
    let registeredUsers = this.selectedUserList.some((e) => e.status === 'REGISTERED' || e.status == 'STARTED');
    if (invitedUsers && !registeredUsers) {
      return this.emailTypes.filter((e) => e.id != 1);
    } else if (!invitedUsers && registeredUsers) {
      return this.emailTypes.filter((e) => e.id != 2 && e.id != 3);
    } else if ((invitedUsers && registeredUsers) || (!invitedUsers && !registeredUsers)) {
      return this.emailTypes;
    }
  }

  openPopup() {
    let isInvitedChecked: boolean = false;
    let isRegisteredChecked: boolean = false;
    let isStartedSurveyChecked: boolean = false;

    let isInvitedCheckedSelectedList = this.selectedUserList.filter((e) => e.status == 'INVITED').length;
    let isInvitedCheckedUserInviteList = this.userInvites.data.filter((e: any) => e.status == 'INVITED').length;

    if (
      isInvitedCheckedSelectedList != isInvitedCheckedUserInviteList ||
      (isInvitedCheckedSelectedList == 0 && isInvitedCheckedUserInviteList == 0)
    ) {
      isInvitedChecked = false;
    } else {
      isInvitedChecked = true;
    }

    let isRegisteredCheckedSelectedList = this.selectedUserList.filter((e) => e.status == 'REGISTERED').length;
    let isRegisteredCheckedUserInviteList = this.userInvites.data.filter((e: any) => e.status == 'REGISTERED').length;

    if (
      isRegisteredCheckedSelectedList != isRegisteredCheckedUserInviteList ||
      (isRegisteredCheckedSelectedList == 0 && isRegisteredCheckedUserInviteList == 0)
    ) {
      isRegisteredChecked = false;
    } else {
      isRegisteredChecked = true;
    }

    let isStartedSurveySelectedList = this.selectedUserList.filter((e) => e.status == 'STARTED').length;
    let isStartedSurveyUserInviteList = this.userInvites.data.filter((e: any) => e.status == 'STARTED').length;

    if (
      isStartedSurveySelectedList != isStartedSurveyUserInviteList ||
      (isStartedSurveySelectedList == 0 && isStartedSurveyUserInviteList == 0)
    ) {
      isStartedSurveyChecked = false;
    } else {
      isStartedSurveyChecked = true;
    }

    let bindCheckBoxValue = {
      invitedChecked: isInvitedChecked,
      registeredChecked: isRegisteredChecked,
      startedSurveyChecked: isStartedSurveyChecked,
    };

    if (isInvitedChecked) {
      this.checkIdx = 0;
    } else if (isRegisteredChecked) {
      this.checkIdx = 1;
    } else if (isStartedSurveyChecked) {
      this.checkIdx = 2;
    } else {
      this.checkIdx = -1;
    }
    this.bindCheckBoxSelection(bindCheckBoxValue);
    this.showFilterOptions = !this.showFilterOptions;
  }

  bindCheckBoxSelection(data: any) {
    if (data.invitedChecked) {
      this.options[0].checked = true;
    }
    if (data.registeredChecked) {
      this.options[1].checked = true;
    }
    if (data.startedSurveyChecked) {
      this.options[2].checked = true;
    }
  }
}
