<div [id]="question.id">
  <h3>{{ qGInstructions }}</h3>
  <h3 [innerHTML]="question.instructions"></h3>
  <label>
    <span>{{ question.questionNumber }}) </span>
    <app-question-text [text]="question.text"></app-question-text>
  </label>
  <br />
  <form class="form-text-list">
    <mat-form-field>
      <mat-label>Answer</mat-label>
      <input
        matInput
        [formControl]="answerTextAreaControl"
        [readonly]="!answering"
        (change)="savePsyOrSuperTextAreaAnswer($event)"
      />
    </mat-form-field>
  </form>
</div>
