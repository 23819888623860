import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';
import { DecisionTableColumn } from '@app/core/models/decision-table-column.model';
import { DecisionTableColumnType } from '@app/core/models/decision-table-column-type.model';

@Component({
  selector: 'app-new-column',
  templateUrl: './new-column.component.html',
  styleUrls: ['./new-column.component.scss'],
})
export class NewColumnComponent {
  @Input() decisionTable: DecisionTable;
  @Output() create = new EventEmitter<boolean>();

  newColumnForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    required: new FormControl(0, [Validators.required]),
  });
  decisionTableColumnTypes: DecisionTableColumnType[] = [
    { id: 1, name: 'Decision Area' },
    { id: 2, name: 'Users' },
    { id: 3, name: 'Text' },
    { id: 4, name: 'Number' },
  ];

  constructor(private decisionTableService: DecisionTableService) {}

  newColumn(formDirective: FormGroupDirective) {
    if (this.newColumnForm.valid) {
      const newDecisionTableColumn: DecisionTableColumn = {
        id: null,
        decisionTableId: this.decisionTable.id,
        type: this.newColumnForm.get('type').value,
        title: this.newColumnForm.get('title').value,
        required: this.newColumnForm.get('required').value,
      };
      this.decisionTableService.createColumn(newDecisionTableColumn).subscribe((res) => {
        newDecisionTableColumn.id = res.insertId;
        this.decisionTable.columns.push(newDecisionTableColumn);
        this.create.emit(true);
      });
    }
    return;
  }

  onCancel() {
    this.create.emit(false);
  }
}
