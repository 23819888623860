import { Component, OnInit, Input } from '@angular/core';
import { PsbItem } from '@app/core/models/psb-item.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

// components
import { PsyOptionsDialogComponent } from '@app/shared/components/psy-options-dialog/psy-options-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';

// services
import { TestService } from '@app/core/services/test.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { PsbOptionItem } from '@app/core/models/psb-option-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-client-assessment-list',
  templateUrl: './client-assessment-list.component.html',
  styleUrls: ['./client-assessment-list.component.scss'],
})
export class ClientAssessmentListComponent implements OnInit {
  assessmentList: PsbItem[] = [];
  assessmentListAssignedToCompany: PsbItem[] = [];
  @Input() companyId: number;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private testService: TestService,
    private companyService: CompanyService,
  ) {}

  ngOnInit(): void {
    this.loadAllPsyTest();
  }

  loadAllPsyTest() {
    forkJoin([
      this.companyService.findAllAssessmentAssignedToCompany(this.companyId),
      this.testService.getAllPsyTest(),
    ]).subscribe(([assignedTests, psyTests]) => {
      this.assessmentList = [];
      this.assessmentListAssignedToCompany = [];

      assignedTests.forEach((assignedTest) => {
        const item: PsbItem = {
          id: assignedTest.id,
          title: assignedTest.title,
          psyTestId: assignedTest.psyTestId,
          description: assignedTest.description,
          status: +assignedTest.active === 1 ? 'Active' : 'Inactive',
          extraColumns: [
            {
              key: 'Version',
              value: assignedTest.version,
            },
          ],
        };

        this.assessmentListAssignedToCompany.push(item);
      });

      psyTests
        .filter((test) => +test.active === 1)
        .forEach((psyTest) => {
          const item: PsbOptionItem = {
            id: psyTest.id,
            itemName: psyTest.title,
            version: psyTest.version,
          };

          let isTestAvailable = assignedTests.some((e) => e.psyTestId == item.id);
          if (!isTestAvailable) {
            this.assessmentList.push(item);
          }
        });
    });
  }

  createNewAssessment() {
    const addTestModal = this.dialog.open(PsyOptionsDialogComponent, {
      data: { items: this.assessmentList, title: 'Add Assessment to Company', formFieldName: 'Assessment' },
    });

    addTestModal.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.companyService.assignTestToCompanyById(this.companyId, result.data.item.id).subscribe(() => {
          this.loadAllPsyTest();
          this.showCompleted('Assessment is assigned to the company.');
        });
      }
    });
  }

  onView(companyPsyTestId: number) {
    const testId = this.assessmentListAssignedToCompany.filter(
      (assignedAssessment) => assignedAssessment.id === companyPsyTestId,
    )[0].psyTestId;
    return this.router.navigate([`/psy-test/${testId}/detail`]);
  }

  onDelete(companyPsyTestId: number) {
    if (companyPsyTestId) {
      const deleteDialog = this.dialog.open(DeleteDialogComponent);

      deleteDialog.afterClosed().subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.companyService.removeAssignedTestFromCompany(companyPsyTestId).subscribe((res) => {
            if (res) {
              this.loadAllPsyTest();
              this.snackBar.info('Assessment is removed from the company.');
            }
          });
        }
      });
    } else {
      const contentDialog = this.dialog.open(InfoDialogComponent);
      contentDialog.componentInstance.message = `We are unable to delete it because it is the default assessment.`;
      contentDialog.componentInstance.title = `Default assessment information`;
      contentDialog.componentInstance.isHtml = false;
      contentDialog.componentInstance.copy = false;
    }
  }

  showCompleted(message: string) {
    this.snackBar.info(message);
  }
}
