<mat-card style="margin: 1rem 0rem">
  <div class="row">
    <div></div>
    <div>
      <button mat-button class="btn-error actions" (click)="onDelete(decisionTableRelation)">Delete</button>
      <button mat-button class="btn-error actions" (click)="onEdit(decisionTableRelation)">Edit</button>
    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <mat-card class="column">
        <mat-card-header>Origin Table</mat-card-header>
        {{ decisionTableRelation.originDecisionTable.title }}
      </mat-card>
      <mat-card class="column">
        <mat-card-header>Destination Table</mat-card-header>
        {{ decisionTableRelation.destinationDecisionTable.title }}
      </mat-card>
    </div>

    <div class="row">
      <div class="column">
        <p>
          By allowing the tasks to be moved, when the user accept a task that they have been added as reponsable or
          collaborator, the same task is transfered to the target user table decision, and remove from the origin user
          table.
        </p>
        <mat-checkbox color="primary" disabled="true" [checked]="decisionTableRelation.accept"
          >Move accepted tasks
        </mat-checkbox>
      </div>
    </div>

    <h4>Columns</h4>
    <div *ngFor="let originColumn of decisionTableRelation.columns" class="row">
      <mat-card class="column">
        {{ originColumn.originTitle }}
      </mat-card>

      <mat-card class="column">
        {{ originColumn.destinationTitle }}
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
