import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// components
import { PsyOptionsDialogComponent } from '@app/shared/components/psy-options-dialog/psy-options-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { TestService } from '@app/core/services/test.service';

// models
import { PsbTableItem } from '@app/core/models/psb-table-item.model';
import { PsbOptionItem } from '@app/core/models/psb-option-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SubscaleService as GeneratedSubscaleService } from '../../../../../generated/v2';
import { SubscaleService as DeprecatedSubscaleService } from '@app/core/services/subcale.service';

@Component({
  selector: 'app-test-subscale-list',
  templateUrl: './test-subscale-list.component.html',
  styleUrls: ['./test-subscale-list.component.scss'],
})
export class TestSubscaleListComponent implements OnInit {
  @Input() testId: number;
  subscales: PsbOptionItem[] = [];
  allSubcaleFromTest: PsbOptionItem[] = [];
  displayedSubscalesColumns: string[] = ['title', 'actions'];

  constructor(
    private testService: TestService,
    private generatedSubscaleService: GeneratedSubscaleService,
    private deprecatedSubscaleService: DeprecatedSubscaleService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getAllSubscale();
    this.getAllSubscaleByTestId(this.testId);
  }

  getAllSubscale() {
    this.deprecatedSubscaleService.getAll().subscribe((subscales) => {
      subscales.map((subscale) => {
        const optionItem: PsbOptionItem = {
          id: subscale.id,
          itemName: subscale.title,
          cardsortSubscale: subscale.cardsortSubscale,
          description: subscale.description,
        };

        if (optionItem.cardsortSubscale === 0) {
          this.subscales.push(optionItem);
        }
      });
    });
  }

  getAllSubscaleByTestId(testId: number) {
    this.allSubcaleFromTest = [];
    this.generatedSubscaleService.getTestAllSubscale(testId).subscribe((subscales) => {
      subscales.map((subscale) => {
        const item: PsbTableItem = {
          id: subscale.id,
          title: subscale.title,
        };

        let cardsortSubscale: any;
        if (subscale.cardsortSubscale.hasOwnProperty('data')) {
          cardsortSubscale = parseInt(subscale.cardsortSubscale['data'], 10) === 0 ? false : true;
        } else {
          cardsortSubscale = subscale.cardsortSubscale;
        }

        if (subscale.cardsortSubscale && cardsortSubscale) {
          item.showDelete = false;
          this.allSubcaleFromTest.push(item);
        } else {
          item.showDelete = true;
          this.allSubcaleFromTest.push(item);
        }
      });
    });
  }

  onDelete(subscaleId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.testService.deletePsyTestSubscale(this.testId, subscaleId).subscribe((res) => {
          this.getAllSubscaleByTestId(this.testId);
        });
      });
  }

  addNewSubscale() {
    const addNewTestUserModal = this.dialog.open(PsyOptionsDialogComponent, {
      data: { items: this.filterAddedSubscales(), title: 'Add a new Subscale', formFieldName: 'Subscale' },
    });

    addNewTestUserModal.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.testService.savePsyTestSubscale(this.testId, result.data.item.id, null).subscribe((res) => {
          this.getAllSubscaleByTestId(this.testId);
        });
      }
    });
  }

  filterAddedSubscales() {
    let nonAddedSubscales: PsbOptionItem[] = [];
    for (const subscale of this.subscales) {
      const addedSubscale = this.allSubcaleFromTest.filter((subscaleFromTest) => subscaleFromTest.id === subscale.id);
      if (addedSubscale.length === 0) {
        nonAddedSubscales.push(subscale);
      }
    }

    return nonAddedSubscales;
  }

  onSort($event: CdkDragDrop<any, any>) {
    const subscaleId = $event.item.data.id;
    const previousIndex = this.allSubcaleFromTest.findIndex((s) => s.id === subscaleId);
    const currentIndex = previousIndex + $event.currentIndex - $event.previousIndex;

    // Create array
    const nextValue = [...this.allSubcaleFromTest];

    // Rearrange items
    moveItemInArray(nextValue, previousIndex, currentIndex);

    // Apply changes to html
    this.allSubcaleFromTest = nextValue;

    // Save order to server
    this.generatedSubscaleService
      .postTestSortSubscales(
        this.testId,
        nextValue.map((n) => n.id),
      )
      .subscribe();
  }
}
