import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-system-maintenance',
  templateUrl: './system-maintenance.component.html',
  styleUrls: ['./system-maintenance.component.scss'],
})
export class SystemMaintenanceComponent implements OnInit {
  userIsAuthenticated = false;
  isAuthenticated$: Subscription;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this.userService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.userIsAuthenticated = isAuthenticated;
    });
  }

  onLogout() {
    this.userService.logout();
  }
}
