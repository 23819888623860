import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Invite } from '../models/invite.model';
import { InviteRoleMandate } from '../models/invite-role-mandate.model';
import { InviteConnectionIndicator } from '../models/invite-connection-indicator.model';
import { InviteTestGroup } from '../models/invite-test-group.model';
import { ExistedEmail } from '@app/core/models/existed-email.model';
import { BehaviorSubject, Observable } from 'rxjs';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  public multiSelectionForUsers = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  checkToken(token: string) {
    return this.http.get<Invite>(BACKEND_URL + '/invite/token/' + token);
  }

  getAllUserInvite() {
    return this.http.get<Invite[]>(BACKEND_URL + '/invite/all');
  }

  getInviteById(id: number) {
    return this.http.get<Invite>(BACKEND_URL + '/invite/' + id);
  }

  getAllInviteByCompanyId(companyId: number) {
    return this.http.get<Invite[]>(BACKEND_URL + '/invite/' + companyId);
  }

  getAllInviteByRoleMandateId(id: number) {
    return this.http.get<Invite[]>(BACKEND_URL + '/invite/role-mandate/' + id);
  }

  getAllInviteByConnectionIndicatorId(id: number) {
    return this.http.get<Invite[]>(BACKEND_URL + '/invite/connection-indicator/' + id);
  }

  getAllInviteByTestGroupId(id: number) {
    return this.http.get<Invite[]>(BACKEND_URL + '/invite/psy-test-group/' + id);
  }

  getEmailSummaryByTestGroupId(id: number) {
    return this.http.get<Invite[]>(BACKEND_URL + '/invite/psy-test-group/' + id + '/email-summary');
  }

  getEmailSummaryByRoleMandateId(id: number) {
    return this.http.get<Invite[]>(BACKEND_URL + '/invite/role-mandate/' + id + '/email-summary');
  }

  create(invite: Invite) {
    return this.http.post<{ message: string; inviteId: any }>(BACKEND_URL + '/invite', invite);
  }

  saveInviteRoleMandate(inviteRoleMandate: InviteRoleMandate): Observable<Invite> {
    return this.http.post<Invite>(BACKEND_URL + '/invite/role-mandate', inviteRoleMandate);
  }

  saveInviteConnectionIndicator(inviteConnectionIndicator: InviteConnectionIndicator) {
    return this.http.post<Invite>(BACKEND_URL + '/invite/connection-indicator', inviteConnectionIndicator);
  }

  saveInviteTestGroup(inviteTestGroup: InviteTestGroup): Observable<Invite> {
    return this.http.post<Invite>(`${BACKEND_URL}/invite/psy-test-group`, inviteTestGroup);
  }

  saveAllInviteRoleMandate(invites: Invite[], roleMandateId: number) {
    return this.http.post<Invite>(BACKEND_URL + '/invite/all-role-mandate', { invites, roleMandateId });
  }

  saveAllInviteConnectionIndicator(invites: Invite[], connectionIndicatorId: number) {
    return this.http.post<Invite>(BACKEND_URL + '/invite/all-connection-indicator', { invites, connectionIndicatorId });
  }

  saveAllInviteTestGroup(invites: Invite[], testGroupId: number): Observable<Invite> {
    return this.http.post<Invite>(`${BACKEND_URL}/invite/all-psy-test-group`, { invites, testGroupId });
  }

  deleteInvite(invite: Invite) {
    return this.http.delete(BACKEND_URL + '/invite/' + invite.id);
  }

  update(invite: Invite) {
    return this.http.put(BACKEND_URL + '/invite', invite);
  }

  updateEmail(id: number, updatedInvite: Invite) {
    return this.http.put(BACKEND_URL + '/invite/email/' + id, { updatedInvite });
  }

  checkExistedEmail(existedEmail: ExistedEmail) {
    return this.http.post<any>(BACKEND_URL + '/invite/existed-email', existedEmail);
  }

  blockSentInviteToSameAddressWithinOneHour(existedEmail: ExistedEmail) {
    return this.http.post<any>(BACKEND_URL + '/invite/block-sent-invite-to-same-address-within-one-hour', existedEmail);
  }

  resendInviteRoleMandate(resendInviteRoleMandate: InviteRoleMandate) {
    return this.http.post<Invite>(BACKEND_URL + '/invite/resend-role-mandate', resendInviteRoleMandate);
  }

  resendInviteConnectionIndicator(resendInviteConnectionIndicator: InviteConnectionIndicator) {
    return this.http.post<Invite>(BACKEND_URL + '/invite/resend-connection-indicator', resendInviteConnectionIndicator);
  }

  resendInviteTestGroup(resendInviteTestGroup: InviteTestGroup): Observable<Invite> {
    return this.http.post<Invite>(`${BACKEND_URL}/invite/resend-psy-test-group`, resendInviteTestGroup);
  }

  archive(id: number): Observable<any> {
    return this.http.delete(BACKEND_URL + '/invite/' + id + '/archive').pipe(take(1));
  }

  checkGenericLinkToken(token: string) {
    return this.http.get(`${BACKEND_URL}/invite/generic-link/validate/${token}`);
  }

  validateMultipleEmails(emails: any) {
    return this.http.post<any>(BACKEND_URL + '/invite/validate-multiple-emails', emails);
  }
}
