import { Component, OnInit } from '@angular/core';

// services
import { UserService } from '@app/core/services/user.service';

// models
import { UserNotification } from '@app/core/models/user-notification.model';

@Component({
  selector: 'app-user-notification-header',
  templateUrl: './user-notification-header.component.html',
  styleUrls: ['./user-notification-header.component.scss'],
})
export class UserNotificationHeaderComponent implements OnInit {
  userInfoId: number;
  newNotifications: UserNotification[] = [];
  hasNew: boolean;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userInfoId = this.userService.getUserData().id;
    this.userService.userNotification$.subscribe((hasNew) => {
      this.hasNew = hasNew;
    });

    this.userService.getUserNotificationsNewByUserInfoId(this.userInfoId).subscribe((notifications) => {
      this.newNotifications = notifications;
      this.hasNew = this.newNotifications.length > 0;
    });
  }
}
