import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DecisionTable } from '../models/decision-table.model';
import { DecisionTableAnswer } from '../models/decision-table-answer.model';
import { DecisionArea } from '../models/decision-area.model';
import { DecisionTableColumn } from '../models/decision-table-column.model';
import { DecisionTableRelation } from '../models/decision-table-relation.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class DecisionTableService {
  constructor(private http: HttpClient) {}

  getDecisionTables() {
    return this.http.get<DecisionTable[]>(BACKEND_URL + '/company');
  }

  getCollaborationTypes() {
    return this.http.get<DecisionTable[]>(BACKEND_URL + '/decision-table/collaboration-type');
  }

  saveDecisionTable(decisionTable: DecisionTable) {
    return this.http.post(BACKEND_URL + '/decision-table', decisionTable);
  }

  saveDecisionTableAnswer(connectionIndicatorId: number, decisionTable: DecisionTableAnswer) {
    return this.http.post(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/decision-table/answer',
      decisionTable,
    );
  }

  deleteUserConnectionByDecisionTableAnswerIdAndToUserInfoIdAndColumnName(
    decisionTableAnswerId: number,
    toUserInfoId: number,
    columnName: string,
  ) {
    return this.http.delete(
      BACKEND_URL +
        '/decision-table/answer/' +
        decisionTableAnswerId +
        '/user-connection/to-user/' +
        toUserInfoId +
        '/column-name/' +
        columnName,
    );
  }

  deleteDecisionTableAnswer(decisionTableAnswerId: number) {
    return this.http.delete(BACKEND_URL + '/decision-table/answer/' + decisionTableAnswerId);
  }

  delete(id: number) {
    return this.http.delete(BACKEND_URL + '/decision-table/' + id);
  }

  saveRoleMandateDecisionTableAnswer(roleMandateId: number, decisionTable: DecisionTableAnswer) {
    return this.http.post(BACKEND_URL + '/role-mandate/' + roleMandateId + '/decision-table/answer', decisionTable);
  }

  updateDecisionTableAnswer(decisionTableAnswer: DecisionTableAnswer) {
    return this.http.put(BACKEND_URL + '/decision-table/answer', decisionTableAnswer);
  }

  getDecisionAreas() {
    return this.http.get<DecisionArea[]>(BACKEND_URL + '/decision-area');
  }

  getDecisionAreasByCompanyId(companyId: number) {
    return this.http.get<DecisionArea[]>(BACKEND_URL + '/decision-area/company/' + companyId);
  }

  createDecisionArea(decisionArea: DecisionArea) {
    return this.http.post<{ id: number }>(BACKEND_URL + '/decision-area', decisionArea);
  }

  deleteDecisionArea(decisionAreaId: number) {
    return this.http.delete(BACKEND_URL + '/decision-area/' + decisionAreaId);
  }

  updateDecisionArea(decisionArea: DecisionArea) {
    return this.http.put(BACKEND_URL + '/decision-area', decisionArea);
  }

  updateByConnectionIndicator(decisionTable: DecisionTable, connectionIndicatorId: number) {
    return this.http.put(
      BACKEND_URL + '/decision-table/' + decisionTable.id + '/connection-indicator/' + connectionIndicatorId,
      decisionTable,
    );
  }

  updateByRoleMandate(decisionTable: DecisionTable, roleMandateId: number) {
    return this.http.put(
      BACKEND_URL + '/decision-table/' + decisionTable.id + '/role-mandate/' + roleMandateId,
      decisionTable,
    );
  }

  createColumn(decisionTableColumn: DecisionTableColumn) {
    return this.http.post<{ insertId: number }>(
      BACKEND_URL + '/decision-table/' + decisionTableColumn.decisionTableId + '/column',
      decisionTableColumn,
    );
  }

  deleteColumn(decisionTableColumn: DecisionTableColumn) {
    return this.http.delete(BACKEND_URL + '/decision-table/column/' + decisionTableColumn.id);
  }

  signOffAnswer(decisionTableId: number, checked: any) {
    return this.http.patch(BACKEND_URL + '/decision-table/answer/' + decisionTableId + '/sign-off', checked);
  }

  saveRelation(dtRelation: DecisionTableRelation) {
    return this.http.post<{ insertId: number }>(BACKEND_URL + '/decision-table/relation', dtRelation);
  }

  findAlDecisionTableRelationByRoleMandateId(roleMandateId) {
    return this.http
      .get<DecisionTableRelation[]>(BACKEND_URL + '/decision-table/relations/' + roleMandateId)
      .pipe(catchError(() => of(undefined)));
  }

  deleteDecisionTableRelation(decisionTableRelationId) {
    return this.http.delete(BACKEND_URL + '/decision-table/relation/' + decisionTableRelationId);
  }

  editDecisionTableRelation(decisionTableRelationId, dtRelation: DecisionTableRelation) {
    return this.http.put(BACKEND_URL + '/decision-table/relation/' + decisionTableRelationId, dtRelation);
  }

  updatePrivateAnswer(decisionTableAnswerId: number, newPrivateValue: boolean) {
    return this.http.patch(BACKEND_URL + '/decision-table/answer/' + decisionTableAnswerId + '/private', {
      private: newPrivateValue,
    });
  }

  saveUserConnectionForDecisionTableAnswer(decisionTableAnswerId: number, userConnection: any) {
    return this.http.post(
      BACKEND_URL + '/decision-table/answer/' + decisionTableAnswerId + '/user-connection',
      userConnection,
    );
  }

  getAnswersByUserId(
    decisionTableId: number,
    userInfoId: number,
    requestFromUserInfoId: number,
    roleMandateId: number,
    connectionIndicatorId: number,
  ) {
    const params = [];

    if (roleMandateId && connectionIndicatorId) {
      return;
    }

    if (roleMandateId) {
      params.push({
        key: 'roleMandateId',
        value: roleMandateId,
      });
    }

    if (connectionIndicatorId) {
      params.push({
        key: 'connectionIndicatorId',
        value: connectionIndicatorId,
      });
    }

    if (requestFromUserInfoId) {
      params.push({
        key: 'requestFromUserInfoId',
        value: requestFromUserInfoId,
      });
    }

    let url = BACKEND_URL + '/decision-table/' + decisionTableId + '/user/' + userInfoId + '/answers';

    if (params.length > 0) {
      url += '?' + params.map((param) => param.key + '=' + param.value).join('&');
    }

    return this.http.get<DecisionTableAnswer[]>(url);
  }

  getRelationAcceptAnswersByToUserInfoIdAndDecisionTableId(
    toUserInfoId: number,
    decisionTableId: number,
  ): Observable<DecisionTableAnswer[]> {
    return this.http
      .get(BACKEND_URL + '/decision-table/' + decisionTableId + '/relation/accept/' + toUserInfoId + '/answers')
      .pipe(
        take(1),
        map((answers: any) =>
          answers.map((answer) => {
            return {
              id: answer.id,
              decisionTableId: answer['decisionTableId'],
              decisionTable: null,
              decisionArea: { id: null, name: answer['decisionArea'] },
              text: answer.text,
              texts: null,
              userConnections: [
                {
                  fromUserInfoId: null,
                  toUserInfoId: answer['fromUserInfoId'],
                  columnName: this.getFormName(answer['columnName']),
                },
              ],
            };
          }),
        ),
      );
  }

  getFormName(columnTitle: string): string {
    return columnTitle.replace(/\s/g, '').replace(/\//g, '');
  }

  loadDecisionTableAnswers(answers: any, decisionTable: DecisionTable, usersName: any) {
    const decisionTableAnswers = [];
    return decisionTableAnswers.concat(
      answers
        .slice(0)
        .reverse()
        .map((element) => {
          const col = {
            decisionFilled: false,
          };
          decisionTable.columns.forEach((column) => {
            if (+column.type === 1) {
              col[this.getFormName(column.title)] = element.decisionArea.name;
            } else if (+column.type === 2) {
              const userConnections = element.userConnections;
              if (Array.isArray(userConnections)) {
                col[this.getFormName(column.title)] = userConnections
                  .map((user) => {
                    if (user.columnName === this.getFormName(column.title) && user.toUserInfoId !== null) {
                      return !element.relatedDecisionTableId
                        ? {
                            decisionTableAnswerId: user.decisionTableAnswerId,
                            toUserInfoId: user.toUserInfoId,
                            userName: usersName[user.toUserInfoId],
                            accept: element.shouldSignOff
                              ? element.toAccept
                                ? element.toAccept
                                : element[this.getFormName(column.title)]
                              : user.accept,
                            userConnectionId: user.userConnectionId,
                          }
                        : {
                            decisionTableAnswerId: user.decisionTableAnswerId,
                            toUserInfoId: user.fromUserInfoId,
                            userName: usersName[user.fromUserInfoId],
                            accept: user.accept,
                          };
                    }
                  })
                  .filter((answer) => answer);
              }
            } else if (+column.type === 3) {
              if (!col.decisionFilled) {
                col[this.getFormName(column.title)] = element.text;
                col.decisionFilled = true;
              }
              const textAnswers = element.texts;
              if (Array.isArray(textAnswers)) {
                const colAns = textAnswers.filter(
                  (textAnswer) => textAnswer.columnName === this.getFormName(column.title),
                );
                if (colAns.length) {
                  col[this.getFormName(column.title)] = colAns[0].text;
                }
              }
            }
          });
          col['id'] = element.id;
          col['fromRelation'] = element.fromRelation;
          col['fromAccept'] = element.fromAccept;
          col['shouldSignOff'] = element.shouldSignOff;
          col['toRelation'] = element.toRelation;
          col['private'] = element.private;
          return col;
        }),
    );
  }

  answerRelationAccept(decisionTableAnswerId: number, accepted: boolean, userName: string): Observable<any> {
    return this.http
      .post(`${BACKEND_URL}/decision-table/relation/accept`, { decisionTableAnswerId, accepted, userName })
      .pipe(take(1));
  }

  signOffAnswerByUserConnectionId(payload: { userConnectionId: number; checked: number }) {
    return this.http.patch(`${BACKEND_URL}/decision-table/user-connection/sign-off`, payload);
  }
}
