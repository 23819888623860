import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// services
import { ChartService } from '@app/core/services/chart.service';
import { SubscaleService } from '@app/core/services/subcale.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { Subscale } from '@app/core/models/subscale.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';
import { SuperscaleService } from '@app/core/services/superscale.service';

@Component({
  selector: 'app-add-subscale-dialog',
  templateUrl: './add-subscale-dialog.component.html',
  styleUrls: ['./add-subscale-dialog.component.scss'],
})
export class AddSubscaleDialogComponent implements OnInit {
  form = this.fb.group({
    axis: ['', [Validators.required]],
    weight: ['', [Validators.required]],
    subscale: ['', [Validators.required]],
  });

  subscales: Subscale[];
  superscales = [];
  filteredSubscales$: Observable<Subscale[]>;

  constructor(
    private fb: FormBuilder,
    private subscaleService: SubscaleService,
    private chartService: ChartService,
    private dialogRef: MatDialogRef<AddSubscaleDialogComponent>,
    private snackBar: SnackBarService,
    private superscaleService: SuperscaleService,
    @Inject(MAT_DIALOG_DATA) public data: { type: string; chartId: number; addedSubscales: number[] },
  ) {}

  ngOnInit(): void {
    if (this.data.type == 'subscale') {
      this.subscaleService.getAll().subscribe((subscales) => {
        this.subscales = subscales.filter((subscale) => !this.data.addedSubscales.includes(subscale.id));
        this.filteredSubscales$ = this.form.controls['subscale'].valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value)),
        );
      });
    } else if (this.data.type === 'supersubscale') {
      this.superscaleService.getAll().subscribe((subscales) => {
        this.superscales = subscales.filter((subscale) => !this.data.addedSubscales.includes(subscale.id));
        this.filteredSubscales$ = this.form.controls['subscale'].valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value)),
        );
      });
    }
  }

  displaySubscaleFn(subscale: Subscale): string {
    return subscale && subscale.title ? subscale.title : '';
  }

  private _filter(value: string): Subscale[] {
    if (typeof value === 'object' || !value) {
      if (this.data.type === 'subscale') {
        return this.subscales;
      } else return this.superscales;
    }

    const filterValue = value.toLowerCase();
    if (this.data.type === 'subscale') {
      return this.subscales.filter((subscale) => subscale.title.toLowerCase().includes(filterValue));
    } else {
      return this.superscales.filter((subscale) => subscale.title.toLowerCase().includes(filterValue));
    }
  }

  onFormSubmit() {
    const { value } = this.form;
    let subscaleId;
    let superScaleId;
    if (this.data.type == 'subscale') {
      subscaleId = this.subscales.find(
        (subscale) => subscale.title.toLowerCase() === value.subscale.title.toLowerCase(),
      )?.id;
    } else {
      superScaleId = this.superscales.find(
        (subscale) => subscale.title.toLowerCase() === value.subscale.title.toLowerCase(),
      )?.id;
    }

    if (!subscaleId && !superScaleId) {
      this.snackBar.info('Unable to POST');
      return;
    }

    const body = {
      axis: value.axis,
      weight: value.weight,
      subscaleId: subscaleId ? subscaleId : null,
      superscaleId: superScaleId ? superScaleId : null,
    };

    this.chartService.addSubscaleToChart(this.data.chartId, body).subscribe(
      (newSubscale) => {
        this.dialogRef.close({ event: EVENT_SUCCESS, newSubscale });
      },
      () => {
        this.snackBar.info('Something went wrong.');
      },
    );
  }
}
