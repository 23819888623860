import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { ClipboardModule } from 'ngx-clipboard';
import { UserAdminRoutingModule } from './user-admin-routing.module';

import { UserListComponent } from './user-list/user-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserInviteListComponent } from './user-invite-list/user-invite-list.component';
import { UserInviteDialogComponent } from './user-invite-dialog/user-invite-dialog.component';

@NgModule({
  declarations: [UserListComponent, UserDetailComponent, UserInviteListComponent, UserInviteDialogComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, UserAdminRoutingModule, ClipboardModule],
  exports: [UserListComponent, UserDetailComponent, SharedModule],
})
export class UserAdminModule {}
