import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-hexagon',
  templateUrl: './area-hexagon.component.html',
  styleUrls: ['./area-hexagon.component.scss'],
})
export class AreaHexagonComponent implements OnInit {
  @Input('selectedAreaNumber') selectedAreaNumber: any;
  constructor() {}

  ngOnInit(): void {}
}
