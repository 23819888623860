import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { CreateNewTestDialogComponent } from '../../tests/create-new-test-dialog/create-new-test-dialog.component';

// services
import { SuperTestService } from '@app/core/services/super-test.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';
import { SuperTest } from '@app/core/models/supertest.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-super-test-list',
  templateUrl: './super-test-list.component.html',
  styleUrls: ['./super-test-list.component.scss'],
})
export class SuperTestListComponent implements OnInit {
  displayedSuperTestListColumns: string[] = ['title', 'actions'];
  superTests: PsbItem[] = [];

  constructor(private router: Router, private superTestService: SuperTestService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getAllSuperTests();
  }

  getAllSuperTests() {
    this.superTests = [];
    this.superTestService.getAllSuperTest().subscribe((superTests) => {
      superTests.forEach((superTest) => {
        const item: PsbItem = {
          id: superTest.id,
          title: superTest.title,
        };

        this.superTests.push(item);
      });
    });
  }

  createNewSupertest() {
    this.dialog
      .open(CreateNewTestDialogComponent, { data: { isSuperTest: true, isEdit: false } })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          const superTest: SuperTest = {
            id: undefined,
            title: result.data.title,
            description: result.data.description,
            startInstructions: result.data.startPageInstructions,
            warningInstructions: result.data.warningInstructions,
            paging: result.data.paging,
            active: result.data.active,
            randomizeOrder: result.data.randomizeOrder,
            deleted: false,
            everyPageInstructionsEnabled: result.data.everyPageInstructionsEnabled,
          };

          this.superTestService.createSuperTest(superTest).subscribe((response) => {
            if (response) {
              this.getAllSuperTests();
            }
          });
        }
      });
  }

  onView(supertestId: number) {
    this.router.navigate([`/super-test/${supertestId}/detail`]);
  }

  onDelete(supertestId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.superTestService.delete(supertestId).subscribe(() => {
            this.getAllSuperTests();
          });
        }
      });
  }
}
