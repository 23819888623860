/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class CompnayService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a department
     * Create a department
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postCompanyDepartment(
        requestBody?: {
            /**
             * Name of the department
             */
            name?: string;
            /**
             * City of the department
             */
            city?: string;
            /**
             * Zip code of the department
             */
            zipCode?: number;
            /**
             * Address of the department
             */
            address?: string;
            /**
             * State id of the department
             */
            stateId?: number;
            /**
             * Company id of the department
             */
            companyId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/{id}/department',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

}
