import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiV2Client, OpenAPI, OpenAPIConfig } from '../../../../../../generated/v2';
import { environment } from '../../../../../../environments/environment';

@NgModule({
  imports: [ApiV2Client],
  providers: [
    {
      provide: OpenAPI,
      useValue: {
        ...OpenAPI,
        BASE: environment.apiUrl,
        HEADERS: async () => ({
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      } as OpenAPIConfig,
    },
  ],
})
export class ApiClientModule {}
