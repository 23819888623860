import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';
import { HomeRoutingModule } from './home-routing.module';

import { HomeComponent } from './pages/home/home.component';
import { HelpComponent } from './pages/help/help.component';

@NgModule({
  declarations: [HelpComponent, HomeComponent],
  imports: [SharedModule, CommonModule, HomeRoutingModule],
  exports: [HelpComponent],
})
export class HomeModule {}
