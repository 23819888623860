import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// models
import { PsyChart } from '@app/core/models/psy-chart.model';
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-test-group-chart-preview',
  templateUrl: './test-group-chart-preview.component.html',
  styleUrls: ['./test-group-chart-preview.component.scss'],
})
export class TestGroupChartPreviewComponent {
  selectedUsers = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { testGroupId: number; chart: PsyChart },
    private dialogRef: MatDialogRef<TestGroupChartPreviewComponent>,
  ) {}

  selectUsers(users: any) {
    this.selectedUsers = users;
  }
}
