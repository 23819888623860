<h1 mat-dialog-title>Edit double range sentence</h1>
<form [formGroup]="form" (submit)="onSubmit()">
  <div class="range-header">
    <mat-form-field>
      <mat-label>From</mat-label>
      <input matInput required formControlName="from1" type="number" min="0" [max]="+form.get('to1').value - 1" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>To</mat-label>
      <input matInput required formControlName="to1" type="number" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>From</mat-label>
      <input matInput required formControlName="from2" type="number" min="0" [max]="+form.get('to2').value - 1" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>To</mat-label>
      <input matInput required formControlName="to2" type="number" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Sentence</mat-label>
      <mat-select [formControl]="form.get('currentOption')">
        <mat-option value="he">He</mat-option>
        <mat-option value="she">She</mat-option>
        <mat-option value="they">They</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div formGroupName="reportText">
    <angular-editor [config]="editorConfig" [formControl]="form.get('reportText').get(form.get('currentOption').value)">
    </angular-editor>
  </div>
  <div id="action-buttons" mat-dialog-actions align="end">
    <button id="cancel-button" mat-flat-button (click)="onCancel()">
      <mat-icon>block</mat-icon>
      Cancel
    </button>
    <button id="update-button" mat-flat-button color="primary" type="submit">
      <mat-icon>save_alt</mat-icon>
      Save
    </button>
  </div>
</form>
