<mat-card [ngClass]="'startPageInstructions'">
  <h3>Related Assessments</h3>
  <div
    *ngIf="
      relatedAssessmentsAndSubscales.allRelatedPsyTestScale &&
        relatedAssessmentsAndSubscales.allRelatedPsyTestScale.length > 0;
      else noRelatedAssessmnents
    "
  >
    <mat-card-content *ngFor="let relatedPsyTestScale of relatedAssessmentsAndSubscales.allRelatedPsyTestScale">
      <a [routerLink]="['/psy-test/' + relatedPsyTestScale.testId + '/detail']">{{ relatedPsyTestScale.testTitle }}</a>
    </mat-card-content>
  </div>
  <ng-template #noRelatedAssessmnents>
    <p>No related assessments found.</p>
  </ng-template>
</mat-card>

<mat-card [ngClass]="'warningInstructions'">
  <h3>Related Subscales</h3>
  <div
    *ngIf="
      relatedAssessmentsAndSubscales.allRelatedSubscale && relatedAssessmentsAndSubscales.allRelatedSubscale.length > 0;
      else noRelatedSubscales
    "
  >
    <mat-card-content
      *ngFor="let relatedSubscale of relatedAssessmentsAndSubscales.allRelatedSubscale | paginate: config"
    >
      <a href="/subscale/{{ relatedSubscale.id }}/detail">{{ relatedSubscale.title }}</a>
    </mat-card-content>
  </div>
  <ng-template #noRelatedSubscales>
    <p>No related subscales found.</p>
  </ng-template>
  <pagination-controls
    *ngIf="
      relatedAssessmentsAndSubscales.allRelatedSubscale && relatedAssessmentsAndSubscales.allRelatedSubscale.length > 5
    "
    class="pagination"
    (pageChange)="pageChanged($event)"
  >
  </pagination-controls>
</mat-card>
