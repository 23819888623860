<div class="container decision-tables">
  <app-psb-page-navigator [pages]="pages"></app-psb-page-navigator>

  <h2>Decision Tables</h2>

  <div *ngFor="let decisionTable of decisionTables">
    <app-decision-table
      [decisionTable]="decisionTable"
      [roleMandateId]="roleMandateId"
      (delete)="onDeleteDecisionTable($event)"
      (edit)="onEditDecisionTable($event)"
      [showAcl]="true"
    >
    </app-decision-table>
  </div>

  <app-new-decision-table
    *ngIf="newDecisionTable"
    [decisionTableType]="decisionTableType"
    [roleMandateId]="roleMandateId"
    [decisionTables]="decisionTables"
    (addedDecisionTables)="updateDecisionTables($event)"
  ></app-new-decision-table>
  <button
    *ngIf="!newDecisionTable"
    mat-raised-button
    (click)="onNewDecisionTable()"
    class="btn-success btn-center btn-300"
  >
    New Decision Table
  </button>

  <mat-divider></mat-divider>
  <h2>Decision Areas</h2>

  <div class="decision-areas">
    <div *ngFor="let decisionArea of decisionAreas">
      <app-decision-area *ngIf="decisionAreas" [decisionArea]="decisionArea" (delete)="onDeleteDecisionArea($event)">
      </app-decision-area>
    </div>
  </div>

  <app-new-decision-area
    *ngIf="newDecisionArea"
    [decisionAreas]="decisionAreas"
    [companyId]="companyId"
    (add)="onAddDecisionArea($event)"
  ></app-new-decision-area>
  <br />

  <button
    *ngIf="!newDecisionArea"
    mat-raised-button
    (click)="onNewDecisionArea()"
    class="btn-success btn-center btn-300"
  >
    New Decision Area
  </button>

  <mat-divider></mat-divider>
  <h2>Decision Tables Relation</h2>
  <div *ngIf="decisionTableRelations">
    <app-decision-table-connection
      *ngFor="let decisionTableRelation of decisionTableRelations"
      [decisionTableRelation]="decisionTableRelation"
      (delete)="onDeleteDecisionRelation($event)"
      (edit)="onEditDecisionRelation($event)"
      [decisionTables]="decisionTables"
    >
    </app-decision-table-connection>
  </div>

  <app-new-decision-table-connection
    *ngIf="newDecisionTableRelation"
    [decisionTables]="decisionTables"
    (save)="onAddDecitionTableRelation($event)"
  >
  </app-new-decision-table-connection>
  <button
    *ngIf="!newDecisionTable"
    mat-raised-button
    (click)="onNewDecitionTableRelation()"
    class="btn-success btn-center btn-300"
  >
    New Decision Table Relation
  </button>

  <button mat-raised-button class="btn-success btn-center btn-300" (click)="onNext()">Next</button>
</div>
