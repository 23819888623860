import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { CreateOrEditReportModelDialogComponent } from '../create-or-edit-report-model-dialog/create-or-edit-report-model-dialog.component';

// services
import { ReportModelService } from '@app/core/services/report-model.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-report-model',
  templateUrl: './report-model.component.html',
  styleUrls: ['./report-model.component.scss'],
})
export class ReportModelComponent implements OnInit {
  reportModelPsbItems: PsbItem[] = [];

  constructor(
    private reportModelService: ReportModelService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.findAllReportModel();
  }

  findAllReportModel() {
    this.reportModelPsbItems = [];
    this.reportModelService.getAll().subscribe((reportModels) => {
      reportModels.forEach((reportModel) => {
        const item: PsbItem = {
          id: reportModel.id,
          title: reportModel.title,
          extraColumns: [
            {
              key: 'Signature Title',
              value: reportModel.signatureTitle,
            },
            {
              key: 'Signature Text',
              value: reportModel.signatureText,
            },
          ],
        };

        this.reportModelPsbItems.push(item);
      });
    });
  }

  createNewReportModel() {
    const createNewDialog = this.dialog.open(CreateOrEditReportModelDialogComponent, {
      data: { isEdit: false },
    });

    createNewDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.reportModelService.save(result.data).subscribe((response) => {
          if (response) {
            this.findAllReportModel();
          }
        });
      }
    });
  }

  onView(reportModelId: number) {
    this.router.navigate([`/report-model/${reportModelId}/details`]);
  }

  onEdit(reportModelId: number) {
    const editDialog = this.dialog.open(CreateOrEditReportModelDialogComponent, {
      data: { isEdit: true, reportModelId },
    });

    editDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.reportModelService.update(reportModelId, result.data).subscribe((response) => {
          if (response) {
            this.findAllReportModel();
          }
        });
      }
    });
  }

  onDelete(reportModelId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.reportModelService.delete(reportModelId).subscribe(() => {
            this.reportModelPsbItems = this.reportModelPsbItems.filter((item) => item.id !== reportModelId);
            this.snackBar.info('Report Model deleted successfully');
          });
        }
      });
  }
}
