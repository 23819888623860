import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// components
import { SubsectionFormComponent } from '@app/core/models/subsection-form-component';

// services
import { SubscaleService } from '@app/core/services/subcale.service';

// models
import { Subscale } from '@app/core/models/subscale.model';

@Component({
  selector: 'app-section-ordinal-paragraph-form',
  templateUrl: './section-ordinal-paragraph-form.component.html',
  styleUrls: ['./section-ordinal-paragraph-form.component.scss'],
})
export class SectionOrdinalParagraphFormComponent implements OnInit, SubsectionFormComponent {
  @Input() model: any;

  topSubscaleFieldUpdate = new Subject<string>();
  bottomSubscaleFieldUpdate = new Subject<string>();
  topSubscaleField: string;
  bottomSubscaleField: string;

  subscales: Subscale[] = [];

  form: FormGroup;
  newSubscaleForm: FormGroup;

  editorConfig: AngularEditorConfig = {
    height: '5rem',
    editable: true,
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(private fb: FormBuilder, private subscaleService: SubscaleService) {
    this.newSubscaleForm = this.fb.group({
      subscale: ['', Validators.required],
      weight: [1, Validators.required],
    });

    this.form = this.fb.group({
      heading: ['', [Validators.required]],
      positiveHeading: [''],
      concernHeading: [''],
      topDefaultSentence: [''],
      bottomDefaultSentence: [''],
      defaultSentenceEnabled: [false],
      leadSentence: this.fb.group({
        top: this.fb.group({
          option: ['he'],
          id: [''],
          he: [''],
          she: [''],
          they: [''],
        }),
        bottom: this.fb.group({
          option: ['he'],
          id: [''],
          he: [''],
          she: [''],
          they: [''],
        }),
      }),
      totals: this.fb.group({
        top: [0, [Validators.required, Validators.min(0)]],
        bottom: [0, [Validators.required, Validators.min(0)]],
      }),
      texts: this.fb.group({
        top: this.fb.array([]),
        bottom: this.fb.array([]),
      }),
    });

    this.topSubscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.topSubscaleField = value;
      }
    });

    this.bottomSubscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.bottomSubscaleField = value;
      }
    });
  }

  ngOnInit(): void {
    if (this.model) {
      if (this.model.heading) {
        this.form.get('heading').patchValue(this.model.heading);
      }
      if (this.model.positiveHeading) {
        this.form.get('positiveHeading').patchValue(this.model.positiveHeading);
      }
      if (this.model.concernHeading) {
        this.form.get('concernHeading').patchValue(this.model.concernHeading);
      }
      if (this.model.leadSentences.top) {
        this.form.get('leadSentence').get('top').patchValue(this.model.leadSentences.top);
      }
      if (this.model.leadSentences.bottom) {
        this.form.get('leadSentence').get('bottom').patchValue(this.model.leadSentences.bottom);
      }
      if (this.model.totals.top) {
        this.form.get('totals').get('top').patchValue(this.model.totals.top);
      }
      if (this.model.totals.bottom) {
        this.form.get('totals').get('bottom').patchValue(this.model.totals.bottom);
      }

      if (this.model.defaultSentenceEnabled) {
        this.form.get('defaultSentenceEnabled').patchValue(this.model.defaultSentenceEnabled === 0 ? false : true);
      }

      if (this.model.topDefaultSentence) {
        this.form.get('topDefaultSentence').patchValue(this.model.topDefaultSentence);
      }

      if (this.model.bottomDefaultSentence) {
        this.form.get('bottomDefaultSentence').patchValue(this.model.bottomDefaultSentence);
      }

      for (const text of this.model.texts.top) {
        this.top.push(
          this.fb.group({
            option: ['he'],
            he: [text.he],
            she: [text.she],
            they: [text.they],
            subscales: [text.subscales],
          }),
        );
      }
      for (const text of this.model.texts.bottom) {
        this.bottom.push(
          this.fb.group({
            option: ['he'],
            he: [text.he],
            she: [text.she],
            they: [text.they],
            subscales: [text.subscales],
          }),
        );
      }
    }

    this.subscaleService.getAll().subscribe((subscales) => {
      this.subscales = subscales.map((subscale) => {
        if (subscale.cardsortSubscale === 1) {
          return { ...subscale, title: `${subscale.title} (card-sort)` };
        }
        return subscale;
      });
    });
  }

  get top() {
    return this.form.get('texts').get('top') as FormArray;
  }

  get bottom() {
    return this.form.get('texts').get('bottom') as FormArray;
  }

  displayTopSubscale(topSubscale: Subscale): string {
    return topSubscale ? topSubscale.title : '';
  }

  displayBottomSubscale(bottomSubscale: Subscale): string {
    return bottomSubscale ? bottomSubscale.title : '';
  }

  onAddText(topOrBottom) {
    let newFormGroup = this.fb.group({
      option: ['he'],
      he: [],
      she: [],
      they: [],
      subscales: [],
    });

    if (topOrBottom === 'top') {
      this.top.insert(0, newFormGroup);
    } else if (topOrBottom === 'bottom') {
      this.bottom.insert(0, newFormGroup);
    }
  }

  onAddSubscale(textForm) {
    const subscale = this.newSubscaleForm.get('subscale').value;
    const subcaleWithWeight = { ...subscale, weight: this.newSubscaleForm.get('weight').value };
    if (textForm.get('subscales').value) {
      textForm.get('subscales').value.push(subcaleWithWeight);
    } else {
      textForm.get('subscales').setValue([subcaleWithWeight]);
    }
    this.newSubscaleForm.reset();
  }

  onDeleteSubscale(index: number, topOrBottom: 'top' | 'bottom', textForm: FormGroup) {
    textForm.get('subscales').value.splice(index, 1);
  }

  onDeleteText(index: number, topOrBottom: 'top' | 'bottom') {
    if (topOrBottom === 'top') {
      this.top.controls.splice(index, 1);
    } else if (topOrBottom === 'bottom') {
      this.bottom.controls.splice(index, 1);
    }
  }

  getSubsection() {
    return {
      id: this.model?.id,
      heading: this.form.get('heading').value,
      positiveHeading: this.form.get('positiveHeading').value,
      concernHeading: this.form.get('concernHeading').value,
      defaultSentenceEnabled: this.form.get('defaultSentenceEnabled').value,
      topDefaultSentence: this.form.get('topDefaultSentence').value,
      bottomDefaultSentence: this.form.get('bottomDefaultSentence').value,
      totals: {
        top: this.form.get('totals').get('top').value,
        bottom: this.form.get('totals').get('bottom').value,
      },
      leadSentences: {
        top: {
          he: this.form.get('leadSentence').get('top').get('he').value,
          she: this.form.get('leadSentence').get('top').get('she').value,
          they: this.form.get('leadSentence').get('top').get('they').value,
        },
        bottom: {
          he: this.form.get('leadSentence').get('bottom').get('he').value,
          she: this.form.get('leadSentence').get('bottom').get('she').value,
          they: this.form.get('leadSentence').get('bottom').get('they').value,
        },
      },
      texts: {
        top: this.top.controls.map((text, idx) => ({
          he: text.get('he').value,
          she: text.get('she').value,
          they: text.get('they').value,
          subscales: text.get('subscales').value,
        })),
        bottom: this.bottom.controls.map((text, idx) => ({
          he: text.get('he').value,
          she: text.get('she').value,
          they: text.get('they').value,
          subscales: text.get('subscales').value,
        })),
      },
    };
  }
}
