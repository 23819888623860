import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { RedirectDialogComponent } from './redirect-dialog/redirect-dialog.component';

// models
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { SuperTest } from '@app/core/models/supertest.model';
import { PsyTest } from '@app/core/models/psy-test.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-test-list',
  templateUrl: './test-list.component.html',
  styleUrls: ['./test-list.component.scss'],
})
export class TestListComponent {
  @Input()
  psyTests: PsyTest[] = [];

  @Input()
  superTests: SuperTest[] = [];

  @Input()
  connectionIndicators: ConnectionIndicator[] = [];

  @Input()
  roleMandates: RoleMandate[] = [];

  constructor(private router: Router, public dialog: MatDialog) {}

  onStartCI(connectionIndicator: ConnectionIndicator) {
    this.router.navigate([`/user/connections-indicator/${connectionIndicator.id}/instructions`]);
  }

  onStartPsyTest(psyTest: PsyTest) {
    return this.router.navigate(['/user', 'test', psyTest.id, 'test-group', psyTest.testGroupId, 'instructions']);
  }

  onContinuePsyTest(psyTest: PsyTest) {
    return this.router.navigate(['/user', 'test', psyTest.id, 'test-group', psyTest.testGroupId, 'continue']);
  }

  onStartSuperTest(supertest: SuperTest) {
    this.router.navigate(['/user', 'supertest', supertest.id, 'instructions']);
  }

  onViewCompanyConnectionIndicator(connectionIndicator) {
    this.router.navigate([`/user/connections-indicator/${connectionIndicator.id}/user-list`]);
  }

  onViewCompanyRoleMandate(roleMandate: RoleMandate) {
    this.router.navigate([`/user/role-mandate/${roleMandate.id}/user-list`]);
  }

  onStartRM(roleMandate: RoleMandate) {
    this.router.navigate([`/user/role-mandate/${roleMandate.id}/instuctions`]);
  }

  onReviewRM(roleMandate: RoleMandate) {
    this.router.navigate([`/user/role-mandate/${roleMandate.id}/review`]);
  }

  roleMandateOpen(roleMandate: RoleMandate) {
    return roleMandate.status === 'STARTED';
  }

  roleMandateButton(roleMandate: RoleMandate): string {
    return roleMandate.status === 'COMPLETED' ? 'Edit' : 'Start';
  }

  isNotPrivate(test: ConnectionIndicator | RoleMandate) {
    return test.acl === 5 || test.acl === 0 || typeof test.acl === 'undefined';
  }

  onSearch(search: { test: RoleMandate | ConnectionIndicator; type: 'PCI' | 'PRN' }) {
    switch (search.type) {
      case 'PCI': {
        return this.router.navigate([`/user/connections-indicator/${search.test.id}/search`]);
      }
      case 'PRN': {
        return this.router.navigate([`/user/role-mandate/${search.test.id}/search`]);
      }
      default: {
        break;
      }
    }
  }
}
