<mat-card *ngIf="!this.question.relatedQuestionId">
  <mat-card-header>
    <mat-card-title>
      <span>{{ question.questionNumber }}) </span>
      <span>{{ question.text }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="form-text-list" *ngIf="answering" (submit)="saveTextAreaAnswer()">
      <mat-form-field>
        <mat-label>Answer</mat-label>
        <input
          matInput
          [formControl]="answerTextAreaControl"
          (change)="updateAnswerNotSaved($event)"
          [readonly]="!answering"
          autocomplete="off"
        />
        <mat-hint align="start"><strong>Click on "Add" or Enter to Save.</strong> </mat-hint>
      </mat-form-field>
      <button type="submit" mat-button class="btn-primary save-button" [disabled]="!answerTextAreaControl.value">
        Add
      </button>
    </form>

    <div *ngIf="!answering && dataSource.data.length == 0">
      <i>No answers found</i>
    </div>

    <mat-table
      style="margin-top: 10px"
      [dataSource]="dataSource"
      *ngIf="textAreaAnswers && textAreaAnswers.length"
      class="mat-elevation-z3"
    >
      <ng-container matColumnDef="answer">
        <mat-header-cell *matHeaderCellDef> Answers </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.answer }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="private">
        <mat-header-cell *matHeaderCellDef>Private</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-slide-toggle
            *ngIf="question.acl === 5"
            color="primary"
            [disabled]="!answering"
            [checked]="+element.private === 1"
            (change)="togglePrivacy(element)"
          ></mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" class="delete-column">
        <mat-header-cell class="header_action" *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell class="cell_action" *matCellDef="let element">
          <button mat-button class="btn-error" (click)="onEdit(element)">Edit</button>
          <button mat-button class="btn-error" (click)="onDelete(element)">Delete</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedReviewTextAnswerColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedReviewTextAnswerColumns"></mat-row>
    </mat-table>
    <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 30]"></mat-paginator>
  </mat-card-content>
</mat-card>

<div *ngIf="this.question.relatedQuestionId">
  <mat-card *ngFor="let relatedAnswer of relatedAnswers">
    <mat-card-header>
      <mat-card-title>
        <span>{{ relatedAnswer.questionNumber }}) {{ relatedAnswer.text }}</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-text-list" (submit)="saveTextAreaAnswerWithRelated(relatedAnswer)" *ngIf="answering">
        <mat-form-field>
          <mat-label>Answer</mat-label>
          <input
            matInput
            [id]="relatedAnswer.id.toString()"
            [formControl]="answerTextAreaControl"
            (change)="updateAnswerNotSaved($event)"
            (input)="onInput($event.target.value, relatedAnswer.id)"
          />
          <mat-hint align="start"><strong>Click on "Add" or Enter to Save.</strong> </mat-hint>
        </mat-form-field>
        <button
          type="submit"
          mat-button
          class="btn-primary save-button"
          [disabled]="!relatedAnswersFormValue[relatedAnswer.id]?.hasValue"
        >
          Add
        </button>
      </form>

      <div
        *ngIf="
          !answering &&
          (!textRelatedAnswers.get(relatedAnswer.id) || textRelatedAnswers.get(relatedAnswer.id).data.length == 0)
        "
      >
        <i>No answers found</i>
      </div>

      <mat-table
        [dataSource]="textRelatedAnswers.get(relatedAnswer.id)"
        *ngIf="textRelatedAnswers.get(relatedAnswer.id) && textRelatedAnswers.get(relatedAnswer.id).data.length > 0"
        class="mat-elevation-z3 mat-table_textRelated"
      >
        <ng-container matColumnDef="answer">
          <mat-header-cell *matHeaderCellDef> Tasks and Activities </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.answer }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions" class="delete-column">
          <mat-header-cell class="header_action" *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell class="cell_action" *matCellDef="let element">
            <button mat-button class="btn-error" (click)="onEdit(element)">Edit</button>
            <button mat-button class="btn-error" (click)="onDelete(element)">Delete</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedReviewTextAnswerColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedReviewTextAnswerColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 30]"> </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
