import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// components
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';

// services
import { QuestionService } from '@app/core/services/question.service';

// models
import { Question } from '@app/core/models/question.model';

@Component({
  selector: 'app-text-area-analysis',
  templateUrl: './text-area-analysis.component.html',
  styleUrls: ['./text-area-analysis.component.scss'],
})
export class TextAreaAnalysisComponent implements OnInit, AfterViewInit {
  @Input() connectionIndicatorId: number;
  @Input() textAreaAnalysis: { question: Question; subscaleName: string; analysis: any[] };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['user', 'answer', 'notes'];

  constructor(
    public dialog: MatDialog,
    private questionService: QuestionService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.textAreaAnalysis.analysis);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onAddOrUpdateNotes(element: any) {
    const notesDialog = this.dialog.open(NotesDialogComponent, {
      data: { notes: element.notes, textAnswerAnalysesId: element.textAnswerAnalysesId },
    });
    if (!element.textAnswerAnalysesId) {
      notesDialog.afterClosed().subscribe((result) => {
        if (typeof result === 'string') {
          this.questionService.saveTextAreaNote(element.genericAnswerId, result).subscribe((res) => {
            if (res) {
              this.refreshTableData();
            }
          });
        }
      });
    } else {
      notesDialog.afterClosed().subscribe((result) => {
        if (typeof result === 'string') {
          this.questionService.updateTextAreaNote(element.textAnswerAnalysesId, result).subscribe((res) => {
            if (res) {
              this.refreshTableData();
            }
          });
        }
      });
    }
  }

  refreshTableData() {
    this.questionService
      .getTextAreaAnalysisByQuestionId(this.connectionIndicatorId, this.textAreaAnalysis.question.id)
      .subscribe({
        next: (textAreaAnalysisResult) => {
          this.textAreaAnalysis.analysis = textAreaAnalysisResult;
          this.dataSource = new MatTableDataSource(this.textAreaAnalysis.analysis);
          this.dataSource.paginator = this.paginator;
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
