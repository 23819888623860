import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// services
import { QuestionService } from '@app/core/services/question.service';

// models
import { Question } from '@app/core/models/question.model';

@Component({
  selector: 'app-slider-analysis',
  templateUrl: './slider-analysis.component.html',
  styleUrls: ['./slider-analysis.component.scss'],
})
export class SliderAnalysisComponent implements OnInit, AfterViewInit {
  @Input() connectionIndicatorId: number;
  @Input() roleMandateId: number;
  @Input() question: Question;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['answer', 'selectionPercentage'];

  constructor(private questionService: QuestionService) {}

  ngOnInit(): void {
    this.questionService
      .getSliderAnalysisByConnectionIndicatorIdAndQuestionId(this.connectionIndicatorId, this.question.id)
      .subscribe((analysis) => (this.dataSource.data = analysis));
    /**
     * Todo:
     * -Adapt for role navigator too;
     * -On creation of pci question add option of sliders;
     * -We are not using question.instructions on creation;
     * -PCI question group is not working (create, associate, etc);
     * -Question is not returning the correct subscale info;
     */
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
