<h1>Charts</h1>
<p class="small-text">Visualize the results for the whole team on the same chart.</p>

<mat-divider></mat-divider>

<button mat-raised-button color="primary" class="add-button" (click)="onAddChart()" [disabled]="!charts.length">
  <mat-icon>add</mat-icon>
  Add Chart
</button>

<div class="mat-elevation-z3">
  <mat-table [dataSource]="testGroupCharts">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Name :</span>
        <a [routerLink]="['/chart', element.id]">{{ element.name }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let element" class="buttons">
        <span class="mobile-label">Actions :</span>
        <button mat-button (click)="onPreview(element)">Preview</button>
        <button mat-button (click)="onSetup(element.id)">Setup</button>
        <button mat-button class="btn-delete" (click)="onDelete(element.id)">Delete</button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="visible">
      <!--comment out so that tgm can turn the charts on and off-->
      <!--<mat-header-cell [style.display]="this.isAdmin ? '' : 'none'" *matHeaderCellDef>Visible</mat-header-cell>
      <mat-cell [style.display]="this.isAdmin ? '' : 'none'" *matCellDef="let element">
              <span class="mobile-label">Visible :</span>
              <mat-button-toggle-group (change)="onToggleVisible(element)">
                <mat-button-toggle [checked]="element.visible">ON</mat-button-toggle>
                <mat-button-toggle [checked]="!element.visible">OFF</mat-button-toggle>
              </mat-button-toggle-group>
      </mat-cell>-->
      <mat-header-cell *matHeaderCellDef>Visible</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Visible :</span>
        <mat-button-toggle-group (change)="onToggleVisible(element)">
          <mat-button-toggle [checked]="element.visible">ON</mat-button-toggle>
          <mat-button-toggle [checked]="!element.visible">OFF</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedTestGroupChartsColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedTestGroupChartsColumns"></mat-row>
  </mat-table>
</div>
