import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ReportService } from '@app/core/services/report.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-custom-range',
  templateUrl: './custom-range.component.html',
  styleUrls: ['./custom-range.component.scss'],
})
export class CustomRangeComponent implements OnInit {
  @Input() testGroupId: number;
  form: FormGroup;

  editLabelDialogRef: MatDialogRef<any>;
  editLabelControl = new FormControl('', [Validators.required]);
  textForLabelTitle: string;
  isExistingCustomRange: boolean;

  editorConfig: AngularEditorConfig = {
    height: '7rem',
    width: '20rem',
    showToolbar: false,
    editable: true,
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private reportService: ReportService,
    private snackBar: SnackBarService,
  ) {
    this.form = this.fb.group({
      customRangeIds: this.fb.group({
        outOfRangeLow: [null],
        cautionaryLow: [null],
        optimal: [null],
        cautionaryHigh: [null],
        outOfRangeHigh: [null],
      }),
      ranges: this.fb.group({
        outOfRangeLow: ['Out of Range: Low'],
        cautionaryLow: ['Cautionary Low'],
        optimal: ['Optimal'],
        cautionaryHigh: ['Cautionary High'],
        outOfRangeHigh: ['Out of Range: High'],
      }),
      labels: this.fb.group({
        outOfRangeLow: ['Out of Range: Low'],
        cautionaryLow: ['Cautionary Low'],
        optimal: ['Optimal'],
        cautionaryHigh: ['Cautionary High'],
        outOfRangeHigh: ['Out of Range: High'],
      }),
      hides: this.fb.group({
        outOfRangeLow: [false],
        cautionaryLow: [false],
        optimal: [false],
        cautionaryHigh: [false],
        outOfRangeHigh: [false],
      }),
    });
  }

  ngOnInit(): void {
    this.getCustomRange();
  }

  getCustomRange() {
    this.reportService.getCustomRanges(this.testGroupId).subscribe((customRanges) => {
      if (customRanges && customRanges.length > 0) {
        this.isExistingCustomRange = true;
        this.refreshCustomRangeTable(customRanges);
      } else {
        this.isExistingCustomRange = false;
      }
    });
  }

  refreshCustomRangeTable(customRanges) {
    for (const customRange of customRanges) {
      switch (customRange.rangeTitle) {
        case 'Out of Range: Low':
          this.form.get('customRangeIds.outOfRangeLow').patchValue(customRange.id);
          this.form.get('labels.outOfRangeLow').patchValue(customRange.rangeLabel);
          this.form.get('hides.outOfRangeLow').patchValue(customRange.hided === 1 ? true : false);
          break;
        case 'Cautionary Low':
          this.form.get('customRangeIds.cautionaryLow').patchValue(customRange.id);
          this.form.get('labels.cautionaryLow').patchValue(customRange.rangeLabel);
          this.form.get('hides.cautionaryLow').patchValue(customRange.hided === 1 ? true : false);
          break;
        case 'Optimal':
          this.form.get('customRangeIds.optimal').patchValue(customRange.id);
          this.form.get('labels.optimal').patchValue(customRange.rangeLabel);
          this.form.get('hides.optimal').patchValue(customRange.hided === 1 ? true : false);
          break;
        case 'Cautionary High':
          this.form.get('customRangeIds.cautionaryHigh').patchValue(customRange.id);
          this.form.get('labels.cautionaryHigh').patchValue(customRange.rangeLabel);
          this.form.get('hides.cautionaryHigh').patchValue(customRange.hided === 1 ? true : false);
          break;
        case 'Out of Range: High':
          this.form.get('customRangeIds.outOfRangeHigh').patchValue(customRange.id);
          this.form.get('labels.outOfRangeHigh').patchValue(customRange.rangeLabel);
          this.form.get('hides.outOfRangeHigh').patchValue(customRange.hided === 1 ? true : false);
          break;
        default:
          break;
      }
    }
  }

  closeEditLabelDialog() {
    this.editLabelDialogRef.close();
  }

  saveEditLabelDialog() {
    this.editLabelDialogRef.close({ event: EVENT_SUCCESS });
  }

  openEditLabelDialog(ref: TemplateRef<any>, textControl: AbstractControl, title: string) {
    this.editLabelControl.setValue(textControl.value);
    this.textForLabelTitle = title;
    this.editLabelDialogRef = this.dialog.open(ref);
    this.editLabelDialogRef.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_SUCCESS) {
        return;
      }
      textControl.setValue(this.editLabelControl.value);
      this.editLabelControl.setValue('');
    });
  }

  onSave() {
    const testGroupCustomRanges = this.getTestGroupCustomRanges();

    if (this.isExistingCustomRange) {
      this.reportService.updateCustomRanges(this.testGroupId, testGroupCustomRanges).subscribe((customRanges) => {
        this.refreshCustomRangeTable(customRanges);
        this.snackBar.info('Custom Ranges updated with success.');
      });
    } else {
      this.reportService.createCustomRanges(this.testGroupId, testGroupCustomRanges).subscribe((customRanges) => {
        this.isExistingCustomRange = true;
        this.refreshCustomRangeTable(customRanges);
        this.snackBar.info('Custom Ranges created with success.');
      });
    }
  }

  getTestGroupCustomRanges() {
    const testGroupOutOfRangeLowCustomRange = {
      id: this.form.controls['customRangeIds'].value.outOfRangeLow,
      rangeTitle: this.form.controls['ranges'].value.outOfRangeLow,
      rangeLabel: this.form.controls['labels'].value.outOfRangeLow,
      hided: this.form.controls['hides'].value.outOfRangeLow,
      testGroupId: this.testGroupId,
    };

    const testGroupCautionaryLowCustomRange = {
      id: this.form.controls['customRangeIds'].value.cautionaryLow,
      rangeTitle: this.form.controls['ranges'].value.cautionaryLow,
      rangeLabel: this.form.controls['labels'].value.cautionaryLow,
      hided: this.form.controls['hides'].value.cautionaryLow,
      testGroupId: this.testGroupId,
    };

    const testGroupOptimalCustomRange = {
      id: this.form.get('customRangeIds').value.optimal,
      rangeTitle: this.form.get('ranges').value.optimal,
      rangeLabel: this.form.get('labels').value.optimal,
      hided: this.form.get('hides').value.optimal,
      testGroupId: this.testGroupId,
    };

    const testGroupCautionaryHighCustomRange = {
      id: this.form.get('customRangeIds').value.cautionaryHigh,
      rangeTitle: this.form.get('ranges').value.cautionaryHigh,
      rangeLabel: this.form.get('labels').value.cautionaryHigh,
      hided: this.form.get('hides').value.cautionaryHigh,
      testGroupId: this.testGroupId,
    };

    const testGroupOutOfRangeHighCustomRange = {
      id: this.form.get('customRangeIds').value.outOfRangeHigh,
      rangeTitle: this.form.get('ranges').value.outOfRangeHigh,
      rangeLabel: this.form.get('labels').value.outOfRangeHigh,
      hided: this.form.get('hides').value.outOfRangeHigh,
      testGroupId: this.testGroupId,
    };

    const testGroupCustomRanges = [
      testGroupOutOfRangeLowCustomRange,
      testGroupCautionaryLowCustomRange,
      testGroupOptimalCustomRange,
      testGroupCautionaryHighCustomRange,
      testGroupOutOfRangeHighCustomRange,
    ];
    return testGroupCustomRanges;
  }
}
