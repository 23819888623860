import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { EVENT_CANCEL } from '@app/core/constants';
import { Score } from '@app/core/models/score.model';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'app-user-score-list',
  templateUrl: './user-score-list.component.html',
  styleUrls: ['./user-score-list.component.scss'],
})
export class UserScoreListComponent implements OnInit {
  userId: number;
  scores: Score[] = [];

  scoresDataSource = new MatTableDataSource<any>([]);
  displayedScoresColumns = ['subscale', 'score', 'percentile', 'actions'];

  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';

  constructor(private activatedRoute: ActivatedRoute, private userService: UserService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.userId = Number(this.activatedRoute.snapshot.params.id);

    this.userService.getUserScores(this.userId).subscribe((scores) => {
      this.scores = scores;
      this.scoresDataSource.data = this.scores.map((score) => ({
        id: +score.id,
        subscale: score.subscaleTitle,
        score: score.score,
        percentile: score.percentile || 0,
      }));
    });
  }

  onDelete(scoreId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.userService.deleteScore(scoreId).subscribe(() => {
          this.scoresDataSource.data = this.scoresDataSource.data.filter((item) => item.id !== scoreId);
        });
      });
  }
}
