<ng-progress #progressBar></ng-progress>

<mat-card class="card">
  <h2>Test Group Report Template</h2>

  <div class="buttons">
    <button mat-flat-button color="primary" (click)="openEditTemplate()" [disabled]="progressBar.isStarted">
      Edit Report Template
    </button>
    <button mat-flat-button color="warn" (click)="resetTemplateToDefaults()" [disabled]="progressBar.isStarted">
      Reset Template To Defaults
    </button>
  </div>
</mat-card>
