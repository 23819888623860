import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Answer } from '@app/core/models/answer.model';
import { CardSortScorePosition } from '@app/core/models/card-sort-score-position.model';
import { Question } from '@app/core/models/question.model';
import { AnswerService } from '@app/core/services/answer.service';
import { QuestionService } from '@app/core/services/question.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-card-sort-score-position',
  templateUrl: './card-sort-score-position.component.html',
  styleUrls: ['./card-sort-score-position.component.scss'],
})
export class CardSortScorePositionComponent implements OnInit {
  @Input() question: Question;
  answersPositionScore: CardSortScorePosition[] = [];
  isEdit: boolean;
  form: FormGroup;

  constructor(
    private answerService: AnswerService,
    private questionService: QuestionService,
    private snackBar: SnackBarService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.answerService.getAllByQuestionId(this.question.id).subscribe((answers: Answer[]) => {
      this.question.answers = answers;

      this.questionService.getAllCardSortScorePositionById(this.question.id).subscribe(
        (cardSortScorePositions: CardSortScorePosition[]) => {
          if (cardSortScorePositions.length) {
            cardSortScorePositions.push(cardSortScorePositions.shift());
            this.answersPositionScore = cardSortScorePositions;
          } else {
            this.question.answers.forEach((v, i) =>
              this.answersPositionScore.push({
                questionId: this.question.id,
                position: i + 1,
                score: parseFloat(Math.sqrt(i).toFixed(6)),
              }),
            );
            this.answersPositionScore.push({
              questionId: this.question.id,
              position: -1,
              score: parseFloat(Math.sqrt(this.question.answers.length).toFixed(6)),
            });
          }
          this.form = this.answersPositionScoreToFormGroup();
        },
        () => {
          this.snackBar.info('Something went wrong.');
        },
      );
    });
  }

  answersPositionScoreToFormGroup(): FormGroup {
    const group: any = {};
    this.answersPositionScore.forEach((answerPositionScore) => {
      group[answerPositionScore.position] = new FormControl(answerPositionScore.score, Validators.required);
    });
    return new FormGroup(group);
  }

  onEdit() {
    this.isEdit = true;
  }

  onReset() {
    // Show dialog
    const deleteDialog = this.dialog.open(DeleteDialogComponent);
    deleteDialog.componentInstance.message = `Resetting the question score will restore default values. Are you sure?`;
    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.questionService.deleteAllCardSortScorePositionById(this.question.id).subscribe(
          () => {
            this.answersPositionScore = [];

            this.question.answers.forEach((v, i) => {
              this.answersPositionScore.push({
                questionId: this.question.id,
                position: i + 1,
                score: parseFloat(Math.sqrt(i).toFixed(6)),
              });
              if (i + 1 === this.question.answers.length) {
                this.answersPositionScore.push({
                  questionId: this.question.id,
                  position: -1,
                  score: parseFloat(Math.sqrt(this.question.answers.length).toFixed(6)),
                });
              }
            });
            this.answersPositionScore.forEach((answerPositionScore) => {
              this.getControlByPosition(answerPositionScore.position).setValue(answerPositionScore.score);
            });
            this.snackBar.info('Question scores have been reset successfully.');
          },
          () => {
            this.snackBar.info('Something went wrong.');
          },
        );
      }
    });
  }

  onCancel() {
    this.isEdit = false;
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    Object.keys(this.form.controls).forEach((position) => {
      this.answersPositionScore.find((answerPositionScore) => answerPositionScore.position === +position).score =
        this.form.controls[position].value;
    });
    this.questionService.updateAllCardSortScorePositionById(this.question.id, this.answersPositionScore).subscribe(
      (answersPositionScore: CardSortScorePosition[]) => {
        answersPositionScore.push(answersPositionScore.shift());
        this.answersPositionScore = answersPositionScore;
        this.isEdit = false;
        this.snackBar.info('Question scores have been updated successfully.');
      },
      () => {
        this.snackBar.info('Something went wrong.');
      },
    );
  }

  getControlByPosition(position: any): any {
    return this.form.controls[position];
  }
}
