import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EVENT_SUCCESS } from '@app/core/constants';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-psb-role-mandate-add-user-dialog',
  templateUrl: './psb-role-mandate-add-user-dialog.component.html',
  styleUrls: ['./psb-role-mandate-add-user-dialog.component.scss'],
})
export class PsbRoleMandateAddUserDialogComponent implements OnInit {
  roleNavigators: any[] = [];
  roleNavigatorControl = new FormControl('');
  filteredRoleNavigators: Observable<RoleMandate[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { roleNavigators: RoleMandate[] },
    private matDialog: MatDialogRef<PsbRoleMandateAddUserDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.roleNavigators = this.data.roleNavigators.length ? this.data.roleNavigators : [];

    this.filteredRoleNavigators = this.roleNavigatorControl.valueChanges.pipe(
      startWith(''),
      map((roleNavigator) => (roleNavigator ? this._filterRoleNavigator(roleNavigator) : this.roleNavigators.slice())),
    );
  }

  onCancel() {
    this.matDialog.close();
  }

  onAddRoleNavigator() {
    this.matDialog.close({ event: EVENT_SUCCESS, roleMandateId: this.roleNavigatorControl.value.id });
  }

  displayRoleNavigator(roleNavigator: RoleMandate): string {
    return roleNavigator ? roleNavigator.title : '';
  }

  private _filterRoleNavigator(value: string): RoleMandate[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.roleNavigators;
    }

    if (typeof filterValue === 'number') {
      return ;
    }

    return this.roleNavigators.filter((roleNavigator: RoleMandate) => {
      if (roleNavigator) {
        return roleNavigator.title.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }
}
