import { Component, Input } from '@angular/core';

// models
import { Section } from '@app/core/models/section.model';

@Component({
  selector: 'app-subsection-container',
  templateUrl: './subsection-container.component.html',
  styleUrls: ['./subsection-container.component.scss'],
})
export class SubsectionContainerComponent {
  @Input() section: Section;

  constructor() {}
}
