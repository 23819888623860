<div class="container-fluid">
  <span *ngIf="this.test" class="psb-page-navigator"
    >Test > <b>{{ test.title }}</b> >
    {{ tabs[+selected.value] }}
  </span>

  <mat-tab-group
    *ngIf="this.test"
    mat-align-tabs="start"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="tabChange($event)"
  >
    <mat-tab label="Details">
      <button mat-raised-button color="primary" class="pull-right" (click)="onEdit()">Edit</button>

      <div class="row">
        <div class="column">
          <span class="label">Title</span>
          <p>{{ test.title }}</p>
          <span class="label">Description</span>
          <p>{{ test.description }}</p>
          <span class="label">Version</span>
          <p>{{ test.version }}</p>
          <span class="label">Status</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip color="{{ active ? 'primary' : 'warn' }}" selected>
              {{ active ? 'Active' : 'Inactive' }}
            </mat-chip>
          </mat-chip-list>
          <span class="label">Start Page Instruction</span>
          <p [innerHTML]="test.startPageInstructions"></p>
          <span class="label">Warning Instruction</span>
          <p [innerHTML]="test.warningInstructions"></p>
          <span class="label">Paging</span>
          <p>{{ test.paging }}</p>
          <span class="label">Answers Required</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="this.answersRequired" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="!this.answersRequired" color="warn" selected> No </mat-chip>
          </mat-chip-list>
          <span class="label">Test Time(mins)</span>
          <p>{{ test.testTime }}</p>
          <span class="label">Randomize Order</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="this.randomizeOrder" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="!this.randomizeOrder" color="warn" selected> No </mat-chip>
          </mat-chip-list>
          <span class="label">Scoring Required</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="this.scoringRequired" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="!this.scoringRequired" color="warn" selected> No </mat-chip>
          </mat-chip-list>
          <span class="label">Instructions on top of every page</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="this.everyPageInstructionEnabled" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="!this.everyPageInstructionEnabled" color="warn" selected> No </mat-chip>
          </mat-chip-list>
          <span class="label">Date Added</span>
          <p>{{ this.dateAdded }}</p>
          <span class="label">Custom Questions Order</span>
          <mat-chip-list aria-label="Custom Questions Order">
            <mat-chip *ngIf="this.test?.customQuestionOrder" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="!this.test?.customQuestionOrder" color="warn" selected> No </mat-chip>
          </mat-chip-list>
          <span class="label">Show Card Sort Instructions</span>
          <mat-chip-list aria-label="Custom Questions Order">
            <mat-chip *ngIf="this.test?.showCardSortInstructions" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="!this.test?.showCardSortInstructions" color="warn" selected> No </mat-chip>
          </mat-chip-list>
          <span class="label">Discard All Cards</span>
          <mat-chip-list aria-label="Discard All Cards">
            <mat-chip *ngIf="this.test?.discardAll" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="!this.test?.discardAll" color="warn" selected> No </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Subscales">
      <app-test-subscale-list [testId]="testId"></app-test-subscale-list>
    </mat-tab>

    <mat-tab label="Custom Questions Order">
      <ng-template matTabContent>
        <app-custom-questions-order
          *ngIf="test.customQuestionOrder; else customQuestionsOrderDisabled"
          [psyTest]="test"
        ></app-custom-questions-order>
        <ng-template #customQuestionsOrderDisabled>
          <p>You must enable the Custom Questions Order test parameter to use this feature.</p>
        </ng-template>
      </ng-template>
    </mat-tab>

    <mat-tab label="Preview">
      <ng-template matTabContent>
        <app-test-preview
          [psyTestId]="testId"
          [startPageInstructions]="test.startPageInstructions"
          [warningInstructions]="test.warningInstructions"
          [paging]="test.paging"
          [toolTipType]="toolTipType"
        ></app-test-preview>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
