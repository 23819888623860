import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// services
import { SearchService } from '@app/shared/components/search/search.service';

// models
import { SearchDecisionTableAnswer } from '@app/core/models/search-decision-table-answer.model';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit {
  testId: number;
  private phrase: string;
  searchResults: SearchDecisionTableAnswer[] = [];
  searchForm = new FormGroup({
    phrase: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });

  constructor(private route: ActivatedRoute, private searchService: SearchService, private router: Router) {}

  ngOnInit(): void {
    this.testId = this.route.snapshot.params['id'];
    this.phrase = decodeURIComponent(this.route.snapshot.params['phrase']);
    this.search();
  }

  search() {
    if (this.phrase) {
      this.searchService
        .findAllRoleMandateDecision({ phrase: this.phrase, roleMandateId: this.testId })
        .subscribe((result) => {
          this.searchResults = result;
        });
    }
  }

  onSearch() {
    if (this.searchForm.valid) {
      this.phrase = this.searchForm.get('phrase').value;
      this.search();
      return this.router.navigate([`/user/role-mandate/${this.testId}/search/${this.phrase}`]);
    }
  }
}
