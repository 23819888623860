import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { StateRoutingModule } from './state.routing.module';

import { StateDetailComponent } from './state-detail/state-detail.component';
import { StateModalComponent } from './state-modal/state-modal.component';

@NgModule({
  declarations: [StateDetailComponent, StateModalComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, StateRoutingModule],
  exports: [StateDetailComponent, StateModalComponent],
})
export class StateModule {}
