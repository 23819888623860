<div class="container">
  <div class="search-header">
    <h1>Decisions Search</h1>
    <h2 class="test-title">{{ search.test.title }}</h2>
  </div>
  <div class="form">
    <form (submit)="onSearch()" [formGroup]="searchForm">
      <mat-form-field>
        <mat-label>Decision</mat-label>
        <input matInput formControlName="phrase" placeholder="Ex. product design" />
        <mat-icon matSuffix color="primary" (click)="onSearch()">search</mat-icon>
        <mat-error *ngIf="searchForm.get('phrase').errors"
          >Please, enter a valid text with at least 3 characters.</mat-error
        >
      </mat-form-field>
    </form>
    <p>Search for the Decisions on your company</p>
  </div>
</div>
