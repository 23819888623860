import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

// models
import { PsbTableItem } from '@app/core/models/psb-table-item.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-psb-list-table',
  templateUrl: './psb-list-table.component.html',
  styleUrls: ['./psb-list-table.component.scss'],
})
export class PsbListTableComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  items: MatTableDataSource<PsbTableItem> = new MatTableDataSource<PsbTableItem>();

  @Output() delete = new EventEmitter<number>();
  @Output() sort = new EventEmitter<CdkDragDrop<any, any>>();
  @Output() edit = new EventEmitter<number>();
  @Output() view = new EventEmitter<number>();
  @Output() copyEvent = new EventEmitter<number>();

  @Input() itemName: string;
  @Input() isSearch: boolean;
  @Input() itemsPerPage: number;
  @Input() displayedColumns: string[];
  @Input() allItem: PsbTableItem[];
  @Input() isLinkable: boolean;
  @Input() actions: string;

  constructor(private changeDetectorRef: ChangeDetectorRef, private router: Router) {}

  ngOnChanges() {
    this.refreshDataSource();
  }

  ngOnInit(): void {
    this.items = new MatTableDataSource(this.allItem);
    this.items.paginator = this.paginator;
  }

  onSearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.items.filter = filterValue;
  }

  onView(id: number) {
    this.view.emit(id);
  }

  onEdit(id: number) {
    this.edit.emit(id);
  }

  onCopy(id: number) {
    this.copyEvent.emit(id);
  }

  onDelete(id: number) {
    this.delete.emit(id);
  }

  refreshDataSource() {
    this.items.data = this.allItem;
    this.items.paginator = this.paginator;
    this.changeDetectorRef.detectChanges();
  }

  onNavigateToUrl(row) {
    this.router.navigate([`/subscale/${row.id}/detail`]);
  }

  onDeleteByUser(row) {
    this.delete.emit(row);
  }

  onSort($event: CdkDragDrop<any, any>) {
    this.sort.emit($event);
  }
}
