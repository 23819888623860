import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Question } from '@app/core/models/question.model';
import { QuestionGroup } from '@app/core/models/question-group.model';
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { ViewportScroller } from '@angular/common';
import { TestService } from '@app/core/services/test.service';
import { TestObjectService } from '@app/core/services/test-object.service';
import { ChangeDetectorRef } from '@angular/core';
import { PsyTest } from '@app/core/models/psy-test.model';
import { forkJoin } from 'rxjs';
import { SuperTestService } from '@app/core/services/super-test.service';
import { CustomQuestionOrder } from '@app/core/models/custom-question-order.model';

@Component({
  selector: 'app-test-preview',
  templateUrl: './test-preview.component.html',
  styleUrls: ['./test-preview.component.scss'],
})
export class TestPreviewComponent implements OnInit, AfterContentChecked {
  @Input() psyTestId: number;
  @Input() superTestId: number;
  @Input() startPageInstructions: string;
  @Input() warningInstructions: string;
  @Input() paging: number;
  @Input() toolTipType: string;
  psyTest: PsyTest;
  config: any;
  allObj: any[];
  questionGroups: QuestionGroup[];
  allQuestionGroups: QuestionGroup[];
  questions: Question[];
  questionNumber = 1;
  everyPageInstructionsEnabled: boolean;
  customQuestionsOrder: CustomQuestionOrder[];

  constructor(
    private route: ActivatedRoute,
    private testService: TestService,
    private cdref: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
    private questionGroupService: QuestionGroupService,
    public testObjectService: TestObjectService,
    public superTestService: SuperTestService,
  ) {}

  ngOnInit(): void {
    this.questionGroupService.getAll().subscribe((questionGroups) => (this.allQuestionGroups = questionGroups));

    if (this.superTestId) {
      this.superTestService.getAllSuperTestTestById(this.superTestId).subscribe(async (superTestTests) => {
        if (superTestTests) {
          const allQuestionGroup: any[] = [];
          const allQuestion: any[] = [];
          if (superTestTests.length) {
            if (superTestTests.some((test) => <boolean>test.everyPageInstructionsEnabled)) {
              this.everyPageInstructionsEnabled = true;
            } else {
              this.everyPageInstructionsEnabled = false;
            }
          }
          for (const superTestTest of superTestTests) {
            await forkJoin([
              this.questionGroupService.getAllQuestionGroupForPsyTest(superTestTest.psyTestId),
              this.testService.getAllQuestionByPsyTestId(superTestTest.psyTestId),
            ])
              .toPromise()
              .then((result) => {
                if (result) {
                  for (const it of result[0]) {
                    allQuestionGroup.push(it);
                  }

                  for (const it of result[1]) {
                    allQuestion.push(it);
                  }
                }
              });
          }

          this.getAllObjAndConfig(allQuestionGroup, allQuestion);
        }
      });
    } else {
      forkJoin([
        this.testService.getPsyTestById(this.psyTestId),
        this.questionGroupService.getAllQuestionGroupForPsyTest(this.psyTestId),
        this.testService.getAllQuestionByPsyTestId(this.psyTestId),
        this.testService.getCustomQuestionsOrderById(this.psyTestId),
      ]).subscribe((results) => {
        this.psyTest = results[0];
        this.everyPageInstructionsEnabled = <boolean>this.psyTest.everyPageInstructionsEnabled;
        this.setToolTipForCardSort(this.psyTest.title);
        this.customQuestionsOrder = results[3];
        this.getAllObjAndConfig(results[1], results[2]);
      });
    }
  }

  setToolTipForCardSort(title: string) {
    if (!title.includes('360')) {
      this.toolTipType = 'cardSort';
      return;
    }

    if (title.includes('360') && title.toLocaleLowerCase().includes('self')) {
      this.toolTipType = 'cardSort';
      return;
    }

    if (title.includes('360') && !title.includes('self')) {
      this.toolTipType = 'cardSort 360';
      return;
    }

    if (title.includes('360')) {
      this.toolTipType = 'cardSort 360';
      return;
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  isQuestion(obj: any): boolean {
    return obj.networkType === 'question';
  }

  isQuestionsGroup(obj: any): boolean {
    return obj.networkType === 'questionGroup';
  }

  getAllObjAndConfig(questionGroup: QuestionGroup[], questions: Question[]) {
    if (this.psyTest?.customQuestionOrder) {
      this.questions = questions;
      this.questions = this.testService.applyCustomOrder(this.questions, this.customQuestionsOrder);
      questionGroup = [];
    }
    this.questionGroups = questionGroup;

    this.questions = questions.filter(
      (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
    );

    this.questions.forEach((question) => {
      if (question.instructions?.length === 0 && question.questionGroupId) {
        question.instructions = this.allQuestionGroups.filter(
          (qG) => qG.id === question.questionGroupId,
        )[0].instructions;
      }
    });

    if (this.psyTest && this.psyTest.randomizeOrder && !this.psyTest.customQuestionOrder) {
      const allowedObjects = this.questions.filter((q) => q.randomAllowed);
      allowedObjects.sort(() => Math.random() - 0.5);
      let allowedIndex = 0;
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].randomAllowed) {
          this.questions[i] = allowedObjects[allowedIndex];
          allowedIndex++;
        }
      }
    }

    this.questions = this.questions.sort((a, b) => {
      return a.aorder - b.aorder;
    });

    this.questionGroups.forEach((group) => {
      group.questions = questions.filter((question) => question.questionGroupId === group.id);

      if (group.questions.length === 0) {
        this.questionGroups = this.questionGroups.filter((qG) => qG.id !== group.id);
      }
    });

    this.questionGroups = this.questionGroups.sort((a, b) => {
      return a.aorder - b.aorder;
    });
    this.allObj = [
      ...this.questionGroups.map((obj) => {
        obj.networkType = 'questionGroup';

        obj.questions.forEach((question) => {
          question.questionNumber = this.questionNumber;
          this.questionNumber++;
        });
        return obj;
      }),
      ...this.questions.map((obj) => {
        obj.networkType = 'question';

        obj.questionNumber = this.questionNumber;
        this.questionNumber++;
        return obj;
      }),
    ];

    this.allObj = this.allObj.sort((a, b) => {
      return a.aorder - b.aorder;
    });

    this.questionNumber = 1;

    this.allObj.map((e) => {
      if (e.hasOwnProperty('questions')) {
        e.questions.map((f) => {
          f.questionNumber = this.questionNumber;
          this.questionNumber++;
        });
      } else {
        e.questionNumber = this.questionNumber;
        this.questionNumber++;
      }
    });

    this.config = {
      id: 'preview-paginator',
      itemsPerPage: this.paging,
      currentPage: 1,
      totalItems: this.allObj.length,
    };
  }
}
