<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> {{ questionGroup.title }}</mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngIf="!showEdit" class="row">
      <div></div>
      <div class="actions">
        <button mat-raised-button color="primary" (click)="onEdit()">Edit</button>
        <button mat-button class="btn-delete" (click)="onDelete(questionGroup.id)">Delete</button>
      </div>
    </div>

    <div *ngIf="questionGroup.instructions && !showEdit" [innerHTML]="questionGroup.instructions"></div>

    <app-edit-question-group *ngIf="showEdit" [questionGroup]="questionGroup" (edit)="onEditResponse($event)">
    </app-edit-question-group>
  </mat-expansion-panel>
</mat-accordion>
