import { Component, Input, OnInit } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-paragraph-section',
  templateUrl: './paragraph-section.component.html',
  styleUrls: ['./paragraph-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParagraphSectionComponent implements OnInit {
  @Input() paragraph: any;

  constructor() {}

  ngOnInit(): void {
    this.paragraph.leadSentence.he =
      this.paragraph.leadSentence.he !== undefined
        ? this.paragraph.leadSentence.he !== 'null'
          ? this.paragraph.leadSentence.he
          : ''
        : '';
  }
}
