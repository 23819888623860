<div *ngIf="charts && charts.length > 0; else noCharts">
  <div *ngFor="let chart of charts">
    <h2 class="chart-title">{{ chart.header }}</h2>
    <div [ngSwitch]="chart.base">
      <app-hexagon
        *ngSwitchCase="'HEXAGON'"
        [visible]="chart.visible"
        [chartId]="chart.id"
        [userId]="userInfoId"
        [testGroupId]="testGroupId"
      >
      </app-hexagon>

      <app-square-plot
        *ngSwitchCase="'CIRCLE_IN_CARTESIAN'"
        [visible]="chart.visible"
        [chartId]="chart.id"
        [userId]="userInfoId"
        [testGroupId]="testGroupId"
      >
      </app-square-plot>

      <app-circular-plot
        *ngSwitchCase="'FOUR_PLUS_ONE_CARTESIAN'"
        [visible]="chart.visible"
        [chartId]="chart.id"
        [userId]="userInfoId"
        [testGroupId]="testGroupId"
      >
      </app-circular-plot>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>
<ng-template #noCharts>No chart results</ng-template>
