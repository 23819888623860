import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { DepartmentModalComponent } from '../department-modal/department-modal.component';

// services
import { CompanyService } from '@app/core/services/company.service';

// models
import { Department } from '@app/core/models/department.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-department-list-table',
  templateUrl: './department-list-table.component.html',
  styleUrls: ['./department-list-table.component.scss'],
})
export class DepartmentListTableComponent implements OnInit {
  @Input() companyId: number;
  @ViewChild(MatPaginator, { static: true }) paginatorDepartments: MatPaginator;
  departments: MatTableDataSource<Department> = new MatTableDataSource<Department>();
  allDepartment = [];
  displayedDepartmentsColumns: string[] = ['name', 'city', 'zipCode', 'address', 'state', 'actions'];

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.companyService.getDepartmentsById(this.companyId).subscribe((res) => {
      if (res) {
        res.forEach((data) => {
          const department = this.buildDepartment(data);
          this.allDepartment.push(department);
        });

        this.departments.data = this.allDepartment;
        this.departments.paginator = this.paginatorDepartments;
      }
    });
  }

  createNewDepartment() {
    const createNewDialog = this.dialog.open(DepartmentModalComponent, {
      data: { isEdit: false, companyId: this.companyId },
    });

    createNewDialog.afterClosed().subscribe((result) => {
      if (result?.event === 'save') {
        this.companyService.saveDepartmentById(this.companyId, result.data).subscribe((response) => {
          if (response) {
            this.allDepartment = [];
            this.companyService.getDepartmentsById(this.companyId).subscribe((res) => {
              res.forEach((data) => {
                const department = this.buildDepartment(data);
                this.allDepartment.push(department);
              });

              this.refreshDataSource();
            });
          }
        });
      }
    });
  }

  onEdit(department: Department) {
    const editDialog = this.dialog.open(DepartmentModalComponent, {
      data: { isEdit: true, companyId: this.companyId, departmentId: department.id },
    });

    editDialog.afterClosed().subscribe((result) => {
      if (result?.event === 'edit') {
        this.companyService.updateDepartmentById(this.companyId, department.id, result.data).subscribe((res) => {
          if (res) {
            this.allDepartment = [];
            this.companyService.getDepartmentsById(this.companyId).subscribe((response) => {
              response.forEach((data) => {
                this.allDepartment.push(this.buildDepartment(data));
              });

              this.refreshDataSource();
            });
          }
        });
      }
    });
  }

  onDelete(department: Department) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result?.event !== EVENT_CANCEL) {
        this.companyService.deleteDepartmentByDepartmentId(this.companyId, department.id).subscribe((response) => {
          this.allDepartment = this.allDepartment.filter((data) => data.id !== department.id);
          this.refreshDataSource();
        });
      }
    });
  }

  onStateDetail(department: Department) {
    if (department.stateId) {
      this.router.navigate([`/state/${department.stateId}/detail`]);
    }
  }

  refreshDataSource() {
    this.departments.data = this.allDepartment;
    this.departments.paginator = this.paginatorDepartments;
    this.changeDetectorRef.detectChanges();
  }

  buildDepartment(data: Department) {
    const department = {
      id: data.id,
      name: data.name,
      city: data.city,
      zipCode: data.zipCode,
      address: data.address,
      stateId: data.stateId,
      state: data['state'],
    };

    return department;
  }
}
