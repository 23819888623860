import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserService } from '../../services/user.service';
import { ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TEST_USER, ROLE_TGM } from '../../constants';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
})
export class LayoutHeaderComponent implements OnInit, OnDestroy {
  @Output() toggleEmitter = new EventEmitter<boolean>();

  userIsAuthenticated = false;
  isAdmin = false;
  homeUrl = '/dashboard';
  visibleMenu = false;
  toggled = true;

  isAuthenticated$: Subscription;

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this.userService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.userIsAuthenticated = isAuthenticated;

      if (this.userIsAuthenticated) {
        const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
        if (
          authorities.includes(ROLE_ADMIN) ||
          authorities.includes(ROLE_TGM) ||
          authorities.includes(ROLE_ORGANIZATIONAL_ADMIN)
        ) {
          this.visibleMenu = this.router.url !== '/';
          this.isAdmin = authorities.includes(ROLE_ADMIN);
          this.homeUrl = '/admin';
        } else if (authorities.includes(ROLE_TEST_USER)) {
          this.visibleMenu = false;
          this.homeUrl = '/dashboard';
        } else {
          this.visibleMenu = false;
          this.homeUrl = '/';
        }
      }
    });
  }

  onLogout() {
    this.userService.logout();
  }

  toggle() {
    this.toggled = !this.toggled;
    this.toggleEmitter.emit(this.toggled);
  }

  ngOnDestroy(): void {
    this.isAuthenticated$.unsubscribe();
  }
}
