import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
})
export class NotesDialogComponent implements OnInit {
  notesControl: any;
  isEdit: boolean;

  constructor(
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { notes: string; textAnswerAnalysesId: number },
  ) {}

  ngOnInit(): void {
    this.data.textAnswerAnalysesId ? (this.isEdit = true) : (this.isEdit = false);
    this.notesControl = new FormControl(this.data.textAnswerAnalysesId ? this.data.notes : null);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.dialogRef.close(this.notesControl.value);
  }
}
