import { Component, OnInit } from '@angular/core';
import { PsbItem } from '@app/core/models/psb-item.model';
import { UserInfo } from '@app/core/models/userInfo.model';
import { Router } from '@angular/router';
import { TestGroupService } from '@app/core/services/test-group.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@app/core/services/user.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { EVENT_CANCEL, ROLE_ADMIN, ROLE_TGM } from '@app/core/constants';
import { TestGroupModalComponent } from '@app/shared/components/test-group-modal/test-group-modal.component';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';

@Component({
  selector: 'app-test-group-list',
  templateUrl: './test-group-list.component.html',
  styleUrls: ['./test-group-list.component.scss'],
})
export class TestGroupListComponent implements OnInit {
  testGroups: PsbItem[] = [];
  userData: UserInfo;
  role: string;

  constructor(
    private router: Router,
    private testGroupService: TestGroupService,
    public dialog: MatDialog,
    private userService: UserService,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.userData = this.userService.getUserData();
    this.role = this.userData.authorities.find((authority) => authority.name === ROLE_ADMIN) ? ROLE_ADMIN : ROLE_TGM;
    this.loadAllTestGroup();
  }

  onView(testGroupId: number) {
    return this.router.navigate([`/psy-test-group/${testGroupId}/details`]);
  }

  onDelete(testGroupId: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.testGroupService.delete(testGroupId).subscribe((res) => {
          if (res) {
            this.snackBar.info('Test group deleted with success.');
            this.testGroups = this.testGroups.filter((data) => data.id !== testGroupId);
          }
        });
      }
    });
  }

  onViewTestResult(testGroupId: number) {
    return this.router.navigate([`/psy-test-group/${testGroupId}/test-result-list-view`]);
  }

  createNewTestGroup() {
    const createNewDialog = this.dialog.open(TestGroupModalComponent, {
      data: { isEdit: false, companyId: null },
    });

    createNewDialog.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.testGroupService.save(result.data).subscribe((res: PsyTestGroup) => {
          this.snackBar.info('Test group created with success.');
          return this.onView(res.id);
        });
      }
    });
  }

  private loadAllTestGroup() {
    this.testGroupService.getAll().subscribe((result) => {
      this.testGroups = result.map((testGroup) => {
        if (testGroup) {
          return {
            id: testGroup.id,
            title: testGroup.title,
            description: testGroup.description,
            status: testGroup.status,
            extraColumns: [
              {
                key: 'Company Name',
                value: testGroup.companyName,
              },
              {
                key: 'Date Created',
                value: new Date(testGroup.dateCreated).toLocaleDateString('en-US'),
              },
            ],
          };
        }
      });
    });
  }
}
