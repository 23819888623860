import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PsyChart } from '@app/core/models/psy-chart.model';
import { EditChartDialogComponent } from '@app/admin/pages/chart/edit-chart-dialog/edit-chart-dialog.component';
import { BlossomChartPetal, BlossomChartPistil, BlossomChartService } from '../../../../../../../generated/v2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscale } from '@app/core/models/subscale.model';
import { hexToColor } from '@app/admin/pages/chart/blossom/hex-to-color';
import { SubscaleService } from '@app/core/services/subcale.service';

@Component({
  selector: 'app-blossom-pistil-form',
  templateUrl: './blossom-pistil-form.component.html',
  styleUrls: ['./blossom-pistil-form.component.scss'],
})
export class BlossomPistilFormComponent implements OnInit {
  form = new FormGroup({
    id: new FormControl(null),
    color: new FormControl(null, [Validators.required]),
    label: new FormControl(null, [Validators.required]),
    petalId: new FormControl(null, [Validators.required]),
    order: new FormControl(1, [Validators.required]),
    subscaleId: new FormControl(null, [Validators.required]),
    isReverse: new FormControl(false, [Validators.required]),
  });

  subscales: Subscale[];
  petals: BlossomChartPetal[];

  constructor(
    private dialogRef: MatDialogRef<BlossomPistilFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { pistil: BlossomChartPistil; petals: BlossomChartPetal[]; subscales: Subscale[] },
  ) {}

  ngOnInit(): void {
    this.subscales = this.data.subscales;
    this.petals = this.data.petals;

    if (this.data.pistil) {
      this.form.patchValue({
        id: this.data.pistil.id,
        color: hexToColor(this.data.pistil.color),
        label: this.data.pistil.label,
        petalId: this.data.pistil.petalId,
        order: this.data.pistil.order,
        subscaleId: this.data.pistil.subscaleId,
        isReverse: this.data.pistil.isReverse,
      });
    }
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close({
        value: {
          ...this.form.value,
          color: `#${this.form.value.color.toHex()}`,
        },
      });
    }
  }

  displaySubscaleFn = (id: number): string => {
    const subscale = this.subscales.find((s) => s.id === id);

    if (subscale) {
      return subscale.title;
    }

    return '';
  };

  filterSubscales(value: string): Subscale[] {
    return this.subscales.filter(
      (subscale) =>
        subscale.title.toLowerCase().includes(value.toLowerCase()) ||
        subscale.description?.toLowerCase().includes(value.toLowerCase()),
    );
  }
}
