import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UserInfo } from '@app/core/models/userInfo.model';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { TestGroupChartPreviewComponent } from '../test-group-chart-preview/test-group-chart-preview.component';
import { PsyOptionsDialogComponent } from '@app/shared/components/psy-options-dialog/psy-options-dialog.component';

// services
import { ChartService } from '@app/core/services/chart.service';
import { TestGroupService } from '@app/core/services/test-group.service';
import { UserService } from '@app/core/services/user.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { PsyChart } from '@app/core/models/psy-chart.model';
import { PsbOptionItem } from '@app/core/models/psb-option-item.model';

// constants
import { EVENT_CANCEL, ROLE_ADMIN } from '@app/core/constants';

@Component({
  selector: 'app-test-group-chart-list-table',
  templateUrl: './test-group-chart-list-table.component.html',
  styleUrls: ['./test-group-chart-list-table.component.scss'],
})
export class TestGroupChartListTableComponent implements OnInit {
  addChartDialog: MatDialogRef<any>;

  @Input() testGroupId: number;
  testGroupCharts = new MatTableDataSource<PsyChart>([]);
  displayedTestGroupChartsColumns = ['name', 'actions', 'visible'];
  charts: PsbOptionItem[] = [];
  isAdmin: boolean = false;
  userData: UserInfo;

  constructor(
    private chartService: ChartService,
    private testGroupService: TestGroupService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private userService: UserService,
    private router: Router,
    private snackBar: SnackBarService,
    private companyService: CompanyService,
  ) {
    this.userData = this.userService.getUserData();
    const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
    if (authorities.includes(ROLE_ADMIN)) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  ngOnInit(): void {
    this.loadAllChart();
  }

  loadAllChart() {
    if (this.isAdmin) {
      forkJoin([this.chartService.getAllByTestGroupId(this.testGroupId), this.chartService.getAll()]).subscribe(
        ([testGroupCharts, charts]) => {
          this.testGroupCharts.data = testGroupCharts;
          this.charts = [];

          this.refreshTableData();

          charts.forEach((chart) => {
            const item: PsbOptionItem = {
              id: chart.id,
              itemName: chart.name,
              description: chart.header,
            };
            let isChartAvailable = testGroupCharts.some((e) => e.id == item.id);
            if (!isChartAvailable) {
              this.charts.push(item);
            }
          });
        },
      );
    } else {
      forkJoin([
        this.chartService.getAllByTestGroupId(this.testGroupId),
        this.companyService.findAllChartAssignedToCompany(this.userData.companyId),
      ]).subscribe(([testGroupCharts, assignedCharts]) => {
        this.testGroupCharts.data = testGroupCharts;
        this.charts = [];

        this.refreshTableData();

        assignedCharts.forEach((assignedChart) => {
          const item: PsbOptionItem = {
            id: assignedChart.chartId,
            itemName: assignedChart.name,
            description: assignedChart.header,
          };
          let isChartAvailable = testGroupCharts.some((e) => e.id == item.id);
          if (!isChartAvailable) {
            this.charts.push(item);
          }
        });
      });
    }
  }

  onAddChart() {
    const addChartModal = this.dialog.open(PsyOptionsDialogComponent, {
      data: { items: this.charts, title: 'Add Chart to Test Group', formFieldName: 'Chart' },
    });

    addChartModal.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.testGroupService.addChartToTestGroup(this.testGroupId, result.data.item.id).subscribe(() => {
          this.loadAllChart();
          this.showCompleted('Chart is added to Test Group');
        });
      }
    });
  }

  onPreview(chart: PsyChart) {
    const data = {
      testGroupId: this.testGroupId,
      chart,
    };
    this.dialog.open(TestGroupChartPreviewComponent, {
      width: '750px',
      height: '600px',
      data,
    });
  }

  onSetup(chartId: number) {
    this.router.navigate(['/chart', chartId]);
  }

  onDelete(chartId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.testGroupService.removeChartFromTestGroup(this.testGroupId, chartId).subscribe(() => {
          this.testGroupCharts.data = this.testGroupCharts.data.filter((chart) => chart.id !== chartId);
          this.loadAllChart();
          this.showCompleted('Chart is deleted');
        });
      });
  }

  onToggleVisible(testGroupChart: PsyChart) {
    this.chartService
      .updateTestGroupChartVisible(this.testGroupId, testGroupChart.id, !testGroupChart.visible)
      .subscribe(
        () => (
          (this.testGroupCharts.data.find((tGC: PsyChart) => tGC.id === testGroupChart.id).visible =
            !testGroupChart.visible),
          this.showCompleted('Chart visible is updated')
        ),
      );
  }

  refreshTableData() {
    this.changeDetectorRef.detectChanges();
  }

  showCompleted(message: string) {
    this.snackBar.info(message);
  }
}
