<div id="main-generic-link" style="padding-top: 1px">
  <mat-card>
    <div id="test-takers-limit-reached" *ngIf="!isValidToken && testTakersLimitReached" class="text-center">
      <h1>Something went wrong</h1>
      <h2>The company has exceeded its number of users. Please contact the person who provided this link.</h2>
    </div>
    <div id="stepper" *ngIf="isValidToken">
      <mat-vertical-stepper linear #stepper (selectionChange)="onStepChange()">
        <mat-step>
          <ng-template matStepLabel>Are you new to our system?</ng-template>
          <button mat-raised-button (click)="isNewUser = true; goToStep(1)" style="width: 100%; margin-bottom: 10px">
            I'm new here
          </button>
          <button mat-raised-button (click)="isNewUser = false; goToStep(1)" style="width: 100%; margin-bottom: 10px">
            I already have an account here
          </button>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Please fill out the form.</ng-template>
          <div *ngIf="isNewUser">
            <form [formGroup]="newUserForm" (submit)="onSubmitNewUser()" autocomplete="off">
              <mat-form-field>
                <mat-label>First Name *</mat-label>
                <input matInput type="text" formControlName="firstName" />
                <mat-error *ngIf="newUserForm.get('firstName').invalid">Please enter the first name.</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Last Name *</mat-label>
                <input matInput type="text" formControlName="lastName" />
                <mat-error *ngIf="newUserForm.get('lastName').invalid">Please enter the last name.</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>E-mail *</mat-label>
                <input matInput type="text" formControlName="email" />
                <mat-error *ngIf="newUserForm.get('email').invalid">Please enter an e-mail.</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Gender *</mat-label>
                <mat-select formControlName="gender">
                  <mat-option value="FEMALE">Female</mat-option>
                  <mat-option value="MALE">Male</mat-option>
                  <mat-option value="OTHER">Other</mat-option>
                  <mat-option value="PREFER_NOT_TO_SAY">Prefer not to say</mat-option>
                </mat-select>
                <mat-error *ngIf="newUserForm.get('gender').invalid">Please choose a gender.</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
                <input
                  matInput
                  #password
                  [type]="toggle.type"
                  required
                  formControlName="password"
                  placeholder="Password"
                  (focus)="focusInPassword()"
                  (focusout)="focusOutPassword()"
                />
                <mat-hint align="end" aria-live="polite"> {{ password.value.length }} / 25</mat-hint>
                <mat-error *ngIf="newUserForm.get('password').invalid">Please enter a valid password.</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  type="password"
                  #passwordConfirmation
                  formControlName="passwordConfirmation"
                  placeholder="Password Confirmation *"
                  [ngClass]="{ 'is-invalid': submitted && formControls.passwordConfirmation.errors }"
                />
                <mat-error *ngIf="newUserForm.get('passwordConfirmation').invalid"
                  >Please enter the same password.</mat-error
                >
                <div *ngIf="submitted && formControls.passwordConfirmation.errors" class="invalid-feedback">
                  <div *ngIf="formControls.passwordConfirmation.errors.required">Confirm Password is required</div>
                  <div *ngIf="formControls.passwordConfirmation.errors.mustMatch">Passwords must match</div>
                </div>
              </mat-form-field>
              <button mat-raised-button type="submit" class="btn-primary btn-center" [disabled]="newUserForm.invalid">
                Create Account
              </button>
              <div class="strength-panel" [ngClass]="{ 'hide-password-info': !showWarningPanel }">
                <mat-password-strength
                  (onStrengthChanged)="onStrengthChanged($event)"
                  #passwordComponent
                  [password]="password.value"
                >
                </mat-password-strength>
                <mat-password-strength-info #passwordInfo [passwordComponent]="passwordComponent">
                </mat-password-strength-info>
              </div>
            </form>
          </div>
          <div *ngIf="!isNewUser">
            <form [formGroup]="loginForm" (ngSubmit)="onAuthenticate()">
              <div class="password-error-message" *ngIf="emailAlreadyRegistered">
                The email you entered is already registered in our system.<br />
                Please authenticate in our system using your credentials.
              </div>
              <mat-form-field>
                <mat-label>Login *</mat-label>
                <input matInput formControlName="login" placeholder="Enter your login" (input)="loginError = false" />
                <mat-error *ngIf="loginForm.get('login').invalid">Please, enter a valid username.</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Password *</mat-label>
                <input
                  matInput
                  formControlName="password"
                  [type]="hidePassword ? 'password' : 'text'"
                  placeholder="Enter your password"
                />
                <mat-icon
                  matSuffix
                  (click)="hidePassword = !hidePassword"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hidePassword"
                >
                  <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </mat-icon>
                <mat-error *ngIf="loginForm.get('password').invalid">Please, enter a valid password.</mat-error>
              </mat-form-field>
              <div class="password-error-message" *ngIf="loginError">
                The username/email or password you entered is incorrect.
              </div>
              <button
                mat-raised-button
                color="primary"
                type="submit"
                class="btn-primary btn-center"
                [disabled]="loginForm.invalid"
              >
                Login
              </button>
              <a routerLink="/recover-password">Forgot your password?</a>
            </form>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </mat-card>
</div>
