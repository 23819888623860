import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { SuperscaleCreateDialogComponent } from '../superscale-create-dialog/superscale-create-dialog.component';
import { ArchiveDialogComponent } from '@app/shared/components/archive-dialog/archive-dialog.component';

// services
import { SuperscaleService } from '@app/core/services/superscale.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-superscale-list',
  templateUrl: './superscale-list.component.html',
  styleUrls: ['./superscale-list.component.scss'],
})
export class SuperscaleListComponent implements OnInit {
  superscales: PsbItem[] = [];
  displayedSubscalesColumns: string[] = ['title', 'description'];

  constructor(
    private superscaleService: SuperscaleService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.loadAllSuperscale();
  }

  loadAllSuperscale() {
    this.superscales = [];
    this.superscaleService.getAll().subscribe((superscales) => {
      superscales.map((superscale) => {
        const item: PsbItem = {
          id: superscale.id,
          title: superscale.title,
          description: superscale.description,
        };
        this.superscales = [...this.superscales, item];
      });
    });
  }

  onCreate() {
    this.dialog
      .open(SuperscaleCreateDialogComponent, { data: { isEdit: false } })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          // Call service
          this.superscaleService.create(result.data).subscribe(() => {
            this.loadAllSuperscale();
            this.snackBar.info('Superscale created with success!');
          });
        }
      });
  }

  onView(superscaleId: number) {
    return this.router.navigate([`/superscale/${superscaleId}/detail`]);
  }

  onDelete(superscaleId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.superscaleService.delete(superscaleId).subscribe(() => {
          this.superscales = this.superscales.filter((subscale) => subscale.id !== superscaleId);
          this.snackBar.info('Superscale deleted with success!');
        });
      });
  }

  onArchive(superscaleId: number) {
    this.dialog
      .open(ArchiveDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.superscaleService.archive(superscaleId).subscribe(() => {
          this.superscales = this.superscales.filter((superscale) => superscale.id !== superscaleId);
          this.snackBar.info('Subscale archived with success!');
        });
      });
  }
}
