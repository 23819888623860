import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { InviteService } from '@app/core/services/invite.service';

// models
import { Question } from '@app/core/models/question.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { Node } from '@app/core/models/node.model';
import { UserInfo } from '@app/core/models/userInfo.model';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { PsbStatsLine } from '@app/core/models/psb-stats-line.model';

@Component({
  selector: 'app-role-mandate-dashboard',
  templateUrl: './role-mandate-dashboard.component.html',
  styleUrls: ['./role-mandate-dashboard.component.scss'],
})
export class RoleMandateDashboardComponent implements OnInit, OnDestroy {
  roleMandate: RoleMandate;
  questions: Question[];
  decisionTables: DecisionTable[];
  expertTableAnalysis: any[];
  globalTableAnalysis: any[];
  referentTableAnalysis: any[];
  roleMandateId: number;
  nodes: Node[];
  nodesLoaded = false;
  private initialSubscriptions: Subscription;
  selected = new FormControl(0);
  tabs: string[] = ['Analysis', 'Questions and Decision Tables', 'Users', 'Data Download', 'Invite E-mails'];
  psbStats: PsbStatsLine[];
  inviteAndReminderStats: PsbStatsLine[];
  users: UserInfo[];

  breakpoint = 2;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rMService: RoleMandateService,
    private inviteSerivce: InviteService,
  ) {}

  ngOnInit(): void {
    this.roleMandateId = Number(this.route.snapshot.params.id);
    this.breakpoint = window.innerWidth <= 1200 ? 1 : 2;
    this.initialSubscriptions = forkJoin([
      this.rMService.getById(this.roleMandateId),
      this.inviteSerivce.getAllInviteByRoleMandateId(this.roleMandateId),
    ]).subscribe((results) => {
      this.roleMandate = results[0];
      this.roleMandate.invitesButNotRegistered = results[1];
    });
    this.tabChange(0);
  }

  tabChange(event) {
    this.selected.setValue(event);
    if (event == 0) {
      this.loadTabAnalysisData();
    } else if (event == 1) {
      this.loadTabQuestionDecistionTableAnalysisData();
    } else if (event == 2) {
      this.loadTabUserData();
    }
  }

  openUser(userInfoId: number) {
    window.open(`/role-mandate/${this.roleMandateId}/user/${userInfoId}/results`, '_blank');
  }

  loadTabAnalysisData() {
    forkJoin([
      this.rMService.getAllNode(this.roleMandateId),
      this.rMService.getUnaForExpertAndReferentByByRoleMandate(this.roleMandateId),
      this.rMService.getNetworkAnalysisByRoleMandate(this.roleMandateId),
      this.inviteSerivce.getEmailSummaryByRoleMandateId(this.roleMandateId),
    ]).subscribe((results) => {
      this.nodes = results[0];
      this.nodesLoaded = true;

      this.referentTableAnalysis = results[1].filter((element) => element.networkType === 'REFERENT');
      this.expertTableAnalysis = results[1].filter((element) => element.networkType === 'EXPERT');
      this.globalTableAnalysis = results[2];

      this.psbStats = [
        {
          title: 'Invited',
          number:
            this.roleMandate && this.roleMandate.invitesButNotRegistered
              ? this.roleMandate.invitesButNotRegistered.length
              : 0,
          description: 'Total invited but not yet registered',
        },
        {
          title: 'Registered',
          number:
            this.roleMandate && this.roleMandate.usersInvited
              ? this.roleMandate.usersInvited - this.roleMandate.userCompleted - this.roleMandate.userStarted
              : 0,
          description: 'Total registered but not yet started',
        },
        {
          title: 'In progress',
          number: this.roleMandate && this.roleMandate.userStarted ? this.roleMandate.userStarted : 0,
          description: 'Total started but not yet completed',
        },
        {
          title: 'Completed',
          number: this.roleMandate && this.roleMandate.userCompleted ? this.roleMandate.userCompleted : 0,
          description: 'Total Completed',
        },
      ];

      this.setInviteAndReminderStats(results[3]);
    });
  }

  loadTabUserData() {
    this.rMService.getUsersComplete(this.roleMandateId).subscribe((result) => {
      this.users = result;
    });
  }

  loadTabQuestionDecistionTableAnalysisData() {
    this.initialSubscriptions = forkJoin([
      this.rMService.getAllQuestionById(this.roleMandateId),
      this.rMService.getAllDecisionTableById(this.roleMandateId),
    ]).subscribe((result) => {
      this.questions = result[0];
      this.decisionTables = result[1];
      console.log(this.decisionTables);
    });
  }

  setInviteAndReminderStats(emailSummary: any) {
    this.inviteAndReminderStats = [
      {
        title: 'Invites Sent Today',
        number: emailSummary.invitedSentByToday,
      },
      {
        title: 'Reminders Sent Today',
        number: emailSummary.remindSentByToday,
      },
      {
        title: 'Invites Sent this Week',
        number: emailSummary.invitedSentByWeek,
      },
      {
        title: 'Reminders Sent this Week',
        number: emailSummary.remindSentByWeek,
      },
      {
        title: 'Total Invites Sent',
        number: emailSummary.totalInvited,
      },
      {
        title: 'Total Reminders Sent',
        number: emailSummary.totalRemind,
      },
    ];
  }

  ngOnDestroy(): void {
    if (this.initialSubscriptions) {
      this.initialSubscriptions.unsubscribe();
    }
  }
}
