import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

// serivces
import { UserService } from '@app/core/services/user.service';
import { MfaEnrollService } from '@app/core/services/authentication.service';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-mfa-enroll-modal',
  templateUrl: './mfa-enroll-modal.component.html',
  styleUrls: ['./mfa-enroll-modal.component.scss'],
})
export class MfaEnrollModalComponent implements OnInit {
  userData: any;
  timeout: any;
  cellForm: FormGroup;
  emailForm: FormGroup;
  verifyForm: FormGroup;
  qrCode =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEsAQMAAABDsxw2AAAABlBMVEUAAADxBQUcfKDiAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAuIwAALiMBeKU/dgAABTpJREFUaN7t2c1t5TYQAGC+KABvoQsIQqSDFBBAtaSD7YASFMDHlJBWqPiQNviQBnTUgeBkZkjJ+iElbRbGbgB517AtfdazyJnhPErAlY9B3OxmN7sZfgSZZ04I7YWkA5p/NDAKVWB4chAP/LFBPApRYhoPoA0Cr4SsLjBFrAYvfUVM7xh+eu0lDGbA19PwgLEeVZ6FChmeHGpokXmZZ0DMK3AGLLJQYh2yoNCgHGsosZaYhB7oxWu8eJ71eBqv0eKJGll3zPD0eJHpc1bRD8z6IyZPWLrTiVUFlsaNmOJx6zNTP88CsqB4FrIs0NSP9IUZ/kKWpQiZ2JhnU7wlVoo3ikhkMDNTYDEXZgZfcjWNwT0YZ6YBGepCLii603oa3rGQMmncpsnyhZQBYn5mQR3nwhRIJ7kwhWX1ZUFuiVVzyrQF5oh1cwL2JWaQtXM62wIbiPVTccCj+SAf6oFEKjXI82wk5qbChRffh6UipomZWAaR1XnmieEIc1Gt8X+BKWI1UHwT03kWiNHfLviL37KLy8e97N7sZh/H5goE79X58xnWDPmfWVAfy+YD/2sWHolR7XcT83rDrIirSqAeW/yk6FwF7rFjihl15kH8kFgTu4clk8yoEfHiu8RSw/3O/sZ1lJirG1wP/oosyEFv77SNjBbV2sVb8Cp2D0vWM+u5YxkiwyZNraYer/1k1sFocA1MLHUPSzYwe8VzT/BnTILHdS8xXMFej1iYWbe9hcjw9uq3mcGONeIKo+aPmMA/oJsYhsHbmrnIwgmz11gTb+GMdYnR3RwweY2pedzoVHFALjOXJotCqjhZa5af+gWj346BBLtAYmZnFsMyyyQOcAxy6hgpyPHQJsjxe0w2ZhaCiSlDobdLmda/pAQcTUxAaDIJaMUvKZ2FiemMbJ/OTnxKxUGYWBzAZYrD8IizAPSOnUsN+E2pWdU4es/hpqPpLcO9Ot/sZh/IVn2Y15/B3rcIDhn2A1hXzAnztH9hhTxhvKtnxeOEudppXseOmQXeqbHmmLXA+z6uPmYV8C7ScMzofSxtXY0njJfOHka9YdzU0c5GT4yqZEuVeMvou4Z3QH/V8Yp9jlE3SFupXvyYmM0xIg1vpX6vgf8ml2MVDucftPVT/06szrMg+b08rgTGLtj2TuPmHA4CLpcTM/tx490hWuVx5BOjbY4hz54I6YWGxNxm6vHX35h1K2b3rE/Mz2wfb6NJ7HXFqizDoF6zbS5gx/HkRlseXo2Zndk0IG2ONStGw2tz7IGfCwb7cWMmd2zIMIyTmaVA2s4pDUjQ87hNYbmNkAzLBTlNVtDcca1SRu2nPrFxkYBB7QOJWQykKZ3T9tSWtSksp+IAch/k+AqPFORUaphV+5TBf4+UMlPhSluX6wSsxp9TAsYy2EOa+206/5bSORbVfnqLuS4OVjxTcYiPW3pIIbIuNa56plITC34/dXKLNk+mOjnt8z2urVn2GnPmElsuWgds1JeYV5fYcu/y7kNu9g2wwdzsW2fYdfDqI+ODkgILWG/pwCiQNVWJjcgsPwv8ZEAUa6/jXT6NX1+MXzws3DBr/uHFxoYXMypXKqot1b5Aj52F4WdtedZxJaUHxY3h54F5JtOzQIkv7yCcMXrE/lx11kvG+2a05Kv49OyY9bzjc8JiOzKYtzLTlxi3d9gq8fNAK4S8wJqvwbpi9F5k2wGBa8ML1yYLrk09XAgkd85iWBbZOsiLbJky2Kz8eSkBi7mwSWd9qTgUmV+WGlssDly4ZByZVYt0L7s3u9nNbvY12L+19QWD65GL+QAAAABJRU5ErkJggg==';
  resposeStatus = '';
  currentStatus = 'You are not currently enrolled in MFA';

  constructor(private mfaEnrollService: MfaEnrollService, private userService: UserService) {}

  ngOnInit(): void {
    this.userData = this.userService.getUserData();

    this.cellForm = new FormGroup({
      cellNumber: new FormControl({
        value: 'Error loading phone',
        disabled: true,
      }),
      sendTextButton: new FormControl(),
    });
    this.emailForm = new FormGroup({
      emailAddress: new FormControl({
        value: 'Error loading email',
        disabled: true,
      }),
    });
    this.verifyForm = new FormGroup({
      verifyCode: new FormControl({ value: '', disabled: false }),
    });

    // Create an MFA row if needed for this user
    this.mfaEnrollService.initializeMFA(this.userData.userInfoId).subscribe(
      (res) => {},
      (error) => {
        this.setResposonse('Error', error);
      },
    );

    // Fill in email, phone, and QR code
    this.mfaEnrollService.mfaQueryAttributes(this.userData.userInfoId).subscribe(
      (res) => {
        const phone = String(res['phone']);
        const email = String(res['email']);
        const mfa = String(res['mfa_app']);

        switch (String(res['auth_choice']).toLowerCase()) {
          case 'text':
            this.currentStatus = 'You are currently enrolled in text-based MFA';
            break;
          case 'email':
            this.currentStatus = 'You are currently enrolled in email-based MFA';
            break;
          case 'app':
            this.currentStatus = 'You are currently enrolled in app-based MFA';
            break;
          case 'none':
          case 'null':
          default:
            this.currentStatus = 'You are not currently enrolled in MFA';
            break;
        }

        let formattedPhone = phone;
        if (phone.length === 10) {
          formattedPhone = '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + ' - ' + phone.substr(6);
        } else if (phone.length === 11) {
          formattedPhone = phone[0] + ' - (' + phone.substr(1, 3) + ') ' + phone.substr(4, 3) + ' - ' + phone.substr(7);
        }

        this.cellForm.get('cellNumber').setValue(formattedPhone);
        this.emailForm.get('emailAddress').setValue(email);
        this.qrCode = mfa;
      },
      (error) => {
        this.setResposonse('Error', error);
      },
    );
  }

  setResposonse(title, body) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.resposeStatus = body;
      setTimeout(() => {
        this.resposeStatus = '';
      }, 8000);
    }, 0);
  }

  sendText() {
    this.mfaEnrollService.sendText(this.userData.userInfoId).subscribe(
      (res) => {
        if (res) {
          this.setResposonse(
            EVENT_SUCCESS,
            'A text has been sent.  Please be patient it may take some time to arrive.',
          );
        } else {
          this.setResposonse(
            'Error',
            'A text could not be sent.  Please try again later, or contact our help desk if you need immediate support.',
          );
        }
      },
      (error) => {
        this.setResposonse('Error', error.message);
      },
    );
  }
  sendEmail() {
    this.mfaEnrollService.sendEmail(this.userData.userInfoId).subscribe(
      (res) => {
        if (res) {
          this.setResposonse(
            EVENT_SUCCESS,
            'An email has been sent.  Please be patient it may take some time to arrive.',
          );
        } else {
          this.setResposonse(
            'Error',
            'An email could not be sent.  Please try again later, or contact our help desk if you need immediate support.',
          );
        }
      },
      (error) => {
        this.setResposonse('Error', error.message);
      },
    );
  }

  verifyCode() {
    this.mfaEnrollService
      .verifyAppCode(this.userData.userInfoId, String(this.verifyForm.get('verifyCode')['value']))
      .subscribe(
        (res) => {
          if (res) {
            this.setResposonse(EVENT_SUCCESS, 'Valid code.  You have been enrolled in app based MFA.');
          } else {
            this.setResposonse(
              'Error',
              'This code could not be verified.  Please try again later, or contact our help desk if you need immediate support.',
            );
          }
        },
        (error) => {
          this.setResposonse('Error', error.message);
        },
      );
  }
}
