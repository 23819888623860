import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked, ElementRef } from '@angular/core';
import { Invite } from '@app/core/models/invite.model';
import { Language } from '@app/core/models/language.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Country } from '@app/core/models/country.model';
import { TimeZone } from '@app/core/models/time-zone.model';
import { Department } from '@app/core/models/department.model';
import { State } from '@app/core/models/state.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InviteService } from '@app/core/services/invite.service';
import { FormService } from '@app/core/services/form.service';
import { CompanyService } from '@app/core/services/company.service';
import { UserService } from '@app/core/services/user.service';
import { MustMatch } from '@app/core/validators/must-match.validator';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import {
  MatPasswordStrengthComponent,
  MatPasswordStrengthInfoComponent,
} from '@angular-material-extensions/password-strength';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit, AfterViewChecked {
  token: string;
  inviteData: Invite;
  form: FormGroup;
  countries: Country[] = [];
  filteredCountries: Observable<Country[]>;
  timeZones: TimeZone[] = [];
  filteredTimeZones: Observable<TimeZone[]>;
  departments: Department[];
  states: State[];
  filteredStates: Observable<State[]>;
  showPasswordDetails: true;
  submitted = false;
  created: boolean;
  isValidToken = false;
  languages = [
    { value: 'en', name: 'English' },
    { value: 'pt-br', name: 'Portuguese' },
    { value: 'zh-cn', name: 'Chinese' },
    { value: 'da', name: 'Danish' },
    { value: 'nl', name: 'Dutch' },
    { value: 'fr', name: 'French' },
    { value: 'de', name: 'German' },
    { value: 'es', name: 'Spanish' },
  ];
  filteredLanguages: Observable<Language[]>;
  showWarningPanel: boolean;
  isPasswordStrengthValid;
  @ViewChild('passwordComponent') passwordComponentWithValidation: MatPasswordStrengthComponent;
  @ViewChild('passwordInfo') passwordInfo: MatPasswordStrengthInfoComponent;
  @ViewChild('password') password!: ElementRef;
  selectedCountry: Country;
  testTakersLimitReached: boolean;

  constructor(
    private route: ActivatedRoute,
    private inviteService: InviteService,
    private formService: FormService,
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    public snackBar: SnackBarService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.token = params['token'];
      this.inviteService.checkToken(this.token).subscribe(
        (invite: Invite | null) => {
          if (invite) {
            if (invite.email === 'limit was reached') {
              this.testTakersLimitReached = true;
              this.isValidToken = false;
            } else {
              this.isValidToken = true;
              this.inviteData = invite;

              this.states = [];
              this.form = this.formBuilder.group(
                {
                  firstName: ['', [Validators.required, Validators.minLength(2)]],
                  lastName: ['', [Validators.required, Validators.minLength(2)]],
                  email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
                  gender: ['', Validators.required],
                  country: [''],
                  state: [{ value: '', disabled: true }],
                  phone: ['', Validators.required],
                  username: ['', [Validators.required, Validators.minLength(4)]],
                  language: [''],
                  timeZone: [''],
                  password: ['', [Validators.required, Validators.minLength(8), this._validateInputFromPassword()]],
                  passwordConfirmation: ['', Validators.required],
                  company: ['', Validators.required],
                },
                {
                  validator: MustMatch('password', 'passwordConfirmation'),
                },
              );
              this.form.controls.email.setValue(this.inviteData.email);
              this.form.controls.firstName.setValue(this.inviteData.firstName);
              this.form.controls.lastName.setValue(this.inviteData.lastName);
              this.form.controls.company.setValue(this.inviteData.companyId);

              this.formService.getCountries().subscribe((result) => {
                this.countries = result;
                this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
                  startWith(''),
                  map((country) => (country ? this._filterCountry(country) : this.countries.slice())),
                );
              });

              this.filteredLanguages = this.form.controls['language'].valueChanges.pipe(
                startWith(''),
                map((language) => (language ? this._filterLanguage(language) : this.languages.slice())),
              );

              this.formService.getTimeZones().subscribe((result) => {
                this.timeZones = result;
                this.filteredTimeZones = this.form.controls['timeZone'].valueChanges.pipe(
                  startWith(''),
                  map((timeZone) => (timeZone ? this._filterTimeZone(timeZone) : this.timeZones.slice())),
                );
              });
              // Prevent browser autofill
              this.form.get('username').valueChanges.subscribe(() => {
                if (this.form.get('username').valid) {
                  this.checkExistedUsername();
                } else {
                  this.form.get('username').setErrors({ invalid: true });
                }
              });
            }
          } else {
            if (!this.isValidToken) {
              this.snackBar.info('Invite token is invalid.');
              return this.router.navigate(['/']);
            }
          }
        },
        () => {
          this.snackBar.info('Something went wrong.');
          return this.router.navigate(['/']);
        },
      );
    });
  }

  onSelectCountry(countryId: number) {
    this.selectedCountry = this.countries.find((country) => country.id === countryId);
    if (this.selectedCountry) {
      this.form.get('state').enable();
    } else {
      this.form.get('state').disable();
    }
    this.formService.getStatesByCountry(countryId).subscribe((states: State[]) => {
      this.states = states;
      this.filteredStates = this.form.controls['state'].valueChanges.pipe(
        startWith(''),
        map((state) => (state ? this._filterState(state) : this.states.slice())),
      );
    });
  }

  checkExistedUsername() {
    if (this.form.get('username').value && this.form.get('username').value.length > 0) {
      this.userService.checkExistedUsername(this.form.get('username').value).subscribe((result) => {
        if (result) {
          this.form.get('username').setErrors({ isUsernameExist: true });
        }
      });
    }
  }

  onSubmit() {
    const newUser = {
      firstName: this.form.getRawValue().firstName,
      lastName: this.form.getRawValue().lastName,
      email: this.form.getRawValue().email,
      gender: this.form.getRawValue().gender,
      country: this.form.getRawValue().country.id ? this.form.getRawValue().country.id : null,
      state: this.form.getRawValue().state.id ? this.form.getRawValue().state.id : null,
      phone: this.form.getRawValue().phone,
      username: this.form.getRawValue().username,
      language: this.form.getRawValue().language.value ? this.form.getRawValue().language.value : null,
      timeZone: this.form.getRawValue().timeZone.id ? this.form.getRawValue().timeZone.id : null,
      password: this.form.getRawValue().password,
      company: this.form.getRawValue().company,
    };

    if (this.form.invalid) {
      return;
    }
    this.submitted = true;
    this.userService.createByInvite(newUser, this.token).subscribe(
      () => {
        this.created = true;
        this.snackBar.info('Registered with success.');
      },
      () => {
        this.snackBar.info('Something went wrong.');
      },
    );
  }

  get formControls() {
    return this.form.controls;
  }

  displayCountry(country: Country): string {
    return country ? country.name : '';
  }

  displayState(state: State): string {
    return state ? state.name : '';
  }

  displayLanguage(language: Language): string {
    return language ? language.name : '';
  }

  displayTimeZone(timeZone: TimeZone): string {
    return timeZone ? timeZone.name : '';
  }

  private _filterCountry(value: string): Country[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.countries;
    }
    return this.countries.filter((country) => country.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
  }

  private _filterState(value: string): State[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.states;
    }
    return this.states.filter((state) => state.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
  }

  private _filterLanguage(value: string): Language[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.languages;
    }
    return this.languages.filter((lanuage) => lanuage.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
  }

  private _filterTimeZone(value: string): TimeZone[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.timeZones;
    }
    return this.timeZones.filter((timeZone) => timeZone.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
  }

  private _validateInputFromCountries() {
    return (control: FormControl) => {
      const inputValue = control.value.name && control.value.id ? control.value.name : control.value;

      const countryNames = this.countries.map((country) => country.name);

      if (!countryNames.includes(inputValue)) {
        return { notInArray: true }; // Add a custom error key
      } else {
        if (!control.value.name && !control.value.id) {
          const selectedCountry = this.countries.filter((country) => country.name === control.value)[0];
          this.form.controls.country.patchValue(selectedCountry);
        }
      }

      return null; // Validation passes
    };
  }

  private _validateInputFromStates() {
    return (control: FormControl) => {
      const inputValue = control.value.name && control.value.id ? control.value.name : control.value;

      const stateNames = this.states.map((state) => state.name);

      if (!stateNames.includes(inputValue)) {
        return { notInArray: true };
      } else {
        if (!control.value.name && !control.value.id) {
          const selectedState = this.states.filter((state) => state.name === control.value)[0];
          this.form.controls.state.patchValue(selectedState);
        }
      }

      return null;
    };
  }

  private _validateInputFromLanguages() {
    return (control: FormControl) => {
      const inputValue = control.value.name && control.value.value ? control.value.name : control.value;

      const languageNames = this.languages.map((language) => language.name);

      if (!languageNames.includes(inputValue)) {
        return { notInArray: true };
      }
      if (!control.value.name && !control.value.value) {
        const selectedLanguage = this.languages.filter((language) => language.name === control.value)[0];
        this.form.controls.language.patchValue(selectedLanguage);
      }

      return null;
    };
  }

  private _validateInputFromTimeZones() {
    return (control: FormControl) => {
      const inputValue = control.value.name && control.value.id ? control.value.name : control.value;

      const timeZoneNames = this.timeZones.map((timeZone) => timeZone.name);

      if (!timeZoneNames.includes(inputValue)) {
        return { notInArray: true };
      }

      if (!control.value.name && !control.value.id) {
        const selectedTimezone = this.timeZones.filter((timezone) => timezone.name === control.value)[0];
        this.form.controls.timeZone.patchValue(selectedTimezone);
      }

      return null;
    };
  }

  private _validateInputFromPassword() {
    return (control: FormControl) => {
      const inputValue = control.value;
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:"<>?`~\-=[\];',./])(?=.{8,}).*$/;

      if (!pattern.test(inputValue)) {
        return { notValid: true };
      }

      return null;
    };
  }

  onStrengthChanged($event?) {
    if (document.activeElement === this.password.nativeElement) {
      if ($event != 100) {
        this.showWarningPanel = true;
      }
      if ($event == 100) {
        this.showWarningPanel = false;
      }
    }
  }

  focusInPassword() {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.showWarningPanel = false;
    } else {
      this.showWarningPanel = true;
    }
  }

  focusOutPassword() {
    this.showWarningPanel = false;
    this.passwordComponentWithValidation.setDisabledState(true);
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }
}
