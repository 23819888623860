import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';

// models
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-test-connections-indicator-user-list',
  templateUrl: './test-connections-indicator-user-list.component.html',
  styleUrls: ['./test-connections-indicator-user-list.component.scss'],
})
export class TestConnectionsIndicatorUserListComponent implements OnInit {
  connectionsIndatorId: number;
  userInfoId: number;
  usersComplete: UserInfo[];

  constructor(private router: Router, private route: ActivatedRoute, private cIService: ConnectionsIndicatorService) {}

  ngOnInit(): void {
    this.connectionsIndatorId = Number(this.route.snapshot.params.id);
    this.cIService.getUsersComplete(this.connectionsIndatorId).subscribe((result) => {
      result = result.filter(
        (user) =>
          user.id && user.firstName !== null && user.lastName !== null && user.firstName !== '' && user.lastName !== '',
      );
      this.usersComplete = result;
    });
  }

  onView(userInfoId: number) {
    this.router.navigate([`/user/connections-indicator/${this.connectionsIndatorId}/user/${userInfoId}/results`]);
  }

  goBack() {
    this.router.navigate(['/dashboard']);
  }
}
