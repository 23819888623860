import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChartComponent } from './chart/chart.component';
import { ChartDetailsComponent } from './chart-details/chart-details.component';

const chartRoutes: Routes = [
  { path: '', component: ChartComponent },
  { path: ':id', component: ChartDetailsComponent },
  { path: ':id/tab/:tab', component: ChartDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(chartRoutes)],
  exports: [RouterModule],
})
export class ChartRoutingModule {}
