import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

// services
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { QuestionService } from '@app/core/services/question.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { TestObjectService } from '@app/core/services/test-object.service';
import { PageService } from '@app/core/services/page.service';

// models
import { QuestionGroup } from '@app/core/models/question-group.model';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { Question } from '@app/core/models/question.model';

@Component({
  selector: 'app-role-mandate-ordenate',
  templateUrl: './role-mandate-ordenate.component.html',
  styleUrls: ['./role-mandate-ordenate.component.scss'],
})
export class RoleMandateOrdenateComponent implements OnInit {
  roleMandate: RoleMandate;
  roleMandateId: number;
  userInfoId: number;
  config: any;
  decisionTables: DecisionTable[];
  questionGroups: QuestionGroup[];
  questions: Question[];
  allObj: any[];
  pages: any[];

  constructor(
    private router: Router,
    private rMService: RoleMandateService,
    private questionGroupService: QuestionGroupService,
    private questionService: QuestionService,
    private route: ActivatedRoute,
    public testObjectService: TestObjectService,
    public pageService: PageService,
  ) {}

  async ngOnInit() {
    this.roleMandateId = this.route.snapshot.params['id'];
    this.userInfoId = +localStorage.getItem('userInfoId');
    this.getRoleMandatePages();

    forkJoin([
      this.rMService.getById(this.roleMandateId),
      this.questionGroupService.getAllQuestionGroupForRoleMandate(this.roleMandateId),
      this.rMService.getAllQuestionById(this.roleMandateId),
      this.rMService.getAllDecisionTableById(this.roleMandateId),
    ]).subscribe((results) => {
      this.roleMandate = results[0];
      this.decisionTables = results[3] ? results[3] : [];
      this.questionGroups = results[1] ? results[1] : [];
      results[2] = results[2] ? results[2] : [];

      this.questions = results[2].filter(
        (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
      );

      this.questionGroups.forEach((questionGroup) => {
        questionGroup.questions = results[2].filter((question) => question.questionGroupId === questionGroup.id);
      });

      this.allObj = [
        ...this.questionGroups.map((obj) => {
          obj.networkType = 'questionGroup';
          return obj;
        }),
        ...this.decisionTables.map((obj) => {
          obj.networkType = 'decisionTable';
          return obj;
        }),
        ...this.questions.map((obj) => {
          obj.networkType = 'question';
          return obj;
        }),
      ];

      this.allObj = this.allObj.sort((a, b) => {
        return a.aorder - b.aorder;
      });
    });
  }

  getRoleMandatePages() {
    this.pages = this.pageService.getRoleMandatePages(this.roleMandateId);
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  onNext() {
    const orders = this.allObj.map((element, index) => {
      return {
        order: index,
        type: element.networkType,
        id: element.id,
      };
    });

    this.questionService.saveOrder(orders).subscribe((result) => {
      this.router.navigate(['/role-mandate/' + this.roleMandateId + '/review']);
    });
  }
}
