<h3 class="text-center" *ngIf="answers && answers.length">Answers</h3>
<div cdkDropList [cdkDropListData]="answers" class="cardsort-list" (cdkDropListDropped)="drop($event)">
  <div class="card" *ngFor="let answer of answers; let i = index" cdkDrag>
    <div *ngIf="!answer.edit">
      <span class="text"> {{ answer.text }} </span>
      <span class="subscale" *ngIf="answer.subscale && allowSubscale"> {{ answer.subscale.title }} </span>
      <span class="weight" *ngIf="answer.weight && allowWeight"> {{ answer.weight }} </span>
    </div>
    <app-form-cardsort
      style="width: 100%"
      *ngIf="answer.edit"
      [allowSubscale]="allowSubscale"
      [allowWeight]="allowWeight"
      [answerSubscales]="answerSubscales"
      [questionId]="questionId"
      [position]="position"
      (save)="onSaveEditAnswer($event, i)"
      [answer]="answer"
    >
    </app-form-cardsort>

    <div>
      <button mat-button *ngIf="editing && !answer.edit" (click)="onEditAnswer(answer)">Edit</button>
      <button mat-button *ngIf="editing" (click)="onDeleteAnswer(answer, i)">Delete</button>
    </div>
  </div>

  <app-form-cardsort
    *ngIf="newAnswer"
    [allowSubscale]="allowSubscale"
    [allowWeight]="allowWeight"
    [answerSubscales]="answerSubscales"
    [questionId]="questionId"
    [position]="position"
    (save)="onSaveAnswer($event)"
  >
  </app-form-cardsort>
</div>

<button *ngIf="mode == 'insert'" type="button" mat-raised-button class="btn-success btn-center" (click)="onAddAnswer()">
  Add Answer
</button>
