<h1 mat-dialog-title>{{ edit ? 'Edit Section Optimal Score' : 'Create a new Section Optimal Score' }}</h1>

<form [formGroup]="form" (submit)="onSubmit()">
  <div class="row">
    <div class="column">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Subscale</mat-label>
        <input
          type="text"
          placeholder="Subscale"
          aria-label="Subscale"
          matInput
          formControlName="subscale"
          [matAutocomplete]="autoSubscale"
        />
        <mat-autocomplete autoActiveFirstOption #autoSubscale="matAutocomplete" [displayWith]="displaySubscaleFn">
          <mat-option *ngFor="let subscale of filteredSubscales | async" [value]="subscale">
            {{ subscale.title }}
            <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Superscale</mat-label>
        <input
          type="text"
          placeholder="Superscale"
          aria-label="Superscale"
          matInput
          formControlName="superscale"
          [matAutocomplete]="autoSuperscale"
        />
        <mat-autocomplete autoActiveFirstOption #autoSuperscale="matAutocomplete" [displayWith]="displaySuperscaleFn">
          <mat-option *ngFor="let superscale of filteredSuperscales | async" [value]="superscale">
            {{ superscale.title }}
            <p *ngIf="superscale.description" class="small-text">{{ superscale.description }}</p>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput required formControlName="weight" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Optimal Curve</mat-label>
        <mat-select
          formControlName="optimalScoreCurveId"
          name="optimalScoreCurveId"
          (valueChange)="selectOptimalScoreCurve($event)"
        >
          <mat-option *ngFor="let optimalScoreCurve of optimalScoreCurves" [value]="optimalScoreCurve.id">
            {{ optimalScoreCurve.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="column">
      <h3>Optimal Range</h3>
      <div class="range-header">
        <mat-form-field>
          <mat-label>From</mat-label>
          <input matInput required formControlName="from" type="number" min="0" max="100" (change)="change('from')" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>To</mat-label>
          <input matInput required formControlName="to" type="number" min="0" max="100" (change)="change('to')" />
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="optimalScoreCurve">
        <mat-label>Curve Number</mat-label>
        <mat-select formControlName="curveNumber" name="curveNumber" (valueChange)="selectCurve($event)">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let curve of spreads" [value]="curve.curveNumber">
            Curve {{ curve.curveNumber }} [ <strong>From:</strong> {{ curve.percentile }} | <strong>To: </strong>
            {{ curve.percentile + curve.spread }}]
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-error *ngIf="!form.get('curveNumber').valid" class="alert-danger">No curve with spread selected.</mat-error>

      <div *ngIf="form.get('curveNumber').value && lineChartLabels && lineChartData">
        <canvas
          baseChart
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
        >
        </canvas>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close type="button">
      <mat-icon>block</mat-icon>
      Cancel
    </button>
    <button mat-flat-button color="primary" type="submit" [disabled]="!form.valid">
      <mat-icon>save_alt</mat-icon>
      Save
    </button>
  </div>
</form>
