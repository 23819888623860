import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// services
import { SubscaleService } from '@app/core/services/subcale.service';

// models
import { Subscale } from '@app/core/models/subscale.model';
import { ScorePercentile } from '@app/core/models/score-percentile.model';

@Component({
  selector: 'app-test-group-percentile-list',
  templateUrl: './test-group-percentile-list.component.html',
  styleUrls: ['./test-group-percentile-list.component.scss'],
})
export class TestGroupPercentileListComponent implements OnInit {
  percentileTitle: string;
  scorePercentiles: ScorePercentile[];
  subscaleId: number;
  subscale: Subscale;
  subscaleDate: string;

  constructor(private route: ActivatedRoute, private subscaleService: SubscaleService) {}

  ngOnInit(): void {
    this.subscaleId = Number(this.route.snapshot.params.subscaleId);
    this.getSubscale();
  }

  private getSubscale(): void {
    this.subscaleService.get(this.subscaleId).subscribe((subscale: Subscale) => {
      this.subscale = subscale;
      this.subscaleDate = new Date(subscale.dateAdded).toLocaleDateString('en-US');
    });
  }
}
