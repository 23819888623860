import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-global-privacy',
  templateUrl: './global-privacy.component.html',
  styleUrls: ['./global-privacy.component.scss'],
})
export class GlobalPrivacyComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() acl: number;
  @Output() update = new EventEmitter<number>();

  privacyMsgs = [
    { id: 5, msg: 'Allow users to set answers as private.' },
    { id: 9, msg: 'Block users to see collegues answers.' },
  ];

  constructor() {}

  onChangePrivacy(event, privacyMsg) {
    this.update.emit(event.checked ? privacyMsg.id : 0);
  }
}
