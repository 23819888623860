import { Component, Input, OnInit } from '@angular/core';
import { PsyTest } from '@app/core/models/psy-test.model';
import { TestService } from '@app/core/services/test.service';
import { Question } from '@app/core/models/question.model';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { forkJoin } from 'rxjs';
import { CustomQuestionOrder } from '@app/core/models/custom-question-order.model';

@Component({
  selector: 'app-custom-questions-order',
  templateUrl: './custom-questions-order.component.html',
  styleUrls: ['./custom-questions-order.component.scss'],
})
export class CustomQuestionsOrderComponent implements OnInit {
  @Input() psyTest: PsyTest;
  questions: Question[];
  customQuestionsOrder: CustomQuestionOrder[];

  /***
   * TODO:
   * - Add child components to the accordion
   * - Limit question title size
   * - Remove HTML Chars from the title
   */

  constructor(private testService: TestService) {}

  ngOnInit(): void {
    forkJoin([
      this.testService.getAllQuestionByPsyTestId(this.psyTest.id),
      this.testService.getCustomQuestionsOrderById(this.psyTest.id),
    ]).subscribe((result: [Question[], CustomQuestionOrder[]]) => {
      this.questions = result[0];
      this.customQuestionsOrder = result[1];
      this.questions = this.testService.applyCustomOrder(this.questions, this.customQuestionsOrder);
      this.orderQuestions();
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.prepareArray();
    this.testService
      .saveCustomQuestionsOrderById(this.psyTest.id, this.customQuestionsOrder)
      .subscribe(() => (this.questions = this.testService.applyCustomOrder(this.questions, this.customQuestionsOrder)));
  }

  prepareArray(): void {
    this.customQuestionsOrder = this.questions.map((obj, index) => {
      return {
        questionId: obj.id,
        order: index + 1,
      };
    });
  }

  orderQuestions(): void {
    this.questions = this.questions.sort((a, b) => {
      if (a.aorder === null && b.aorder !== null) {
        return 1;
      } else if (a.aorder !== null && b.aorder === null) {
        return -1;
      } else if (a.aorder === null && b.aorder === null) {
        return 0;
      } else {
        return a.aorder - b.aorder;
      }
    });
  }
}
