import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

// modules
import { SharedModule } from '@app/shared/shared.module';
import { ReportDashboardRoutingModule } from './report-dashboard-routing.module';

// components
import { ReportDashboardComponent } from './report-dashboard/report-dashboard.component';
import { ReportDashboardChartsComponent } from './report-dashboard-charts/report-dashboard-charts.component';
import { ReportDashboardSubscalesComponent } from './report-dashboard-subscales/report-dashboard-subscales.component';
import { ReportSubscaleDetailComponent } from './report-subscale-detail/report-subscale-detail.component';
import { ReportUserScoreComponent } from './report-user-score/report-user-score.component';

@NgModule({
  declarations: [
    ReportDashboardComponent,
    ReportDashboardChartsComponent,
    ReportDashboardSubscalesComponent,
    ReportSubscaleDetailComponent,
    ReportUserScoreComponent,
  ],
  imports: [CommonModule, ReportDashboardRoutingModule, SharedModule, ReactiveFormsModule, ColorPickerModule],
})
export class ReportDashboardModule {}
