import { Component, OnInit, Input, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { forkJoin } from 'rxjs';

// services
import { QuestionService } from '@app/core/services/question.service';
import { TestObjectService } from '@app/core/services/test-object.service';
import { QuestionGroupService } from '@app/core/services/question-group.service';

// models
import { Question } from '@app/core/models/question.model';
import { QuestionGroup } from '@app/core/models/question-group.model';
import { Subscale } from '@app/core/models/subscale.model';

@Component({
  selector: 'app-subscale-preview',
  templateUrl: './subscale-preview.component.html',
  styleUrls: ['./subscale-preview.component.scss'],
})
export class SubscalePreviewComponent implements OnInit, AfterContentChecked {
  @Input() subscaleId: number;
  @Input() subscale: Subscale;

  config: any;
  allObj: any[];
  questionGroups: QuestionGroup[];
  questions: Question[];
  questionNumber = 1;

  constructor(
    private questionService: QuestionService,
    private questionGroupService: QuestionGroupService,
    private viewportScroller: ViewportScroller,
    private cdref: ChangeDetectorRef,
    public testObjectService: TestObjectService,
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.questionGroupService.getAllBySubscaleId(this.subscaleId),
      this.questionService.getAllBySubscaleId(this.subscaleId),
    ]).subscribe((results) => {
      this.getAllObjAndConfig(results[0], results[1]);
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  isQuestion(obj: any): boolean {
    return obj.networkType === 'question';
  }

  isQuestionsGroup(obj: any): boolean {
    return obj.networkType === 'questionGroup';
  }

  getAllObjAndConfig(questionGroup: QuestionGroup[], questions: Question[]) {
    this.questionGroups = questionGroup;

    this.questions = questions.filter(
      (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
    );

    if (this.subscale.randomAllowed) {
      this.questions = this.questions.sort(() => Math.random() - 0.5);
    } else {
      this.questions = this.questions.sort((a, b) => {
        return a.aorder - b.aorder;
      });
    }

    this.questionGroups.forEach((group) => {
      group.questions = questions.filter((question) => question.questionGroupId === group.id);

      if (group.questions.length === 0) {
        this.questionGroups = this.questionGroups.filter((qG) => qG.id !== group.id);
      }
    });

    this.questionGroups = this.questionGroups.sort((a, b) => {
      return a.aorder - b.aorder;
    });

    this.allObj = [
      ...this.questionGroups.map((obj) => {
        obj.networkType = 'questionGroup';

        obj.questions.forEach((question) => {
          question.questionNumber = this.questionNumber;
          this.questionNumber++;
        });
        return obj;
      }),
      ...this.questions.map((obj) => {
        obj.networkType = 'question';

        obj.questionNumber = this.questionNumber;
        this.questionNumber++;
        return obj;
      }),
    ];

    this.allObj = this.allObj.sort((a, b) => {
      return a.aorder - b.aorder;
    });

    this.questionNumber = 1;

    this.allObj.map((e) => {
      if (e.hasOwnProperty('questions')) {
        e.questions.map((f) => {
          f.questionNumber = this.questionNumber;
          this.questionNumber++;
        });
      } else {
        e.questionNumber = this.questionNumber;
        this.questionNumber++;
      }
    });

    this.config = {
      id: 'preview-paginator',
      itemsPerPage: this.allObj.length,
      currentPage: 1,
      totalItems: this.allObj.length,
    };
  }
}
