import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Company } from '@app/core/models/company.model';
import { Observable } from 'rxjs';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { PsbItem } from '@app/core/models/psb-item.model';
import { PsyChart } from '@app/core/models/psy-chart.model';
import { Report } from '@app/core/models/report.model';
import { UserInfo } from '@app/core/models/userInfo.model';
import { PsbStatsLine } from '@app/core/models/psb-stats-line.model';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyService } from '@app/core/services/company.service';
import { ChartService } from '@app/core/services/chart.service';
import { TestGroupService } from '@app/core/services/test-group.service';
import { ReportService } from '@app/core/services/report.service';
import { UserService } from '@app/core/services/user.service';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ROLE_ORGANIZATIONAL_ADMIN, ROLE_TGM } from '@app/core/constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewInit {
  @ViewChild('flex-container') elementView: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autoTestGroup: MatAutocompleteTrigger;

  companies: Company[];
  filteredCompanies: Observable<Company[]>;
  filteredTestGroups: Observable<PsyTestGroup[]>;
  testGroups: PsyTestGroup[];
  usersPsbItems: PsbItem[] = [];

  charts: PsyChart[] = [];
  reports: Report[] = [];
  users = [];
  psbStats: PsbStatsLine[];

  isLoading = false;

  companyControl: FormControl;
  testGroupControl: FormControl;
  testGroup: PsyTestGroup;

  userData: UserInfo;
  usersPsbItem: PsbItem;
  isTGM: boolean = false;

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private chartService: ChartService,
    private testGroupService: TestGroupService,
    private userService: UserService,
    private reportService: ReportService,
  ) {}

  ngAfterViewInit() {
    this.userData = this.userService.getUserData();

    this.isLoading = true;

    if (this.userData.authorities.find((authority) => authority.name === 'ROLE_ADMIN')) {
      this.companyService.getAll().subscribe((results) => {
        this.companies = results;

        const initialCompany = this.companies.filter((c) => c.id === 11)[0];
        this.companyControl = new FormControl(initialCompany);

        this.setCompany(initialCompany.id);

        this.filteredCompanies = this.companyControl.valueChanges.pipe(
          startWith(''),
          map((item) => (item ? this._filter(item) : this.companies.slice())),
        );
      });
    } else if (
      this.userData.authorities.find((authority) => [ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN].includes(authority.name))
    ) {
      this.companyService.getById(this.userData.companyId).subscribe((company: Company) => {
        this.companies = [company];
        this.companyControl = new FormControl(company);
        this.companyControl.disable();
        this.setCompany(company.id);
      });
      this.isTGM = true;
    }
  }

  private _filterTG(value: string): PsyTestGroup[] {
    let isFilteredString = typeof value === 'string';
    if (isFilteredString) {
      const filterValue = value?.toLowerCase();
      return this.testGroups.filter((item) => item.title.toLowerCase().includes(filterValue));
    } else {
      return this.testGroups;
    }
  }

  private _filter(value: string): Company[] {
    let isFilteredString = typeof value === 'string';
    if (isFilteredString) {
      const filterValue = value?.toLowerCase();
      return this.companies.filter((item) => item.name.toLowerCase().includes(filterValue));
    } else {
      return this.companies;
    }
  }

  displayCompanyFn(company: Company) {
    return company ? company.name : '';
  }

  displayTestGroupFn(testGroup: PsyTestGroup) {
    return testGroup ? testGroup.title : '';
  }

  setCompany(companyId: number) {
    if (companyId) {
      this.testGroupService.getAllByCompanyId(companyId).subscribe((results) => {
        if (results.length > 0) {
          this.testGroups = results;

          const initialTG = results[0];
          this.testGroupControl = new FormControl(initialTG);

          this.setTestGroup(initialTG);

          this.filteredTestGroups = this.testGroupControl.valueChanges.pipe(
            startWith(''),
            map((item) => (item ? this._filterTG(item) : this.testGroups.slice())),
          );
        }
      });
    }
  }

  setTestGroupStats(testGroup: PsyTestGroup) {
    this.psbStats = [
      {
        title: 'Invited',
        number: testGroup.totalInvited,
        description: 'Total invited but not yet registered',
      },
      {
        title: 'Registered',
        number: testGroup.totalUsers,
        description: 'Total registered but not yet started',
      },
      {
        title: 'In Progress',
        number: testGroup.totalInProgress,
        description: 'Total started but not yet completed',
      },
      {
        title: 'Completed',
        number: testGroup.totalCompleted,
        description: 'Total Completed',
      },
    ];
  }

  setTestGroup(testGroup: PsyTestGroup) {
    this.setTestGroupStats(testGroup);

    this.testGroup = testGroup;

    this.chartService.getAllByTestGroupId(testGroup.id).subscribe((results) => {
      this.charts = results;
    });

    this.testGroupService.getAllReportById(testGroup.id).subscribe((results) => {
      this.reports = results;
    });

    this.testGroupService.getUsersById(testGroup.id).subscribe((results) => {
      this.users = results;
      this.usersPsbItems = [];
      this.reportService
        .getTestResults(testGroup.id)
        .subscribe((testResults: { title: string; dateCompleted: string; userId: number }[]) => {
          this.users.map((user) => {
            this.usersPsbItem = {
              id: user.id,
              title: user.firstName + ' ' + user.lastName,
              description: user.roleTitle ? user.roleTitle : null,
              progress: +user.status * 100,
              progressDescription: (+user.status * 100).toFixed() + '% completed',
              reportModel: user.reportModelId ? false : true,
              privateScores: +user.privateScores === 0 ? false : true,
            };
            this.usersPsbItem.testResults = testResults
              .filter((testResult) => testResult.userId === user.id)
              .map((testResult) => {
                return {
                  title: testResult.title,
                  dateCompleted: testResult.dateCompleted,
                };
              });
            this.usersPsbItems.push(this.usersPsbItem);
          });
        });
    });
  }

  openReport(report: any) {
    window.open('/user/' + report.psbItemId + '/test-group/' + this.testGroup.id + '/report/' + report.id);
  }

  onTestGroupFocus() {
    this.autoTestGroup._onChange('');
    this.autoTestGroup.openPanel();
  }
}
