import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// services
import { CompanyService } from '@app/core/services/company.service';
import { ReportDataDownloadService } from '@app/core/services/report-data-download.service';
import { TestGroupService } from '@app/core/services/test-group.service';
import { TestService } from '@app/core/services/test.service';

// models
import { Company } from '@app/core/models/company.model';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { PsyTest } from '@app/core/models/psy-test.model';
import { UtilsService } from '@app/core/services/utils.service';
import { UserService } from '@app/core/services/user.service';
import { ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TGM } from '@app/core/constants';

@Component({
  selector: 'app-report-data-download',
  templateUrl: './report-data-download.component.html',
  styleUrls: ['./report-data-download.component.scss'],
})
export class ReportDataDownloadComponent implements OnInit {
  public startDate: any;
  public endDate: any;
  public downloadType = [
    { id: 'pivotScaleTotalScores', name: 'Pivot Scale Total Scores' },
    { id: 'pivotScaleTotalPercentiles', name: 'Pivot Scale Total Percentiles' },
    { id: 'pivotTestAnswersNumbers', name: 'Pivot Test Answers Numbers' },
    { id: 'pivotTestAnswersText', name: 'Pivot Test Answers Text' },
    { id: 'pivotTestAnswersWeights', name: 'Pivot Test Answers Weights' },
    { id: 'downloadTestQuestionAndAnswers', name: 'Download Test Questions And Answers' },
    { id: 'downloadTestQuestionBySubscale', name: 'Download Test Questions By Subscale' },
    { id: 'downloadTestQuestions', name: 'Download Test Questions' },
    { id: 'timeSpentPerQuestion', name: 'Time Spent Per Question' },
    { id: 'timeSpentPerAssessment', name: 'Time Spent Per Assessment' },
  ];
  public client: Company;
  public clients: Company[];
  public test: PsyTest;
  public tests: PsyTest[];
  testFieldUpdate = new Subject<string>();
  testField: string;
  typeDownloadFieldUpdate = new Subject<string>();
  typeDownloadField: string;
  clientFieldUpdate = new Subject<string>();
  clientField: string;
  testGroupFieldUpdate = new Subject<string>();
  testGroupField: string;
  public testGroup: PsyTestGroup;
  public testGroups: PsyTestGroup[];
  userRole: string;
  allowRawScoresAccess = false;

  dateForm = new FormGroup({
    start: new FormControl('', [Validators.required]),
    end: new FormControl('', [Validators.required]),
    client: new FormControl(''),
    typeDownload: new FormControl('', [Validators.required]),
    test: new FormControl('', [Validators.required]),
    testGroup: new FormControl(''),
  });

  constructor(
    private companyService: CompanyService,
    private testService: TestService,
    private testGroupService: TestGroupService,
    private reportDataDownloadService: ReportDataDownloadService,
    private utilsService: UtilsService,
    private userService: UserService,
  ) {
    this.testFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.testField = value;
      }
    });

    this.typeDownloadFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.typeDownloadField = value;
      }
    });

    this.clientFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.clientField = value;
      }
    });

    this.testGroupFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.testGroupField = value;
      }
    });
  }

  ngOnInit(): void {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.startDate = new Date(year, month - 1, 1);
    this.endDate = new Date(year, month, new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate());
    this.dateForm.controls['start'].setValue(this.startDate);
    this.dateForm.controls['end'].setValue(this.endDate);

    this.loadData();
  }

  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type === 'start') {
      this.startDate = event.value;
    } else if (type === 'end') {
      this.endDate = event.value;
    }
  }

  loadData() {
    forkJoin([this.companyService.getAll(), this.testService.getAllPsyTest()]).subscribe((results) => {
      if (results[0]) {
        this.clients = results[0];
      }
      if (results[1]) {
        this.tests = results[1];
      }
      this.actionsForOaOrTgm();
    });
  }

  displayTest(test: PsyTest) {
    return test ? test.title : '';
  }

  displayDownloadType(type: any) {
    return type ? type.name : '';
  }

  displayClient(client: Company) {
    return client ? client.name : '';
  }

  displayTestGroup(testGroup: PsyTestGroup) {
    return testGroup ? testGroup.title : '';
  }

  onSelectClient(companyId: number) {
    forkJoin([
      this.testService.getPsyTestByCompanyId(companyId),
      this.testGroupService.getAllByCompanyId(companyId),
    ]).subscribe((response) => {
      this.tests = response[0];
      this.testGroups = response[1];
    });
  }

  onSelectTestGroup(testGroupId: number) {
    if (testGroupId) {
      this.testGroupService.getPsyTestsById(testGroupId).subscribe((tests) => {
        this.tests = tests;
      });
    }
  }

  onSubmit() {
    const companyId = this.dateForm.controls['client'].value.id;
    const testGroupId = this.dateForm.controls['testGroup'].value.id;
    const testId = testGroupId ? this.dateForm.controls['test'].value.testId : this.dateForm.controls['test'].value.id;
    const reportType = this.dateForm.controls['typeDownload'].value.id;
    const dateFrom = formatDate(this.startDate, 'yyyy-MM-dd', 'en-US');
    const dateTo = formatDate(this.endDate, 'yyyy-MM-dd', 'en-US');

    if (this.dateForm.valid) {
      this.reportDataDownloadService
        .getReportDataDownload(companyId, testGroupId, testId, reportType, dateFrom, dateTo)
        .subscribe((result: { fileName: string; fileMimeType: string; fileBase64Content: string }) => {
          console.log('result', result);
          console.log('to result', typeof result);
          this.downloadFile(result);
        });
    }
  }

  downloadFile(data: { fileName: string; fileMimeType: string; fileBase64Content: string }) {
    const blob = this.utilsService.b64toBlob(data.fileBase64Content, data.fileMimeType);
    const fileName: string = data.fileName;
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  private actionsForOaOrTgm(): void {
    const userData = this.userService.getUserData();
    const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
    if (authorities.includes(ROLE_ADMIN)) {
      this.userRole = ROLE_ADMIN;
    } else if (authorities.includes(ROLE_ORGANIZATIONAL_ADMIN)) {
      this.userRole = ROLE_ORGANIZATIONAL_ADMIN;
    } else if (authorities.includes(ROLE_TGM)) {
      this.userRole = ROLE_TGM;
    }

    if (this.userRole === ROLE_ORGANIZATIONAL_ADMIN) {
      this.companyService.getById(this.clients[0].id).subscribe((company) => {
        this.allowRawScoresAccess = +company.allowRawScoresAccess === 1 ? true : false;
        if (!this.allowRawScoresAccess) {
          // Limits raw scores access
          this.downloadType = [{ id: 'pivotScaleTotalPercentiles', name: 'Pivot Scale Total Percentiles' }];

          this.automaticallySelectedCompany();
        }
      });
    } else if (this.userRole === ROLE_TGM) {
      // Limits downloadType
      this.downloadType = [{ id: 'pivotScaleTotalPercentiles', name: 'Pivot Scale Total Percentiles' }];

      this.automaticallySelectedCompany();
    }
  }

  private automaticallySelectedCompany() {
    // Automatically selects the TGM's company
    if (this.clients[0].id) {
      this.dateForm.get('client').setValue(this.clients[0].id);
      this.onSelectClient(this.clients[0].id);
    }
  }
}
