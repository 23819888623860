import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

// components
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { UserService } from '@app/core/services/user.service';
import { CompanyService } from '@app/core/services/company.service';
import { DepartmentService } from '@app/core/services/department.service';
import { FilterService } from '@app/core/services/filter.service';

// models
import { Question } from '@app/core/models/question.model';
import { UserConnection } from '@app/core/models/user-connection.model';
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';
import { User } from '@app/core/models/user.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-answer-user-connection',
  templateUrl: './answer-user-connection.component.html',
  styleUrls: ['./answer-user-connection.component.scss'],
})
export class AnswerUserConnectionComponent implements OnInit {
  @Input() question: Question;
  @Input() connectionIndicatorId: number;
  @Input() connectionsIndicator: ConnectionIndicator;
  @Input() roleMandateId: number;
  @Input() answering: boolean;
  @Input() companyId: number;
  @Input() userInfoId: number;
  @Input() showResults: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  users: User[];
  searchControl = new FormControl();
  displayedColumns: string[];
  userConnections: MatTableDataSource<User> = new MatTableDataSource<User>();
  filteredUsers: Observable<User[]>;
  userList = [];
  usersData: UserConnection[];
  toUserInfoId: number;
  departmentName: string;
  roleTitle: string;
  selectedUsers: User[] = [];
  departments: string[];
  roles: string[] = [];
  dataSource = null;
  answerPrivate: boolean;

  constructor(
    private cIService: ConnectionsIndicatorService,
    private rMService: RoleMandateService,
    private userService: UserService,
    private companyService: CompanyService,
    private departmentService: DepartmentService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private filterService: FilterService,
  ) {}

  ngOnInit(): void {
    this.displayedColumns =
      this.answering &&
      (this.connectionsIndicator.acl === 9 || (this.connectionsIndicator.acl === 5 && this.question.acl === 9))
        ? ['firstName', 'lastName', 'role', 'department']
        : ['firstName', 'lastName', 'role', 'department', 'private', 'actionDelete'];

    if (this.showResults) {
      if (this.roleMandateId && this.userInfoId) {
        this.mapUserData(this.question.userAnswer.userConnections);
      }
    } else {
      if (this.companyId) {
        this.companyService.getUsersById(this.companyId).subscribe({
          next: (result) => {
            this.users = result.filter((user) => user.firstName && user.id);
            this.filterUsers();
          },
        });
        this.getUserInfoList();
      }
    }
  }

  private _filter(value: string): User[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    return this.filterService.filterUsersByNameAndDepartmentAndRoleTitle(this.users, filterValue);
  }

  selectUser() {
    if (Array.isArray(this.searchControl.value)) {
      for (const user of this.searchControl.value) {
        const userConnection: UserConnection = {
          id: null,
          fromUserInfoId: +Number(localStorage.getItem('userInfoId')),
          toUserInfoId: user.id,
          questionId: this.question.id,
          decisionTableAnswerId: null,
        };

        if (this.connectionIndicatorId) {
          this.cIService.saveUserConnection(this.connectionIndicatorId, userConnection).subscribe((result) => {
            this.searchControl.reset();
            this.userList.push(user);
            this.refreshDataSource();
            this.users = this.users.filter((user0) => user0.id !== user.id);
          });
        }

        if (this.roleMandateId) {
          this.rMService.saveUserConnection(this.roleMandateId, userConnection).subscribe((result) => {
            user.userConnectionId = result['insertId'];
            this.searchControl.reset();
            const userItem = {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              role: user.roleTitle,
              department: user.department,
              userConnectionId: user.userConnectionId,
            };
            this.userList.push(userItem);
            this.refreshDataSource();
            this.users = this.users.filter((user0) => user0.id !== user.id);
          });
        }
      }
    } else {
      if (this.searchControl.value instanceof Object) {
        this.searchControl.setValue([this.searchControl.value]);
      } else {
        this.searchControl.setErrors({ incorrect: true });
      }
    }
  }

  async getUserInfoList() {
    if (this.connectionIndicatorId) {
      if (this.userService.isOwner(+this.userInfoId)) {
        this.cIService
          .getToUserConnectionByQuestionId(this.connectionIndicatorId, this.question.id, this.userInfoId)
          .subscribe((data) => {
            this.usersData = data;
            this.mapUserData(this.usersData);
          });
      } else {
        this.cIService
          .getConnectionIndicatorNonOwnerToUserConnectionByQuestionId(
            this.connectionIndicatorId,
            this.question.id,
            this.userInfoId,
          )
          .subscribe((data) => {
            this.usersData = data;
            this.mapUserData(this.usersData);
          });
      }
    }

    if (this.roleMandateId) {
      this.rMService
        .getToUserConnectionByQuestionId(this.roleMandateId, this.question.id, this.userInfoId)
        .subscribe((data) => {
          this.usersData = data;
          this.mapUserData(this.usersData);
        });
    }
  }

  mapUserData(usersData: UserConnection[]) {
    usersData.forEach(async (userConnection) => {
      if (this.users) {
        const toUser = this.users.find((user) => user.id === userConnection.toUserInfoId);
        if (toUser) {
          const userItem = {
            id: toUser.id,
            firstName: toUser.firstName,
            lastName: toUser.lastName,
            role: toUser.roleTitle,
            department: toUser.department,
            userConnectionId: userConnection.id,
            private: userConnection.private,
          };

          this.users = this.users.filter((user) => user.id !== userItem.id);
          this.userList.push(userItem);
          this.refreshDataSource();
          this.userConnections.paginator = this.paginator;
        }
      }
    });
  }

  displayUser(value: User[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((user, index) => {
        if (index === 0) {
          displayValue = user.firstName + ' ' + user.lastName;
        } else {
          displayValue += ', ' + user.firstName + ' ' + user.lastName;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, user: User) {
    event.stopPropagation();
    this.toggleSelection(user);
  }

  onSelectionChange($event: MatOptionSelectionChange, user: User) {
    if ($event.source.selected) {
      this.toggleSelection(user);
    }
  }

  toggleSelection(user: User) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedUsers.push(user);
    } else {
      const i = this.selectedUsers.findIndex(
        (value) => value.firstName === user.firstName && value.lastName === user.lastName,
      );
      this.selectedUsers.splice(i, 1);
    }

    this.searchControl.setValue(this.selectedUsers);
  }

  filterUsers() {
    this.filteredUsers = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((user) => (user ? this._filter(user) : this.users)),
    );
  }

  onClearSearch(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.closePanel();
    this.searchControl.reset();
    this.clearUserList();
  }

  clearUserList() {
    this.users.map((user) => {
      user.selected = false;
    });
    this.selectedUsers = [];
  }

  onDelete(user: User) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.rMService.deleteUserConnection(this.roleMandateId, user.userConnectionId).subscribe((res) => {
          if (res) {
            const index = this.userConnections.data.map((e) => e['userConnectionId']).indexOf(user.userConnectionId);
            this.userConnections.data = this.userConnections.data.filter(
              (item) => item['userConnectionId'] !== user.userConnectionId,
            );
            this.users.push(user);
          }
        });
      }
    });
  }

  refreshDataSource() {
    this.userConnections.data = this.userList;
    this.changeDetectorRef.detectChanges();
    this.paginator.nextPage();
  }

  togglePrivacy(user: User) {
    this.answerPrivate = !!user.private;
    this.answerPrivate = !this.answerPrivate;
    this.updateUserConnectionPrivacy(user, this.answerPrivate);
  }

  updateUserConnectionPrivacy(user: User, answerPrivate: boolean) {
    const userConnection: UserConnection = {
      id: user.userConnectionId,
      fromUserInfoId: +Number(localStorage.getItem('userInfoId')),
      toUserInfoId: user.id,
      questionId: this.question.id,
      decisionTableAnswerId: null,
      private: answerPrivate,
    };

    if (this.connectionIndicatorId) {
      this.cIService.updateUserConnectionPrivacy(this.connectionIndicatorId, userConnection).subscribe((res) => {
        if (res) {
          this.userList.map((u) => {
            if (u.userConnectionId === userConnection.id) {
              u.private = answerPrivate;
              return u;
            }
          });

          this.userConnections.data = this.userList;
        }
      });
    }
  }
}
