<mat-form-field>
  <mat-label>Level</mat-label>
  <input
    matInput
    type="text"
    [formControl]="levelControl"
    required
    [matAutocomplete]="answering ? autoLevel : null"
    [readonly]="!answering"
  />
  <mat-autocomplete #autoLevel="matAutocomplete" (optionSelected)="setLevel($event)" [displayWith]="displayLevel">
    <mat-option *ngFor="let level of filteredLevels | async" [value]="level">
      {{ level.name }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="levelControl.errors && answering">Please, enter a valid level.</mat-error>
</mat-form-field>
