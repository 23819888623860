<h1>{{ isEdit ? 'Edit a Report Model' : 'Create a new Report Model' }}</h1>
<form [formGroup]="reportModelForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Title</mat-label>
    <input matInput type="text" formControlName="title" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Disclosure</mat-label>
    <input matInput type="text" formControlName="disclosure" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Text Color</mat-label>
    <input
      formControlName="textColor"
      matInput
      [style.background]="textColor"
      autocomplete="off"
      [(colorPicker)]="textColor"
      (colorPickerClose)="changeColor($event, 'textColor')"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Title Color</mat-label>
    <input
      formControlName="titleColor"
      matInput
      [style.background]="titleColor"
      autocomplete="off"
      [(colorPicker)]="titleColor"
      (colorPickerClose)="changeColor($event, 'titleColor')"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Top Bar Color</mat-label>
    <input
      formControlName="topBarColor"
      matInput
      [style.background]="topBarColor"
      autocomplete="off"
      [(colorPicker)]="topBarColor"
      (colorPickerClose)="changeColor($event, 'topBarColor')"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Side Bar Color</mat-label>
    <input
      formControlName="sideBarColor"
      matInput
      [style.background]="sideBarColor"
      autocomplete="off"
      [(colorPicker)]="sideBarColor"
      (colorPickerClose)="changeColor($event, 'sideBarColor')"
    />
  </mat-form-field>
  <div>
    <h4>Picture</h4>
    <img
      *ngIf="reportModel && reportModel.picture"
      style="max-height: 100px"
      src="{{ 'data:' + reportModel.pictureContentType + ';base64,' + reportModel.picture }}"
    />
    <button mat-button class="btn-primary btn-center btn-300">
      Add image
      <input type="file" accept="image/*" (change)="handleFileSelect($event, 1)" class="input-file" />
    </button>
  </div>
  <mat-form-field>
    <mat-label>Signature Title</mat-label>
    <input matInput type="text" formControlName="signatureTitle" />
  </mat-form-field>
  <div>
    <h4>Signature Logo</h4>
    <img
      *ngIf="reportModel && reportModel.signatureLogo"
      style="max-height: 100px"
      src="{{ 'data:' + reportModel.signatureLogoContentType + ';base64,' + reportModel.signatureLogo }}"
    />
    <button mat-button class="btn-primary btn-center btn-300">
      Add image
      <input type="file" accept="image/*" (change)="handleFileSelect($event, 2)" class="input-file" />
    </button>
  </div>
  <mat-form-field>
    <mat-label>Signature Text</mat-label>
    <input matInput type="text" formControlName="signatureText" />
  </mat-form-field>
  <div class="bottom-button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!reportModelForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
