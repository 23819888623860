import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject } from 'rxjs';

// components
import { SubsectionFormComponent } from '@app/core/models/subsection-form-component';

// services
import { SubscaleService } from '@app/core/services/subcale.service';

// models
import { Subscale } from '@app/core/models/subscale.model';
import { ScoreCardRange } from '@app/core/models/score-card-range.model';
import { ScoreCard } from '@app/core/models/score-card.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';
import { Superscale } from '@app/core/models/superscale.model';
import { forkJoin } from 'rxjs';
import { SuperscaleService } from '@app/core/services/superscale.service';

@Component({
  selector: 'app-section-scorecards-form',
  templateUrl: './section-scorecards-form.component.html',
  styleUrls: ['./section-scorecards-form.component.scss'],
})
export class SectionScorecardsFormComponent implements OnInit, SubsectionFormComponent {
  @Input() model: any;

  addTextEditorControl = new FormControl('', [Validators.required]);
  form: FormGroup;
  addTextDialogRef: MatDialogRef<any>;
  subscaleFieldUpdate = new Subject<string>();
  subscaleField: string;
  superscaleFieldUpdate = new Subject<string>();
  superscaleField: string;
  subscales: Subscale[] = [];
  superscales: Superscale[] = [];

  editorConfig: AngularEditorConfig = {
    height: '10rem',
    editable: true,
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private fb: FormBuilder,
    private subscaleService: SubscaleService,
    private superscaleService: SuperscaleService,
    private dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      heading: ['', [Validators.required]],
      positiveHeading: [''],
      concernHeading: [''],
      scoreCards: this.fb.array([]),
    });

    this.subscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.subscaleField = value;
      }
    });

    this.superscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.superscaleField = value;
      }
    });
  }

  ngOnInit(): void {
    this.model ? this.loadFormData() : this.addScoreCard();

    forkJoin([this.subscaleService.getAll(), this.superscaleService.getAll()]).subscribe((results) => {
      this.subscales = results[0].map((subscale) => {
        if (subscale.cardsortSubscale === 1) {
          return { ...subscale, title: `${subscale.title} (card-sort)` };
        }
        return subscale;
      });
      this.superscales = results[1];
    });
  }

  private loadFormData(): void {
    this.form.get('heading').setValue(this.model.heading);
    this.form.get('positiveHeading').patchValue(this.model.positiveHeading);
    this.form.get('concernHeading').patchValue(this.model.concernHeading);
    this.model.scoreCards.map((scorecard) => {
      this.addScoreCard(scorecard);
    });
  }

  createRangeFormGroup(range?: ScoreCardRange) {
    return this.fb.group({
      from: [range ? range.from : '', [Validators.required, Validators.min(0)]],
      to: [range ? range.to : '', [Validators.required, Validators.min(0)]],
      text: [range ? range.text : '', [Validators.required]],
    });
  }

  createScoreCardFormGroup(scorecard?: ScoreCard) {
    return this.fb.group({
      subscale: [scorecard && scorecard.subscale ? scorecard.subscale : '', [Validators.required]],
      superscale: [scorecard && scorecard.superscale ? scorecard.superscale : '', [Validators.required]],
      ranges: this.fb.group({
        outOfRangeLow: this.createRangeFormGroup(
          scorecard && scorecard.ranges ? scorecard.ranges.find((o) => o.type === 'OUT_OF_RANGE_LOW') : null,
        ),
        cautionaryLow: this.createRangeFormGroup(
          scorecard && scorecard.ranges ? scorecard.ranges.find((o) => o.type === 'CAUTIONARY_LOW') : null,
        ),
        optimal: this.createRangeFormGroup(
          scorecard && scorecard.ranges ? scorecard.ranges.find((o) => o.type === 'OPTIMAL') : null,
        ),
        outOfRangeHigh: this.createRangeFormGroup(
          scorecard && scorecard.ranges ? scorecard.ranges.find((o) => o.type === 'OUT_OF_RANGE_HIGH') : null,
        ),
        cautionaryHigh: this.createRangeFormGroup(
          scorecard && scorecard.ranges ? scorecard.ranges.find((o) => o.type === 'CAUTIONARY_HIGH') : null,
        ),
      }),
    });
  }

  get scoreCardsFormArray() {
    return this.form.get('scoreCards') as FormArray;
  }

  addScoreCard(scorecard?: any) {
    this.scoreCardsFormArray.push(this.createScoreCardFormGroup(scorecard));
  }

  getSubsection() {
    const { value } = this.form;

    const subsection = {
      id: this.model?.id,
      heading: value.heading,
      positiveHeading: value.positiveHeading,
      concernHeading: value.concernHeading,
      scoreCards: value.scoreCards.map((scorecard) => ({
        subscale: { id: scorecard.subscale.id },
        superscale: { id: scorecard.superscale.id },
        ranges: Object.entries(scorecard.ranges).map(([type, rangeValue]: [string, any]) => ({
          type: this.convertType(type),
          from: rangeValue.from,
          to: rangeValue.to,
          text: rangeValue.text,
        })),
      })),
    };

    return subsection;
  }

  convertType(scorecardRangeType: string) {
    return {
      outOfRangeLow: 'OUT_OF_RANGE_LOW',
      cautionaryLow: 'CAUTIONARY_LOW',
      optimal: 'OPTIMAL',
      outOfRangeHigh: 'OUT_OF_RANGE_HIGH',
      cautionaryHigh: 'CAUTIONARY_HIGH',
    }[scorecardRangeType];
  }

  openAddTextDialog(ref: TemplateRef<any>, textControl: AbstractControl) {
    this.addTextEditorControl.setValue(textControl.value);
    this.addTextDialogRef = this.dialog.open(ref);
    this.addTextDialogRef.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_SUCCESS) {
        return;
      }
      textControl.setValue(this.addTextEditorControl.value);
      this.addTextEditorControl.setValue('');
    });
  }

  removeTextDialog(textControl: AbstractControl, title?: string) {
    this.addTextEditorControl.setValue('');

    textControl.setValue(this.addTextEditorControl.value);
  }

  closeAddTextDialog() {
    this.addTextDialogRef.close();
  }

  saveAddTextDialog() {
    if (this.addTextEditorControl.value.indexOf('&#160;') > -1) {
      const newValue = this.addTextEditorControl.value.replace(/&#160;/g, '');
      this.addTextEditorControl.setValue(newValue);
    }

    this.addTextDialogRef.close({ event: EVENT_SUCCESS });
  }

  displaySubscale(subscale: Subscale): string {
    return subscale ? subscale.title : '';
  }

  displaySuperscale(superscale: Superscale): string {
    return superscale ? superscale.title : '';
  }
}
