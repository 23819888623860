import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import 'hammerjs';
import { LayoutModule } from './core/layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';
import { AppRoutingModule } from './app-routing.module';
import { RoleMandateModule } from './admin/pages/role-mandate/role-mandate.module';
import { OptimalScoreModule } from './admin/pages/optimal-score/optimal-score.module';
import { SettingModule } from './setting/setting.module';
import { RoleMandateService } from './core/services/role-mandate.service';
import { UserAuthInterceptor } from './core/services/user-auth-interceptor.service';
import { LoaderInterceptorService } from './shared/components/loader/loader-interceptor.service';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RetryInterceptor } from '@app/shared/interceptors/retry.interceptor';
import { SystemMaintenanceComponent } from './setting/system-maintenance/system-maintenance.component';
import { ApiV3ClientModule } from '@app/core/client/v3/api-v3-client.module';

@NgModule({
  declarations: [AppComponent, SystemMaintenanceComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LayoutModule,
    RoleMandateModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ChartsModule,
    SharedModule,
    OptimalScoreModule,
    NgxSliderModule,
    SettingModule,
    ApiV3ClientModule,
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    CookieService,
    RoleMandateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
