<div class="grid-container">
  <span *ngIf="this.connectionIndicator" class="psb-page-navigator"
    >Connection Indicator > <b>{{ this.connectionIndicator.title }}</b> >
    {{ tabs[+selected.value] }}
  </span>

  <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected.value" (selectedIndexChange)="tabChange($event)">
    <mat-tab label="Analysis">
      <ng-template matTabContent>
        <app-psb-stats-line *ngIf="psbStats" [psbStats]="psbStats"></app-psb-stats-line>

        <div class="row">
          <div class="column">
            <app-user-network
              [nodesData]="nodes"
              [type]="'GLOBAL'"
              [connectionIndicatorId]="connectionIndicatorId"
              *ngIf="nodesLoaded"
            ></app-user-network>
          </div>
        </div>

        <div class="row">
          <div class="column">
            <h2>Global</h2>
            <app-centrality-table *ngIf="globalTableAnalysis" [dataSource]="globalTableAnalysis">
            </app-centrality-table>
          </div>
          <div class="column">
            <h2>Referent</h2>
            <app-centrality-table *ngIf="referentTableAnalysis" [dataSource]="globalTableAnalysis">
            </app-centrality-table>
          </div>
          <div class="column">
            <h2>Expert</h2>
            <app-centrality-table *ngIf="expertTableAnalysis" [dataSource]="globalTableAnalysis">
            </app-centrality-table>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Connections Analysis">
      <ng-template matTabContent>
        <div *ngFor="let decisionTable of decisionTables; let indexOfElement = index" style="margin-top: 10px">
          <app-decision-table-analysis
            [decisionTable]="decisionTable"
            [connectionIndicatorId]="connectionIndicatorId"
            [nodes]="nodes"
          >
          </app-decision-table-analysis>
          <mat-divider *ngIf="!(indexOfElement === decisionTables.length - 1)"></mat-divider>
        </div>
      </ng-template>

      <!-- <div *ngFor="let question of userConnectionsQuestions; let indexOfElement = index;" style="margin-top: 10px">
        <app-question-analysis [question]="question" [nodes]="nodes"
          [connectionIndicatorId]="connectionIndicatorId"></app-question-analysis>

        <mat-divider *ngIf="!(indexOfElement === userConnectionsQuestions.length -1 || decisionTables.length > 0)"></mat-divider>
      </div> -->
    </mat-tab>
    <mat-tab label="Questions Analysis">
      <ng-template matTabContent>
        <div *ngFor="let cardsortAnalysis of cardsortAnalysis; let indexOfElement = index" style="margin-top: 10px">
          <app-cardsort-analysis [connectionIndicatorId]="connectionIndicatorId" [cardsortAnalysis]="cardsortAnalysis">
          </app-cardsort-analysis>
        </div>
        <div *ngFor="let textAreaAnalysis of textAreaAnalysis; let indexOfElement = index" style="margin-top: 10px">
          <app-text-area-analysis [connectionIndicatorId]="connectionIndicatorId" [textAreaAnalysis]="textAreaAnalysis">
          </app-text-area-analysis>
        </div>
        <!-- <app-slider-analysis *ngIf="questions" [connectionIndicatorId]="connectionIndicatorId" [question]="questions[0]">
      </app-slider-analysis> -->
      </ng-template>
    </mat-tab>
    <mat-tab label="E-mails">
      <ng-template matTabContent>
        <!-- <app-invite-email [connectionIndicatorId]="connectionIndicatorId"></app-invite-email> -->
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
