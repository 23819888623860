import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Report } from '../models/report.model';
import { Chapter } from '../models/chapter.model';
import { PsyChart } from '../models/psy-chart.model';
import { CustomRange } from '../models/custom-range.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TestGroupChartComparison } from '@app/core/models/test-group-chart-comparison.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {}

  getById(reportId: number) {
    return this.http.get<Report>(`${BACKEND_URL}/report/${reportId}`);
  }

  getReportByUserIdAndId(userId: number, id: number, testGroupId: number) {
    return this.http.get<Report>(BACKEND_URL + '/report/' + id + '/user/' + userId + '/test-group/' + testGroupId);
  }

  getAll() {
    return this.http.get<Pick<Report, 'id' | 'title'>[]>(BACKEND_URL + '/report');
  }

  save(report: Pick<Report, 'title'>) {
    return this.http.post<Pick<Report, 'id' | 'title'>>(BACKEND_URL + '/report', report);
  }

  delete(reportId: number) {
    return this.http.delete(`${BACKEND_URL}/report/${reportId}`);
  }

  update(reportId: number, params: Pick<Report, 'title'>) {
    return this.http.patch<Pick<Report, 'id' | 'title'>>(`${BACKEND_URL}/report/${reportId}`, params);
  }

  removeChapterFromReport(reportId: number, chapterId: number) {
    return this.http.delete(`${BACKEND_URL}/report/${reportId}/chapter/${chapterId}`);
  }

  addChapterToReport(reportId: number, chapterId: number) {
    const url = `${BACKEND_URL}/report/${reportId}/chapter`;
    const body = { chapterId };

    return this.http.post<Chapter>(url, body);
  }

  addChartToReport(reportId: number, chartId: number) {
    const url = `${BACKEND_URL}/report/${reportId}/chart`;
    const body = { chartId };

    return this.http.post<PsyChart>(url, body);
  }

  removeChartFromReport(reportId: number, chartId: number) {
    const url = `${BACKEND_URL}/report/${reportId}/chart/${chartId}`;

    return this.http.delete(url);
  }

  onCopySection(reportId: number, chapterId: number, body: any) {
    const url = `${BACKEND_URL}/report/${reportId}/chapter/${chapterId}/copy-section`;

    return this.http.post<PsyChart>(url, body);
  }

  getTestResults(testGroupId: number): Observable<any> {
    return this.http.get<{ title: string; dateCompleted: string; userId: number }[]>(
      `${BACKEND_URL}/report/test-group/${testGroupId}/users/test-results`,
    );
  }

  createCustomRanges(testGroupId: number, testGroupCustomRanges: CustomRange[]) {
    const url = `${BACKEND_URL}/report/test-group/${testGroupId}/custom-range`;

    return this.http.post<CustomRange[]>(url, testGroupCustomRanges);
  }

  updateCustomRanges(testGroupId: number, testGroupCustomRanges: CustomRange[]) {
    const url = `${BACKEND_URL}/report/test-group/${testGroupId}/custom-range`;

    return this.http.patch<CustomRange[]>(url, testGroupCustomRanges);
  }

  getCustomRanges(testGroupId: number) {
    const url = `${BACKEND_URL}/report/test-group/${testGroupId}/custom-range`;

    return this.http.get<CustomRange[]>(url);
  }

  getAllTestGroupChartComparisonByTestGroupIdAndChartId(
    testGroupId: number,
    chartId: number,
  ): Observable<TestGroupChartComparison[]> {
    return this.http
      .get<any>(`${BACKEND_URL}/report/test-group-chart-comparison/test-group/${testGroupId}/chart/${chartId}`)
      .pipe(take(1));
  }

  updateTestGroupChartComparison(testGroupChartComparison: TestGroupChartComparison): Observable<any> {
    return this.http
      .patch<any>(`${BACKEND_URL}/report/test-group-chart-comparison/user`, testGroupChartComparison)
      .pipe(take(1));
  }

  massUpdateTestGroupChartComparison(testGroupChartComparisons: TestGroupChartComparison[]): Observable<any> {
    return this.http
      .patch<any>(`${BACKEND_URL}/report/test-group-chart-comparison/user/mass`, testGroupChartComparisons)
      .pipe(take(1));
  }

  removeUnnecessaryParagraphs(sentence: string) {
    sentence = sentence.replace(/<p><br><\/p>/gi, '');
    sentence = sentence.replace(/<p><br\/><\/p>/gi, '');
    return sentence;
  }

  removeSelectionBoundary(sentence: string) {
    if (sentence) {
      sentence = sentence.replace(
        /<span id='selectionBoundary_\d+_\d+' class='rangySelectionBoundary'.*?>[^<>]+?<\/span>/gi,
        '',
      );
      sentence = sentence.replace(/<span class="rangySelectionBoundary">([^<>]+?)<\/span>/g, '$1');
      sentence = sentence.replace(/<span class="rangySelectionBoundary">[^<>]+?<\/span>/gi, '');
      return sentence;
    }
  }

  removeSpecificHtmlChars(sentence: string) {
    if (sentence) {
      sentence = sentence.replace(/&#xFEFF(;)?/g, '');
      sentence = sentence.replace(/\uFEFF/g, '');
      sentence = sentence.replace(/&#65279(;)?/g, '');
      sentence = sentence.replace(/<span id="docs-internal-guid-c1dd9b95-7fff-4360-6afd-7379ea8974bd">/g, '<span>');
      sentence = sentence.replace(/<span id='docs-internal-guid-c1dd9b95-7fff-4360-6afd-7379ea8974bd'>/g, '<span>');
      sentence = sentence.replace(
        /<span style="font-size: 11pt;background-color: transparent;vertical-align: baseline;">/g,
        '<span>',
      );
      sentence = sentence.replace(
        /<span style='font-size: 11pt;background-color: transparent;vertical-align: baseline;'>/g,
        '<span>',
      );
      sentence = sentence.replace(
        /<span style="font-size: 14.6667px;float: none;"><span style="font-size: 14.6667px;float: none;">/g,
        '<span>',
      );
      sentence = sentence.replace(
        /<span style='font-size: 14.6667px;float: none;'><span style='font-size: 14.6667px;float: none;'>/g,
        '<span>',
      );
      sentence = sentence.replace(/<span id="docs-internal-guid-c1dd9b95-7fff-4360-6afd-7379ea8974bd">/g, '<span>');
      sentence = sentence.replace(/<span id='docs-internal-guid-c1dd9b95-7fff-4360-6afd-7379ea8974bd'>/g, '<span>');
      // Remove color property from header tags
      const headerRegex = /<h[1-4][^>]*>(.*?)<\/h[1-4]>/g;
      sentence = sentence.replace(headerRegex, (header) => {
        // Use another regular expression to remove the color property within all tags inside the header
        const colorTagRegex = /<[^>]*\s*color\s*=\s*["'][^"']*["'][^>]*>/g;
        const cleanedHeader = header.replace(colorTagRegex, '');

        return cleanedHeader;
      });
      return sentence;
    }
  }

  removeFirstLastPTags(str: string) {
    if (str) {
      return str.replace(/^<p>|<\/p>$/gi, '');
    }
  }

  findAllSubscaleById(reportId: number) {
    const url = `${BACKEND_URL}/report/${reportId}/all-subscale`;

    return this.http.get<any>(url);
  }
}
