import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionTableColumnType } from '@app/core/models/decision-table-column-type.model';
import { DecisionTableColumn } from '@app/core/models/decision-table-column.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-edit-column',
  templateUrl: './edit-column.component.html',
  styleUrls: ['./edit-column.component.scss'],
})
export class EditColumnComponent implements OnInit {
  @Input() column: DecisionTableColumn;
  @Output() edit = new EventEmitter<DecisionTableColumn>();
  @Output() delete = new EventEmitter<DecisionTableColumn>();

  editColumnForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    required: new FormControl('', [Validators.required]),
  });
  decisionTableColumnTypes: DecisionTableColumnType[] = [
    { id: 1, name: 'Decision Area' },
    { id: 2, name: 'Users' },
    { id: 3, name: 'Text' },
    { id: 4, name: 'Number' },
  ];

  constructor(private decisionTableService: DecisionTableService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.editColumnForm.get('title').setValue(this.column.title);
    this.editColumnForm
      .get('type')
      .setValue(this.decisionTableColumnTypes.find((type) => type.id === +this.column.type).id);
    this.editColumnForm.get('required').setValue(this.column.required);
  }

  editColumn(formDirective: FormGroupDirective) {
    return;
  }

  onChange() {
    this.column.title = this.editColumnForm.get('title').value;
    this.column.type = this.editColumnForm.get('type').value;
    this.column.required = this.editColumnForm.get('required').value;

    this.edit.emit(this.column);
  }

  onDelete() {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.componentInstance.message = `Please confirm that you want delete this Column?`;

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.decisionTableService.deleteColumn(this.column).subscribe(() => {
          this.delete.emit(this.column);
        });
      }
    });
  }
}
