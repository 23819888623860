import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// components
import { CreateNewTestDialogComponent } from '@app/admin/pages/tests/create-new-test-dialog/create-new-test-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { CopyDialogComponent } from '@app/shared/components/copy-dialog/copy-dialog.component';

// services
import { TestService } from '@app/core/services/test.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { PsyTest } from '@app/core/models/psy-test.model';
import { MatDialog } from '@angular/material/dialog';
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-psy-test-list',
  templateUrl: './psy-test-list.component.html',
  styleUrls: ['./psy-test-list.component.scss'],
})
export class PsyTestListComponent implements OnInit {
  displayedPsyTestListColumns: string[] = ['title', 'description', 'version', 'status', 'dateAdded', 'actions'];
  psyTestForCopy: PsyTest;
  psyTestCopyTitle: string;
  psyTests: PsbItem[] = [];

  constructor(
    private router: Router,
    private testService: TestService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.getAllPsyTest();
  }

  getAllPsyTest() {
    this.psyTests = [];
    this.testService.getAllPsyTest().subscribe((psyTests) => {
      psyTests.forEach((psyTest) => {
        const item: PsbItem = {
          id: psyTest.id,
          title: psyTest.title,
          description: psyTest.description,
          status: +psyTest.active === 1 ? 'Active' : 'Inactive',
          extraColumns: [
            {
              key: 'Version',
              value: psyTest.version,
            },
            {
              key: 'Created Date',
              value: new Date(psyTest.dateAdded).toLocaleDateString('en-US'),
            },
          ],
        };

        this.psyTests.push(item);
      });
    });
  }

  createNewTest() {
    this.dialog
      .open(CreateNewTestDialogComponent, { data: { isSuperTest: false, isEdit: false } })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.testService.createPsyTest(result.data).subscribe(
            (response) => {
              if (response) {
                this.getAllPsyTest();
                this.snackBar.info('Test created successfully.');
              }
            },
            () => {
              this.snackBar.info('Something went wrong. Please, try again later.');
            },
          );
        }
      });
  }

  onCopy(testId: number) {
    const copyDialog = this.dialog.open(CopyDialogComponent);
    this.psyTests.map((psyTest) => {
      if (psyTest.id === testId) {
        this.psyTestCopyTitle = psyTest.title;
        copyDialog.componentInstance.title = psyTest.title;
        copyDialog.componentInstance.type = 'Test';
      }
    });
    copyDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.copyPsyTestById(testId);
      }
    });
  }

  onView(testId: number) {
    this.router.navigate([`/psy-test/${testId}/detail`]);
  }

  onDelete(testId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.testService.deletePsyTest(testId).subscribe(() => {
          this.psyTests = this.psyTests.filter((psyTest) => psyTest.id !== testId);
        });
      });
  }

  copyPsyTestById(testId: number) {
    this.copyPsyTest(testId);
  }

  copyPsyTest(testId: number) {
    this.testService.copy(testId).subscribe((testForCopy) => {
      this.getAllPsyTest();
      this.router.navigate([`/psy-test/${testForCopy.id}/detail`]);
      this.openSnackBar('Test copied with success.');
    });
  }

  openSnackBar(title: string) {
    this.snackBar.info('A new Test is created with name ' + title);
  }
}
