/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Level } from '../models/Level';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class LevelService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all level by id
     * Retrieve all level by id
     * @param id Id of the company
     * @returns Level A list of levels
     * @throws ApiError
     */
    public getCompanyLevel(
        id: number,
    ): Observable<Level> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/level',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of levels not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a level by id
     * Retrieve a level by id
     * @param id Id of the company
     * @param levelId Id of the level
     * @returns Level A level
     * @throws ApiError
     */
    public getCompanyLevel1(
        id: number,
        levelId: number,
    ): Observable<Level> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/level/{levelId}',
            path: {
                'id': id,
                'levelId': levelId,
            },
            errors: {
                404: `A level not found`,
                500: `Internal error`,
            },
        });
    }

}
