import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Country } from '../models/country.model';
import { State } from '../models/state.model';
import { TimeZone } from '../models/time-zone.model';
import { Company } from '../models/company.model';
import { Observable } from 'rxjs';
import { Department } from '../models/department.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private http: HttpClient) {}

  getCountries() {
    return this.http.get<Country[]>(BACKEND_URL + '/country').pipe(take(1));
  }

  getStatesByCountry(countryId: number): Observable<State[]> {
    return this.http.get<State[]>(`${BACKEND_URL}/country/${countryId}/state`).pipe(take(1));
  }

  getCountryByStateId(stateId: number) {
    return this.http.get<Country>(BACKEND_URL + '/country/state/' + stateId);
  }

  getTimeZones() {
    return this.http.get<TimeZone[]>(BACKEND_URL + '/time-zone');
  }

  getCompanies() {
    return this.http.get<Company[]>(BACKEND_URL + '/company');
  }

  getDepartmentByCompanyId(companyId: number) {
    return this.http.get<Department[]>(BACKEND_URL + '/department/company/' + companyId);
  }
}
