import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { UserService } from '@app/core/services/user.service';
@Component({
  selector: 'app-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.scss'],
})
export class LayoutAdminComponent implements OnInit {
  mobileQuery: MediaQueryList;
  toggled = window.innerWidth > 1200;
  role: string;

  private mobileQueryListener: () => void;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private userService: UserService
  ) {
    this.role = this.userService.getUserData().authorities.find((authority) => authority.name === 'ROLE_ADMIN')
      ? 'admin'
      : 'tgm';
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
  }

  onToggle(toggled: boolean) {
    this.toggled = toggled;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.toggled = window.innerWidth > 1200;
  }

  ngOnInit(): void {}
}
