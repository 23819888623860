<h3>{{ title }}</h3>
<mat-divider></mat-divider>
<form class="pt-2" [formGroup]="itemForm" (ngSubmit)="onSave()">
  <mat-form-field *ngIf="data.items.length > 0">
    <mat-label>{{ formFieldName }}</mat-label>
    <input type="text" matInput formControlName="item" [matAutocomplete]="auto" />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option
        *ngFor="let item of filteredItems | async"
        [value]="item"
        (onSelectionChange)="onItemSelectionChanged(item.isSuperItem)"
      >
        {{ item.itemName }}
        <p
          *ngIf="
            (formFieldName === 'Test' || 'Assessments') &&
            formFieldName !== 'Chart' &&
            formFieldName !== 'User' &&
            formFieldName !== 'Subscale'
          "
          class="small-text"
        >
          Version: {{ item.version }}
        </p>
        <p *ngIf="formFieldName === 'Chart' || formFieldName === 'Subscale'" class="small-text">
          {{ item.description }}
        </p>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="itemForm.get('item').invalid">Please select a valid {{ formFieldName.toLowerCase() }}.</mat-error>
  </mat-form-field>
  <p *ngIf="data.items.length < 1">No data.</p>
  <div class="mt-2">
    <button mat-button *ngIf="isAddButton" class="btn-success btn-300" type="button" (click)="onAdd()">
      <mat-icon>add</mat-icon>{{ addButtonName }}
    </button>
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon inline="true">block</mat-icon>
      <span class="ml-1">Cancel</span>
    </button>
    <button [disabled]="data.items.length < 1 || itemForm.invalid" mat-button class="btn-success btn-300" type="submit">
      <mat-icon inline="true">save_alt</mat-icon>
      <span class="ml-1">Save</span>
    </button>
  </div>
</form>
