import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InviteService } from '@app/core/services/invite.service';
import { GenericLink } from '@app/core/models/generic-link.model';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '@app/core/validators/must-match.validator';
import { MatVerticalStepper } from '@angular/material/stepper';
import { UserService } from '@app/core/services/user.service';
import {
  MatPasswordStrengthComponent,
  MatPasswordStrengthInfoComponent,
} from '@angular-material-extensions/password-strength';

@Component({
  selector: 'app-generic-link-invite',
  templateUrl: './generic-link-invite.component.html',
  styleUrls: ['./generic-link-invite.component.scss'],
})
export class GenericLinkInviteComponent implements OnInit, AfterViewChecked {
  token: string;
  isValidToken: boolean;
  testTakersLimitReached: boolean;
  newUserForm: FormGroup;
  loginForm: FormGroup;
  isNewUser: boolean;
  @ViewChild('stepper') stepper: MatVerticalStepper;
  hidePassword = true;
  genericLink: GenericLink;
  loginError: boolean;
  submitted: boolean;
  showWarningPanel: boolean;
  @ViewChild('passwordComponent') passwordComponentWithValidation: MatPasswordStrengthComponent;
  @ViewChild('passwordInfo') passwordInfo: MatPasswordStrengthInfoComponent;
  @ViewChild('password') password!: ElementRef;
  emailAlreadyRegistered: boolean;

  constructor(
    private route: ActivatedRoute,
    private inviteService: InviteService,
    private router: Router,
    public snackBar: SnackBarService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cDRef: ChangeDetectorRef,
  ) {}

  private static _validateInputFromPassword() {
    return (control: FormControl) => {
      const inputValue = control.value;
      const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[(){},.:?'|>/<"~`;*_@#$%^&+=!\[\]-]).*$/;

      if (!pattern.test(inputValue)) {
        return { notValid: true };
      }

      return null;
    };
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.route.params.subscribe((params: Params) => {
      this.token = params['token'];
      this.inviteService.checkGenericLinkToken(this.token).subscribe((genericLink: GenericLink | null) => {
        if (genericLink) {
          //...
          this.genericLink = genericLink;
          if (genericLink.token === 'limit was reached') {
            this.testTakersLimitReached = true;
            this.isValidToken = false;
          } else {
            this.isValidToken = true;
            this.loginForm = this.formBuilder.group({
              login: ['', [Validators.required, Validators.minLength(2)]],
              password: ['', [Validators.required, Validators.minLength(6)]],
            });
            this.newUserForm = this.formBuilder.group(
              {
                firstName: ['', [Validators.required, Validators.minLength(2)]],
                lastName: ['', [Validators.required, Validators.minLength(2)]],
                email: ['', [Validators.required, Validators.email]],
                gender: ['', Validators.required],
                password: [
                  '',
                  [
                    Validators.required,
                    Validators.minLength(8),
                    GenericLinkInviteComponent._validateInputFromPassword(),
                  ],
                ],
                passwordConfirmation: ['', Validators.required],
              },
              {
                validator: MustMatch('password', 'passwordConfirmation'),
              },
            );
          }
        } else {
          this.isValidToken = false;
          this.snackBar.info('Invite token is invalid.');
          return this.router.navigate(['/']);
        }
      });
    });
  }

  goToStep(stepIndex: number): void {
    this.stepper.selectedIndex = stepIndex;
    this.resetFormControls();
  }

  onAuthenticate() {
    if (this.loginForm.valid) {
      const loginData = {
        login: this.loginForm.get('login').value,
        password: this.loginForm.get('password').value,
        token: this.genericLink.token,
      };
      this.userService.authenticateGenericLink(loginData).subscribe((res) => {
        if ('error' in res) {
          this.loginError = true;
        } else {
          this.userService.handleLoginResponse(res, true);
        }
      });
    }
  }

  focusInPassword() {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.showWarningPanel = false;
    } else {
      this.showWarningPanel = true;
    }
  }

  focusOutPassword() {
    this.showWarningPanel = false;
    this.passwordComponentWithValidation.setDisabledState(true);
  }

  get formControls() {
    return this.newUserForm.controls;
  }

  onStrengthChanged($event?) {
    if (document.activeElement === this.password.nativeElement) {
      if ($event != 100) {
        this.showWarningPanel = true;
      }
      if ($event == 100) {
        this.showWarningPanel = false;
      }
    }
  }

  ngAfterViewChecked(): void {
    this.cDRef.detectChanges();
  }

  onSubmitNewUser() {
    const newUser = {
      firstName: this.newUserForm.getRawValue().firstName,
      lastName: this.newUserForm.getRawValue().lastName,
      email: this.newUserForm.getRawValue().email,
      gender: this.newUserForm.getRawValue().gender,
      password: this.newUserForm.getRawValue().password,
      token: this.genericLink.token,
    };

    if (this.newUserForm.invalid) {
      return;
    }

    this.userService.registerGenericLink(newUser).subscribe((res: any) => {
      if ('error' in res) {
        if (res.error === 'Email address already exists.') {
          this.emailAlreadyRegistered = true;
          this.isNewUser = false;
          this.loginForm.get('login').setValue(this.newUserForm.get('email').value);
        }
      } else {
        this.userService.handleLoginResponse(res, true);
      }
    });
  }

  private resetFormControls(): void {
    this.loginForm.reset();
    this.newUserForm.reset();
    // TODO: Fix reset form later
    // Object.keys(this.loginForm.controls).forEach((key) => {
    //   const control = this.loginForm.get(key);
    //   if (control) {
    //     control.setErrors(null);
    //     control.markAsUntouched();
    //     control.clearValidators();
    //     control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    //   }
    // });
    // Object.keys(this.newUserForm.controls).forEach((key) => {
    //   const control = this.newUserForm.get(key);
    //   if (control) {
    //     control.setErrors(null);
    //     control.markAsUntouched();
    //     // control.clearValidators();
    //     control.updateValueAndValidity();
    //   }
    // });
  }

  onStepChange(): void {
    this.resetFormControls();
  }
}
