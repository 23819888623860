<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Heading</mat-label>
    <input matInput type="text" required formControlName="heading" />
    <mat-error *ngIf="form.get('heading').hasError">You must provide a heading.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Positive Heading</mat-label>
    <input matInput type="text" formControlName="positiveHeading" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Concern Heading</mat-label>
    <input matInput type="text" formControlName="concernHeading" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Lead Sentence</mat-label>
    <mat-select formControlName="leadSentenceOption">
      <mat-option value="he">He</mat-option>
      <mat-option value="she">She</mat-option>
      <mat-option value="they">They</mat-option>
    </mat-select>
  </mat-form-field>
  <angular-editor
    [config]="editorConfig"
    [formControl]="form.get('leadSentence').get(form.value.leadSentenceOption)"
  ></angular-editor>

  <h3>Type of Scales</h3>
  <mat-button-toggle-group formControlName="useSubscale">
    <mat-button-toggle [value]="false">Superscale</mat-button-toggle>
    <mat-button-toggle [value]="true">Subscale</mat-button-toggle>
  </mat-button-toggle-group>

  <div class="scale-form-group subscale" *ngIf="useSubscale" formGroupName="subscale">
    <mat-form-field>
      <mat-label>Subscale</mat-label>
      <input
        matInput
        required
        type="text"
        (ngModelChange)="this.subscaleFieldUpdate.next($event)"
        formControlName="subscaleId"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySubscale">
        <mat-option *ngFor="let subscale of subscales | filter: subscaleField" [value]="subscale">
          <div [matTooltip]="subscale.title">
            <p class="report-scale-title-text">{{ subscale.title }}</p>
            <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Weight</mat-label>
      <input matInput type="number" step="1" formControlName="weight" required />
    </mat-form-field>
    <mat-button-toggle-group formControlName="side">
      <mat-button-toggle value="LEFT">Left</mat-button-toggle>
      <mat-button-toggle value="RIGHT">Right</mat-button-toggle>
    </mat-button-toggle-group>
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="onAddSubscale()"
      [disabled]="form.get('subscale').invalid || ((addedSubscales['LEFT'] && addedSubscales['LEFT'].length > 0) && (addedSubscales['RIGHT'] && addedSubscales['RIGHT'].length > 0))"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <div class="scale-form-group superscale" *ngIf="!useSubscale" formGroupName="superscale">
    <mat-form-field>
      <mat-label>Superscale</mat-label>
      <input
        matInput
        required
        type="text"
        (ngModelChange)="this.superscaleFieldUpdate.next($event)"
        formControlName="superscaleId"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySuperscale">
        <mat-option *ngFor="let superscale of superscales | filter: superscaleField" [value]="superscale">
          <div [matTooltip]="superscale.title">
            <p class="report-scale-title-text">{{ superscale.title }}</p>
            <p *ngIf="superscale.description" class="small-text">{{ superscale.description }}</p>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-button-toggle-group formControlName="side">
      <mat-button-toggle value="LEFT">Left</mat-button-toggle>
      <mat-button-toggle value="RIGHT">Right</mat-button-toggle>
    </mat-button-toggle-group>
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="onAddSuperscale()"
      [disabled]="form.get('subscale').invalid"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <div class="added-subscales" *ngIf="useSubscale">
    <div>
      <h3>Left Subscales</h3>
      <table>
        <colgroup>
          <col class="name" />
          <col class="weight" />
          <col class="actions" />
        </colgroup>
        <thead>
          <tr>
            <th>Subscale</th>
            <th>Weight</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let subscale of addedSubscales['LEFT']">
            <td>{{ subscale.title }}</td>
            <td>{{ subscale.weight }}</td>
            <td>
              <button mat-flat-button color="accent" (click)="onDeleteSubscale('LEFT', subscale.id)">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <h3>Right Subscales</h3>
      <table>
        <colgroup>
          <col class="name" />
          <col class="weight" />
          <col class="actions" />
        </colgroup>
        <thead>
          <tr>
            <th>Subscale</th>
            <th>Weight</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let subscale of addedSubscales['RIGHT']">
            <td>{{ subscale.title }}</td>
            <td>{{ subscale.weight }}</td>
            <td>
              <button mat-flat-button color="accent" (click)="onDeleteSubscale('RIGHT', subscale.id)">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="added-superscales" *ngIf="!useSubscale">
    <div>
      <h3>Left Superscales</h3>
      <table>
        <colgroup>
          <col class="name" />
          <col class="actions" />
        </colgroup>
        <thead>
          <tr>
            <th>Superscale</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let superscale of addedSuperscales['LEFT']">
            <td>{{ superscale.title }}</td>
            <td>
              <button mat-flat-button color="accent" (click)="onDeleteSuperscale('LEFT', superscale.id)">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <h3>Right Superscales</h3>
      <table>
        <colgroup>
          <col class="name" />
          <col class="actions" />
        </colgroup>
        <thead>
          <tr>
            <th>Superscale</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let superscale of addedSuperscales['RIGHT']">
            <td>{{ superscale.title }}</td>
            <td>
              <button mat-flat-button color="accent" (click)="onDeleteSuperscale('RIGHT', superscale.id)">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between; align-items: center">
    <h2>Default Sentences Toggle</h2>
    <mat-button-toggle-group class="default-sentence-toggle" [formControl]="form.get('defaultSentenceEnabled')">
      <mat-button-toggle [value]="true">Enable</mat-button-toggle>
      <mat-button-toggle [value]="false">Disable</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="double-ranges-header">
    <h2>Double Ranges</h2>
    <button mat-flat-button color="primary" (click)="addDoubleRange()" [disabled]="form.get('doubleRange').invalid">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="default-sentence">
    <h3>Default Sentence</h3>
    <angular-editor
      [config]="editorConfig"
      [formControl]="form.get('doubleRangesDefaultSentence')"
    ></angular-editor>
  </div>
  <div class="double-range-field" formGroupName="doubleRange">
    <div class="ranges">
      <mat-form-field>
        <mat-label>From</mat-label>
        <input matInput formControlName="from1" type="number" min="0" required />
      </mat-form-field>
      <mat-form-field>
        <mat-label>To</mat-label>
        <input matInput formControlName="to1" type="number" min="0" required />
      </mat-form-field>
      <mat-form-field>
        <mat-label>From</mat-label>
        <input matInput formControlName="from2" type="number" min="0" required />
      </mat-form-field>
      <mat-form-field>
        <mat-label>To</mat-label>
        <input matInput formControlName="to2" type="number" min="0" required />
      </mat-form-field>
    </div>
    <div class="sentence" [formGroup]="form.get('doubleRange.sentence')">
      <mat-form-field>
        <mat-label>Sentence</mat-label>
        <mat-select [formControl]="form.get('doubleRange.sentence.option')">
          <mat-option value="he">He</mat-option>
          <mat-option value="she">She</mat-option>
          <mat-option value="they">They</mat-option>
        </mat-select>
      </mat-form-field>
      <angular-editor
        [formControl]="form.get('doubleRange.sentence.text').get(form.get('doubleRange.sentence.option').value)"
        [config]="editorConfig"
      ></angular-editor>
    </div>
  </div>

  <table class="added-double-ranges">
    <colgroup>
      <col class="num-col" />
      <col class="num-col" />
      <col class="num-col" />
      <col class="num-col" />
      <col />
      <col />
    </colgroup>
    <thead>
      <tr>
        <th colspan="2">{{ form.value.useSubscale ? 'Subscale' : 'Superscale' }} Left</th>
        <th colspan="2">{{ form.value.useSubscale ? 'Subscale' : 'Superscale' }} Right</th>
      </tr>
      <tr>
        <th>From</th>
        <th>To</th>
        <th>From</th>
        <th>To</th>
        <th>Text</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let doubleRange of addedDoubleRanges; let i = index">
        <td>{{ doubleRange.from1 }}</td>
        <td>{{ doubleRange.to1 }}</td>
        <td>{{ doubleRange.from2 }}</td>
        <td>{{ doubleRange.to2 }}</td>
        <div class="texts">
          <div [innerHTML]="doubleRange.text.he"></div>
          <div [innerHTML]="doubleRange.text.she"></div>
          <div [innerHTML]="doubleRange.text.they"></div>
        </div>
        <td>
          <button class="edit-button" mat-flat-button color="primary" (click)="onEditDoubleRange(i)">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-flat-button color="accent" (click)="removeDoubleRange(i)">
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</form>
