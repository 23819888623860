import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// components
import { ClientModalComponent } from '../client-modal/client-modal.component';

// services
import { CompanyService } from '@app/core/services/company.service';
import { UtilsService } from '@app/core/services/utils.service';

// constants
import { EVENT_CANCEL, EVENT_SUCCESS } from '@app/core/constants';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { UserCreateDialogComponent } from '@app/shared/components/user-create-dialog/user-create-dialog.component';
import { UserListTableComponent } from '@app/shared/components/user-list-table/user-list-table.component';
import { Company } from '@app/core/models/company.model';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
})
export class ClientDetailsComponent implements OnInit {
  @ViewChild(UserListTableComponent) private userListTableComponent: UserListTableComponent;
  companyId: number;
  client: Company;
  selected = new FormControl(0);
  active: number;
  tabs: string[] = ['Details', 'Test Groups', 'Assessments', 'Charts', 'Users', 'Departments', 'Statistics'];

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.selected.setValue(params.id ? +params.tab : 0);
    });

    this.companyId = Number(this.route.snapshot.params.id);
    this.getClientDetail();
  }

  tabChange(event) {
    this.selected.setValue(event);
    this.router.navigate([this.router.url.split('tab/')[0] + '/tab/' + event]);
  }

  onEdit() {
    const createNewDialog = this.dialog.open(ClientModalComponent, {
      data: { isEdit: true, companyId: this.companyId },
    });

    createNewDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.companyService.update(this.companyId, result.data).subscribe(
          (response) => {
            if (response) {
              this.snackBar.info('Client updated successfully.');
              this.getClientDetail();
            }
          },
          () => {
            this.snackBar.info('Something went wrong. Please, try again later.');
          },
        );
      }
    });
  }

  getClientDetail() {
    this.companyService.getCompanyDetail(this.companyId).subscribe((res) => {
      this.client = res;
      this.client.telephone = this.client.telephone ? `+${this.client.telephone.toString()}` : '';
    });
  }

  onCreateUser() {
    const createUserDialog = this.dialog.open(UserCreateDialogComponent, {
      height: '90vh',
      width: '55vw',
      data: { companyId: this.companyId },
    });

    createUserDialog.afterClosed().subscribe(
      (result) => {
        if (result && result.event === EVENT_SUCCESS) {
          this.snackBar.info('User created with success.');
          this.userListTableComponent.ngOnInit();
        }
      },
      () => {
        this.snackBar.info('Something went wrong, please try again later.');
      },
    );
  }

  // TODO: Refact. Methods wrong placed.
  onUsersExport() {
    this.companyService
      .getUsersDownloadByCompanyId(this.companyId)
      .subscribe((result: { fileName: string; fileMimeType: string; fileBase64Content: string }) => {
        this.downloadFile(result);
      });
  }

  downloadFile(data: { fileName: string; fileMimeType: string; fileBase64Content: string }) {
    const blob = this.utilsService.b64toBlob(data.fileBase64Content, data.fileMimeType);
    const fileName: string = data.fileName;
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }
}
