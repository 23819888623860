import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// services
import { CompanyService } from '@app/core/services/company.service';
import { UserService } from '@app/core/services/user.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { TestGroupService } from '@app/core/services/test-group.service';

// models
import { Company } from '@app/core/models/company.model';
import { User } from '@app/core/models/user.model';
import { UtilsService } from '@app/core/services/utils.service';
import { Report } from '@app/core/models/report.model';
import { ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TGM } from '@app/core/constants';

@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.scss'],
})
export class ReportDashboardComponent implements OnInit, AfterViewInit, OnChanges {
  reportDashboardForm = new FormGroup({
    company: new FormControl('', Validators.required),
    user: new FormControl('', Validators.required),
  });
  companies: Company[] = [];
  users: User[] = [];
  reportResult: any;
  company: Company;
  userInfoId: number;
  tabSelected = 0;
  filteredCompanies: Observable<Company[]>;
  filteredUsers: Observable<User[]>;
  randomColors = [
    'skyblue',
    'moccasin',
    'coral',
    'darkolivegreen',
    'yellowgreen',
    'gold',
    'cadetblue',
    'crimson',
    'cornflowerblue',
    'darkgrey',
    'darkseagreen',
    'deeppink',
  ];
  randomColor: string;
  role: string;
  currentTabIndex = -1;
  reports: Report[];
  isAdmin: boolean = false;
  isOA: boolean = false;
  isTgm: boolean = false;
  allowRawScoresAccess: boolean = false;

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private testGroupService: TestGroupService,
    private utilsService: UtilsService,
    public snackBar: SnackBarService,
    private cdr: ChangeDetectorRef,
  ) {
    const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
    if (authorities.includes(ROLE_ADMIN)) {
      this.isAdmin = true;
    } else if (authorities.includes(ROLE_ORGANIZATIONAL_ADMIN)) {
      this.isOA = true;
    } else if (authorities.includes(ROLE_TGM)) {
      this.isTgm = true;
    }
  }

  ngOnInit(): void {
    this.companyService.getAll().subscribe((companies) => {
      this.companies = companies;
      this.filteredCompanies = this.reportDashboardForm.controls['company'].valueChanges.pipe(
        startWith(''),
        map((company) => (company ? this._filter(company) : this.companies.slice())),
      );
      this.actionsForTgm();
    });
  }

  private _filter(value: string): Company[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.companies;
    }
    return this.companies.filter((company) => company.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
  }

  private _filterUser(value: string): User[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.users;
    }
    return this.users.filter(
      (user) =>
        user.firstName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
        [user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase()].indexOf(filterValue.toLowerCase()) > -1,
    );
  }

  displayFn(company: Company): string {
    return company ? company.name : '';
  }

  displayFnUser(user: User): string {
    return user ? user.firstName + ' ' + user.lastName : '';
  }

  setCompany(company: Company) {
    this.company = company;
    this.companyService.getById(this.company.id).subscribe((company) => {
      this.allowRawScoresAccess = +company.allowRawScoresAccess === 1 ? true : false;
    });
    this.loadUsers(company);
  }

  loadUsers(company: Company) {
    this.companyService.getUsersById(company.id).subscribe((users) => {
      this.users = users.filter((current, i, arr) => arr.findIndex((t) => t.id === current.id) === i);

      this.filteredUsers = this.reportDashboardForm.controls['user'].valueChanges.pipe(
        startWith(''),
        map((user) => (user ? this._filterUser(user) : this.users.slice())),
      );
    });
  }

  onSelectUser() {
    if (this.reportDashboardForm.value.user.id) {
      this.userInfoId = Number(this.reportDashboardForm.value.user.id);

      this.findReportResultsByUserInfoId(this.userInfoId);
    }
  }

  findReportResultsByUserInfoId(userInfoId: number) {
    this.userService.getReportResult(userInfoId).subscribe((reportResult) => {
      this.reportResult = reportResult;

      if (this.reportResult && this.reportResult.tests) {
        Object.keys(this.reportResult.tests).map((title) => {
          this.randomColor = this.getRandomColor();
          const colorSubscale = this.reportResult.tests[title].filter((subscale) => subscale.color !== null);
          colorSubscale && colorSubscale.length > 0
            ? this.addSubscaleColor(title, colorSubscale[0].color.toLowerCase())
            : this.addSubscaleColor(title, this.randomColor);
        });
      }
    });
  }

  addSubscaleColor(title: string, color: string) {
    this.reportResult.tests[title].map((subscale) => {
      subscale.color = color;
    });
  }

  getRandomColor() {
    return this.randomColors[Math.floor(Math.random() * this.randomColors.length)];
  }

  deleteUsersScores() {
    this.userService.deletedByUserInfoId(this.userInfoId).subscribe((res) => {
      if (res) {
        this.snackBar.info('All scores of user deleted successfully');
        this.findReportResultsByUserInfoId(this.userInfoId);
      }
    });
  }

  updateUsersScores() {
    this.testGroupService.getTestGroupUsersByUserInfoId(this.userInfoId).subscribe((testGroupUsers) => {
      for (const testGroupUser of testGroupUsers) {
        this.userService.updateUserScores([this.userInfoId], testGroupUser.testGroupId).subscribe();
      }

      this.snackBar.info('All user scores updated successfully');
      this.findReportResultsByUserInfoId(this.userInfoId);
    });
  }

  downloadUsersScores() {
    this.userService
      .getUserScoresDownloadByUserInfoId(this.userInfoId)
      .subscribe((result: { fileName: string; fileMimeType: string; fileBase64Content: string }) => {
        this.downloadFile(result);
      });
  }

  downloadFile(data: { fileName: string; fileMimeType: string; fileBase64Content: string }) {
    const blob = this.utilsService.b64toBlob(data.fileBase64Content, data.fileMimeType);
    const fileName: string = data.fileName;
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  private actionsForTgm(): void {
    if (this.isTgm) {
      if (this.companies[0].id) {
        this.reportDashboardForm.get('company').setValue(this.companies[0]);
        this.setCompany(this.companies[0]);
      }
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnChanges() {}

  switchTab(tabIndex: number, testGroupId: number) {
    this.currentTabIndex = tabIndex;
    this.testGroupService.getAllReportById(testGroupId).subscribe((reports: Report[]) => {
      this.reports = reports;
    });
  }

  onSelectReport(userInfoId: number, reportId: number, testGroupId: number) {
    window.open('/user/' + userInfoId + '/test-group/' + testGroupId + '/report/' + reportId);
  }
}
