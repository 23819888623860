/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Company } from '../models/Company';
import type { Department } from '../models/Department';
import type { Invite } from '../models/Invite';
import type { Level } from '../models/Level';
import type { PsyTestGroup } from '../models/PsyTestGroup';
import type { TestGroup } from '../models/TestGroup';
import type { User } from '../models/User';
import type { UserConnection } from '../models/UserConnection';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class CompanyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a list with all companies from start to end
     * @returns Company A list of companies from start to end
     * @throws ApiError
     */
    public getCompany(): Observable<Array<Company>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company',
            errors: {
                404: `Company not found`,
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a company
     * Create a company
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postCompany(
        requestBody?: {
            /**
             * Name of the company
             */
            name: string;
            /**
             * City of the company
             */
            city: string;
            /**
             * Zip code of the company
             */
            zipCode: number;
            /**
             * Address of the company
             */
            address: string;
            /**
             * Active of the company
             */
            active: boolean;
            /**
             * State id of the company
             */
            stateId: number;
            /**
             * Email of the company
             */
            email: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a company by id
     * Retrieve a company by id
     * @param id Id of the company
     * @returns Company A company
     * @throws ApiError
     */
    public getCompany1(
        id: number,
    ): Observable<Company> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Company not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update an company
     * Update an company
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchCompany(
        id: number,
        requestBody?: {
            /**
             * name of company
             */
            name: number;
            /**
             * Address of the company
             */
            address: string;
            /**
             * Address 2 of the company
             */
            address2?: string;
            /**
             * City of the company
             */
            city: string;
            /**
             * Zip code of the company
             */
            zipCode: number;
            /**
             * Telephone of the company
             */
            telephone: number;
            /**
             * Fax of the company
             */
            fax?: number;
            /**
             * active of the company
             */
            active: boolean;
            /**
             * default language of the company
             */
            defaultLanguage?: string;
            /**
             * date added of the company
             */
            dateAdded?: string;
            /**
             * State id of the company
             */
            stateId: number;
            /**
             * Time Zone id of the company
             */
            timeZoneId?: number;
            /**
             * Billing Address of the company
             */
            billingAddress?: string;
            /**
             * Billing Address 2 of the company
             */
            billingAddress2?: string;
            /**
             * Billing City of the company
             */
            billingCity?: string;
            /**
             * Billing State Id of the company
             */
            billingStateId?: string;
            /**
             * Billing Zip Code of the company
             */
            billingZipCode?: string;
            /**
             * Email of the company
             */
            email: string;
            /**
             * Email of the company
             */
            skype?: string;
            /**
             * Allow Post Plan Access of the company
             */
            allowPostPlanAccess?: number;
            /**
             * Subscription Test Takers of the company
             */
            subscriptionTestTakers?: number;
            /**
             * Subscription Time of the company
             */
            subscriptionTime?: string;
            /**
             * Deleted of the company
             */
            deleted?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/company/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Company not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a company
     * Delete a company
     * @param id Id of the company
     * @returns any Company deleted successfully
     * @throws ApiError
     */
    public deleteCompany(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/company/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a company by id
     * Retrieve a company by id
     * @param id Id of the company
     * @returns Company A company
     * @throws ApiError
     */
    public getCompanyDetail(
        id: number,
    ): Observable<Company> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/detail',
            path: {
                'id': id,
            },
            errors: {
                404: `Company not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all users by id
     * Retrieve all users by id
     * @param id Id of the Company
     * @returns User A list of users
     * @throws ApiError
     */
    public getCompanyUser(
        id: number,
    ): Observable<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/user',
            path: {
                'id': id,
            },
            errors: {
                404: `User not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all users and invites by id
     * Retrieve all users and invites by id
     * @param id Id of the Company
     * @returns Invite A list of invites
     * @throws ApiError
     */
    public getCompanyAllUser(
        id: number,
    ): Observable<Invite> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/all-user',
            path: {
                'id': id,
            },
            errors: {
                404: `Invites not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all departments by id
     * Retrieve all departments by id
     * @param id Id of the Company
     * @returns Department A list of Departments
     * @throws ApiError
     */
    public getCompanyDepartment(
        id: number,
    ): Observable<Department> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/department',
            path: {
                'id': id,
            },
            errors: {
                404: `Departments not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve one department by id and departmentId
     * Retrieve one department by id and departmentId
     * @param id Id of the department
     * @param departmentId departmentId of the department
     * @returns Department A department
     * @throws ApiError
     */
    public getDepartmentDepartment(
        id: number,
        departmentId: number,
    ): Observable<Department> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/department/{id}/department/{departmentId}',
            path: {
                'id': id,
                'departmentId': departmentId,
            },
            errors: {
                404: `Department not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update an department
     * Update an department
     * @param id
     * @param departmentId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchCompanyDepartment(
        id: number,
        departmentId: number,
        requestBody?: {
            /**
             * name of department
             */
            name?: number;
            /**
             * City of the department
             */
            city: string;
            /**
             * Zip code of the department
             */
            zipCode: number;
            /**
             * Address of the company
             */
            address: string;
            /**
             * State id of the company
             */
            stateId: number;
            /**
             * Company id of the company
             */
            companyId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/company/{id}/department/{departmentId}',
            path: {
                'id': id,
                'departmentId': departmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Department not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a department
     * Delete a department
     * @param id Id of the company
     * @param departmentId Id of the department
     * @returns any Department deleted successfully
     * @throws ApiError
     */
    public deleteCompanyDepartment(
        id: number,
        departmentId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/company/{id}/department/{departmentId}',
            path: {
                'id': id,
                'departmentId': departmentId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all level by id
     * Retrieve all level by id
     * @param id Id of the company
     * @returns Level A list of levels
     * @throws ApiError
     */
    public getCompanyLevel(
        id: number,
    ): Observable<Level> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/level',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of levels not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a level by id
     * Retrieve a level by id
     * @param id Id of the company
     * @param levelId Id of the level
     * @returns Level A level
     * @throws ApiError
     */
    public getCompanyLevel1(
        id: number,
        levelId: number,
    ): Observable<Level> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/level/{levelId}',
            path: {
                'id': id,
                'levelId': levelId,
            },
            errors: {
                404: `A level not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a Invite
     * Create a Invite
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postCompanyInvite(
        id: number,
        requestBody?: {
            /**
             * Company id of the invite
             */
            companyId?: number;
            /**
             * test group id of the invite
             */
            psyTestGroupId?: number;
            /**
             * active of the invite
             */
            active?: boolean;
            /**
             * token of the invite
             */
            token?: string;
            /**
             * Email of the invite
             */
            email?: string;
            /**
             * First Name of the invite
             */
            firstName?: string;
            /**
             * Last Name of the invite
             */
            lastName?: string;
            /**
             * Invited of the invite
             */
            invited?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/{id}/invite',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all user connection by id
     * Retrieve all user connection by id
     * @param id Id of the company
     * @returns UserConnection A list of user connections
     * @throws ApiError
     */
    public getCompanyUserConnection(
        id: number,
    ): Observable<UserConnection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/user-connection',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of user connections not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all user connection info by id
     * Retrieve all user connection info by id
     * @param id Id of the company
     * @returns UserConnection A list of user connection infos
     * @throws ApiError
     */
    public getCompanyUserConnectionInfo(
        id: number,
    ): Observable<UserConnection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/user-connection-info',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of user connection infos not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all test group from start to end
     * Retrieve all test group from start to end
     * @param id Id of the company
     * @param start The date of start
     * @param end The date of start
     * @returns PsyTestGroup A list of test groups
     * @throws ApiError
     */
    public getCompanyTestGroupFromTo(
        id: number,
        start?: string,
        end?: string,
    ): Observable<PsyTestGroup> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/test-group/from/{start}/to/{end}',
            path: {
                'id': id,
                'start': start,
                'end': end,
            },
            errors: {
                404: `a list of test groups not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all test group by id
     * Retrieve all test group by id
     * @param id Id of the company
     * @returns TestGroup A list of test groups
     * @throws ApiError
     */
    public getCompanyTestGroup(
        id: number,
    ): Observable<TestGroup> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/test-group',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of test groups not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Download all user by company id
     * Download all user by company id
     * @param id Id of the company
     * @returns User A list of test groups
     * @throws ApiError
     */
    public getCompanyUsersDownload(
        id: number,
    ): Observable<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/users-download',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of users not download`,
                500: `Internal error`,
            },
        });
    }

}
