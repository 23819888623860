import { Component, EventEmitter, Input, Output } from '@angular/core';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';

@Component({
  selector: 'app-decision-table-accept',
  templateUrl: './decision-table-accept.component.html',
  styleUrls: ['./decision-table-accept.component.scss'],
})
export class DecisionTableAcceptComponent {
  @Input() decisionTable: DecisionTable;
  @Input() decisionTableAnswersToAccept: any[] = [];
  @Output() answer = new EventEmitter<any>();

  constructor(private decisionTableService: DecisionTableService) {}

  getFormName(columnTitle: string): string {
    return this.decisionTableService.getFormName(columnTitle);
  }

  onAnswer(action: boolean, decisionTableAnswerId: number, userName: string) {
    this.answer.emit({ decisionTableAnswerId, action, userName });
  }
}
