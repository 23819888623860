<h3>{{ title }}</h3>
<mat-divider></mat-divider>
<form [formGroup]="otherCompanyUserForm" (submit)="onSave()" class="pt-2">
  <mat-form-field>
    <mat-label>Clients</mat-label>
    <input matInput type="text" formControlName="company" [matAutocomplete]="auto" />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="setCompany($event.option.value)"
    >
      <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
        {{ company.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="otherCompanyUserForm.get('company').errors">Please, enter a valid client.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Users</mat-label>
    <input matInput type="text" formControlName="user" [matAutocomplete]="autoUser" />
    <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFnUser">
      <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
        {{ user.firstName }} {{ user.lastName }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="otherCompanyUserForm.get('user').errors">Please, enter a valid user.</mat-error>
  </mat-form-field>
  <div class="mt-2">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon inline="true">block</mat-icon>
      <span class="ml-1">Cancel</span>
    </button>
    <button mat-button class="btn-success btn-300" type="submit">
      <mat-icon inline="true">save_alt</mat-icon>
      <span class="ml-1">Save</span>
    </button>
  </div>
</form>
