<div *ngIf="visible" class="row">
  <div [ngClass]="isSubcalesX || isSubcalesY ? 'column' : 'row'">
    <div *ngIf="visible" [ngClass]="isSubcalesX || isSubcalesY ? '' : 'side-by-side'" class="image-block">
      <img [src]="src" alt="" class="image" />
    </div>
    <div
      [ngClass]="isSubcalesX || isSubcalesY ? '' : 'side-by-side vertical-center'"
      style="margin-bottom: 2rem"
      *ngIf="description"
      [innerText]="description"
    ></div>
  </div>
  <div class="column subcales" *ngIf="userId">
    <app-bar-subscales-new
      *ngIf="isSubcalesX"
      [chartId]="chartId"
      [userId]="userId"
      [testGroupId]="testGroupId"
      [XSubscale]="true"
    ></app-bar-subscales-new>
    <app-bar-subscales-new
      *ngIf="isSubcalesY"
      [chartId]="chartId"
      [userId]="userId"
      [testGroupId]="testGroupId"
      [YSubscale]="true"
    ></app-bar-subscales-new>
  </div>
</div>
