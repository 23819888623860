import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartDataSets, ChartLineOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnChanges {
  @Input() chartData: number[];
  @Input() chartLabels: string[];
  @Input() label: string;

  public lineChartData: ChartDataSets[];
  public lineChartLabels: Label[] = [];

  // Define chart options
  lineChartOptions: ChartLineOptions = {
    fill: false,
  };

  // Define colors of chart segments
  lineChartColors: Color[] = [
    {
      // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
    },
    {
      // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
    },
  ];

  // Set true to show legends
  lineChartLegend = false;

  // Define type of chart
  lineChartType = 'line';

  lineChartPlugins = [];

  constructor() {}

  ngOnInit(): void {
    if (this.chartData && this.chartLabels) {
      this.lineChartData = [{ data: this.chartData, label: this.label }];

      // Labels shown on the x-axis
      this.lineChartLabels = this.chartLabels;
    }
  }

  // events
  chartClicked({ event, active }: { event: MouseEvent; active: {}[] }): void {}

  chartHovered({ event, active }: { event: MouseEvent; active: {}[] }): void {}

  ngOnChanges() {
    if (this.chartData && this.chartLabels) {
      this.lineChartData = [{ data: this.chartData, label: this.label }];

      // Labels shown on the x-axis
      this.lineChartLabels = this.chartLabels;
    }
  }
}
