<div>
  <div class="userInvited_counter_panel">
    <div style="flex: 0 0 50%">
      <div>Users Invited</div>
      <div style="font-size: 36px; margin-top: 12px">{{ overAllUserInvited }}</div>
    </div>
    <div style="flex: 0 0 50%">
      <div style="display: flex; flex-direction: column">
        <span>Registered</span>
        <app-progress-bar-determinate
          [minValue]="overAllRegistered"
          [maxValue]="overAllUserInvited"
          color="registered"
        ></app-progress-bar-determinate>
      </div>
      <div style="margin-top: 12px; display: flex; flex-direction: column">
        <span>Completed</span>
        <app-progress-bar-determinate
          [minValue]="overAllCompleted"
          [maxValue]="overAllUserInvited"
          color="completed"
        ></app-progress-bar-determinate>
      </div>
      <div style="margin-top: 12px; display: flex; flex-direction: column">
        <span>In Progress</span>
        <app-progress-bar-determinate
          [minValue]="overAllInProgress"
          [maxValue]="overAllUserInvited"
          color="inprogress"
        ></app-progress-bar-determinate>
      </div>
    </div>
  </div>
</div>

<div style="display: flex; flex: 0 0 100%; flex-wrap: wrap">
  <div *ngFor="let item of psyTestGroupStatistics">
    <mat-card class="example-card">
      <mat-card-title>{{ item.title }}</mat-card-title>
      <mat-card-content>
        <div style="flex: 0 0 100%; display: flex">
          <div style="flex: 0 0 50%">
            <div>Users Invited</div>
            <div style="font-size: 36px; margin-top: 12px">{{ item.totalUsers + item.totalInvited }}</div>

            <div style="margin-top: 5px">Tests Available</div>
            <div style="font-size: 36px; margin-top: 12px">{{ item.testAvailable }}</div>
          </div>

          <div style="flex: 0 0 50%">
            <div style="display: flex; flex-direction: column">
              <span>Registered</span>
              <app-progress-bar-determinate
                [minValue]="item.totalUsers"
                [maxValue]="calculateTotalUser(item)"
                color="registered"
              ></app-progress-bar-determinate>
            </div>
            <div style="margin-top: 12px; display: flex; flex-direction: column">
              <span>Completed</span>
              <app-progress-bar-determinate
                [minValue]="item.totalCompleted"
                [maxValue]="calculateTotalUser(item)"
                color="completed"
              ></app-progress-bar-determinate>
            </div>
            <div style="margin-top: 12px; display: flex; flex-direction: column">
              <span>In Progress</span>
              <app-progress-bar-determinate
                minValue="{{ item.totalUsers - item.totalCompleted }}"
                [maxValue]="calculateTotalUser(item)"
                color="inprogress"
              ></app-progress-bar-determinate>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
