<h1 mat-dialog-title>Notification {{ notification.id }}</h1>
<mat-divider></mat-divider>
<div class="row">
  <div class="column">
    <span class="label">Webhook</span>
    <p>{{ notification.webhook }}</p>
    <span class="label">Chanel</span>
    <p>{{ notification.chanel }}</p>
  </div>
</div>

<div class="close-button">
  <button mat-button (click)="onClose()">Close</button>
</div>
