/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Chart } from '../models/Chart';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ChartSubscaleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a list with all chart subscales
     * Retrieve a list with all chart subscales
     * @param id Id of the chart
     * @returns Chart A list of chart subscales
     * @throws ApiError
     */
    public getChartSubscale(
        id: number,
    ): Observable<Array<Chart>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{id}/subscale',
            path: {
                'id': id,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Add a subscale to a chart
     * Add a subscale to a chart
     * @param id Id of the chart
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postChartSubscale(
        id: number,
        requestBody?: {
            /**
             * Id of the subscale
             */
            subscaleId: number;
            /**
             * Axis of the chart
             */
            axis: number;
            /**
             * Weight of the chart
             */
            weight: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/chart/{id}/subscale',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Error while creating a chart subscale`,
                404: `Chart not found`,
            },
        });
    }

    /**
     * Update a chart subscale
     * Update a chart subscale
     * @param chartSubscaleId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchChartSubscale(
        chartSubscaleId: number,
        requestBody?: {
            /**
             * Axis of the chart subscale
             */
            axis: number;
            /**
             * Weight of the chart subscale
             */
            weight: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/chart/subscale/{chartSubscaleId}',
            path: {
                'chartSubscaleId': chartSubscaleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `You have to specify a type`,
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a chart subscale
     * Delete a chart subscale
     * @param chartSubscaleId Id of the chart subscale
     * @returns any Chart subscale deleted successfully
     * @throws ApiError
     */
    public deleteChartSubscale(
        chartSubscaleId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/chart/subscale/{chartSubscaleId}',
            path: {
                'chartSubscaleId': chartSubscaleId,
            },
            errors: {
                404: `Chart subscale not found`,
                500: `Internal error`,
            },
        });
    }

}
