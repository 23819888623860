import { Component, Input, OnInit } from '@angular/core';
import { PsyChart } from '@app/core/models/psy-chart.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChartService } from '@app/core/services/chart.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { MatTableDataSource } from '@angular/material/table';
import { Subscale } from '@app/core/models/subscale.model';
import { Superscale } from '@app/core/models/superscale.model';
import { Observable, of, Subject } from 'rxjs';
import { SuperscaleService } from '@app/core/services/superscale.service';
import { ApiV2Client, BlossomChartPetal, BlossomChartService } from '../../../../../../generated/v2';
import { Color } from '@angular-material-components/color-picker';
import { hexToColor } from '@app/admin/pages/chart/blossom/hex-to-color';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BlossomPetalFormComponent } from '@app/admin/pages/chart/blossom/blossom-petal-setup/blossom-petal-form/blossom-petal-form.component';

@Component({
  selector: 'app-blossom-petal-setup',
  templateUrl: './blossom-petal-setup.component.html',
  styleUrls: ['./blossom-petal-setup.component.scss'],
})
export class BlossomPetalSetupComponent implements OnInit {
  @Input() chart: PsyChart;

  matTableDataSource$: Observable<MatTableDataSource<BlossomChartPetal>>;

  superscales: Superscale[] = [];

  displayColumns = ['order', 'label', 'color', 'score', 'actions'];

  refetch$ = new Subject<true>();

  constructor(
    private blossomChart: BlossomChartService,
    private superscaleService: SuperscaleService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.matTableDataSource$ = this.refetch$.pipe(
      startWith(true),
      switchMap(() => this.blossomChart.getChartPetal(this.chart.id)),
      map((v) => new MatTableDataSource(v)),
    );

    this.superscaleService.getAll().subscribe((superscales) => {
      this.superscales = superscales;
    });
  }

  onAddPetal() {
    this.dialog
      .open(BlossomPetalFormComponent, { data: { superscales: this.superscales } })
      .afterClosed()
      .subscribe(({ value }) => {
        this.blossomChart.postChartPetal(this.chart.id, value).subscribe(() => {
          this.refetch$.next(true);
        });
      });
  }

  onEditPetal(petal: BlossomChartPetal) {
    this.dialog
      .open(BlossomPetalFormComponent, { data: { petal, superscales: this.superscales } })
      .afterClosed()
      .subscribe(({ value }) => {
        this.blossomChart.putChartPetal(this.chart.id, petal.id, value).subscribe(() => {
          this.refetch$.next(true);
        });
      });
  }

  onDeletePetal(petal: BlossomChartPetal) {
    this.blossomChart.deleteChartPetal(this.chart.id, petal.id).subscribe(() => {
      this.refetch$.next(true);
    });
  }

  displaySuperscaleFn = (id: number): string => {
    const superscale = this.superscales.find((s) => s.id === id);

    if (superscale) {
      return superscale.title;
    }

    return '';
  };
}
