import { Component, EventEmitter, Input, OnChanges, Output, ChangeDetectorRef } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

// models
import { PsbItem } from '@app/core/models/psb-item.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SubscaleService } from '../../../../generated/v2';

@Component({
  selector: 'app-psb-list',
  templateUrl: './psb-list.component.html',
  styleUrls: ['./psb-list.component.scss'],
})
export class PsbListComponent implements OnChanges {
  @Output() sortChild = new EventEmitter<[PsbItem, CdkDragDrop<any, any>]>();
  @Output() sort = new EventEmitter<CdkDragDrop<any, any>>();

  @Input() userInvite?: boolean;
  @Input() items: PsbItem[];
  @Input() columnNumber?: number;

  @Input() itemsPerPage: number;
  @Input() showSearch?: boolean;
  @Input() textSize?: string;

  @Input() deleteLabel?: string;
  @Input() editLabel?: string;
  @Input() viewLabel?: string;
  @Input() dashboardLabel?: string;
  @Input() typeOfView?: string;
  @Input() isIntegerAnswer?: boolean;
  @Input() archiveLabel?: string;

  @Output() delete = new EventEmitter<number>();
  @Output() archive = new EventEmitter<number>();
  @Output() edit = new EventEmitter<number>();
  @Output() view = new EventEmitter<number>();
  @Output() dashboard = new EventEmitter<number>();
  @Output() copyEvent = new EventEmitter<number>();
  @Output() testResult = new EventEmitter<number>();
  @Output() ranges = new EventEmitter<number>();
  @Output() percentiles = new EventEmitter<number>();

  @Input() optionButton: string;
  @Input() options: { id: number; title: string }[];
  @Input() isTGM?: boolean;
  @Output() selectOption = new EventEmitter<any>();
  @Input() isExpansionPanel: boolean;
  @Output() testScaleVisible = new EventEmitter<any>();

  config: any;
  searchTerm: string;
  id: string;
  searchFieldUpdate = new Subject<string>();
  searchField: string;
  filteredItems: any[];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.id = this.newId();
    this.searchFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.searchTerm = value;
      this.filteredItems = this.searchTerm
        ? this.items.filter(
            (item: PsbItem) =>
              item.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1 ||
              (item.email && item.email.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1),
          )
        : this.items;
      if (this.filteredItems && this.filteredItems.length > 0) {
        this.config.currentPage = 1;
      }
      this.setConfig();
    });
  }

  newId() {
    return Math.random().toString(36).substr(2, 9);
  }

  onView(id: number) {
    this.view.emit(id);
  }

  onEdit(id: number) {
    this.edit.emit(id);
  }

  onDashboard(id: number) {
    this.dashboard.emit(id);
  }

  onDelete(id: number) {
    this.delete.emit(id);
  }

  onCopy(id: number) {
    this.copyEvent.emit(id);
  }

  onViewTestResult(id: number) {
    this.testResult.emit(id);
  }

  onViewRanges(id: number) {
    this.ranges.emit(id);
  }

  onViewPercentiles(element: any) {
    this.percentiles.emit(element);
  }

  onArchive(element: any) {
    this.archive.emit(element);
  }

  onSelectOption(psbItemId: number, id: number) {
    this.selectOption.emit({ psbItemId, id });
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  ngOnChanges(): void {
    this.filteredItems = this.items;
    this.setConfig();
    this.changeDetectorRef.detectChanges();
  }

  setConfig(): void {
    this.config = {
      id: this.id,
      itemsPerPage: this.itemsPerPage ? this.itemsPerPage : 10,
      currentPage: typeof this.config !== 'undefined' ? this.config.currentPage : 1,
      totalItems: this.filteredItems ? this.filteredItems.length : 0,
    };
  }

  sortTitleData(sort: Sort) {
    const data = this.items.slice();

    this.items = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title':
          return this.compare(a.title, b.title, isAsc);
        default:
          return 0;
      }
    });
  }

  sortSubscaleData(sort: Sort) {
    const data = this.items.slice();

    this.items = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'subscale':
          return this.compare(a.subscaleTitle, b.subscaleTitle, isAsc);
        default:
          return 0;
      }
    });
  }

  sortExtraColumnsData(sort: Sort, index: number) {
    const data = this.items.slice();

    this.items = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case a.extraColumns[index].key:
          return this.compare(a.extraColumns[index].value, b.extraColumns[index].value, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getToolTip(arrayOfWords) {
    let toolTip = '';

    for (let i = 0; i < arrayOfWords.length; i++) {
      toolTip = toolTip + ' ' + arrayOfWords[i].title + ' ' + arrayOfWords[i].dateCompleted;
    }

    return toolTip;
  }

  onTestScaleVisibleChange(index: number, event: any) {
    const visible = event.value;
    this.testScaleVisible.emit({ index, visible });
  }

  onSortChild($event: CdkDragDrop<any, any>, psbItem: PsbItem) {
    this.sortChild.emit([psbItem, $event]);
  }

  onSort($event: CdkDragDrop<any, any>) {
    this.sort.emit($event);
  }
}
