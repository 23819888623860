import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Output() update = new EventEmitter<any>();
  @Input() start: Date;
  @Input() end: Date;

  dateFromGroup: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.dateFromGroup = new FormGroup({
      start: new FormControl(this.start),
      end: new FormControl(this.end),
    });
  }

  updateDate(inputName: string) {
    this.update.emit({
      name: inputName,
      date: this.dateFromGroup.get(inputName).value,
    });
  }
}
