<div class="container">
  <app-psb-page-navigator [pages]="pageService.getConnectionIndicatorPages()"></app-psb-page-navigator>

  <h2>Question Groups</h2>
  <p>All the questions that belong to the same Question Group will be showed on the same screen.</p>
  <div *ngFor="let questionGroup of questionGroups">
    <app-question-group [questionGroup]="questionGroup" (delete)="onDeleteQuestionGroup($event)"></app-question-group>
  </div>

  <form *ngIf="creatingNewQuestionGroup" [formGroup]="newQuestionGroupForm" (ngSubmit)="saveQuestionGroup()">
    <mat-card>
      <h3>New Question Group</h3>
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title" />
        <mat-error *ngIf="newQuestionGroupForm.get('title').errors">Please, enter a valid text.</mat-error>
      </mat-form-field>

      <angular-editor type="text" formControlName="instructions" [config]="editorConfig"></angular-editor>
      <mat-error *ngIf="newQuestionGroupForm.get('instructions').errors">Please, enter a valid text.</mat-error>
      <mat-hint>The instructions is a optional introdutory text to the questions on the question group.</mat-hint>

      <button mat-button type="submit" mat-raised-button class="btn-primary btn-center btn-300">Save</button>
    </mat-card>
  </form>

  <button
    mat-raised-button
    class="btn-success btn-center btn-300"
    (click)="createNewQuestionGroup()"
    *ngIf="!creatingNewQuestionGroup"
  >
    <mat-icon>add</mat-icon>New Question Group
  </button>

  <mat-divider></mat-divider>
  <h2>Questions</h2>

  <div *ngFor="let question of getSavedQuestions(); let i = index">
    <app-question
      [question]="question"
      [questions]="getSavedCardsortQuestions()"
      [showAcl]="true"
      (delete)="onDeleteQuestion($event)"
      [questionGroups]="questionGroups"
      (edit)="onEditQuestion($event)"
    >
    </app-question>
  </div>

  <form *ngIf="creatingNewQuestion" [formGroup]="newQuestionForm" (ngSubmit)="addQuestion()">
    <mat-card>
      <h3>New Question</h3>
      <p>The questions to Connections Indicator will represent the connections between the users.</p>
      <mat-form-field>
        <mat-label>Text</mat-label>
        <input matInput type="text" formControlName="text" />
        <mat-error *ngIf="newQuestionForm.get('text').errors">Please, enter a valid text.</mat-error>
        <mat-hint>Enter the question.</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput type="number" formControlName="weight" />
        <mat-error *ngIf="newQuestionForm.get('weight').errors">Please, enter a valid weight.</mat-error>
        <mat-hint>The weight represent the importance of the connection.</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Network Type</mat-label>
        <p></p>
        <mat-select formControlName="networkType">
          <mat-option value="EXPERT">Expert</mat-option>
          <mat-option value="REFERENT">Referent</mat-option>
        </mat-select>
        <mat-error *ngIf="newQuestionForm.get('networkType').errors">Please, enter a valid weight.</mat-error>
        <mat-hint>The Network Type of the question is related to the type of the collaboration of the users.</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Question Type</mat-label>
        <mat-select formControlName="questionType">
          <mat-option value="USER_CONNECTION">User Connection</mat-option>
          <mat-option value="TEXT_AREA">Text Area</mat-option>
          <mat-option value="RICH_TEXT">Rich Text</mat-option>
          <mat-option value="CARDSORT">Cardsort</mat-option>
        </mat-select>
        <mat-error *ngIf="newQuestionForm.get('questionType').errors">Please, select a valid question type.</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="questionGroups.length">
        <mat-label>Question Group</mat-label>
        <mat-select formControlName="questionGroupId">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let questionGroup of questionGroups" value="{{ questionGroup.id }}">
            {{ questionGroup.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="privacy">
        <mat-label>Question Privacy</mat-label>
        <section *ngFor="let privacyMsg of privacyMsgs">
          <mat-checkbox color="primary" [checked]="acl === privacyMsg.id" (change)="onChange($event, privacyMsg)">{{
            privacyMsg.msg
          }}</mat-checkbox>
        </section>
      </div>

      <div [ngSwitch]="newQuestionForm.get('questionType').value">
        <div *ngIf="answerSubscalesLoaded | async">
          <mat-slide-toggle
            *ngSwitchCase="'CARDSORT'"
            class="toggle-margin"
            color="primary"
            formControlName="showDiscarded"
          >
            Show list of cards discarded
          </mat-slide-toggle>
          <app-cardsort
            *ngSwitchCase="'CARDSORT'"
            [answerSubscales]="answerSubscales"
            [answers]="answers"
            [allowSubscale]="false"
            [allowWeight]="false"
            mode="insert"
            (add)="onAddAnswer($event)"
          ></app-cardsort>
        </div>
      </div>

      <button mat-raised-button mat-raised-button type="submit" class="btn-primary btn-center btn-300">Save</button>
      <button mat-raised-button (click)="cancelCreateQuestion()" class="btn-primary btn-center btn-300">Cancel</button>
    </mat-card>
  </form>

  <button
    mat-raised-button
    class="btn-success btn-center btn-300"
    (click)="createNewQuestion()"
    *ngIf="!creatingNewQuestion"
  >
    <mat-icon>add</mat-icon>New Question
  </button>
  <button mat-raised-button class="btn-success btn-center btn-300" (click)="onCIDecisionTabels()">Next</button>
</div>
