/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class TestGroupTestScaleVisibleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Update visible to test group
     * Update visible to test group
     * @param testGroupId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupTestScaleVisible(
        testGroupId: number,
        requestBody?: {
            /**
             * Id of the test group
             */
            chartId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{id}/test-scale-visible',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

}
