import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { SubscaleQuestionAnswerCreateDialogComponent } from '../subscale-question-answer-create-dialog/subscale-question-answer-create-dialog.component';

// services
import { AnswerService } from '@app/core/services/answer.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';
import { Answer } from '@app/core/models/answer.model';
import { Question } from '@app/core/models/question.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-subscale-question-answer-list',
  templateUrl: './subscale-question-answer-list.component.html',
  styleUrls: ['./subscale-question-answer-list.component.scss'],
})
export class SubscaleQuestionAnswerListComponent implements OnInit {
  @Input() questionId: number;
  @Input() subscaleId: number;
  @Input() question: Question;
  answers: PsbItem[] = [];
  isIntegerAnswer: boolean = false;

  constructor(
    private answerService: AnswerService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.question.questionType === ('SLIDER' || 'IMAGE' || 'MULTI_HOR' || 'PENTAGON' || 'MULTI_VERT')
      ? (this.isIntegerAnswer = true)
      : (this.isIntegerAnswer = false);
    this.loadAllAnswerByQuestionId();
  }

  private loadAllAnswerByQuestionId() {
    this.answers = [];
    this.answerService.getAllByQuestionId(this.questionId).subscribe((answers: Answer[]) => {
      answers.map((answer) => {
        const item: PsbItem = {
          id: answer.id,
          title: answer.text,
          subscaleTitle: answer.subscaleTitle,
          extraColumns: [
            {
              key: 'Order',
              value: answer['aOrder'],
            },
            {
              key: 'Weight',
              value: answer.weight,
            },
          ],
        };
        this.answers = [...this.answers, item];
      });
    });
  }

  onCreate() {
    this.dialog
      .open(SubscaleQuestionAnswerCreateDialogComponent, {
        data: { isEdit: false, question: this.question, subscaleId: this.subscaleId },
      })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          const answer: Answer = result.data;
          answer.questionId = this.questionId;
          this.answerService.create(this.question.id, answer).subscribe(() => {
            this.loadAllAnswerByQuestionId();
            this.snackBar.info('Answer created with success!');
          });
        }
      });
  }

  onView(answerId: number) {
    return this.router.navigate([`/subscale/${this.subscaleId}/question/${this.questionId}/answer/${answerId}/detail`]);
  }

  onDelete(answerId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.answerService.delete(answerId).subscribe(() => {
          this.answers = this.answers.filter((answer) => answer.id !== answerId);
          this.snackBar.info('Answer deleted with success!');
        });
      });
  }
}
