<div class="blossom-chart-container">
  <button mat-raised-button color="primary" class="edit-button" (click)="onEdit()">
    <mat-icon>edit</mat-icon>
    Edit
  </button>

  <div class="row">
    <div class="column">
      <span class="label">Name</span>
      <p>{{ blossomChart.name }}</p>

      <span class="label">Header</span>
      <p>{{ blossomChart.header }}</p>

      <span class="label">Show Logo</span>
      <p>{{ blossomChart.hasLogo ? 'Yes' : 'No' }}</p>

      <span class="label">Has Self Evaluation</span>
      <p>{{ blossomChart.hasSelfEvaluationKey ? 'Yes' : 'No' }}</p>

      <span class="label">Show Table</span>
      <p>{{ blossomChart.isTable ? 'Yes' : 'No' }}</p>
    </div>
  </div>
</div>
