import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// services
import { UserService } from '@app/core/services/user.service';
import { SendEmailService } from '@app/core/services/send-email.service';

// models
import { UserRecoverPassword } from './user-recover-password.model';

@Component({
  selector: 'app-user-recover-password',
  templateUrl: './user-recover-password.component.html',
  styleUrls: ['./user-recover-password.component.scss'],
})
export class UserRecoverPasswordComponent {
  requested: boolean;
  recoverPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.email]),
  });

  constructor(private userService: UserService, private sendEmailService: SendEmailService) {}

  onSubmit() {
    if (this.recoverPasswordForm.invalid) {
      return;
    }

    const userRecoverPassword: UserRecoverPassword = {
      email: this.recoverPasswordForm.get('email').value,
    };

    this.userService.recoverPassword(userRecoverPassword).subscribe(
      () => {
        this.sendEmailService.sendUserResetPassword(userRecoverPassword.email).subscribe(
          () => (this.requested = true),
          () => this.recoverPasswordForm.get('email').setErrors({ incorrect: true }),
        );
      },
      () => {
        this.recoverPasswordForm.get('email').setErrors({ incorrect: true });
      },
    );
    this.recoverPasswordForm.reset();
  }
}
