import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

// services
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { InviteService } from '@app/core/services/invite.service';
import { FormService } from '@app/core/services/form.service';
import { UserService } from '@app/core/services/user.service';

// models
import { UserForm } from '@app/core/models/user-form.model';
import { Country } from '@app/core/models/country.model';
import { TimeZone } from '@app/core/models/time-zone.model';
import { Company } from '@app/core/models/company.model';
import { State } from '@app/core/models/state.model';
import { Invite } from '@app/core/models/invite.model';
import { MustMatch } from '@app/core/validators/must-match.validator';

@Component({
  selector: 'app-user-registration-by-invite',
  templateUrl: './user-registration-by-invite.component.html',
  styleUrls: ['./user-registration-by-invite.component.scss'],
})
export class UserRegistrationByInviteComponent implements OnInit {
  createUserForm = this.fb.group(
    {
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      gender: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      username: ['', [Validators.required, Validators.maxLength(50)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: ['', [Validators.required]],
      language: ['', [Validators.required]],
      timezone: ['', [Validators.required]],
      client: ['', [Validators.required]],
      status: ['', [Validators.required]],
    },
    {
      validator: MustMatch('password', 'passwordConfirmation'),
    },
  );

  countries$: Observable<Country[]>;
  timezones$: Observable<TimeZone[]>;
  companies$: Observable<Company[]>;
  states$: Observable<State[]>;

  inviteToken: string;
  invite: Invite;

  constructor(
    private fb: FormBuilder,
    private formService: FormService,
    private route: ActivatedRoute,
    private inviteService: InviteService,
    private userService: UserService,
    private router: Router,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.countries$ = this.formService.getCountries();
    this.timezones$ = this.formService.getTimeZones();
    this.companies$ = this.formService.getCompanies();

    this.inviteToken = this.route.snapshot.paramMap.get('inviteToken');
    this.inviteService.checkToken(this.inviteToken).subscribe(
      (invite) => {
        this.invite = invite;
        this.createUserForm.patchValue({
          email: invite.email,
          client: invite.companyId,
          firstName: invite.firstName || '',
          lastName: invite.lastName || '',
        });
      },
      () => {
        this.snackBar.info('Invalid token.');
        return this.router.navigate(['/']);
      },
    );
  }

  onSelectCountry() {
    if (this.createUserForm.value.country) {
      this.createUserForm.get('state').setValue('');

      const countryId = Number(this.createUserForm.value.country);
      this.states$ = this.formService.getStatesByCountry(countryId);
    }
  }

  onSave() {
    const { value } = this.createUserForm;
    const userForm: UserForm = {
      email: this.invite.email,
      company: `${this.invite.companyId}`,
      firstName: value.firstName,
      lastName: value.lastName,
      gender: value.gender,
      country: value.country,
      state: value.state,
      phone: value.phone,
      username: value.username,
      password: value.password,
      language: value.language,
      timeZone: value.timezone,
    };

    this.userService.create(userForm).subscribe(() => this.router.navigate(['/']));
  }
}
