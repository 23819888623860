/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AngularHttpRequest } from './core/AngularHttpRequest';
import { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { OpenAPI } from './core/OpenAPI';

import { BarSubscalesController } from './services/BarSubscalesController';
import { BlossomChartController } from './services/BlossomChartController';
import { ChartController } from './services/ChartController';
import { ChartRangeController } from './services/ChartRangeController';
import { CircularPlotController } from './services/CircularPlotController';
import { HealthCheckController } from './services/HealthCheckController';
import { HexagonChartController } from './services/HexagonChartController';
import { ReportController } from './services/ReportController';
import { SquarePlotController } from './services/SquarePlotController';
import { TemplateController } from './services/TemplateController';
import { TestGroupController } from './services/TestGroupController';
import { TransferUserController } from './services/TransferUserController';
import { ViolinChartController } from './services/ViolinChartController';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        {
            provide: OpenAPI,
            useValue: {
                BASE: OpenAPI?.BASE ?? '',
                VERSION: OpenAPI?.VERSION ?? '3.0',
                WITH_CREDENTIALS: OpenAPI?.WITH_CREDENTIALS ?? false,
                CREDENTIALS: OpenAPI?.CREDENTIALS ?? 'include',
                TOKEN: OpenAPI?.TOKEN,
                USERNAME: OpenAPI?.USERNAME,
                PASSWORD: OpenAPI?.PASSWORD,
                HEADERS: OpenAPI?.HEADERS,
                ENCODE_PATH: OpenAPI?.ENCODE_PATH,
            } as OpenAPIConfig,
        },
        {
            provide: BaseHttpRequest,
            useClass: AngularHttpRequest,
        },
        BarSubscalesController,
        BlossomChartController,
        ChartController,
        ChartRangeController,
        CircularPlotController,
        HealthCheckController,
        HexagonChartController,
        ReportController,
        SquarePlotController,
        TemplateController,
        TestGroupController,
        TransferUserController,
        ViolinChartController,
    ]
})
export class ApiV3Client {}

