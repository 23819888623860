import { Component, OnInit, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  MatPasswordStrengthComponent,
  MatPasswordStrengthInfoComponent,
} from '@angular-material-extensions/password-strength';

// services
import { FormService } from '@app/core/services/form.service';
import { UserService } from '@app/core/services/user.service';

// models
import { Country } from '@app/core/models/country.model';
import { State } from '@app/core/models/state.model';
import { TimeZone } from '@app/core/models/time-zone.model';
import { Company } from '@app/core/models/company.model';
import { MustMatch } from '@app/core/validators/must-match.validator';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit, AfterViewChecked {
  isLoading = false;
  form: FormGroup;
  countries: Observable<Country[]>;
  timeZones: Observable<TimeZone[]>;
  companies: Observable<Company[]>;
  states: State[];
  showPasswordDetails: true;
  submitted = false;
  created: boolean;
  createdUserInfo: string;
  isLoginUserAdmin: boolean = false;
  showWarningPanel: boolean;
  isPasswordStrengthValid;
  @ViewChild('passwordComponent') passwordComponentWithValidation: MatPasswordStrengthComponent;
  @ViewChild('passwordInfo') passwordInfo: MatPasswordStrengthInfoComponent;

  constructor(
    private formService: FormService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.states = [];
    this.countries = this.formService.getCountries();
    this.timeZones = this.formService.getTimeZones();
    this.companies = this.formService.getCompanies();
    this.form = this.formBuilder.group(
      {
        firstName: ['', [Validators.required, Validators.minLength(3)]],
        lastName: ['', [Validators.required, Validators.minLength(3)]],
        email: ['', [Validators.required, Validators.email]],
        gender: ['', Validators.required],
        country: ['', Validators.required],
        state: ['', Validators.required],
        phone: ['', Validators.required],
        username: ['', Validators.required],
        language: ['', Validators.required],
        timeZone: ['', Validators.required],
        company: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]],
        passwordConfirmation: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'passwordConfirmation'),
      },
    );
  }

  // Function called when a country is selected
  onSelectCountry(countryId: number) {
    this.formService.getStatesByCountry(countryId).subscribe((response) => {
      this.states = response;
    });
  }

  // Getter to access reactive form controls
  get formControls() {
    return this.form.controls;
  }

  // Function called when form is submitted
  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.submitted = true;
    this.userService.create(this.form.value).subscribe((res) => {
      this.created = true;
      this.createdUserInfo = res.userInfoId;
    });
  }

  // Validator to check if the password and passwordConfirmation are the same
  checkPasswords(group: FormGroup) {
    const password = group.get('password').value;
    const passwordConfirmation = group.get('passwordConfirmation').value;

    return password === passwordConfirmation ? null : { notSame: true };
  }

  onStrengthChanged($event?) {
    if ($event != 100) {
      this.showWarningPanel = true;
    }
    if ($event == 100) {
      this.showWarningPanel = false;
    }
  }

  focusInPassword() {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.showWarningPanel = false;
    } else {
      this.showWarningPanel = true;
    }
  }

  focusOutPassword() {
    this.showWarningPanel = false;
  }

  ngAfterViewChecked(): void {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.isPasswordStrengthValid = true;
    } else {
      this.isPasswordStrengthValid = false;
    }
    this.cd.detectChanges();
  }
}
