import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { CreateNewTestDialogComponent } from '@app/admin/pages/tests/create-new-test-dialog/create-new-test-dialog.component';
import { TestService } from '@app/core/services/test.service';
import { PsyTest } from '@app/core/models/psy-test.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { SnackBarService } from '@app/core/services/snack-bar.service';

@Component({
  selector: 'app-test-detail',
  templateUrl: './test-detail.component.html',
  styleUrls: ['./test-detail.component.scss'],
})
export class TestDetailComponent implements OnInit {
  testId: number;
  test: PsyTest;
  selected = new FormControl(0);
  active: boolean;
  answersRequired: boolean;
  randomizeOrder: boolean;
  scoringRequired: boolean;
  dateAdded: any;
  toolTipType: string = '';
  tabs: string[] = ['Details', 'Subscales', 'Custom Questions Order', 'Preview'];
  everyPageInstructionEnabled: boolean = false;
  constructor(
    private testService: TestService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.selected.setValue(this.route.snapshot.params.id ? +this.route.snapshot.params.tab : 0);

    this.testId = Number(this.route.snapshot.params.id);
    this.getTestDetail();
  }

  tabChange(event) {
    this.selected.setValue(event);
    // this.router.navigate([this.router.url.split('tab/')[0] + '/tab/' + event]);
  }

  onEdit() {
    this.dialog
      .open(CreateNewTestDialogComponent, { data: { isSuperTest: false, isEdit: true, test: this.test } })
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.testService.updatePsyTest(this.testId, result.data).subscribe(
            (response) => {
              if (response) {
                this.getTestDetail();
                this.snackBar.info('Test updated successfully.');
              }
            },
            () => {
              this.snackBar.info('Something went wrong. Please, try again later.');
            },
          );
        }
      });
  }

  getTestDetail() {
    this.testService.getPsyTestById(this.testId).subscribe((res) => {
      this.test = res;
      this.setToolTipForCardSort(res.title);
      this.active = this.test.active;
      this.answersRequired = this.test.answersRequired;
      this.randomizeOrder = this.test.randomizeOrder;
      this.scoringRequired = this.test.scoringRequired;
      this.dateAdded = new Date(this.test.dateAdded).toLocaleDateString('en-US');
      this.everyPageInstructionEnabled = this.test.everyPageInstructionsEnabled;
    });
  }

  // TODO: DRY refact
  setToolTipForCardSort(title: string) {
    if (!this.test.showCardSortInstructions) {
      return;
    }
    if (!title.includes('360')) {
      this.toolTipType = 'cardSort';
    }

    if (title.includes('360') && title.toLocaleLowerCase().includes('self')) {
      // && (!title.includes("360") && !title.toLowerCase().includes("self"))) {
      this.toolTipType = 'cardSort';
    }

    if (title.includes('360')) {
      this.toolTipType = 'cardSort 360';
    }

    if (title.includes('360') && !title.includes('self')) {
      this.toolTipType = 'cardSort 360';
    }
  }
}
