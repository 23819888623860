import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

// components
import { PsyOptionsDialogComponent } from '@app/shared/components/psy-options-dialog/psy-options-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';

// services
import { TestGroupService } from '@app/core/services/test-group.service';
import { TestService } from '@app/core/services/test.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { UserService } from '@app/core/services/user.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { PsyTestGroupTest } from '@app/core/models/psy-test-group-test.model';
import { PsbOptionItem } from '@app/core/models/psb-option-item.model';
import { UserInfo } from '@app/core/models/userInfo.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-test-group-test-list-table',
  templateUrl: './test-group-test-list-table.component.html',
  styleUrls: ['./test-group-test-list-table.component.scss'],
})
export class TestGroupTestListTableComponent implements OnInit {
  @Input() isSuperTest: boolean;
  @Input() testGroupId: number;
  @Input() testGroupTitle: string;
  @Output() hasTestsInTestGroup = new EventEmitter<boolean>();
  @ViewChild(MatPaginator, { static: true }) paginatorPsyTestGroupTests: MatPaginator;
  testGroupTests: MatTableDataSource<PsyTestGroupTest> = new MatTableDataSource<PsyTestGroupTest>();
  displayedTestGroupTestsColumns: string[] = ['tests', 'status', 'actions'];
  allTestGroupTests = [];
  tests: PsbOptionItem[] = [];
  superTests: PsbOptionItem[] = [];
  userData: UserInfo;
  isAdmin: boolean;

  constructor(
    private testGroupService: TestGroupService,
    private testService: TestService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: SnackBarService,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private companyService: CompanyService,
  ) {}

  ngOnInit(): void {
    this.userData = this.userService.getUserData();
    if (this.userData) {
      this.isAdmin = !!this.userData.authorities.find((authority) => authority.name === 'ROLE_ADMIN');
    }

    if (this.isSuperTest) {
      this.getSuperTestsByTestGroupId();
      this.getAllSuperTest();
    } else {
      this.loadAllPsyTest();
    }
  }

  getSuperTestsByTestGroupId() {
    this.allTestGroupTests = [];
    this.testGroupService.getSupertestsById(this.testGroupId).subscribe((superTests) => {
      if (superTests.length > 0) {
        this.hasTestsInTestGroup.emit(true);
      }

      superTests.forEach((superTest) => {
        this.buildTest(superTest);
      });

      this.refreshTableData();
    });
  }

  loadAllPsyTest() {
    if (this.isAdmin) {
      forkJoin([this.testGroupService.getPsyTestsById(this.testGroupId), this.testService.getAllPsyTest()]).subscribe(
        ([testGroupPsyTests, psyTests]) => {
          this.allTestGroupTests = [];
          this.tests = [];
          if (testGroupPsyTests.length > 0) {
            this.hasTestsInTestGroup.emit(true);
          }
          testGroupPsyTests.forEach((testGroupPsyTest) => {
            this.buildTest(testGroupPsyTest);
          });

          this.refreshTableData();

          psyTests
            .filter((test) => +test.active === 1)
            .forEach((psyTest) => {
              const item: PsbOptionItem = {
                id: psyTest.id,
                itemName: psyTest.title,
                version: psyTest.version,
              };
              let isTestAvailable = testGroupPsyTests.some((e) => e.testId == item.id);
              if (!isTestAvailable) {
                this.tests.push(item);
              }
            });
        },
      );
    } else {
      forkJoin([
        this.testGroupService.getPsyTestsById(this.testGroupId),
        this.companyService.findAllAssessmentAssignedToCompany(this.userData.companyId),
      ]).subscribe(([testGroupPsyTests, assignedTests]) => {
        this.allTestGroupTests = [];
        this.tests = [];
        if (testGroupPsyTests.length > 0) {
          this.hasTestsInTestGroup.emit(true);
        }

        testGroupPsyTests.forEach((testGroupPsyTest) => {
          this.buildTest(testGroupPsyTest);
        });

        this.refreshTableData();

        assignedTests
          .filter((test) => +test.active === 1)
          .forEach((assignedTest) => {
            const item: PsbOptionItem = {
              id: assignedTest.psyTestId,
              itemName: assignedTest.title,
              version: assignedTest.version,
            };
            let isTestAvailable = testGroupPsyTests.some((e) => e.testId == item.id);
            if (!isTestAvailable) {
              this.tests.push(item);
            }
          });
      });
    }
  }

  getAllSuperTest() {
    this.testService.getAllSuperTest().subscribe((superTests) => {
      superTests.forEach((superTest) => {
        const item: PsbOptionItem = {
          id: superTest.id,
          itemName: superTest.title,
        };

        this.superTests.push(item);
      });
    });
  }

  buildTest(test: any) {
    const psyTest: PsyTestGroupTest = {
      id: test.id,
      available: !!test.available,
      test: test['title'],
      testId: test.testId ? test.testId : null,
      testGroup: test['testGroupId'],
      hasUsers: test.hasUsers,
    };

    this.allTestGroupTests.push(psyTest);
  }

  refreshTableData() {
    this.testGroupTests.data = this.allTestGroupTests;
    this.testGroupTests.paginator = this.paginatorPsyTestGroupTests;
    this.changeDetectorRef.detectChanges();
  }

  onAddTest() {
    if (this.isSuperTest) {
      const addSupertestModal = this.dialog.open(PsyOptionsDialogComponent, {
        data: { items: this.superTests, title: 'Add Supertest to Test Group', formFieldName: 'Supertest' },
      });

      addSupertestModal.afterClosed().subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          this.testGroupService.addSupertestToTestGroup(this.testGroupId, result.data.item.id).subscribe(() => {
            this.getSuperTestsByTestGroupId();
            this.showCompleted('SuperTest is added to Test Group');
          });
        }
      });
    } else {
      const addTestModal = this.dialog.open(PsyOptionsDialogComponent, {
        data: { items: this.tests, title: 'Add Test to Test Group', formFieldName: 'Test' },
      });

      addTestModal.afterClosed().subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          this.testGroupService.addTestToTestGroup(this.testGroupId, result.data.item.id).subscribe(() => {
            this.loadAllPsyTest();
            this.showCompleted('Test is added to Test Group');
          });
        }
      });
    }
  }

  onDelete(element: any) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);
    if (element.hasUsers) {
      deleteDialog.componentInstance.message = `Assigned users to this Test will also be removed. Are you sure you want to delete?`;
    }

    deleteDialog.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        if (this.isSuperTest) {
          this.testGroupService.deleteSuperTestByPsyTestGroupSupertestId(element.id).subscribe(() => {
            this.allTestGroupTests = this.allTestGroupTests.filter((item) => item.id !== element.id);
            this.refreshTableData();
            this.showCompleted('Supertest is deleted');
          });
        } else {
          this.testGroupService.deleteTestByPsyTestGroupTestId(element.id).subscribe(() => {
            this.loadAllPsyTest();
            this.showCompleted('Test is deleted');
          });
        }
      }
    });
  }

  onArchive(element: any) {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
    confirmationDialog.componentInstance.message = `Are you sure you want to archive this test?`;
    confirmationDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        if (this.isSuperTest) {
          this.testGroupService.archiveSuperTestByPsyTestGroupSupertestId(element.id).subscribe(() => {
            this.allTestGroupTests = this.allTestGroupTests.filter((item) => item.id !== element.id);
            this.refreshTableData();
            this.showCompleted('Supertest is archived');
          });
        } else {
          this.testGroupService.archiveTestByPsyTestGroupTestId(element.id).subscribe(() => {
            this.allTestGroupTests = this.allTestGroupTests.filter((item) => item.id !== element.id);
            this.refreshTableData();
            this.showCompleted('Test is archived');
          });
        }
      }
    });
  }

  updateStatus(event: any, element: any) {
    if (this.isSuperTest) {
      this.testGroupService.updateStatusByPsyTestGroupSupertestId(event, element.id).subscribe(() => {
        this.showCompleted('Supertest status updated');
      });
    } else {
      this.testGroupService.updateStatusByPsyTestGroupTestId(event, element.id).subscribe(() => {
        this.showCompleted('Test status updated');
      });
    }
  }

  showCompleted(message: string) {
    this.snackBar.info(message);
  }

  onViewPercentiles(element: any) {
    return this.router.navigate([`/psy-test-group/${this.testGroupId}/test/${element.testId}/scale-list`], {
      state: { test: element.test },
    });
  }

  onViewRanges(element) {
    return this.router.navigate(
      [`/psy-test-group/${this.testGroupId}/test/${element.testId}/preferred-range-subscale`],
      {
        state: { testGroupTitle: this.testGroupTitle, testTitle: element.test },
      },
    );
  }
}
