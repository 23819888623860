import { Component, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { UserDetailsDefaultValues } from '@app/shared/components/user-details-form/user-details-form.component';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

// components
import { MfaEnrollModalComponent } from '../mfa-enroll-modal/mfa-enroll-modal.component';

// services
import { UserService } from '@app/core/services/user.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { FormService } from '@app/core/services/form.service';

// models
import { UserInfo } from '@app/core/models/userInfo.model';
import { Country } from '@app/core/models/country.model';
import { TimeZone } from '@app/core/models/time-zone.model';
import { Company } from '@app/core/models/company.model';
import { ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TGM } from '@app/core/constants';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userInfo: UserInfo;
  isLoading = false;
  userData: UserInfo;
  userInfoId: number;
  defaultValues: UserDetailsDefaultValues;
  timeZones: TimeZone[];
  companies: Company[];
  countries: Country[];

  constructor(
    private userService: UserService,
    private formService: FormService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private router: Router,
    @Optional() public dialogRef: MatDialogRef<ProfileComponent>,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userData = this.userService.getUserData();

    if (this.userData) {
      this.isLoading = false;
    }
    this.userData = this.userService.getUserData();
    this.userInfoId = parseInt(localStorage.getItem('userInfoId'), 10);

    this.getUser();
  }

  openMFAEnrollmentModal() {
    this.dialog.open(MfaEnrollModalComponent);
  }

  onFormSubmit(form: FormGroup) {
    const { id: userId } = form.value;

    const updatedUser = {
      firstName: form.value.firstName,
      lastName: form.value.lastName,
      activated: this.defaultValues.active,
      companyId: this.defaultValues.client,
      department: form.value.department,
      email: this.defaultValues.email,
      gender: form.value.gender,
      language: form.value.language,
      phone: form.value.phone,
      stateId: form.value.state ? form.value.state.id : null,
      timeZoneId: form.value.timezone ? form.value.timezone.id : null,
      authorityName: this.defaultValues.authorityName,
      privateScores: this.defaultValues.privateScores,
    };

    if (updatedUser.phone) {
      updatedUser.phone = updatedUser.phone.replace(/\s/g, '');
    }

    this.userService.updateUser(userId, updatedUser).subscribe((user) => {
      this.userData = user;
      this.getUser();
      this.snackBar.info('Your profile has been updated successfully.');
    });
  }

  getUser() {
    forkJoin({
      timeZones: this.formService.getTimeZones(),
      companies: this.formService.getCompanies(),
      countries: this.formService.getCountries(),
      userInfo:  this.userService.get(this.userInfoId)
    }).subscribe(({ timeZones, companies, countries, userInfo }) => {
      this.timeZones = [null, ...timeZones];
      this.companies = companies;
      this.countries = [null, ...countries];
      this.userInfo = userInfo;

      this.userInfo.phone = this.userInfo.phone?.toString().includes('+')
        ? this.userInfo.phone
        : `+${this.userInfo.phone.toString()}`;

      this.defaultValues = {
        id: this.userInfo.id,
        firstName: this.userInfo.firstName,
        lastName: this.userInfo.lastName,
        email: this.userInfo.email,
        gender: this.userInfo.gender,
        country: this.userInfo.countryId,
        state: this.userInfo.stateId,
        phone: this.userInfo.phone,
        language: this.userInfo.languageKey,
        timezone: this.userInfo.timeZoneId,
        client: this.userInfo.companyId,
        department: this.userInfo.departmentId,
        active: this.userInfo.active,
        authorityName: this.userInfo.authorityName,
        privateScores: this.userInfo.privateScores,
      };
    });
  }

  onCancel() {
    if ([ROLE_ADMIN, ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN].includes(this.userInfo.authorityName)) {
      return this.router.navigate(['/admin']);
    } else if (this.userInfo.authorityName === 'ROLE_TEST_USER') {
      return this.router.navigate(['/dashboard']);
    } else {
      return this.router.navigate(['/login']);
    }
  }
}
