import { Routes, RouterModule } from '@angular/router';
import { TestGroupListComponent } from './test-group-list/test-group-list.component';
import { TestGroupDetailComponent } from './test-group-details/test-group-detail.component';
import { TestGroupScaleListComponent } from '../../../shared/components/test-group-scale-list/test-group-scale-list.component';
import { TestGroupPercentileListComponent } from '../../../shared/components/test-group-percentile-list/test-group-percentile-list.component';
import { TestGroupPreferredRangeSuperscaleComponent } from '@app/admin/pages/test-group/test-group-preferred-range-superscale/test-group-preferred-range-superscale.component';
import { TestGroupPreferredRangesSubscaleComponent } from '@app/admin/pages/test-group/test-group-preferred-ranges-subscale/test-group-preferred-ranges-subscale.component';
import { TestResultListViewComponent } from '@app/admin/pages/test-group/test-result-list-view/test-result-list-view.component';
import { TestGroupScorecardComponent } from '@app/admin/pages/test-group/test-group-scorecard/test-group-scorecard.component';

export const testGroupRouting: Routes = [
  { path: '', component: TestGroupListComponent },
  { path: ':id/details', component: TestGroupDetailComponent },
  { path: ':id/details/tab/:tab', component: TestGroupDetailComponent },
  { path: ':id/test/:testId/scale-list', component: TestGroupScaleListComponent },
  { path: ':id/test/:testId/subscale/:subscaleId/percentile', component: TestGroupPercentileListComponent },
  { path: ':id/preferred-range-superscale', component: TestGroupPreferredRangeSuperscaleComponent },
  { path: ':id/test/:testId/preferred-range-subscale', component: TestGroupPreferredRangesSubscaleComponent },
  { path: ':id/test-result-list-view', component: TestResultListViewComponent },
  { path: ':id/user/:userInfoId/test/:testId/test-group-scorecard', component: TestGroupScorecardComponent },
];
