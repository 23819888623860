<div class="container">
  <div class="test-instruction">
    <app-instructions
      *ngIf="this.psyTest?.everyPageInstructionsEnabled || this.superTest?.everyPageInstructionsEnabled"
      [text]="this.psyTest?.startPageInstructions || this.superTest?.startInstructions"
      title="Instructions"
      [background]="'startPageInstructions'"
    ></app-instructions>

    <app-instructions
      *ngIf="this.psyTest?.everyPageInstructionsEnabled || this.superTest?.everyPageInstructionsEnabled"
      [text]="this.psyTest?.warningInstructions || this.superTest?.warningInstructions"
      title="Warning"
      [background]="'warningInstructions'"
    ></app-instructions>
  </div>

  <div class="row">
    <div class="column test_title">
      <h1 *ngIf="psyTest">{{ this.psyTest.title }}</h1>
    </div>
  </div>

  <div class="test-question-list" *ngIf="allObj">
    <div *ngFor="let obj of allObj | paginate: config">
      <div *ngIf="testObjectService.isQuestion(obj)" #questionRef id="{{ obj.id }}" (mouseleave)="mouseLeaveFn(obj.id)">
        <mat-card class="card-panel-scroll card-sort-item">
          <mat-error
            *ngIf="isQuestionUnanswered(obj.id) && psyTest.answersRequired && obj.questionType !== 'CARDSORT'"
            class="text-center"
          >
            <p>*This question must be answered.</p>
          </mat-error>
          <mat-error
            *ngIf="isQuestionUnanswered(obj.id) && psyTest.answersRequired && obj.questionType === 'CARDSORT'"
            class="text-center"
          >
            <p>
              *This question should be answered.
              <a class="answer-cardsort-original-order" (click)="keepCardsortAnswersInTheOriginalOrder(obj)"
                >Click here</a
              >
              to keep the answers in the original order as presented, AND you choose not to discard any items.
            </p>
          </mat-error>
          <div *ngIf="obj.questionType === 'TEXT_AREA' || obj.questionType === 'RICH_TEXT'">
            <app-answer-text-area
              [question]="obj"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [loadAnswer]="loadAnswers"
              [answering]="true"
              [isPreview]="false"
              [userInfoId]="userInfoId"
              [testResultId]="psyTest.testResultId"
              (userAnswer)="onAnswer($event)"
            >
            </app-answer-text-area>
          </div>
          <div *ngIf="obj.questionType === 'SLIDER'">
            <app-answer-slider
              [question]="obj"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [answering]="true"
              [isPreview]="false"
              [loadAnswer]="loadAnswers"
              [testResultId]="psyTest?.testResultId"
              [testAnswerRequired]="psyTest?.answersRequired"
              (userAnswer)="onAnswer($event)"
            ></app-answer-slider>
          </div>
          <div *ngIf="obj.questionType === 'MULTI_VERT' || obj.questionType === 'IMAGE'">
            <app-answer-multiple-vertical-choice
              [question]="obj"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [loadAnswer]="loadAnswers"
              [answering]="true"
              [isPreview]="false"
              [testResultId]="psyTest?.testResultId"
              (userAnswer)="onAnswer($event)"
            >
            </app-answer-multiple-vertical-choice>
          </div>
          <div *ngIf="obj.questionType === 'MULTI_HOR'">
            <app-answer-multiple-horizontal-choice
              [question]="obj"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [loadAnswer]="loadAnswers"
              [answering]="true"
              [isPreview]="false"
              [testResultId]="psyTest?.testResultId"
              (userAnswer)="onAnswer($event)"
            >
            </app-answer-multiple-horizontal-choice>
          </div>
          <div *ngIf="obj.questionType === 'CARDSORT'">
            <app-answer-cardsort
              [question]="obj"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [userInfoId]="userInfoId"
              [loadAnswer]="loadAnswers"
              [saveOriginalOrderAnswersQuestionNumber]="saveOriginalOrderCardsortAnswersQuestionNumber"
              [answering]="true"
              [isPreview]="false"
              (userAnswer)="onAnswer($event)"
              [testAnswerRequired]="psyTest?.answersRequired"
              [toolTipType]="toolTipType"
              [psyTest]="psyTest"
            >
            </app-answer-cardsort>
          </div>
          <div *ngIf="obj.questionType === 'PENTAGON'">
            <app-answer-pentagon
              [question]="obj"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [loadAnswer]="loadAnswers"
              [userInfoId]="userInfoId"
              [answering]="true"
              [isPreview]="false"
              [testResultId]="psyTest?.testResultId"
              (userAnswer)="onAnswer($event)"
            >
            </app-answer-pentagon>
          </div>
          <div *ngIf="obj.questionType === 'TEXT_SINGLE_LINE'">
            <app-answer-text-single-line
              [question]="obj"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [loadAnswer]="loadAnswers"
              [userInfoId]="userInfoId"
              [testResultId]="psyTest.testResultId"
              [answering]="true"
              [isPreview]="false"
              (userAnswer)="onAnswer($event)"
            >
            </app-answer-text-single-line>
          </div>
        </mat-card>
      </div>
      <mat-card *ngIf="testObjectService.isQuestionsGroup(obj)" class="question-group">
        <div
          *ngIf="obj.instructions"
          [innerHTML]="obj.instructions"
          class="question-group-center-insturction question-group-item"
        ></div>
        <div
          *ngFor="let question of obj.questions"
          #questionRef
          id="{{ question.id }}"
          (mouseleave)="mouseLeaveFn(question.id)"
        >
          <div class="card-center-item question-group-item">
            <mat-error
              *ngIf="
                isQuestionUnanswered(question.id) && psyTest.answersRequired && question.questionType === 'CARDSORT'
              "
              class="text-center"
            >
              <p>*This question must be answered.</p>
            </mat-error>
            <mat-error
              *ngIf="
                isQuestionUnanswered(question.id) && psyTest.answersRequired && question.questionType === 'CARDSORT'
              "
              class="text-center"
            >
              <p>
                *This question should be answered.
                <a class="answer-cardsort-original-order" (click)="keepCardsortAnswersInTheOriginalOrder(question)"
                  >Click here</a
                >
                to keep the answers in the original order as presented, AND you choose not to discard any items.
              </p>
            </mat-error>
            <div *ngIf="question.questionType === 'TEXT_AREA' || question.questionType === 'RICH_TEXT'">
              <app-answer-text-area
                [question]="question"
                [loadAnswer]="loadAnswers"
                [psyTestId]="psyTestId"
                [superTestId]="superTestId"
                [answering]="true"
                [isPreview]="false"
                [userInfoId]="userInfoId"
                [testResultId]="psyTest.testResultId"
                (userAnswer)="onAnswer($event)"
              >
              </app-answer-text-area>
            </div>
            <div *ngIf="question.questionType === 'SLIDER'">
              <app-answer-slider
                [question]="question"
                [psyTestId]="psyTestId"
                [superTestId]="superTestId"
                [answering]="true"
                [isPreview]="false"
                [loadAnswer]="loadAnswers"
                [testResultId]="psyTest?.testResultId"
                [testAnswerRequired]="psyTest?.answersRequired"
                (userAnswer)="onAnswer($event)"
              ></app-answer-slider>
            </div>
            <div *ngIf="question.questionType === 'MULTI_VERT' || question.questionType === 'IMAGE'">
              <app-answer-multiple-vertical-choice
                [question]="question"
                [psyTestId]="psyTestId"
                [superTestId]="superTestId"
                [loadAnswer]="loadAnswers"
                [answering]="true"
                [isPreview]="false"
                [testResultId]="psyTest?.testResultId"
                (userAnswer)="onAnswer($event)"
              >
              </app-answer-multiple-vertical-choice>
            </div>
            <div *ngIf="question.questionType === 'MULTI_HOR'">
              <app-answer-multiple-horizontal-choice
                [question]="question"
                [psyTestId]="psyTestId"
                [superTestId]="superTestId"
                [loadAnswer]="loadAnswers"
                [answering]="true"
                [isPreview]="false"
                [testResultId]="psyTest?.testResultId"
                (userAnswer)="onAnswer($event)"
              >
              </app-answer-multiple-horizontal-choice>
            </div>
            <div *ngIf="question.questionType === 'CARDSORT'">
              <app-answer-cardsort
                [question]="question"
                [psyTestId]="psyTestId"
                [superTestId]="superTestId"
                [userInfoId]="userInfoId"
                [loadAnswer]="loadAnswers"
                [saveOriginalOrderAnswersQuestionNumber]="saveOriginalOrderCardsortAnswersQuestionNumber"
                [testAnswerRequired]="psyTest?.answersRequired"
                [answering]="true"
                [isPreview]="false"
                (userAnswer)="onAnswer($event)"
                [toolTipType]="toolTipType"
              >
              </app-answer-cardsort>
            </div>
            <div *ngIf="question.questionType === 'PENTAGON'">
              <app-answer-pentagon
                [question]="question"
                [psyTestId]="psyTestId"
                [superTestId]="superTestId"
                [loadAnswer]="loadAnswers"
                [userInfoId]="userInfoId"
                [answering]="true"
                [isPreview]="false"
                [testResultId]="psyTest?.testResultId"
                (userAnswer)="onAnswer($event)"
              >
              </app-answer-pentagon>
            </div>
            <div *ngIf="question.questionType === 'TEXT_SINGLE_LINE'">
              <app-answer-text-single-line
                [question]="question"
                [psyTestId]="psyTestId"
                [superTestId]="superTestId"
                [loadAnswer]="loadAnswers"
                [userInfoId]="userInfoId"
                [answering]="true"
                [isPreview]="false"
                [testResultId]="psyTest.testResultId"
                (userAnswer)="onAnswer($event)"
              >
              </app-answer-text-single-line>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <div *ngIf="showProgressError()" id="error-message" class="alert text-center">
      This test has {{ totalQuestions - totalAnsweredQuestions > 1 ? 'questions' : 'question' }}
      {{ unansweredQuestionNumbersInCurrentPageSentence }} to be answered.
    </div>

    <pagination-controls class="pagination" id="paginator" (pageChange)="pageChanged($event)"></pagination-controls>
    <div class="row" *ngIf="math.ceil(this.config.totalItems / this.config.itemsPerPage) === this.config.currentPage">
      <button mat-raised-button class="btn-success btn-center btn-300" (click)="onFinish()" [disabled]="!isOnline">
        Finish
      </button>
    </div>
    <div id="test-progress" *ngIf="this.progress && this.progress > 0">
      <mat-progress-bar color="primary" mode="determinate" [value]="progress"> </mat-progress-bar>
      <div class="progress-text">{{ totalAnsweredQuestions }} / {{ totalQuestions }}</div>
    </div>
  </div>
</div>

<app-timer
  *ngIf="this.timerConfig.leftTime"
  [config]="timerConfig"
  title="Timing remaining"
  position="bottom-right"
  (timeOver)="onTimeIsOver($event)"
>
</app-timer>
