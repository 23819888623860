<app-layout-header (toggleEmitter)="onToggle($event)"></app-layout-header>
<mat-sidenav-container>
  <mat-sidenav [mode]="mobileQuery.matches ? 'over' : 'side'" class="sidenav" [opened]="toggled">
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/tgm/user'"> User </a>
    </mat-nav-list>
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/tgm/psy-test-group'"> Test Group </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
