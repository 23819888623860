<h1 mat-dialog-title>Add Test Group</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Test Group</mat-label>
    <input
      matInput
      type="text"
      [formControl]="testGroupControl"
      [matAutocomplete]="autoTestGroup" />
    <mat-autocomplete
      #autoTestGroup="matAutocomplete"
      [displayWith]="displayTestGroup"
    >
    <mat-option *ngFor="let testGroup of filteredTestGroups | async" [value]="testGroup">
      {{ displayTestGroup(testGroup) }}
    </mat-option>
  </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="onAddTestGroup()">
    <mat-icon>add</mat-icon>
    Add
  </button>
</div>
