<div *ngIf="connectionIndicators.length">
  <mat-card class="list" *ngFor="let connectionIndicator of connectionIndicators">
    <h3>
      {{ connectionIndicator.title }}
    </h3>
    <div>
      <button
        mat-raised-button
        *ngIf="isNotPrivate(connectionIndicator)"
        (click)="onViewCompanyConnectionIndicator(connectionIndicator)"
      >
        View Company
      </button>
      <button
        mat-raised-button
        aria-label="Start"
        class="btn-important"
        (click)="onStartCI(connectionIndicator)"
        *ngIf="connectionIndicator.status !== 'COMPLETED'; else completed"
      >
        Start
      </button>
      <!--      <button mat-flat-button color="primary" (click)="onSearch({ test: connectionIndicator, type: 'PCI' })">-->
      <!--        Decision Search-->
      <!--        <mat-icon>search</mat-icon>-->
      <!--      </button>-->
    </div>
  </mat-card>
</div>

<div *ngIf="roleMandates.length">
  <mat-card class="list" *ngFor="let roleMandate of roleMandates">
    <h3>
      {{ roleMandate.title }}
    </h3>
    <div class="test-buttons">
      <button mat-raised-button *ngIf="isNotPrivate(roleMandate)" (click)="onViewCompanyRoleMandate(roleMandate)">
        View Company
      </button>
      <button mat-raised-button class="btn-important" aria-label="Start" (click)="onStartRM(roleMandate)">
        {{ roleMandateButton(roleMandate) }}
      </button>
      <button mat-flat-button color="primary" (click)="onSearch({ test: roleMandate, type: 'PRN' })">
        Decision Search
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </mat-card>
</div>

<mat-card class="list psy-test" *ngFor="let psyTest of psyTests">
  <h3>
    {{ psyTest.title }}
    <p *ngIf="psyTest.testTime" class="small-text">Timer: {{ psyTest.testTime }} minutes</p>
  </h3>
  <button
    *ngIf="psyTest.status !== 'COMPLETED' && psyTest.status !== 'STARTED'"
    mat-raised-button
    class="btn-important"
    aria-label="Start"
    (click)="onStartPsyTest(psyTest)"
  >
    &nbsp;&nbsp;&nbsp;&nbsp;Start&nbsp;&nbsp;&nbsp;&nbsp;
  </button>
  <button
    *ngIf="psyTest.status !== 'COMPLETED' && psyTest.status !== 'PENDING'"
    mat-raised-button
    class="btn-important"
    aria-label="Start"
    (click)="onContinuePsyTest(psyTest)"
  >
    Continue
  </button>
  <span *ngIf="psyTest.status !== 'STARTED' && psyTest.status !== 'PENDING'">Completed</span>
</mat-card>

<mat-card class="list" *ngFor="let superTest of superTests">
  <h3>
    {{ superTest.title }}
  </h3>
  <button
    mat-raised-button
    class="btn-important"
    aria-label="Start"
    (click)="onStartSuperTest(superTest)"
    *ngIf="superTest.status !== 'COMPLETED'; else completed"
  >
    &nbsp;&nbsp;&nbsp;&nbsp;Start&nbsp;&nbsp;&nbsp;&nbsp;
  </button>
</mat-card>
<ng-template #completed> Completed </ng-template>
