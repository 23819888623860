import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// models
import { PsbOptionItem } from '@app/core/models/psb-option-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-psy-options-dialog',
  templateUrl: './psy-options-dialog.component.html',
  styleUrls: ['./psy-options-dialog.component.scss'],
})
export class PsyOptionsDialogComponent implements OnInit {
  items: PsbOptionItem[];
  title: string;
  formFieldName: string;
  isSuperItem: boolean;
  isAddButton: boolean;
  addButtonName: string;

  filteredItems: Observable<PsbOptionItem[]>;

  itemForm = new FormGroup({
    item: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<PsyOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      items: PsbOptionItem[];
      title: string;
      formFieldName: string;
      isAddButton?: boolean;
      addButtonName?: string;
    },
  ) {}

  ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });

    this.items = this.data.items;
    this.title = this.data.title;
    this.isAddButton = this.data.isAddButton;
    this.formFieldName = this.data.formFieldName;
    this.addButtonName = this.data.addButtonName;

    this.filteredItems = this.itemForm.controls['item'].valueChanges.pipe(
      startWith(''),
      map((item) => (item ? this._filter(item) : this.items.slice())),
    );
  }

  private _filter(value: any): PsbOptionItem[] {
    const filterValue = value.hasOwnProperty('itemName') ? value?.itemName.toLowerCase() : value?.toLowerCase();

    return this.items.filter((item) => item.itemName?.toLowerCase()?.includes(filterValue));
  }

  displayFn(item: PsbOptionItem): string {
    return item ? item.itemName : '';
  }

  compareFn(x: any, y: any): boolean {
    return parseInt(x, 10) === y ? true : false;
  }

  onItemSelectionChanged(isSuperItem: boolean) {
    this.isSuperItem = isSuperItem;
  }

  onAdd() {
    this.dialogRef.close({ event: 'add', data: this.itemForm.value, isSuperItem: this.isSuperItem });
  }

  onSave() {
    this.dialogRef.close({ event: 'save', data: this.itemForm.value, isSuperItem: this.isSuperItem });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
