<div *ngIf="chapter" class="container">
  <div class="chapter-header">
    <h1>{{ chapter.title }}</h1>
    <button mat-raised-button color="primary" (click)="onEdit()">
      <mat-icon>edit</mat-icon>
      Update Chapter
    </button>
  </div>

  <mat-card class="section-card">
    <div>
      <mat-card-header>
        <mat-card-title>
          <span>
            <h2>Optimal Score</h2>
          </span>
        </mat-card-title>
      </mat-card-header>
    </div>

    <div id="actions">
      <button mat-flat-button color="accent" (click)="viewOptimalScore(chapter.id)">
        <mat-icon>remove_red_eye</mat-icon>
        View Optimal Score
      </button>
    </div>
  </mat-card>

  <div class="page-list-header" style="margin-top: 1rem">
    <h2>Sections</h2>

    <div id="buttons-new">
      <button mat-flat-button color="primary" (click)="onAddNewSection()">
        <mat-icon>add</mat-icon>
        Add Section
      </button>
    </div>
  </div>

  <ul class="sections-list">
    <li *ngFor="let section of chapter.sections">
      <mat-card>
        <div class="section-card">
          <mat-card-title class="chapter-title">
            <span (click)="expandSection(section)">
              {{ section.title }}
              <mat-icon>
                {{ section.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </mat-icon>
            </span>
            <small class="section-type">{{ section.type }}</small>
          </mat-card-title>
          <div>
            <button mat-button class="btn-edit" (click)="onEditSection(section.id)">Edit</button>
            <button mat-button class="btn-delete" (click)="onDelete(section.id)">Delete</button>
            <a [routerLink]="['/section', section.id]" [queryParams]="{ chapterId: chapter.id, reportId: reportId }">
              <button mat-flat-button color="primary">Open</button>
            </a>
          </div>
        </div>
        <div *ngIf="section.expanded" class="section-preview">
          <app-subsection-container [section]="selectedSection"></app-subsection-container>
        </div>
      </mat-card>
    </li>
  </ul>
</div>
