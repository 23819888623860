/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyTestGroupNotification } from '../models/PsyTestGroupNotification';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupNotificationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a psyTestGroupNotification
     * Create a psyTestGroupNotification
     * @param testGroupId Id of the test group
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupNotification(
        testGroupId: number,
        requestBody?: {
            /**
             * Id of the PstTestGroup
             */
            psyTestGroupId?: number;
            /**
             * Id of the notification
             */
            notificationId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group-notification/{testGroupId}',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a psyTestGroupNotification by testGroupId
     * Retrieve a psyTestGroupNotification by testGroupId
     * @param testGroupId Id of the Test Group
     * @returns PsyTestGroupNotification A psyTestGroupNotification
     * @throws ApiError
     */
    public getTestGroupNotification(
        testGroupId: number,
    ): Observable<PsyTestGroupNotification> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group-notification/{testGroupId}',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `PsyTestGroupNotification not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a psy test group notification
     * @param testGroupId Id of the test group
     * @returns any PsyTestGroupNotification deleted successfully
     * @throws ApiError
     */
    public deleteTestGroupNotificationNotification(
        testGroupId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group-notification/{testGroupId}/notification/{notificationId}',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
