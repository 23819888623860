<div class="container">
  <h1>Invites</h1>
  <app-psb-list
    [items]="wholeSystemInvites"
    itemsPerPage="10"
    (view)="onView($event)"
    (edit)="onEdit($event)"
    (delete)="onDelete($event)"
    (archive)="onArchive($event)"
    [userInvite]="true"
    [columnNumber]="5"
  >
  </app-psb-list>
</div>
