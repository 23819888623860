<div class="container">
  <div class="page-list-header">
    <h1>Reports</h1>
    <button
      mat-raised-button
      color="primary"
      (click)="onCreateNewReport(reportFormDialog)"
      class="create-report-button"
    >
      <mat-icon>add</mat-icon>
      Create a new report
    </button>
  </div>

  <app-psb-list
    [items]="reportPsbItems"
    (dashboard)="onDashboard($event)"
    dashboardLabel="Open"
    (delete)="onDelete($event)"
  ></app-psb-list>
</div>

<ng-template #reportFormDialog>
  <h1 mat-dialog-title>{{ isFormEditing ? 'Edit a' : 'Create a new' }} Report</h1>
  <form id="reportForm" mat-dialog-content [formGroup]="reportForm" (submit)="onFormSubmit()">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput type="text" required formControlName="title" />
      <mat-error *ngIf="reportForm.get('title').invalid">Title is required</mat-error>
    </mat-form-field>
  </form>
  <div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <mat-icon>block</mat-icon>
      Cancel
    </button>
    <button mat-flat-button color="primary" type="submit" form="reportForm" [disabled]="reportForm.invalid">
      <mat-icon>save_alt</mat-icon>
      Save
    </button>
  </div>
</ng-template>
