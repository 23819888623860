<h1 *ngIf="!isEdit">Create a new Test Group</h1>
<h1 *ngIf="isEdit">Update a Test Group</h1>
<form [formGroup]="testGroupModalForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Title</mat-label>
    <input matInput type="text" formControlName="title" />
    <mat-error *ngIf="testGroupModalForm.get('title').errors">Please, enter a valid title.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Description</mat-label>
    <textarea matInput rows="5" cols="10" type="text" formControlName="description"></textarea>
    <mat-error *ngIf="testGroupModalForm.get('description').errors">Please, enter a valid description.</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="isAdmin">
    <mat-label>Client</mat-label>
    <input matInput type="text" formControlName="companyId" [matAutocomplete]="autoClient" />
    <mat-autocomplete
      #autoClient="matAutocomplete"
      (optionSelected)="onSelectClient($event.option.value.id)"
      [displayWith]="displayClient"
    >
      <mat-option *ngFor="let client of filteredClients | async" [value]="client">
        {{ displayClient(client) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="testGroupModalForm.get('companyId').errors">Please, enter a valid client.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Department</mat-label>
    <input matInput type="text" formControlName="departmentId" [matAutocomplete]="autoDepartment" />
    <mat-autocomplete #autoDepartment="matAutocomplete" [displayWith]="displayDepartment">
      <mat-option *ngFor="let department of filteredDepartments | async" [value]="department">
        {{ displayDepartment(department) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="testGroupModalForm.get('departmentId').errors">Please, enter a valid department.</mat-error>
  </mat-form-field>
  <div class="location">
    <div class="address">
      <h3>Location</h3>
      <mat-form-field>
        <mat-label>Country</mat-label>
        <input matInput type="text" formControlName="country" [matAutocomplete]="autoCountry" />
        <mat-autocomplete
          #autoCountry="matAutocomplete"
          (optionSelected)="onSelectCountry($event.option.value.id)"
          [displayWith]="displayCountry"
        >
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{ displayCountry(country) }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="testGroupModalForm.get('country').errors">Please, select a valid country.</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="states && states.length">
        <mat-label>State</mat-label>
        <input matInput type="text" formControlName="stateId" [matAutocomplete]="autoState" />
        <mat-autocomplete #autoState="matAutocomplete" [displayWith]="displayState">
          <mat-option *ngFor="let state of filteredStates | async" [value]="state">
            {{ displayState(state) }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="testGroupModalForm.get('stateId').errors">Please, select a valid state.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput type="text" formControlName="city" />
        <mat-error *ngIf="testGroupModalForm.get('city').errors">Please, enter a valid city.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Zip Code</mat-label>
        <input matInput type="text" formControlName="zipCode" />
        <mat-error *ngIf="testGroupModalForm.get('zipCode').errors">Please, enter a valid zip code.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Address</mat-label>
        <input matInput type="text" formControlName="address" />
        <mat-error *ngIf="testGroupModalForm.get('address').errors">Please, enter a valid address.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Address 2</mat-label>
        <input matInput type="text" formControlName="address2" />
      </mat-form-field>
    </div>
    <div class="address">
      <div class="billing-address">
        <h3>Billing Address</h3>
        <mat-checkbox
          color="primary"
          [checked]="sameAsLocation"
          (change)="toggleBillingAddress()"
          (click)="$event.stopPropagation()"
        >
          <h6>Same as Location</h6>
        </mat-checkbox>
      </div>
      <div *ngIf="!sameAsLocation">
        <mat-form-field>
          <mat-label>Country</mat-label>
          <input matInput type="text" formControlName="billingCountry" [matAutocomplete]="autoBillingCountry" />
          <mat-autocomplete
            #autoBillingCountry="matAutocomplete"
            (optionSelected)="onSelectBillingCountry($event.option.value.id)"
            [displayWith]="displayCountry"
          >
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
              {{ displayCountry(country) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field *ngIf="billingStates && billingStates.length">
          <mat-label>State</mat-label>
          <input matInput type="text" formControlName="billingStateId" [matAutocomplete]="autoBillingState" />
          <mat-autocomplete #autoBillingState="matAutocomplete" [displayWith]="displayState">
            <mat-option *ngFor="let billingState of filteredBillingStates | async" [value]="billingState">
              {{ displayState(billingState) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput type="text" formControlName="billingCity" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Zip Code</mat-label>
          <input matInput type="text" formControlName="billingZipCode" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput type="text" formControlName="billingAddress" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Address 2</mat-label>
          <input matInput type="text" formControlName="billingAddress2" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="imageBlock">
    <div *ngIf="testGroupModalForm.get('logo').value">
      <p><img [src]="testGroupModalForm.get('logo').value" width="100px" height="auto" alt="Group Logo" /></p>
    </div>
    <button type="button" mat-button class="btn-primary btn-left btn-200">
      <mat-icon>add</mat-icon>
      Upload Logo Image
      <input
        formControlName="logoFile"
        type="file"
        accept="image/*"
        class="input-file"
        (change)="handleFile('logo', $event)"
      />
    </button>
  </div>
  <mat-form-field *ngIf="emailAddresses && emailAddresses.length && isAdmin">
    <mat-label>Send Emails From</mat-label>
    <mat-select formControlName="fromEmailAddress">
      <mat-option *ngFor="let emailAddress of emailAddresses" value="{{ emailAddress }}">{{ emailAddress }}</mat-option>
    </mat-select>
    <mat-error *ngIf="testGroupModalForm.get('fromEmailAddress').errors">Please, select a email address.</mat-error>
  </mat-form-field>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">Cancel</button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!testGroupModalForm.valid">Save</button>
  </div>
</form>
