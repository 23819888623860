import { Component, OnInit, Input } from '@angular/core';
import { ReportModel } from '@app/core/models/report-model.model';

@Component({
  selector: 'app-report-model-view',
  templateUrl: './report-model-view.component.html',
  styleUrls: ['./report-model-view.component.scss'],
})
export class ReportModelViewComponent implements OnInit {
  @Input() reportModel: ReportModel;

  constructor() {}

  ngOnInit(): void {}
}
