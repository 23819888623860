import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/core/services/user.service';
import { FormGroup } from '@angular/forms';
import { UserDetailsDefaultValues } from '@app/shared/components/user-details-form/user-details-form.component';

// models
import { Country } from '@app/core/models/country.model';
import { TimeZone } from '@app/core/models/time-zone.model';
import { Company } from '@app/core/models/company.model';

type MatDialogData = {
  defaultValues: UserDetailsDefaultValues;
  timeZones: TimeZone[];
  companies: Company[];
  countries: Country[];
};

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss'],
})
export class UserEditDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData,
    public dialogRef: MatDialogRef<UserEditDialogComponent>,
    private userService: UserService,
  ) {}

  onFormSubmit(form: FormGroup) {
    const { id: userId } = form.value;

    const updatedUser = {
      firstName: form.value.firstName,
      lastName: form.value.lastName,
      activated: form.value.active,
      companyId: form.value.client.id,
      department: form.value.department,
      email: form.value.email,
      gender: form.value.gender,
      language: form.value.language ? form.value.language : null,
      phone: form.value.phone,
      stateId: form.value && form.value.state ? form.value.state.id : null,
      timeZoneId: form.value && form.value.timezone ? form.value.timezone.id : null,
      authorityName: form.value.authorityName,
      privateScores: form.value.privateScores,
    };

    if (updatedUser.phone) {
      updatedUser.phone = updatedUser.phone.replace(/\s/g, '');
    }

    this.userService.updateUser(userId, updatedUser).subscribe((user) => {
      this.dialogRef.close({ updatedUser: user });
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
