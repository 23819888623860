import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';

// models
import { CollaborationType } from '@app/core/models/collaboration-type.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { DecisionTableColumnType } from '@app/core/models/decision-table-column-type.model';
import { DecisionTableColumn } from '@app/core/models/decision-table-column.model';

@Component({
  selector: 'app-new-decision-table',
  templateUrl: './new-decision-table.component.html',
  styleUrls: ['./new-decision-table.component.scss'],
})
export class NewDecisionTableComponent implements OnInit, OnDestroy {
  @Input() decisionTableType: string;
  @Input() roleMandateId: number;
  @Input() connectionIndicatorId: number;
  @Input() decisionTables: DecisionTable[];
  @Output() addedDecisionTables = new EventEmitter<DecisionTable[]>();

  newDecisionTableForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    instructions: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    collaborationType: new FormControl('', [Validators.required]),
    networkType: new FormControl(''),
  });
  newDecisionTableColumnForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    required: new FormControl(0, [Validators.required]),
  });
  decisionTableColumns: DecisionTableColumn[] = [];
  collaborationTypes: CollaborationType[] = [];
  displayedColumns: string[] = ['title', 'type'];

  decisionTableColumnTypes: DecisionTableColumnType[] = [
    { id: 1, name: 'Decision Area' },
    { id: 2, name: 'Users' },
    { id: 3, name: 'Text' },
    { id: 4, name: 'Number' },
  ];

  private initialSubscriptions: Subscription;

  privacyMessages = [
    { id: 5, message: 'Allow users to set answers as private' },
    { id: 9, message: 'This table is private' },
  ];
  acl = 0;

  constructor(
    private decisionTableService: DecisionTableService,
    private roleMandateService: RoleMandateService,
    private cIService: ConnectionsIndicatorService,
  ) {}

  ngOnInit(): void {
    this.initialSubscriptions = this.decisionTableService.getCollaborationTypes().subscribe({
      next: (result) => {
        this.collaborationTypes = result;
      },
    });
  }

  printColumnTypeName(id: number) {
    const name = this.decisionTableColumnTypes.filter((element) => Number(element.id) === id)[0].name;
    return name ? name : '';
  }

  addDecisionTable(formDirective: FormGroupDirective) {
    if (this.newDecisionTableForm.invalid) {
      return;
    }

    const decisionTable: DecisionTable = {
      id: null,
      title: this.newDecisionTableForm.get('title').value,
      instructions: this.newDecisionTableForm.get('instructions').value,
      description: this.newDecisionTableForm.get('description').value,
      collaborationTypeId: this.newDecisionTableForm.get('collaborationType').value,
      networkType: this.newDecisionTableForm.get('networkType').value,
      columns: this.decisionTableColumns,
    };

    if (this.decisionTableType === 'roleMandate') {
      decisionTable.acl = this.acl;
      this.roleMandateService.saveDecisionTable(this.roleMandateId, decisionTable).subscribe({
        next: (result) => {
          decisionTable.id = result.id;
          if (this.decisionTables && this.decisionTables.length > 0) {
            this.decisionTables = this.decisionTables.concat(decisionTable);
          } else {
            this.decisionTables = [decisionTable];
          }
          this.addedDecisionTables.emit(this.decisionTables);
          this.decisionTableColumns = [];
          formDirective.resetForm();
          this.newDecisionTableForm.reset();
          this.newDecisionTableColumnForm.reset();
          this.acl = 0;
        },
      });
    } else if (this.decisionTableType === 'connectionsIndicator') {
      decisionTable.acl = this.acl;
      this.cIService.saveDecisionTable(this.connectionIndicatorId, decisionTable).subscribe({
        next: (result) => {
          decisionTable.id = result.decisionTableId;
          if (this.decisionTables && this.decisionTables.length > 0) {
            this.decisionTables = this.decisionTables.concat(decisionTable);
          } else {
            this.decisionTables = [decisionTable];
          }
          this.addedDecisionTables.emit(this.decisionTables);
          this.decisionTableColumns = [];
          formDirective.resetForm();
          this.newDecisionTableForm.reset();
          this.newDecisionTableColumnForm.reset();
          this.acl = 0;
        },
      });
    }
  }

  addColumn(ngNewDecisionTableColumnForm: FormGroupDirective) {
    if (this.newDecisionTableColumnForm.invalid) {
      return;
    }

    const decisionTableColumn: DecisionTableColumn = {
      id: null,
      decisionTableId: null,
      title: this.newDecisionTableColumnForm.get('title').value,
      type: this.newDecisionTableColumnForm.get('type').value,
      required: this.newDecisionTableColumnForm.get('required').value,
    };

    this.decisionTableColumns = this.decisionTableColumns.concat(decisionTableColumn);
    this.newDecisionTableColumnForm.reset();
    ngNewDecisionTableColumnForm.resetForm();
    this.newDecisionTableColumnForm.get('required').setValue(0);
  }

  ngOnDestroy(): void {
    if (this.initialSubscriptions) {
      this.initialSubscriptions.unsubscribe();
    }
  }

  onChangeACL(event, privacyMsg) {
    return event.checked ? (this.acl = privacyMsg.id) : (this.acl = 0);
  }
}
