<div class="container-fluid">
  <span *ngIf="psyTestGroup" class="psb-page-navigator"
    >Test Group > <b>{{ this.psyTestGroup.title }}</b> >
    {{ tabs[+selected.value] }}
  </span>

  <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected.value" (selectedIndexChange)="tabChange($event)">
    <mat-tab label="Details">
      <app-psb-stats-line *ngIf="psbStats?.length" [psbStats]="psbStats"></app-psb-stats-line>
      <button mat-raised-button color="primary" class="btn-sucess pull-rigth edit-button" (click)="onEdit()">
        Edit
      </button>

      <div class="row panel" *ngIf="psyTestGroup">
        <div class="column">
          <span class="label">Title</span>
          <p>{{ psyTestGroup.title }}</p>
          <span class="label">Description</span>
          <p>{{ psyTestGroup.description }}</p>
          <span class="label">Client</span>
          <p>{{ psyTestGroup.company }}</p>
          <span class="label">Status</span>
          <p>{{ psyTestGroup.status }}</p>
          <span class="label">Scorecard Title</span>
          <p>{{ psyTestGroup.scorecardTitle }}</p>
          <span class="label">Date Created</span>
          <p>{{ psyTestGroup.dateCreated }}</p>
          <div *ngIf="psyTestGroup.dateOpened">
            <span class="label">Date Last Opened</span>
            <p>{{ psyTestGroup.dateOpened }}</p>
          </div>
          <div class="row">
            <span class="label">Use Company Logo</span>
            <mat-slide-toggle
              class="slider"
              color="primary"
              [formControl]="logo"
              (change)="onLogoChange($event)"
              [checked]="logo.value"
            ></mat-slide-toggle>
          </div>
          <span class="label">Send Emails From</span>
          <p>{{ psyTestGroup.fromEmailAddress }}</p>
        </div>
        <div class="column">
          <h2>Location</h2>
          <span class="label">Address</span>
          <p>{{ psyTestGroup.address }}</p>
          <span class="label">Address 2</span>
          <p>{{ psyTestGroup.address2 }}</p>
          <span class="label">Country</span>
          <p>{{ psyTestGroup.country }}</p>
          <span class="label">State</span>
          <p>{{ psyTestGroup.state }}</p>
          <span class="label">City</span>
          <p>{{ psyTestGroup.city }}</p>
          <span class="label">Zip Code</span>
          <p>{{ psyTestGroup.zipCode }}</p>
          <div *ngIf="psyTestGroup.logo">
            <span class="label">Logo</span>
            <p><img [src]="psyTestGroup.logo" width="100px" height="auto" alt="Group Logo" /></p>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Users">
      <ng-template matTabContent>
        <app-test-group-user-list-table
          *ngIf="testGroupId && psyTestGroup"
          [testGroupId]="testGroupId"
          [companyId]="psyTestGroup.companyId"
        >
        </app-test-group-user-list-table>

        <app-edit-docx-template></app-edit-docx-template>

        <app-user-invite *ngIf="psyTestGroup" [testGroup]="psyTestGroup" [hasTests]="hasTests"></app-user-invite>
      </ng-template>
    </mat-tab>
    <mat-tab label="Tests">
      <app-test-group-test-list-table
        *ngIf="psyTestGroup && psyTestGroup.title"
        [isSuperTest]="false"
        [testGroupId]="testGroupId"
        (hasTestsInTestGroup)="checkTestsInTestGroup($event)"
        [testGroupTitle]="psyTestGroup.title"
      >
      </app-test-group-test-list-table>
      <app-test-group-test-list-table
        *ngIf="psyTestGroup && psyTestGroup.title"
        [isSuperTest]="true"
        [testGroupId]="testGroupId"
        (hasTestsInTestGroup)="checkTestsInTestGroup($event)"
        [testGroupTitle]="psyTestGroup.title"
      ></app-test-group-test-list-table>
    </mat-tab>
    <mat-tab label="Charts">
      <ng-template matTabContent>
        <app-test-group-chart-list-table [testGroupId]="testGroupId"></app-test-group-chart-list-table>
      </ng-template>
    </mat-tab>

    <mat-tab label="E-mail">
      <app-psb-stats-line
        *ngIf="inviteAndReminderStats?.length"
        [psbStats]="inviteAndReminderStats"
      ></app-psb-stats-line>
      <app-test-group-email
        *ngIf="psyTestGroupUsers && userInvites"
        [companyId]="companyId"
        [testGroupId]="testGroupId"
        [testGroup]="psyTestGroup"
        [testGroupUsers]="psyTestGroupUsers"
        [invites]="userInvites"
      ></app-test-group-email>
    </mat-tab>

    <mat-tab label="Report">
      <ng-template matTabContent>
        <app-test-group-report
          *ngIf="psyTestGroup && psyTestGroup.title"
          [testGroupId]="testGroupId"
          [role]="role"
          [testGroupTitle]="psyTestGroup.title"
        ></app-test-group-report>
      </ng-template>
    </mat-tab>

    <mat-tab label="Notifications Channel">
      <ng-template matTabContent>
        <app-notifications-channel [testGroupId]="testGroupId"></app-notifications-channel>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
