import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDragEnter, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TooltipPosition } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';

// services
import { UserService } from '@app/core/services/user.service';
import { QuestionService } from '@app/core/services/question.service';
import { PaginationService } from '@app/core/services/pagination.service';
import { TestService } from '@app/core/services/test.service';

// models
import { UserAnswer } from '@app/core/models/user-answer.model';
import { Question } from '@app/core/models/question.model';
import { Answer } from '@app/core/models/answer.model';
import { CARDSORT360_TOOLTIP, CARDSORT_TOOLTIP } from '@app/shared/constants/cardSort-Tooltip';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UtilsService } from '@app/core/services/utils.service';
import { PsyTest } from '@app/core/models/psy-test.model';

@Component({
  selector: 'app-answer-cardsort',
  templateUrl: './answer-cardsort.component.html',
  styleUrls: ['./answer-cardsort.component.scss'],
})
export class AnswerCardsortComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: Question;
  @Input() answering: boolean;
  @Input() loadAnswer: boolean;
  @Input() roleMandateId: number;
  @Input() connectionIndicatorId: number;
  @Input() psyTestId: number;
  @Input() superTestId: number;
  @Input() userInfoId: number;
  @Input() testResultId: number;
  @Input() isPreview: boolean;
  @Input() showResults: boolean;
  @Input() toolTipType: string;
  @Input() testAnswerRequired?: boolean;
  @Input() saveOriginalOrderAnswersQuestionNumber: number;
  @Input() psyTest: PsyTest;
  @Output() userAnswer = new EventEmitter<{ questionId: number; userAnswer: UserAnswer }>();
  @Output() cardAnswerSaved = new EventEmitter<any>();

  answers: Answer[] = [];
  lastAnswer: { answer: Answer; position: number; currentPosition?: number };
  noDeleted: boolean;
  deletedAnswers: Answer[] = [];
  cdkEntered = false;
  undone: boolean;
  answerPrivate = false;
  message: string;
  tooltipPosition: TooltipPosition = 'right';
  cardSortTooltip: string = '';
  errorMessage: string;
  paginationListenerSubs: Subscription;
  saveOriginalOrderAnswerQuestionNumberListenerSubs: Subscription;
  isTooltipDisabled = false;
  timeout: any;

  @ViewChild('cardAsnwers') CardAsnwersDOMElement: ElementRef;

  constructor(
    private pService: PaginationService,
    private questionService: QuestionService,
    private userService: UserService,
    private cDRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private utilsService: UtilsService,
    private testService: TestService,
  ) {}

  ngOnInit(): void {
    if (this.loadAnswer) {
      this.loadAnswers();
    }

    if (!this.isPreview) {
      if (this.psyTestId || this.superTestId) {
        if (this.answering) {
          if (!this.testResultId && this.question.userAnswer && this.question.userAnswer.listAnswer) {
            this.loadListAnswerFromUserAnswer(this.question.userAnswer);
          }
        }
      } else {
        if (this.showResults) {
          if (this.roleMandateId && this.userInfoId) {
            if (this.userService.isOwner(+this.userInfoId)) {
              this.loadUserAnswer(this.question.answers, true);
            } else {
              this.loadUserAnswer(this.question.answers, false);
            }
          }
        } else {
          if (this.userService.isOwner(+this.userInfoId)) {
            if (this.question.userAnswer && this.question.userAnswer.listAnswer) {
              this.loadListAnswerFromUserAnswer(this.question.userAnswer);
            }
          } else {
            this.questionService
              .findAnswerCardsortByQuestionIdAndUserId(
                this.question.id,
                this.userInfoId,
                this.roleMandateId,
                this.connectionIndicatorId,
              )
              .subscribe((result) => {
                this.loadUserAnswer(result, false);
              });
          }
        }
      }
    }
  }

  ngOnChanges(): void {
    if (this.toolTipType == 'cardSort') {
      this.cardSortTooltip = CARDSORT_TOOLTIP;
    } else if (this.toolTipType == 'cardSort 360') {
      this.cardSortTooltip = CARDSORT360_TOOLTIP;
    }
    this.loadAnswers();

    if (!this.roleMandateId) {
      this.saveOriginalOrderAnswers();

      if (this.testAnswerRequired) {
        this.testService.getUnAnswerQuestionIds().subscribe((unAnswerQuestionIds) => {
          if (unAnswerQuestionIds && unAnswerQuestionIds.length > 0 && unAnswerQuestionIds.includes(this.question.id)) {
            this.saveAnswerCardsort();
            this.cDRef.detectChanges();
          }
        });
      }
    }

    if (this.roleMandateId) {
      this.paginationListenerSubs = this.pService.getPaginationListener().subscribe((value) => {
        if (value) {
          this.saveOriginalOrderAnswerQuestionNumberListenerSubs = this.questionService
            .getOriginalOrderCardsortAnswersQuestionNumberListener()
            .subscribe((saveOriginalOrderCardsortAnswersQuestionNumber) => {
              this.saveOriginalOrderAnswersQuestionNumber = saveOriginalOrderCardsortAnswersQuestionNumber;
              this.saveOriginalOrderAnswers();
              this.cDRef.detectChanges();
            });
        }
      });
    }
  }

  onMouseEnter() {
    this.isTooltipDisabled = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.isTooltipDisabled = true;
    }, 3500);
  }

  onMouseLeave() {
    this.isTooltipDisabled = false;
  }

  saveOriginalOrderAnswers() {
    if (
      this.saveOriginalOrderAnswersQuestionNumber &&
      this.saveOriginalOrderAnswersQuestionNumber === this.question.id
    ) {
      this.saveAnswerCardsort();
    }
  }

  loadListAnswerFromUserAnswer(userAnswer: UserAnswer) {
    this.answers = [];
    this.question.originalAnswers.forEach((cardsortAnswer) => {
      if (!this.answers.includes(cardsortAnswer)) {
        this.answers.push(cardsortAnswer);
      }
    });

    userAnswer.listAnswer.map((cardsortAnswer) => {
      this.answers.map((answer) => {
        if (answer.id === cardsortAnswer.id) {
          answer['aOrder'] = cardsortAnswer.order;
          if (this.roleMandateId) {
            answer.private = cardsortAnswer.private;
          }
        }
      });
    });

    const userAnswerIds = userAnswer.listAnswer.map((answer) => answer.id);
    this.deletedAnswers = this.question.originalAnswers.filter(
      (answer) => !userAnswer.listAnswer.some((ans) => ans.id === answer.id),
    );
    if (this.deletedAnswers && this.deletedAnswers.length > 0) {
      this.answers = this.answers.filter((answer) => userAnswerIds.includes(answer.id));
    }

    this.answers.sort((a, b) => a['aOrder'] - b['aOrder']);

    if (this.roleMandateId) {
      this.updateRoleNavigatorCardsortAnswers();
      if (this.answers && this.answers.length > 0) {
        this.answerPrivate = !!this.answers[0].private;
      } else {
        if (true) {
          this.loadAnswers();
        }
      }
    }
  }

  updateRoleNavigatorCardsortAnswers() {
    if (this.roleMandateId) {
      if (this.answers.length > 0) {
        this.updateCardSortAnswerSaved(this.answers);
      } else if (this.answers.length === 0) {
        this.updateCardSortAnswerNotSaved(this.question.originalAnswers);
      }
    }
  }

  getInstructionInnerHTML(text: any) {
    let _innerText = new DOMParser().parseFromString(text, 'text/html');
    return _innerText.body?.innerText;
  }

  loadUserAnswer(result: Answer[], owner: boolean) {
    if (result?.length) {
      this.deletedAnswers = this.question.answers.filter((answer) => !result.some((ans) => ans.id === answer.id));
      this.answers = result;
      this.answerPrivate = !!result[0].private;
    } else {
      if (owner) {
        this.loadAnswers();
      } else {
        this.message = 'This answer was defined as private by the user';
      }
    }
  }

  loadAnswers() {
    this.answers = this.question.answers;
    if (this.question.randomizeCards) {
      const randomValues = this.answers.map(() => Math.random());

      this.answers.sort((a, b) => {
        const randomA = randomValues[this.answers.indexOf(a)];
        const randomB = randomValues[this.answers.indexOf(b)];

        return randomA - randomB;
      });
    } else {
      this.answers.forEach((x, index) => {
        x.order = index;
      });
    }
  }

  drop(event: CdkDragDrop<Answer[]>, noDeleted?: boolean) {
    this.utilsService.isDragAndDropAnswerCard.drag = false;
    this.utilsService.isDragAndDropAnswerCard.drop = true;
    this.cdkEntered = false;
    if (noDeleted) {
      this.noDeleted = noDeleted;
    }

    if (event.previousContainer === event.container) {
      this.lastAnswer = {
        answer: event.container.data[event.previousIndex],
        position: event.previousIndex,
        currentPosition: event.currentIndex,
      };
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      this.saveAnswers(event);
    } else {
      if (event.previousContainer.data.length > 2 || event.previousContainer.id.includes('deleted')) {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        this.lastAnswer = {
          answer: event.container.data[0],
          position: event.previousIndex,
        };

        this.saveAnswers(event);
      } else if (
        (event.previousContainer.data.length === 2 || event.previousContainer.id.includes('deleted')) &&
        !this.psyTest?.discardAll
      ) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
        confirmationDialog.componentInstance.message = `Are you sure you want to keep just only one?`;
        confirmationDialog.componentInstance.isShowCancel = true;
        confirmationDialog.afterClosed().subscribe((result) => {
          if (result.event !== 'cancel') {
            transferArrayItem(
              event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex,
            );
            this.lastAnswer = {
              answer: event.container.data[0],
              position: event.previousIndex,
            };

            this.saveAnswers(event);
          }
        });
      } else if (event.previousContainer.data.length === 1 && this.psyTest && this.psyTest.discardAll) {
        // transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        // this.lastAnswer = {
        //   answer: event.container.data[0],
        //   position: event.previousIndex,
        // };
        //
        // this.saveAnswers(event);
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
        confirmationDialog.componentInstance.message = `Confirm that you would like to discard the remaining card.`;
        confirmationDialog.componentInstance.isShowCancel = true;
        confirmationDialog.afterClosed().subscribe((result) => {
          if (result.event !== 'cancel') {
            transferArrayItem(
              event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex,
            );
            this.lastAnswer = {
              answer: event.container.data[0],
              position: event.previousIndex,
            };

            this.saveAnswers(event);
          }
        });
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        this.lastAnswer = {
          answer: event.container.data[0],
          position: event.previousIndex,
        };

        this.saveAnswers(event);
      }
    }
  }

  saveAnswers(event: CdkDragDrop<Answer[]>) {
    event.previousContainer.data.forEach((x, index) => {
      x.order = index;
    });
    event.container.data.forEach((x, index) => {
      x.order = index;
    });

    this.saveAnswerCardsort();
  }

  undo() {
    if (this.noDeleted) {
      this.answers.splice(this.lastAnswer.currentPosition, 1);
      this.answers.splice(this.lastAnswer.position, 0, this.lastAnswer.answer);
      this.undone = true;
    } else {
      this.answers.splice(this.lastAnswer.position, 0, this.lastAnswer.answer);
      this.deletedAnswers = this.deletedAnswers.filter((a) => a !== this.lastAnswer.answer);
      this.undone = true;
    }

    this.answers.forEach((x, index) => {
      x.order = index;
    });

    this.saveAnswerCardsort();
  }

  saveAnswerCardsort() {
    this.answers.map((answer) => (answer.private = this.answerPrivate));
    if (this.answers.length < 1 && this.deletedAnswers.length > 0) {
      const discardedAll = {
        id: null,
        image: null,
        imageContentType: null,
        order: null,
        preferedAnswerTypeId: null,
        private: false,
        questionId: this.question.id,
        text: this.lastAnswer.answer.text,
        subscaleId: this.lastAnswer.answer.subscaleId,
      };
      this.questionService.saveAnswerCardsort(this.question.id, this.userInfoId, [discardedAll]).subscribe();
    } else {
      this.answers = this.answers.map((answer) => {
        if (!answer.hasOwnProperty('order')) {
          return { ...answer, order: answer.aOrder - 1 };
        }
        return answer;
      });

      this.questionService.saveAnswerCardsort(this.question.id, this.userInfoId, this.answers).subscribe(() => {
        const listAnswer = this.answers.map((answer) => {
          return { id: answer.id, order: answer.order ? answer.order : answer.aOrder - 1 };
        });
        const userAnswer = {
          questionId: this.question.id,
          userAnswer: {
            integerAnswer: null,
            textAnswer: null,
            listAnswer,
          },
        };

        if (this.roleMandateId) {
          this.updateCardSortAnswerSaved(this.answers);
        }

        if (this.deletedAnswers && this.deletedAnswers.length >= this.question.numOnGarbage) {
          this.errorMessage = '';
          this.userAnswer.emit(userAnswer);
          this.pService.setPaginationListener(false);
        } else {
          if (this.question.numOnGarbage === 1) {
            this.errorMessage = `You need to discard at least ${this.question.numOnGarbage} card`;
          } else if (this.question.numOnGarbage > 1) {
            this.errorMessage = `You need to discard at least ${this.question.numOnGarbage} cards`;
          }
        }
      });
    }
  }

  deletePredicate(item: CdkDrag<Answer>) {
    if (this.question && !this.question.showDiscarded) {
      return item.dropContainer.data.length > 1;
    } else {
      return true;
    }
  }

  entered(event: CdkDragEnter) {
    this.cdkEntered = true;
  }

  cdkDragStarted(event: any) {
    this.utilsService.isDragAndDropAnswerCard.drag = true;
    this.utilsService.isDragAndDropAnswerCard.drop = false;
  }
  togglePrivacy() {
    this.answerPrivate = !this.answerPrivate;

    this.answers.forEach((x, index) => {
      x.order = index;
    });

    this.saveAnswerCardsort();
  }

  updateCardSortAnswerSaved(answers: any) {
    this.cardAnswerSaved.emit({
      questionId: this.question.id,
      answers,
      saved: true,
    });
  }

  updateCardSortAnswerNotSaved(answers: any) {
    this.cardAnswerSaved.emit({
      questionId: this.question.id,
      answers,
      saved: false,
    });
  }

  ngOnDestroy(): void {
    if (this.paginationListenerSubs) {
      this.paginationListenerSubs.unsubscribe();
    }

    if (this.saveOriginalOrderAnswerQuestionNumberListenerSubs) {
      this.saveOriginalOrderAnswerQuestionNumberListenerSubs.unsubscribe();
    }
  }
}
