/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';

// services
import { FormService } from '@app/core/services/form.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { Country } from '@app/core/models/country.model';
import { State } from '@app/core/models/state.model';
import { Department } from '@app/core/models/department.model';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { Company } from '@app/core/models/company.model';

// constants
import { EVENT_CANCEL, ROLE_ADMIN } from '@app/core/constants';
import { EmailService } from '@app/core/services/email.service';
import { UserService } from '@app/core/services/user.service';
import { logoDefault } from './test-group-modal.helper';

@Component({
  selector: 'app-test-group-modal',
  templateUrl: './test-group-modal.component.html',
  styleUrls: ['./test-group-modal.component.scss'],
})
export class TestGroupModalComponent implements OnInit {
  testGroupModalForm = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    companyId: new FormControl(null, [Validators.required]),
    departmentId: new FormControl(null),
    country: new FormControl(null),
    stateId: new FormControl(null),
    city: new FormControl(null, [Validators.required]),
    zipCode: new FormControl(null, [Validators.required]),
    address: new FormControl(null, [Validators.required]),
    address2: new FormControl(null),
    billingCountry: new FormControl(null),
    billingStateId: new FormControl(null),
    billingCity: new FormControl(null),
    billingZipCode: new FormControl(null),
    billingAddress: new FormControl(null),
    billingAddress2: new FormControl(null),
    fromEmailAddress: new FormControl('psybil@psynetgroup.com', [Validators.required]),
    logo: new FormControl(logoDefault),
    logoContentType: new FormControl('image/jpeg'),
    logoFile: new FormControl(null),
  });

  countries: Country[];
  clients: Company[];
  states: State[];
  billingStates: State[];
  sameAsLocation = true;
  departments: Department[];
  isEdit: boolean;
  emailAddresses: string[];
  isAdmin: boolean = false;
  filteredClients: Observable<Company[]>;
  filteredDepartments: Observable<Department[]>;
  filteredCountries: Observable<Country[]>;
  filteredStates: Observable<State[]>;
  filteredBillingStates: Observable<State[]>;
  selectedCountry: Country;
  selectedClient: Company;
  selectedDepartment: Department;
  selectedState: State;
  selectedBillingState: State;
  fileName: string;

  private subscriptionsStateReadySubject: Subject<boolean> = new Subject<boolean>();
  private subscriptionsBillingStateReadySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<TestGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; companyId: number; psyTestGroup: PsyTestGroup },
    private formService: FormService,
    private companyService: CompanyService,
    private emailService: EmailService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
    if (authorities.includes(ROLE_ADMIN)) {
      this.isAdmin = true;
    }

    if (this.isAdmin) {
      this.emailService.findAllSources().subscribe((sources: string[]) => (this.emailAddresses = sources));
    } else {
      this.testGroupModalForm.get('fromEmailAddress').setValue('psybil@psynetgroup.com');
      this.testGroupModalForm.get('companyId').setValue(this.userService.getUserData().companyId);
      this.onSelectClient(this.userService.getUserData().companyId);
    }

    this.companyService.getAll().subscribe((result) => {
      this.clients = [null, ...result];
      if (this.data.psyTestGroup) {
        this.selectedClient = this.clients.filter((c) => c && c.id === this.data.psyTestGroup.companyId)[0];
        this.loadData();
      } else if (this.data.companyId) {
        this.selectedClient = this.clients.filter((c) => c && c.id === this.data.companyId)[0];
        this.loadData();
      }

      this.filteredClients = this.testGroupModalForm.controls['companyId'].valueChanges.pipe(
        startWith(''),
        map((client) => (client ? this._filterCompany(client) : this.clients.slice())),
      );
    });

    this.formService.getCountries().subscribe((result: Country[]) => {
      this.countries = [null, ...result];
      if (this.data.psyTestGroup) {
        this.selectedCountry = this.countries.filter((c) => c && c.id === this.data.psyTestGroup['countryId'])[0];
      }

      this.filteredCountries = this.testGroupModalForm.controls['country'].valueChanges.pipe(
        startWith(''),
        map((country) => (country ? this._filterCountry(country) : this.countries.slice())),
      );
    });

    this.isEdit = this.data.isEdit;

    if (this.data.companyId && !this.isEdit) {
      this.onSelectClient(this.data.companyId);
    }

    if (this.isEdit && this.data.psyTestGroup) {
      if (
        (this.data.psyTestGroup.billingAddress === this.data.psyTestGroup.address &&
          this.data.psyTestGroup.zipCode === this.data.psyTestGroup.billingZipCode &&
          this.data.psyTestGroup.billingCity === this.data.psyTestGroup.city) ||
        !this.data.psyTestGroup.billingAddress
      ) {
        this.sameAsLocation = true;
      } else {
        this.sameAsLocation = false;
      }

      if (this.sameAsLocation !== true) {
        this.formService.getCountryByStateId(this.data.psyTestGroup.billingStateId).subscribe((billingCountry) => {
          if (billingCountry) {
            this.testGroupModalForm
              .get('billingCountry')
              .setValue(this.countries.filter((c) => c && c.id === billingCountry[0]['countryId'])[0]);
            this.onSelectBillingCountry(billingCountry[0]['countryId']);
          }
        });
      }

      this.getDepartmentById(this.data.psyTestGroup.companyId);
      this.onSelectCountry(this.data.psyTestGroup['countryId']);

      if (this.sameAsLocation === true) {
        this.subscriptionsStateReadySubject.subscribe((result) => {
          if (result) {
            this.loadData();
          }
        });
      } else {
        this.subscriptionsBillingStateReadySubject.subscribe((result) => {
          if (result) {
            this.loadData();
          }
        });
      }
    }
  }

  loadData() {
    if (this.sameAsLocation === true) {
      this.testGroupModalForm.get('billingStateId').setValue(this.selectedState);
      this.testGroupModalForm.get('billingCountry').setValue(this.selectedCountry);
    } else {
      this.testGroupModalForm.get('billingStateId').setValue(this.selectedBillingState);
    }

    this.testGroupModalForm.get('title').setValue(this.data.psyTestGroup.title);
    this.testGroupModalForm.get('description').setValue(this.data.psyTestGroup.description);
    this.testGroupModalForm.get('companyId').setValue(this.selectedClient);
    this.testGroupModalForm.get('departmentId').setValue(this.selectedDepartment);
    this.testGroupModalForm.get('country').setValue(this.selectedCountry);
    this.testGroupModalForm.get('stateId').setValue(this.selectedState);
    this.testGroupModalForm.get('city').setValue(this.data.psyTestGroup.city);
    this.testGroupModalForm.get('zipCode').setValue(this.data.psyTestGroup.zipCode);
    this.testGroupModalForm.get('address').setValue(this.data.psyTestGroup.address);
    this.testGroupModalForm.get('address2').setValue(this.data.psyTestGroup.address2);
    this.testGroupModalForm.get('billingCity').setValue(this.data.psyTestGroup.billingCity);
    this.testGroupModalForm.get('billingZipCode').setValue(this.data.psyTestGroup.billingZipCode);
    this.testGroupModalForm.get('billingAddress').setValue(this.data.psyTestGroup.billingAddress);
    this.testGroupModalForm.get('billingAddress2').setValue(this.data.psyTestGroup.billingAddress2);
    this.testGroupModalForm.get('fromEmailAddress').setValue(this.data.psyTestGroup.fromEmailAddress);
    this._getLogo();
  }

  compareFn(x: any, y: any): boolean {
    return parseInt(x, 10) === y ? true : false;
  }

  onSelectClient(companyId: number) {
    this.getCompanyLocation(companyId);
    this.getDepartmentById(companyId);
  }

  onSelectCountry(countryId: number) {
    this.formService.getStatesByCountry(countryId).subscribe((response) => {
      this.states = [null, ...response];
      if (this.data.psyTestGroup) {
        this.selectedState = this.states.filter((s) => s && s.id === this.data.psyTestGroup['stateId'])[0];
      }
      this.filteredStates = this.testGroupModalForm.controls['stateId'].valueChanges.pipe(
        startWith(''),
        map((state) => (state ? this._filterState(state) : this.states.slice())),
      );
      this.subscriptionsStateReadySubject.next(true);
    });
  }

  onSelectBillingCountry(countryId: number) {
    this.formService.getStatesByCountry(countryId).subscribe((response) => {
      this.billingStates = [null, ...response];
      if (this.data.psyTestGroup) {
        this.selectedBillingState = this.billingStates.filter(
          (s) => s && s.id === this.data.psyTestGroup['billingStateId'],
        )[0];
      }
      this.filteredBillingStates = this.testGroupModalForm.controls['billingStateId'].valueChanges.pipe(
        startWith(''),
        map((billingState) => (billingState ? this._filterBillingState(billingState) : this.billingStates.slice())),
      );

      this.subscriptionsBillingStateReadySubject.next(true);
    });
  }

  getCompanyLocation(companyId: number) {
    this.companyService.getById(companyId).subscribe((response) => {
      this.formService.getCountries().subscribe((result: Country[]) => {
        this.countries = [null, ...result];
        this.selectedCountry = this.countries.filter((c) => c && c.id === response.countryId)[0];
        this.testGroupModalForm.get('country').setValue(this.selectedCountry);

        this.filteredCountries = this.testGroupModalForm.controls['country'].valueChanges.pipe(
          startWith(''),
          map((country) => (country ? this._filterCountry(country) : this.countries.slice())),
        );
      });

      this.formService.getStatesByCountry(response.countryId).subscribe((states) => {
        this.states = [null, ...states];
        this.selectedState = this.states.filter((s) => s && s.id === response.stateId)[0];
        this.testGroupModalForm.get('stateId').setValue(this.selectedState);

        this.filteredStates = this.testGroupModalForm.controls['stateId'].valueChanges.pipe(
          startWith(''),
          map((state) => (state ? this._filterState(state) : this.states.slice())),
        );
      });
      this.testGroupModalForm.get('city').setValue(response.city);
      this.testGroupModalForm.get('zipCode').setValue(response.zipCode);
      this.testGroupModalForm.get('address').setValue(response.address);
      this.testGroupModalForm.get('address2').setValue(response.address2);
      if (response.logo) {
        this.testGroupModalForm.get('logo').setValue(response.logo);
        this.testGroupModalForm.get('logoContentType').setValue(response.logoContentType);
      }
    });
  }

  getDepartmentById(companyId: number) {
    this.companyService.getDepartmentsById(companyId).subscribe((response) => {
      this.departments = [null, ...response];
      if (this.data.psyTestGroup) {
        this.selectedDepartment = this.departments.filter(
          (d) => d && d.id === this.data.psyTestGroup['departmentId'],
        )[0];
      }

      this.filteredDepartments = this.testGroupModalForm.controls['departmentId'].valueChanges.pipe(
        startWith(''),
        map((department) => (department ? this._filterDepartment(department) : this.departments.slice())),
      );
    });
  }

  toggleBillingAddress() {
    this.sameAsLocation = !this.sameAsLocation;
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onSave() {
    if (this.sameAsLocation === true) {
      this.testGroupModalForm.get('billingCountry').setValue(this.testGroupModalForm.get('country').value);
      this.testGroupModalForm.get('billingStateId').setValue(this.testGroupModalForm.get('stateId').value);
      this.testGroupModalForm.get('billingCity').setValue(this.testGroupModalForm.get('city').value);
      this.testGroupModalForm.get('billingZipCode').setValue(this.testGroupModalForm.get('zipCode').value);
      this.testGroupModalForm.get('billingAddress').setValue(this.testGroupModalForm.get('address').value);
      this.testGroupModalForm.get('billingAddress2').setValue(this.testGroupModalForm.get('address2').value);
    }

    let companyId;
    if (!this.isAdmin) {
      companyId = this.userService.getUserData().companyId;
    } else {
      companyId = this.testGroupModalForm.get('companyId').value.id;
    }

    let psyTestGroup = {
      title: this.testGroupModalForm.get('title').value,
      description: this.testGroupModalForm.get('description').value,
      companyId,
      address: this.testGroupModalForm.get('address').value,
      address2: this.testGroupModalForm.get('address2').value,
      city: this.testGroupModalForm.get('city').value,
      country: this.testGroupModalForm.get('country').value ? this.testGroupModalForm.get('country').value.id : null,
      stateId: this.testGroupModalForm.get('stateId').value ? this.testGroupModalForm.get('stateId').value.id : null,
      zipCode: this.testGroupModalForm.get('zipCode').value,
      billingCountry: this.testGroupModalForm.get('billingCountry').value
        ? this.testGroupModalForm.get('billingCountry').value.id
        : null,
      billingAddress: this.testGroupModalForm.get('billingAddress').value,
      billingAddress2: this.testGroupModalForm.get('billingAddress2').value,
      billingCity: this.testGroupModalForm.get('billingCity').value,
      billingStateId: this.testGroupModalForm.get('billingStateId').value
        ? this.testGroupModalForm.get('billingStateId').value.id
        : null,
      billingZipCode: this.testGroupModalForm.get('billingZipCode').value,
      departmentId: this.testGroupModalForm.get('departmentId').value
        ? this.testGroupModalForm.get('departmentId').value.id
        : null,
      fromEmailAddress: this.testGroupModalForm.get('fromEmailAddress').value,
      logo: this.testGroupModalForm.get('logo').value ? this.testGroupModalForm.get('logo').value : null,
      logoContentType: this.testGroupModalForm.get('logoContentType').value
        ? this.testGroupModalForm.get('logoContentType').value
        : null,
    };

    this.dialogRef.close({ event: 'save', data: psyTestGroup });
  }

  displayClient(client: Company) {
    return client ? client.name : '';
  }

  displayDepartment(department: Department) {
    return department ? department.name : '';
  }

  displayCountry(country: Country): string {
    return country ? country.name : '';
  }

  displayState(state: State): string {
    return state ? state.name : '';
  }

  handleFile(fileType: string, $event: any) {
    const file = $event.target.files[0];
    this.fileName = file.name;
    const reader = new FileReader();
    this.testGroupModalForm.get('logoContentType').setValue(file.type);
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.testGroupModalForm.get('logo').setValue(event.target.result);
    };
  }

  private _filterCompany(value: string): Company[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.clients;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.clients.filter((client: Company) => {
      if (client) {
        return client.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterDepartment(value: string): Department[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.departments;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.departments.filter((department: Department) => {
      if (department) {
        return department.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterCountry(value: string): Country[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.countries;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.countries.filter((country: Country) => {
      if (country) {
        return country.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterState(value: string): State[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.states;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.states.filter((state: State) => {
      if (state) {
        return state.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterBillingState(value: string): State[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.billingStates;
    }

    if (typeof filterValue === 'number') {
      return;
    }

    return this.billingStates.filter((billingState: State) => {
      if (billingState) {
        return billingState.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _getLogo() {
    let logo = null;
    let logoContentType = null;

    if (this.data.psyTestGroup?.logo) {
      logo = this.data.psyTestGroup?.logo;
      logoContentType = this.data.psyTestGroup?.logoContentType;
    } else if (this.selectedClient?.logo) {
      logo = this.selectedClient.logo;
      logoContentType = this.selectedClient.logoContentType;
    } else {
      logo = logoDefault;
      logoContentType = 'image/jpeg';
    }

    this.testGroupModalForm.get('logo').setValue(logo);
    this.testGroupModalForm.get('logoContentType').setValue(logoContentType);
  }
}
