import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// services
import { UserService } from '@app/core/services/user.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { Department } from '@app/core/models/department.model';
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-department-form',
  templateUrl: './department-form.component.html',
  styleUrls: ['./department-form.component.scss'],
})
export class DepartmentFormComponent implements OnInit {
  @Input() answering: boolean;
  @Input() userInfo: UserInfo;

  departments: Department[] = [];
  filteredDepartments: Observable<Department[]>;
  departmentControl = new FormControl('', [Validators.required]);

  constructor(private userService: UserService, private companyService: CompanyService) {}

  ngOnInit(): void {
    this.companyService.getDepartmentsById(this.userInfo.companyId).subscribe({
      next: (result) => {
        this.departments = [
          ...new Map(
            result
              .filter((department) => department.name !== 'undefined')
              .map((department) => [department.name, department]),
          ).values(),
        ];
        if (this.userInfo.departmentId) {
          const dpt = result.filter((department) => department.id === this.userInfo.departmentId)[0];
          this.departmentControl.setValue(this.answering ? dpt : dpt.name);
        }

        this.filteredDepartments = this.departmentControl.valueChanges.pipe(
          startWith(''),
          map((department) => (department ? this._filterDepartment(department) : this.departments.slice())),
        );
      },
    });
  }

  displayDepartment(department: Department): string {
    return department ? department.name : '';
  }

  setDepartment(event) {
    this.userService.updateDepartment(this.userInfo.id, event.option.value).subscribe({
      next: (result) => {
        this.userInfo.departmentId = event.option.value.id;
      },
    });
  }

  private _filterDepartment(value: string): Department[] {
    const filterValue = value;
    return this.departments.filter((department) => department.name.indexOf(filterValue) === 0);
  }
}
