import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';

// services
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { ChartService } from '@app/core/services/chart.service';

// models
import { PsyChart } from '@app/core/models/psy-chart.model';

@Component({
  selector: 'app-chart-plot-setup',
  templateUrl: './chart-plot-setup.component.html',
  styleUrls: ['./chart-plot-setup.component.scss'],
})
export class ChartPlotSetupComponent implements OnInit {
  @Input() chart: PsyChart;

  form = this.fb.group({
    isColored: [false, [Validators.required]],
    plotRadius: [1, [Validators.required, Validators.min(1)]],
  });

  constructor(private fb: FormBuilder, private chartService: ChartService, private snackBar: SnackBarService) {}

  ngOnInit(): void {
    this.updateForm();
  }

  updateForm() {
    // Handling different db connectors responses
    let coloredPlot;
    if (this.chart.coloredPlot) {
      if (Object(this.chart.coloredPlot).hasOwnProperty('data')) {
        coloredPlot = this.chart.coloredPlot.data[0];
      } else {
        coloredPlot = this.chart.coloredPlot;
      }
    }
    this.form.patchValue({
      isColored: coloredPlot,
      plotRadius: this.chart.plotRadius || 1,
    });
  }

  onSave() {
    const { isColored: coloredPlot, plotRadius } = this.form.value;
    this.chartService.updateChartPlot(this.chart.id, { coloredPlot, plotRadius }).subscribe(
      (newChart) => {
        this.chart = newChart;
        this.updateForm();
        this.snackBar.info('Chart Plot set up successfully.');
      },
      (err) => {
        console.error(err);
        this.updateForm();
      },
    );
  }
}
