import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { Node, ForceDirectedGraph } from '@app/core/models/d3';
import { D3Service } from '@app/core/services/d3.service';

@Directive({
  selector: 'g[appDraggable],appDraggable',
})
export class DraggableDirective implements OnInit {
  @Input() appDraggable: null;
  @Input() draggableNode: Node;
  @Input() draggableInGraph: ForceDirectedGraph;

  constructor(private d3Service: D3Service, private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.d3Service.applyDraggableBehaviour(this.elementRef.nativeElement, this.draggableNode, this.draggableInGraph);
  }
}
