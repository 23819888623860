<mat-card>
  <mat-card-header>
    <h2>New Users</h2>
  </mat-card-header>
  <mat-card-content>
    <span class="mat-card-description">Invite new user to register.</span>
    <p class="space"></p>
    <form [formGroup]="inviteForm" (submit)="createNewInvite()">
      <div class="row">
        <mat-form-field>
          <mat-label>From</mat-label>
          <mat-select formControlName="from">
            <mat-option *ngFor="let source of sources" [value]="source"> {{ source }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row invite-form">
        <div class="column">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput type="text" formControlName="firstName" />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput type="text" formControlName="lastName" />
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field>
            <input
              matInput
              type="email"
              formControlName="email"
              placeholder="E-mail"
              (ngModelChange)="this.emailFieldUpdate.next($event)"
            />
            <mat-error
              *ngIf="
                inviteForm.get('email').errors &&
                !inviteForm.get('email').hasError('invalidEmailAddress') &&
                !inviteForm.get('email').hasError('isEmailExist') &&
                !inviteForm.get('email').hasError('noTests') &&
                !inviteForm.get('email').hasError('isSentInviteToSameAddressWithinOneHour')
              "
              >Email address is required</mat-error
            >
            <mat-error
              *ngIf="
                inviteForm.get('email').hasError('invalidEmailAddress') &&
                !inviteForm.get('email').hasError('isEmailExist')
              "
            >
              This email is an invalid address.
            </mat-error>
            <mat-error
              *ngIf="
                !inviteForm.get('email').hasError('invalidEmailAddress') &&
                inviteForm.get('email').hasError('isSentInviteToSameAddressWithinOneHour')
              "
            >Can't send an invitation to the same email address within an hour.
            </mat-error>
            <mat-error
              *ngIf="
                !inviteForm.get('email').hasError('invalidEmailAddress') &&
                inviteForm.get('email').hasError('isEmailExist')
                && !inviteForm.get('email').hasError('isSentInviteToSameAddressWithinOneHour')
              "
              >This email has already been registered or invited.
            </mat-error>
            <mat-error
              *ngIf="
                inviteForm.get('email').hasError('noTests') &&
                !inviteForm.get('email').hasError('invalidEmailAddress') &&
                !inviteForm.get('email').hasError('isEmailExist') &&
                this.testGroup
              "
              >Can't invite users because there're no tests in this test group.
            </mat-error>
            <mat-hint>Enter the email of the new users to be invited.</mat-hint>
          </mat-form-field>
        </div>
        <button
          mat-raised-button
          color="primary"
          class="btn-success"
          type="submit"
          [disabled]="inviteForm.invalid || !emailNoExisted || (!this.hasTests && this.testGroup)"
        >
          Add
        </button>
        <button
          mat-raised-button
          color="accent"
          class="btn-success"
          type="button"
          (click)="resendInviteEmail()"
          [disabled]="!resendInvite || (!this.hasTests && this.testGroup) || this.inviteForm.get('email').value"
        >
          Resend Invite
        </button>
      </div>
    </form>
  </mat-card-content>

  <mat-card-header>
    <h3>Mass Invites</h3>
  </mat-card-header>
  <mat-card-content>
    <span class="mat-card-description">Invite multiple new users to register. Limited to 300 email addresses at once.</span>
    <p class="space"></p>
    <form [formGroup]="massInviteForm" (submit)="createNewInvites()">
      <div class="row">
        <mat-form-field>
          <mat-label>From</mat-label>
          <mat-select formControlName="from">
            <mat-option *ngFor="let source of sources" [value]="source"> {{ source }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row invite-form">
        <div class="column">
          <mat-form-field>
            <mat-chip-list #chipList aria-label="Email selection" ngDefaultControl formArrayName="emails">
              <mat-chip
                *ngFor="let email of emailControls.value"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeEmail(email)"
              >
                {{ email }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                matInput
                type="email"
                placeholder="E-mail"
                #massEmail
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (input)="this.massEmailFieldUpdate.next($event)"
              />
            </mat-chip-list>
            <mat-error *ngIf="emailControls.value.length === 0 && !massInviteForm.get('emails').hasError('noTests')">
              At least 1 email need to be added.
            </mat-error>
            <mat-error *ngIf="massInviteForm.get('emails').hasError('noTests') && this.testGroup">
              Can't invite users because there're no tests in this test group.
            </mat-error>
            <mat-hint>Enter the emails of the new users to be invited.</mat-hint>
          </mat-form-field>
        </div>
        <button
          mat-raised-button
          color="primary"
          class="btn-success"
          type="submit"
          [disabled]="
            emailControls.value.length === 0 || (massInviteForm.get('emails').hasError('noTests') && this.testGroup)
          "
        >
          Add
        </button>
      </div>
    </form>
  </mat-card-content>
  <div style="display: flex; flex-wrap: wrap">
    <mat-card *ngFor="let invalidEmail of invalidEmails" class="email-card">
      <mat-card-content class="card-content">
        {{ invalidEmail.email }} {{ invalidEmail.reason }}.
        <button mat-raised-button color="warn" (click)="closeCard(invalidEmail)">Close</button>
      </mat-card-content>
    </mat-card>
  </div>

  <app-generic-link *ngIf="testGroup && testGroup.id" [testGroupId]="testGroup.id"></app-generic-link>

  <app-psb-table-search [data]="invites" (filter)="applyFilterInvites($event)" placeholder="Search for Invites">
  </app-psb-table-search>

  <mat-table *ngIf="invites" [dataSource]="invites" class="mat-elevation-z3">
    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef> First Name</mat-header-cell>
      <mat-cell *matCellDef="let element"
        ><span class="mobile-label">First Name :</span> {{ element.firstName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef> Last Name</mat-header-cell>
      <mat-cell *matCellDef="let element"
        ><span class="mobile-label">Last Name :</span> {{ element.lastName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> E-mail</mat-header-cell>
      <mat-cell *matCellDef="let element"><span class="mobile-label">E-mail :</span> {{ element.email }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actionDelete">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Actions :</span>
        <button mat-button class="btn-error" (click)="onEditInvite(element)">Edit</button>
        <button mat-button class="btn-error" (click)="onDeleteInvite(element)">Delete</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedInviteColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedInviteColumns"></mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</mat-card>
