import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stripeHtml' })
export class StripeHtmlPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      // Remove HTML tags
      const strippedValue = value.replace(/<.*?>/g, '');

      // Remove HTML special characters
      const parser = new DOMParser();
      const dom = parser.parseFromString(`<div>${strippedValue}</div>`, 'text/html');
      return dom.body.textContent || '';
    }
  }
}
