<div *ngIf="userIsAuthenticated" class="container">
  <h1 id="welcome">Welcome, {{ userData.firstName }}</h1>

  <div class="progress" id="progress">
    <div class="progress-title">
      <h2>Progress</h2>
      <p
        *ngIf="
          getTotal() === getProgressBar() &&
            (userInfo.connectionIndicators || userInfo.roleMandates || userInfo.psyTests);
          else notShow
        "
      >
        You completed all the tests
      </p>
      <ng-template #notShow>
        <p *ngIf="getProgressBar()">{{ getProgressBar() }}% Completed</p>
      </ng-template>
    </div>
    <div>
      <mat-progress-bar mode="determinate" *ngIf="this.userInfo" value="{{ getProgressBar() }}"></mat-progress-bar>
    </div>
  </div>

  <div
    class="tests-available"
    *ngIf="userInfo?.superTests?.length > 0 || userInfo?.psyTests?.length > 0"
    id="tests-available"
  >
    <h2>Complete your Assessments</h2>
    <app-test-list [psyTests]="userInfo.psyTests"></app-test-list>
    <app-test-list [superTests]="userInfo.superTests"></app-test-list>
  </div>

  <div class="connection-indicator-available" *ngIf="userInfo.connectionIndicators" id="connection-indicator-available">
    <h2>Complete your Connections Indicator</h2>
    <app-test-list [connectionIndicators]="userInfo.connectionIndicators"> </app-test-list>
  </div>

  <div class="role-mandate-available" *ngIf="userInfo.roleMandates" id="role-mandate-available">
    <h2>Complete your Role Navigator</h2>
    <app-test-list [roleMandates]="userInfo.roleMandates"> </app-test-list>
  </div>

  <app-mfa-required-modal></app-mfa-required-modal>
</div>
