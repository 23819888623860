import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// models
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';
import { SearchDecisionTableAnswer } from '@app/core/models/search-decision-table-answer.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() search: { test: RoleMandate | ConnectionIndicator; type: 'PCI' | 'PRN' };
  searchForm = new FormGroup({
    phrase: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });
  searchResults: SearchDecisionTableAnswer[] = [];

  constructor(private router: Router) {}

  onSearch() {
    if (this.searchForm.valid) {
      return this.router.navigate([
        `/user/role-mandate/${this.search.test.id}/search/${this.searchForm.get('phrase').value}`,
      ]);
    }
  }
}
