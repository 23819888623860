import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SuperscaleListComponent } from './superscale-list/superscale-list.component';
import { SuperscaleDetailComponent } from './superscale-detail/superscale-detail.component';

const clientsRoutes: Routes = [
  { path: '', component: SuperscaleListComponent },
  { path: ':id/detail', component: SuperscaleDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(clientsRoutes)],
  exports: [RouterModule],
})
export class SuperscaleRoutingModule {}
