/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class AreaService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Add an area to an area index
     * Add an area to an area index
     * @param id Id of the area index
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postAreaIndexArea(
        id: number,
        requestBody?: {
            /**
             * Num of the area
             */
            num: number;
            /**
             * He of the area
             */
            he?: string;
            /**
             * She of the area
             */
            she?: string;
            /**
             * They of the area
             */
            they?: string;
        },
    ): Observable<{
        /**
         * Id of the area
         */
        id?: number;
        /**
         * Id of the area index
         */
        areaIndexId?: number;
        /**
         * Num of the area
         */
        num?: number;
        /**
         * He of the index
         */
        he?: string;
        /**
         * She of the index
         */
        she?: string;
        /**
         * They of the index
         */
        they?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/area-index/{id}/area',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove an area from the area index
     * Remove an area from the area index
     * @param areaIndexId Id of the area index
     * @param areaId Id of the area
     * @returns any Area removed successfully from Area index
     * @throws ApiError
     */
    public deleteAreaIndexArea(
        areaIndexId: number,
        areaId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/area-index/{areaIndexId}/area/{areaId}',
            path: {
                'areaIndexId': areaIndexId,
                'areaId': areaId,
            },
            errors: {
                404: `Area Index and/or area not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update an area index
     * Update an area index
     * @param areaIndexId
     * @param areaId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchAreaIndexArea(
        areaIndexId: number,
        areaId: number,
        requestBody?: {
            /**
             * Num of the area
             */
            num: number;
            /**
             * He of the area
             */
            he?: string;
            /**
             * She of the area
             */
            she?: string;
            /**
             * They of the area
             */
            they?: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/area-index/{areaIndexId}/area/{areaId}',
            path: {
                'areaIndexId': areaIndexId,
                'areaId': areaId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Area Index and/or area not found`,
                500: `Internal error`,
            },
        });
    }

}
