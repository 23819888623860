import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReportModelComponent } from './report-model/report-model.component';
import { ReportModelDetailComponent } from './report-model-detail/report-model-detail.component';

const reportModelRoutes: Routes = [
  { path: '', component: ReportModelComponent },
  { path: ':id/details', component: ReportModelDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(reportModelRoutes)],
  exports: [RouterModule],
})
export class ReportModelRoutingModule {}
