import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// services
import { AreaIndexService } from '@app/core/services/area-index.service';
import { ChartService } from '@app/core/services/chart.service';

// models
import { PsyChart } from '@app/core/models/psy-chart.model';
import { AreaIndex } from '@app/core/models/area-index.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';
import { ChartBaseEnum } from '@app/admin/pages/chart/create-new-chart-dialog/chart-base.enum';

@Component({
  selector: 'app-edit-chart-dialog',
  templateUrl: './edit-chart-dialog.component.html',
  styleUrls: ['./edit-chart-dialog.component.scss'],
})
export class EditChartDialogComponent implements OnInit {
  form = this.fb.group({
    name: ['', [Validators.required]],
    header: ['', [Validators.required]],
    base: ['', [Validators.required]],
    plot: ['', [Validators.required]],
    areaIndex: ['', [Validators.required]],
  });

  bases = Object.values(ChartBaseEnum);

  plots = ['DOT'];

  areas$: Observable<AreaIndex[]>;

  constructor(
    private fb: FormBuilder,
    private chartService: ChartService,
    private areaIndexService: AreaIndexService,
    private dialogRef: MatDialogRef<EditChartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { chart: PsyChart },
  ) {}

  ngOnInit(): void {
    const { chart } = this.data;
    this.form.patchValue({
      name: chart.name,
      header: chart.header,
      base: chart.base,
      plot: chart.plot,
      areaIndex: chart.areaIndexId,
    });
    this.areas$ = this.areaIndexService.getAll();
  }

  onFormSubmit() {
    const { value } = this.form;
    this.chartService
      .updateChartDetails(this.data.chart.id, {
        name: value.name,
        header: value.header,
        base: value.base,
        plot: value.plot,
        areaIndexId: value.areaIndex,
      })
      .subscribe((updatedChart) => {
        this.dialogRef.close({ event: EVENT_SUCCESS, updatedChart });
      });
  }
}
