<h1 mat-dialog-title>{{ isEdit ? 'Edit Notification' : 'Create New Notification' }}</h1>
<mat-divider></mat-divider>
<form [formGroup]="notificationForm" (ngSubmit)="onSave()">
  <mat-form-field *ngIf="isEdit">
    <mat-label>ID</mat-label>
    <input matInput type="number" formControlName="id" [readonly]="isEdit" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Webhook</mat-label>
    <input matInput type="text" formControlName="webhook" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Channel</mat-label>
    <input matInput type="text" formControlName="chanel" />
  </mat-form-field>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit"><mat-icon>save_alt</mat-icon>Save</button>
  </div>
</form>
