import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleMaintenanceModeComponent } from './toggle-maintenance-mode/toggle-maintenance-mode.component';
import { MaintenanceModeRoutingModule } from './maintenance-mode.routing.module';

@NgModule({
  declarations: [ToggleMaintenanceModeComponent],
  imports: [SharedModule, CommonModule, MaintenanceModeRoutingModule, FormsModule, ReactiveFormsModule],
  exports: [ToggleMaintenanceModeComponent],
})
export class MaintenanceModeModule {}
