import {
  MatPasswordStrengthComponent,
  MatPasswordStrengthInfoComponent,
} from '@angular-material-extensions/password-strength';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, AfterViewChecked {
  password: string = '';
  currentUserId: number;
  showWarningPanel: boolean;
  isPasswordStrengthValid;

  @ViewChild('passwordComponentWithValidation') passwordComponentWithValidation: MatPasswordStrengthComponent;
  @ViewChild('passwordInfo') passwordInfo: MatPasswordStrengthInfoComponent;
  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: { userId: number },
    private snackBarService: SnackBarService,
    private matDialogRef: MatDialogRef<ResetPasswordComponent>,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.currentUserId = this.data.userId;
  }

  getRandomElement(arr: any) {
    const rand = Math.floor(Math.random() * arr.length);
    return arr[rand];
  }

  dynamicPassword(length) {
    const uppercase = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    const lowercase = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
    ];
    const special = [
      '~',
      '!',
      '@',
      '#',
      '$',
      '%',
      '^',
      '&',
      '*',
      '(',
      ')',
      '_',
      '+',
      '-',
      '=',
      '{',
      '}',
      '[',
      ']',
      ':',
      ';',
      '?',
      ', ',
      '.',
      '|',
    ];
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    const nonSpecial = [...uppercase, ...lowercase, ...numbers];
    let password = '';

    for (let i = 0; i < length; i++) {
      if (i !== 0 && special.includes(password[i - 1])) {
        password += this.getRandomElement(nonSpecial);
      } else password += this.getRandomElement([...nonSpecial, ...special]);
    }

    return password;
  }

  generatePassword() {
    this.password = this.dynamicPassword(10);
  }

  resetPassword() {
    this.userService.resetPasswordForUser(this.currentUserId, this.password).subscribe((response: boolean) => {
      if (response) {
        this.snackBarService.info('Password reset successfully.');
      } else {
        this.snackBarService.info('something went wrong, Please try again later.');
      }
      this.matDialogRef.close();
    });
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  onStrengthChanged($event?) {
    if ($event != 100) {
      this.showWarningPanel = true;
    }
    if ($event == 100) {
      this.showWarningPanel = false;
    }
  }

  focusInPassword() {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.showWarningPanel = false;
    } else {
      this.showWarningPanel = true;
    }
  }

  focusOutPassword() {
    this.showWarningPanel = false;
  }

  ngAfterViewChecked(): void {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.isPasswordStrengthValid = true;
    } else {
      this.isPasswordStrengthValid = false;
    }
    this.cd.detectChanges();
  }
}
