/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { BlossomChart } from '../models/BlossomChart';
import type { BlossomChartPetal } from '../models/BlossomChartPetal';
import type { BlossomChartPistil } from '../models/BlossomChartPistil';
import type { BlossomChartPistilReport } from '../models/BlossomChartPistilReport';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class BlossomChartService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get a  blossom chart petal config
     * Get a blossom chart petal config
     * @param chartId Id of the chart
     * @returns BlossomChartPetal Returns chart petal config
     * @throws ApiError
     */
    public getChartPetal(
        chartId: number,
    ): Observable<Array<BlossomChartPetal>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{chartId}/petal',
            path: {
                'chartId': chartId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a blossom chart petal
     * Create a blossom chart petal
     * @param chartId Id of the chart
     * @param requestBody Blossom Chart petal to create
     * @returns BlossomChartPetal Returns chart petal config
     * @throws ApiError
     */
    public postChartPetal(
        chartId: number,
        requestBody?: BlossomChartPetal,
    ): Observable<Array<BlossomChartPetal>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/chart/{chartId}/petal',
            path: {
                'chartId': chartId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a blossom chart petal
     * Create a blossom chart petal
     * @param chartId Id of the chart
     * @param petalId Id of the petal
     * @param requestBody Blossom Chart petal to create
     * @returns BlossomChartPetal Returns chart petal config
     * @throws ApiError
     */
    public putChartPetal(
        chartId: number,
        petalId: number,
        requestBody?: BlossomChartPetal,
    ): Observable<Array<BlossomChartPetal>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/chart/{chartId}/petal/{petalId}',
            path: {
                'chartId': chartId,
                'petalId': petalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a blossom chart petal
     * Create a blossom chart petal
     * @param chartId Id of the chart
     * @param petalId Id of the petal
     * @returns BlossomChartPetal Returns chart petal config
     * @throws ApiError
     */
    public deleteChartPetal(
        chartId: number,
        petalId: number,
    ): Observable<Array<BlossomChartPetal>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/chart/{chartId}/petal/{petalId}',
            path: {
                'chartId': chartId,
                'petalId': petalId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Get a  blossom chart pistil config
     * Get a blossom chart pistil config
     * @param chartId Id of the chart
     * @returns BlossomChartPistil Returns chart pistil config
     * @throws ApiError
     */
    public getChartPistil(
        chartId: number,
    ): Observable<Array<BlossomChartPistil>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{chartId}/pistil',
            path: {
                'chartId': chartId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Get a  blossom chart pistil config
     * Get a blossom chart pistil config
     * @param chartId Id of the chart
     * @param requestBody Blossom Chart pistils to save
     * @returns BlossomChartPistil Returns chart pistil config
     * @throws ApiError
     */
    public postChartPistil(
        chartId: number,
        requestBody?: BlossomChartPistil,
    ): Observable<Array<BlossomChartPistil>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/chart/{chartId}/pistil',
            path: {
                'chartId': chartId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Get a  blossom chart pistil config
     * Get a blossom chart pistil config
     * @param chartId Id of the chart
     * @param pistilId Id of the chart
     * @param requestBody Blossom Chart pistils to save
     * @returns BlossomChartPistil Returns chart pistil config
     * @throws ApiError
     */
    public putChartPistil(
        chartId: number,
        pistilId: number,
        requestBody?: BlossomChartPistil,
    ): Observable<Array<BlossomChartPistil>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/chart/{chartId}/pistil/{pistilId}',
            path: {
                'chartId': chartId,
                'pistilId': pistilId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Get a  blossom chart pistil config
     * Get a blossom chart pistil config
     * @param chartId Id of the chart
     * @param pistilId Id of the chart
     * @returns BlossomChartPistil Returns chart pistil config
     * @throws ApiError
     */
    public deleteChartPistil(
        chartId: number,
        pistilId: number,
    ): Observable<Array<BlossomChartPistil>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/chart/{chartId}/pistil/{pistilId}',
            path: {
                'chartId': chartId,
                'pistilId': pistilId,
            },
            errors: {
                404: `Chart not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * @param chartId
     * @returns BlossomChart
     * @throws ApiError
     */
    public getChartBlossomChart(
        chartId?: number,
    ): Observable<BlossomChart> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chart/{chartId}/blossom-chart',
            path: {
                'chartId': chartId,
            },
        });
    }

    /**
     * @param chartId
     * @param requestBody
     * @returns BlossomChart
     * @throws ApiError
     */
    public postChartBlossomChart(
        chartId?: number,
        requestBody?: BlossomChart,
    ): Observable<BlossomChart> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/chart/{chartId}/blossom-chart',
            path: {
                'chartId': chartId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param url
     * @returns binary Download pdf file
     * @throws ApiError
     */
    public getReportWebPageToPdf(
        url: string,
    ): Observable<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/web-page-to-pdf',
            query: {
                'url': url,
            },
        });
    }

    /**
     * Returns petals and nested pistils with percentile report for current user in test group
     * @param testGroupId Id of the TestGroup
     * @param userInfoId Id of the User
     * @param chartId Id of the Chart
     * @returns BlossomChartPistilReport A list with BlossomChartPistilReport
     * @throws ApiError
     */
    public getReportBlossomChartTestGroupUserChart(
        testGroupId: number,
        userInfoId: number,
        chartId: number,
    ): Observable<Array<BlossomChartPistilReport>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/blossom-chart/test-group/{testGroupId}/user/{userInfoId}/chart/{chartId}',
            path: {
                'testGroupId': testGroupId,
                'userInfoId': userInfoId,
                'chartId': chartId,
            },
            errors: {
                404: `TestGroupChartComparison not found`,
                500: `Internal error`,
            },
        });
    }

}
