import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TestGroupService } from '@app/core/services/test-group.service';
import { Subscale } from '@app/core/models/subscale.model';

@Component({
  selector: 'app-test-group-scale-list',
  templateUrl: './test-group-scale-list.component.html',
  styleUrls: ['./test-group-scale-list.component.scss'],
})
export class TestGroupScaleListComponent implements OnInit {
  testGroupId: number;
  psyTestId: number;
  psyTestTitle: string;
  subscales: Subscale[];

  constructor(private router: Router, private route: ActivatedRoute, private testGroupSerive: TestGroupService) {
    this.psyTestTitle = this.router.getCurrentNavigation().extras.state.test;
  }

  ngOnInit(): void {
    this.testGroupId = Number(this.route.snapshot.params.id);
    this.psyTestId = Number(this.route.snapshot.params.testId);

    this.testGroupSerive
      .getSubscalesByTestGroupIdAndTestId(this.testGroupId, this.psyTestId)
      .subscribe((subscales) => (this.subscales = subscales));
  }

  onDashboard(subscaleId: number) {
    this.router.navigate([
      `/psy-test-group/${this.testGroupId}/test/${this.psyTestId}/subscale/${subscaleId}/percentile`,
    ]);
  }
}
