/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Department } from '../models/Department';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class DepartmentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all departments by id
     * Retrieve all departments by id
     * @param id Id of the Company
     * @returns Department A list of Departments
     * @throws ApiError
     */
    public getCompanyDepartment(
        id: number,
    ): Observable<Department> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/department',
            path: {
                'id': id,
            },
            errors: {
                404: `Departments not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a department
     * Create a department
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postCompanyDepartment(
        requestBody?: {
            /**
             * Name of the department
             */
            name?: string;
            /**
             * City of the department
             */
            city?: string;
            /**
             * Zip code of the department
             */
            zipCode?: number;
            /**
             * Address of the department
             */
            address?: string;
            /**
             * State id of the department
             */
            stateId?: number;
            /**
             * Company id of the department
             */
            companyId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/{id}/department',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve one department by id and departmentId
     * Retrieve one department by id and departmentId
     * @param id Id of the department
     * @param departmentId departmentId of the department
     * @returns Department A department
     * @throws ApiError
     */
    public getDepartmentDepartment(
        id: number,
        departmentId: number,
    ): Observable<Department> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/department/{id}/department/{departmentId}',
            path: {
                'id': id,
                'departmentId': departmentId,
            },
            errors: {
                404: `Department not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update an department
     * Update an department
     * @param id
     * @param departmentId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchCompanyDepartment(
        id: number,
        departmentId: number,
        requestBody?: {
            /**
             * name of department
             */
            name?: number;
            /**
             * City of the department
             */
            city: string;
            /**
             * Zip code of the department
             */
            zipCode: number;
            /**
             * Address of the company
             */
            address: string;
            /**
             * State id of the company
             */
            stateId: number;
            /**
             * Company id of the company
             */
            companyId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/company/{id}/department/{departmentId}',
            path: {
                'id': id,
                'departmentId': departmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Department not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a department
     * Delete a department
     * @param id Id of the company
     * @param departmentId Id of the department
     * @returns any Department deleted successfully
     * @throws ApiError
     */
    public deleteCompanyDepartment(
        id: number,
        departmentId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/company/{id}/department/{departmentId}',
            path: {
                'id': id,
                'departmentId': departmentId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all departments
     * @returns Department A list of departments
     * @throws ApiError
     */
    public getDepartment(): Observable<Array<Department>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/department',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a department
     * Create a department
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postDepartment(
        requestBody?: {
            /**
             * Name of the department
             */
            name: string;
            /**
             * City of the department
             */
            city: string;
            /**
             * Zip code of the department
             */
            zipCode: number;
            /**
             * Address of the department
             */
            address: string;
            /**
             * State id of the department
             */
            stateId: number;
            /**
             * Company id of the department
             */
            companyId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/department',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a department by id
     * Retrieve a department by id
     * @param id Id of the department
     * @returns Department A department
     * @throws ApiError
     */
    public getDepartment1(
        id: number,
    ): Observable<Department> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/department/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Department not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a department
     * Delete a department
     * @param id Id of the department
     * @returns any Department deleted successfully
     * @throws ApiError
     */
    public deleteDepartment(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/department/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
