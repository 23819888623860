import { Component, Input, OnInit } from '@angular/core';

// services
import { ChartService } from '@app/core/services/chart.service';

// helpers
import { environment } from 'src/environments/environment';
import { BarSubscalesController, HexagonChartController } from 'src/generated/v3';

@Component({
  selector: 'app-hexagon-new',
  templateUrl: './hexagon.component.html',
  styleUrls: ['./hexagon.component.scss'],
})
export class HexagonComponent implements OnInit {
  @Input() chartId: number;
  @Input() userId: number;
  @Input() testGroupId: number;
  @Input() companyId?: number;
  @Input() chartSelectedUsers: any[];
  @Input() visible?: boolean = true;

  description: string;
  src: string;
  isSubcales: boolean;

  constructor(
    private barSubscalesController: BarSubscalesController,
    private hexagonChartController: HexagonChartController,
  ) {}

  ngOnInit(): void {
    this.src = `${environment.apiUrl}/v3/user/${this.userId}/testGroup/${this.testGroupId}/chart/${this.chartId}/hexagon-chart.png`;

    this.barSubscalesController
      .barSubscalesControllerBarSubscalesChartIsSubscales(this.userId, this.chartId, this.testGroupId, '')
      .subscribe((result) => {
        this.isSubcales = result;
      });
    this.hexagonChartController
      .hexagonChartControllerHexagonChartDescription(this.userId, this.testGroupId, this.chartId)
      .subscribe((result) => {
        this.description = result?.description;
      });
  }
}
