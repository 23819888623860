import { Component, Inject, ChangeDetectorRef, OnInit, ViewChild, AfterViewChecked, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MustMatch } from '@app/core/validators/must-match.validator';
import { Observable } from 'rxjs';
import { Country } from '@app/core/models/country.model';
import { TimeZone } from '@app/core/models/time-zone.model';
import { Language } from '@app/core/models/language.model';
import { Company } from '@app/core/models/company.model';
import { State } from '@app/core/models/state.model';
import { map, startWith } from 'rxjs/operators';
import { FormService } from '@app/core/services/form.service';
import { EVENT_CANCEL, EVENT_ERROR, EVENT_SUCCESS } from '@app/core/constants';
import { UserService } from '@app/core/services/user.service';
import { EMAIL_PATTERN, INTL_TELEPHONE_PATTERN } from '@app/shared/patterns/pattern-format';
import { intlTelephoneCodes } from '@app/shared/constants/intl-telephone-codes';
import { DepartmentService } from '@app/core/services/department.service';
import { CompanyService } from '@app/core/services/company.service';
import { Department } from '@app/core/models/department.model';
import { MatSelectChange } from '@angular/material/select';
import {
  MatPasswordStrengthComponent,
  MatPasswordStrengthInfoComponent,
} from '@angular-material-extensions/password-strength';

// constants
import { ROLE_ADMIN } from '@app/core/constants';

@Component({
  selector: 'app-user-create-dialog',
  templateUrl: './user-create-dialog.component.html',
  styleUrls: ['./user-create-dialog.component.scss'],
})
export class UserCreateDialogComponent implements OnInit, AfterViewChecked {
  form: FormGroup;
  companies: Company[];
  filteredCompanies: Observable<Company[]>;
  countries: Country[] = [];
  filteredCountries: Observable<Country[]>;
  states: State[];
  filteredStates: Observable<State[]>;
  timeZones: TimeZone[] = [];
  filteredTimeZones: Observable<TimeZone[]>;
  showPasswordDetails: true;
  alreadyRegisteredUsernames: string[] = [];
  departments: Department[] = [];
  isLoginUserAdmin: boolean = false;
  selectedCountry: Country;
  selectedState: State;
  languages = [
    { value: null, name: null },
    { value: 'en', name: 'English' },
    { value: 'pt-br', name: 'Portuguese' },
    { value: 'zh-cn', name: 'Chinese' },
    { value: 'da', name: 'Danish' },
    { value: 'nl', name: 'Dutch' },
    { value: 'fr', name: 'French' },
    { value: 'de', name: 'German' },
    { value: 'es', name: 'Spanish' },
  ];
  filteredLanguages: Observable<Language[]>;
  showWarningPanel: boolean;
  isPasswordStrengthValid;
  @ViewChild('passwordComponent') passwordComponentWithValidation: MatPasswordStrengthComponent;
  @ViewChild('passwordInfo') passwordInfo: MatPasswordStrengthInfoComponent;
  @ViewChild('password') password!: ElementRef;

  constructor(
    private formService: FormService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UserCreateDialogComponent>,
    private userService: UserService,
    private departmentService: DepartmentService,
    private cd: ChangeDetectorRef,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: { companyId?: number; testGroupId?: number },
  ) {
    const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
    if (authorities.includes(ROLE_ADMIN)) {
      this.isLoginUserAdmin = true;
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        firstName: ['', [Validators.required, Validators.minLength(2)]],
        lastName: ['', [Validators.required, Validators.minLength(2)]],
        email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])],
        gender: ['', Validators.required],
        country: [''],
        state: [''],
        phone: ['', Validators.compose([Validators.required, Validators.pattern(INTL_TELEPHONE_PATTERN)])],
        username: ['', [Validators.required, Validators.minLength(2)]],
        language: [''],
        timeZone: [''],
        company: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(8), this._validateInputFromPassword()]],
        passwordConfirmation: ['', Validators.required],
        activated: [true, [Validators.required]],
        role: [''],
        privateScores: [false, [Validators.required]],
      },
      {
        validator: MustMatch('password', 'passwordConfirmation'),
      },
    );
    if (!this.isLoginUserAdmin) {
      if (this.form) {
        this.form.get('activated').setValue(true);
        this.form.get('privateScores').setValue(false);
        this.form.get('company').setValue(this.userService.getUserData().companyId);
      }
    }
    this.formService.getCompanies().subscribe((response) => {
      this.companies = response;
      if (this.data?.hasOwnProperty('companyId')) {
        this.form.get('company').setValue(this.data.companyId);
      }

      this.filteredCompanies = this.form.controls['company'].valueChanges.pipe(
        startWith(''),
        map((company) => (company ? this._filterCompany(company) : this.companies.slice())),
      );
    });

    this.formService.getCountries().subscribe((result) => {
      this.countries = [null, ...result];
      this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
        startWith(''),
        map((country) => (country ? this._filterCountry(country) : this.countries.slice())),
      );

      if (this.data && this.data.companyId) {
        this.getCountryAndState(this.data.companyId);
      } else if (!this.isLoginUserAdmin && this.userService.getUserData().companyId) {
        this.getCountryAndState(this.userService.getUserData().companyId);
      }
    });

    this.filteredLanguages = this.form.controls['language'].valueChanges.pipe(
      startWith(''),
      map((language) => (language ? this._filterLanguage(language) : this.languages.slice())),
    );

    this.formService.getTimeZones().subscribe((result) => {
      this.timeZones = [null, ...result];
      this.filteredTimeZones = this.form.controls['timeZone'].valueChanges.pipe(
        startWith(''),
        map((timeZone) => (timeZone ? this._filterTimeZone(timeZone) : this.timeZones.slice())),
      );
    });
  }

  getCountryAndState(companyId) {
    this.companyService.getById(companyId).subscribe((response) => {
      this.selectedCountry = this.countries.filter((c) => c && c.id === response.countryId)[0];
      this.form.get('country').setValue(this.selectedCountry);

      this.formService.getStatesByCountry(response.countryId).subscribe((states: State[]) => {
        this.states = [null, ...states];
        this.selectedState = this.states.filter((s) => s && s.id === response.stateId)[0];
        this.form.get('state').setValue(this.selectedState);

        this.filteredStates = this.form.controls['state'].valueChanges.pipe(
          startWith(''),
          map((state) => (state ? this._filterState(state) : this.states.slice())),
        );
      });
    });
  }

  onFocusOutUsername() {
    if (this.form.get('username').value && this.form.get('username').value.length > 0) {
      this.userService.checkExistedUsername(this.form.get('username').value).subscribe((result) => {
        if (result) {
          this.form.get('username').setErrors({ isUsernameExist: true });
        }
      });
    }
  }

  onFocusOutEmail() {
    if (this.form.get('email').value && this.form.get('email').value.length > 0) {
      this.userService.checkExistedEmail(this.form.get('email').value).subscribe((result) => {
        if (result) {
          this.form.get('email').setErrors({ isEmailExist: true });
        }
      });
    }
  }

  onSelectCountry() {
    if (this.form.value.country) {
      this.form.get('state').setValue('');
      const intlCode = this.form.get('country').value.abbrev;
      const countryId = Number(this.form.value.country.id);
      this.formService.getStatesByCountry(countryId).subscribe((states: State[]) => {
        this.states = [null, ...states];
        this.filteredStates = this.form.controls['state'].valueChanges.pipe(
          startWith(''),
          map((state) => (state ? this._filterState(state) : this.states.slice())),
        );
      });
      this.intlTelphoneCode(intlCode);
    } else {
      this.form.get('phone').setValue('');
    }
  }

  intlTelphoneCode(intlCode: string) {
    const selectedIntlCode = intlTelephoneCodes.filter((e: any) => e.code === intlCode);
    if (selectedIntlCode.length) {
      this.form.get('phone').setValue(`+${selectedIntlCode[0].callingCode}`);
    }
  }

  onSelectClient(companyId: number) {
    this.getCompanyLocation(companyId);
  }

  getCompanyLocation(companyId: number) {
    this.companyService.getById(companyId).subscribe((response) => {
      this.formService.getCountries().subscribe((result: Country[]) => {
        this.countries = [null, ...result];
        this.selectedCountry = this.countries.filter((c) => c && c.id === response.countryId)[0];
        this.form.get('country').setValue(this.selectedCountry);

        this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
          startWith(''),
          map((country) => (country ? this._filterCountry(country) : this.countries.slice())),
        );
      });

      this.formService.getStatesByCountry(response.countryId).subscribe((states) => {
        this.states = [null, ...states];
        this.selectedState = this.states.filter((s) => s && s.id === response.stateId)[0];
        this.form.get('state').setValue(this.selectedState);

        this.filteredStates = this.form.controls['stateId'].valueChanges.pipe(
          startWith(''),
          map((state) => (state ? this._filterState(state) : this.states.slice())),
        );
      });
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.form.value['country'] = this.form.get('country').value?.id;
    if (this.form.value.phone) {
      this.form.value.phone = this.form.value.phone.replace(/\s/g, '');
    }

    const newUser = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      gender: this.form.value.gender,
      country: this.form.value.country && this.form.value.country.id ? this.form.value.country.id : null,
      state: this.form.value.state && this.form.value.state.id ? this.form.value.state.id : null,
      phone: this.form.value.phone,
      username: this.form.value.username,
      language: this.form.value.language.value ? this.form.value.language.value : null,
      timeZone: this.form.value.timeZone && this.form.value.timeZone.id ? this.form.value.timeZone.id : null,
      password: this.form.value.password,
      company: this.form.value.company.id ? this.form.value.company.id : this.form.value.company,
      department: null,
      activated: this.form.value.activated ? this.form.value.activated : true,
      privateScores: this.form.value.privateScores ? this.form.value.privateScores : false,
      testGroupId: this.data && this.data.testGroupId ? this.data.testGroupId : null,
    };

    this.userService.create(newUser).subscribe((res: { message: string; userInfoId: string }) => {
      if (res) {
        this.dialogRef.close({ event: EVENT_SUCCESS, data: res });
      } else {
        this.dialogRef.close({ event: EVENT_ERROR });
      }
    });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onCompanyChange(event: MatSelectChange) {
    if (event.value) {
      this.departmentService.getAllByCompanyId(event.value).subscribe(
        (response: Department[]) => {
          this.departments = response;
        },
        () => {
          this.departments.length = 0;
        },
      );
    }
  }

  displayCompany(company: Company): string {
    return company ? company.name : '';
  }

  displayCountry(country: Country): string {
    return country ? country.name : '';
  }

  displayState(state: State): string {
    return state ? state.name : '';
  }

  displayLanguage(language: Language): string {
    return language ? language.name : '';
  }

  displayTimeZone(timeZone: TimeZone): string {
    return timeZone ? timeZone.name : '';
  }

  private _filterCompany(value: string): Company[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.companies;
    }
    return this.companies.filter((company: Company) => {
      if (company) {
        return company.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterCountry(value: string): Country[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.countries;
    }
    return this.countries.filter((country: Country) => {
      if (country) {
        return country.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterState(value: string): State[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.states;
    }
    return this.states.filter((state: State) => {
      if (state) {
        return state.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterLanguage(value: string): Language[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.languages;
    }
    return this.languages.filter((language: { name: string; value: string }) => {
      if (language) {
        return language.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _filterTimeZone(value: string): TimeZone[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.timeZones;
    }
    return this.timeZones.filter((timeZone: TimeZone) => {
      if (timeZone) {
        return timeZone.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      }
    });
  }

  private _validateInputFromPassword() {
    return (control: FormControl) => {
      const inputValue = control.value;
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:"<>?`~\-=[\];',./])(?=.{8,}).*$/;

      if (!pattern.test(inputValue)) {
        return { notValid: true };
      }

      return null;
    };
  }

  onStrengthChanged($event?) {
    if (document.activeElement === this.password.nativeElement) {
      if ($event != 100) {
        this.showWarningPanel = true;
      }
      if ($event == 100) {
        this.showWarningPanel = false;
      }
    }
  }

  focusInPassword() {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.showWarningPanel = false;
    } else {
      this.showWarningPanel = true;
    }
  }

  focusOutPassword() {
    this.showWarningPanel = false;
    this.passwordComponentWithValidation.setDisabledState(true);
  }

  ngAfterViewChecked(): void {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.isPasswordStrengthValid = true;
    } else {
      this.isPasswordStrengthValid = false;
    }
    this.cd.detectChanges();
  }
}
