import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { CollaborationType } from '@app/core/models/collaboration-type.model';
import { DecisionTableColumnType } from '@app/core/models/decision-table-column-type.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { DecisionTableColumn } from '@app/core/models/decision-table-column.model';

@Component({
  selector: 'app-decision-table',
  templateUrl: './decision-table.component.html',
  styleUrls: ['./decision-table.component.scss'],
})
export class DecisionTableComponent implements OnInit {
  @Input() decisionTable: DecisionTable;
  @Output() delete = new EventEmitter<number>();
  @Output() edit = new EventEmitter<DecisionTable>();
  @Input() roleMandateId: number;
  @Input() connectionIndicatorId: number;
  @Input() showAcl: boolean;

  displayedColumns: string[];
  collaborationTypes: CollaborationType[] = [];
  showEdit: boolean;

  @ViewChild('dtTable')
  inputMessageRef: ElementRef;

  decisionTableColumnTypes: DecisionTableColumnType[] = [
    { id: 1, name: 'Decision Area' },
    { id: 2, name: 'Users' },
    { id: 3, name: 'Text' },
    { id: 4, name: 'Number' },
  ];

  constructor(private decisionTableService: DecisionTableService) {
    this.decisionTableService.getCollaborationTypes().subscribe((collaborationTypes) => {
      this.collaborationTypes = collaborationTypes;
    });
  }

  ngOnInit(): void {
    this.decisionTable.networkType =
      this.decisionTable.networkType === 'undefined' || !this.decisionTable.networkType
        ? ''
        : this.decisionTable.networkType;
    this.displayedColumns = this.decisionTable.columns.map((column) => column.title.replace(' ', ''));
  }

  printColumnTypeName(id: number) {
    const columnType = this.decisionTableColumnTypes.filter((element) => Number(element.id) === id)[0];
    return columnType ? columnType.name : '';
  }

  getColumnDef(title: string) {
    return title.replace(' ', '');
  }

  getDataSource(columns: DecisionTableColumn[]) {
    const cols = {};
    columns.forEach((column) => {
      cols[this.getColumnDef(column.title)] = column.title;
    });
    return [cols];
  }

  onDelete(id: number) {
    this.delete.emit(id);
  }

  onEdit() {
    this.showEdit = this.showEdit ? false : true;
  }

  onEditConfirm(editedDecisionTable: DecisionTable) {
    this.decisionTable = editedDecisionTable;
    this.displayedColumns = this.decisionTable.columns.map((column) => column.title.replace(' ', ''));
    this.showEdit = !editedDecisionTable ? true : false;
    this.edit.emit(this.decisionTable);
    this.inputMessageRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  getDecisionTableCollaborationTypeNameById(id: number) {
    const collaborationType = this.collaborationTypes.find((dTCT) => dTCT.id === id);
    return collaborationType?.title;
  }

  onCreate($event: boolean) {
    if ($event) {
      this.displayedColumns = this.decisionTable.columns.map((column) => column.title.replace(' ', ''));
      this.showEdit = false;
    }
  }

  onDeleteColumn($event: boolean) {
    if ($event) {
      this.displayedColumns = this.decisionTable.columns.map((column) => column.title.replace(' ', ''));
    }
  }

  getDecisionTablePrivacyByACLId(acl: number) {
    switch (acl) {
      case 5:
        return 'Allow users to set answers as private';
      case 9:
        return 'This table is private';
    }
  }
}
