<div class="container">
  <div class="page-list-header">
    <h1>Test Groups</h1>
    <button mat-raised-button color="primary" class="btn-success new-button" (click)="createNewTestGroup()">
      <mat-icon aria-hidden="false" inline="true">add</mat-icon>
      <span class="ml-1">Create a new Test Group</span>
    </button>
  </div>

  <app-psb-list
    *ngIf="role === 'ROLE_ADMIN'"
    [items]="testGroups"
    itemsPerPage="10"
    (view)="onView($event)"
    (delete)="onDelete($event)"
    (testResult)="onViewTestResult($event)"
  >
  </app-psb-list>

  <app-psb-list
    *ngIf="role === 'ROLE_TGM'"
    [items]="testGroups"
    itemsPerPage="10"
    (view)="onView($event)"
    (testResult)="onViewTestResult($event)"
  >
  </app-psb-list>

  <app-psb-list
    *ngIf="role === 'ROLE_ORGANIZATIONAL_ADMIN'"
    [items]="testGroups"
    itemsPerPage="10"
    (view)="onView($event)"
    (testResult)="onViewTestResult($event)"
  >
  </app-psb-list>
</div>
