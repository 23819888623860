import { Component, EventEmitter, Input, Output } from '@angular/core';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';
import { DecisionTableRelation } from '@app/core/models/decision-table-relation.model';

@Component({
  selector: 'app-new-decision-table-connection',
  templateUrl: './new-decision-table-connection.component.html',
  styleUrls: ['./new-decision-table-connection.component.scss'],
})
export class NewDecisionTableConnectionComponent {
  @Input() decisionTables: DecisionTable[];
  @Output() save = new EventEmitter<DecisionTableRelation>();

  constructor(private decisionTableService: DecisionTableService) {}

  onSave(decisionTableRelation: DecisionTableRelation) {
    this.decisionTableService.saveRelation(decisionTableRelation).subscribe((result) => {
      this.save.emit(decisionTableRelation);
    });
  }
}
