import { Component, OnInit, Input } from '@angular/core';
import { PsbItem } from '@app/core/models/psb-item.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

// components
import { PsyOptionsDialogComponent } from '@app/shared/components/psy-options-dialog/psy-options-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { ChartService } from '@app/core/services/chart.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { PsbOptionItem } from '@app/core/models/psb-option-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-client-chart-list',
  templateUrl: './client-chart-list.component.html',
  styleUrls: ['./client-chart-list.component.scss'],
})
export class ClientChartListComponent implements OnInit {
  chartList: PsbItem[] = [];
  chartListAssignedToCompany: PsbItem[] = [];
  @Input() companyId: number;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private chartService: ChartService,
    private companyService: CompanyService,
  ) {}

  ngOnInit(): void {
    this.loadAllChart();
  }

  loadAllChart() {
    forkJoin([this.companyService.findAllChartAssignedToCompany(this.companyId), this.chartService.getAll()]).subscribe(
      ([assignedCharts, charts]) => {
        this.chartList = [];
        this.chartListAssignedToCompany = [];

        assignedCharts.forEach((assignedChart) => {
          const item: PsbItem = {
            id: assignedChart.id,
            title: assignedChart.name,
            chartId: assignedChart.chartId,
            description: assignedChart.header,
          };

          this.chartListAssignedToCompany.push(item);
        });

        charts.forEach((chart) => {
          const item: PsbOptionItem = {
            id: chart.id,
            itemName: chart.name,
            description: chart.header,
          };

          let isChartAvailable = assignedCharts.some((e) => e.chartId == item.id);
          if (!isChartAvailable) {
            this.chartList.push(item);
          }
        });
      },
    );
  }

  createNewChart() {
    const addTestModal = this.dialog.open(PsyOptionsDialogComponent, {
      data: { items: this.chartList, title: 'Add Chart to Company', formFieldName: 'Chart' },
    });

    addTestModal.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.companyService.assignChartToCompanyById(this.companyId, result.data.item.id).subscribe(() => {
          this.loadAllChart();
          this.showCompleted('Chart is assigned to the company.');
        });
      }
    });
  }

  onView(companyChartId: number) {
    const chartId = this.chartListAssignedToCompany.filter((assignedChart) => assignedChart.id === companyChartId)[0]
      .chartId;
    return this.router.navigate([`/chart/${chartId}`]);
  }

  onDelete(companyChartId: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.companyService.removeAssignedChartFromCompany(companyChartId).subscribe((res) => {
          if (res) {
            this.loadAllChart();
            this.snackBar.info('Chart is removed from the company.');
          }
        });
      }
    });
  }

  showCompleted(message: string) {
    this.snackBar.info(message);
  }
}
