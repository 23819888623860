import { Node } from './node';

export class Link implements d3.SimulationLinkDatum<Node> {
  index?: number;

  source: Node;
  target: Node;
  value: string;
  distance: number;

  constructor(source, target, value) {
    this.source = source;
    this.target = target;
    this.value = value;
    this.distance = 50;
  }
}
