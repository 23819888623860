<mat-card>
  <h1 *ngIf="!created">Create a New User</h1>
  <div id="success" *ngIf="created" class="text-center">
    <h2>Congratulations! User created successfully!</h2>
    <h3>You can access the system with your credentials <a routerLink="/login">here</a>.</h3>
  </div>
  <form [formGroup]="form" (submit)="onSubmit()" *ngIf="!created">
    <mat-form-field>
      <input matInput type="text" formControlName="firstName" placeholder="First Name" />
      <mat-error *ngIf="form.get('firstName').invalid">Please enter the first name.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="lastName" placeholder="Last Name" />
      <mat-error *ngIf="form.get('lastName').invalid">Please enter the last name.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="email" placeholder="E-mail" />
      <mat-error *ngIf="form.get('email').invalid">Please enter an e-mail.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Gender</mat-label>
      <mat-select formControlName="gender">
        <mat-option value="FEMALE">Female</mat-option>
        <mat-option value="MALE">Male</mat-option>
        <mat-option value="OTHER">Other</mat-option>
        <mat-option value="PREFER_NOT_TO_SAY">Prefer not to say</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('gender').invalid">Please select an option.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Country</mat-label>
      <mat-select #c formControlName="country" (selectionChange)="onSelectCountry(c.value)">
        <mat-option *ngFor="let country of countries | async" value="{{ country.id }}">{{ country.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('country').invalid">Please select an option.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>State</mat-label>
      <mat-select formControlName="state">
        <mat-option *ngFor="let state of states" value="{{ state.id }}">{{ state.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('state').invalid">Please select an option.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="tel" formControlName="phone" placeholder="Phone" />
      <mat-error *ngIf="form.get('phone').invalid">Please enter a phone.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="username" placeholder="Username" />
      <mat-error *ngIf="form.get('username').invalid">Please enter an username.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
      <input matInput #password [type]="toggle.type" required formControlName="password" placeholder="Password" />
      <mat-hint align="end" aria-live="polite"> {{ password.value.length }} / 25 </mat-hint>
      <mat-error *ngIf="form.get('password').invalid">Please enter a password.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        (focus)="focusInPassword()"
        (focusout)="focusOutPassword()"
        type="password"
        #passwordConfirmation
        formControlName="passwordConfirmation"
        placeholder="Password Confirmation"
        [ngClass]="{ 'is-invalid': submitted && formControls.passwordConfirmation.errors }"
      />
      <mat-error *ngIf="form.get('passwordConfirmation').invalid">Please enter the same password.</mat-error>
      <div *ngIf="submitted && formControls.passwordConfirmation.errors" class="invalid-feedback">
        <div *ngIf="formControls.passwordConfirmation.errors.required">Confirm Password is required</div>
        <div *ngIf="formControls.passwordConfirmation.errors.mustMatch">Passwords must match</div>
      </div>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Language</mat-label>
      <mat-select formControlName="language">
        <mat-option value="en">English</mat-option>
        <mat-option value="pt-br">Portuguese</mat-option>
        <mat-option value="zh-cn">Chinese</mat-option>
        <mat-option value="da">Danish</mat-option>
        <mat-option value="nl">Dutch</mat-option>
        <mat-option value="fr">French</mat-option>
        <mat-option value="de">German</mat-option>
        <mat-option value="es">Spanish</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('language').invalid">Please select an option.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Time Zone</mat-label>
      <mat-select formControlName="timeZone">
        <mat-option *ngFor="let timeZone of timeZones | async" value="{{ timeZone.id }}">{{
          timeZone.name
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('timeZone').invalid">Please select an option.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Company</mat-label>
      <mat-select formControlName="company">
        <mat-option *ngFor="let company of companies | async" value="{{ company.id }}">{{ company.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('company').invalid">Please select an option.</mat-error>
    </mat-form-field>
    <button mat-raised-button type="submit" class="btn-primary">Create User</button>
    <div class="strength-panel" [ngClass]="{ 'hide-password-info': !showWarningPanel }">
      <mat-password-strength
        (onStrengthChanged)="onStrengthChanged($event)"
        #passwordComponent
        [password]="password.value"
      >
      </mat-password-strength>
      <mat-password-strength-info #passwordInfo [passwordComponent]="passwordComponent"> </mat-password-strength-info>
    </div>
  </form>
</mat-card>
