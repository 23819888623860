import { Component, Input, OnInit } from '@angular/core';

// models
import { OptimalScoreService } from '@app/core/services/optimal-score.service';

@Component({
  selector: 'app-ranges-table',
  templateUrl: './ranges-table.component.html',
  styleUrls: ['./ranges-table.component.scss'],
})
export class RangesTableComponent implements OnInit {
  @Input() ranges: any[];
  @Input() spreads: any[];
  @Input() opptimalScoreCurveId: number;
  dataSource: any[];

  displayedColumns: string[];
  columns: any[];
  editColumn: string;
  showDeleteColumn = false;

  constructor(private optimalScoreService: OptimalScoreService) {}

  ngOnInit(): void {
    this.loadData();
  }

  update() {
    this.loadData();
  }

  loadData() {
    this.columns = [
      {
        columnDef: 'percentile',
        header: 'Percentile',
        cell: (element: any) => `${element.percentile}`,
      },
    ];

    this.columns = [
      ...this.columns,
      ...this.spreads
        .filter((item) => item !== undefined)
        .map((spread, index) => {
          return {
            columnDef: `curve${spread.curveNumber}`,
            header: `Curve ${spread.curveNumber}`,
            curveNumber: spread.curveNumber,
            cell: (element: any) => element[`curve${spread.curveNumber}`],
          };
        }),
    ];

    this.displayedColumns = this.columns.filter((item) => item !== undefined).map((c) => c.columnDef);
    const rangeMap = new Map(Object.entries(this.ranges));

    const dataSource = new Map();

    rangeMap.forEach((values, key) => {
      values.forEach((value) => {
        const obj = dataSource.has(value.optimalPercentile) ? dataSource.get(value.optimalPercentile) : {};

        obj['percentile'] = value.optimalPercentile;
        obj[`curve${value.curveNumber}`] = value.score;

        dataSource.set(value.optimalPercentile, obj);
      });
    });

    let rangeLowData = { percentile: 'Range Low' };
    let rangeHighData = { percentile: 'Range High' };

    for (let i = 0; i < this.spreads.length; i++) {
      if (i + 1 === this.spreads[i].curveNumber) {
        rangeLowData[`curve${this.spreads[i].curveNumber}`] = this.spreads[i].rangeLow;
        rangeHighData[`curve${this.spreads[i].curveNumber}`] = this.spreads[i].rangeHigh;
      }
    }

    let data = [rangeLowData, rangeHighData];

    dataSource.forEach((values, key) => {
      data = [...data, values];
    });

    this.dataSource = data;
  }

  isSticky(columnDef: string) {
    return columnDef === 'percentile';
  }

  edit(column) {
    this.editColumn = column;
  }

  onChangeEvent(column, row, event: any) {
    row[column.columnDef] = event.target.value;

    this.ranges[column.curveNumber].filter((element) => element.optimalPercentile === row.percentile)[0].score = Number(
      event.target.value,
    );
  }

  onRemoveColumn(column) {
    this.optimalScoreService.deleteCurveRanges(this.opptimalScoreCurveId, column.curveNumber).subscribe((result) => {
      this.spreads = this.spreads.filter((spread) => spread.curveNumber !== column.curveNumber);
      this.update();
    });
  }
}
