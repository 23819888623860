import { Component, OnInit, Input, Injectable, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

// services
import { CompanyService } from '@app/core/services/company.service';
import { DecisionTableService } from '@app/core/services/decision-table.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { UserService } from '@app/core/services/user.service';
import { PaginationService } from '@app/core/services/pagination.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';
import { User } from '@app/core/models/user.model';
import { DecisionTableColumnType } from '@app/core/models/decision-table-column-type.model';
import { UserConnection } from '@app/core/models/user-connection.model';
import { DecisionArea } from '@app/core/models/decision-area.model';
import { DecisionTableAnswer } from '@app/core/models/decision-table-answer.model';

@Component({
  selector: 'app-answer-decision-table',
  templateUrl: './answer-decision-table.component.html',
  styleUrls: ['./answer-decision-table.component.scss'],
})
@Injectable() // TODO: make sure what it is
export class AnswerDecisionTableComponent implements OnInit {
  @Input() decisionTable: DecisionTable;
  @Input() connectionIndicatorId: number;
  @Input() roleMandateId: number;
  @Input() companyId: number;
  @Input() userInfoId: number;
  @Input() showResults: boolean;
  @Input() answering: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  users: any[] = [];
  selectedUsers: any[] = [];
  decisionTableAnswers: any[] = [];
  originalDecisionTableAnswers: DecisionTableAnswer[];
  usersName: any[] = [];
  filteredUsers: Array<Observable<User[]>> = [];
  allDecisionAreaName: any[] = [];
  allDecisionArea: DecisionArea[] = [];
  originalUsers: User[] = [];
  lowValue: number;
  highValue: number;
  actualPaginator: PageEvent;

  decisionTableColumnTypes: DecisionTableColumnType[] = [
    { id: 1, name: 'Decision Area' },
    { id: 2, name: 'Users' },
    { id: 3, name: 'Text' },
    { id: 4, name: 'Number' },
  ];
  decisionTableForm: FormGroup;
  showEditDTAnswer: { decisionTableAnswerId: number; index: number }[] = [];
  answerPrivate = false;
  decisionTableAnswersToAccept: any[] = [];
  private paginationListenerSubs: Subscription;
  filteredUsersDecisionAreas: Observable<DecisionArea[]>;

  constructor(
    private companyService: CompanyService,
    private decisionTableService: DecisionTableService,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private pService: PaginationService,
    public snackBar: SnackBarService,
  ) {}

  async ngOnInit() {
    this.paginationListenerSubs = this.pService.getPaginationListener().subscribe((value) => {
      if (value) {
        let atLeatOneFieldFilled: boolean;
        Object.keys(this.decisionTableForm.controls).forEach((key) => {
          if (this.decisionTableForm.controls[key].value && this.decisionTableForm.controls[key].value.length > 0) {
            atLeatOneFieldFilled = true;
          }
        });
        if (this.decisionTableForm.valid && atLeatOneFieldFilled) {
          this.saveAnswer();
        }
      }
    });

    // number of items on tables
    this.lowValue = 0;
    this.highValue = 10;

    const group: any = {};
    this.decisionTable.columns.forEach((column) => {
      if (Number(column.type) === 2) {
        this.selectedUsers[this.getFormName(column.title)] = [];
        this.filteredUsers[this.getFormName(column.title)] = new Observable();
      }
      group[this.getFormName(column.title)] = new FormControl('', column.required ? [Validators.required] : []);
    });

    this.decisionTableForm = new FormGroup(group);

    const typeColumns = this.decisionTable.columns.map((column) => column.type);
    if (typeColumns.indexOf('2') > -1 && this.companyId) {
      this.companyService.getUsersById(this.companyId).subscribe((result) => {
        this.originalUsers = [];
        result.forEach((val) => this.originalUsers.push(Object.assign({}, val)));

        this.decisionTable.columns.forEach((column) => {
          if (Number(column.type) === 2) {
            const formName = this.getFormName(column.title);
            this.users[formName] = this.originalUsers.filter(
              (user) => user.firstName !== null && user.firstName !== '',
            );

            this.filteredUsers[formName] = this.decisionTableForm.get(formName).valueChanges.pipe(
              startWith(''),
              map((user) => (user ? this._filter(user) : this.filterOriginalUsers())),
            );
          }
        });

        this.usersName = this.originalUsers.reduce<any>((element, obj) => {
          element[obj.id] = obj.firstName + ' ' + obj.lastName;
          return element;
        }, {});

        if (this.showResults) {
          if (this.roleMandateId && this.userInfoId) {
            this.loadDecisionTableAnswers(this.decisionTable.decisionTableAnswers);
          }
        } else {
          this.getDecisionTableAnswers();
        }
      });
    }

    this.decisionTableService.getDecisionAreasByCompanyId(this.companyId).subscribe((decisionAreas) => {
      this.allDecisionArea = decisionAreas;
      this.allDecisionAreaName = this.allDecisionArea.reduce<any>((element, obj) => {
        element[obj.id] = obj.name;
        return element;
      }, {});

      this.decisionTable.columns.forEach((column) => {
        if (Number(column.type) === 1) {
          const formName = this.getFormName(column.title);
          this.filteredUsersDecisionAreas = this.decisionTableForm.get(formName).valueChanges.pipe(
            startWith(''),
            map((decisionArea) => this._filterDecisionAreas(decisionArea)),
          );
        }
      });
    });

    this.loadAnswersToAccept();
  }

  filterOriginalUsers() {
    return this.originalUsers.filter((user) => user.id !== this.userInfoId);
  }

  accept(i: number, j, formTitle: string) {
    if (this.actualPaginator && this.actualPaginator.pageIndex > 0) {
      i = this.actualPaginator.pageIndex * this.actualPaginator.pageSize + i;
    }
    const check = this.decisionTableAnswers[i][formTitle][j].accept === 1 ? 0 : 1;
    const userConnectionId = this.decisionTableAnswers[i][formTitle][j].userConnectionId;
    if (userConnectionId) {
      const payload = {
        userConnectionId,
        checked: check,
      };
      this.decisionTableService.signOffAnswerByUserConnectionId(payload).subscribe(() => {
        this.updateAnswer(i, j, formTitle, check);
        this.snackBar.info('Decision has been accepted successfully.');
      });
    }
  }

  reject(i: number, j, formTitle: string) {
    if (this.actualPaginator && this.actualPaginator.pageIndex > 0) {
      i = this.actualPaginator.pageIndex * this.actualPaginator.pageSize + i;
    }
    const check = this.decisionTableAnswers[i][formTitle][j].accept === -1 ? 0 : -1;
    const userConnectionId = this.decisionTableAnswers[i][formTitle][j].userConnectionId;
    if (userConnectionId) {
      const payload = {
        userConnectionId,
        checked: check,
      };
      this.decisionTableService.signOffAnswerByUserConnectionId(payload).subscribe(() => {
        this.updateAnswer(i, j, formTitle, check);
        this.snackBar.info('Decision has been rejected successfully.');
      });
    }
  }

  updateAnswer(i: number, j: number, formTitle: string, check: number) {
    const answers = this.decisionTableAnswers;
    this.decisionTableAnswers[i][formTitle][j].accept = check;
  }

  getFormName(columnTitle: string) {
    return this.decisionTableService.getFormName(columnTitle);
  }

  saveAnswer() {
    const decisionArea: DecisionArea = {
      id: null,
      name: null,
    };

    let decisionTableAnswer: DecisionTableAnswer = {
      decisionTable: this.decisionTable,
      decisionArea: null,
      text: null,
      texts: [],
      userConnections: [],
    };

    const col = {};

    this.decisionTable.columns.forEach((column) => {
      if (+column.type === 1) {
        decisionArea.id = +this.decisionTableForm.get(this.getFormName(column.title)).value.id;
        col[this.getFormName(column.title)] =
          this.allDecisionAreaName[+this.decisionTableForm.get(this.getFormName(column.title)).value];
      } else if (+column.type === 2) {
        if (this.selectedUsers[this.getFormName(column.title)].length === 0) {
          const userConnection: UserConnection = {
            fromUserInfoId: this.userInfoId,
            toUserInfoId: null,
            questionId: null,
            decisionTableAnswerId: null,
            columnName: this.getFormName(column.title),
          };
          decisionTableAnswer.userConnections.push(userConnection);
        } else {
          for (const user of this.selectedUsers[this.getFormName(column.title)]) {
            const userConnection: UserConnection = {
              fromUserInfoId: this.userInfoId,
              toUserInfoId: null,
              questionId: null,
              decisionTableAnswerId: null,
              columnName: this.getFormName(column.title),
            };

            userConnection.toUserInfoId = +user.id;
            decisionTableAnswer.userConnections.push(userConnection);
            col[this.getFormName(column.title)] = this.usersName[+user.id];
          }
        }
      } else if (+column.type === 3) {
        if (!decisionTableAnswer.text) {
          decisionTableAnswer.text = this.decisionTableForm.get(this.getFormName(column.title)).value;
        } else {
          decisionTableAnswer.texts.push({
            text: this.decisionTableForm.get(this.getFormName(column.title)).value,
            columnName: this.getFormName(column.title),
            columnId: column.id,
          });
        }
        col[this.getFormName(column.title)] = this.decisionTableForm.get(this.getFormName(column.title)).value;
      }
    });

    decisionTableAnswer = {
      decisionTable: this.decisionTable,
      decisionArea: { ...decisionArea },
      text: decisionTableAnswer.text,
      userConnections: decisionTableAnswer.userConnections,
      texts: decisionTableAnswer.texts,
    };

    if (this.connectionIndicatorId) {
      decisionTableAnswer.private = this.answerPrivate;
      if (this.decisionTable.acl === 9) {
        decisionTableAnswer.private = true;
      }

      decisionTableAnswer.userConnections.map((userConnection) => {
        userConnection.private = decisionTableAnswer.private;
      });

      this.decisionTableService
        .saveDecisionTableAnswer(this.connectionIndicatorId, decisionTableAnswer)
        .subscribe((result) => {
          this.formSaved();
          this.answerPrivate = false;
          this.pService.setPaginationListener(false);
        });
    }

    if (this.roleMandateId) {
      this.decisionTableService
        .saveRoleMandateDecisionTableAnswer(this.roleMandateId, decisionTableAnswer)
        .subscribe((result) => {
          this.formSaved();
          this.answerPrivate = false;
          this.pService.setPaginationListener(false);
        });
    }
  }

  formSaved() {
    this.decisionTableForm.reset();
    this.decisionTableAnswers = [];
    this.getDecisionTableAnswers();
    this.clearAllUserList();
  }

  getDecisionTableAnswers() {
    if (this.userInfoId)
      this.decisionTableService
        .getAnswersByUserId(
          this.decisionTable.id,
          +this.userInfoId,
          this.userService.getUserData().id,
          this.roleMandateId ? this.roleMandateId : null,
          this.connectionIndicatorId ? this.connectionIndicatorId : null,
        )
        .subscribe((result) => this.loadDecisionTableAnswers(result));
  }

  loadDecisionTableAnswers(data) {
    this.decisionTableAnswers = this.decisionTableService.loadDecisionTableAnswers(
      data,
      this.decisionTable,
      this.usersName,
    );
    this.originalDecisionTableAnswers = data;
  }

  private _filterDecisionAreas(name: string): DecisionArea[] {
    const filterValue = typeof name === 'string' ? name.toLowerCase() : '';

    return this.allDecisionArea.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  private _filter(value: string): User[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    if (value.length > 2) {
      return this.originalUsers.filter((element) => {
        const user = Object.assign({}, element);
        user.department = user.department ? user.department.toLowerCase() : '';
        user.roleTitle = user.roleTitle ? user.roleTitle.toLowerCase() : '';
        user.firstName = user.firstName ? user.firstName.toLowerCase() : '';
        user.lastName = user.lastName ? user.lastName.toLowerCase() : '';

        const found =
          (user.firstName + ' ' + user.lastName + ' ' + user.department + ' ' + user.roleTitle).indexOf(filterValue) >
            -1 && user.id !== this.userInfoId;

        return found ? element : false;
      });
    } else {
      return this.originalUsers.filter((element) => {
        const user = Object.assign({}, element);
        user.department = user.department ? user.department.toLowerCase() : '';
        user.roleTitle = user.roleTitle ? user.roleTitle.toLowerCase() : '';
        user.firstName = user.firstName ? user.firstName.toLowerCase() : '';
        user.lastName = user.lastName ? user.lastName.toLowerCase() : '';

        const found =
          user.firstName.startsWith(filterValue) ||
          user.lastName.startsWith(filterValue) ||
          user.department.startsWith(filterValue) ||
          user.roleTitle.startsWith(filterValue);
        return found ? element : false;
      });
    }
  }

  displayUser(value: User[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((user, index) => {
        if (index === 0) {
          displayValue = user.firstName + ' ' + user.lastName;
        } else {
          displayValue += ', ' + user.firstName + ' ' + user.lastName;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, user: User, formName: string) {
    if (!this.selectedUsers[formName].length) {
      this.selectedUsers[formName] = [];
    }

    user.selected = true;
    const i = this.selectedUsers[formName].findIndex(
      (value) => value.firstName === user.firstName && value.lastName === user.lastName,
    );
    if (i > -1) {
      this.selectedUsers[formName].splice(i, 1);
    } else {
      this.selectedUsers[formName].push(Object.assign({}, user));
    }
    this.decisionTableForm.get(formName).reset();

    this.decisionTableForm
      .get(formName)
      .setErrors(this.selectedUsers[formName].length > 0 ? null : { incorrect: true });

    this.resetUsersSelection(formName);
  }

  clearAllUserList() {
    this.decisionTable.columns.forEach((column) => {
      if (Number(column.type) === 2) {
        const formName = this.getFormName(column.title);
        this.selectedUsers[formName] = [];
        this.resetUsersSelection(formName);
      }
    });
  }

  resetUsersSelection(formName: string) {
    this.filteredUsers[formName] = this.decisionTableForm.get(formName).valueChanges.pipe(
      startWith(''),
      map((user) => (user ? this._filter(user) : this.filterOriginalUsers())),
    );
  }

  onClearSearch(name: string, event: Event, trigger: MatAutocompleteTrigger) {
    this.selectedUsers[name] = [];
    trigger.closePanel();
    this.decisionTableForm.get(name).reset();
    this.clearAllUserList();
  }

  deleteAnswer(decisionTableAnswer: any) {
    this.decisionTableService.deleteDecisionTableAnswer(decisionTableAnswer.id).subscribe(() => {
      this.decisionTableAnswers = [];
      this.getDecisionTableAnswers();
    });
  }

  removedSelectedUser(user: User, columnName: string) {
    const i = this.selectedUsers[columnName].map((usr) => usr.id).indexOf(user.id);
    this.selectedUsers[columnName].splice(i, 1);

    this.decisionTableForm
      .get(columnName)
      .setErrors(this.selectedUsers[columnName].length > 0 ? null : { incorrect: true });
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.actualPaginator = event;
    return event;
  }

  onEditAnswerResponse(decisionTableAnswer: DecisionTableAnswer | boolean, index: number) {
    if (typeof decisionTableAnswer === 'object') {
      this.formSaved();
      this.showEditDTAnswer = this.showEditDTAnswer.filter((v) => v.decisionTableAnswerId !== decisionTableAnswer.id);
    } else {
      this.showEditDTAnswer = this.showEditDTAnswer.filter((v) => v.index !== index);
    }
  }

  showEditAnswer(decisionTableAnswer: DecisionTableAnswer) {
    return this.showEditDTAnswer.find((v) => v.decisionTableAnswerId === decisionTableAnswer.id);
  }

  editAnswer(decisionTableAnswer: DecisionTableAnswer, index: number) {
    this.showEditDTAnswer.push({ decisionTableAnswerId: decisionTableAnswer.id, index });
  }

  togglePrivacy() {
    this.answerPrivate = !this.answerPrivate;
  }

  updateAnswerPrivacy(decisionTableAnswer: any) {
    this.decisionTableService
      .updatePrivateAnswer(decisionTableAnswer.id, !decisionTableAnswer.private)
      .subscribe(() => (decisionTableAnswer.private = !decisionTableAnswer.private));
  }

  onAnswerToAccept($event: { action: boolean; decisionTableAnswerId: number; userName: string }) {
    this.decisionTableService
      .answerRelationAccept($event.decisionTableAnswerId, $event.action, $event.userName)
      .subscribe(() => {
        this.loadAnswersToAccept();
        this.getDecisionTableAnswers();
        this.snackBarService.info('Decision Table Answered Successfully!');
      });
  }

  private loadAnswersToAccept() {
    this.decisionTableService
      .getRelationAcceptAnswersByToUserInfoIdAndDecisionTableId(
        this.userService.getUserData().id,
        this.decisionTable.id,
      )
      .subscribe((res: any) => {
        this.decisionTableAnswersToAccept = this.decisionTableService.loadDecisionTableAnswers(
          res,
          this.decisionTable,
          this.usersName,
        );
      });
  }

  displayDecisionArea(decisionArea: DecisionArea): string {
    return decisionArea ? decisionArea.name : '';
  }
}
