<div *ngIf="visible" class="row">
  <div [ngClass]="chart && chart.subscales.length > 0 ? 'column' : 'row'">
    <div [ngClass]="chart && chart.subscales.length > 0 ? '' : 'side-by-side'">
      <figure [attr.id]="'HEXAGON' + chartId" class="chart-report"></figure>
    </div>
    <div
      [ngClass]="chart && chart.subscales.length > 0 ? '' : 'side-by-side vertical-center'"
      style="margin-bottom: 2rem"
      *ngIf="description"
      [innerText]="description"
    ></div>
  </div>
  <div class="column subcales" *ngIf="userId">
    <app-bar-subscales
      *ngIf="chart && chart.subscales"
      [chartId]="chartId"
      [subcales]="chart.subscales"
    ></app-bar-subscales>
  </div>
</div>
<span hidden [attr.id]="'HEXAGON_PATH_NUMBER' + chartId">{{ pathNumber }}</span>
<div style="transform: translateX(-10000%)" id="tempBindHexagonPlot"></div>
