import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { QuestionService } from '@app/core/services/question.service';
import { SubscaleService } from '@app/core/services/subcale.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';
import { Question } from '@app/core/models/question.model';
import { Node } from '@app/core/models/node.model';
import { PsbStatsLine } from '@app/core/models/psb-stats-line.model';
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';

@Component({
  selector: 'app-connections-indicator-dashboard',
  templateUrl: './connections-indicator-dashboard.component.html',
  styleUrls: ['./connections-indicator-dashboard.component.scss'],
})
export class ConnectionsIndicatorDashboardComponent implements OnInit, OnDestroy {
  questionAnalysis: { question: Question; analysis: any[] }[] = [];
  cardsortAnalysis: { question: Question; analysis: any[] }[] = [];
  textAreaAnalysis: { question: Question; subscaleName: string; analysis: any[] }[] = [];
  decisionTableAnalysis: { decisionTable: DecisionTable; analysis: any[] }[] = [];
  expertTableAnalysis: any[];
  globalTableAnalysis: any[];
  referentTableAnalysis: any[];
  connectionIndicatorId: number;
  nodes: Node[];
  nodesLoaded = false;
  private initialSubscriptions: Subscription;
  questions: Question[];
  userConnectionsQuestions: Question[] = [];
  decisionTables: DecisionTable[] = [];
  psbStats: PsbStatsLine[];
  connectionIndicator: ConnectionIndicator;

  selected = new FormControl(0);
  tabs: string[] = ['Analysis', 'Connections Analysis', 'Questions Analysis', 'Invite E-mails'];

  constructor(
    private route: ActivatedRoute,
    private cIService: ConnectionsIndicatorService,
    private questionService: QuestionService,
    private subscaleService: SubscaleService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.connectionIndicatorId = Number(this.route.snapshot.params.id);
    this.route.params.subscribe((params) => {
      this.selected.setValue(params.id ? +params.tab : 0);
    });

    this.initialSubscriptions = forkJoin([
      this.cIService.getAllQuestionById(this.connectionIndicatorId),
      this.cIService.getAllDecisionTableById(this.connectionIndicatorId),
      this.cIService.getAllNode(this.connectionIndicatorId),
      this.cIService.getUnaForExpertAndReferentByByConnectionIndicator(this.connectionIndicatorId),
      this.cIService.getNetworkAnalysisByConnectionIndicator(this.connectionIndicatorId),
      this.cIService.getById(this.connectionIndicatorId),
    ]).subscribe((results) => {
      this.decisionTables = results[1];

      this.questions = results[0];

      this.userConnectionsQuestions = this.questions.filter((q) => q.questionType === 'USER_CONNECTION');

      this.questions.forEach((questionResult) => {
        if (questionResult.questionType === 'CARDSORT') {
          this.questionService
            .getCardsortAnalysisByQuestionId(this.connectionIndicatorId, questionResult.id)
            .subscribe({
              next: (cardsortAnalysisResult) => {
                const cardsortAnalysis = [];
                cardsortAnalysisResult.forEach((res) => {
                  const importance = res.totalAppearance + res.topThree - res.thrownOut;
                  cardsortAnalysis.push({ ...res, importance });
                });
                this.cardsortAnalysis = this.cardsortAnalysis.concat({
                  question: questionResult,
                  analysis: cardsortAnalysis,
                });
              },
            });
        }

        if (questionResult.questionType === 'TEXT_AREA') {
          this.questionService
            .getTextAreaAnalysisByQuestionId(this.connectionIndicatorId, questionResult.id)
            .subscribe({
              next: (textAreaAnalysisResult) => {
                if (questionResult['subscaleId']) {
                  this.subscaleService.get(questionResult['subscaleId']).subscribe((subscale) => {
                    this.textAreaAnalysis = this.textAreaAnalysis.concat({
                      question: questionResult,
                      subscaleName: subscale.title,
                      analysis: textAreaAnalysisResult,
                    });
                  });
                } else {
                  this.textAreaAnalysis = this.textAreaAnalysis.concat({
                    question: questionResult,
                    subscaleName: null,
                    analysis: textAreaAnalysisResult,
                  });
                }
              },
            });
        }
      });

      this.nodes = results[2];
      this.nodesLoaded = true;

      this.referentTableAnalysis = results[3].filter((element) => element.networkType === 'REFERENT');
      this.expertTableAnalysis = results[3].filter((element) => element.networkType === 'EXPERT');
      this.globalTableAnalysis = results[4];

      this.connectionIndicator = results[5];

      this.psbStats = [
        {
          header: 'Invited',
          number: this.connectionIndicator.usersInvited ? this.connectionIndicator.usersInvited : 0,
          description: 'Total of users invited',
        },
        {
          header: 'Started',
          number: this.connectionIndicator.usersInvited
            ? (this.connectionIndicator.userStarted / this.connectionIndicator.usersInvited) * 100
            : 0,
          un: '%',
          description: 'Users started but did not complete',
        },
        {
          header: 'Completed',
          number: this.connectionIndicator.usersInvited
            ? (this.connectionIndicator.userCompleted / this.connectionIndicator.usersInvited) * 100
            : 0,
          un: '%',
          description: 'Users that complete',
        },
        {
          header: 'Not registered',
          number: this.connectionIndicator.usersInvited
            ? ((this.connectionIndicator.usersInvited -
                this.connectionIndicator.userCompleted -
                this.connectionIndicator.userStarted) /
                this.connectionIndicator.usersInvited) *
              100
            : 0,
          un: '%',
          description: 'Users waiting for registration',
        },
      ];
    });
  }

  tabChange(event) {
    this.selected.setValue(event);

    this.router.navigate([this.router.url.split('tab/')[0] + '/tab/' + event]);
  }

  ngOnDestroy(): void {
    if (this.initialSubscriptions) {
      this.initialSubscriptions.unsubscribe();
    }
  }
}
