import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// services
import { ChapterService } from '@app/core/services/chapter.service';

// components
import { Chapter } from '@app/core/models/chapter.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-update-chapter-dialog',
  templateUrl: './update-chapter-dialog.component.html',
  styleUrls: ['./update-chapter-dialog.component.scss'],
})
export class UpdateChapterDialogComponent implements OnInit {
  form = this.fb.group({
    title: ['', Validators.required],
    secondTierOptimalScoresHeading: [''],
  });

  constructor(
    private dialogRef: MatDialogRef<UpdateChapterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { chapter: Chapter },
    private fb: FormBuilder,
    private chapterService: ChapterService,
  ) {}

  ngOnInit(): void {
    this.form.patchValue({
      title: this.data.chapter.title,
      secondTierOptimalScoresHeading: this.data.chapter.secondTierOptimalScoresHeading,
    });
  }

  onSubmit() {
    this.chapterService
      .update(this.data.chapter.id, {
        title: this.form.value.title,
        secondTierOptimalScoresHeading: this.form.value.secondTierOptimalScoresHeading,
      })
      .subscribe((updatedChapter) => {
        this.dialogRef.close({ event: EVENT_SUCCESS, updatedChapter });
      });
  }
}
