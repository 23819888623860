import { Component, EventEmitter, Input, Output } from '@angular/core';

// services
import { QuestionGroupService } from '@app/core/services/question-group.service';

// models
import { QuestionGroup } from '@app/core/models/question-group.model';

@Component({
  selector: 'app-question-group',
  templateUrl: './question-group.component.html',
  styleUrls: ['./question-group.component.scss'],
})
export class QuestionGroupComponent {
  @Input() questionGroup: QuestionGroup;
  @Output() delete = new EventEmitter<number>();
  showEdit: boolean;

  constructor(private questionGroupService: QuestionGroupService) {}

  onDelete(id: number) {
    this.questionGroupService.delete(id).subscribe((result) => {
      this.delete.emit(id);
    });
  }

  onEdit() {
    this.showEdit = this.showEdit ? false : true;
  }

  onEditResponse(editedQuestionGroup: QuestionGroup) {
    this.questionGroup = editedQuestionGroup;
    this.showEdit = !editedQuestionGroup ? true : false;
  }
}
