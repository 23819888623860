import { Component, Input } from '@angular/core';
import { SectionService } from '@app/core/services/section.service';

@Component({
  selector: 'app-bullet-point-section',
  templateUrl: './bullet-point-section.component.html',
  styleUrls: ['./bullet-point-section.component.scss'],
})
export class BulletPointSectionComponent {
  @Input() bulletPoint: any;

  constructor(private sectionService: SectionService) {}

  moveUpSentence(bulletPointId: number, sentenceId: number, sentenceOrder: number) {
    if (sentenceOrder < 1) return;

    this.sectionService
      .moveBulletPointSentences(bulletPointId, {
        sentenceId: sentenceId,
        direction: 'up',
      })
      .subscribe((result) => {
        this.swap(this.bulletPoint.sentences, sentenceOrder, sentenceOrder - 1);
      });
  }

  moveDownSentence(bulletPointId: number, sentenceId: number, sentenceOrder: number) {
    if (sentenceOrder >= this.bulletPoint.sentences.length) return;

    this.sectionService
      .moveBulletPointSentences(bulletPointId, {
        sentenceId: sentenceId,
        direction: 'down',
      })
      .subscribe((result) => {
        this.swap(this.bulletPoint.sentences, sentenceOrder, sentenceOrder + 1);
      });
  }

  private swap(selectedSentences: any[], x: any, y: any) {
    var b = selectedSentences[x];
    selectedSentences[x] = selectedSentences[y];
    selectedSentences[y] = b;
  }

}
