import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

// components
import { SubscaleQuestionAnswerCreateDialogComponent } from '../subscale-question-answer-create-dialog/subscale-question-answer-create-dialog.component';

// services
import { AnswerService } from '@app/core/services/answer.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { QuestionService } from '@app/core/services/question.service';

// models
import { Answer } from '@app/core/models/answer.model';
import { Question } from '@app/core/models/question.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { SubscaleBreadcrumbService } from '@app/shared/services/subscale-breadcrumb.service';
import { extractContent, isHTML } from '@app/shared/utils/common-utils';

@Component({
  selector: 'app-subscale-question-answer-detail',
  templateUrl: './subscale-question-answer-detail.component.html',
  styleUrls: ['./subscale-question-answer-detail.component.scss'],
})
export class SubscaleQuestionAnswerDetailComponent implements OnInit {
  questionId: number;
  subscaleId: number;
  answerId: number;
  tabs: string[] = ['Details'];
  selected = new FormControl(0);
  answer: Answer;
  question: Question;
  constructor(
    private route: ActivatedRoute,
    private answerService: AnswerService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private questionService: QuestionService,
    private breadScrumService: SubscaleBreadcrumbService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.questionId = Number(this.route.snapshot.params.questionId);
    this.subscaleId = Number(this.route.snapshot.params.id);
    this.answerId = Number(this.route.snapshot.params.answerId);
    this.getData();
    this.breadScrumService.updatedBreadScrum.subscribe((data) => {
      this.tabs = data;
    });
  }

  private getData() {
    forkJoin([this.answerService.getById(this.answerId), this.questionService.getById(this.questionId)]).subscribe(
      (result: [Answer, Question]) => {
        this.answer = result[0];
        this.question = result[1];
        if (isHTML(this.question.text)) {
          this.question.text = extractContent(this.question.text);
        }
        this.breadScrumService.setBreadCrumbNavigation('answer_Details', {
          subScaleTitle: this.question.subscaleTitle,
          subScaleId: this.subscaleId,
          questionTitle: this.question.text,
          questionId: this.question.id,
          answerTitle: this.answer.text,
          answerId: this.answer.id,
        });

        this.breadScrumService.currentNavigationLink.subscribe((data) => {
          if (data == 'questionName') this.selected.setValue(0);
        });
      },
    );
  }

  getAnswer() {
    this.answerService.getById(this.answerId).subscribe((response) => {
      this.answer = response;
    });
  }

  tabChange($event): void {
    this.selected.setValue($event);
    if ($event == 1) {
      this.breadScrumService.setBreadCrumbNavigation('question_answer', {
        subScaleTitle: this.question.subscaleTitle,
        subScaleId: this.subscaleId,
        questionTitle: this.question.text,
        questionId: this.questionId,
      });
    }
  }

  onEdit() {
    this.dialog
      .open(SubscaleQuestionAnswerCreateDialogComponent, {
        data: {
          isEdit: true,
          question: this.question,
          answer: this.answer,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          result.data.id = this.answerId;
          this.answerService.update(this.questionId, result.data).subscribe(() => {
            this.answer = result.data;
            this.snackBar.info('Answer updated with success!');
            this.getAnswer();
          });
        }
      });
  }

  navigateUrl(item: any) {
    if (this.router.url != item.url) {
      this.router.navigate([item.url]);
    }
    this.breadScrumService.currentNavigationLink.next(item.currentTab);
  }
}
