import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { State } from '../models/state.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class StateService {
  constructor(private http: HttpClient) {}

  getById(id: number) {
    return this.http.get<State>(BACKEND_URL + '/state/' + id);
  }

  updateById(id: number, state: State) {
    return this.http.patch(BACKEND_URL + '/state/' + id, state);
  }
}
