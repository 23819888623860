<h1 mat-dialog-title>Create or edit area</h1>
<form id="areaForm" mat-dialog-content [formGroup]="form" (submit)="onSubmit()">
  <mat-form-field>
    <mat-label>Num</mat-label>
    <input matInput type="number" min="0" formControlName="num" />
    <mat-error *ngIf="form.get('num').hasError">Num is required</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>He</mat-label>
    <textarea matInput formControlName="he" (change)="onChange()"></textarea>
    <mat-error *ngIf="form.get('he').hasError">He is required</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>She</mat-label>
    <textarea matInput formControlName="she" (change)="onChange()"></textarea>
    <mat-error *ngIf="form.get('she').hasError">She is required</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>They</mat-label>
    <textarea matInput formControlName="they" (change)="onChange()"></textarea>
    <mat-error *ngIf="form.get('they').hasError">They is required</mat-error>
  </mat-form-field>
</form>
<div align="end" mat-dialog-actions>
  <button mat-flat-button mat-dialog-close type="button">
    <mat-icon>block</mat-icon>
    Cancel
  </button>
  <button mat-flat-button color="primary" type="submit" form="areaForm" [disabled]="form.invalid">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</div>
