import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// services
import { ReportModelService } from '@app/core/services/report-model.service';

// models
import { ReportModel } from '@app/core/models/report-model.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-create-or-edit-report-model-dialog',
  templateUrl: './create-or-edit-report-model-dialog.component.html',
  styleUrls: ['./create-or-edit-report-model-dialog.component.scss'],
})
export class CreateOrEditReportModelDialogComponent implements OnInit {
  public textColor = '#FFFFFF';
  public titleColor = '#FFFFFF';
  public topBarColor = '#FFFFFF';
  public sideBarColor = '#FFFFFF';
  public base64Picture: any;
  public base64SignatureLogo: any;
  public reportModel: ReportModel;
  isEdit: boolean;

  reportModelForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    disclosure: new FormControl('', [Validators.required]),
    textColor: new FormControl('', [Validators.required]),
    titleColor: new FormControl('', [Validators.required]),
    topBarColor: new FormControl('', [Validators.required]),
    sideBarColor: new FormControl('', [Validators.required]),
    picture: new FormControl(''),
    pictureContentType: new FormControl(''),
    signatureTitle: new FormControl('', [Validators.required]),
    signatureLogo: new FormControl(''),
    signatureLogoContentType: new FormControl('', [Validators.required]),
    signatureText: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateOrEditReportModelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; reportModelId: number },
    private reportModelService: ReportModelService,
  ) {}

  ngOnInit(): void {
    this.isEdit = this.data.isEdit;
    if (this.isEdit && this.data.reportModelId) {
      this.reportModelService.getOne(this.data.reportModelId).subscribe((res) => {
        this.reportModel = {
          title: res.title,
          disclosure: res.disclosure,
          textColor: res.textColor,
          titleColor: res.titleColor,
          topBarColor: res.topBarColor,
          sideBarColor: res.sideBarColor,
          picture: res.picture ? res.picture : null,
          pictureContentType: res.pictureContentType,
          signatureTitle: res.signatureTitle,
          signatureLogo: res.signatureLogo ? res.signatureLogo : null,
          signatureLogoContentType: res.signatureLogoContentType,
          signatureText: res.signatureText,
        };

        this.reportModelForm.get('title').setValue(this.reportModel.title);
        this.reportModelForm.get('disclosure').setValue(this.reportModel.disclosure);
        this.reportModelForm.get('textColor').setValue(this.reportModel.textColor);
        this.reportModelForm.get('titleColor').setValue(this.reportModel.titleColor);
        this.reportModelForm.get('topBarColor').setValue(this.reportModel.topBarColor);
        this.reportModelForm.get('sideBarColor').setValue(this.reportModel.sideBarColor);
        this.reportModelForm.get('picture').setValue(this.reportModel.picture);
        this.reportModelForm.get('pictureContentType').setValue(this.reportModel.pictureContentType);
        this.reportModelForm.get('signatureTitle').setValue(this.reportModel.signatureTitle);
        this.reportModelForm.get('signatureLogo').setValue(this.reportModel.signatureLogo);
        this.reportModelForm.get('signatureLogoContentType').setValue(this.reportModel.signatureLogoContentType);
        this.reportModelForm.get('signatureText').setValue(this.reportModel.signatureText);
      });
    }
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  changeColor(color, colorType) {
    switch (colorType) {
      case 'textColor':
        this.reportModelForm.get('textColor').patchValue(color);
        break;
      case 'titleColor':
        this.reportModelForm.get('titleColor').patchValue(color);
        break;
      case 'topBarColor':
        this.reportModelForm.get('topBarColor').patchValue(color);
        break;
      case 'sideBarColor':
        this.reportModelForm.get('sideBarColor').patchValue(color);
        break;
      default:
        break;
    }
  }

  handleFileSelect(evt, dataType) {
    const files = evt.target.files;
    const file = files[0];
    if (files && file) {
      const reader = new FileReader();
      reader.onload = (f) => {
        this.handleReaderLoaded(f, file.type, dataType);
      };

      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(file, fileType, dataType) {
    if (dataType === 1) {
      this.base64Picture = btoa(file.target.result);
      this.reportModelForm.patchValue({ picture: this.base64Picture, pictureContentType: fileType });
      const pictureObj = {
        picture: this.base64Picture,
        pictureContentType: fileType,
      };

      this.reportModel = { ...this.reportModel, ...pictureObj };
    }

    if (dataType === 2) {
      this.base64SignatureLogo = btoa(file.target.result);
      this.reportModelForm.patchValue({ signatureLogo: this.base64SignatureLogo, signatureLogoContentType: fileType });
      const signatureLogoObj = {
        signatureLogo: this.base64SignatureLogo,
        signatureLogoContentType: fileType,
      };
      this.reportModel = { ...this.reportModel, ...signatureLogoObj };
    }
  }

  onSave() {
    this.dialogRef.close({ event: 'save', data: this.reportModelForm.value });
  }
}
