<div id="dt-accept">
  <h2>Decisions Assigned to you</h2>

  <p>Once you accept, the decision is added on your table.</p>

  <table *ngIf="decisionTable.columns.length > 0" class="table-columns mat-elevation-z3">
    <thead>
      <tr>
        <td *ngFor="let decisionTableColumn of decisionTable.columns">
          {{ decisionTableColumn.title }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let decisionTableAnswer of decisionTableAnswersToAccept; let i = index" [id]="decisionTableAnswer.id">
        <td *ngFor="let decisionTableColumn of decisionTable.columns" style="padding: 2px 0px">
          <div [ngSwitch]="decisionTableColumn.type">
            <div *ngSwitchCase="'2'">
              <mat-chip-list class="mat-chip-list-stacked" aria-label="Users list">
                <div *ngFor="let answer of decisionTableAnswer[getFormName(decisionTableColumn.title)]">
                  <mat-chip style="width: auto" [selectable]="false" [removable]="false" *ngIf="answer">
                    {{ answer.userName }}
                  </mat-chip>
                </div>
              </mat-chip-list>
              <div id="accept-actions">
                <mat-checkbox
                  color="primary"
                  (change)="
                    onAnswer(
                      true,
                      decisionTableAnswer.id,
                      decisionTableAnswer[getFormName(decisionTableColumn.title)][0].userName
                    )
                  "
                >
                  Accept</mat-checkbox
                >
                <mat-checkbox
                  color="primary"
                  (change)="
                    onAnswer(
                      false,
                      decisionTableAnswer.id,
                      decisionTableAnswer[getFormName(decisionTableColumn.title)][0].userName
                    )
                  "
                >
                  Reject</mat-checkbox
                >
              </div>
            </div>
            <div *ngSwitchDefault>
              {{ decisionTableAnswer[getFormName(decisionTableColumn.title)] }}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
