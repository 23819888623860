import { Component, Input, OnInit } from '@angular/core';
import { GenericLink } from '@app/core/models/generic-link.model';
import { TestGroupService } from '@app/core/services/test-group.service';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { MatDialog } from '@angular/material/dialog';
import { CreateOrEditGenericLinkDialogComponent } from '@app/shared/components/create-or-edit-generic-link-dialog/create-or-edit-generic-link-dialog.component';
import { EVENT_CANCEL } from '@app/core/constants';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { SnackBarService } from '@app/core/services/snack-bar.service';

@Component({
  selector: 'app-generic-link',
  templateUrl: './generic-link.component.html',
  styleUrls: ['./generic-link.component.scss'],
})
export class GenericLinkComponent implements OnInit {
  @Input() testGroupId: number;
  genericLinks: GenericLink[];
  dataSource = new MatTableDataSource<GenericLink>(this.genericLinks);
  displayedColumns: string[] = ['token', 'enabled', 'actions'];
  environment = environment;
  constructor(
    private testGroupService: TestGroupService,
    private clipboardApi: ClipboardService,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.retrieveGenericLinks();
  }

  retrieveGenericLinks(): void {
    this.testGroupService.getAllGenericLinkByTestGroupId(this.testGroupId).subscribe((genericLinks: GenericLink[]) => {
      this.genericLinks = genericLinks;
      this.dataSource.data = this.genericLinks;
    });
  }

  onEdit(genericLink: GenericLink) {
    const editDialog = this.dialog.open(CreateOrEditGenericLinkDialogComponent, {
      data: { action: 'update', genericLink },
    });

    editDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.testGroupService.updateGenericLink(result.data).subscribe((response) => {
          if (response) {
            this.retrieveGenericLinks();
            this.snackBar.info('Generic Link has been updated successfully');
          }
        });
      }
    });
  }

  onDelete(id) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.testGroupService.deleteGenericLink(id).subscribe(() => {
            this.retrieveGenericLinks();
            this.snackBar.info('Generic Link has been deleted successfully');
          });
        }
      });
  }

  onCopy(token) {
    this.clipboardApi.copyFromContent(`${environment.url}/g-invite/${token}`);
    this.snackBar.info('Generic Link has been copied successfully');
  }

  onView(genericLink: GenericLink) {
    this.dialog.open(CreateOrEditGenericLinkDialogComponent, {
      data: { action: 'view', genericLink },
    });

    // viewDialog.afterClosed().subscribe((result) => {
    //   if (result.event !== EVENT_CANCEL) {
    //     this.testGroupService.createGenericLink(result.data).subscribe((response) => {
    //       if (response) {
    //         this.retrieveGenericLinks();
    //         this.snackBar.info('Generic Link has been created successfully');
    //       }
    //     });
    //   }
    // });
  }

  onCreate() {
    const createDialog = this.dialog.open(CreateOrEditGenericLinkDialogComponent, {
      data: { action: 'create', testGroupId: this.testGroupId },
    });

    createDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.testGroupService.createGenericLink(result.data).subscribe((response) => {
          if (response) {
            this.retrieveGenericLinks();
            this.snackBar.info('Generic Link has been created successfully');
          }
        });
      }
    });
  }
}
