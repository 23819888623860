import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConnectionsIndicatorComponent } from './connections-indicator/connections-indicator.component';
import { ConnectionsIndicatorInfoComponent } from './connections-indicator-info/connections-indicator-info.component';
import { ConnectionsIndicatorQuestionsComponent } from './connections-indicator-questions/connections-indicator-questions.component';
import { ConnectionsIndicatorDecisionTablesComponent } from './connections-indicator-decision-tables/connections-indicator-decision-tables.component';
import { ConnectionsIndicatorReviewComponent } from './connections-indicator-review/connections-indicator-review.component';
import { ConnectionsIndicatorDashboardComponent } from './connections-indicator-dashboard/connections-indicator-dashboard.component';
import { ConnectionsIndicatorSortComponent } from './connections-indicator-sort/connections-indicator-sort.component';
import { ConnectionsIndicatorUsersComponent } from './connections-indicator-users/connections-indicator-users.component';
import { ConnectionsIndicatorSearchComponent } from './connections-indicator-search/connections-indicator-search.component';

const connectionsIndicatorRoutes: Routes = [
  { path: '', component: ConnectionsIndicatorComponent },
  { path: 'new', component: ConnectionsIndicatorInfoComponent },
  { path: ':id/users', component: ConnectionsIndicatorUsersComponent },
  { path: ':id/edit', component: ConnectionsIndicatorInfoComponent },
  { path: ':id/questions', component: ConnectionsIndicatorQuestionsComponent },
  { path: ':id/decision-tables', component: ConnectionsIndicatorDecisionTablesComponent },
  { path: ':id/sort', component: ConnectionsIndicatorSortComponent },
  { path: ':id/review', component: ConnectionsIndicatorReviewComponent },
  { path: ':id/dashboard', component: ConnectionsIndicatorDashboardComponent },
  { path: ':id/dashboard/tab/:tab', component: ConnectionsIndicatorDashboardComponent },
  { path: ':id/search', component: ConnectionsIndicatorSearchComponent },
];

@NgModule({
  imports: [RouterModule.forChild(connectionsIndicatorRoutes)],
  exports: [RouterModule],
})
export class ConnectionsIndicatorRoutingModule {}
