<div class="chartjs-container" *ngIf="lineChartData && lineChartLabels">
  <canvas
    baseChart
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [colors]="lineChartColors"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
    [plugins]="lineChartPlugins"
    (chartHover)="chartHovered($event)"
    (chartClick)="chartClicked($event)"
  >
  </canvas>
</div>
