<div class="reset-password-container">
  <h1 mat-dialog-title>Reset Password</h1>
  <div mat-dialog-content>
    <div class="password-container">
      <div class="fields-panel">
        <mat-form-field>
          <mat-label>New Password</mat-label>
          <input
            matInput
            (focus)="focusInPassword()"
            (focusout)="focusOutPassword()"
            [(ngModel)]="password"
            minlength="8"
            maxlength="25"
            autocomplete="off"
          />
        </mat-form-field>
        <div>
          <button mat-flat-button color="primary" (click)="generatePassword()">Generate Password</button>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <p class="small-text">
        Attention: Resetting the user's password is an irreversible action.<br />
        The old password will be replaced by the chosen one.
      </p>
    </div>
  </div>
  <div class="mt-2 footer" mat-dialog-actions align="end">
    <button (click)="closeDialog()" mat-flat-button mat-dialog-close>Cancel</button>
    <button (click)="resetPassword()" [disabled]="!isPasswordStrengthValid" mat-flat-button color="primary">
      Reset
    </button>
  </div>

  <div class="strength-panel" [ngClass]="{ 'hide-password-info': !showWarningPanel }">
    <mat-password-strength
      (onStrengthChanged)="onStrengthChanged($event)"
      #passwordComponentWithValidation
      [password]="password"
    >
    </mat-password-strength>
    <mat-password-strength-info #passwordInfo [passwordComponent]="passwordComponentWithValidation">
    </mat-password-strength-info>
  </div>
</div>
