<form [formGroup]="form" (submit)="onSubmit()" autocomplete="off">
  <mat-grid-list cols="2" rowHeight="4rem" gutterSize="1rem">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>First Name *</mat-label>
        <input matInput type="text" appAllowAlphabetsOnly formControlName="firstName" />
        <mat-error *ngIf="form.get('firstName').errors">Please, enter a valid first name.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Last Name *</mat-label>
        <input matInput type="text" appAllowAlphabetsOnly formControlName="lastName" />
        <mat-error *ngIf="form.get('lastName').errors">Please, enter a valid last name.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>E-mail *</mat-label>
        <input matInput type="email" formControlName="email" (focusout)="onFocusOutEmail()" />
        <mat-error *ngIf="form.get('email').errors && !form.get('email').hasError('isEmailExist')"
          >Please, enter a valid e-mail.</mat-error
        >
        <mat-error *ngIf="form.get('email').hasError('isEmailExist')">
          This email has already been registered.
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Username *</mat-label>
        <input matInput type="text" formControlName="username" (focusout)="onFocusOutUsername()" />
        <mat-error *ngIf="form.get('username').errors && !form.get('username').hasError('isUsernameExist')"
          >Please, enter a valid username.</mat-error
        >
        <mat-error *ngIf="form.get('username').hasError('isUsernameExist')">
          This username has already been registered.
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
        <input
          matInput
          (focus)="focusInPassword()"
          (focusout)="focusOutPassword()"
          #password
          [type]="toggle.type"
          required
          formControlName="password"
          placeholder="Password"
          maxlength="25"
        />
        <mat-hint align="end" aria-live="polite"> {{ password.value.length }} / 25 </mat-hint>
        <mat-error *ngIf="form.get('password').invalid">Please enter a valid password.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Confirm Password *</mat-label>
        <input
          matInput
          type="password"
          #passwordConfirmation
          formControlName="passwordConfirmation"
          placeholder="Password Confirmation"
          [ngClass]="{ 'is-invalid': form.get('passwordConfirmation').errors }"
          maxlength="25"
        />
        <mat-error *ngIf="form.get('passwordConfirmation').invalid">Please enter the same password.</mat-error>
      </mat-form-field>
      <mat-password-strength #passwordComponent [password]="password.value"></mat-password-strength>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1" *ngIf="isLoginUserAdmin && !data?.companyId">
      <mat-form-field>
        <mat-label>Company *</mat-label>
        <input matInput type="text" formControlName="company" [matAutocomplete]="autoCompany" />
        <mat-autocomplete
          #autoCompany="matAutocomplete"
          (optionSelected)="onSelectClient($event.option.value.id)"
          [displayWith]="displayCompany"
        >
          <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
            {{ company.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="form.get('company').invalid">Please, enter a valid company.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1" *ngIf="isLoginUserAdmin">
      <mat-form-field>
        <mat-label>Role</mat-label>
        <mat-select formControlName="role">
          <mat-option value="ROLE_TEST_USER">Test User</mat-option>
          <mat-option value="ROLE_TGM">Test Group Manager</mat-option>
          <mat-option value="ROLE_ORGANIZATIONAL_ADMIN">Organizational Admin</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('role').errors">Please, select a valid role.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Country</mat-label>
        <input matInput type="text" formControlName="country" [matAutocomplete]="autoCountry" />
        <mat-autocomplete
          #autoCountry="matAutocomplete"
          (optionSelected)="onSelectCountry()"
          [displayWith]="displayCountry"
        >
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{ displayCountry(country) }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput type="text" formControlName="state" [matAutocomplete]="autoState" />
        <mat-autocomplete #autoState="matAutocomplete" [displayWith]="displayState">
          <mat-option *ngFor="let state of filteredStates | async" [value]="state">
            {{ state && state.name ? state.name : null }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field>
        <mat-label>Gender *</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="FEMALE">Female</mat-option>
          <mat-option value="MALE">Male</mat-option>
          <mat-option value="OTHER">Other</mat-option>
          <mat-option value="PREFER_NOT_TO_SAY">Prefer not to say</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('gender').errors">Please, enter a valid gender.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Phone *</mat-label>
        <input matInput type="text" formControlName="phone" />
        <mat-error *ngIf="form.get('phone').errors">Please, enter a valid phone.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Language</mat-label>
        <input matInput type="text" formControlName="language" [matAutocomplete]="autoLanguage" />
        <mat-autocomplete #autoLanguage="matAutocomplete" [displayWith]="displayLanguage">
          <mat-option *ngFor="let language of filteredLanguages | async" [value]="language">
            {{ language.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="form.get('language').invalid">Please, enter a valid language.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Time Zone</mat-label>
        <input matInput type="text" formControlName="timeZone" [matAutocomplete]="autoTimeZone" />
        <mat-autocomplete #autoTimeZone="matAutocomplete" [displayWith]="displayTimeZone">
          <mat-option *ngFor="let timeZone of filteredTimeZones | async" [value]="timeZone">
            {{ timeZone && timeZone.name ? timeZone.name : null }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile
      *ngIf="isLoginUserAdmin && data?.companyId"
      [colspan]="isLoginUserAdmin ? 1 : 2"
      rowspan="1"
    ></mat-grid-tile>

    <mat-grid-tile [colspan]="isLoginUserAdmin ? 1 : 2" rowspan="1">
      <div>
        <mat-slide-toggle color="primary" formControlName="activated" checked="true"> Active </mat-slide-toggle>
        <p
          class="small-text"
          [ngStyle]="{ opacity: form.get('activated').value === false || !form.get('activated').value ? 1 : 0 }"
        >
          Inactive users will not be able to access their accounts or respond to assessments.
        </p>
      </div>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1" *ngIf="isLoginUserAdmin">
      <div>
        <mat-slide-toggle color="primary" formControlName="privateScores" [checked]="false">
          Private Scores
        </mat-slide-toggle>
        <p
          class="small-text"
          [ngStyle]="{ opacity: form.get('privateScores').value === true || form.get('privateScores').value ? 1 : 0 }"
        >
          By turning private scores on, they will be available only for admins.
        </p>
      </div>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <div>
        <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
          <mat-icon>block</mat-icon>
          Cancel
        </button>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <div>
        <button mat-button class="btn-success btn-300" type="submit" [disabled]="form.invalid">
          <mat-icon>save_alt</mat-icon>
          Save
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="strength-panel" [ngClass]="{ 'hide-password-info': !showWarningPanel }">
    <mat-password-strength
      (onStrengthChanged)="onStrengthChanged($event)"
      #passwordComponent
      [password]="password.value"
    >
    </mat-password-strength>
    <mat-password-strength-info #passwordInfo [passwordComponent]="passwordComponent"> </mat-password-strength-info>
  </div>
</form>
