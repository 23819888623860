import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { HelpComponent } from './pages/help/help.component';

const adminRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'help', component: HelpComponent },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
