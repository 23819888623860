<div class="container">
  <h1>{{ title }}</h1>

  <app-instructions
    [text]="instructions"
    title="Instructions"
    [background]="'startPageInstructions'"
  ></app-instructions>

  <app-instructions
    *ngIf="warningInstructions"
    [text]="warningInstructions"
    title="Warning"
    [background]="'warningInstructions'"
  ></app-instructions>

  <p *ngIf="testTime">
    <b
      >The following assessment has a limit of {{ testTime }} minutes to be finished. Make sure you are in a calm
      environment and have no sources of interruption before you start. Once the test has started, you will not be able
      to redo it.</b
    >
  </p>
  <button mat-raised-button class="btn-success btn-center btn-300" (click)="onClickStart()">Start</button>
</div>
