import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionTableRelation } from '@app/core/models/decision-table-relation.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { DecisionTableColumn } from '@app/core/models/decision-table-column.model';

@Component({
  selector: 'app-form-decision-table-connection',
  templateUrl: './form-decision-table-connection.component.html',
  styleUrls: ['./form-decision-table-connection.component.scss'],
})
export class FormDecisionTableConnectionComponent implements OnInit {
  @Input() decisionTableRelation?: DecisionTableRelation;
  @Input() decisionTables: DecisionTable[];
  @Input() inputColumnsConnections?: any[];
  @Output() save = new EventEmitter<DecisionTableRelation>();

  connectionFormGroup = new FormGroup({
    originFormControl: new FormControl('', [Validators.required]),
    destinationFormControl: new FormControl('', [Validators.required]),
    acceptFormControl: new FormControl(0, [Validators.required]),
  });

  originDecisionTable: DecisionTable;
  destinationDecisionTable: DecisionTable;

  columnsConnections = [];
  destinationColumnFrom: FormGroup;

  constructor(private decisionTableService: DecisionTableService) {}

  ngOnInit(): void {
    if (this.inputColumnsConnections?.length > 0) {
      this.columnsConnections = this.inputColumnsConnections;
    }

    if (this.decisionTableRelation) {
      this.originDecisionTable = this.decisionTableRelation.originDecisionTable;
      this.destinationDecisionTable = this.decisionTableRelation.destinationDecisionTable;
      this.columnsConnections = this.decisionTableRelation.columns;

      const group: any = {};
      this.originDecisionTable.columns.forEach((column) => {
        const columnExist = this.columnsConnections.find((col) => col.origin === column.id);
        group[this.getFormName(column.title)] = new FormControl(columnExist ? columnExist.destination : '');
      });
      this.destinationColumnFrom = new FormGroup(group);

      this.connectionFormGroup.setValue({
        originFormControl: this.originDecisionTable.id,
        destinationFormControl: this.destinationDecisionTable.id,
        acceptFormControl: this.decisionTableRelation.accept,
      });
    }
  }

  getFormName(columnTitle: string) {
    return this.decisionTableService.getFormName(columnTitle);
  }

  onSelectionChangeColumn(originColumn: DecisionTableColumn, destinationColumns: DecisionTableColumn[]) {
    const columnChanged = {
      origin: originColumn.id,
      originTitle: originColumn.title,
      destination: this.destinationColumnFrom.get(this.getFormName(originColumn.title)).value,
      destinationTitle: destinationColumns.filter(
        (col) => col.id === this.destinationColumnFrom.get(this.getFormName(originColumn.title)).value,
      )[0].title,
    };
    const originColumnIndex = this.columnsConnections.map((col) => col.origin).indexOf(originColumn.id);

    if (originColumnIndex < 0) {
      this.columnsConnections.push(columnChanged);
    } else {
      columnChanged['id'] = this.columnsConnections[originColumnIndex].id;
      this.columnsConnections[originColumnIndex] = columnChanged;
    }
  }

  onSelectionChange() {
    this.originDecisionTable = this.decisionTables.find(
      (dt) => dt.id === this.connectionFormGroup.get('originFormControl').value,
    );
    this.destinationDecisionTable = this.decisionTables.find(
      (dt) => dt.id === this.connectionFormGroup.get('destinationFormControl').value,
    );

    if (this.originDecisionTable && this.destinationDecisionTable) {
      this.createDestinationFormGroup(this.originDecisionTable);
    }
  }

  createDestinationFormGroup(decisionTable: DecisionTable) {
    const group: any = {};
    decisionTable.columns.forEach((column) => {
      group[this.getFormName(column.title)] = new FormControl('');
    });
    this.destinationColumnFrom = new FormGroup(group);
  }

  onSave() {
    if (this.columnsConnections.length > 0) {
      if (this.connectionFormGroup.valid && this.destinationColumnFrom.valid) {
        const dtRelation = {
          origin: this.connectionFormGroup.get('originFormControl').value,
          originDecisionTable: this.decisionTables.filter(
            (dt) => dt.id === this.connectionFormGroup.get('originFormControl').value,
          )[0],
          destination: this.connectionFormGroup.get('destinationFormControl').value,
          destinationDecisionTable: this.decisionTables.filter(
            (dt) => dt.id === this.connectionFormGroup.get('destinationFormControl').value,
          )[0],
          columns: this.columnsConnections,
          accept: this.connectionFormGroup.get('acceptFormControl').value,
        };

        this.save.emit(dtRelation);
      }
    } else {
      this.destinationColumnFrom.setErrors({ 'missing-column-destination': true });
    }
  }
}
