import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class ReportDataDownloadService {
  constructor(private http: HttpClient) {}

  getReportDataDownload(
    companyId: number,
    testGroupId: number,
    testId: number,
    type: string,
    dateFrom: string,
    dateTo: string,
  ) {
    return this.http.get<{ fileName: string; fileMimeType: string; fileBase64Content: string }>(
      BACKEND_URL + '/report-data-download/export-report',
      {
        params: {
          companyId: String(companyId),
          testGroupId: String(testGroupId),
          testId: String(testId),
          reportType: type,
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      },
    );
  }
}
