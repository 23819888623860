import { Component, Input, OnInit } from '@angular/core';
import { TestGroupService } from '@app/core/services/test-group.service';
import { PsyTestGroupUser } from '@app/core/models/psy-test-group-user.model';

@Component({
  selector: 'app-blossom-graph-preview',
  templateUrl: './blossom-graph-preview.component.html',
  styleUrls: ['./blossom-graph-preview.component.scss'],
})
export class BlossomGraphPreviewComponent implements OnInit {
  @Input() chartId: number;

  @Input() testGroupId: number;

  userInfoId: number | undefined;

  users: PsyTestGroupUser[] = [];

  constructor(private testGroupService: TestGroupService) {}

  ngOnInit(): void {
    this.testGroupService.getTestGroupUserById(this.testGroupId).subscribe((users) => {
      this.users = users;
      this.userInfoId = users[0].userInfoId;
    });
  }
}
