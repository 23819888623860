<h1>{{ data.isEdit ? 'Edit a superscale' : 'Create a superscale' }}</h1>
<form [formGroup]="superscaleDialogForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Title</mat-label>
    <input matInput type="text" formControlName="title" />
    <mat-error *ngIf="superscaleDialogForm.get('title').errors">Please, enter a valid title.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Description</mat-label>
    <input matInput type="text" formControlName="description" />
    <mat-error *ngIf="superscaleDialogForm.get('description').errors">Please, enter a valid description.</mat-error>
  </mat-form-field>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!superscaleDialogForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
