<div class="container">
  <app-psb-page-navigator [pages]="pages"></app-psb-page-navigator>

  <app-test-users [roleMandateId]="roleMandateId"></app-test-users>

  <app-user-invite [roleMandateId]="roleMandateId"></app-user-invite>

  <div>
    <button mat-raised-button class="btn-success btn-center btn-300" (click)="onNext()">Next</button>
  </div>
</div>
