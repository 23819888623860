/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { SupertestTest } from '../models/SupertestTest';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class SupertestTestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all SupertestTest by id
     * Retrieve all SupertestTest by id
     * @param id Id of the supertest
     * @returns SupertestTest A list of supertestTests
     * @throws ApiError
     */
    public getSupertestSupertestTest(
        id: number,
    ): Observable<SupertestTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/supertest/{id}/supertest-test',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of supertestTests not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all SupertestTest by id
     * Retrieve all SupertestTest by id
     * @param id Id of the psytest
     * @returns SupertestTest A list of supertestTests
     * @throws ApiError
     */
    public getTestSupertestTest(
        id: number,
    ): Observable<SupertestTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/{id}/supertest-test',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of supertestTests not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Complete a supertesttest
     * Create a supertesttest
     * @param requestBody Callback payload
     * @throws ApiError
     */
    public postSupertestTest(
        requestBody?: {
            /**
             * Id of the psytest
             */
            id: number;
            /**
             * Id of the supertest
             */
            supertestId?: number;
            /**
             * Id of the test
             */
            psyTestId?: number;
        },
    ): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{id}/supertest-test',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a SupertestTest
     * Delete a SupertestTest
     * @param id Id of the supertestTest
     * @returns any SupertestTest deleted successfully
     * @throws ApiError
     */
    public deleteTestSupertestTest(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test/supertest-test/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
