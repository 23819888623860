import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ReportModel } from '../models/report-model.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class ReportModelService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<ReportModel[]>(BACKEND_URL + '/report-model');
  }

  getOne(reportModelId: number) {
    return this.http.get<ReportModel>(BACKEND_URL + '/report-model/' + reportModelId);
  }

  save(reportModel: ReportModel) {
    return this.http.post(BACKEND_URL + '/report-model', reportModel);
  }

  update(reportModelId: number, reportModel: ReportModel) {
    return this.http.patch(BACKEND_URL + '/report-model/' + reportModelId, reportModel);
  }

  delete(reportModelId: number) {
    return this.http.delete(BACKEND_URL + '/report-model/' + reportModelId);
  }
}
