import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Services
import { ChartService } from '@app/core/services/chart.service';
import { ReportService } from '@app/core/services/report.service';

// Models
import { TestGroupChartComparison } from '@app/core/models/test-group-chart-comparison.model';

@Component({
  selector: 'app-filter-chart-users',
  templateUrl: './filter-chart-users.component.html',
  styleUrls: ['./filter-chart-users.component.scss'],
})
export class FilterChartUsersComponent implements OnInit {
  users: any[] = [];
  searchTerm: string;
  searchFieldUpdate = new Subject<string>();
  searchField: string;
  selectedUsers: any[] = [];
  testGroupChartComparisons: TestGroupChartComparison[];
  @Input() testGroupId: number;
  @Input() chartId: number;
  @Output() filteredUsers = new EventEmitter<any[]>();

  selection = new SelectionModel<any>(true, []);

  constructor(private chartService: ChartService, private reportService: ReportService) {}

  ngOnInit(): void {
    this.chartService.getChartUsersListener().subscribe((users) => {
      this.users = users.sort((a, b) => a.userName.localeCompare(b.userName));
      this.reportService
        .getAllTestGroupChartComparisonByTestGroupIdAndChartId(this.testGroupId, this.chartId)
        .subscribe((testGroupChartComparisons: TestGroupChartComparison[]) => {
          this.testGroupChartComparisons = testGroupChartComparisons;
          this.users.forEach((user) => {
            if (
              this.testGroupChartComparisons.find(
                (testGroupChartComparison) => testGroupChartComparison.userInfoId === user.userInfoId,
              )
            ) {
              this.selection.select(user);
              this.selectedUsers.push(user);
              this.filteredUsers.emit(this.selectedUsers);
            }
          });
        });
    });

    this.searchFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.searchTerm = value;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.users.length;
    return numSelected === numRows;
  }

  masterToggle() {
    let testGroupChartComparisons: TestGroupChartComparison[] = [];
    if (this.isAllSelected()) {
      this.selection.selected.forEach((selected) => {
        let testGroupChartComparison = {
          testGroupId: this.testGroupId,
          chartId: this.chartId,
          userInfoId: selected.userInfoId,
          hided: true,
        };
        testGroupChartComparisons.push(testGroupChartComparison);
      });
      this.selection.clear();
      this.filteredUsers.emit([]);
    } else {
      this.users.forEach((user) => this.selection.select(user));
      this.selection.selected.forEach((selected) => {
        let testGroupChartComparison = {
          testGroupId: this.testGroupId,
          chartId: this.chartId,
          userInfoId: selected.userInfoId,
          hided: false,
        };
        testGroupChartComparisons.push(testGroupChartComparison);
      });
      this.filteredUsers.emit(this.users);
    }
    this.reportService.massUpdateTestGroupChartComparison(testGroupChartComparisons).subscribe();
  }

  handleSelectUser(user: any) {
    this.selection.toggle(user);
    const testGroupChartComparison = {
      testGroupId: this.testGroupId,
      chartId: this.chartId,
      userInfoId: user.userInfoId,
      hided: !this.selection.isSelected(user),
    };
    this.reportService.updateTestGroupChartComparison(testGroupChartComparison).subscribe();
    this.selectedUsers = [];
    this.users.forEach((user) => {
      if (this.selection.isSelected(user)) {
        this.selectedUsers.push(user);
      }
    });

    this.filteredUsers.emit(this.selectedUsers);
  }
}
