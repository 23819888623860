<mat-table *ngIf="testResults" [dataSource]="testResults">
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.title }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dateCompleted">
    <mat-header-cell *matHeaderCellDef> Date Completed </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.dateCompleted }} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
