import { Component, Input, OnInit } from '@angular/core';
import { CombinationParagraph } from '@app/core/models/combination-paragraph.model';

@Component({
  selector: 'app-combination-paragraph',
  templateUrl: './combination-paragraph.component.html',
  styleUrls: ['./combination-paragraph.component.scss'],
})
export class CombinationParagraphComponent implements OnInit {
  @Input() model: CombinationParagraph;
  @Input() leadSentence: string;

  constructor() {}

  ngOnInit(): void {
    this.leadSentence =
      this.model.leadSentence.text !== undefined
        ? this.model.leadSentence.text !== 'null'
          ? this.model.leadSentence.text
          : ''
        : '';
  }
}
