import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SharedModule } from '@app/shared/shared.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { testGroupRouting } from './test-group.routing';
import { TestGroupListTableComponent } from './test-group-list-table/test-group-list-table.component';
import { TestGroupListComponent } from './test-group-list/test-group-list.component';
import { TestGroupDetailComponent } from './test-group-details/test-group-detail.component';
import { TestGroupReportComponent } from '@app/admin/pages/test-group/test-group-report/test-group-report.component';
import { TestGroupPreferredRangeSuperscaleComponent } from '@app/admin/pages/test-group/test-group-preferred-range-superscale/test-group-preferred-range-superscale.component';
import { TestGroupPreferredRangesSubscaleComponent } from '@app/admin/pages/test-group/test-group-preferred-ranges-subscale/test-group-preferred-ranges-subscale.component';
import { TestResultListViewComponent } from '@app/admin/pages/test-group/test-result-list-view/test-result-list-view.component';
import { TestGroupScorecardComponent } from '@app/admin/pages/test-group/test-group-scorecard/test-group-scorecard.component';
import { ApiV2Client } from '../../../../generated/v2';
import { TestGroupUserListTableComponent } from '@app/admin/pages/test-group/test-group-details/test-group-user-list-table/test-group-user-list-table.component';
import { MoveUsersFromOtherTestGroupsComponent } from '@app/admin/pages/test-group/test-group-details/test-group-user-list-table/move-users-from-other-test-groups/move-users-from-other-test-groups.component';
import { AddUsersFromOtherTestGroupsComponent } from '@app/admin/pages/test-group/test-group-details/test-group-user-list-table/add-users-from-other-test-groups/add-users-from-other-test-groups.component';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { EditDocxTemplateComponent } from './test-group-details/edit-docx-template/edit-docx-template.component';
import { NgProgressModule } from 'ngx-progressbar';
import { ApiV3ClientModule } from '@app/core/client/v3/api-v3-client.module';

@NgModule({
  declarations: [
    TestGroupListTableComponent,
    TestGroupListComponent,
    TestGroupDetailComponent,
    TestGroupUserListTableComponent,
    MoveUsersFromOtherTestGroupsComponent,
    AddUsersFromOtherTestGroupsComponent,
    TestGroupReportComponent,
    TestGroupPreferredRangeSuperscaleComponent,
    TestGroupPreferredRangesSubscaleComponent,
    TestResultListViewComponent,
    TestGroupScorecardComponent,
    EditDocxTemplateComponent,
  ],
  exports: [
    TestGroupListTableComponent,
    TestGroupListComponent,
    TestGroupPreferredRangeSuperscaleComponent,
    TestGroupPreferredRangesSubscaleComponent,
    TestResultListViewComponent,
    TestGroupScorecardComponent,
  ],
  imports: [
    RouterModule.forChild(testGroupRouting),
    MatSortModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    ApiV2Client,
    ReactiveFormsModule,
    AngularEditorModule,
    SharedModule,
    Ng2SearchPipeModule,

    NgProgressModule,
  ],
})
export class TestGroupModule {}
