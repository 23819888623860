import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { AreaIndex } from '../models/area-index.model';
import { Area } from '../models/area.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class AreaIndexService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<AreaIndex[]>(`${BACKEND_URL}/area-index`);
  }

  getById(areaIndexId: number) {
    return this.http.get<AreaIndex>(`${BACKEND_URL}/area-index/${areaIndexId}`);
  }

  remove(areaIndexId: number) {
    return this.http.delete(`${BACKEND_URL}/area-index/${areaIndexId}`);
  }

  save(areaIndex: AreaIndex) {
    return this.http.post<AreaIndex>(`${BACKEND_URL}/area-index`, areaIndex);
  }

  update(areaIndexId: number, areaIndex: AreaIndex) {
    return this.http.patch<AreaIndex>(`${BACKEND_URL}/area-index/${areaIndexId}`, areaIndex);
  }

  addArea(areaIndexId: number, params: { num: number; he: string; she: string; they: string }) {
    const url = `${BACKEND_URL}/area-index/${areaIndexId}/area`;
    const body = {
      num: params.num,
      he: params.he,
      she: params.she,
      they: params.they,
    };

    return this.http.post<Area>(url, body);
  }

  removeArea(areaIndexId: number, areaId: number) {
    const url = `${BACKEND_URL}/area-index/${areaIndexId}/area/${areaId}`;
    return this.http.delete(url);
  }

  updateArea(areaIndexId: number, areaId: number, area: Area) {
    const url = `${BACKEND_URL}/area-index/${areaIndexId}/area/${areaId}`;
    const body = {
      num: area.num,
      he: area.he,
      she: area.she,
      they: area.they,
    };

    return this.http.patch<Area>(url, body);
  }

  async getAreaIndex(chart: any, pathNumber: any) {
    return new Promise((resolve, reject) => {
      if (chart.areaIndexId) {
        return this.getById(chart.areaIndexId)
          .toPromise()
          .then((response: any) => {
            if (response.areas.length) {
              let _filteredPathNumber = response.areas.filter((area) => area.num === Number(pathNumber));
              if (_filteredPathNumber.length < 1 && Number(pathNumber) === 0) {
                _filteredPathNumber = response.areas.filter((area) => area.num === Number(pathNumber) + 1);
              }
              if (_filteredPathNumber.length > 0) {
                resolve(_filteredPathNumber[0]);
              } else {
                resolve(null);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }
}
