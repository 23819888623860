import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { CurveRange } from '../models/curve-range.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class CurveRangeService {
  constructor(private http: HttpClient) {}

  saveAll(curveRanges: CurveRange[]) {
    return this.http.post(BACKEND_URL + '/curve-range/file', { curveRanges });
  }
}
