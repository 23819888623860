import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss'],
})
export class InstructionsComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() background: string;

  constructor() {}
}
