<h1 mat-dialog-title>This test is not available</h1>
<div mat-dialog-content>
  <p>The system that you are trying to log into is under construction.</p>
  <p>Please select "Confirm" to be navigated to the correct link.</p>
  <p>You will be required to log in again</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button cdkFocusInitial (click)="onConfirm()">Confirm</button>
</div>
