import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// components
import { CreateSubscaleDialogComponent } from '../create-subscale-dialog/create-subscale-dialog.component';

// services
import { SubscaleService } from '@app/core/services/subcale.service';

// models
import { Subscale } from '@app/core/models/subscale.model';
import { Question } from '@app/core/models/question.model';
import { Answer } from '@app/core/models/answer.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-subscale-question-answer-create-dialog',
  templateUrl: './subscale-question-answer-create-dialog.component.html',
  styleUrls: ['./subscale-question-answer-create-dialog.component.scss'],
})
export class SubscaleQuestionAnswerCreateDialogComponent implements OnInit {
  subscaleQuestionAnswerDialogForm = new FormGroup({
    order: new FormControl(null),
    text: new FormControl(null),
    weight: new FormControl(null),
    subscaleId: new FormControl(null),
    style: new FormControl(null),
    image: new FormControl(null),
    imageFile: new FormControl(null),
    videoFile: new FormControl(null),
    imageContentType: new FormControl(null),
    video: new FormControl(null),
    videoContentType: new FormControl(null),
  });
  fileName: string;
  subscales: Subscale[];
  filteredSubscales$: Observable<Subscale[]>;
  private subscaleId: number = null;
  isIntegerAnswer: boolean;
  isCardsortAnswer: boolean;

  constructor(
    public dialogRef: MatDialogRef<CreateSubscaleDialogComponent>,
    private subscaleService: SubscaleService,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; question: Question; answer: Answer; subscaleId: number },
  ) {}

  ngOnInit(): void {
    ['SLIDER', 'IMAGE', 'MULTI_HOR', 'PENTAGON', 'MULTI_VERT'].includes(this.data.question.questionType)
      ? (this.isIntegerAnswer = true)
      : (this.isIntegerAnswer = false);
    this.data.question.questionType === 'CARDSORT' ? (this.isCardsortAnswer = true) : (this.isCardsortAnswer = false);
    this.subscaleService.getAllAnswerSubscale().subscribe((subscales: Subscale[]) => {
      this.subscales = subscales;
      this.filteredSubscales$ = this.subscaleQuestionAnswerDialogForm.controls['subscaleId'].valueChanges.pipe(
        startWith(''),
        map((value) => (value ? this._filter(value) : this.subscales.slice())),
      );
      if (this.data.isEdit && this.data.answer && !this.isIntegerAnswer) {
        const subscaleEdit = this.subscales.find((subscale) => {
          if (subscale.id === this.data.answer.subscaleId) {
            this.subscaleId = subscale.id;
            return subscale;
          }
        });
        if (subscaleEdit) {
          this.subscaleQuestionAnswerDialogForm.get('subscaleId').setValue(subscaleEdit.title);
        }
      } else {
        let filteredSubscale = this.subscales.find((f) => f.id == this.data.subscaleId);
        if (filteredSubscale) {
          this.subscaleQuestionAnswerDialogForm.get('subscaleId').setValue(filteredSubscale.title);
          this.onChange(filteredSubscale);
        }
      }
    });
    if (this.data.isEdit && this.data.answer) {
      this.subscaleQuestionAnswerDialogForm.get('order').setValue(this.data.answer.order);
      this.subscaleQuestionAnswerDialogForm.get('text').setValue(this.data.answer.text);
      this.subscaleQuestionAnswerDialogForm.get('order').setValue(this.data.answer.order);
      this.subscaleQuestionAnswerDialogForm.get('style').setValue(this.data.answer.style);
      this.subscaleQuestionAnswerDialogForm.get('image').setValue(this.data.answer.image);
      this.subscaleQuestionAnswerDialogForm.get('imageContentType').setValue(this.data.answer.imageContentType);
      this.subscaleQuestionAnswerDialogForm.get('video').setValue(this.data.answer.video);
      this.subscaleQuestionAnswerDialogForm.get('videoContentType').setValue(this.data.answer.videoContentType);
      this.subscaleQuestionAnswerDialogForm.get('weight').setValue(this.data.answer.weight);
      if (!this.isIntegerAnswer) {
        this.subscaleQuestionAnswerDialogForm.get('subscaleId').setValue(this.data.subscaleId);
      }
    }
    this.subscaleQuestionAnswerDialogForm.controls['text'].setValidators(
      ['IMAGE', 'VIDEO'].includes(this.data.question.questionType) ? null : [Validators.required],
    );
    this.subscaleQuestionAnswerDialogForm.controls['subscaleId'].setValidators(
      ['CARDSORT'].includes(this.data.question.questionType) ? [Validators.required] : null,
    );
    this.subscaleQuestionAnswerDialogForm.controls['text'].updateValueAndValidity();
    this.subscaleQuestionAnswerDialogForm.controls['subscaleId'].updateValueAndValidity();
  }

  onSave(): void {
    this.dialogRef.close({ event: 'save', data: this.getAnswer() });
  }

  getAnswer(): Answer {
    const answer: Answer = this.subscaleQuestionAnswerDialogForm.value;
    answer.weight =
      this.subscaleQuestionAnswerDialogForm.value.weight !== null
        ? this.subscaleQuestionAnswerDialogForm.value.weight
        : null;
    answer.oldSubscaleId = this.data.answer?.subscaleId ? this.data.answer.subscaleId : null;
    if (!this.isIntegerAnswer) {
      answer.subscaleId = this.subscaleQuestionAnswerDialogForm.value.subscaleId.length > 0 ? this.subscaleId : null;
    }

    return answer;
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  handleFile(fileType: string, $event: any) {
    const file = $event.target.files[0];
    this.fileName = file.name;
    const reader = new FileReader();
    fileType === 'image'
      ? this.subscaleQuestionAnswerDialogForm.get('imageContentType').setValue(file.type)
      : this.subscaleQuestionAnswerDialogForm.get('videoContentType').setValue(file.type);
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      fileType === 'image'
        ? this.subscaleQuestionAnswerDialogForm.get('image').setValue(event.target.result)
        : this.subscaleQuestionAnswerDialogForm.get('video').setValue(event.target.result);
    };
  }

  private _filter(value: string): Subscale[] {
    const filterValue = value.toLowerCase();

    return this.subscales.filter((subscale) => subscale.title.toLowerCase().includes(filterValue));
  }

  onChange(subscale: Subscale) {
    this.subscaleId = subscale.id;
  }
}
