import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// components
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { EditAnswerComponent } from './edit-answer/edit-answer.component';

// services
import { AnswerService } from '@app/core/services/answer.service';
import { QuestionService } from '@app/core/services/question.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';

// models
import { Question } from '@app/core/models/question.model';
import { TextAnswer } from '@app/core/models/text-answer.model';
import { Answer } from '@app/core/models/answer.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-answer-list-text',
  templateUrl: './answer-list-text.component.html',
  styleUrls: ['./answer-list-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerListTextComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() question: Question;
  @Input() connectionIndicatorId: number;
  @Input() roleMandateId: number;
  @Input() answering: boolean;
  @Input() companyId: number;
  @Input() userInfoId: number;
  @Input() showResults: boolean;
  @Input() currentPage: number;
  @Input() lastQuestionNumber: number;

  @Output() answerSaved = new EventEmitter<any>();
  @Output() relatedAnswersWithAnsweringCardsort = new EventEmitter<{
    currentPage: number;
    relatedAnswersNumber: number;
    lastRelatedQuestionNumber: number;
  }>();

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  // TODO: Refact to use formArray
  answerTextAreaControl = new FormControl('');

  textAreaAnswers: TextAnswer[] = [];
  dataSource: MatTableDataSource<TextAnswer> = new MatTableDataSource<TextAnswer>();
  textRelatedAnswers: Map<number, MatTableDataSource<TextAnswer>> = new Map();
  displayedReviewTextAnswerColumns: string[];
  relatedAnswers: Answer[] = [];
  private activeRequestsSubs: Subscription;
  private loader: boolean;
  private loaderListenerValue: boolean;
  answerPrivate: boolean;
  answerSavedTimer: any;
  clickAdd = false;
  relatedAnswersFormValue: { hasValue: boolean; value: string }[] = [];
  relatedQuestionNumber: number;

  constructor(
    private questionService: QuestionService,
    private answerService: AnswerService,
    private changeDetectorRef: ChangeDetectorRef,
    private rMService: RoleMandateService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.displayedReviewTextAnswerColumns = this.answering ? ['answer', 'actions'] : ['answer'];

    if (this.question.acl === 5 && !this.question.relatedQuestionId) {
      this.displayedReviewTextAnswerColumns.push('private');
    }

    if (this.showResults) {
      if (this.roleMandateId && this.userInfoId) {
        if (!this.question.relatedQuestionId) {
          this.refreshData(
            this.question.userAnswer.listTextAnswer.textAreaAnswers,
            this.question.userAnswer.listTextAnswer.relatedAnswers,
            this.question.answers,
          );
        } else {
          this.relatedAnswers = this.question.userAnswer.relatedAnswers;
          if (this.question.userAnswer.listTextAnswer.textAreaAnswers) {
            this.question.userAnswer.listTextAnswer.relatedAnswers.forEach((element) => {
              const source = new MatTableDataSource(
                this.question.userAnswer.listTextAnswer.textAreaAnswers.filter(
                  (answer) => answer.relatedAnswerId === element.id,
                ),
              );
              source.paginator = this.paginator.toArray()[0];
              this.textRelatedAnswers.set(element.id, source);
            });
          }
        }
      }
    } else {
      if (this.question.relatedQuestionId) {
        if (this.question.relatedQuestionNumber) {
          this.relatedQuestionNumber = this.question.relatedQuestionNumber;
          if (this.relatedQuestionNumber) {
            this.getLoader();
          }
        } else {
          this.rMService.getQuestionRoleMandate().subscribe((question) => {
            if (question.id === this.question.id) {
              this.relatedQuestionNumber = question.relatedQuestionNumber;
              if (this.question.relatedQuestionNumber) {
                this.getLoader();
              }
            }
          });
        }
      } else {
        if (this.question.questionNumber) {
          this.getLoader();
        } else {
          this.rMService.getQuestionRoleMandate().subscribe((question) => {
            if (question.id === this.question.id) {
              question.questionNumber = question.questionNumber;
              if (this.question.questionNumber) {
                this.getLoader();
              }
            }
          });
        }
      }
    }
  }

  ngOnChanges(): void {}

  getLoader() {
    this.loaderListenerValue = this.rMService.getLoaderListenerValue();
    this.activeRequestsSubs = this.rMService.getLoaderListener().subscribe((loader) => {
      if (!loader && !this.loader) {
        this.loadData();
      }
    });
    if (!this.loader && !this.loaderListenerValue) {
      this.loadData();
    }
  }

  refreshDataSource() {
    if (this.textAreaAnswers.length > 0) {
      this.textAreaAnswers.unshift(this.textAreaAnswers[this.textAreaAnswers.length - 1]);
      this.textAreaAnswers.pop();
    }
    this.dataSource.data = this.textAreaAnswers;
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.changeDetectorRef.detectChanges();
  }

  togglePrivacy(answer: TextAnswer) {
    answer.private = !!answer.private;
    answer.private = !answer.private;

    this.updateAnswer(this.question, answer);
  }

  saveTextAreaAnswer() {
    this.clickAdd = true;
    const textAreaAnswer = {
      questionId: this.question.id,
      answer: this.answerTextAreaControl.value,
      userInfoId: this.userInfoId,
      private: this.question.acl === 9 ? true : this.answerPrivate,
    };

    this.answerService.saveTextArea(this.question.id, textAreaAnswer).subscribe((result) => {
      textAreaAnswer['answerId'] = result.id;
      this.textAreaAnswers = this.textAreaAnswers.concat(textAreaAnswer);

      this.updateAnswerSaved(result.id);
      this.refreshDataSource();
      this.answerTextAreaControl.reset();
    });
  }

  saveTextAreaAnswerWithRelated(relatedAnswer: Answer) {
    this.clickAdd = true;
    const textAreaAnswer = {
      questionId: this.question.id,
      answer: this.relatedAnswersFormValue[relatedAnswer.id].value,
      userInfoId: this.userInfoId,
      relatedAnswerId: relatedAnswer.id,
    };
    this.answerService.saveTextArea(this.question.id, textAreaAnswer).subscribe((result) => {
      textAreaAnswer['answerId'] = result.id;

      if (this.textRelatedAnswers.size > 0) {
        let tmp = this.textRelatedAnswers.get(relatedAnswer.id);
        if (!tmp) {
          tmp = new MatTableDataSource([textAreaAnswer]);
        } else {
          tmp.data = tmp.data.concat(textAreaAnswer);
        }
        this.textRelatedAnswers.set(relatedAnswer.id, tmp);
      } else {
        const netTableSource = new MatTableDataSource([textAreaAnswer]);
        netTableSource.paginator = this.paginator.toArray()[0];
        this.textRelatedAnswers.set(relatedAnswer.id, netTableSource);
      }

      this.updateAnswerSaved(result.id);
      this.answerTextAreaControl.reset();
      this.relatedAnswersFormValue.map((relatedAnswerFormValue) => {
        relatedAnswerFormValue.hasValue = false;
        relatedAnswerFormValue.value = null;
        return relatedAnswerFormValue;
      });
      this.dataSource.paginator?.lastPage();
      this.changeDetectorRef.detectChanges();
    });
  }

  onDelete(textAnswer: TextAnswer) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.questionService.deleteTextAnswer(textAnswer.answerId).subscribe((res) => {
          if (res) {
            const index = this.textAreaAnswers.map((e) => e.answerId).indexOf(textAnswer.answerId);
            this.textAreaAnswers = this.textAreaAnswers.filter((item) => item.answerId !== textAnswer.answerId);

            for (const item of this.textRelatedAnswers.keys()) {
              const tmp = this.textRelatedAnswers.get(item);
              tmp.data = tmp.data.filter((i) => i.answerId !== textAnswer.answerId);
              this.textRelatedAnswers.set(item, tmp);
            }

            this.refreshDataSource();
          }
        });
      }
    });
  }

  updateAnswer(question: Question, updatedtextAreaAnswer: TextAnswer) {
    this.answerService.updateTextArea(this.question.id, updatedtextAreaAnswer).subscribe((res) => {
      if (res) {
        this.textAreaAnswers = this.textAreaAnswers.map((answer) =>
          answer.answerId === updatedtextAreaAnswer.answerId ? updatedtextAreaAnswer : answer,
        );

        for (const item of this.textRelatedAnswers.keys()) {
          const temp = this.textRelatedAnswers
            .get(item)
            .data.map((answer) =>
              answer.answerId === updatedtextAreaAnswer.answerId ? updatedtextAreaAnswer : answer,
            );
          this.textRelatedAnswers = this.textRelatedAnswers.set(item, new MatTableDataSource(temp));
        }
        this.refreshDataSource();
      }
    });
  }

  onEdit(textAnswer: TextAnswer) {
    const editDialog = this.dialog.open(EditAnswerComponent, {
      data: { answer: textAnswer },
    });

    editDialog.afterClosed().subscribe((result) => {
      if (typeof result === 'string') {
        const updatedtextAreaAnswer: TextAnswer = {
          answerId: textAnswer.answerId,
          questionId: this.question.id,
          answer: result,
          userInfoId: this.userInfoId,
          relatedAnswerId: textAnswer.relatedAnswerId,
          private: textAnswer.private,
        };
        this.updateAnswer(this.question, updatedtextAreaAnswer);
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.refreshDataSource();
  }

  ngOnDestroy(): void {
    if (this.activeRequestsSubs) {
      this.activeRequestsSubs.unsubscribe();
    }
  }

  updateAnswerSaved(answerId) {
    this.answerSaved.emit({
      questionId: this.question.id,
      answerId,
      saved: true,
    });
  }

  updateAnswerNotSaved(event) {
    clearTimeout(this.answerSavedTimer);
    this.answerSavedTimer = setTimeout(() => {
      if (event.target.value && !this.clickAdd) {
        this.answerSaved.emit({
          questionId: this.question.id,
          answerId: null,
          saved: false,
        });
      }
    }, 2000);
  }

  loadData() {
    this.loader = true;
    forkJoin([
      this.questionService.findAllTextAnswerByQuestionIdAndUserId(
        this.question.id,
        this.userInfoId,
        this.roleMandateId,
        this.connectionIndicatorId,
      ),
      this.questionService.findAnswerCardsortByQuestionIdAndUserId(
        this.question.relatedQuestionId,
        this.userInfoId,
        this.roleMandateId,
        this.connectionIndicatorId,
      ),
      this.questionService.findAllAnswersByQuestionId(this.question.relatedQuestionId),
    ]).subscribe((results) => {
      this.refreshData(results[0], results[1], results[2]);
    });
  }

  refreshData(result0: TextAnswer[], result1: Answer[], result2: any[]) {
    let relatedAnswerQuestionNumber =
      this.lastQuestionNumber + 1 ? this.lastQuestionNumber + 1 : this.relatedQuestionNumber;
    this.textAreaAnswers = result0 ? result0 : [];
    if (result0) {
      this.refreshDataSource();
    }

    if (result1) {
      this.relatedAnswers = result1;

      if (result0) {
        result1.forEach((element) => {
          const source = new MatTableDataSource(result0.filter((answer) => answer.relatedAnswerId === element.id));
          source.paginator = this.paginator.toArray()[0];
          this.textRelatedAnswers.set(element.id, source);
        });
      }
    } else {
      this.relatedAnswers = result2;
    }

    this.relatedAnswers.forEach((rA: Answer, index) => {
      this.relatedAnswersFormValue[rA.id] = { hasValue: false, value: null };
      rA.questionNumber = relatedAnswerQuestionNumber++;

      if (index === this.relatedAnswers.length - 1) {
        if (this.relatedAnswers && this.relatedAnswers.length > 0) {
          this.relatedAnswersWithAnsweringCardsort.emit({
            currentPage: this.currentPage,
            relatedAnswersNumber: this.relatedAnswers.length,
            lastRelatedQuestionNumber: this.relatedAnswers[this.relatedAnswers.length - 1].questionNumber,
          });
        }
      }
    });

    this.refreshDataSource();
  }

  onInput(value: string, relatedAnswerId: number) {
    if (value) {
      this.relatedAnswersFormValue[relatedAnswerId] = { hasValue: true, value: value };
    } else {
      this.relatedAnswersFormValue[relatedAnswerId] = { hasValue: false, value: null };
    }
  }
}
