import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// components
import { CreateSubscaleDialogComponent } from '../create-subscale-dialog/create-subscale-dialog.component';

// services
import { QuestionGroupService } from '@app/core/services/question-group.service';

// models
import { QuestionGroup } from '@app/core/models/question-group.model';
import { Question } from '@app/core/models/question.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-create-subscale-question-dialog',
  templateUrl: './create-subscale-question-dialog.component.html',
  styleUrls: ['./create-subscale-question-dialog.component.scss'],
})
export class CreateSubscaleQuestionDialogComponent implements OnInit {
  isEdit: boolean;
  subscaleQuestionDialogForm = new FormGroup({
    text: new FormControl('', [Validators.required]),
    questionGroupId: new FormControl(''),
    questionType: new FormControl('', [Validators.required]),
    instructions: new FormControl(''),
    aorder: new FormControl(null),
    numberAnswersAllowed: new FormControl(null),
    numOfCards: new FormControl(null),
    numOnGarbage: new FormControl(null),
    image: new FormControl(null),
    imageFile: new FormControl(null),
    videoFile: new FormControl(null),
    imageContentType: new FormControl(null),
    video: new FormControl(null),
    videoContentType: new FormControl(null),
    active: new FormControl(true, [Validators.required]),
    showDiscarded: new FormControl(true),
    randomizeCards: new FormControl(null),
  });
  fileName: string;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '130px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };
  questionGroups$: Observable<QuestionGroup[]>;
  constructor(
    public dialogRef: MatDialogRef<CreateSubscaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; question: Question },
    private questionGroupService: QuestionGroupService,
  ) {}

  ngOnInit(): void {
    this.questionGroups$ = this.questionGroupService.getAll();
    this.isEdit = this.data.isEdit;
    if (this.isEdit) {
      this.subscaleQuestionDialogForm.get('text').setValue(this.data.question.text);
      this.subscaleQuestionDialogForm.get('questionGroupId').setValue(this.data.question.questionGroupId);
      this.subscaleQuestionDialogForm.get('questionType').setValue(this.data.question.questionType);
      this.subscaleQuestionDialogForm.get('instructions').setValue(this.data.question.instructions);
      this.subscaleQuestionDialogForm.get('aorder').setValue(this.data.question.aorder);
      this.subscaleQuestionDialogForm.get('numberAnswersAllowed').setValue(this.data.question.numberAnswersAllowed);
      this.subscaleQuestionDialogForm.get('numOfCards').setValue(this.data.question.numOfCards);
      this.subscaleQuestionDialogForm.get('numOnGarbage').setValue(this.data.question.numOnGarbage);
      this.subscaleQuestionDialogForm.get('image').setValue(this.data.question.image);
      this.subscaleQuestionDialogForm.get('imageContentType').setValue(this.data.question.imageContentType);
      this.subscaleQuestionDialogForm.get('video').setValue(this.data.question.video);
      this.subscaleQuestionDialogForm.get('videoContentType').setValue(this.data.question.videoContentType);
      this.subscaleQuestionDialogForm.get('active').setValue(this.data.question.active);
      this.subscaleQuestionDialogForm.get('showDiscarded').setValue(this.data.question.showDiscarded);
      this.subscaleQuestionDialogForm.get('randomizeCards').setValue(this.data.question.randomizeCards);
    }
  }

  compareFn(x: any, y: any): boolean {
    return parseInt(x, 10) === y ? true : false;
  }

  onSave(): void {
    this.dialogRef.close({ event: 'save', data: this.subscaleQuestionDialogForm.value });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  handleFile(fileType: string, $event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    this.fileName = file.name;
    const reader = new FileReader();
    fileType === 'image'
      ? this.subscaleQuestionDialogForm.get('imageContentType').setValue(file.type)
      : this.subscaleQuestionDialogForm.get('videoContentType').setValue(file.type);
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      fileType === 'image'
        ? this.subscaleQuestionDialogForm.get('image').setValue(event.target.result)
        : this.subscaleQuestionDialogForm.get('video').setValue(event.target.result);
    };
  }
}
