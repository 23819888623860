import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { PsyTestGroup } from '../models/psy-test-group.model';
import { PsyTestGroupUser } from '../models/psy-test-group-user.model';
import { PsyTestGroupReport } from '../models/psy-test-group-report.model';
import { UserInfo } from '../models/userInfo.model';
import { Report } from '../models/report.model';
import { ReportModel } from '../models/report-model.model';
import { Subscale } from '../models/subscale.model';
import { PsyTestGroupTestScale } from '../models/psy-test-group-test-scale.model';
import { TestGroupScorecardTrigger } from '../models/test-group-scorecard-trigger.model';
import { PsyTestGroupTestResult } from '@app/core/models/psy-test-group-test-result.model';
import { Subject } from 'rxjs';
import { GenericLink } from '@app/core/models/generic-link.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TestGroupService {
  public reportModelUpdate$ = new Subject();
  private allTestGroupUserListener = new Subject<any[]>();
  constructor(private http: HttpClient) {}

  getAllCreateAt(start, end) {
    return this.http.get<PsyTestGroup[]>(BACKEND_URL + '/test-group/from/' + start + '/to/' + end);
  }

  getAllByCompanyIdCreateAt(companyId: number, start, end) {
    return this.http.get<PsyTestGroup[]>(
      BACKEND_URL + '/company/' + companyId + '/test-group/from/' + start + '/to/' + end,
    );
  }

  getAllByCompanyId(companyId: number) {
    return this.http.get<PsyTestGroup[]>(BACKEND_URL + '/company/' + companyId + '/test-group');
  }

  getUsersById(testGroupId: number) {
    return this.http.get<UserInfo[]>(BACKEND_URL + '/test-group/' + testGroupId + '/user');
  }

  getAllReportById(testGroupId: number) {
    return this.http.get<Report[]>(BACKEND_URL + '/test-group/' + testGroupId + '/report');
  }

  getAllSuperscaleById(testGroupId: number) {
    return this.http.get<any>(BACKEND_URL + '/test-group/' + testGroupId + '/superscale');
  }

  save(psyTestGroup: PsyTestGroup) {
    return this.http.post(BACKEND_URL + '/test-group', psyTestGroup).pipe(take(1));
  }

  getAll() {
    return this.http.get<PsyTestGroup[]>(BACKEND_URL + '/test-group').pipe(take(1));
  }

  delete(testGroupId: number) {
    return this.http.delete(BACKEND_URL + '/test-group/' + testGroupId);
  }

  deleteTestGroupUser(testGroupUserId: number) {
    return this.http.delete(BACKEND_URL + '/test-group/psy-test-group-user/' + testGroupUserId);
  }

  get(testGroupId: number) {
    return this.http.get<PsyTestGroup>(BACKEND_URL + '/test-group/' + testGroupId);
  }

  getTestGroupUserById(testGroupId: number) {
    return this.http.get<PsyTestGroupUser[]>(BACKEND_URL + '/test-group/' + testGroupId + '/psy-test-group-user');
  }

  getTestGroupUserByTestGroupUserId(testGroupUserId: number) {
    return this.http.get<PsyTestGroupUser>(BACKEND_URL + '/test-group/psy-test-group-user/' + testGroupUserId);
  }

  saveTestGroupUser(testGroupId: number, userInfoId: number) {
    return this.http
      .post(`${BACKEND_URL}/test-group/${testGroupId}/psy-test-group-user`, {
        userInfoId,
      })
      .pipe(take(1));
  }

  updateTestGroupUser(testGroupUserId: number, userType: string) {
    return this.http.patch(BACKEND_URL + '/test-group/psy-test-group-user/' + testGroupUserId, { userType });
  }

  getCompletedPsyTestsByUserInfoIdAndId(userInfoId: number, testGroupId: number) {
    return this.http.get<any[]>(
      BACKEND_URL + '/test-group/' + testGroupId + '/user/' + userInfoId + '/completed-psy-test/',
    );
  }

  getCompletedSupertestsByUserInfoIdAndId(userInfoId: number, testGroupId: number) {
    return this.http.get<any[]>(
      BACKEND_URL + '/test-group/' + testGroupId + '/user/' + userInfoId + '/completed-supertest',
    );
  }

  getPsyTestsById(testGroupId: number) {
    return this.http.get<any[]>(BACKEND_URL + '/test-group/' + testGroupId + '/psy-test');
  }

  getSupertestsById(testGroupId: number) {
    return this.http.get<any[]>(BACKEND_URL + '/test-group/' + testGroupId + '/supertest');
  }

  updatePsyTestGroup(testGroupId: number, testGroup: PsyTestGroup) {
    return this.http.patch(BACKEND_URL + '/test-group/' + testGroupId, testGroup);
  }

  updateStatusByPsyTestGroupTestId(status: boolean, psyTestGroupTestId: number) {
    return this.http.patch(BACKEND_URL + '/test-group/test-group-test/' + psyTestGroupTestId + '/available', {
      available: status,
    });
  }

  updateStatusByPsyTestGroupSupertestId(status: boolean, psyTestGroupSupertestId: number) {
    return this.http.patch(BACKEND_URL + '/test-group/test-group-supertest/' + psyTestGroupSupertestId + '/available', {
      available: status,
    });
  }

  deleteTestByPsyTestGroupTestId(psyTestGroupTestId: number) {
    return this.http.delete(BACKEND_URL + '/test-group/test-group-test/' + psyTestGroupTestId);
  }

  deleteSuperTestByPsyTestGroupSupertestId(psyTestGroupSupertestId: number) {
    return this.http.delete(BACKEND_URL + '/test-group/test-group-supertest/' + psyTestGroupSupertestId);
  }

  archiveTestByPsyTestGroupTestId(psyTestGroupTestId: number) {
    return this.http.delete(BACKEND_URL + '/test-group/test-group-test/' + psyTestGroupTestId + '/archive');
  }

  archiveSuperTestByPsyTestGroupSupertestId(psyTestGroupSupertestId: number) {
    return this.http.delete(BACKEND_URL + '/test-group/test-group-supertest/' + psyTestGroupSupertestId + '/archive');
  }

  addSupertestToTestGroup(testGroupId: number, psyOptionItemId: number) {
    return this.http.post(BACKEND_URL + '/test-group/' + testGroupId + '/psy-test-group-supertest', {
      supertestId: psyOptionItemId,
    });
  }

  addTestToTestGroup(testGroupId: number, psyOptionItemId: number) {
    return this.http.post(BACKEND_URL + '/test-group/' + testGroupId + '/psy-test-group-test', {
      testId: psyOptionItemId,
    });
  }

  addReportToTestGroup(testGroupId: number, psyOptionItemId: number) {
    return this.http.post<PsyTestGroupReport>(BACKEND_URL + '/test-group/' + testGroupId + '/psy-test-group-report', {
      reportId: psyOptionItemId,
    });
  }

  addReportModelToTestGroup(testGroupId: number, psyOptionItemId: number) {
    return this.http.post<PsyTestGroupReport>(
      BACKEND_URL + '/test-group/' + testGroupId + '/psy-test-group-report-model',
      {
        reportModelId: psyOptionItemId,
      },
    );
  }

  getAnswerTestUsersByTestId(testGroupId: number, psyTestId: number) {
    return this.http.get<PsyTestGroupUser[]>(
      BACKEND_URL + '/test-group/' + testGroupId + '/psy-test-group-answer-test-users/' + psyTestId,
    );
  }

  addChartToTestGroup(testGroupId: number, chartId: number) {
    const url = `${BACKEND_URL}/test-group/${testGroupId}/chart`;
    const body = { chartId };

    return this.http.post(url, body);
  }

  removeChartFromTestGroup(testGroupId: number, chartId: number) {
    const url = `${BACKEND_URL}/test-group/${testGroupId}/chart/${chartId}`;

    return this.http.delete(url);
  }

  removeReportFromTestGroup(testGroupId: number, reportId: number) {
    const url = `${BACKEND_URL}/test-group/${testGroupId}/report/${reportId}`;

    return this.http.delete(url);
  }

  removeReportModelFromTestGroup(testGroupId: number, reportModelId: number) {
    const url = `${BACKEND_URL}/test-group/${testGroupId}/report-model/${reportModelId}`;

    return this.http.delete(url);
  }

  removeSuperscaleFromTestGroup(testGroupId: number, testGroupSuperscaleId: number) {
    const url = `${BACKEND_URL}/test-group/${testGroupId}/test-group-superscale/${testGroupSuperscaleId}`;

    return this.http.delete(url);
  }

  addSuperscaleToTestGroup(testGroupId: number, superscaleId: number) {
    return this.http.post<PsyTestGroupReport>(BACKEND_URL + '/test-group/' + testGroupId + '/test-group-superscale', {
      superscaleId: superscaleId,
    });
  }

  getReportModelByTestGroupId(testGroupId: number) {
    return this.http.get<ReportModel>(BACKEND_URL + '/test-group/' + testGroupId + '/report-model');
  }

  getSubscalesByTestGroupIdAndTestId(testGroupId: number, psyTestId: number) {
    return this.http.get<Subscale[]>(
      BACKEND_URL + '/test-group/' + testGroupId + '/psy-test/' + psyTestId + '/subscale',
    );
  }

  saveScoreCardTrigger(testGroupId: number, value: boolean) {
    return this.http.post<TestGroupScorecardTrigger>(
      BACKEND_URL + '/test-group/' + testGroupId + '/scorecard-trigger',
      {
        showScoreCard: value,
      },
    );
  }

  updateScoreCardTrigger(testGroupId: number, value: boolean) {
    return this.http.patch<TestGroupScorecardTrigger>(
      BACKEND_URL + '/test-group/' + testGroupId + '/scorecard-trigger',
      {
        showScoreCard: value,
      },
    );
  }

  updateTestScaleVisible(testGroupId: number, index: number, value: boolean) {
    return this.http.patch<any>(BACKEND_URL + '/test-group/' + testGroupId + '/test-scale-visible', {
      psyTestGroupTestScaleId: index,
      visible: value,
    });
  }

  getScoreCardTrigger(testGroupId: number) {
    return this.http
      .get<{ id: number; showScoreCard: any }>(BACKEND_URL + '/test-group/' + testGroupId + '/scorecard-trigger')
      .pipe(
        map((response) => {
          if (response) {
            let showScoreCard: boolean;
            if (response.showScoreCard.hasOwnProperty('data')) {
              showScoreCard = response.showScoreCard.data[0] === 0 ? false : true;
            } else {
              showScoreCard = response.showScoreCard;
            }
            return {
              id: response.id,
              showScoreCard,
            };
          } else {
            return {
              id: null,
              showScoreCard: true,
            };
          }
        }),
      );
  }

  getTestGroupSuperscaleRanges(testGroupId: number) {
    return this.http.get<PsyTestGroupTestScale[]>(BACKEND_URL + '/test-group/' + testGroupId + '/superscale-ranges');
  }

  saveTestGroupSuperscaleRanges(testGroupId: number, value: PsyTestGroupTestScale[]) {
    return this.http.post<PsyTestGroupTestScale[]>(BACKEND_URL + '/test-group/' + testGroupId + '/superscale-ranges', {
      testGroupSuperscales: value,
    });
  }

  getTestGroupSubscaleRanges(testGroupId: number, testId: number) {
    return this.http.get<PsyTestGroupTestScale[]>(
      BACKEND_URL + '/test-group/' + testGroupId + '/test/' + testId + '/subscale-ranges',
    );
  }

  saveTestGroupSubscaleRanges(testGroupId: number, testId: number, value: PsyTestGroupTestScale[]) {
    return this.http.post<PsyTestGroupTestScale[]>(
      BACKEND_URL + '/test-group/' + testGroupId + '/test/' + testId + '/subscale-ranges',
      {
        testGroupSubscales: value,
      },
    );
  }

  getTestGroupUsersByUserInfoId(userInfoId: number) {
    return this.http.get<PsyTestGroupUser[]>(BACKEND_URL + '/test-group/psy-test-group-users/' + userInfoId);
  }

  getTestGroupTestResults(testGroupId: number) {
    return this.http.get<PsyTestGroupTestResult[]>(BACKEND_URL + '/test-group/' + testGroupId + '/test-result');
  }

  getTestResultDataDownload(
    testGroupId: number,
    testId: number,
    userInfoId: number,
    testResultId: number,
    type: string,
    dateFrom: Date,
    dateTo: Date,
  ) {
    return this.http.get<{ fileName: string; fileMimeType: string; fileBase64Content: string }>(
      BACKEND_URL + '/test-group/' + testGroupId + '/export-test-result',
      {
        params: {
          testGroupId: String(testGroupId),
          testId: String(testId),
          userInfoId: String(userInfoId),
          testResultId: String(testResultId),
          reportType: type,
          dateFrom: String(dateFrom.getTime()),
          dateTo: String(dateTo.getTime()),
        },
      },
    );
  }

  searchTestResult(
    testGroupId: number,
    userName: string,
    userEmail: string,
    dateFrom: Date,
    dateTo: Date,
    testId: string,
    isSuperTest: boolean,
  ) {
    return this.http.get<PsyTestGroupTestResult[]>(BACKEND_URL + '/test-group/' + testGroupId + '/search-test-result', {
      params: {
        testGroupId: String(testGroupId),
        userName: String(userName),
        userEmail: String(userEmail),
        dateFrom: String(dateFrom.getTime()),
        dateTo: String(dateTo.getTime()),
        testId: String(testId),
        isSuperTest: String(isSuperTest),
      },
    });
  }

  updateCompanyLogoEnabled(testGroupId: number, enabled: boolean) {
    return this.http.patch(BACKEND_URL + '/test-group/' + testGroupId + '/logo', { logoEnabled: enabled });
  }

  getTransferredTestGroupsByOriginalTestGroupIdAndCompanyId(testGroupId: number, companyId: number) {
    return this.http.get<PsyTestGroup[]>(
      BACKEND_URL + '/test-group/' + testGroupId + '/transferred-test-group/' + companyId,
    );
  }

  transferTestGroupResults(testGroupId: number, selectedUserList: any[], selectedTestGroupList: any[]) {
    return this.http.patch(BACKEND_URL + '/test-group/' + testGroupId + '/transfer-test-group-results', {
      selectedUserList: selectedUserList,
      selectedTestGroupList: selectedTestGroupList,
    });
  }

  setAllTestGroupUser(users: UserInfo[]) {
    this.allTestGroupUserListener.next(users);
  }

  getAllTestGroupUser() {
    return this.allTestGroupUserListener.asObservable();
  }

  getAllGenericLinkByTestGroupId(testGroupId: number) {
    return this.http.get<GenericLink[]>(`${BACKEND_URL}/test-group/${testGroupId}/generic-link`);
  }

  createGenericLink(genericLink: GenericLink) {
    return this.http.post<GenericLink[]>(
      `${BACKEND_URL}/test-group/${genericLink.testGroupId}/generic-link`,
      genericLink,
    );
  }

  updateGenericLink(genericLink: GenericLink) {
    return this.http.patch<GenericLink[]>(`${BACKEND_URL}/test-group/generic-link/${genericLink.id}`, genericLink);
  }

  deleteGenericLink(genericLinkId: number) {
    return this.http.delete<GenericLink[]>(`${BACKEND_URL}/test-group/generic-link/${genericLinkId}`);
  }
}
