import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Section } from '@app/core/models/section.model';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OptimalScoreScale } from '@app/core/models/optimal-score-scale.model';
import { OptimalScoreScaleRangeText } from '@app/core/models/optimal-score-scale-range-text.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { OptimalScoreService } from '@app/core/services/optimal-score.service';
import { EditOptimalScoreScaleRangeTextComponent } from '@app/shared/components/edit-optimal-score-scale-range-text/edit-optimal-score-scale-range-text.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-optimal-score-section-summary-range-content',
  templateUrl: './optimal-score-section-summary-range-content.component.html',
  styleUrls: ['./optimal-score-section-summary-range-content.component.scss'],
})
export class OptimalScoreSectionSummaryRangeContentComponent implements OnInit {
  optimalScoreScale: OptimalScoreScale;
  title: string;
  addedRanges: OptimalScoreScaleRangeText[] = [];

  editorConfig: AngularEditorConfig = {
    height: '8rem',
    editable: true,
    toolbarHiddenButtons: [
      ['subscript', 'superscript', 'indent', 'outdent', 'insertUnorderedList', 'insertOrderedList'],
      ['link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode'],
    ],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { section: Section; optimalScoreScale: OptimalScoreScale },
    private fb: FormBuilder,
    private optimalScoreService: OptimalScoreService,
    private dialog: MatDialog,
  ) {
    this.optimalScoreScale = this.data.optimalScoreScale;
    this.rangeContentForm = this.fb.group({
      from: ['', [Validators.required]],
      to: ['', [Validators.required]],
      rangeText: ['', [Validators.required]],
    });
    this.title = this.optimalScoreScale['subscaleTitle']
      ? this.optimalScoreScale['subscaleTitle']
      : this.optimalScoreScale['superscaleTitle']
      ? this.optimalScoreScale['superscaleTitle']
      : '';
  }

  rangeContentForm: FormGroup;

  ngOnInit(): void {
    this.loadOptimalScoreScaleRangeText();
  }

  loadOptimalScoreScaleRangeText() {
    this.optimalScoreService
      .findAllOptimalScoreScaleRangeTextByOptimalScoreScaleId(this.optimalScoreScale.id)
      .subscribe((optimalScoreScaleRangeTexts) => {
        if (optimalScoreScaleRangeTexts && optimalScoreScaleRangeTexts.length > 0) {
          this.addedRanges = [...optimalScoreScaleRangeTexts];
        }
      });
  }

  onAddRange() {
    const newRangeText: OptimalScoreScaleRangeText = {
      optimalScoreScaleId: this.data.optimalScoreScale.id,
      from: this.rangeContentForm.get('from').value,
      to: this.rangeContentForm.get('to').value,
      rangeText: this.rangeContentForm.get('rangeText').value,
    };

    this.optimalScoreService
      .createOptimalScoreScaleRangeText(newRangeText)
      .subscribe((newOptimalScoreScaleRangeText) => {
        this.addedRanges.push(newOptimalScoreScaleRangeText);
        this.rangeContentForm.reset();
      });
  }

  onEditRange(range: OptimalScoreScaleRangeText) {
    const data = range;
    this.dialog
      .open(EditOptimalScoreScaleRangeTextComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event === EVENT_SUCCESS) {
          if (result.range) {
            this.optimalScoreService
              .editOptimalScoreScaleRangeText(range.optimalScoreScaleId, range.id, result.range)
              .subscribe((res) => {
                if (res) {
                  this.loadOptimalScoreScaleRangeText();
                }
              });
          }
        }
      });
  }

  onRemoveRange(optimalScoreScaleRangeTextId: number, optimalScoreScaleId: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.optimalScoreService
          .deleteOptimalScoreScaleRangeText(optimalScoreScaleId, optimalScoreScaleRangeTextId)
          .subscribe((res) => {
            if (res) {
              this.addedRanges = this.addedRanges.filter(
                (addedRange) => addedRange.id !== optimalScoreScaleRangeTextId,
              );
            }
          });
      }
    });
  }
}
