import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxPaginationModule } from 'ngx-pagination';

import { SharedModule } from '@app/shared/shared.module';
import { PsyTestRoutingModule } from './test-routing.module';

import { PsyTestListComponent } from './psy-test-list/psy-test-list.component';
import { TestDetailComponent } from './test-detail/test-detail.component';
import { CreateNewTestDialogComponent } from './create-new-test-dialog/create-new-test-dialog.component';
import { TestSubscaleListComponent } from './test-subscale-list/test-subscale-list.component';
import { TestPreviewComponent } from './test-preview/test-preview.component';

@NgModule({
  declarations: [
    PsyTestListComponent,
    TestDetailComponent,
    CreateNewTestDialogComponent,
    TestSubscaleListComponent,
    TestPreviewComponent,
  ],
  imports: [
    CommonModule,
    PsyTestRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    NgxPaginationModule,
    SharedModule,
  ],
  exports: [PsyTestListComponent, TestDetailComponent, CreateNewTestDialogComponent, TestPreviewComponent],
})
export class PsyTestModule {}
