import { Component } from '@angular/core';

@Component({
  selector: 'app-connections-indicator-search',
  templateUrl: './connections-indicator-search.component.html',
  styleUrls: ['./connections-indicator-search.component.scss'],
})
export class ConnectionsIndicatorSearchComponent {
  constructor() {}
}
