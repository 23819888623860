import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// components
import { SubsectionFormComponent } from '@app/core/models/subsection-form-component';
import { EditRangeDialogComponent } from '../../report-wizard/edit-range-dialog/edit-range-dialog.component';

// services
import { SubscaleService } from '@app/core/services/subcale.service';
import { SuperscaleService } from '@app/core/services/superscale.service';

// models
import { Subscale } from '@app/core/models/subscale.model';
import { Superscale } from '@app/core/models/superscale.model';
import { Sentence } from '@app/core/models/sentence.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-section-bullet-point-form',
  templateUrl: './section-bullet-point-form.component.html',
  styleUrls: ['./section-bullet-point-form.component.scss'],
})
export class SectionBulletPointFormComponent implements OnInit, SubsectionFormComponent {
  @Input() model: any;

  editorConfig: AngularEditorConfig = {
    height: '8rem',
    editable: true,
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  form: FormGroup;

  addedBullets: {
    useSubscale: boolean;
    ranges: {
      from: number;
      to: number;
      reportText: {
        he: string;
        she: string;
        they: string;
      };
    }[];
    superscales: Superscale[];
    subscales: (Subscale & { weight?: number })[];
  }[] = [];
  superscales: Superscale[] = [];
  subscales: Subscale[] = [];
  subscaleFieldUpdate = new Subject<string>();
  subscaleField: string;
  superscaleFieldUpdate = new Subject<string>();
  superscaleField: string;

  constructor(
    private fb: FormBuilder,
    private subscaleService: SubscaleService,
    private superscaleService: SuperscaleService,
    private dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      leadSentenceOption: ['he', [Validators.required]],
      leadSentenceBody: this.genReportTextFormGroup(),
      bullets: this.fb.array([]),
      heading: ['', [Validators.required]],
      positiveHeading: [''],
      concernHeading: [''],
      defaultSentence: [''],
      defaultSentenceEnabled: [false],
    });

    this.subscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.subscaleField = value;
      }
    });

    this.superscaleFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.superscaleField = value;
      }
    });
  }

  ngOnInit(): void {
    if (this.model) {
      this.loadFormData();
    }

    forkJoin({
      superscales: this.superscaleService.getAll(),
      subscales: this.subscaleService.getAll(),
    }).subscribe(({ subscales, superscales }) => {
      this.superscales = superscales;
      this.subscales = subscales.map((subscale) => {
        if (subscale.cardsortSubscale === 1) {
          return { ...subscale, title: `${subscale.title} (card-sort)` };
        }
        return subscale;
      });
    });
  }

  genReportTextFormGroup() {
    return this.fb.group({
      he: ['', [Validators.required]],
      she: [''],
      they: [''],
    });
  }

  genSentenceFormGroup(sentence?: Sentence) {
    return this.fb.group({
      useSubscale: [sentence ? sentence.useSubscale : true, [Validators.required]],
      range: this.genSentenceRangeFormGroup(),
      superscale: this.fb.group({
        id: [sentence && sentence.superscales?.length ? sentence.superscales[0].id : '', [Validators.required]],
      }),
      subscale: this.fb.group({
        id: [sentence && sentence.subscales?.length ? sentence.subscales[0].id : '', [Validators.required]],
        weight: [sentence && sentence.subscales?.length ? sentence.subscales[0].weight : '', [Validators.required]],
      }),
    });
  }

  genSentenceRangeFormGroup() {
    return this.fb.group({
      currentOption: ['he', [Validators.required]],
      from: [0, [Validators.required]],
      to: [1, [Validators.required]],
      reportText: this.genReportTextFormGroup(),
    });
  }

  onAddNewSentence(sentence?: Sentence) {
    this.bulletsFormArray.push(this.genSentenceFormGroup(sentence));
    this.addedBullets.push({
      useSubscale: sentence ? sentence.useSubscale : true,
      ranges: sentence && sentence.ranges ? sentence.ranges : [],
      superscales: sentence && sentence.superscales?.length ? sentence.superscales : [],
      subscales: sentence && sentence.subscales?.length ? sentence.subscales : [],
    });

    if (this.bulletsFormArray.length === 1) {
      return;
    }

    this.bulletsFormArray.controls.forEach((control, idx) => {
      const { value } = control;
      this.addedBullets[idx] = {
        ...this.addedBullets[idx],
        useSubscale: value.useSubscale,
      };
    });
  }

  get bulletsFormArray() {
    return this.form.get('bullets') as FormArray;
  }

  getBulletScales(i: number) {
    return (this.bulletsFormArray.get('scales') as FormArray).at(i) as FormGroup;
  }

  getUseSubscale(i: number) {
    return this.bulletsFormArray.at(i).get('useSubscale').value;
  }

  getbulletsuperscales(i: number) {
    return this.bulletsFormArray.at(i).get('superscales') as FormArray;
  }

  getbulletsubscales(i: number) {
    return this.bulletsFormArray.at(i).get('subscales') as FormArray;
  }

  displaySubscale(subscale: Subscale): string {
    return subscale ? subscale.title : '';
  }

  displaySuperscale(superscale: Superscale): string {
    return superscale ? superscale.title : '';
  }

  onAddSubscale(i: number) {
    const subscalesFormGroup = this.bulletsFormArray.at(i).get('subscale') as FormGroup;
    const { id: subscaleId } = subscalesFormGroup.value.id;
    const subscaleToAdd = this.subscales.find((subscale) => subscale.id === subscaleId);
    this.addedBullets[i].subscales.push({
      ...subscaleToAdd,
      weight: subscalesFormGroup.value.weight,
    });

    subscalesFormGroup.reset();
  }

  onAddSuperscale(i: number) {
    const superscalesFormGroup = this.bulletsFormArray.at(i).get('superscale') as FormGroup;
    const { id: superscaleId } = superscalesFormGroup.value.id;
    const superscaleToAdd = this.superscales.find((superscale) => superscale.id === superscaleId);

    this.addedBullets[i].superscales.push(superscaleToAdd);

    superscalesFormGroup.reset();
  }

  onAddRange(i: number) {
    const rangeFormGroup = this.bulletsFormArray.at(i).get('range') as FormGroup;
    const { from, to, reportText } = rangeFormGroup.value;

    this.addedBullets[i].ranges.push({ from, to, reportText });
    this.resetRangeFormGroup(rangeFormGroup);
  }

  onDeleteBullet(i: number) {
    this.addedBullets.splice(i, 1);
    this.bulletsFormArray.controls.splice(i, 1);
  }

  resetRangeFormGroup(rangeFormGroup: FormGroup) {
    rangeFormGroup.patchValue({
      currentOption: 'he',
      from: Number(rangeFormGroup.value.to),
      to: Number(rangeFormGroup.value.to) + 1,
      reportText: {
        he: '',
        she: '',
        they: '',
      },
    });
  }

  getSubsection() {
    const { value } = this.form;
    return {
      id: this.model?.id,
      heading: value.heading,
      positiveHeading: value.positiveHeading,
      concernHeading: value.concernHeading,
      leadSentence: {
        he: value.leadSentenceBody.he,
        she: value.leadSentenceBody.she,
        they: value.leadSentenceBody.they,
      },
      defaultSentence: value.defaultSentence,
      defaultSentenceEnabled: value.defaultSentenceEnabled,
      sentences: this.bulletsFormArray.controls.map((control, idx) => ({
        useSubscale: !!control.value.useSubscale,
        ranges: this.addedBullets[idx].ranges.map((range) => ({
          from: range.from,
          to: range.to,
          text: {
            he: range.reportText.he,
            she: range.reportText.she,
            they: range.reportText.they,
          },
        })),
        subscales:
          control.value.useSubscale &&
          this.addedBullets[idx].subscales.map((sub) => ({ id: sub.id, weight: sub.weight })),
        superscales:
          !control.value.useSubscale && this.addedBullets[idx].superscales.map((superscale) => ({ id: superscale.id })),
      })),
    };
  }

  onRemoveSubscale(sentenceIdx: number, subscaleId: number) {
    this.addedBullets[sentenceIdx].subscales = this.addedBullets[sentenceIdx].subscales.filter(
      (subscale) => subscale.id !== subscaleId,
    );
  }

  onRemoveSuperscale(sentenceIdx: number, superscaleId: number) {
    this.addedBullets[sentenceIdx].superscales = this.addedBullets[sentenceIdx].superscales.filter(
      (superscale) => superscale.id !== superscaleId,
    );
  }

  onRemoveRange(sentenceIdx: number, rangeIdx: number) {
    this.addedBullets[sentenceIdx].ranges = this.addedBullets[sentenceIdx].ranges.filter((_, idx) => idx !== rangeIdx);
  }

  private loadFormData(): void {
    this.form.get('heading').setValue(this.model.heading);
    this.form.get('positiveHeading').setValue(this.model.positiveHeading);
    this.form.get('concernHeading').setValue(this.model.concernHeading);
    this.form.get('defaultSentence').setValue(this.model.defaultSentence);
    this.form.get('defaultSentenceEnabled').setValue(this.model.defaultSentenceEnabled === 0 ? false : true);
    const modelLeadSentence = this.model.leadSentence;
    Object.keys(modelLeadSentence).forEach((pronoun: string) => {
      if (pronoun !== 'id' && modelLeadSentence[pronoun]) {
        modelLeadSentence[pronoun].length > 0
          ? this.form.get(`leadSentenceOption`).setValue(pronoun)
          : this.form.get(`leadSentenceOption`).setValue('he');
        this.form.get(`leadSentenceBody.${pronoun}`).setValue(modelLeadSentence[pronoun]);
      }
    });
    this.model.sentences.filter((sentence) => {
      sentence.ranges.map((range) => (range.reportText = range.text));
      this.onAddNewSentence(sentence);
    });
  }

  onEditRange(i: number, j: number) {
    const data = this.addedBullets[i].ranges[j];
    this.dialog
      .open(EditRangeDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event === EVENT_SUCCESS) {
          if (result.range) {
            this.addedBullets[i].ranges[j] = result.range;
          }
        }
      });
  }
}
