import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class ScorePercentileService {
  constructor(private http: HttpClient, private router: Router) {}

  delete(id: number) {
    return this.http.delete(`${BACKEND_URL}/subscale/score-percentile/${id}`);
  }
}
