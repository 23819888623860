<div class="container">
  <p *ngIf="psyTest">{{ psyTest.title }}</p>
  <div
    *ngIf="
      (psyTest && allObj && testResultId && finishGettingCardSortAnswers) ||
      (superTest && allObj && superTestResultId && finishGettingCardSortAnswers)
    "
  >
    <div
      *ngFor="let obj of allObj | paginate: { itemsPerPage: psyTest.paging, currentPage: p, totalItems: allObj.length }"
    >
      <mat-card
        *ngIf="
          testObjectService.isQuestion(obj) && (obj.questionType === 'TEXT_AREA' || obj.questionType === 'RICH_TEXT')
        "
      >
        <app-answer-text-area
          [question]="obj"
          [psyTestId]="psyTestId"
          [superTestId]="superTestId"
          [answering]="false"
          [testResultId]="testResultId"
        ></app-answer-text-area>
      </mat-card>
      <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'SLIDER'">
        <app-answer-slider
          [question]="obj"
          [psyTestId]="psyTestId"
          [superTestId]="superTestId"
          [userInfoId]="userInfoId"
          [answering]="false"
          [testResultId]="testResultId"
        ></app-answer-slider>
      </mat-card>
      <mat-card
        *ngIf="
          (testObjectService.isQuestion(obj) && obj.questionType === 'MULTI_VERT') ||
          (testObjectService.isQuestion(obj) && obj.questionType === 'IMAGE')
        "
      >
        <app-answer-multiple-vertical-choice
          [question]="obj"
          [psyTestId]="psyTestId"
          [superTestId]="superTestId"
          [userInfoId]="userInfoId"
          [answering]="false"
          [testResultId]="testResultId"
        ></app-answer-multiple-vertical-choice>
      </mat-card>
      <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'MULTI_HOR'">
        <app-answer-multiple-horizontal-choice
          [question]="obj"
          [psyTestId]="psyTestId"
          [superTestId]="superTestId"
          [userInfoId]="userInfoId"
          [answering]="false"
          [testResultId]="testResultId"
        ></app-answer-multiple-horizontal-choice>
      </mat-card>
      <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'CARDSORT'">
        <app-answer-cardsort
          [question]="obj"
          [psyTestId]="psyTestId"
          [superTestId]="superTestId"
          [userInfoId]="userInfoId"
          [loadAnswer]="false"
          [answering]="false"
          [testResultId]="testResultId"
        ></app-answer-cardsort>
      </mat-card>
      <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'PENTAGON'">
        <app-answer-pentagon
          [question]="obj"
          [psyTestId]="psyTestId"
          [superTestId]="superTestId"
          [answering]="false"
          [userInfoId]="userInfoId"
          [testResultId]="testResultId"
        ></app-answer-pentagon>
      </mat-card>
      <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'TEXT_SINGLE_LINE'">
        <app-answer-text-single-line
          [question]="obj"
          [psyTestId]="psyTestId"
          [superTestId]="superTestId"
          [answering]="false"
          [userInfoId]="userInfoId"
          [testResultId]="testResultId"
        ></app-answer-text-single-line>
      </mat-card>

      <mat-card *ngIf="testObjectService.isQuestionsGroup(obj)" class="question-group">
        <p *ngIf="obj.instructions" [innerHTML]="obj.instructions"></p>
        <div *ngFor="let question of obj.questions" class="question">
          <div *ngIf="question.questionType === 'TEXT_AREA' || question.questionType === 'RICH_TEXT'">
            <app-answer-text-area
              [question]="question"
              [answering]="false"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [userInfoId]="userInfoId"
              [testResultId]="testResultId"
            ></app-answer-text-area>
          </div>
          <div *ngIf="question.questionType === 'SLIDER'">
            <app-answer-slider
              [question]="question"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [userInfoId]="userInfoId"
              [answering]="false"
              [testResultId]="testResultId"
            ></app-answer-slider>
          </div>
          <div *ngIf="question.questionType === 'MULTI_VERT' || question.questionType === 'IMAGE'">
            <app-answer-multiple-vertical-choice
              [question]="question"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [userInfoId]="userInfoId"
              [answering]="false"
              [testResultId]="testResultId"
            ></app-answer-multiple-vertical-choice>
          </div>
          <div *ngIf="question.questionType === 'MULTI_HOR' || question.questionType === 'IMAGE'">
            <app-answer-multiple-horizontal-choice
              [question]="question"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [userInfoId]="userInfoId"
              [answering]="false"
              [testResultId]="testResultId"
            ></app-answer-multiple-horizontal-choice>
          </div>
          <div *ngIf="question.questionType === 'CARDSORT'">
            <app-answer-cardsort
              [question]="question"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [userInfoId]="userInfoId"
              [answering]="false"
              [testResultId]="testResultId"
            ></app-answer-cardsort>
          </div>
          <div *ngIf="question.questionType === 'PENTAGON'">
            <app-answer-pentagon
              [question]="question"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [answering]="false"
              [userInfoId]="userInfoId"
              [testResultId]="testResultId"
            ></app-answer-pentagon>
          </div>
          <div *ngIf="question.questionType === 'TEXT_SINGLE_LINE'">
            <app-answer-text-single-line
              [question]="question"
              [psyTestId]="psyTestId"
              [superTestId]="superTestId"
              [answering]="false"
              [userInfoId]="userInfoId"
              [testResultId]="testResultId"
            ></app-answer-text-single-line>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="row">
      <pagination-controls class="pagination" (pageChange)="p = $event; pageChanged($event)"></pagination-controls>
    </div>
  </div>
</div>
