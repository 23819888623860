import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { CreateNewChartDialogComponent } from '../create-new-chart-dialog/create-new-chart-dialog.component';

// services
import { ChartService } from '@app/core/services/chart.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  chartPsbItems: PsbItem[] = [];

  constructor(private chartService: ChartService, public router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.chartService.getAll().subscribe((charts) => {
      this.chartPsbItems = charts.map((chart) => ({
        id: chart.id,
        title: chart.name,
        description: chart.header,
      }));
    });
  }

  onDashboard(chartId: number) {
    this.router.navigate(['/chart', chartId]);
  }

  onCreateNewChart() {
    this.dialog
      .open(CreateNewChartDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event !== EVENT_SUCCESS) {
          return;
        }

        const { newChart } = result;
        this.chartPsbItems = [
          ...this.chartPsbItems,
          { id: newChart.id, title: newChart.name, description: newChart.header },
        ];
      });
  }

  onDelete(chartId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.chartService.delete(chartId).subscribe(() => {
          this.chartPsbItems = this.chartPsbItems.filter((item) => item.id !== chartId);
        });
      });
  }
}
