<ng-progress #progressBar></ng-progress>

<table class="mat-table versioning">
  <tr class="mat-header-row">
    <th class="mat-header-cell">Created At</th>
    <th class="mat-header-cell">Status</th>
    <th class="mat-header-cell">
      <div class="buttons">
        <button mat-raised-button color="primary" class="edit" (click)="create()">
          <mat-icon class="material-icons">add</mat-icon>
          Generate Report
        </button>
      </div>
    </th>
  </tr>
  <tr class="mat-row" *ngFor="let report of reports">
    <td class="mat-cell">{{ report.createdAt | date: 'medium' }}</td>
    <td class="mat-cell">
      {{ report.generateStatus === 'done' ? 'Done' : '' }}
      {{ report.generateStatus === 'processing' ? 'Processing...' : '' }}
      {{ report.generateStatus === 'error' ? 'Error' : '' }}
    </td>
    <td class="mat-cell">
      <div class="buttons">
        <a *ngIf="report.generateStatus === 'done'" mat-button [href]="report.link" target="_blank">
          <mat-icon class="material-icons">edit</mat-icon>
          Edit
        </a>
        <button *ngIf="report.generateStatus === 'done'" mat-button (click)="download(report)">
          <mat-icon class="material-icons">download</mat-icon>
          Download PDF
        </button>
        <button mat-button (click)="delete(report)">
          <mat-icon class="material-icons">delete</mat-icon>
          Delete
        </button>
      </div>
    </td>
  </tr>
</table>
