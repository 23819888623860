<h1>Test Groups</h1>

<button *ngIf="canAdd" mat-raised-button color="primary" (click)="onAddTestGroup()">
  <mat-icon>add</mat-icon>
  Add Test Group
</button>

<app-psb-table-search
  [data]="testGroupsDataSource"
  (filter)="searchTestGroups($event)"
  placeholder="Search for Test Groups"
>
</app-psb-table-search>

<div class="mat-elevation-z3">
  <mat-table [dataSource]="testGroupsDataSource">
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.title }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.description }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.status }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell mat-header-cell class="action" *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button class="btn-view test-group-button" (click)="onView(element.id)">View</button>
        <button mat-button class="btn-error test-group-button btn-delete" (click)="onDelete(element.id)">Delete</button>
      </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedTestGroupsColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedTestGroupsColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
