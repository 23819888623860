<svg viewBox="0 0 400 400" style="width: auto; max-height: 400px">
  <g style="pointer-events: all">
    <path
      number="1"
      style="stroke: none"
      [style.fill]="selectedAreaNumber == 1 ? '#ccc' : '#425465'"
      d="m 198.91219,200.00804 -49.90463,-86.38006 h 99.80927 z"
    />

    <path
      number="2"
      style="stroke: none"
      [style.fill]="selectedAreaNumber == 2 ? '#ccc' : '#425465'"
      d="M 182.27698,113.62798 H 149.00756 L 99.10292,27.24881 h 66.53985 z"
    />

    <path
      number="3"
      style="stroke: #394e61"
      [style.fill]="selectedAreaNumber == 3 ? '#ccc' : '#425465'"
      d="M 215.54741,113.62798 H 182.27698 L 165.64277,27.24881 h 66.53885 z"
    />

    <path
      number="4"
      style="fill: none; stroke: none"
      [style.fill]="selectedAreaNumber == 4 ? '#ccc' : '#425465'"
      d="m 248.81683,113.62798 h -33.26942 l 16.63421,-86.37917 h 66.53984 z"
    />

    <path
      number="5"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 5 ? '#ccc' : '#425465'"
      d="m 198.91219,200.00804 49.90464,-86.38006 49.90463,86.38006 z"
    />

    <path
      number="6"
      style="fill: #425465; stroke: none"
      [style.fill]="selectedAreaNumber == 6 ? '#ccc' : '#425465'"
      d="m 265.45204,142.42166 -16.63521,-28.79368 49.90463,-86.37917 33.26942,57.58638 z"
    />
    <path
      number="7"
      style="stroke: #394e61"
      [style.fill]="selectedAreaNumber == 7 ? '#ccc' : '#425465'"
      d="m 282.08626,171.21436 -16.63422,-28.7927 66.53884,-57.58647 33.27042,57.58647 z"
    />
    <path
      number="8"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 8 ? '#ccc' : '#425465'"
      d="m 298.72146,200.00804 -16.6352,-28.79368 83.17504,-28.7927 33.76849,58.0851 z"
    />
    <path
      number="9"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 9 ? '#ccc' : '#425465'"
      d="m 198.91219,200.00804 h 99.80927 l -49.90463,86.38008 -49.90464,-86.38008"
    />
    <path
      number="10"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 10 ? '#ccc' : '#425465'"
      d="m 282.08626,228.80173 16.6352,-28.79369 100.30833,0.49872 -33.76849,57.08766 z"
    />
    <path
      number="11"
      style="stroke: #394e61"
      [style.fill]="selectedAreaNumber == 11 ? '#ccc' : '#425465'"
      d="m 265.45204,257.59442 16.63422,-28.79269 83.17504,28.79269 -33.27042,57.58639 z"
    />
    <path
      number="12"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 12 ? '#ccc' : '#425465'"
      d="m 248.81683,286.38812 16.63521,-28.7937 66.53884,57.58639 -33.26942,57.58639 z"
    />
    <path
      number="13"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 13 ? '#ccc' : '#425465'"
      d="m 248.81683,286.38812 h -99.80927 l 49.90463,-86.38008 z"
    />
    <path
      number="14"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 14 ? '#ccc' : '#425465'"
      d="m 215.54741,286.38812 h 33.26942 l 49.90463,86.37908 h -66.53984 z"
    />
    <path
      number="15"
      style="stroke: #394e61"
      [style.fill]="selectedAreaNumber == 15 ? '#ccc' : '#425465'"
      d="m 182.27698,286.38812 h 33.27043 l 16.63421,86.37908 h -66.53885 z"
    />
    <path
      number="16"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 16 ? '#ccc' : '#425465'"
      d="m 149.00756,286.38812 h 33.26942 L 165.64277,372.7672 H 99.10292 Z"
    />
    <path
      number="17"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 17 ? '#ccc' : '#425465'"
      d="m 198.91219,200.00804 -49.90463,86.38008 -49.90464,-86.38008 h 99.80927"
    />
    <path
      number="18"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 18 ? '#ccc' : '#425465'"
      d="m 132.37235,257.59442 16.63521,28.7937 -49.90464,86.37908 -33.26973,-57.58639 z"
    />
    <path
      number="19"
      style="stroke: #394e61"
      [style.fill]="selectedAreaNumber == 19 ? '#ccc' : '#425465'"
      d="m 115.73813,228.80173 16.63422,28.79269 -66.53916,57.58639 -33.26982,-57.58639 z"
    />
    <path
      number="20"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 20 ? '#ccc' : '#425465'"
      d="m 99.10292,200.00804 16.63521,28.79369 -83.17476,28.79269 -32.77068,-57.08766 z"
    />
    <path
      number="21"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 21 ? '#ccc' : '#425465'"
      d="M 198.91219,200.00804 H 99.10292 l 49.90464,-86.38006 z"
    />
    <path
      number="22"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 22 ? '#ccc' : '#425465'"
      d="M 99.10292,200.00804 H -0.70636 l 33.26973,-57.58638 83.17476,28.7927 z"
    />
    <path
      number="23"
      style="stroke: #394e61"
      [style.fill]="selectedAreaNumber == 23 ? '#ccc' : '#425465'"
      d="m 132.37235,142.42166 -16.63422,28.7927 -83.17476,-28.7927 33.26982,-57.58647 z"
    />
    <path
      number="24"
      style="fill: none; stroke: none; stroke-width: 0.26458332px; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 24 ? '#ccc' : '#425465'"
      d="M 149.00756,113.62798 132.37235,142.42166 65.83319,84.83519 99.10292,27.24881 Z"
    />
    <path
      number="25"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 25 ? '#ccc' : '#425465'"
      d="M -0.20731,200.50676 99.10292,27.24881"
    />
    <path
      number="26"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 26 ? '#ccc' : '#425465'"
      d="M 99.10292,27.24881 H 298.72146"
    />
    <path
      number="27"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 27 ? '#ccc' : '#425465'"
      d="m 298.72146,27.24881 99.74236,173.25095"
    />
    <path
      number="28"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 28 ? '#ccc' : '#425465'"
      d="M 398.46382,200.49976 298.72146,372.7672"
    />
    <path
      number="29"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 29 ? '#ccc' : '#425465'"
      d="M 298.72146,372.7672 H 99.10292"
    />
    <path
      number="30"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 30 ? '#ccc' : '#425465'"
      d="M 99.10292,372.7672 -0.20731,200.50676"
    />
    <path
      number="31"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 31 ? '#ccc' : '#425465'"
      d="m 99.10292,200.00804 49.77901,-86.05701"
    />
    <path
      number="32"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 32 ? '#ccc' : '#425465'"
      d="m 248.81683,113.62798 49.694,86.75385"
    />
    <path
      number="33"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 33 ? '#ccc' : '#425465'"
      d="m 248.81683,286.38812 -99.80927,8e-5"
    />
    <path
      number="34"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 34 ? '#ccc' : '#425465'"
      d="m 149.00756,286.38812 -50.4706,-86.387"
    />
    <path
      number="35"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 35 ? '#ccc' : '#425465'"
      d="m 248.81683,286.38812 49.90463,-86.38008"
    />
    <path
      number="36"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 36 ? '#ccc' : '#425465'"
      d="m 148.88193,113.95103 99.9349,-0.32305"
    />
    <path
      number="37"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 37 ? '#ccc' : '#425465'"
      d="M 98.53696,27.24189 298.72145,372.76728"
    />
    <path
      number="38"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 38 ? '#ccc' : '#425465'"
      d="M 298.72146,27.24881 99.10292,372.7672"
    />
    <path
      number="39"
      style="fill: none; stroke: #ccc; stroke-width: 1; stroke-opacity: 1"
      [style.fill]="selectedAreaNumber == 39 ? '#ccc' : '#425465'"
      d="m -0.20731,200.50676 398.67113,-0.007"
    />
  </g>
</svg>
