<div class="container">
  <div class="page-list-header">
    <h1>Subscales</h1>
    <button mat-raised-button class="new-button pull-right" color="primary" (click)="onCreate()">
      <mat-icon aria-hidden="false">add</mat-icon>
      Create a Subscale
    </button>
    <button mat-raised-button color="primary" class="new-button csv">
      <mat-icon>input</mat-icon>
      <input type="file" accept=".csv" (change)="handleSubscaleListCSVFile($event)" class="input-file" />
      Import CSV
    </button>
  </div>

  <app-psb-list
    *ngIf="subscales.length"
    [items]="subscales"
    [itemsPerPage]="10"
    (view)="onView($event)"
    (delete)="onDelete($event)"
    (copyEvent)="onCopy($event)"
    (archive)="onArchive($event)"
  >
  </app-psb-list>
</div>
