import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericLink } from '@app/core/models/generic-link.model';
import { EVENT_CANCEL } from '@app/core/constants';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { ClipboardService } from 'ngx-clipboard';
type ActionType = 'create' | 'update' | 'view';

@Component({
  selector: 'app-create-or-edit-generic-link-dialog',
  templateUrl: './create-or-edit-generic-link-dialog.component.html',
  styleUrls: ['./create-or-edit-generic-link-dialog.component.scss'],
})
export class CreateOrEditGenericLinkDialogComponent implements OnInit {
  action: ActionType;
  genericLink: GenericLink;
  environment = environment;

  genericLinkForm = new FormGroup({
    expirationDate: new FormControl(''),
    enabled: new FormControl(true),
    autoSendPdf: new FormControl(false),
  });
  constructor(
    public dialogRef: MatDialogRef<CreateOrEditGenericLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: ActionType; genericLink?: GenericLink; testGroupId?: number },
    private datePipe: DatePipe,
    private snackBar: SnackBarService,
    private clipboardApi: ClipboardService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.action = this.data.action;
    this.genericLink = this.data.genericLink;
    if (this.action === 'update' && this.genericLink) {
      this.genericLinkForm.get('expirationDate').setValue(this.genericLink.expirationDate);
      this.genericLinkForm.get('enabled').setValue(this.genericLink.enabled);
      this.genericLinkForm.get('autoSendPdf').setValue(this.genericLink.autoSendPdf);
    }
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onSave() {
    let formattedExpirationDate = '';
    if (this.genericLinkForm.get('expirationDate').value) {
      formattedExpirationDate = this.datePipe.transform(
        this.genericLinkForm.get('expirationDate').value,
        'yyyy-MM-dd HH:mm:ss',
      );

      if (this.genericLink) {
        this.genericLink.expirationDate = formattedExpirationDate;
      }
    }
    if (this.action === 'create') {
      this.genericLink = {
        testGroupId: this.data.testGroupId,
        expirationDate: formattedExpirationDate ? formattedExpirationDate : null,
        enabled: this.genericLinkForm.get('enabled').value,
        autoSendPdf: this.genericLinkForm.get('autoSendPdf').value,
      };
    } else if (this.action === 'update') {
      this.genericLink.enabled = this.genericLinkForm.get('enabled').value;
      this.genericLink.autoSendPdf = this.genericLinkForm.get('autoSendPdf').value;
      this.genericLink.expirationDate = formattedExpirationDate ? formattedExpirationDate : null;
    }
    console.log(this.genericLink);
    this.dialogRef.close({ event: this.action === 'update' ? 'update' : 'create', data: this.genericLink });
  }

  onCopy() {
    this.clipboardApi.copyFromContent(`${environment.url}/invite/${this.genericLink.token}`);
    this.snackBar.info('Generic Link has been copied successfully');
  }
}
