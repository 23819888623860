<div class="container">
  <p>Drag and drop the questions according to the order you want them sorted.</p>
  <div
    *ngIf="questions"
    class="drop-list"
    cdkDropList
    [cdkDropListData]="questions"
    (cdkDropListDropped)="drop($event)"
  >
    <div *ngFor="let question of questions; let i = index" class="question" cdkDrag [cdkDragData]="question">
      <div class="test-custom-placeholder" *cdkDragPlaceholder></div>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header class="right-aligned-header">
            <mat-panel-title>
              <h3>{{ i + 1 }}) {{ question.text | stripeHtml | truncate }}</h3>
            </mat-panel-title>
            <mat-panel-description class="right-aligned-description">
              <mat-chip>{{ question.questionType }}</mat-chip>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngIf="question.questionType === 'CARDSORT'">
            <app-answer-cardsort [psyTestId]="psyTest.id" [question]="question" [answering]="false" [isPreview]="true">
            </app-answer-cardsort>
          </div>
          <div *ngIf="question.questionType === 'MULTI_HOR'">
            <app-answer-multiple-horizontal-choice
              [question]="question"
              [psyTestId]="this.psyTest.id"
              [loadAnswer]="false"
              [answering]="false"
              [isPreview]="true"
            ></app-answer-multiple-horizontal-choice>
          </div>
          <div *ngIf="question.questionType === 'MULTI_VERT' || question.questionType === 'IMAGE'">
            <app-answer-multiple-vertical-choice
              [question]="question"
              [psyTestId]="this.psyTest.id"
              [loadAnswer]="false"
              [answering]="false"
              [isPreview]="true"
            ></app-answer-multiple-vertical-choice>
          </div>
          <div *ngIf="question.questionType === 'SLIDER'">
            <app-answer-slider
              [question]="question"
              [psyTestId]="psyTest.id"
              [loadAnswer]="false"
              [answering]="false"
              [isPreview]="true"
            ></app-answer-slider>
          </div>
          <div *ngIf="question.questionType === 'TEXT_AREA' || question.questionType === 'RICH_TEXT'">
            <app-answer-text-area
              [question]="question"
              [psyTestId]="psyTest.id"
              [loadAnswer]="false"
              [answering]="false"
              [isPreview]="true"
            ></app-answer-text-area>
          </div>
          <div *ngIf="question.questionType === 'PENTAGON'">
            <app-answer-pentagon
              [question]="question"
              [psyTestId]="psyTest.id"
              [loadAnswer]="false"
              [answering]="false"
              [isPreview]="true"
            ></app-answer-pentagon>
          </div>
          <div *ngIf="question.questionType === 'PENTAGON'">
            <app-answer-text-single-line
              [question]="question"
              [psyTestId]="psyTest.id"
              [loadAnswer]="false"
              [answering]="false"
              [isPreview]="true"
            ></app-answer-text-single-line>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
