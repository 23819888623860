import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

// services
import { AnswerService } from '@app/core/services/answer.service';
import { QuestionService } from '@app/core/services/question.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { QuestionGroup } from '@app/core/models/question-group.model';
import { Answer } from '@app/core/models/answer.model';
import { Question } from '@app/core/models/question.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
  @Input() question: Question;
  @Input() questions: Question[];
  @Input() questionGroups: QuestionGroup[];
  @Input() answers: Answer[];
  @Input() showAcl = false;
  @Output() delete = new EventEmitter<number>();
  @Output() edit = new EventEmitter<Question>();
  questionType: string;
  cardsortQuestions: Question[];
  relatedQuestionText: string;
  editing = false;
  privacyMsgs = [
    { id: 5, msg: 'Allow users to set answers as private' },
    { id: 9, msg: 'This question is private' },
  ];
  editQuestionForm = new FormGroup({
    text: new FormControl('', [Validators.required]),
    weight: new FormControl(0),
    questionType: new FormControl('', [Validators.required]),
    questionGroupId: new FormControl(''),
    relatedQuestionId: new FormControl(''),
    showDiscarded: new FormControl(0),
    networkType: new FormControl('', [Validators.required]),
  });

  constructor(
    private questionService: QuestionService,
    private answerService: AnswerService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    switch (this.question.questionType) {
      case 'CARDSORT':
        this.questionType = 'Cardsort';
        break;
      case 'TEXT_AREA':
        this.questionType = 'Text';
        break;
      case 'USER_CONNECTION':
        this.questionType = 'User Connection';
        break;
      case 'LIST_TEXT':
        this.questionType = 'List Text';
        break;
      case 'RICH_TEXT':
        this.questionType = 'Rich Text';
        break;
      default:
        break;
    }

    this.cardsortQuestions = this.questions.filter((question) => question.questionType === 'CARDSORT');

    if (this.question && this.question.relatedQuestionId) {
      const filteredCardsortQuestion = this.cardsortQuestions.filter(
        (cardsortQuestion) => cardsortQuestion.id === this.question.relatedQuestionId,
      );
      if (filteredCardsortQuestion && filteredCardsortQuestion.length > 0) {
        this.relatedQuestionText = filteredCardsortQuestion[0].text;
      }
    }
  }

  onDelete(id: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.componentInstance.message = `Please confirm that you want delete this Question?`;

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.questionService.delete(id).subscribe(() => {
          this.delete.emit(id);
          this.snackBar.info('Question deleted with success!');
        }, () => {
          this.snackBar.info('Something went wrong when deleting the question.');
        });
      }
    });
  }

  onChangePrivacy(event, privacyMsg) {
    this.question.acl = event.checked ? privacyMsg.id : 0;
    return this.question.acl;
  }

  onEditQuestion() {
    this.editing = true;
    this.editQuestionForm.get('text').setValue(this.question.text);
    this.editQuestionForm.get('weight').setValue(this.question.weight);
    this.editQuestionForm.get('questionGroupId').setValue(this.question.questionGroupId);
    this.editQuestionForm.get('questionType').setValue(this.question.questionType);
    this.editQuestionForm.get('relatedQuestionId').setValue(this.question.relatedQuestionId);
    this.editQuestionForm.get('showDiscarded').setValue(this.question.showDiscarded);
    this.editQuestionForm.get('networkType').setValue(this.question.networkType);
  }

  save() {
    this.question.text = this.editQuestionForm.get('text').value;
    this.question.weight = this.editQuestionForm.get('weight').value ? this.editQuestionForm.get('weight').value : 0;
    this.question.questionType = this.editQuestionForm.get('questionType').value;
    this.question.questionGroupId = +this.editQuestionForm.get('questionGroupId').value;
    this.question.questionGroup = this.questionGroups.find(
      (questionGroup) => questionGroup.id === this.question.questionGroupId,
    );
    this.question.relatedQuestionId = this.editQuestionForm.get('relatedQuestionId').value;
    this.question.showDiscarded = this.editQuestionForm.get('showDiscarded').value;
    this.question.networkType = this.editQuestionForm.get('networkType').value;
    this.question.answers = this.question.answers;

    if (!this.question.text && !this.question.questionType) {
      return;
    }

    this.questionService.update(this.question).subscribe((result) => {
      if (this.question.answers) {
        this.answerService.updateAll(this.question.id, this.question.answers).subscribe((res) => {
          this.edit.emit(this.question);
          this.editQuestionForm.reset();
          this.editing = false;
        });
      } else {
        this.edit.emit(this.question);
        this.editQuestionForm.reset();
        this.editing = false;
      }
    });
  }

  cancel() {
    this.editQuestionForm.reset();
    this.editing = false;
  }

  onAddAnswer(answer: Answer) {
    if (!this.question.answers) {
      this.question.answers = [];
    }
    this.question.answers.push(answer);
  }

  onEditAnswer(answer: Answer) {
    if (answer.id) {
      const pos = this.question.answers.map((ans) => ans.id).indexOf(answer.id);
      this.question.answers[pos] = answer;
    }
  }

  onDeleteAnswer(answer: Answer) {
    if (answer.id) {
      this.answerService.delete(answer.id).subscribe((res) => {
        this.question.answers = this.question.answers.filter((ans) => ans.id !== answer.id);
      });
    } else {
      this.question.answers = this.question.answers.filter((ans) => ans.text !== answer.text);
    }
  }

  getQuestionPrivacyByACLId(acl: number) {
    switch (acl) {
      case 5:
        return 'Allow users to set answers as private';
      case 9:
        return 'This question is private';
    }
  }

  compareFn(x: any, y: any) {
    return x === parseInt(y, 10) ? true : false;
  }
}
