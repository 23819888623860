import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// services
import { UserService } from '@app/core/services/user.service';

// models
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-test-connections-indicator-user-info',
  templateUrl: './test-connections-indicator-user-info.component.html',
  styleUrls: ['./test-connections-indicator-user-info.component.scss'],
})
export class TestConnectionsIndicatorUserInfoComponent implements OnInit {
  userInfoId: number;
  connectionsIndicatorId: number;
  userInfoData: UserInfo;

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.connectionsIndicatorId = this.route.snapshot.params['id'];
    this.userInfoId = parseInt(localStorage.getItem('userInfoId'), 10);
    this.userService.get(this.userInfoId).subscribe((result) => {
      this.userInfoData = result;
    });
  }

  onNext() {
    this.router.navigate([`/user/connections-indicator/${this.connectionsIndicatorId}/answer`]);
  }
}
