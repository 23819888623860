import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserInvite } from '@app/core/models/user-invite';
import { InviteService } from '@app/core/services/invite.service';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { EMAIL_TYPES } from '@app/shared/constants/emailTypes';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { PsyTestGroupUser } from '@app/core/models/psy-test-group-user.model';
import { Invite } from '@app/core/models/invite.model';

@Component({
  selector: 'app-test-group-email',
  templateUrl: './test-group-email.component.html',
  styleUrls: ['./test-group-email.component.scss'],
})
export class TestGroupEmailComponent implements OnInit {
  @Input() testGroupId?: number;
  @Input() companyId?: number;
  @Input() testGroup: PsyTestGroup;
  @Input() testGroupUsers: PsyTestGroupUser[];
  @Input() invites: Invite[];

  @ViewChild('selectAllRef', { static: false }) selectAllRef: ElementRef;
  displayedColumns: string[] = ['select', 'firstName', 'lastName', 'email', 'status', 'isNew'];
  displayedLogColumns: string[] = ['toEmail', 'sentAt', 'emailType', 'actions'];

  userInvites = new MatTableDataSource<UserInvite>();
  selection = new SelectionModel<UserInvite>(true, []);
  filter = new FormControl('');
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('sortUsers') sortUsers: MatSort;

  panelOpenState = false;
  selectedUserList: any[] = [];
  emailTypes = EMAIL_TYPES;
  filteredEmailTypes: any[] = [];
  public options: { id: number; label: string; checked: boolean }[] = [
    { id: 0, label: 'All invited users', checked: false },
    { id: 1, label: 'All registered users', checked: false },
    { id: 2, label: 'All users who started survey', checked: false },
  ];
  public checkIdx = -1;
  showFilterOptions: Boolean = false;
  public currentDisabledType: string = '';
  constructor(private inviteService: InviteService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.showFilterOptions = false;
    this.loadUsers();
  }

  remove(user) {
    if (user) {
      let removeUserData = this.userInvites.data.filter((e) => e.id == user.id)[0];
      this.selection.deselect(removeUserData);
      this.selectedUserList = this.selectedUserList.filter((e) => e.id != removeUserData.id);
      this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
    }
  }

  loadUsers() {
    let userInvites: UserInvite[] = [];

    userInvites = this.invites.map((invite) => {
      const roleMandateInvite: UserInvite = {
        id: invite.id,
        firstName: invite.firstName,
        lastName: invite.lastName,
        email: invite.email,
        isNew: true,
        status: 'INVITED',
        token: invite.token,
      };
      return roleMandateInvite;
    });

    userInvites = [
      ...userInvites,
      ...this.testGroupUsers.map((user) => {
        const roleMandateInvite: UserInvite = {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          isNew: false,
        };

        if (user.status == 0 || user.status === null) {
          roleMandateInvite.status = 'REGISTERED';
        } else if (user.status == 1) {
          roleMandateInvite.status = 'COMPLETED';
        } else {
          roleMandateInvite.status = (user.status * 100).toFixed(0) + '%';
        }

        return roleMandateInvite;
      }),
    ];
    this.userInvites.data = userInvites;
    if (this.userInvites.paginator) {
      this.userInvites.paginator.firstPage();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.userInvites.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.userInvites.data.filter((e) => e.status != 'COMPLETED').length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedUserList.length = 0;
      this.filteredEmailTypes = [];
    } else {
      this.userInvites.data.forEach((row, i) => {
        if (row.status != 'COMPLETED') {
          this.selection.select(row);
          this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row), status: row.status });
        }
      });
    }
    this.filteredEmailTypes = this.emailTypes;
  }

  checkboxLabel(row?: UserInvite): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email}`;
  }

  showLink(element) {
    const contentDialog = this.dialog.open(InfoDialogComponent);
    contentDialog.componentInstance.title = 'Link';
    contentDialog.componentInstance.message = `${environment.url}/invite/${element.token}`;
    contentDialog.componentInstance.isHtml = true;
    contentDialog.componentInstance.copy = true;
  }

  ngAfterViewInit() {
    this.userInvites.paginator = this.paginator.toArray()[0];
    this.userInvites.sort = this.sortUsers;
  }

  onSelectUser(row: any, isChecked: boolean) {
    if (isChecked) {
      if (!this.currentDisabledType) {
        if (row.status === 'INVITED') {
          this.currentDisabledType = 'REGISTERED';
        } else if (row.status === 'REGISTERED') {
          this.currentDisabledType = 'INVITED';
        } else if (row.status.includes('%')) {
          this.currentDisabledType = 'INVITED';
        }
      }
      if (row.status !== this.currentDisabledType) {
        this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row), status: row.status });
        this.selection.toggle(row);
        this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
      }
      this.filter.reset();
    } else {
      this.selectedUserList = this.selectedUserList.filter((e) => e.id != row.id);
      this.selection.deselect(row);
      if (!this.selection.selected.length) {
        this.currentDisabledType = '';
      }
      this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
    }
  }

  getAlreadyCheckedEmailType() {
    let invitedUsers = this.selectedUserList.some((e) => e.status === 'INVITED');
    let registeredUsers = this.selectedUserList.some((e) => e.status === 'REGISTERED' || e.status.includes('%'));
    if (invitedUsers && !registeredUsers) {
      return this.emailTypes.filter((e) => e.id != 1);
    } else if (!invitedUsers && registeredUsers) {
      return this.emailTypes.filter((e) => e.id != 2 && e.id != 3);
    } else if ((invitedUsers && registeredUsers) || (!invitedUsers && !registeredUsers)) {
      return this.emailTypes;
    }
  }

  setAllInviteUsers(e: any) {
    if (e.id == 0 && e.checked) {
      this.checkIdx = 0;
      this.selectedUserList = [];
      this.userInvites.data.forEach((row) => {
        if (row.status == 'INVITED') {
          this.selection.select(row);
          this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row), status: row.status });
        } else if (row.status != 'INVITED') {
          this.selection.deselect(row);
        }
      });
    } else if (e.id == 0 && !e.checked) {
      this.userInvites.data.forEach((row) => {
        this.selection.deselect(row);
      });
      this.selectedUserList = [];
      this.checkIdx = -1;
    }
    this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
  }

  setAllRegisterUsers(e: any) {
    if (e.id == 1 && e.checked) {
      this.checkIdx = 1;
      this.selectedUserList = [];
      this.userInvites.data.forEach((row: any) => {
        if (row.status == 'REGISTERED') {
          this.selection.select(row);
          this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row), status: row.status });
        } else if (row.status != 'REGISTERED') {
          this.selection.deselect(row);
        }
      });
    } else if (e.id == 1 && !e.checked) {
      this.userInvites.data.forEach((row: any) => {
        this.selection.deselect(row);
      });
      this.selectedUserList = [];
      this.checkIdx = -1;
    }
    this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
  }

  setAllSurveyUsers(e: any) {
    if (e.id == 2 && e.checked) {
      this.selectedUserList = [];
      this.checkIdx = 2;
      this.userInvites.data.forEach((row: any) => {
        if (row.status.includes('%')) {
          this.selection.select(row);
          this.selectedUserList.push({ id: row.id, name: this.formatChipSetLabel(row), status: row.status });
        } else if (row.status == 'INVITED' || row.status == 'REGISTERED') {
          this.selection.deselect(row);
        }
      });
    } else if (e.id == 2 && !e.checked) {
      this.userInvites.data.forEach((row: any) => {
        this.selection.deselect(row);
      });
      this.selectedUserList = [];
      this.checkIdx = -1;
    }
    this.filteredEmailTypes = this.getAlreadyCheckedEmailType();
  }

  formatChipSetLabel(row: UserInvite): string {
    if (row.firstName && row.lastName) {
      return `${row.firstName} ${row.lastName}`;
    } else if (row.firstName && (row.lastName == null || row.lastName == 'undefined')) {
      return row.firstName;
    } else {
      return row.email;
    }
  }

  openPopup() {
    let isInvitedChecked: boolean = false;
    let isRegisteredChecked: boolean = false;
    let isStartedSurveyChecked: boolean = false;

    let isInvitedCheckedSelectedList = this.selectedUserList.filter((e) => e.status == 'INVITED').length;
    let isInvitedCheckedUserInviteList = this.userInvites.data.filter((e: any) => e.status == 'INVITED').length;

    if (
      isInvitedCheckedSelectedList != isInvitedCheckedUserInviteList ||
      (isInvitedCheckedSelectedList == 0 && isInvitedCheckedUserInviteList == 0)
    ) {
      isInvitedChecked = false;
    } else {
      isInvitedChecked = true;
    }

    let isRegisteredCheckedSelectedList = this.selectedUserList.filter((e) => e.status == 'REGISTERED').length;
    let isRegisteredCheckedUserInviteList = this.userInvites.data.filter((e: any) => e.status == 'REGISTERED').length;

    if (
      isRegisteredCheckedSelectedList != isRegisteredCheckedUserInviteList ||
      (isRegisteredCheckedSelectedList == 0 && isRegisteredCheckedUserInviteList == 0)
    ) {
      isRegisteredChecked = false;
    } else {
      isRegisteredChecked = true;
    }

    let isStartedSurveySelectedList = this.selectedUserList.filter((e) => e.status.includes('%')).length;
    let isStartedSurveyUserInviteList = this.userInvites.data.filter((e: any) => e.status.includes('%')).length;

    if (
      isStartedSurveySelectedList != isStartedSurveyUserInviteList ||
      (isStartedSurveySelectedList == 0 && isStartedSurveyUserInviteList == 0)
    ) {
      isStartedSurveyChecked = false;
    } else {
      isStartedSurveyChecked = true;
    }

    let bindCheckBoxValue = {
      invitedChecked: isInvitedChecked,
      registeredChecked: isRegisteredChecked,
      startedSurveyChecked: isStartedSurveyChecked,
    };

    if (isInvitedChecked) {
      this.checkIdx = 0;
    } else if (isRegisteredChecked) {
      this.checkIdx = 1;
    } else if (isStartedSurveyChecked) {
      this.checkIdx = 2;
    } else {
      this.checkIdx = -1;
    }
    this.bindCheckBoxSelection(bindCheckBoxValue);
    this.showFilterOptions = !this.showFilterOptions;
  }

  onSelection(i: number, id: number, checked: boolean) {
    this.showFilterOptions = !this.showFilterOptions;
    this.options.map((e) => {
      if (e.id == id && checked) {
        e.checked = checked;
      } else {
        e.checked = false;
      }
    });
    if (!checked) {
      this.currentDisabledType = '';
    }

    let userType = this.options[i];
    if (userType) {
      switch (userType.id) {
        case 0:
          this.setAllInviteUsers(userType);
          if (checked) {
            this.currentDisabledType = 'REGISTERED';
          }
          break;
        case 1:
          this.setAllRegisterUsers(userType);
          if (checked) {
            this.currentDisabledType = 'INVITED';
          }
          break;
        case 2:
          this.setAllSurveyUsers(userType);
          if (checked) {
            this.currentDisabledType = 'INVITED';
          }
          break;
        default:
          break;
      }
      this.isAllSelected();
    }

    this.filter.reset();
  }

  bindCheckBoxSelection(data: any) {
    if (data.invitedChecked) {
      this.options[0].checked = true;
    }
    if (data.registeredChecked) {
      this.options[1].checked = true;
    }
    if (data.startedSurveyChecked) {
      this.options[2].checked = true;
    }
  }
}
