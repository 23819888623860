<div class="pdf-container">
  <div class="pdf-cover">
    <div class="pdf-cover-line"></div>
    <div class="pdf-cover-content">
      <h1 class="pdf-title">Psybil Assessment Report</h1>
      <p class="pdf-generated-for">Generated for:</p>
      <p class="pdf-full-name">{{ userInfo.firstName + ' ' + userInfo.lastName }}</p>
      <p class="pdf-date-label">Date:</p>
      <p class="pdf-date">{{ now }}</p>
      <img class="pdf-logo" [src]="reportModel.signatureLogo" alt="Psybil Logo" />
      <p class="pdf-signature-title">{{ reportModel.signatureTitle }}</p>
      <p class="pdf-signature-text">{{ reportModel.signatureText }}</p>
    </div>
  </div>

  <div class="pdf-page">
    <h2>Table of Contents</h2>
    <div *ngFor="let level2 of tableOfContents">
      <div>
        <a class="pdf-toc-level2" [href]="'#' + level2.h2.id">{{ level2.h2.innerHTML }}</a>

        <div *ngFor="let level3 of level2.children">
          <a class="pdf-toc-level3" [href]="'#' + level3.h3.id">{{ level3.h3.innerHTML }}</a>
        </div>
      </div>
    </div>

    <div>
      <h2>Disclosure</h2>
      <div>
        <p>
          Accurate results are essential to our clients and test-takers. Therefore, Psynet Group®️ has invested years of
          extra effort and expense in developing Psybil®️, including approximately 2,000 hours of volunteer time
          answering questions. For this reason, Psybil®️ is the most reliable and valid assessment battery on the
          market.
        </p>
        <p>
          All psychological assessments have a margin of error. For example, the results may be affected by a test taker
          who was overly stressed or distracted. The nuance of language may be another potential source of error.
          Although we have worked hard to make all test language as clear and concise as possible, test takers may still
          misinterpret an item in rare instances. The test was constructed so that stress, distractions, and
          misinterpretations of language will not invalidate individual results.
        </p>
        <p>
          People change throughout their lifetimes. As psychologists, our core business is changing people. Under
          typical circumstances, Psybil®️ results are valid for three years. However, an individual who actively
          attempts to develop as a person or as a leader (possibly with a coach or therapist) may see change more
          quickly.
        </p>
        <p>
          Finally, whenever organizations use an assessment battery in hiring, promotion, or development decisions, they
          should be considered as a singular data point for comparison with other information sources, including past
          performance, interview responses, and references.
        </p>
        <p>Organizations should never make important personnel decisions based on online test results alone.</p>
      </div>
    </div>
  </div>

  <div id="table-of-contents-parent">
    <app-user-report-pdf></app-user-report-pdf>
  </div>
</div>
