<div class="container">
  <h1 *ngIf="this.roleMandate">{{ this.roleMandate.title }}</h1>
  <app-role *ngIf="userInfoData" [answering]="false" [userInfo]="userInfoData"> </app-role>

  <div *ngIf="allObj">
    <div *ngFor="let obj of allObj">
      <div *ngIf="testObjectService.isQuestionsGroup(obj)" class="question-group">
        <h2>{{ obj.title }}</h2>
        <p *ngIf="obj.instructions" [innerHtml]="obj.instructions"></p>
        <div *ngFor="let question of obj.questions" class="question">
          <div *ngIf="question.questionType === 'CARDSORT'">
            <app-answer-cardsort
              [question]="question"
              [roleMandateId]="roleMandateId"
              [answering]="true"
              [userInfoId]="userInfoId"
            >
            </app-answer-cardsort>
          </div>
          <div *ngIf="question.questionType === 'USER_CONNECTION'">
            <app-answer-user-connection
              [question]="question"
              [companyId]="this.roleMandate.companyId"
              [roleMandateId]="roleMandateId"
              [userInfoId]="userInfoId"
              [answering]="false"
            ></app-answer-user-connection>
          </div>
          <div *ngIf="question.questionType === 'TEXT_AREA' || question.questionType === 'RICH_TEXT'">
            <app-answer-text-area
              [question]="question"
              [companyId]="this.roleMandate.companyId"
              [roleMandateId]="roleMandateId"
              [userInfoId]="userInfoId"
              [answering]="false"
            ></app-answer-text-area>
          </div>
          <div *ngIf="question.questionType === 'LIST_TEXT'">
            <app-answer-list-text
              [question]="question"
              [roleMandateId]="roleMandateId"
              [companyId]="roleMandate.companyId"
              [userInfoId]="userInfoId"
              [answering]="false"
            >
            </app-answer-list-text>
          </div>
        </div>
      </div>

      <div *ngIf="testObjectService.isDecisionTable(obj)">
        <app-answer-decision-table
          [companyId]="this.roleMandate.companyId"
          [roleMandateId]="roleMandateId"
          [decisionTable]="obj"
          [userInfoId]="userInfoId"
          [answering]="false"
        >
        </app-answer-decision-table>
      </div>

      <div *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'CARDSORT'">
        <app-answer-cardsort
          [question]="obj"
          [roleMandateId]="roleMandateId"
          [answering]="false"
          [userInfoId]="userInfoId"
        >
        </app-answer-cardsort>
      </div>
      <div *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'USER_CONNECTION'">
        <app-answer-user-connection
          [question]="obj"
          [answering]="false"
          [roleMandateId]="roleMandateId"
          [userInfoId]="userInfoId"
        >
        </app-answer-user-connection>
      </div>
      <div
        *ngIf="
          testObjectService.isQuestion(obj) && (obj.questionType === 'TEXT_AREA' || obj.questionType === 'RICH_TEXT')
        "
      >
        <app-answer-text-area
          [question]="obj"
          [roleMandateId]="roleMandateId"
          [answering]="false"
          [userInfoId]="userInfoId"
        >
        </app-answer-text-area>
      </div>
      <div *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'LIST_TEXT'">
        <app-answer-list-text
          [question]="obj"
          [roleMandateId]="roleMandateId"
          [companyId]="roleMandate.companyId"
          [userInfoId]="userInfoId"
          [answering]="false"
        >
        </app-answer-list-text>
      </div>
    </div>
  </div>

  <div class="row">
    <button mat-raised-button class="btn-success btn-center btn-300" (click)="onSave()">Save</button>
  </div>
</div>
