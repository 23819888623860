<mat-form-field>
  <mat-label>Alternative Title</mat-label>
  <input
    matInput
    type="text"
    [formControl]="alternativeTitleControl"
    required
    (change)="createAlternativeTitle($event)"
    [matAutocomplete]="answering ? autoAlternativeTitle : null"
    [readonly]="!answering"
  />
  <mat-autocomplete
    #autoAlternativeTitle="matAutocomplete"
    (optionSelected)="setAlternativeTitle($event)"
    [displayWith]="displayAlternativeTitle"
  >
    <mat-option *ngFor="let alternativeTitle of filteredAlternativeTitles | async" [value]="alternativeTitle">
      {{ alternativeTitle.alternativeTitle }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="alternativeTitleControl.errors && answering">Please, enter a valid alternative title.</mat-error>
</mat-form-field>
