<h3 mat-dialog-title>Edit Decision Table Connection</h3>

<mat-dialog-content class="mat-typography">
  <app-form-decision-table-connection
    [decisionTables]="decisionTables"
    [decisionTableRelation]="decisionTableRelation"
    (save)="onSave($event)"
  >
  </app-form-decision-table-connection>
</mat-dialog-content>
