<div class="container decision-tables">
  <app-psb-page-navigator [pages]="pageService.getConnectionIndicatorPages()"></app-psb-page-navigator>

  <h2>Decision Tables</h2>

  <div *ngFor="let decisionTable of decisionTables">
    <app-decision-table
      [decisionTable]="decisionTable"
      [connectionIndicatorId]="connectionIndicatorId"
      [showAcl]="true"
      (delete)="onDeleteDecisionTable($event)"
      (edit)="onEditDecisionTable($event)"
    ></app-decision-table>
  </div>

  <app-new-decision-table
    *ngIf="newDecisionTable"
    [decisionTableType]="decisionTableType"
    [connectionIndicatorId]="connectionIndicatorId"
    [decisionTables]="decisionTables"
    (addedDecisionTables)="updateDecisionTables($event)"
  ></app-new-decision-table>

  <button
    mat-raised-button
    class="btn-success btn-center btn-300"
    (click)="createNewDecisionTable()"
    *ngIf="!newDecisionTable"
  >
    New Decision Table
  </button>

  <button mat-raised-button class="btn-success btn-center btn-300" (click)="onNext()">Next</button>
</div>
