import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { ClientModalComponent } from '../client-modal/client-modal.component';

// services
import { CompanyService } from '@app/core/services/company.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { SnackBarService } from '@app/core/services/snack-bar.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginatorClients: MatPaginator;
  clients: PsbItem[] = [];

  constructor(
    private router: Router,
    private companyService: CompanyService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.companyService.getAll().subscribe((result) => {
      this.clients = result.map((client) => {
        return {
          id: client.id,
          title: client.name,
          description: client.country,
          status: client.active ? 'Active' : 'Inactive',
          extraColumns: [
            {
              key: 'Subscription Time',
              value: new Date(client.subscriptionTime).toLocaleDateString('en-US'),
            },
            {
              key: 'Created Date',
              value: new Date(client.dateAdded).toLocaleDateString('en-US'),
            },
          ],
        };
      });
    });
  }

  createNewClient() {
    const createNewDialog = this.dialog.open(ClientModalComponent, {
      data: { isEdit: false },
    });

    createNewDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.companyService.save(result.data).subscribe(
          (response) => {
            if (response) {
              this.clients = [];
              this.snackBar.info('Client created successfully.');
              this.companyService.getAll().subscribe((res) => {
                res.forEach((data) => {
                  this.clients.push({
                    id: data.id,
                    title: data.name,
                    description: data.country,
                    status: data.active ? 'Active' : 'Inactive',
                    extraColumns: [
                      {
                        key: 'Subscription Time',
                        value: new Date(data.subscriptionTime).toLocaleDateString('en-US'),
                      },
                      {
                        key: 'Created Date',
                        value: new Date(data.dateAdded).toLocaleDateString('en-US'),
                      },
                    ],
                  });
                });
              });
            }
          },
          () => {
            this.snackBar.info('Something went wrong while creating new client.');
          },
        );
      }
    });
  }

  onView(clientId: number) {
    this.router.navigate([`/clients/${clientId}/details`]);
  }

  onDelete(clientId: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.companyService.delete(clientId).subscribe(
          (res) => {
            if (res) {
              this.snackBar.info('Client deleted successfully.');
              this.clients = this.clients.filter((data) => data.id !== clientId);
            }
          },
          () => {
            this.snackBar.info('Something went wrong while deleting existing client.');
          },
        );
      }
    });
  }
}
