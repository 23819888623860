import { Component, Input, OnInit } from '@angular/core';
import {
  BlossomChart,
  BlossomChartPetalReport,
  BlossomChartPistilReport,
  BlossomChartService,
} from '../../../../../generated/v2';

@Component({
  selector: 'app-blossom',
  templateUrl: './blossom.component.html',
  styleUrls: ['./blossom.component.scss'],
})
export class BlossomComponent implements OnInit {
  @Input() chartId: number;

  @Input() testGroupId: number;

  @Input() userInfoId: number;

  blossomChart: BlossomChart;

  petals: BlossomChartPetalReport[] = [];

  constructor(private blossomChartService: BlossomChartService) {}

  ngOnInit(): void {
    this.blossomChartService.getChartBlossomChart(this.chartId).subscribe((blossomChart) => {
      this.blossomChart = blossomChart;
    });

    this.blossomChartService
      .getReportBlossomChartTestGroupUserChart(this.testGroupId, this.userInfoId, this.chartId)
      .subscribe((petals) => {
        this.petals = petals;
      });
  }

  legend() {
    const pistils: BlossomChartPistilReport[] = [];

    this.petals.forEach((petal) =>
      petal.pistils.forEach((pistil) => {
        if (!pistils.some((p) => p.color === pistil.color && p.label === pistil.label)) {
          pistils.push(pistil);
        }
      }),
    );

    return pistils;
  }
}
