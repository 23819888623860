<!-- Top Subscale -->
<p [innerHTML]="model.topLeadSentence?.text"></p>

<div *ngIf="topSentences && topSentences.length > 0 else showTopDefaultSentence">
  <div *ngFor="let sentence of topSentences">
    <h3 class="r-h3">{{ sentence.subscaleTitle }}</h3>
    <div [innerHTML]="sentence.text"></div>
  </div>
</div>
<ng-template #showTopDefaultSentence><div [innerHTML]="model.topDefaultSentence"></div></ng-template>



<!-- Bottom Subscale -->
<div *ngIf="showBottom">
  <p *ngIf="model.bottomLeadSentence && model.bottomLeadSentence.text" [innerHTML]="model.bottomLeadSentence.text"></p>

  <div *ngIf="bottomSentences && bottomSentences.length > 0 else showBottomDefaultSentence">
    <div *ngFor="let sentence of bottomSentences">
      <h3 class="r-h3">{{ sentence.subscaleTitle }}</h3>
      <div [innerHTML]="sentence.text"></div>
    </div>
  </div>
  <ng-template #showBottomDefaultSentence><div [innerHTML]="model.topDefaultSentence"></div></ng-template>
</div>
