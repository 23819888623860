<form [formGroup]="connectionFormGroup">
  <div class="row">
    <div class="column">
      <mat-form-field>
        <mat-label>Origin Table</mat-label>
        <mat-select formControlName="originFormControl" (selectionChange)="onSelectionChange()">
          <mat-option *ngFor="let decisionTable of decisionTables" [value]="decisionTable.id">
            {{ decisionTable.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="connectionFormGroup.get('originFormControl').hasError('required')">
          Please, select a valid item.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="column">
      <mat-form-field>
        <mat-label>Destination Table</mat-label>
        <mat-select formControlName="destinationFormControl" (selectionChange)="onSelectionChange()">
          <mat-option *ngFor="let decisionTable of decisionTables" [value]="decisionTable.id">
            {{ decisionTable.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="connectionFormGroup.get('destinationFormControl').hasError('required')">
          Please, select a valid item.
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <mat-checkbox color="primary" formControlName="acceptFormControl">Move accepted tasks</mat-checkbox>
  </div>

  <div *ngIf="originDecisionTable && destinationDecisionTable && destinationColumnFrom">
    <h4>Columns</h4>
    <mat-error *ngIf="destinationColumnFrom.hasError('missing-column-destination')">
      Please, select at least 1 (one) column relation.
    </mat-error>

    <div *ngFor="let originColumn of originDecisionTable.columns" class="row">
      <mat-card class="column">
        {{ originColumn.title }}
      </mat-card>

      <mat-form-field class="column field" *ngIf="destinationColumnFrom.get(getFormName(originColumn.title))">
        <mat-label>Column</mat-label>

        <mat-select
          (selectionChange)="onSelectionChangeColumn(originColumn, destinationDecisionTable.columns)"
          [formControl]="destinationColumnFrom.get(getFormName(originColumn.title))"
        >
          <mat-option *ngFor="let destinationColumn of destinationDecisionTable.columns" [value]="destinationColumn.id">
            {{ destinationColumn.title }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="destinationColumnFrom.get(getFormName(originColumn.title)).hasError('required')">
          Please, select a valid item.
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <button mat-raised-button class="btn-success btn-center btn-300" (click)="onSave()">Save</button>
</form>
