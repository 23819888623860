import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { CompanyService } from '@app/core/services/company.service';
import { PageService } from '@app/core/services/page.service';

// models
import { RoleMandatePosition } from '@app/core/models/role-mandate-position.model';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { Company } from '@app/core/models/company.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-role-mandate-titles',
  templateUrl: './role-mandate-titles.component.html',
  styleUrls: ['./role-mandate-titles.component.scss'],
})
export class RoleMandateTitlesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoading = false;
  roleMandatePositions: MatTableDataSource<RoleMandatePosition> = new MatTableDataSource<RoleMandatePosition>();
  roleMandateId: number = null;
  newRoleMandatePosition: RoleMandatePosition;

  displayedPositionColumns: string[] = ['title', 'actionDelete'];

  newPositionTitleForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
  });

  roleMandate: RoleMandate;
  companies: Company[] = [];
  filteredCompanies: Observable<Company[]>;
  positionTitleFormSubmitted = false;
  disableCompany = false;
  pages: any = [];

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private roleMandateService: RoleMandateService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public pageService: PageService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.roleMandateId = Number(this.route.snapshot.params.id);
    this.getRoleMandatePages();

    forkJoin([
      this.roleMandateService.getById(this.roleMandateId),
      this.roleMandateService.getRoleMandatePositions(this.roleMandateId),
    ]).subscribe((results) => {
      this.roleMandate = results[0];
      this.roleMandatePositions = new MatTableDataSource(results[1]);
      this.roleMandatePositions.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  createNewPositionTitle() {
    this.positionTitleFormSubmitted = true;
    const newRoleMandatePosition: RoleMandatePosition = {
      title: this.newPositionTitleForm.get('title').value,
    };

    if (!newRoleMandatePosition.title) {
      return;
    }

    if (this.roleMandatePositions.data.find((position) => position.title === newRoleMandatePosition.title)) {
      this.newPositionTitleForm.get('title').setErrors({ incorrect: true });
      return;
    }

    newRoleMandatePosition.roleMandateId = this.roleMandate.id;

    this.roleMandateService.saveRoleMandatePosition(newRoleMandatePosition).subscribe((res) => {
      this.newPositionTitleForm.reset();
      this.roleMandateService.getRoleMandatePositions(this.roleMandateId).subscribe((result) => {
        this.roleMandatePositions.data = result;
      });

      Object.keys(this.newPositionTitleForm.controls).forEach((key) => {
        this.newPositionTitleForm.get(key).setErrors(null);
      });
    });
  }

  onNext() {
    return this.router.navigate(['/role-mandate/' + this.roleMandate.id + '/users']);
  }

  onDeletePosition(element: RoleMandatePosition) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);
    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.roleMandateService.deleteRoleMandatePosition(element).subscribe((res) => {
          this.roleMandatePositions.data = this.roleMandatePositions.data.filter(
            (item) => item.title !== element.title,
          );
        });
      }
    });
  }

  getRoleMandatePages() {
    this.pages = this.pageService.getRoleMandatePages(this.roleMandateId);
  }
}
