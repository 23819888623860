<h1 mat-dialog-title>Edit chart</h1>

<form id="addChartForm" [formGroup]="form" mat-dialog-content (submit)="onFormSubmit()">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" required />
    <mat-error *ngIf="form.get('name').invalid">Please enter a valid name.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Header</mat-label>
    <input matInput formControlName="header" required />
    <mat-error *ngIf="form.get('header').invalid">Please enter a valid header.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Base</mat-label>
    <mat-select formControlName="base" required>
      <mat-option *ngFor="let base of bases" [value]="base">{{ base }}</mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('base').invalid">Please select a base.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Plot</mat-label>
    <mat-select formControlName="plot" required>
      <mat-option *ngFor="let plot of plots" [value]="plot">{{ plot }}</mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('plot').invalid">Please select a plot.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Area Mapping</mat-label>
    <mat-select formControlName="areaIndex" required>
      <mat-option *ngFor="let area of (areas$ | async) || []" [value]="area.id">
        {{ area.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('areaIndex').invalid">Please select an area.</mat-error>
  </mat-form-field>
</form>

<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>
    <mat-icon>block</mat-icon>
    Cancel
  </button>
  <button mat-flat-button color="primary" [disabled]="form.invalid" type="submit" form="addChartForm">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</div>
