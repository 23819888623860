<div>
  <div class="row">
    <div class="col-2">
      <app-total-box [total]="companies ? companies.length : 0" description="Total Companies" color="yellow">
      </app-total-box>
    </div>
    <div class="col-10">
      <app-line-chart [chartData]="lineChartData" [chartLabels]="lineChartLabels" [label]="'Companies'">
      </app-line-chart>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateAdded">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Created </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.dateAdded }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="select(row)"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10]"></mat-paginator>
  </div>
</div>
