import { Component, OnInit, AfterContentChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { forkJoin, timer } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CountdownConfig } from 'ngx-countdown';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Location } from '@angular/common';

// components
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { UnansweredQuestionsDialogComponent } from '@app/shared/components/unanswered-questions-dialog/unanswered-questions-dialog.component';

// services
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { QuestionService } from '@app/core/services/question.service';
import { TestObjectService } from '@app/core/services/test-object.service';
import { TestService } from '@app/core/services/test.service';
import { UserService } from '@app/core/services/user.service';
import { UtilsService } from '@app/core/services/utils.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { Question } from '@app/core/models/question.model';
import { QuestionGroup } from '@app/core/models/question-group.model';
import { PsyTest } from '@app/core/models/psy-test.model';
import { SuperTest } from '@app/core/models/supertest.model';
import { UserAnswer } from '@app/core/models/user-answer.model';
import { PsyTestResult } from '@app/core/models/psy-test-result.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { UserInfo } from '@app/core/models/userInfo.model';
import { CustomQuestionOrder } from '@app/core/models/custom-question-order.model';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit, AfterContentChecked, OnDestroy {
  userInfoId: number;
  psyTestId: number;
  superTestId: number;
  testResultId: number;
  currentPageId: number;
  config: {
    id: string;
    itemsPerPage: number;
    currentPage: number;
    totalItems: number;
  };
  allObj: any[];
  psyTest: PsyTest;
  superTest: SuperTest;
  questionGroups: QuestionGroup[];
  allQuestionGroups: QuestionGroup[];
  questions: Question[];
  originalQuestions: Question[];
  timerConfig: CountdownConfig = {
    format: 'HH:mm:ss',
    notify: [600, 120, 60, 10],
  };
  private testGroupId: number;
  math = Math;
  loadAnswers = true;
  isOnline: boolean;
  totalQuestions: number;
  totalAnsweredQuestions: number;
  progress: number;
  unansweredQuestionsInCurrentPage: number[] = [];
  unansweredQuestionNumbersInCurrentPage: number[] = [];
  questionNumber = 1;
  saveOriginalOrderCardsortAnswersQuestionNumber: number;
  unAnswerQuestionIds: number[] = [];
  dateStarted: any;
  currentDate: any;
  userInfo = {} as UserInfo;
  toolTipType = '';
  scrolled = false;
  questionForLocalStorage = [];
  customQuestionsOrder: CustomQuestionOrder[];
  isUnAnswerQuestionsInCurrentPage$: Subscription;
  unansweredQuestionNumbersInCurrentPageSentence = '';
  isClickedFinishButton = false;
  firstUnansweredQuestion: Question;
  isContinue: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private testService: TestService,
    private questionGroupService: QuestionGroupService,
    private viewportScroller: ViewportScroller,
    private cdref: ChangeDetectorRef,
    public dialog: MatDialog,
    public testObjectService: TestObjectService,
    private userService: UserService,
    private utilsService: UtilsService,
    private snackBar: SnackBarService,
    private questionService: QuestionService,
    private location: Location,
  ) {}

  async ngOnInit() {
    this.utilsService.isOnline$().subscribe((isOnline: boolean) => (this.isOnline = isOnline));
    this.userInfoId = this.userService.getUserData().id;
    this.psyTestId = Number(this.route.snapshot.params['psyTestId']);
    this.testGroupId = Number(this.route.snapshot.params['testGroupId']);
    this.superTestId = Number(this.route.snapshot.params['superTestId']);
    this.currentPageId = Number(this.route.snapshot.params['page']);
    this.isContinue = this.location.path().includes('continue');

    this.questionGroupService.getAll().subscribe((questionGroups) => (this.allQuestionGroups = questionGroups));

    if (this.psyTestId) {
      this.testService
        .getPsyTestByIdAndTestGroupIdAndUserInfoId(this.psyTestId, this.testGroupId, this.userInfoId)
        .subscribe((psyTest) => {
          this.psyTest = psyTest;
          this.setToolTipForCardSort(psyTest.title);
          if (this.psyTest.status === 'COMPLETED') {
            return this.router.navigate(['/dashboard']);
          } else {
            if (this.psyTest.testTime) {
              if (!localStorage.getItem('leftTime')) {
                // TODO: check possible problem related to deleteUserAnswers
                this.testService.deleteUserAnswers(this.psyTestId, this.testGroupId, this.userInfoId).subscribe();
              }
            }
            forkJoin([
              this.questionGroupService.getAllQuestionGroupForPsyTest(this.psyTestId),
              this.testService.getAllQuestionByPsyTestId(this.psyTestId, this.userInfoId, this.testGroupId),
              this.testService.getTestResultByTestIdAndTestGroupIdAndUserInfoId(
                this.psyTestId,
                this.testGroupId,
                this.userInfoId,
              ),
              this.testService.getCustomQuestionsOrderById(this.psyTestId),
            ]).subscribe((results: [QuestionGroup[], Question[], PsyTestResult, CustomQuestionOrder[]]) => {
              if (!(this.psyTest.testTime > 0)) {
                this.loadAnswers = true;
              } else {
                if (this.timerConfig.leftTime > 0) {
                  this.loadAnswers = true;
                }
              }
              // Convert time to seconds and utc
              const dateStartedUTC = new Date(results[2].dateStarted).toISOString();
              this.dateStarted = new Date(dateStartedUTC).getTime() / 1000;
              this.currentDate = Date.parse(new Date().toISOString()) / 1000;
              this.testResultId = results[2].id;

              if (this.psyTest.testTime > 0) {
                if (!localStorage.getItem('leftTime')) {
                  results[1].map((question) => delete question.userAnswer);
                }
                this.timerConfig.leftTime = this.psyTest.testTime * 60 - (this.currentDate - this.dateStarted);
                localStorage.setItem('leftTime', this.timerConfig.leftTime.toString());
              }

              if (this.psyTest.testTime > 0 && this.timerConfig.leftTime <= 0) {
                this.onTimeIsOver(true);
              }

              this.calculateProgress(results[1]);
              this.customQuestionsOrder = results[3];
              this.getAllObjAndConfig(results[0], results[1]);
            });
          }
        });
    }

    if (this.superTestId) {
      forkJoin([
        this.testService.getSuperTestById(this.superTestId),
        this.questionGroupService.getAllQuestionGroupForSuperTest(this.superTestId),
        this.testService.getAllQuestionBySuperTestId(this.superTestId),
      ]).subscribe((results) => {
        this.superTest = results[0];
        this.getAllObjAndConfig(results[1], results[2]);
      });
    }

    this.router.events.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.isContinue && this.firstUnansweredQuestion) {
          this.scrollToQuestion(this.firstUnansweredQuestion.id.toString());
        } else if (this.isContinue && this.isAbleToFinish()) {
          this.scrollToQuestion(this.allObj[this.allObj.length - 1].id.toString());
        }
      }
    });
  }

  setToolTipForCardSort(title: string) {
    if (!this.psyTest.showCardSortInstructions) {
      return;
    }
    if (!title.includes('360')) {
      this.toolTipType = 'cardSort';
    }

    if (title.includes('360') && title.toLocaleLowerCase().includes('self')) {
      this.toolTipType = 'cardSort';
    }

    if (title.includes('360')) {
      this.toolTipType = 'cardSort 360';
    }

    if (title.includes('360') && !title.includes('self')) {
      this.toolTipType = 'cardSort 360';
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  pageChanged(page: number): Promise<boolean> {
    if (this.psyTest?.answersRequired && page > this.config.currentPage) {
      this.unansweredQuestionsInCurrentPage = [];
      this.unansweredQuestionsInCurrentPage = this.getUnansweredQuestionsInCurrentPage();
      this.unansweredQuestionNumbersInCurrentPage = this.getUnansweredQuestionNumbersInCurrentPage();
      if (this.unansweredQuestionsInCurrentPage.length > 0) {
        page = this.config.currentPage;

        let unansweredQuestionMessage = '';
        if (this.unansweredQuestionNumbersInCurrentPage.length > 2) {
          unansweredQuestionMessage = 'Questions ';
          for (const [
            index,
            unansweredQuestionNumberInCurrentPage,
          ] of this.unansweredQuestionNumbersInCurrentPage.entries()) {
            if (index < this.unansweredQuestionNumbersInCurrentPage.length - 2) {
              unansweredQuestionMessage = unansweredQuestionMessage + unansweredQuestionNumberInCurrentPage + ', ';
            } else if (index === this.unansweredQuestionNumbersInCurrentPage.length - 2) {
              unansweredQuestionMessage = unansweredQuestionMessage + unansweredQuestionNumberInCurrentPage + ' and ';
            } else if (index === this.unansweredQuestionNumbersInCurrentPage.length - 1) {
              unansweredQuestionMessage =
                unansweredQuestionMessage + unansweredQuestionNumberInCurrentPage + ' were not answered. ';
            }
          }
        } else if (this.unansweredQuestionNumbersInCurrentPage.length === 2) {
          unansweredQuestionMessage =
            'Questions ' +
            this.unansweredQuestionNumbersInCurrentPage[0] +
            ' and ' +
            this.unansweredQuestionNumbersInCurrentPage[1] +
            ' were not answered. ';
        } else if (this.unansweredQuestionNumbersInCurrentPage.length === 1) {
          unansweredQuestionMessage =
            'Question ' + this.unansweredQuestionNumbersInCurrentPage[0] + ' was not answered. ';
        }

        unansweredQuestionMessage =
          unansweredQuestionMessage + 'Moving to the next page confirms no change is indicated.';

        const unansweredQuestionDialog = this.dialog.open(UnansweredQuestionsDialogComponent, {
          panelClass: 'auto-sized-dialog',
        });
        unansweredQuestionDialog.componentInstance.message = unansweredQuestionMessage;
        unansweredQuestionDialog.componentInstance.isShowCancel = true;
        unansweredQuestionDialog.afterClosed().subscribe((result) => {
          if (result.event !== EVENT_CANCEL) {
            for (const unansweredQuestionNumbersInCurrentPage of this.unansweredQuestionNumbersInCurrentPage) {
              const unansweredQuestion = this.originalQuestions.filter(
                (question) => question.questionNumber === unansweredQuestionNumbersInCurrentPage,
              )[0];
              this.unAnswerQuestionIds.push(unansweredQuestion.id);
            }

            this.testService.setUnAnswerQuestionIds(this.unAnswerQuestionIds);
            this.isUnAnswerQuestionsInCurrentPage$ = this.testService
              .getUnAnswerQuestionsInCurrentPage()
              .subscribe((unAnsweredQuestionsInCurrentPage) => {
                if (unAnsweredQuestionsInCurrentPage && unAnsweredQuestionsInCurrentPage.length === 0) {
                  const nextPage = page + 1;
                  return this.goNextOrPreviousPage(nextPage);
                }
              });
          }
        });
      }
    } else if (this.psyTest && !this.psyTest.answersRequired && page > this.config.currentPage) {
      this.unansweredQuestionsInCurrentPage = [];
      this.unansweredQuestionNumbersInCurrentPage = [];
      this.unansweredQuestionsInCurrentPage = this.getUnansweredQuestionsInCurrentPage();
      this.unansweredQuestionNumbersInCurrentPage = this.getUnansweredQuestionNumbersInCurrentPage();

      let storedUnAnswerQuestionNumbers = JSON.parse(localStorage.getItem('unAnswerQuestionNumbers')) || [];
      let storedUnAnswerQuestionIds = JSON.parse(localStorage.getItem('unAnswerQuestionIds')) || [];

      let getStoredUnAnswerCardsortAndSliderQuestionIds = localStorage.getItem('unAnswerCardsortAndSliderQuestionIds');
      let storedUnAnswerCardsortAndSliderQuestionIds = [];
      if (getStoredUnAnswerCardsortAndSliderQuestionIds) {
        storedUnAnswerCardsortAndSliderQuestionIds = JSON.parse(
          localStorage.getItem('unAnswerCardsortAndSliderQuestionIds'),
        );
      }

      if (this.unansweredQuestionsInCurrentPage.length > 0) {
        for (const unansweredQuestionNumbersInCurrentPage of this.unansweredQuestionNumbersInCurrentPage) {
          const unansweredQuestion = this.originalQuestions.filter(
            (question) => question.questionNumber === unansweredQuestionNumbersInCurrentPage,
          )[0];
          if (!storedUnAnswerQuestionNumbers.includes(unansweredQuestion.questionNumber)) {
            storedUnAnswerQuestionNumbers.push(unansweredQuestion.questionNumber);
          }

          if (!storedUnAnswerQuestionIds.includes(unansweredQuestion.id)) {
            storedUnAnswerQuestionIds.push(unansweredQuestion.id);
          }

          if (unansweredQuestion.questionType === 'CARDSORT' || unansweredQuestion.questionType === 'SLIDER') {
            if (!storedUnAnswerCardsortAndSliderQuestionIds.includes(unansweredQuestion.id)) {
              storedUnAnswerCardsortAndSliderQuestionIds.push(unansweredQuestion.id);
            }
          }
        }

        localStorage.setItem('unAnswerQuestionNumbers', JSON.stringify(storedUnAnswerQuestionNumbers));
        localStorage.setItem('unAnswerQuestionIds', JSON.stringify(storedUnAnswerQuestionIds));
        localStorage.setItem(
          'unAnswerCardsortAndSliderQuestionIds',
          JSON.stringify(storedUnAnswerCardsortAndSliderQuestionIds),
        );
      }
    }

    return this.goNextOrPreviousPage(page);
  }

  goNextOrPreviousPage(page: any) {
    this.isUnAnswerQuestionsInCurrentPage$?.unsubscribe();
    this.config.currentPage = page;
    this.viewportScroller.scrollToPosition([0, 0]);

    if (this.psyTestId) {
      return this.router.navigate([
        `/dashboard/test/${this.psyTestId}/test-group/${this.testGroupId}/page/${this.config.currentPage}`,
      ]);
    }

    if (this.superTestId) {
      return this.router.navigate([`/dashboard/supertest/${this.superTestId}/page/${this.config.currentPage}`]);
    }
  }

  // @TODO: We need to put this function into a service to be reused
  getAllObjAndConfig(questionGroup: QuestionGroup[], questions: Question[]) {
    if (this.psyTest.customQuestionOrder) {
      this.questions = questions;
      this.questions = this.testService.applyCustomOrder(this.questions, this.customQuestionsOrder);
      questionGroup = [];
    }
    this.questionGroups = questionGroup;
    this.originalQuestions = questions;
    this.questions = questions.filter(
      (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
    );

    this.questions.forEach((question) => {
      if (question.instructions?.length === 0 && question.questionGroupId) {
        question.instructions = this.allQuestionGroups.filter(
          (qG) => qG.id === question.questionGroupId,
        )[0].instructions;
      }
    });

    this.questions = this.questions.sort((a, b) => {
      return a.aorder - b.aorder;
    });

    this.questionGroups.forEach((group) => {
      group.questions = questions.filter((question) => question.questionGroupId === group.id);

      if (group.questions.length === 0) {
        this.questionGroups = this.questionGroups.filter((qG) => qG.id !== group.id);
      }
    });

    this.questionGroups = this.questionGroups.sort((a, b) => {
      return a.aorder - b.aorder;
    });

    this.allObj = [
      ...this.questionGroups.map((obj) => {
        obj.networkType = 'questionGroup';

        obj.questions.forEach((question) => {
          question.questionNumber = this.questionNumber;
          this.questionNumber++;
        });
        return obj;
      }),
      ...this.questions.map((obj) => {
        obj.networkType = 'question';

        obj.questionNumber = this.questionNumber;
        this.questionNumber++;
        return obj;
      }),
    ];

    this.allObj = this.allObj.sort((a, b) => {
      return a.questionNumber - b.questionNumber;
    });

    this.utilsService.timerDist = {};
    let getAllSpentTimeQuestion = JSON.parse(localStorage.getItem('question_spendTime'));
    let isExisting;
    let resultId = this.testResultId;
    if (getAllSpentTimeQuestion) {
      isExisting = getAllSpentTimeQuestion[resultId] !== undefined;
    }
    this.allObj.map((e) => {
      if (e.hasOwnProperty('networkType') && e.networkType === 'questionGroup') {
        e.questions.forEach((n) => {
          if (isExisting) {
            let hasFound = false;
            getAllSpentTimeQuestion[resultId].forEach((m) => {
              for (const [key, value] of Object.entries(m)) {
                if (n.id == key) {
                  hasFound = true;
                  this.utilsService.timerDist[n.id] = Number(value);
                }
              }
            });
            if (!hasFound) {
              this.utilsService.timerDist[n.id] = 0;
            }
          } else {
            this.utilsService.timerDist[n.id] = 0;
          }
          this.utilsService.timerDistObservable[n.id] = timer(1000, 1000);
          this.utilsService.timerUnsubscribe$[n.id] = null;
          this.utilsService.timerWarningObservable[n.id] = timer(1000, 1000);
          this.utilsService.timerWarningUnsubscribe$[n.id] = null;
        });
      } else {
        if (isExisting) {
          let hasFound = false;
          getAllSpentTimeQuestion[resultId].forEach((m) => {
            for (const [key, value] of Object.entries(m)) {
              if (e.id == key) {
                hasFound = true;
                this.utilsService.timerDist[e.id] = Number(value);
              }
            }
          });
          if (!hasFound) {
            this.utilsService.timerDist[e.id] = 0;
          }
        } else {
          this.utilsService.timerDist[e.id] = 0;
        }
        this.utilsService.timerDistObservable[e.id] = timer(1000, 1000);
        this.utilsService.timerUnsubscribe$[e.id] = null;
        this.utilsService.timerWarningObservable[e.id] = timer(1000, 1000);
        this.utilsService.timerWarningUnsubscribe$[e.id] = null;
      }
    });

    this.config = {
      id: 'paginator',
      itemsPerPage:
        this.psyTest && this.psyTest.paging
          ? this.psyTest.paging
          : this.superTest && this.superTest.paging
          ? this.superTest.paging
          : 1,
      currentPage: this.currentPageId ? this.currentPageId : 1,
      totalItems: this.allObj.length,
    };

    if (this.isContinue && this.firstUnansweredQuestion) {
      const pageToGo = Math.ceil((this.questions.indexOf(this.firstUnansweredQuestion) + 1) / this.config.itemsPerPage);
      return this.goNextOrPreviousPage(pageToGo);
    } else if (this.isAbleToFinish() && this.isContinue) {
      const pageToGo = Math.ceil(this.config.totalItems / this.config.itemsPerPage);
      return this.goNextOrPreviousPage(pageToGo);
    }
  }

  onFinish() {
    this.isClickedFinishButton = true;
    this.unansweredQuestionsInCurrentPage = this.getUnansweredQuestionsInCurrentPage();
    let isClickedUnansweredQuestionsConfirmedButton = false;

    if (this.psyTest && this.psyTest.answersRequired) {
      if (this.unansweredQuestionsInCurrentPage && this.unansweredQuestionsInCurrentPage.length > 0) {
        this.unansweredQuestionNumbersInCurrentPage = this.getUnansweredQuestionNumbersInCurrentPage();

        let unansweredQuestionMessage = '';
        if (this.unansweredQuestionNumbersInCurrentPage.length > 2) {
          unansweredQuestionMessage = 'Questions ';
          for (const [
            index,
            unansweredQuestionNumberInCurrentPage,
          ] of this.unansweredQuestionNumbersInCurrentPage.entries()) {
            if (index < this.unansweredQuestionNumbersInCurrentPage.length - 2) {
              unansweredQuestionMessage = unansweredQuestionMessage + unansweredQuestionNumberInCurrentPage + ', ';
            }
            if (index === this.unansweredQuestionNumbersInCurrentPage.length - 2) {
              unansweredQuestionMessage = unansweredQuestionMessage + unansweredQuestionNumberInCurrentPage + ' and ';
            } else if (index === this.unansweredQuestionNumbersInCurrentPage.length - 1) {
              unansweredQuestionMessage =
                unansweredQuestionMessage + unansweredQuestionNumberInCurrentPage + ' were not answered. ';
            }
          }
        } else if (this.unansweredQuestionNumbersInCurrentPage.length === 2) {
          unansweredQuestionMessage =
            'Questions ' +
            this.unansweredQuestionNumbersInCurrentPage[0] +
            ' and ' +
            this.unansweredQuestionNumbersInCurrentPage[1] +
            ' were not answered. ';
        } else if (this.unansweredQuestionNumbersInCurrentPage.length === 1) {
          unansweredQuestionMessage =
            'Question ' + this.unansweredQuestionNumbersInCurrentPage[0] + ' was not answered. ';
        }

        unansweredQuestionMessage =
          unansweredQuestionMessage + "If no change is indicated, select 'confirm' to submit.";

        const unansweredQuestionDialog = this.dialog.open(UnansweredQuestionsDialogComponent, {
          panelClass: 'auto-sized-dialog',
        });
        unansweredQuestionDialog.componentInstance.message = unansweredQuestionMessage;
        unansweredQuestionDialog.componentInstance.isShowCancel = true;
        unansweredQuestionDialog.afterClosed().subscribe((result) => {
          if (result.event !== EVENT_CANCEL) {
            isClickedUnansweredQuestionsConfirmedButton = true;
            for (const unansweredQuestionNumbersInCurrentPage of this.unansweredQuestionNumbersInCurrentPage) {
              const unansweredQuestion = this.originalQuestions.filter(
                (question) => question.questionNumber === unansweredQuestionNumbersInCurrentPage,
              )[0];
              this.unAnswerQuestionIds.push(unansweredQuestion.id);
            }

            this.testService.setUnAnswerQuestionIds(this.unAnswerQuestionIds);
            let countProgress100 = 0;
            this.testService.getProgress().subscribe((progress) => {
              if (progress === 100) {
                countProgress100++;
              }

              if (progress === 100 && countProgress100 === 1) {
                this.completeTest();
              }
            });
          }
        });
      }
    } else if (this.psyTest && !this.psyTest.answersRequired) {
      let storedUnAnswerQuestionNumbers = JSON.parse(localStorage.getItem('unAnswerQuestionNumbers')) || [];
      let storedUnAnswerQuestionIds = JSON.parse(localStorage.getItem('unAnswerQuestionIds')) || [];
      let getStoredUnAnswerCardsortAndSliderQuestionIds = localStorage.getItem('unAnswerCardsortAndSliderQuestionIds');
      let storedUnAnswerCardsortAndSliderQuestionIds = [];
      if (getStoredUnAnswerCardsortAndSliderQuestionIds) {
        storedUnAnswerCardsortAndSliderQuestionIds = JSON.parse(
          localStorage.getItem('unAnswerCardsortAndSliderQuestionIds'),
        );
      }

      if (this.unansweredQuestionsInCurrentPage && this.unansweredQuestionsInCurrentPage.length > 0) {
        this.unansweredQuestionNumbersInCurrentPage = this.getUnansweredQuestionNumbersInCurrentPage();

        for (const unansweredQuestionNumbersInCurrentPage of this.unansweredQuestionNumbersInCurrentPage) {
          const unansweredQuestion = this.originalQuestions.filter(
            (question) => question.questionNumber === unansweredQuestionNumbersInCurrentPage,
          )[0];
          if (!storedUnAnswerQuestionNumbers.includes(unansweredQuestion.questionNumber)) {
            storedUnAnswerQuestionNumbers.push(unansweredQuestion.questionNumber);
          }

          if (!storedUnAnswerQuestionIds.includes(unansweredQuestion.id)) {
            storedUnAnswerQuestionIds.push(unansweredQuestion.id);
          }

          if (unansweredQuestion.questionType === 'CARDSORT' || unansweredQuestion.questionType === 'SLIDER') {
            if (!storedUnAnswerCardsortAndSliderQuestionIds.includes(unansweredQuestion.id)) {
              storedUnAnswerCardsortAndSliderQuestionIds.push(unansweredQuestion.id);
            }
          }
        }
      }

      localStorage.setItem(
        'unAnswerCardsortAndSliderQuestionIds',
        JSON.stringify(storedUnAnswerCardsortAndSliderQuestionIds),
      );

      storedUnAnswerQuestionNumbers = storedUnAnswerQuestionNumbers
        .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
        .sort((a, b) => a - b);
      storedUnAnswerQuestionIds = storedUnAnswerQuestionIds
        .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
        .sort((a, b) => a - b);
      localStorage.setItem('unAnswerQuestionNumbers', JSON.stringify(storedUnAnswerQuestionNumbers));
      localStorage.setItem('unAnswerQuestionIds', JSON.stringify(storedUnAnswerQuestionIds));

      if (storedUnAnswerQuestionNumbers.length > 0 && storedUnAnswerQuestionIds.length > 0) {
        let unansweredQuestionMessage = '';
        if (storedUnAnswerQuestionNumbers.length > 2) {
          unansweredQuestionMessage = 'Items ';
          for (const [index, unAnswerQuestionNumber] of storedUnAnswerQuestionNumbers.entries()) {
            if (index < storedUnAnswerQuestionNumbers.length - 2) {
              unansweredQuestionMessage = unansweredQuestionMessage + unAnswerQuestionNumber + ', ';
            } else if (index === storedUnAnswerQuestionNumbers.length - 2) {
              unansweredQuestionMessage = unansweredQuestionMessage + unAnswerQuestionNumber + ' and ';
            } else if (index === storedUnAnswerQuestionNumbers.length - 1) {
              unansweredQuestionMessage = unansweredQuestionMessage + unAnswerQuestionNumber + ' have no answer. ';
            }
          }
        } else if (storedUnAnswerQuestionNumbers.length === 2) {
          unansweredQuestionMessage =
            'Items ' +
            storedUnAnswerQuestionNumbers[0] +
            ' and ' +
            storedUnAnswerQuestionNumbers[1] +
            ' were not answered. ';
        } else if (storedUnAnswerQuestionNumbers.length === 1) {
          unansweredQuestionMessage = 'Item ' + storedUnAnswerQuestionNumbers[0] + ' was not answered. ';
        }

        unansweredQuestionMessage = unansweredQuestionMessage + 'Confirm this is your intention.';

        const unansweredQuestionDialog = this.dialog.open(UnansweredQuestionsDialogComponent, {
          panelClass: 'auto-sized-dialog',
        });
        unansweredQuestionDialog.componentInstance.message = unansweredQuestionMessage;
        unansweredQuestionDialog.componentInstance.isShowCancel = true;
        unansweredQuestionDialog.afterClosed().subscribe((result) => {
          if (result.event !== EVENT_CANCEL) {
            isClickedUnansweredQuestionsConfirmedButton = true;
            if (storedUnAnswerCardsortAndSliderQuestionIds && storedUnAnswerCardsortAndSliderQuestionIds.length === 0) {
              this.completeTest();
            } else {
              const unAnsweredCardsortAndSliderQuestions = this.originalQuestions.filter((question) =>
                storedUnAnswerCardsortAndSliderQuestionIds.includes(question.id),
              );

              this.questionService
                .saveAllUnansweredCardsortOriginalOrderAndSliderFirstOption(
                  this.testResultId,
                  this.userInfoId,
                  unAnsweredCardsortAndSliderQuestions,
                )
                .subscribe((result) => {
                  this.completeTest();
                });
            }
          }
        });
      }
    }

    if (this.isAbleToFinish() && !isClickedUnansweredQuestionsConfirmedButton) {
      const confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
      confirmationDialog.componentInstance.message =
        this.testGroupId === 171
          ? `Are you sure you want to finish this assessment?`
          : `Are you sure you want to finish this test?`;
      confirmationDialog.componentInstance.isShowCancel = true;
      confirmationDialog.afterClosed().subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          this.completeTest();
        }
      });
    }
  }

  completeTest() {
    let spendTimeForTestQuestion: any;
    if (localStorage.getItem('question_spendTime')) {
      let getAllTestSpentTimeForQuestions = JSON.parse(localStorage.getItem('question_spendTime'));
      spendTimeForTestQuestion = getAllTestSpentTimeForQuestions[this.testResultId];
      this.testService.saveQuestionSpentTime(this.testResultId, spendTimeForTestQuestion).subscribe(() => {
        spendTimeForTestQuestion = delete spendTimeForTestQuestion[this.testResultId];
        localStorage.removeItem('question_spendTime');
        localStorage.setItem('question_spendTime', JSON.stringify(spendTimeForTestQuestion));
      });
    }
    this.utilsService.timerDist = {};
    this.testService.complete(this.psyTestId, this.testGroupId, this.userInfoId).subscribe(() => {
      localStorage.removeItem('leftTime');
      localStorage.removeItem('unAnswerQuestionNumbers');
      localStorage.removeItem('unAnswerQuestionIds');
      localStorage.removeItem('unAnswerCardsortAndSliderQuestionIds');
      this.showTestCompleted();
      this.userService.updateUserScores([this.userInfoId], this.testGroupId).subscribe();
      return this.router.navigate(['/dashboard']);
    });
  }

  private isAbleToFinish(): boolean {
    let isAble = false;
    if (this.psyTest.answersRequired && this.progress === 100) {
      isAble = true;
    } else if (!this.psyTest.answersRequired) {
      let storedUnAnswerQuestionIds = JSON.parse(localStorage.getItem('unAnswerQuestionIds')) || [];
      if (storedUnAnswerQuestionIds.length === 0) {
        isAble = true;
      }
    }
    return isAble;
  }

  showTestCompleted() {
    this.snackBar.info(this.testGroupId === 171 ? 'The assessment has been completed' : 'The test has been completed');
  }

  onTimeIsOver(complete: boolean) {
    if (complete) {
      let spendTimeForTestQuestion: any;
      if (localStorage.getItem('question_spendTime')) {
        let getAllTestSpentTimeForQuestions = JSON.parse(localStorage.getItem('question_spendTime'));
        spendTimeForTestQuestion = getAllTestSpentTimeForQuestions[this.testResultId];
        this.testService.saveQuestionSpentTime(this.testResultId, spendTimeForTestQuestion).subscribe(() => {
          spendTimeForTestQuestion = delete spendTimeForTestQuestion[this.testResultId];
          localStorage.removeItem('question_spendTime');
          localStorage.setItem('question_spendTime', JSON.stringify(spendTimeForTestQuestion));
        });
      }
      this.utilsService.timerDist = {};
      this.testService.complete(this.psyTestId, this.testGroupId, this.userInfoId).subscribe(() => {
        localStorage.removeItem('leftTime');
        localStorage.removeItem('unAnswerQuestionNumbers');
        localStorage.removeItem('unAnswerQuestionIds');
        localStorage.removeItem('unAnswerCardsortAndSliderQuestionIds');
        this.showTestCompleted();
        this.userService.updateUserScores([this.userInfoId], this.testGroupId).subscribe();
        return this.router.navigate(['/dashboard']);
      });
    }
  }

  onAnswer(answer: { questionId: number; userAnswer: UserAnswer }) {
    if (this.psyTest && !this.psyTest.answersRequired) {
      let getStoredUnAnswerCardsortAndSliderQuestionIds = localStorage.getItem('unAnswerCardsortAndSliderQuestionIds');
      let storedUnAnswerCardsortAndSliderQuestionIds = [];
      let updatedStoredUnAnswerCardsortAndSliderQuestionIds = [];
      if (getStoredUnAnswerCardsortAndSliderQuestionIds) {
        storedUnAnswerCardsortAndSliderQuestionIds = JSON.parse(
          localStorage.getItem('unAnswerCardsortAndSliderQuestionIds'),
        );
        updatedStoredUnAnswerCardsortAndSliderQuestionIds = storedUnAnswerCardsortAndSliderQuestionIds.filter(
          (questionId) => questionId !== answer.questionId,
        );
      }

      localStorage.setItem(
        'unAnswerCardsortAndSliderQuestionIds',
        JSON.stringify(updatedStoredUnAnswerCardsortAndSliderQuestionIds),
      );

      let storedUnAnswerQuestionIds = JSON.parse(localStorage.getItem('unAnswerQuestionIds')) || [];
      let updatedStoredUnAnswerQuestionIds = storedUnAnswerQuestionIds.filter(
        (questionId) => questionId !== answer.questionId,
      );
      localStorage.setItem('unAnswerQuestionIds', JSON.stringify(updatedStoredUnAnswerQuestionIds));

      let storedUnAnswerQuestionNumbers = JSON.parse(localStorage.getItem('unAnswerQuestionNumbers')) || [];
      const answeredQuestion = this.originalQuestions.filter((question) => question.id === answer.questionId)[0];
      let updatedStoreedUnAnsweredQuestionNumbers = storedUnAnswerQuestionNumbers.filter(
        (questionNumber) => questionNumber !== answeredQuestion.questionNumber,
      );
      localStorage.setItem('unAnswerQuestionNumbers', JSON.stringify(updatedStoreedUnAnsweredQuestionNumbers));
    }

    // Remove error message on answer
    this.unansweredQuestionsInCurrentPage = this.unansweredQuestionsInCurrentPage.filter(
      (question) => question !== answer.questionId,
    );

    this.testService.setUnAnswerQuestionsInCurrentPage(this.unansweredQuestionsInCurrentPage);

    // Add userAnswer property on answer
    this.allObj.map((obj) => {
      if (obj.networkType === 'questionGroup') {
        obj.questions.filter((question) => {
          if (question.id === answer.questionId) {
            if (!('userAnswer' in question)) {
              this.totalAnsweredQuestions++;
            }
            question.userAnswer = answer.userAnswer;
          }
        });
      } else {
        if (obj.id === answer.questionId) {
          if (!('userAnswer' in obj)) {
            this.totalAnsweredQuestions++;
          }
          obj.userAnswer = answer.userAnswer;
        }
      }
      this.calculateProgress(undefined);
      return obj;
    });
  }

  private calculateProgress(questions: Question[]) {
    if (typeof questions !== 'undefined') {
      this.totalQuestions = questions.length;
      this.totalAnsweredQuestions = questions.reduce((totalAnswered, question) => {
        if ('userAnswer' in question) {
          totalAnswered++;
        } else {
          if (!this.firstUnansweredQuestion) {
            this.firstUnansweredQuestion = question;
          }
        }
        return totalAnswered;
      }, 0);
    }
    this.progress = +((this.totalAnsweredQuestions / this.totalQuestions) * 100).toFixed(0);
    this.testService.setProgress(this.progress);
  }

  private getUnansweredQuestionsInCurrentPage(): number[] {
    let unansweredQuestionsInPage = [];
    if (!(this.config.itemsPerPage >= this.config.totalItems)) {
      const betweenItems = {
        start: this.config.currentPage * this.config.itemsPerPage - this.config.itemsPerPage,
        end: this.config.currentPage * this.config.itemsPerPage - 1,
      };
      for (let i = betweenItems.start; i <= betweenItems.end; i++) {
        if (typeof this.allObj[i] !== 'undefined') {
          unansweredQuestionsInPage = [
            ...unansweredQuestionsInPage,
            ...this.getUnAnsweredQuestionsByObj(this.allObj[i]),
          ];
        }
      }
    } else {
      this.allObj.forEach((obj) => {
        unansweredQuestionsInPage = [...unansweredQuestionsInPage, ...this.getUnAnsweredQuestionsByObj(obj)];
      });
    }
    return unansweredQuestionsInPage;
  }

  private getUnansweredQuestionNumbersInCurrentPage(): number[] {
    let unansweredQuestionNumbersInPage = [];
    if (!(this.config.itemsPerPage >= this.config.totalItems)) {
      const betweenItems = {
        start: this.config.currentPage * this.config.itemsPerPage - this.config.itemsPerPage,
        end: this.config.currentPage * this.config.itemsPerPage - 1,
      };
      for (let i = betweenItems.start; i <= betweenItems.end; i++) {
        if (typeof this.allObj[i] !== 'undefined') {
          unansweredQuestionNumbersInPage = [
            ...unansweredQuestionNumbersInPage,
            ...this.getUnAnsweredQuestionNumbersByObj(this.allObj[i]),
          ];
        }
      }
    } else {
      this.allObj.forEach((obj) => {
        unansweredQuestionNumbersInPage = [
          ...unansweredQuestionNumbersInPage,
          ...this.getUnAnsweredQuestionNumbersByObj(obj),
        ];
      });
    }

    return unansweredQuestionNumbersInPage;
  }

  private getUnAnsweredQuestionsByObj(obj: any): number[] {
    let unansweredQuestions = [];
    if (obj.networkType === 'questionGroup') {
      obj.questions.reduce((totalUnanswered, question) => {
        if (!('userAnswer' in question)) {
          unansweredQuestions = [...unansweredQuestions, question.id];
        }
        return totalUnanswered;
      }, 0);
    } else {
      if (!('userAnswer' in obj)) {
        unansweredQuestions = [...unansweredQuestions, obj.id];
      }
    }
    return unansweredQuestions;
  }

  private getUnAnsweredQuestionNumbersByObj(obj: any): number[] {
    let unansweredQuestionNumbers = [];
    if (obj.networkType === 'questionGroup') {
      obj.questions.reduce((totalUnanswered, question) => {
        if (!('userAnswer' in question)) {
          unansweredQuestionNumbers = [...unansweredQuestionNumbers, question.questionNumber];
        }
        return totalUnanswered;
      }, 0);
    } else {
      if (!('userAnswer' in obj)) {
        unansweredQuestionNumbers = [...unansweredQuestionNumbers, obj.questionNumber];
      }
    }
    return unansweredQuestionNumbers;
  }

  isQuestionUnanswered(questionId: number): boolean {
    return this.unansweredQuestionsInCurrentPage.includes(questionId);
  }

  keepCardsortAnswersInTheOriginalOrder(cardsortQuestion: any) {
    this.saveOriginalOrderCardsortAnswersQuestionNumber = cardsortQuestion.id;

    // Remove error message on answer
    this.unansweredQuestionsInCurrentPage = this.unansweredQuestionsInCurrentPage.filter(
      (question) => question !== cardsortQuestion.id,
    );
  }

  showProgressError(): boolean {
    let show = false;
    if (this.psyTest?.answersRequired) {
      if (this.unansweredQuestionsInCurrentPage.length > 0) {
        this.unansweredQuestionNumbersInCurrentPage = this.getUnansweredQuestionNumbersInCurrentPage();
        this.generateUnAnsweredQuestionNumbersInCurrentPageMessage();
        show = true;
      } else if (
        this.progress < 100 &&
        this.math.ceil(this.config.totalItems / this.config.itemsPerPage) === this.config.currentPage
      ) {
        this.unansweredQuestionNumbersInCurrentPage = this.getUnansweredQuestionNumbersInCurrentPage();
        this.generateUnAnsweredQuestionNumbersInCurrentPageMessage();
        show = true;
      }
    }
    return show;
  }

  generateUnAnsweredQuestionNumbersInCurrentPageMessage() {
    if (this.unansweredQuestionNumbersInCurrentPage && this.unansweredQuestionNumbersInCurrentPage.length > 0) {
      this.unansweredQuestionNumbersInCurrentPageSentence = '';
      if (this.unansweredQuestionNumbersInCurrentPage.length > 2) {
        for (const [index, unAnswerQuestionNumber] of this.unansweredQuestionNumbersInCurrentPage.entries()) {
          if (index < this.unansweredQuestionNumbersInCurrentPage.length - 2) {
            this.unansweredQuestionNumbersInCurrentPageSentence =
              this.unansweredQuestionNumbersInCurrentPageSentence + unAnswerQuestionNumber + ', ';
          } else if (index === this.unansweredQuestionNumbersInCurrentPage.length - 2) {
            this.unansweredQuestionNumbersInCurrentPageSentence =
              this.unansweredQuestionNumbersInCurrentPageSentence + unAnswerQuestionNumber + ' and ';
          } else if (index === this.unansweredQuestionNumbersInCurrentPage.length - 1) {
            this.unansweredQuestionNumbersInCurrentPageSentence =
              this.unansweredQuestionNumbersInCurrentPageSentence + unAnswerQuestionNumber;
          }
        }
      } else if (this.unansweredQuestionNumbersInCurrentPage.length === 2) {
        this.unansweredQuestionNumbersInCurrentPageSentence =
          this.unansweredQuestionNumbersInCurrentPageSentence +
          this.unansweredQuestionNumbersInCurrentPage[0] +
          ' and ' +
          this.unansweredQuestionNumbersInCurrentPage[1];
      } else if (this.unansweredQuestionNumbersInCurrentPage.length === 1) {
        this.unansweredQuestionNumbersInCurrentPageSentence =
          this.unansweredQuestionNumbersInCurrentPageSentence + this.unansweredQuestionNumbersInCurrentPage[0];
      }
    }
  }

  mouseLeaveFn(questionId: number) {
    this.utilsService.isDragAndDropAnswerCard.drop = false;
    this.utilsService.isDragAndDropAnswerCard.drag = false;
    this.utilsService.stopTimer(questionId, this.testResultId);
  }

  scrollToQuestion(firstUnansweredQuestionID: string) {
    const questionElement = document.getElementById(firstUnansweredQuestionID);
    if (questionElement) {
      const offset = questionElement.offsetTop;
      this.viewportScroller.scrollToPosition([0, offset]);
    } else {
      console.error(`Element with ID ${firstUnansweredQuestionID} not found.`);
    }
  }

  ngOnDestroy(): void {
    this.isUnAnswerQuestionsInCurrentPage$?.unsubscribe();
  }
}
