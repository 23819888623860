<div class="container">
  <p>
    Select the Role that corresponds to <b>your position</b>. To start to answer your Role Navigator, click on
    <b>Fill</b>.
  </p>
  <div class="role-list">
    <mat-card
      *ngFor="let roleMandatePosition of roleMandatePositions"
      [class]="isUserRoleTitle(roleMandatePosition) ? 'user-title' : ''"
    >
      {{ roleMandatePosition.title }}
      <div>
        <button
          *ngIf="!isUserRoleTitle(roleMandatePosition)"
          mat-button
          class="btn-primary"
          (click)="onFill(roleMandatePosition)"
        >
          Fill
        </button>

        <button *ngIf="isUserRoleTitle(roleMandatePosition)" mat-button class="btn-primary" (click)="onNext()">
          Edit
        </button>
      </div>
    </mat-card>
  </div>

  <div id="next" *ngIf="userInfoData?.roleMandatePositionId">
    <p>
      By clicking on <b>Next</b> you'll keep the position highlighted. If you want to change, please click on the option
      <b>Fill</b> from the new position.
    </p>

    <div class="row">
      <button mat-raised-button color="primary" class="btn-success btn-center btn-300" (click)="onNext()">Next</button>
    </div>
  </div>
</div>
