<div [id]="question.id">
  <div style="text-align: center; margin-bottom: 12px">
    <span>{{ question.questionNumber }}) </span><app-question-text [text]="question.text"></app-question-text>
    <mat-error *ngIf="errorMessage && errorMessage.length > 0" class="text-center">
      <p>{{ errorMessage }}</p>
    </mat-error>
    <div style="text-align: center; margin: 6px" class="info_tooltip">
      <mat-icon
        style="cursor: pointer"
        [matTooltip]="cardSortTooltip"
        [matTooltipPosition]="'below'"
        (click)="toolTipInfo.show()"
        (mouseenter)="$event.stopImmediatePropagation()"
        (mouseleave)="$event.stopImmediatePropagation()"
        #toolTipInfo="matTooltip"
        >info</mat-icon
      >
    </div>
  </div>

  <div class="instructions" *ngIf="question.instructions && question.instructions !== 'null'">
    <mat-icon matTooltip="{{ getInstructionInnerHTML(question.instructions) }}" [matTooltipPosition]="tooltipPosition"
      >info</mat-icon
    >
  </div>
  <div>
    <div *ngIf="answering && question">
      <button
        *ngIf="!question.showDiscarded && lastAnswer && !undone"
        (click)="undo()"
        class="btn-center"
        mat-icon-button
        aria-label="Refresh"
      >
        <mat-icon>refresh</mat-icon>
      </button>

      <!-- cards -->
      <div
        id="answers{{ question.id }}"
        cdkDropList
        [cdkDropListData]="answers"
        class="cardsort-list"
        (cdkDropListDropped)="drop($event, true)"
        cdkDropListConnectedTo="deleted{{ question.id }}"
        [cdkDropListEnterPredicate]="deletePredicate"
        #cardAsnwersList="cdkDropList"
        [matTooltip]="cardSortTooltip"
        [matTooltipDisabled]="isTooltipDisabled"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()"
        [matTooltipPosition]="'right'"
        matTooltipClass="custom-tooltip right"
      >
        <div
          class="card"
          *ngFor="let answer of answers"
          cdkDrag
          [cdkDragData]="answer.id"
          [cdkDragDisabled]="
            !answering || (psyTest && !psyTest.discardAll && answers.length <= 1) || (!psyTest && answers.length <= 1)
          "
          cdkDragLockAxis="y"
          (cdkDragStarted)="cdkDragStarted($event)"
        >
          <div class="test-custom-placeholder" *cdkDragPlaceholder></div>
          <mat-icon class="menu">menu</mat-icon>
          <span class="content">
            <span class="text"> {{ answer.text }} </span>
            <span class="subscale" *ngIf="answer.subscale"> {{ answer.subscale.title }} </span>
          </span>
        </div>
      </div>

      <!-- garbage -->
      <div *ngIf="!question.showDiscarded || deletedAnswers.length === 0" class="test-container-deleted">
        <div
          id="deleted{{ question.id }}"
          cdkDropList
          [cdkDropListData]="deletedAnswers"
          cdkDropListConnectedTo="answers{{ question.id }}"
          class="test-list-deleted"
          (cdkDropListDropped)="drop($event)"
          (cdkDropListEntered)="entered($event)"
          [cdkDropListEnterPredicate]="deletePredicate"
          #deteletedAnswersList="cdkDropList"
        >
          <div class="test-box-deleted">
            <div class="trash_panel">
              <mat-icon
                inline="true"
                matBadgeOverlap="false"
                matBadgePosition="before"
                matBadgeSize="medium"
                [class.mat-icon-entered]="cdkEntered"
                >delete</mat-icon
              >
              <span class="trash_message">If an item does not fit you at all, drag here</span>
            </div>
          </div>
        </div>
      </div>

      <!-- deleted list -->
      <p *ngIf="question.showDiscarded && deletedAnswers.length > 0"><b>Discarded</b></p>
      <mat-card
        *ngIf="question.showDiscarded && deletedAnswers.length > 0"
        id="deleted{{ question.id }}"
        cdkDropList
        [cdkDropListData]="deletedAnswers"
        cdkDropListConnectedTo="answers{{ question.id }}"
        (cdkDropListDropped)="drop($event)"
        (cdkDropListEntered)="entered($event)"
        class="card-discarded"
        #deteletedAnswersList
      >
        <div
          class="card delete_list"
          *ngFor="let answer of deletedAnswers"
          cdkDrag
          [cdkDragData]="answer.id"
          [cdkDragDisabled]="!answering"
        >
          <div class="test-custom-placeholder" *cdkDragPlaceholder></div>
          <span class="text"> {{ answer.text }} </span>
          <span class="subscale" *ngIf="answer.subscale"> {{ answer.subscale.title }} </span>
        </div>
      </mat-card>
    </div>
    <div
      *ngIf="!answering && question"
      class="cardsort-list cardsort-list-wd"
      [matTooltip]="cardSortTooltip"
      [matTooltipPosition]="'right'"
      matTooltipClass="custom-tooltip right"
    >
      <div class="card" *ngFor="let answer of answers">
        <mat-icon class="menu">menu</mat-icon>
        <span class="content">
          <span class="text"> {{ answer.text }} </span>
          <span class="subscale" *ngIf="answer.subscale"> {{ answer.subscale.title }} </span>
        </span>
      </div>
    </div>
    <div id="privacy" *ngIf="answers.length > 0">
      <mat-slide-toggle
        *ngIf="question.acl === 5"
        color="primary"
        [disabled]="!answering"
        [checked]="answerPrivate"
        (change)="togglePrivacy()"
      >
        This answer is private
      </mat-slide-toggle>
    </div>
    <div id="privacy-message" *ngIf="answers.length === 0">
      {{ message }}
    </div>
  </div>
</div>
