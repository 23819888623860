<div class="row" *ngIf="showSearch != null ? showSearch : true">
  <input
    type="text"
    class="form-control"
    placeholder="Enter a key word to search"
    [(ngModel)]="searchField"
    (ngModelChange)="this.searchFieldUpdate.next($event)"
  />
</div>

<div cdkDropList (cdkDropListDropped)="onSort($event)">
  <mat-card
    *ngFor="let psbItem of filteredItems | paginate: config | slice: 0:itemsPerPage"
    class="list-item"
    cdkDrag
    [cdkDragDisabled]="sort.observers.length === 0"
  >
    <div class="card-header">
      <div
        matSort
        class="pb-10"
        (matSortChange)="sortTitleData($event)"
        [ngClass]="textSize ? 'column' : textSize + ' column'"
      >
        <div class="row" mat-sort-header="title" [ngClass]="userInvite === true ? 'title-description' : ''">
          <span class="title sort" [matTooltip]="psbItem.testResults ? getToolTip(psbItem.testResults) : null">{{
            psbItem.title | stripeHtml
          }}</span>
          <mat-icon *ngIf="isExpansionPanel" (click)="psbItem.expanded = !psbItem.expanded">{{
            psbItem.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
          }}</mat-icon>
        </div>
        <div class="row">
          <span class="description">{{ psbItem.description }}</span>
        </div>
      </div>
      <div
        matSort
        class="pb-10"
        (matSortChange)="sortSubscaleData($event)"
        *ngIf="typeOfView == 'questionAnswerSubscaleTitle' && isIntegerAnswer == false"
        [ngClass]="textSize ? 'column' : textSize + ' column'"
      >
        <div class="row" mat-sort-header="subscale">
          <span class="title sort">{{ psbItem.subscaleTitle | stripeHtml }}</span>
        </div>
        <div class="row">
          <span class="description">Subscale</span>
        </div>
      </div>

      <div
        *ngIf="psbItem.status && psbItem.status.length > 0"
        class="column pb-10"
        [ngStyle]="{ width: columnNumber ? 'calc(100% / ' + columnNumber + ')' : '' }"
      >
        <mat-chip-list>
          <mat-chip> {{ psbItem.status }}</mat-chip>
        </mat-chip-list>
        <div class="row">
          <span class="status">Status</span>
        </div>
      </div>

      <div
        *ngIf="psbItem.progress >= 0"
        class="column pb-10"
        [ngStyle]="{ width: columnNumber ? 'calc(100% / ' + columnNumber + ')' : '' }"
      >
        <mat-progress-bar mode="determinate" [value]="psbItem.progress"></mat-progress-bar>
        <div class="row">
          <span class="description">{{ psbItem.progressDescription }}</span>
        </div>
      </div>

      <ng-container *ngIf="psbItem.extraColumns && psbItem.extraColumns.length > 0">
        <div
          matSort
          (matSortChange)="sortExtraColumnsData($event, i)"
          class="column pb-10"
          [ngStyle]="{ width: columnNumber ? 'calc(100% / ' + columnNumber + ')' : '' }"
          *ngFor="let column of psbItem.extraColumns; let i = index"
        >
          <div class="row" mat-sort-header="{{ column['key'] }}">
            <span class="title sort">{{ column.value }}</span>
          </div>
          <div class="row">
            <span class="description">{{ column.key }}</span>
          </div>
        </div>
      </ng-container>

      <div class="center-buttons">
        <button *ngIf="ranges.observers.length > 0" mat-button class="btn-view" (click)="onViewRanges(psbItem.id)">
          {{ viewLabel ? viewLabel : 'Ranges' }}
        </button>
        <button
          *ngIf="percentiles.observers.length > 0"
          mat-button
          class="btn-dashboard"
          (click)="onViewPercentiles(psbItem)"
        >
          {{ editLabel ? editLabel : 'Percentiles' }}
        </button>
        <button *ngIf="view.observers.length > 0" mat-button class="btn-view" (click)="onView(psbItem.id)">
          {{ viewLabel ? viewLabel : 'View' }}
        </button>
        <button *ngIf="edit.observers.length > 0" mat-button class="btn-edit" (click)="onEdit(psbItem.id)">
          {{ editLabel ? editLabel : 'Edit' }}
        </button>
        <button
          *ngIf="testResult.observers.length > 0"
          mat-button
          class="btn-test-result"
          (click)="onViewTestResult(psbItem.id)"
        >
          Test Result
        </button>
        <button *ngIf="copyEvent.observers.length > 0" mat-button class="btn-copy" (click)="onCopy(psbItem.id)">
          Copy
        </button>
        <button
          *ngIf="dashboard.observers.length > 0"
          mat-raised-button
          class="btn-dashboard"
          (click)="onDashboard(psbItem.id)"
        >
          {{ dashboardLabel ? dashboardLabel : 'Dashboard' }}
        </button>

        <button
          *ngIf="selectOption.observers.length > 0"
          mat-button
          [matMenuTriggerFor]="menu"
          [disabled]="psbItem.progress == 0 || psbItem.reportModel || (psbItem.privateScores && isTGM)"
          [class]="{
            'report-btn-disabled': psbItem.progress == 0 || psbItem.reportModel || (psbItem.privateScores && isTGM)
          }"
          class="btn-view"
        >
          {{ this.optionButton }}
        </button>

        <button *ngIf="delete.observers.length > 0" mat-button class="btn-delete" (click)="onDelete(psbItem.id)">
          {{ deleteLabel ? deleteLabel : 'Delete' }}
        </button>
        <mat-menu #menu="matMenu" class="menu-without-scroll">
          <button mat-menu-item *ngFor="let option of options" (click)="onSelectOption(psbItem.id, option.id)">
            {{ option.title }}
          </button>
        </mat-menu>
        <button *ngIf="archive.observers.length > 0" mat-button class="btn-archive" (click)="onArchive(psbItem.id)">
          {{ archiveLabel ? archiveLabel : 'Archive' }}
        </button>
      </div>
    </div>
    <div
      class="test-scale-list"
      *ngIf="isExpansionPanel && !!psbItem.expanded"
      cdkDropList
      (cdkDropListDropped)="onSortChild($event, psbItem)"
    >
      <div
        class="test-scale-list-item"
        cdkDrag
        [cdkDragData]="psyTestGroupTestScale"
        [cdkDragDisabled]="sortChild.observers.length === 0"
        *ngFor="let psyTestGroupTestScale of psbItem.psyTestGroupTestScales"
      >
        <p>{{ psyTestGroupTestScale.title }}</p>
        <mat-button-toggle-group (change)="onTestScaleVisibleChange(psyTestGroupTestScale.id, $event)">
          <mat-button-toggle [checked]="psyTestGroupTestScale.visible" [value]="true">Visible</mat-button-toggle>
          <mat-button-toggle [checked]="!psyTestGroupTestScale.visible" [value]="false">Invisible</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </mat-card>
</div>

<pagination-controls
  *ngIf="items && items.length > 0"
  class="pagination"
  (pageChange)="pageChanged($event)"
  [id]="this.id"
>
</pagination-controls>
