import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class MFARequiredModalService {
  constructor(private http: HttpClient, private router: Router) {}

  checkIfMFARequired() {
    return this.http.get<boolean>(
      BACKEND_URL + '/authentication/check-if-need-enroll-in-mfa/' + localStorage.getItem('userInfoId'),
    );
  }
}
