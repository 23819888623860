import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { CreateSubscaleQuestionGroupDialogComponent } from '../create-subscale-question-group-dialog/create-subscale-question-group-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';
import { QuestionGroup } from '@app/core/models/question-group.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { SubscaleBreadcrumbService } from '@app/shared/services/subscale-breadcrumb.service';

@Component({
  selector: 'app-subscale-question-group-list',
  templateUrl: './subscale-question-group-list.component.html',
  styleUrls: ['./subscale-question-group-list.component.scss'],
})
export class SubscaleQuestionGroupListComponent implements OnInit {
  @Input() subscaleId: number;
  @Input() subscaleTitle: string;
  @Input() questionGroupDetail: string;
  questionGroups: PsbItem[] = [];

  constructor(
    private questionGroupService: QuestionGroupService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private router: Router,
    private breadScrumService: SubscaleBreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.loadAllQuestionGroupBySubscaleId();
  }

  loadAllQuestionGroupBySubscaleId() {
    this.questionGroups = [];
    this.questionGroupService.getAllBySubscaleId(this.subscaleId).subscribe((questionGroups: QuestionGroup[]) => {
      questionGroups.map((questionGroup) => {
        const item: PsbItem = {
          id: questionGroup.id,
          title: questionGroup.title,
          extraColumns: [
            {
              key: 'Instruction',
              value: questionGroup.instructions ? questionGroup.instructions : null,
            },
            {
              key: 'Order',
              value: questionGroup.aorder ? questionGroup.aorder : null,
            },
          ],
        };
        this.questionGroups = [...this.questionGroups, item];
      });
    });
  }

  onCreate() {
    this.dialog
      .open(CreateSubscaleQuestionGroupDialogComponent, { data: { isEdit: false } })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          const questionGroup: QuestionGroup = result.data;
          questionGroup.subscaleId = this.subscaleId;
          this.questionGroupService.create(questionGroup).subscribe(() => {
            this.loadAllQuestionGroupBySubscaleId();
            this.snackBar.info('Subscale Question Group created with success!');
          });
        }
      });
  }

  onDelete(questionGroupId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.questionGroupService.delete(questionGroupId).subscribe(() => {
          this.questionGroups = this.questionGroups.filter((questionGroup) => questionGroup.id !== questionGroupId);
          this.snackBar.info('Question Group deleted with success!');
        });
      });
  }

  onView(questionGroupId: number) {
    this.breadScrumService.setBreadCrumbNavigation('question_group_details', {
      subScaleTitle: this.subscaleTitle,
      subScaleId: this.subscaleId,
      questionGroupId: questionGroupId,
      questionGroupTitle: this.questionGroups.filter((e) => e.id == questionGroupId)[0]?.title,
    });
    localStorage.setItem(
      'breadscrum',
      JSON.stringify({
        subScaleTitle: this.subscaleTitle,
        subScaleId: this.subscaleId,
        questionGroupId: questionGroupId,
        questionGroupTitle: this.questionGroups.filter((e) => e.id == questionGroupId)[0]?.title,
      }),
    );
    return this.router.navigate([`/subscale/${this.subscaleId}/question/group/${questionGroupId}/detail`]);
  }
}
