import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';

import { AreaMappingsRoutingModule } from './area-mappings-routing.module';

import { AreaMappingsComponent } from './area-mappings/area-mappings.component';
import { AreaMappingDetailsComponent } from './area-mapping-details/area-mapping-details.component';
import { CreateNewAreaDialogComponent } from './create-new-area-dialog/create-new-area-dialog.component';
import { AreaMappingAreasComponent } from './area-mapping-areas/area-mapping-areas.component';
import { AreaFormDialogComponent } from './area-form-dialog/area-form-dialog.component';
import { AreaCirclePlotComponent } from './area-mapping-charts/area-circle-plot/area-circle-plot.component';
import { AreaHexagonComponent } from './area-mapping-charts/area-hexagon/area-hexagon.component';
import { AreaFourOneCartesianComponent } from './area-mapping-charts/area-four-one-cartesian/area-four-one-cartesian.component';

@NgModule({
  declarations: [
    AreaMappingsComponent,
    AreaMappingDetailsComponent,
    CreateNewAreaDialogComponent,
    AreaMappingAreasComponent,
    AreaFormDialogComponent,
    AreaCirclePlotComponent,
    AreaHexagonComponent,
    AreaFourOneCartesianComponent,
  ],
  imports: [CommonModule, AreaMappingsRoutingModule, SharedModule, ReactiveFormsModule],
})
export class AreaMappingsModule {}
