<div class="container">
  <mat-card>
    <div *ngIf="requestResponse">
      <h3>Your password was changed! Now log in with your new password.</h3>
    </div>
    <div *ngIf="!requestResponse && userData">
      <h2>Hello {{ userData.firstName }}</h2>
      <h3>Please, set your new password</h3>
      <form [formGroup]="form" (submit)="onSubmit()">
        <mat-form-field>
          <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
          <input
            matInput
            #password
            (focus)="focusInPassword()"
            (focusout)="focusOutPassword()"
            [type]="toggle.type"
            required
            formControlName="password"
            placeholder="Password"
            maxlength="25"
            type="password"
          />
          <mat-hint align="end" aria-live="polite"> {{ password.value.length }} / 25 </mat-hint>
          <mat-error *ngIf="form.get('password').invalid">Please enter a password.</mat-error>
        </mat-form-field>
        <mat-password-strength [password]="password.value"></mat-password-strength>
        <mat-form-field>
          <input
            matInput
            type="password"
            required
            formControlName="passwordConfirmation"
            placeholder="Password Confirmation"
            maxlength="25"
            [ngClass]="{ 'is-invalid': formControls.passwordConfirmation.errors }"
          />
          <mat-error *ngIf="form.get('passwordConfirmation').invalid">Please enter the same password.</mat-error>
        </mat-form-field>
        <button mat-raised-button type="submit" color="primary" [disabled]="form.invalid">Reset Password</button>
        <div class="strength-panel" [ngClass]="{ 'hide-password-info': !showWarningPanel }">
          <mat-password-strength
            (onStrengthChanged)="onStrengthChanged($event)"
            #passwordComponent
            [password]="password.value"
          >
          </mat-password-strength>
          <mat-password-strength-info #passwordInfo [passwordComponent]="passwordComponent">
          </mat-password-strength-info>
        </div>
      </form>
    </div>
  </mat-card>
</div>
