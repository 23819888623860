import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ordinal-paragraph-section',
  templateUrl: './ordinal-paragraph-section.component.html',
  styleUrls: ['./ordinal-paragraph-section.component.scss'],
})
export class OrdinalParagraphSectionComponent {
  @Input() ordinalParagraph: any;

  constructor() {}
}
