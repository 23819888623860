<div class="report-tab-title" *ngIf="isAdmin">
  <div>
    <h2>Report Model</h2>
    <p>Choose the visual identity of the results presentation.</p>
  </div>
  <div class="mt-4">
    <button mat-raised-button color="primary" (click)="expandReportModel()">
      {{ reportModelExpanded ? 'Collapse' : 'Expand' }}
      <mat-icon>
        {{ reportModelExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
      </mat-icon>
    </button>
  </div>
</div>
<div *ngIf="reportModelExpanded">
  <div *ngIf="reportModel">
    <button mat-raised-button color="warn" (click)="onDeleteReportModel()">
      <mat-icon>delete</mat-icon>Remove Model
    </button>
    <app-report-model-view *ngIf="reportModel" [reportModel]="reportModel"></app-report-model-view>
  </div>
  <div *ngIf="!reportModel" class="mb-4">
    <button mat-raised-button color="warn" (click)="onAddReportModel()"><mat-icon>add</mat-icon>Add Model</button>
  </div>
</div>
<mat-divider></mat-divider>
<div class="report-tab-title" *ngIf="isAdmin">
  <div>
    <h2>Reports</h2>
    <p>Add the reports</p>
  </div>
  <div class="mt-4">
    <button mat-raised-button color="primary" (click)="expandReports()">
      {{ reportExpanded ? 'Collapse' : 'Expand' }}
      <mat-icon>
        {{ reportExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
      </mat-icon>
    </button>
  </div>
</div>
<div *ngIf="reportExpanded">
  <button mat-raised-button color="primary" (click)="onAddReport()"><mat-icon>add</mat-icon>Add Report</button>
  <app-psb-list
    *ngIf="reportPsbItems.length > 0"
    [items]="reportPsbItems"
    [itemsPerPage]="10"
    [showSearch]="false"
    (delete)="onDeleteReport($event)"
  >
  </app-psb-list>
</div>
<mat-divider></mat-divider>
<div class="report-tab-title">
  <div>
    <h2>Scorecards</h2>
    <p>Configurations of Scorecards</p>
  </div>
  <div class="mt-4">
    <button mat-raised-button color="primary" (click)="expandScoreCards()">
      {{ scoreCardExpanded ? 'Collapse' : 'Expand' }}
      <mat-icon>
        {{ scoreCardExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
      </mat-icon>
    </button>
  </div>
</div>
<div *ngIf="scoreCardExpanded">
  <mat-card [ngClass]="isAdmin ? 'card' : 'tgm-card'">
    <mat-card-title>Report</mat-card-title>
    <mat-card-subtitle>Show Scorecards on Report</mat-card-subtitle>
    <mat-card-content>
      <mat-button-toggle-group (change)="onScoreCardValChange()" [formControl]="scoreCardTriggerControl">
        <mat-button-toggle [value]="true">ON</mat-button-toggle>
        <mat-button-toggle [value]="false">OFF</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card-content>
  </mat-card>

  <mat-card class="card" *ngIf="isAdmin">
    <mat-card-title>Custom Ranges</mat-card-title>
    <app-custom-range [testGroupId]="testGroupId"></app-custom-range>
  </mat-card>

  <mat-card class="card" *ngIf="isAdmin">
    <mat-card-title>Ranges</mat-card-title>
    <mat-card-subtitle>Open the Ranges of the test to add the values to each subscale.</mat-card-subtitle>
    <mat-card-content>
      <app-psb-list
        *ngIf="testPsbItems.length > 0"
        (sortChild)="onSortChild($event)"
        (sort)="onSort($event)"
        [items]="testPsbItems"
        [itemsPerPage]="10"
        [showSearch]="false"
        [isExpansionPanel]="true"
        (ranges)="onViewSubscaleRanges($event)"
        (percentiles)="onViewPercentiles($event)"
        (testScaleVisible)="onTestScaleVisibleChange($event)"
      >
      </app-psb-list>
    </mat-card-content>
  </mat-card>

  <mat-card class="card" *ngIf="isAdmin">
    <mat-card-title>Superscale Ranges</mat-card-title>
    <mat-card-subtitle>Add a Superscale</mat-card-subtitle>
    <div class="superscale-button">
      <button mat-raised-button color="primary" (click)="addSuperscale()" class="add-superscale">
        <mat-icon>add</mat-icon>Add Superscale
      </button>
      <button mat-raised-button color="accent" (click)="onViewSuperscaleRanges()">
        <mat-icon> linear_scale</mat-icon>Ranges
      </button>
    </div>
    <mat-card-content>
      <app-psb-list
        *ngIf="superscalePsbItems.length > 0"
        [items]="superscalePsbItems"
        [itemsPerPage]="10"
        [showSearch]="false"
        (delete)="onDeleteSuperscale($event)"
      >
      </app-psb-list>
    </mat-card-content>
  </mat-card>
</div>
