<mat-card>
  <form [formGroup]="editDecisionAreaForm" #formDirective="ngForm" (ngSubmit)="editDecisionArea(formDirective)">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" />
      <mat-error *ngIf="editDecisionAreaForm.get('name').errors">Please, enter a valid text.</mat-error>
      <mat-hint>Name of the Decision Area</mat-hint>
    </mat-form-field>
    <div class="edit-buttons-container text-center">
      <button mat-raised-button color="primary" type="submit" class="btn-success btn-300">Update</button>
      <button mat-raised-button class="btn-success btn-300" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</mat-card>
