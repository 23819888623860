<div class="container">
  <div class="form">
    <form (submit)="onSearch()" [formGroup]="searchForm">
      <mat-form-field>
        <mat-label>Decisions</mat-label>
        <input matInput formControlName="phrase" placeholder="Ex. product design" />
        <mat-icon matSuffix color="primary" (click)="onSearch()">search</mat-icon>
        <mat-error *ngIf="searchForm.get('phrase').errors"
          >Please, enter a valid text with at least 3 characters.
        </mat-error>
      </mat-form-field>
    </form>
    <a routerLink="/dashboard" id="dashboard-button">return to dashboard</a>
  </div>
  <div id="result-container">
    <p *ngIf="searchResults.length === 0">Nothing Found</p>
    <div *ngFor="let result of searchResults" id="result">
      <div id="result-main">
        <a
          [routerLink]="['/role-mandate/' + testId + '/user/' + result.user.userInfoId + '/results']"
          fragment="{{ result.id }}"
          >{{ result.text }}</a
        >
        <p>{{ result.user.username }}</p>
        <p>{{ result.user.companyRole }}</p>
        <p>Decision Table Name: {{ result.decisionTable.title }}</p>
      </div>
      <div id="result-connections" *ngIf="result.userConnections">
        <p>Users related</p>
        <p *ngFor="let userConnection of result.userConnections">
          {{ userConnection }}
        </p>
      </div>
    </div>
  </div>
</div>
