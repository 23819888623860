import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { UserService } from '@app/core/services/user.service';
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { TestObjectService } from '@app/core/services/test-object.service';

// models
import { Question } from '@app/core/models/question.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { UserInfo } from '@app/core/models/userInfo.model';
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';
import { QuestionGroup } from '@app/core/models/question-group.model';

@Component({
  selector: 'app-test-connections-indicator-user-result',
  templateUrl: './test-connections-indicator-user-result.component.html',
  styleUrls: ['./test-connections-indicator-user-result.component.scss'],
})
export class TestConnectionsIndicatorUserResultComponent implements OnInit {
  connectionsIndicatorId: number;
  userInfoId: number;
  userInfoData: UserInfo;
  connectionsIndicator: ConnectionIndicator;
  questions: Question[];
  decisionTables: DecisionTable[];
  questionGroups: QuestionGroup[];
  allObj: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private cIService: ConnectionsIndicatorService,
    private questionGroupService: QuestionGroupService,
    public testObjectService: TestObjectService,
  ) {}

  ngOnInit(): void {
    this.connectionsIndicatorId = this.route.snapshot.params['id'];
    this.userInfoId = this.route.snapshot.params['userId'];

    this.cIService.getById(this.connectionsIndicatorId).subscribe((result) => {
      this.connectionsIndicator = result;
    });

    this.userService.get(this.userInfoId).subscribe({
      next: (result) => {
        this.userInfoData = result;
      },
    });

    forkJoin([
      this.questionGroupService.getAllQuestionGroupForConnectionIndicator(this.connectionsIndicatorId),
      this.cIService.getAllQuestionById(this.connectionsIndicatorId),
      this.cIService.getAllDecisionTableById(this.connectionsIndicatorId),
    ]).subscribe((results) => {
      this.questionGroups = results[0];

      this.questionGroups.forEach((questionGroup) => {
        questionGroup.questions = results[1].filter((question) => question.questionGroupId === questionGroup.id);
      });

      this.questions = results[1].filter(
        (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
      );

      this.decisionTables = results[2];

      this.allObj = [
        ...this.questionGroups.map((obj) => {
          obj.networkType = 'questionGroup';
          return obj;
        }),
        ...this.decisionTables.map((obj) => {
          obj.networkType = 'decisionTable';
          return obj;
        }),
        ...this.questions.map((obj) => {
          obj.networkType = 'question';
          return obj;
        }),
      ];

      this.allObj = this.allObj.sort((a, b) => {
        return a.aorder - b.aorder;
      });
    });
  }

  goBack() {
    this.router.navigate([`/user/connections-indicator/${this.connectionsIndicatorId}/user-list`]);
  }
}
