<h1 mat-dialog-title>Update chapter</h1>
<form id="updateChapterForm" [formGroup]="form" (submit)="onSubmit()" mat-dialog-content>
  <mat-form-field>
    <mat-label>Title</mat-label>
    <input matInput type="text" formControlName="title" />
    <mat-error *ngIf="form.get('title').hasError">Title is required</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Second Tier Optimal Scores Heading</mat-label>
    <input matInput type="text" formControlName="secondTierOptimalScoresHeading" />
  </mat-form-field>
</form>
<div mat-dialog-actions class="actions">
  <button mat-flat-button mat-dialog-close>
    <mat-icon>block</mat-icon>
    Cancel
  </button>
  <button mat-flat-button color="primary" type="submit" form="updateChapterForm" [disabled]="form.invalid">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</div>
