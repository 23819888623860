import { Injectable } from '@angular/core';
import * as d3 from 'd3';

import { Node, Link, ForceDirectedGraph } from '../models/d3';

// TODO: Finish zoom and drag methods
@Injectable({ providedIn: 'root' })
export class D3Service {
  /** This service will provide methods to enable user interaction with elements
   * while maintaining the d3 simulations physics
   */
  constructor() {}

  /** A method to bind a pan and zoom behaviour to an svg element */
  applyZoomableBehaviour(svgElement, containerElement) {
    let svg;
    let container;
    let zoomed;
    let zoom;

    svg = d3.select(svgElement);
    container = d3.select(containerElement);

    zoomed = (event) => {
      const transform = event.transform;
      container.attr('transform', 'translate(' + transform.x + ',' + transform.y + ') scale(' + transform.k + ')');
    };

    zoom = d3.zoom().on('zoom', zoomed);
    svg.call(zoom);
  }

  /** A method to bind a draggable behaviour to an svg element */
  applyDraggableBehaviour(element, node: Node, graph: ForceDirectedGraph) {
    const d3Element = d3.select(element);

    function started(event) {
      /** Preventing propagation of dragstart to parent elements */
      event.sourceEvent.stopPropagation();

      if (!event.active) {
        graph.simulation.alphaTarget(0.3).restart();
      }
    }

    function dragged(event) {
      node.fx = event.x;
      node.fy = event.y;
    }

    function ended(event) {
      if (!event.active) {
        graph.simulation.alphaTarget(0);
      }

      node.fx = null;
      node.fy = null;
    }

    d3Element.call(d3.drag().on('start', started).on('drag', dragged).on('end', ended));
  }

  /** The interactable graph we will simulate in this article
   * This method does not interact with the document, purely physical calculations with d3
   */
  getForceDirectedGraph(nodes: Node[], links: Link[], options: { width; height }) {
    const sg = new ForceDirectedGraph(nodes, links, options);
    return sg;
  }
}
