/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ConnectionIndicatorService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve user's connection indicator report
     * Retrieve user's connection indicator report
     * @param userId Id of the user
     * @returns any User's report
     * @throws ApiError
     */
    public getReportUserConnectionIndicatorDownload(
        userId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/user/{userId}/connection-indicator/download',
            path: {
                'userId': userId,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve connection indicator report
     * Retrieve connection indicator report
     * @param id Id of the connection indicator
     * @returns any Connection Indicator report
     * @throws ApiError
     */
    public getReportConnectionIndicatorDownload(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/connection-indicator/{id}/download',
            path: {
                'id': id,
            },
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

}
