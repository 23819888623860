<div class="row">
  <div class="column"><h2>Users Decisions</h2></div>
  <div class="column">
    <button
      mat-raised-button
      color="primary"
      aria-label="Download Decisions"
      class="pull-right"
      (click)="downloadDecisions()"
    >
      Download Excel
      <mat-icon matSuffix>file_download</mat-icon>
    </button>
  </div>
</div>

<mat-form-field>
  <mat-label>Filter</mat-label>
  <input
    matInput
    (keyup)="applyFilter($event)"
    placeholder="Ex. Bryce, Consultant, CEO, etc"
    #input
    autocomplete="off"
  />
</mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="tasksSource" matSort #sortData="matSort">
    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User Owner</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.userName">{{ row.userName }}</td>
    </ng-container>

    <ng-container matColumnDef="decisionArea">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Decision Area</th>
      <td mat-cell *matCellDef="let row">{{ row.decisionArea }}</td>
    </ng-container>

    <ng-container matColumnDef="task">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Decision To Be Made</th>
      <td mat-cell *matCellDef="let row">{{ row.task }}</td>
    </ng-container>

    <ng-container matColumnDef="departmentName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.departmentName">{{ row.departmentName }}</td>
    </ng-container>

    <ng-container matColumnDef="roleTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
      <td mat-cell *matCellDef="let row" [style.color]="row.roleTitle">{{ row.roleTitle }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedTasksColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedTasksColumns"></tr>
  </table>

  <mat-paginator #paginatorData [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons> </mat-paginator>
</div>
