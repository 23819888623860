<button mat-icon-button aria-label="Notifications" *ngIf="newNotifications" [matMenuTriggerFor]="notifications">
  <mat-icon
    [matBadge]="newNotifications.length.toString()"
    [matBadgeHidden]="!hasNew"
    matBadgeColor="#c4c4c4"
    matBadgeSize="small"
    color="black"
    >notifications</mat-icon
  >
</button>

<mat-menu #notifications="matMenu" xPosition="before">
  <button
    mat-menu-item
    *ngFor="let notification of newNotifications"
    [routerLink]="['/user/notifications']"
    fragment="{{ notification.id }}"
  >
    {{ notification.message }}
  </button>
  <button mat-menu-item [routerLink]="['/user/notifications']">See all notifications...</button>
</mat-menu>
