import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-layout-tgm',
  templateUrl: './layout-tgm.component.html',
  styleUrls: ['./layout-tgm.component.scss'],
})
export class LayoutTgmComponent {
  mobileQuery: MediaQueryList;
  toggled = window.innerWidth > 1200;
  private mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
  }

  onToggle(toggled: boolean) {
    this.toggled = toggled;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.toggled = window.innerWidth > 1200;
  }
}
