import { Component, OnInit } from '@angular/core';
import { UserInfo } from '@app/core/models/userInfo.model';

// services
import { UserService } from '@app/core/services/user.service';
import { SystemSettingsService } from '@app/core/services/system-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Psybil';
  maintenanceEnabled: boolean;
  userIsAuthenticated: boolean;
  userData: UserInfo;
  isAdmin: boolean;

  constructor(private userService: UserService, private systemSettingsService: SystemSettingsService) {}

  ngOnInit() {
    this.userService.autoAuthUser();

    this.userService.isAuthenticated$.subscribe((result) => {
      this.userIsAuthenticated = result;
      this.systemSettingsService.getMaintenanceMode().subscribe((result) => {
        result.maintenanceEnabled === 0 ? (this.maintenanceEnabled = false) : (this.maintenanceEnabled = true);
      });

      this.userData = this.userService.getUserData();
      if (this.userData) {
        this.isAdmin = this.userData.authorities.find((authority) => authority.name === 'ROLE_ADMIN') ? true : false;
      }
    });
  }
}
