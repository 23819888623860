import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { CreateSubscaleQuestionDialogComponent } from '../create-subscale-question-dialog/create-subscale-question-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { QuestionService } from '@app/core/services/question.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { Question } from '@app/core/models/question.model';
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-subscale-question-list',
  templateUrl: './subscale-question-list.component.html',
  styleUrls: ['./subscale-question-list.component.scss'],
})
export class SubscaleQuestionListComponent implements OnInit {
  @Input() subscaleId: number;
  questions: PsbItem[] = [];

  constructor(
    private questionService: QuestionService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loadAllQuestionBySubscaleId();
  }

  loadAllQuestionBySubscaleId() {
    this.questions = [];
    this.questionService.getAllBySubscaleId(this.subscaleId).subscribe((questions: Question[]) => {
      questions.map((question) => {
        let status: string;
        if (question.active.hasOwnProperty('data')) {
          status = parseInt(question.active['data'], 10) === 1 ? 'Active' : 'Inactive';
        } else {
          status = question.active ? 'Active' : 'Inactive';
        }
        const item: PsbItem = {
          id: question.id,
          title: question.text,
          status,
          extraColumns: [
            {
              key: 'Question Group',
              value: question.questionGroup.id ? question.questionGroup.title : 'No',
            },
            {
              key: 'Question Type',
              value: question.questionType ? question.questionType : 'No',
            },
          ],
        };
        this.questions = [...this.questions, item];
      });
    });
  }

  onCreate() {
    this.dialog
      .open(CreateSubscaleQuestionDialogComponent, { data: { isEdit: false } })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          const question: Question = result.data;
          question.subscaleId = this.subscaleId;
          this.questionService.create(question).subscribe(() => {
            this.loadAllQuestionBySubscaleId();
            this.snackBar.info('Subscale Question created with success!');
          });
        }
      });
  }

  onDelete(questionId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.questionService.delete(questionId).subscribe(() => {
          this.questions = this.questions.filter((question) => question.id !== questionId);
          this.snackBar.info('Question deleted with success!');
        }, () => {
          this.snackBar.info('Something went wrong when deleting the question.');
        });
      });
  }

  onView(questionId: number) {
    return this.router.navigate([`/subscale/${this.subscaleId}/question/${questionId}/detail`]);
  }
}
