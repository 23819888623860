/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Question } from '../models/Question';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class QuestionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all question by id
     * Retrieve all question by id
     * @param id Id of the supertest
     * @returns Question A list of questions
     * @throws ApiError
     */
    public getSupertestQuestion(
        id: number,
    ): Observable<Question> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/supertest/{id}/question',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of questions not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all question by id
     * Retrieve all question by id
     * @param id Id of the psytest
     * @returns Question A list of questions
     * @throws ApiError
     */
    public getTestPsyTestQuestion(
        id: number,
    ): Observable<Question> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/psy-test/{id}/question',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of questions not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete All User Answer
     * Delete All User Answer
     * @param id Id of the psytest
     * @param testGroupId Id of the test group
     * @param userInfoId Id of the user info
     * @returns any All User Answer deleted successfully
     * @throws ApiError
     */
    public deleteTestTestGroupUserAnswers(
        id: number,
        testGroupId: number,
        userInfoId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test/{id}/test-group/{testGroupId}/user/{userInfoId}/answers',
            path: {
                'id': id,
                'testGroupId': testGroupId,
                'userInfoId': userInfoId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
