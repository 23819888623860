<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Heading</mat-label>
    <input matInput required type="text" formControlName="heading" />
    <mat-error *ngIf="form.get('heading').hasError">You must provide a heading</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Positive Heading</mat-label>
    <input matInput type="text" formControlName="positiveHeading" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Concern Heading</mat-label>
    <input matInput type="text" formControlName="concernHeading" />
  </mat-form-field>
  <div style="display: flex; justify-content: space-between; align-items: center">
    <h2>Default Sentences Toggle</h2>
    <mat-button-toggle-group class="default-sentence-toggle" [formControl]="form.get('defaultSentenceEnabled')">
      <mat-button-toggle [value]="true">Enable</mat-button-toggle>
      <mat-button-toggle [value]="false">Disable</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <!-- Top Form -->
  <h1>Top</h1>
  <h2>Lead sentence</h2>
  <div class="lead-sentence">
    <mat-form-field>
      <mat-label>Lead Sentence</mat-label>
      <mat-select required [formControl]="form.get('leadSentence').get('top').get('option')">
        <mat-option value="he">He</mat-option>
        <mat-option value="she">She</mat-option>
        <mat-option value="they">They</mat-option>
      </mat-select>
    </mat-form-field>
    <angular-editor
      [config]="editorConfig"
      [formControl]="form.get('leadSentence').get('top').get(form.get('leadSentence').get('top').get('option').value)"
    ></angular-editor>
  </div>
  <div class="default-sentence">
    <h2>Default Sentence</h2>
    <angular-editor
      [config]="editorConfig"
      [formControl]="form.get('topDefaultSentence')"
    ></angular-editor>
  </div>
  <mat-form-field>
    <mat-label>Number of TOP subscales</mat-label>
    <input matInput [formControl]="form.get('totals').get('top')" required type="number" />
    <mat-error *ngIf="form.get('totals').get('top').hasError">Field is required.</mat-error>
  </mat-form-field>

  <div class="subscales-header">
    <h2>Subscales</h2>
    <button mat-flat-button color="primary" (click)="onAddText('top')">
      <mat-icon>add</mat-icon>
      Add new subscale
    </button>
  </div>
  <div class="texts" [formGroup]="form.get('texts')">
    <ng-container formArray="top">
      <div class="text" *ngFor="let textForm of top.controls; let i = index">
        <div class="text-item-header">
          <h3 class="title">Subscale #{{ i + 1 }}</h3>
          <button mat-flat-button color="accent" (click)="onDeleteText(i, 'top')">
            <mat-icon>delete</mat-icon>
            Delete subscale
          </button>
        </div>
        <div class="subscales">
          <div class="form">
            <mat-form-field>
              <mat-label>Subscale</mat-label>
              <input
                matInput
                required
                type="text"
                (ngModelChange)="this.topSubscaleFieldUpdate.next($event)"
                [formControl]="newSubscaleForm.get('subscale')"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTopSubscale">
                <mat-option *ngFor="let subscale of subscales | filter: topSubscaleField" [value]="subscale">
                  <div [matTooltip]="subscale.title">
                    <p class="report-scale-title-text">{{ subscale.title }}</p>
                    <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Weight</mat-label>
              <input matInput type="number" required [formControl]="newSubscaleForm.get('weight')" />
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="onAddSubscale(textForm)">
              <mat-icon>add</mat-icon>
              Add
            </button>
          </div>
          <table>
            <thead>
              <colgroup>
                <col />
                <col class="num-col" />
                <col style="width: fit-content" />
              </colgroup>
              <tr>
                <th>Subscale</th>
                <th>Weight</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let subscale of textForm.get('subscales').value; let subscaleIndex = index">
                <td>{{ subscale.title }}</td>
                <td>{{ subscale.weight }}</td>
                <td>
                  <button mat-flat-button color="accent" (click)="onDeleteSubscale(subscaleIndex, 'top', textForm)">
                    <mat-icon>delete</mat-icon>
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="editor">
          <mat-form-field>
            <mat-select [formControl]="textForm.get('option')">
              <mat-option value="he">He</mat-option>
              <mat-option value="she">She</mat-option>
              <mat-option value="they">They</mat-option>
            </mat-select>
          </mat-form-field>
          <angular-editor
            [config]="editorConfig"
            [formControl]="textForm.get(textForm.get('option').value)"
            required
          ></angular-editor>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Bottom Form -->
  <h1>Bottom</h1>
  <h2>Lead sentence</h2>
  <div class="lead-sentence">
    <mat-form-field>
      <mat-label>Lead Sentence</mat-label>
      <mat-select required [formControl]="form.get('leadSentence').get('bottom').get('option')">
        <mat-option value="he">He</mat-option>
        <mat-option value="she">She</mat-option>
        <mat-option value="they">They</mat-option>
      </mat-select>
    </mat-form-field>
    <angular-editor
      [config]="editorConfig"
      [formControl]="
        form.get('leadSentence').get('bottom').get(form.get('leadSentence').get('bottom').get('option').value)
      "
    ></angular-editor>
  </div>
  <div class="default-sentence">
    <h2>Default Sentence</h2>
    <angular-editor
      [config]="editorConfig"
      [formControl]="form.get('bottomDefaultSentence')"
    ></angular-editor>
  </div>
  <mat-form-field>
    <mat-label>Number of BOTTOM subscales</mat-label>
    <input matInput [formControl]="form.get('totals').get('bottom')" required type="number" />
    <mat-error *ngIf="form.get('totals').get('bottom').hasError">Field is required.</mat-error>
  </mat-form-field>

  <div class="subscales-header">
    <h2>Subscales</h2>
    <button mat-flat-button color="primary" (click)="onAddText('bottom')">
      <mat-icon>add</mat-icon>
      Add new subscale
    </button>
  </div>
  <div class="texts" [formGroup]="form.get('texts')">
    <ng-container formArray="bottom">
      <div class="text" *ngFor="let textForm of bottom.controls; let i = index">
        <div class="text-item-header">
          <h3 class="title">Subscale #{{ i + 1 }}</h3>
          <button mat-flat-button color="accent" (click)="onDeleteText(i, 'bottom')">
            <mat-icon>delete</mat-icon>
            Delete subscale
          </button>
        </div>
        <div class="subscales">
          <div class="form">
            <mat-form-field>
              <mat-label>Subscale</mat-label>
              <input
                matInput
                required
                type="text"
                (ngModelChange)="this.bottomSubscaleFieldUpdate.next($event)"
                [formControl]="newSubscaleForm.get('subscale')"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayBottomSubscale">
                <mat-option *ngFor="let subscale of subscales | filter: bottomSubscaleField" [value]="subscale">
                  <div [matTooltip]="subscale.title">
                    <p class="report-scale-title-text">{{ subscale.title }}</p>
                    <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Weight</mat-label>
              <input matInput type="number" required min="0" [formControl]="newSubscaleForm.get('weight')" />
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="onAddSubscale(textForm)">
              <mat-icon>add</mat-icon>
              Add
            </button>
          </div>
          <table>
            <thead>
              <colgroup>
                <col />
                <col class="num-col" />
                <col style="width: fit-content" />
              </colgroup>
              <tr>
                <th>Subscale</th>
                <th>Weight</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let subscale of textForm.get('subscales').value; let subscaleIndex = index">
                <td>{{ subscale.title }}</td>
                <td>{{ subscale.weight }}</td>
                <td>
                  <button mat-flat-button color="accent" (click)="onDeleteSubscale(subscaleIndex, 'bottom', textForm)">
                    <mat-icon>delete</mat-icon>
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="editor">
          <mat-form-field>
            <mat-select [formControl]="textForm.get('option')">
              <mat-option value="he">He</mat-option>
              <mat-option value="she">She</mat-option>
              <mat-option value="they">They</mat-option>
            </mat-select>
          </mat-form-field>
          <angular-editor
            [config]="editorConfig"
            [formControl]="textForm.get(textForm.get('option').value)"
            required
          ></angular-editor>
        </div>
      </div>
    </ng-container>
  </div>
</form>
