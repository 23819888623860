import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Notification } from '../models/notification.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  save(notification: Notification) {
    return this.http.post<Notification>(BACKEND_URL + '/notification', notification);
  }

  update(notification: Notification) {
    return this.http.patch<Notification>(BACKEND_URL + '/notification', notification);
  }

  getAll() {
    return this.http.get<Notification[]>(BACKEND_URL + '/notification');
  }

  delete(id: number) {
    return this.http.delete(BACKEND_URL + '/notification/' + id);
  }
}
