<div class="container-fluid">
  <span *ngIf="this.roleMandate" class="psb-page-navigator"
    >Role Navigator > <b>{{ this.roleMandate.title }}</b> >
    {{ tabs[+selected.value] }}
  </span>

  <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected.value" (selectedIndexChange)="tabChange($event)">
    <mat-tab label="Analysis">
      <ng-template matTabContent>
        <app-psb-stats-line [psbStats]="psbStats"></app-psb-stats-line>

        <div class="row">
          <div class="column">
            <app-user-network [nodesData]="nodes" [type]="'GLOBAL'" [roleMandateId]="roleMandateId" *ngIf="nodesLoaded">
            </app-user-network>
          </div>
        </div>

        <div class="row">
          <div class="column">
            <h2>Global</h2>
            <app-centrality-table *ngIf="globalTableAnalysis" [dataSource]="globalTableAnalysis">
            </app-centrality-table>
          </div>
          <div class="column">
            <h2>Referent</h2>
            <app-centrality-table *ngIf="referentTableAnalysis" [dataSource]="globalTableAnalysis">
            </app-centrality-table>
          </div>
          <div class="column">
            <h2>Expert</h2>
            <app-centrality-table *ngIf="expertTableAnalysis" [dataSource]="globalTableAnalysis">
            </app-centrality-table>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Questions And Decision Tables Analysis">
      <ng-template matTabContent>
        <div *ngFor="let question of questions; let indexOfElement = index" style="margin-top: 10px">
          <app-question-analysis
            *ngIf="question.questionType === 'USER_CONNECTION'"
            [question]="question"
            [nodes]="nodes"
            [roleMandateId]="this.roleMandateId"
          ></app-question-analysis>

          <mat-divider *ngIf="!(indexOfElement === questions.length - 1 || decisionTables.length > 0)"></mat-divider>
        </div>

        <div *ngFor="let decisionTable of decisionTables; let indexOfElement = index" style="margin-top: 10px">
          <app-decision-table-analysis [decisionTable]="decisionTable" [roleMandateId]="roleMandateId" [nodes]="nodes">
          </app-decision-table-analysis>

          <mat-divider *ngIf="!(indexOfElement === decisionTables.length - 1)"></mat-divider>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Users">
      <app-board-users *ngIf="users" [users]="users" (openAnswers)="openUser($event)"> </app-board-users>

      <app-test-users [roleMandateId]="roleMandateId"></app-test-users>

      <app-user-invite [roleMandateId]="roleMandateId"></app-user-invite>

      <app-role-mandate-migrate-user-answers [roleMandateId]="roleMandateId"></app-role-mandate-migrate-user-answers>
    </mat-tab>

    <mat-tab label="Data Download">
      <ng-template matTabContent>
        <app-role-mandate-users-data [roleMandateId]="roleMandateId"></app-role-mandate-users-data>
      </ng-template>
    </mat-tab>

    <mat-tab label="E-Mail">
      <ng-template matTabContent>
        <app-psb-stats-line
          *ngIf="inviteAndReminderStats?.length"
          [psbStats]="inviteAndReminderStats"
        ></app-psb-stats-line>
        <app-role-mandate-email [roleMandateId]="roleMandateId"></app-role-mandate-email>
      </ng-template>
    </mat-tab>

    <mat-tab label="Notifications Channel">
      <ng-template matTabContent>
        <app-notifications-channel [roleMandateId]="roleMandateId"></app-notifications-channel>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
