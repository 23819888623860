<h1>{{ isEdit ? 'Edit a subscale question' : 'Create a subscale question' }}</h1>
<form [formGroup]="subscaleQuestionDialogForm" (ngSubmit)="onSave()">
  <h4>Text</h4>
  <angular-editor placeholder="Text" id="text" formControlName="text" [config]="editorConfig"></angular-editor>
  <mat-error
    *ngIf="
      subscaleQuestionDialogForm.get('text').errors &&
      (subscaleQuestionDialogForm.get('text').dirty || subscaleQuestionDialogForm.get('text').touched)
    "
  >
    Please, enter a valid text.</mat-error
  >
  <mat-form-field>
    <mat-label>Question Group</mat-label>
    <mat-select formControlName="questionGroupId" [compareWith]="compareFn">
      <mat-option value=""></mat-option>
      <mat-option *ngFor="let questionGroup of questionGroups$ | async" value="{{ questionGroup.id }}">{{
        questionGroup.title
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Question Type</mat-label>
    <mat-select formControlName="questionType">
      <mat-option value="TEXT_SINGLE_LINE">Text Single Line</mat-option>
      <mat-option value="TEXT_AREA">Text Area</mat-option>
      <mat-option value="RICH_TEXT">Rich Text</mat-option>
      <mat-option value="MULTI_VERT">Multi Vertical</mat-option>
      <mat-option value="MULTI_HOR">Multi Horizontal</mat-option>
      <mat-option value="IMAGE">Image</mat-option>
      <mat-option value="VIDEO">Video</mat-option>
      <mat-option value="SLIDER">Slider</mat-option>
      <mat-option value="PENTAGON">Pentagon</mat-option>
      <mat-option value="CARDSORT">Card Sort</mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="subscaleQuestionDialogForm.get('questionType').value === 'IMAGE'">
    <p class="file-name">{{ fileName }}</p>
    <button mat-button class="btn-primary btn-center btn-300">
      <mat-icon>add</mat-icon>
      Upload Image
      <input
        formControlName="imageFile"
        type="file"
        accept="image/*"
        class="input-file"
        (change)="handleFile('image', $event)"
      />
    </button>
  </div>
  <div *ngIf="subscaleQuestionDialogForm.get('questionType').value === 'VIDEO'">
    <p class="file-name">{{ fileName }}</p>
    <button mat-button class="btn-primary btn-center btn-300">
      <mat-icon>add</mat-icon>
      Upload Video
      <input
        formControlName="videoFile"
        type="file"
        accept="video/*"
        class="input-file"
        (change)="handleFile('video', $event)"
      />
    </button>
  </div>
  <mat-form-field *ngIf="subscaleQuestionDialogForm.get('questionType').value === 'CARDSORT'">
    <mat-label>Number of Cards</mat-label>
    <input matInput type="number" formControlName="numOfCards" />
  </mat-form-field>
  <mat-form-field *ngIf="subscaleQuestionDialogForm.get('questionType').value === 'CARDSORT'">
    <mat-label>Required number of discards</mat-label>
    <input matInput type="number" formControlName="numOnGarbage" />
  </mat-form-field>
  <mat-form-field
    *ngIf="['TEXT_SINGLE_LINE', 'TEXT_AREA'].includes(subscaleQuestionDialogForm.get('questionType').value)"
  >
    <mat-label>Number of Answers Allowed</mat-label>
    <input matInput type="number" formControlName="numberAnswersAllowed" />
  </mat-form-field>
  <h4>Instructions</h4>
  <angular-editor
    placeholder="Instructions"
    id="instructions"
    formControlName="instructions"
    [config]="editorConfig"
  ></angular-editor>
  <mat-form-field>
    <mat-label>Order</mat-label>
    <input matInput type="number" formControlName="aorder" />
  </mat-form-field>
  <div>
    <mat-slide-toggle class="slide" color="primary" formControlName="active" checked="active.value">
      Active
    </mat-slide-toggle>
    <mat-error *ngIf="subscaleQuestionDialogForm.get('active').errors">Please, enter a valid status.</mat-error>
  </div>
  <div *ngIf="subscaleQuestionDialogForm.get('questionType').value === 'CARDSORT'">
    <mat-slide-toggle
      class="slide"
      color="primary"
      formControlName="showDiscarded"
      checked="showDiscarded.value"
    >
    Show Discarded
    </mat-slide-toggle>
  </div>
  <div *ngIf="subscaleQuestionDialogForm.get('questionType').value === 'CARDSORT'">
    <mat-slide-toggle class="slide" color="primary" formControlName="randomizeCards" checked="randomizeCards.value">
      Randomize Cards
    </mat-slide-toggle>
    <mat-error *ngIf="subscaleQuestionDialogForm.get('randomizeCards').errors">Please, enter a valid value.</mat-error>
  </div>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!subscaleQuestionDialogForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
