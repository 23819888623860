<mat-form-field>
  <mat-label>Role Title</mat-label>
  <input
    matInput
    type="text"
    [formControl]="roleTitleControl"
    required
    (focusout)="onFocusOut($event)"
    [matAutocomplete]="answering ? autoTitle : null"
    [readonly]="!answering"
  />
  <mat-autocomplete
    #autoTitle="matAutocomplete"
    (optionSelected)="setRoleTitle($event)"
    [displayWith]="displayRoleTitle"
  >
    <mat-option *ngFor="let roleTitle of filteredRoleTitles | async" [value]="roleTitle">
      {{ roleTitle.title }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="roleTitleControl.errors && answering">Please, enter a valid role title.</mat-error>
</mat-form-field>
