import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// services
import { ChartService } from '@app/core/services/chart.service';
import { ReportService } from '@app/core/services/report.service';

// models
import { PsyChart } from '@app/core/models/psy-chart.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-add-chart-dialog',
  templateUrl: './add-chart-dialog.component.html',
  styleUrls: ['./add-chart-dialog.component.scss'],
})
export class AddChartDialogComponent implements OnInit {
  form = this.fb.group({
    chart: ['', [Validators.required]],
  });

  availableCharts: PsyChart[] = [];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: { reportId: number; addedChartsIds?: number[] },
    private dialogRef: MatDialogRef<AddChartDialogComponent>,
    private reportService: ReportService,
    private chartService: ChartService,
  ) {}

  ngOnInit(): void {
    this.chartService.getAll().subscribe((charts) => {
      const addedChartsIds = this.data.addedChartsIds || [];
      this.availableCharts = charts.filter((chart) => !addedChartsIds.includes(chart.id));
    });
  }

  onSubmit() {
    const { reportId } = this.data;
    const chartId = Number(this.form.value.chart);
    this.reportService.addChartToReport(reportId, chartId).subscribe((addedChart) => {
      this.dialogRef.close({ event: EVENT_SUCCESS, addedChart });
    });
  }
}
