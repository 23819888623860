<h1 mat-dialog-title>Add a new {{ data?.type == 'subscale' ? 'subscale' : 'Superscale' }} to this chart</h1>

<form id="addSubscaleForm" mat-dialog-content [formGroup]="form" (submit)="onFormSubmit()">
  <mat-form-field>
    <mat-label>Axis</mat-label>
    <mat-select required formControlName="axis">
      <mat-option value="X">X</mat-option>
      <mat-option value="Y">Y</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Weight</mat-label>
    <input matInput type="number" required formControlName="weight" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ data?.type == 'subscale' ? 'Subscale' : 'Superscale' }} </mat-label>
    <input matInput required type="text" formControlName="subscale" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySubscaleFn">
      <mat-option *ngFor="let subscale of filteredSubscales$ | async" [value]="subscale">
        {{ subscale.title }}
        <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>
    <mat-icon>block</mat-icon>
    Cancel
  </button>
  <button mat-flat-button color="primary" type="submit" form="addSubscaleForm" [disabled]="form.invalid">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</div>
