<h1>{{ isEdit ? 'Edit a subscale' : 'Create a subscale' }}</h1>
<form [formGroup]="subscaleDialogForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Title</mat-label>
    <input matInput type="text" formControlName="title" />
    <mat-error *ngIf="subscaleDialogForm.get('title').errors">Please, enter a valid title.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Description</mat-label>
    <input matInput type="text" formControlName="description" />
    <mat-error *ngIf="subscaleDialogForm.get('description').errors">Please, enter a valid description.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Color</mat-label>
    <mat-select formControlName="color">
      <mat-option value=""></mat-option>
      <mat-option *ngFor="let color of colors" [value]="color">{{ color }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-slide-toggle
      class="slide"
      color="primary"
      formControlName="cardsortSubscale"
      checked="cardsortSubscale.value"
    >
      Answer Subscale
    </mat-slide-toggle>
  </div>
  <div *ngIf="!subscaleDialogForm.get('cardsortSubscale').value">
    <mat-slide-toggle class="slide" color="primary" formControlName="randomAllowed" checked="randomAllowed.value">
      Random Allowed
    </mat-slide-toggle>
  </div>
  <div *ngIf="!subscaleDialogForm.get('cardsortSubscale').value">
    <mat-slide-toggle
      class="slide"
      color="primary"
      formControlName="scorePercentileActive"
      checked="scorePercentileActive.value"
    >
      Score Percentile Active
    </mat-slide-toggle>
  </div>
  <div>
    <mat-slide-toggle class="slide" color="primary" formControlName="active" checked="active.value">
      Status
    </mat-slide-toggle>
    <mat-error *ngIf="subscaleDialogForm.get('active').errors">Please, enter a valid status.</mat-error>
  </div>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!subscaleDialogForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
