import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { MatSelectionListChange } from '@angular/material/list';

// services
import { FilterService } from '@app/core/services/filter.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { User } from '@app/core/models/user.model';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';

@Component({
  selector: 'app-test-users',
  templateUrl: './test-users.component.html',
  styleUrls: ['./test-users.component.scss'],
})
export class TestUsersComponent implements OnInit {
  @Input() connectionIndicatorId: number;
  @Input() roleMandateId: number;
  filteredUsers: User[];
  users: User[] = [];
  linkedUsers = 0;
  companyId: number;

  constructor(
    private companyService: CompanyService,
    private filterService: FilterService,
    private cdRef: ChangeDetectorRef,
    public snackBar: SnackBarService,
    private cIService: ConnectionsIndicatorService,
    private rMService: RoleMandateService,
  ) {}

  ngOnInit(): void {
    if (this.roleMandateId) {
      this.rMService.getById(this.roleMandateId).subscribe((roleMandate: RoleMandate) => {
        this.companyId = roleMandate?.companyId;
        this.loadUsers();
      });
    }
    if (this.connectionIndicatorId) {
      this.cIService.getById(this.connectionIndicatorId).subscribe((connectionIndicator: ConnectionIndicator) => {
        this.companyId = connectionIndicator?.companyId;
        this.loadUsers();
      });
    }
  }

  filterUser(name: string) {
    const filterValue = typeof name === 'string' ? name.toLowerCase() : '';
    this.filteredUsers = this.filterService.filterUsersByNameAndDepartmentAndRoleTitle(this.users, filterValue);
    this.cdRef.detectChanges();
  }

  selectUser($event: MatSelectionListChange) {
    if ($event.option.selected) {
      if (this.connectionIndicatorId) {
        this.cIService.saveUsers(this.connectionIndicatorId, [$event.option.value]).subscribe(
          () => {
            this.snackBar.info('Added user to the test successfully!');
            this.linkedUsers++;
          },
          () => {
            this.snackBar.info('Some error occurred while trying to add user to the test. Contact the support.');
            $event.option.selected = false;
          },
        );
      }
      if (this.roleMandateId) {
        this.rMService.saveRoleMandateUser(this.roleMandateId, [$event.option.value]).subscribe(
          () => {
            this.snackBar.info('Added user to the test successfully!');
            this.linkedUsers++;
          },
          () => {
            this.snackBar.info('Some error occurred while trying to add user to the test. Contact the support.');
            $event.option.selected = false;
          },
        );
      }
    }
    if (!$event.option.selected) {
      if (this.connectionIndicatorId) {
        this.cIService.deleteUser(this.connectionIndicatorId, $event.option.value.id).subscribe(
          () => {
            this.snackBar.info('Removed user from the test successfully!');
            this.linkedUsers--;
          },
          () => {
            this.snackBar.info('Some error occurred while trying to remove user to the test. Contact the support.');
            $event.option.selected = true;
          },
        );
      }
      if (this.roleMandateId) {
        this.rMService.deleteRoleMandateUser(this.roleMandateId, $event.option.value.id).subscribe(
          () => {
            this.snackBar.info('Removed user from the test successfully!');
            this.linkedUsers--;
          },
          () => {
            this.snackBar.info('Some error occurred while trying to remove user to the test. Contact the support.');
            $event.option.selected = true;
          },
        );
      }
    }
  }

  private loadUsers() {
    if (this.connectionIndicatorId) {
      forkJoin([
        this.companyId ? this.companyService.getUsersById(this.companyId) : of(null),
        this.cIService.getUsers(this.connectionIndicatorId),
      ]).subscribe((result) => {
        this.allocateUsers(result);
      });
    }
    if (this.roleMandateId) {
      forkJoin([
        this.companyId ? this.companyService.getUsersById(this.companyId) : of(null),
        this.rMService.getAllUsers(this.roleMandateId),
      ]).subscribe((result) => {
        this.allocateUsers(result);
      });
    }
  }

  private allocateUsers(result: [User[], User[]]) {
    if (result[1]) {
      this.linkedUsers = result[1].length;
    }

    if (result[0]) {
      this.users = result[0]
        .filter((user) => user.id && user.firstName !== null)
        .map((user) => {
          user.selected = result[1].some((u) => u.id === user.id);
          return user;
        });
      this.filteredUsers = this.users;
    }

    this.cdRef.detectChanges();
  }
}
