<h1>{{ isSuperTest ? 'Supertests' : 'Tests' }}</h1>
<p class="small-text">
  {{
    isSuperTest
      ? 'Supertests are the group of tests that will be merged and showed to the users as a unique test.'
      : 'List of tests available to the users.'
  }}
</p>
<mat-divider></mat-divider>
<button
  mat-raised-button
  color="primary"
  class="new-button"
  (click)="onAddTest()"
  [disabled]="!superTests.length && !tests.length"
>
  <mat-icon aria-hidden="false">add</mat-icon> {{ isSuperTest ? 'Add Supertest' : 'Add Test' }}
</button>
<mat-table [dataSource]="testGroupTests" class="mat-elevation-z3">
  <ng-container matColumnDef="tests">
    <mat-header-cell *matHeaderCellDef>Tests</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="mobile-label">Tests :</span>
      <a
        [routerLink]="
          isSuperTest ? ['/super-test/' + element.testId + '/detail'] : ['/psy-test/' + element.testId + '/detail']
        "
        >{{ element.test }}</a
      >
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="mobile-label">Status :</span>
      <app-status [available]="element.available" (statusChange)="updateStatus($event, element)"></app-status>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell mat-header-cell class="action" *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="mobile-label">Action :</span>
      <span class="mobile-view-action-buttons">
        <button mat-button class="btn-range" (click)="onViewRanges(element)" *ngIf="!isSuperTest">Ranges</button>
        <button mat-button class="btn-view" (click)="onViewPercentiles(element)" *ngIf="!isSuperTest">
          Percentiles
        </button>
        <button mat-button class="btn-error btn-delete" (click)="onDelete(element)">Delete from Test Group</button>
        <button mat-button class="btn-warning" (click)="onArchive(element)">Archive</button>
      </span>
    </mat-cell>
  </ng-container>

  <mat-header-row mat-header-row *matHeaderRowDef="displayedTestGroupTestsColumns"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedTestGroupTestsColumns"></mat-row>
</mat-table>
<mat-paginator
  #paginatorTestGroups
  [pageSize]="10"
  [pageSizeOptions]="[10, 20, 50]"
  showFirstLastButtons
></mat-paginator>
