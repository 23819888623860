import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

// models
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';

@Component({
  selector: 'app-stats-test-group',
  templateUrl: './stats-test-group.component.html',
  styleUrls: ['./stats-test-group.component.scss'],
})
export class StatsTestGroupComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() testGroups: PsyTestGroup[];

  dataSource: MatTableDataSource<PsyTestGroup>;
  displayedColumns: string[] = ['title'];
  lineChartLabels: string[];
  lineChartData: number[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.loadData();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadData() {
    this.dataSource = new MatTableDataSource(this.testGroups);

    const result = [];
    this.testGroups.reduce((res, value) => {
      if (!res[value.dateCreated]) {
        res[value.dateCreated] = { dateCreated: value.dateCreated, qty: 0 };
        result.push(res[value.dateCreated]);
      }
      res[value.dateCreated].qty++;
      return res;
    }, {});

    this.lineChartLabels = result.map((data) => data.dateCreated);
    this.lineChartData = result.map((data) => data.qty);
  }
}
