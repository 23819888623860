import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionArea } from '@app/core/models/decision-area.model';

@Component({
  selector: 'app-new-decision-area',
  templateUrl: './new-decision-area.component.html',
  styleUrls: ['./new-decision-area.component.scss'],
})
export class NewDecisionAreaComponent {
  @Input() decisionAreas: DecisionArea[];
  @Input() companyId: number;
  @Output() add = new EventEmitter<boolean>();

  newDecisionAreaForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  constructor(private decisionTableService: DecisionTableService) {}

  newDecisionArea(formDirective: FormGroupDirective) {
    if (this.newDecisionAreaForm.valid) {
      const newDecisionArea: DecisionArea = {
        id: null,
        name: this.newDecisionAreaForm.get('name').value,
        companyId: this.companyId,
      };
      this.decisionTableService.createDecisionArea(newDecisionArea).subscribe((res) => {
        newDecisionArea.id = res.id;
        this.decisionAreas.push(newDecisionArea);
        this.add.emit(true);
      });
    }
    return;
  }

  onCancel() {
    this.add.emit(true);
  }
}
