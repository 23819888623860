<app-psb-table-search [data]="users"></app-psb-table-search>

<mat-table [dataSource]="users" class="mat-elevation-z3">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="userName">
    <mat-header-cell *matHeaderCellDef>Username</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.userName }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="user">
    <mat-header-cell mat-header-cell *matHeaderCellDef>User Type</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.userType }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dateAdded">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Date Added</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.dateAdded }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-chip-list aria-label="Status selection">
        <mat-chip *ngIf="element.status === 'Active'" color="primary" selected> {{ element.status }} </mat-chip>
        <mat-chip *ngIf="element.status === 'Inactive'" color="warn" selected> {{ element.status }} </mat-chip>
      </mat-chip-list>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell mat-header-cell class="action" *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-button class="btn-primary user-button" (click)="onViewUser(element)">View</button>
      <button mat-button class="btn-error user-button btn-delete" (click)="onDeleteUser(element)">Delete</button>
    </mat-cell>
  </ng-container>

  <mat-header-row mat-header-row *matHeaderRowDef="displayedUsersColumns"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedUsersColumns"></mat-row>
</mat-table>
<mat-paginator #paginatorUsers [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
