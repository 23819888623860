<div class="container-fluid">
  <span *ngIf="this.client" class="psb-page-navigator"
    >Client > <b>{{ client.name }}</b> >
    {{ tabs[+selected.value] }}
  </span>

  <mat-tab-group
    *ngIf="this.client"
    mat-align-tabs="start"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="tabChange($event)"
  >
    <mat-tab label="Details">
      <button mat-raised-button color="primary" class="pull-right" (click)="onEdit()">Edit</button>

      <div class="row">
        <div class="column2">
          <h2>General</h2>
          <span class="label">Name</span>
          <p>{{ client.name }}</p>
          <span class="label">Phone</span>
          <p>{{ client.telephone }}</p>
          <span class="label">E-mail</span>
          <p>{{ client.email }}</p>
          <span class="label">Skype ID</span>
          <p>{{ client.skype }}</p>
          <span class="label">Status</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="client.active === 1" color="primary" selected> Active </mat-chip>
            <mat-chip *ngIf="client.active !== 1" color="warn" selected> Inactive </mat-chip>
          </mat-chip-list>
          <span class="label">Allow access to raw scores</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="client.allowRawScoresAccess === 1" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="client.allowRawScoresAccess !== 1" color="warn" selected> No </mat-chip>
          </mat-chip-list>
          <span class="label">Language</span>
          <p>{{ client.defaultLanguage }}</p>
          <span class="label">Date Added</span>
          <p>{{ client.dateAdded }}</p>
          <span class="label">Time Zone</span>
          <p>{{ client.timeZone }}</p>
          <span class="label">Subscription Date Limit</span>
          <p>{{ client.subscriptionTime | date: 'yyyy-MM-dd' }}</p>
        </div>
        <div class="column2">
          <h2>Location</h2>
          <span class="label">Address</span>
          <p>{{ client.address }}</p>
          <span class="label">Address 2</span>
          <p>{{ client.address2 }}</p>
          <span class="label">Country</span>
          <p>{{ client.country }}</p>
          <span class="label">State</span>
          <p>{{ client.state }}</p>
          <span class="label">City</span>
          <p>{{ client.city }}</p>
          <span class="label">Zip Code</span>
          <p>{{ client.zipCode }}</p>
          <div *ngIf="client.logo">
            <span class="label">Logo</span>
            <p><img [src]="client.logo" width="150px" height="auto" alt="Client Logo" /></p>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Test Groups">
      <app-test-group-list-table [companyId]="companyId"></app-test-group-list-table>
    </mat-tab>
    <mat-tab label="Assessments">
      <app-client-assessment-list [companyId]="companyId"></app-client-assessment-list>
    </mat-tab>

    <mat-tab label="Charts">
      <app-client-chart-list [companyId]="companyId"></app-client-chart-list>
    </mat-tab>

    <mat-tab label="Users">
      <div class="user-buttons">
        <button mat-raised-button color="primary" class="btn-success create-button" (click)="onCreateUser()">
          <mat-icon aria-hidden="false">add</mat-icon>Create a New User
        </button>
        <button mat-raised-button color="warn" class="btn-success create-button" (click)="onUsersExport()">
          <mat-icon aria-hidden="false">refresh</mat-icon>Users Export
        </button>
      </div>
      <p class="small-text">Users registered to the company</p>
      <app-user-list-table [companyId]="companyId"></app-user-list-table>
    </mat-tab>

    <mat-tab label="Departments">
      <app-department-list-table [companyId]="companyId"></app-department-list-table>
    </mat-tab>

    <mat-tab label="Statistics">
      <ng-template matTabContent>
        <app-statistics></app-statistics>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
