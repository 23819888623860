<form [formGroup]="form" (submit)="onSave()">
  <button mat-raised-button color="primary" type="submit">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
  <div class="toggles">
    <mat-slide-toggle formControlName="calculateDiversity"> Diversity Coefficient </mat-slide-toggle>
    <mat-slide-toggle formControlName="calculateProximity"> Proximity Coefficient </mat-slide-toggle>
  </div>

  <div class="sectors-grid" formGroupName="sectors">
    <fieldset
      *ngFor="let pos of positions; let i = index"
      [formGroupName]="pos.code"
      class="sector-item"
      [ngClass]="{ center: chart?.base == 'FOUR_PLUS_ONE_CARTESIAN' && i == 2, hexagonOrder: chart?.base == 'HEXAGON' }"
    >
      <legend>{{ pos.title }}</legend>
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Text</mat-label>
        <input matInput type="text" formControlName="text" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Color</mat-label>
        <mat-select formControlName="color">
          <mat-option *ngFor="let color of colors" [value]="color">
            {{ color }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </fieldset>
  </div>
</form>
