import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-test-results-table',
  templateUrl: './test-results-table.component.html',
  styleUrls: ['./test-results-table.component.scss'],
})
export class TestResultsTableComponent implements OnInit {
  @Input() testResults: any;
  displayedColumns: string[] = ['title', 'dateCompleted'];

  constructor() {}

  ngOnInit(): void {}
}
