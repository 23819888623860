import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  @Input() available: boolean;
  @Output() statusChange = new EventEmitter<boolean>();
  statusControl = new FormControl();
  status: boolean;

  constructor() {}

  ngOnInit(): void {
    if (this.available === true || this.available === false) {
      this.status = this.available;
    }
  }

  onChangeActive() {
    this.status = true;
    this.statusChange.emit(true);
  }

  onChangeInactive() {
    this.status = false;
    this.statusChange.emit(false);
  }

  styleActive() {
    return { backgroundColor: 'green', color: 'white' };
  }

  styleInactive() {
    return { backgroundColor: 'red', color: 'white' };
  }
}
