import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InviteComponent } from '@app/user/invite/invite/invite.component';

const inviteRoutes: Routes = [{ path: ':token', component: InviteComponent }];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(inviteRoutes)],
  exports: [RouterModule],
})
export class InviteRoutingModule {}
