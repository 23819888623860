import { Component, OnInit } from '@angular/core';

// services
import { UserService } from '@app/core/services/user.service';

// models
import { UserNotification } from '@app/core/models/user-notification.model';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss'],
})
export class UserNotificationComponent implements OnInit {
  notifications: UserNotification[];

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getUserNotificationsByUserInfoId(this.userService.getUserData().id).subscribe((notifications) => {
      this.notifications = notifications;
      this.userService.userNotification.next(false);
    });
  }
}
