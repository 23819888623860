<div class="container-fluid">
  <div *ngIf="this.questionGroup" class="psb-page-navigator">
    <span *ngFor="let item of tabs; last as isLast" class="breadscrum_links">
      <label (click)="navigateUrl(item)"
        >{{ item?.title | slice: 0:40 }}{{ item?.title?.length > 40 ? '...' : '' }}</label
      ><label *ngIf="!isLast" class="breadscrum_arrow_icon">></label>
    </span>
  </div>

  <mat-tab-group
    *ngIf="this.questionGroup"
    mat-align-tabs="start"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="tabChange($event)"
  >
    <mat-tab label="Details">
      <button mat-raised-button color="primary" class="pull-right" (click)="onEdit()">Edit</button>
      <div class="row">
        <div class="column">
          <div *ngIf="questionGroup.id">
            <span class="label">Question Group</span>
            <p>{{ questionGroup.id }}</p>
          </div>
          <div *ngIf="questionGroup.instructions">
            <span class="label">Instructions</span>
            <p>{{ questionGroup.instructions }}</p>
          </div>
          <div *ngIf="questionGroup.title">
            <span class="label">Title</span>
            <p>{{ questionGroup.title }}</p>
          </div>
          <div *ngIf="questionGroup.aorder">
            <span class="label">Order</span>
            <p>{{ questionGroup.aorder }}</p>
          </div>
          <div *ngIf="questionGroup.subscale">
            <span class="label">Subscale</span>
            <p>{{ questionGroup.subscale }}</p>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
