import { Component, Input, OnInit } from '@angular/core';
import { PsyChart } from '@app/core/models/psy-chart.model';
import { EditChartDialogComponent } from '@app/admin/pages/chart/edit-chart-dialog/edit-chart-dialog.component';
import { EVENT_SUCCESS } from '@app/core/constants';
import { MatDialog } from '@angular/material/dialog';
import { BlossomChartSetupFormComponent } from '@app/admin/pages/chart/blossom/blossom-chart-setup/blossom-chart-setup-form/blossom-chart-setup-form.component';
import { BlossomChart, BlossomChartService } from '../../../../../../generated/v2';

@Component({
  selector: 'app-blossom-chart-setup',
  templateUrl: './blossom-chart-setup.component.html',
  styleUrls: ['./blossom-chart-setup.component.scss'],
})
export class BlossomChartSetupComponent implements OnInit {
  @Input() chartId: number;

  blossomChart: BlossomChart;

  constructor(private dialog: MatDialog, private blossomChartService: BlossomChartService) {}

  ngOnInit(): void {
    this.blossomChartService.getChartBlossomChart(this.chartId).subscribe((blossomChart) => {
      this.blossomChart = blossomChart;
    });
  }

  onEdit() {
    const data = { chart: this.blossomChart };
    this.dialog
      .open(BlossomChartSetupFormComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_SUCCESS) {
          this.blossomChart = result.updatedChart;
        }
      });
  }
}
