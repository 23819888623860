<h4 *ngIf="departments.data && departments.data.length === 0" class="no-department-message">
  <mat-icon aria-hidden="false" class="notifications-icon">notifications</mat-icon>This client has no departments yet.
</h4>
<button mat-raised-button class="new-button" color="primary" (click)="createNewDepartment()">
  <mat-icon>create</mat-icon>Create a new Department
</button>
<mat-divider></mat-divider>
<div [hidden]="departments.data && departments.data.length === 0">
  <mat-table [dataSource]="departments" class="mat-elevation-z3">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="city">
      <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.city }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="zipCode">
      <mat-header-cell mat-header-cell *matHeaderCellDef>Zip Code</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.zipCode }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell mat-header-cell *matHeaderCellDef>Address</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.address }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="state">
      <mat-header-cell mat-header-cell *matHeaderCellDef>State</mat-header-cell>
      <mat-cell *matCellDef="let element" class="state" (click)="onStateDetail(element)">{{ element.state }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell mat-header-cell class="action" *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button class="btn-primary user-button" (click)="onEdit(element)">Edit</button>
        <button mat-button class="btn-error user-button btn-delete" (click)="onDelete(element)">Delete</button>
      </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedDepartmentsColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedDepartmentsColumns"></mat-row>
  </mat-table>
  <mat-paginator #paginatorDepartments [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
  </mat-paginator>
</div>
