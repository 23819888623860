import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// models
import { Subscale } from '@app/core/models/subscale.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { SUBSCALE_COLORS } from '@app/shared/constants/subscale-colors';

@Component({
  selector: 'app-create-subscale-dialog',
  templateUrl: './create-subscale-dialog.component.html',
  styleUrls: ['./create-subscale-dialog.component.scss'],
})
export class CreateSubscaleDialogComponent implements OnInit {
  isEdit: boolean;
  colors: String[] = SUBSCALE_COLORS;
  subscaleDialogForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    color: new FormControl(''),
    active: new FormControl(true, [Validators.required]),
    cardsortSubscale: new FormControl(true, [Validators.required]),
    randomAllowed: new FormControl(false),
    scorePercentileActive: new FormControl(false),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateSubscaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; subscale: Subscale },
  ) {}

  ngOnInit(): void {
    this.isEdit = this.data.isEdit;
    if (this.isEdit) {
      this.subscaleDialogForm.get('title').setValue(this.data.subscale.title);
      this.subscaleDialogForm.get('description').setValue(this.data.subscale.description);
      this.subscaleDialogForm.get('color').setValue(this.data.subscale.color);
      this.subscaleDialogForm.get('active').setValue(this.data.subscale.active);
      this.subscaleDialogForm.get('cardsortSubscale').setValue(this.data.subscale.cardsortSubscale);
      this.subscaleDialogForm.get('randomAllowed').setValue(this.data.subscale.randomAllowed);
      this.subscaleDialogForm.get('scorePercentileActive').setValue(this.data.subscale.scorePercentileActive);
    }
  }

  onSave(): void {
    this.dialogRef.close({ event: 'save', data: this.subscaleDialogForm.value });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
