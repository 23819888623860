<form [formGroup]="editInviteForm" (submit)="onConfirm()">
  <mat-form-field>
    <mat-label>First Name</mat-label>
    <input matInput type="text" formControlName="firstName" />
    <mat-error *ngIf="editInviteForm.get('firstName').errors">Please, enter a valid first name.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Last Name</mat-label>
    <input matInput type="text" formControlName="lastName" />
    <mat-error *ngIf="editInviteForm.get('lastName').errors">Please, enter a valid last name.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>E-mail</mat-label>
    <input matInput type="text" formControlName="email" />
    <mat-error *ngIf="editInviteForm.get('email').errors">Please, enter a valid e-mail.</mat-error>
  </mat-form-field>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit" class="btn-primary">Save</button>
    <button mat-raised-button type="button" (click)="onCancel()">Cancel</button>
  </div>
</form>
