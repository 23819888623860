import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';

// models
import { AreaIndex } from '@app/core/models/area-index.model';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-create-new-area-dialog',
  templateUrl: './create-new-area-dialog.component.html',
  styleUrls: ['./create-new-area-dialog.component.scss'],
})
export class CreateNewAreaDialogComponent implements OnInit {
  isEdit: boolean;

  form = this.fb.group({
    name: ['', [Validators.required]],
    base: ['', [Validators.required]],
  });

  bases = ['FOUR_PLUS_ONE_CARTESIAN', 'CIRCLE_IN_CARTESIAN', 'HEXAGON'];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreateNewAreaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { isEdit: boolean; areaIndex: AreaIndex } | null,
  ) {}

  ngOnInit(): void {
    this.isEdit = this.data.isEdit;

    if (this.isEdit) {
      this.form.get('name').setValue(this.data.areaIndex.name);
      this.form.get('base').setValue(this.data.areaIndex.base);
    }
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }

  onFormSubmit() {
    this.dialogRef.close({ event: EVENT_SUCCESS, data: this.form.value });
  }
}
