import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// models
import { Subscale } from '@app/core/models/subscale.model';
import { Answer } from '@app/core/models/answer.model';

// directives
import { subscaleValidator } from '@app/core/validators/subscaleValidator.directive';

@Component({
  selector: 'app-form-cardsort',
  templateUrl: './form-cardsort.component.html',
  styleUrls: ['./form-cardsort.component.scss'],
})
export class FormCardsortComponent implements OnInit {
  @Input() allowSubscale: boolean;
  @Input() allowWeight: boolean;
  @Input() answerSubscales?: Subscale[];
  @Input() position?: number;
  @Input() questionId?: number;
  @Input() answer?: Answer;
  @Output() save = new EventEmitter<Answer>();
  filteredSubscales: Observable<Subscale[]>;

  cardForm = new FormGroup({
    text: new FormControl('', [Validators.required]),
    subscale: new FormControl('', [subscaleValidator()]),
    weight: new FormControl(''),
  });

  constructor() {}

  ngOnInit(): void {
    this.getSubscales();

    if (this.answer) {
      this.cardForm.get('text').setValue(this.answer.text);
    }
  }

  getSubscales() {
    this.filteredSubscales = this.cardForm.get('subscale').valueChanges.pipe(
      startWith(''),
      map((subscale) => (subscale ? this._filterSubscale(subscale) : this.answerSubscales.slice())),
    );
  }

  private _filterSubscale(value: string): Subscale[] {
    const filterValue = value;
    return this.answerSubscales.filter((subscale) => subscale.title.indexOf(filterValue) === 0);
  }

  displaySubscale(subscale: Subscale): string {
    return subscale ? subscale.title : '';
  }

  saveAnswer() {
    const answer: Answer = {
      id: this.answer ? this.answer.id : null,
      questionId: this.questionId,
      text: this.cardForm.get('text').value,
      subscaleId: this.cardForm.get('subscale').value ? +this.cardForm.get('subscale').value.id : null,
      weight: Number(this.cardForm.get('weight').value),
      order: this.position++,
    };
    this.save.emit(answer);
    this.cardForm.reset();
  }
}
