import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaginationService {
  private paginationListener = new Subject<boolean>();

  constructor() {}

  getPaginationListener() {
    return this.paginationListener.asObservable();
  }

  setPaginationListener(value: boolean) {
    this.paginationListener.next(value);
  }
}
