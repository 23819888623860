import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {
  MatPasswordStrengthComponent,
  MatPasswordStrengthInfoComponent,
} from '@angular-material-extensions/password-strength';

// services
import { UserService } from '@app/core/services/user.service';

// models
import { User } from '@app/core/models/user.model';
import { UserResetPassword } from './user-reset-password.model';
import { MustMatch } from '@app/core/validators/must-match.validator';

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss'],
})
export class UserResetPasswordComponent implements OnInit, AfterViewChecked {
  resetKey: string;
  requestResponse = false;
  userData: User;
  form: FormGroup;
  userResetPassword: UserResetPassword;
  showWarningPanel: boolean;
  isPasswordStrengthValid;
  @ViewChild('passwordComponent') passwordComponentWithValidation: MatPasswordStrengthComponent;
  @ViewChild('passwordInfo') passwordInfo: MatPasswordStrengthInfoComponent;
  @ViewChild('password') password!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // Start formBuilder
    this.form = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8), this._validateInputFromPassword()]],
        passwordConfirmation: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'passwordConfirmation'),
      },
    );
    this.route.params.subscribe((params) => {
      this.resetKey = params['resetKey'];
      this.userService.checkResetKey(this.resetKey).subscribe(
        (res) => (this.userData = res),
        () => this.router.navigate(['/']),
      );
    });
  }

  // Getter to access reactive form controls
  get formControls() {
    return this.form.controls;
  }

  onStrengthChanged($event?) {
    if (document.activeElement === this.password.nativeElement) {
      if ($event != 100) {
        this.showWarningPanel = true;
      }
      if ($event == 100) {
        this.showWarningPanel = false;
      }
    }
  }

  focusInPassword() {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.showWarningPanel = false;
    } else {
      this.showWarningPanel = true;
    }
  }

  focusOutPassword() {
    this.showWarningPanel = false;
    this.passwordComponentWithValidation.setDisabledState(true);
  }

  onSubmit() {
    if (this.form.valid) {
      const newPassword = this.form.value.password;
      this.userResetPassword = {
        resetKey: this.resetKey,
        newPassword,
      };
      this.userService.resetPassword(this.userResetPassword).subscribe(() => (this.requestResponse = true));
    }
  }

  ngAfterViewChecked(): void {
    if (this.passwordComponentWithValidation.strength == 100) {
      this.isPasswordStrengthValid = true;
    } else {
      this.isPasswordStrengthValid = false;
    }
    this.cd.detectChanges();
  }

  private _validateInputFromPassword() {
    return (control: FormControl) => {
      const inputValue = control.value;
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:"<>?`~\-=[\];',./])(?=.{8,}).*$/;

      if (!pattern.test(inputValue)) {
        return { notValid: true };
      }

      return null;
    };
  }
}
