<div *ngIf="users && users.length > 0" class="table">
  <div class="table-header">
    <div>
      <mat-checkbox
        class="mb-4"
        (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        >Select all</mat-checkbox
      >
    </div>
    <br />
    <div class="row mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search for Name"
        [(ngModel)]="searchField"
        (ngModelChange)="this.searchFieldUpdate.next($event)"
      />
    </div>
  </div>

  <div class="table-content">
    <mat-card *ngFor="let user of users | filter: searchTerm" class="list-item mb-1">
      <div class="row">
        <mat-checkbox (change)="$event ? handleSelectUser(user) : null" [checked]="selection.isSelected(user)">{{
          user.userName
        }}</mat-checkbox>
      </div>
    </mat-card>
  </div>
</div>
