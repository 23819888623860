import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { forkJoin } from 'rxjs';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { UserService } from '@app/core/services/user.service';
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { TestObjectService } from '@app/core/services/test-object.service';

// models
import { Question } from '@app/core/models/question.model';
import { DecisionTable } from '@app/core/models/decision-table.model';
import { UserInfo } from '@app/core/models/userInfo.model';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { QuestionGroup } from '@app/core/models/question-group.model';

@Component({
  selector: 'app-role-mandate-user-result',
  templateUrl: './role-mandate-user-result.component.html',
  styleUrls: ['./role-mandate-user-result.component.scss'],
})
export class RoleMandateUserResultComponent implements OnInit, AfterViewChecked {
  roleMandateId: number;
  userInfoId: number;
  userInfoData: UserInfo;
  roleMandate: RoleMandate;
  questions: Question[];
  decisionTables: DecisionTable[];
  questionGroups: QuestionGroup[];
  allObj: any[];
  private answerAnchor: number;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private rMService: RoleMandateService,
    private questionGroupService: QuestionGroupService,
    public testObjectService: TestObjectService,
    private router: Router,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof Scroll && event.anchor) {
        this.answerAnchor = +event.anchor;
      }
    });
  }

  ngAfterViewChecked(): void {
    this.scroll('#' + this.answerAnchor);
  }

  ngOnInit(): void {
    this.roleMandateId = this.route.snapshot.params['id'];
    this.userInfoId = this.route.snapshot.params['userInfoId'];

    this.rMService.getRoleMandateResults(this.roleMandateId, this.userInfoId).subscribe({
      next: (result) => {
        this.roleMandate = result;
        this.userInfoData = result.user;
        this.decisionTables = result.decisionTables;
        this.allObj = result.allObj;
      },
    });
  }

  goBack() {
    this.router.navigate([`/user/role-mandate/${this.roleMandateId}/user-list`]);
  }

  private scroll(query: string) {
    const targetElement = document.getElementById(query.replace('#', ''));
    if (!this.isInViewport(targetElement) && targetElement) {
      setTimeout(() => {
        const elementPosition = targetElement.getBoundingClientRect();
        window.scrollTo(elementPosition.left, elementPosition.top + window.scrollY - 200);
      }, 150);
    }
  }

  private isInViewport = (elem: any) => {
    if (elem) {
      const bounding = elem.getBoundingClientRect();
      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  };
}
