<div *ngIf="allObj">
  <div *ngIf="everyPageInstructionsEnabled">
    <app-instructions
      [text]="startPageInstructions"
      title="Instructions"
      [background]="'startPageInstructions'"
    ></app-instructions>

    <app-instructions
      [text]="warningInstructions"
      title="Warning"
      [background]="'warningInstructions'"
    ></app-instructions>
  </div>

  <div *ngFor="let obj of allObj | paginate: config">
    <div *ngIf="this.config.currentPage === 1 && obj === allObj[0] && !everyPageInstructionsEnabled">
      <app-instructions
        [text]="startPageInstructions"
        title="Instructions"
        [background]="'startPageInstructions'"
      ></app-instructions>

      <app-instructions
        [text]="warningInstructions"
        title="Warning"
        [background]="'warningInstructions'"
      ></app-instructions>
    </div>

    <mat-card
      *ngIf="
        testObjectService.isQuestion(obj) && (obj.questionType === 'TEXT_AREA' || obj.questionType === 'RICH_TEXT')
      "
    >
      <app-answer-text-area
        [question]="obj"
        [psyTestId]="psyTestId"
        [superTestId]="superTestId"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-text-area>
    </mat-card>
    <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'SLIDER'">
      <app-answer-slider
        [question]="obj"
        [psyTestId]="psyTestId"
        [superTestId]="superTestId"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-slider>
    </mat-card>
    <mat-card
      *ngIf="(testObjectService.isQuestion(obj) && obj.questionType === 'MULTI_VERT') || obj.questionType === 'IMAGE'"
    >
      <app-answer-multiple-vertical-choice
        [question]="obj"
        [psyTestId]="psyTestId"
        [superTestId]="superTestId"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-multiple-vertical-choice>
    </mat-card>
    <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'MULTI_HOR'">
      <app-answer-multiple-horizontal-choice
        [question]="obj"
        [psyTestId]="psyTestId"
        [superTestId]="superTestId"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-multiple-horizontal-choice>
    </mat-card>
    <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'CARDSORT'">
      <app-answer-cardsort
        [question]="obj"
        [psyTestId]="psyTestId"
        [superTestId]="superTestId"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
        [toolTipType]="toolTipType"
      ></app-answer-cardsort>
    </mat-card>
    <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'PENTAGON'">
      <app-answer-pentagon
        [question]="obj"
        [psyTestId]="psyTestId"
        [superTestId]="superTestId"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-pentagon>
    </mat-card>
    <mat-card *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'TEXT_SINGLE_LINE'">
      <app-answer-text-single-line
        [question]="obj"
        [psyTestId]="psyTestId"
        [superTestId]="superTestId"
        [loadAnswer]="true"
        [answering]="false"
        [isPreview]="true"
      ></app-answer-text-single-line>
    </mat-card>

    <mat-card *ngIf="testObjectService.isQuestionsGroup(obj)">
      <p *ngIf="obj.instructions" [innerHTML]="obj.instructions"></p>
      <div *ngFor="let question of obj.questions" class="question">
        <div *ngIf="question.questionType === 'TEXT_AREA' || question.questionType === 'RICH_TEXT'">
          <app-answer-text-area
            [question]="question"
            [psyTestId]="psyTestId"
            [superTestId]="superTestId"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-text-area>
        </div>
        <div *ngIf="question.questionType === 'SLIDER'">
          <app-answer-slider
            [question]="question"
            [psyTestId]="psyTestId"
            [superTestId]="superTestId"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-slider>
        </div>
        <div *ngIf="question.questionType === 'MULTI_VERT' || question.questionType === 'IMAGE'">
          <app-answer-multiple-vertical-choice
            [question]="question"
            [psyTestId]="psyTestId"
            [superTestId]="superTestId"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-multiple-vertical-choice>
        </div>
        <div *ngIf="question.questionType === 'MULTI_HOR'">
          <app-answer-multiple-horizontal-choice
            [question]="question"
            [psyTestId]="psyTestId"
            [superTestId]="superTestId"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-multiple-horizontal-choice>
        </div>
        <div *ngIf="question.questionType === 'CARDSORT'">
          <app-answer-cardsort
            [question]="question"
            [psyTestId]="psyTestId"
            [superTestId]="superTestId"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
            [toolTipType]="toolTipType"
          ></app-answer-cardsort>
        </div>
        <div *ngIf="question.questionType === 'PENTAGON'">
          <app-answer-pentagon
            [question]="question"
            [psyTestId]="psyTestId"
            [superTestId]="superTestId"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-pentagon>
        </div>
        <div *ngIf="question.questionType === 'TEXT_SINGLE_LINE'">
          <app-answer-text-single-line
            [question]="question"
            [psyTestId]="psyTestId"
            [superTestId]="superTestId"
            [loadAnswer]="true"
            [answering]="false"
            [isPreview]="true"
          ></app-answer-text-single-line>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="row">
    <pagination-controls class="pagination" (pageChange)="pageChanged($event)" id="preview-paginator">
    </pagination-controls>
  </div>
</div>
