import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { fromEvent, merge, Observable, Observer, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  timerDist: { [key: string]: number } = {};
  timerDistObservable: { [key: string]: Observable<number> } = {};
  timerUnsubscribe$: { [key: string]: Subscription } = {};
  timerWarningObservable: { [key: string]: Observable<number> } = {};
  timerWarningUnsubscribe$: { [key: string]: Subscription } = {};
  lastWarningMessageSentAt: Date = new Date();
  isDragAndDropAnswerCard = { drag: false, drop: false };

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public isOnline$(): Observable<boolean> {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }),
    );
  }

  public b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  startTimer(questionId: number) {
    this.lastWarningMessageSentAt = new Date();
    this.timerUnsubscribe$[questionId] = this.timerDistObservable[questionId]?.subscribe(() => {
      this.timerDist[questionId] += 1;
      let canShowDialogAgain =
        Math.abs((new Date().getTime() - this.lastWarningMessageSentAt.getTime()) / 1000) >= 10;

      if (canShowDialogAgain) {
        this.lastWarningMessageSentAt = new Date();
        
        const urlSegments = this.router.url.split('/');

        if (this.isRouteMatched(urlSegments)) {
          const confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
          confirmationDialog.componentInstance.message = `Seems like you are thinking hard about this question. Trust your first instinct.`;
          confirmationDialog.componentInstance.isShowCancel = false;
          confirmationDialog.afterClosed().subscribe(() => {
            this.dialog.closeAll();
          });
        }
      }
    });
  }

  stopTimer(questionId?: number, testResultId?: number) {
    const localStorageQuestion = [];
    if (!localStorage.getItem('question_spendTime')) {
      for (const [key, value] of Object.entries(this.timerDist)) {
        let timerPerQuestion = { [key]: value };
        localStorageQuestion.push(timerPerQuestion);
      }
      let localStorageQuestionObject = {
        [testResultId]: [...localStorageQuestion],
      };
      localStorage.setItem('question_spendTime', JSON.stringify(localStorageQuestionObject));
    } else {
      this.isCheckAlreadyTestStarted(testResultId);
    }
    this.timerUnsubscribe$[questionId]?.unsubscribe();
    this.timerWarningUnsubscribe$[questionId]?.unsubscribe();
  }

  isCheckAlreadyTestStarted(resultId: number) {
    let localStorageQuestion = [];
    const getAllSpentTimeQuestion = JSON.parse(localStorage.getItem('question_spendTime'));
    let isExisting = getAllSpentTimeQuestion[resultId] !== undefined;
    if (isExisting) {
      for (const key of Object.keys(getAllSpentTimeQuestion)) {
        if (Number(key) == resultId) {
          for (const [key, value] of Object.entries(this.timerDist)) {
            let timerPerQuestion = { [key]: value };
            localStorageQuestion.push(timerPerQuestion);
          }
          getAllSpentTimeQuestion[resultId] = [...localStorageQuestion];
          localStorage.setItem('question_spendTime', JSON.stringify(getAllSpentTimeQuestion));
        }
      }
    } else {
      for (const [key, value] of Object.entries(this.timerDist)) {
        let timerPerQuestion = { [key]: value };
        localStorageQuestion.push(timerPerQuestion);
      }
      let localStorageQuestionObject = {
        [resultId]: [...localStorageQuestion],
      };
      localStorage.setItem(
        'question_spendTime',
        JSON.stringify(Object.assign(localStorageQuestionObject, getAllSpentTimeQuestion)),
      );
    }
  }

  getWidth(text, fontSize?: number, fontFace?: string) {
    if (!fontSize) fontSize = 14;
    if (!fontFace) fontFace = 'Arial, Helvetica, sans-serif';
    var a = document.createElement('canvas');
    var b = a.getContext('2d');
    b.font = fontSize + 'px ' + fontFace;
    return b.measureText(text).width;
  }

   // dashboard/test/:testId/test-group/:testGroupId/page/:page
  isRouteMatched(segments: any[]): boolean {
    const desiredPath = [
      '',
      'dashboard',
      'test',
      1,
      'test-group',
      1,
      'page',
      1,
    ];
  
    // Compare the URL segments to the desired path.
    if (segments.length !== desiredPath.length) {
      return false;
    }
  
    if (segments[0] !== desiredPath[0] || segments[1] !== desiredPath[1] || segments[2] !== desiredPath[2] || segments[4] !== desiredPath[4] || segments[6] !== desiredPath[6]) {
      return false;
    }

    if (!this.isNumber(segments[3]) || !this.isNumber(segments[5]) || !this.isNumber(segments[7])) {
      return false;
    }
  
    return true;
  }

  isNumber(value: string) {
    const isNumber = /^\d+$/.test(value);
    return isNumber ? true : false;
  }
}
