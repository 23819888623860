<h1 *ngIf="isSuperTest">{{ isEdit ? 'Edit a super test' : 'Create a new super test' }}</h1>
<h1 *ngIf="!isSuperTest">{{ isEdit ? 'Edit a test' : 'Create a new test' }}</h1>
<form [formGroup]="testDialogForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Title</mat-label>
    <input matInput type="text" formControlName="title" />
    <mat-error *ngIf="testDialogForm.get('title').errors">Please, enter a valid title.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Description</mat-label>
    <input matInput type="text" formControlName="description" />
    <mat-error *ngIf="testDialogForm.get('description').errors">Please, enter a valid description.</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="!this.isSuperTest">
    <mat-label>Version</mat-label>
    <input matInput type="number" formControlName="version" />
    <mat-error *ngIf="testDialogForm.get('version').errors">Please, enter a valid version.</mat-error>
  </mat-form-field>
  <h4>StartPageInstructions: To add icons type starting with: and any alphabet</h4>
  <angular-editor type="text" formControlName="startPageInstructions" [config]="editorConfig"></angular-editor>
  <mat-error
    *ngIf="
      testDialogForm.get('startPageInstructions').errors &&
      (testDialogForm.get('startPageInstructions').dirty || testDialogForm.get('startPageInstructions').touched)
    "
  >
    Please, enter a valid start page instructions.</mat-error
  >
  <h4>Warning Instructions: To add icons type starting with: and any alphabet</h4>
  <angular-editor type="text" formControlName="warningInstructions" [config]="editorConfig"></angular-editor>
  <mat-error
    *ngIf="
      testDialogForm.get('warningInstructions').errors &&
      (testDialogForm.get('warningInstructions').dirty || testDialogForm.get('warningInstructions').touched)
    "
  >
    Please, enter a valid warning instructions.</mat-error
  >
  <mat-form-field>
    <mat-label>Paging</mat-label>
    <input matInput type="number" formControlName="paging" />
    <mat-error *ngIf="testDialogForm.get('paging').errors">Please, enter a valid paging.</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="!this.isSuperTest">
    <mat-label>Test Time(mins)</mat-label>
    <input matInput type="number" formControlName="testTime" />
    <mat-error *ngIf="testDialogForm.get('testTime').errors">Please, enter a valid test time.</mat-error>
  </mat-form-field>
  <div *ngIf="!this.isSuperTest">
    <mat-slide-toggle class="slide" color="primary" formControlName="answersRequired" checked="answersRequired.value">
      Answers Required
    </mat-slide-toggle>
    <mat-error *ngIf="testDialogForm.get('answersRequired').errors">Please, enter a valid answers required.</mat-error>
  </div>
  <div>
    <mat-slide-toggle
      class="slide"
      color="primary"
      formControlName="randomizeOrder"
      checked="randomizeOrder.value"
      [disabled]="disabledRandomOrder"
    >
      Randomize Order
    </mat-slide-toggle>
    <p class="description" *ngIf="testDialogForm && testDialogForm.get('customQuestionOrder').value">
      Randomize Order will be available only when Custom Question Order is disabled.
    </p>
  </div>
  <div *ngIf="!this.isSuperTest">
    <mat-slide-toggle class="slide" color="primary" formControlName="scoringRequired" checked="scoringRequired.value">
      Scoring Required
    </mat-slide-toggle>
    <mat-error *ngIf="testDialogForm.get('scoringRequired').errors">Please, enter a valid scoring required.</mat-error>
  </div>
  <div>
    <mat-slide-toggle class="slide" color="primary" formControlName="active" checked="active.value">
      Status
    </mat-slide-toggle>
    <mat-error *ngIf="testDialogForm.get('active').errors">Please, enter a valid status.</mat-error>
  </div>
  <div>
    <mat-slide-toggle
      class="slide"
      color="primary"
      formControlName="everyPageInstructionsEnabled"
      checked="everyPageInstructionsEnabled.value"
    >
      Instructions on top of every page
    </mat-slide-toggle>
  </div>
  <div>
    <mat-slide-toggle
      class="slide"
      color="primary"
      formControlName="customQuestionOrder"
      checked="customQuestionOrder.value"
      (change)="changedCustomerQuestionOrder()"
    >
      Custom Questions Order
    </mat-slide-toggle>
  </div>
  <div>
    <mat-slide-toggle
      class="slide"
      color="primary"
      formControlName="showCardSortInstructions"
      checked="showCardSortInstructions.value"
    >
      Show Card Sort Instructions
    </mat-slide-toggle>
  </div>
  <div>
    <mat-slide-toggle class="slide" color="primary" formControlName="discardAll" checked="discardAll.value">
      Discard All Cards
    </mat-slide-toggle>
  </div>

  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!testDialogForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
