import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { ReportService } from '@app/core/services/report.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-report-wizard',
  templateUrl: './report-wizard.component.html',
  styleUrls: ['./report-wizard.component.scss'],
})
export class ReportWizardComponent implements OnInit {
  reportPsbItems: PsbItem[] = [];

  reportForm = this.fb.group({
    id: [''],
    title: ['', [Validators.required]],
  });

  reportFormDialogRef: MatDialogRef<any>;

  constructor(
    private reportService: ReportService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.reportService.getAll().subscribe((reports) => {
      this.reportPsbItems = reports.map((report) => ({
        id: report.id,
        title: report.title,
      }));
    });
  }

  onDashboard(reportId: number) {
    this.router.navigate(['/report', 'wizard', reportId]);
  }

  onDelete(reportId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.reportService.delete(reportId).subscribe(() => {
          this.reportPsbItems = this.reportPsbItems.filter((item) => item.id !== reportId);
          this.snackBar.info('Report deleted successfully');
        });
      });
  }

  onCreateNewReport(ref: TemplateRef<any>) {
    this.reportForm.patchValue({ id: '', title: '' });
    this.reportFormDialogRef = this.dialog.open(ref);
  }

  onFormSubmit() {
    const { id, title } = this.reportForm.value;

    if (this.isFormEditing) {
      this.reportService.update(id, { title }).subscribe((updatedReport) => {
        this.reportPsbItems = this.reportPsbItems.map((item) =>
          item.id !== updatedReport.id ? item : { id: updatedReport.id, title: updatedReport.title },
        );

        this.reportFormDialogRef.close();
        this.reportFormDialogRef = null;

        this.snackBar.info('Report updated successfully');
      });
      return;
    }

    this.reportService.save({ title }).subscribe((newReport) => {
      this.reportPsbItems = [{ id: newReport.id, title: newReport.title }, ...this.reportPsbItems];

      this.reportFormDialogRef.close();
      this.reportFormDialogRef = null;

      this.snackBar.info('Report created successfully');
    });
  }

  get isFormEditing() {
    return !!this.reportForm.value.id;
  }
}
