import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { PaginationService } from '@app/core/services/pagination.service';
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { QuestionService } from '@app/core/services/question.service';
import { TestObjectService } from '@app/core/services/test-object.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { UserService } from '@app/core/services/user.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';
import { RoleMandate } from '@app/core/models/role-mandate.model';
import { Question } from '@app/core/models/question.model';
import { QuestionGroup } from '@app/core/models/question-group.model';
import { TextAnswer } from '@app/core/models/text-answer.model';
import { Answer } from '@app/core/models/answer.model';

@Component({
  selector: 'app-test-role-mandate',
  templateUrl: './test-role-mandate.component.html',
  styleUrls: ['./test-role-mandate.component.scss'],
})
export class TestRoleMandateComponent implements OnInit {
  roleMandate: RoleMandate;
  roleMandateId: number;
  userInfoId: number;
  config: any;
  decisionTables: DecisionTable[];
  questionGroups: QuestionGroup[] = [];
  questions: Question[];
  allObj: any[];
  questionNumber = 1;
  listTextQuestionsAnswers: any[] = [];
  cardSortQuestionsAnswers: any[] = [];
  lastQuestionNumber: number;
  saveOriginalOrderCardsortAnswersQuestionNumber: number;
  relatedAnswersWithAnsweringCardsort: { currentPage: number; totalRelatedAnswersNumber: number }[] = [];
  relatedAnswers: Answer[] = [];
  textAreaAnswers: TextAnswer[] = [];
  private currentPage: number;

  constructor(
    private router: Router,
    private rMService: RoleMandateService,
    private pService: PaginationService,
    private questionGroupService: QuestionGroupService,
    private questionService: QuestionService,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    public testObjectService: TestObjectService,
    private snackBar: SnackBarService,
    private userService: UserService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit() {
    this.currentPage = +this.route.snapshot.params['page'];
    this.roleMandateId = this.route.snapshot.params['id'];
    this.userInfoId = +localStorage.getItem('userInfoId');
    this.loadData();
  }

  pageChanged(event) {
    let noSaved = false;
    if (this.listTextQuestionsAnswers.length > 0) {
      for (const listTextQuestionAnswer of this.listTextQuestionsAnswers) {
        if (listTextQuestionAnswer && !listTextQuestionAnswer.saved) {
          this.snackBar.info('Please save your answer before you go to the next page');
          noSaved = true;
          return;
        }
      }

      if (!noSaved) {
        this.setCurrentPage(event);
      }
    } else {
      this.setCurrentPage(event);
    }

    if (this.cardSortQuestionsAnswers.length > 0) {
      for (let cardSortQuestionsAnswer of this.cardSortQuestionsAnswers) {
        if (cardSortQuestionsAnswer && !cardSortQuestionsAnswer.saved) {
          this.saveOriginalOrderCardsortAnswersQuestionNumber = cardSortQuestionsAnswer.questionId;
          this.questionService.setOriginalOrderCardsortAnswersQuestionNumberListener(
            this.saveOriginalOrderCardsortAnswersQuestionNumber,
          );
          this.pService.setPaginationListener(true);
        }
      }
    }
  }

  setCurrentPage(event) {
    this.currentPage = event;
    this.config.currentPage = this.currentPage;
    this.router.navigate([this.router.url.split('page/')[0] + '/page/' + this.currentPage]);
    this.pService.setPaginationListener(true);
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  onChangeCardSortAnswerSaved($event) {
    let found = false;
    if (this.cardSortQuestionsAnswers.length === 0) {
      this.cardSortQuestionsAnswers.push($event);
      return;
    }

    if (this.cardSortQuestionsAnswers && this.cardSortQuestionsAnswers.length > 0) {
      for (let cardSortQuestionsAnswer of this.cardSortQuestionsAnswers) {
        if (cardSortQuestionsAnswer.questionId === $event.questionId) {
          if (cardSortQuestionsAnswer.saved === false) {
            cardSortQuestionsAnswer.saved = $event.saved;
            cardSortQuestionsAnswer.answers = $event.answers;
          }

          found = true;
          return;
        }
      }

      if (!found) {
        this.cardSortQuestionsAnswers.push($event);
      }
    }
  }

  onChangeListTextAnswerSaved($event) {
    let found = false;
    if (this.listTextQuestionsAnswers.length === 0) {
      this.listTextQuestionsAnswers.push($event);
      return;
    }

    if (this.listTextQuestionsAnswers && this.listTextQuestionsAnswers.length > 0) {
      for (const listTextQuestionAnswer of this.listTextQuestionsAnswers) {
        if (listTextQuestionAnswer.questionId === $event.questionId) {
          if (!listTextQuestionAnswer.answerId) {
            if (listTextQuestionAnswer.saved === false) {
              listTextQuestionAnswer.saved = $event.saved;
              listTextQuestionAnswer.answerId = $event.answerId;
            }
            found = true;
          }
        }
      }

      if (!found) {
        this.listTextQuestionsAnswers.push($event);
      }
    }
  }

  onReview() {
    this.router.navigate([`/user/role-mandate/${this.roleMandateId}/review`]);
  }

  onBack() {
    this.router.navigate([`/user/role-mandate/${this.roleMandateId}/user-info`]);
  }

  loadData() {
    forkJoin([
      this.rMService.getById(this.roleMandateId),
      this.questionGroupService.getAllQuestionGroupForRoleMandate(this.roleMandateId),
      this.rMService.getAllQuestionById(this.roleMandateId),
      this.rMService.getAllDecisionTableById(this.roleMandateId),
    ]).subscribe(async (results) => {
      this.roleMandate = results[0];
      if (!this.userService.isAllowedOnRoleMandate(this.roleMandate)) {
        return this.router.navigate([`/`]);
      }
      this.decisionTables = results[3] ? results[3] : [];

      for (const questionGroup of results[1]) {
        const questions = results[2].filter((question) => question.questionGroupId === questionGroup.id);

        if (questions.filter((e) => e.relatedQuestionId !== null).length > 0) {
          const questionGroupLists = { ...questionGroup };
          let questionGroupListTextQuestions = questions.filter((question) => question.questionType === 'LIST_TEXT');
          questionGroupLists.questions = questionGroupListTextQuestions.sort((a, b) => {
            return a.relatedQuestionId - b.relatedQuestionId;
          });

          const questionGroupCardsorts = { ...questionGroup };
          questionGroupCardsorts.questions = questions.filter((question) => question.questionType === 'CARDSORT');

          this.questionGroups.push(questionGroupCardsorts);
          this.questionGroups.push(questionGroupLists);
        } else {
          questionGroup.questions = questions;
          this.questionGroups.push(questionGroup);
        }
      }

      this.questions = results[2].filter(
        (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
      );

      this.allObj = [];

      for (let obj of this.questionGroups) {
        obj.networkType = 'questionGroup';
        this.allObj.push(obj);
      }

      for (let obj of this.decisionTables) {
        obj.networkType = 'decisionTable';
        this.allObj.push(obj);
      }

      for (let obj of this.questions) {
        obj.networkType = 'question';
        this.allObj.push(obj);
      }

      this.allObj = this.allObj.sort((a, b) => {
        return a.aorder - b.aorder;
      });

      this.config = {
        itemsPerPage: 1,
        currentPage: this.currentPage,
        totalItems: this.allObj.length,
      };

      await this.calculateQuestionNumber();
    });
  }

  getForkJoin(f: Question) {
    return forkJoin([
      this.questionService.findAllTextAnswerByQuestionIdAndUserId(f.id, this.userInfoId, this.roleMandateId),
      this.questionService.findAnswerCardsortByQuestionIdAndUserId(
        f.relatedQuestionId,
        this.userInfoId,
        this.roleMandateId,
      ),
      this.questionService.findAllAnswersByQuestionId(f.relatedQuestionId),
    ]);
  }

  async getRelatedAnswerTotalNumber(f: Question) {
    const results = await this.getForkJoin(f).toPromise();
    return this.refreshData(results[0], results[1], results[2]);
  }

  refreshData(result0: TextAnswer[], result1: Answer[], result2: any[]): number {
    let relatedAnswerQuestionNumber = 0;
    this.textAreaAnswers = result0 ? result0 : [];

    if (result1) {
      this.relatedAnswers = result1;
    } else {
      this.relatedAnswers = result2;
    }

    for (let index = 0; index < this.relatedAnswers.length; index++) {
      if (index === this.relatedAnswers.length - 1 && this.relatedAnswers.length > 0) {
        relatedAnswerQuestionNumber = this.relatedAnswers.length;
      }
    }

    return relatedAnswerQuestionNumber;
  }

  setRelatedAnswerWithAnsweringCardsort(event: any) {
    let existedCurrentPage = false;
    this.lastQuestionNumber = event.lastRelatedQuestionNumber;
    for (let element of this.relatedAnswersWithAnsweringCardsort) {
      if (element.currentPage === event.currentPage) {
        element.totalRelatedAnswersNumber = event.relatedAnswersNumber + element.totalRelatedAnswersNumber;
        existedCurrentPage = true;
      }
    }

    if (!existedCurrentPage) {
      this.relatedAnswersWithAnsweringCardsort.push({
        currentPage: event.currentPage,
        totalRelatedAnswersNumber: event.relatedAnswersNumber,
      });
    }
  }

  async calculateQuestionNumber() {
    this.questionNumber = 1;

    for (const e of this.allObj) {
      if (e.hasOwnProperty('questions')) {
        if (
          e.questions.some((question) => question.questionType === 'LIST_TEXT' && question.relatedQuestionId !== null)
        ) {
          this.questionNumber = this.questionNumber + (await this.recalculateQuestionNumberWhenAnsweringCardsort(e, 0));
        } else {
          for (const q of e.questions) {
            q.questionNumber = this.questionNumber;
            this.rMService.setRoleMandate(q);
            this.questionNumber++;
          }
        }
      } else {
        if (e.questionType === 'LIST_TEXT' && e.relatedQuestionId !== null) {
          e.relatedQuestionNumber = this.questionNumber;
          this.questionNumber = this.questionNumber + (await this.getRelatedAnswerTotalNumber(e));
          this.rMService.setRoleMandate(e);
        } else {
          e.questionNumber = this.questionNumber;
          this.rMService.setRoleMandate(e);
          this.questionNumber++;
        }
      }
    }
  }

  async recalculateQuestionNumberWhenAnsweringCardsort(e: any, totalRelatedAnswersNumber: number) {
    let relatedAnswerQuestionNumbers = [];
    for (const f of e.questions) {
      if (f.questionType === 'LIST_TEXT' && f.relatedQuestionId !== null) {
        f.relatedQuestionNumber = this.questionNumber;
        this.rMService.setRoleMandate(f);
        relatedAnswerQuestionNumbers.push(await this.getRelatedAnswerTotalNumber(f));
      }
    }

    return totalRelatedAnswersNumber + relatedAnswerQuestionNumbers.reduce((acc, val) => acc + val, 0);;
  }
}
