import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

// services
import { UserService } from '@app/core/services/user.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';

// models
import { UserInfo } from '@app/core/models/userInfo.model';
import { RoleMandatePosition } from '@app/core/models/role-mandate-position.model';

@Component({
  selector: 'app-test-role-mandate-user-info',
  templateUrl: './test-role-mandate-user-info.component.html',
  styleUrls: ['./test-role-mandate-user-info.component.scss'],
})
export class TestRoleMandateUserInfoComponent implements OnInit {
  userInfoId: number;
  roleMandateId: number;
  userInfoData: UserInfo;
  roleMandatePositions: RoleMandatePosition[] = [];

  constructor(
    private userService: UserService,
    private roleMandateService: RoleMandateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.roleMandateId = this.route.snapshot.params['id'];
    this.userInfoId = parseInt(localStorage.getItem('userInfoId'), 10);
    forkJoin([
      this.userService.get(this.userInfoId),
      this.roleMandateService.getRoleMandatePositions(this.roleMandateId),
    ]).subscribe((results) => {
      this.userInfoData = results[0];
      this.roleMandatePositions = results[1];
    });
  }

  onFill(roleTitlePosition: RoleMandatePosition) {
    this.userService.updateRolePosition(this.userInfoId, roleTitlePosition).subscribe({
      next: (result) => {
        this.router.navigate([`/user/role-mandate/${this.roleMandateId}/answer`]);
      },
    });
  }

  isUserRoleTitle(roleTitlePosition: RoleMandatePosition) {
    return this.userInfoData.roleMandatePositionId === roleTitlePosition.id;
  }

  onNext() {
    this.router.navigate([`/user/role-mandate/${this.roleMandateId}/answer`]);
  }
}
