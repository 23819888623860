import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { OptimalScoreRoutingModule } from './optimal-score-routing.module';

import { OptimalScoreCurveComponent } from './optimal-score-curve/optimal-score-curve.component';
import { OptimalScoreCurveDetailComponent } from './optimal-score-curve-detail/optimal-score-curve-detail.component';

@NgModule({
  declarations: [OptimalScoreCurveComponent, OptimalScoreCurveDetailComponent],
  exports: [OptimalScoreCurveComponent, OptimalScoreCurveDetailComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, OptimalScoreRoutingModule],
})
export class OptimalScoreModule {}
