<div class="container-fluid">
  <span *ngIf="this.superTest" class="psb-page-navigator"
    >Supertest > <b>{{ superTest.title }}</b> >
    {{ tabs[+selected.value] }}
  </span>

  <mat-tab-group
    *ngIf="this.superTest"
    mat-align-tabs="start"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="tabChange($event)"
  >
    <mat-tab label="Details">
      <button mat-raised-button color="primary" class="pull-right" (click)="onEdit()">Edit</button>
      <div class="row">
        <div class="column">
          <span class="label">Title</span>
          <p>{{ superTest.title }}</p>
          <span class="label">Description</span>
          <p>{{ superTest.description }}</p>
          <span class="label">Status</span>
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="this.active" color="primary" selected disableRipple> Active </mat-chip>
            <mat-chip *ngIf="!this.active" color="warn" selected disableRipple> Inactive </mat-chip>
          </mat-chip-list>
          <span class="label">Start Instructions</span>
          <p [innerHTML]="superTest.startInstructions"></p>
          <span class="label">Warning Instructions</span>
          <p [innerHTML]="superTest.warningInstructions"></p>
          <span class="label">Paging</span>
          <p>{{ superTest.paging }}</p>
          <span class="label">Randomize Order</span>
          <mat-chip-list aria-label="Randomize Order">
            <mat-chip *ngIf="this.randomizeOrder === 1" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="this.randomizeOrder !== 1" color="warn" selected> No </mat-chip>
          </mat-chip-list>

          <span class="label">Instructions on top of every page</span>
          <mat-chip-list aria-label="Randomize Order">
            <mat-chip *ngIf="this.everyPageInstructionEnabled" color="primary" selected> Yes </mat-chip>
            <mat-chip *ngIf="!this.everyPageInstructionEnabled" color="warn" selected> No </mat-chip>
          </mat-chip-list>
        </div>
      </div>

      <app-super-test-test-list [superTestId]="superTestId"></app-super-test-test-list>
    </mat-tab>

    <mat-tab label="Preview">
      <ng-template matTabContent>
        <app-test-preview
          [superTestId]="superTest.id"
          [startPageInstructions]="superTest.startInstructions"
          [warningInstructions]="superTest.warningInstructions"
          [paging]="superTest.paging"
        ></app-test-preview>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
