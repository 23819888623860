<mat-card>
  <div class="delete">
    <button mat-raised-button type="button" color="accent" aria-label="Delete column" (click)="onDelete()">
      Delete Column
    </button>
  </div>
  <form [formGroup]="editColumnForm" #formDirective="ngForm" (ngSubmit)="editColumn(formDirective)">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" (change)="onChange()" />
      <mat-error *ngIf="editColumnForm.get('title').errors">Please, enter a valid text.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Column Type</mat-label>
      <mat-select [formControl]="editColumnForm.get('type')" (selectionChange)="onChange()">
        <mat-option
          *ngFor="let decisionTableColumnType of decisionTableColumnTypes"
          [value]="decisionTableColumnType.id"
        >
          {{ decisionTableColumnType.name }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="editColumnForm.get('type').hasError('required')"> You must make a selection </mat-error>
    </mat-form-field>

    <mat-slide-toggle color="primary" t [formControl]="editColumnForm.get('required')" (change)="onChange()"
      >Required
    </mat-slide-toggle>
  </form>
</mat-card>
