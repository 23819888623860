import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Superscale } from '@app/core/models/superscale.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-superscale-create-dialog',
  templateUrl: './superscale-create-dialog.component.html',
  styleUrls: ['./superscale-create-dialog.component.scss'],
})
export class SuperscaleCreateDialogComponent implements OnInit {
  superscaleDialogForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<SuperscaleCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; superscale: Superscale },
  ) {}

  ngOnInit(): void {
    if (this.data.superscale) {
      this.superscaleDialogForm.get('title').setValue(this.data.superscale.title);
      this.superscaleDialogForm.get('description').setValue(this.data.superscale.description);
    }
  }

  onSave(): void {
    this.dialogRef.close({ event: 'save', data: this.superscaleDialogForm.value });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
