import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BlossomChartPetalReport, BlossomChartPistilReport, BlossomChartService } from '../../../../../../generated/v2';
import { hexToColor } from '@app/admin/pages/chart/blossom/hex-to-color';
import svgpath from 'svgpath';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-blossom-graph',
  templateUrl: './blossom-graph.component.html',
  styleUrls: ['./blossom-graph.component.scss'],
})
export class BlossomGraphComponent implements OnInit {
  src: string;

  @Input() chartId: number;

  @Input() testGroupId: number;

  @Input() userInfoId: number;

  petals: BlossomChartPetalReport[] = [];

  constructor() {}

  ngOnInit(): void {
    this.src = `${environment.apiUrl}/v3/user/${this.userInfoId}/testGroup/${this.testGroupId}/chart/${this.chartId}/blossom-chart.png`;
  }
}
