export class ChartHelper {
  addCopyright(svg, x, y) {
    svg
      .append('text')
      .attr('y', y)
      .attr('x', x)
      .text('* Information Design created by Pennsylvania College of Art & Design')
      .style('font-family', 'Arial')
      .style('font-size', 11)
      .style('text-anchor', 'middle')
      .style('fill', '#808080');
  }
}
