<h3>Move User From Other Test Group</h3>
<mat-divider></mat-divider>
<div class="row transfer-user">
  <div class="column" *ngIf="data.testGroupUsers.length > 0; else noTestGroupUsers">
    <mat-form-field>
      <mat-label>Search User</mat-label>
      <mat-chip-list #chipList aria-label="User selection">
        <mat-chip *ngFor="let user of selectedUserList" (removed)="removeUser(user)">
          {{ user.name }}
          <button class="close-icon" mat-icon-button matChipRemove>
            <mat-icon><span>cancel</span></mat-icon>
          </button>
        </mat-chip>
        <input
          matInput
          [formControl]="filterUser"
          (keyup)="applyFilterUser($event)"
          [matChipInputFor]="chipList"
          placeholder="User name to be made"
          #input
        />
      </mat-chip-list>
    </mat-form-field>
    <div class="row">
      <span class="users-selected"
        ><b>{{ selectionUsers.selected.length }}</b> users selected</span
      >
    </div>
    <table mat-table [dataSource]="testGroupUsers" class="mat-elevation-z8" matSort #sortUsers="matSort">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? masterToggleUser() : null"
            [checked]="selectionUsers.hasValue() && isAllUserSelected()"
            [indeterminate]="selectionUsers.hasValue() && !isAllUserSelected()"
            [aria-label]="checkUserboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            #eachUser
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="onUserSelection(row, eachUser.checked)"
            [checked]="selectionUsers.isSelected(row)"
            [aria-label]="checkUserboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedTestGroupUsersColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedTestGroupUsersColumns"></tr>
    </table>
    <mat-paginator #userPaginator [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
  </div>
  <ng-template class="column" #noTestGroupUsers> No transferred test group users found. </ng-template>

  <div class="column" *ngIf="data.transferredTestGroups.length > 0; else noTransferredTestGroups">
    <mat-form-field>
      <mat-label>Search Test Group</mat-label>
      <mat-chip-list #testGroupChipList aria-label="Test Group selection">
        <mat-chip *ngIf="selectedTestGroup" (removed)="removeTestGroup()">
          {{ selectedTestGroup.title }}
          <button class="close-icon" mat-icon-button matChipRemove>
            <mat-icon><span>cancel</span></mat-icon>
          </button>
        </mat-chip>
        <input
          matInput
          [formControl]="filterTestGroup"
          (keyup)="applyFilterTestGroup($event)"
          [matChipInputFor]="testGroupChipList"
          placeholder="Test group name to be made"
          #input
        />
      </mat-chip-list>
    </mat-form-field>

    <div class="row">
      <span class="users-selected"
        ><b>{{ selectedTestGroup ? 1 : 0 }}</b> test groups selected</span
      >
    </div>
    <table mat-table [dataSource]="testGroups" class="mat-elevation-z8" matSort #sortTestGroups="matSort">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            #eachTestGroup
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="onTestGroupSelection(row, eachTestGroup.checked)"
            [checked]="row.id === selectedTestGroup?.id"
            [aria-label]="checkTestGroupboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Test Group Title</th>
        <td mat-cell *matCellDef="let element" class="mat-cell-wrap">{{ element.title }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedTestGroupsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedTestGroupsColumns"></tr>
    </table>
    <mat-paginator
      #testGroupPaginator
      [pageSizeOptions]="[5, 10, 25]"
      [length]="testGroupPaginator.length"
    ></mat-paginator>
  </div>
  <ng-template class="column" #noTransferredTestGroups> No transferred test groups found. </ng-template>
</div>
<div class="row transfer-buttons">
  <button mat-button class="btn-success btn-300" type="button" (click)="onClose()">
    <mat-icon inline="true">block</mat-icon>
    <span class="ml-1">Close</span>
  </button>
  <button
    mat-button
    class="btn-success btn-300"
    (click)="onTransfer()"
    [disabled]="selectedUserList.length === 0 || selectedTestGroup === null"
  >
    <mat-icon inline="true">send</mat-icon>
    <span class="ml-1">Transfer</span>
  </button>
</div>
