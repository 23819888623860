<div class="container">
  <app-psb-page-navigator [pages]="pageService.getConnectionIndicatorPages()"></app-psb-page-navigator>

  <div id="connections-indicators">
    <form [formGroup]="ciForm">
      <mat-form-field>
        <mat-label>Connections Indicator Title</mat-label>
        <input matInput type="text" formControlName="title" />
        <mat-error *ngIf="ciForm.get('title').errors">Please, enter a valid title.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Company</mat-label>
        <input matInput type="text" formControlName="company" [matAutocomplete]="auto" />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="setCompany($event.option.value)"
        >
          <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
            {{ company.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="ciForm.get('company').errors">Please, enter a valid company.</mat-error>
      </mat-form-field>

      <h4>Instructions</h4>
      <angular-editor type="text" formControlName="instructions" [config]="editorConfig"></angular-editor>
      <mat-error
        *ngIf="
          ciForm.get('instructions').errors && (ciForm.get('instructions').dirty || ciForm.get('instructions').touched)
        "
      >
        Please, enter a valid instructions.</mat-error
      >
    </form>

    <div>
      <h2>Departments</h2>
      <p>
        Enter the name of the departments of the company. This department will be showed to the users selection and
        clustering.
      </p>

      <form [formGroup]="departmentForm" (ngSubmit)="newDepartment()" class="row">
        <div class="column">
          <mat-form-field class="department-input">
            <mat-label>Department</mat-label>
            <input matInput type="text" formControlName="name" />
            <mat-error *ngIf="departmentForm.get('name').errors">Please, enter a valid department.</mat-error>
            <mat-error *ngIf="ciForm.get('company').getError('incorrect') && departmentFormSubmitted"
              >Please, select a company</mat-error
            >
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button type="submit" class="btn-success" [disabled]="!ciForm.controls['company'].valid">
            Add
          </button>
        </div>
      </form>

      <mat-table [dataSource]="departments" class="mat-elevation-z3">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actionDelete">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button class="btn-error" (click)="onDeleteDepartment(element)">Delete</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedDepartmentsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedDepartmentsColumns"></mat-row>
      </mat-table>
      <button mat-button class="btn-primary btn-center btn-300">
        <mat-icon>add</mat-icon>Import CSV
        <input type="file" accept=".csv" (change)="handleDepartmentFile($event)" class="input-file" />
        <mat-error *ngIf="ciForm.get('company').getError('incorrect') && departmentsCSVImported"
          >Please, select a company</mat-error
        >
      </button>
    </div>
    <div class="next">
      <button mat-raised-button class="btn-success btn-center btn-300" (click)="onNext()" [disabled]="!ciForm.valid">
        Next
      </button>
    </div>
  </div>
</div>
