import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// components
import { EditDecisionTableConnectionComponent } from './edit-decision-table-connection/edit-decision-table-connection.component';

// services
import { DecisionTableService } from '@app/core/services/decision-table.service';

// models
import { DecisionTableRelation } from '@app/core/models/decision-table-relation.model';
import { DecisionTable } from '@app/core/models/decision-table.model';

@Component({
  selector: 'app-decision-table-connection',
  templateUrl: './decision-table-connection.component.html',
  styleUrls: ['./decision-table-connection.component.scss'],
})
export class DecisionTableConnectionComponent {
  @Input() decisionTableRelation: DecisionTableRelation;
  @Input() decisionTables: DecisionTable[];
  @Output() delete = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();

  constructor(private decisionTableService: DecisionTableService, private dialog: MatDialog) {}

  onDelete(decisionTableRelation) {
    this.delete.emit(decisionTableRelation.id);
  }

  onEdit(dtRelation) {
    const dialogEdit = this.dialog.open(EditDecisionTableConnectionComponent, {
      width: '700px',
      data: {
        decisionTableRelation: dtRelation,
        decisionTables: this.decisionTables,
      },
    });

    dialogEdit.afterClosed().subscribe((result) => {
      if (result && result.event === 'confirm') {
        this.decisionTableService
          .editDecisionTableRelation(dtRelation.id, result.data)
          .subscribe(() => this.edit.emit({ id: this.decisionTableRelation.id, data: result.data }));
      }
    });
  }
}
