import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Subscale } from '../models/subscale.model';
import { ScorePercentile } from '../models/score-percentile.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class SubscaleService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<any> {
    return this.http.get<Subscale[]>(BACKEND_URL + '/subscale').pipe(take(1));
  }

  getAllAnswerSubscale() {
    return this.http.get<Subscale[]>(BACKEND_URL + '/subscale/answer').pipe(take(1));
  }

  get(id: number): Observable<Subscale> {
    return this.http.get<Subscale>(BACKEND_URL + '/subscale/' + id).pipe(take(1));
  }

  delete(id: number): Observable<any> {
    return this.http.delete(BACKEND_URL + '/subscale/' + id).pipe(take(1));
  }

  archive(id: number): Observable<any> {
    return this.http.delete(BACKEND_URL + '/subscale/' + id + '/archive').pipe(take(1));
  }

  create(subscale: Subscale): Observable<any> {
    return this.http.post(`${BACKEND_URL}/subscale`, subscale).pipe(take(1));
  }

  createAll(subscales: Subscale[]): Observable<any> {
    return this.http.post(`${BACKEND_URL}/subscale/all`, subscales).pipe(take(1));
  }

  update(id: number, subscale: Subscale): Observable<any> {
    return this.http.put(`${BACKEND_URL}/subscale/` + id, subscale).pipe(take(1));
  }

  getAllScorePercentile(id: number) {
    return this.http.get<ScorePercentile[]>(BACKEND_URL + '/subscale/' + id + '/score-percentile');
  }

  saveAllScorePercentile(id: number, scorePercentiles: ScorePercentile[]) {
    return this.http.post(BACKEND_URL + '/subscale/' + id + '/score-percentile', { scorePercentiles });
  }

  deleteAllScorePercentile(id: number) {
    return this.http.delete(BACKEND_URL + '/subscale/' + id + '/score-percentile');
  }

  copy(id: number): Observable<any> {
    return this.http.get(BACKEND_URL + '/subscale/' + id + '/copy').pipe(take(1));
  }

  getAllRelatedAssessmentAndSubscaleById(id: number) {
    return this.http.get(BACKEND_URL + '/subscale/' + id + '/all-related-assessment-and-subscale').pipe(take(1));
  }
}
