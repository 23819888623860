import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { SharedModule } from '@app/shared/shared.module';
import { ReportWizardRoutingModule } from './report-wizard-routing.module';

import { ReportWizardComponent } from './report-wizard/report-wizard.component';
import { ReportDetailsComponent } from './report-details/report-details.component';
import { EditReportTitleDialogComponent } from './edit-report-title-dialog/edit-report-title-dialog.component';
import { AddChapterDialogComponent } from './add-chapter-dialog/add-chapter-dialog.component';
import { AddChartDialogComponent } from './add-chart-dialog/add-chart-dialog.component';
import { EditRangeDialogComponent } from './edit-range-dialog/edit-range-dialog.component';
import { EditDoubleRangeDialogComponent } from './edit-double-range-dialog/edit-double-range-dialog.component';
import { ReportScaleListComponent } from './report-scale-list/report-scale-list.component';

@NgModule({
  declarations: [
    ReportWizardComponent,
    ReportDetailsComponent,
    EditReportTitleDialogComponent,
    AddChapterDialogComponent,
    AddChartDialogComponent,
    EditRangeDialogComponent,
    EditDoubleRangeDialogComponent,
    ReportScaleListComponent,
  ],
  imports: [CommonModule, ReportWizardRoutingModule, SharedModule, ReactiveFormsModule, AngularEditorModule],
})
export class ReportWizardModule {}
