/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { User } from '../models/User';
import type { UserConnection } from '../models/UserConnection';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class UserConnectionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all users by id
     * Retrieve all users by id
     * @param id Id of the Company
     * @returns User A list of users
     * @throws ApiError
     */
    public getCompanyUser(
        id: number,
    ): Observable<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/user',
            path: {
                'id': id,
            },
            errors: {
                404: `User not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all user connection by id
     * Retrieve all user connection by id
     * @param id Id of the company
     * @returns UserConnection A list of user connections
     * @throws ApiError
     */
    public getCompanyUserConnection(
        id: number,
    ): Observable<UserConnection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/user-connection',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of user connections not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all user connection info by id
     * Retrieve all user connection info by id
     * @param id Id of the company
     * @returns UserConnection A list of user connection infos
     * @throws ApiError
     */
    public getCompanyUserConnectionInfo(
        id: number,
    ): Observable<UserConnection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/user-connection-info',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of user connection infos not found`,
                500: `Internal error`,
            },
        });
    }

}
