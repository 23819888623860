import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { ChartsModule } from 'ng2-charts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { SharedModule } from '@app/shared/shared.module';

import { ChapterRoutingModule } from './chapter-routing.module';
import { ChapterComponent } from './chapter/chapter.component';
import { SectionDialogFormComponent } from './section-dialog-form/section-dialog-form.component';
import { UpdateChapterDialogComponent } from './update-chapter-dialog/update-chapter-dialog.component';
import { SectionParagraphFormComponent } from './section-paragraph-form/section-paragraph-form.component';
import { SectionBulletPointFormComponent } from './section-bullet-point-form/section-bullet-point-form.component';
import { SectionAllCombinationParagraphFormComponent } from './section-all-combination-paragraph-form/section-all-combination-paragraph-form.component';
import { SectionOrdinalParagraphFormComponent } from './section-ordinal-paragraph-form/section-ordinal-paragraph-form.component';
import { SectionChartFormComponent } from './section-chart-form/section-chart-form.component';
import { SectionScorecardsFormComponent } from './section-scorecards-form/section-scorecards-form.component';

@NgModule({
  declarations: [
    ChapterComponent,
    SectionDialogFormComponent,
    UpdateChapterDialogComponent,
    SectionParagraphFormComponent,
    SectionBulletPointFormComponent,
    SectionAllCombinationParagraphFormComponent,
    SectionOrdinalParagraphFormComponent,
    SectionChartFormComponent,
    SectionScorecardsFormComponent,
  ],
  imports: [
    CommonModule,
    ChapterRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MatStepperModule,
    ChartsModule,
    Ng2SearchPipeModule,
  ],
  exports: [
    SectionParagraphFormComponent,
    SectionBulletPointFormComponent,
    SectionAllCombinationParagraphFormComponent,
    SectionOrdinalParagraphFormComponent,
    SectionChartFormComponent,
    SectionScorecardsFormComponent,
  ],
})
export class ChapterModule {}
