/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupTestUserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Start a psytest
     * Start a psytest
     * @param requestBody Callback payload
     * @throws ApiError
     */
    public postTestStart(
        requestBody?: {
            /**
             * Id of the psytest
             */
            id: number;
            /**
             * Id of the Test Group
             */
            testGroupId: string;
            /**
             * Id of the User Info
             */
            userInfoId: number;
        },
    ): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Complete a psytest
     * Complete a psytest
     * @param requestBody Callback payload
     * @throws ApiError
     */
    public postTestComplete(
        requestBody?: {
            /**
             * Id of the psytest
             */
            id: number;
            /**
             * Id of the Test Group
             */
            testGroupId: string;
            /**
             * Id of the User Info
             */
            userInfoId: number;
        },
    ): Observable<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/complete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
