<form [formGroup]="form" (ngSubmit)="save()">
  <mat-form-field>
    <mat-label>Label</mat-label>
    <input matInput type="text" formControlName="label" />
    <mat-error>Enter label</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Color</mat-label>
    <input formControlName="color" matInput [ngxMatColorPicker]="picker" />
    <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
    <ngx-mat-color-picker #picker [touchUi]="true"></ngx-mat-color-picker>
    <mat-error>Enter valid color</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Petal</mat-label>
    <mat-select formControlName="petalId">
      <mat-option *ngFor="let petal of petals" [value]="petal.id">
        {{ petal.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Order</mat-label>
    <input matInput type="number" formControlName="order" />
    <mat-error>Enter valid order number</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Subscale</mat-label>
    <input #input required matInput type="text" formControlName="subscaleId" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySubscaleFn">
      <mat-option *ngFor="let subscale of filterSubscales(input.value)" [value]="subscale.id">
        <div class="double-line-option">
          {{ subscale.title }}
          <div *ngIf="subscale.description" class="small-text">{{ subscale.description }}</div>
        </div>
      </mat-option>
    </mat-autocomplete>
    <mat-error>Select subscale</mat-error>
  </mat-form-field>

  <div class="reset-checkbox">
    <mat-slide-toggle class="slide" formControlName="isReverse">Reverse</mat-slide-toggle>
  </div>

  <button mat-raised-button color="primary" type="submit">
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</form>
