import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Subject, forkJoin } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatChipList } from '@angular/material/chips';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// components
import { EditUserInviteDialogComponent } from './edit-user-invite-dialog/edit-user-invite-dialog.component';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { InviteService } from '@app/core/services/invite.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { EmailService } from '@app/core/services/email.service';
import { SendEmailService } from '@app/core/services/send-email.service';

// models
import { User } from '@app/core/models/user.model';
import { Invite } from '@app/core/models/invite.model';
import { ExistedEmail } from '@app/core/models/existed-email.model';
import { InviteConnectionIndicator } from '@app/core/models/invite-connection-indicator.model';
import { InviteRoleMandate } from '@app/core/models/invite-role-mandate.model';
import { InviteTestGroup } from '@app/core/models/invite-test-group.model';
import { EmailSet } from '@app/core/models/email-set.model';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { EMAIL_PATTERN } from '@app/shared/patterns/pattern-format';
import { TestGroupService } from '@app/core/services/test-group.service';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss'],
})
export class UserInviteComponent implements OnInit {
  @Input() connectionIndicatorId: number;
  @Input() roleMandateId: number;
  @Input() testGroup: PsyTestGroup;
  @Input() hasTests: boolean;
  users: User[] = [];
  invites: MatTableDataSource<Invite> = new MatTableDataSource<Invite>();
  emailFieldUpdate = new Subject<string>();
  massEmailFieldUpdate = new Subject<string>();
  companyId: number;
  displayedInviteColumns: string[] = ['firstName', 'lastName', 'email', 'actionDelete'];
  linkedUsers = 0;
  emailNoExisted = false;
  validEmailAddress = false;
  inviteForm: FormGroup;
  massInviteForm: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  emailSet: EmailSet;
  sources: String[] = [];
  resendInvite: Invite;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  invalidEmails: { email: string; reason: string }[] = [];
  isSentInvitationToSameAddressWithinOneHour = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('chipList') chipList: MatChipList;
  @ViewChild('massEmail') massEmail: ElementRef;

  constructor(
    private cIService: ConnectionsIndicatorService,
    private rMService: RoleMandateService,
    public snackBar: SnackBarService,
    private inviteService: InviteService,
    private emailService: EmailService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private testGroupService: TestGroupService,
    private sendEmailService: SendEmailService,
  ) {
    this.inviteForm = this.fb.group({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl('', [Validators.required]),
      from: new FormControl({ value: '', disabled: true }, [Validators.required]),
    });

    this.massInviteForm = this.fb.group({
      emails: this.fb.array([], this.validateArrayNotEmpty),
      from: new FormControl({ value: '', disabled: true }, [Validators.required]),
    });

    this.emailFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        if (!this.hasTests && this.testGroup) {
          this.inviteForm.get('email').setErrors({ noTests: true });
          return;
        }

        if (EMAIL_PATTERN.test(value)) {
          let existedEmail = this.createExistedEmail(value);

          this.inviteService.blockSentInviteToSameAddressWithinOneHour(existedEmail).subscribe((result) => {
              if (result.status === 'id') {
                this.inviteForm.get('email').setErrors({ isSentInviteToSameAddressWithinOneHour: true });
              } else {
                this.inviteService.checkExistedEmail(existedEmail).subscribe((result) => {
                  if (result) {
                    this.inviteForm.get('email').setErrors({ isEmailExist: true });
                    this.emailNoExisted = false;
                  } else {
                    this.emailNoExisted = true;
                    this.validEmailAddress = true;
                  }
                });
              }
          });
        } else {
          this.inviteForm.get('email').setErrors({ invalidEmailAddress: true });
          this.validEmailAddress = false;
        }
      }
    });

    this.massEmailFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((event) => {
      if (!this.hasTests && this.testGroup) {
        this.massInviteForm.get('emails').setErrors({ noTests: true });
        return;
      }

      this.addEmail(event).then();
    });
  }

  ngOnInit(): void {
    this.massInviteForm
      .get('emails')
      .statusChanges.subscribe((status) => (this.chipList.errorState = status === 'INVALID'));

    this.loadInvites();
    this.loadEmail();
  }

  private loadInvites() {
    // @TODO: Refact PCI and PRN as PTG
    if (this.connectionIndicatorId) {
      forkJoin([
        this.inviteService.getAllInviteByConnectionIndicatorId(this.connectionIndicatorId),
        this.cIService.getById(this.connectionIndicatorId),
      ]).subscribe((results) => {
        this.invites.data = results[0];
        this.invites.paginator = this.paginator;
        this.companyId = results[1]?.companyId;
      });
    }
    if (this.roleMandateId) {
      forkJoin([
        this.inviteService.getAllInviteByRoleMandateId(this.roleMandateId),
        this.rMService.getById(this.roleMandateId),
      ]).subscribe((results) => {
        this.invites.data = results[0];
        this.invites.paginator = this.paginator;
        this.companyId = results[1]?.companyId;
      });
    }
    if (this.testGroup) {
      this.inviteService.getAllInviteByTestGroupId(this.testGroup.id).subscribe((invites: Invite[]) => {
        this.invites.data = invites;
        this.invites.paginator = this.paginator;
        this.companyId = this.testGroup?.companyId;
      });
    }
  }

  private loadEmail() {
    if (this.roleMandateId) {
      this.emailService.findAllByRoleMandateId(this.roleMandateId).subscribe((emailSet) => {
        this.emailSet = emailSet;
        this.sources = emailSet.sources;
        if (this.sources) {
          this.setEmailSource();
        }
      });
    } else if (this.connectionIndicatorId) {
      this.emailService.findAllByConnectionIndicatorId(this.connectionIndicatorId).subscribe((emailSet) => {
        this.emailSet = emailSet;
        this.sources = emailSet.sources;
        if (this.sources) {
          this.setEmailSource();
        }
      });
    } else if (this.testGroup.id) {
      this.testGroupService.get(this.testGroup.id).subscribe((psyTestGroup: PsyTestGroup) => {
        this.emailService.findAllByTestGroupId(this.testGroup.id).subscribe((emailSet) => {
          this.emailSet = emailSet;
          this.sources.push(psyTestGroup.fromEmailAddress ? psyTestGroup.fromEmailAddress : 'psybil@psynetgroup.com');
          if (this.sources) {
            this.setEmailSource();
          }
        });
      });
    }
  }

  setEmailSource() {
    if (this.sources.length > 1) {
      this.inviteForm.controls['from'].enable();
      this.massInviteForm.controls['from'].enable();
    }
    this.inviteForm.patchValue({ from: typeof this.sources[0] !== 'undefined' ? this.sources[0] : '' });
    this.massInviteForm.patchValue({ from: typeof this.sources[0] !== 'undefined' ? this.sources[0] : '' });
  }

  get emailControls(): FormArray {
    return this.massInviteForm.controls.emails as FormArray;
  }

  validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false },
      };
    }
    return null;
  }

  async addEmail(event: any) {
    const input = event.target.input;
    let value = event.target.value;
    if (value.includes(' ') && !value.includes(',')) {
      value = value.replace(/ /g, ',');
    }

    if (value && value.length > 0) {
      if (value.indexOf(',') > -1) {
        const emails = value.split(',').map((e: string) => e.trim());
        this.validateEmails(input, emails);
      } else {
        await this.validateEmail(input, value);
      }
    } else {
      this.massInviteForm.get('emails').setErrors({ isRequired: true });
    }
  }

  validateEmails(input: any, emails: any) {
    let validatedEmail: any;

    if (this.connectionIndicatorId) {
      validatedEmail = {
        emails: emails,
        connectionIndicatorId: this.connectionIndicatorId,
      };
    }

    if (this.roleMandateId) {
      validatedEmail = {
        emails: emails,
        roleMandateId: this.roleMandateId,
      };
    }

    if (this.testGroup) {
      validatedEmail = {
        emails: emails,
        testGroupId: this.testGroup.id,
      };
    }

    this.inviteService.validateMultipleEmails(validatedEmail).subscribe(results => {
      
      for (const result of results) {
        if (result.status === 'Ok') {
          this.emailControls.push(this.fb.control(result.email));
        } else {
          if (result.isExisted) {
            if (this.invalidEmails.every((e) => e.email !== result.email)) {
              this.invalidEmails.push({ email: result.email, reason: ' was already ' + result.isExisted.status.toLowerCase() });
            }
          } else if (result.status === 'Blocked') {
            if (this.invalidEmails.every((e) => e.email !== result.email)) {
              this.invalidEmails.push({ email: result.email, reason: 'was already sent an invitation to the same address within one hour' });
            }
          } else if (result.status === 'Invalid') {
            if (this.invalidEmails.every((e) => e.email !== result.email)) {
              this.invalidEmails.push({ email: result.email, reason: 'was an invalid email address' });
            }
          }
        }
      }

      const okEmails = results.filter(result => result.status === 'Ok');

      if (okEmails && okEmails.length >= 300) {
        const infoDialog = this.dialog.open(InfoDialogComponent, {
          height: '21vh',
          width: '25vw',
        });
        infoDialog.componentInstance.message = `The 300 email addresses limit was exceeded; we will only send the invites to the first 300 emails.`;
        infoDialog.afterClosed().subscribe((result) => {
          if (typeof result !== 'undefined' && result.event === 'close') {
            this.emailControls.value.slice(0, 301);
          }
        });
      }

      // Reset the input and value
      if (input) {
        input.value = '';
      }

      this.massEmail.nativeElement.value = '';
    });
  }

  async validateEmail(input: any, value: string) {
    if (EMAIL_PATTERN.test(value)) {
      let existedEmail = this.createExistedEmail(value);
      this.isSentInvitationToSameAddressWithinOneHour = false;

      this.inviteService.blockSentInviteToSameAddressWithinOneHour(existedEmail).subscribe((result) => {
        if (result) {
          this.isSentInvitationToSameAddressWithinOneHour = true;

          if (this.invalidEmails.every((e) => e.email !== value)) {
            this.invalidEmails.push({ email: value, reason: 'was already sent an invitation to the same address within one hour' });
          }

          // Reset the input and value
          if (input) {
            input.value = '';
          }
          this.massEmail.nativeElement.value = '';
        }
      });

      this.inviteService.checkExistedEmail(existedEmail).subscribe((result) => {
        if (result) {
          this.massInviteForm.get('emails').setErrors({ isEmailExist: true });
          this.emailNoExisted = false;
          if (this.invalidEmails.every((e) => e.email !== value)) {
            this.invalidEmails.push({ email: value, reason: ' was already ' + result.status.toLowerCase() });
          }
        } else {
          // Add our email
          if ((value || '').trim() && !this.emailControls.value.includes(value)) {
            if (this.emailControls.value && this.emailControls.value.length >= 300) {
              const infoDialog = this.dialog.open(InfoDialogComponent, {
                height: '21vh',
                width: '25vw',
              });
              infoDialog.componentInstance.message = `The 300 email addresses limit was exceeded; we will only send the invites to the first 300 emails.`;
              infoDialog.afterClosed().subscribe((result) => {
                if (typeof result !== 'undefined' && result.event === 'close') {
                  this.emailControls.value.slice(0, 301);
                }
              });
            } else if (!this.isSentInvitationToSameAddressWithinOneHour) {
              this.emailControls.push(this.fb.control(value));
            }
          }

          this.emailNoExisted = true;
          this.validEmailAddress = true;
        }
        // Reset the input and value
        if (input) {
          input.value = '';
        }
        this.massEmail.nativeElement.value = '';
      });
    } else {
      this.validEmailAddress = false;
      if (this.invalidEmails.every((e) => e.email !== value)) {
        this.invalidEmails.push({ email: value, reason: 'was an invalid email address' });
      }

      // Reset the input and value
      if (input) {
        input.value = '';
      }
      this.massEmail.nativeElement.value = '';
    }
  }

  createExistedEmail(value: string) {
    let existedEmail: ExistedEmail;

    if (this.connectionIndicatorId) {
      existedEmail = {
        email: value,
        connectionIndicatorId: this.connectionIndicatorId,
      };
    }

    if (this.roleMandateId) {
      existedEmail = {
        email: value,
        roleMandateId: this.roleMandateId,
      };
    }

    if (this.testGroup) {
      existedEmail = {
        email: value,
        testGroupId: this.testGroup.id,
      };
    }

    return existedEmail;
  }

  removeEmail(email: string): void {
    const index = this.emailControls.value.indexOf(email);
    if (index >= 0) {
      this.emailControls.removeAt(index);
    }
  }

  createNewInvites() {
    let massInvites: Invite[];
    if (this.connectionIndicatorId) {
      (massInvites = this.assignNewInvites()),
        this.inviteService
          .saveAllInviteConnectionIndicator(massInvites, this.connectionIndicatorId)
          .subscribe((result) => {
            this.snackBar.info('Invite successfully created.', 'invite-snack');
            this.afterNewInvites(massInvites);
          });
    }

    if (this.roleMandateId) {
      (massInvites = this.assignNewInvites()),
        this.inviteService.saveAllInviteRoleMandate(massInvites, this.roleMandateId).subscribe((result) => {
          this.snackBar.info('Invite successfully created.', 'invite-snack');
          this.afterNewInvites(massInvites);
        });
    }

    if (this.testGroup) {
      (massInvites = this.assignNewInvites(this.testGroup.id)),
        this.inviteService
          .saveAllInviteTestGroup(massInvites, this.testGroup.id)
          .subscribe((result) => {
            this.snackBar.info('Invite successfully created.', 'invite-snack');
            this.afterNewInvites(massInvites);
          });
    }
  }

  createNewInvite() {
    const invite: Invite = {
      psyTestGroupId: null,
      active: true,
      token: null,
      firstName: this.inviteForm.get('firstName').value ? this.inviteForm.get('firstName').value : '',
      lastName: this.inviteForm.get('lastName').value ? this.inviteForm.get('lastName').value : '',
      companyId: this.companyId,
      email: this.inviteForm.get('email').value,
      invited: false,
      from: this.inviteForm.get('from').value ? this.inviteForm.get('from').value : null,
      logoEnabled: this.testGroup ? this.testGroup.logoEnabled : null,
    };

    if (!invite.email) {
      return;
    }

    if (this.connectionIndicatorId) {
      const inviteConnectionIndicator: InviteConnectionIndicator = {
        invite,
        connectionIndicatorId: this.connectionIndicatorId,
      };
      this.inviteService.saveInviteConnectionIndicator(inviteConnectionIndicator).subscribe((result) => {
        invite.id = result.id;
        this.resendInvite = invite;
        this.snackBar.info('Invite successfully created.', 'invite-snack');
        this.afterNewInvite(invite);
      });
    }
    if (this.roleMandateId) {
      const inviteRoleMandate: InviteRoleMandate = {
        invite,
        roleMandateId: this.roleMandateId,
      };
      this.inviteService.saveInviteRoleMandate(inviteRoleMandate).subscribe((result) => {
        invite.id = result.id;
        invite.token = result.token;
        this.resendInvite = invite;
        this.snackBar.info('Invite successfully created.', 'invite-snack');
        this.afterNewInvite(invite);
      });
    }
    if (this.testGroup) {
      invite.psyTestGroupId = this.testGroup.id;
      const inviteTestGroup: InviteTestGroup = {
        invite,
        testGroupId: this.testGroup.id,
      };
      this.inviteService.saveInviteTestGroup(inviteTestGroup).subscribe((invite: Invite) => {
        this.resendInvite = invite;
        this.snackBar.info('Invite successfully created.', 'invite-snack');
        this.afterNewInvite(invite);
      });
    }
  }

  assignNewInvites(psyTestGroupId?: number) {
    let newInvites: Invite[] = [];
    this.emailControls.value.forEach((email) => {
      const invite: Invite = {
        psyTestGroupId: psyTestGroupId ? psyTestGroupId : null,
        active: true,
        token: null,
        firstName: '',
        lastName: '',
        companyId: this.companyId,
        email: email,
        invited: false,
        from: this.massInviteForm.get('from').value ? this.massInviteForm.get('from').value : null,
      };

      if (!invite.email) {
        return;
      }

      newInvites.push(invite);
    });

    return newInvites;
  }

  afterNewInvites(invites) {
    invites.forEach((invite) => {
      const data = this.invites.data;
      data.unshift(invite);
      this.invites.data = data;
      this.clearMassInviteFormValidators();
    });
    this.sendEmailService.setInviteSentListener(true);
  }

  afterNewInvite(invite) {
    const data = this.invites.data;
    data.unshift(invite);
    this.invites.data = data;
    this.clearInviteFormValidators();
    this.sendEmailService.setInviteSentListener(true);
  }

  applyFilterInvites(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.invites.filter = filterValue.trim().toLowerCase();

    if (this.invites.paginator) {
      this.invites.paginator.firstPage();
    }
  }

  onDeleteInvite(element) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);
    deleteDialog.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        if (!element.id) {
          this.invites.data = this.invites.data.filter((item) => item.email !== element.email);
        } else {
          this.inviteService.deleteInvite(element).subscribe(() => {
            this.invites.data = this.invites.data.filter((item) => item.id !== element.id);
          });
        }
      }
    });
  }

  onEditInvite(element) {
    const editDialog = this.dialog.open(EditUserInviteDialogComponent, { data: element });
    editDialog.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event === EVENT_SUCCESS) {
        this.snackBar.info('Invite successfully updated!');
      }
    });
  }

  handleInviteCSVData(lines) {
    for (const line of lines) {
      this.addToInvite(line[0], line[1], line[2]);
    }
  }

  addToInvite(firstName, lastName, email) {
    const invite: Invite = {
      firstName,
      lastName,
      email,
      companyId: this.companyId,
      psyTestGroupId: null,
      active: null,
      token: null,
      invited: false,
    };

    if (!invite.firstName && !invite.lastName && !invite.email) {
      return;
    }

    this.invites.data = this.invites.data.concat(invite);
  }

  resendInviteEmail() {
    if (this.resendInvite) {
      if (this.connectionIndicatorId) {
        const inviteConnectionIndicator: InviteConnectionIndicator = {
          invite: this.resendInvite,
          connectionIndicatorId: this.connectionIndicatorId,
        };

        this.inviteService.resendInviteConnectionIndicator(inviteConnectionIndicator).subscribe((result) => {
          this.snackBar.info('Invite successfully resent.', 'invite-snack');
          this.afterNewInvite(this.resendInvite);
        });
      }

      if (this.roleMandateId) {
        const inviteRoleMandate: InviteRoleMandate = {
          invite: this.resendInvite,
          roleMandateId: this.roleMandateId,
        };
        this.inviteService.resendInviteRoleMandate(inviteRoleMandate).subscribe((result) => {
          this.snackBar.info('Invite successfully resent.', 'invite-snack');
          this.afterNewInvite(this.resendInvite);
        });
      }

      if (this.testGroup) {
        this.resendInvite.psyTestGroupId = this.testGroup.id;
        const inviteTestGroup: InviteTestGroup = {
          invite: this.resendInvite,
          testGroupId: this.testGroup.id,
        };
        this.inviteService.resendInviteTestGroup(inviteTestGroup).subscribe((invite: Invite) => {
          this.snackBar.info('Invite successfully resent.', 'invite-snack');
          this.afterNewInvite(this.resendInvite);
        });
      }
    }
  }

  private clearMassInviteFormValidators() {
    this.massInviteForm.get('emails').reset();
    this.emailControls.clear();
    this.massInviteForm.get('emails').clearValidators();
    this.massInviteForm.get('emails').updateValueAndValidity();
    this.validEmailAddress = false;
    this.emailNoExisted = false;
  }

  private clearInviteFormValidators() {
    this.inviteForm.get('firstName').reset();
    this.inviteForm.get('lastName').reset();
    this.inviteForm.get('email').reset();
    this.inviteForm.get('email').clearValidators();
    this.inviteForm.get('email').updateValueAndValidity();
    this.inviteForm.get('firstName').clearValidators();
    this.inviteForm.get('firstName').updateValueAndValidity();
    this.inviteForm.get('lastName').clearValidators();
    this.inviteForm.get('lastName').updateValueAndValidity();
    this.emailNoExisted = false;
  }

  closeCard(invalidEmail: { email: string; reason: string }): void {
    this.invalidEmails = this.invalidEmails.filter((email) => email !== invalidEmail);
  }
}
