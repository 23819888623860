import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';

// services
import { TestGroupService } from '@app/core/services/test-group.service';
import { UtilsService } from '@app/core/services/utils.service';
import { UserService } from '@app/core/services/user.service';
import { CompanyService } from '@app/core/services/company.service';

// models
import { PsyTest } from '@app/core/models/psy-test.model';
import { PsyTestGroupTestResult } from '@app/core/models/psy-test-group-test-result.model';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';

// constants
import { ROLE_ADMIN, ROLE_ORGANIZATIONAL_ADMIN, ROLE_TEST_USER, ROLE_TGM } from '@app/core/constants';

@Component({
  selector: 'app-test-result-list-view',
  templateUrl: './test-result-list-view.component.html',
  styleUrls: ['./test-result-list-view.component.scss'],
})
export class TestResultListViewComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginatorTestResults: MatPaginator;
  testResults: MatTableDataSource<PsyTestGroupTestResult> = new MatTableDataSource<PsyTestGroupTestResult>();
  displayedTestResultsColumns: string[] = ['name', 'testName', 'status', 'spentTime', 'scoreCards', 'actions'];
  testGroupId: number;
  allTestResult = [];
  psyTestGroup: PsyTestGroup;
  startDate: Date;
  endDate: Date;
  testFieldUpdate = new Subject<string>();
  testField: string;
  tests: any[];
  psyTests: any[];
  superTests: any[];
  testResultForm = new FormGroup({
    userName: new FormControl(''),
    userEmail: new FormControl(''),
    start: new FormControl(''),
    end: new FormControl(''),
    test: new FormControl(''),
  });
  isAdmin: boolean = false;
  isTGM: boolean = false;
  isOA: boolean = false;
  allowRawScoresAccess = false;
  companyId: number;
  userNameSearchFieldUpdate = new Subject<string>();
  userEmailSearchFieldUpdate = new Subject<string>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testGroupService: TestGroupService,
    private changeDetectorRef: ChangeDetectorRef,
    private utilsService: UtilsService,
    private userService: UserService,
    private companyService: CompanyService,
  ) {
    this.userNameSearchFieldUpdate.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'object') {
        this.onSearch();
      }
    });

    this.userEmailSearchFieldUpdate.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'object') {
        this.onSearch();
      }
    });

    this.testFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.testField = value;
      }
    });

    this.companyId = this.userService.getUserData().companyId;
    const authorities = this.userService.getUserData().authorities?.map((value) => value.name);
    if (authorities.includes(ROLE_ADMIN)) {
      this.isAdmin = true;
    } else if (authorities.includes(ROLE_ORGANIZATIONAL_ADMIN)) {
      this.isOA = true;
    } else if (authorities.includes(ROLE_TGM)) {
      this.isTGM = true;
      this.displayedTestResultsColumns = ['name', 'testName', 'status', 'spentTime', 'scoreCards'];
    }
  }

  ngOnInit(): void {
    this.testGroupId = Number(this.route.snapshot.params.id);
    this.initTable();
  }

  initTable() {
    this.getTestGroup();
    this.getTestResult();
    this.getAllTest();
    this.initDate();
    this.getCompanyAllowRawScoresAccess();
  }

  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type === 'start') {
      this.startDate = event.value;
    } else if (type === 'end') {
      this.endDate = event.value;
    }
  }

  displayTest(test: PsyTest) {
    return test ? test.title : '';
  }

  initDate() {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.startDate = new Date(year - 10, month, 1);
    this.endDate = new Date(year, month, new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate());

    this.testResultForm.controls['start'].setValue(this.startDate);
    this.testResultForm.controls['end'].setValue(this.endDate);
  }

  getAllTest() {
    forkJoin([
      this.testGroupService.getPsyTestsById(this.testGroupId),
      this.testGroupService.getSupertestsById(this.testGroupId),
    ]).subscribe((results) => {
      this.psyTests = results[0];
      this.superTests = results[1];
      this.tests = [...results[0], ...results[1]];
    });
  }

  getTestGroup() {
    this.testGroupService.get(this.testGroupId).subscribe((psyTestGroup: PsyTestGroup) => {
      this.psyTestGroup = psyTestGroup;
    });
  }

  getTestResult() {
    this.testGroupService.getTestGroupTestResults(this.testGroupId).subscribe((response) => {
      this.renderTableData(response);
    });
  }

  getCompanyAllowRawScoresAccess() {
    this.companyService.getById(this.companyId).subscribe((company) => {
      this.allowRawScoresAccess = +company.allowRawScoresAccess === 1 ? true : false;
      if (!this.allowRawScoresAccess && this.isOA) {
        this.displayedTestResultsColumns = ['name', 'testName', 'status', 'spentTime', 'scoreCards'];
      }
    });
  }

  renderTableData(response: PsyTestGroupTestResult[]) {
    this.allTestResult = [];
    response.forEach((data) => {
      let spentTime;
      let startDate = data.dateStarted;
      let endDate = data.dateCompleted;

      const start = new Date(startDate).getTime();
      const end = new Date(endDate).getTime();
      const milliseconds = Math.abs(end - start);

      spentTime = this.milisecondToHoursAndMinute(milliseconds);

      const testResult: PsyTestGroupTestResult = {
        id: data.id,
        name: data.name,
        userInfoId: data.userInfoId,
        testId: data.testId,
        dateCompleted: data.dateCompleted,
        spentTime: !data.dateStarted ? 'Not enough data' : spentTime,
        title: data.title,
        scorecards: data.scorecards,
        hasIntegerQuestions: data.hasIntegerQuestions,
        hasCardSortQuestions: data.hasCardSortQuestions,
      };

      this.allTestResult.push(testResult);
    });

    this.refreshTableData();
  }

  milisecondToHoursAndMinute(time: any) {
    let spendTime;
    const addPrefix = (time) => (time < 10 ? '0' + time : time);
    const toHours = (time) => addPrefix(Math.floor((time / (1000 * 60 * 60)) % 24));
    const toMinutes = (time) => addPrefix(Math.floor((time / (1000 * 60)) % 60));
    const toSeconds = (time) => addPrefix(Math.floor((time / 1000) % 60));
    const toMiliseconds = (time) => Math.floor((time % 1000) / 100);

    const hours = toHours(time);
    const minutes = toMinutes(time);
    const seconds = toSeconds(time);
    const miliseconds = toMiliseconds(time);

    if (hours !== '00') {
      spendTime = `${hours} hours ${minutes} minutes ${seconds} seconds`;
    } else {
      spendTime = `${minutes} minutes ${seconds} seconds`;
    }

    return spendTime;
  }

  refreshTableData() {
    this.testResults.data = this.allTestResult;
    this.testResults.paginator = this.paginatorTestResults;
    this.changeDetectorRef.detectChanges();
  }

  onSearch(test?: any) {
    const testGroupId = this.testGroupId;
    const userName = this.testResultForm.get('userName').value ? this.testResultForm.get('userName').value : '';
    const userEmail = this.testResultForm.get('userEmail').value ? this.testResultForm.get('userEmail').value : '';
    const dateFrom = this.startDate;
    const dateTo = this.endDate;
    const testId =
      this.testResultForm.get('test').value && this.testResultForm.get('test').value.testId
        ? this.testResultForm.get('test').value.testId
        : test
        ? test.testId
        : null;
    let isSuperTest = false;
    if (this.testResultForm.get('test').value) {
      const filteredSuperTest = this.superTests.filter(
        (superTest) => superTest.title === this.testResultForm.get('test').value.title,
      );
      isSuperTest = filteredSuperTest && filteredSuperTest.length > 0 ? true : false;
    }

    this.testGroupService
      .searchTestResult(testGroupId, userName, userEmail, dateFrom, dateTo, testId, isSuperTest)
      .subscribe((response) => {
        this.renderTableData(response);
      });
  }

  resetSearch() {
    this.testResultForm.reset();
    this.initTable();
  }

  onExportAnswerNumbers(element: any) {
    const testGroupId = this.testGroupId;
    const testId = element.testId;
    const userInfoId = element.userInfoId;
    const testResultId = element.id;
    const reportType = 'testAnswersNumbers';
    const dateFrom = this.startDate;
    const dateTo = this.endDate;

    this.testGroupService
      .getTestResultDataDownload(testGroupId, testId, userInfoId, testResultId, reportType, dateFrom, dateTo)
      .subscribe((result: { fileName: string; fileMimeType: string; fileBase64Content: string }) => {
        console.log('result', result);
        console.log('to result', typeof result);
        this.downloadFile(result);
      });
  }

  onExportAnswerText(element: any) {
    const testGroupId = this.testGroupId;
    const testId = element.testId;
    const userInfoId = element.userInfoId;
    const testResultId = element.id;
    const reportType = 'testAnswersText';
    const dateFrom = this.startDate;
    const dateTo = this.endDate;

    this.testGroupService
      .getTestResultDataDownload(testGroupId, testId, userInfoId, testResultId, reportType, dateFrom, dateTo)
      .subscribe((result: { fileName: string; fileMimeType: string; fileBase64Content: string }) => {
        console.log('result', result);
        console.log('to result', typeof result);
        this.downloadFile(result);
      });
  }

  onTestGroupScorecard(element: any) {
    return this.router.navigate(
      [`/psy-test-group/${this.testGroupId}/user/${element.userInfoId}/test/${element.testId}/test-group-scorecard`],
      { state: { scoreCards: element.scorecards } },
    );
  }

  downloadFile(data: { fileName: string; fileMimeType: string; fileBase64Content: string }) {
    const blob = this.utilsService.b64toBlob(data.fileBase64Content, data.fileMimeType);
    const fileName: string = data.fileName;
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }
}
