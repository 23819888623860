import { Component, OnInit, OnChanges, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-related-assessment-list-and-subscale-list',
  templateUrl: './related-assessment-list-and-subscale-list.component.html',
  styleUrls: ['./related-assessment-list-and-subscale-list.component.scss'],
})
export class RelatedAssessmentListAndSubscaleListComponent implements OnInit, OnChanges {
  @Input() relatedAssessmentsAndSubscales?: any;
  config: any;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.setConfig();
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.setConfig();
    this.changeDetectorRef.detectChanges();
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  setConfig(): void {
    this.config = {
      itemsPerPage: 5,
      currentPage: typeof this.config !== 'undefined' ? this.config.currentPage : 1,
      totalItems:
        this.relatedAssessmentsAndSubscales && this.relatedAssessmentsAndSubscales.allRelatedSubscale
          ? this.relatedAssessmentsAndSubscales.allRelatedSubscale.length
          : 0,
    };
  }
}
