<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Heading</mat-label>
    <input matInput required type="text" formControlName="heading" />
    <mat-error *ngIf="form.get('heading').hasError">Heading is required</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Positive Heading</mat-label>
    <input matInput type="text" formControlName="positiveHeading" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Concern Heading</mat-label>
    <input matInput type="text" formControlName="concernHeading" />
  </mat-form-field>
  <div class="form-lead-sentence">
    <mat-form-field>
      <mat-label>Lead sentence</mat-label>
      <mat-select formControlName="leadSentenceOption">
        <mat-option value="he">He</mat-option>
        <mat-option value="she">She</mat-option>
        <mat-option value="they">They</mat-option>
      </mat-select>
    </mat-form-field>
    <angular-editor
      [config]="editorConfig"
      [formControl]="form.get('leadSentenceBody').get(form.get('leadSentenceOption').value)"
    ></angular-editor>
  </div>
  <div style="display: flex; justify-content: space-between; align-items: center">
    <h2>Default Sentences Toggle</h2>
    <mat-button-toggle-group class="default-sentence-toggle" [formControl]="form.get('defaultSentenceEnabled')">
      <mat-button-toggle [value]="true">Enable</mat-button-toggle>
      <mat-button-toggle [value]="false">Disable</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="bullets">
    <div class="bullets-header">
      <h2>Bullets</h2>
    </div>
    <div>
      <h3>Default Sentence</h3>
      <angular-editor
        [config]="editorConfig"
        [formControl]="form.get('defaultSentence')"
      ></angular-editor>
    </div>
    <div class="bullet-item" formArrayName="bullets" *ngFor="let control of bulletsFormArray.controls; let i = index">
      <h3>Bullet #{{ i + 1 }}</h3>
      <div class="bullet-formgroups">
        <div class="bullet-scales">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <h4>Scales</h4>
            <mat-button-toggle-group [formControl]="control.get('useSubscale')">
              <mat-button-toggle [value]="false">Superscale</mat-button-toggle>
              <mat-button-toggle [value]="true">Subscale</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div *ngIf="getUseSubscale(i)" class="subscale-fields" [formGroup]="control.get('subscale')">
            <mat-form-field>
              <mat-label>Subscale</mat-label>
              <input
                matInput
                required
                type="text"
                (ngModelChange)="this.subscaleFieldUpdate.next($event)"
                formControlName="id"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySubscale">
                <mat-option *ngFor="let subscale of subscales | filter: subscaleField" [value]="subscale">
                  <div [matTooltip]="subscale.title">
                    <p class="report-scale-title-text" [matTooltip]="subscale.title">{{ subscale.title }}</p>
                    <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Weight</mat-label>
              <input matInput formControlName="weight" type="number" />
            </mat-form-field>
            <button
              mat-flat-button
              color="primary"
              (click)="onAddSubscale(i)"
              [disabled]="control.get('subscale').invalid || (addedBullets[i].subscales && addedBullets[i].subscales.length > 0)"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ul class="subscales-list" *ngIf="getUseSubscale(i) && addedBullets[i]">
            <li *ngFor="let subscale of addedBullets[i].subscales">
              <span>
                <small>Subscale</small>
                {{ subscale.title }}
              </span>
              <span>
                <small>Weight</small>
                {{ subscale.weight }}
              </span>
              <button class="action-button" mat-flat-button color="accent" (click)="onRemoveSubscale(i, subscale.id)">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </li>
          </ul>

          <div *ngIf="!getUseSubscale(i)" class="superscale-fields" [formGroup]="control.get('superscale')">
            <mat-form-field>
              <mat-label>Superscale</mat-label>
              <input
                matInput
                required
                type="text"
                (ngModelChange)="this.superscaleFieldUpdate.next($event)"
                formControlName="id"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySuperscale">
                <mat-option *ngFor="let superscale of superscales | filter: superscaleField" [value]="superscale">
                  <div [matTooltip]="superscale.title">
                    <p class="report-scale-title-text" [matTooltip]="superscale.title">{{ superscale.title }}</p>
                    <p *ngIf="superscale.description" class="small-text">{{ superscale.description }}</p>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <button
              mat-flat-button
              color="primary"
              (click)="onAddSuperscale(i)"
              [disabled]="control.get('superscale').invalid"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ul class="superscales-list" *ngIf="!getUseSubscale(i) && addedBullets[i]">
            <li *ngFor="let superscale of addedBullets[i].superscales">
              <span>{{ superscale.title }}</span>
              <button
                class="action-button"
                mat-flat-button
                color="accent"
                (click)="onRemoveSuperscale(i, superscale.id)"
              >
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </li>
          </ul>
        </div>

        <div [formGroup]="control.get('range')">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <h4>Ranges</h4>
            <div>
              <button mat-flat-button color="primary" class="add-range" (click)="onAddRange(i)" [disabled]="control.get('range').invalid">
                <mat-icon>add</mat-icon>
                Add range
              </button>
              <button mat-flat-button color="accent" (click)="onDeleteBullet(i)">
                <mat-icon>delete</mat-icon>
                Delete bullet
              </button>
            </div>
          </div>
          <div class="range-header">
            <mat-form-field>
              <mat-label>From</mat-label>
              <input
                matInput
                required
                formControlName="from"
                type="number"
                min="0"
                [max]="+control.get('range').get('to').value - 1"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>To</mat-label>
              <input matInput required formControlName="to" type="number" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Sentence</mat-label>
              <mat-select [formControl]="control.get('range').get('currentOption')">
                <mat-option value="he">He</mat-option>
                <mat-option value="she">She</mat-option>
                <mat-option value="they">They</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div formGroupName="reportText">
            <angular-editor
              [config]="editorConfig"
              [formControl]="
                control.get('range').get('reportText').get(control.get('range').get('currentOption').value)
              "
            >
            </angular-editor>
          </div>
          <ul class="ranges-list">
            <li *ngFor="let range of addedBullets[i].ranges; let j = index">
              <div class="info">
                <span>
                  <small>From</small>
                  {{ range.from }}
                </span>
                <span>
                  <small>To</small>
                  {{ range.to }}
                </span>
                <span>
                  <small>He</small>
                  <div [innerHTML]="range.reportText.he"></div>
                </span>
                <span>
                  <small>She</small>
                  <div [innerHTML]="range.reportText.she"></div>
                </span>
                <span>
                  <small>They</small>
                  <div [innerHTML]="range.reportText.they"></div>
                </span>
              </div>
              <div>
                <button class="edit-button" mat-flat-button color="primary" (click)="onEditRange(i, j)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-flat-button color="accent" (click)="onRemoveRange(i, j)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="clearfix">
      <button class="new-bullet-button" mat-flat-button color="primary" (click)="onAddNewSentence()" type="button">
        Add new bullet
      </button>
    </div>
  </div>
</form>
