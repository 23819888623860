<h1 mat-dialog-title>Unanswered Questions Confirmation</h1>
<div mat-dialog-content *ngIf="message; else defaultMessage">{{ message }}</div>
<ng-template #defaultMessage
  >Some questions were not answered. If no change is indicated, select 'confirm' to move to the next page or to
  submit.</ng-template
>
<div mat-dialog-actions>
  <button mat-button *ngIf="isShowCancel" (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onConfirm()">Confirm</button>
</div>
