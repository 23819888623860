<table *ngIf="form" class="table-columns mat-elevation-z3">
  <thead>
    <tr>
      <th class="mat-elevation-z3">Position</th>
      <th *ngFor="let answer of question.answers; index as i">{{ i + 1 }}</th>
      <th>Discarded</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr [formGroup]="form">
      <td class="mat-elevation-z3">Score</td>
      <td *ngFor="let answerPositionScore of answersPositionScore">
        <mat-form-field style="width: 70px" [appearance]="!isEdit ? 'none' : 'legacy'">
          <input matInput type="text" [formControlName]="answerPositionScore.position" [readonly]="!isEdit" />
          <mat-error *ngIf="getControlByPosition(answerPositionScore.position).hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
      </td>
      <td>
        <button *ngIf="!isEdit" mat-flat-button type="button" class="center-no-margin" (click)="onEdit()">Edit</button>
        <button *ngIf="!isEdit" mat-flat-button type="button" class="center-no-margin" (click)="onReset()">
          Reset
        </button>
        <button *ngIf="isEdit" mat-flat-button type="button" class="center-no-margin" (click)="onCancel()">
          Cancel
        </button>
        <button
          *ngIf="isEdit"
          mat-flat-button
          type="button"
          class="center-no-margin"
          [disabled]="!form.valid"
          (click)="onSubmit()"
        >
          Save
        </button>
      </td>
    </tr>
  </tbody>
</table>
