import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { CompanyService } from '@app/core/services/company.service';
import { InviteService } from '@app/core/services/invite.service';
import { DepartmentService } from '@app/core/services/department.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { HandleCsvService } from '@app/core/services/handle-csv.service';
import { PageService } from '@app/core/services/page.service';

// models
import { Company } from '@app/core/models/company.model';
import { Department } from '@app/core/models/department.model';
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';
import { InvitesConnectionIndicator } from '@app/core/models/invites-connection-indicator.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-connections-indicator-info',
  templateUrl: './connections-indicator-info.component.html',
  styleUrls: ['./connections-indicator-info.component.scss'],
})
export class ConnectionsIndicatorInfoComponent implements OnInit, AfterViewChecked {
  ciForm: FormGroup;
  companies: Company[] = [];
  company: Company;
  connectionIndicator: ConnectionIndicator;
  filteredCompanies: Observable<Company[]>;
  departments: Department[] = [];
  departmentFormSubmitted = false;
  usersCSVImported = false;
  departmentsCSVImported = false;
  invitesConnectionIndicator: InvitesConnectionIndicator;
  departmentForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });
  displayedDepartmentsColumns: string[] = ['name', 'actionDelete'];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '130px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Instructions',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private departmentService: DepartmentService,
    private cIService: ConnectionsIndicatorService,
    private inviteService: InviteService,
    public dialog: MatDialog,
    public snackBar: SnackBarService,
    private changeDetectorRef: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
    private handleCsvService: HandleCsvService,
    private route: ActivatedRoute,
    public pageService: PageService,
  ) {}

  ngOnInit(): void {
    if (Number(this.route.snapshot.params.id)) {
      const connectionIndicatorId = Number(this.route.snapshot.params.id);

      this.pageService.setConnectionIndicatorPages([
        {
          title: 'Connections Indicator',
          path: '/connections-indicator/' + connectionIndicatorId + '/edit',
        },
        {
          title: 'Users',
          path: '/connections-indicator/' + connectionIndicatorId + '/users',
        },
        {
          title: 'Questions',
          path: '/connections-indicator/' + connectionIndicatorId + '/questions',
        },
        {
          title: 'Decision Tables',
          path: '/connections-indicator/' + connectionIndicatorId + '/decision-tables',
        },
        {
          title: 'Sort Questions',
          path: '/connections-indicator/' + connectionIndicatorId + '/sort',
        },
        {
          title: 'Review',
          path: '/connections-indicator/' + connectionIndicatorId + '/review',
        },
      ]);

      this.cIService.getById(connectionIndicatorId).subscribe((connectionIndicator) => {
        this.connectionIndicator = connectionIndicator;
        this.ciForm.controls['title'].setValue(this.connectionIndicator.title);
        this.ciForm.controls['instructions'].setValue(this.connectionIndicator.instructions);
        this.companyService.getById(this.connectionIndicator.companyId).subscribe((company) => {
          this.company = company;
          this.ciForm.controls['company'].setValue(company);
          this.setCompany(company);
        });
      });
    }

    this.ciForm = new FormGroup({
      title: new FormControl('', Validators.required),
      instructions: new FormControl('', Validators.required),
      company: new FormControl('', Validators.required),
    });

    this.companyService.getAll().subscribe((companies) => {
      this.companies = companies;
      this.filteredCompanies = this.ciForm.controls['company'].valueChanges.pipe(
        startWith(''),
        map((company) => (company ? this._filter(company) : this.companies.slice())),
      );
    });
  }

  private _filter(value: string): Company[] {
    const filterValue = value;
    if (typeof filterValue === 'object') {
      return this.companies;
    }
    return this.companies.filter((company) => company.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
  }

  displayFn(company: Company): string {
    return company ? company.name : '';
  }

  setCompany(company: Company) {
    this.company = company;
    this.loadDepartments(company);
  }

  loadDepartments(company: Company) {
    this.companyService.getDepartmentsById(company.id).subscribe((departments) => {
      this.departments = departments;
    });
  }

  newDepartment() {
    this.departmentFormSubmitted = true;
    if (typeof this.ciForm.get('company').value.id === 'undefined') {
      this.ciForm.get('company').setErrors({ incorrect: true });
      return;
    }

    const department: Department = {
      id: null,
      name: this.departmentForm.get('name').value,
      city: null,
      zipCode: null,
      address: null,
      stateId: null,
      companyId: this.company.id,
    };

    if (!department.name) {
      return;
    }

    if (this.departments.find((d) => d.name === department.name)) {
      return;
    }

    this.companyService.saveDepartmentById(this.company.id, department).subscribe({
      next: (result) => {
        department.id = result['insertId'];
        this.departments = this.departments.concat(department);

        this.departmentForm.reset();
        Object.keys(this.departmentForm.controls).forEach((key) => {
          this.departmentForm.get(key).setErrors(null);
        });
      },
    });
  }

  saveCI(connectionIndicator: ConnectionIndicator) {
    return this.cIService.save(connectionIndicator);
  }

  saveDepartments() {
    return this.companyService.saveDepartmentsById(this.company.id, this.departments).subscribe();
  }

  onNext() {
    if (!this.ciForm.valid) {
      // Run validation and scroll to top
      this.ciForm.markAllAsTouched();
      this.viewportScroller.scrollToPosition([0, 0]);
      return;
    }
    const connectionIndicator: ConnectionIndicator = {
      id: null,
      companyId: this.company.id,
      title: this.ciForm.controls['title'].value,
      active: false,
      instructions: this.ciForm.controls['instructions'].value,
    };

    if (!this.route.snapshot.params.id) {
      this.saveCI(connectionIndicator).subscribe((response: Response) => {
        connectionIndicator.id = Number(response);
        return this.router.navigate(['/connections-indicator/' + connectionIndicator.id + '/users']);
      });
    } else {
      connectionIndicator.id = Number(this.route.snapshot.params.id);

      this.cIService
        .update(connectionIndicator)
        .subscribe(() => this.router.navigate(['/connections-indicator/' + this.route.snapshot.params.id + '/users']));
    }
  }

  onDeleteDepartment(department: Department) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.departmentService.delete(department.id).subscribe((res) => {
          if (res) {
            this.departments = this.departments.filter((item) => item.id !== department.id);
          }
        });
      }
    });
  }

  async handleDepartmentFile($event: Event) {
    const csvData: any = await this.handleCsvService.readCsvByEvent($event);
    this.departmentsCSVImported = true;
    if (typeof this.ciForm.get('company').value.id === 'undefined') {
      this.ciForm.get('company').setErrors({ incorrect: true });
      return;
    }

    for (const line of csvData) {
      if (String(line).length > 0) {
        const department: Department = {
          id: null,
          name: line,
          city: null,
          zipCode: null,
          address: null,
          stateId: null,
          companyId: this.company.id,
        };

        if (!department.name) {
          return;
        }

        if (this.departments.find((d) => d.name === department.name.toString())) {
          return;
        }

        this.companyService.saveDepartmentById(this.company.id, department).subscribe({
          next: (result) => {
            department.id = result['insertId'];
            this.departments = this.departments.concat(department);
          },
        });
      }
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }
}
