<h3>Edit a Test Group User</h3>
<mat-divider></mat-divider>
<form [formGroup]="editTestGroupUserModalForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Date Assigned</mat-label>
    <input matInput type="text" formControlName="dateAssigned" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>User Type</mat-label>
    <mat-select formControlName="userType">
      <mat-option value="TEST">Candidate</mat-option>
      <mat-option value="MANAGER">Manager</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>User</mat-label>
    <input matInput type="text" formControlName="name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Test Group</mat-label>
    <input matInput type="text" formControlName="testGroup" />
  </mat-form-field>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!editTestGroupUserModalForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
