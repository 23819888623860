import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { fabric } from 'fabric';

// services
import { QuestionService } from '@app/core/services/question.service';
import { AnswerService } from '@app/core/services/answer.service';

// models
import { Question } from '@app/core/models/question.model';
import { UserAnswer } from '@app/core/models/user-answer.model';
import { IntegerAnswer } from '@app/core/models/integer-answer.model';

@Component({
  selector: 'app-answer-pentagon',
  templateUrl: './answer-pentagon.component.html',
  styleUrls: ['./answer-pentagon.component.scss'],
})
export class AnswerPentagonComponent implements OnInit, AfterViewInit {
  @Input() question: Question;
  @Input() answering: boolean;
  @Input() loadAnswer: boolean;
  @Input() psyTestId: number;
  @Input() superTestId: number;
  @Input() userInfoId: number;
  @Input() testResultId: number;
  @Input() isPreview: boolean;
  @Output() userAnswer = new EventEmitter<{ questionId: number; userAnswer: UserAnswer }>();

  lines = [];
  circles = [];
  choices = [];
  colors = ['#3aab01', '#85012d', '#b34001', '#074061', '#000000'];
  model: number;
  selectedAnswer: IntegerAnswer;
  linesCoords = [
    [130, 50, 54, 105],
    [54, 105, 83, 195],
    [83, 195, 177, 195],
    [177, 195, 206, 105],
    [206, 105, 130, 50],
  ];

  factors = [
    {
      leftFactor: 3,
      topFactor: 15,
    },
    {
      leftFactor: 0.4,
      topFactor: 15,
    },
    {
      leftFactor: 0.5,
      topFactor: 15,
    },
    {
      leftFactor: -0.6,
      topFactor: -10,
    },
    {
      leftFactor: -3,
      topFactor: -10,
    },
  ];
  canvas: fabric.Canvas;
  canvasWidth: number = 300;
  constructor(private questionService: QuestionService, private answerService: AnswerService) {}

  ngAfterViewInit(): void {
    this.canvas = new fabric.Canvas(`canvas-${this.question.id}`);
    this.canvas.selectionColor = 'transparent';
    if (this.answering) {
      this.initEvents(this.canvas);
    } else {
      this.questionService
        .findIntegerAnswerByQuestionIdAndTestResultId(this.question.id, this.testResultId)
        .subscribe((answer) => {
          if (answer && typeof answer !== 'undefined') {
            this.selectedAnswer = {
              answerId: answer.id,
              answer: answer.answer,
              questionId: this.question.id,
              testResultId: this.testResultId,
            };
            this.model = parseInt(answer.answer.toString(), 10);
            this.selectedAnswer.answerId = answer.answerId;
            this.initModel(this.canvas);
          }
        });
    }

    this.initCanvas(this.canvas);
    if (this.model && this.question.userAnswer && this.question.userAnswer.integerAnswer) {
      this.loadAnswerFromUserAnswer(this.question.userAnswer);
    }
  }

  ngOnInit(): void {
    this.question.answers.sort((a, b) => {
      return a['aOrder'] - b['aOrder'];
    });

    if (!this.isPreview) {
      if (this.answering) {
        if (!this.selectedAnswer && !this.model) {
          if (this.question.userAnswer && this.question.userAnswer.integerAnswer) {
            this.loadAnswerFromUserAnswer(this.question.userAnswer);
          }
        }
      }
    }
    this.canvasWidth = window.innerWidth > 768 ? 300 : 260;
  }

  loadAnswerFromUserAnswer(userAnswer: UserAnswer) {
    this.selectedAnswer = {
      answerId: userAnswer.integerAnswer.id,
      answer: userAnswer.integerAnswer.answer,
      questionId: this.question.id,
      testResultId: this.testResultId,
    };

    this.model = userAnswer.integerAnswer.answer;
    this.selectedAnswer.answerId = userAnswer.integerAnswer.id;
    this.fillCirclesByAnswerOrIndex(this.model, undefined);
  }

  initCanvas(canvas) {
    this.linesCoords.forEach((coords) => {
      const line = this.makeLine(coords);
      this.lines.push(line);
      canvas.add(line);
    });

    this.lines.forEach((line, index) => {
      const circle = this.makeCircle(line.get('x1') - 12, line.get('y1') - 12, this.colors[index]);
      circle.index = index;
      circle.id = this.question.answers[index]?.id;
      this.circles.push(circle);
      canvas.add(circle);
    });

    this.factors.forEach((factor, idx) => {
      const result = this.makeText(idx, factor.leftFactor, factor.topFactor, this.colors[idx]);
      this.choices.push(result);
      canvas.add(result);
    });
  }

  initEvents(canvas) {
    canvas.on('mouse:down', (e) => {
      const type = e.target ? e.target.get('type') : null;
      if (e.target && (type === 'circle' || type === 'text')) {
        this.fillCirclesByAnswerOrIndex(undefined, e.target.index);
        this.model = this.choices[e.target.index].id;
        this.AnswerChange(this.model);
      }
    });

    canvas.on('mouse:over', (e) => {
      if (!e.target) {
        return;
      }

      e.target.hoverCursor = 'default';
      const type = e.target.get('type');
      if (e.target && (type === 'circle' || type === 'text')) {
        e.target.hoverCursor = 'pointer';
      }
    });
  }

  fillCirclesByAnswerOrIndex(answer: number, index: number): void {
    const answerOrIndex = typeof answer !== 'undefined' ? answer : this.choices[index].id;
    this.circles.filter((circle) =>
      circle.id === answerOrIndex ? circle.set('fill', 'red') : circle.set('fill', 'white'),
    );
  }

  initModel(canvas) {
    if (!this.model) {
      return;
    }

    this.circles.forEach((circle) => {
      const color = circle.id === this.model ? 'red' : 'white';
      circle.set('fill', color);
      canvas.renderAll();
    });
  }

  makeLine(coords) {
    return new fabric.Line(coords, {
      fill: '#cecece',
      stroke: '#cecece',
      strokeWidth: 0.8,
      selectable: false,
      hasControls: false,
      hasBorders: false,
    });
  }

  makeCircle(left, top, color) {
    return new fabric.Circle({
      left,
      top,
      strokeWidth: 2,
      radius: 12,
      fill: color,
      stroke: color,
      selectable: false,
      hasControls: false,
      hasBorders: false,
      cursor: 'pointer',
    });
  }

  makeText(idx, leftFactor, topFactor, color) {
    const vct = this.question.answers[idx]?.text.split(' ');
    const halfLength = Math.ceil(vct?.length / 2);
    const label = vct ? vct.splice(0, halfLength).join(' ') + '\n' + vct.join(' ') : '';

    const left = this.lines[idx]?.get('x1') - label.length * leftFactor;
    const top = this.lines[idx]?.get('y1') + topFactor;
    const answerId = this.question.answers[idx]?.id;

    const text = new fabric.Text(label, {
      left,
      top,
      hasControls: false,
      hasBorders: false,
      selectable: false,
      stroke: color,
      fontSize: 14,
      fontFamily: 'Arial, Helvetica, sans-serif',
      cursor: 'pointer',
      index: idx,
      id: answerId,
    });
    return text;
  }

  AnswerChange(model: number) {
    if (this.superTestId || this.psyTestId) {
      if (this.selectedAnswer && this.selectedAnswer.answerId) {
        this.selectedAnswer.answer = model;
        this.answerService.updateIntegerAnswer(this.question.id, this.selectedAnswer).subscribe();
      } else {
        this.selectedAnswer = {
          questionId: this.question.id,
          userInfoId: this.userInfoId,
          answer: model,
          testResultId: this.testResultId,
        };

        this.answerService.saveIntegerAnswer(this.question.id, this.selectedAnswer).subscribe((result) => {
          const userAnswer = {
            questionId: this.question.id,
            userAnswer: {
              integerAnswer: { id: result.id, answer: model },
              textAnswer: null,
              listAnswer: null,
            },
          };
          this.userAnswer.emit(userAnswer);
          this.selectedAnswer.answerId = result.id;
        });
      }
    }
  }
}
