<base target="_blank" />
<div class="container">
  <h1>Psynet Group Connections Indicator</h1>

  <div *ngIf="allObj">
    <div *ngFor="let obj of allObj | paginate: config">
      <div *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'CARDSORT'">
        <app-answer-cardsort
          [question]="obj"
          [connectionIndicatorId]="connectionsIndicatorId"
          [answering]="true"
          [userInfoId]="userInfoId"
        >
        </app-answer-cardsort>
      </div>
      <div *ngIf="testObjectService.isQuestion(obj) && obj.questionType === 'USER_CONNECTION'">
        <app-answer-user-connection
          [question]="obj"
          [companyId]="this.connectionsIndicator.companyId"
          [connectionIndicatorId]="connectionsIndicatorId"
          [connectionsIndicator]="connectionsIndicator"
          [userInfoId]="userInfoId"
          [answering]="true"
        >
        </app-answer-user-connection>
      </div>
      <div
        *ngIf="
          testObjectService.isQuestion(obj) && (obj.questionType === 'TEXT_AREA' || obj.questionType === 'RICH_TEXT')
        "
      >
        <app-answer-text-area
          [question]="obj"
          [companyId]="this.connectionsIndicator.companyId"
          [connectionIndicatorId]="connectionsIndicatorId"
          [userInfoId]="userInfoId"
          [answering]="true"
        >
        </app-answer-text-area>
      </div>

      <div *ngIf="testObjectService.isDecisionTable(obj)">
        <app-answer-decision-table
          [companyId]="this.connectionsIndicator.companyId"
          [connectionIndicatorId]="connectionsIndicatorId"
          [decisionTable]="obj"
          [userInfoId]="userInfoId"
          [answering]="true"
        >
        </app-answer-decision-table>
      </div>

      <div *ngIf="testObjectService.isQuestionsGroup(obj)" class="question-group">
        <h2>{{ obj.title }}</h2>
        <p *ngIf="obj.instructions" [innerHTML]="obj.instructions"></p>
        <div *ngFor="let question of obj.questions" class="question">
          <div *ngIf="question.questionType === 'CARDSORT'">
            <app-answer-cardsort
              [question]="question"
              [connectionIndicatorId]="connectionsIndicatorId"
              [answering]="true"
              [userInfoId]="userInfoId"
            >
            </app-answer-cardsort>
          </div>
          <div *ngIf="question.questionType === 'USER_CONNECTION'">
            <app-answer-user-connection
              [question]="question"
              [companyId]="this.connectionsIndicator.companyId"
              [connectionIndicatorId]="connectionsIndicatorId"
              [connectionsIndicator]="connectionsIndicator"
              [userInfoId]="userInfoId"
              [answering]="true"
            >
            </app-answer-user-connection>
          </div>
          <div *ngIf="question.questionType === 'TEXT_AREA' || obj.questionType === 'RICH_TEXT'">
            <app-answer-text-area
              [question]="question"
              [companyId]="this.connectionsIndicator.companyId"
              [connectionIndicatorId]="connectionsIndicatorId"
              [userInfoId]="userInfoId"
              [answering]="true"
            >
            </app-answer-text-area>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="this.config.currentPage === 1 || !this.config.currentPage">
      <button mat-raised-button color="primary" class="btn-success btn-center btn-300" (click)="onBack()">
        Back to Role Title
      </button>
    </div>

    <div class="row">
      <pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>
    </div>

    <button
      class="row"
      color="primary"
      mat-raised-button
      *ngIf="this.config.currentPage === this.config.totalItems"
      class="btn-success btn-center btn-300"
      (click)="onReview()"
    >
      Review
    </button>
  </div>
</div>
