import { Component, OnInit } from '@angular/core';
import { InviteService } from '@app/core/services/invite.service';
import { MatDialog } from '@angular/material/dialog';

// components
import { UserInviteDialogComponent } from '@app/admin/pages/user/user-invite-dialog/user-invite-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { ArchiveDialogComponent } from '@app/shared/components/archive-dialog/archive-dialog.component';

// models
import { PsbItem } from '@app/core/models/psb-item.model';
import { Invite } from '@app/core/models/invite.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

// services
import { SnackBarService } from '@app/core/services/snack-bar.service';

@Component({
  selector: 'app-user-invite-list',
  templateUrl: './user-invite-list.component.html',
  styleUrls: ['./user-invite-list.component.scss'],
})
export class UserInviteListComponent implements OnInit {
  wholeSystemInvites: PsbItem[] = [];
  allInvites: Invite[] = [];

  constructor(private inviteService: InviteService, private dialog: MatDialog, private snackBar: SnackBarService) {}

  ngOnInit(): void {
    this.getAllUserInvite();
  }

  getAllUserInvite() {
    this.inviteService.getAllUserInvite().subscribe((result: Invite[]) => {
      this.allInvites = result;
      this.wholeSystemInvites = result.map((invite: Invite) => this.mapInvite(invite));
    });
  }

  mapInvite(invite: Invite): PsbItem {
    return {
      id: invite.id,
      title: invite.firstName ? invite.firstName : '',
      description: 'First Name',
      email: invite.email,

      extraColumns: [
        {
          key: 'Last Name',
          value: invite.lastName ? invite.lastName : '',
        },
        {
          key: 'Email',
          value: invite.email,
        },
        {
          key: 'TG/RN/CI',
          value: invite.title,
        },
        {
          key: 'Company',
          value: invite.company,
        },
      ],
    };
  }

  onEdit(inviteId: number) {
    const currentInvite = this.allInvites.filter((invite) => invite.id === inviteId)[0];
    this.inviteService.getInviteById(inviteId).subscribe((res) => {
      const token = res.token;
      const editedInvite = { ...currentInvite, token };

      const editDialog = this.dialog.open(UserInviteDialogComponent, {
        data: { isEdit: true, invite: editedInvite },
      });

      editDialog.afterClosed().subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          const updatedInvite: Invite = {
            firstName: editedInvite.firstName,
            lastName: editedInvite.lastName,
            email: result.data.email,
            title: editedInvite.title,
            company: editedInvite.company,
            psyTestGroupId: editedInvite.psyTestGroupId,
            token: editedInvite.token,
            companyId: editedInvite.companyId,
            active: editedInvite.active,
            invited: editedInvite.invited,
            testId: editedInvite.testId,
          };

          this.inviteService.updateEmail(inviteId, updatedInvite).subscribe((response) => {
            for (let index = 0; index < this.allInvites.length; index++) {
              if (this.allInvites[index].id === inviteId) {
                this.allInvites[index].email = result.data.email;
                this.wholeSystemInvites = this.allInvites.map((invite: Invite) => this.mapInvite(invite));
                this.snackBar.info('Invite email updated with success.');
                break;
              }
            }
          });
        }
      });
    }) 
  }

  onDelete(id: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        const deletedInvite = this.allInvites.filter((invite) => invite.id === id)[0];

        this.inviteService.deleteInvite(deletedInvite).subscribe(() => {
          this.wholeSystemInvites = this.wholeSystemInvites.filter((invite) => invite.id !== id);
          this.snackBar.info('Invite email deleted with success.');
        });
      });
  }

  onView(id: number) {
    let currentInvite = this.allInvites.filter((invite) => invite.id === id)[0];
    this.inviteService.getInviteById(id).subscribe((res) => {
      const token = res.token;
      const viewInvite = { ...currentInvite, token };
      const viewDialog = this.dialog.open(UserInviteDialogComponent, {
        data: { isEdit: false, invite: viewInvite },
      });
    })
  }

  onArchive(id: number) {
    this.dialog
      .open(ArchiveDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.inviteService.archive(id).subscribe(() => {
          this.wholeSystemInvites = this.wholeSystemInvites.filter((invite) => invite.id !== id);
          this.snackBar.info('Invite archived with success!');
        });
      });
  }
}
