import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';

@Component({
  selector: 'app-role-mandate-users-data',
  templateUrl: './role-mandate-users-data.component.html',
  styleUrls: ['./role-mandate-users-data.component.scss'],
})
export class RoleMandateUsersDataComponent implements OnInit {
  @Input() roleMandateId: number;

  tasksSource: MatTableDataSource<any>;
  @ViewChild('sortData') sortData: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginatorData: MatPaginator;
  displayedTasksColumns: string[] = ['userName', 'decisionArea', 'task', 'roleTitle'];

  constructor(private rMService: RoleMandateService) {}

  ngOnInit(): void {
    this.rMService.getAllTasks(this.roleMandateId).subscribe((result) => {
      this.tasksSource = new MatTableDataSource(result);
      this.tasksSource.sort = this.sortData;
      this.tasksSource.paginator = this.paginatorData;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tasksSource.filter = filterValue.trim().toLowerCase();

    if (this.tasksSource.paginator) {
      this.tasksSource.paginator.firstPage();
    }
  }

  downloadDecisions() {
    this.rMService.downloadDecisionsFile(this.roleMandateId, new Date().getTime().toString()).subscribe((result) => {
      if (result.fileUrl) {
        window.open(result.fileUrl, '_blank');
      }
    });
  }
}
