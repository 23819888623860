import { Component, Input, OnInit } from '@angular/core';
import { BulletPoint } from '@app/core/models/bullet-point.model';
import { ReportService } from '@app/core/services/report.service';

@Component({
  selector: 'app-bullet-point',
  templateUrl: './bullet-point.component.html',
  styleUrls: ['./bullet-point.component.scss'],
})
export class BulletPointComponent implements OnInit {
  @Input() model: BulletPoint;

  constructor(private reportService: ReportService) {}

  ngOnInit(): void {
    this.formatSentences();
  }

  formatSentences() {
    // Removes unnecessary tags
    this.model.leadSentence.text = this.reportService.removeUnnecessaryParagraphs(this.model.leadSentence.text);
    Object.keys(this.model.sentences).forEach((index) => {
      this.model.sentences[index].text = this.reportService.removeUnnecessaryParagraphs(
        this.model.sentences[index].text,
      );
      this.model.sentences[index].text = this.reportService.removeUnnecessaryParagraphs(
        this.model.sentences[index].text,
      );
      const textOfListTags = this.formatSentencesWithListTags(this.model.sentences[index].text);
      textOfListTags?.forEach((text, index) => {
        if (index === 0) {
          setTimeout(() => {
            this.model.sentences[index].text = `<p><span>${text}</span></p>`;
          });
        } else {
          setTimeout(() => {
            let newSentence = Object.assign({}, this.model.sentences[index]);
            newSentence.text = `<p><span>${text}</span></p>`;
            this.model.sentences.push(newSentence);
          });
        }
      });
    });
  }

  formatSentencesWithListTags(sentence: string): string[] {
    let sentenceArray = [];
    const hasListTags = sentence.includes('<li>');

    if (hasListTags) {
      const listElements = sentence.match(/<li>(.*?)<\/li>/g);
      sentenceArray = listElements.map((element) => element.replace(/<\/?li>/g, ''));
    }
    return sentenceArray;
  }
}
