import { Component, Input, OnInit } from '@angular/core';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { NetworkAnalysisService } from '@app/core/services/network-analysis.service';
import { RoleMandateService } from '@app/core/services/role-mandate.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';

@Component({
  selector: 'app-decision-table-analysis',
  templateUrl: './decision-table-analysis.component.html',
  styleUrls: ['./decision-table-analysis.component.scss'],
})
export class DecisionTableAnalysisComponent implements OnInit {
  @Input() decisionTable: DecisionTable;
  @Input() roleMandateId: number;
  @Input() connectionIndicatorId: number;
  @Input() nodes: Node[];
  breakpoint = 2;
  analysis: any[];

  constructor(
    private cIService: ConnectionsIndicatorService,
    private rMService: RoleMandateService,
    private networkAnalysisService: NetworkAnalysisService,
  ) {}

  ngOnInit(): void {
    if (this.roleMandateId) {
      this.rMService
        .getNetworkAnalysisByDecisionTableId(this.roleMandateId, this.decisionTable.id)
        .subscribe((analysisResult) => (this.analysis = analysisResult));
    } else if (this.connectionIndicatorId) {
      this.cIService
        .getNetworkAnalysisByDecisionTableId(this.connectionIndicatorId, this.decisionTable.id)
        .subscribe((analysisResult) => (this.analysis = analysisResult));
    }
  }

  refreshDecisionTable(decisionTableId: number) {
    this.networkAnalysisService.refreshDecisionTable(decisionTableId).subscribe((result) => {
      this.rMService
        .getNetworkAnalysisByDecisionTableId(this.roleMandateId, decisionTableId)
        .subscribe((analysisResult) => {
          this.analysis.filter((element) => element.decisionTable.id === decisionTableId)[0].analysis = analysisResult;
        });
    });
  }
}
