import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Notification } from '../models/notification.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class TestGroupNotificationService {
  constructor(private http: HttpClient) {}

  getTestGroupNotificationById(testGroupId: number) {
    return this.http.get<Notification[]>(BACKEND_URL + '/test-group-notification/' + testGroupId);
  }

  saveTestGroupNotification(testGroupId: number, notificationId: number) {
    return this.http.post(BACKEND_URL + '/test-group-notification/' + testGroupId, { notificationId });
  }

  deleteByTestGroupIdAndNotificationId(testGroupId: number, notificationId: number) {
    return this.http.delete(
      BACKEND_URL + '/test-group-notification/' + testGroupId + '/notification/' + notificationId,
    );
  }
}
