<div *ngIf="visible" class="row">
  <div [ngClass]="chart && chart.subscales.length > 0 ? 'column' : 'row'">
    <div *ngIf="visible" [ngClass]="chart && chart.subscales.length > 0 ? '' : 'side-by-side'">
      <figure [attr.id]="'CIRCLE_IN_CARTESIAN' + chartId" class="chart-report"></figure>
    </div>
    <div
      [ngClass]="chart && chart.subscales.length > 0 ? '' : 'side-by-side vertical-center'"
      style="margin-bottom: 2rem"
      *ngIf="description"
      [innerText]="description"
    ></div>
  </div>
  <div class="column subcales" *ngIf="userId">
    <app-bar-subscales *ngIf="subcalesX" [chartId]="chartId" [subcales]="subcalesX"></app-bar-subscales>
    <app-bar-subscales *ngIf="subcalesY" [chartId]="chartId" [subcales]="subcalesY"></app-bar-subscales>
  </div>
</div>
<span hidden [attr.id]="'CIRCLE_IN_CARTESIAN_PATH_NUMBER' + chartId">{{ pathNumber }}</span>
<div style="transform: translateX(-10000%)" id="tempBindCircleInCartesianPlot"></div>
