import { Component, Input, OnInit } from '@angular/core';

// services
import { ChartService } from '@app/core/services/chart.service';

// helpers
import { environment } from 'src/environments/environment';
import { BarSubscalesController } from 'src/generated/v3';
import { CircularPlotController } from 'src/generated/v3/services/CircularPlotController';

@Component({
  selector: 'app-circular-plot-new',
  templateUrl: './circular-plot.component.html',
  styleUrls: ['./circular-plot.component.scss'],
})
export class CircularPlotComponent implements OnInit {
  @Input() chartId: number;
  @Input() userId: number;
  @Input() testGroupId: number;
  @Input() companyId?: number;
  @Input() chartSelectedUsers: any[];
  @Input() visible?: boolean = true;

  description: string;
  src: string;
  isSubcales: boolean;

  constructor(
    private barSubscalesController: BarSubscalesController,
    private circularPlotController: CircularPlotController,
  ) {}

  ngOnInit(): void {
    this.src = `${environment.apiUrl}/v3/user/${this.userId}/testGroup/${this.testGroupId}/chart/${this.chartId}/circular-chart.png`;
    this.barSubscalesController
      .barSubscalesControllerBarSubscalesChartIsSubscales(this.userId, this.chartId, this.testGroupId, '')
      .subscribe((result) => {
        this.isSubcales = result;
      });
    this.circularPlotController
      .circularPlotControllerCircularChartDescription(this.userId, this.testGroupId, this.chartId)
      .subscribe((result) => {
        this.description = result?.description;
      });
  }
}
