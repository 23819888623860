<div class="container">
  <div class="page-list-header">
    <h1>Data Download</h1>
  </div>

  <form [formGroup]="dateForm" class="row" (ngSubmit)="onSubmit()">
    <div class="row">
      <mat-form-field class="column">
        <mat-label>Start date</mat-label>
        <input
          matInput
          [matDatepicker]="pickerStart"
          (dateInput)="dateChange('start', $event)"
          (dateChange)="dateChange('start', $event)"
          formControlName="start"
        />
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="column">
        <mat-label>End date</mat-label>
        <input
          matInput
          [matDatepicker]="pickerEnd"
          (dateInput)="dateChange('end', $event)"
          (dateChange)="dateChange('end', $event)"
          formControlName="end"
        />
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Download Type</mat-label>
        <input
          matInput
          required
          type="text"
          (ngModelChange)="this.typeDownloadFieldUpdate.next($event)"
          formControlName="typeDownload"
          [matAutocomplete]="autoTypeDownload"
        />
        <mat-autocomplete #autoTypeDownload="matAutocomplete" [displayWith]="displayDownloadType">
          <mat-option *ngFor="let type of downloadType | filter: typeDownloadField" [value]="type">
            {{ type.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="dateForm.get('typeDownload').errors">This field is required.</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Client</mat-label>
        <input
          matInput
          type="text"
          formControlName="client"
          (ngModelChange)="this.clientFieldUpdate.next($event)"
          [matAutocomplete]="autoClient" />
        <mat-autocomplete
          #autoClient="matAutocomplete"
          (optionSelected)="onSelectClient($event.option.value.id)"
          [displayWith]="displayClient"
        >
          <mat-option *ngFor="let client of clients | filter: clientField" [value]="client">
            {{ client.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Test Group</mat-label>
        <input
          matInput
          type="text"
          formControlName="testGroup"
          (ngModelChange)="this.testGroupFieldUpdate.next($event)"
          [matAutocomplete]="autoTestGroup" />
        <mat-autocomplete
          #autoTestGroup="matAutocomplete"
          (optionSelected)="onSelectTestGroup($event.option.value.id)"
          [displayWith]="displayTestGroup"
        >
          <mat-option *ngFor="let testGroup of testGroups | filter: testGroupField" [value]="testGroup">
            {{ testGroup.title }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Test</mat-label>
        <input
          matInput
          required
          type="text"
          (ngModelChange)="this.testFieldUpdate.next($event)"
          formControlName="test"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTest">
          <mat-option *ngFor="let test of tests | filter: testField" [value]="test">
            {{ test.title }}
            <p *ngIf="test.version" class="small-text">Version: {{ test.version }}</p>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="dateForm.get('test').errors">This field is required.</mat-error>
      </mat-form-field>
    </div>

    <button mat-raised-button color="primary" type="submit">Generate Report</button>
  </form>
</div>
