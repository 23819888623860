import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

// components
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { ScorePercentileService } from '@app/core/services/score-percentile.service';
import { HandleCsvService } from '@app/core/services/handle-csv.service';
import { SubscaleService } from '@app/core/services/subcale.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { ScorePercentile } from '@app/core/models/score-percentile.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-subscale-score-percentile',
  templateUrl: './subscale-score-percentile.component.html',
  styleUrls: ['./subscale-score-percentile.component.scss'],
})
export class SubscaleScorePercentileComponent implements OnInit {
  @Input() subscaleId: number;
  @Input() cardsortSubscale: number;
  @Input() subscaleTitle: string;

  scorePercentileDataSource: MatTableDataSource<ScorePercentile> = new MatTableDataSource<ScorePercentile>();
  displayedScorePercentileColumns: string[];
  scorePercentiles = [];

  constructor(
    private subscaleService: SubscaleService,
    private scorePercentileService: ScorePercentileService,
    private snackBar: SnackBarService,
    private handleCsvService: HandleCsvService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.displayedScorePercentileColumns = this.cardsortSubscale
      ? ['title', 'score', 'percentile', 'actions']
      : ['title', 'score', 'percentile'];

    this.getAllScorePercentile();
  }

  onAdd() {
    const scorePercentile: ScorePercentile = {
      id: null,
      score: 0,
      percentile: 0,
      subscaleId: this.subscaleId,
    };

    this.scorePercentiles.push(scorePercentile);

    this.refreshTableData();
  }

  async importCSV($event: Event) {
    const csvData: any = await this.handleCsvService.readCsvByEvent($event);

    for (const line of csvData) {
      if (String(line).length > 0) {
        const scorePercentile: ScorePercentile = {
          id: null,
          score: line[0],
          percentile: line[1],
          subscaleId: this.subscaleId,
        };

        this.scorePercentiles.push(scorePercentile);
        this.refreshTableData();
        this.snackBar.info('Score Percentile imported csv file with success!');
      }
    }
  }

  onReset() {
    this.subscaleService.deleteAllScorePercentile(this.subscaleId).subscribe((res) => {
      this.getAllScorePercentile();
      this.snackBar.info('Score Percentile reseted with success!');
    });
  }

  onSave() {
    this.subscaleService.saveAllScorePercentile(this.subscaleId, this.scorePercentiles).subscribe((res) => {
      this.getAllScorePercentile();
      this.snackBar.info('Score Percentile saved with success!');
    });
  }

  onDelete(element: ScorePercentile) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        if (element.id) {
          this.scorePercentileService.delete(element.id).subscribe(() => {
            this.scorePercentiles = this.scorePercentiles.filter(
              (scorePercentile) => scorePercentile.id !== element.id,
            );

            this.refreshTableData();
          });
        } else {
          this.scorePercentiles = this.scorePercentiles.filter(
            (scorePercentile) =>
              scorePercentile.score !== element.score || scorePercentile.percentile !== element.percentile,
          );

          this.refreshTableData();
        }

        this.snackBar.info('Score Percentile deleted with success!');
      });
  }

  getAllScorePercentile() {
    this.scorePercentiles = [];
    this.subscaleService.getAllScorePercentile(this.subscaleId).subscribe((scorePercentiles) => {
      if (scorePercentiles && scorePercentiles.length > 0) {
        scorePercentiles = scorePercentiles.sort((a, b) => {
          return a.percentile - b.percentile;
        });
        this.renderTableData(scorePercentiles);
      } else {
        const scorePercentile: ScorePercentile = {
          id: null,
          score: 0,
          percentile: 0,
          subscaleId: this.subscaleId,
        };

        this.scorePercentiles.push(scorePercentile);
        this.refreshTableData();
      }
    });
  }

  renderTableData(scorePercentiles: ScorePercentile[]) {
    scorePercentiles.map((sp) => {
      const scorePercentile: ScorePercentile = {
        id: sp.id,
        score: sp.score,
        percentile: sp.percentile,
        subscaleId: this.subscaleId,
      };

      this.scorePercentiles.push(scorePercentile);
    });

    this.refreshTableData();
  }

  refreshTableData() {
    this.scorePercentileDataSource.data = this.scorePercentiles;
    this.changeDetectorRef.detectChanges();
  }

  onAddPredefinedPercentileThinkingStyle() {
    this.scorePercentiles = [
      {
        percentile: 0,
        score: 0,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 1,
        score: 0.335373,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 5,
        score: 0.510978,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 10,
        score: 0.653983,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 20,
        score: 0.829588,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 30,
        score: 0.934258,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 35,
        score: 1.04794,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 40,
        score: 0.999091,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 50,
        score: 1.159236,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 65,
        score: 1.264552,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 75,
        score: 1.396164,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 80,
        score: 1.527776,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 85,
        score: 1.69342,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 90,
        score: 1.895872,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 95,
        score: 2.098325,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 100,
        score: 2.828427,
        subscaleId: this.subscaleId,
      },
    ];

    this.refreshTableData();
  }

  onAddPredefinedPercentileCRI() {
    this.scorePercentiles = [
      {
        percentile: 0,
        score: 0,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 1,
        score: 0.406557,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 5,
        score: 0.487821,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 10,
        score: 0.545065,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 13,
        score: 0.607422,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 16,
        score: 0.661342,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 19,
        score: 0.725771,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 23,
        score: 0.767521,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 26,
        score: 0.802791,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 29,
        score: 0.827077,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 32,
        score: 0.866656,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 35,
        score: 0.91265,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 38,
        score: 0.935788,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 41,
        score: 0.958815,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 44,
        score: 0.995109,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 47,
        score: 1.032003,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 50,
        score: 1.04878,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 50,
        score: 1.079773,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 53,
        score: 1.107242,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 56,
        score: 1.139301,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 59,
        score: 1.180815,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 62,
        score: 1.225494,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 65,
        score: 1.25202,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 68,
        score: 1.29148,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 71,
        score: 1.317204,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 74,
        score: 1.356011,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 77,
        score: 1.44032,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 80,
        score: 1.524033,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 83,
        score: 1.593496,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 86,
        score: 1.661117,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 90,
        score: 1.693614,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 95,
        score: 1.826184,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 99,
        score: 1.924598,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 100,
        score: 2.828427,
        subscaleId: this.subscaleId,
      },
    ];

    this.refreshTableData();
  }

  onAddPredefinedPercentile() {
    this.scorePercentiles = [
      {
        percentile: 0,
        score: 0,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 1,
        score: 0.157759,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 3,
        score: 0.304686,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 5,
        score: 0.451613,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 7,
        score: 0.539768,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 9,
        score: 0.598541,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 11,
        score: 0.600262,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 13,
        score: 0.639796,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 15,
        score: 0.673375,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 17,
        score: 0.686096,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 19,
        score: 0.707873,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 21,
        score: 0.770331,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 23,
        score: 0.771411,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 25,
        score: 0.810572,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 27,
        score: 0.815936,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 29,
        score: 0.839156,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 31,
        score: 0.841669,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 33,
        score: 0.862299,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 35,
        score: 0.907315,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 37,
        score: 0.931488,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 39,
        score: 0.978728,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 41,
        score: 0.98258,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 45,
        score: 1.006547,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 50,
        score: 1.054726,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 55,
        score: 1.068709,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 59,
        score: 1.098066,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 61,
        score: 1.145308,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 63,
        score: 1.180071,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 65,
        score: 1.19139,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 67,
        score: 1.228196,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 69,
        score: 1.240244,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 71,
        score: 1.303978,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 73,
        score: 1.304516,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 75,
        score: 1.368129,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 77,
        score: 1.400819,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 79,
        score: 1.435284,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 81,
        score: 1.52132,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 83,
        score: 1.545782,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 85,
        score: 1.656717,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 87,
        score: 1.689209,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 89,
        score: 1.831476,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 91,
        score: 1.895923,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 93,
        score: 2.165493,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 95,
        score: 2.186805,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 97,
        score: 2.472258,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 99,
        score: 2.757711,
        subscaleId: this.subscaleId,
      },
      {
        percentile: 100,
        score: 2.828427,
        subscaleId: this.subscaleId,
      },
    ];

    this.refreshTableData();
  }

  onInput(event: any, index: number) {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // Remove non-numeric characters and decimals
    value = value.replace(/[^0-9]/g, '');

    inputElement.value = value;
    this.scorePercentiles[index].percentile = value;
  }

  onKeyPress(event: KeyboardEvent) {
    // Allow only digits, backspace, and arrow keys
    const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Tab', 'Delete'];

    if (allowedKeys.indexOf(event.key) === -1 && !this.isDigit(event.key)) {
      event.preventDefault();
    }
  }

  isDigit(key: string): boolean {
    return /^\d$/.test(key);
  }
}
