<div class="container">
  <mat-card>
    <form (submit)="onLoginRequest(postForm)" #postForm="ngForm">
      <div class="password-error-message">
        <p *ngIf="loginError" role="alert" class="ng-tns-c87-0 ng-star-inserted" id="mat-error-0">
          <mat-icon aria-hidden="false">block</mat-icon>Sorry, that password isn't right. We can help you
          <a routerLink="/recover-password">recover your password</a>
        </p>
      </div>
      <mat-form-field>
        <mat-label>Username or Email</mat-label>
        <input matInput type="username" name="username" ngModel required #username="ngModel" id="username" />
        <mat-error *ngIf="username.invalid">Please, enter a valid username.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          name="password"
          ngModel
          required
          #password="ngModel"
          id="password"
        />
        <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </mat-icon>
        <mat-error *ngIf="password.invalid">Please, enter a valid password.</mat-error>
      </mat-form-field>
      <div class="password-error-message" *ngIf="userNotAuthenticated && !formChanged">The username/email or password you entered is incorrect.</div>
      <button mat-raised-button class="btn-success btn-center btn-300" type="submit" id="log-in-submit">Login</button>

      <a routerLink="/recover-password">Forgot your password?</a>
    </form>
  </mat-card>
</div>
