<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Heading</mat-label>
    <input matInput type="text" required formControlName="heading" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Positive Heading</mat-label>
    <input matInput type="text" formControlName="positiveHeading" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Concern Heading</mat-label>
    <input matInput type="text" formControlName="concernHeading" />
  </mat-form-field>
  <button mat-flat-button color="primary" (click)="addScoreCard()">Add new scorecard</button>
  <table>
    <colgroup>
      <col style="width: 10rem" />
      <col class="range" />
      <col class="range" />
      <col class="range" />
      <col class="range" />
      <col class="range" />
    </colgroup>
    <thead>
      <tr>
        <th></th>
        <th colspan="5">Preferred Ranges</th>
      </tr>
      <tr>
        <th>Scale</th>
        <th>Out of Range: Low</th>
        <th>Cautionary: Low</th>
        <th>Optimal</th>
        <th>Out of Range: High</th>
        <th>Cautionary: High</th>
      </tr>
      <tr>
        <th></th>
        <th>From - To</th>
        <th>From - To</th>
        <th>From - To</th>
        <th>From - To</th>
        <th>From - To</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let scoreCardControl of scoreCardsFormArray.controls; let i = index" [formGroup]="scoreCardControl">
        <td>
          <mat-form-field>
            <mat-label>Subscale</mat-label>
            <input
              matInput
              required
              type="text"
              (ngModelChange)="this.subscaleFieldUpdate.next($event)"
              formControlName="subscale"
              [matAutocomplete]="subscaleAuto"
            />
            <mat-autocomplete #subscaleAuto="matAutocomplete" [displayWith]="displaySubscale">
              <mat-option *ngFor="let subscale of subscales | filter: subscaleField" [value]="subscale">
                <div [matTooltip]="subscale.title">
                  <p class="report-scale-title-text">{{ subscale.title }}</p>
                  <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Superscale</mat-label>
            <input
              matInput
              required
              type="text"
              (ngModelChange)="this.superscaleFieldUpdate.next($event)"
              formControlName="superscale"
              [matAutocomplete]="superscaleAuto"
            />
            <mat-autocomplete #superscaleAuto="matAutocomplete" [displayWith]="displaySuperscale">
              <mat-option *ngFor="let superscale of superscales | filter: superscaleField" [value]="superscale">
                <div [matTooltip]="superscale.title">
                  <p class="report-scale-title-text">{{ superscale.title }}</p>
                  <p *ngIf="superscale.description" class="small-text">{{ superscale.description }}</p>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </td>
        <td [formGroup]="scoreCardControl.get('ranges.outOfRangeLow')">
          <div class="range">
            <mat-form-field>
              <mat-label>From</mat-label>
              <input matInput type="number" min="0" required formControlName="from" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>To</mat-label>
              <input matInput type="number" min="0" required formControlName="to" />
            </mat-form-field>
            <button
              *ngIf="scoreCardControl.get('ranges.outOfRangeLow.text').value === ''"
              mat-flat-button
              color="primary"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.outOfRangeLow.text'))"
            >
              Add text
            </button>
            <p
              *ngIf="scoreCardControl.get('ranges.outOfRangeLow.text').value !== ''"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.outOfRangeLow.text'))"
            >
              {{ scoreCardControl.get('ranges.outOfRangeLow.text').value }}
            </p>
            <button
              *ngIf="scoreCardControl.get('ranges.outOfRangeLow.text').value !== ''"
              mat-flat-button
              color="primary"
              (click)="removeTextDialog(scoreCardControl.get('ranges.outOfRangeLow.text'), 'Out of Range: low')"
            >
              Remove Text
            </button>
          </div>
        </td>
        <td [formGroup]="scoreCardControl.get('ranges.cautionaryLow')">
          <div class="range">
            <mat-form-field>
              <mat-label>From</mat-label>
              <input matInput type="number" min="0" required formControlName="from" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>To</mat-label>
              <input matInput type="number" min="0" required formControlName="to" />
            </mat-form-field>
            <button
              *ngIf="scoreCardControl.get('ranges.cautionaryLow.text').value === ''"
              mat-flat-button
              color="primary"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.cautionaryLow.text'))"
            >
              Add text
            </button>
            <p
              *ngIf="scoreCardControl.get('ranges.cautionaryLow.text').value !== ''"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.cautionaryLow.text'))"
            >
              {{ scoreCardControl.get('ranges.cautionaryLow.text').value }}
            </p>
            <button
              *ngIf="scoreCardControl.get('ranges.cautionaryLow.text').value !== ''"
              mat-flat-button
              color="primary"
              (click)="removeTextDialog(scoreCardControl.get('ranges.cautionaryLow.text'), 'Cautionary: Low')"
            >
              Remove Text
            </button>
          </div>
        </td>
        <td [formGroup]="scoreCardControl.get('ranges.optimal')">
          <div class="range">
            <mat-form-field>
              <mat-label>From</mat-label>
              <input matInput type="number" min="0" required formControlName="from" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>To</mat-label>
              <input matInput type="number" min="0" required formControlName="to" />
            </mat-form-field>
            <button
              *ngIf="scoreCardControl.get('ranges.optimal.text').value === ''"
              mat-flat-button
              color="primary"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.optimal.text'))"
            >
              Add text
            </button>
            <p
              *ngIf="scoreCardControl.get('ranges.optimal.text').value !== ''"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.optimal.text'))"
            >
              {{ scoreCardControl.get('ranges.optimal.text').value }}
            </p>
            <button
              *ngIf="scoreCardControl.get('ranges.optimal.text').value !== ''"
              mat-flat-button
              color="primary"
              (click)="removeTextDialog(scoreCardControl.get('ranges.optimal.text'), 'Optimal')"
            >
              Remove Text
            </button>
          </div>
        </td>
        <td [formGroup]="scoreCardControl.get('ranges.outOfRangeHigh')">
          <div class="range">
            <mat-form-field>
              <mat-label>From</mat-label>
              <input matInput type="number" min="0" required formControlName="from" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>To</mat-label>
              <input matInput type="number" min="0" required formControlName="to" />
            </mat-form-field>
            <button
              *ngIf="scoreCardControl.get('ranges.outOfRangeHigh.text').value === ''"
              mat-flat-button
              color="primary"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.outOfRangeHigh.text'))"
            >
              Add text
            </button>
            <p
              *ngIf="scoreCardControl.get('ranges.outOfRangeHigh.text').value !== ''"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.outOfRangeHigh.text'))"
            >
              {{ scoreCardControl.get('ranges.outOfRangeHigh.text').value }}
            </p>
            <button
              *ngIf="scoreCardControl.get('ranges.outOfRangeHigh.text').value !== ''"
              mat-flat-button
              color="primary"
              (click)="removeTextDialog(scoreCardControl.get('ranges.outOfRangeHigh.text'), 'Out Of Range: high')"
            >
              Remove Text
            </button>
          </div>
        </td>
        <td [formGroup]="scoreCardControl.get('ranges.cautionaryHigh')">
          <div class="range">
            <mat-form-field>
              <mat-label>From</mat-label>
              <input matInput type="number" min="0" required formControlName="from" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>To</mat-label>
              <input matInput type="number" min="0" required formControlName="to" />
            </mat-form-field>
            <button
              *ngIf="scoreCardControl.get('ranges.cautionaryHigh.text').value === ''"
              mat-flat-button
              color="primary"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.cautionaryHigh.text'))"
            >
              Add text
            </button>
            <p
              *ngIf="scoreCardControl.get('ranges.cautionaryHigh.text').value !== ''"
              (click)="openAddTextDialog(addTextForRangeDialog, scoreCardControl.get('ranges.cautionaryHigh.text'))"
            >
              {{ scoreCardControl.get('ranges.cautionaryHigh.text').value }}
            </p>
            <button
              *ngIf="scoreCardControl.get('ranges.cautionaryHigh.text').value !== ''"
              mat-flat-button
              color="primary"
              (click)="removeTextDialog(scoreCardControl.get('ranges.cautionaryHigh.text'), 'Cautionary: High')"
            >
              Remove Text
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>

<ng-template #addTextForRangeDialog>
  <h1 mat-dialog-title>Add text</h1>
  <angular-editor [formControl]="addTextEditorControl" [config]="editorConfig"></angular-editor>
  <div align="end" mat-dialog-actions>
    <button mat-flat-button (click)="closeAddTextDialog()">
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
    <button mat-flat-button [disabled]="addTextEditorControl.invalid" color="primary" (click)="saveAddTextDialog()">
      <mat-icon>save_alt</mat-icon>
      Save
    </button>
  </div>
</ng-template>
