<div class="pt-4">
  <div class="reportUser_score_panel">
    <div>
      <span
        [matTooltip]="userScore.subscaleDescription ? userScore.subscaleDescription : null"
        matTooltipPosition="above"
        >{{ index + 1 }}. {{ userScore.subscaleTitle }}</span
      >
      <mat-icon class="view_icon" routerLink="/subscale/{{ userScore.subscaleId }}/detail">remove_red_eye</mat-icon>
    </div>
    <div *ngIf="isAdmin || (isOA && allowRawScoresAccess); else noScores">
      <span class="percentile-score">{{ userScore.percentile }} / {{ userScore.score.toFixed(3) }}</span>
    </div>
    <ng-template #noScores>
      <span class="percentile-score">{{ userScore.percentile }}</span>
    </ng-template>
  </div>
  <div>
    <mat-progress-bar [ngClass]="color" [mode]="mode" [value]="userScore.percentile"> </mat-progress-bar>
  </div>
</div>
