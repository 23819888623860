import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// services
import { UserService } from '@app/core/services/user.service';

// models
import { UserInfo } from '@app/core/models/userInfo.model';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardUserComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  userData: any;
  userInfo = {} as UserInfo;

  isAuthenticated$: Subscription;
  useInfo$: Subscription;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userData = this.userService.getUserData();

    this.isAuthenticated$ = this.userService.isAuthenticated$.subscribe((value) => {
      this.userIsAuthenticated = value;
    });

    const userInfoId = parseInt(localStorage.getItem('userInfoId'), 10);

    this.useInfo$ = this.userService.get(userInfoId).subscribe((result) => {
      this.userInfo = result;
    });
  }

  getProgressBar(): number {
    const rmCompleted = this.userInfo.roleMandates?.filter((rM) => rM.status === 'COMPLETED').length || 0;
    const cICompleted = this.userInfo.connectionIndicators?.filter((cI) => cI.status === 'COMPLETED').length || 0;
    const pTCompleted = this.userInfo.psyTests?.filter((pT) => pT.status === 'COMPLETED').length || 0;
    const sTCompleted = this.userInfo.superTests?.filter((sT) => sT.status === 'COMPLETED').length || 0;

    const totalCompleted = rmCompleted + pTCompleted + cICompleted + sTCompleted;

    return +((totalCompleted / this.getTotal()) * 100).toFixed(0);
  }

  getTotal(): number {
    return (
      (this.userInfo.connectionIndicators?.length || 0) +
      (this.userInfo.roleMandates?.length || 0) +
      (this.userInfo.psyTests?.length || 0) +
      (this.userInfo.superTests?.length || 0)
    );
  }

  ngOnDestroy(): void {
    this.isAuthenticated$?.unsubscribe();
    this.useInfo$?.unsubscribe();
  }
}
