import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxPaginationModule } from 'ngx-pagination';

import { SharedModule } from '@app/shared/shared.module';
import { SubscaleRoutingModule } from './subscale-routing.module';

import { SubscaleListComponent } from './subscale-list/subscale-list.component';
import { SubscaleDetailComponent } from './subscale-detail/subscale-detail.component';
import { CreateSubscaleDialogComponent } from './create-subscale-dialog/create-subscale-dialog.component';
import { SubscaleQuestionListComponent } from './subscale-question-list/subscale-question-list.component';
import { SubscaleQuestionGroupListComponent } from './subscale-question-group-list/subscale-question-group-list.component';
import { CreateSubscaleQuestionDialogComponent } from './create-subscale-question-dialog/create-subscale-question-dialog.component';
import { SubscaleQuestionDetailComponent } from './subscale-question-detail/subscale-question-detail.component';
import { SubscaleQuestionAnswerListComponent } from './subscale-question-answer-list/subscale-question-answer-list.component';
import { SubscaleQuestionAnswerDetailComponent } from './subscale-question-answer-detail/subscale-question-answer-detail.component';
import { SubscaleQuestionAnswerCreateDialogComponent } from './subscale-question-answer-create-dialog/subscale-question-answer-create-dialog.component';
import { SubscaleQuestionGroupDetailComponent } from './subscale-question-group-detail/subscale-question-group-detail.component';
import { CreateSubscaleQuestionGroupDialogComponent } from './create-subscale-question-group-dialog/create-subscale-question-group-dialog.component';
import { SubscalePreviewComponent } from './subscale-preview/subscale-preview.component';

@NgModule({
  declarations: [
    SubscaleListComponent,
    SubscaleDetailComponent,
    CreateSubscaleDialogComponent,
    SubscaleQuestionListComponent,
    SubscaleQuestionGroupListComponent,
    CreateSubscaleQuestionDialogComponent,
    SubscaleQuestionDetailComponent,
    SubscaleQuestionAnswerListComponent,
    SubscaleQuestionAnswerDetailComponent,
    SubscaleQuestionAnswerCreateDialogComponent,
    SubscaleQuestionGroupDetailComponent,
    CreateSubscaleQuestionGroupDialogComponent,
    SubscalePreviewComponent,
  ],
  imports: [
    CommonModule,
    SubscaleRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    NgxPaginationModule,
  ],
  exports: [SubscaleListComponent],
})
export class SubscaleModule {}
