<div *ngIf="areaIndex" class="container">
  <span class="psb-page-navigator">
    Area Mapping > <b>{{ areaIndex.name }}</b> > {{ tabs[tabSelected] }}
  </span>

  <mat-tab-group
    *ngIf="areaIndex"
    mat-align-tabs="start"
    [selectedIndex]="tabSelected"
    (selectedIndexChange)="tabChange($event)"
  >
    <mat-tab label="Details">
      <button mat-raised-button color="primary" class="edit-button" (click)="onEdit()">
        <mat-icon>edit</mat-icon>
        Edit
      </button>

      <div class="row">
        <div class="column">
          <span class="label">Name</span>
          <p>{{ areaIndex.name }}</p>

          <span class="label">Base</span>
          <p>{{ areaIndex.base || 'Not specified' }}</p>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Areas">
      <app-area-mapping-areas [areaIndexId]="areaIndex.id" [areaIndexBase]="areaIndex.base"></app-area-mapping-areas>
    </mat-tab>
  </mat-tab-group>
</div>
