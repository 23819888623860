<div class="slider small_device" #slider [id]="question.id">
  <div>
    <h3 *ngIf="question.instructions" [innerHTML]="question.instructions"></h3>
    <div class="question-statement">
      <label class="questionTitle"
        ><span>{{ question.questionNumber }}) </span>
        <app-question-text class="innerTextForQuestion" [text]="question.text"></app-question-text
      ></label>
    </div>
  </div>
  <div class="slider_panel">
    <ngx-slider [(value)]="slideValue" (userChange)="saveSliderAnswer()" [options]="options">
      <ng-template #tooltipTemplate let-tooltip="tooltip" let-placement="placement" let-content="content">
        <div class="slider-wrap-text">{{ content }}</div>
      </ng-template>
    </ngx-slider>
  </div>
</div>
