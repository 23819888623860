import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// components
import { AlertDialogComponent } from '@app/shared/components/alert-dialog/alert-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';
import { SubscaleService } from '@app/core/services/subcale.service';
import { AnswerService } from '@app/core/services/answer.service';
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { PageService } from '@app/core/services/page.service';

// models
import { Question } from '@app/core/models/question.model';
import { Subscale } from '@app/core/models/subscale.model';
import { Answer } from '@app/core/models/answer.model';
import { QuestionGroup } from '@app/core/models/question-group.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-role-mandate-questions',
  templateUrl: './role-mandate-questions.component.html',
  styleUrls: ['./role-mandate-questions.component.scss'],
})
export class RoleMandateQuestionsComponent implements OnInit {
  creatingNewQuestion = false;
  creatingNewQuestionGroup = false;
  roleMandateId: number;
  savedQuestionsForm: Question[] = [];
  newQuestionForm = new FormGroup({
    text: new FormControl('', [Validators.required]),
    weight: new FormControl(0),
    questionType: new FormControl('', [Validators.required]),
    questionGroupId: new FormControl(''),
    relatedQuestionId: new FormControl(''),
    showDiscarded: new FormControl(0),
  });

  newQuestionGroupForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    instructions: new FormControl(''),
  });
  answerSubscales: Subscale[];
  answers: Answer[] = [];
  questionGroups: QuestionGroup[] = [];
  answerSubscalesLoaded: Promise<boolean>;
  pages: any[];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '130px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Instructions',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  constructor(
    private router: Router,
    private roleMandateService: RoleMandateService,
    private route: ActivatedRoute,
    private subscaleService: SubscaleService,
    private answerService: AnswerService,
    private questionGroupService: QuestionGroupService,
    public dialog: MatDialog,
    public pageService: PageService,
  ) {}

  ngOnInit(): void {
    this.roleMandateId = Number(this.route.snapshot.params.id);
    this.getRoleMandatePages();

    forkJoin([
      this.roleMandateService.getAllQuestionById(this.roleMandateId),
      this.subscaleService.getAllAnswerSubscale(),
      this.questionGroupService.getAllQuestionGroupForRoleMandate(this.roleMandateId),
    ]).subscribe({
      next: (result) => {
        this.questionGroups = typeof result[2] !== 'undefined' ? result[2] : [];
        if (result[0]) {
          this.savedQuestionsForm = result[0].map((question) => {
            question.answers = question.answers.map((answer) => {
              answer.subscale = result[1].filter((ansSub) => ansSub.id === answer.subscaleId)[0];
              return answer;
            });

            if (result[2]) {
              question.questionGroup = result[2].filter((qG) => qG.id === question.questionGroupId)[0];
            }

            return question;
          });
        }

        this.answerSubscales = result[1];
        this.answerSubscalesLoaded = Promise.resolve(true);
      },
    });
  }

  getSavedQuestions() {
    return this.savedQuestionsForm;
  }

  getSavedCardsortQuestions() {
    return this.savedQuestionsForm.filter((question) => question.questionType === 'CARDSORT');
  }

  saveQuestion(goToNext?) {
    if (!this.newQuestionForm.valid) {
      return;
    }

    const question: Question = {
      text: this.newQuestionForm.get('text').value,
      weight: this.newQuestionForm.get('weight').value ? this.newQuestionForm.get('weight').value : 0,
      questionType: this.newQuestionForm.get('questionType').value,
      questionGroupId: this.newQuestionForm.get('questionGroupId').value,
      relatedQuestionId: parseInt(this.newQuestionForm.get('relatedQuestionId').value, 10),
      showDiscarded: this.newQuestionForm.get('showDiscarded').value,
      questionGroup: this.questionGroups.filter(
        (qG) => qG.id === +this.newQuestionForm.get('questionGroupId').value,
      )[0],
    };

    if (this.answers.length) {
      question.answers = this.answers;
      this.roleMandateService
        .saveQuestion(this.roleMandateId, question)
        .pipe(mergeMap((questionSaved) => this.answerService.saveAll(questionSaved['questionId'], this.answers)))
        .subscribe((result) => {
          question.id = parseInt(result['questionId'], 10);
          this.answerService.getAllByQuestionId(question.id).subscribe((result) => {
            question.answers = result;
            this.savedQuestionsForm.push(question);
            this.newQuestionForm.reset();
            this.creatingNewQuestion = false;
            this.answers = [];
            if (goToNext) {
              return this.router.navigate([`/role-mandate/${this.roleMandateId}/decision-tables`]);
            }
          });
        });
    } else {
      this.roleMandateService.saveQuestion(this.roleMandateId, question).subscribe((result) => {
        question.id = parseInt(result['questionId'], 10);
        this.savedQuestionsForm.push(question);
        this.newQuestionForm.reset();
        this.creatingNewQuestion = false;
        this.answers = [];
        if (goToNext) {
          return this.router.navigate([`/role-mandate/${this.roleMandateId}/decision-tables`]);
        }
      });
    }
  }

  saveQuestionGroup(goToNext?) {
    if (this.newQuestionGroupForm.valid) {
      const questionGroup: QuestionGroup = {
        title: this.newQuestionGroupForm.get('title').value,
        instructions: this.newQuestionGroupForm.get('instructions').value,
      };
      this.questionGroupService
        .saveQuestionGroupForRoleMandate(this.roleMandateId, questionGroup)
        .subscribe((result) => {
          questionGroup.id = result.id;
          this.questionGroups.push(questionGroup);
          this.newQuestionGroupForm.reset();
          this.newQuestionGroupForm.get('title').clearValidators();
          this.newQuestionGroupForm.get('title').updateValueAndValidity();
          this.newQuestionGroupForm.get('instructions').clearValidators();
          this.newQuestionGroupForm.get('instructions').updateValueAndValidity();
          this.creatingNewQuestionGroup = false;
          if (goToNext) {
            return this.router.navigate([`/role-mandate/${this.roleMandateId}/decision-tables`]);
          }
        });
    } else {
      return;
    }
  }

  createNewQuestion() {
    this.creatingNewQuestion = true;
  }

  createNewQuestionGroup() {
    this.creatingNewQuestionGroup = true;
  }

  onDeleteQuestion(questionId: number) {
    this.savedQuestionsForm = this.savedQuestionsForm.filter((question) => question.id !== questionId);
  }

  onEditQuestion(event) {
    const questionPosition = this.savedQuestionsForm.map((question) => question.id).indexOf(event.id);
    this.savedQuestionsForm[questionPosition] = event;
  }

  onAddAnswer(answer: Answer) {
    this.answers = this.answers.concat(answer);
  }

  onDeleteQuestionGroup(questionGroupId: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);

    deleteDialog.componentInstance.message = `Please confirm that you want delete this Question Group?`;

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.questionGroups = this.questionGroups.filter((questionGroup) => questionGroup.id !== questionGroupId);
      }
    });
  }

  cancelCreateQuestion() {
    this.newQuestionForm.reset();
    this.creatingNewQuestion = false;
    this.answers = [];
  }

  onNewDecisionTables() {
    if (this.creatingNewQuestionGroup || this.creatingNewQuestion) {
      const alertDialog = this.dialog.open(AlertDialogComponent);
      alertDialog.componentInstance.message = `You want to save the question before move forward?`;
      alertDialog.afterClosed().subscribe((result) => {
        if (result.event !== EVENT_CANCEL) {
          if (this.creatingNewQuestion) {
            this.saveQuestion(true);
          }
          if (this.creatingNewQuestionGroup) {
            this.saveQuestionGroup(true);
          }
        }
      });
    } else {
      return this.router.navigate([`/role-mandate/${this.roleMandateId}/decision-tables`]);
    }
  }

  getRoleMandatePages() {
    this.pages = this.pageService.getRoleMandatePages(this.roleMandateId);
  }

  onCancelQuestionGroup(newQuestionGroupForm: FormGroupDirective) {
    newQuestionGroupForm.resetForm();
    this.creatingNewQuestionGroup = false;
  }
}
