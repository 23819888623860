<div class="spreads-table">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.columnDef"
      [sticky]="isSticky(column.columnDef)"
    >
      <th mat-header-cell *matHeaderCellDef>
        {{ column.header }}
      </th>

      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.percentile !== editRow || column.columnDef === 'percentile'">
          <span (click)="edit(row)"> {{ column.cell(row) ? column.cell(row) : '-' }} </span>
        </ng-container>
        <ng-container *ngIf="row.percentile === editRow && column.columnDef !== 'percentile'">
          <input
            [name]="column.columnDef"
            type="number"
            min="0"
            max="10"
            [value]="column.cell(row)"
            (change)="onChangeEvent(column, row, $event)"
          />
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
