import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '@app/core/services/company.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  psyTestGroupStatistics: any = [];
  overAllUserInvited: number = 0;
  overAllRegistered: number = 0;
  overAllCompleted: number = 0;
  overAllInProgress: number = 0;
  constructor(private companyService: CompanyService, private router: ActivatedRoute) {}

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      this.companyService.getStatisticsByCompanyId(params.id).subscribe((data: any[]) => {
        this.psyTestGroupStatistics = data;

        this.overAllUserInvited = this.psyTestGroupStatistics.reduce((accumulator, obj) => {
          return accumulator + obj.totalUsers + obj.totalInvited;
        }, 0);

        this.overAllRegistered = this.psyTestGroupStatistics.reduce((accumulator, obj) => {
          return accumulator + obj.totalUsers;
        }, 0);

        this.overAllCompleted = this.psyTestGroupStatistics.reduce((accumulator, obj) => {
          return accumulator + obj.totalCompleted;
        }, 0);

        this.overAllInProgress = this.psyTestGroupStatistics.reduce((accumulator, obj) => {
          return accumulator + (obj.totalUsers - obj.totalCompleted);
        }, 0);
      });
    });
  }

  calculateTotalUser(item: any) {
    return item.totalUsers + item.totalInvited;
  }
}
