<h1>{{ data.isEdit ? 'Edit an answer' : 'Create an answer' }}</h1>
<form [formGroup]="subscaleQuestionAnswerDialogForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <textarea matInput formControlName="text" placeholder="Text" rows="3"></textarea>
  </mat-form-field>
  <mat-error *ngIf="subscaleQuestionAnswerDialogForm.get('text').errors">Please, enter a valid text.</mat-error>
  <div *ngIf="data.question.questionType === 'IMAGE'">
    <p class="file-name">{{ fileName }}</p>
    <button mat-button class="btn-primary btn-center btn-300">
      <mat-icon>add</mat-icon>
      Upload Image
      <input
        formControlName="imageFile"
        type="file"
        accept="image/*"
        class="input-file"
        (change)="handleFile('image', $event)"
      />
    </button>
  </div>
  <div *ngIf="data.question.questionType === 'VIDEO'">
    <p class="file-name">{{ fileName }}</p>
    <button mat-button class="btn-primary btn-center btn-300">
      <mat-icon>add</mat-icon>
      Upload Video
      <input
        formControlName="videoFile"
        type="file"
        accept="video/*"
        class="input-file"
        (change)="handleFile('video', $event)"
      />
    </button>
  </div>
  <mat-form-field>
    <mat-label>Order</mat-label>
    <input matInput type="number" formControlName="order" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Weight</mat-label>
    <input matInput type="number" formControlName="weight" />
  </mat-form-field>
  <mat-form-field *ngIf="!isIntegerAnswer">
    <mat-label>Subscale</mat-label>
    <input
      type="text"
      placeholder="Pick one subscale"
      aria-label="Subscale"
      matInput
      formControlName="subscaleId"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let subscale of filteredSubscales$ | async"
        value="{{ subscale.title }}"
        (onSelectionChange)="onChange(subscale)"
      >
        {{ subscale.title }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-error *ngIf="isCardsortAnswer && subscaleQuestionAnswerDialogForm.get('subscaleId').errors"
    >Please, enter a valid subscale.</mat-error
  >
  <mat-form-field>
    <textarea matInput formControlName="style" placeholder="Style" rows="3"></textarea>
  </mat-form-field>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!subscaleQuestionAnswerDialogForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
