import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PsyTestListComponent } from './psy-test-list/psy-test-list.component';
import { TestDetailComponent } from './test-detail/test-detail.component';

const clientsRoutes: Routes = [
  { path: '', component: PsyTestListComponent },
  { path: ':id/detail', component: TestDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(clientsRoutes)],
  exports: [RouterModule],
})
export class PsyTestRoutingModule {}
