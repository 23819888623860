<div class="container">
  <div id="connections-indicators" *ngIf="connectionIndicators">
    <div class="page-list-header">
      <h1>Connections Indicator</h1>
      <button mat-raised-button color="primary" class="btn-success" (click)="onNewCI()">New</button>
    </div>

    <app-psb-list
      [items]="connectionIndicators"
      itemsPerPage="10"
      (edit)="onEdit($event)"
      (dashboard)="onDashboard($event)"
      (view)="onView($event)"
      (delete)="onDelete($event)"
    >
    </app-psb-list>
  </div>
</div>
