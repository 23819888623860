import { OnInit, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// services
import { OptimalCurvesService } from '@app/core/services/optimal-curves.service';
import { SubscaleService } from '@app/core/services/subcale.service';
import { SuperscaleService } from '@app/core/services/superscale.service';
import { OptimalScoreService } from '@app/core/services/optimal-score.service';

// models
import { OptimalScoreCurve } from '@app/core/models/optimal-score-curve.model';
import { Subscale } from '@app/core/models/subscale.model';
import { Superscale } from '@app/core/models/superscale.model';
import { Chapter } from '@app/core/models/chapter.model';
import { Section } from '@app/core/models/section.model';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';

@Component({
  selector: 'app-add-optimal-score-dialog',
  templateUrl: './add-optimal-score-dialog.component.html',
  styleUrls: ['./add-optimal-score-dialog.component.scss'],
})
export class AddOptimalScoreDialogComponent implements OnInit {
  form = this.fb.group({
    title: [''],
    subscale: [''],
    superscale: [''],
    optimalScoreCurveId: ['', [Validators.required]],
    from: ['', [Validators.required]],
    to: ['', [Validators.required]],
    curveNumber: ['', [Validators.required]],
    curveSpreadId: ['', [Validators.required]],
    weight: ['', [Validators.required]],
  });

  optimalScoreId: number;
  optimalScoreScaleId: number;
  optimalScoreCurveId: number;

  superscales: Superscale[] = [];
  subscales: Subscale[] = [];
  optimalScoreCurves: OptimalScoreCurve[] = [];
  optimalScoreCurve: OptimalScoreCurve;
  spreads: any[];

  filteredSubscales: Observable<Subscale[]>;
  filteredSuperscales: Observable<Superscale[]>;

  from: number;
  to: number;

  edit = false;

  /** Chart Config */
  lineChartData: ChartDataSets[];

  lineChartLabels: Label[];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: '#425465',
      backgroundColor: '#4ac29a',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  /** */

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      chapter?: Chapter;
      section?: Section;
      optimalScoreScaleId?: number;
      reportId?: number;
      chapterId?: number;
    },
    private subscaleService: SubscaleService,
    private superscaleService: SuperscaleService,
    private optimalCurvesService: OptimalCurvesService,
    private dialogRef: MatDialogRef<AddOptimalScoreDialogComponent>,
    private optimalScoreService: OptimalScoreService,
  ) {
    this.optimalCurvesService.getAll().subscribe((optimalScoreCurves) => {
      this.optimalScoreCurves = optimalScoreCurves;
    });

    this.loadOptimalScoreCurve();

    forkJoin({
      superscales: this.superscaleService.getAll(),
      subscales: this.subscaleService.getAll(),
    }).subscribe(({ subscales, superscales }) => {
      this.superscales = superscales;
      this.subscales = subscales;

      if (this.data && this.data.optimalScoreScaleId) {
        this.loadOptimalScoreScale();
      }
    });
  }

  ngOnInit(): void {
    this.filteredSubscales = this.form.get('subscale').valueChanges.pipe(
      startWith(''),
      map((value) => this._filterSubcales(value)),
    );

    this.filteredSuperscales = this.form.get('superscale').valueChanges.pipe(
      startWith(''),
      map((value) => this._filterSupercales(value)),
    );
  }

  loadOptimalScoreCurve(curveNumber?: number) {
    this.optimalCurvesService.findById(this.optimalScoreCurveId).subscribe((optimalScoreCurve) => {
      this.optimalScoreCurve = optimalScoreCurve;

      if (this.optimalScoreCurve) {
        this.spreads = this.optimalScoreCurve.spreads.map((elem) => {
          return {
            percentile: elem.percentile,
            spread: elem.spread,
            curveNumber: elem.curveNumber,
          };
        });

        if (curveNumber) {
          this.selectCurve(curveNumber);
        }
      }
    });
  }

  loadOptimalScoreScale() {
    this.optimalScoreService.getOptimalScoreScale(this.data.optimalScoreScaleId).subscribe((optimalScoreScale) => {
      if (optimalScoreScale) {
        this.form.get('title').setValue(optimalScoreScale.optimalScoreTitle);
        this.form
          .get('subscale')
          .setValue(this.subscales.find((subscale) => subscale.id === optimalScoreScale.subscaleId));
        this.form
          .get('superscale')
          .setValue(this.superscales.find((superscale) => superscale.id === optimalScoreScale.superscaleId));
        this.optimalScoreCurveId = optimalScoreScale.optimalScoreCurveId;
        this.optimalScoreId = optimalScoreScale.optimalScoreId;
        this.form.get('optimalScoreCurveId').setValue(optimalScoreScale.optimalScoreCurveId);
        this.form.get('weight').setValue(optimalScoreScale.weight);
        this.form.get('curveNumber').setValue(optimalScoreScale.curveNumber);
        this.loadOptimalScoreCurve(optimalScoreScale.curveNumber);
        this.edit = true;
      }
    });
  }

  displaySubscaleFn(subscale: Subscale): string {
    return subscale && subscale.title ? subscale.title : '';
  }

  displaySuperscaleFn(superscale: Subscale): string {
    return superscale && superscale.title ? superscale.title : '';
  }

  private _filterSubcales(value: string): Subscale[] {
    if (typeof value === 'object' || !value) {
      return this.subscales;
    }
    const filterValue = value.toLowerCase();

    return this.subscales.filter((subscale) => subscale.title.toLowerCase().includes(filterValue));
  }

  private _filterSupercales(value: string): Superscale[] {
    if (typeof value === 'object' || !value) {
      return this.superscales;
    }
    const filterValue = value.toLowerCase();

    return this.superscales.filter((superscale) => superscale.title.toLowerCase().includes(filterValue));
  }

  selectCurve(curveNumber) {
    const curve = this.optimalScoreCurve.spreads.filter((spread) => spread.curveNumber === curveNumber)[0];
    if (curve) {
      this.form.get('from').setValue(curve.percentile);
      this.form.get('to').setValue(curve.percentile + curve.spread);
      this.form.get('curveSpreadId').setValue(curve.id);
    }

    this.lineChartLabels = this.optimalScoreCurve.ranges[curveNumber].map((elem) => elem.optimalPercentile);

    this.lineChartData = [
      { data: this.optimalScoreCurve.ranges[curveNumber].map((elem) => elem.score), label: 'Curve ' + curveNumber },
    ];
  }

  selectOptimalScoreCurve(optimalScoreCurveId) {
    this.optimalCurvesService.findById(optimalScoreCurveId).subscribe((optimalScoreCurve) => {
      this.optimalScoreCurve = optimalScoreCurve;
      this.spreads = optimalScoreCurve.spreads.map((elem) => {
        return {
          percentile: elem.percentile,
          spread: elem.spread,
          curveNumber: elem.curveNumber,
        };
      });
    });
  }

  change(field) {
    this.from = this.form.get('from').value;
    this.to = this.form.get('to').value;

    if (this.from && this.to) {
      const curve = this.optimalScoreCurve.spreads
        .filter((spread) => spread.percentile === this.from)
        .filter((spread) => spread.spread === this.to - this.from)[0];
      if (curve) {
        this.form.get('curveNumber').setValue(curve.curveNumber);
        this.form.controls['curveNumber'].setErrors(null);
        this.selectCurve(curve.curveNumber);
      } else {
        this.form.get('curveNumber').setValue(null);
        this.form.controls['curveNumber'].setErrors({ incorrect: true });
      }
      console.log(this.form.controls['curveNumber']);
    }
  }

  onSubmit() {
    const { title, subscale, superscale, optimalScoreCurveId, curveNumber, curveSpreadId, weight } = this.form.value;

    if (this.edit) {
      this.optimalScoreService
        .update({
          id: this.optimalScoreId,
          optimalScoreScaleId: this.data.optimalScoreScaleId,
          reportId: this.data.reportId,
          chapterId: this.data.chapterId,
          sectionId: this.data.section.id,
          title,
          subscaleId: subscale ? subscale.id : null,
          superscaleId: superscale ? superscale.id : null,
          optimalScoreCurveId,
          curveNumber,
          curveSpreadId,
          weight: +Number(weight),
        })
        .subscribe((editedOptimalScore) => {
          this.dialogRef.close({ event: EVENT_SUCCESS });
        });
    } else {
      this.optimalScoreService
        .create({
          reportId: this.data.reportId,
          chapterId: this.data.chapterId,
          sectionId: this.data.section.id,
          title,
          subscaleId: subscale ? subscale.id : null,
          superscaleId: superscale ? superscale.id : null,
          optimalScoreCurveId,
          curveNumber,
          curveSpreadId,
          weight: +Number(weight),
        })
        .subscribe((addedOptimalScore) => {
          this.dialogRef.close({ event: EVENT_SUCCESS });
        });
    }
  }
}
