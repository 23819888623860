<div class="container">
  <app-psb-page-navigator [pages]="pageService.getConnectionIndicatorPages()"></app-psb-page-navigator>

  <h1>{{ connectionIndicator?.title }}</h1>

  <mat-card>
    <mat-card-header>
      <mat-card-title> Instructions </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [innerHTML]="connectionIndicator?.instructions"></div>
    </mat-card-content>

    <mat-form-field>
      <mat-label>Company</mat-label>
      <input matInput type="text" name="company" [ngModel]="company?.name" #ctrl="ngModel" readonly="true" />
    </mat-form-field>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title> Users </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-table [dataSource]="users" class="mat-elevation-z3">
        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.firstName }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.lastName }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="invited">
          <mat-header-cell *matHeaderCellDef> Invite </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.id ? false : true }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedReviewUsersColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedReviewUsersColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginatorUsers [pageSize]="5" [pageSizeOptions]="[5, 10, 50]" showFirstLastButtons>
      </mat-paginator>
    </mat-card-content>
  </mat-card>

  <app-global-privacy
    *ngIf="connectionIndicator"
    title="Connections Indicator Privacy"
    description="Select the level of privacy to the entire Connections Indicator"
    [acl]="connectionIndicator.acl"
    (update)="onChangePrivacy($event)"
  >
  </app-global-privacy>

  <button mat-raised-button class="btn-success btn-center btn-300" (click)="saveAndInvite()">
    Save and Invite Users
  </button>
</div>
