import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// services
import { TestGroupService } from '@app/core/services/test-group.service';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-edit-test-group-user-modal',
  templateUrl: './edit-test-group-user-modal.component.html',
  styleUrls: ['./edit-test-group-user-modal.component.scss'],
})
export class EditTestGroupUserModalComponent implements OnInit {
  editTestGroupUserModalForm = new FormGroup({
    dateAssigned: new FormControl({ value: '', disabled: true }),
    userType: new FormControl(''),
    name: new FormControl({ value: '', disabled: true }),
    testGroup: new FormControl({ value: '', disabled: true }),
  });

  constructor(
    public dialogRef: MatDialogRef<EditTestGroupUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { psyTestGroupUserId: number },
    private testGroupService: TestGroupService,
  ) {}

  ngOnInit(): void {
    this.testGroupService.getTestGroupUserByTestGroupUserId(this.data.psyTestGroupUserId).subscribe((res) => {
      if (res) {
        this.editTestGroupUserModalForm
          .get('dateAssigned')
          .setValue(new Date(res.dateAssigned).toLocaleDateString('en-US'));
        this.editTestGroupUserModalForm.get('userType').setValue(res.userType);
        this.editTestGroupUserModalForm.get('name').setValue(res.name);
        this.editTestGroupUserModalForm.get('testGroup').setValue(res['testGroup']);
      }
    });
  }

  onSave() {
    this.dialogRef.close({ event: 'save', data: this.editTestGroupUserModalForm.value.userType });
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL });
  }
}
