<div class="container">
  <app-report-versioning></app-report-versioning>

  <div class="container-report" *ngIf="report">
    <div class="page-list-header">
      <div>
        <h1>{{ report.title }}</h1>
        <h2>{{ user.firstName }} {{ user.lastName }}</h2>
      </div>
      <div *ngIf="report.optimalScore" hidden>
        <div>
          <app-optimal-score
            [chartId]="'report' + report.id"
            [score]="report.optimalScore.score.toFixed(2)"
            [size]="'large'"
          >
          </app-optimal-score>
        </div>
      </div>
    </div>
    <app-user-report-pdf></app-user-report-pdf>
  </div>
</div>
