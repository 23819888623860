import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// models
import { PsbPage } from '@app/core/models/psb-page.model';

@Component({
  selector: 'app-psb-page-navigator',
  templateUrl: './psb-page-navigator.component.html',
  styleUrls: ['./psb-page-navigator.component.scss'],
})
export class PsbPageNavigatorComponent {
  @Input() pages: PsbPage[];

  constructor(private router: Router) {}

  onClick(page: PsbPage) {
    this.router.navigate([page.path]);
  }
}
