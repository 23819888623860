import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { ConnectionsIndicatorService } from '../services/connections-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class PciAclGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private cIService: ConnectionsIndicatorService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkAcl(route.params.id, route);
  }

  checkAcl(connectionIndicatorId: number, route: ActivatedRouteSnapshot) {
    return this.cIService.getById(connectionIndicatorId).pipe(
      map((connectionIndicator) => {
        if (
          (this.userService.isOwner(+route.params.userId) || [0, 5].includes(connectionIndicator.acl)) &&
          this.userService.isSameCompany(connectionIndicator.companyId)
        ) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      }),
    );
  }
}
