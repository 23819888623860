/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Question } from '../models/Question';
import type { Supertest } from '../models/Supertest';
import type { SupertestTest } from '../models/SupertestTest';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class SupertestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a psyTestGroupSupertest
     * Create a psyTestGroupSupertest
     * @param testGroupId Id of the test group
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupPsyTestGroupSupertest(
        testGroupId: number,
        requestBody?: {
            /**
             * Title of the supertest
             */
            title?: string;
            /**
             * Description of the supertest
             */
            description?: number;
            /**
             * Active of supertest
             */
            active?: boolean;
            /**
             * Start Instructions of supertest
             */
            startInstructions?: string;
            /**
             * Warning Instructions of supertest
             */
            warningInstructions?: string;
            /**
             * Paging of supertest
             */
            paging?: number;
            /**
             * Deleted of supertest
             */
            deleted?: boolean;
            /**
             * Randomize Order of supertest
             */
            randomizeOrder?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{testGroupId}/psy-test-group-supertest',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all completed supertests by testGroupId and userInfoId
     * Retrieve all completed supertests by testGroupId and userInfoId
     * @param testGroupId Id of the Test Group
     * @param userInfoId Id of the User Info
     * @returns Supertest A list of completed supertests
     * @throws ApiError
     */
    public getTestGroupUserCompletedSupertest(
        testGroupId: number,
        userInfoId: number,
    ): Observable<Supertest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/user/{userInfoId}/completed-supertest',
            path: {
                'testGroupId': testGroupId,
                'userInfoId': userInfoId,
            },
            errors: {
                404: `Supertest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list of supertests by testGroupId
     * Retrieve a list of supertests by testGroupId
     * @param testGroupId Id of the Test Group
     * @returns Supertest A list of supertests
     * @throws ApiError
     */
    public getTestGroupSupertest(
        testGroupId: number,
    ): Observable<Supertest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/supertest',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Supertest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all supertests
     * @returns Supertest A list of Supertests
     * @throws ApiError
     */
    public getSupertest(): Observable<Array<Supertest>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/supertest',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a supertest
     * Create a supertest
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postSupertest(
        requestBody?: {
            /**
             * Title of the supertest
             */
            title: string;
            /**
             * Description of the supertest
             */
            description: string;
            /**
             * Active of the supertest
             */
            active?: number;
            /**
             * Start Instructions of the supertest
             */
            startInstructions: string;
            /**
             * Warning Instructions of the supertest
             */
            warningInstructions: string;
            /**
             * Paging of the supertest
             */
            paging: number;
            /**
             * Randomize Order of the supertest
             */
            randomizeOrder: number;
            /**
             * Deleted of the supertest
             */
            deleted: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/supertest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a supertest by id
     * Retrieve a supertest by id
     * @param id Id of the supertest
     * @returns Supertest A supertest
     * @throws ApiError
     */
    public getSupertest1(
        id: number,
    ): Observable<Supertest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/supertest/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Supertest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update an supertest
     * Update an supertest
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchSupertest(
        id: number,
        requestBody?: {
            /**
             * Title of the supertest
             */
            title: string;
            /**
             * Description of the supertest
             */
            description: string;
            /**
             * Active of the supertest
             */
            active: number;
            /**
             * Start Instructions of the supertest
             */
            startInstructions: string;
            /**
             * Warning Instructions of the supertest
             */
            warningInstructions: string;
            /**
             * Paging of the supertest
             */
            paging: number;
            /**
             * Randomize Order of the supertest
             */
            randomizeOrder: number;
            /**
             * Deleted of the supertest
             */
            deleted: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/supertest/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Supertest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a supertest
     * Delete a supertest
     * @param id Id of the supertest
     * @returns any Supertest deleted successfully
     * @throws ApiError
     */
    public deleteSupertest(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/supertest/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all question by id
     * Retrieve all question by id
     * @param id Id of the supertest
     * @returns Question A list of questions
     * @throws ApiError
     */
    public getSupertestQuestion(
        id: number,
    ): Observable<Question> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/supertest/{id}/question',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of questions not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all SupertestTest by id
     * Retrieve all SupertestTest by id
     * @param id Id of the supertest
     * @returns SupertestTest A list of supertestTests
     * @throws ApiError
     */
    public getSupertestSupertestTest(
        id: number,
    ): Observable<SupertestTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/supertest/{id}/supertest-test',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of supertestTests not found`,
                500: `Internal error`,
            },
        });
    }

}
