/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Chapter } from '../models/Chapter';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ChapterService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve a list with all chapters
     * Retrieve a list with all chapters
     * @returns Chapter A list of chapters
     * @throws ApiError
     */
    public getChapter(): Observable<Array<Chapter>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chapter',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a chapter
     * Create a chapter
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postChapter(
        requestBody?: {
            /**
             * Title of the chapter
             */
            title: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/chapter',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a chapter by id
     * Retrieve a chapter by id
     * @param id Id of the chapter
     * @returns Chapter A section
     * @throws ApiError
     */
    public getChapter1(
        id: number,
    ): Observable<Chapter> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/chapter/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Section not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update a chapter
     * Update a chapter
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchChapter(
        id: number,
        requestBody?: {
            /**
             * Title of the chapter
             */
            title: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/chapter/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Chapter not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Add a section to a chapter
     * Add a section to a chapter
     * @param id Id of the chapter
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postChapterSection(
        id: number,
        requestBody?: {
            /**
             * Id of the section
             */
            sectionId: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/chapter/{id}/section',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Chapter not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a section from the chapter
     * Remove a section from the chapter
     * @param chapterId Id of the chapter
     * @param sectionId Id of the section
     * @returns any Success on removing section from chapter
     * @throws ApiError
     */
    public deleteChapterSection(
        chapterId: number,
        sectionId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/chapter/{chapterId}/section/{sectionId}',
            path: {
                'chapterId': chapterId,
                'sectionId': sectionId,
            },
            errors: {
                404: `Chapter and/or section not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a chapter from the report
     * Remove a chapter from the report
     * @param reportId Id of the report
     * @param chapterId Id of the chapter
     * @returns any A report
     * @throws ApiError
     */
    public deleteReportChapter(
        reportId: number,
        chapterId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/report/{reportId}/chapter/{chapterId}',
            path: {
                'reportId': reportId,
                'chapterId': chapterId,
            },
            errors: {
                404: `Report or Chapter not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Add a chapter to a report
     * Add a chapter to a report
     * @param id Id of the report
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postReportChapter(
        id: number,
        requestBody?: {
            /**
             * Id of the chapter
             */
            chapterId?: number;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/{id}/chapter',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Report not found`,
                500: `Internal error`,
            },
        });
    }

}
