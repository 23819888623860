import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

// components
import { AddSubscaleDialogComponent } from '../add-subscale-dialog/add-subscale-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// services
import { ChartService } from '@app/core/services/chart.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';
import { forkJoin } from 'rxjs';
import { SubscaleService } from '@app/core/services/subcale.service';
import { SuperscaleService } from '@app/core/services/superscale.service';

@Component({
  selector: 'app-chart-subscales',
  templateUrl: './chart-subscales.component.html',
  styleUrls: ['./chart-subscales.component.scss'],
})
export class ChartSubscalesComponent implements OnInit, OnChanges {
  @Input() chartId: number;
  @Input() chartBase: string;
  subscalesDataSource = new MatTableDataSource([]);
  hexagonOrFourPlusSubScaleDataSource = new MatTableDataSource([]);
  displayedSubscalesColumns = [];
  axes = ['X', 'Y'];
  subscaleList = [];
  superSubscaleList = [];
  isDisableSubscaleBtn: boolean = false;
  isDisableSuperscaleBtn: boolean = false;

  constructor(
    private chartService: ChartService,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
    private subScaleService: SubscaleService,
    private superSubscaleService: SuperscaleService,
  ) {}

  ngOnInit(): void {
    this.chartService.getSubscalesByChartId(this.chartId).subscribe((chartSubscales) => {
      if (this.chartBase == 'HEXAGON' || this.chartBase == 'FOUR_PLUS_ONE_CARTESIAN') {
        if (this.chartBase == 'HEXAGON') this.buildTableDataForHexagon(chartSubscales);
        if (this.chartBase == 'FOUR_PLUS_ONE_CARTESIAN') this.buildTableDataForFourPlusOneCartesian(chartSubscales);
        this.getSubscaleAndSupersubScale(chartSubscales);
      } else {
        this.subscalesDataSource.data = chartSubscales.map((chartSubscale) => ({
          id: chartSubscale.id,
          subscaleId: !chartSubscale.useSuperscale ? chartSubscale.subscaleId : chartSubscale.superscaleId,
          name: !chartSubscale.useSuperscale ? chartSubscale.subscaleTitle : chartSubscale.superscaleTitle,
          axis: chartSubscale.axis,
          weight: chartSubscale.weight,
          isSuperScale: !chartSubscale.useSuperscale ? false : true,
        }));

        if (this.subscalesDataSource.data.length) {
          let isSuperscale = this.subscalesDataSource.data[0]?.isSuperScale;
          this.disableSubscaleSuperScaleButton(isSuperscale);
        } else if (this.subscalesDataSource.data.length == 0) {
          this.isDisableSubscaleBtn = false;
          this.isDisableSubscaleBtn = false;
        }
      }
    });
  }

  ngOnChanges(): void {
    if (this.chartBase == 'HEXAGON' || this.chartBase == 'FOUR_PLUS_ONE_CARTESIAN') {
      this.displayedSubscalesColumns = ['sector', 'subscale', 'superscale', 'actions'];
    } else if (this.chartBase == 'FOUR_PLUS_ONE_CARTESIAN') {
      this.displayedSubscalesColumns = ['sector', 'subscale', 'superscale', 'actions'];
    } else {
      this.displayedSubscalesColumns = ['name', 'axis', 'weight', 'actions'];
    }
  }

  onDelete(chartSubscaleId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.chartService.deleteChartSubscale(chartSubscaleId).subscribe(() => {
          this.subscalesDataSource.data = this.subscalesDataSource.data.filter(
            (subscale) => subscale.id !== chartSubscaleId,
          );
          if (this.subscalesDataSource.data.length == 0) {
            this.isDisableSubscaleBtn = false;
            this.isDisableSuperscaleBtn = false;
          }
        });

        this.openSnackBar('Subscale was removed from this chart.');
      });
  }

  onAddSubscale(type?: string) {
    const data = {
      type: type,
      chartId: this.chartId,
      addedSubscales: this.subscalesDataSource.data.map((subscale) => subscale.subscaleId),
    };

    this.dialog
      .open(AddSubscaleDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_SUCCESS) {
          const { newSubscale } = result;
          this.subscalesDataSource.data = [
            ...this.subscalesDataSource.data,
            {
              id: newSubscale.id,
              subscaleId: newSubscale.subscaleId,
              name: newSubscale.subscaleTitle,
              weight: newSubscale.weight,
              axis: newSubscale.axis,
            },
          ];

          if (type == 'subscale' && this.subscalesDataSource.data.length > 0) {
            this.isDisableSuperscaleBtn = true;
          } else {
            this.isDisableSubscaleBtn = true;
          }
          this.openSnackBar('Added a new subscale to this chart.');
        }
      });
  }

  openSnackBar(message: string) {
    this.snackBar.info(message);
  }

  onSave(chartSubscale: any) {
    const { id: chartSubscaleId, axis, weight } = chartSubscale;
    this.chartService.updateChartSubscale(chartSubscaleId, { axis, weight, base: this.chartBase }).subscribe(() => {
      this.openSnackBar('Chart subscale was updated successfully.');
    });
  }

  buildTableDataForHexagon(chartSubscales) {
    this.hexagonOrFourPlusSubScaleDataSource = new MatTableDataSource([
      { id: chartSubscales[0].id, sector: { id: 'NW', label: 'Northwest' }, subscale: null, superscale: null },
      { id: chartSubscales[1].id, sector: { id: 'N', label: 'North' }, subscale: null, superscale: null },
      { id: chartSubscales[2].id, sector: { id: 'NE', label: 'Northeast' }, subscale: null, superscale: null },
      { id: chartSubscales[3].id, sector: { id: 'SW', label: 'Southwest' }, subscale: null, superscale: null },
      { id: chartSubscales[4].id, sector: { id: 'S', label: 'South' }, subscale: null, superscale: null },
      { id: chartSubscales[5].id, sector: { id: 'SE', label: 'Southeast' }, subscale: null, superscale: null },
    ]);
  }

  buildTableDataForFourPlusOneCartesian(chartSubscales) {
    this.hexagonOrFourPlusSubScaleDataSource = new MatTableDataSource([
      { id: chartSubscales[0].id, sector: { id: 'TOP_LEFT', label: 'TOP LEFT' }, subscale: null, superscale: null },
      { id: chartSubscales[1].id, sector: { id: 'TOP_RIGHT', label: 'TOP RIGHT' }, subscale: null, superscale: null },
      { id: chartSubscales[2].id, sector: { id: 'CENTER', label: 'CENTER' }, subscale: null, superscale: null },
      {
        id: chartSubscales[3].id,
        sector: { id: 'BOTTOM_LEFT', label: 'BOTTOM LEFT' },
        subscale: null,
        superscale: null,
      },
      {
        id: chartSubscales[4].id,
        sector: { id: 'BOTTOM_RIGHT', label: 'BOTTOM RIGHT' },
        subscale: null,
        superscale: null,
      },
    ]);
  }

  getSubscaleAndSupersubScale(responseSubscales: any[]) {
    forkJoin([this.subScaleService.getAll(), this.superSubscaleService.getAll()]).subscribe((result: any) => {
      if (result[0].length) {
        this.subscaleList = result[0].map((e: any) => {
          return { id: e.id, title: e.title };
        });
      }
      if (result[1].length) {
        this.superSubscaleList = result[1].map((e: any) => {
          return { id: e.id, title: e.title };
        });
      }

      responseSubscales.map((e) => {
        this.hexagonOrFourPlusSubScaleDataSource.data.map((i) => {
          if (i.sector.id == e.direction) {
            // Handling different db connectors responses
            let useSuperscale;
            if (e.useSuperscale) {
              if (Object(e.useSuperscale).hasOwnProperty('data')) {
                useSuperscale = e.useSuperscale.data[0];
              } else {
                useSuperscale = e.useSuperscale;
              }
            }
            return !useSuperscale ? (i.subscale = e.subscaleId) : (i.superscale = e.superscaleId);
          }
        });
      });
    });
  }

  selectSubscaleOrSuperSubScale(control: string, element: any, index: number) {
    if (control == 'subscale') {
      if (this.hexagonOrFourPlusSubScaleDataSource.data[index].subscale) {
        this.hexagonOrFourPlusSubScaleDataSource.data[index].superscale = null;
      }
    }

    if (control == 'superSubscale') {
      if (this.hexagonOrFourPlusSubScaleDataSource.data[index].superscale) {
        this.hexagonOrFourPlusSubScaleDataSource.data[index].subscale = null;
      }
    }
  }

  saveChartSubscale(element: any) {
    let params = {
      direction: element?.sector.id,
      chartId: this.chartId,
      subscale: element?.subscale,
      superscale: element?.superscale,
      useSuperscale: element?.subscale ? 0 : 1,
      base: this.chartBase,
    };

    this.chartService.updateChartSubscale(element.id, params).subscribe(() => {
      this.openSnackBar('Chart subscale was updated successfully.');
    });
  }

  resetSubscaleRow(element: any, index: number) {
    this.hexagonOrFourPlusSubScaleDataSource.data[index].subscale = null;
    this.hexagonOrFourPlusSubScaleDataSource.data[index].superscale = null;
  }

  disableSubscaleSuperScaleButton(isSuperscale: boolean) {
    if (!isSuperscale && this.subscalesDataSource.data.length > 0) this.isDisableSuperscaleBtn = true;
    if (isSuperscale && this.subscalesDataSource.data.length > 0) this.isDisableSubscaleBtn = true;
  }
}
