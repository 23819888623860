import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { CreateSubscaleDialogComponent } from '../create-subscale-dialog/create-subscale-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { CopyDialogComponent } from '@app/shared/components/copy-dialog/copy-dialog.component';
import { ArchiveDialogComponent } from '@app/shared/components/archive-dialog/archive-dialog.component';

// services
import { SubscaleService } from '@app/core/services/subcale.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { HandleCsvService } from '@app/core/services/handle-csv.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';
import { Subscale } from '@app/core/models/subscale.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-subscale-list',
  templateUrl: './subscale-list.component.html',
  styleUrls: ['./subscale-list.component.scss'],
})
export class SubscaleListComponent implements OnInit {
  subscales: PsbItem[] = [];
  subscalesImportedByCSV: Subscale[] = [];
  displayedSubscalesColumns: string[] = ['title', 'description', 'status', 'actions'];
  constructor(
    private subscaleService: SubscaleService,
    private handleCsvService: HandleCsvService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getAllSubscale();
  }

  getAllSubscale() {
    this.subscaleService.getAll().subscribe((subscales) => {
      subscales.map((subscale) => {
        const item: PsbItem = {
          id: subscale.id,
          title: subscale.title,
          description: subscale.description,
          status: subscale.active ? 'Active' : 'Inactive',
          extraColumns: [
            {
              key: 'Answer Subscale',
              value: subscale.cardsortSubscale ? 'Yes' : 'No',
            },
            {
              key: 'Created Date',
              value: new Date(subscale.dateAdded).toLocaleDateString('en-US'),
            },
          ],
        };
        this.subscales.push(item);
      });
    });
  }

  onDelete(subscaleId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.subscaleService.delete(subscaleId).subscribe(() => {
          this.subscales = this.subscales.filter((subscale) => subscale.id !== subscaleId);
          this.snackBar.info('Subscale deleted with success!');
        });
      });
  }

  onView(subscaleId: number) {
    return this.router.navigate([`/subscale/${subscaleId}/detail`]);
  }

  onCopy(subscaleId: number) {
    const copyDialog = this.dialog.open(CopyDialogComponent);
    this.subscales.map((subscale) => {
      if (subscale.id === subscaleId) {
        copyDialog.componentInstance.title = subscale.title;
        copyDialog.componentInstance.type = 'Subscale';
      }
    });
    copyDialog.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.copySubscaleById(subscaleId);
      }
    });
  }

  onArchive(subscaleId: number) {
    this.dialog
      .open(ArchiveDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }
        this.subscaleService.archive(subscaleId).subscribe(() => {
          this.subscales = this.subscales.filter((subscale) => subscale.id !== subscaleId);
          this.snackBar.info('Subscale archived with success!');
        });
      });
  }

  copySubscaleById(subscaleId: number) {
    this.subscaleService.copy(subscaleId).subscribe((res) => {
      this.getAllSubscale();
      this.snackBar.info('Subscale copied with success!');
    });
  }

  onCreate(): void {
    this.dialog
      .open(CreateSubscaleDialogComponent, { data: { isEdit: false } })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          // Create
          this.subscaleService.create(result.data).subscribe((created: Subscale) => {
            const item: PsbItem = {
              id: created.id,
              title: created.title,
              description: created.description,
              status: created.active ? 'Active' : 'Inactive',
              extraColumns: [
                {
                  key: 'Answer Subscale',
                  value: created.cardsortSubscale ? 'Yes' : 'No',
                },
                {
                  key: 'Created Date',
                  value: new Date(created.dateAdded).toLocaleDateString('en-US'),
                },
              ],
            };
            this.subscales = [...this.subscales, item];
            this.snackBar.info('Subscale created with success!');
          });
        }
      });
  }

  async handleSubscaleListCSVFile($event: Event) {
    const csvData: any = await this.handleCsvService.readCsvByEvent($event);

    for (const line of csvData) {
      if (String(line).length > 0) {
        const subscale: Subscale = {
          id: null,
          title: line[0],
          description: line[1],
          randomAllowed: line[2],
          active: line[3],
          scorePercentileActive: line[4],
          dateAdded: null,
          cardsortSubscale: line[5],
          color: line[6],
        };

        this.subscalesImportedByCSV.push(subscale);
      }
    }

    this.subscaleService.createAll(this.subscalesImportedByCSV).subscribe({
      next: (result) => {
        this.getAllSubscale();
      },
    });
  }
}
