<mat-card>
  <form [formGroup]="newColumnForm" #formDirective="ngForm" (ngSubmit)="newColumn(formDirective)">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" />
      <mat-error *ngIf="newColumnForm.get('title').errors">Please, enter a valid text.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Column Type</mat-label>
      <mat-select [formControl]="newColumnForm.get('type')">
        <mat-option
          *ngFor="let decisionTableColumnType of decisionTableColumnTypes"
          [value]="decisionTableColumnType.id"
        >
          {{ decisionTableColumnType.name }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="newColumnForm.get('type').hasError('required')"> You must make a selection </mat-error>
    </mat-form-field>
    <mat-slide-toggle color="primary" [formControl]="newColumnForm.get('required')">Required </mat-slide-toggle>
    <div class="edit-buttons-container text-center">
      <button mat-button type="submit" class="btn-success btn-300">Create Column</button>
      <button type="button" mat-button class="btn-success btn-300" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</mat-card>
