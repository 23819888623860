<div class="container">
  <div *ngIf="userInfoData">
    <app-role [answering]="true" [userInfo]="userInfoData"> </app-role>

    <app-alternative-role [answering]="true" [userInfo]="userInfoData"> </app-alternative-role>

    <app-department-form [answering]="true" [userInfo]="userInfoData"> </app-department-form>

    <app-level [answering]="true" [userInfo]="userInfoData"> </app-level>
  </div>

  <button mat-raised-button class="btn-success btn-center btn-300" (click)="onNext()">Next</button>
</div>
