import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  public title: string;
  public message: string;
  public isHtml: boolean;
  public copy: boolean;

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    private clipboardApi: ClipboardService,
    public sanitizer: DomSanitizer,
  ) {}

  onClose() {
    this.dialogRef.close({ event: 'close' });
  }

  onCopy() {
    this.clipboardApi.copyFromContent(this.message);
    this.dialogRef.close({ event: 'close' });
  }
}
