import { Component, OnInit, ViewChild } from '@angular/core';
import { TemplateController } from '../../../../../../generated/v3';
import { ActivatedRoute } from '@angular/router';
import { NgProgressComponent } from 'ngx-progressbar';

@Component({
  selector: 'app-edit-docx-template',
  templateUrl: './edit-docx-template.component.html',
  styleUrls: ['./edit-docx-template.component.scss'],
})
export class EditDocxTemplateComponent {
  @ViewChild(NgProgressComponent) private progressBar: NgProgressComponent;

  constructor(private templateController: TemplateController, private route: ActivatedRoute) {}

  openEditTemplate() {
    this.progressBar.start();
    this.templateController
      .templateControllerOpenDocxTemplate(Number(this.route.snapshot.params.id))
      .subscribe((response) => {
        window.open(response.link, 'blank');
        this.progressBar.complete();
      });
  }

  resetTemplateToDefaults() {
    this.progressBar.start();
    this.templateController
      .templateControllerCreateDocxTemplate(Number(this.route.snapshot.params.id))
      .subscribe((response) => {
        window.open(response.link, 'blank');
        this.progressBar.complete();
      });
  }
}
