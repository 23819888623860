import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// services
import { TestService } from '@app/core/services/test.service';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-test-instructions',
  templateUrl: './test-instructions.component.html',
  styleUrls: ['./test-instructions.component.scss'],
})
export class TestInstructionsComponent implements OnInit {
  @Input() title: string;
  @Input() instructions: string;
  @Output() clickStart = new EventEmitter();
  warningInstructions: string;
  userInfoId: number;
  psyTestId: number;
  superTestId: number;
  testTime: number;
  dateStarted: any;
  private testGroupId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private testService: TestService,
  ) {}

  ngOnInit(): void {
    this.userInfoId = parseInt(localStorage.getItem('userInfoId'), 10);
    this.testGroupId = Number(this.route.snapshot.params['testGroupId']);
    this.psyTestId = Number(this.route.snapshot.params['psyTestId']);
    this.superTestId = Number(this.route.snapshot.params['superTestId']);

    if (this.psyTestId) {
      this.testService.getPsyTestById(this.psyTestId).subscribe((data) => {
        this.testTime = data.testTime;
        (this.title = data.title), (this.instructions = data.startPageInstructions);
        this.warningInstructions = data.warningInstructions;
      });
    }

    if (this.superTestId) {
      this.testService.getSuperTestById(this.superTestId).subscribe((data) => {
        (this.title = data.title), (this.instructions = data.startInstructions);
        this.warningInstructions = data.warningInstructions;
      });
    }

    if (this.psyTestId && this.testGroupId && this.userInfoId) {
      this.findDateStarted();
    }
  }

  findDateStarted() {
    this.testService
      .getTestResultByTestIdAndTestGroupIdAndUserInfoId(this.psyTestId, this.testGroupId, this.userInfoId)
      .subscribe((data) => {
        if (data && data.dateStarted) {
          this.dateStarted = data.dateStarted;
        }
      });
  }

  onClickStart() {
    if (this.psyTestId) {
      if (!this.dateStarted) {
        this.testService.start(this.psyTestId, this.testGroupId, this.userInfoId).subscribe(() => {
          return this.router.navigate([`/dashboard/test/${this.psyTestId}/test-group/${this.testGroupId}/page/1`]);
        });
      } else {
        return this.router.navigate([`/dashboard/test/${this.psyTestId}/test-group/${this.testGroupId}/page/1`]);
      }
    } else if (this.superTestId) {
      return this.router.navigate([`/dashboard/supertest/${this.superTestId}/page/1`]);
    } else {
      this.clickStart.emit();
    }
  }
}
