<div class="container">
  <div *ngIf="!isLoading">
    <div class="page-list-header">
      <h1>Role Navigator</h1>
      <button mat-raised-button color="primary" class="btn-success" (click)="onNewTitle()">New</button>
    </div>

    <app-psb-list
      [items]="roleMandates"
      itemsPerPage="10"
      (edit)="onEdit($event)"
      (dashboard)="onDashboard($event)"
      (view)="onView($event)"
      viewLabel="Preview"
      (delete)="onDelete($event)"
    >
    </app-psb-list>
  </div>
</div>
