/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Supertest } from '../models/Supertest';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupSuperTestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a psyTestGroupSupertest
     * Create a psyTestGroupSupertest
     * @param testGroupId Id of the test group
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupPsyTestGroupSupertest(
        testGroupId: number,
        requestBody?: {
            /**
             * Title of the supertest
             */
            title?: string;
            /**
             * Description of the supertest
             */
            description?: number;
            /**
             * Active of supertest
             */
            active?: boolean;
            /**
             * Start Instructions of supertest
             */
            startInstructions?: string;
            /**
             * Warning Instructions of supertest
             */
            warningInstructions?: string;
            /**
             * Paging of supertest
             */
            paging?: number;
            /**
             * Deleted of supertest
             */
            deleted?: boolean;
            /**
             * Randomize Order of supertest
             */
            randomizeOrder?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{testGroupId}/psy-test-group-supertest',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list of supertests by testGroupId
     * Retrieve a list of supertests by testGroupId
     * @param testGroupId Id of the Test Group
     * @returns Supertest A list of supertests
     * @throws ApiError
     */
    public getTestGroupSupertest(
        testGroupId: number,
    ): Observable<Supertest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/supertest',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `Supertest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update a status
     * Update a status
     * @param psyTestGroupSupertestId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchTestGroupTestGroupSupertestAvailable(
        psyTestGroupSupertestId: number,
        requestBody?: {
            /**
             * id of psy test group
             */
            testGroupId?: number;
            /**
             * id of supertest
             */
            supertestId?: number;
            /**
             * available of psy test group supertest
             */
            available?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/test-group/test-group-supertest/{psyTestGroupSupertestId}/available',
            path: {
                'psyTestGroupSupertestId': psyTestGroupSupertestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `PsyTestGroupSuperTest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a super test from test group
     * Remove a super test from test group
     * @param psyTestGroupSupertestId Id of the test group supertest
     * @returns any SuperTest deleted from test group successfully
     * @throws ApiError
     */
    public deleteTestGroupTestGroupSupertest(
        psyTestGroupSupertestId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/test-group-supertest/{psyTestGroupSupertestId}',
            path: {
                'psyTestGroupSupertestId': psyTestGroupSupertestId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Archive a super test from test group
     * Archive a super test from test group
     * @param psyTestGroupSupertestId Id of the test group supertest
     * @returns any SuperTest is archived from test group successfully
     * @throws ApiError
     */
    public deleteTestGroupTestGroupSupertestArchive(
        psyTestGroupSupertestId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/test-group-supertest/{psyTestGroupSupertestId}/archive',
            path: {
                'psyTestGroupSupertestId': psyTestGroupSupertestId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

}
