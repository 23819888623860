<div class="container">
  <mat-card>
    <div *ngIf="requested">
      <h3>Please, check your mailbox and follow the instructions to recover your account.</h3>
    </div>
    <form *ngIf="!requested" [formGroup]="recoverPasswordForm" (submit)="onSubmit()">
      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input matInput type="email" formControlName="email" />
        <mat-error *ngIf="recoverPasswordForm.get('email').invalid"
          >Given e-mail is invalid or doesn't exists.</mat-error
        >
      </mat-form-field>
      <button mat-raised-button type="submit" color="primary">Recover Password</button>
    </form>
  </mat-card>
</div>
