/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class BarSubscalesController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param userId
     * @param chartId
     * @param testGroupId
     * @returns string
     * @throws ApiError
     */
    public barSubscalesControllerBarSubscalesChartPng(
        userId: number,
        chartId: number,
        testGroupId: number,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/user/{userId}/chart/{chartId}/testGroupId/{testGroupId}/bar-subscales-chart.png',
            path: {
                'userId': userId,
                'chartId': chartId,
                'testGroupId': testGroupId,
            },
        });
    }

    /**
     * @param userId
     * @param chartId
     * @param testGroupId
     * @returns string
     * @throws ApiError
     */
    public barSubscalesControllerBarSubscalesChartXPng(
        userId: number,
        chartId: number,
        testGroupId: number,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/user/{userId}/chart/{chartId}/testGroupId/{testGroupId}/bar-subscales-chart-x.png',
            path: {
                'userId': userId,
                'chartId': chartId,
                'testGroupId': testGroupId,
            },
        });
    }

    /**
     * @param userId
     * @param chartId
     * @param testGroupId
     * @returns string
     * @throws ApiError
     */
    public barSubscalesControllerBarSubscalesChartYPng(
        userId: number,
        chartId: number,
        testGroupId: number,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/user/{userId}/chart/{chartId}/testGroupId/{testGroupId}/bar-subscales-chart-y.png',
            path: {
                'userId': userId,
                'chartId': chartId,
                'testGroupId': testGroupId,
            },
        });
    }

    /**
     * @param userId
     * @param chartId
     * @param testGroupId
     * @param type
     * @returns boolean
     * @throws ApiError
     */
    public barSubscalesControllerBarSubscalesChartIsSubscales(
        userId: number,
        chartId: number,
        testGroupId: number,
        type: string,
    ): Observable<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/isSubscalesUserByTestGroup',
            query: {
                'userId': userId,
                'chartId': chartId,
                'testGroupId': testGroupId,
                'type': type,
            },
        });
    }

}
