<div *ngIf="isLoaded">
  <div *ngIf="optimalScoreScales && optimalScoreScales.length > 0; else elseBlock">
    <mat-table [dataSource]="chaptersOptimalScoreScales">
      <ng-container matColumnDef="sectionTitle">
        <mat-header-cell *matHeaderCellDef class="column-interval"> Section </mat-header-cell>
        <mat-cell *matCellDef="let element" class="column-interval">
          <p>{{ element.sectionTitle }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="subscale">
        <mat-header-cell *matHeaderCellDef class="column-interval"> Subscale </mat-header-cell>
        <mat-cell *matCellDef="let element" class="column-interval">
          <p>{{ element.subscaleTitle }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="superscale">
        <mat-header-cell *matHeaderCellDef class="column-interval"> Superscale </mat-header-cell>
        <mat-cell *matCellDef="let element" class="column-interval">
          <p>{{ element.superscaleTitle }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="optimalCurveTitle">
        <mat-header-cell *matHeaderCellDef class="column-interval"> Optimal Curve</mat-header-cell>
        <mat-cell *matCellDef="let element" class="column-interval">
          <p>{{ element.optimalScoreCurveTitle }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="curve">
        <mat-header-cell *matHeaderCellDef class="column-interval"> Curve Range </mat-header-cell>
        <mat-cell *matCellDef="let element" class="column-interval">
          <p>
            Curve {{ element.curveNumber }} [<strong>From:</strong> {{ element.from }} | <strong>To: </strong>
            {{ element.to }}]
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="weight">
        <mat-header-cell *matHeaderCellDef> Weight </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <p class="content-right">{{ element.weight }}</p>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedSectionOptimalScoreColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedSectionOptimalScoreColumns"></mat-row>
    </mat-table>
  </div>
  <ng-template #elseBlock>This chapter does not have optimal scores.</ng-template>
  <div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>
      <mat-icon>block</mat-icon>
      Close
    </button>
  </div>
</div>
