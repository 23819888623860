/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AngularHttpRequest } from './core/AngularHttpRequest';
import { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { OpenAPI } from './core/OpenAPI';

import { AreaService } from './services/AreaService';
import { AreaIndexService } from './services/AreaIndexService';
import { BlossomChartService } from './services/BlossomChartService';
import { ChapterService } from './services/ChapterService';
import { ChartService } from './services/ChartService';
import { ChartSubscaleService } from './services/ChartSubscaleService';
import { CompanyService } from './services/CompanyService';
import { CompnayService } from './services/CompnayService';
import { ConnectionIndicatorService } from './services/ConnectionIndicatorService';
import { CustomRangeService } from './services/CustomRangeService';
import { DefaultService } from './services/DefaultService';
import { DepartmentService } from './services/DepartmentService';
import { EmailLogService } from './services/EmailLogService';
import { InviteService } from './services/InviteService';
import { LevelService } from './services/LevelService';
import { NotificationService } from './services/NotificationService';
import { PsyEmailService } from './services/PsyEmailService';
import { PsyTestService } from './services/PsyTestService';
import { PsyTestGroupService } from './services/PsyTestGroupService';
import { PsyTestGroupChartService } from './services/PsyTestGroupChartService';
import { PsyTestGroupNotificationService } from './services/PsyTestGroupNotificationService';
import { PsyTestGroupReportService } from './services/PsyTestGroupReportService';
import { PsyTestGroupReportModelService } from './services/PsyTestGroupReportModelService';
import { PsyTestGroupSuperscaleService } from './services/PsyTestGroupSuperscaleService';
import { PsyTestGroupSuperTestService } from './services/PsyTestGroupSuperTestService';
import { PsyTestGroupTestService } from './services/PsyTestGroupTestService';
import { PsyTestGroupTestScaleService } from './services/PsyTestGroupTestScaleService';
import { PsyTestGroupTestUserService } from './services/PsyTestGroupTestUserService';
import { PsyTestGroupUserService } from './services/PsyTestGroupUserService';
import { PsyTestResultService } from './services/PsyTestResultService';
import { PsyTestScaleService } from './services/PsyTestScaleService';
import { PsyTestSubscaleService } from './services/PsyTestSubscaleService';
import { QuestionService } from './services/QuestionService';
import { ReportService } from './services/ReportService';
import { ReportModelService } from './services/ReportModelService';
import { RoleMandateNotificationService } from './services/RoleMandateNotificationService';
import { SectionService } from './services/SectionService';
import { SubscaleService } from './services/SubscaleService';
import { SuperscaleService } from './services/SuperscaleService';
import { SupertestService } from './services/SupertestService';
import { SupertestTestService } from './services/SupertestTestService';
import { SystemSettingsService } from './services/SystemSettingsService';
import { TestGroupService } from './services/TestGroupService';
import { TestGroupChartComparisonService } from './services/TestGroupChartComparisonService';
import { TestGroupScorecardTriggerService } from './services/TestGroupScorecardTriggerService';
import { TestGroupTestScaleVisibleService } from './services/TestGroupTestScaleVisibleService';
import { UserService } from './services/UserService';
import { UserConnectionService } from './services/UserConnectionService';
import { UserScoresService } from './services/UserScoresService';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        {
            provide: OpenAPI,
            useValue: {
                BASE: OpenAPI?.BASE ?? '',
                VERSION: OpenAPI?.VERSION ?? '2.1.0',
                WITH_CREDENTIALS: OpenAPI?.WITH_CREDENTIALS ?? false,
                CREDENTIALS: OpenAPI?.CREDENTIALS ?? 'include',
                TOKEN: OpenAPI?.TOKEN,
                USERNAME: OpenAPI?.USERNAME,
                PASSWORD: OpenAPI?.PASSWORD,
                HEADERS: OpenAPI?.HEADERS,
                ENCODE_PATH: OpenAPI?.ENCODE_PATH,
            } as OpenAPIConfig,
        },
        {
            provide: BaseHttpRequest,
            useClass: AngularHttpRequest,
        },
        AreaService,
        AreaIndexService,
        BlossomChartService,
        ChapterService,
        ChartService,
        ChartSubscaleService,
        CompanyService,
        CompnayService,
        ConnectionIndicatorService,
        CustomRangeService,
        DefaultService,
        DepartmentService,
        EmailLogService,
        InviteService,
        LevelService,
        NotificationService,
        PsyEmailService,
        PsyTestService,
        PsyTestGroupService,
        PsyTestGroupChartService,
        PsyTestGroupNotificationService,
        PsyTestGroupReportService,
        PsyTestGroupReportModelService,
        PsyTestGroupSuperscaleService,
        PsyTestGroupSuperTestService,
        PsyTestGroupTestService,
        PsyTestGroupTestScaleService,
        PsyTestGroupTestUserService,
        PsyTestGroupUserService,
        PsyTestResultService,
        PsyTestScaleService,
        PsyTestSubscaleService,
        QuestionService,
        ReportService,
        ReportModelService,
        RoleMandateNotificationService,
        SectionService,
        SubscaleService,
        SuperscaleService,
        SupertestService,
        SupertestTestService,
        SystemSettingsService,
        TestGroupService,
        TestGroupChartComparisonService,
        TestGroupScorecardTriggerService,
        TestGroupTestScaleVisibleService,
        UserService,
        UserConnectionService,
        UserScoresService,
    ]
})
export class ApiV2Client {}

