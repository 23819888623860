<div *ngIf="visible" class="row">
  <div [ngClass]="isSubcales ? 'column' : 'row'">
    <div *ngIf="visible" [ngClass]="isSubcales ? '' : 'side-by-side'" class="image-block">
      <img [src]="src" alt="" class="image" />
    </div>
    <div
      [ngClass]="isSubcales ? '' : 'side-by-side vertical-center'"
      *ngIf="description"
      [innerText]="description"
      class="description"
    ></div>
  </div>
  <div class="column subcales" *ngIf="userId">
    <app-bar-subscales-new
      *ngIf="isSubcales"
      [chartId]="chartId"
      [userId]="userId"
      [testGroupId]="testGroupId"
    ></app-bar-subscales-new>
  </div>
</div>
