import { Injectable } from '@angular/core';

import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class FilterService {
  users: User[];

  constructor() {}

  filterUsersByNameAndDepartmentAndRoleTitle(users: User[], filterValue: string) {
    return users.filter((user) => {
      const originalUser = Object.assign({}, user);
      user.department = user.department ? user.department.toLowerCase() : '';
      user.roleTitle = user.roleTitle ? user.roleTitle.toLowerCase() : '';
      user.firstName = user.firstName ? user.firstName.toLowerCase() : '';
      user.lastName = user.lastName ? user.lastName.toLowerCase() : '';
      const found =
        (user.firstName + ' ' + user.lastName + ' ' + user.department + ' ' + user.roleTitle).indexOf(filterValue) > -1;
      user.firstName = originalUser.firstName;
      user.lastName = originalUser.lastName;
      user.department = originalUser.department;
      user.roleTitle = originalUser.roleTitle;
      return found ? user : found;
    });
  }
}
