import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-test-group-scorecard',
  templateUrl: './test-group-scorecard.component.html',
  styleUrls: ['./test-group-scorecard.component.scss'],
})
export class TestGroupScorecardComponent implements OnInit {
  testGroupId: number;
  scoreCards: any;
  userInfoId: number;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(map(() => window.history.state)).subscribe((response) => {
      this.scoreCards = response.scoreCards;
    });
  }
}
