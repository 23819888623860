import { Pipe, PipeTransform } from '@angular/core';
import { PsbItem } from '@app/core/models/psb-item.model';

@Pipe({ name: 'search' })
export class SearchPipe implements PipeTransform {
  /**
   * Searches for the term by case insensitive
   * @param items
   * @param term
   * @returns the result of the search
   */
  transform(items: PsbItem[], term: string): any {
    return term
      ? items.filter(
          (item: PsbItem) =>
            item.title.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
            (item.email && item.email.toLowerCase().indexOf(term.toLowerCase()) !== -1),
        )
      : items;
  }
}
