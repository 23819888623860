<div class="container">
  <h3>Notifications</h3>
  <div class="notification" [id]="notification.id" *ngFor="let notification of notifications">
    <mat-card>
      <mat-card-content>
        {{ notification.message }}
      </mat-card-content>
    </mat-card>
  </div>
</div>
