import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'app-report-user-score',
  templateUrl: './report-user-score.component.html',
  styleUrls: ['./report-user-score.component.scss'],
})
export class ReportUserScoreComponent implements OnInit {
  @Input() userScore: any;
  @Input() index: number;
  @Input() color: string;
  @Input() isAdmin: boolean;
  @Input() isOA: boolean;
  @Input() isTgm: boolean;
  @Input() allowRawScoresAccess: boolean;
  mode: ProgressBarMode = 'determinate';

  constructor() {}

  ngOnInit(): void {}
}
