<div *ngIf="isSearch">
  <app-psb-table-search [data]="items" (filter)="onSearch($event)" placeholder="Search for {{ itemName }}">
  </app-psb-table-search>
</div>
<mat-table [dataSource]="items" class="mat-elevation-z3" cdkDropList (cdkDropListDropped)="onSort($event)">
  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <div *ngIf="column !== 'actions'; else columnAction">
      <div *ngIf="column !== 'dateAdded'; else columnDateAdded">
        <mat-header-cell *matHeaderCellDef>{{ column.charAt(0).toUpperCase() + column.slice(1) }}</mat-header-cell>
      </div>
      <ng-template #columnDateAdded>
        <mat-header-cell *matHeaderCellDef>Date Added</mat-header-cell>
      </ng-template>
      <div *ngIf="column !== 'status'; else columnStatus">
        <mat-cell *matCellDef="let element" [ngClass]="{ 'hyper-link': isLinkable }" (click)="onNavigateToUrl(element)">
          {{ element[column] }}
        </mat-cell>
      </div>
      <ng-template #columnStatus>
        <mat-cell *matCellDef="let element">
          <mat-chip-list aria-label="Status selection">
            <mat-chip *ngIf="element.status === 'Active'" color="primary" selected> {{ element[column] }} </mat-chip>
            <mat-chip *ngIf="element.status === 'Inactive'" color="warn" selected> {{ element[column] }} </mat-chip>
            <mat-chip *ngIf="element.status === 'STARTED' || 'COMPLETED' || 'NOT_STARTED'" [ngClass]="element.status">{{
              element.status != 'NOT_STARTED' ? element.status : 'NOT STARTED'
            }}</mat-chip>
          </mat-chip-list>
        </mat-cell>
      </ng-template>
    </div>
    <ng-template #columnAction>
      <mat-header-cell mat-header-cell class="action" *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button *ngIf="view.observers.length > 0" mat-button class="btn-view" (click)="onView(element.id)">View</button>
        <button *ngIf="edit.observers.length > 0" mat-button class="btn-edit" (click)="onEdit(element.id)">Edit</button>
        <button
          *ngIf="delete.observers.length > 0 && element.showDelete"
          mat-button
          class="btn-delete"
          (click)="onDelete(element.id)"
        >
          Delete
        </button>
        <button *ngIf="copyEvent.observers.length > 0" mat-button class="btn-copy" (click)="onCopy(element.id)">
          Copy
        </button>
        <button
          mat-button
          *ngIf="delete.observers.length > 0 && actions"
          class="btn-delete"
          (click)="onDeleteByUser(element)"
        >
          Delete
        </button>
      </mat-cell>
    </ng-template>
  </ng-container>
  <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    cdkDrag
    [cdkDragData]="row"
    [cdkDragDisabled]="sort.observers.length === 0"
  ></mat-row>
</mat-table>
<mat-paginator
  #paginator
  [pageSize]="itemsPerPage"
  [pageSizeOptions]="[10, 20, 50]"
  showFirstLastButtons
></mat-paginator>
