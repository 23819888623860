/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { TransferUserWithTestResults } from '../models/TransferUserWithTestResults';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class TransferUserController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public transferUserControllerTransferUserWithTestResults(
        requestBody: TransferUserWithTestResults,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/transfer-user-with-test-results',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public transferUserControllerCopyUserWithTestResults(
        requestBody: TransferUserWithTestResults,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/copy-user-with-test-results',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
