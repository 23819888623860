<div class="grid-container">
  <form [formGroup]="dateForm" class="row">
    <mat-form-field class="column">
      <mat-label>Start</mat-label>
      <input
        matInput
        [matDatepicker]="pickerStart"
        (dateInput)="dateChange('start', $event)"
        (dateChange)="dateChange('start', $event)"
        formControlName="start"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
      <mat-datepicker #pickerStart></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="column">
      <mat-label>End</mat-label>
      <input
        matInput
        [matDatepicker]="pickerEnd"
        (dateInput)="dateChange('end', $event)"
        (dateChange)="dateChange('end', $event)"
        formControlName="end"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
      <mat-datepicker #pickerEnd></mat-datepicker>
    </mat-form-field>
    <button mat-raised-button color="primary" type="button" (click)="onChangeDate()">Refresh</button>
  </form>

  <div class="row">
    <div class="column">
      <mat-card *ngIf="companies">
        <app-stats-company (selectCompany)="onSelectCompany($event)" [companies]="companies"></app-stats-company>
      </mat-card>
      <mat-card *ngIf="testGroups">
        <app-stats-test-group [testGroups]="testGroups"></app-stats-test-group>
      </mat-card>
    </div>

    <div class="column" style="margin-top: 0px">
      <mat-card *ngIf="users">
        <app-stats-user [users]="users"></app-stats-user>
      </mat-card>
    </div>
  </div>
</div>
