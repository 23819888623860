<h1>{{ isEdit ? 'Edit a subscale question group' : 'Create a subscale question group' }}</h1>
<form [formGroup]="subscaleQuestionGroupDialogForm" (ngSubmit)="onSave()">
  <h4>Instructions</h4>
  <angular-editor
    placeholder="Instructions"
    id="instructions"
    formControlName="instructions"
    [config]="editorConfig"
  ></angular-editor>
  <mat-error
    *ngIf="
      subscaleQuestionGroupDialogForm.get('instructions').errors &&
      (subscaleQuestionGroupDialogForm.get('instructions').dirty ||
        subscaleQuestionGroupDialogForm.get('instructions').touched)
    "
  >
    Please, enter a valid instructions.</mat-error
  >
  <mat-form-field>
    <mat-label>Title</mat-label>
    <input matInput type="string" formControlName="title" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Order</mat-label>
    <input matInput type="number" formControlName="aorder" />
  </mat-form-field>
  <div class="button">
    <button mat-button class="btn-success btn-300" type="button" (click)="onCancel()">
      <mat-icon>block</mat-icon>Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit" [disabled]="!subscaleQuestionGroupDialogForm.valid">
      <mat-icon>save_alt</mat-icon>Save
    </button>
  </div>
</form>
