<mat-card>
  <mat-card-header>
    <mat-card-subtitle>
      {{ cardsortAnalysis.question.text }}
    </mat-card-subtitle>
    <mat-chip-list aria-label="question selection">
      <mat-chip color="accent">{{ cardsortAnalysis.question.questionType }}</mat-chip>
    </mat-chip-list>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z3">
      <ng-container matColumnDef="answer">
        <th mat-header-cell *matHeaderCellDef>Answer</th>
        <td mat-cell *matCellDef="let element">{{ element.answer }}</td>
      </ng-container>

      <ng-container matColumnDef="subscale">
        <th mat-header-cell *matHeaderCellDef>Subscale</th>
        <td mat-cell *matCellDef="let element">{{ element.subscale }}</td>
      </ng-container>

      <ng-container matColumnDef="totalAppearance">
        <th mat-header-cell *matHeaderCellDef>Total Appearance</th>
        <td mat-cell *matCellDef="let element">{{ element.totalAppearance }}</td>
      </ng-container>

      <ng-container matColumnDef="topThree">
        <th mat-header-cell *matHeaderCellDef>Top 3</th>
        <td mat-cell *matCellDef="let element">{{ element.topThree }}</td>
      </ng-container>

      <ng-container matColumnDef="thrownOut">
        <th mat-header-cell *matHeaderCellDef>Thrown Out</th>
        <td mat-cell *matCellDef="let element">{{ element.thrownOut }}</td>
      </ng-container>

      <ng-container matColumnDef="importance">
        <th mat-header-cell *matHeaderCellDef>Importance</th>
        <td mat-cell *matCellDef="let element">{{ element.importance }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </mat-card-content>
</mat-card>
