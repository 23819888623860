<h2>Users</h2>
<div>
  <mat-form-field class="user-chip-list">
    <mat-label>Search</mat-label>

    <mat-chip-list #chipList aria-label="User selection">
      <mat-chip *ngFor="let user of selectedUserList" (removed)="remove(user)">
        {{ user.name }}
        <button mat-icon-button matChipRemove>
          <mat-icon><span>cancel</span></mat-icon>
        </button>
      </mat-chip>
      <input
        matInput
        [formControl]="filter"
        (keyup)="applyFilter($event)"
        [matChipInputFor]="chipList"
        placeholder="User name, email or decision to be made"
        #input
      />
    </mat-chip-list>
  </mat-form-field>
</div>

<div class="row">
  <span class="users-selected">
    <b>{{ selection.selected.length }}</b>
    <ng-container *ngIf="selection.selected.length > 1; else singular"> users</ng-container>
    <ng-template #singular> user</ng-template>
    selected
  </span>
</div>
<table mat-table [dataSource]="userInvites" class="mat-elevation-z8" matSort #sortUsers="matSort">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <div #selectAllRef class="selectAllCheckBox" (click)="openPopup(); $event.preventDefault()">
        <mat-checkbox
          color="primary"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
        <mat-icon class="mat-icon">keyboard_arrow_down</mat-icon>
      </div>

      <div *ngIf="showFilterOptions" class="selectAllCheckBox_Panel">
        <div class="selectAllCheckBox_Panel_Inner" *ngFor="let item of options; let i = index">
          <mat-checkbox
            color="primary"
            #optionRef
            [value]="item.id"
            [checked]="checkIdx == i && item.checked ? true : false"
            (change)="optionRef.value ? (item.id = i) : (checkIdx = -1); onSelection(i, item.id, optionRef.checked)"
            class="radio example-margin"
            >{{ item.label }}
          </mat-checkbox>
        </div>
      </div>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        #eachUser
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="onSelectUser(row, eachUser.checked)"
        [checked]="selection.isSelected(row)"
        [aria-label]="checkboxLabel(row)"
        [disabled]="
          row.status == 'COMPLETED' ||
          row.status === currentDisabledType || (currentDisabledType === 'REGISTERED' && row.status.includes('%'))
        "
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
    <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
    <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let element">
      <mat-chip-list aria-label="Status">
        <mat-chip [ngClass]="element.status">{{ element.status }}</mat-chip>
      </mat-chip-list>
    </td>
  </ng-container>

  <ng-container matColumnDef="isNew">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Link</th>
    <td mat-cell *matCellDef="let element">
      <button mat-button type="button" (click)="showLink(element)" *ngIf="element.isNew">View</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4" *ngIf="filter.value">No data matching the filter "{{ input.value }}"</td>
  </tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

<mat-card class="my-5">
  <app-psb-send-email
    *ngIf="testGroupId"
    [testGroupId]="testGroupId"
    [users]="this.selection.selected"
    [filteredEmailTypes]="filteredEmailTypes"
    [companyId]="companyId"
    [psyTestGroup]="testGroup"
  ></app-psb-send-email>
</mat-card>

<mat-accordion class="my-5">
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h2>E-mail logs</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-email-logs [testGroupId]="testGroupId"></app-email-logs>
  </mat-expansion-panel>
</mat-accordion>
