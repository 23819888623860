<form [formGroup]="form" (submit)="formSubmit.emit(form)">
  <mat-grid-list cols="2" rowHeight="4rem" gutterSize="1rem">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>First Name *</mat-label>
        <input matInput type="text" formControlName="firstName" />
        <mat-error *ngIf="form.get('firstName').errors">Please, enter a valid first name.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Last Name *</mat-label>
        <input matInput type="text" formControlName="lastName" />
        <mat-error *ngIf="form.get('lastName').errors">Please, enter a valid last name.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>E-mail *</mat-label>
        <input matInput type="email" formControlName="email" (focusout)="onFocusOutEmail()" />
        <mat-error *ngIf="form.get('email').errors && !form.get('email').hasError('isEmailExist')"
          >Please, enter a valid e-mail.</mat-error
        >
        <mat-error *ngIf="form.get('email').hasError('isEmailExist')">
          This email has already been registered.
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Country</mat-label>
        <input matInput type="text" formControlName="country" [matAutocomplete]="autoCountry" />
        <mat-autocomplete
          #autoCountry="matAutocomplete"
          (optionSelected)="onSelectCountry($event.option.value.id)"
          [displayWith]="displayCountry"
        >
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{ displayCountry(country) }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput type="text" formControlName="state" [matAutocomplete]="autoState" />
        <mat-autocomplete #autoState="matAutocomplete" [displayWith]="displayState">
          <mat-option *ngFor="let state of filteredStates | async" [value]="state">
            {{ displayState(state) }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1"></mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field>
        <mat-label>Gender *</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="FEMALE">Female</mat-option>
          <mat-option value="MALE">Male</mat-option>
          <mat-option value="OTHER">Other</mat-option>
          <mat-option value="PREFER_NOT_TO_SAY">Prefer not to say</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('gender').errors">Please, enter a valid gender.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Phone *</mat-label>
        <input matInput type="text" formControlName="phone" />
        <mat-error *ngIf="form.get('phone').errors">Please, enter a valid phone.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Language</mat-label>
        <mat-select formControlName="language">
          <mat-option></mat-option>
          <mat-option value="en">English</mat-option>
          <mat-option value="pt-br">Portuguese</mat-option>
          <mat-option value="zh-cn">Chinese</mat-option>
          <mat-option value="da">Danish</mat-option>
          <mat-option value="nl">Dutch</mat-option>
          <mat-option value="fr">French</mat-option>
          <mat-option value="de">German</mat-option>
          <mat-option value="es">Spanish</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('language').errors">Please, enter a valid language.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Time Zone</mat-label>
        <input matInput type="text" formControlName="timezone" [matAutocomplete]="autoTimeZone" />
        <mat-autocomplete #autoTimeZone="matAutocomplete" [displayWith]="displayTimeZone">
          <mat-option *ngFor="let timezone of filteredTimeZones | async" [value]="timezone">
            {{ displayTimeZone(timezone) }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1" *ngIf="!isUserUpdated">
      <mat-form-field>
        <mat-label>Client *</mat-label>
        <input matInput type="text" formControlName="client" [matAutocomplete]="autoClient" />
        <mat-autocomplete
          #autoClient="matAutocomplete"
          (optionSelected)="onClientChange($event.option.value.id)"
          [displayWith]="displayCompany"
        >
          <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
            {{ displayCompany(company) }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="form.get('client').errors">Please, enter a valid client.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Department</mat-label>
        <mat-select formControlName="department">
          <mat-option></mat-option>
          <mat-option *ngFor="let department of departments" [value]="department.id">
            {{ department.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('client').errors">Please, enter a valid department.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1" *ngIf="!isUserUpdated">
      <mat-form-field>
        <mat-label>Role</mat-label>
        <mat-select formControlName="authorityName">
          <mat-option value="ROLE_TEST_USER">Test User</mat-option>
          <mat-option value="ROLE_TGM">Test Group Manager</mat-option>
          <mat-option value="ROLE_ORGANIZATIONAL_ADMIN" *ngIf="isLoginUserAdmin || isLoginUserOa"
            >Organizational Admin</mat-option
          >
        </mat-select>
        <mat-error *ngIf="form.get('authorityName').errors">Please, select a valid role.</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1" *ngIf="isLoginUserAdmin && !isUserUpdated">
      <mat-slide-toggle formControlName="privateScores" [checked]="defaultValues?.privateScores || false">
        Private Scores
      </mat-slide-toggle>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1" *ngIf="!isUserUpdated">
      <div>
        <mat-slide-toggle formControlName="active" [checked]="defaultValues?.active || false">
          Active
        </mat-slide-toggle>
        <p
          class="small-text"
          [ngStyle]="{ opacity: form.get('active').value === false || !form.get('active').value ? 1 : 0 }"
        >
          Inactive users will not be able to access their accounts or respond to assessments.
        </p>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="button">
    <button mat-button class="btn-300" type="button" (click)="cancel.emit()">
      <mat-icon>block</mat-icon>
      Cancel
    </button>
    <button mat-button class="btn-success btn-300" type="submit">
      <mat-icon>save_alt</mat-icon>
      Save
    </button>
  </div>
</form>
