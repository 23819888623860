import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../core/guards/auth.guard';
import { PciAclGuard } from '../core/guards/pci-acl.guard';

import { DashboardUserComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { UserNotificationComponent } from './user-notification/user-notification.component';
import { UserReportComponent } from './user-report/user-report.component';
import { TestConnectionsIndicatorInstructionsComponent } from './connections-indicator/test-connections-indicator-instructions/test-connections-indicator-instructions.component';
import { TestConnectionsIndicatorUserInfoComponent } from './connections-indicator/test-connections-indicator-user-info/test-connections-indicator-user-info.component';
import { TestConnectionsIndicatorUserListComponent } from './connections-indicator/test-connections-indicator-user-list/test-connections-indicator-user-list.component';
import { TestConnectionsIndicatorComponent } from './connections-indicator/test-connections-indicator/test-connections-indicator.component';
import { TestReviewComponent } from './connections-indicator/test-review/test-review.component';
import { TestConnectionsIndicatorUserResultComponent } from './connections-indicator/test-connections-indicator-user-result/test-connections-indicator-user-result.component';
import { TestRoleMandateInstructionsComponent } from './role-mandate/test-role-mandate-instructions/test-role-mandate-instructions.component';
import { TestRoleMandateUserInfoComponent } from './role-mandate/test-role-mandate-user-info/test-role-mandate-user-info.component';
import { TestRoleMandateUserListComponent } from './role-mandate/test-role-mandate-user-list/test-role-mandate-user-list.component';
import { TestRoleMandateComponent } from './role-mandate/test-role-mandate/test-role-mandate.component';
import { TestRoleMandateReviewComponent } from './role-mandate/test-role-mandate-review/test-role-mandate-review.component';
import { TestInstructionsComponent } from './test-instructions/test-instructions.component';
import { PsyTestListComponent } from '../admin/pages/tests/psy-test-list/psy-test-list.component';
import { TestViewComponent } from './test-view/test-view.component';
import { TestComponent } from './test/test.component';
import { RoleMandateSearchComponent } from './role-mandate/role-mandate-search/role-mandate-search.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { RoleMandateUserResultComponent } from '../admin/pages/role-mandate/role-mandate-user-result/role-mandate-user-result.component';

const adminRoutes: Routes = [
  { path: 'notifications', component: UserNotificationComponent },
  { path: '', component: DashboardUserComponent },
  { path: 'profile', component: ProfileComponent },
  { path: ':id/test-group/:testGroupId/report/:reportId', component: UserReportComponent },
  {
    path: 'connections-indicator/:id/instructions',
    component: TestConnectionsIndicatorInstructionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'connections-indicator/:id/user-info',
    component: TestConnectionsIndicatorUserInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'connections-indicator/:id/user-list',
    component: TestConnectionsIndicatorUserListComponent,
    canActivate: [AuthGuard],
  },
  { path: 'connections-indicator/:id/answer', component: TestConnectionsIndicatorComponent, canActivate: [AuthGuard] },
  {
    path: 'connections-indicator/:id/answer/page/:page',
    component: TestConnectionsIndicatorComponent,
    canActivate: [AuthGuard],
  },
  { path: 'connections-indicator/:id/review', component: TestReviewComponent, canActivate: [AuthGuard] },
  {
    path: 'connections-indicator/:id/user/:userId/results',
    component: TestConnectionsIndicatorUserResultComponent,
    canActivate: [AuthGuard, PciAclGuard],
  },
  { path: 'role-mandate/:id/instuctions', component: TestRoleMandateInstructionsComponent, canActivate: [AuthGuard] },
  { path: 'role-mandate/:id/user-info', component: TestRoleMandateUserInfoComponent, canActivate: [AuthGuard] },
  { path: 'role-mandate/:id/user-list', component: TestRoleMandateUserListComponent, canActivate: [AuthGuard] },
  { path: 'role-mandate/:id/answer', component: TestRoleMandateComponent, canActivate: [AuthGuard] },
  { path: 'role-mandate/:id/answer/page/:page', component: TestRoleMandateComponent, canActivate: [AuthGuard] },
  { path: 'role-mandate/:id/review', component: TestRoleMandateReviewComponent, canActivate: [AuthGuard] },
  { path: 'role-mandate/:id/search', component: RoleMandateSearchComponent },
  { path: 'role-mandate/:id/search/:phrase', component: SearchResultComponent },
  {
    path: 'test/:psyTestId/test-group/:testGroupId/instructions',
    component: TestInstructionsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'supertest/:superTestId/instructions', component: TestInstructionsComponent, canActivate: [AuthGuard] },
  { path: 'test/:psyTestId/test-group/:testGroupId', component: TestComponent, canActivate: [AuthGuard] },
  {
    path: 'test/:psyTestId/test-group/:testGroupId/continue',
    component: TestComponent,
    canActivate: [AuthGuard],
  },
  { path: 'test/:psyTestId/test-group/:testGroupId/page/:page', component: TestComponent, canActivate: [AuthGuard] },
  { path: 'supertest/:superTestId/page/:page', component: TestComponent, canActivate: [AuthGuard] },
  { path: 'psy-test-list', component: PsyTestListComponent, canActivate: [AuthGuard] },
  { path: 'supertest/:superTestId', component: TestComponent, canActivate: [AuthGuard] },
  {
    path: ':userInfoId/test/:psyTestId/psy-test-results/:testResultId/view',
    component: TestViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'test/:superTestId/psy-super-test-results/:superTestResultId/view',
    component: TestViewComponent,
    canActivate: [AuthGuard],
  },
  { path: ':id/test-group/:testGroupId/report/:reportId', component: UserReportComponent },
  { path: 'role-mandate/:id/user/:userInfoId/results', component: RoleMandateUserResultComponent },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
