/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { ReportModel } from '../models/ReportModel';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ReportModelService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all report model by id
     * Retrieve all report model by id
     * @param id Id of the test group
     * @returns ReportModel A list of report models
     * @throws ApiError
     */
    public getTestGroupReportModel(
        id: number,
    ): Observable<ReportModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/report-model',
            path: {
                'id': id,
            },
            errors: {
                404: `a list of report models not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all report models
     * Retrieve a list with all report models
     * @returns ReportModel A list of report models
     * @throws ApiError
     */
    public getReportModel(): Observable<Array<ReportModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report-model',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a report model
     * Create a report model
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postReportModel(
        requestBody?: {
            /**
             * Title of the report model
             */
            title: string;
            /**
             * Disclosure of the report model
             */
            disclosure?: string;
            /**
             * Text Color of the report model
             */
            textColor?: string;
            /**
             * Title Color of the report model
             */
            titleColor?: string;
            /**
             * Top Bar Color of the report model
             */
            topBarColor?: string;
            /**
             * Side Bar Color of the report model
             */
            sideBarColor?: string;
            /**
             * Picture of the report model
             */
            picture?: string;
            /**
             * Picture content type of the report model
             */
            pictureContentType?: string;
            /**
             * Signature title of the report model
             */
            signatureTitle?: string;
            /**
             * Signature logo of the report model
             */
            signatureLogo?: string;
            /**
             * Signature logo content type of the report model
             */
            signatureLogoContentType?: string;
            /**
             * Signature text of the report model
             */
            signatureText?: string;
            /**
             * If of the psy test group report model
             */
            psyTestGroupReportModelId?: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report-model',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a report model
     * Update a report model
     * @param id
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchReportModel(
        id: number,
        requestBody?: {
            /**
             * Title of the report model
             */
            title: string;
            /**
             * Disclosure of the report model
             */
            disclosure?: string;
            /**
             * Text Color of the report model
             */
            textColor?: string;
            /**
             * Title Color of the report model
             */
            titleColor?: string;
            /**
             * Top Bar Color of the report model
             */
            topBarColor?: string;
            /**
             * Side Bar Color of the report model
             */
            sideBarColor?: string;
            /**
             * Picture of the report model
             */
            picture?: string;
            /**
             * Picture content type of the report model
             */
            pictureContentType?: string;
            /**
             * Signature title of the report model
             */
            signatureTitle?: string;
            /**
             * Signature logo of the report model
             */
            signatureLogo?: string;
            /**
             * Signature logo content type of the report model
             */
            signatureLogoContentType?: string;
            /**
             * Signature text of the report model
             */
            signatureText?: string;
            /**
             * If of the psy test group report model
             */
            psyTestGroupReportModelId?: string;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report-model',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a report model by id
     * Retrieve a report model by id
     * @param id Id of the report model
     * @returns ReportModel A report model
     * @throws ApiError
     */
    public getReportModel1(
        id: number,
    ): Observable<ReportModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report-model/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Report Model not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a report model
     * Delete a report model
     * @param id Id of the report model
     * @returns any Report deleted successfully
     * @throws ApiError
     */
    public deleteReportModel(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/report-model/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Report model not found`,
                500: `Internal error`,
            },
        });
    }

}
