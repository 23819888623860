import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscaleBreadcrumbService {
  public navigationLinks: { title: string; url: string; currentTab?: string }[] = [];
  updatedBreadScrum = new BehaviorSubject(null);
  currentNavigationLink = new BehaviorSubject(null);

  public subScaleNav = {
    subScale: { url: '/subscale' },
    details: { url: '/subscale/{subScaleId}/detail' },
    questions: { url: '/subscale/{subScaleId}/question' },
    question_details: { url: '/subscale/{subScaleId}/question/{questionId}/detail' },
    answers: { url: '/subscale/{subScaleId}/question/{questionId}/detail' },
    answer_details: { url: '/subscale/{subScaleId}/question/{questionId}/answer/{answerId}/detail' },
  };

  constructor() {}

  setBreadCrumbNavigation(pageName: string, params: any) {
    switch (pageName) {
      case 'subScale_Details':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;
      case 'question':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Question',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'Question',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;

      case 'question_Details':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Question',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'Question',
        });
        this.navigationLinks.push({
          title: `${params.questionTitle}`,
          url: `/subscale/${params.subScaleId}/question/${params.questionId}/detail`,
          currentTab: 'QuestionName',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;

      case 'answer':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Question',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'Question',
        });
        this.navigationLinks.push({
          title: `${params.questionTitle}`,
          url: `/subscale/${params.subScaleId}/question/${params.questionId}/detail`,
          currentTab: 'QuestionName',
        });
        this.navigationLinks.push({
          title: 'Answers',
          url: `/subscale/${params.subScaleId}/question/${params.questionId}/detail`,
          currentTab: 'Answers',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;

      case 'answer_Details':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Question',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'Question',
        });
        this.navigationLinks.push({
          title: `${params.questionTitle}`,
          url: `/subscale/${params.subScaleId}/question/${params.questionId}/detail`,
          currentTab: 'QuestionName',
        });
        this.navigationLinks.push({
          title: 'Answers',
          url: `/subscale/${params.subScaleId}/question/${params.questionId}/detail`,
          currentTab: 'Answers',
        });
        this.navigationLinks.push({
          title: params.answerTitle,
          url: `/subscale/${params.subScaleId}/question/${params.questionId}/answer/${params.answerId}/detail`,
          currentTab: 'AnswerName',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;

      case 'question_group':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Question Group',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'question group',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;

      case 'question_group_details':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Question Group',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'question group',
        });
        this.navigationLinks.push({
          title: params.questionGroupTitle,
          url: `/subscale/${params.subScaleId}/question/group/${params.questionGroupId}/detail`,
          currentTab: 'question group detail',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;

      case 'score_percentiles':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Score Percentile',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'score percentiles',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;

      case 'related_assessments':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Related Assessments',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'related assessments',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;

      case 'related_subscales':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Related Subscales',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'related subscales',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;

      case 'preview':
        this.navigationLinks = [];
        this.navigationLinks.push({
          title: 'Subscale',
          url: this.subScaleNav.subScale.url,
          currentTab: 'subscaleList',
        });
        this.navigationLinks.push({
          title: params.subScaleTitle,
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'subscaleName',
        });
        this.navigationLinks.push({
          title: 'Preview',
          url: this.subScaleNav.details.url.replace('{subScaleId}', params.subScaleId),
          currentTab: 'Preview',
        });
        this.updatedBreadScrum.next(this.navigationLinks);
        break;
    }
  }
}
