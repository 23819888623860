<h1>{{ ordinalParagraph.heading }}</h1>

<h2 class="top-subscales-heading">TOP Subscales</h2>
<p class="lead-sentence" [innerHtml]="ordinalParagraph.leadSentences.top.he"></p>
<h3>Number of TOP subscales</h3>
<p>{{ ordinalParagraph.totals.top }}</p>
<div class="sentences-list">
  <div class="sentences-item" *ngFor="let sentence of ordinalParagraph.texts.top; let i = index">
    <div class="subscales">
      <table>
        <colgroup>
          <col />
          <col class="num-col" />
        </colgroup>
        <thead>
          <tr>
            <th>Subscales {{ i + 1 }}</th>
            <th>Weight</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let subscale of sentence.subscales">
            <td>{{ subscale.title }}</td>
            <td>{{ subscale.weight }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p [innerHtml]="sentence.he"></p>
  </div>
</div>

<h2 class="bottom-subscales-heading">BOTTOM Subscales</h2>
<p class="lead-sentence" [innerHtml]="ordinalParagraph.leadSentences.bottom?.he"></p>
<h3>Number of BOTTOM subscales</h3>
<p>{{ ordinalParagraph.totals.bottom }}</p>
<div class="sentences-list">
  <div class="sentences-item" *ngFor="let sentence of ordinalParagraph.texts.bottom; let i = index">
    <div class="subscales">
      <table>
        <colgroup>
          <col />
          <col class="num-col" />
        </colgroup>
        <thead>
          <tr>
            <th>Subscales {{ i + 1 }}</th>
            <th>Weight</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let subscale of sentence.subscales">
            <td>{{ subscale.title }}</td>
            <td>{{ subscale.weight }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p [innerHtml]="sentence.he"></p>
  </div>
</div>
