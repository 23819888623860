/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class HealthCheckController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public healthCheckControllerHealthCheck(): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/health-check',
        });
    }

}
