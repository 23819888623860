import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { CreateNewAreaDialogComponent } from './../create-new-area-dialog/create-new-area-dialog.component';

// services
import { AreaIndexService } from '@app/core/services/area-index.service';

// model
import { AreaIndex } from '@app/core/models/area-index.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-area-mapping-details',
  templateUrl: './area-mapping-details.component.html',
  styleUrls: ['./area-mapping-details.component.scss'],
})
export class AreaMappingDetailsComponent implements OnInit {
  areaIndex: AreaIndex;
  areaIndexId: number;

  tabs = ['Details'];
  tabSelected = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private areaIndexService: AreaIndexService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.tabSelected = params.id ? +params.tab : 0;
    });

    const areaIndexId = this.activatedRoute.snapshot.params.id;
    if (Number.isNaN(areaIndexId)) {
      this.router.navigate(['/']);
      return;
    }

    this.areaIndexId = Number(areaIndexId);
    this.getAreaIndexById();
  }

  onEdit() {
    this.dialog
      .open(CreateNewAreaDialogComponent, { data: { isEdit: true, areaIndex: this.areaIndex } })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          this.areaIndexService.update(this.areaIndexId, result.data).subscribe((res) => {
            if (res) {
              this.getAreaIndexById();
            }
          });
        }
      });
  }

  getAreaIndexById() {
    this.areaIndexService.getById(this.areaIndexId).subscribe(
      (areaIndex) => {
        this.areaIndex = areaIndex;
      },
      (err) => {
        console.error(err);
        this.router.navigate(['/']);
      },
    );
  }

  tabChange(idx: any) {
    this.tabSelected = Number(idx);
  }
}
