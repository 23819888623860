import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// services
import { RoleMandateService } from '@app/core/services/role-mandate.service';

// models
import { RoleMandate } from '@app/core/models/role-mandate.model';

@Component({
  selector: 'app-test-role-mandate-instructions',
  templateUrl: './test-role-mandate-instructions.component.html',
  styleUrls: ['./test-role-mandate-instructions.component.scss'],
})
export class TestRoleMandateInstructionsComponent implements OnInit {
  roleMandate: RoleMandate;
  roleMandateId: number;

  constructor(private router: Router, private rmService: RoleMandateService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.roleMandateId = this.route.snapshot.params['id'];

    this.rmService.getById(this.roleMandateId).subscribe((data) => {
      this.roleMandate = data;
    });
  }

  onStart() {
    this.router.navigate([`/user/role-mandate/${this.roleMandateId}/user-info`]);
  }
}
