import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HandleCsvService {
  constructor() {}

  readCsvByEvent($event: Event) {
    return new Promise((resolve) => {
      const file = ($event.target as HTMLInputElement).files[0];
      const reader = new FileReader();
      let lines = [];
      reader.readAsText(file);
      reader.onload = (event) => {
        const csv = event.target.result;
        lines = this.extractData(csv as string);
        resolve(lines);
      };
    });
  }

  extractData(csvData: string) {
    const allTextLines = csvData.split(/\r\n|\n/);
    const headers = allTextLines[0].split(',');
    const lines = [];

    for (const textLine of allTextLines) {
      const data = textLine.split(',');
      if (data.length === headers.length) {
        const line = [];
        for (let j = 0; j < headers.length; j++) {
          line.push(data[j]);
        }
        lines.push(line);
      }
    }
    return lines;
  }
}
