import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RoleMandate } from '../models/role-mandate.model';
import { RoleMandatePosition } from '../models/role-mandate-position.model';
import { Question } from '../models/question.model';
import { User } from '../models/user.model';
import { UserConnection } from '../models/user-connection.model';
import { DecisionTable } from '../models/decision-table.model';
import { Node } from '../models/node.model';
import { Link } from '../models/link.model';
import { UserInfo } from '../models/userInfo.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class RoleMandateService {
  private loaderListener = new Subject<boolean>();
  private loaderListenerValue: boolean;
  private roleMandateQuestionListener = new Subject<Question>();

  constructor(private http: HttpClient) {}

  getLoaderListener() {
    return this.loaderListener.asObservable();
  }

  setLoaderListener(value: boolean) {
    this.loaderListenerValue = value;
    this.loaderListener.next(value);
  }

  getLoaderListenerValue() {
    return this.loaderListenerValue;
  }

  save(roleMandate: RoleMandate) {
    return this.http.post<{ message: string; id: string }>(BACKEND_URL + '/role-mandate', roleMandate);
  }

  update(roleMandate: RoleMandate) {
    return this.http.put(BACKEND_URL + '/role-mandate', roleMandate);
  }

  delete(roleMandateId: number) {
    return this.http.delete(BACKEND_URL + '/role-mandate/' + roleMandateId);
  }

  get() {
    return this.http.get<RoleMandate[]>(BACKEND_URL + '/role-mandate');
  }

  getCompleteById(roleMandateId: number) {
    return this.http.get<RoleMandate>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/complete');
  }

  getById(roleMandateId: number) {
    return this.http
      .get<RoleMandate>(BACKEND_URL + '/role-mandate/' + roleMandateId)
      .pipe(catchError(() => of(undefined)));
  }

  getAllTitleByCompanyId(companyId: number) {
    return this.http.get<RoleMandate[]>(BACKEND_URL + '/role-mandate/' + companyId);
  }

  getAllQuestionById(roleMandateId: number) {
    return this.http
      .get<Question[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/question')
      .pipe(catchError(() => of(undefined)));
  }

  saveQuestion(roleMandateId: number, question: Question) {
    return this.http.post(BACKEND_URL + '/role-mandate/' + roleMandateId + '/question', question);
  }

  saveDecisionTable(roleMandateId: number, decisionTable: DecisionTable) {
    return this.http.post<{ id: number }>(
      BACKEND_URL + '/role-mandate/' + roleMandateId + '/decision-table',
      decisionTable,
    );
  }

  getAllDecisionTableById(roleMandateId: number) {
    return this.http
      .get<DecisionTable[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/decision-table')
      .pipe(catchError(() => of([])));
  }

  saveRoleMandateUser(roleMandateId: number, usersSelected: User[]) {
    return this.http.post(BACKEND_URL + '/role-mandate/' + roleMandateId + '/user', usersSelected);
  }

  getAllUsers(roleMandateId: number) {
    return this.http.get<User[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/user');
  }

  deleteRoleMandateUser(roleMandateId: number, userInfoId: number) {
    return this.http.delete(BACKEND_URL + '/role-mandate/' + roleMandateId + '/user/' + userInfoId);
  }

  getRoleMandateResults(roleMandateId: number, userInfoId: number) {
    return this.http.get<any>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/user/' + userInfoId + '/results');
  }

  saveRoleMandatePosition(roleMandatePosition: RoleMandatePosition) {
    return this.http.post(BACKEND_URL + '/role-mandate/position', roleMandatePosition);
  }

  deleteRoleMandatePosition(roleMandatePosition: RoleMandatePosition) {
    return this.http.delete(BACKEND_URL + '/role-mandate/position/' + roleMandatePosition.id);
  }

  getRoleMandatePositions(roleMandateId: number) {
    return this.http.get<RoleMandatePosition[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/position');
  }

  saveUserConnection(roleMandateId: number, userConnection: UserConnection) {
    return this.http.post(BACKEND_URL + '/role-mandate/' + roleMandateId + '/user-connection', userConnection);
  }

  getToUserConnectionByQuestionId(roleMandateId: number, questionId: number, userInfoId: number) {
    return this.http.get<UserConnection[]>(
      BACKEND_URL +
        '/role-mandate/' +
        roleMandateId +
        '/question/' +
        questionId +
        '/user/' +
        userInfoId +
        '/connection',
    );
  }

  getNetworkAnalysisByRoleMandate(roleMandateId: number) {
    return this.http.get<any[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/analysis');
  }

  getUnaForExpertAndReferentByByRoleMandate(roleMandateId: number) {
    return this.http.get<any[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/analysis-expert-referent');
  }

  getNetworkAnalysisByQuestionId(roleMandateId: number, questionId: number) {
    return this.http.get<any[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/analysis-by-question/' + questionId);
  }

  getNetworkAnalysisByDecisionTableId(roleMandateId: number, decisionTableId: number) {
    return this.http.get<any[]>(
      BACKEND_URL + '/role-mandate/' + roleMandateId + '/analysis-by-decision-table/' + decisionTableId,
    );
  }

  getAllNode(roleMandateId: number) {
    return this.http.get<Node[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/node');
  }

  getAllLinkByQuestionId(roleMandateId: number, questionId: number) {
    return this.http.get<Link[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/link/question/' + questionId);
  }

  getAllLinkByDecisionTableId(roleMandateId: number, decisionTableId: number) {
    return this.http.get<Link[]>(
      BACKEND_URL + '/role-mandate/' + roleMandateId + '/link/decision-table/' + decisionTableId,
    );
  }

  getAllLinkByType(roleMandateId: number, type: string) {
    return this.http.get<Link[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/link/' + type);
  }

  getAllLinks(roleMandateId: number) {
    return this.http.get<Link[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/link');
  }

  getAllByUserInfoId(roleMandateId: number, userInfoId: number) {
    return this.http.get(BACKEND_URL + `/role-mandate/find-all-title/${roleMandateId}/user/${userInfoId}`);
  }

  completeRoleMandate(roleMandateId: number, userInfoId: number) {
    return this.http.patch(BACKEND_URL + '/role-mandate/' + roleMandateId + '/user/' + userInfoId + '/complete', '');
  }

  updateActive(roleMandateId: number) {
    return this.http.patch(BACKEND_URL + '/role-mandate/' + roleMandateId + '/active', true);
  }

  deleteRoleMandateDecisionTable(decisionTableId: number) {
    return this.http.delete(BACKEND_URL + '/role-mandate/decision-table/' + decisionTableId);
  }

  getUsersComplete(roleMandateId: number) {
    return this.http.get<UserInfo[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/user-complete');
  }

  updateRoleMandatePositions(roleMandateId: number, roleMandatePositions: RoleMandatePosition[]) {
    return this.http.patch(BACKEND_URL + '/role-mandate/' + roleMandateId + '/position', roleMandatePositions);
  }

  deleteUserConnection(roleMandateId: number, userConnectionId: number) {
    return this.http.delete(BACKEND_URL + '/role-mandate/' + roleMandateId + '/user-connection/' + userConnectionId);
  }

  getAllTasks(roleMandateId: number) {
    return this.http.get<any[]>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/tasks');
  }

  downloadDecisionsFile(roleMandateId: number, fileName: string) {
    return this.http.get<{ fileUrl: string }>(BACKEND_URL + '/role-mandate/' + roleMandateId + '/decisions-file');
  }

  updateAcl(connectionIndicatorId: number, acl: number) {
    return this.http.patch(BACKEND_URL + '/role-mandate/' + connectionIndicatorId + '/acl', { acl });
  }

  migrate(roleMandateId: number, data: { sourceUserId: number; targetUserId: number }) {
    return this.http.post(`${BACKEND_URL}/role-mandate/${roleMandateId}/migrate-user-answers`, data).pipe(take(1));
  }

  setRoleMandate(f: Question) {
    this.roleMandateQuestionListener.next(f);
  }

  getQuestionRoleMandate() {
    return this.roleMandateQuestionListener.asObservable();
  }
}
