<mat-card>
  <form [formGroup]="newDecisionTableForm" #formDirective="ngForm" (ngSubmit)="addDecisionTable(formDirective)">
    <h3>New Table</h3>
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" />
      <mat-error *ngIf="newDecisionTableForm.get('title').errors">Please, enter a valid text.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Instructions</mat-label>
      <textarea matInput type="text" formControlName="instructions"></textarea>
      <mat-error *ngIf="newDecisionTableForm.get('instructions').errors">Please, enter a valid text.</mat-error>
    </mat-form-field>

    <div id="privacy" class="privacy">
      <mat-label>Table Privacy</mat-label>
      <section *ngFor="let privacyMessage of privacyMessages">
        <mat-checkbox
          color="primary"
          [checked]="acl === privacyMessage.id"
          (change)="onChangeACL($event, privacyMessage)"
          >{{ privacyMessage.message }}</mat-checkbox
        >
      </section>
    </div>

    <mat-form-field>
      <mat-label>Network Type</mat-label>
      <mat-select formControlName="networkType">
        <mat-option></mat-option>
        <mat-option value="EXPERT">Expert</mat-option>
        <mat-option value="REFERENT">Referent</mat-option>
      </mat-select>
      <mat-hint>Definition if the network is technical or social.</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Collaboration Type</mat-label>
      <mat-select [formControl]="newDecisionTableForm.get('collaborationType')">
        <mat-option *ngFor="let collaborationType of collaborationTypes" value="{{ collaborationType.id }}">
          {{ collaborationType.title }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="newDecisionTableForm.get('collaborationType').hasError('required')">
        You must make a selection
      </mat-error>
      <mat-hint>Type that the collabotion between the users.</mat-hint>
    </mat-form-field>

    <form
      [formGroup]="newDecisionTableColumnForm"
      #ngNewDecisionTableColumnForm="ngForm"
      (ngSubmit)="addColumn(ngNewDecisionTableColumnForm)"
    >
      <h3>Columns</h3>
      <p>Create all the columns to the decision table.</p>
      <table *ngIf="decisionTableColumns.length > 0" class="table-columns mat-elevation-z3">
        <thead>
          <tr>
            <td *ngFor="let decisionTableColumn of decisionTableColumns">
              {{ decisionTableColumn.title }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td *ngFor="let decisionTableColumn of decisionTableColumns">
              {{ printColumnTypeName(+decisionTableColumn.type) }}
              <i *ngIf="decisionTableColumn.required"> [Required]</i>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-form-field>
        <mat-label>Column Title</mat-label>
        <input matInput type="text" formControlName="title" />
        <mat-error *ngIf="newDecisionTableColumnForm.get('title').hasError('required')"
          >Please, enter a valid text.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Column Type</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let decisionTableColumnType of decisionTableColumnTypes"
            value="{{ decisionTableColumnType.id }}"
            >{{ decisionTableColumnType.name }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="newDecisionTableColumnForm.get('type').hasError('required')"
          >You must make a selection</mat-error
        >
      </mat-form-field>

      <mat-slide-toggle color="primary" formControlName="required">Required </mat-slide-toggle>

      <button mat-raised-button type="submit" color="primary" class="btn-primary btn-center btn-300">Add Column</button>
    </form>
    <button mat-raised-button type="submit" class="btn-success btn-center btn-300">Save</button>
  </form>
</mat-card>
