<mat-spinner *ngIf="isLoading"></mat-spinner>

<div *ngIf="!isLoading" class="container">
  <h1>Welcome {{ userData.firstName }}</h1>

  <!--<h2>Security Options</h2>
  <button mat-raised-button color="primary" (click)="openMFAEnrollmentModal()">Enroll in MFA</button>-->
  <div class="user-details" *ngIf="this.defaultValues && this.timeZones && this.companies &&  this.countries">
    <app-user-details-form
      [defaultValues]="this.defaultValues"
      [timeZones]="this.timeZones"
      [companies]="this.companies"
      [countries]="this.countries"
      [isUserUpdated]="true"
      (formSubmit)="onFormSubmit($event)"
      (cancel)="onCancel()"
    ></app-user-details-form>
  </div>
</div>
