import { Component, Input, OnInit } from '@angular/core';

// services
import { HtmlCleanService } from '@app/core/services/html-cleam.service';

// models
import { Paragraph } from '@app/core/models/paragraph.model';

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
})
export class ParagraphComponent implements OnInit {
  @Input() model: Paragraph;
  paragraphText: string;

  constructor(private htmlCleanService: HtmlCleanService) {}

  ngOnInit(): void {
    this.paragraphText =
      (this.model.leadSentence.text ? this.model.leadSentence.text : '') +
      ' ' +
      this.model.sentences.map((sentence) => sentence.text).join(' ');
  }
}
