import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PsyTestGroupTestUser } from '../models/psy-test-group-test-user.model';
import { SuperTest } from '../models/supertest.model';
import { PsyTest } from '../models/psy-test.model';
import { Question } from '../models/question.model';
import { Subscale } from '../models/subscale.model';
import { UserScores } from '../models/user-scores.model';
import { PsyTestGroupTest } from '../models/psy-test-group-test.model';
import { SuperTestTest } from '../models/supertest-test.model';
import { PsyTestResult } from '../models/psy-test-result.model';
import { PsyTestSubscale } from '@app/core/models/psy-test-subscale.model';
import { CustomQuestionOrder } from '@app/core/models/custom-question-order.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class TestService {
  private unAnswerQuestionIdsListener = new Subject<number[]>();
  private unAnswerCardsortAndSliderQuestionIdsListener = new Subject<number[]>();
  private progressListener = new Subject<number>();
  private unAnswerQuestionsInCurrentPage = new Subject<number[]>();
  constructor(private http: HttpClient) {}

  getActualUserTests(): Observable<Array<PsyTestGroupTestUser>> {
    return this.http.get<Array<PsyTestGroupTestUser>>(BACKEND_URL + '/psy-test-group-test-by-user/');
  }

  getActualUserSuperTests(): Observable<Array<SuperTest>> {
    return this.http.get<Array<SuperTest>>(BACKEND_URL + '/supertest-by-user/');
  }

  getPsyTestById(psyTestId: number) {
    return this.http.get<PsyTest>(BACKEND_URL + '/test/' + psyTestId).pipe(
      map((response) => {
        if (response) {
          response.customQuestionOrder = Number(response.customQuestionOrder) === 0 ? false : true;
          response.showCardSortInstructions = Number(response.showCardSortInstructions) === 0 ? false : true;
          response.discardAll = Number(response.discardAll) === 0 ? false : true;
          return response;
        }
      }),
    );
  }

  getPsyTestByIdAndTestGroupIdAndUserInfoId(id: number, testGroupId: number, userInfoId: number) {
    return this.http
      .get<PsyTest>(`${BACKEND_URL}/test/${id}/test-group/${testGroupId}/user-info/${userInfoId}`)
      .pipe(take(1));
  }

  start(id: number, testGroupId: number, userInfoId: number) {
    return this.http.post<any>(`${BACKEND_URL}/test/start`, { id, testGroupId, userInfoId }).pipe(take(1));
  }

  complete(id: number, testGroupId: number, userInfoId: number) {
    return this.http.post<any>(`${BACKEND_URL}/test/complete`, { id, testGroupId, userInfoId }).pipe(take(1));
  }

  getSupertestTestById(psyTestId: number) {
    return this.http.get<SuperTestTest[]>(BACKEND_URL + '/test/' + psyTestId + '/supertest-test');
  }

  getUserScoresById(psyTestId: number) {
    return this.http.get<UserScores[]>(BACKEND_URL + '/test/' + psyTestId + '/user-scores');
  }

  getPsyTestGroupTestById(psyTestId: number) {
    return this.http.get<PsyTestGroupTest[]>(BACKEND_URL + '/test/' + psyTestId + '/psy-test-group-test');
  }

  getPsyTestByCompanyId(companyId: number) {
    return this.http.get<PsyTest[]>(BACKEND_URL + '/test/psy-test/' + companyId + '/company');
  }

  getSuperTestById(superTestId: number) {
    return this.http.get<SuperTest>(BACKEND_URL + '/supertest/' + superTestId);
  }

  getAllQuestionByPsyTestId(psyTestId: number, requestFromUserInfoId?: number, testGroupId?: number) {
    const params = [];

    if (testGroupId) {
      params.push({
        key: 'testGroupId',
        value: testGroupId,
      });
    }

    if (requestFromUserInfoId) {
      params.push({
        key: 'requestFromUserInfoId',
        value: requestFromUserInfoId,
      });
    }

    let url = `${BACKEND_URL}/test/psy-test/${psyTestId}/question`;

    if (params.length > 0) {
      url += '?' + params.map((param) => param.key + '=' + param.value).join('&');
    }
    return this.http.get<Question[]>(url);
  }

  getAllQuestionBySuperTestId(superTestId: number) {
    return this.http.get<Question[]>(BACKEND_URL + '/supertest/' + superTestId + '/question');
  }

  getAllPsyTest() {
    return this.http.get<PsyTest[]>(BACKEND_URL + '/test');
  }

  getAllSuperTest() {
    return this.http.get<SuperTest[]>(BACKEND_URL + '/supertest');
  }

  getAllSubscale(psyTestId: number) {
    return this.http.get<Subscale[]>(BACKEND_URL + '/test/' + psyTestId + '/all-subscale');
  }

  createPsyTest(psyTest: PsyTest) {
    return this.http.post(BACKEND_URL + '/test', psyTest);
  }

  updatePsyTest(psyTestId: number, psyTest: PsyTest) {
    return this.http.patch(BACKEND_URL + '/test/' + psyTestId, { psyTest });
  }

  savePsyTestSubscale(psyTestId: number, id: number, parentId: number) {
    return this.http.post(BACKEND_URL + '/test/' + psyTestId + '/psy-test-subscale', {
      subscaleId: id,
      parentSubscale: parentId,
    });
  }

  saveAllPsyTestSubscale(psyTestId: number, psyTestSubscales: PsyTestSubscale[]) {
    return this.http.post(BACKEND_URL + '/test/' + psyTestId + '/all-psy-test-subscale', {
      psyTestSubscales,
    });
  }

  saveSupertestTest(psyTestId: number, supertestTest: SuperTestTest) {
    return this.http.post(BACKEND_URL + '/test/' + psyTestId + '/supertest-test', supertestTest);
  }

  deleteSuperTestTest(superTestTestId: number) {
    return this.http.delete(BACKEND_URL + `/test/supertest-test/${superTestTestId}`);
  }

  saveUserScores(psyTestId: number, userScores: UserScores) {
    return this.http.post(BACKEND_URL + '/test/' + psyTestId + '/user-scores', userScores);
  }

  savePsyTestGroupTest(psyTestId: number, psyTestGroupTest: any) {
    return this.http.post(BACKEND_URL + '/test/' + psyTestId + '/psy-test-group-test', psyTestGroupTest);
  }

  deletePsyTestSubscale(psyTestId: number, subscaleId: number) {
    return this.http.delete(BACKEND_URL + '/test/' + psyTestId + '/subscale/' + subscaleId);
  }

  deletePsyTest(testId: number) {
    return this.http.delete(BACKEND_URL + '/test/' + testId);
  }

  deleteUserAnswers(id: number, testGroupId: number, userInfoId: number) {
    return this.http
      .delete<any>(`${BACKEND_URL}/test/${id}/test-group/${testGroupId}/user/${userInfoId}/answers`)
      .pipe(take(1));
  }

  getAllUserCardSortAnswersByTestResultId(testResultId: number) {
    return this.http.get(`${BACKEND_URL}/test/result/${testResultId}/card-sort`).pipe(take(1));
  }

  getTestResultByTestIdAndTestGroupIdAndUserInfoId(id: number, testGroupId: number, userInfoId: number) {
    return this.http
      .get<PsyTestResult>(`${BACKEND_URL}/test/${id}/test-group/${testGroupId}/user/${userInfoId}/test-result`)
      .pipe(take(1));
  }

  saveQuestionSpentTime(testResultId: number, spendTimeForTestQuestion: any) {
    return this.http.post(
      BACKEND_URL + '/test/result/' + testResultId + '/question-spent-time',
      spendTimeForTestQuestion,
    );
  }

  getCustomQuestionsOrderById(id: number) {
    return this.http.get<CustomQuestionOrder[]>(`${BACKEND_URL}/test/${id}/questions-order`);
  }

  saveCustomQuestionsOrderById(id: number, customQuestionsOrder: CustomQuestionOrder[]) {
    return this.http.post(`${BACKEND_URL}/test/${id}/custom-question-order`, customQuestionsOrder);
  }

  applyCustomOrder(questions: Question[], customQuestionsOrder: CustomQuestionOrder[]): Question[] {
    return questions.map((question: Question) => {
      const customQuestionOrder = customQuestionsOrder.find(
        (customQuestionOrder: CustomQuestionOrder) => customQuestionOrder.questionId === question.id,
      );
      if (customQuestionOrder) {
        question.aorder = customQuestionOrder.order;
        question.questionNumber = customQuestionOrder.order;
      }
      return question;
    });
  }

  copy(id: number) {
    return this.http.get<PsyTest>(`${BACKEND_URL}/test/${id}/copy-test`).pipe(take(1));
  }

  setUnAnswerQuestionIds(value: number[]) {
    this.unAnswerQuestionIdsListener.next(value);
  }

  getUnAnswerQuestionIds() {
    return this.unAnswerQuestionIdsListener.asObservable().pipe(take(1));
  }

  setProgress(value: number) {
    this.progressListener.next(value);
  }

  getProgress() {
    return this.progressListener.asObservable();
  }

  setUnAnswerQuestionsInCurrentPage(value: number[]) {
    this.unAnswerQuestionsInCurrentPage.next(value);
  }

  getUnAnswerQuestionsInCurrentPage() {
    return this.unAnswerQuestionsInCurrentPage.asObservable();
  }
}
