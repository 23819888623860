<h2>Enroll in Multi Factor Authentication</h2>
<h3>{{ currentStatus }}</h3>
<mat-tab-group>
  <mat-tab label="Phone">
    <h4>Hit send and we'll send you a verification text. Click the link in that text to enroll in text-based MFA.</h4>

    <form class="cell-form" (submit)="sendText()" [formGroup]="cellForm">
      <mat-form-field class="full-width">
        <mat-label>MFA Phone Number</mat-label>
        <input matInput type="tel" formControlName="cellNumber" />
      </mat-form-field>
      <button mat-raised-button type="submit" color="primary">Send Text</button>
    </form>
  </mat-tab>
  <mat-tab label="Email">
    <h4>Hit send and we'll send you a verification email. Click the link in that text to enroll in email-based MFA.</h4>

    <form class="email-form" (submit)="sendEmail()" [formGroup]="emailForm">
      <mat-form-field class="full-width">
        <mat-label>MFA Email Address</mat-label>
        <input matInput type="email" formControlName="emailAddress" />
      </mat-form-field>
      <button mat-raised-button type="submit" color="primary">Send Email</button>
    </form>
  </mat-tab>
  <mat-tab label="App">
    <h4>
      Scan this with your phone app. Generate a one-time-passcode in the app and enter it below to enroll in app-based
      MFA.
    </h4>
    <img [src]="qrCode" class="qr-code full-width" />
    <form class="verify-form" (submit)="verifyCode()" [formGroup]="verifyForm">
      <mat-form-field class="full-width">
        <mat-label>Verification Code</mat-label>
        <input matInput formControlName="verifyCode" />
      </mat-form-field>
      <button mat-raised-button type="submit" color="primary">Verify</button>
    </form>
  </mat-tab>
</mat-tab-group>
<div *ngIf="resposeStatus">
  {{ resposeStatus }}
</div>
