<div class="container">
  <h1>View Result</h1>
  <h2 *ngIf="psyTestGroup && psyTestGroup.title">{{ psyTestGroup.title }}</h2>
  <mat-divider></mat-divider>
  <form [formGroup]="testResultForm">
    <div class="row">
      <mat-form-field class="column">
        <mat-label>User Name:</mat-label>
        <input matInput type="text" formControlName="userName" (keyup)="this.userNameSearchFieldUpdate.next($event)" />
      </mat-form-field>

      <mat-form-field class="column">
        <mat-label>User Email:</mat-label>
        <input
          matInput
          type="text"
          formControlName="userEmail"
          (keyup)="this.userEmailSearchFieldUpdate.next($event)"
        />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="column">
        <mat-label>Start date</mat-label>
        <input
          matInput
          [matDatepicker]="pickerStart"
          (dateInput)="dateChange('start', $event)"
          (dateChange)="dateChange('start', $event)"
          formControlName="start"
        />
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="column">
        <mat-label>End date</mat-label>
        <input
          matInput
          [matDatepicker]="pickerEnd"
          (dateInput)="dateChange('end', $event)"
          (dateChange)="dateChange('end', $event)"
          formControlName="end"
        />
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Test</mat-label>
        <input
          matInput
          type="text"
          (ngModelChange)="this.testFieldUpdate.next($event)"
          formControlName="test"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTest">
          <mat-option
            *ngFor="let test of tests | filter: testField"
            [value]="test"
            (onSelectionChange)="onSearch(test)"
          >
            {{ test.title }}
            <p *ngIf="test.version" class="small-text">Version: {{ test.version }}</p>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>
  <div class="reset-search">
    <button mat-raised-button color="primary" (click)="resetSearch()"><mat-icon>search</mat-icon>Reset Search</button>
  </div>
  <div class="mat-elevation-z3">
    <mat-table [dataSource]="testResults">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="testName">
        <mat-header-cell *matHeaderCellDef>Test Name</mat-header-cell>
        <mat-cell *matCellDef="let element"
          ><div style="width: 80%">{{ element.title }}</div></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.dateCompleted ? 'Completed' : '' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="spentTime">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Spent Time</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.dateCompleted ? element.spentTime : '' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="scoreCards">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Scorecard</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-button class="btn-scorecard" (click)="onTestGroupScorecard(element)">
            <span class="scorecard-text">Scorecard</span>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" *ngIf="isAdmin || (isOA && allowRawScoresAccess)">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="action-buttons">
            <button
              mat-flat-button
              color="primary"
              class="export-answer-number"
              *ngIf="element.hasIntegerQuestions"
              (click)="onExportAnswerNumbers(element)"
            >
              <span class="action-text">Export Answer Numbers</span>
            </button>
            <button
              mat-flat-button
              color="warn"
              class="export-answer-text"
              *ngIf="element.hasCardSortQuestions"
              (click)="onExportAnswerText(element)"
            >
              <span class="action-text">Export Answer Text</span>
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row mat-header-row *matHeaderRowDef="displayedTestResultsColumns"></mat-header-row>
      <mat-row mat-row *matRowDef="let row; columns: displayedTestResultsColumns"></mat-row>
    </mat-table>
  </div>

  <mat-paginator
    #paginatorTestResults
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
</div>
