import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Link } from '../models/link.model';
import { Node } from '../models/node.model';
import { ConnectionIndicator } from '../models/connections-indicator.model';
import { User } from '../models/user.model';
import { Invite } from '../models/invite.model';
import { Question } from '../models/question.model';
import { DecisionTable } from '../models/decision-table.model';
import { UserConnection } from '../models/user-connection.model';
import { UserInfo } from '../models/userInfo.model';

const BACKEND_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class ConnectionsIndicatorService {
  deleteUser(connectionIndicatorId: number, userInfoId: number) {
    return this.http.delete(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/user/' + userInfoId);
  }

  constructor(private http: HttpClient, private router: Router) {}

  save(connectionIndicator: ConnectionIndicator) {
    return this.http.post(BACKEND_URL + '/connection-indicator', connectionIndicator);
  }

  update(connectionIndicator: ConnectionIndicator) {
    return this.http.put(BACKEND_URL + '/connection-indicator', connectionIndicator);
  }

  updateActive(connectionIndicator: ConnectionIndicator) {
    return this.http.patch(BACKEND_URL + '/connection-indicator/active', connectionIndicator);
  }

  delete(connectionIndicatorId: number) {
    return this.http.delete(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId);
  }

  saveUsers(connectionIndicatorId: number, users: User[]) {
    return this.http.post(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/user', users);
  }

  updateUsers(connectionIndicatorId: number, users: User[]) {
    return this.http.patch(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/user', users);
  }

  saveInvites(connectionIndicatorId: number, invites: Invite[]) {
    return this.http.post(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/invite', invites);
  }

  getUsers(connectionIndicatorId: number) {
    return this.http.get<User[]>(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/user');
  }

  getUsersAndInvites(connectionIndicatorId: number) {
    return this.http.get<User[]>(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/user-and-invite');
  }

  saveDecisionTable(connectionIndicatorId: number, decisionTable: DecisionTable) {
    return this.http.post<{ decisionTableId: number; connectionIndicatorDecisionTableId: number }>(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/decision-table',
      decisionTable,
    );
  }

  getAllQuestionById(connectionIndicatorId: number) {
    return this.http.get<Question[]>(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/question');
  }

  getAllDecisionTableById(connectionIndicatorId: number) {
    return this.http.get<DecisionTable[]>(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/decision-table',
    );
  }

  getAll() {
    return this.http.get<ConnectionIndicator[]>(BACKEND_URL + '/connection-indicator');
  }

  getById(connectionIndicatorId: number) {
    return this.http.get<ConnectionIndicator>(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId);
  }

  getList(userInfoId: number) {
    return this.http.get<ConnectionIndicator[]>(BACKEND_URL + '/connection-indicator/availabe-for-user/' + userInfoId);
  }

  saveUserConnection(connectionIndicatorId: number, userConnection: UserConnection) {
    return this.http.post(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/user-connection',
      userConnection,
    );
  }

  getToUserConnectionByQuestionId(connectionIndicatorId: number, questionId: number, userInfoId: number) {
    return this.http.get<UserConnection[]>(
      BACKEND_URL +
        '/connection-indicator/' +
        connectionIndicatorId +
        '/question/' +
        questionId +
        '/user/' +
        userInfoId +
        '/connection',
    );
  }

  getConnectionIndicatorNonOwnerToUserConnectionByQuestionId(
    connectionIndicatorId: number,
    questionId: number,
    userInfoId: number,
  ) {
    return this.http.get<UserConnection[]>(
      BACKEND_URL +
        '/connection-indicator/' +
        connectionIndicatorId +
        '/question/' +
        questionId +
        '/user/' +
        userInfoId +
        '/non-owner-connection',
    );
  }

  getNetworkAnalysisByQuestionId(connectionIndicatorId: number, questionId: number) {
    return this.http.get<any[]>(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/analysis-by-question/' + questionId,
    );
  }

  getNetworkAnalysisByDecisionTableId(connectionIndicatorId: number, decisionTableId: number) {
    return this.http.get<any[]>(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/analysis-by-decision-table/' + decisionTableId,
    );
  }

  getNetworkAnalysisByCompany(connectionIndicatorId: number) {
    return this.http.get<any[]>(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/analysis-by-company',
    );
  }

  getNetworkAnalysisByConnectionIndicator(connectionIndicatorId: number) {
    return this.http.get<any[]>(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/analysis');
  }

  getUnaForExpertAndReferentByByConnectionIndicator(connectionIndicatorId: number) {
    return this.http.get<any[]>(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/analysis-expert-referent',
    );
  }

  completeConnectionIndicator(connectionIndicatorId: number, userInfoId: number) {
    return this.http.patch(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/user/' + userInfoId + '/complete',
      '',
    );
  }

  getAllNode(connectionIndicatorId: number) {
    return this.http.get<Node[]>(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/node');
  }

  getAllLinkByQuestionId(connectionIndicatorId: number, questionId: number) {
    return this.http.get<Link[]>(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/link/question/' + questionId,
    );
  }

  getAllLinkByDecisionTableId(connectionIndicatorId: number, decisionTableId: number) {
    return this.http.get<Link[]>(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/link/decision-table/' + decisionTableId,
    );
  }

  getAllLinkByType(connectionIndicatorId: number, type: string) {
    return this.http.get<Link[]>(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/link/' + type);
  }

  getAllLinks(connectionIndicatorId: number) {
    return this.http.get<Link[]>(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/link');
  }

  deleteConnectionIndicatorDecisionTable(decisionTableId: number) {
    return this.http.delete(BACKEND_URL + '/connection-indicator/decision-table/' + decisionTableId);
  }

  updateAcl(connectionIndicatorId: number, acl: number) {
    return this.http.patch(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/acl', { acl });
  }

  updateUserConnectionPrivacy(connectionIndicatorId: number, userConnection: UserConnection) {
    return this.http.patch(
      BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/user-connection-privacy',
      userConnection,
    );
  }

  getUsersComplete(connectionIndicatorId: number) {
    return this.http.get<UserInfo[]>(BACKEND_URL + '/connection-indicator/' + connectionIndicatorId + '/user-complete');
  }
}
