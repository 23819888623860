<mat-card *ngIf="!showEdit">
  <div class="row m-15 title">
    <div>
      <h3>{{ decisionArea.name }}</h3>
    </div>
    <div class="actions">
      <button mat-button class="btn-error" (click)="onEdit()">Edit</button>
      <button mat-button class="btn-error" (click)="onDelete(decisionArea.id)">Delete</button>
    </div>
  </div>
  <div></div>
</mat-card>
<app-edit-decision-area
  *ngIf="showEdit"
  [decisionArea]="decisionArea"
  (edit)="onEditResponse($event)"
></app-edit-decision-area>
