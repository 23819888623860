/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyTestGroup } from '../models/PsyTestGroup';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Retrieve all test group from start to end
     * Retrieve all test group from start to end
     * @param id Id of the company
     * @param start The date of start
     * @param end The date of start
     * @returns PsyTestGroup A list of test groups
     * @throws ApiError
     */
    public getCompanyTestGroupFromTo(
        id: number,
        start?: string,
        end?: string,
    ): Observable<PsyTestGroup> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/{id}/test-group/from/{start}/to/{end}',
            path: {
                'id': id,
                'start': start,
                'end': end,
            },
            errors: {
                404: `a list of test groups not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list with all test groups
     * @returns PsyTestGroup A list of test groups
     * @throws ApiError
     */
    public getTestGroup(): Observable<Array<PsyTestGroup>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group',
            errors: {
                500: `Internal Error`,
            },
        });
    }

    /**
     * Create a test group
     * Create a test group
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroup(
        requestBody?: {
            /**
             * Id of the test group
             */
            id?: number;
            /**
             * Title of the test group
             */
            title?: string;
            /**
             * Description of the test group
             */
            description?: string;
            /**
             * Status of the test group
             */
            status?: string;
            /**
             * Scorecard Title of the test group
             */
            scorecardTitle?: string;
            /**
             * Date Created of the test group
             */
            dateCreated?: string;
            /**
             * Date Opened of the test group
             */
            dateOpened?: string;
            /**
             * Company Id of the test group
             */
            companyId?: number;
            /**
             * Address of the test group
             */
            address?: string;
            /**
             * Address 2 of the test group
             */
            address2?: string;
            /**
             * City of the test group
             */
            city?: string;
            /**
             * State Id of the test group
             */
            stateId?: number;
            /**
             * Zip Code of the test group
             */
            zipCode?: number;
            /**
             * Billing Address of the test group
             */
            billingAddress?: string;
            /**
             * Billing Address 2 of the test group
             */
            billingAddress2?: string;
            /**
             * Billing City of the test group
             */
            billingCity?: string;
            /**
             * Billing State Id of the test group
             */
            billingStateId?: number;
            /**
             * Billing Zip Code of the test group
             */
            billingZipCode?: number;
            /**
             * Department Id of the test group
             */
            departmentId?: number;
            /**
             * Deleted of the test group
             */
            deleted?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a test group by id
     * Retrieve a test group by id
     * @param id Id of the test group
     * @returns PsyTestGroup A test group
     * @throws ApiError
     */
    public getTestGroup1(
        id: number,
    ): Observable<PsyTestGroup> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Company not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Delete a test group
     * Delete a test group
     * @param id Id of the test group
     * @returns any test group deleted successfully
     * @throws ApiError
     */
    public deleteTestGroup(
        id: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Update a test group
     * Update a test group
     * @param testGroupUserId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchTestGroup(
        testGroupUserId: number,
        requestBody?: {
            /**
             * Id of the test group
             */
            id?: number;
            /**
             * Title of the test group
             */
            title?: string;
            /**
             * Description of the test group
             */
            description?: string;
            /**
             * Status of the test group
             */
            status?: string;
            /**
             * Scorecard Title of the test group
             */
            scorecardTitle?: string;
            /**
             * Date Created of the test group
             */
            dateCreated?: string;
            /**
             * Date Opened of the test group
             */
            dateOpened?: string;
            /**
             * Company Id of the test group
             */
            companyId?: number;
            /**
             * Address of the test group
             */
            address?: string;
            /**
             * Address 2 of the test group
             */
            address2?: string;
            /**
             * City of the test group
             */
            city?: string;
            /**
             * State Id of the test group
             */
            stateId?: number;
            /**
             * Zip Code of the test group
             */
            zipCode?: number;
            /**
             * Billing Address of the test group
             */
            billingAddress?: string;
            /**
             * Billing Address 2 of the test group
             */
            billingAddress2?: string;
            /**
             * Billing City of the test group
             */
            billingCity?: string;
            /**
             * Billing State Id of the test group
             */
            billingStateId?: number;
            /**
             * Billing Zip Code of the test group
             */
            billingZipCode?: number;
            /**
             * Department Id of the test group
             */
            departmentId?: number;
            /**
             * Deleted of the test group
             */
            deleted?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/test-group/{id}',
            path: {
                'testGroupUserId': testGroupUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `PsyTestGroup not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve all test group from start to end
     * Retrieve all test group from start to end
     * @param start The date of start
     * @param end The date of start
     * @returns PsyTestGroup A list of test groups
     * @throws ApiError
     */
    public getTestGroupFromTo(
        start?: string,
        end?: string,
    ): Observable<PsyTestGroup> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/from/{start}/to/{end}',
            path: {
                'start': start,
                'end': end,
            },
            errors: {
                404: `a list of test groups not found`,
                500: `Internal error`,
            },
        });
    }

}
