import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-area-circle-plot',
  templateUrl: './area-circle-plot.component.html',
  styleUrls: ['./area-circle-plot.component.scss'],
})
export class AreaCirclePlotComponent implements OnInit {
  @Input('selectedAreaNumber') selectedAreaNumber: any;

  constructor() {}

  ngOnInit(): void {}
}
