// Core modules
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular material modules
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Other modules
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ChartsModule } from 'ng2-charts';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CountdownModule } from 'ngx-countdown';

// Components
import { ChartSubsectionComponent } from './components/sections/chart-subsection/chart-subsection.component';
import { DecisionTableComponent } from './components/decision-table/decision-table.component';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { QuestionComponent } from './components/question/question.component';
import { AnswerUserConnectionComponent } from './components/answer-user-connection/answer-user-connection.component';
import { AnswerDecisionTableComponent } from './components/answer-decision-table/answer-decision-table.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DepartmentFormComponent } from './components/department/department-form/department-form.component';
import { RoleComponent } from './components/role/role.component';
import { AlternativeRoleComponent } from './components/alternative-role/alternative-role.component';
import { LevelComponent } from './components/level/level.component';
import { UserNetworkComponent } from './components/user-network/user-network.component';
import { CentralityTableComponent } from './components/centrality-table/centrality-table.component';
import { NewDecisionTableComponent } from './components/decision-table/new-decision-table/new-decision-table.component';
import { CardsortComponent } from './components/cardsort/cardsort.component';
import { AnswerCardsortComponent } from './components/answer-cardsort/answer-cardsort.component';
import { AnswerTextAreaComponent } from './components/answer-text-area/answer-text-area.component';
import { AnswerMultipleVerticalChoiceComponent } from './components/answer-multiple-vertical-choice/answer-multiple-vertical-choice.component';
import { AnswerSliderComponent } from './components/answer-slider/answer-slider.component';
import { QuestionGroupComponent } from './components/question-group/question-group.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { AnswerListTextComponent } from './components/answer-list-text/answer-list-text.component';
import { FormCardsortComponent } from './components/cardsort/form-cardsort/form-cardsort.component';
import { EditAnswerComponent } from './components/answer-list-text/edit-answer/edit-answer.component';
import { EditDecisionTableComponent } from './components/decision-table/edit-decision-table/edit-decision-table.component';
import { EditColumnComponent } from './components/decision-table/edit-column/edit-column.component';
import { NewColumnComponent } from './components/decision-table/new-column/new-column.component';
import { EditQuestionGroupComponent } from './components/question-group/edit-question-group/edit-question-group.component';
import { DecisionTableConnectionComponent } from './components/decision-table-connection/decision-table-connection.component';
import { NewDecisionTableConnectionComponent } from './components/decision-table-connection/new-decision-table-connection/new-decision-table-connection.component';
import { DecisionAreaComponent } from './components/decision-table/decision-area/decision-area.component';
import { NewDecisionAreaComponent } from './components/decision-table/new-decision-area/new-decision-area.component';
import { EditDecisionAreaComponent } from './components/decision-table/edit-decision-area/edit-decision-area.component';
import { EditAnswerDecisionTableComponent } from './components/answer-decision-table/edit-answer-decision-table/edit-answer-decision-table.component';
import { EditDecisionTableConnectionComponent } from './components/decision-table-connection/edit-decision-table-connection/edit-decision-table-connection.component';
import { CardsortAnalysisComponent } from './components/cardsort-analysis/cardsort-analysis.component';
import { TextAreaAnalysisComponent } from './components/text-area-analysis/text-area-analysis.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { SliderAnalysisComponent } from './components/slider-analysis/slider-analysis.component';
import { NotesDialogComponent } from './components/notes-dialog/notes-dialog.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { TotalBoxComponent } from './components/total-box/total-box.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { EmailLogsComponent } from './components/email-logs/email-logs.component';
import { UserInviteComponent } from './components/user-invite/user-invite.component';
import { EditUserInviteDialogComponent } from './components/user-invite/edit-user-invite-dialog/edit-user-invite-dialog.component';
import { QuestionAnalysisComponent } from './components/question-analysis/question-analysis.component';
import { DecisionTableAnalysisComponent } from './components/decision-table-analysis/decision-table-analysis.component';
import { BoardUsersComponent } from './components/board-users/board-users.component';
import { RoleMandateUsersDataComponent } from './components/role-mandate-users-data/role-mandate-users-data.component';
import { StatusComponent } from './components/status/status.component';
import { PsbListComponent } from './components/psb-list/psb-list.component';
import { PsbListTableComponent } from './components/psb-list-table/psb-list-table.component';
import { PsbStatsLineComponent } from './components/psb-stats-line/psb-stats-line.component';
import { PsbPageNavigatorComponent } from './components/psb-page-navigator/psb-page-navigator.component';
import { SearchComponent } from './components/search/search.component';
import { AnswerMultipleHorizontalChoiceComponent } from './components/answer-multiple-horizontal-choice/answer-multiple-horizontal-choice.component';
import { TimerComponent } from './components/timer/timer.component';
import { AnswerPentagonComponent } from './components/answer-pentagon/answer-pentagon.component';
import { PsbTableSearchComponent } from './components/psb-table-search/psb-table-search.component';
import { PsyOptionsDialogComponent } from './components/psy-options-dialog/psy-options-dialog.component';
import { PsbSendEmailComponent } from './components/psb-send-email/psb-send-email.component';
import { GlobalPrivacyComponent } from './components/global-privacy/global-privacy.component';
import { UserDetailsFormComponent } from './components/user-details-form/user-details-form.component';
import { UserNotificationHeaderComponent } from './components/user-notification-header/user-notification-header.component';
import { ChapterComponent } from './components/report/chapter/chapter.component';
import { SectionComponent } from './components/report/section/section.component';
import { ParagraphComponent } from './components/report/paragraph/paragraph.component';
import { BulletPointComponent } from './components/report/bullet-point/bullet-point.component';
import { CombinationParagraphComponent } from './components/report/combination-paragraph/combination-paragraph.component';
import { OrdinalParagraphComponent } from './components/report/ordinal-paragraph/ordinal-paragraph.component';
import { ScorecardsTableComponent } from './components/report/scorecards-table/scorecards-table.component';
import { ChartSectionComponent } from './components/report/chart-section/chart-section.component';
import { UserListTableComponent } from './components/user-list-table/user-list-table.component';
import { CopyDialogComponent } from './components/copy-dialog/copy-dialog.component';
import { FormDecisionTableConnectionComponent } from './components/decision-table-connection/form-decision-table-connection/form-decision-table-connection.component';
import { DecisionTableAcceptComponent } from './components/decision-table-accept/decision-table-accept.component';
import { NotificationDetailDialogComponent } from './components/notification-detail-dialog/notification-detail-dialog.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { ViolinComponent } from './components/chart/violin/violin.component';
import { HexagonComponent } from './components/chart/hexagon/hexagon.component';
import { SquarePlotComponent } from './components/chart/square-plot/square-plot.component';
import { CircularPlotComponent } from './components/chart/circular-plot/circular-plot.component';
import { BarSubscalesComponent } from './components/chart/bar-subscales/bar-subscales.component';
import { OptimalScoreComponent } from './components/chart/optimal-score/optimal-score.component';
import { OrdinalParagraphSectionComponent } from './components/sections/ordinal-paragraph-section/ordinal-paragraph-section.component';
import { ParagraphSectionComponent } from './components/sections/paragraph-section/paragraph-section.component';
import { BulletPointSectionComponent } from './components/sections/bullet-point-section/bullet-point-section.component';
import { AllCombinationParagraphComponent } from './components/sections/all-combination-paragraph/all-combination-paragraph.component';
import { SubsectionContainerComponent } from './components/sections/subsection-container/subsection-container.component';
import { ScorecardsSectionComponent } from './components/sections/scorecards-section/scorecards-section.component';
import { RangesTableComponent } from './components/optimal-score/ranges-table/ranges-table.component';
import { SpreadsTableComponent } from './components/optimal-score/spreads-table/spreads-table.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { TestUsersComponent } from './components/test-users/test-users.component';
import { DepartmentListTableComponent } from './components/department/department-list-table/department-list-table.component';
import { DepartmentModalComponent } from './components/department/department-modal/department-modal.component';
import { AnswerTextSingleLineComponent } from './components/answer-text-single-line/answer-text-single-line.component';
import { SearchResultComponent } from '../user/search-result/search-result.component';
import { UserNotificationComponent } from '../user/user-notification/user-notification.component';
import { FilterChartUsersComponent } from './components/filter-chart-users/filter-chart-users.component';
import { ReportModelViewComponent } from './components/report-model-view/report-model-view.component';
import { TestResultsTableComponent } from './components/report/test-results-table/test-results-table.component';

import { StripeHtmlPipe } from './pipes/stripe-html.pipe';
import { ReplaceNamePipe } from './pipes/replace-name.pipe';

import { LinkVisualComponent, NodeVisualComponent } from './directives/d3';
import { D3_EFFECTS_DIRECTIVES } from './directives/d3/effects';
import { QuestionTextComponent } from './components/question-text/question-text.component';
import { UserTestGroupListComponent } from './components/user-test-group-list/user-test-group-list.component';
import { UserScoreListComponent } from './components/user-score-list/user-score-list.component';
import { UserEditDialogComponent } from './components/user-edit-dialog/user-edit-dialog.component';
import { UserAddToTestGroupDialogComponent } from './components/user-add-to-test-group-dialog/user-add-to-test-group-dialog.component';
import { UserCreateDialogComponent } from './components/user-create-dialog/user-create-dialog.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { TestGroupModalComponent } from '@app/shared/components/test-group-modal/test-group-modal.component';
import { TestGroupTestListTableComponent } from '@app/shared/components/test-group-test-list-table/test-group-test-list-table.component';
import { TestGroupChartListTableComponent } from '@app/shared/components/test-group-chart-list-table/test-group-chart-list-table.component';
import { TestGroupEmailComponent } from '@app/shared/components/test-group-email/test-group-email.component';
import { NotificationsChannelComponent } from '@app/shared/components/notifications-channel/notifications-channel.component';
import { EditTestGroupUserModalComponent } from '@app/shared/components/edit-test-group-user-modal/edit-test-group-user-modal.component';
import { TestGroupChartPreviewComponent } from '@app/shared/components/test-group-chart-preview/test-group-chart-preview.component';
import { TestGroupScaleListComponent } from '@app/shared/components/test-group-scale-list/test-group-scale-list.component';
import { TestGroupPercentileListComponent } from '@app/shared/components/test-group-percentile-list/test-group-percentile-list.component';
import { SubscaleScorePercentileComponent } from '@app/shared/components/subscale-score-percentile/subscale-score-percentile.component';
import { SearchPipe } from '@app/shared/pipes/search.pipe';
import { AllowAlphabetsOnlyDirective } from './directives/allow-alphabets-only.directive';
import { CardSortScorePositionComponent } from './components/card-sort-score-position/card-sort-score-position.component';
import { ProgressBarDeterminateComponent } from './components/progress-bar-determinate/progress-bar-determinate.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PsynetAddNewUserComponent } from './components/psynet-add-new-user/psynet-add-new-user.component';
import { PsbRoleMandateAddUserDialogComponent } from './components/psb-role-mandate-add-user-dialog/psb-role-mandate-add-user-dialog.component';
import { CustomRangeComponent } from './components/custom-range/custom-range.component';
import { OptimalScoreListComponent } from './components/optimal-score-list/optimal-score-list.component';
import { CustomQuestionsOrderComponent } from './components/custom-questions-order/custom-questions-order.component';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import { OptimalScoreSectionSummaryRangeContentComponent } from './components/optimal-score-section-summary-range-content/optimal-score-section-summary-range-content.component';
import { EditOptimalScoreScaleRangeTextComponent } from './components/edit-optimal-score-scale-range-text/edit-optimal-score-scale-range-text.component';
import { AddOptimalScoreDialogComponent } from '@app/shared/components/add-optimal-score-dialog/add-optimal-score-dialog.component';
import { UnansweredQuestionsDialogComponent } from '@app/shared/components/unanswered-questions-dialog/unanswered-questions-dialog.component';
import { ArchiveDialogComponent } from '@app/shared/components/archive-dialog/archive-dialog.component';
import { RelatedAssessmentListAndSubscaleListComponent } from '@app/shared/components/related-assessment-list-and-subscale-list/related-assessment-list-and-subscale-list.component';
import { GenericLinkComponent } from './components/generic-link/generic-link.component';
import { CreateOrEditGenericLinkDialogComponent } from './components/create-or-edit-generic-link-dialog/create-or-edit-generic-link-dialog.component';
import { ClientChartListComponent } from './components/client-chart-list/client-chart-list.component';
import { ClientAssessmentListComponent } from './components/client-assessment-list/client-assessment-list.component';
import { BlossomComponent } from './components/chart/blossom/blossom.component';
import { ApiClientModule } from '@app/admin/pages/chart/blossom/api-client/api-client.module';
import { BlossomGraphComponent } from './components/chart/blossom/blossom-graph/blossom-graph.component';
import { BlossomGraphPreviewComponent } from './components/chart/blossom/blossom-graph-preview/blossom-graph-preview.component';

const MATERIAL_MODULES = [
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatChipsModule,
  MatBadgeModule,
  MatRadioModule,
  MatInputModule,
  MatFormFieldModule,
  MatListModule,
  MatTableModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatTabsModule,
  MatSortModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatSnackBarModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatButtonToggleModule,
  MatPasswordStrengthModule,
];

const components = [
  DecisionTableComponent,
  InstructionsComponent,
  QuestionComponent,
  AnswerUserConnectionComponent,
  LoaderComponent,
  DepartmentFormComponent,
  RoleComponent,
  AlternativeRoleComponent,
  LevelComponent,
  UserNetworkComponent,
  LinkVisualComponent,
  NodeVisualComponent,
  CentralityTableComponent,
  NewDecisionTableComponent,
  CardsortComponent,
  AnswerCardsortComponent,
  AnswerTextAreaComponent,
  AnswerMultipleVerticalChoiceComponent,
  AnswerSliderComponent,
  QuestionGroupComponent,
  AlertDialogComponent,
  AnswerListTextComponent,
  FormCardsortComponent,
  EditAnswerComponent,
  EditDecisionTableComponent,
  EditColumnComponent,
  NewColumnComponent,
  EditQuestionGroupComponent,
  DecisionTableConnectionComponent,
  NewDecisionTableConnectionComponent,
  DecisionAreaComponent,
  NewDecisionAreaComponent,
  EditDecisionAreaComponent,
  EditAnswerDecisionTableComponent,
  EditDecisionTableConnectionComponent,
  FormDecisionTableConnectionComponent,
  InfoDialogComponent,
  SliderAnalysisComponent,
  LineChartComponent,
  TotalBoxComponent,
  DateFilterComponent,
  AnswerMultipleHorizontalChoiceComponent,
  TimerComponent,
  AnswerPentagonComponent,
  EmailLogsComponent,
  CardsortAnalysisComponent,
  TextAreaAnalysisComponent,
  NotesDialogComponent,
  SliderAnalysisComponent,
  StripeHtmlPipe,
  UserInviteComponent,
  EditUserInviteDialogComponent,
  QuestionAnalysisComponent,
  DecisionTableAnalysisComponent,
  BoardUsersComponent,
  RoleMandateUsersDataComponent,
  StatusComponent,
  PsbListComponent,
  PsbStatsLineComponent,
  SearchComponent,
  PsbPageNavigatorComponent,
  AnswerMultipleHorizontalChoiceComponent,
  TimerComponent,
  AnswerPentagonComponent,
  PsbTableSearchComponent,
  PsyOptionsDialogComponent,
  PsbSendEmailComponent,
  PsbListTableComponent,
  GlobalPrivacyComponent,
  UserDetailsFormComponent,
  SearchResultComponent,
  UserNotificationHeaderComponent,
  UserNotificationComponent,
  ChapterComponent,
  SectionComponent,
  ParagraphComponent,
  BulletPointComponent,
  CombinationParagraphComponent,
  OrdinalParagraphComponent,
  ChartSectionComponent,
  ScorecardsTableComponent,
  ViolinComponent,
  HexagonComponent,
  UserListTableComponent,
  DecisionTableAcceptComponent,
  CopyDialogComponent,
  AnswerDecisionTableComponent,
  CopyDialogComponent,
  SquarePlotComponent,
  NotificationDetailDialogComponent,
  CircularPlotComponent,
  BarSubscalesComponent,
  NotificationDialogComponent,
  OrdinalParagraphSectionComponent,
  ParagraphSectionComponent,
  BulletPointSectionComponent,
  AllCombinationParagraphComponent,
  ChartSubsectionComponent,
  SubsectionContainerComponent,
  ScorecardsSectionComponent,
  OptimalScoreComponent,
  RangesTableComponent,
  SpreadsTableComponent,
  ConfirmationDialogComponent,
  TestUsersComponent,
  DepartmentListTableComponent,
  DepartmentModalComponent,
  AnswerTextSingleLineComponent,
  FilterChartUsersComponent,
  ReportModelViewComponent,
  QuestionTextComponent,
  UserTestGroupListComponent,
  UserScoreListComponent,
  UserEditDialogComponent,
  UserAddToTestGroupDialogComponent,
  UserCreateDialogComponent,
  TestGroupModalComponent,
  TestGroupTestListTableComponent,
  TestGroupChartListTableComponent,
  TestGroupEmailComponent,
  NotificationsChannelComponent,
  EditTestGroupUserModalComponent,
  TestGroupChartPreviewComponent,
  TestGroupScaleListComponent,
  TestGroupPercentileListComponent,
  SubscaleScorePercentileComponent,
  TestResultsTableComponent,
  SearchPipe,
  ReplaceNamePipe,
  AllowAlphabetsOnlyDirective,
  ProgressBarDeterminateComponent,
  CardSortScorePositionComponent,
  ResetPasswordComponent,
  PsynetAddNewUserComponent,
  PsbRoleMandateAddUserDialogComponent,
  CustomRangeComponent,
  OptimalScoreListComponent,
  TruncatePipe,
  OptimalScoreSectionSummaryRangeContentComponent,
  EditOptimalScoreScaleRangeTextComponent,
  AddOptimalScoreDialogComponent,
  UnansweredQuestionsDialogComponent,
  ArchiveDialogComponent,
  RelatedAssessmentListAndSubscaleListComponent,
  ClientChartListComponent,
  CustomQuestionsOrderComponent,
  ClientAssessmentListComponent,
  BlossomComponent,
];

@NgModule({
  declarations: [
    ...D3_EFFECTS_DIRECTIVES,
    ...components,
    CustomQuestionsOrderComponent,
    GenericLinkComponent,
    CreateOrEditGenericLinkDialogComponent,
    BlossomGraphComponent,
    BlossomGraphPreviewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ...MATERIAL_MODULES,
    LayoutModule,
    DragDropModule,
    AngularEditorModule,
    NgxSliderModule,
    ChartsModule,
    ClipboardModule,
    NgxPaginationModule,
    CountdownModule,
    Ng2SearchPipeModule,
    ApiClientModule,
  ],
  exports: [...MATERIAL_MODULES, ...components],
  providers: [DatePipe],
})
export class SharedModule {}
