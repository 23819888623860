import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// components
import { NotificationDetailDialogComponent } from '@app/shared/components/notification-detail-dialog/notification-detail-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { PsyOptionsDialogComponent } from '@app/shared/components/psy-options-dialog/psy-options-dialog.component';
import { NotificationDialogComponent } from '@app/shared/components/notification-dialog/notification-dialog.component';

// services
import { TestGroupNotificationService } from '@app/core/services/test-group-notification.service';
import { RoleMandateNotificationService } from '@app/core/services/role-mandate-notification.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { PsbItem } from '@app/core/models/psb-item.model';
import { PsbOptionItem } from '@app/core/models/psb-option-item.model';
import { Notification } from '@app/core/models/notification.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';

@Component({
  selector: 'app-notifications-channel',
  templateUrl: './notifications-channel.component.html',
  styleUrls: ['./notifications-channel.component.scss'],
})
export class NotificationsChannelComponent implements OnInit {
  @Input() testGroupId: number;
  @Input() roleMandateId: number;
  hasNotification: boolean;
  newNotificationChannel = false;
  notificationPsbItems: PsbItem[] = [];
  notifications: Notification[] = [];
  testNoficationList: Notification[] = [];
  notificationPsbOptionItems: PsbOptionItem[] = [];

  constructor(
    private testGroupNotificationServive: TestGroupNotificationService,
    private roleMandateNotificationService: RoleMandateNotificationService,
    private notificationService: NotificationService,
    private snackBar: SnackBarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getAllNotification();
    if (this.testGroupId) {
      this.getTestGroupNotification();
    }

    if (this.roleMandateId) {
      this.getRoleMandateNotification();
    }
  }

  getTestGroupNotification() {
    this.testNoficationList = [];
    if (this.testGroupId) {
      this.testGroupNotificationServive.getTestGroupNotificationById(this.testGroupId).subscribe((results) => {
        if (results.length === 0) {
          this.hasNotification = false;
        } else {
          this.hasNotification = true;
          this.testNoficationList = results;
        }
      });
    }
  }

  getRoleMandateNotification() {
    this.testNoficationList = [];
    if (this.roleMandateId) {
      this.roleMandateNotificationService.getRoleMandateNotificationById(this.roleMandateId).subscribe((results) => {
        if (results.length === 0) {
          this.hasNotification = false;
        } else {
          this.hasNotification = true;
          this.testNoficationList = results;
        }
      });
    }
  }

  getAllNotification() {
    this.notifications = [];
    this.notificationPsbOptionItems = [];
    this.notificationPsbItems = [];

    this.notificationService.getAll().subscribe((notifications) => {
      this.notifications = notifications;

      notifications.map((notification) => {
        const optionItem: PsbOptionItem = {
          id: notification.id,
          itemName: notification.chanel,
        };

        this.notificationPsbOptionItems.push(optionItem);

        const notificationPsbItems: PsbItem = {
          id: notification.id,
          extraColumns: [
            {
              key: 'Webhook',
              value: notification.webhook,
            },
          ],
        };

        this.notificationPsbItems.push(notificationPsbItems);
      });
    });
  }

  addChannel() {
    const addChannelModel = this.dialog.open(PsyOptionsDialogComponent, {
      data: {
        items: this.notificationPsbOptionItems,
        title: 'Add Channel',
        formFieldName: 'Notification',
        isAddButton: true,
        addButtonName: 'New Notification Channel',
      },
    });

    addChannelModel.afterClosed().subscribe((result) => {
      if (result.event === 'save') {
        if (this.testGroupId) {
          this.testGroupNotificationServive
            .saveTestGroupNotification(this.testGroupId, result.data.item.id)
            .subscribe((res) => {
              this.getTestGroupNotification();
            });
        }

        if (this.roleMandateId) {
          this.roleMandateNotificationService
            .saveRoleMandateNotification(this.roleMandateId, result.data.item.id)
            .subscribe((res) => {
              this.getRoleMandateNotification();
            });
        }
      }

      if (result.event === 'add') {
        this.newNotificationChannel = true;
      }
    });
  }

  createNewChannel() {
    const createNewChannelModel = this.dialog.open(NotificationDialogComponent, {
      data: { isEdit: false },
    });

    createNewChannelModel.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.notificationService.save(result.data).subscribe((res) => {
          this.getAllNotification();
        });
      }
    });
  }

  onView(id: number) {
    const notification = this.notifications.filter((n) => n.id === id)[0];
    const viewDialog = this.dialog.open(NotificationDetailDialogComponent, {
      data: { notification },
    });
  }

  onEdit(id: number) {
    const notification = this.notifications.filter((n) => n.id === id)[0];
    const editChannelModel = this.dialog.open(NotificationDialogComponent, {
      data: { isEdit: true, notification },
    });

    editChannelModel.afterClosed().subscribe((result) => {
      if (result.event !== EVENT_CANCEL) {
        this.notificationService.update(result.data).subscribe((res) => {
          this.getAllNotification();
        });
      }
    });
  }

  onDelete(id: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);
    deleteDialog.componentInstance.message = `Are you sure you want to delete Notification ${id}`;
    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event === EVENT_CANCEL) {
        return;
      }

      this.notificationService.delete(id).subscribe((res) => {
        this.getAllNotification();
        this.snackBar.info(`Notification ${id} deleted with success.`);
      });
    });
  }

  removeChannel(id: number) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent);
    if (this.testGroupId) {
      deleteDialog.componentInstance.message = `Are you sure you want to delete Psy Test Group Notification ${this.testGroupId}`;
    }

    if (this.roleMandateId) {
      deleteDialog.componentInstance.message = `Are you sure you want to delete Role Navigator Notification ${this.roleMandateId}`;
    }

    deleteDialog.afterClosed().subscribe((result) => {
      if (result.event === EVENT_CANCEL) {
        return;
      }

      if (this.testGroupId) {
        this.testGroupNotificationServive
          .deleteByTestGroupIdAndNotificationId(this.testGroupId, id)
          .subscribe((res) => {
            this.getTestGroupNotification();
            this.snackBar.info(`Psy Test Group Notification ${this.testGroupId} deleted with success.`);
          });
      }

      if (this.roleMandateId) {
        this.roleMandateNotificationService
          .deleteByRoleMandateIdAndNotificationId(this.roleMandateId, id)
          .subscribe((res) => {
            this.getRoleMandateNotification();
            this.snackBar.info(`Role Navigator Notification ${this.roleMandateId} deleted with success.`);
          });
      }
    });
  }
}
