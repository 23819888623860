import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { Angular2CsvModule } from 'angular2-csv';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxPaginationModule } from 'ngx-pagination';

import { SharedModule } from '@app/shared/shared.module';
import { RoleMandateRoutingModule } from './role-mandate.routing.module';

import { RoleMandateComponent } from './role-mandate/role-mandate.component';
import { RoleMandateTitlesComponent } from './role-mandate-titles/role-mandate-titles.component';
import { RoleMandateUsersComponent } from './role-mandate-users/role-mandate-users.component';
import { RoleMandateQuestionsComponent } from './role-mandate-questions/role-mandate-questions.component';
import { RoleMandateDecisionTablesComponent } from './role-mandate-decision-tables/role-mandate-decision-tables.component';
import { RoleMandateDashboardComponent } from './role-mandate-dashboard/role-mandate-dashboard.component';
import { RoleMandateUserResultComponent } from './role-mandate-user-result/role-mandate-user-result.component';
import { RoleMandateOrdenateComponent } from './role-mandate-ordenate/role-mandate-ordenate.component';
import { RoleMandateReviewComponent } from './role-mandate-review/role-mandate-review.component';
import { RoleMandateSearchComponent } from '../../../user/role-mandate/role-mandate-search/role-mandate-search.component';
import { RoleMandateMigrateUserAnswersComponent } from './role-mandate-migrate-user-answers/role-mandate-migrate-user-answers.component';
import { RoleMandateCreateComponent } from './role-mandate-create/role-mandate-create.component';
import { RoleMandateEmailComponent } from './role-mandate-email/role-mandate-email.component';

@NgModule({
  declarations: [
    RoleMandateComponent,
    RoleMandateTitlesComponent,
    RoleMandateUsersComponent,
    RoleMandateQuestionsComponent,
    RoleMandateDecisionTablesComponent,
    RoleMandateDashboardComponent,
    RoleMandateUserResultComponent,
    RoleMandateOrdenateComponent,
    RoleMandateReviewComponent,
    RoleMandateSearchComponent,
    RoleMandateMigrateUserAnswersComponent,
    RoleMandateCreateComponent,
    RoleMandateEmailComponent,
  ],
  exports: [
    RoleMandateComponent,
    RoleMandateTitlesComponent,
    RoleMandateUsersComponent,
    RoleMandateQuestionsComponent,
    RoleMandateDecisionTablesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RoleMandateRoutingModule,
    NgxPaginationModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    DragDropModule,
    Angular2CsvModule,
    MatStepperModule,
  ],
})
export class RoleMandateModule {}
