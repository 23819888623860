/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PsyTest } from '../models/PsyTest';
import type { PsyTestGroupTest } from '../models/PsyTestGroupTest';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class PsyTestGroupTestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a psyTestGroupTest
     * Create a psyTestGroupTest
     * @param testGroupId Id of the test group
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestGroupPsyTestGroupTest(
        testGroupId: number,
        requestBody?: {
            /**
             * Id of the psy test group
             */
            testGroupId?: number;
            /**
             * Id of the psy test
             */
            testId?: number;
            /**
             * available of PsyTestGroupTest
             */
            available?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test-group/{testGroupId}/psy-test-group-test',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Retrieve a list of psy tests by testGroupId
     * Retrieve a list of psy tests by testGroupId
     * @param testGroupId Id of the Test Group
     * @returns PsyTest A list of psy tests
     * @throws ApiError
     */
    public getTestGroupPsyTest(
        testGroupId: number,
    ): Observable<PsyTest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test-group/{id}/psy-test',
            path: {
                'testGroupId': testGroupId,
            },
            errors: {
                404: `PsyTest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Update a status
     * Update a status
     * @param psyTestGroupTestId
     * @param requestBody Callback payload
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public patchTestGroupTestGroupTestAvailable(
        psyTestGroupTestId: number,
        requestBody?: {
            /**
             * id of psy test group
             */
            testGroupId?: number;
            /**
             * id of psy test
             */
            testId?: number;
            /**
             * available of psy test group test
             */
            available?: boolean;
        },
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/test-group/test-group-test/{psyTestGroupTestId}/available',
            path: {
                'psyTestGroupTestId': psyTestGroupTestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `PsyTestGroupTest not found`,
                500: `Internal error`,
            },
        });
    }

    /**
     * Remove a psy test from test group
     * Remove a psy test from test group
     * @param psyTestGroupTestId Id of the test group test
     * @returns any PsyTest deleted from test group successfully
     * @throws ApiError
     */
    public deleteTestGroupTestGroupTest(
        psyTestGroupTestId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/test-group-test/{psyTestGroupTestId}',
            path: {
                'psyTestGroupTestId': psyTestGroupTestId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Archive a psy test from test group
     * Archive a psy test from test group
     * @param psyTestGroupTestId Id of the test group test
     * @returns any PsyTest is archived from test group successfully
     * @throws ApiError
     */
    public deleteTestGroupTestGroupTestArchive(
        psyTestGroupTestId: number,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test-group/test-group-test/{psyTestGroupTestId}/archive',
            path: {
                'psyTestGroupTestId': psyTestGroupTestId,
            },
            errors: {
                500: `Internal error`,
            },
        });
    }

    /**
     * Create a PsyTestGroupTest
     * Create a PsyTestGroupTest
     * @param id Id of the psy test
     * @param requestBody Callback payload
     * @returns PsyTestGroupTest A list of test group tests
     * @returns any Callback successfully processed
     * @throws ApiError
     */
    public postTestPsyTestGroupTest(
        id: number,
        requestBody?: {
            /**
             * Id of the test group test
             */
            id: number;
            /**
             * Id of the psy test group
             */
            testGroupId?: number;
            /**
             * Zip code of the company
             */
            zipCode?: number;
            /**
             * Address of the company
             */
            address?: string;
            /**
             * Id of the psy test
             */
            testId?: number;
            /**
             * Available of PsyTestGroupTest
             */
            available?: boolean;
        },
    ): Observable<PsyTestGroupTest | any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/{id}/psy-test-group-test',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `a list of test group tests not found`,
                500: `Internal error`,
            },
        });
    }

}
