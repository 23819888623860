import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { SharedModule } from '@app/shared/shared.module';
import { InviteRoutingModule } from './invite-routing.module';
import { InviteComponent } from './invite/invite.component';

@NgModule({
  declarations: [InviteComponent],
  exports: [],
  imports: [CommonModule, InviteRoutingModule, SharedModule, ReactiveFormsModule, MatPasswordStrengthModule],
})
export class InviteModule {}
