import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// components
import { CreateSubscaleQuestionGroupDialogComponent } from '@app/admin/pages/subscale/create-subscale-question-group-dialog/create-subscale-question-group-dialog.component';

// services
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { QuestionGroup } from '@app/core/models/question-group.model';

// constants
import { EVENT_CANCEL } from '@app/core/constants';
import { SubscaleBreadcrumbService } from '@app/shared/services/subscale-breadcrumb.service';

@Component({
  selector: 'app-subscale-question-group-detail',
  templateUrl: './subscale-question-group-detail.component.html',
  styleUrls: ['./subscale-question-group-detail.component.scss'],
})
export class SubscaleQuestionGroupDetailComponent implements OnInit, OnDestroy {
  questionGroupId: number;
  subscaleId: number;
  tabs: string[] = ['Details'];
  selected = new FormControl(0);
  questionGroup: QuestionGroup;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private questionGroupService: QuestionGroupService,
    private breadScrumService: SubscaleBreadcrumbService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.questionGroupId = Number(this.route.snapshot.params.questionGroupId);
    this.subscaleId = Number(this.route.snapshot.params.id);
    this.getQuestionGroup();
    let currentBreadScrum = JSON.parse(localStorage.getItem('breadscrum'));
    if (currentBreadScrum?.hasOwnProperty('questionGroupId')) {
      this.breadScrumService.setBreadCrumbNavigation('question_group_details', currentBreadScrum);
    }
    this.breadScrumService.updatedBreadScrum.subscribe((data) => {
      this.tabs = data;
    });
  }

  private getQuestionGroup() {
    this.questionGroupService.getById(this.questionGroupId).subscribe((questionGroup: QuestionGroup) => {
      this.questionGroup = questionGroup;
    });
  }

  tabChange($event): void {
    this.selected.setValue($event);
  }

  onEdit() {
    this.dialog
      .open(CreateSubscaleQuestionGroupDialogComponent, { data: { isEdit: true, questionGroup: this.questionGroup } })
      .afterClosed()
      .subscribe((result) => {
        if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
          const updatedQuestionGroup: QuestionGroup = {
            id: this.questionGroupId,
            instructions: result.data.instructions,
            title: result.data.title,
            aorder: result.data.aorder,
            subscaleId: this.subscaleId,
          };

          this.questionGroupService.update(updatedQuestionGroup).subscribe(() => {
            this.getQuestionGroup();
            this.snackBar.info('Subscale Question Group updated with success!');
          });
        }
      });
  }

  navigateUrl(item) {
    if (this.router.url != item.url) {
      this.router.navigate([item.url]);
    }
    this.breadScrumService.currentNavigationLink.next(item?.currentTab);
  }

  ngOnDestroy(): void {
    localStorage.removeItem('breadscrum');
  }
}
