import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PageService } from '@app/core/services/page.service';

@Component({
  selector: 'app-connections-indicator-users',
  templateUrl: './connections-indicator-users.component.html',
  styleUrls: ['./connections-indicator-users.component.scss'],
})
export class ConnectionsIndicatorUsersComponent implements OnInit {
  connectionIndicatorId: number;

  constructor(private route: ActivatedRoute, private router: Router, public pageService: PageService) {}

  ngOnInit(): void {
    this.connectionIndicatorId = Number(this.route.snapshot.params.id);
  }

  onNext() {
    if (this.connectionIndicatorId) {
      return this.router.navigate(['/connections-indicator/' + this.connectionIndicatorId + '/questions']);
    }
  }
}
