import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'replaceName',
})
export class ReplaceNamePipe implements PipeTransform {
  constructor(private _domSanitizer: DomSanitizer) {}

  transform(value: any, name?: any): any {
    return this._domSanitizer.bypassSecurityTrustHtml(this.stylize(value, name));
  }

  private stylize(text: string, name: string): string {
    let replaceNameText: string = '';
    replaceNameText = text.replace(
      `<span style='background-color: transparent;font-size: 11pt;'>&lt;Nam</span><span style='background-color: transparent;font-size: 11pt;'>e&gt; is </span>`,
      `${name} is `,
    );
    return replaceNameText;
  }
}
