import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

// services
import { ConnectionsIndicatorService } from '@app/core/services/connections-indicator.service';
import { PaginationService } from '@app/core/services/pagination.service';
import { QuestionGroupService } from '@app/core/services/question-group.service';
import { TestObjectService } from '@app/core/services/test-object.service';
import { UserService } from '@app/core/services/user.service';

// models
import { DecisionTable } from '@app/core/models/decision-table.model';
import { ConnectionIndicator } from '@app/core/models/connections-indicator.model';
import { Question } from '@app/core/models/question.model';
import { QuestionGroup } from '@app/core/models/question-group.model';

@Component({
  selector: 'app-test-connections-indicator',
  templateUrl: './test-connections-indicator.component.html',
  styleUrls: ['./test-connections-indicator.component.scss'],
})
export class TestConnectionsIndicatorComponent implements OnInit {
  connectionsIndicator: ConnectionIndicator;
  connectionsIndicatorId: number;
  userInfoId: number;
  config: any;
  decisionTables: DecisionTable[];
  questionGroups: QuestionGroup[] = [];
  questions: Question[];
  allObj: any[];
  private currentPage: number;

  constructor(
    private router: Router,
    private cIService: ConnectionsIndicatorService,
    private pService: PaginationService,
    private questionGroupService: QuestionGroupService,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    public testObjectService: TestObjectService,
    private userService: UserService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit() {
    this.currentPage = +this.route.snapshot.params['page'];
    this.userInfoId = +localStorage.getItem('userInfoId');
    this.connectionsIndicatorId = this.route.snapshot.params['id'];

    forkJoin([
      this.cIService.getById(this.connectionsIndicatorId),
      this.questionGroupService.getAllQuestionGroupForConnectionIndicator(this.connectionsIndicatorId),
      this.cIService.getAllQuestionById(this.connectionsIndicatorId),
      this.cIService.getAllDecisionTableById(this.connectionsIndicatorId),
    ]).subscribe((results) => {
      this.connectionsIndicator = results[0];
      if (this.connectionsIndicator.companyId !== this.userService.getUserData().companyId) {
        return this.router.navigate([`/`]);
      }
      this.decisionTables = results[3];

      this.questionGroups = results[1];

      this.questions = results[2].filter(
        (question) => !this.questionGroups.some((qG) => qG.id === question.questionGroupId),
      );

      this.questionGroups.forEach((questionGroup) => {
        questionGroup.questions = results[2].filter((question) => question.questionGroupId === questionGroup.id);
      });

      this.allObj = [
        ...this.questionGroups.map((obj) => {
          obj.networkType = 'questionGroup';
          return obj;
        }),
        ...this.decisionTables.map((obj) => {
          obj.networkType = 'decisionTable';
          return obj;
        }),
        ...this.questions.map((obj) => {
          obj.networkType = 'question';
          return obj;
        }),
      ];

      this.allObj = this.allObj.sort((a, b) => {
        return a.aorder - b.aorder;
      });

      this.config = {
        itemsPerPage: 1,
        currentPage: this.currentPage,
        totalItems: this.allObj.length,
      };
    });
  }

  pageChanged(event) {
    this.currentPage = event;
    this.config.currentPage = this.currentPage;
    this.router.navigate([this.router.url.split('page/')[0] + '/page/' + this.currentPage]);
    this.pService.setPaginationListener(true);
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  onReview() {
    this.router.navigate([`/user/connections-indicator/${this.connectionsIndicatorId}/review`]);
  }

  onBack() {
    this.router.navigate([`/user/connections-indicator/${this.connectionsIndicatorId}/user-info`]);
  }
}
