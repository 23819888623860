<div [id]="question.id">
  <label>
    <span>{{ question.questionNumber }}) </span>
    <app-question-text [text]="question.text"></app-question-text>
  </label>
  <br />
  <form class="form-text-list">
    <angular-editor
      *ngIf="question.questionType === 'RICH_TEXT'"
      type="text"
      [formControl]="answerTextAreaControl"
      (ngModelChange)="savePsyOrSuperTextAreaAnswer($event)"
      [config]="editorConfig"
    ></angular-editor>
    <mat-form-field *ngIf="question.questionType === 'TEXT_AREA'">
      <mat-label>Answer</mat-label>
      <textarea
        matInput
        [formControl]="answerTextAreaControl"
        [readonly]="!answering"
        (change)="savePsyOrSuperTextAreaAnswer($event)"
      ></textarea>
    </mat-form-field>
  </form>
  <div id="privacy">
    <mat-slide-toggle
      *ngIf="question.acl === 5"
      color="primary"
      [disabled]="!answering"
      [checked]="answerPrivate"
      (change)="togglePrivacy()"
    >
      This answer is private
    </mat-slide-toggle>
  </div>
</div>
